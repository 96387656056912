/**
 * An enum of server messages coming from the server.
 * Used to determine and display the correct error for the end user.
 */
export enum ServerMessage {
  UNAUTHORIZED = 'UNAUTHORIZED',
  JWT_MALFORMED = 'JWT_MALFORMED',
  URL_NOT_VALID = 'URL_NOT_VALID',
  FEATURE_NOT_ALLOWED = 'FEATURE_NOT_ALLOWED',
  FEATURE_NOT_SUPPORTED = 'FEATURE_NOT_SUPPORTED',
  PAGE_LIMIT_EXCEEDED = 'PAGE_LIMIT_EXCEEDED',
  CONTACT_NOT_ALLOWED = 'CONTACT_NOT_ALLOWED',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  SESSION_NOT_FOUND = 'SESSION_NOT_FOUND',
  SESSION_INVALID = 'SESSION_INVALID',
  REFRESH_TOKEN_NOT_FOUND = 'REFRESH_TOKEN_NOT_FOUND',
  REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  DATABASE_INTEGRITY_FAILED = 'DATABASE_INTEGRITY_FAILED',

  GEOLOCATION_FAILED = 'GEOLOCATION_FAILED',

  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_WRONG_CREDENTIALS = 'USER_WRONG_CREDENTIALS',

  BIRTHDATE_NOT_SPECIFIED = 'BIRTHDATE_NOT_SPECIFIED',
  BIRTHDATE_INCORRECT = 'BIRTHDATE_INCORRECT',

  LOGIN_EMAIL_INCORRECT = 'LOGIN_EMAIL_INCORRECT',
  LOGIN_PASSWORD_INCORRECT = 'LOGIN_PASSWORD_INCORRECT',

  USER_SETTINGS_LOCALE_INCORRECT = 'USER_SETTINGS_LOCALE_INCORRECT',
  USER_SETTINGS_WRONG_AUTH_PROVIDER = 'USER_SETTINGS_WRONG_AUTH_PROVIDER',

  PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',

  CHAR_ID_INCORRECT = 'CHAR_ID_INCORRECT',
  CHAR_ID_ALREADY_EXISTS = 'CHAR_ID_ALREADY_EXISTS',

  DISPLAY_NAME_LENGTH_INCORRECT = 'DISPLAY_NAME_LENGTH_INCORRECT',

  EMAIL_INCORRECT = 'EMAIL_INCORRECT',
  EMAIL_ALREADY_REGISTERED = 'EMAIL_ALREADY_REGISTERED',
  PHONE_ALREADY_EXISTS = 'PHONE_ALREADY_EXISTS',
  PHONE_INCORRECT = 'PHONE_INCORRECT',
  BIO_LENGTH_INCORRECT = 'BIO_LENGTH_INCORRECT',

  PROFESSION_NOT_FOUND = 'PROFESSION_NOT_FOUND',
  PROFESSION_TOO_MANY = 'PROFESSION_TOO_MANY',
  PROFESSION_NAME_INCORRECT = 'PROFESSION_NAME_INCORRECT',
  PROFESSION_IN_USE = 'PROFESSION_IN_USE',

  GENRE_NOT_FOUND = 'GENRE_NOT_FOUND',
  GENRE_TOO_MANY = 'GENRE_TOO_MANY',
  GENRE_NAME_INCORRECT = 'GENRE_NAME_INCORRECT',
  GENRE_IN_USE = 'GENRE_IN_USE',

  SKILL_NOT_FOUND = 'SKILL_NOT_FOUND',
  SKILL_TOO_MANY = 'SKILL_TOO_MANY',
  SKILL_NAME_INCORRECT = 'SKILL_NAME_INCORRECT',
  SKILL_IN_USE = 'SKILL_IN_USE',

  PROFILE_TYPE_NOT_SUPPORTED = 'PROFILE_TYPE_NOT_SUPPORTED',
  PROFILE_WITH_PROFILE_ORDERS = 'PROFILE_WITH_PROFILE_ORDERS',
  PROFILE_CANNOT_BE_CREATED_AGAIN = 'PROFILE_CANNOT_BE_CREATED_AGAIN',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  PROFILE_NOT_BELONGS_TO_USER = 'PROFILE_NOT_BELONGS_TO_USER',
  PROFILE_SEARCH_TOO_LONG_INPUT = 'PROFILE_SEARCH_TOO_LONG_INPUT',
  PROFILE_IN_PENALTY = 'PROFILE_IN_PENALTY',
  PROFILE_IN_BAN = 'PROFILE_IN_BAN',
  PROFILE_HAS_ACTIVE_ORDER = 'PROFILE_HAS_ACTIVE_ORDER',
  PROFILE_NOT_ALLOWED_TO_CHANGE_STATUS = 'PROFILE_NOT_ALLOWED_TO_CHANGE_STATUS',
  PROFILE_NOT_IN_ACTIVE_STATUS = 'PROFILE_NOT_IN_ACTIVE_STATUS',
  ALL_PROFILES_DELETED = 'ALL_PROFILES_DELETED',

  FILE_FORMAT_NOT_SUPPORTED = 'FILE_FORMAT_NOT_SUPPORTED',
  FILE_CATEGORY_UNKNOWN = 'FILE_CATEGORY_UNKNOWN',
  IMAGE_SIZE_TOO_SMALL = 'IMAGE_SIZE_TOO_SMALL',
  IMAGE_NOT_FOUND = 'IMAGE_NOT_FOUND',
  IMAGE_UPLOAD_FAILED = 'IMAGE_UPLOAD_FAILED', // UI only
  IMAGE_LOAD_FAILED = 'IMAGE_LOAD_FAILED', //  UI only

  WALLPAPERS_UPDATE_FAILED = 'WALLPAPERS_UPDATE_FAILED', // UI only
  WALLPAPERS_MAXIMUM_EXCEEDED = 'WALLPAPERS_MAXIMUM_EXCEEDED',

  IMAGES_MAXIMUM_EXCEEDED = 'IMAGES_MAXIMUM_EXCEEDED',
  IMAGE_DESCRIPTION_TOO_LONG = 'IMAGE_DESCRIPTION_TOO_LONG',

  NAME_FIELD_TOO_LONG = 'NAME_FIELD_TOO_LONG',
  ADDRESS_FIELD_TOO_LONG = 'ADDRESS_FIELD_TOO_LONG',
  ADDRESS_FIELD_MISSING = 'ADDRESS_FIELD_MISSING',
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',

  HOST_PROFILE_NOT_FOUND = 'HOST_PROFILE_NOT_FOUND',
  HOST_PROFILE_HOST_CHANGED = 'HOST_PROFILE_HOST_CHANGED',

  PROFILE_CANNOT_BE_HOSTED = 'PROFILE_CANNOT_BE_HOSTED',
  PROFILE_HOST_CANNOT_HOST = 'PROFILE_HOST_CANNOT_HOST',

  CALENDAR_NOT_EXISTS = 'CALENDAR_NOT_EXISTS',
  CALENDAR_ITEM_NOT_FOUND = 'CALENDAR_ITEM_NOT_FOUND',
  CALENDAR_ITEM_NESTED_PROFILE_NOT_FOUND = 'CALENDAR_ITEM_NESTED_PROFILE_NOT_FOUND',
  CALENDAR_ITEM_NAME_INCORRECT = 'CALENDAR_ITEM_NAME_INCORRECT',
  CALENDAR_ITEM_DESCRIPTION_INCORRECT = 'CALENDAR_ITEM_DESCRIPTION_INCORRECT',
  CALENDAR_ITEM_DATES_INCORRECT = 'CALENDAR_ITEM_DATES_INCORRECT',
  CALENDAR_ITEM_MESSAGE_TYPE_INCORRECT = 'CALENDAR_ITEM_MESSAGE_TYPE_INCORRECT',
  CALENDAR_ITEM_STARTED = 'CALENDAR_ITEM_STARTED',

  PROFILE_CANNOT_HAVE_OPENING_HOURS = 'PROFILE_CANNOT_HAVE_OPENING_HOURS',
  OPENING_HOURS_RESTRICTION_NOT_EXISTS = 'OPENING_HOURS_RESTRICTION_NOT_EXISTS',
  OPENING_HOURS_RESTRICTION_NOT_BELONGS_TO_PROFILE = 'OPENING_HOURS_RESTRICTION_NOT_BELONGS_TO_PROFILE',
  OPENING_HOURS_RESTRICTION_MESSAGE_INCORRECT = 'OPENING_HOURS_RESTRICTION_MESSAGE_INCORRECT',
  OPENING_HOURS_RESTRICTION_TIMES_INCORRECT = 'OPENING_HOURS_RESTRICTION_TIMES_INCORRECT',

  PROFILE_CANNOT_HAVE_SCHEDULE = 'PROFILE_CANNOT_HAVE_SCHEDULE',
  SCHEDULE_NOT_EXISTS = 'SCHEDULE_NOT_EXISTS',
  SCHEDULE_ITEM_NOT_EXISTS = 'SCHEDULE_ITEM_NOT_EXISTS',
  SCHEDULE_ITEM_PROFILE_NOT_EXISTS = 'SCHEDULE_ITEM_PROFILE_NOT_EXISTS',
  SCHEDULE_ITEM_PROFILE_CHANGED = 'SCHEDULE_ITEM_PROFILE_CHANGED',
  SCHEDULE_ITEM_NOT_SUITABLE_PROFILE = 'SCHEDULE_ITEM_NOT_SUITABLE_PROFILE',
  SCHEDULE_ITEM_TIMES_INCORRECT = 'SCHEDULE_ITEM_TIMES_INCORRECT', // UI only

  CHAT_CHANNEL_NOT_FOUND = 'CHAT_CHANNEL_NOT_FOUND',
  CHAT_CHANNEL_LOCKED = 'CHAT_CHANNEL_LOCKED',
  CHAT_CHANNEL_INCORRECT_PARTICIPANTS = 'CHAT_CHANNEL_INCORRECT_PARTICIPANTS',
  CHAT_CHANNEL_NAME_INCORRECT = 'CHAT_CHANNEL_NAME_INCORRECT',
  CHAT_CHANNEL_PROFILE_CANNOT_BE_IN_CHANNEL = 'CHAT_CHANNEL_PROFILE_CANNOT_BE_IN_CHANNEL',
  CHAT_CHANNEL_PROFILE_CANNOT_BE_IN_GROUP = 'CHAT_CHANNEL_PROFILE_CANNOT_BE_IN_GROUP',
  CHAT_CHANNEL_PROFILE_NOT_PARTICIPATES = 'CHAT_CHANNEL_PROFILE_NOT_PARTICIPATES',

  CHAT_MESSAGE_NOT_FOUND = 'CHAT_MESSAGE_NOT_FOUND',
  CHAT_MESSAGE_TOO_LONG = 'CHAT_MESSAGE_TOO_LONG',

  LIKE_PROFILE_PROFILE_TO_NOT_FOUND = 'LIKE_PROFILE_PROFILE_TO_NOT_FOUND',
  LIKE_PROFILE_DENIED = 'LIKE_PROFILE_DENIED',

  LIKE_POST_DENIED = 'LIKE_POST_DENIED',
  LIKE_POST_COMMENT_DENIED = 'LIKE_POST_COMMENT_DENIED',

  POST_DESCRIPTION_TOO_LONG = 'POST_DESCRIPTION_TOO_LONG',
  POST_MEDIA_TOO_LARGE = 'POST_MEDIA_TOO_LARGE',
  POST_MEDIA_REACHED_MAX_ITEMS = 'POST_MEDIA_REACHED_MAX_ITEMS',
  POST_MEDIA_RATIO_INVALID = 'POST_MEDIA_RATIO_INVALID',
  POST_NOT_FOUND = 'POST_NOT_FOUND',

  POST_COMMENT_NOT_FOUND = 'POST_COMMENT_NOT_FOUND',
  POST_COMMENT_NOT_BELONGS_TO_PROFILE = 'POST_COMMENT_NOT_BELONGS_TO_PROFILE',

  HASHTAG_NAME_INCORRECT = 'HASHTAG_NAME_INCORRECT',
  HASHTAG_COUNT = 'HASHTAG_COUNT',

  REVIEW_NOT_FOUND = 'REVIEW_NOT_FOUND',
  REVIEW_BEFORE_EVENT_ENDS = 'REVIEW_BEFORE_EVENT_ENDS',
  REVIEW_TEXT_INCORRECT = 'REVIEW_TEXT_INCORRECT',
  REVIEW_STARS_INCORRECT = 'REVIEW_STARS_INCORRECT',
  REVIEW_ALREADY_EXISTS = 'REVIEW_ALREADY_EXISTS',
  REVIEW_CANNOT_BE_EDITED = 'REVIEW_CANNOT_BE_EDITED',
  REVIEW_AFTER_DEADLINE = 'REVIEW_AFTER_DEADLINE',

  YOUTUBE_LINK_INCORRECT = 'YOUTUBE_LINK_INCORRECT',
  YOUTUBE_LINK_CHANNEL = 'YOUTUBE_LINK_CHANNEL',
  YOUTUBE_LINK_PLAYLIST = 'YOUTUBE_LINK_PLAYLIST',
  YOUTUBE_LINK_MAXIMUM_EXCEEDED = 'YOUTUBE_LINK_MAXIMUM_EXCEEDED',

  SPOTIFY_LINK_INCORRECT = 'SPOTIFY_LINK_INCORRECT',
  SPOTIFY_LINK_MAXIMUM_EXCEEDED = 'SPOTIFY_LINK_MAXIMUM_EXCEEDED',

  SOUNDCLOUD_LINK_MAXIMUM_EXCEEDED = 'SOUNDCLOUD_LINK_MAXIMUM_EXCEEDED',
  EMBED_LINK_SOUNDCLOUD_INCORRECT = 'EMBED_LINK_SOUNDCLOUD_INCORRECT',

  PROFILE_CANNOT_HAVE_SHOWCASE = 'PROFILE_CANNOT_HAVE_SHOWCASE',

  PRICE_ITEM_CATEGORY_NOT_FOUND = 'PRICE_ITEM_CATEGORY_NOT_FOUND',
  PRICE_ITEM_CATEGORY_IN_USE = 'PRICE_ITEM_CATEGORY_IN_USE',
  PRICE_ITEM_ADDITION_NOT_FOUND = 'PRICE_ITEM_ADDITION_NOT_FOUND',
  PRICE_ITEM_ADDITION_NAME_INCORRECT = 'PRICE_ITEM_ADDITION_NAME_INCORRECT',
  PRICE_ITEM_NOT_FOUND = 'PRICE_ITEM_NOT_FOUND',
  PRICE_ITEM_NAME_INCORRECT = 'PRICE_ITEM_NAME_INCORRECT',
  PRICE_ITEM_DESCRIPTION_INCORRECT = 'PRICE_ITEM_DESCRIPTION_INCORRECT',

  PROFILE_ORDER_NOT_FOUND = 'PROFILE_ORDER_NOT_FOUND',
  PROFILE_ORDER_IS_EMPTY = 'PROFILE_ORDER_IS_EMPTY',
  PROFILE_ORDER_ALREADY_PAID = 'PROFILE_ORDER_ALREADY_PAID',
  PROFILE_ORDER_AUTHOR_CANNOT_ORDER = 'PROFILE_ORDER_AUTHOR_CANNOT_ORDER',
  PROFILE_ORDER_PROFILE_CANNOT_BE_ORDERED = 'PROFILE_ORDER_PROFILE_CANNOT_BE_ORDERED',
  PROFILE_ORDER_PROFILE_BUSY = 'PROFILE_ORDER_PROFILE_BUSY',
  PROFILE_ORDER_NAME_INCORRECT = 'PROFILE_ORDER_NAME_INCORRECT',
  PROFILE_ORDER_DESCRIPTION_INCORRECT = 'PROFILE_ORDER_DESCRIPTION_INCORRECT',
  PROFILE_ORDER_PROFILE_REPLY_INCORRECT = 'PROFILE_ORDER_PROFILE_REPLY_INCORRECT',
  PROFILE_ORDER_PROFILE_CANCEL_REASON_INCORRECT = 'PROFILE_ORDER_PROFILE_CANCEL_REASON_INCORRECT',
  PROFILE_ORDER_ACCEPTANCE_NOT_VALID = 'PROFILE_ORDER_ACCEPTANCE_NOT_VALID',
  PROFILE_ORDER_ACCEPTANCE_ORDER_STARTED = 'PROFILE_ORDER_ACCEPTANCE_ORDER_STARTED',
  PROFILE_ORDER_ACCEPTANCE_AT_NOT_PRESENT = 'PROFILE_ORDER_ACCEPTANCE_AT_NOT_PRESENT',
  PROFILE_ORDER_ACCEPTANCE_EARLY = 'PROFILE_ORDER_ACCEPTANCE_EARLY',
  PROFILE_ORDER_REJECTED = 'PROFILE_ORDER_REJECTED',
  PROFILE_ORDER_PROFILE_NOT_AT_LOCATION = 'PROFILE_ORDER_PROFILE_NOT_AT_LOCATION',
  PROFILE_ORDER_ALREADY_CONFIRMED = 'PROFILE_ORDER_ALREADY_CONFIRMED',
  PROFILE_ORDER_NOT_CONFIRMED = 'PROFILE_ORDER_NOT_CONFIRMED',
  PROFILE_ORDER_NOT_PAID = 'PROFILE_ORDER_NOT_PAID',
  PROFILE_ORDER_ADDITIONAL_PRICE_NOT_VALID = 'PROFILE_ORDER_ADDITIONAL_PRICE_NOT_VALID',
  PROFILE_ORDER_MIN_PRICE = 'PROFILE_ORDER_MIN_PRICE',
  PROFILE_ORDER_MINIMAL_DISTANCE_FAILED = 'PROFILE_ORDER_MINIMAL_DISTANCE_FAILED',
  PROFILE_ORDER_EXPIRED = 'PROFILE_ORDER_EXPIRED',
  PROFILE_ORDER_NO_ACTIVE_ORDER = 'PROFILE_ORDER_NO_ACTIVE_ORDER',
  PROFILE_ORDER_IN_DISPUTE = 'PROFILE_ORDER_IN_DISPUTE',
  PROFILE_ORDER_DISPUTE_ALREADY_EXISTS = 'PROFILE_ORDER_DISPUTE_ALREADY_EXISTS',
  PROFILE_PENALTY_INVALID = 'PROFILE_PENALTY_INVALID',
  PROFILE_PENALTY_EXPIRATION_INVALID = 'PROFILE_PENALTY_EXPIRATION_INVALID',
  PROFILE_ORDER_NOT_IN_DISPUTE = 'PROFILE_ORDER_NOT_IN_DISPUTE',
  PROFILE_ORDER_SEARCH_TOO_LONG_INPUT = 'PROFILE_ORDER_SEARCH_TOO_LONG_INPUT',
  PROFILE_ORDER_LOCATION_MISSING = 'PROFILE_ORDER_LOCATION_MISSING', // UI only

  PAYMENTS_INTENT_NOT_FOUND = 'PAYMENTS_INTENT_NOT_FOUND',
  PAYMENTS_EXPIRED = 'PAYMENTS_EXPIRED',
  PAYMENTS_INVOICE_ALREADY_EXISTS = 'PAYMENTS_INVOICE_ALREADY_EXISTS',
  PAYMENTS_ALREADY_REFUNDED = 'PAYMENTS_ALREADY_REFUNDED',
  PAYMENTS_INCONSISTENT = 'PAYMENTS_INCONSISTENT',
  PAYMENTS_ORDER_ALREADY_TRANSFERRED = 'PAYMENTS_ORDER_ALREADY_TRANSFERRED',
  STRIPE_ACCOUNT_NOT_FOUND = 'STRIPE_ACCOUNT_NOT_FOUND',
  STRIPE_CUSTOMER_NOT_FOUND = 'STRIPE_CUSTOMER_NOT_FOUND',
  STRIPE_WEBHOOK_FAILED = 'STRIPE_WEBHOOK_FAILED',
  STRIPE_LANGUAGE_INCORRECT = 'STRIPE_LANGUAGE_INCORRECT',

  QR_CANNOT_BE_SCANNED_BY_ORDERED_ARTIST = 'QR_CANNOT_BE_SCANNED_BY_ORDERED_ARTIST',

  VERIFIED_CHANGE_TIMEOUT = 'VERIFIED_CHANGE_TIMEOUT',
  VERIFIED_CHANGE_CODE_NOT_FOUND = 'VERIFIED_CHANGE_CODE_NOT_FOUND',
  VERIFIED_CHANGE_CODE_INCORRECT = 'VERIFIED_CHANGE_CODE_INCORRECT',

  FIREBASE_EMAIL_ALREADY_REGISTERED = 'FIREBASE_EMAIL_ALREADY_REGISTERED',
  FIREBASE_ID_TOKEN_INVALID = 'FIREBASE_ID_TOKEN_INVALID',
  FIREBASE_UID_INVALID = 'FIREBASE_UID_INVALID',
  FIREBASE_GENERAL_ERROR = 'FIREBASE_GENERAL_ERROR',
  FIREBASE_NO_PASSWORD_NEEDED = 'FIREBASE_NO_PASSWORD_NEEDED',

  LEGAL_TAX_ID_INCORRECT = 'LEGAL_TAX_ID_INCORRECT',
  LEGAL_VAT_ID_INCORRECT = 'LEGAL_VAT_ID_INCORRECT',
  LEGAL_VAT_NOT_FOUND = 'LEGAL_VAT_NOT_FOUND',
  LEGAL_REGISTRATION_ID_INCORRECT = 'LEGAL_REGISTRATION_ID_INCORRECT',
  LEGAL_REGISTRATION_REQUIRED = 'LEGAL_REGISTRATION_REQUIRED',
  LEGAL_REGISTRATION_NUMBER_NOT_FOUND = 'LEGAL_REGISTRATION_NUMBER_NOT_FOUND',

  PDF_COULD_NOT_BE_RENDERED = 'PDF_COULD_NOT_BE_RENDERED',
  CONTRACT_NOT_FOUND = 'CONTRACT_NOT_FOUND',

  PLATFORM_DOCUMENT_NOT_FOUND = 'PLATFORM_DOCUMENT_NOT_FOUND',
  PLATFORM_RATING_TEXT_TOO_LONG = 'PLATFORM_RATING_TEXT_TOO_LONG',

  NOTIFICATION_NOT_FOUND = 'NOTIFICATION_NOT_FOUND',

  SORT_COLUMN_INCORRECT = 'SORT_COLUMN_INCORRECT',

  PROFILE_ATTRIBUTE_NAME_LENGTH_INCORRECT = 'PROFILE_ATTRIBUTE_NAME_LENGTH_INCORRECT',

  SLUG_LENGTH_INCORRECT = 'SLUG_LENGTH_INCORRECT',

  CITY_NAME_INCORRECT = 'CITY_NAME_INCORRECT',
  CITY_COUNTRY_CODE_INCORRECT = 'CITY_COUNTRY_CODE_INCORRECT',
  CITY_NOT_FOUND = 'CITY_NOT_FOUND',

  SEO_PAGE_TITLE_LENGTH_INCORRECT = 'SEO_PAGE_TITLE_LENGTH_INCORRECT',
  SEO_PAGE_SUBTITLE_LENGTH_INCORRECT = 'SEO_PAGE_SUBTITLE_LENGTH_INCORRECT',
  SEO_PAGE_NOT_FOUND = 'SEO_PAGE_NOT_FOUND',
}
