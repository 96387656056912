<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            url="chat"
            type="info"
            #dialog>

  <!--Top action bar-->
  <ng-template pTemplate="topBar">
    <div class="p-d-flex p-ai-center p-jc-between w-100">
      <div class="p-d-flex p-ai-center">
        <!--Avatar-->
        <div class="p-pr-3">
          <app-avatar [profile]="channel | firstProfile: currentProfile"
                      [redirect]="false"
                      [showTooltip]="false"
                      [sizePx]="40"></app-avatar>
        </div>

        <!--Names-->
        <div *ngIf="channel">
          <div *ngVar="(channel | firstProfile: currentProfile) as profile" class="header-names">
            <h1 class="l-count-1 unselectable">{{ profile.displayName || profile.charId }}</h1>
          </div>
        </div>
      </div>

      <!--Get back from the chat-->
      <app-button type="icon"
                  icon="fa-solid fa-xmark"
                  styleClass="exit-button"
                  pTooltip="Close"
                  i18n-pTooltip
                  (clicked)="dialog.closeDialog()"></app-button>

    </div>
  </ng-template>


  <div class="p-relative p-mb-4">

    <!--Info Overlay to Confirm-->
    <div *ngIf="infoOverlayVisible" [@fade]="infoOverlayVisible"
         class="p-absolute info-overlay p-d-flex p-jc-center w-100 h-100 p-px-3 p-px-md-5">
      <article class="info-overlay-content p-d-flex p-flex-column p-ai-center">
        <i class="fa-solid fa-circle-check info-overlay-icon"></i>
        <h1 class="p-mt-2" i18n>Chat Conditions</h1>
        <article i18n>
          <p><b>All conversations are reviewed by Umevia</b> to protect both sides from any kind of violence content.
          </p>
          <p class="p-mt-2">By Accepting, you confirm that you will respect the other side and <b>you will not share any
            kind of
            personal contact information</b> such as emails, phone numbers, or links.</p>
          <p class="p-mt-2"><b>Your inappropriate behavior can lead to penalization.</b></p>
          <p class="p-mt-2">Use this chat to know the details about the event.</p>
        </article>
        <app-button label="Agree"
                    i18n-label
                    icon="fa-solid fa-check"
                    styleClass="p-mt-3"
                    type="primary"
                    (clicked)="infoOverlayVisible = false"></app-button>
      </article>
    </div>
    <div *ngIf="infoOverlayVisible" style="min-height: 8rem"></div>

    <!--Messages-->
    <app-lazy-list [(items)]="messages"
                   [component]="this"
                   scrollHeight="33rem"
                   [virtual]="false"
                   [enableTop]="true"
                   [enableBottom]="false"
                   [skipInitialLoad]="true"
                   [beforeAppend]="reversePage.bind(this)"
                   (topLoaded)="processViolations($event.content)"
                   [loadFunction]="callLoadMessages.bind(this, messages.nextPage)"
                   #list>
      <ng-template let-message pTemplate="item">
        <div class="p-mb-3 p-px-3 p-px-md-5">

          <!--Message-->
          <div class="message-item p-d-flex"
               [class.p-mt-3]="message.avatarVisible"
               [class.not-sent]="message.notSent">

            <!--Avatar-->
            <div class="p-mr-2">
              <app-avatar [profile]="message.author"
                          [sizePx]="40"
                          [redirect]="false"></app-avatar>
            </div>

            <!--Message-->
            <div class="message-content">

              <!--Name and Date-->
              <div class="p-d-flex p-ai-center">
                <h4>{{message.author.displayName || message.author.charId}}</h4>
                <span
                  class="time p-ml-2">{{(message.violationsCheckedAt || message.createdAt) | date: 'HH:mm  dd.MM.yyyy'}}</span>
              </div>

              <p>{{message.content}}</p>

              <!--Violation content reason-->
              <small *ngIf="message.notSent"
                     class="p-mt-1 not-sent-reason" i18n>Not delivered. Contact not allowed.</small>
            </div>

          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="skeleton">
        <div *ngFor="let i of [].constructor(4)" class="p-mt-2">
          <div class="p-d-flex p-px-3 p-px-md-5">
            <p-skeleton shape="circle" size="40px" styleClass="p-mr-2"></p-skeleton>
            <div>
              <p-skeleton width="50%" height="1.5rem" styleClass="rounded-small"></p-skeleton>
              <p-skeleton width="100%" height="3rem" styleClass="rounded-small p-mt-2"></p-skeleton>
              <span
                style="opacity: 0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, molestiae.</span>
            </div>
          </div>
        </div>
      </ng-template>
    </app-lazy-list>
  </div>

  <!--Bottom Action Bar-->
  <ng-template pTemplate="bottomBar"
               let-onSave="onSave" let-saveLoading="saveLoading" let-saveDisable="saveDisable"
               let-onDiscard="onDiscard" let-discardDisable="discardDisable">

    <div class="p-d-flex p-ai-center w-100">
      <form [formGroup]="form" class="w-100 p-pr-2">
        <app-text-input [textArea]="true"
                        [form]="form"
                        styleClass="w-100"
                        formFieldName="text"
                        [rows]="2"
                        placeholder="Type a message"
                        i18n-placeholder
                        (keyupEvent)="onKeyup($event)"
                        [disable]="infoOverlayVisible || loading || sending"
                        [notContact]="true"
                        [length]="{max: Restrictions.MAX_CHAT_MESSAGE_LENGTH}"
                        (ngInit)="inputField = inputF"
                        #inputF>
        </app-text-input>

        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.CONTACT_NOT_ALLOWED" i18n>
          Your message probably contains a link, email, or has exceeded allowed maximum of numbers.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_ORDER_NO_ACTIVE_ORDER"
                                i18n>
          This chat has been closed since the order has already finished.
        </app-backend-validation>
      </form>

      <!--Send button-->
      <app-button type="icon"
                  icon="fa-solid fa-paper-plane"
                  [loading]="sending"
                  [disabled]="form.invalid || sending || saveLoading || saveDisable || infoOverlayVisible || loading"
                  (clicked)="onSendMessage()"></app-button>
    </div>

  </ng-template>

</app-dialog>
