import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'

@Injectable({
  providedIn: 'root'
})
export class SupportService extends ApiService {

  /**
   * Makes an API call to retrieve all versions of legal documents.
   */
  callGetDocuments(): Observable<BaseResp<SupportDocumentLinks>> {
    return this.get(Endpoint.SUPPORT_DOCUMENTS, this.getHeaders(), false)
  }
}

export interface DocumentLinks {
  tosLink: string
  tosArtistLink: string
  privacyLink: string
  cookiesLink: string
}

export interface SupportDocumentLink {
  link: string
  effectiveAt: Date
  endAt?: Date
  version: number
}

export interface SupportDocumentLinks {
  tosLinks: SupportDocumentLink[]
  tosArtistLinks: SupportDocumentLink[]
  privacyLinks: SupportDocumentLink[]
  cookiesLinks: SupportDocumentLink[]
}
