import {Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {CalendarCellDetail} from '../profile-calendar.component'
import {ProfileResp} from '../../../../service/profile.service'
import {ApiComponent} from '../../../abstract/api.component'
import {CalendarItemNestedProfileResp, CalendarItemResp} from '../../../../service/calendar.service'
import {CalendarMessageType} from 'src/app/common/calendar-message-type'
import {fadeAnimation} from '../../../../animation/fade.animation'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-calendar-detail',
  templateUrl: './profile-calendar-detail.component.html',
  styleUrls: ['./profile-calendar-detail.component.scss']
})
export class ProfileCalendarDetailComponent extends ApiComponent implements OnChanges {

  @Input()
  data?: CalendarCellDetail

  @Input()
  currentProfile: ProfileResp

  /**
   * Contains the fetched profiles array keyed by its 'profileId'.
   */
  fetchedProfiles: CalendarItemNestedProfileResp[] = []

  /**
   * Controls the visibility of the message full dialog.
   */
  messageFullVisible: boolean
  /**
   * Contains the data of the message full dialog content.
   */
  messageFulLData?: CalendarItemResp

  CalendarMessageType: typeof CalendarMessageType = CalendarMessageType

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      for (const profile of this.data.fetchedProfiles) {
        this.fetchedProfiles[profile?.profileId] = profile
      }
    }
  }

  /**
   * Displays the message content to the full screen.
   *
   * @param item The data to be displayed
   * @param show Controls the visibility.
   */
  showMessage(item: CalendarItemResp, show: boolean): void {
    this.messageFulLData = show ? item : null
    this.messageFullVisible = show
  }
}
