<div class="wrapper p-d-flex p-jc-center p-py-2">
  <div class="component-wrapper">
    <div class="p-grid" *ngIf="isScreenOf(Screen.SM)">
      <div class="p-col-12 p-sm-6 p-md-4 p-p-0" *ngFor="let a of attributes">
        <ng-container [ngTemplateOutlet]="attrTemplate" [ngTemplateOutletContext]="{attr: a}"/>
      </div>
    </div>

    <p-accordion *ngIf="!isScreenOf(Screen.SM)"
                 [activeIndex]="0"
                 [multiple]="false"
                 (activeIndexChange)="onAccordionChange($event)">
      <p-accordionTab *ngFor="let attr of attributes">
        <ng-template pTemplate="header">
          <div class="attribute-header p-d-flex p-jc-between p-ai-center p-p-2 w-100">
            <div class="p-d-flex p-ai-center">
              <i class="{{attr.icon}} p-mx-2"></i>
              <span class="p-mr-2">{{ attr.title }}</span>
              <span *ngIf="attr?.expanded && attr.items.length > defaultSlice" @fade>
                +{{ attr.items.length - defaultSlice }}
              </span>
            </div>
            <i class="fa-solid p-mr-2"
               [class.fa-chevron-left]="!attr.expanded"
               [class.fa-chevron-down]="attr.expanded"></i>
          </div>
        </ng-template>

        <ng-template [ngTemplateOutlet]="attrTemplate" [ngTemplateOutletContext]="{attr: attr}"/>
      </p-accordionTab>
    </p-accordion>

    <div class="w-100 p-d-flex p-jc-center"
         *ngIf="(genres?.items?.length > defaultSlice
                || skills?.items?.length > defaultSlice
                || piCategories?.items?.length > defaultSlice)">
      <!-- More -->
      <app-button type="text"
                  label="More"
                  i18n-label
                  icon="fa-solid fa-plus"
                  styleClass="p-mt-2"
                  (clicked)="attributesPreviewDialogVisible = true"></app-button>
    </div>
  </div>
</div>

<ng-template #attrTemplate let-attr="attr">
  <div class="attribute-box rounded-small p-mr-sm-2 p-pt-sm-2 h-100 p-d-flex p-flex-column p-jc-between">
    <div>
      <div *ngIf="isScreenOf(Screen.SM)" class="attribute-header p-d-flex p-ai-center p-jc-between p-p-2">
        <div class="p-d-flex p-ai-center">
          <i class="{{attr.icon}} p-mx-2"></i>
          <span>{{ attr.title }}</span>
        </div>
        <span *ngIf="attr.items.length > defaultSlice">+{{ attr.items.length - defaultSlice }}</span>
      </div>
      <div class="attribute-content">
        <div *ngFor="let item of attr.items | slice: 0:defaultSlice"
             class="attribute-item p-d-flex p-ai-center p-py-2 p-px-3">
          <i class="{{item['icon'] || 'fa-solid fa-check'}} p-mr-2"></i>
          <span>{{ item['name'] }}</span>
        </div>

        <app-no-content *ngIf="editButtonVisible && !attr.items?.length"
                        styleClass="p-m-2">{{ attr.noContentDescription }}
        </app-no-content>

      </div>
    </div>
    <!-- Save Action -->
    <div class="p-d-flex p-jc-center"
         *ngIf="editButtonVisible && attr.onEdit">
      <app-button type="text"
                  label="Edit"
                  i18n-label
                  icon="fa-solid fa-pen"
                  styleClass="p-mt-2"
                  (clicked)="attr.onEdit?.()"></app-button>
    </div>
  </div>
</ng-template>

<!--Skills edit-->
<app-attribute-search-dialog *ngIf="editSkillsDialogVisible"
                             [(show)]="editSkillsDialogVisible"
                             [(selectedItems)]="skills.items"
                             (selectedItemsChange)="profile.skills = skills.items; profileCompletionService.closeCompletionItemAndCheck()"
                             type="skills"
                             behavior="save"
                             dialogHeader="Edit Skills"
                             i18n-dialogHeader/>

<!--Genres edit-->
<app-attribute-search-dialog *ngIf="editGenresDialogVisible"
                             [(show)]="editGenresDialogVisible"
                             [(selectedItems)]="genres.items"
                             (selectedItemsChange)="profile.genres = genres.items; profileCompletionService.closeCompletionItemAndCheck()"
                             type="genres"
                             behavior="save"
                             dialogHeader="Edit Genres"
                             i18n-dialogHeader/>

<app-dialog *ngIf="attributesPreviewDialogVisible"
            [(show)]="attributesPreviewDialogVisible"
            [component]="this"
            header="All attributes"
            i18n-header
            [allowSaveOption]="false"
            type="info">
  <div *ngFor="let attr of attributes" class="p-mb-3">
    <h2 class="p-mt-0 p-mb-2">{{ attr.title }}</h2>
    <div class="preview-item p-d-flex p-ai-center p-py-3 p-px-2" *ngFor="let item of attr.items">
      <i class="{{item.icon || 'fa-solid fa-check'}} p-mr-2"></i>
      <div>{{ item.name }}</div>
    </div>
  </div>
</app-dialog>
