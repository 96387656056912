import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {NgIf} from '@angular/common'

@Component({
  selector: 'app-frontend-validation',
  templateUrl: './frontend-validation.component.html',
  styleUrls: ['./frontend-validation.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class FrontendValidationComponent {

  @Input()
  form: FormGroup

  @Input()
  field: string

  @Input()
  error: string

  constructor() {
  }
}
