import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  EventEmitter,
  OnInit,
  Output,
  RendererFactory2,
  ViewContainerRef
} from '@angular/core'
import {Circle, CircleMarker, Icon, LatLngBounds, LatLngExpression, Map, Marker} from 'leaflet'
import {LeafletService} from '../../service/ui/leaflet.service'
import {ProfileType} from '../../common/profile-type'
import {BriefProfileResp} from '../../service/profile.service'
import {stringifyProfileType} from '../../pipe/profile-type.pipe'
import {ApiComponent} from '../abstract/api.component'
import {ProfileMarkerComponent} from './profile-marker/profile-marker.component'
import {BriefMapProfileResp} from '../../service/map.service'

@Directive()
export class MapHelper extends ApiComponent implements OnInit {

  /**
   * Stores the LatLng bounds value of current loaded markers. Required for the {@link calculateBoundsDifference}.
   */
  lastSearchedMapBounds: LatLngBounds

  /**
   * Enables the popup bubble on each marker.
   * The property is initialized in the {@link ngOnInit} function.
   */
  bubbleEnabled: boolean

  /**
   * Invokes when a user has clicked on the marker icon.
   */
  @Output()
  profileClicked = new EventEmitter<BriefProfileResp>()

  protected map: Map

  /**
   *  Represents a radius of all circle markers depending on the {@link map} zoom level.
   */
  protected currentCircleMarkerRadius = 7

  /**
   * An array of all circle markers currently visible on the {@link map}.
   */
  protected circleMarkers: CircleMarker[] = []
  /**
   * An array of all detail markers currently visible on the {@link map}.
   */
  protected detailMarkers: DetailMarker[] = []
  /**
   * The logic behind the detail marker popup is different from the circle markers.
   * This value represents the currently opened detail marker popup. Used for closing mechanism {@link closeDetailMarkerBubble}.
   */
  protected currentOpenDetailMarker?: DetailMarker

  /**
   * Represents a floating action button to download markers from the server.
   */
  floatingSearchButtonVisible: boolean

  /**
   * Loads the default geopoint marker icon.
   */
  protected defaultMarkerIcon: Icon

  /**
   * Renders Angular components in the view.
   */
  private renderer

  /**
   * The current object of the {@link radiusMarkerCircle} marker shown on the map.
   */
  private radiusMarkerCircle?: Circle

  constructor(
    protected changeRef: ChangeDetectorRef,
    private rendererFactory: RendererFactory2,
    private viewRef: ViewContainerRef,
    protected leaflet: LeafletService) {
    super()
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  ngOnInit(): void {
    if (this.leaflet.isReady()) {
      this.defaultMarkerIcon = this.leaflet.icon({
        iconUrl: 'assets/map/icon/default-geopoint.png',
        shadowUrl: 'assets/map/icon/default-geopoint-shadow.png',

        iconSize: [35, 47],
        shadowSize: [43, 47],
        iconAnchor: [18, 47],
        shadowAnchor: [18, 47]
      })
    }
  }

  /**
   * Calculates the X(lng) and Y(lat) difference between the {@link lastSearchedMapBounds} and the current.
   */
  calculateBoundsDifference(current: LatLngBounds): { xDiff: number; yDiff: number } {
    const previous = this.lastSearchedMapBounds

    const x: any = Math.abs(previous.getSouthWest().lng - current.getSouthWest().lng).toFixed(4)
    const y: any = Math.abs(previous.getSouthWest().lat - current.getSouthWest().lat).toFixed(4)
    return {xDiff: (x * 1), yDiff: (y * 1)} // converts to numbers
  }

  /**
   * Gets the circle marker color by the type of a marker.
   *
   * @param type A type of a marker.
   */
  protected getColorByMarkerType(type: ProfileType): string {
    switch (type) {
      case ProfileType.ENTERPRISE:
        return 'black'
      case ProfileType.EVENT:
        return 'green'
      default:
        return 'blue'
    }
  }

  /**
   * Constructs the marker component.
   */
  protected constructMarkerComponent(): ComponentRef<ProfileMarkerComponent> {
    return this.viewRef.createComponent(ProfileMarkerComponent)
  }

  /**
   * Removes a detail marker component from the view.
   */
  protected rendererRemoveChild(marker: Marker, compRef: ComponentRef<any>): void {
    this.renderer.removeChild(
      marker.getElement(),
      compRef.location.nativeElement
    )
  }

  /**
   * Appends a detail marker component from the view.
   */
  protected rendererAppendChild(marker: Marker, compRef: ComponentRef<any>): void {
    this.renderer.appendChild(
      marker.getElement(),
      compRef.location.nativeElement
    )
  }

  /**
   * Creates the base tooltip showed when a user hovers on markers.
   *
   * @param profile Used to display proper text on the tooltip.
   */
  protected createToolTipHTML(profile: BriefProfileResp): string {
    return `
      <div>
        <div style="font-size: 1.2rem; font-weight: bold; text-decoration: underline;">` + profile.displayName + `</div>
        <div style="font-size: 1rem; font-style: italic; margin-top: -5px;">` + stringifyProfileType(profile.profileType) + `</div>
      </div>
    `
  }

  /**
   * Adds a circle marker to the map.
   *
   * @param marker The marker data from the server.
   */
  protected addCircleMarker(marker: BriefMapProfileResp): void {
    const profile = marker.profile
    // Marker position
    const latLngVal: LatLngExpression = {
      lat: profile.address?.lat,
      lng: profile.address?.lng
    }

    if (this.leaflet.isReady()) {
      // Circle marker
      const markerDiv = this.leaflet.circleMarker(latLngVal, {
        fillColor: this.getColorByMarkerType(profile.profileType),
        fillOpacity: 1,
        color: 'white',
        weight: 2,
        radius: this.currentCircleMarkerRadius
      })

      // Create tooltip
      markerDiv.bindTooltip(this.createToolTipHTML(profile))
      markerDiv.addTo(this.map)

      // Click listener
      markerDiv.on('click', () => {
        if (this.bubbleEnabled) {
          this.openCircleMarkerBubble(markerDiv, marker)
        }
        this.map.panTo(latLngVal)
        this.profileClicked.emit(profile)

        // close the tooltip
        setTimeout(() => {
          markerDiv.closeTooltip()
        })
      })
      this.circleMarkers.push(markerDiv)
    }
  }

  /**
   * Adds a detail marker to the map.
   *
   * @param marker The marker data from the server.
   */
  protected addDetailMarker(marker: BriefMapProfileResp): void {
    const profile = marker.profile
    // Marker position
    const latLngVal: LatLngExpression = {
      lat: profile.address?.lat,
      lng: profile.address?.lng
    }

    const detailMarker = this.createDetailMarker(marker, latLngVal)

    // Click listener
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    detailMarker.marker.on('click', (event) => {
      if (detailMarker.componentRef !== this.currentOpenDetailMarker?.componentRef) {
        this.closeDetailMarkerBubble()
      }
      this.currentOpenDetailMarker = detailMarker
      this.map.panTo(latLngVal)
      this.profileClicked.emit(profile)

      // close the tooltip
      setTimeout(() => {
        detailMarker.marker.closeTooltip()
      })
    })

    // Create tooltip
    detailMarker.marker.bindTooltip(this.createToolTipHTML(profile))

    // Render
    detailMarker.marker.addTo(this.map)
    this.detailMarkers.push(detailMarker)
    this.rendererAppendChild(detailMarker.marker, detailMarker.componentRef)
  }

  /**
   * Creates a new radius marker on the map.
   * Before it creates a new one, it removes the previous circle marker ({@link radiusMarkerCircle})
   */
  protected addRadiusMarker(marker: RadiusMarker, latLngVal?: LatLngExpression): void {
    if (this.radiusMarkerCircle) {
      this.radiusMarkerCircle.removeFrom(this.map)
    }

    if (latLngVal) {
      marker.latLng = latLngVal
    }
    if (this.leaflet.isReady()) {
      this.radiusMarkerCircle = new this.leaflet.L.Circle(marker.latLng)
        .setRadius(marker.radius).setStyle({
          color: '#6eb9f3',
          opacity: 0.8,
          fillColor: '#6eb9f3',
          fillOpacity: 0.8
        })
      this.radiusMarkerCircle.addTo(this.map)
    }
  }

  /**
   * Creates the detail marker component.
   * Replaces the Leaflet's divIcon implementation with a custom Angular component marker.
   * Based on {@link https://stackoverflow.com/a/54628656/7586486}.
   *
   * @param marker Marker data.
   * @param latLngVal Position of the marker on a map.
   */
  protected createDetailMarker(marker: BriefMapProfileResp, latLngVal: LatLngExpression): DetailMarker {
    // Marker Div element
    if (this.leaflet.isReady()) {
      const detailMarkerDiv = new this.leaflet.L.Marker(latLngVal)
        .setIcon(this.leaflet.L.divIcon({
            iconSize: [50, 50],
            iconAnchor: [25, 25],
            className: 'marker-style'
          }
        ))
      // Marker's component reference
      const compRef = this.constructMarkerComponent()

      // Defining input data & emitters' callbacks
      compRef.instance.data = marker
      compRef.instance.bubbleEnabled = this.bubbleEnabled
      compRef.instance.markerColor = this.getColorByMarkerType(marker.profile.profileType)
      compRef.instance.changeRef.detectChanges()

      // Initialize child component's input fields
      return {
        componentRef: compRef,
        marker: detailMarkerDiv
      }
    }
  }

  /**
   * Removes all circle markers from the map.
   */
  protected removeAllCircleMarkers(): void {
    this.circleMarkers.forEach(circleMarker => {
      circleMarker.removeFrom(this.map)
    })
    // clear the array
    this.circleMarkers = []
  }

  /**
   * Removes all detail markers from the map.
   */
  protected removeAllDetailMarkers(): void {
    this.detailMarkers.forEach((detailMarker) => {
      this.removeDetailMarker(detailMarker)
    })
    // clear the array
    this.detailMarkers = []
  }

  /**
   * Removes the detail marker from the map.
   */
  protected removeDetailMarker(detailMarker: DetailMarker): void {
    const componentRef: ComponentRef<any> = detailMarker.componentRef
    const marker: Marker = detailMarker.marker

    marker.removeFrom(this.map)
    componentRef.destroy()
    this.rendererRemoveChild(marker, componentRef)
  }

  /**
   * Creates and shows the circle marker bubble Angular component on a map.
   *
   * @param circleMarker The original circle marker, where the bubble should be visible.
   * @param profile The marker data from the server.
   */
  protected openCircleMarkerBubble(circleMarker: CircleMarker, profile: BriefMapProfileResp): void {
    // Marker's component reference
    const compRef = this.constructMarkerComponent()

    // Defining input data & emitters' callbacks
    compRef.instance.data = profile
    compRef.instance.bubbleEnabled = this.bubbleEnabled
    compRef.instance.bubbleOnly = true
    compRef.instance.changeRef.detectChanges()

    circleMarker.on('popupclose', () => {
      compRef.destroy()
    })

    circleMarker.on('popupopen', () => {
      this.map.panTo(circleMarker.getLatLng())
    })

    circleMarker.bindPopup(compRef.location.nativeElement).openPopup()
  }

  /**
   * Closes the current opened detail marker bubble.
   * This action is performed by changing the marker's 'bubbleVisible' property in a componentRef instance.
   * This means that every detail marker component must have this property to control it's bubbles.
   */
  protected closeDetailMarkerBubble(): void {
    if (this.currentOpenDetailMarker) {
      this.currentOpenDetailMarker.componentRef
        .instance.bubbleVisible = false
    }
  }

  /**
   * Loads markers provided from the server.
   * Determines whether they should be displayed as circle or detail markers.
   *
   * @param profiles Markers from the server.
   */
  protected loadMarkers(profiles: BriefMapProfileResp[]): void {
    const zoom = this.map.getZoom()
    this.removeAllCircleMarkers()
    this.removeAllDetailMarkers()

    profiles.forEach((marker) => {
      if (zoom <= 14) {
        this.addCircleMarker(marker)
      } else if (zoom >= 15) {
        this.addDetailMarker(marker)
      }
    })
  }

  /**
   * Detects the radius that is needed for all {@link circleMarkers} by the current zoom level of the {@link map}.
   */
  protected checkCircleMarkerRadius(): void {
    const zoom = this.map.getZoom()
    if (zoom <= 13) {
      this.setCircleMarkerRadius(7)
    } else if (zoom <= 15) {
      this.setCircleMarkerRadius(8)
    }
  }

  /**
   * Changes the radius of all {@link circleMarkers}.
   *
   * @param radius The exact integer number radius of a circle marker.
   */
  protected setCircleMarkerRadius(radius: number): void {
    if (this.circleMarkers[0]?.getRadius() !== radius) {
      this.currentCircleMarkerRadius = radius
      this.circleMarkers.forEach((marker: CircleMarker) => {
        marker.setRadius(radius)
      })
    }
  }

  /**
   * Manages visibility of the 'Explore Area' button.
   * (the {@link floatingSearchButtonVisible} property)
   */
  protected refreshSearchAreaButton(): void {
    // return true if no previous searching was made
    if (!this.lastSearchedMapBounds) {
      this.floatingSearchButtonVisible = true
      return
    }

    const currentBounds = this.map.getBounds()
    const boundsDifference = this.calculateBoundsDifference(currentBounds)
    if (boundsDifference.xDiff >= 0.02 || boundsDifference.yDiff >= 0.02) {
      this.floatingSearchButtonVisible = true
    }
  }

  /**
   * Sets map bounds to the bounding box.
   */
  protected fitMapBounds(boundingBox: number[]): void {
    if (this.leaflet.isReady()) {
      this.map.fitBounds(this.leaflet.L.latLngBounds([
        {
          lat: boundingBox[0],
          lng: boundingBox[2]
        },
        {
          lat: boundingBox[1],
          lng: boundingBox[3]
        }
      ]))
    }
  }

  /**
   * Generates a random position within given bounds.
   */
  private generateRandomPosition(bounds: LatLngBounds): LatLngExpression {
    const northEast = bounds.getNorthEast()
    const southWest = bounds.getSouthWest()
    return {
      lat: this.getRandomInRange(southWest.lat, northEast.lat, 6),
      lng: this.getRandomInRange(southWest.lng, northEast.lng, 6)
    }
  }

  private getRandomInRange(from, to, fixed): number {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1
  }

  protected calculateBoundsDelta(): void {
    const bounds = this.map.getBounds()
    const deltaLat = bounds.getNorthEast().lat - bounds.getSouthWest().lat
    const deltaLng = bounds.getNorthEast().lng - bounds.getSouthWest().lng

    console.log('Delta Lat:' + deltaLat)
    console.log('Delta Lng:' + deltaLng)
  }

  /**
   * Converts the {@link BriefMapProfileResp} to the {@link BriefProfileResp} with default values.
   * Use this function only when a new marker is going to be added to the map or the singleMarker is visible.
   */
  protected getBriefMapProfileResp(p: BriefProfileResp): BriefMapProfileResp {
    return {
      profile: p,
      hasEvents: false,
      isLive: false,
      isNowOpen: true
    }
  }
}

/**
 * Contains the Leaflet's marker itself and the Component Reference of the custom marker.
 */
export interface DetailMarker {
  componentRef?: ComponentRef<any>
  marker?: Marker
}

/**
 * Defines a structure of a circle marker with a radius used in {@link MapComponent}.
 */
export interface RadiusMarker {
  radius: number
  latLng?: LatLngExpression
}
