import {Component, Input, OnInit} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {CookieOption, CookiesSettings, CookiesSettingsService} from 'src/app/service/cookies-settings.service'

@Component({
  selector: 'app-dialog-cookies-details',
  templateUrl: './dialog-cookies-details.component.html',
  styleUrls: ['./dialog-cookies-details.component.scss']
})
export class DialogCookiesDetailsComponent extends EditableComponent implements OnInit {

  /**
   * Version of cookies passed from {@link DialogCookiesComponent}.
   */
  @Input()
  cookiesVersion: number

  functional: CookiesItem[]
  analytics: CookiesItem[]
  marketing: CookiesItem[]

  allAnalyticsSwitch = true
  allMarketingSwitch = true

  /**
   * Cookie settings from a local storage.
   */
  storageSettings: CookiesSettings

  constructor(private cookiesSettingsService: CookiesSettingsService) {
    super()
  }

  ngOnInit(): void {
    this.initItems()
    this.loadSettings()
    this.updateGroupSwitch()
  }

  /**
   * Enables or disabled the whole group.
   */
  enableGroup(enable: boolean, items: CookiesItem[]): void {
    for (const item of items) {
      item.state = enable
    }
  }

  /**
   * Updates the group switch value.
   */
  updateGroupSwitch(): void {
    this.allAnalyticsSwitch = this.analytics.some(it => it.state)
    this.allMarketingSwitch = this.marketing.some(it => it.state)
  }

  /**
   * Saves cookie settings to the local storage.
   */
  private saveSettings(): void {
    this.storageSettings = {version: this.cookiesVersion, items: []}
    this.saveArray(this.analytics)
    this.saveArray(this.marketing)
    this.cookiesSettingsService.saveSettings(this.storageSettings)
  }

  /**
   * Load cookie settings from the local storage and parse them.
   */
  private loadSettings(): void {
    this.storageSettings = this.cookiesSettingsService.getSettings()
    this.applyToArray(this.analytics)
    this.applyToArray(this.marketing)
  }

  /**
   * Applies the {@link storageSettings} to {@link items}.
   */
  applyToArray(items: CookiesItem[]): void {
    for (const item of items) {
      item.state = !!this.storageSettings.items.find(it => it.name === item.field)?.state
    }
  }

  /**
   * Converts the {@link items} to the {@link storageSettings}.
   */
  saveArray(items: CookiesItem[]): void {
    items.forEach(it => {
      this.storageSettings.items.push({name: it.field, state: it.state})
    })
  }

  /**
   * Closes the dialog and saves the cookie settings.
   */
  acceptAll(): void {
    this.cookiesSettingsService.acceptAll(this.storageSettings.version)
  }

  /**
   * Accepts only selected cookies.
   */
  onAcceptSelected(): void {
    this.saveSettings()
  }

  /**
   * Initializes cookie items.
   */
  private initItems(): void {
    this.functional = [
      {
        field: 'mandatory',
        name: $localize`Mandatory`,

        description: $localize`These cookies are necessary for running this website.`,
        icon: 'fa-solid fa-gear',
        state: true,
        disabled: true
      },
      {
        field: 'security',
        name: $localize`Security`,
        description: $localize`Cookies to keep your sensitive data protected.`,
        icon: 'fa-solid fa-shield-halved',
        state: true,
        disabled: true
      },
      {
        field: 'stripe',
        name: `Stripe`,
        description: $localize`For secure online payment processing.`,
        icon: 'fa-brands fa-stripe-s',
        state: true,
        disabled: true
      },
      {
        field: 'language',
        name: $localize`Language`,
        description: $localize`Keeps information about your language settings.`,
        icon: 'fa-solid fa-language',
        state: true,
        disabled: true
      },
      {
        field: 'userExperience',
        name: $localize`User Experience`,
        description: $localize`To enhance the experience by using our service.`,
        icon: 'fa-solid fa-computer-mouse',
        state: true,
        disabled: true
      },
      {
        field: CookieOption.COOKIE_UMEVIA_FUNCTIONAL,
        name: $localize`Umevia Functional`,
        description: $localize`For accessing certain technical features provided by ourself to work properly.`,
        icon: 'fa-solid fa-comments',
        state: true,
        disabled: true
      },
      {
        field: CookieOption.COOKIE_ACCOUNTS_GOOGLE,
        name: $localize`Accounts Google`,
        description: $localize`For interaction and storing communication tokens with your google accounts.`,
        icon: 'fa-solid fa-user',
        state: true,
        disabled: true
      },
      {
        field: CookieOption.COOKIE_FACEBOOK_FUNCTIONALITY,
        name: $localize`Facebook Functionality`,
        description: $localize`Allows us to give you abilities such as sharing your personal content on Facebook.`,
        icon: 'fa-brands fa-facebook',
        state: true,
        disabled: true
      }]

    this.analytics = [
      {
        field: CookieOption.COOKIE_UMEVIA_ANALYTICS,
        name: $localize`Umevia Analytics`,
        description: $localize`We use these cookies to track count visits and traffic sources to deliver the best performance result.`,
        icon: 'fa-solid fa-chart-column',
        state: false
      },
      {
        field: CookieOption.COOKIE_GOOGLE_ANALYTICS,
        name: $localize`Google Analytics`,
        description: $localize`Gives us more opportunities to build this platform better based on your interaction.`,
        icon: 'fa-brands fa-google',
        state: false
      },
      {
        field: CookieOption.COOKIE_CLARITY_ANALYTICS,
        name: $localize`Clarity`,
        description: $localize`Enables us with tracking your movement across application.`,
        icon: 'fa-solid fa-play rotate-90-left',
        state: false
      },
      {
        field: CookieOption.COOKIE_SENTRY_ANALYTICS,
        name: $localize`Sentry`,
        description: $localize`Website performance measurement through error tracking and analysis.`,
        icon: 'fa-solid fa-chart-simple',
        state: false
      }
    ]

    this.marketing = [
      {
        field: CookieOption.COOKIE_TARGET_GOOGLE,
        name: $localize`Google Ads`,
        description: $localize`To personalize and measure ad performance for tailored experiences.`,
        icon: 'fa-brands fa-google',
        state: false
      },
      {
        field: CookieOption.COOKIE_TARGET_META,
        name: $localize`Meta Pixel`,
        description: $localize`To enhance ad targeting and measure conversions for us.`,
        icon: 'fa-brands fa-meta',
        state: false
      }
    ]
  }
}

/**
 * A cookie data structure visible on the screen.
 */
export interface CookiesItem {
  field: CookieOption | string // string for mandatory cookies
  name: string
  description: string
  icon: string
  state?: boolean
  version?: number
  disabled?: boolean
}


