import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ProfileResp} from '../../../../service/profile.service'
import {hasFeatures} from '../../../../common/profile-type'
import {Feature} from '../../../../common/feature'
import {ChannelResp, ChatChannelService} from '../../../../service/chat-channel.service'
import {EditableComponent} from '../../../abstract/editable.component'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {NavigationService} from '../../../../service/ui/navigation.service'
import {firstValueFrom} from 'rxjs'
import {ButtonComponent} from '../../../common/button/button.component'
import {NgIf, NgTemplateOutlet} from '@angular/common'
import {ProfileOrderChatComponent} from '../../../../modules/order-manager/profile-manager/profile-order-chat/profile-order-chat.component'

@Component({
  standalone: true,
  selector: 'app-profile-chat-button',
  templateUrl: './profile-chat-button.component.html',
  imports: [
    ButtonComponent,
    DialogComponent,
    NgIf,
    ProfileOrderChatComponent,
    NgTemplateOutlet
  ],
  styleUrl: './profile-chat-button.component.scss'
})
export class ProfileChatButtonComponent extends EditableComponent implements OnInit {

  /**
   * The currently previewing profile.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * The currently logged profile.
   */
  @Input({required: true})
  currentProfile: ProfileResp
  /**
   * Emits, when the user has to be scrolled into the offer component.
   */
  @Output()
  scrollToOffer = new EventEmitter<void>()
  /**
   * Determines whether the {@link currentProfile} can order profiles.
   */
  canOrder: boolean
  /**
   * Determines whether the {@link profile} can be ordered.
   */
  canBeOrdered: boolean
  /**
   * A current chat channel between {@link profile} and {@link currentProfile}.
   */
  chatChannel: ChannelResp
  /**
   * Opens the chat dialog component.
   */
  chatVisible: boolean
  /**
   * Opens an info dialog about cannot chat right now.
   */
  cannotChatDialogVisible: boolean
  /**
   * Contains the content of not-logged-user in the cannot-chat-dialog.
   */
  notLoggedContent: CannotChatDialogContent = {
    icon: 'fa-solid fa-users',
    title: $localize`To start chat, please log in.`,
    button: {
      icon: 'fa-solid fa-arrow-right',
      label: $localize`Login`,
      fun: this.onLogin.bind(this)
    }
  }
  /**
   * Contains the content of not-order in the cannot-chat-dialog.
   */
  missingOrderContent: CannotChatDialogContent = {
    icon: 'fa-solid fa-calendar-check',
    title: $localize`To start chat, you have to order an artist first.`,
    button: {
      icon: 'fa-solid fa-shopping-cart',
      label: $localize`Order`,
      fun: this.onShopping.bind(this)
    }
  }

  constructor(
    private navigation: NavigationService,
    private chatChannelService: ChatChannelService) {
    super()
  }

  ngOnInit(): void {
    if (this.currentProfile) { // logged profile
      this.canOrder = hasFeatures(this.currentProfile.profileType, Feature.ORDER_PROFILE)
    } else {
      this.canOrder = true //guest
    }

    this.canBeOrdered = hasFeatures(this.profile.profileType, Feature.BE_ORDERED)
    if (this.currentProfile && this.canOrder && this.canBeOrdered) {
      this.loadChannel()
    }
  }

  /**
   * Fires when a user clicks on the chat button.
   */
  onChat(): void {
    if (this.chatChannel) { // open chat
      this.chatVisible = true
    } else { // cannot chat dialog
      this.cannotChatDialogVisible = true
    }
  }

  /**
   * - Fires when a user clicks on the login button in the cannot-order-dialog.
   * - Basically, it redirects a user to the login page while the destination is remembered.
   */
  onLogin(dialog: DialogComponent): void {
    dialog.closeAndNavigate(() => {
      this.navigation.toLogIn(true)
    })
  }

  /**
   * - Fires when a user clicks on the order button in the cannot-order-dialog.
   * - Basically, it scrolls a user to the offer component.
   */
  onShopping(dialog: DialogComponent): void {
    dialog.closeAndNavigate(() => {
      this.scrollToOffer.emit()
    })
  }

  /**
   * Tries to load a channel between {@link currentProfile} and {@link profile}.
   */
  private loadChannel(): void {
    this.call(async () => {
      this.chatChannel = await firstValueFrom(this.unwrap(this.chatChannelService.callGetOrCreateChannel({
        isDirect: true,
        participants: [this.currentProfile.profileId, this.profile.profileId]
      })))
    })
  }
}

/**
 * Defines data structure for the cannot-chat dialog.
 */
interface CannotChatDialogContent {
  icon: string
  title: string
  button: {
    icon: string
    label: string
    fun: (dialog: DialogComponent) => void
  }
}
