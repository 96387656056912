import {Pipe, PipeTransform} from '@angular/core'

/**
 * Creates an array with one string value that is suited for the router.
 */
@Pipe({
  name: 'routerLink',
  standalone: true
})
export class RouterLinkPipe implements PipeTransform {
  transform(urls: string[]): string[] {
    let path = ''
    for (const url of urls) {
      path += `/${url}`
    }
    return [path]
  }
}
