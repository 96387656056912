<!--Desktop devices layout-->
<div class="p-d-flex desktop-wrapper w-100 p-p-0"
     *ngIf="isScreenOf(Screen.XXL)"
     [class.desktop-wrapper-height]="(navbarService.visible | async) === false">

  <!--Filter on desktop devices-->
  <div class="p-col-fixed p-p-0 catalog-filter"
       [class.desktop-filter-height]="navbarService.visible | async">

    <app-profile-catalog-filter #desktopFilter
                                [alwaysVisible]="true"
                                (request)="callInitialLoad($event)"></app-profile-catalog-filter>
  </div>

  <!--Content-->
  <div class="p-col w-100 p-p-0 desktop-content">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>
</div>

<!--Small devices Layout-->
<div *ngIf="!isScreenOf(Screen.XXL)">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</div>

<!--Content template-->
<ng-template #content>
  <div class="p-mb-2 p-sticky profession-categories w-100 box-shadow"
       [class.categories-top]="(navbarService.visible | async) === false">
    <app-price-item-categories #priceItemCategories
                               [descriptionTitle]="title"
                               [descriptionText]="description"
                               (categoryChange)="onSelectedCategory($event)"
                               (filterClick)="mobileFilter?.showSidebar()"></app-price-item-categories>
  </div>

  <div class="p-relative">
    <!--Skeleton Loading-->
    <div class="p-absolute w-100 h-100" *ngIf="loading" @fade>
      <ng-template [ngTemplateOutlet]="skeleton"></ng-template>
    </div>

    <!--Content-->
    <p-dataView [value]="profiles?.content"
                layout="grid"
                *ngIf="!loading"
                [trackBy]="trackBy.bind(this)">
      <ng-template pTemplate="grid" let-profiles>
        <div class="p-grid p-p-0">
          <div [class]="isScreenOf(Screen.XXL)? 'p-col item' : 'p-col-12 p-sm-6 p-md-4 p-xl-3'"
               *ngFor="let profile of profiles">
            <div class="rounded-large box-shadow">
              <app-profile-catalog-item [data]="profile"
                                        (clicked)="onProfileClick()"></app-profile-catalog-item>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="empty">
          <h1 class="empty" *ngIf="!initialLoadFinished">. . .</h1>
          <h1 class="empty" *ngIf="initialLoadFinished" i18n>No profiles found</h1>
      </ng-template>
    </p-dataView>

    <div class="p-p-2 w-100 p-d-flex p-jc-center" *ngIf="profiles.nextPageLoading" @fade>
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-circle-notch fa-spin p-mr-2"></i>
        <div i18n>Loading</div>
      </div>
    </div>
  </div>
</ng-template>

<!--Hidden filter on small devices-->
<app-profile-catalog-filter #mobileFilter *ngIf="!isScreenOf(Screen.XXL)"
                            (request)="callInitialLoad($event)"></app-profile-catalog-filter>

<!--Skeleton-->
<ng-template #skeleton>
  <div *ngFor="let j of [].constructor(4)" class="p-grid">
    <div *ngFor="let i of [].constructor(numberOfSkeletons)" class="p-p-3"
         [class]="isScreenOf(Screen.XXL)? 'p-col item' : 'p-col-12 p-sm-6 p-md-4 p-xl-3'">
      <p-skeleton width="100%" height="23rem"></p-skeleton>
    </div>
  </div>
</ng-template>

<!-- SEO descriptions -->
<div *ngIf="title" class="p-d-none">
  <h2>{{ title }}</h2>
  <p>{{ description }}</p>
</div>

<div *ngIf="title" class="p-d-none">
  <h2 i18n>Steps to book an {{ title }}</h2>
  <p i18n>
    Booking an artist through the Umevia platform is a simple and efficient process designed to ensure a seamless
    experience for both the organiser and the artist. To get started, create an Umevia account if you don't already have
    one, or login to your existing account.
  </p>
  <p i18n>
    Once logged in, use Umevia's search and filter functions to find artists that meet the specific needs of your event.
    You can filter by genre, location, budget and availability. Detailed artist profiles are available, showing samples
    of their work, past performances, reviews and ratings from previous clients. These profiles often include high
    quality photos and videos, giving you a comprehensive view of the artist's style and skills.
  </p>
  <p i18n>
    Once you have narrowed down your choices, check the artist's availability on their calendar. Select an artist that
    meets your event requirements and click on their profile to view more details. Submit a booking request by filling
    out a form with essential details such as date, time, location and type of event.
  </p>
  <p i18n>
    The artist will receive your booking request and review the details. They can accept, decline or suggest changes to
    the price based on your requirements. Communication is facilitated through the platform, ensuring that all
    interactions are documented and clear. Once both parties agree to the terms, you will proceed to the payment stage
    to securely pay for the order.
  </p>
  <p i18n>
    Once payment has been confirmed, you and the artist will receive a booking confirmation, which will include a
    detailed contract outlining the terms of the agreement. This contract may include performance details, payment
    terms, cancellation policy and any other relevant details.
  </p>
  <p i18n>
    Using an Umevia platform not only simplifies the process of finding and securing artists, but also provides a secure
    and professional framework that benefits both organisers and artists.
  </p>
</div>
