import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core'
import {PLATFORM_BROWSER} from '../../../app.module'
import {ProfileResp} from '../../../service/profile.service'
import {growAnimation} from '../../../animation/grow.animation'
import {AbstractComponent} from '../../abstract/abstract.component'

@Component({
  animations: [growAnimation()],
  selector: 'app-profile-bottom-bar',
  templateUrl: './profile-bottom-bar.component.html',
  styleUrl: './profile-bottom-bar.component.scss'
})
export class ProfileBottomBarComponent extends AbstractComponent implements OnChanges, OnDestroy {
  /**
   * Defines a class identifier in the 'offer component'. It is used to properly scroll the user on that component on CTA.
   */
  static readonly OFFER_COMPONENT_CLASS = 'profile-offer-section'
  /**
   * The current previewing profile data.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * Emits when the user has to be scrolled into the profile offer component.
   */
  @Output()
  scrollToOffer = new EventEmitter<any>()
  /**
   * Determines the current visibility of the bottom bar.
   */
  visible: boolean = undefined
  /**
   * Defines a top border of the {@link OFFER_COMPONENT_CLASS}. It is used for the auto-hide feature.
   */
  private topBorder: number = null
  /**
   * Defines a bottom border of the {@link OFFER_COMPONENT_CLASS}. It is used for the auto-hide feature.
   */
  private bottomBorder: number = null
  /**
   * Defines whether the listener has been started.
   */
  private listenerEnabled: boolean
  /**
   * Contains information about the interval of recalculating the {@link topBorder} and {@link bottomBorder}.
   */
  private recalcInterval: any

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile?.currentValue && PLATFORM_BROWSER) {
      clearInterval(this.recalcInterval)
      this.recalcInterval = setInterval(() => {
        this.initProperties()
        this.scrollListener()
      }, 1000)
    }
  }

  /**
   * Initializes properties of this component.
   */
  private initProperties(): void {
    if (PLATFORM_BROWSER) {
      const el = document.getElementsByClassName(ProfileBottomBarComponent.OFFER_COMPONENT_CLASS)[0]
      const rect = el?.getBoundingClientRect()
      const offsetPx = 50
      this.topBorder = rect.top + window.scrollY - offsetPx
      this.bottomBorder = rect.bottom + window.scrollY + offsetPx
      if (this.topBorder !== null && this.bottomBorder !== null && !this.listenerEnabled) {
        this.listenScrollEvent(true)
        this.listenerEnabled = true
      }
    }
  }

  /**
   * The scroll listener enabler.
   */
  private listenScrollEvent(enable: boolean): void {
    if (PLATFORM_BROWSER) {
      if (enable) {
        this.listenScrollEvent(false) // firstly, remove old listeners
        document.addEventListener('scroll', this.scrollListener)
      } else {
        document.removeEventListener('scroll', this.scrollListener)
      }
    }
  }

  /**
   * The scroll listener function block.
   */
  private scrollListener = (): void => {
    if (PLATFORM_BROWSER) {
      const y = window.scrollY + window.innerHeight
      this.visible = !(y >= this.topBorder)
    }
  }

  ngOnDestroy(): void {
    this.listenScrollEvent(false)
    clearInterval(this.recalcInterval)
  }
}
