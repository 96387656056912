import {Inject, Injectable} from '@angular/core'
import {GoogleAnalyticsService as NgxGoogleAnalytics, IGoogleAnalyticsCommand} from 'ngx-google-analytics'
import {environment} from '../../../environments/environment'
import {DOCUMENT} from '@angular/common'
import {StorageService} from '../storage.service'

/**
 * Manages the communication with the Google Analytics API.
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  /**
   * The initial commands that are sent at the page load.
   */
  static readonly INITIAL_COMMANDS: IGoogleAnalyticsCommand[] = [
    {
      command: 'consent',
      values: ['default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied'
      }]
    },
    {
      command: 'config',
      values: [environment.googleAnalytics.trackingCode,
        {send_page_view: false}
      ]
    }
  ]

  /**
   * Contains the current state of the Google Analytics feature.
   */
  private analyticsEnabled: boolean

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngxGaService: NgxGoogleAnalytics,
    private storageService: StorageService) {
  }

  /**
   * Enable or disables the Google Analytics service.
   */
  enableGoogleAnalytics(enable: boolean): void {
    // Prevents multiple same updates
    if (enable === this.analyticsEnabled) {
      return
    }

    this.analyticsEnabled = enable

    // update cookie consent
    this.ngxGaService.gtag('consent', 'update', {
      ad_storage: (enable) ? 'granted' : 'denied',
      analytics_storage: (enable) ? 'granted' : 'denied',
      functionality_storage: (enable) ? 'granted' : 'denied',
      personalization_storage: (enable) ? 'granted' : 'denied',
      security_storage: (enable) ? 'granted' : 'denied'
    })

    // update the config
    this.ngxGaService.gtag('config',
      environment.googleAnalytics.trackingCode,
      {send_page_view: enable}
    )

    // Delete GA's cookies
    if (!this.analyticsEnabled) {
      this.storageService.deleteCookie('_ga_MNH8X2BVJ0')
      this.storageService.deleteCookie('_ga_LJK2C4LZCZ')
      // Remove all Google Analytics data and events
      this.document.defaultView['dataLayer'] = []
    }
  }

  /**
   * Sends an event to the Google Analytics.
   */
  event(event: GoogleAnalyticsEvent, opts?: any): void {
    if (this.analyticsEnabled) {
      this.ngxGaService.event(event, null, null, null, null, opts)
    }
  }
}

/**
 * Enum for names of Google Analytics events.
 */
export enum GoogleAnalyticsEvent {
  PROFILE_ORDER_SENT = 'profile_order_sent',
  PROFILE_ORDER_PAID = 'profile_order_paid',
  PROFILE_REGISTER = 'profile_register',
}
