import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core'
import {AuthComponent} from './auth.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {firstValueFrom, Observable} from 'rxjs'
import {UserResponseType} from '../../../common/user-response-type'
import {TwoFactorResp, UserResp} from '../../../service/user.service'

/**
 * Abstract class for login components that contains common methods and properties.
 * Most of the logic is implemented here, and only a minor methods are abstract and need to be implemented in child components.
 * The purpose is to avoid code duplication and let the component handle only view without logic.
 */
@Component({template: ''})
export abstract class LoginAbstractComponent extends AuthComponent implements OnInit {

  form: FormGroup
  socialButtonsDisabled = false

  protected formBuilder = inject(FormBuilder)
  protected changeRef = inject(ChangeDetectorRef)

  ngOnInit(): void {
    this.initForm()
    this.changeRef.detectChanges()
  }

  /**
   * Fires when a user submits the {@link form}.
   * If response is of type {@link UserResponseType.USER}, redirect to content.
   * If response is of type {@link UserResponseType.TWO_FACTOR} redirect to {@link TwoFactorAuthenticationComponent}.
   */
  login(): void {
    this.callAndFinish(async () => {
      this.disableForm(true)
      const formData = this.form.value
      const resp = await firstValueFrom(this.callLogIn(formData))

      if (!resp?.type || !this.noServerMessages()) {
        this.disableForm(false)
        return
      }

      switch (resp.type) {
        case UserResponseType.USER:
          const user = resp as UserResp
          await this.finishLogin(user)
          this.successLoginAction(user)
          break
        case UserResponseType.TWO_FACTOR:
          this.userService.twoFactorAuthentication.next(resp as TwoFactorResp)
          this.twoFactorAction()
      }
    }, () => {
      this.disableForm(false)
    })
  }

  /**
   * Calls the server API to log in the user.
   */
  private callLogIn(formData): Observable<UserResp | TwoFactorResp> {
    return this.unwrap(this.userService.callLogIn({
      email: formData.email,
      password: formData.password,
      stayLogged: formData.rememberMe
    }))
  }

  /**
   * Creates and inits the input form with validators.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      email: [''],
      password: [''],
      rememberMe: [false]
    })
  }

  /**
   * Sets the {@link disable} property a new value.
   * It also controls the disability of the {@link form}.
   */
  disableForm(disable: boolean): void {
    this.socialButtonsDisabled = disable
    if (disable) {
      this.form.disable()
    } else {
      this.form.enable()
    }
  }

  /**
   * Let the child component to handle the success login action.
   */
  abstract successLoginAction(user: UserResp): void

  /**
   * Let the child component to handle the two-factor action.
   */
  abstract twoFactorAction(): void
}
