import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core'
import {ApiComponent} from '../../abstract/api.component'
import {environment} from '../../../../environments/environment'
import {growAnimation} from '../../../animation/grow.animation'
import {Subscription} from 'rxjs'
import {scrollToIndexOptions} from '../../../utils/scroll.utils'
import {NgIf} from '@angular/common'

/**
 * Displays the response from the API call.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-call-response',
  templateUrl: './call-response.component.html',
  styleUrls: ['./call-response.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class CallResponseComponent implements OnChanges, OnDestroy {

  /**
   * Defines the text of a successful call.
   */
  @Input()
  successMsg: string
  /**
   * The icon appeared near the success message.
   */
  @Input()
  successIcon = 'fa-solid fa-check'
  /**
   * Defines the text of an unsuccessful / error call.
   */
  @Input()
  errorMsg = $localize`An unexpected error occurred.`
  /**
   * The icon apeared near the error message.
   */
  @Input()
  errorIcon = 'fa-solid fa-heart-crack'
  /**
   * The source of API properties. Needs to be initialized.
   */
  @Input()
  component: ApiComponent
  /**
   * Scrolls to the response if success
   */
  @Input()
  scrollOnResponse = true
  /**
   * Styles of this component.
   */
  @Input()
  styleClass: string

  /**
   * The scroll options of the scrolling.
   */
  private readonly scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'end'
  }

  /**
   * The subscribtion of the {@link component}'s api value changes.
   */
  private apiChangesSub?: Subscription

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.component && !environment.production) {
      console.error($localize`The CallResponseComponent doesn\'t have the [component] input specified.`)
    }

    if ((changes.component?.currentValue || changes.scrollOnResponse)) {
      this.apiChangesSub?.unsubscribe()
      if (this.component && this.scrollOnResponse) {
        this.apiChangesSub = this.component.apiValueChanges.subscribe(this.scrollToResult.bind(this))
      }
    }
  }

  ngOnDestroy(): void {
    this.apiChangesSub?.unsubscribe()
  }

  /**
   * Scrolls to the result.
   */
  private scrollToResult(): void {
    if ((this.component.responseError || this.component.responseFailed || this.component.disconnected)
      || (this.component.responseSuccess && this.successMsg)) {
      scrollToIndexOptions('call-selector', 0, this.scrollOptions, 250)
    }
  }
}
