import {Injectable} from '@angular/core'
import {Endpoint} from '../common/endpoints'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {ApiService} from './api.service'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class ProfessionService extends ApiService {

  constructor() {
    super()
  }

  callSearchProfession(req: SearchProfessionReq): Observable<BaseResp<Page<BriefProfessionResp>>> {
    return this.post(Endpoint.PROFESSION_SEARCH_URL, req, super.getHeaders(), false)
  }

  callGetProfession(id: number): Observable<BaseResp<BriefProfessionResp>> {
    return this.post(Endpoint.PROFESSION_GET_URL, id, super.getHeaders(), false)
  }

  callFindProfessionBySlug(url: string): Observable<BaseResp<BriefProfessionResp>> {
    return this.post(Endpoint.PROFESSION_FIND_BY_SLUG, url, super.getHeaders(), false)
  }

  callUpdateProfileProfessions(req: UpdateProfileProfessionsReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_PROFESSIONS_URL, req)
  }

  callAllProfessions(req: AllProfessionsReq): Observable<BaseResp<Page<BriefProfessionResp>>> {
    return this.post(Endpoint.PROFESSION_ALL_URL, req, super.getHeaders(), false)
  }

  callGetMostUsed(): Observable<BaseResp<BriefProfessionResp[]>> {
    return this.post(Endpoint.PROFESSION_MOST_USED, null, super.getHeaders(), false)
  }
}

export interface SearchProfessionReq {
  name: string
  page?: number
}

export interface UpdateProfileProfessionsReq {
  ids: number[]
}

export interface AllProfessionsReq {
  page?: number
}

export interface BriefProfessionResp {
  id: number
  name: string
  count: number
  /**
   * UI-only property.
   */
  selected?: boolean
}
