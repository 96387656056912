<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            header="Chat Inbox"
            i18n-header
            url="chat-inbox"
            discardLabel="Close"
            i18n-discardLabel
            [hold]="chatVisible"
            [allowSaveOption]="false"
            (discard)="closeEditComponent()">
  <div class="p-py-2">
    <app-lazy-list [(items)]="channels"
                   [itemHeight]="89"
                   [maxScrollHeight]="'35rem' | pixels"
                   [virtual]="false"
                   [component]="this"
                   [loadFunction]="loadAllOfActiveOrders.bind(this, channels?.nextPage)">
      <!-- Item -->
      <ng-template let-item pTemplate="item">
        <div class="channel-item rounded-small p-mb-2 p-p-3 p-d-flex p-ai-center p-jc-between c-pointer" pRipple
             (click)="selectChannel(item)"
             *ngVar="item | firstProfile: this.currentProfile as profile">
          <!--Profile info-->
          <div class="p-d-flex p-ai-center">
            <app-avatar [profile]="profile"
                        sizePx="50"
                        [redirect]="false"
                        class="p-mr-3"></app-avatar>

            <div>
              <app-profile-names [profile]="profile"
                                 [size]="4"
                                 [charIdVisible]="false"
                                 [showPointer]="true"
                                 [displayNameLinesCount]="1"
                                 [redirect]="false"></app-profile-names>
              <app-profile-professions type="text"
                                       [data]="profile"
                                       lineCount="l-count-1"></app-profile-professions>
            </div>
          </div>

          <!--Badge-->
          <b class="badge rounded-small p-p-1 p-mx-2" *ngIf="item.unreadMessages > 0">
            {{item.unreadMessages}}
          </b>
        </div>
      </ng-template>

      <!-- Loading Skeleton -->
      <ng-template pTemplate="skeleton">
        <div *ngFor="let i of [].constructor(3)">
          <div class="p-d-flex p-ai-center p-mb-3">
            <p-skeleton shape="circle" size="40px" styleClass="p-mr-2"></p-skeleton>
            <div>
              <p-skeleton width="15rem" height="1rem" styleClass="rounded-small"></p-skeleton>
              <p-skeleton width="40rem" height="2rem" styleClass="rounded-small p-mt-2"></p-skeleton>
            </div>
          </div>
          <div class="p-d-flex p-ai-center p-mb-3">
            <p-skeleton shape="circle" size="40px" styleClass="p-mr-2"></p-skeleton>
            <div>
              <p-skeleton width="15rem" height="1rem" styleClass="rounded-small"></p-skeleton>
              <p-skeleton width="30rem" height="2rem" styleClass="rounded-small p-mt-2"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-template>
    </app-lazy-list>
  </div>
</app-dialog>

<!--Order Chat Dialog-->
<app-profile-order-chat *ngIf="chatVisible"
                        [(show)]="chatVisible"
                        (showChange)="selectedChannel = undefined"
                        [channel]="selectedChannel"
                        [currentProfile]="currentProfile"></app-profile-order-chat>
