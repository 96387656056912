<div class="wrapper p-d-flex p-jc-center" *ngIf="!phoneVerificationInProgress">
  <div @grow class="component-wrapper p-p-3 p-py-sm-5 p-d-flex p-ai-center p-jc-center rounded-medium">
    <div class="w-100">
      <!--Logo image-->
      <div *ngIf="isScreenOf(Screen.SM)" class="w-100 p-d-flex p-jc-center logo">
        <img src="assets/logo/logo-color.svg" alt="Umevia logo" i18n-alt class="logo">
      </div>

      <div class="w-100 p-px-2 p-px-sm-5">
        <h2 class="title p-py-2 p-text-uppercase" data-test="title" i18n>Register</h2>
        <form [formGroup]="form"
              (keyup.enter)="checkUniqueUserAndVerifyPhone()">

          <!--DISPLAY-NAME-->
          <app-text-input label="Display Name"
                          i18n-label
                          inputType="text"
                          placeholder="John Smith"
                          i18n-placeholder
                          formFieldName="displayName"
                          autocomplete="name"
                          data-test="displayName"
                          [form]="form"
                          [required]="true"
                          hint="Your real name that will be print on invoices."
                          i18n-hint
                          [length]="{min: Restrictions.MIN_DISPLAY_NAME_LENGTH, max: Restrictions.MAX_DISPLAY_NAME_LENGTH}">
            <!--BE-CHECK-->
            <app-backend-validation [serverMessages]="serverMessages"
                                    [check]="ServerMessage.DISPLAY_NAME_LENGTH_INCORRECT" i18n>
              The length needs to be within {{ Restrictions.MIN_DISPLAY_NAME_LENGTH }}
              - {{ Restrictions.MAX_DISPLAY_NAME_LENGTH }} characters.
            </app-backend-validation>
          </app-text-input>

          <!--USER-NAME-->
          <app-text-input *ngIf="false"
                          label="Nickname"
                          i18n-label
                          inputType="text"
                          placeholder="john.smith"
                          i18n-placeholder
                          formFieldName="charId"
                          autocomplete="nickname"
                          data-test="charId"
                          [space]="false"
                          [form]="form"
                          [required]="true"
                          [noBlankCharacters]="true"
                          hint="Your unique nick name used for your overall identification. Must not contain any blank characters."
                          i18n-hint
                          [length]="{min: Restrictions.MIN_CHAR_ID_LENGTH, max: Restrictions.MAX_CHAR_ID_LENGTH}">
            <!--FE-CHECK-->
            <app-frontend-validation [form]="form" field="charId" error="charId" i18n>
              Unsupported characters.
            </app-frontend-validation>

            <!--BE-CHECK-->
            <app-backend-validation [serverMessages]="serverMessages"
                                    [check]="ServerMessage.CHAR_ID_ALREADY_EXISTS" i18n>
              This name is already taken.
            </app-backend-validation>

            <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.CHAR_ID_INCORRECT" i18n>
              Not valid nickname.
            </app-backend-validation>
          </app-text-input>

          <!--E-MAIL-->
          <app-text-input label="Email"
                          i18n-label
                          inputType="email"
                          placeholder="john.smith@email.com"
                          i18n-placeholder
                          formFieldName="email"
                          data-test="email"
                          [space]="false"
                          [paste]="false"
                          [copy]="false"
                          [form]="form"
                          [required]="true"
                          [email]="true"
                          hint="Your login email used for main communication and verification."
                          i18n-hint
                          [length]="{min: Restrictions.MIN_EMAIL_LENGTH}">

            <!--BE-CHECK-->
            <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.EMAIL_ALREADY_REGISTERED"
                                    i18n>
              E-mail already registered.
            </app-backend-validation>

            <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_EMAIL_INCORRECT"
                                    i18n>
              Not a valid email.
            </app-backend-validation>
          </app-text-input>

          <!--PHONE-->
          <app-text-input label="Phone number"
                          i18n-label
                          placeholder="+4219xxxxxxxx"
                          i18n-placeholder
                          inputType="tel"
                          formFieldName="phone"
                          data-test="phone"
                          autocomplete="tel-area-code"
                          hint="Used for verification and resolving order disputes."
                          i18n-hint
                          [form]="form"
                          [phone]="true"
                          [required]="true"
                          [noBlankCharacters]="true"
                          [space]="false">
            <!--BE-CHECK-->
            <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_INCORRECT" i18n>
              Not a valid phone number.
            </app-backend-validation>

            <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_ALREADY_EXISTS"
                                    i18n>
              The number is already taken.
            </app-backend-validation>
          </app-text-input>

          <label class="p-text-bold p-mt-3 p-mb-0" i18n>I want to</label>
          <form class="p-d-flex p-jc-between p-ai-center p-mb-2 p-grid" [formGroup]="form">
            <div *ngFor="let category of categories" class="p-col-6 p-p-0 p-d-flex p-ai-center">
              <p-radioButton [inputId]="category.key" [value]="category"
                             formControlName="selectedCategory"></p-radioButton>
              <label [for]="category.key" class="p-ml-2 p-my-2 c-pointer">{{ category.name }}</label>
            </div>
          </form>

          <!--PASSWORD-->
          <app-text-input label="Password"
                          i18n-label
                          inputType="password"
                          formFieldName="password"
                          data-test="password"
                          [space]="false"
                          [copy]="false"
                          [form]="form"
                          [required]="true"
                          [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
            <!--BE CHECK-->
            <app-backend-validation [serverMessages]="serverMessages"
                                    [check]="ServerMessage.LOGIN_PASSWORD_INCORRECT" i18n>
              Not a valid password.
            </app-backend-validation>
          </app-text-input>

          <!--PASSWORD CONFIRMATION-->
          <app-text-input label="Password again"
                          i18n-label
                          inputType="password"
                          formFieldName="passwordAgain"
                          autocomplete="password"
                          data-test="passwordAgain"
                          [space]="false"
                          [paste]="false"
                          [copy]="false"
                          [form]="form"
                          [required]="true"
                          [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
            <!--FE-CHECK-->
            <app-frontend-validation [form]="form" field="passwordAgain" error="mustMatch" i18n>
              Passwords do not match.
            </app-frontend-validation>
          </app-text-input>

          <!--Legal Documents-->
          <div class="legal-text" *ngVar="form.value['selectedCategory'].key === 'customer' as customer" i18n>
            By clicking Register or registering through a third party you accept the Umevia
            <a [routerLink]="toLegalDocuments(customer ? 'terms' : 'terms-art')" target="_blank" class="text-link">
              Terms of Services</a> and acknowledge the
            <a [routerLink]="toLegalDocuments('privacy')" target="_blank" class="text-link">Privacy Policy</a> and <a
            [routerLink]="toLegalDocuments('cookies')" target="_blank" class="text-link">Cookie Policy</a>.
          </div>

          <!--Register Button-->
          <app-button type="primary"
                      label="Continue"
                      i18n-label
                      styleClass="w-100 p-mt-4 submit-button"
                      icon="fa-solid fa-arrow-right"
                      iconPos="right"
                      data-test="continue"
                      (clicked)="checkUniqueUserAndVerifyPhone()"
                      [loading]="saving || registered"
                      [disabled]="!form.valid || submitted"
          ></app-button>
        </form>
        <!--Response-->
        <app-call-response [component]="this"
                           successMsg="You have been successfully registered!"
                           i18n-successMsg
                           successIcon="fa-solid fa-user-plus"
                           errorMsg="Failed to register."
                           i18n-errorMsg
                           styleClass="p-mt-4"
                           errorIcon="fa-solid fa-user-slash"></app-call-response>
      </div>
    </div>
  </div>
</div>

<!--Phone verification component-->
<app-phone-verification *ngIf="phoneVerificationInProgress"
                        [email]="form.value.email"
                        [phone]="form.value.phone"
                        (phoneVerificationResult)="phoneVerificationResult($event)">
</app-phone-verification>

<!--Unsaved changes dialog-->
<app-unsaved-changes *ngIf="unsavedDialogVisible"
                     [(show)]="unsavedDialogVisible"></app-unsaved-changes>

<!-- Scrolling purposes -->
<div class="app-register-bottom"></div>
