import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ExploreMapBoundsFilterReq} from '../../../service/map.service'
import {Observable, Subscription} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {GenreRest, GenreService} from '../../../service/genre.service'
import {FormBuilder, FormGroup, FormsModule} from '@angular/forms'
import {growAnimation} from '../../../animation/grow.animation'
import {dateMustBeAfter} from '../../../validator/custom.validators'
import {dateCut, dateEditHourMinutes} from '../../../utils/date.utils'
import {formDatesNotOverlaps} from '../../../utils/form.utils'
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {DateInputComponent} from '../../common/form/date-input/date-input.component'
import {ButtonComponent} from '../../common/button/button.component'
import {SliderModule} from 'primeng/slider'
import {LazyListComponent} from '../../common/list/lazy-list/lazy-list.component'
import {CheckboxComponent} from '../../common/form/checkbox/checkbox.component'
import {SharedModule} from 'primeng/api'

@Component({
  animations: [growAnimation()],
  selector: 'app-map-filter',
  templateUrl: './map-filter.component.html',
  styleUrls: ['./map-filter.component.scss'],
  imports: [
    NgIf,
    DialogComponent,
    DateInputComponent,
    ButtonComponent,
    SliderModule,
    FormsModule,
    NgTemplateOutlet,
    NgForOf,
    LazyListComponent,
    CheckboxComponent,
    SharedModule
  ],
  standalone: true
})
export class MapFilterComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The base initialized {@link ExploreMapBoundsFilterReq} request.
   */
  @Input()
  filter: ExploreMapBoundsFilterReq
  /**
   * When the {@link filterChange} gets changed.
   */
  @Output()
  filterChange = new EventEmitter<ExploreMapBoundsFilterReq>()
  /**
   * All available genres.
   */
  genres: Page<GenreRest> = newEmptyPage()
  /**
   * All user selected genres.
   */
  selectedGenres: GenreRest[] = []
  /**
   * Defines the price range by which a user wants to filter profiles.
   */
  priceRange: number[] = [0, 500]
  /**
   * Max price to display.
   */
  maxPrice = 500
  /**
   * The date form instance.
   */
  dateForm: FormGroup
  /**
   * The minimum selectable date in {@link dateForm} fields.
   */
  readonly minDate = new Date()

  private formSubs: Subscription[] = []

  constructor(
    private genreService: GenreService,
    private formBuilder: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.resetApi()
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter?.currentValue) {
      this.initForm()
      this.priceRange[0] = this.filter.priceStart || 0
      this.priceRange[1] = this.filter.priceEnd || 100
    }
  }

  /**
   * Fires when the user wants to save changes.
   */
  onComponentSave(): void {
    super.onComponentSave()
    if (this.filter) {
      const formData = this.dateForm.value
      let start: Date = null
      let end: Date = null
      if (formData.end) {
        end = dateEditHourMinutes(formData.end, 23, 59)
      }
      if (formData.start) {
        start = dateCut(formData.start, 'h')
      }
      this.filter.start = start || null
      this.filter.end = end || null
      this.filter.priceStart = this.priceRange[0] || 0
      this.filter.priceEnd = this.priceRange[1] || null
      this.filter.genres = this.selectedGenres.map(it => it.id) || []
      this.filterChange.emit(this.filter)
    }
  }

  /**
   * - Calls the server API to get all genres.
   * - If the {@link search} is present, it will call the search endpoint instead of the default one.
   */
  callSearchGenres(pageNum: number): Observable<Page<GenreRest>> {
    return this.unwrap(this.genreService.callGetAllGenres({page: pageNum}))
  }

  /**
   * Adds the {@link genre} to the {@link selectedGenres} array.
   */
  addGenre(genre: GenreRest): void {
    for (const p of this.selectedGenres) {
      if (p.id === genre.id) {
        return
      }
    }
    this.selectedGenres = [...this.selectedGenres, genre]
  }

  /**
   * Removes the {@link genre} from the {@link selectedGenres} array.
   */
  removeGenre(genre: GenreRest): void {
    for (let i = 0; i < this.selectedGenres.length; i++) {
      if (this.selectedGenres[i].id === genre.id) {
        this.selectedGenres.splice(i, 1)
        this.selectedGenres = [...this.selectedGenres]
        break
      }
    }
    this.genres.content.find(it => (it.id === genre.id))[`selected`] = false
  }

  /**
   * Resets date fields to the default values.
   */
  resetDateFields(): void {
    const c = this.dateForm.controls
    c.start.setValue('')
    c.end.setValue('')
  }

  /**
   * Initializes the {@link dateForm}.
   */
  private initForm(): void {
    this.dateForm = this.formBuilder.group({
      start: [this.filter?.start || ''],
      end: [this.filter?.end || '']
    }, {
      validators: [dateMustBeAfter('start', 'end')]
    })

    this.formSubs.push(...formDatesNotOverlaps(this.dateForm, 'start', 'end'))
  }

  ngOnDestroy(): void {
    this.formSubs?.forEach(it => {
      it.unsubscribe()
    })
  }
}
