/**
 * - Defines, from which part of the date should be all set to 0. (See {@link dateCut})
 * - (E.g.: 'm' -> minutes, seconds, and milliseconds will be set to 0.)
 * - 'h' - hours
 * - 'm' - minutes
 * - 's' - seconds
 * - 'ms' - milliseconds
 */
export type DateCut = 'h' | 'm' | 's' | 'ms'

/**
 * - Defines, from which part of the date will start ignoring values. (See {@link dateEquals})
 * - (E.g.: 'm' -> minutes, seconds, and milliseconds will not be comparing)
 * - 'M' - <i>(capital M)</i> - months
 * - 'd' - date
 * - 'h' - hours
 * - 'm' - minutes
 * - 's' - seconds
 * - 'ms' - milliseconds
 */
export type DateEqualsCut = 'M' | 'd' | 'h' | 'm' | 's' | 'ms'

/**
 * Adds a number of months to the date. It also takes care about the year, if the next year should appear.
 *
 * @param date The date to be changed.
 * @param monthsToAdd How many numbers will be added.
 */

export function addMonths(date: Date, monthsToAdd: number): Date {
  const result = new Date(date)
  result.setDate(1)
  result.setMonth(result.getMonth() + monthsToAdd)
  return result
}

/**
 * Deducts a number of months from the date. It also takes care about the year, if the previous year should appear.
 *
 * @param date The date to be changed.
 * @param monthsToReduce How many numbers will be added.
 */
export function minusMonths(date: Date, monthsToReduce: number): Date {
  const result = new Date(date)
  result.setDate(1)
  result.setMonth(result.getMonth() - monthsToReduce)
  return result
}

/**
 * Adds days to the date.
 */
export function addDays(date: Date, days: number): Date {
  const d = new Date(date)
  d.setDate(d.getDate() + days)
  return d
}

/**
 * Removes days from the date.
 */
export function minusDays(date: Date, days: number): Date {
  const d = new Date(date)
  d.setDate(d.getDate() - days)
  return d
}

/**
 * Add {@link hours} to the {@link date}.
 */
export function addHours(date: Date, hours: number): Date {
  const d = new Date(date)
  d.setHours(date.getHours() + hours)
  return d
}

/**
 * Deducts {@link hours} from the {@link date}.
 */
export function minusHours(date: Date, hours: number): Date {
  const d = new Date(date)
  d.setHours(date.getHours() - hours)
  return d
}

/**
 * Add {@link minutes} to the {@link date}.
 */
export function addMinutes(date: Date, minutes: number): Date {
  const d = new Date(date)
  d.setMinutes(date.getMinutes() + minutes)
  return d
}

/**
 * Add {@link seconds} to the {@link date}.
 */
export function addSeconds(date: Date, seconds: number): Date {
  const d = new Date(date)
  d.setSeconds(date.getSeconds() + seconds)
  return d
}

/**
 * Deducts {@link minutes} from the {@link date}.
 */
export function minusMinutes(date: Date, minutes: number): Date {
  const d = new Date(date)
  d.setMinutes(date.getMinutes() - minutes)
  return d
}

/**
 * Returns the days of a month.
 *
 * @param date The source of data.
 */
export function daysInMonth(date: Date): number {
  return 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate()
}

/**
 * Returns the days difference between two dates.
 */
export function daysDifference(firstDate: Date, secondDate: Date): number {
  const date1 = new Date(firstDate)
  const date2 = new Date(secondDate)

  date1.setHours(0, 0, 0, 0)
  date2.setHours(0, 0, 0, 0)

  const diffTime = Math.abs(date1.getTime() - date2.getTime())
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

/**
 * Returns the minutes difference between two dates.
 */
export function minutesDifference(firstDate: Date, secondDate: Date): number {
  const date1 = new Date(firstDate)
  const date2 = new Date(secondDate)

  const diffTime = Math.abs(date1.getTime() - date2.getTime())
  return Math.ceil(diffTime / (1000 * 60))
}

/**
 * - Returns the hours difference between two dates.
 * - Returns 0 if one of the parameters is not specified.
 */
export function hoursDifference(first: Date, second: Date): number {
  if (!first || !second) {
    return 0
  }

  const date1 = new Date(first)
  const date2 = new Date(second)

  const difference = minutesDifference(date1, date2)
  return Math.ceil(difference / 60)
}

/**
 * Returns the position (0 - 6) of the day in a week.
 * If the sundayFirst parameter is false, the first day in a week is Monday.
 *
 * @param date Gets the day.
 * @param sundayFirst If true, then Sunday is the first day in a week, otherwise, it is Monday.
 */
export function dayPosInWeek(date: Date, sundayFirst: boolean = true): number {
  const num = date.getDay()
  if (sundayFirst) {
    return num
  } else {
    if (num === 0) {
      return 6
    } else {
      return num - 1
    }
  }
}

/**
 * Returns true if the 'comparingYearMonth' and 'date' parameters are the same as the 'todayDate' year-month-day values.
 *
 * @param todayDate Today's date.
 * @param comparingYearMonth The year-month date to be compared with the todayDate.
 * @param date The day(date) number to be compared with the todayDate.
 */
export function isToday(todayDate: Date, comparingYearMonth: Date, date: number): boolean {
  return (todayDate.getFullYear() === comparingYearMonth.getFullYear()
    && todayDate.getMonth() === comparingYearMonth.getMonth()
    && todayDate.getDate() === date)
}

/**
 * - Joins together both provided dates. The year, month and date will be taken from the {@link yearMonthDay} date while hours,
 * minutes, seconds, and milliseconds will be taken from the {@link hourMinutesSecMs} date.
 * - The {@link cut} parameter represents from which 'unit' it will start setting 0. (See {@link DateCut}).
 * - Returns a new date instance.
 */
export function dateJoin(yearMonthDay: Date, hourMinutesSecMs: Date, utc: boolean = false, cut: DateCut = 's'): Date {
  let hours = hourMinutesSecMs.getHours()
  let minutes = hourMinutesSecMs.getMinutes()
  let seconds = hourMinutesSecMs.getSeconds()
  let ms = hourMinutesSecMs.getMilliseconds()

  switch (cut) {
    case 'h':
      hours = 0
    // eslint-disable-next-line no-fallthrough
    case 'm':
      minutes = 0
    // eslint-disable-next-line no-fallthrough
    case 's':
      seconds = 0
    // eslint-disable-next-line no-fallthrough
    case 'ms':
      ms = 0
  }

  if (utc) {
    return new Date(Date.UTC(yearMonthDay.getFullYear(), yearMonthDay.getMonth(), yearMonthDay.getDate(), hours, minutes, seconds, ms))
  }
  // non UTC
  return new Date(yearMonthDay.getFullYear(), yearMonthDay.getMonth(), yearMonthDay.getDate(), hours, minutes, seconds, ms)
}

/**
 * - Compares date values were it doesn't take into account the part starting from the {@link cut}.
 * - By default, it doesn't care about seconds and milliseconds.
 * - {@link cut} values:
 * - 'M' - comparing year
 * - 'd' - comparing year, month
 * - 'h' - comparing year, month, date
 * - 'm' - comparing year, month, date, hour
 * - 's' - comparing year, month, date, hour, minute
 * - 'ms' - comparing year, month, date, hour, minute, seconds
 */
export function dateEquals(first: Date, second: Date, cut: DateEqualsCut = 's'): boolean {
  const years = first.getFullYear() === second.getFullYear()
  const months = first.getMonth() === second.getMonth()
  const days = first.getDate() === second.getDate()
  const hours = first.getHours() === second.getHours()

  switch (cut) {
    case 'M':
      return years
    case 'd':
      return years && months
    case 'h':
      return years && months && days
    case 'm':
      return years && months && days && hours
    case 's':
      return years && months && days && hours
        && first.getMinutes() === second.getMinutes()
    case 'ms':
      return years && months && days && hours
        && first.getMinutes() === second.getMinutes()
        && first.getSeconds() === second.getSeconds()
  }
}

/**
 * - Cuts the provided {@link date} into a new instance.
 * - The {@link cut} parameter represents from which 'unit' it will start setting 0.
 * - By default, it cuts seconds and milliseconds.(See {@link DateCut}).
 * - The {@link utc} parameter treats passed values as UTC ready format (no changes will be performed).
 * Otherwise, the passed values will be changed to the UTC.
 * - Returns a new date instance.
 */
export function dateCut(date: Date, cut: DateCut = 's', utc: boolean = false): Date {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  let ms = date.getMilliseconds()

  switch (cut) {
    case 'h':
      hours = 0
    // eslint-disable-next-line no-fallthrough
    case 'm':
      minutes = 0
    // eslint-disable-next-line no-fallthrough
    case 's':
      seconds = 0
    // eslint-disable-next-line no-fallthrough
    case 'ms':
      ms = 0
  }

  if (utc) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, ms))
  }
  // non UTC
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, ms)
}

/**
 * Creates the Year Month Day Hour Minutes Date from given parameters. Other units are set to zero.
 * The utc parameter treats passed values as UTC ready format (no changes will be performed).
 * Otherwise, the passed values will be changed to the UTC.
 */
export function dateEditHourMinutes(yearMonthDay: Date, hours: number, minutes: number, utc: boolean = false): Date {
  if (utc) {
    return new Date(yearMonthDay.getFullYear(), yearMonthDay.getMonth(), yearMonthDay.getDate(), hours, minutes, 0, 0)
  }
  return new Date(Date.UTC(yearMonthDay.getFullYear(), yearMonthDay.getMonth(), yearMonthDay.getDate(), hours, minutes, 0, 0))
}

/**
 * Sets the FullYear, Month, and Date to the date param object.
 *
 * @param yearMonthDate The year-month-date to be set into the date object.
 * @param date The object to be changed.
 */
export function dateEditYearMonthDate(yearMonthDate: Date, date: Date): void {
  date.setFullYear(yearMonthDate.getFullYear(), yearMonthDate.getMonth(), yearMonthDate.getDate())
}

/**
 * Iterates over all entries of the input object and replace the string dates with the objects of {@link Date}.
 */
export function fixDateObjects(responseBody: any): void {
  if (responseBody) {
    const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/
    for (const [key, value] of Object.entries(responseBody)) {
      const val = String(value)
      if (val.startsWith('[object Object]')) {
        fixDateObjects(value)
      }
      if (val.match(regex)) {
        responseBody[key] = new Date(val)
      }
    }
  }
}

/**
 * Removes the [Continent/CapitalCity] flag at the end of the date.
 */
export function dateCutCapital(date: string): Date {
  const regex = /\[/
  return new Date(date.substring(0, regex.exec(date).index).trim())
}

/**
 * Determines if provided date ends withing provided time in given unit.
 * @param date Time to compare to.
 * @param endTime Number to which should be provided time lesser.
 * @param cut Unit to count against.
 */
export function dateEndsWithin(date: Date, endTime: number, cut: DateCut = 'm'): boolean {
  if (date == null) {
    return false
  }

  const currentTime = new Date().getTime()
  const time = date.getTime()
  let divisor = 1

  switch (cut) {
    case 'h':
      divisor = 60 * 60 * 1000
      break
    case 'm':
      divisor = 60 * 1000
      break
    case 's':
      divisor = 1000
  }

  const difference = (time / divisor) - (currentTime / divisor)

  return difference >= 0 && difference <= endTime
}

/**
 * Fix date value selected from p-calendar component.
 * Source: https://github.com/primefaces/primeng/issues/2426
 */
export function fixUTCDate(date: Date): Date {
  return new Date(new Date(date.getTime() - (date.getTimezoneOffset() * 60 *
    1000)).toUTCString())
}
