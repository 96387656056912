import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core'
import {BriefMapProfileResp, MapProfileReq, MapProfileResp, MapService} from '../../../service/map.service'
import {ApiComponent} from '../../abstract/api.component'
import {fadeAnimation} from '../../../animation/fade.animation'
import {firstValueFrom, Observable} from 'rxjs'
import {NgIf} from '@angular/common'
import {ProfileBubbleComponent} from '../profile-bubble/profile-bubble.component'
import {AvatarPipe} from '../../../pipe/avatar.pipe'
import {VarDirective} from '../../../directive/var.directive'
import {SkeletonModule} from 'primeng/skeleton'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-marker',
  templateUrl: './profile-marker.component.html',
  styleUrls: ['./profile-marker.component.scss'],
  imports: [
    NgIf,
    ProfileBubbleComponent,
    AvatarPipe,
    VarDirective,
    SkeletonModule
  ],
  standalone: true
})
export class ProfileMarkerComponent extends ApiComponent implements OnInit {

  /**
   * From this data will be downloaded the rest data from the server.
   */
  @Input()
  data: BriefMapProfileResp

  /**
   * Determines the visibility of a bubble after a user clicks on the marker.
   */
  @Input()
  bubbleEnabled: boolean

  /**
   * This is used when only the bubble component is needed.
   * But also the {@link bubbleEnabled} property needs to be set to true.
   */
  @Input()
  bubbleOnly: boolean

  /**
   * Defines the marker color.
   */
  @Input()
  markerColor = 'gray'

  /**
   * Detailed data from the server based on the input {@link data}.
   */
  profileMapData?: MapProfileResp

  /**
   * The toggle of the marker's bubble.
   */
  bubbleVisible: boolean

  /**
   * The state of downloading process.
   */
  downloading: boolean

  constructor(
    public changeRef: ChangeDetectorRef,
    private mapService: MapService) {
    super()
  }

  ngOnInit(): void {
    // if bubble only, download and show data
    if (this.bubbleOnly) {
      this.onClick()
    }
  }

  /**
   * Fires when a user has clicked on the marker.
   */
  async onClick(): Promise<void> {
    if (this.bubbleEnabled) {
      this.bubbleVisible = !this.bubbleVisible

      if (!this.profileMapData && !this.downloading) {
        try {
          this.downloading = true
          this.profileMapData = await firstValueFrom(this.callGetProfileMap())
        } finally {
          this.downloading = false
        }
      }
    }
  }

  /**
   * Calls the API to download details of the {@link data}.
   */
  private callGetProfileMap(): Observable<MapProfileResp> {
    const req: MapProfileReq = {
      profileId: this.data.profile.profileId,
      date: new Date()
    }
    return this.unwrap(this.mapService.callGetProfileMapDetails(req))
  }
}
