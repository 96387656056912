import {PLATFORM_BROWSER} from '../app.module'

/**
 * Checks if the given parameter of widthPx matches with the 'min-device-width' property.
 */
export function deviceMatchesWidth(widthPx: number): boolean {
  return window.matchMedia('(min-device-width: ' + widthPx + 'px)').matches
}

/**
 * Checks if the given parameter of widthPx matches with the 'min-screen-width' property.
 */
export function deviceScreenMatchesWidth(widthPx: number): boolean {
  if (PLATFORM_BROWSER) {
    return window.matchMedia('(min-width: ' + widthPx + 'px)').matches
  } else {
    return widthPx === ScreenSize.MD
  }
}

/**
 * Returns the next min-device-width in pixels by the current device width.
 * For example, if the device width is 768px, this function returns
 * 992, which is the next breakpoint in width.
 * This is handy to determine what size of image the backend should return.
 */
export function getDeviceMatchWidth(): number {
  if (deviceMatchesWidth(2560)) { // Original
    return 99999

  } else if (deviceMatchesWidth(2000)) { // xx-large
    return 2560

  } else if (deviceMatchesWidth(1200)) { // x-large
    return 2000

  } else if (deviceMatchesWidth(992)) { // large
    return 1200

  } else if (deviceMatchesWidth(768)) { // medium
    return 992

  } else if (deviceMatchesWidth(576)) { // small
    return 768

  } else { // x-small
    return 576
  }
}


export function getDeviceMatchWidthWallpaper(): number {
  if (deviceMatchesWidth(1920)) {
    return 1920

  } else if (deviceMatchesWidth(1024)) {
    return 1920

  } else if (deviceMatchesWidth(800)) {
    return 1024

  } else if (deviceMatchesWidth(500)) {
    return 800

  } else {
    return 500
  }
}

// Don't forget to update the Cypress ScreenSize enum as well!
export enum ScreenSize {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1920
}

/**
 * - Converts given unit in string to the pixels.
 * - E.g. '10rem' -> 10*16
 */
export function toPixels(unit: string): number {
  if (unit.includes('rem')) {
    const num = +unit.split('rem')[0] || 0
    return num * 16
  } else if (unit.includes('dvh')) {
    const num = +unit.split('dvh')[0] || 0
    return num * (window.innerHeight / 100)
  }
}

