import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {fadeAnimation} from '../../../animation/fade.animation'
import {BriefProfileResp, ProfileResp} from '../../../service/profile.service'
import {MapService, OSMSearchResult} from '../../../service/map.service'
import {ProfileType} from '../../../common/profile-type'
import {Restrictions} from '../../../common/restrictions'
import {FormBuilder, FormGroup} from '@angular/forms'
import {dateMustBeAfter, dateTimeAfterDateTime, minDate} from '../../../validator/custom.validators'
import {AddressReq, AddressResp, AddressService} from '../../../service/address.service'
import {HostProfileService, NewHostProfileReq} from '../../../service/host-profile.service'
import {
  CalendarItemResp,
  CalendarService,
  NewCalendarItemReq,
  UpdateCalendarItemReq
} from '../../../service/calendar.service'
import {dateCut, dateEditHourMinutes, dateEquals, dateJoin} from '../../../utils/date.utils'
import {Acceptance} from 'src/app/common/acceptance'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {formDatesNotOverlaps, formTimesNotOverlaps} from '../../../utils/form.utils'
import {Feature} from '../../../common/feature'
import {ValidComponent} from '../../abstract/valid.component'
import {LatLng, LatLngExpression} from 'leaflet'
import {LeafletService} from '../../../service/ui/leaflet.service'
import {ServerMessage} from '../../../common/server-message'
import {growAnimation} from '../../../animation/grow.animation'
import {scrollToIndex} from '../../../utils/scroll.utils'
import {Country, countryDetailsOf, countryDetailsOfCode, SUPPORTED_COUNTRIES} from '../../../common/country'
import {ProfileCompletionService} from '../../../service/profile-completion.service'

@Component({
  animations: [fadeAnimation(200), growAnimation()],
  selector: 'app-update-map-location',
  templateUrl: './update-map-location.component.html',
  styleUrls: ['./update-map-location.component.scss']
})
export class UpdateMapLocationComponent extends ValidComponent implements OnInit, OnChanges, OnDestroy {

  @Output()
  changed = new EventEmitter<boolean>()
  /**
   * The current profile that the location will be changed.
   */
  @Input()
  data: ProfileResp
  /**
   * Used to fill the {@link dateTimeForm}.
   */
  @Input()
  timeDateItem?: CalendarItemResp
  /**
   * Emits any changes of the {@link timeDateItem}.
   */
  @Output()
  timeDateItemChange = new EventEmitter<CalendarItemResp>()
  /**
   * Emits loading process of the component.
   */
  @Output()
  emitLoading = new EventEmitter<boolean>()
  /**
   * This profile represents the host profile, that {@link data} profile will be hosted at.
   */
  hostProfile: BriefProfileResp
  /**
   * Contains the original location on the map from the map search.
   */
  originalMapLocation?: LatLngExpression
  /**
   * Contains the user-specified map location.
   */
  mapLocation?: LatLng
  /**
   * - When the map search fails, this will contain only the city-postal-country-state search result.
   * - It is used to center the map.
   */
  cityOnlyLocation?: LatLngExpression
  /**
   * Represents the postal information form.
   */
  addressForm: FormGroup
  /**
   * Represents the date time information form.
   */
  dateTimeForm?: FormGroup
  /**
   * Defines whether the {@link data} profile has the {@link Feature.BE_HOSTED} feature.
   */
  canBeHosted: boolean
  /**
   * Shows the map a little bit late after this component gets fully initialized.
   */
  mapVisible: boolean

  readonly todayDate = new Date()
  readonly defaultStartTime = dateEditHourMinutes(this.todayDate, 9, 0)
  readonly defaultEndTime = dateEditHourMinutes(this.todayDate, 20, 0)

  Acceptance: typeof Acceptance = Acceptance
  supportedCountries: typeof SUPPORTED_COUNTRIES = SUPPORTED_COUNTRIES

  /**
   * - Holds the current map search timeout.
   * - See the {@link startMapSearch} function.
   */
  private mapSearchTimeout
  /**
   * All form value change subscriptions.
   */
  private formSubs?: Subscription[] = []

  constructor(
    private mapService: MapService,
    private addressService: AddressService,
    private profileHostService: HostProfileService,
    private calendarService: CalendarService,
    private formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef,
    private leaflet: LeafletService,
    private profileCompletionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    this.hostProfile = this.data.hostProfile?.host
    this.initAddressForm(this.data.address || this.data.hostProfile?.host.address)

    // Init the date form only when the profile type is a type of EVENT
    if (this.data.profileType === ProfileType.EVENT) {
      this.initDateTimeForm(this.timeDateItem)
    }

    // Update marker position
    if (this.hostProfile) {
      const address = this.hostProfile.address
      this.initMapSearch(address)

    } else if (this.data.address) {
      const address = this.data.address
      this.initMapSearch(address)
      this.setValid(true)
    }

    this.changeRef.detectChanges() // it gets rid of AfterCheckedException
    setTimeout(() => {
      this.mapVisible = true
    }, 200)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue) {
      this.canBeHosted = this.hasFeatures(this.data.profileType, Feature.BE_HOSTED)
    }
  }

  /**
   * Starts map search if the address has not been set.
   */
  private initMapSearch(address): void {
    if (!address?.lat || !address?.lng) {
      this.startMapSearch(address)
    } else {
      if (this.leaflet.isReady()) {
        this.mapLocation = this.leaflet.latLng(address.lat, address.lng)
        this.originalMapLocation = this.mapLocation
        this.validateAddress()
      }
    }
  }

  /**
   * Updates a map location.
   */
  updateLocation(location): void {
    if (this.hostProfile && location.lat !== this.hostProfile.address?.lat) {
      this.hostProfile = null
      this.clearPostalForm()
    }

    this.mapLocation = location

    if (this.hostProfile) {
      const address = this.hostProfile.address
      address.lat = location?.lat || null
      address.lng = location?.lng || null
    } else if (this.data.address) {
      const address = this.data.address
      address.lat = location?.lat || null
      address.lng = location?.lng || null
    }
  }

  /**
   * Fires when a user has clicked on the save button.
   * The conditions bellow needs to be in the right order!
   */
  async onComponentSave(): Promise<void> {
    this.disableForms(true)

    try {
      // Update date-time calendar item.
      const dateTimeData = this.dateTimeForm?.value
      if (dateTimeData && this.isDifferentDateTime(dateTimeData)) {
        const resp = await firstValueFrom(this.callUpdateCalendarItem(dateTimeData))
        if (resp && this.noServerMessages()) {
          this.timeDateItemChange.emit(resp)
        }
      }

      // Update Host Profile
      if (this.hostProfile) {
        // update only if a user has made some changes
        if (this.isDifferentHostProfile()) {
          await firstValueFrom(this.callUpdateProfileHostProfile(this.hostProfile))
        }

        // Update Address (needs to be after checking the host profile)
      } else if (this.isPostalFormValid()) {
        // update only if a user has made some changes
        if (this.isDifferentAddress(this.addressForm.value)) {
          const resp = await firstValueFrom(this.callUpdateProfileAddress(this.addressForm.value))
          if (resp && this.noServerMessages()) {
            this.data.address = resp
          }
        }
      }

      if (this.noServerMessages()) {
        this.changed.emit(true)
      }

    } finally {
      this.disableForms(false)
      this.profileCompletionService.closeCompletionItemAndCheck()
    }
  }

  /**
   * Calls the server API to update the profile address.
   */
  private callUpdateProfileAddress(formData): Observable<AddressResp> {
    // create a request
    const req: AddressReq = {
      lat: this.mapLocation.lat,
      lng: this.mapLocation.lng,

      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      postalCode: formData.postalCode,
      state: formData.country.name,
      country: formData.country.code
    }

    // Call the API
    return this.unwrap(this.addressService.callUpdateProfileAddress(req))
  }

  /**
   * Calls the server API to update the profile's host profile.
   */
  private callUpdateProfileHostProfile(host: BriefProfileResp): Observable<boolean> {
    // Construct a request
    const req: NewHostProfileReq = {
      profileId: this.data.profileId,
      hostProfileId: host.profileId
    }

    // Call the API
    return this.unwrap(this.profileHostService.callNewHostProfile(req))
  }

  /**
   * Updates the profile date-time calendar item.
   *
   * @param formData The {@link dateTimeForm} data.
   */
  private callUpdateCalendarItem(formData): Observable<CalendarItemResp> {
    const formStart = dateJoin(formData.startDate, formData.startTime)
    const formEnd = dateJoin(formData.endDate, formData.endTime)

    if (this.timeDateItem) {
      // UPDATE
      const req: UpdateCalendarItemReq = {
        profileId: this.data.profileId,
        start: formStart,
        end: formEnd,
        calendarItemId: this.timeDateItem.id
      }
      // Call the API
      return this.unwrap(this.calendarService.callUpdateCalendarItem(req))
    } else {
      // NEW
      const req: NewCalendarItemReq = {
        start: formStart,
        end: formEnd,
        profileId: this.data.profileId
      }
      // Call the API
      return this.unwrap(this.calendarService.callNewCalendarItem(req))
    }
  }

  /**
   * Initializes the {@link addressForm} with values from the server.
   */
  private initAddressForm(address: AddressReq): void {
    this.addressForm = this.formBuilder.group({
      line1: [address?.line1 || ''],
      line2: [address?.line2 || ''],
      city: [address?.city || ''],
      postalCode: [address?.postalCode || ''],
      country: [countryDetailsOfCode(address?.country) || countryDetailsOf(Country.SLOVAKIA)]
    })

    this.validateAddress()
    this.startMapSearch(this.addressForm.value)

    // Observe value changes
    this.formSubs.push(this.addressForm.valueChanges.subscribe(() => {
      this.cityOnlyLocation = null
      const formData = this.addressForm.value
      address = {
        lat: formData.lat,
        lng: formData.lng,
        line1: formData.line1,
        line2: formData.line2,
        postalCode: formData.postalCode,
        city: formData.city,
        country: formData.country.code,
        state: formData.country.name
      }

      this.validateAddress()
      this.startMapSearch(formData)
    }))
  }

  /**
   * - Calls the request to search on the map for the latitude and longitude of given {@link addressForm} data.
   * - The request will start after the 750ms of user inactivity. (From the last function call).
   */
  private startMapSearch(d): void {
    if (this.mapSearchTimeout) {
      clearTimeout(this.mapSearchTimeout)
    }
    this.mapSearchTimeout = setTimeout(() => {
      // return if missing mandatory values
      if (!d.line1 || !d.city || !d.country) {
        return
      }

      // call the map search
      this.call(async () => {
        this.emitLoading.emit(true)
        this.setValid(false)
        const citySearch = `${d.city || ''} ${d.postalCode || ''} ${countryDetailsOfCode(d.country)?.name || ''}`.trim()
        const search = `${d.line1 || ''} ${citySearch}`.trim()
        let result = (await firstValueFrom(this.mapService.callOSMSearch(search)))?.[0]
        if (result) {
          this.cityOnlyLocation = null
          if (this.leaflet.isReady()) {
            this.mapLocation = this.leaflet.latLng(+result.lat, +result.lon)
            this.originalMapLocation = this.mapLocation // update the original location
            this.updateLocation(this.originalMapLocation)
            // Based on the user feedback, automatic adress rewrite is disabled
            // this.updateAddressFields(result)
          }
        } else {
          this.mapLocation = null
          this.originalMapLocation = null

          // City Only location
          result = (await firstValueFrom(this.mapService.callOSMSearch(citySearch)))?.[0]
          if (result && this.leaflet.isReady()) {
            this.cityOnlyLocation = this.leaflet.latLng(+result.lat, +result.lon)
            // Based on the user feedback, automatic adress rewrite is disabled
            // this.updateAddressFields(result)
          }

          this.pushToMessages(ServerMessage.PROFILE_ORDER_LOCATION_MISSING)
          this.updateLocation(null)
        }
        this.validateAddress()
        this.emitLoading.emit(false)
      }, (e) => {
        this.onResponseError(e)
        this.pushToMessages(ServerMessage.PROFILE_ORDER_LOCATION_MISSING)
        this.updateLocation(null)
        this.validateAddress()
      })
    }, 750)
  }

  /**
   * Initializes the {@link dateTimeForm} with values from the server.
   */
  private initDateTimeForm(calendarItem?: CalendarItemResp): void {
    this.dateTimeForm = this.formBuilder.group({
      startDate: [calendarItem ? new Date(calendarItem.start) : '', [
        minDate(dateCut(new Date(), 'h'))
      ]],
      endDate: [calendarItem ? new Date(calendarItem.end) : ''],
      startTime: [calendarItem ? new Date(calendarItem.start) : ''],
      endTime: [calendarItem ? new Date(calendarItem.end) : '']
    }, {
      validators: [
        dateMustBeAfter('startDate', 'endDate'),
        dateTimeAfterDateTime(
          'startDate',
          'endDate',
          'startTime',
          'endTime',
          Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH)
      ]
    })

    this.formSubs.push(
      // Subscribe for value changes and ensure their correctness
      ...formDatesNotOverlaps(this.dateTimeForm, 'startDate', 'endDate'),
      ...formTimesNotOverlaps(this.dateTimeForm, 'startDate', 'endDate', 'startTime', 'endTime')
    )
  }

  /**
   * Clears the {@link addressForm} fields, host profile and marker from the map.
   */
  onClearClicked(): void {
    this.hostProfile = null
    this.mapLocation = null
    this.clearPostalForm()
    this.clearDateTimeForm()
    this.resetApi()
  }

  /**
   * Updates certain address fields from the {@link OSMSearchResult} object.
   */
  private updateAddressFields(osmResult: OSMSearchResult): void {
    if (osmResult) {
      const opts = {emitEvent: false}
      const c = this.addressForm.controls
      const postalCode = osmResult.address?.postcode?.replace(' ', '') || ''

      c.country.setValue(countryDetailsOfCode(osmResult.address.country_code) || countryDetailsOf(Country.SLOVAKIA), opts)
      c.postalCode.setValue(postalCode, opts)
    }
  }

  /**
   * Clears the {@link addressForm}.
   */
  private clearPostalForm(): void {
    const controls = this.addressForm.controls
    controls.line1.setValue('')
    controls.line2.setValue('')
    controls.city.setValue('')
    controls.postalCode.setValue('')
    controls.country.setValue(countryDetailsOf(Country.SLOVAKIA)) // TODO get by locale
  }

  private clearDateTimeForm(): void {
    if (this.dateTimeForm) {
      const controls = this.dateTimeForm.controls
      controls.startDate.setValue('')
      controls.endDate.setValue('')
      controls.startTime.setValue('')
      controls.endTime.setValue('')
    }
  }

  /**
   * Checks if the {@link addressForm} does not have any blank field.
   */
  private isPostalFormValid(): boolean {
    const formData = this.addressForm.value
    return this.mapLocation && formData.line1 && formData.city && formData.postalCode && formData.country
  }

  /**
   * Validates the address.
   */
  private validateAddress(): void {
    const addressValid = this.addressForm.valid && this.noServerMessages()
    this.setValid(addressValid)
  }

  /**
   * - Scrolls to the bottom of this component.
   * - It is called outside the component.
   */
  scrollBottom(): void {
    scrollToIndex('bottom-map', 0, 'smooth')
  }

  /**
   * Returns true if a user has changed the host profile.
   */
  private isDifferentHostProfile(): boolean {
    return this.data.hostProfile?.host.profileId !== this.hostProfile?.profileId
  }

  /**
   * Returns true if a user has changed the profile address.
   *
   * @param formData The current postal form data.
   */
  private isDifferentAddress(formData): boolean {
    const pr = this.data.address
    return pr?.lat !== this.mapLocation.lat
      || pr?.lng !== this.mapLocation.lng
      || pr?.line1 !== formData.line1
      || pr?.line2 !== formData.line2
      || pr?.city !== formData.city
      || pr?.postalCode !== formData.postalCode
      || pr?.state !== formData.country.name
      || pr?.country !== formData.country.code
  }

  /**
   * Returns true if a user has changed the date-time form.
   *
   * @param formData The current datetime form data.
   */
  private isDifferentDateTime(formData): boolean {
    const prForm = this.timeDateItem
    if (!prForm) {
      return true
    }
    const start = new Date(prForm.start)
    const end = new Date(prForm.end)
    const formStart = dateJoin(formData.startDate, formData.startTime)
    const formEnd = dateJoin(formData.endDate, formData.endTime)

    return !dateEquals(start, formStart, 's') || !dateEquals(end, formEnd, 's')
  }

  /**
   * Disables the {@link addressForm} and {@link dateTimeForm}.
   */
  private disableForms(disable: boolean): void {
    if (disable) {
      this.addressForm.disable()
      this.dateTimeForm?.disable()
    } else {
      this.addressForm.enable()
      this.dateTimeForm?.enable()
    }
  }

  ngOnDestroy(): void {
    // unsubscribe all form value changes
    this.formSubs.forEach((it) => {
      it?.unsubscribe()
    })
  }
}
