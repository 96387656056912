export const BASE_SERVER_URL = 'https://api.umevia.com'
export const BASE_SITE_URL = 'https://app.umevia.com'
export const COOKIE_ORIGIN = 'umevia.com' // must be the base domain

export const environment = {
  name: 'production',
  sslEnabled: true,
  production: true,
  /**
   * True when this environment runs locally only and not on a remote server.
   */
  runsLocally: false,
  splashscreen: true,
  /**
   * Determines whether the leave action (e.g., from save dialogs) will be block if necessary.
   */
  blockLeaveAction: true,
  /**
   * Minimum value of the order amount.
   */
  minimumOrderPrice: 4.0,
  /**
   * Order acceptance area radius in meters
   */
  orderAcceptanceAreaRadius: 250,
  firebaseConfig: {
    apiKey: 'AIzaSyDgLIU-hPtrB350FYXamETtyyoZ28dw5jo',
    authDomain: 'umevia-prod.firebaseapp.com',
    projectId: 'umevia-prod',
    storageBucket: 'umevia-prod.appspot.com',
    messagingSenderId: '62222971426',
    appId: '1:62222971426:web:b3a37a4d3795e48511d07b',
    measurementId: 'G-TDT6G8WE9Y'
  },

  stripe: {
    apiKey: 'pk_live_51NIwk3HVDCSZQWDll4Wrd0nf9RDt6Y6gT6VoyC55v1MmQZMdgoAvWNvhpLdxgZ1NGOPh2w2QxsAtQyvTqmHE3ON900uUqiERCs'
  },

  clarity: {
    appId: 'k4sl105xlf'
  },

  googleAnalytics: {
    trackingCode: 'G-TDT6G8WE9Y'
  },

  /**
   * A Meta Pixel tracking code.
   */
  metaPixel: '7151665178221936',

  // Platform provision - MUST BE SYNCED WITH BACKEND!
  platformFee: {
    // Provision for profile orders
    platformOrder: {
      // Percentage fee provision
      feePercentage: 8.00,
      // Fixed fee provision
      feeFixed: 3.50,
      // Maximal revenue for artist in case of order cancellation
      maxProfileRejectProfitPercentage: 30.00
    }
  },

  browserDist: '../../app/sk/',
  ssrProxy: false,
  sitemap: 'https://umevia-prod-public.s3.eu-central-1.amazonaws.com/sitemap/sitemap.xml',

  // Support contact
  contact: {
    phone: '+421918021848',
    email: 'support@umevia.com',

    instagram: 'https://instagram.com/umevia.official',
    x: 'https://x.com/umeviaofficial',
    youtube: 'https://youtube.com/@umevia',
    facebook: 'https://facebook.com/umevia',
    linkedin: 'https://www.linkedin.com/company/umevia',
    docs: 'https://docs.umevia.com/sk'
  },

  // Rating component
  platformRating: {
    showAfterMinutes: 5,
    daysUntilFirstShow: 2
  }
}
