<div class="p-d-flex p-ai-center p-jc-center p-relative">
  <div class="p-d-flex wrapper"
       [class.wallpaper-small]="!largeWallpaper && data"
       [class.wallpaper-large]="largeWallpaper || !data"
       [class.wallpaper-blur]="blurWallpaper">

    <!--Wallpaper-->
    <div class="wallpaper-wrapper">
      <!--Overlay-->
      <div *ngIf="data && maxWallpapers > 0"
           class="wallpaper-overlay wallpaper-small p-d-flex p-ai-center p-jc-center"
           [class.wallpaper-overlay-edit]="editButtonVisible"
           (click)="editButtonVisible ? editWallpaperComponentVisible = true : openGalleria(false)"
           [class.wallpaper-small]="!largeWallpaper"
           [class.wallpaper-large]="largeWallpaper"

           [class.wallpaper-blur]="blurWallpaper"
           [class.wallpaper-overlay-padding]="!blurWallpaper"
           appParallax
           [parallaxEnable]="isScreenOf(Screen.MD)"
           [ratio]="1">
        <div *ngIf="editButtonVisible">
          <div class="p-d-flex p-jc-center">
            <i class="fa-solid fa-pencil p-d-block p-mb-2"></i>
          </div>
          <span i18n>
            Edit
          </span>
        </div>
      </div>

      <!--Gallery - Multiple images-->
      <div *ngIf="data && wallpapers?.length > 0" class="wallpaper fit-image wallpaper-large"
           appParallax
           [parallaxEnable]="isScreenOf(Screen.MD)"
           [ratio]="parallaxRatioWallpaper">
        <p-carousel *ngVar="wallpapers.length > 1 as multiple"
                    [value]="wallpapers"
                    [numVisible]="1"
                    [numScroll]="1"
                    [page]="wallpaperActiveIndex"
                    [showIndicators]="false"
                    [autoplayInterval]="(multiple) ? 5 * 1000 : null"
                    [showNavigators]="multiple"
                    [circular]="multiple">

          <ng-template let-item pTemplate="item">
            <!--Normal Wallpaper-->
            <div *ngIf="!blurWallpaper" class="wallpaper-item fit-image" [style]="item | backgroundImage"></div>

            <!--Blur Wallpaper-->
            <ng-template *ngIf="blurWallpaper"
                         [ngTemplateOutlet]="blurWallpaperTemplate"
                         [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
          </ng-template>
        </p-carousel>
      </div>

      <!--No Wallpapers-->
      <div *ngIf="data && (maxWallpapers < 1 || wallpapers?.length < 1)"
           class="wallpaper-default wallpaper fit-image wallpaper-small"
           [class.wallpaper-small]="!largeWallpaper"
           [class.wallpaper-large]="largeWallpaper"
           appParallax
           [parallaxEnable]="isScreenOf(Screen.MD)"
           [ratio]="parallaxRatioWallpaper">
      </div>

      <!--Wallpaper skeleton-->
      <p-skeleton *ngIf="!data"
                  @fade
                  width="100%"
                  height="25rem"
                  styleClass="wallpaper-skeleton"></p-skeleton>

    </div>

    <!--Avatar image-->
    <div *ngIf="data && profileType !== ProfileType.EVENT"
         class="avatar-wrapper p-d-flex p-ai-center p-jc-center w-100">
      <!--Overlay-->
      <div class="avatar-overlay avatar-user p-d-flex p-ai-center p-jc-center"
           *ngIf="editButtonVisible"
           (click)="editAvatarComponentVisible = true"
           [class.avatar-wallpaper-small]="!largeWallpaper"
           [class.avatar-wallpaper-large]="largeWallpaper"
           [class.avatar-none]="blurWallpaper">
        <div>
          <div class="p-d-flex p-jc-center"><i class="fa-solid fa-pencil p-d-block p-mb-2"></i></div>
          <span i18n>
            Edit
          </span>
        </div>
      </div>

      <!--Avatar-->
      <div class="avatar avatar-user c-pointer"
           (click)="openGalleria(true)"
           [style]="avatar | backgroundImage"
           [class.avatar-wallpaper-small]="!largeWallpaper"
           [class.avatar-wallpaper-large]="largeWallpaper"
           [class.avatar-none]="blurWallpaper"></div>

    </div>
    <div class="white-bar"></div>
  </div>
</div>

<!--Blur Wallpaper-->
<ng-template #blurWallpaperTemplate let-wallpaper>
  <div data-aos="fade-in" data-aos-duration="1000" data-aos-delay="100"
       class="wallpaper fit-image wallpaper-blur"
       [style]="wallpaper | backgroundImage"
       appParallax [ratio]="parallaxRatioWallpaper">

    <!--Blur-->
    <canvas class="fit-image blur" [style]="wallpaper | backgroundImage"></canvas>
    <!--Poster-->
    <div class="poster p-d-flex p-jc-center">
      <div class="fit-image poster-image" [style]="wallpaper | backgroundImage"></div>
    </div>
  </div>
</ng-template>

<!--Avatar Edit Component-->
<app-upload-avatar *ngIf="editAvatarComponentVisible"
                   [(show)]="editAvatarComponentVisible"
                   [data]="data"
                   [bannerComponent]="this"
                   (changed)="onUploadAvatar($event)">
</app-upload-avatar>

<!--Wallpaper Edit Component-->
<app-upload-wallpaper *ngIf="editWallpaperComponentVisible"
                      [(show)]="editWallpaperComponentVisible"
                      [data]="data"
                      (changed)="onUploadWallpaper($event)">
</app-upload-wallpaper>

<!--Fullscreen galleria-->
<app-galleria [images]="galleriaImages"
              [(activeIndex)]="galleriaActiveIndex"
              [(visible)]="fullscreenGalleriaVisible"></app-galleria>
