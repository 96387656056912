import {Component, Input} from '@angular/core'
import {AbstractComponent} from '../../abstract/abstract.component'
import {VarDirective} from '../../../directive/var.directive'
import {NgIf} from '@angular/common'

/**
 * Creates the hint box for the user.
 * Place the content message between the selector tags.
 */
@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  imports: [
    VarDirective,
    NgIf
  ],
  standalone: true
})
export class HintComponent extends AbstractComponent {

  /**
   * The icon near the hint.
   */
  @Input()
  icon = 'fa-solid fa-lightbulb'

  /**
   * Style classes that are applied on the very top of the component.
   */
  @Input()
  styleClass: string

  /**
   * Decides whether the hint content should be clickable or not (if {@link showHintContent} should be activated).
   * The default value is true.
   */
  @Input()
  collapsableHint = true

  /**
   * Decides whether to display the full hint content or just the first line.
   */
  showHintContent = false

  /**
   * Display the full hint content, changing the value of {@link showHintContent}, when it is clicked.
   */
  hintClick(): void {
    if (this.collapsableHint && !this.isScreenOf(this.Screen.MD)) {
      this.showHintContent = !this.showHintContent
    }
  }
}
