<h2 class="p-py-3 p-text-uppercase" i18n>Phone verification</h2>

<!--CODE-->
<app-text-input label="Paste verification code"
                i18n-label
                autocomplete="one-time-code"
                icon="fa-solid fa-message"
                inputType="text"
                formFieldName="code"
                [form]="form"
                [loading]="saving"
                [noBlankCharacters]="true"
                [space]="false"
                [copy]="false"
                [required]="true"
                [length]="{min: Restrictions.VERIFIED_CHANGE_CODE_LENGTH, max: Restrictions.VERIFIED_CHANGE_CODE_LENGTH}">
  <!--BE-CHECK-->
  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.VERIFIED_CHANGE_CODE_NOT_FOUND" i18n>
    The verified code doesn't exist.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.VERIFIED_CHANGE_CODE_INCORRECT" i18n>
    The verified code is not valid.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.VERIFIED_CHANGE_TIMEOUT"
                          [showAsBox]="true" styleClass="box-margin p-mt-3">
    <div class="p-d-inline" i18n>
      Sorry, but you have tried to verify your <b>phone number</b> multiple times, this number can be
      verified again in <b> {{ timeout | countdown:'dhms' | async }} </b>
    </div>
  </app-backend-validation>
</app-text-input>

<p class="phone-verification-text" i18n>A message with verification code has been sent to your phone {{ phone }}.
  Enter the code to continue.</p>

<!--Edit register form-->
<div class="p-col-12 p-px-0 p-py-0 p-d-flex p-jc-center">
  <app-button type="text"
              label="Edit the phone"
              i18n-label
              icon="fa-solid fa-pencil"
              [disabled]="saving"
              (clicked)="phoneVerificationResult.emit(PhoneVerificationResult.CANCEL)"></app-button>
</div>

<!--Code not received-->
<div class="p-col-12 p-px-0 p-py-0 p-d-flex p-jc-center">
  <app-button type="text"
              label="Resend the code"
              i18n-label
              icon="fa-solid fa-rotate-right"
              [disabled]="saving"
              @grow
              *ngIf="showResendCode && !isMessage(ServerMessage.VERIFIED_CHANGE_TIMEOUT)"
              (clicked)="initializePhoneVerification()"></app-button>
</div>
