import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {firstValueFrom, Observable} from 'rxjs'
import {
  GetLikeProfileReq,
  LikeProfileResp,
  LikeProfileService,
  UpdateLikeProfileReq
} from '../../../service/like/like-profile.service'
import {ApiComponent} from '../../abstract/api.component'
import {BriefProfileResp, ProfileResp} from '../../../service/profile.service'
import {CalendarItemNestedProfileResp} from 'src/app/service/calendar.service'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'
import {NgIf} from '@angular/common'
import {ButtonComponent} from '../button/button.component'
import {SkeletonModule} from 'primeng/skeleton'
import {CountPipe} from '../../../pipe/count.pipe'

@Component({
  selector: 'app-profile-like',
  templateUrl: './profile-like.component.html',
  styleUrls: ['./profile-like.component.scss'],
  imports: [
    NgIf,
    ButtonComponent,
    SkeletonModule,
    CountPipe
  ],
  standalone: true
})
export class ProfileLikeComponent extends ApiComponent implements OnInit, OnChanges {

  /**
   * The current logged profile.
   */
  @Input()
  currentProfile: ProfileResp

  /**
   * The profile that the like will be updated.
   */
  @Input()
  likeTo: BriefProfileResp | ProfileResp | CalendarItemNestedProfileResp

  /**
   * Represents the button layout type.
   * The default value is 'heart'.
   */
  @Input()
  buttonType: 'button' | 'heart' = 'heart'

  /**
   * Controls the visibility of the number of likes.
   */
  @Input()
  displayCount = false

  /**
   * Style classes that are applied to the wrapper div of this component
   */
  @Input()
  styleClass: string

  /**
   * Returns the number of likes of the {@link likeTo} profile.
   */
  @Output()
  likes = new EventEmitter<number>()

  /**
   * The like response object of the {@link likeTo} profile.
   */
  likeResp: LikeProfileResp

  /**
   * The visibility of the component.
   */
  visibility = true

  protected trans = {
    followed: $localize`Followed`,
    follow: $localize`Follow`,
  }

  constructor(
    private likeProfileService: LikeProfileService) {
    super()
  }

  ngOnInit(): void {
    this.loading = true
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ((changes.currentProfile?.currentValue || changes.likeTo?.currentValue) && this.currentProfile && this.likeTo) {
      // change the visibility
      this.visibility = hasFeatures(this.currentProfile.profileType, Feature.LIKE_PROFILE) && this.isNotUserProfile()

      // load the likeResp of the likeTo profile
      this.likeResp = await firstValueFrom(this.callGetLike())
      if (this.likeResp) {
        this.likes.emit(this.likeResp.count)
      }
      this.loading = false
    }
  }

  /**
   * Fires when a user clicked on the profile's 'Like' button.
   */
  async onLikeClicked(): Promise<void> {
    if (this.isNotUserProfile()) {
      const previousState = this.likeResp.liked
      this.likeResp.liked = !previousState
      this.likeResp.count += ((this.likeResp.liked) ? 1 : -1)
      const resp = await firstValueFrom(this.callUpdateLike(this.likeResp.liked))
      if (!(resp && this.noServerMessages())) {
        this.likeResp.liked = previousState
      }
    }
  }

  /**
   * Calls the server API to update the {@link data} profile's like from the {@link currentProfile}.
   *
   * @param likeProfile Update or delete the like record.
   */
  private callUpdateLike(likeProfile: boolean): Observable<boolean> {
    const req: UpdateLikeProfileReq = {
      from: this.currentProfile.profileId,
      to: this.likeTo.profileId,
      like: likeProfile
    }
    return this.unwrap(this.likeProfileService.callUpdateLikeProfile(req))
  }

  /**
   * Calls the server API to get the data whether the {@link currentProfile} liked the {@link data} profile.
   *
   * @private
   */
  private callGetLike(): Observable<LikeProfileResp> {
    const req: GetLikeProfileReq = {
      from: this.currentProfile.profileId,
      to: this.likeTo.profileId
    }
    return this.unwrap(this.likeProfileService.callGetLikeProfile(req))
  }

  /**
   * Determines whether the {@link currentProfile} is not the same as the {@link data} profile.
   */
  private isNotUserProfile(): boolean {
    return this.likeTo.profileId !== this.currentProfile.profileId
  }
}
