import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core'
import {FormBuilder, FormGroup} from '@angular/forms'
import {addDays, addHours, dateCut, dateJoin, daysDifference, minusDays} from '../../../../utils/date.utils'
import {dateMustBeAfter, dateTimeAfterDateTime} from '../../../../validator/custom.validators'
import {Restrictions} from '../../../../common/restrictions'
import {BookInformation, BookingService} from '../../../../service/ui/booking.service'
import {formDatesNotOverlaps, formTimesNotOverlaps} from '../../../../utils/form.utils'
import {Subscription} from 'rxjs'
import {AbstractComponent} from '../../../abstract/abstract.component'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {growAnimation} from '../../../../animation/grow.animation'

@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-profile-catalog-book-form',
  templateUrl: './profile-catalog-book-form.component.html',
  styleUrls: ['./profile-catalog-book-form.component.scss']
})
export class ProfileCatalogBookFormComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The value which is used to initialize the start date field.
   * Only date is updated without time.
   */
  @Input()
  initialStartDate?: Date
  /**
   * The value which is used to initialize the end date field.
   * Only date is updated without time.
   */
  @Input()
  initialEndDate?: Date
  /**
   * The joined start date from the booking form.
   */
  @Output()
  startDate = new EventEmitter<Date>()
  /**
   * The joined end date from the booking form.
   */
  @Output()
  endDate = new EventEmitter<Date>()
  /**
   * The date that is used to initialize the date fields in the form.
   * The same date is used for both start and end fields.
   */
  @Input()
  initializeDate?: Date
  /**
   * A form of all fields.
   */
  form: FormGroup
  /**
   * Disables the left action button in the start and end date fields.
   */
  disableDateMinusIcon = true

  /**
   * Tomorrow date without hours, minutes, and seconds. Used for restricting the calendar selection.
   */
  readonly tomorrowDate = dateCut(addDays(new Date(), 1), 'h')
  /**
   * Default start date option in popups.
   */
  defaultStart = dateCut(addHours(addDays(new Date(), 1), 1), 'm')
  /**
   * Default end date option in popups.
   */
  defaultEnd = dateCut(addHours(addDays(new Date(), 1), 2), 'm')
  /**
   * Contains the current selected start time. It is used to show additional error of selecting past-dates.
   */
  selectedStartTime: Date = this.defaultStart
  /**
   * The current date difference in days between start and end dates.
   */
  daysDifference: number
  /**
   * All form value changes subscribers that need to be unsubscribed in the ngOnDestroy.
   */
  private formSubs?: Subscription[] = []

  constructor(
    private formBuilder: FormBuilder,
    private bookingService: BookingService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update the form values when the initialStartDate or initialEndDate changes.
    // The value of initializedDate can be set later than the component initialization
    // because it's read from the query params
    if (changes.initialStartDate?.currentValue && changes.initialEndDate?.currentValue) {
      if (this.form) {
        this.form.patchValue({
          startDate: changes.initialStartDate.currentValue,
          endDate: changes.initialEndDate.currentValue
        })
      }
    }
  }

  ngOnInit(): void {
    this.initForm()
  }

  /**
   * - Initializes the {@link form}.
   * - Uses the {@link BookingService} to try to fill the stored values.
   */
  private initForm(): void {
    const bookInfo = this.bookingService.getBookDate()
    const sDate = new Date(bookInfo?.startDate || this.defaultStart)
    const eDate = new Date(bookInfo?.endDate || this.defaultEnd)
    const sTime = new Date(bookInfo?.startTime || this.defaultStart)
    const eTime = new Date(bookInfo?.endTime || this.defaultEnd)

    this.form = this.formBuilder.group({
      startDate: [this.initialStartDate ? this.initialStartDate : bookInfo?.startDate ? sDate : ''],
      endDate: [this.initialEndDate ? this.initialEndDate : bookInfo?.endDate ? eDate : ''],

      startTime: [dateJoin(sDate, sTime)],
      endTime: [dateJoin(eDate, eTime)]
    }, {
      validators: [
        dateMustBeAfter('startDate', 'endDate'),
        dateTimeAfterDateTime('startDate', 'endDate', 'startTime', 'endTime', Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH)
      ]
    })

    this.formHasChanged()

    this.formSubs.push(
      // value changes
      this.form.valueChanges.subscribe(this.formHasChanged.bind(this)),

      // Subscribe for value changes and ensure their correctness
      ...formDatesNotOverlaps(this.form, 'startDate', 'endDate'),
      ...formTimesNotOverlaps(this.form, 'startDate', 'endDate', 'startTime', 'endTime')
    )
  }

  /**
   * - Fires when the form has changed.
   * - Updates the localstorage using {@link BookingService} when the form is valid. Otherwise, it clears the stored values.
   * - Initializes the {@link daysDifference} when the form is valid, otherwise '0' is set.
   */
  private formHasChanged(): void {
    const data = this.form.value
    let bookInfo: BookInformation
    if (this.form.invalid) {
      bookInfo = {}
      this.startDate.emit(null)
      this.endDate.emit(null)
      this.selectedStartTime = this.defaultStart
      this.daysDifference = 0
    } else {
      // Cached value
      bookInfo = {
        startDate: data.startDate || null,
        endDate: data.endDate || null,
        startTime: data.startTime || null,
        endTime: data.endTime || null,
        expirationAt: addHours(new Date(), 1)
      }
      // Start Date
      if (data.startDate && data.startTime) {
        this.selectedStartTime = dateJoin(data.startDate, data.startTime)
        this.startDate.emit(this.selectedStartTime)
      }

      // End Date
      if (data.endDate && data.endTime) {
        this.endDate.emit(dateJoin(data.endDate, data.endTime))
      }

      if (data.startDate && data.startTime && data.endDate && data.endTime) {
        const start = dateJoin(data.startDate, data.startTime)
        const end = dateJoin(data.endDate, data.endTime)
        this.daysDifference = daysDifference(end, start)
      }
    }
    this.bookingService.updateBookDate(bookInfo)
  }

  /**
   * Fires when a user clicked on the 'startTime', or 'endTime' field.
   * - Fills up the field by the {@link defaultStart}, or {@link defaultEnd}.
   */
  clickOnTimeField(field: string): void {
    const data = this.form.value
    if (!data[field]) {
      this.form.controls[field].setValue(field.startsWith('start') ? this.defaultStart : this.defaultEnd)
    }
  }

  /**
   * Clears {@link form} controls' values.
   */
  resetFields(): void {
    this.form.controls.startTime.setValue(this.defaultStart)
    this.form.controls.startDate.setValue('')
    this.form.controls.endTime.setValue(this.defaultEnd)
    this.form.controls.endDate.setValue('')
    this.bookingService.updateBookDate({})

    this.form.controls.startDate.setErrors(null)
    this.form.controls.endDate.setErrors(null)
  }

  /**
   * Adds or reduces days from the {@link field}.
   */
  modifyDate(field: string, plus: boolean): void {
    // modify value
    const data = this.form.value
    const val = dateCut(data[field] || this.tomorrowDate, 'h')
    const modified = (plus) ? addDays(val, 1) : minusDays(val, 1)
    this.disableDateMinusIcon = modified < this.tomorrowDate
    if (!this.disableDateMinusIcon) {
      this.form.controls[field].setValue(modified)
      this.form.controls.endTime.markAsTouched()
      this.form.updateValueAndValidity()
    }
  }

  ngOnDestroy(): void {
    // unsubscribe all form value changes
    this.formSubs.forEach((it) => {
      it?.unsubscribe()
    })
  }
}
