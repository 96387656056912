import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core'
import {PLATFORM_BROWSER} from '../app.module'

@Directive({
  selector: '[appParallax]',
  standalone: true
})
export class ParallaxDirective implements OnInit {

  /**
   * Enables the parallax effect
   */
  @Input()
  parallaxEnable = true

  /**
   * Defines the parallax effect ratio.
   */
  @Input()
  ratio = 1

  initialTop = 0

  constructor(private eleRef: ElementRef) {
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    if (this.parallaxEnable) {
      this.eleRef.nativeElement.style.top = (this.initialTop - (window.scrollY * this.ratio)) + 'px'
    }
  }

  ngOnInit(): void {
    if (PLATFORM_BROWSER) {
      this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top
      this.onWindowScroll()
    }
  }
}
