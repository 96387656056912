import {Component, Input, OnInit} from '@angular/core'
import {MapProfileResp} from '../../../service/map.service'
import {ApiComponent} from '../../abstract/api.component'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {ProfileType} from '../../../common/profile-type'
import {NgIf} from '@angular/common'
import {CarouselModule} from 'primeng/carousel'
import {VarDirective} from '../../../directive/var.directive'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {RedirectProfileDirective} from '../../../directive/redirect-profile.directive'
import {ProfileIconComponent} from '../../common/profile-icon/profile-icon.component'
import {ProfileLikeComponent} from '../../common/profile-like/profile-like.component'
import {WallpaperPipe} from '../../../pipe/wallpaper.pipe'
import {BackgroundImagePipe} from '../../../pipe/background-image.pipe'
import {RatingComponent} from '../../common/rating/rating.component'
import {PricePipe} from '../../../pipe/price.pipe'
import {JoinPipe} from '../../../pipe/join.pipe'
import {CountPipe} from '../../../pipe/count.pipe'
import {TooltipModule} from 'primeng/tooltip'
import {ProfileOpeningHoursComponent} from '../../profile/profile-opening-hours/profile-opening-hours.component'

@Component({
  selector: 'app-profile-bubble',
  templateUrl: './profile-bubble.component.html',
  styleUrls: ['./profile-bubble.component.scss'],
  imports: [
    NgIf,
    CarouselModule,
    VarDirective,
    AvatarComponent,
    RedirectProfileDirective,
    ProfileIconComponent,
    ProfileLikeComponent,
    WallpaperPipe,
    BackgroundImagePipe,
    RatingComponent,
    PricePipe,
    JoinPipe,
    CountPipe,
    TooltipModule,
    ProfileOpeningHoursComponent
  ],
  standalone: true
})
export class ProfileBubbleComponent extends ApiComponent implements OnInit {

  /**
   * The profile data that is visible on a map.
   */
  @Input()
  data: MapProfileResp

  /**
   * Specifies whether the {@link data} is a type of event.
   */
  isEvent: boolean
  /**
   * Contains the {@link data}'s genres in a string array.
   */
  genres: string[] = []

  /**
   * The current logged profile.
   */
  currentProfile: ProfileResp

  constructor(
    private profileService: ProfileService) {
    super()
  }

  ngOnInit(): void {
    this.currentProfile = this.profileService.currentProfile.getValue()
    this.isEvent = this.data.profile.profileType === ProfileType.EVENT

    if (this.data.genres) {
      this.genres = this.data.genres.map(it => it.name) || []
    }
  }
}
