import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core'
import {LazyLoadEvent} from 'primeng/api/lazyloadevent'
import {ProfileType} from '../../../common/profile-type'
import {BriefProfileResp, ProfileResp, ProfileService, SearchProfilesReq} from '../../../service/profile.service'
import {ApiComponent} from '../../abstract/api.component'
import {firstValueFrom, Observable} from 'rxjs'
import {Page} from '../../../rest/page-resp'
import {FormsModule} from '@angular/forms'
import {InputTextModule} from 'primeng/inputtext'
import {BackendValidationComponent} from '../../common/backend-validation/backend-validation.component'
import {OverlayPanelModule} from 'primeng/overlaypanel'
import {NgForOf, NgIf} from '@angular/common'
import {DataViewModule} from 'primeng/dataview'
import {ProfileNamesComponent} from '../../common/profile-names/profile-names.component'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'

@Component({
  selector: 'app-profile-search-bar',
  templateUrl: './profile-search-bar.component.html',
  styleUrls: ['./profile-search-bar.component.scss'],
  imports: [
    FormsModule,
    InputTextModule,
    BackendValidationComponent,
    OverlayPanelModule,
    NgIf,
    DataViewModule,
    ProfileNamesComponent,
    AvatarComponent,
    NgForOf
  ],
  standalone: true
})
export class ProfileSearchBarComponent extends ApiComponent {

  @Input()
  data: ProfileResp

  /**
   * The search process is restricted to only these types.
   * An empty array means no restrictions.
   */
  @Input()
  types: ProfileType[]

  /**
   * If a user has clicked on some profile in the search result.
   */
  @Output()
  profile = new EventEmitter<BriefProfileResp>()

  /**
   * Search input field.
   */
  @ViewChild('searchField')
  searchField: ElementRef

  /**
   * The current searching value.
   */
  searchValue: string
  /**
   * The search result.
   */
  searchProfiles: BriefProfileResp[] = []
  /**
   * The current searching page.
   */
  searchPage: number
  /**
   * The current searching total elements.
   */
  totalElements: number
  /**
   * Currently running searching task of the {@link searchValue}.
   */
  searchTask: any
  /**
   * Defines whether the {@link searchTask} is running.
   */
  searching: boolean

  constructor(
    private profileService: ProfileService) {
    super()
  }

  /**
   * Fires when a user has changed the input string of the {@link searchField}.
   */
  onSearchInput(): void {
    const input = this.searchField.nativeElement.value.trim()

    if (input !== this.searchValue) { // only if new text is obtained
      this.searchValue = input
      this.searchPage = 0

      if (input.length >= 3) {
        this.startProfileSearch(input)
      } else {
        this.searchProfiles = []
      }
    }
  }

  /**
   * Fires when more records are needed to display of the current {@link searchValue}.
   *
   * @param lazy The lazy-load object.
   */
  onSearchLazy(lazy: LazyLoadEvent): void {
    if (lazy.first && this.searchValue.length >= 3 && this.searchProfiles.length !== this.totalElements) {
      this.searchPage++
      this.startProfileSearch(this.searchValue, this.searchPage)
    }
  }

  /**
   * Fires when a user clicked on the profile in the search result.
   */
  onProfileClick(profile: BriefProfileResp): void {
    this.profile.emit(profile)
  }

  /**
   * Calls the server API to search for direct channels.
   *
   * @param inputStr Input string from the {@link searchField}.
   * @param pageNum Pagination.
   */
  private async callSearchProfiles(inputStr: string, pageNum: number = 0): Promise<void> {
    this.resetApi()
    const page = await firstValueFrom(this.callSearchBriefProfiles(inputStr, pageNum))
    if (pageNum === 0) {
      this.searchProfiles = page.content
    } else {
      this.searchProfiles = [...this.searchProfiles, ...page.content]
    }
    this.searchPage = pageNum
    this.totalElements = page.totalElements
  }

  /**
   * Starts a searching task for the profiles.
   *
   * @param input Input string from the {@link searchField}.
   * @param page Pagination.
   * @private
   */
  private startProfileSearch(input: string, page: number = 0): void {
    // remove the previous set timeout
    if (this.searchTask) {
      clearTimeout(this.searchTask)
      this.searching = false
    }

    if (this.totalElements === 0 || this.searchProfiles.length !== this.totalElements) {
      this.searching = true
    }

    // add a new one
    this.searchTask = setTimeout(async () => {
      try {
        this.searching = true
        if (this.data) {
          await this.callSearchProfiles(input, page)
        }
      } finally {
        this.searching = false
      }
    }, 1000)
  }

  /**
   * Calls the server API to search for brief profiles based on the input string.
   *
   * @param inputStr The input string.
   * @param pageNum The number of a page of the input string. (lazy-loading)
   */
  private callSearchBriefProfiles(inputStr: string, pageNum: number = 0): Observable<Page<BriefProfileResp>> {
    const req: SearchProfilesReq = {
      input: inputStr,
      types: this.types,
      page: pageNum
    }
    return this.unwrap(this.profileService.callSearchBriefProfiles(req))
  }
}
