import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {ProfileType} from '../common/profile-type'
import {BaseResp} from '../rest/base-resp'
import {BriefProfileResp} from './profile.service'
import {ApiService} from './api.service'
import {LikeProfileResp} from './like/like-profile.service'
import {Page} from '../rest/page-resp'
import {CalendarItemResp} from './calendar.service'
import {Endpoint} from '../common/endpoints'
import {GenreRest} from './genre.service'
import {PriceItemResp} from './price-item.service'

@Injectable({
  providedIn: 'root'
})
export class MapService extends ApiService {

  constructor() {
    super()
  }

  callOSMSearch(input: string): Observable<OSMSearchResult[]> {
    const url = `https://nominatim.openstreetmap.org/search?q=${input}&limit=5&format=json&addressdetails=1`
    return this.http.get(url, {withCredentials: false}) as Observable<OSMSearchResult[]>
  }

  /**
   * Do not call this function too often since the API has limitations for the number of requests per minute!
   */
  callTimezoneSearch(lat: number, lng: number): Observable<TimezoneResult> {
    const url = `https://api.wheretheiss.at/v1/coordinates/${lat},${lng}`
    return this.http.get(url, {withCredentials: false}) as Observable<TimezoneResult>
  }

  callExploreMapBounds(req: ExploreMapBoundsReq): Observable<BaseResp<BriefMapProfileResp[]>> {
    return this.post(Endpoint.MAP_EXPLORE_BOUNDS_URL, req)
  }

  callExploreMapBoundsFilter(req: ExploreMapBoundsFilterReq): Observable<BaseResp<BriefMapProfileResp[]>> {
    return this.post(Endpoint.MAP_EXPLORE_BOUNDS_FILTER_URL, req)
  }

  callGetProfileMapDetails(req: MapProfileReq): Observable<BaseResp<MapProfileResp>> {
    return this.post(Endpoint.MAP_GET_PROFILE_DETAILS, req)
  }

}

export interface OSMSearchResult {
  place_id: number
  licence: string
  osm_type: string
  osm_id: number
  boundingbox: string[]
  lat: string
  lon: string
  display_name: string
  class: string
  type: string
  importance: number
  icon: string
  address: {
    town: string
    city: string
    state: string
    region: string
    postcode: string
    country: string
    country_code: string
  }
}

export interface TimezoneResult {
  timezone_id: string
  country_code: string
}

export interface ExploreMapBoundsReq {
  bounds: MapBounds
  types: ProfileType[]
  date: Date
}

export interface MapBounds {
  NELat: number
  NELng: number
  SWLat: number
  SWLng: number
}

export interface BriefMapProfileResp {
  profile: BriefProfileResp
  isNowOpen: boolean
  isLive: boolean
  hasEvents: boolean
}

export interface MapProfileReq {
  profileId: number
  date: Date
}

export interface MapProfileResp {
  profile: BriefProfileResp

  likes: LikeProfileResp
  rating: number
  genres: GenreRest[]
  price?: PriceItemResp
  bio?: string
  wallpapers?: string[]
  events: Page<CalendarItemResp>
}

export interface ExploreMapBoundsFilterReq {
  start?: Date
  end?: Date

  priceStart?: number
  priceEnd?: number

  bounds: MapBounds
  date: Date
  genres?: number[]
}
