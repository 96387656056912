<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            header="Edit your profile photo"
            i18n-header
            successMessage="Profile photo has been saved"
            i18n-successMessage
            (err)="console.error($event)"
            (discard)="closeEditComponent()"
            [save]="onComponentSave.bind(this)"
            [disableSaveOption]="loading || saving || disconnected">

  <!--Preview with upload overlay -->
  <label class="hoverable p-mr-auto p-ml-auto" for="fileInput">
    <div *ngIf="!disconnected" @grow>

      <!--Image-->
      <app-img [src]="previewImage || defaultAvatar"
               [loading]="loading"
               alt="{{data?.charId}} avatar profile"
               i18n-alt
               styleClass="box-shadow preview p-absolute">
        <p-skeleton shape="circle" size="200px"></p-skeleton>
      </app-img>

      <!--Overlay-->
      <div *ngIf="!loading">
        <div class="hover-text" i18n>Upload</div>
        <div class="background"></div>
      </div>
    </div>
  </label>
  <br/>

  <!--Avatar Preview Image-->
  <input id="fileInput"
         type="file"
         class="p-d-none"
         [disabled]="saving || loading || disconnected"
         (change)="onFileSelect($event.target['files'][0])"
         accept="image/png, image/jpeg"
         #fileInput>

  <!--Image Buttons-->
  <div class="p-d-flex p-jc-center p-ai-center">
    <!--Upload-->
    <app-button type="secondary"
                icon="fa-solid fa-upload"
                pTooltip="Browse"
                i18n-pTooltip
                tooltipPosition="top"
                [disabled]="saving || loading || disconnected"
                (clicked)="fileInput.click()"></app-button>

    <!--Remove-->
    <app-button type="danger-light"
                styleClass="p-ml-2"
                icon="fa-solid fa-trash"
                pTooltip="Remove"
                i18n-pTooltip
                tooltipPosition="top"
                *ngIf="cropperImage || cropperUrl"
                [disabled]="saving || loading || disconnected"
                (clicked)="removeImage()"></app-button>
  </div>

  <!--Hint-->
  <app-hint *ngIf="cropperImage || cropperUrl" styleClass="p-my-4" i18n>
    Specify your visible part of a photo as you wish.
  </app-hint>

  <!--Cropper-->
  <div class="p-d-flex p-jc-center">
    <div class="cropper p-relative">
      <image-cropper [imageFile]="cropperImage"
                     [imageURL]="cropperUrl"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="1"
                     [cropperMinWidth]="300"
                     [cropperMinHeight]="300"
                     [cropperMaxHeight]="1200"
                     [disabled]="saving"
                     output="base64"
                     format="jpeg"
                     [backgroundColor]="'#ffffff'"
                     (imageCropped)="imageCropped($event)"
                     (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </div>

  <!--BE check-->
  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_LOAD_FAILED"
                          [showAsBox]="true" i18n>
    Your image couldn't be loaded. Try a different image.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_NOT_FOUND" [showAsBox]="true"
                          i18n>
    You are editing an image that doesn't exist. Please upload a new one.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_SIZE_TOO_SMALL"
                          [showAsBox]="true">
    Your image needs to be at least {{ Restrictions.MIN_IMAGE_AVATAR_SIZE }}
    x {{ Restrictions.MIN_IMAGE_AVATAR_SIZE }} pixels.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.FILE_FORMAT_NOT_SUPPORTED"
                          [showAsBox]="true" i18n>
    Please upload an image file. ['.<b>jpg</b>', or '.<b>png</b>']
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_UPLOAD_FAILED"
                          [showAsBox]="true" i18n>
    Your image was not uploaded.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND"
                          [showAsBox]="true" i18n>
    Your profile does not exists.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                          [showAsBox]="true" i18n>
    You do not have a permission to this action!
  </app-backend-validation>
</app-dialog>
