import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'

/**
 * Emits the HTML content when the element's content has changed.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[contentChange]',
  standalone: true
})
export class ChangeDirective implements OnInit, OnDestroy {

  @Output()
  contentChange = new EventEmitter<ElementRef>()

  private previousContent = ''

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit(): void {
    this.eleRef.nativeElement.addEventListener('keyup', this.onKeyUp.bind(this))
  }

  /**
   * Fires when a user pressed any key on his keyboard.
   */
  private onKeyUp(): void {
    const content = this.eleRef.nativeElement.innerHTML
    if (content.length !== this.previousContent.length) {
      this.contentChange.emit(content)
    }
    this.previousContent = content
  }

  ngOnDestroy(): void {
    this.eleRef.nativeElement.removeAllListeners()
  }
}
