import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {ProfileResp} from '../../../service/profile.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ProfileShowcaseService, UpdateShowcaseReq} from '../../../service/showcase.service'
import {pattern, soundcloudRegex, spotifyRegex, youtubeRegex} from '../../../validator/custom.validators'
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import {environment} from '../../../../environments/environment'
import {CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'

@Component({
  selector: 'app-profile-showcase',
  templateUrl: './profile-showcase.component.html',
  styleUrls: ['./profile-showcase.component.scss']
})
export class ProfileShowcaseComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly env = environment

  @Input()
  data: ProfileResp

  /**
   * The form which includes the {@link youtubeForm}, {@link soundcloudForm}, {@link spotifyForm} values.
   */
  form: FormGroup

  youtubeUrlSafe: SafeResourceUrl
  soundcloudUrlSafe: SafeResourceUrl
  spotifyUrlSafe: SafeResourceUrl

  /**
   * The YouTube value visible in form.
   */
  private youtubeForm: string
  /**
   * The Spotify value visible in form.
   */
  private spotifyForm: string
  /**
   * The Soundcloud value visible in form.
   */
  private soundcloudForm: string

  /**
   * Decides whether the value of {@link form} is changed.
   */
  private valueChanges: boolean
  /**
   * A subscription for the {@link ProfileCompletionService} show requests.
   */
  private showSub: Subscription

  constructor(
    private completionService: ProfileCompletionService,
    public changeRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private showcaseService: ProfileShowcaseService,
    public sanitizer: DomSanitizer) {
    super()
  }

  ngOnInit(): void {
    this.initFormLinks()
    this.createUrls()

    // completion show observer
    this.showSub = this.completionService.completionTypeShow.subscribe((type) => {
      if (type === CompletionType.SHOWCASE) {
        this.showEditComponent()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.ngOnInit()
      this.changeRef.detectChanges()
    }
  }

  async save(): Promise<void> {
    const formData = this.form.value
    const resp = await firstValueFrom(this.callUpdateProfileEmbedLinks(formData))
    if (resp && this.noServerMessages()) {
      this.completionService.closeCompletionItemAndCheck()
    }
  }

  /**
   * Initializes the form fields.
   */
  private initEditDialogForm(): void {
    this.form = this.formBuilder.group({
        youtube: [this.youtubeForm || '', [
          pattern(youtubeRegex)
        ]],
        soundcloud: [this.soundcloudForm || '', [
          pattern(soundcloudRegex)
        ]],
        spotify: [this.spotifyForm || '', [
          pattern(spotifyRegex)
        ]]
      }
    )

    this.form.valueChanges.subscribe(() => {
      this.valueChanges = true
      this.serverMessages = []
    })
  }

  /**
   * Initializes form and shows edit dialog
   */
  showEditComponent(): void {
    this.initEditDialogForm()
    super.showEditComponent()
    this.changeRef.detectChanges()
  }

  /**
   * Creates iframe links from given YouTube, Spotify, and Soundcloud links.
   */
  private createUrls(): void {
    this.youtubeUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.data.showcase?.youtube}/`)
    this.spotifyUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://open.spotify.com/embed/${this.data.showcase?.spotify}/`)
    this.soundcloudUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.showcase?.soundcloud)
  }


  /**
   * Initializes the {@link youtubeForm}, {@link spotifyForm}, and {@link soundcloudForm} in the format in which the user saved them.
   */
  private initFormLinks(): void {
    this.youtubeForm = (!this.data.showcase?.youtube) ? '' : `https://www.youtube.com/watch?v=${this.data.showcase.youtube}`
    this.spotifyForm = (!this.data.showcase?.spotify) ? '' : `https://open.spotify.com/${this.data.showcase.spotify}`
    this.soundcloudForm = (!this.data.showcase?.soundcloud) ? '' : `<iframe width="100%" src="${this.data.showcase.soundcloud}" </iframe>`
  }

  /**
   * Calls the server API to update profile showcase embed links.
   */
  private callUpdateProfileEmbedLinks(formData): Observable<boolean> {
    const req: UpdateShowcaseReq = {
      youtube: formData.youtube || null,
      spotify: formData.spotify || null,
      soundcloud: formData.soundcloud || null
    }
    return this.unwrap(this.showcaseService.callUpdateProfileEmbedLink(req))
  }

  /**
   * Returns true if any link is not null or empty.
   */
  isAnyLinkPresent(): boolean {
    return (!!this.youtubeForm || !!this.spotifyForm || !!this.soundcloudForm)
  }

  /**
   * Reload whole page after successfully saved changes.
   */
  reloadPage(): void {
    if (this.valueChanges) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  ngOnDestroy(): void {
    this.showSub?.unsubscribe()
  }
}
