import {ApiService} from './api.service'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'

@Injectable({
  providedIn: 'root'
})
export class CityService extends ApiService {

  /**
   * Finds a city by its url slug.
   */
  findBySlug(slug: string): Observable<BaseResp<CityResp>> {
    return this.post(Endpoint.CITY_FIND_BY_SLUG, slug, super.getHeaders(), false)
  }
}

/**
 * Represents data about the city.
 */
export interface CityResp {
  name: string
  lat: number
  lng: number
}
