<div class="p-relative">
  <!--Image-->
  <div *ngIf="src && imgLoaded && !loading; else skeleton" [@fade]="animations" class="p-relative">
    <img *ngIf="!background; else backgroundImage"
         [src]="src"
         [alt]="alt"
         class="{{styleClass}}"
         [style.z-index]="baseZIndex">
  </div>

  <!--Background image-->
  <ng-template #backgroundImage>
    <div [class]="styleClass"
         [style.z-index]="baseZIndex"
         [style]="src | backgroundImage"></div>
  </ng-template>

  <!--Skeleton-->
  <ng-template #skeleton>
    <div class="p-absolute" [style.z-index]="baseZIndex + 2" [@fade]="animations">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>
