/**
 * Defines the image variants. Each variant must contain the width and height.
 */
export const ImageVariant = {
  AVATAR_300: {width: 300, fileName: '300'},
  AVATAR_130: {width: 130, fileName: '130'},
  AVATAR_50: {width: 50, fileName: '50'},
  EVENT_AVATAR: {width: 130, fileName: '130'},
  AVATAR_COMPRESS_SIZE: {width: 1920, height: 1080},

  WALLPAPER_500: {width: 500, fileName: '500'},
  WALLPAPER_800: {width: 800, fileName: '800'},
  WALLPAPER_1024: {width: 1024, fileName: '1024'},
  WALLPAPER_1920: {width: 1920, fileName: '1920'},
  WALLPAPER_COMPRESS_SIZE: {width: 1920, height: 1080},
  WALLPAPER_EVENT: {width: 1080, height: 1080, fileName: '1080'},

  IMAGE_SHOWCASE_500: {width: 500, fileName: '500'},
  IMAGE_SHOWCASE_800: {width: 800, fileName: '800'},
  IMAGE_SHOWCASE_1024: {width: 1024, fileName: '1024'},
  IMAGE_SHOWCASE_1920: {width: 1920, fileName: '1920'},
  IMAGE_SHOWCASE_COMPRESS_SIZE: {width: 1080, height: 1080},

  SEO_PAGE_1920: {width: 1920, fileName: '1920'},
  SEO_PAGE_1200: {width: 1200, fileName: '1200'},
  SEO_PAGE_800: {width: 800, fileName: '800'},
  SEO_PAGE_500: {width: 500, fileName: '500'},

  PROFESSION_1080: {width: 1080, fileName: '1080'},
  PROFESSION_800: {width: 800, fileName: '800'},
  PROFESSION_400: {width: 400, fileName: '400'},
}
