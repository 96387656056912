import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core'
import {AbstractFormField} from '../abstract-form-field'
import {PrimeTemplate} from 'primeng/api'
import {environment} from 'src/environments/environment'
import {Dropdown, DropdownModule} from 'primeng/dropdown'
import {email, phone, url} from '../../../../validator/custom.validators'
import {growAnimation} from '../../../../animation/grow.animation'
import {ReactiveFormsModule} from '@angular/forms'
import {VarDirective} from '../../../../directive/var.directive'
import {NgIf, NgTemplateOutlet} from '@angular/common'
import {TooltipModule} from 'primeng/tooltip'
import {FrontendValidationComponent} from '../../frontend-validation/frontend-validation.component'

@Component({
  animations: [growAnimation()],
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  imports: [
    ReactiveFormsModule,
    VarDirective,
    NgIf,
    TooltipModule,
    DropdownModule,
    NgTemplateOutlet,
    FrontendValidationComponent
  ],
  standalone: true
})
export class DropdownComponent extends AbstractFormField implements OnInit, AfterViewInit {

  /**
   * The source of options.
   */
  @Input()
  options: any[] = []
  /**
   * - The filter by property of the item in the {@link options}.
   * - Example: `[{name:string, something:number},..]`, the `filterBy="something"` will be sort the array by the 'something' key.
   */
  @Input()
  filterBy: string
  /**
   * Fires when the user changed a value.
   */
  @Output()
  changed = new EventEmitter<any>()
  /**
   * Defines the base z-index.
   */
  @Input()
  baseZIndex = 1
  /**
   * Enables to type into dropdown.
   */
    //@Input()
  editable: boolean
  /**
   * Requires the specified length of the text filled in the input field.
   */
  @Input()
  length?: { min?: number; max?: number }
  /**
   * Adds several validators to protect the input field of having the email, url, and phone present.
   */
  @Input()
  notContact: boolean
  /**
   * The dropdown PrimeNG component.
   */
  @ViewChild('dropdown')
  dropdown: Dropdown
  /**
   * The dropdown component to access the inner elements of the {@link dropdown}.
   */
  @ViewChild('dropdownContainer')
  dropdownContainer: ElementRef
  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>
  /**
   * The primary item template.
   */
  itemTemplate?: TemplateRef<any>
  /**
   * The selected item template.
   */
  selectedTemplate?: TemplateRef<any>
  /**
   * Enables the touch UI layout.
   * It is automatically enabled if the screen width is less than LG.
   */
  @Input()
  touchUI: boolean = null
  /**
   * The current state of the dropdown menu.
   */
  dropdownOpened: boolean
  /**
   * Disables the default 'length' error message because the user has his own implementation.
   */
  disableLength: boolean
  /**
   * Disables the default 'notEmail' error message because the user has his own implementation.
   */
  disableNotEmail: boolean
  /**
   * Disables the default 'notPhone' error message because the user has his own implementation.
   */
  disableNotPhone: boolean
  /**
   * Disables the default 'notUrl' error message because the user has his own implementation.
   */
  disableNotUrl: boolean

  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit()
    if (this.rightIcon && !environment.production) {
      console.warn($localize`The [rightIcon] is not implemented in the DropdownComponent!`)
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this.initTemplates()

    if (this.editable) {
      this.initEditableDropdown()
    }

    // triggers the initial validation when the value is present
    if (this.form.value[this.formFieldName]) {
      setTimeout(() => {
        this.form.controls[this.formFieldName].markAsTouched()
        this.form.controls[this.formFieldName].markAsDirty()
        this.triggerValidations()
        this.changed.emit({})
      }, 100) // give some time to initialize the form value
    }
  }

  override doFocus(): void {
    this.dropdown?.focus()
  }

  /**
   * Shows the dropdown panel if the text is empty.
   */
  onClick(): void {
    if (!this.form.value[this.formFieldName]?.toString()?.trim() && this.editable) {
      this.dropdown?.show()
    }
  }

  /**
   * Fires when the input value has changed.
   */
  onChange(event): void {
    this.triggerValidations()
    this.changed.emit(event)
    if (this.editable) {
      this.dropdown.filterValue = this.form.value[this.formFieldName]?.toString()?.trim() || ''
    }
  }

  /**
   * Disables the default messages of the validation errors based on the {@link frontendValidations}.
   */
  protected override disableDefaultValidators(): void {
    super.disableDefaultValidators()

    for (const valid of this.frontendValidations) {
      switch (valid.error) {
        case 'length':
          this.disableLength = true
          break
        case 'notEmail':
          this.disableNotEmail = true
          break
        case 'notPhone':
          this.disableNotPhone = true
          break
        case 'notUrl':
          this.disableNotUrl = true
          break
      }
    }
  }

  /**
   * Manually triggers validators.
   */
  private triggerValidations(): void {
    const control = this.form.controls[this.formFieldName]
    const errors = control.errors || {}

    // return when no value is present
    if (!control.value) {
      return
    }

    if (this.notContact) {
      if (email(true)(control)) {
        errors['notEmail'] = true
      }
      if (url(true)(control)) {
        errors['notUrl'] = true
      }
      if (phone(true)(control)) {
        errors['notPhone'] = true
      }

      if (Object.keys(errors).length !== 0) {
        this.form.controls[this.formFieldName].setErrors(errors)
      } else {
        this.form.controls[this.formFieldName].setErrors(null)
      }
    }
  }


  /**
   * Initializes all templates.
   */
  private initTemplates(): void {
    setTimeout(() => {
      for (const pTemplate of this.templates) {
        switch (pTemplate.name) {
          case 'item':
            this.itemTemplate = pTemplate.template
            break
          case 'selectedItem':
            this.selectedTemplate = pTemplate.template
            break
        }
      }
    })
  }

  /**
   * Initializes the editable dropdown listeners and event actions.
   */
  private initEditableDropdown(): void {
    const input = this.dropdownContainer.nativeElement.querySelector('.p-inputtext')
    input.addEventListener('keyup', (e) => {
      const options = this.dropdown.options

        // select first option
        if (e.key === 'Enter') {
          const firstOption = options[0]
          if (firstOption) {
            input.value = firstOption[this.filterBy] // TODO verify
            this.form.controls[this.formFieldName].setValue(input.value)
            this.dropdown.hide()
          }

          // show dropdown if records are presents
        } else if (options.length > 0) {
          this.dropdown.show()
        }

        // hide dropdown if no records have been found
        if (options.length === 0 && input.value) {
          this.dropdown.hide()
        }
      }
    )
  }
}
