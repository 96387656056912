import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {NavbarService} from '../../../service/ui/navbar.service'
import {ProfileResp} from '../../../service/profile.service'
import {getFeature, ProfileType} from '../../../common/profile-type'
import {ImageVariant} from '../../../common/image-variant'
import {defaultAvatar} from '../../../utils/asset.utils'
import {deviceScreenMatchesWidth, getDeviceMatchWidthWallpaper, ScreenSize} from '../../../utils/device.utils'
import {Feature} from '../../../common/feature'
import {EditableComponent} from '../../abstract/editable.component'
import {randomHash} from '../../../utils/string.utils'
import {fadeAnimation} from '../../../animation/fade.animation'
import {PLATFORM_BROWSER} from '../../../app.module'
import {Subscription} from 'rxjs'
import {CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'
import {GalleriaImage} from '../../common/galleria/galleria.component'

@Component({
  animations: [fadeAnimation(200)],
  selector: 'app-profile-banner',
  templateUrl: './profile-banner.component.html',
  styleUrls: ['./profile-banner.component.scss']
})
export class ProfileBannerComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  data: ProfileResp

  /**
   * The profile wallpapers urls.
   */
  wallpapers: string[] = []
  /**
   * The profile avatar url.
   */
  avatar: string

  /**
   * The profile type initialized from the {@link data}.
   */
  profileType: ProfileType

  /**
   * Wallpaper parallax effect ratio.
   */
  parallaxRatioWallpaper = 0.5

  /**
   * The maximum allowed wallpapers of the {@link data} profile.
   */
  maxWallpapers = 0

  /**
   * Represents the visibility of the {@link UploadAvatarComponent}.
   */
  editAvatarComponentVisible: boolean

  /**
   * Represents the visibility of the {@link UploadWallpaperComponent}.
   */
  editWallpaperComponentVisible: boolean

  /**
   * Defines whether the wallpaper can be large.
   */
  largeWallpaper: boolean
  /**
   * Defines whether the wallpaper's image is fully visible and unfilled parts are blurred.
   */
  blurWallpaper: boolean
  galleriaImages: GalleriaImage[] = []
  wallpaperActiveIndex: number = 0
  galleriaActiveIndex: number = 0
  fullscreenGalleriaVisible: boolean
  fullscreenAvatarVisible: boolean
  /**
   * Subscription related for showing this dialog.
   * Emitted from {@link ProfileCompletionComponent}.
   */
  private showSub: Subscription

  constructor(
    private navbarService: NavbarService,
    private completionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    // Navbar transparency
    this.navbarService.setNavbarTransparent(true)
    this.navbarService.listenScrollEvent(true, this.getTopBorder())
    this.initShowSub()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.profileType = this.data.profileType
      this.initProperties()
      this.initWallpapers()
      this.initAvatar()

      this.ngOnInit()
    }
  }

  /**
   * Initializes the component properties.
   */
  private initProperties(): void {
    const feature = getFeature(this.data.profileType, Feature.WALLPAPER)
    this.maxWallpapers = feature?.max || 0
    this.largeWallpaper = feature !== undefined
    //this.blurWallpaper = this.data.profileType === ProfileType.EVENT
  }

  /**
   * Fires when a user has changed his wallpaper.
   *
   * @param wallpapers The modified wallpapers.
   */
  onUploadWallpaper(wallpapers: string[]): void {
    this.data.wallpapers = wallpapers || []
    this.initWallpapers()
    this.completionService.closeCompletionItemAndCheck()
  }

  /**
   * Fires when a user has changed his avatar.
   *
   * @param changed Determines if the changes were saved or removed.
   */
  onUploadAvatar(changed?: string): void {
    this.data.avatar = changed
    this.initAvatar(true)
    this.completionService.closeCompletionItemAndCheck()
  }

  /**
   * Tries to open the fullscreen galleria. The {@link avatar} determines whether the avatar should be opened or wallpapers.
   */
  openGalleria(avatar: boolean): void {
    if (avatar) {
      if (this.data.avatar) {
        this.galleriaImages = [{url: this.avatar}]
        this.galleriaActiveIndex = 0
      }
    } else { // wallpapers
      if (this.data.wallpapers?.length > 0) {
        this.galleriaImages = this.wallpapers.map(it => {
          return {url: it}
        })
        this.galleriaActiveIndex = this.wallpaperActiveIndex
      }
    }
    // try open
    if (this.galleriaImages?.length > 0) {
      this.fullscreenGalleriaVisible = true
    }
  }

  /**
   * Initializes the wallpaper images.
   */
  private initWallpapers(): void {
    if (PLATFORM_BROWSER) {
      const deviceVariant = getDeviceMatchWidthWallpaper()
      this.wallpapers = this.data.wallpapers?.map(w => `${w}/${deviceVariant}`)
    }
  }

  /**
   * - Initializes the avatar image.
   * - The {@link forceLoad} appends a random hash to the url to force the browser load the image again.
   */
  private initAvatar(forceLoad: boolean = false): void {
    const avatarUrl = this.data.avatar

    if (avatarUrl) {
      const url = `${avatarUrl}/${ImageVariant.AVATAR_300.width}`
      const hash = randomHash(4)
      this.avatar = (forceLoad) ? `${url}?v=${hash}` : url
    } else {
      this.avatar = defaultAvatar(this.profileType)
    }
    this.data.avatar = this.avatar

    // Get back the old url without an image variant
    setTimeout(() => {
      this.data.avatar = avatarUrl
    }, 250)
  }

  /**
   * Returns the top border where the navigation bar should toggle its transparency.
   */
  private getTopBorder(): number {
    const mediumSize = deviceScreenMatchesWidth(ScreenSize.MD)
    const feature = getFeature(this.profileType, Feature.WALLPAPER)

    let size
    if (mediumSize) {
      size = (feature) ? 560 : 400
    } else {
      size = (feature) ? 400 : 240
    }
    return size
  }

  /**
   * Initializes {@link showSub} based on value from {@link ProfileCompletionService.completionTypeShow}.
   */
  private initShowSub(): void {
    this.showSub = this.completionService.completionTypeShow
      .subscribe(type => {
        if (type) {
          this.editAvatarComponentVisible = type === CompletionType.AVATAR
          this.editWallpaperComponentVisible = type === CompletionType.WALLPAPER
        }
      })
  }

  ngOnDestroy(): void {
    // Navbar transparency
    this.navbarService.listenScrollEvent(false)
    this.navbarService.setNavbarTransparent(false)

    this.showSub?.unsubscribe()
  }
}
