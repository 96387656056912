import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'

@Injectable({
  providedIn: 'root'
})
export class QrScannerService extends ApiService {

  constructor() {
    super()
  }

  callGetOrderByQR(order: string): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.QR_SCANNER_FIND_ORDER, order)
  }

  callGenerateQRCode(orderId: number): Observable<BaseResp<string>> {
    return this.post(Endpoint.QR_CODE_GENERATE, orderId)
  }
}

export interface ProfileOrderQRResp {
  id: number
  orderHash: string
}
