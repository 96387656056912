import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {BehaviorSubject, Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {TwoFactorResp, UserResp} from './user.service'
import {AngularFireAuth} from '@angular/fire/compat/auth'
import * as auth from 'firebase/auth'
import {AuthTypeEnum} from '../common/auth-type-enum'
import {ProfileType} from '../common/profile-type'
import {UserResponseType} from '../common/user-response-type'

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService extends ApiService {

  /**
   * When authenticating with Firebase as new User, request to register from server is pushed to this BehaviorSubject.
   * {@link RegisterFirebaseComponent} is then initialized, from latest value.
   */
  socialRegisterUser = new BehaviorSubject<FirebaseRegisterReq | undefined>(undefined)

  constructor(private fireAuth: AngularFireAuth) {
    super()
  }

  /**
   * Makes a call of {@link FirebaseAuthReq} on the backend API.
   */
  callLoginFirebase(req: FirebaseAuthReq): Observable<BaseResp<UserResp | TwoFactorResp | FirebaseRegisterReq>> {
    return this.post(Endpoint.FIREBASE_AUTH_URL, req, this.deviceHeaders, false)
  }

  /**
   * Makes a call of {@link FirebaseRegisterReq} on the backend API.
   */
  callRegisterFirebase(req: FirebaseRegisterReq): Observable<BaseResp<UserResp>> {
    return this.post(Endpoint.FIREBASE_REGISTER_URL, req, this.deviceHeaders, false)
  }

  /**
   * Pushes new value to the {@link socialRegisterUser}.
   */
  pushRegistrationReq(registerReq?: FirebaseRegisterReq): void {
    this.socialRegisterUser.next(registerReq)
  }

  /**
   * Opens popup window to authenticate with Google via Firebase.
   * @returns UserCredentials object from Firebase library.
   */
  googleAuth(): Promise<any> {
    return this.authLogin(new auth.GoogleAuthProvider())
  }

  /**
   * Opens popup window to authenticate with Facebook via Firebase.
   * @returns UserCredentials object from Firebase library.
   */
  facebookAuth(): Promise<any> {
    return this.authLogin(new auth.FacebookAuthProvider())
  }

  /**
   * Is used to log-out user from Firebase.
   * Firebase is used only to retrieve idToken.
   * When that idToken was used, there is no need to have user data IndexedDb.
   */
  authLogout(): Promise<void> {
    return this.fireAuth.signOut()
  }

  /**
   * @param provider Which provider pop-up should be opened.
   */
  private authLogin(provider: auth.AuthProvider): Promise<any> {
    return this.fireAuth.signInWithPopup(provider)
  }
}

/**
 * An interface that defines the structure of the Firebase authentication call accepted by the server.
 */
export interface FirebaseAuthReq {
  idToken: string
  phone: string
  type: AuthTypeEnum
}

/**
 * Interface that defines structure of the register call with Firebase authentication, accepted by the server.
 */
export interface FirebaseRegisterReq {
  displayName: string
  email: string
  phone: string
  profileType: ProfileType
  authType: AuthTypeEnum
  firebaseUid: string
  type?: UserResponseType
}
