import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Page} from '../rest/page-resp'
import {Endpoint} from '../common/endpoints'
import {ApiService} from './api.service'
import {BriefProfileResp} from './profile.service'
import {NotificationType} from '../common/notification-type'

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService {

  constructor() {
    super()
  }

  /**
   * Get all notifications for the current user from the server as a page.
   */
  callGetAllNotifications(req: AllNotificationsReq): Observable<BaseResp<Page<InboxNotificationResp>>> {
    return this.post(Endpoint.NOTIFICATION_GET_ALL, req)
  }

  /**
   * Update the seen status of a single notification.
   */
  callUpdateNotificationSeenStatus(req: NotificationStatusUpdateReq): Observable<BaseResp<InboxNotificationResp>> {
    return this.post(Endpoint.NOTIFICATION_UPDATE_SEEN_STATUS, req)
  }

  /**
   * Update the seen status of all notifications and mark them as seen.
   */
  callMarkAllAsSeen(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.NOTIFICATION_MARK_ALL_SEEN, {})
  }

  /**
   * Delete a single notification.
   */
  callDeleteNotification(req: NotificationDeleteReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.NOTIFICATION_DELETE, req)
  }
}

export interface AllNotificationsReq {
  page: number
}

export interface NotificationStatusUpdateReq {
  id: number
  seen: boolean
}

export interface NotificationDeleteReq {
  id: number
}

export interface InboxNotificationResp {
  id: number
  type: NotificationType
  seen: boolean
  title: string
  body: string
  icon?: string
  url?: string
  sourceProfile?: BriefProfileResp
}
