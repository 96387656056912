import {Pipe, PipeTransform} from '@angular/core'
import {ChannelResp} from '../service/chat-channel.service'
import {BriefProfileResp, ProfileResp} from '../service/profile.service'

/**
 * Returns first channel participant's profile that is not the current user one.
 */
@Pipe({
  name: 'firstProfile',
  standalone: true
})
export class FirstChannelProfilePipe implements PipeTransform {
  transform(channel: ChannelResp, excludeProfile: BriefProfileResp | ProfileResp): BriefProfileResp | null {
    return getDirectChannelAddressee(channel, excludeProfile)
  }
}

/**
 * Returns first channel participant's profile that is not the current user one.
 *
 * @param channel The channel to be searched in.
 * @param sender The current logged profile.
 */
export function getDirectChannelAddressee(channel: ChannelResp, sender: BriefProfileResp | ProfileResp): BriefProfileResp | null {
  if (!channel) {
    return null
  }

  if (!sender) {
    return channel.participants[0].profile
  }

  for (const participant of channel.participants) {
    if (participant.profile.profileId !== sender.profileId) {
      return participant.profile
    }
  }
}
