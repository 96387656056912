//@formatter:off
import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {ProfileManagerItemComponent} from './profile-manager/profile-manager-item/profile-manager-item.component'
import {ContractPreviewComponent} from './profile-manager/profile-manager-item-detail/contract-preview/contract-preview.component'
import {ProfileManagerItemDetailComponent} from './profile-manager/profile-manager-item-detail/profile-manager-item-detail.component'
import {ProfileManagerComponent} from './profile-manager/profile-manager.component'
import {CountdownPipe} from '../../pipe/countdown-timer.pipe'
import {AvatarComponent} from '../../component/common/avatar/avatar/avatar.component'
import {VarDirective} from '../../directive/var.directive'
import {ProfileProfessionsComponent} from '../../component/profile/profile-professions/profile-professions.component'
import {CountryPipe} from '../../pipe/country.pipe'
import {PricePipe} from '../../pipe/price.pipe'
import {DialogComponent} from '../../component/common/dialog/dialog.component'
import {HintComponent} from '../../component/common/hint/hint.component'
import {ButtonComponent} from '../../component/common/button/button.component'
import {TooltipModule} from 'primeng/tooltip'
import {TabMenuModule} from 'primeng/tabmenu'
import {CallResponseComponent} from '../../component/common/call-response/call-response.component'
import {OrderSegmentComponent} from '../../component/profile/profile-offer/order-segment/order-segment.component'
import {MapComponent} from '../../component/map/map.component'
import {ProfileRateComponent} from '../../component/profile/profile-rate/profile-rate.component'
import {TextInputComponent} from '../../component/common/form/text-input/text-input.component'
import {FrontendValidationComponent} from '../../component/common/frontend-validation/frontend-validation.component'
import {BackendValidationComponent} from '../../component/common/backend-validation/backend-validation.component'
import {LoadingComponent} from '../../component/loading/loading.component'
import {OrderStateComponent} from '../../component/dialog/order-state/order-state.component'
import {StripePayComponent} from '../../component/stripe-pay/stripe-pay.component'
import {SkeletonModule} from 'primeng/skeleton'
import {FirstChannelProfilePipe} from '../../pipe/first-channel-profile.pipe'
import {LazyListComponent} from '../../component/common/list/lazy-list/lazy-list.component'
import {ReactiveFormsModule} from '@angular/forms'
import {NavigationService as NS} from '../../service/ui/navigation.service'
import {authGuard} from '../../utils/router.utils'
import {ProfileCalendarComponent} from '../../component/profile/profile-calendar/profile-calendar.component'
import {OrderSearchComponent} from '../../component/order/order-search/order-search.component'
import {PixelsPipe} from '../../pipe/pixels.pipe'
import {InitDirective} from '../../directive/init.directive'
import {RadioButtonModule} from 'primeng/radiobutton'
import {SidebarModule} from 'primeng/sidebar'
import {ProfileOrderManagerAttentionComponent} from './profile-order-manager-attention/profile-order-manager-attention.component'
import {SharedModule} from 'primeng/api'
import {OrderReplyComponent} from './profile-manager/profile-manager-item-detail/order-reply/order-reply.component'
import {RippleModule} from 'primeng/ripple'
import {DropdownComponent} from '../../component/common/form/dropdown/dropdown.component'
import {DocsHintComponent} from '../../component/common/docs-hint/docs-hint.component'
import {ProfileNamesComponent} from '../../component/common/profile-names/profile-names.component'
import {TooltipDirective} from '../../directive/tooltip.directive'
import {ProfileOrderChatComponent} from './profile-manager/profile-order-chat/profile-order-chat.component'
import {BankInstructionsComponent} from '../../component/stripe-pay/bank-instructions/bank-instructions.component'
import {WallpaperPipe} from '../../pipe/wallpaper.pipe'
import {BackgroundImagePipe} from '../../pipe/background-image.pipe'
import {ProfileOrderStatusBadgeComponent} from './profile-manager/profile-order-status-badge/profile-order-status-badge.component'
import {CopyClipboardDirective} from '../../directive/copy.directive'
import {RatingComponent} from '../../component/common/rating/rating.component'
import {DividerModule} from 'primeng/divider'
import {BreadcrumbModule} from 'primeng/breadcrumb'
import {BreadcrumbComponent} from '../../component/common/breadcrumb/breadcrumb.component'

//@formatter:on
@NgModule({
  declarations: [
    ProfileManagerItemComponent,
    ContractPreviewComponent,
    ProfileManagerItemDetailComponent,
    ProfileManagerComponent,
    ProfileOrderManagerAttentionComponent,
    OrderReplyComponent,
    ProfileOrderStatusBadgeComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: `:${NS.PROFILE_CHAR_ID_PARAMETER}/${NS.PROFILE_BOOKINGS_LIST}/:${NS.PROFILE_BOOKINGS_LIST_PARAM}`,
        component: ProfileManagerComponent,
        canActivate: [authGuard]
      },
      {
        path: `:${NS.PROFILE_CHAR_ID_PARAMETER}/:${NS.PROFILE_BOOKINGS_DETAIL_PARAMETER}`,
        component: ProfileManagerItemDetailComponent,
        canActivate: [authGuard]
      }
    ]),
    CountdownPipe,
    AvatarComponent,
    VarDirective,
    ProfileProfessionsComponent,
    CountryPipe,
    PricePipe,
    DialogComponent,
    HintComponent,
    ButtonComponent,
    TooltipModule,
    TabMenuModule,
    CallResponseComponent,
    OrderSegmentComponent,
    MapComponent,
    ProfileRateComponent,
    TextInputComponent,
    FrontendValidationComponent,
    BackendValidationComponent,
    LoadingComponent,
    OrderStateComponent,
    StripePayComponent,
    SkeletonModule,
    FirstChannelProfilePipe,
    LazyListComponent,
    ReactiveFormsModule,
    ProfileCalendarComponent,
    OrderSearchComponent,
    PixelsPipe,
    InitDirective,
    RadioButtonModule,
    SidebarModule,
    SharedModule,
    RippleModule,
    DropdownComponent,
    DocsHintComponent,
    ProfileNamesComponent,
    TooltipDirective,
    ProfileOrderChatComponent,
    BankInstructionsComponent,
    TooltipDirective,
    WallpaperPipe,
    BackgroundImagePipe,
    CopyClipboardDirective,
    RatingComponent,
    DividerModule,
    BreadcrumbModule,
    BreadcrumbComponent
  ]
})
export class OrderManagerModule {
}
