import {Injectable} from '@angular/core'
import {addHours, dateCut} from '../../utils/date.utils'
import {StorageItem, StorageService} from '../storage.service'
import {AppModule, PLATFORM_BROWSER} from '../../app.module'
import {BasketService} from '../basket.service'

/**
 * Contains information about currently user-selected booking date information.
 */
@Injectable({
  providedIn: 'root'
})
export class BookingService {
  /**
   * Contains information about currently selected dates in the {@link ProfileCatalogBookFormComponent}.
   */
  private bookDate: BookInformation
  /**
   * Contains information about currently selected dates per profile character id.
   */
  private profileBookDate: BookInformation = {}
  /**
   * Default start date option in popups.
   */
  private defaultStart = addHours(dateCut(new Date(), 'm'), 1)
  /**
   * Default end date option in popups.
   */
  private defaultEnd = addHours(dateCut(new Date(), 'm'), 2)

  constructor(private storageService: StorageService) {
    this.init()
  }

  /**
   * Returns the current {@link bookDate} from localstorage only if it is not expired. Otherwise, an empty object is returned.
   */
  getBookDate(): BookInformation {
    if (this.bookDate?.expirationAt && new Date(this.bookDate.expirationAt) > new Date()) {
      return this.bookDate
    }
    this.updateBookDate({})
    return this.bookDate
  }

  /**
   * Updates the {@link bookDate} and updates the localstorage record.
   */
  updateBookDate(b: BookInformation): void {
    if (!b) {
      b = {}
    }
    this.bookDate = b
    this.cache(b)
  }

  /**
   * Returns the current {@link profileBookDate} from localstorage only if it is not expired. Otherwise, an empty object is returned.
   */
  getProfileBookDate(): BookInformation | null {
    const raw = this.profileBookDate
    if (raw?.expirationAt && new Date(raw.expirationAt) > new Date()) {
      return {
        startTime: (raw?.startTime) ? new Date(raw.startTime) : null,
        endTime: (raw?.endTime) ? new Date(raw.endTime) : null,
        startDate: (raw?.startDate) ? new Date(raw.startDate) : null,
        endDate: (raw?.endDate) ? new Date(raw.endDate) : null,
        expirationAt: addHours(new Date(), 1)
      }
    }

    this.updateProfileBookDate({} as BookField, {} as Date)
    return {
      startTime: (raw?.startTime) ? new Date(raw.startTime) : null,
      endTime: (raw?.endTime) ? new Date(raw.endTime) : null,
      startDate: (raw?.startDate) ? new Date(raw.startDate) : null,
      endDate: (raw?.endDate) ? new Date(raw.endDate) : null,
      expirationAt: addHours(new Date(), 1)
    }
  }

  /**
   * Updates the profile field date to the {@link profileBookDate} and updates the localstorage record.
   * Only one profile is stored in local storage and this profile is overwritten by another profile after any changes.
   */
  updateProfileBookDate(field: BookField, date: Date): void {
    if (!field || !date) {
      this.profileBookDate = {}
    } else {
      switch (field) {
        case 'startTime':
          this.profileBookDate.startTime = date
          break
        case 'endTime':
          this.profileBookDate.endTime = date
          break
        case 'startDate':
          this.profileBookDate.startDate = date
          break
        case 'endDate':
          this.profileBookDate.endDate = date
          break
      }
      this.profileBookDate.expirationAt = addHours(new Date(), 1)
    }
    this.cacheProfileBookDate(this.profileBookDate)
  }

  /**
   * Resets fields to default values.
   */
  reset(): void {
    this.bookDate = {
      startTime: this.defaultStart,
      endTime: this.defaultEnd,
      startDate: null,
      endDate: null
    }
  }

  /**
   * Clears fields to default values of the specific profile {@link charId}.
   */
  resetProfileBookDate(): void {
    this.profileBookDate = {
      startTime: this.defaultStart,
      endTime: this.defaultEnd,
      startDate: null,
      endDate: null,
      expirationAt: addHours(new Date(), 1)
    }
    this.cacheProfileBookDate(this.profileBookDate)
  }

  /**
   * Store the {@link bookDate} value to the local storage.
   */
  private cache(b: BookInformation): void {
    this.storageService.setItemStorage(StorageItem.BOOK_DATES, JSON.stringify(b))
  }

  /**
   * Store the {@link profileBookDate} value to the local storage.
   */
  private cacheProfileBookDate(b: BookInformation): void {
    const basketService = AppModule.injector.get(BasketService)
    basketService.updateOrderStorage(null, null, null, b)
    // basketService.createNewOrderTimeout() // created for the future
  }

  /**
   * - Initializes the {@link bookDate} and {@link profileBookDate} values from the localstorage.
   */
  private init(): void {
    if (PLATFORM_BROWSER) {
      const raw = this.storageService.getItemStorage(StorageItem.BOOK_DATES)
      if (raw) {
        this.bookDate = JSON.parse(raw) as BookInformation
      }

      //init book date
      const rawCalendar = this.storageService.getItemStorage(StorageItem.SELECTED_CALENDAR_DATA)
      if (rawCalendar) {
        this.profileBookDate = JSON.parse(rawCalendar) as BookInformation
      } else {
        this.resetProfileBookDate() //init to the default state
      }
    }
  }
}

/**
 * Defines an object of currently visible booking form information.
 */
export interface BookInformation {
  startTime?: Date
  startDate?: Date
  endTime?: Date
  endDate?: Date
  /**
   * Until what date, the record is valid.
   */
  expirationAt?: Date
}

/**
 * All available fields to be stored in the local storage.
 */
export type BookField = 'startTime' | 'endTime' | 'startDate' | 'endDate'
