import {Injectable} from '@angular/core'
import {PLATFORM_BROWSER} from '../../app.module'

declare let require: any

/**
 * Initializes the Leaflet library only on a browser platform.
 */
@Injectable({
  providedIn: 'root'
})
export class LeafletService {

  /**
   * The leaflet instance.
   */
  public L = null

  constructor() {
    if (PLATFORM_BROWSER) {
      this.L = require('leaflet')
    }
  }

  isReady(): boolean {
    return this.L !== null
  }

  latLng(lat: number, lng: number): any {
    return this.L.latLng(lat, lng)
  }

  tileLayer(url: string, options: any): any {
    return this.L.tileLayer(url, options)
  }

  icon(options: any): any {
    return this.L.icon(options)
  }

  circleMarker(latlng: any, options: any): any {
    return this.L.circleMarker(latlng, options)
  }

  map(element: any, options: any): any {
    return this.L.map(element, options)
  }
}
