<div class="title p-d-flex p-jc-center">
  <div class="content p-d-lg-flex p-jc-center p-ai-center">
    <!--Graphics-->
    <div class="graphic-content p-d-flex p-jc-center">
      <img src="assets/error/404.svg"
           alt="Page Not Found icon"
           i18n-alt
           class="graphic">
    </div>
    <!--Page not found text-->
    <div class="p-ml-lg-4">
      <!--Title-->
      <h1 i18n>Page Not Found</h1>
      <!--Info box-->
      <div class="box rounded-medium">
        <p i18n>We're sorry, this page couldn't be found.<br>
          If this was not a mistake, please contact support.</p>
        <!--E-mail-->
        <div class="p-d-flex p-ai-center">
          <i class="fa-solid fa-envelope p-mr-2"></i>
          <b class="p-mr-2">E-Mail:</b>
          <a href="mailto:support@umevia.com">support&#64;umevia.com</a>
        </div>
        <!-- Return -->
        <div class="p-d-flex p-jc-center">
          <app-button type="secondary"
                      label="Return"
                      i18n-label
                      icon="fa-solid fa-home"
                      styleClass="p-mt-2"
                      [url]="navigation.urlProfileCatalog()"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
