<!--Display, User name-->
<div *ngIf="data; else skeletonUserName">
  <!--Display name-->
  <div class="p-d-flex p-ai-center p-jc-center">
    <h1 class="display-name unselectable">{{ data.displayName }}</h1>
  </div>

  <!--Stars-->
  <div (click)="showReviews = true"
       class="p-text-bold p-d-flex p-mb-2 c-pointer">
    <app-profile-rate [countVisible]="true"
                      [profile]="data"></app-profile-rate>
  </div>

  <!--Professions-->
  <app-profile-professions *ngIf="professionsVisible"
                           [data]="data"
                           [editButtonVisible]="editButtonVisible"
                           styleClass="professions"
                           type="text"></app-profile-professions>

  <!--Location-->
  <div (click)="openSettings('location')" *ngIf="data.address"
       class="location p-d-inline-flex p-ai-center">
    <i class="fa-solid fa-globe-europe p-mr-2"></i>
    <p class="custom-p p-m-0">{{ data.address.city }}, {{ data.address.country }}</p>
  </div>

  <!--Solo/Group-->
  <div (click)="openSettings()" *ngIf="canBeOrdered"
       class="quantity p-d-flex p-ai-center">
    <i [class.fa-user-group]="data.group"
       [class.fa-user]="!data.group"
       class="fa-solid p-mr-2"></i>
    <p class="custom-p p-m-0">{{ (data.group) ? trans.group : trans.solo }}</p>
  </div>

  <!--BE-CHECK-->
  <app-backend-validation [check]="ServerMessage.LIKE_PROFILE_DENIED" [serverMessages]="serverMessages" i18n>
    You can't like this profile.
  </app-backend-validation>
</div>

<app-profile-rate *ngIf="showReviews && data?.rating?.ratingCount > 0"
                  [(show)]="showReviews"
                  [profile]="data"
                  [withDetails]="true">
</app-profile-rate>

<!--The skeleton layout that is visible during the data loading-->
<ng-template #skeletonUserName>
  <div class="p-col-12 p-md-5 p-d-flex p-jc-center p-jc-md-start">
    <div class="p-d-flex p-flex-column p-ai-start p-ai-md-start">
      <p-skeleton height="3rem" styleClass="p-mb-2" width="15rem"></p-skeleton>

      <div class="p-d-flex p-flex-column p-ai-start">
        <p-skeleton height="1.5rem" styleClass="p-mb-2" width="10rem"></p-skeleton>
        <p-skeleton height="1.5rem" styleClass="p-mb-2" width="8rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>
