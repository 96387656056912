import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../abstract/editable.component'
import {
  FilterSimilarProfilesReq,
  OrderableProfileResp,
  ProfileResp,
  ProfileService
} from '../../service/profile.service'
import {newEmptyPage, Page} from '../../rest/page-resp'
import {firstValueFrom, Observable} from 'rxjs'
import {getDeviceGeolocation} from '../../utils/navigator.utils'
import {dateJoin} from '../../utils/date.utils'

@Component({
  selector: 'app-alternative-artists',
  templateUrl: './alternative-artists.component.html',
  styleUrls: ['./alternative-artists.component.scss']
})
export class AlternativeArtistsComponent extends EditableComponent implements OnInit, OnChanges {

  @Input()
  profileData: ProfileResp
  /**
   * External input for the start date field.
   */
  @Input()
  selectedStartDate: Date
  /**
   * External input for the end date field.
   */
  @Input()
  selectedEndDate: Date
  /**
   * External input for the start time field.
   */
  @Input()
  selectedStartTime: Date
  /**
   * External input for the end time field.
   */
  @Input()
  selectedEndTime: Date
  /**
   * External input decides if the date in calendar book form is available.
   */
  @Input()
  isDateAvailable: boolean
  /**
   * Alternative artists.
   */
  artists: Page<OrderableProfileResp> = newEmptyPage()
  /**
   * Selects the profiles that are for a specific review or/and price category.
   */
  alternativeArtistFilters: AlternativeFilterOption[] = [
    {
      id: 1,
      name: $localize`reviews`,
      icon: 'fa-solid fa-star'
    },
    {
      id: 2,
      name: $localize`price`,
      icon: 'fa-solid fa-hand-holding-dollar'
    },
    {
      id: 3,
      name: $localize`location`,
      icon: 'fa-solid fa-earth-europe'
    }
  ]
  /**
   * Defines the number of visible skeleton cards in a single row.
   */
  numberOfSkeletons = 3

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '900px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    }
  ]

  private currentLoggedUserLocation: GeolocationPosition
  private currentSearchTimeout: any
  contentLoaded: boolean

  constructor(
    private profileService: ProfileService
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.isScreenBetween(0, this.Screen.SM)) {
      this.numberOfSkeletons = 1
    } else if (this.isScreenBetween(this.Screen.SM, this.Screen.MD)) {
      this.numberOfSkeletons = 2
    } else {
      this.numberOfSkeletons = 3
    }

    this.callAndLoadSimilarProfiles()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedStartTime?.currentValue || changes.selectedEndTime?.currentValue ||
      changes.selectedStartDate?.currentValue || changes.selectedEndDate?.currentValue) {
      this.ngOnInit()
    }
  }

  /**
   * Calls similar profiles of the unavailable profile in the setTimeout() function.
   * Cancels the currently scheduled timeout before setting a new one, it
   * effectively reset the delay when the user performs another action.
   */
  callAndLoadSimilarProfiles(): void {
    this.contentLoaded = false

    clearTimeout(this.currentSearchTimeout)
    this.currentSearchTimeout = setTimeout(async () => {
      this.call(async () => {
        this.artists = await firstValueFrom(this.callSimilarProfiles(this.profileData))
        this.contentLoaded = true
        this.currentLoggedUserLocation = await getDeviceGeolocation()
      })
    }, 2000)
  }

  /**
   * Call server to get alternative similar profiles.
   */
  private callSimilarProfiles(profile: ProfileResp): Observable<Page<OrderableProfileResp>> {
    const req: FilterSimilarProfilesReq = {
      profileId: profile.profileId,
      page: 0,
      start: dateJoin(this.selectedStartDate, this.selectedStartTime),
      end: dateJoin(this.selectedEndDate, this.selectedEndTime),
      reviews: this.alternativeArtistFilters[0].selected,
      price: this.alternativeArtistFilters[1].selected,
      lat: this.alternativeArtistFilters[2].selected ? this.currentLoggedUserLocation?.coords?.latitude : null,
      lng: this.alternativeArtistFilters[2].selected ? this.currentLoggedUserLocation?.coords?.longitude : null
    }
    return this.unwrap(this.profileService.callSimilarProfiles(req))
  }
}

/**
 * Defines a structure of the age filter option used in the {@link ProfileCatalogHeaderComponent}.
 */
export interface AlternativeFilterOption {
  id: number
  name: string
  icon: string
  selected?: boolean
}
