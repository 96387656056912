import {Component} from '@angular/core'
import {growAnimation} from '../../../animation/grow.animation'
import {PhoneVerificationAbstractComponent} from '../abstract/phone-verification-abstract.component'

/**
 * Phone verification component used as a single page. Logic is implemented in the abstract class.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})
export class PhoneVerificationComponent extends PhoneVerificationAbstractComponent {
}
