<app-dialog #dialog
            [(show)]="show"
            [component]="this"
            [hold]="deleteAddressConfirmVisible"
            [save]="callCreateNewProfileOrder.bind(this)"
            [allowSaveOption]="steps[currentStepIndex] === Step.SUM"
            [disableSaveOption]="!segmentValid"
            (saved)="onSubmittedOrder()"
            successMessage="Order has been created."
            i18n-successMessage>

  <!--Top action bar-->
  <ng-template pTemplate="topBar">
    <app-dialog-steps [steps]="visualSteps"
                      class="w-100"
                      [(activeIndex)]="currentVisualStepIndex"
    ></app-dialog-steps>
  </ng-template>

  <!--Steps of the dialog-->
  <div *ngIf="steps[currentStepIndex] as currentStep"
       [ngSwitch]="currentStep" @grow
       class="steps-content">

    <!--ADDITIONS-->
    <div *ngIf="hasAdditionalItems">
      <div *ngSwitchCase="Step.ADDITIONS" class="step" (ngInit)="dialog?.onScrollContent()">
        <h3 class="p-text-bold p-my-3 p-px-2" i18n>Want to add some of the offered additions to your price items?</h3>
        <div class="additions-lazy rounded-medium p-p-2">
          <app-lazy-list [(items)]="allAdditionalItems"
                         scrollHeight="30rem"
                         [virtual]="false"
                         [overlay]="false"
                         [overlayZIndex]="1120"
                         [component]="this"
                         [enableScroll]="true"
                         [skipInitialLoad]="true"
                         overlayClass="search-lazy-list-overlay"
                         [loadFunction]="callGetPriceItemAdditionsLazy.bind(this, allAdditionalItems?.nextPage)"
                         (bottomLoaded)="reselectAdditionalItems()">

            <!--Item content-->
            <ng-template let-addition pTemplate="item">
              <div
                class="addition p-d-flex p-jc-between p-ai-center rounded-small p-text-bold c-pointer p-mx-1 p-mt-1 p-mb-2 p-py-2 p-px-3">
                <app-checkbox [label]="addition.name"
                              [check]="addition.selected || false"
                              (checkChange)="addition.selected = $event; updateAddition(addition)"
                              class="w-100"></app-checkbox>
                <div>{{ addition.price | price }}</div>
              </div>
            </ng-template>
          </app-lazy-list>
        </div>
      </div>
    </div>

    <!--When the profile owns some additions, then the steps begin with additions, otherwise the steps begin with address -->
    <!--ADDRESS-->
    <div *ngIf="currentStep === Step.ORDER_ADDRESS || currentStep === Step.INVOICING_ADDRESS" @grow>
      <div class="sticky-select-button w-100 rounded-medium">
        <p-selectButton [options]="selectedBtnAddressOptions"
                        [ngModel]="currentStep"
                        (ngModelChange)="goToStep($event)"
                        [disabled]="!segmentValid || loading"
                        [allowEmpty]="false"
                        (onOptionClick)="changeRef.detectChanges()"
                        optionLabel="label"
                        optionValue="value"></p-selectButton>
      </div>
      <h3>{{ currentStep === Step.INVOICING_ADDRESS ? trans.invoice_adress : trans.event_adress }}</h3>
    </div>

    <!--Invoicing Address-->
    <app-order-invoicing-address
      *ngSwitchCase="Step.INVOICING_ADDRESS"
      [profile]="profileData"
      (deleteAddressConfirmVisibleChange)="deleteAddressConfirmVisible = true"
      (loadingChange)="loading = $event"
      (disableNavigation)="disableNext = $event; loading = $event"
      (valid)="segmentValid = $event"/>

    <!--Performance Address-->
    <app-order-performance-address
      *ngSwitchCase="Step.ORDER_ADDRESS"
      #perfAddr
      (ngInit)="orderPerformanceAddress = perfAddr"
      [profile]="profileData"
      [dialog]="dialog"
      (emitLoading)="loading = $event"
      (valid)="segmentValid = $event"/>

    <!-- DESCRIPTION-->
    <div *ngSwitchCase="Step.DESCRIPTION" class="step">
      <app-order-description [data]="profileData"
                             [allPriceItemCategories]="allPriceItemCategories"
                             (valid)="segmentValid = $event"/>
    </div>

    <!-- ACCOUNT-->
    <div *ngSwitchCase="Step.ACCOUNT" class="step">
      <app-order-account (valid)="segmentValid = $event"/>
    </div>

    <!--SUM-->
    <div *ngSwitchCase="Step.SUM" @grow class="step p-mt-5">
      <app-order-sum [data]="profileData"
                     [selectedPriceItems]="selectedPriceItems"
                     [selectedPriceAdditions]="selectedPriceItemsAdditions"
                     [allPriceAdditions]="allAdditionalItems.content"
                     [hours]="hours"
                     [dates]="dates"
                     [orderTotal]="orderTotal"
                     (stepsChanged)="goToStep($event); changeRef.detectChanges()"
                     (isSegmentInvoicing)="isInvoicing=$event"
                     (valid)="segmentValid = $event"/>

      <!--BE-CHECK-->
      <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED"
                              [serverMessages]="serverMessages"
                              [showAsBox]="true"
                              styleClass="p-mt-2" i18n>
        Fields cannot contain any contact in previous steps.
      </app-backend-validation>

      <app-backend-validation [check]="ServerMessage.PROFILE_NOT_IN_ACTIVE_STATUS"
                              [serverMessages]="serverMessages"
                              [showAsBox]="true"
                              styleClass="p-mt-2" i18n>
        Your profile is currently inactive. Please activate it in your profile settings to unlock full access and
        platform benefits.
      </app-backend-validation>

      <app-backend-validation [check]="ServerMessage.PROFILE_IN_PENALTY"
                              [serverMessages]="serverMessages"
                              [showAsBox]="true"
                              styleClass="p-mt-2" i18n>
        Chosen artist is currently in penalty. Therefore, it is not possible to create an order.
      </app-backend-validation>
    </div>
  </div>

  <!--Global BE-CHECK-->
  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.PROFILE_ORDER_PROFILE_CANNOT_BE_ORDERED"
                          [showAsBox]="true"
                          styleClass="p-mt-2" i18n>
    <b class="p-mr-2">{{ profileData.displayName }}</b> is not eligible to be ordered. Try to pick a different profile.
  </app-backend-validation>

  <!--Minimal distance BE-CHECK-->
  <app-backend-validation [check]="ServerMessage.PROFILE_ORDER_MINIMAL_DISTANCE_FAILED"
                          [serverMessages]="serverMessages"
                          [showAsBox]="true"
                          styleClass="p-mt-2">
    <div i18n>
      Cannot set order's start earlier than <b>{{ Restrictions.MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER }} minutes </b>
      from now.
    </div>
  </app-backend-validation>

  <!--EMPTY order BE-CHECK-->
  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.PROFILE_ORDER_IS_EMPTY"
                          [showAsBox]="true"
                          styleClass="p-mt-2" i18n>
    For a successful order you must select at least one item, please select.
  </app-backend-validation>

  <!--Birthdate-->
  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.BIRTHDATE_INCORRECT"
                          [showAsBox]="true"
                          styleClass="p-mt-2" i18n>
    You have to be at least {{ Restrictions.MIN_BIRTHDATE_YEARS }} years old.
  </app-backend-validation>

  <!--Birthdate isn't specified-->
  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.BIRTHDATE_NOT_SPECIFIED"
                          [showAsBox]="true"
                          styleClass="p-mt-2" i18n>
    You haven't specified your <a (click)="onBirthdateClick()"
                                  class="p-ml-1">birthdate</a>.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.PROFILE_ORDER_AUTHOR_CANNOT_ORDER"
                          [showAsBox]="true"
                          i18n>
    Sorry, we don't support ordering artists for other artists so far.
  </app-backend-validation>

  <!--Preview and next buttons-->
  <ng-template pTemplate="bottomBar"
               let-onSave="onSave" let-saveLoading="saveLoading" let-saveDisable="saveDisable"
               let-onDiscard="onDiscard" let-discardDisable="discardDisable">
    <div class="p-d-flex p-jc-between p-ai-center w-100">
      <!--Previous-->
      <app-button *ngIf="currentStepIndex > 0"
                  label="Previous"
                  i18n-label
                  [labelAutoHide]="true"
                  type="text"
                  icon="fa-solid fa-chevron-left"
                  iconPos="left"
                  [disabled]="discardDisable || loading || disableNext"
                  (clicked)="increaseStep(false)"></app-button>

      <!-- Phone -->
      <a href="tel:{{contactInfo.phone}}">
        <app-button type="text"
                    label="Help"
                    i18n-label
                    [labelAutoHide]="true"
                    icon="fa-solid fa-phone"></app-button>
      </a>

      <!--Discard-->
      <app-button type="text"
                  label="Close"
                  i18n-label
                  icon="fa-solid fa-xmark"
                  [labelAutoHide]="true"
                  [disabled]="discardDisable"
                  (clicked)="onDiscard(); isInvoicing = null"></app-button>

      <!--Next-->
      <app-button
        *ngIf="steps[currentStepIndex] !== Step.SUM"
        label="Next"
        i18n-label
        type="cta"
        icon="fa-solid fa-chevron-right"
        iconPos="right"
        [highlight]="true"
        [disabled]="!segmentValid || loading || serverMessages?.length > 0 || disableNext"
        [loading]="loading"
        (clicked)="increaseStep(true)"></app-button>

      <!--Save & Finish-->
      <app-button
        *ngIf="steps[currentStepIndex] === Step.SUM"
        icon="fa-solid fa-paper-plane"
        [label]="isScreenOf(Screen.MD) ? trans.send_order : trans.send_order_sm"
        [highlight]="true"
        type="cta"
        [loading]="saveLoading"
        [disabled]="saveDisable"
        (clicked)="onSave()"></app-button>
    </div>
  </ng-template>
</app-dialog>
