import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core'
import {BriefProfileResp, ProfileService, SearchProfilesWithAddressReq} from 'src/app/service/profile.service'
import {MapService, OSMSearchResult} from '../../../service/map.service'
import {ApiComponent} from '../../abstract/api.component'
import {fadeAnimation} from '../../../animation/fade.animation'
import {ProfileType} from '../../../common/profile-type'
import {firstValueFrom, Observable} from 'rxjs'
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel'
import {InputTextModule} from 'primeng/inputtext'
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {ScrollPanelModule} from 'primeng/scrollpanel'
import {DataViewModule} from 'primeng/dataview'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {RippleModule} from 'primeng/ripple'

@Component({
  animations: [fadeAnimation(100)],
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
  imports: [
    InputTextModule,
    OverlayPanelModule,
    NgTemplateOutlet,
    ScrollPanelModule,
    DataViewModule,
    AvatarComponent,
    RippleModule,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class MapSearchComponent extends ApiComponent implements OnChanges {

  @Input()
  show: boolean

  @Output()
  showChange = new EventEmitter<boolean>()

  /**
   * Autofocuses the {@link input} field.
   */
  @Input()
  autofocus = false

  /**
   * Displays the result content within the {@link overlayPanel} layout.
   * If this is false, it displays the result content as an embedded view.
   */
  @Input()
  resultAsOverlay = false

  /**
   * Represents what profile types should be visible for a user. If empty, it means no restriction.
   */
  @Input()
  searchOnly: ProfileType[] = []

  /**
   * Invokes when a user has clicked on the profile search option.
   */
  @Output()
  profileOption = new EventEmitter<BriefProfileResp>()

  /**
   * Invokes when a user has clicked on the address search option.
   */
  @Output()
  addressOption = new EventEmitter<any>()

  /**
   * Defines whether the search result list will have padding.
   */
  @Input()
  resultStyleClass: string

  /**
   * If a user clicks on the {@link input} field, it scrolls the content to the field automatically.
   */
  @Input()
  autoClickScroll = true

  /**
   * Search input field.
   */
  @ViewChild('input')
  input: ElementRef

  /**
   * The overlay panel of the search result
   */
  @ViewChild('overlayPanel')
  overlayPanel: OverlayPanel

  /**
   * The wrapper div element of this component.
   */
  @ViewChild('container')
  container: ElementRef<HTMLDivElement>

  /**
   * Style class of the {@link container} element.
   */
  @Input()
  styleClass: string

  /**
   * The current searching input string.
   */
  inputValue: string

  /**
   * The current searching task.
   */
  searchTask: any

  /**
   * Defines if the searching task is running.
   */
  searching: boolean
  /**
   * Controls the visibility of the embed result element.
   */
  embedResultVisible = true

  /**
   * Found a result from the open street maps.
   */
  osmSearchResult: OSMSearchResult[] = []

  /**
   * Found a result from the application server api.
   */
  profileSearchResult: BriefProfileResp[] = []

  /**
   * Represents the merged result from all search tasks that is suitable for the end user.
   */
  displayableSearchResult: any[] = []

  constructor(
    private mapService: MapService,
    private profileService: ProfileService) {
    super()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.autofocus?.currentValue || changes.show?.currentValue) && this.show && this.autofocus) {
      setTimeout(() => { // the animation timeout
        this.input.nativeElement.focus()
      }, 100)
    }
  }

  /**
   * Fires when a user has changed the input string of the {@link input}.
   */
  onSearchInput(event): void {
    const input = this.input.nativeElement.value.trim()

    if (input !== this.inputValue) { // only if new text is obtained
      this.inputValue = input

      if (input.length >= 3) {
        this.startSearchTask(input, event)
      } else {
        this.osmSearchResult = []
        this.profileSearchResult = []
      }
    }
  }

  /**
   * Fires when a user clicked on the input field.
   */
  onInputClick(event): void {
    setTimeout(() => {
      if (this.autoClickScroll) {
        this.container.nativeElement.scrollIntoView({behavior: 'smooth'})
      }
      setTimeout(() => {
        if (this.input.nativeElement.value.trim().length >= 3) {
          this.overlayPanel?.show(event)
          this.embedResultVisible = true
        }
      }, 350)
    }, 150)
  }

  /**
   * Fires when a user has clicked on a search result item.
   *
   * @param selected the selected option.
   */
  searchResultSelected(selected: any): void {
    if (selected.charId) { // Is a profile
      this.profileOption.emit(selected)
    } else { // Is an address
      this.addressOption.emit(selected)
    }
  }

  /**
   * Fires when the {@link input} lost focus.
   */
  onFocusLost(): void {
    setTimeout(() => { // hide this component with delay
      this.overlayPanel?.hide()
      this.embedResultVisible = false
      this.showChange.emit(false)
    }, 200)
  }

  /**
   * Starts the searching task to the API.
   *
   * @param input The input string from the user.
   * @param event The event from the {@link input} native element.
   */
  private startSearchTask(input: string, event: Event): void {
    // remove the previous set timeout
    if (this.searchTask) {
      clearTimeout(this.searchTask)
      this.searching = false
    }

    // add a new one
    this.searchTask = setTimeout(async () => {
      try {
        this.searching = true
        // Open Street Map Search
        this.osmSearchResult = await firstValueFrom(this.callOSMSearch(input))

        // App-Server profile search
        // if (input.length <= Restrictions.MAX_PROFILE_SEARCH_LENGTH) {
        //   this.profileSearchResult = await firstValueFrom(this.callSearchBriefProfileWithAddress(input))
        // }
        this.createDisplayableSearchResult(event)
      } finally {
        this.searching = false
      }
    }, 1000)
  }

  /**
   * Calls the server API to get brief profiles with address by the input string.
   */
  private callSearchBriefProfileWithAddress(inputStr: string): Observable<BriefProfileResp[]> {
    const req: SearchProfilesWithAddressReq = {
      input: inputStr,
      types: this.searchOnly
    }
    return this.unwrap(this.profileService.callSearchBriefProfileWithAddress(req))
  }

  /**
   * Calls the OpenStreetMap API to search a location by the input string.
   */
  private callOSMSearch(input: string): Observable<any> {
    return this.mapService.callOSMSearch(input)
  }

  /**
   * Updates the {@link displayableSearchResult} property.
   * Basically it merges the {@link profileSearchResult} and {@link osmSearchResult} into a single array.
   * The point of this function is to determine the exact number of elements of each array to be visible for a user.
   */
  private createDisplayableSearchResult(event): void {
    const profileResultMaxRecords = 0
    const OSMResultMaxRecords = 4
    this.displayableSearchResult = []

    for (let i = 0; i < this.profileSearchResult.length; i++) {
      if (i >= profileResultMaxRecords) {
        break
      } else {
        this.displayableSearchResult.push(this.profileSearchResult[i])
      }
    }

    for (let i = 0; i < this.osmSearchResult.length; i++) {
      if (i >= OSMResultMaxRecords) {
        break
      } else {
        this.displayableSearchResult.push(this.osmSearchResult[i])
      }
    }

    if (this.displayableSearchResult.length > 0) {
      this.overlayPanel?.show(event)
    } else {
      this.overlayPanel?.hide()
    }
  }
}
