import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {ProfileResp} from '../../../service/profile.service'
import {EditableComponent} from '../../abstract/editable.component'
import {HashtagResp, HashtagService, UpdateProfileHashtagsReq} from '../../../service/hashtag.service'
import {firstValueFrom, Observable} from 'rxjs'
import {appendNewPage, newEmptyPage, Page} from '../../../rest/page-resp'
import {FormBuilder, FormGroup} from '@angular/forms'
import {hashtag, maxLength} from '../../../validator/custom.validators'
import {Restrictions} from '../../../common/restrictions'
import {capitalizeWords} from '../../../utils/utils'
import {fadeAnimation} from '../../../animation/fade.animation'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-hashtags',
  templateUrl: './profile-hashtags.component.html',
  styleUrls: ['./profile-hashtags.component.scss']
})
export class ProfileHashtagsComponent extends EditableComponent implements OnInit, OnChanges {

  /**
   * The profile data.
   */
  @Input()
  data: ProfileResp

  /**
   * Defines the number of visible hashtags for the first time.
   * This value may change if a profile has more hashtags than this default value,
   * and user has clicked on the more option button.
   */
  sliceEnd = 5

  /**
   * Represents the user selected hashtags.
   */
  selectedHashtags: HashtagResp[]

  /**
   * Represents the result from the {@link searchTask}.
   */
  searchHashtags: Page<HashtagResp> = newEmptyPage()
  /**
   * The search input field.
   */
  @ViewChild('input')
  input: ElementRef<HTMLInputElement>

  /**
   * Defines the timeout task to call the API.
   * Used by the {@link startSearchTask} function.
   */
  searchTask: any

  /**
   * The form of input field.
   */
  form: FormGroup

  /**
   * Represents the current string used in {@link searchTask}.
   */
  private inputSearch: string

  constructor(
    private hashtagService: HashtagService,
    private formBuilder: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.selectedHashtags = this.data.hashtags || []
    }
  }

  /**
   * Fires when a user wants to save the changes.
   */
  async onComponentSave(): Promise<void> {
    this.resetApi()
    await firstValueFrom(this.callUpdateProfileHashtags())
    this.evaluateAndFinish()
  }


  /**
   * Fires when a user wants to show an edit component.
   */
  showEditComponent(): void {
    this.resetApi()
    super.showEditComponent()
  }

  /**
   * Fires when a user has typed some character inside of the search input field.
   */
  onInputChange(): void {
    const input = capitalizeWords(this.input.nativeElement.value)

    // only if new text is obtained
    if (input !== this.inputSearch) {
      this.inputSearch = input

      if (input.length >= 3) {
        this.searchHashtags.nextPage = 0

        this.startSearchTask(input, this.searchHashtags.nextPage)
      } else {
        this.searchHashtags = newEmptyPage()
      }
    }
  }

  /**
   * Starts the searching task to the API.
   *
   * @param name The input string from the user.
   * @param page Lazy-loading pagination
   */
  private startSearchTask(name: string, page: number): void {
    // remove the previous set timeout
    if (this.searchTask) {
      clearTimeout(this.searchTask)
    }

    if (this.searchHashtags.totalElements === 0 || this.searchHashtags.content.length !== this.searchHashtags.totalElements) {
      this.loading = true
    }

    // add a new one
    this.searchTask = setTimeout(async () => {
      const resp = await firstValueFrom(this.callSearchHashtags(name, page))
      if (page !== 0) {
        appendNewPage(this.searchHashtags, resp)
      } else {
        this.searchHashtags = resp
      }
      this.loading = false
    }, 750)
  }

  /**
   * Adds a hashtag to the {@link selectedHashtags}.
   */
  add(h: HashtagResp): void {
    this.selectedHashtags = [...this.selectedHashtags, h]
  }

  /**
   * Fires when a user wants to remove a hashtag.
   */
  remove(h: HashtagResp): void {
    for (let i = 0; i < this.selectedHashtags.length; i++) {
      const item = this.selectedHashtags[i]
      if (item.id === h.id) {
        this.selectedHashtags.splice(i, 1)
      }
    }
    // refresh the content
    this.selectedHashtags = [...this.selectedHashtags]
  }

  /**
   * Calls the server API to update profile hashtags.
   */
  private callUpdateProfileHashtags(): Observable<boolean> {
    const req: UpdateProfileHashtagsReq = {
      names: this.selectedHashtags.map(it => it.name)
    }
    return this.unwrap(this.hashtagService.callUpdateProfileHashtags(req))
  }

  /**
   * Calls the server API to update profile hashtags.
   */
  private callSearchHashtags(name: string, page: number = 0): Observable<Page<HashtagResp>> {
    return this.unwrap(this.hashtagService.callSearchHashtags(name, page))
  }

  /**
   * Initializes the {@link form}.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      search: ['', [
        hashtag(),
        maxLength(Restrictions.MAX_HASHTAG_NAME_LENGTH)
      ]]
    })
  }
}
