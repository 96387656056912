/**
 * Checks if the file passed as an argument has the proper image format.
 *
 * @param file that is being checked
 */
export function isFileImage(file: File): boolean {
  const format = file.type
  return format !== null && (format === 'image/jpeg' || format === 'image/jpg' || format === 'image/png')
}

/**
 * Returns a FileReader instance of a loaded image.<br >
 * <b>The usage of this method:</b>
 *
 * <pre>
 *   getImageFromUploadedAvatar(event, ev => {
 *     ev.target.result as string;
 *   });
 * </pre>
 *
 * @param event An image upload event.
 * @param observer The observer, where the value is loaded.
 */
export function getImageFromUploadedAvatar(event, observer: ((this: FileReader, ev: ProgressEvent<FileReader>) => any)): void {
  const file = event.target.files[0]
  if (event.target.files && file && isFileImage(file)) {
    const reader = new FileReader()
    reader.readAsDataURL(file) // read file as data url
    reader.onload = observer
  }
}
