import {ApiService} from './api.service'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {SimpleProfileResp} from './profile.service'
import {Page} from '../rest/page-resp'
import {Restrictions} from '../common/restrictions'
import {ViolationType} from '../common/violation-type'

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService extends ApiService {

  static readonly NOTIFICATION_CHAT_MESSAGE_NEW = 'chat_message_new'
  static readonly NOTIFICATION_CHAT_MESSAGE_EDIT = 'chat_message_edit'
  static readonly NOTIFICATION_CHAT_MESSAGE_DELETE = 'chat_message_delete'
  static readonly NOTIFICATION_CHAT_MESSAGE_BLOCKED = 'chat_message_blocked'

  constructor() {
    super()
  }

  callNewMessage(req: NewMessageReq): Observable<BaseResp<MessageResp>> {
    return this.post(Endpoint.CHAT_MESSAGE_NEW, req)
  }

  callEditMessage(req: EditMessageReq): Observable<BaseResp<MessageResp>> {
    return this.post(Endpoint.CHAT_MESSAGE_EDIT, req)
  }

  callDeleteMessage(req: DeleteMessageReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_MESSAGE_DELETE, req)
  }

  callUpdateSeenMessage(req: UpdateSeenByReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_MESSAGE_SEEN_BY, req)
  }

  callLoadMessages(req: LoadMessagesReq): Observable<BaseResp<Page<MessageResp>>> {
    if (req.limit && req.limit > Restrictions.MAX_CHAT_CHANNEL_MESSAGES_LOAD) {
      throw Error($localize`Cannot load more chat messages than ` + Restrictions.MAX_CHAT_CHANNEL_MESSAGES_LOAD)
    }
    return this.post(Endpoint.CHAT_MESSAGE_LOAD, req)
  }

  /**
   * Returns all unchecked messages for Umevia review team.
   */
  callLoadUncheckedMessages(req: LoadMessagesReq): Observable<BaseResp<Page<MessageResp>>> {
    return this.post(Endpoint.CHAT_MESSAGE_LOAD_UNCHECKED, req)
  }

  /**
   * Updates the chat message with violations.
   */
  callViolationsResolve(req: NewChatViolationsReq): Observable<BaseResp<MessageResp>> {
    return this.post(Endpoint.CHAT_MESSAGE_VIOLATIONS_RESOLVE, req)
  }

  /**
   * Updates the chat message with violations.
   */
  callAllViolationsResolve(lastMessageId: number): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_MESSAGE_VIOLATIONS_RESOLVE_ALL, lastMessageId)
  }
}

export interface NewMessageReq {
  channelId: number
  content: string
}

export interface EditMessageReq {
  profileId: number
  messageId: number

  content: string
}

export interface DeleteMessageReq {
  profileId: number
  messageId: number
}

export interface UpdateSeenByReq {
  profileId: number
  messageId: number
}

export interface LoadMessagesReq {
  channelId: number
  before?: number
  after?: number
  limit?: number
}

export interface MessageResp {
  id: number
  channelId: number
  content: string
  author: SimpleProfileResp
  createdAt: Date
  modifiedAt: Date
  violations: ViolationType[]
  violationsCheckedAt?: Date
}

export interface NewChatViolationsReq {
  id: number
  violations: ViolationType[]
}
