<form [formGroup]="form" class="p-mt-5">
  <app-text-input label="Order name"
                  i18n-label
                  placeholder="Celebration"
                  i18n-placeholder
                  icon="fa-solid fa-tag"
                  [form]="form"
                  [required]="true"
                  [notContact]="true"
                  formFieldName="orderName"
                  [length]="{max: Restrictions.MAX_PROFILE_ORDER_NAME_LENGTH}"
                  styleClass="p-mt-2"></app-text-input>
  <!--BE-CHECK-->
  <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED" [serverMessages]="serverMessages"
                          i18n>
    The field cannot contain any contact.
  </app-backend-validation>

  <!--Number of guests-->
  <app-dropdown [options]="menuGuestsNumber"
                formFieldName="numberOfGuests"
                placeholder="Number of guests"
                i18n-placeholder
                label="Number of guests"
                i18n-label
                icon="fa-solid fa-users-line"
                [form]="form"
                [required]="true">
    <ng-template let-item pTemplate="item">
      <div>{{item.label}}</div>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <div>{{item.label}}</div>
    </ng-template>
  </app-dropdown>

  <!--Description-->
  <app-text-input label="What should artist know about event"
                  i18n-label
                  inputType="text"
                  icon="fa-solid fa-comment-dots"
                  formFieldName="orderDescription"
                  placeholder="Provide the artist with as much detail as possible, such as venue requirements, theme, dresscode ..."
                  i18n-placeholder
                  [length]="{max: Restrictions.MAX_PROFILE_ORDER_DESCRIPTION_LENGTH}"
                  [textArea]="true"
                  [notContact]="true"
                  [rows]="6"
                  [form]="form"></app-text-input>
  <!--BE-CHECK-->
  <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED" [serverMessages]="serverMessages"
                          i18n>
    The field cannot contain any contact.
  </app-backend-validation>
</form>
