import {
  AfterViewInit,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core'
import {FormGroup, ValidatorFn, Validators} from '@angular/forms'
import {FrontendValidationComponent} from '../frontend-validation/frontend-validation.component'
import {AbstractComponent} from '../../abstract/abstract.component'

/**
 * This abstract class contains several base inputs for declaring own implementations of FormGroup fields.
 */
@Directive()
export abstract class AbstractFormField extends AbstractComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /**
   * A form that this input belongs to.
   */
  @Input()
  form: FormGroup
  /**
   * The form control name property.
   */
  @Input()
  formFieldName: string
  /**
   * The label displayed above the input field.
   */
  @Input()
  label?: string
  /**
   * The hint text displayed under the input field if the user has clicked on the 'show hint' icon.
   */
  @Input()
  hint?: string
  /**
   * Controls the visibility of the hint.
   */
  showHint = false
  /**
   * The placeholder displayed in the field.
   */
  @Input()
  placeholder?: string
  /**
   * Optional icon that appears on the left side of the field.
   */
  @Input()
  icon?: string
  /**
   * Emits when the user clicked on the {@link icon}.
   */
  @Output()
  iconClick = new EventEmitter<any>()
  /**
   * Defines the loading icon visible when the {@link loading} is true.
   */
  @Input()
  loadingIcon = 'fa-solid fa-circle-notch fa-spin'
  /**
   * Adds an optional action icon on the right.
   */
  @Input()
  rightIcon: string
  /**
   * Emits when the user clicked on the {@link rightIcon}.
   */
  @Output()
  rightIconClick = new EventEmitter<any>()
  /**
   * Disables the right action icon.
   */
  @Input()
  disableRightIcon: boolean
  /**
   * Disables the left action icon.
   */
  @Input()
  disableIcon: boolean
  /**
   * Sets the {@link loadingIcon} visible.
   */
  @Input()
  loading: boolean
  /**
   * Disables this field.
   */
  @Input()
  disable: boolean
  /**
   * The CSS class of the entire {@link TextInputComponent}.
   */
  @Input()
  styleClass: string
  /**
   * This is a set of extra validators that will be added to the {@link formFieldName}.
   */
  @Input()
  validators: ValidatorFn[] = []
  /**
   * - Adds the 'Validators.required' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'required' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  required: boolean
  /**
   * Controls the visibility of the required symbol.
   */
  showRequiredSymbol: boolean
  /**
   * Enables the autofocus.
   */
  @Input()
  focus = false
  /**
   * Enables the 'Space' key usage.
   */
  @Input()
  space = true
  /**
   * Enables the copy option.
   */
  @Input()
  copy = true
  /**
   * Enables the paste option.
   */
  @Input()
  paste = true
  /**
   * Fires when a user 'key-up' some key.
   */
  @Output()
  keyupEvent = new EventEmitter<KeyboardEvent>()
  /**
   * Fires when the input field gets blurred.
   */
  @Output()
  blurEvent = new EventEmitter<any>()
  /**
   * Fires when the user clicked on the input.
   */
  @Output()
  clickEvent = new EventEmitter<any>()
  /**
   * Contains all frontend validations specified in the ng-content
   */
  @ContentChildren(FrontendValidationComponent)
  frontendValidations: QueryList<FrontendValidationComponent>
  /**
   * Disables the default 'required' error message because the user has his own implementation.
   */
  disableRequired: boolean

  protected constructor() {
    super()
  }

  /**
   * Focuses the field.
   */
  abstract doFocus(): void

  ngOnInit(): void {
    // init missing validators
    this.initValidators()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.form?.currentValue || changes.validators?.currentValue || changes.required) && this.form) {
      this.ngOnInit()
    }

    this.showRequiredSymbol = this.form?.controls[this.formFieldName].hasValidator(Validators.required)

    if (changes.disable) {
      if (this.disable) {
        this.form?.controls[this.formFieldName].disable()
      } else {
        this.form?.controls[this.formFieldName].enable()
      }
    }
  }

  ngAfterViewInit(): void {
    // disable default error messages
    this.disableDefaultValidators()
  }

  /**
   * Initializes the validators of this {@link formFieldName}.
   */
  protected initValidators(): void {
    // Add extra validators
    for (const validator of this.validators) {
      this.addValidator(validator)
    }

    // Required
    if (this.required) {
      this.addValidator(Validators.required)
    } else {
      this.removeValidator(Validators.required)
    }
  }

  /**
   * Removes all validators from the {@link formFieldName}.
   */
  protected clearValidators(): void {
    this.form?.controls[this.formFieldName]?.clearValidators()
  }

  /**
   * Adds a validator to the {@link formFieldName} if it isn't already present.
   */
  protected addValidator(validator: ValidatorFn): void {
    if (!this.form.controls[this.formFieldName].hasValidator(validator)) {
      this.form.controls[this.formFieldName].addValidators(validator)
    }
  }

  /**
   * Removes a validator from the {@link formFieldName}.
   */
  protected removeValidator(validator: ValidatorFn): void {
    this.form?.controls[this.formFieldName].removeValidators(validator)
  }

  /**
   * Disables the default messages of the validation errors based on the {@link frontendValidations}.
   */
  protected disableDefaultValidators(): void {
    for (const valid of this.frontendValidations) {
      switch (valid.error) {
        case 'required':
          this.disableRequired = true
          break
      }
    }
  }

  /**
   * Fires when a user pressed the 'Space' key.
   */
  onSpace(event): void {
    if (!this.space) {
      event.preventDefault()
    }
  }

  /**
   * Fires when a user pressed the 'Paste option' key.
   */
  onPaste(event): void {
    if (!this.paste) {
      event.preventDefault()
    }
  }

  /**
   * Fires when a user pressed the 'Copy option' key.
   */
  onCopy(event): void {
    if (!this.copy) {
      event.preventDefault()
    }
  }

  ngOnDestroy(): void {
    this.clearValidators()
  }
}
