import {Component, Input} from '@angular/core'
import {RegisterReq} from 'src/app/service/user.service'
import {ProfileType} from '../../../common/profile-type'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {CallResponseComponent} from '../../../component/common/call-response/call-response.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {FrontendValidationComponent} from '../../../component/common/frontend-validation/frontend-validation.component'
import {NgForOf, NgIf} from '@angular/common'
import {RadioButtonModule} from 'primeng/radiobutton'
import {TextInputComponent} from '../../../component/common/form/text-input/text-input.component'
import {VarDirective} from '../../../directive/var.directive'
import {RouterLink} from '@angular/router'
import {RegisterTraditionalAbstractComponent} from '../abstract/register-traditional-abstract.component'
import {PhoneVerificationEmbedComponent} from '../phone-verification-embed/phone-verification-embed.component'

/**
 * Register component which can be embedded in other components. Logic is implemented in the abstract class.
 */
@Component({
  selector: 'app-register-embed',
  standalone: true,
  imports: [
    BackendValidationComponent,
    ButtonComponent,
    CallResponseComponent,
    FormsModule,
    FrontendValidationComponent,
    NgForOf,
    NgIf,
    RadioButtonModule,
    ReactiveFormsModule,
    TextInputComponent,
    VarDirective,
    RouterLink,
    PhoneVerificationEmbedComponent
  ],
  templateUrl: './register-embed.component.html',
  styleUrl: './register-embed.component.scss'
})
export class RegisterEmbedComponent extends RegisterTraditionalAbstractComponent {

  /**
   * Display name of the user which is being registered.
   * By inserting the name here user is not required to enter it in the form to speed up registration process.
   */
  @Input({required: true})
  displayName: string

  /**
   * Profile type of the user which is being registered.
   * By inserting the type here user is not required to enter it in the form to speed up registration process.
   */
  @Input()
  profileType: ProfileType = ProfileType.USER

  createRegisterReq(formData: any): RegisterReq {
    return {
      displayName: this.displayName,
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      profileType: this.profileType,
      password: formData.password
    }
  }
}
