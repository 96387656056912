import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {StripePaymentElementComponent, StripeService as Stripe} from 'ngx-stripe'
import {BankInstructions, StripeService} from '../../service/stripe.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {environment} from '../../../environments/environment'
import {PaymentIntentResult, StripePaymentElementChangeEvent, StripePaymentElementOptions} from '@stripe/stripe-js'
import {EditableComponent} from '../abstract/editable.component'
import {throwAppError} from '../../utils/log.utils'
import {growAnimation} from '../../animation/grow.animation'
import {ServerMessage} from '../../common/server-message'
import {DialogComponent} from '../common/dialog/dialog.component'
import {NgIf} from '@angular/common'
import {BackendValidationComponent} from '../common/backend-validation/backend-validation.component'
import {SkeletonModule} from 'primeng/skeleton'
import {ProfileResp} from '../../service/profile.service'
import {CheckboxComponent} from '../common/form/checkbox/checkbox.component'
import {HintComponent} from '../common/hint/hint.component'
import {PricePipe} from '../../pipe/price.pipe'
import {UserResp, UserService} from '../../service/user.service'
import {ButtonComponent} from '../common/button/button.component'
import {SharedModule} from 'primeng/api'
import {VarDirective} from '../../directive/var.directive'
import {BankInstructionsComponent} from './bank-instructions/bank-instructions.component'
import {abortableRequest} from '../../utils/utils'
import {CarouselModule} from 'primeng/carousel'
import {TooltipDirective} from '../../directive/tooltip.directive'
import {SupportContact} from '../support/support.component'


@Component({
  animations: [growAnimation()],
  selector: 'app-stripe-pay',
  templateUrl: './stripe-pay.component.html',
  styleUrls: ['./stripe-pay.component.scss'],
  imports: [
    DialogComponent,
    StripePaymentElementComponent,
    NgIf,
    BackendValidationComponent,
    SkeletonModule,
    CheckboxComponent,
    HintComponent,
    PricePipe,
    ButtonComponent,
    SharedModule,
    VarDirective,
    BankInstructionsComponent,
    CarouselModule,
    TooltipDirective
  ],
  standalone: true
})
export class StripePayComponent extends EditableComponent implements OnInit, OnDestroy {
  /**
   * ID of ProfileOrder
   */
  @Input({required: true})
  orderId: number
  /**
   * Order amount to pay.
   */
  @Input({required: true})
  orderAmount: number
  /**
   * Represents the current previewing profile data.
   */
  @Input()
  profileData: ProfileResp
  /**
   * A current logged user.
   */
  loggedUser: UserResp
  /**
   * Hash returned from API containing payment information
   */
  clientSecret: string
  /**
   * Stripe payment element - GUI of payment
   */
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent

  /**
   * When the payment is successful.
   */
  @Output()
  successful = new EventEmitter<boolean>()

  /**
   * Error message of payment
   */
  errorMessage: string
  /**
   * Hides the save option.
   */
  hideSaveOption: boolean
  /**
   * Determines whether the {@link paymentElement} is ready.
   */
  paymentReady: boolean
  /**
   * Determines whether the currently running server machine has a running Stripe Webhook.
   */
  webhookEnabled: boolean
  /**
   * Whether the currently running environment is production.
   */
  production = environment.production
  /**
   * Whether the currently running environment runs locally and not on a remote server
   */
  isLocalEnvironment: boolean
  /**
   * Stripe-provided bank instructions for the current payment intent.
   */
  @Input()
  bankInstructions: BankInstructions
  /**
   * Emits when the custom bank instructions should be displayed.
   */
  @Output()
  showBankInstructions = new EventEmitter<boolean>()
  /**
   * - Emits when the user has selected the bank transfer payment method.
   * - Basically, the {@link bankInstructions} have to be fetched.
   */
  @Output()
  loadBankInstructions = new EventEmitter<boolean>()
  /**
   * Represents a currently selected payment method in the Stripe {@link paymentElement} view.
   */
  currentSelectedMethod: StripePaymentMethod
  /**
   * Contains layout styles of the payment form.
   */
  options: StripePaymentElementOptions = {
    layout: {
      type: 'tabs'
    },
    fields: {
      billingDetails: {
        email: 'never'
      }
    }
  }

  PM: typeof StripePaymentMethod = StripePaymentMethod
  /**
   * Used to transform numbers into prices.
   */
  pricePipe = new PricePipe()
  /**
   * - The current pay button label.
   * - Gets changed by the {@link currentSelectedMethod}.
   */
  payButtonLabel: string = ''
  /**
   * Contains frequent-used banks in Slovakia region.
   */
  popularBanks: PopularBank[] = [
    {title: 'VÚB Banka'},
    {title: 'ČSOB'},
    {title: 'Slovenská Sporiteľňa'},
    {title: 'Poštová Banka'},
    {title: 'Prima Banka'},
    {title: '365.bank'},
    {title: 'UniCredit'},
    {title: 'Raiffeisen'},
    {title: 'Tatra Banka'},
    {title: 'Fio'}
  ]
  /**
   * Frequent-used banks carousel responsive options.
   */
  popularBanksOpts = [
    {
      breakpoint: '992px',
      numVisible: 3,
      numScroll: 2
    },
    {
      breakpoint: '768px',
      numVisible: 4,
      numScroll: 2
    },
    {
      breakpoint: '576px',
      numVisible: 3,
      numScroll: 2
    },
    {
      breakpoint: '476px',
      numVisible: 2,
      numScroll: 2
    }
  ]
  /**
   * Holds a support contact information.
   */
  contact: SupportContact = environment.contact
  /**
   * All subscriptions than need to be unsubscribed.
   */
  private subs: Subscription[] = []

  protected trans = {
    okay: $localize`Okay`,
    not_now: $localize`Not now`,
    pay: $localize`Pay`,
    continue: $localize`Continue`
  }

  constructor(
    public stripe: Stripe,
    private stripeService: StripeService,
    private userService: UserService) {
    super()
  }

  ngOnInit(): void {
    this.loading = true
    this.observeUser()
    this.stripe.key = environment.stripe.apiKey
    this.isLocalEnvironment = environment.runsLocally
    this.webhookEnabled = (this.isLocalEnvironment) ? (environment.name === 'e2e' ? false : this.webhookEnabled) : true
    if (!this.orderId) {
      throwAppError('stripe-pay', $localize`The [orderId] must be initialized!`)
    }

    this.call(async () => {
      this.clientSecret = await firstValueFrom(this.callGetPaymentIntent(this.orderId))
      if (this.isMessage(ServerMessage.PROFILE_ORDER_ALREADY_PAID)) {
        this.hideSaveOption = true
      }
    })
  }

  /**
   * Fires when a user selects a payment method in the {@link paymentElement} view.
   */
  onChangePaymentMethod(e: StripePaymentElementChangeEvent): void {
    this.currentSelectedMethod = e.value.type as StripePaymentMethod

    switch (e.value.type) {
      case StripePaymentMethod.BANK_TRANSFER:
        this.payButtonLabel = this.trans.continue
        break

      case StripePaymentMethod.CARD:
      default:
        const price = ((this.bankInstructions?.amountRemaining || 0) / 100) || this.orderAmount
        this.payButtonLabel = `${this.trans.pay} ${this.pricePipe.transform(price)}`
        break
    }
  }

  /**
   * Fires when a user clicked on the Pay button.
   */
  async onPay(): Promise<void> {
    // Used for bank transfers to release Stripe lock of showing their own payment instructions
    const abortController = new AbortController()

    await this.callAndFinish(async () => {
      // Show bank instructions when already requested
      if (this.currentSelectedMethod === StripePaymentMethod.BANK_TRANSFER && this.bankInstructions) {
        this.showBankInstructions.emit(true)
        this.setFailed(true)
        this.closeEditComponent()
        return
      }

      // For bank transfers, release the awaiting of Stripe own payment instructions
      if (this.currentSelectedMethod === StripePaymentMethod.BANK_TRANSFER) {
        setTimeout(() => {
          this.loadBankInstructions.emit(true)
          abortController.abort()
        }, 5000)
      }

      // Confirm a payment method
      const result: PaymentIntentResult = await abortableRequest(this.callConfirmPayment.bind(this), abortController)
      await this.processConfirmResult(result)

    }, (err) => {
      if (abortController.signal.aborted) { // When a stripe has been
        this.setFailed(true)
        this.closeEditComponent()
        return
      } else {
        throw err
      }
    })
  }

  /**
   * Processes the {@link result} of the Stripe payment confirmation request.
   */
  private async processConfirmResult(result: PaymentIntentResult): Promise<void> {
    if (result.error) {
      this.errorMessage = result.error.message
      this.setFailed(true)
      return
    }

    switch (result.paymentIntent.status) {
      case 'succeeded':
        if (!this.production && !this.webhookEnabled) {
          this.resetApi()
          await firstValueFrom(this.callLocalhostAcceptPayment(this.orderId))
          this.evaluateAndFinish()
        } else {
          this.setSuccess(true)
        }
        this.successful.emit(true)
        break

      case 'canceled':
        this.errorMessage = $localize`Payment has been cancelled.`
        console.error('Canceled payment, PaymentIntent: ', result.paymentIntent.id)
        this.setFailed(true)
        break

      default:
        this.errorMessage = $localize`Payment not finished: ${result.paymentIntent.status}`
        console.error('Payment not finished, PaymentIntent: ', result.paymentIntent.id)
        this.setFailed(true)
    }
  }

  /**
   * Observe current logged user and updates the {@link options}.
   */
  private observeUser(): void {
    this.subs.push(this.userService.user.subscribe(user => {
      this.loggedUser = user
      if (user) {
        this.options.defaultValues = {
          billingDetails: {
            email: user.email
          }
        }
      }
    }))
  }

  /**
   * Calls the Stripe service to submit a new payment.
   */
  private callConfirmPayment(): Observable<PaymentIntentResult> {
    return this.stripe.confirmPayment({
      elements: this.paymentElement.elements,
      redirect: 'if_required',
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: this.loggedUser?.email
          }
        }
      }
    })
  }

  /**
   * Calls the server to get a token for a payment
   */
  private callGetPaymentIntent(orderId: number): Observable<string> {
    return this.unwrap(this.stripeService.callGetPaymentIntent(orderId))
  }

  /**
   * Calls the server only in non-production environment to manually accept payments since the Stripe webhook is not available
   * on local environments.
   */
  private callLocalhostAcceptPayment(orderId: number): Observable<boolean> {
    return this.unwrap(this.stripeService.callLocalhostAcceptPayment(orderId))
  }

  ngOnDestroy(): void {
    this.subs.forEach(it => it?.unsubscribe())
  }
}

/**
 * Represents available payment methods to select.
 */
enum StripePaymentMethod {
  BANK_TRANSFER = 'customer_balance',
  CARD = 'card'
}

/**
 * Represents a frequent-used bank layout.
 */
interface PopularBank {
  title: string
  fee?: string
  description?: string
}
