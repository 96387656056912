<div class="p-grid wrapper rounded-medium p-px-2">
  <div class="p-col-12 p-md-6 p-pb-0 p-pb-md-2">
    <!-- Invoicing address-->
    <app-order-segment titleIcon="fa-solid fa-location-dot"
                       title="Invoicing address"
                       i18n-title
                       rightIcon="fa-solid fa-edit"
                       (rightIconClick)="stepsChanged.emit(Step.INVOICING_ADDRESS); isSegmentInvoicing.emit(true)">
      <div>
        <div class="p-ml-4">
          <div>{{ authorAddress.name }}</div>
          <div>{{ authorAddress.line1 }}</div>
          <div *ngIf="authorAddress.line2">{{ authorAddress.line2 }}</div>
          <div>{{ authorAddress.postalCode }}, {{ authorAddress.city }}, {{ authorAddress.state }}</div>
          <div *ngIf="authorAddress.legalEntity as le">
            <hr class="p-my-1">
            <div i18n>CRN: {{ le.registrationNumber }}</div>
            <div i18n>Tax ID: {{ le.taxId }}</div>
            <div *ngIf="le.vatId">Vat ID: {{ le.vatId }}</div>
          </div>
          <div *ngIf="authorAddress.birthDate && !authorAddress.legalEntity">
            <hr class="p-my-1">
            <div *ngVar="authorAddress.birthDate as b">
              <div i18n>Birth Date: {{ b.day }}.{{ b.month }}. {{ b.year }}</div>
            </div>
          </div>
        </div>
      </div>
    </app-order-segment>

    <div class="p-mt-3">
      <!-- Order address-->
      <app-order-segment titleIcon="fa-solid fa-location-dot"
                         title="Event address"
                         i18n-title
                         rightIcon="fa-solid fa-edit"
                         (rightIconClick)="stepsChanged.emit(Step.ORDER_ADDRESS); isSegmentInvoicing.emit(false)">
        <div>
          <div class="p-ml-4">
            <div>{{ orderAddress.line1 }}</div>
            <div *ngIf="orderAddress.line2">{{ orderAddress.line2 }}</div>
            <div>{{ orderAddress.postalCode }}, {{ orderAddress.city }}, {{ orderAddress.state }}</div>
          </div>
          <div class="map p-py-2" *ngIf="lazyLoadMap" @grow>
            <app-map [newMarker]="data | briefProfile"
                     [newMarkerPos]="coords"
                     [resetButton]="false"
                     [readonly]="true"
                     [search]="false"
                     styleClass="rounded-medium"
                     [filterButton]="false"
                     [explore]="false"></app-map>
          </div>
        </div>
      </app-order-segment>
    </div>

    <!--Info section-->
    <ng-template [ngIf]="isScreenOf(Screen.MD)">
      <ng-template [ngTemplateOutlet]="info"></ng-template>
    </ng-template>
  </div>

  <div class="p-col-12 p-md-6 p-pt-0 p-pt-md-2">
    <!-- Details content -->
    <app-order-segment titleIcon="fa-solid fa-circle-info"
                       [styleClass]="!isScreenOf(Screen.MD) ? 'p-mt-3' : ''"
                       title="Details"
                       i18n-title
                       rightIcon="fa-solid fa-edit"
                       (rightIconClick)="stepsChanged.emit(Step.DESCRIPTION)">
      <div>
        <div>
          <div class="p-text-bold p-ml-2" i18n>Order name:</div>
          <div class="p-ml-4">{{ detail?.name }}</div>
        </div>
        <div class="p-mb-1">
          <div class="p-text-bold p-ml-2" i18n>Guests number:</div>
          <div [ngSwitch]="detail?.guestsNumber" class="p-ml-4">
            <div *ngSwitchCase="Guests.UNDER_50" i18n>Less then 50 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_100" i18n>Less then 100 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_500" i18n>Less then 500 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_1000" i18n>Less then 1000 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_5000" i18n>Less then 5000 guests.</div>
            <div *ngSwitchCase="Guests.OVER_5000" i18n>Over then 5000 guests.</div>
          </div>
        </div>

        <div *ngIf="detail?.description">
          <div class="p-text-bold p-ml-2" i18n>Description:</div>
          <div #description
               class="p-text-italic c-pointer p-ml-4"
               [class.l-count-1]="!description['unwrapped']"
               (click)="description['unwrapped'] = !description['unwrapped']">
            {{ detail.description }}
          </div>
        </div>
      </div>
    </app-order-segment>

    <div class="p-mt-3">
      <!-- Summary content-->
      <app-order-segment titleIcon="fa-solid fa-list"
                         title="Summary"
                         i18n-title>

        <div class="p-grid p-my-2">
          <!--Start date and time info-->
          <div class="p-col-6 p-p-0">
            <div class="p-text-bold" i18n>Starts At:</div>
            <div class="p-my-1">
              <div>{{ dates[0] | date: 'd. MMM. yyyy' }}</div>
              <div>{{ dates[0] | date: 'HH:mm' }}</div>
            </div>
          </div>
          <div class="p-col-6 p-p-0">
            <div class="p-text-bold" i18n>Ends At:</div>
            <div class="p-my-1">
              <div>{{ dates[1] | date: 'd. MMM. yyyy' }}</div>
              <div>{{ dates[1] | date: 'HH:mm' }}</div>
            </div>
          </div>
        </div>

        <div>
          <!--Price items-->
          <p-divider *ngIf="selectedPriceAdditions.length > 0"
                     layout="horizontal" type="solid" align="center" styleClass="p-my-2" i18n>
            Main Services
          </p-divider>

          <div *ngFor="let item of selectedPriceItems" class="p-mb-2">
            <b class="p-m-0">{{ item.name }}</b>
            <div class="p-d-flex p-jc-between p-ai-center">
              <div *ngIf="item.fixedPrice" i18n>Fixed</div>
              <div *ngIf="!item.fixedPrice" class="p-d-flex" i18n>Hour ({{ item.price | price }})
                <div class="p-ml-2">x {{ hours }}</div>
              </div>
              <div>{{ (item.price * ((!item.fixedPrice) ? hours : 1)) | price }}</div>
            </div>
          </div>

          <!--Additional services-->
          <p-divider *ngIf="selectedPriceAdditions.length > 0"
                     layout="horizontal" type="solid" align="center" styleClass="p-my-2" i18n>
            Additional Services
          </p-divider>

          <div *ngFor="let item of selectedPriceAdditions" class="p-my-2">
            <div class="p-d-flex p-jc-between p-ai-center">
              <div>{{ item.name }}</div>
              <div>{{ item.price | price }}</div>
            </div>
          </div>
        </div>

        <div>
          <p-divider layout="horizontal" type="solid" align="center" styleClass="p-my-2" i18n>
            Summary
          </p-divider>

          <!-- Platform fee -->
          <div class="p-d-flex p-jc-between p-px-2"
               pTooltip="The one-time fee allows us to cover the basic functionality of this platform and provide you with the best possible user experience."
               i18n-pTooltip
               tooltipPosition="top">
            <div class="p-d-flex p-ai-center">
              <i class="fa-solid fa-info-circle p-mr-2"></i>
              <div class="c-pointer" i18n>Administrative fee</div>
            </div>
            <div class="p-ml-2">{{ fixedFee | price }}</div>
          </div>

          <!-- Total sum -->
          <div class="p-d-flex p-jc-between p-ai-center p-px-2 p-mt-2"
               pTooltip="This price can be increased by {{data.displayName}} to cover performance costs."
               i18n-pTooltip
               tooltipPosition="top">
            <h3 class="p-m-0" i18n>Total</h3>
            <h3 class="p-m-0">{{ (fixedFee + orderTotal) | price }}</h3>
          </div>

          <small class="p-mb-2 p-px-2" i18n>You will not pay anything yet</small>

          <div class="p-mb-1 p-px-2 p-mt-2">
            <i class="fa-solid fa-check p-mr-2 p-mt-1"></i>
            <small i18n>Includes taxes and fees</small>
          </div>
          <div *ngFor="let i of benefits" class="p-d-flex p-px-2 p-mb-1">
            <i class="fa-solid fa-check p-mr-2 p-mt-1"></i>
            <small>{{ i }}</small>
          </div>
        </div>
      </app-order-segment>
    </div>

    <!--Info Section - Small screens-->
    <ng-template [ngIf]="!isScreenOf(Screen.MD)">
      <ng-template [ngTemplateOutlet]="info"></ng-template>
    </ng-template>
  </div>
</div>

<!--Info Section-->
<ng-template #info>
  <div class="p-mt-3">
    <app-order-segment titleIcon="fa-solid fa-circle-info"
                       title="Pricing"
                       i18n-title>
      <p class="p-text-italic p-mt-1" i18n>The <b>total price is only informative</b> and can be increased by
        <b>{{ data.displayName }}</b> to cover all performance costs based on your requirements. The final
        price will be displayed in your booking detail after {{ data.displayName }} accepts your order.
        (You'll be notified.)</p>
    </app-order-segment>
  </div>
</ng-template>
