import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class HashtagService extends ApiService {
  constructor() {
    super()
  }

  /**
   * Searches for all available hashtags.
   */
  callSearchHashtags(name: string, page: number = 0): Observable<BaseResp<Page<HashtagResp>>> {
    const formData: FormData = new FormData()
    formData.append('name', name)
    formData.append('page', page.toString())
    return this.post(Endpoint.HASHTAG_SEARCH, formData, this.getFormHeaders())
  }

  /**
   * Updates the profile hashtags.
   */
  callUpdateProfileHashtags(req: UpdateProfileHashtagsReq): Observable<BaseResp<boolean>> {
    return this.post(`${Endpoint.HASHTAG_PROFILE_UPDATE}`, req)
  }

}

export interface UpdateProfileHashtagsReq {
  names: string[]
}

export interface HashtagResp {
  id: number
  name: string
  count: number
}
