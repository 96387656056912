import {Component, OnDestroy, OnInit} from '@angular/core'
import {mustMatch} from '../../../validator/custom.validators'
import {RegisterReq, UserResp} from '../../../service/user.service'
import {firstValueFrom, Observable} from 'rxjs'
import {RegisterAbstractComponent} from './register-abstract.component'

/**
 * Abstract class for traditional register components that contains common methods and properties.
 * Contains only common logic for all traditional register components.
 * It is used to avoid code duplication and let the component handle only view without logic.
 * The traditional register is a register with email and password not a 3rd party service.
 */
@Component({template: ''})
export abstract class RegisterTraditionalAbstractComponent extends RegisterAbstractComponent implements OnInit, OnDestroy {

  constructor() {
    super()
  }

  /**
   * Fires when a user submits this {@link form}.
   */
  async onRegister(): Promise<void> {
    this.callAndFinish(async () => {
      this.setFormSubmitted(true)
      const resp = await firstValueFrom(this.callRegister(this.form.getRawValue()))

      // when registration was successful
      if (resp && this.noServerMessages()) {
        this.afterRegisterSuccess(resp)
      }
    }, () => {
      this.setFormSubmitted(false)
    })
  }

  /**
   * Call the server API to register a new user.
   *
   * @param formData The {@link form} value.
   */
  protected override callRegister(formData): Observable<UserResp> {
    return this.unwrap(this.userService.callRegister(this.createRegisterReq(formData)))
  }

  /**
   * Creates and inits the input form with validators.
   */
  protected override initForm(): void {
    this.form = this.formBuilder.group({
      displayName: [''],
      email: [''],
      phone: [''],
      selectedCategory: [this.categories[0]],
      password: [''],
      passwordAgain: ['']
    }, {
      validators: [
        mustMatch('password', 'passwordAgain')
      ]
    })
  }

  /**
   * Generate a register request object.
   */
  abstract createRegisterReq(formData): RegisterReq
}


