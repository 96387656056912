import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {Endpoint} from '../common/endpoints'
import {BaseResp} from '../rest/base-resp'
import {ApiService} from './api.service'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class SkillService extends ApiService {

  constructor() {
    super()
  }

  callSearchSkill(req: SearchSkillReq): Observable<BaseResp<Page<SkillResp>>> {
    return this.post(Endpoint.SKILL_SEARCH_URL, req, super.getHeaders(), false)
  }

  callUpdateProfileSkills(req: UpdateProfileSkillsReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_SKILLS_URL, req)
  }

  callGetAllSkills(req: AllSkillsReq): Observable<BaseResp<Page<SkillResp>>> {
    return this.post(Endpoint.SKILL_ALL_URL, req)
  }

  callGetMostUsed(): Observable<BaseResp<SkillResp[]>> {
    return this.post(Endpoint.SKILL_MOST_USED, null, super.getHeaders(), false)
  }
}

export interface SkillResp {
  id: number
  name: string
  count?: number
  /**
   * UI-only property.
   */
  selected?: boolean
}

export interface UpdateProfileSkillsReq {
  ids: number[]
}

export interface SearchSkillReq {
  name: string
  page?: number
}

export interface AllSkillsReq {
  page: number
}
