<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [hold]="deleteConfirmDialogVisible"
            type="save"
            header="Edit your profile banner"
            i18n-header
            [disableSaveOption]="saving || loading"
            (discard)="closeEditComponent()"
            [save]="onComponentSave.bind(this)">

  <!--Hint-->
  <app-hint i18n>
    <span>
      We recommend the image size at least<span><b>&numsp;{{ requiredSizes[0] }}
      x {{ requiredSizes[1] }}&numsp;</b></span>pixels to
    avoid the unwanted image stretch.<br>
    <span *ngIf="maxWallpapers > 1">You are also able to upload up to <b>{{ maxWallpapers }}</b>
      images and change their order.</span>
    </span>
  </app-hint>

  <!--Wallpapers-->
  <div *ngIf="!loading; else skeleton">
    <div class="p-py-3 p-relative">
      <div *ngIf="wallpapers.length !== 0">
        <p-carousel [value]="wallpapers"
                    [numVisible]="1"
                    [numScroll]="1"
                    [circular]="true"
                    [showIndicators]="true"
                    [showNavigators]="wallpapers.length > 1"
                    #carousel>

          <ng-template let-item pTemplate="item">
            <div class="carousel-item p-px-1">
              <app-img [src]="item.url"
                       [background]="true"
                       styleClass="background-image fit-image rounded"></app-img>
            </div>
          </ng-template>
        </p-carousel>
      </div>

      <!--No wallpapers available-->
      <div *ngIf="wallpapers.length === 0">
        <div class="carousel-item">
          <i class="fa-solid fa-image default-icon"></i>
        </div>
      </div>

      <!--Max reached-->
      <small class="p-d-flex p-ai-center p-jc-center"
             *ngIf="wallpapers.length >= maxWallpapers">
        <i class="fa-solid fa-check p-mr-2"></i>
        <span i18n>
          You have reached the max number of images.
        </span>
      </small>
    </div>


    <!--File input-->
    <input id="fileInput"
           type="file"
           class="p-d-none"
           [disabled]="loading || saving || wallpapers.length >= maxWallpapers"
           (change)="onFileSelect($event.target['files'][0])"
           accept="image/png, image/jpeg"
           #fileInput>

    <!--BE-CHECK-->
    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.WALLPAPERS_UPDATE_FAILED"
                            [showAsBox]="true" i18n>
      Profile banner update has failed.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.WALLPAPERS_MAXIMUM_EXCEEDED"
                            [showAsBox]="true" i18n>
      You have exceeded the max number of images.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                            [showAsBox]="true" i18n>
      You are not permitted to this operation!
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_NOT_FOUND"
                            [showAsBox]="true" i18n>
      You are editing an image that doesn't exist. Please upload a new one.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_SIZE_TOO_SMALL"
                            [showAsBox]="true" i18n>
      Your image needs to be at least {{ requiredSizes[0] }} x {{ requiredSizes[1] }} pixels.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.FILE_FORMAT_NOT_SUPPORTED"
                            [showAsBox]="true" i18n>
      Please upload an image file. ['.<b>jpg</b>', or '.<b>png</b>']
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_UPLOAD_FAILED"
                            [showAsBox]="true" i18n>
      Your image was not uploaded.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND"
                            [showAsBox]="true" i18n>
      Your profile does not exists.
    </app-backend-validation>

    <!--Action Buttons-->
    <div class="p-d-flex p-jc-center p-ai-center p-mt-4">

      <!--Upload-->
      <app-button type="secondary"
                  icon="fa-solid fa-upload"
                  styleClass="p-mr-3"
                  pTooltip="Browse"
                  i18n-pTooltip
                  tooltipPosition="top"
                  *ngIf="maxWallpapers === 1"
                  [disabled]="loading || saving"
                  (clicked)="fileInput.click()"></app-button>

      <!--Add-->
      <app-button type="secondary"
                  icon="fa-solid fa-plus"
                  styleClass="p-mr-3"
                  pTooltip="Add"
                  i18n-pTooltip
                  tooltipPosition="top"
                  *ngIf="maxWallpapers > 1"
                  [disabled]="loading || saving || wallpapers.length >= maxWallpapers"
                  (clicked)="fileInput.click()"></app-button>

      <!--Remove-->
      <app-button type="danger-light"
                  styleClass="p-mr-3"
                  icon="fa-solid fa-trash"
                  pTooltip="Remove this image"
                  i18n-pTooltip
                  tooltipPosition="top"
                  [disabled]="loading || saving || wallpapers.length === 0"
                  (clicked)="onRemove(carousel?.page)"></app-button>

      <!--Sort-->
      <app-button type="secondary"
                  icon="fa-solid fa-sort"
                  pTooltip="Change order"
                  i18n-pTooltip
                  tooltipPosition="top"
                  *ngIf="maxWallpapers > 1"
                  [disabled]="loading || saving || wallpapers.length < 2"
                  (clicked)="onReorderClicked = !onReorderClicked"></app-button>
    </div>

    <!--Order list-->
    <div class="p-py-3"
         *ngIf="onReorderClicked && wallpapers.length > 1"
         [@fade]="onReorderClicked && wallpapers.length > 1">

      <!--Hint-->
      <app-hint i18n>
        Drag images in the order you want to display them.
      </app-hint>

      <p-orderList [value]="wallpapers"
                   (onReorder)="reloadWallpapers()"
                   [dragdrop]="true"
                   styleClass="p-mt-4">
        <ng-template let-item pTemplate="item">

          <div class="p-grid">
            <div class="p-col-11 p-sm-6">
              <!--Image-->
              <div class="orderlist-item">
                <div class="box-shadow rounded-medium fit-image" [style]="item.url | backgroundImage"></div>
              </div>
            </div>

            <!--Sort Icon-->
            <div class="p-col-1 p-sm-6 p-d-flex p-jc-end p-ai-center">
              <i class="fa-solid fa-sort sort-icon"></i>
            </div>
          </div>
        </ng-template>

      </p-orderList>

    </div>
  </div>
</app-dialog>

<!--Skeleton-->
<ng-template #skeleton>
  <div class="p-py-3">
    <p-skeleton width="100%" height="13rem"></p-skeleton>
  </div>
  <div class="p-d-flex p-jc-center p-mt-2">
    <p-skeleton shape="circle" size="2.5rem" styleClass="p-mr-3"></p-skeleton>
    <p-skeleton shape="circle" size="2.5rem" styleClass="p-mr-3"></p-skeleton>
    <p-skeleton shape="circle" size="2.5rem"></p-skeleton>
  </div>
</ng-template>

<!--Remove confirm dialog-->
<app-dialog *ngIf="deleteConfirmDialogVisible"
            [(show)]="deleteConfirmDialogVisible"
            [component]="this"
            type="danger"
            header="Are you sure?"
            i18n-header
            class="delete-confirm-dialog"
            successMessage="Removed successfully"
            i18n-successMessage
            saveLabel="Remove"
            i18n-saveLabel
            discardLabel="Keep"
            i18n-discardLabel
            [disableSaveOption]="saving || loading"
            (discard)="onRemoveConfirm(false)"
            (saved)="onRemoveConfirm(true)">

  <div class="p-py-2">
    <p i18n>The following image will be removed</p>
    <app-img [src]="wallpapers[deleteConfirmDialogImageIndex].url"
             alt="Profile wallpaper"
             i18n-alt
             styleClass="fit-image delete-image p-mt-2 rounded"></app-img>
  </div>
</app-dialog>
