/* eslint-disable */
/**
 *  Defines restrictions of parameters before further processing.
 */
export enum Restrictions {
  MIN_BIRTHDATE_YEARS = 16,

  TIMEOUT_EMAIL_CHANGE_SEC = 30 * 24 * 60 * 60,
  TIMEOUT_PHONE_CHANGE_SEC = 15 * 60,
  TIMEOUT_2FA_CODE_SEC = 15 * 60,
  VERIFIED_CHANGE_CODE_LENGTH = 6,

  MAX_IMAGE_EDGE_SIZE = 1920,
  MIN_IMAGE_AVATAR_SIZE = 300,
  MIN_IMAGE_WALLPAPER_EVENT_SIZE = 1080,

  MIN_IMAGE_WALLPAPER_WIDTH = 1920,
  MIN_IMAGE_WALLPAPER_HEIGHT = 1080,

  MIN_IMAGE_SHOWCASE_WIDTH = 1080,

  MAX_FILE_DESCRIPTION_LENGTH = 500,

  MIN_PASSWORD_LENGTH = 8,

  MIN_EMAIL_LENGTH = 5,

  MIN_CHAR_ID_LENGTH = 5,
  MAX_CHAR_ID_LENGTH = 30,

  MIN_DISPLAY_NAME_LENGTH = 5,
  MAX_DISPLAY_NAME_LENGTH = 30,

  MAX_BIO_LENGTH = 2000,

  MAX_URL_LENGTH = 100,

  MAX_PHONE_NUMBER_LENGTH = 16,

  MAX_GENRES_PER_PROFILE = 10,
  MAX_GENRE_NAME_LENGTH = 255,

  MAX_SKILLS_PER_PROFILE = 10,
  MAX_SKILL_NAME_LENGTH = 255,

  MAX_PROFESSION_NAME_LENGTH = 255,
  MAX_PROFESSION_PER_PROFILE = 3,
  MIN_PROFESSION_IMAGE_WIDTH = 1080,
  MIN_PROFESSION_IMAGE_HEIGHT = 1080,

  MAX_PROFILE_SEARCH_LENGTH = 30,

  MAX_NAME_FIELD_LENGTH = 30,

  MAX_ADDRESS_FIELD_LENGTH = 50,
  LEGAL_VAT_ID_LENGTH = 12,
  LEGAL_TAX_ID_LENGTH = 10,
  LEGAL_REGISTRATION_ID_LENGTH = 8,

  MIN_EVENT_DURATION_IN_MS_LENGTH = 15 * 60 * 1000,

  MIN_AND_MAX_DURATION_IN_MS_LENGTH = 5 * 60 * 1000,

  MAX_CALENDAR_ITEM_NAME_LENGTH = 50,
  MAX_CALENDAR_ITEM_DESCRIPTION_LENGTH = 600,

  MAX_OPENING_HOURS_RESTRICTION_MESSAGE_LENGTH = 50,

  MAX_CHAT_CHANNEL_NAME_LENGTH = 30,
  MAX_CHAT_CHANNEL_MESSAGES_LOAD = 50,
  MAX_CHAT_MESSAGE_LENGTH = 1000,
  MAX_CHAT_CHANEL_DAYS_OPEN = 14,

  MAX_POST_DESCRIPTION_LENGTH = 1000,
  MAX_POST_MEDIA_URL_LENGTH = 500,
  MAX_POST_MEDIA_ITEMS = 10,
  MAX_POST_MEDIA_IMAGE_SIZE = 500_000,

  MAX_COMMENT_LENGTH = 1000,

  MIN_HASHTAG_NAME_LENGTH = 3,
  MAX_HASHTAG_NAME_LENGTH = 20,
  MAX_HASHTAGS_PER_PROFILE = 10,

  MIN_PRICE_ITEM_NAME_LENGTH = 5,
  MAX_PRICE_ITEM_NAME_LENGTH = 100,
  MAX_PRICE_ITEM_DESCRIPTION_LENGTH = 1000,

  MAX_PROFILE_ORDER_NAME_LENGTH = 100,
  MAX_PROFILE_ORDER_DESCRIPTION_LENGTH = 2000,
  MAX_PROFILE_ORDER_CANCEL_REASON_LENGTH = 2000,
  MAX_PROFILE_ORDER_PROFILE_REPLY_LENGTH = 2000,

  /**
   * - Defines the moment before the order start after which the order can be confirmed (confirm location and arrival).
   * - Furthermore, after that moment, the order cannot be paid or rejected by the author.
   */
  PROFILE_ORDER_START_BEFORE_MINUTES = 15,

  /**
   * - Defines the moment before the order end after which the order cannot be rejected by the ordered profile.
   * - From this moment, only the author can open a dispute and freeze the order, since the ordered profile may not arrive.
   * - Furthermore, after that moment, both sides are able to review each-other.
   */
  PROFILE_ORDER_END_BEFORE_MINUTES = 15,

  /**
   * - Defines the moment from now, when the order's author can create a new order.
   * - (A user cannot set order's start earlier than X minutes from now.)
   */
  MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER = 60,

  /**
   * - Defines the days of expiration for a newly created order.
   * - The ordered profile has to accept/reject within X days.
   */
  MAX_PROFILE_ORDER_PROFILE_ACCEPT_DAYS = 2,

  /**
   * - Defines the days of expiration for a newly accepted order by the ordered profile.
   * - The author of the order has to pay within X days.
   */
  MAX_PROFILE_ORDER_AUTHOR_PAYMENT_DAYS = 1,
  /**
   * The max order price. (10 digits and 2 fractal digits)
   */
  MAX_PROFILE_ORDER_PRICE = 9_999_999_999.99,

  MAX_PROFILE_SEARCH_RADIUS_KM = 40,

  MAX_YOUTUBE_LINK_LENGTH = 300,
  MAX_SPOTIFY_LINK_LENGTH = 300,
  MAX_EMBED_SOUNDCLOUD_LINK_LENGTH = 1500,


  MAX_REVIEW_TEXT_LENGTH = 1000,
  /**
   * Determines how many days both sides can review each-other.
   */
  MAX_REVIEW_DAYS_AFTER_ORDER = 2,

  /**
   * Default timout for the basket, when no user is signed in, in minutes.
   * @deprecated created for the future
   */
  BASKET_DEFAULT_TIMEOUT = 3 * 60,
  /**
   * Timout for the basket, when a user is signed in, in minutes.
   * @deprecated created for the future
   */
  BASKET_LOGGED_USER_TIMEOUT = 24 * 60,
  /**
   * The maximum allowed penalty expiration in minutes.
   */
  MAX_PENALTY_MINUTES_EXPIRATION = 30 * 24 * 60, // 30 days

  MAX_PLATFORM_RATING_TEXT = 2000,

  /**
   * The maximum length for the slug field in various entities.
   */
  MAX_SLUG_LENGTH = 100,

  /**
   * Restrictions related to the city entity.
   */
  MAX_CITY_NAME_LENGTH = 100,
  MAX_CITY_COUNTRY_CODE_LENGTH = 2,

  MAX_SEO_PAGE_TITLE_LENGTH = 200,
  MIN_SEO_PAGE_HERO_IMAGE_WIDTH = 1920,
  MIN_SEO_PAGE_HERO_IMAGE_HEIGHT = 1080,
}
