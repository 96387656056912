<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"

            [save]="onComponentSave.bind(this)"
            (discard)="closeEditComponent()"
            [allowSaveOption]="false"
            header="Search a location">
  <div class="p-py-3 p-d-flex p-ai-center">
    <div class="w-100">
      <app-map-search [show]="true"
                      [autofocus]="true"
                      (profileOption)="profile.emit($event)"
                      (addressOption)="address.emit($event)"
      ></app-map-search>
    </div>
  </div>
</app-dialog>
