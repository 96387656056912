/**
 * - Enum of some order JobReminder categories used to show order-awaiting-confirm dialog.
 * - Must be synced with the backend JobReminder category names!
 */
export enum OrderJobCategory {
  ORDER_STATE_START = 'ORDER_STATE_START',
  ORDER_STATE_END = 'ORDER_STATE_END',
  ORDER_STATE_AFTER_ONE_HOUR = 'ORDER_STATE_AFTER_ONE_HOUR',
  ORDER_STATE_AFTER_ONE_DAY = 'ORDER_STATE_AFTER_ONE_DAY',
  ORDER_STATE_AFTER_TWO_DAYS = 'ORDER_STATE_AFTER_TWO_DAYS'
}
