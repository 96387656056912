import {Component, Input} from '@angular/core'
import {BriefProfileResp, OrderableProfileResp, ProfileResp} from '../../../service/profile.service'
import {CalendarItemNestedProfileResp} from '../../../service/calendar.service'
import {TooltipModule} from 'primeng/tooltip'
import {ProfileTypeIconPipe} from '../../../pipe/profile-type-icon.pipe'
import {ProfileTypePipe} from '../../../pipe/profile-type.pipe'

/**
 * Creates the <i></i> element with a proper icon of the provided profile.
 */
@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss'],
  standalone: true,
  imports: [
    TooltipModule,
    ProfileTypeIconPipe,
    ProfileTypePipe
  ]
})
export class ProfileIconComponent {

  @Input()
  profile: ProfileResp | BriefProfileResp | CalendarItemNestedProfileResp | OrderableProfileResp

  /**
   * Additional styling. This string will be added after the icon.
   */
  @Input()
  styleClass: string

  /**
   * Controls the visibility of a tooltip.
   */
  @Input()
  showTooltip = true
}
