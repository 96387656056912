import {Component, Input} from '@angular/core'
import {AbstractComponent} from '../../../abstract/abstract.component'

@Component({
  selector: 'app-badge-required',
  templateUrl: './badge-required.component.html',
  styleUrl: './badge-required.component.scss'
})
export class BadgeRequiredComponent extends AbstractComponent {

  /**
   * Used for custom styling.
   */
  @Input()
  styleClass: string

}
