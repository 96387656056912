import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {ProfileResp} from '../../../service/profile.service'
import {EditableComponent} from '../../abstract/editable.component'
import {ShowProfileSettingsDialogEvent} from '../profile.component'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {NavigationService} from '../../../service/ui/navigation.service'
import {firstValueFrom, Subscription} from 'rxjs'
import {PriceItemService} from '../../../service/price-item.service'
import {CompletionItem, CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'
import {defaultAvatar} from '../../../utils/asset.utils'
import {growAnimation} from '../../../animation/grow.animation'
import {StripeCompletionCheckResp} from '../../../service/stripe.service'

/**
 * Component to keep track of users progress with filling all of {@link CompletionType} items.
 * Show progress bar with percentage next to it.
 * Contains {@link DialogComponent} with sections for completed and uncompleted items.
 * Upon clicking on item, user is redirected based on {@link CompletionItem.onClick} action.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-profile-completion',
  templateUrl: './profile-completion.component.html',
  styleUrl: './profile-completion.component.scss'
})
export class ProfileCompletionComponent extends EditableComponent implements OnInit, OnDestroy {

  @Input()
  data: ProfileResp
  /**
   * Send signal to open dialog for settings with Location, Stripe and Professions.
   */
  @Output()
  showSettingsDialog = new EventEmitter<ShowProfileSettingsDialogEvent>()
  /**
   * The initial stripe completion check object from the profile component.
   */
  @Input()
  stripeCompletionCheck: StripeCompletionCheckResp

  @ViewChild('dialog')
  dialog: DialogComponent
  /**
   * Percentage of completed items to be shown next to the progress bar.
   */
  percentage = 0
  /**
   * Item to be shown in completed section.
   */
  completedItems: CompletionItem[] = []
  /**
   * Item to be shown in uncompleted section.
   */
  uncompletedItems: CompletionItem[] = []
  /**
   * Array of all items.
   */
  allItems: CompletionItem[] = []
  /**
   * Shows the required items information is missing label.
   */
  someRequiredItemsMissing: boolean
  /**
   * - This property controls the success message visibility of this component.
   * - The idea is that if the {@link percentage} is 100 in the OnInit hook, it will hide the component.
   * If the {@link percentage} gets 100 later, this property keeps visible to notifying user with a success message.
   */
  initiallyCompleted: boolean = true

  private completionCheckSub?: Subscription

  /**
   * Function for sorting in arrays.
   */
  readonly compareFunction = (a: CompletionItem, b: CompletionItem): number => {
    return a.type - b.type
  }

  constructor(
    private completionService: ProfileCompletionService,
    private navigation: NavigationService,
    private priceItemService: PriceItemService,
    private cd: ChangeDetectorRef) {
    super()
  }

  async ngOnInit(): Promise<void> {
    this.initItems()
    this.completionCheckSub = this.completionService.checkCompletion.subscribe(this.initArrays.bind(this))
    await this.initArrays()
    if (this.percentage === 100) {
      this.initiallyCompleted = true
    }
  }

  /**
   * Executes {@link CompletionItem.onClick} for provided {@link item}.
   * Before executing, it closes the dialog.
   * @param item
   */
  executeOnClick(item: CompletionItem): void {
    this.dialog.onDiscard()
    setTimeout(() => {
      item.onClick()
    }, DialogComponent.DIALOG_CLOSE_DURATION + 250)
  }

  /**
   * Initializes completion items into {@link allItems} array.
   */
  private initItems(): void {
    const showcase = this.data?.showcase
    this.allItems = [
      {
        name: $localize`Stripe`,
        description: $localize`Create Stripe account to be able to receive payments`,
        completed: false,
        type: CompletionType.STRIPE,
        required: true,
        onClick: () => this.showSettingsDialog.emit({show: true, highlightSetting: 'stripe'}),
        completionCheck: async (item) => {
          item.completed = this.stripeCompletionCheck?.completed || false
        }
      },
      {
        name: $localize`Price Items`,
        description: $localize`Create offers to attract potential customers`,
        completed: false,
        required: true,
        type: CompletionType.PRICE_ITEMS,
        onClick: () => this.navigation.toProfileServicesEditor(),
        completionCheck: async (item) => {
          item.completed = await this.checkPriceItemsExist()
        }
      },
      {
        name: $localize`Professions`,
        description: $localize`List your professional skills`,
        completed: this.data.professions?.length > 0,
        type: CompletionType.PROFESSIONS,
        required: true,
        onClick: () => this.showSettingsDialog.emit({show: true, highlightSetting: 'profession'}),
        completionCheck: async (item) => {
          item.completed = this.data.professions?.length > 0
        }
      },
      {
        name: $localize`Acting Address`,
        description: $localize`Provide your address of placement`,
        completed: !!this.data.address,
        type: CompletionType.LOCATION,
        required: true,
        onClick: () => this.showSettingsDialog.emit({show: true, highlightSetting: 'location'}),
        completionCheck: async (item) => {
          item.completed = !!this.data.address
        }
      },
      {
        name: $localize`Avatar`,
        description: $localize`Upload a professional picture of yourself`,
        completed: !!this.data.avatar,
        type: CompletionType.AVATAR,
        onClick: () => this.completionService.openCompletionItem(CompletionType.AVATAR),
        completionCheck: async (item) => {
          item.completed = this.data.avatar != null && this.data.avatar !== defaultAvatar(this.data.profileType)
        }
      },
      {
        name: $localize`Wallpaper`,
        description: $localize`Present your art to customers`,
        completed: this.data.wallpapers?.length > 0,
        type: CompletionType.WALLPAPER,
        onClick: () => this.completionService.openCompletionItem(CompletionType.WALLPAPER),
        completionCheck: async (item) => {
          item.completed = this.data.wallpapers?.length > 0
        }
      },
      {
        name: $localize`Biography`,
        description: $localize`Add a biography highlighting your talent`,
        completed: !!this.data.bio,
        type: CompletionType.BIOGRAPHY,
        onClick: () => this.completionService.openCompletionItem(CompletionType.BIOGRAPHY),
        completionCheck: async (item) => {
          item.completed = !!this.data.bio
        }
      },
      {
        name: $localize`Genres`,
        description: $localize`Tell users about genres of your music`,
        completed: this.data.genres?.length > 0,
        type: CompletionType.GENRES,
        onClick: () => this.completionService.openCompletionItem(CompletionType.GENRES),
        completionCheck: async (item) => {
          item.completed = this.data.genres?.length > 0
        }
      },
      {
        name: $localize`Skills`,
        description: $localize`Showcase your areas of expertise`,
        completed: this.data.skills?.length > 0,
        type: CompletionType.SKILLS,
        onClick: () => this.completionService.openCompletionItem(CompletionType.SKILLS),
        completionCheck: async (item) => {
          item.completed = this.data.skills?.length > 0
        }
      },
      {
        name: $localize`Images`,
        description: $localize`Show your talent to customers through images`,
        completed: !!this.data.hasImageShowcase,
        type: CompletionType.IMAGE_SHOWCASE,
        onClick: () => this.completionService.openCompletionItem(CompletionType.IMAGE_SHOWCASE),
        completionCheck: async (item) => {
          item.completed = !!this.data.hasImageShowcase
        }
      },
      {
        name: $localize`Social Media`,
        description: $localize`Add a YouTube video, Spotify, or SoundCloud track to make your profile more attractive.`,
        completed: !!(showcase?.youtube || showcase?.spotify || showcase?.soundcloud),
        type: CompletionType.SHOWCASE,
        onClick: () => this.completionService.openCompletionItem(CompletionType.SHOWCASE),
        completionCheck: async (item) => {
          item.completed = !!(showcase?.youtube || showcase?.spotify || showcase?.soundcloud)
        }
      },
    ]
  }

  /**
   * Executes {@link CompletionItem.completionCheck} on all items in {@link allItems}.
   * Divides items into {@link completedItems} and {@link uncompletedItems} and sorts both arrays with {@link compareFunction}.
   * Lastly calculates new percentage of completed items by {@link data}.
   */
  private async initArrays(): Promise<void> {
    this.someRequiredItemsMissing = false
    await Promise.all(
      this.allItems.map(async item => {
        await item.completionCheck(item)
        // some required items are missing label
        if (!item.completed && item.required) {
          this.someRequiredItemsMissing = true
        }
      })
    )

    // filter to completed items
    this.completedItems = this.allItems
      .filter(item => item.completed)
      .sort(this.compareFunction)

    // filter to incomplete items
    this.uncompletedItems = this.allItems
      .filter(item => !item.completed)
      .sort(this.compareFunction)

    this.calculatePercentage()
    this.cd.detectChanges()
  }

  /**
   * Calculates percentage of completed items.
   */
  private calculatePercentage(): void {
    this.percentage = Math.round((this.completedItems.length / this.allItems.length) * 100)
    if (this.percentage < 100) {
      this.initiallyCompleted = false
    }
  }

  /**
   * Calls the server API to get {@link PriceItemResp}s.
   * @return True if page with {@link PriceItemResp}s is not empty.
   */
  private async checkPriceItemsExist(): Promise<boolean> {
    const observable = this.unwrap(this.priceItemService.callGetPriceItemsFilter({
      profileId: this.data.profileId,
      page: 0
    }))
    const page = await firstValueFrom(observable)
    return page.totalElements > 0
  }

  ngOnDestroy(): void {
    this.completionCheckSub?.unsubscribe()
  }
}
