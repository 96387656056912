import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {growAnimation} from '../../../../animation/grow.animation'
import {AddressResp, AddressService, LegalEntityRest} from '../../../../service/address.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ConnectAccountSettingsResp, StripeService} from '../../../../service/stripe.service'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {LegalEntityService, SKLegalApiItemResp} from '../../../../service/legal-entity.service'
import {Restrictions} from '../../../../common/restrictions'
import {ProfileResp} from '../../../../service/profile.service'
import {LocaleOption} from '../../../../modules/user-settings/settings/settings-account/settings-account.component'
import {ProfileSettingsComponent} from '../profile-settings.component'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {environment} from '../../../../../environments/environment'
import {ProfileCompletionService} from '../../../../service/profile-completion.service'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-legal-entity-settings',
  templateUrl: './legal-entity-settings.component.html',
  styleUrls: ['./legal-entity-settings.component.scss']
})
export class LegalEntitySettingsComponent extends EditableComponent implements OnInit, OnDestroy {

  protected readonly env = environment

  @Input()
  data: ProfileResp
  /**
   * When the {@link data} profile has completed the Stripe account.
   */
  @Input()
  settings?: ConnectAccountSettingsResp
  /**
   * Force closes when the user clicked on the 'Activate' stripe.
   */
  @Output()
  forceClose = new EventEmitter<void>()
  /**
   * The {@link ProfileSettingsComponent} dialog.
   */
  @Input()
  profileSettingsDialog: ProfileSettingsComponent
  /**
   * This currently visible dialog.
   */
  @ViewChild('dialog')
  dialog: DialogComponent
  /**
   * The legal entity form.
   */
  form: FormGroup
  /**
   * All available dropdown options for legal entity.
   */
  legalEntityTypes: LegalEntityType[] = []
  /**
   * The user-selected legal entity type from {@link legalEntityTypes}.
   */
  selectedEntityType?: LegalEntityType
  /**
   * All available options for stripe locale.
   */
  stripeLanguages: LocaleOption[] = []
  /**
   * Loading of the stripe activate button.
   */
  stripeAccountLoading: boolean
  /**
   * Shows the legal entity type hint.
   */
  activateEnsureHint: boolean
  /**
   * Displays a loading when the search process for details of a registration number has start.
   */
  registrationNumberLoading: boolean
  /**
   * Current result of the registration number search.
   */
  registrationNumberSearch?: SKLegalApiItemResp = null
  /**
   * Current data of the legal entity.
   */
  legalEntity: LegalEntityRest
  /**
   * Determines, whether a Stripe Connect account is created.
   */
  stripeCreated: boolean
  /**
   * The Stripe Onboarding link from the backend.
   */
  stripeLink: string
  /**
   * Determines the current state when the user clicked on the 'Redirect to Stripe' button.
   */
  redirecting: boolean

  trans = {
    activateStripe: $localize`Activate Stripe`,
    next: $localize`Next`
  }

  /**
   * {@link form} value changes subscription.
   */
  private formSubs: Subscription[] = []

  constructor(
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private stripeService: StripeService,
    private legalEntityService: LegalEntityService,
    private changeRef: ChangeDetectorRef,
    private profileCompletionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    this.initLocaleOptions()
    this.initEntityTypes()
    this.initProperties()
    this.initForm()
  }

  /**
   * Initializes the properties based on {@link settings}.
   */
  private initProperties(): void {
    this.legalEntity = this.settings?.invoicingAddress?.legalEntity
    this.stripeCreated = !!this.settings
    if (this.settings) {
      this.initSelectedEntity()
    }
  }

  /**
   * - Creates a new link to a Stripe Account onboarding.
   * - Returns immediately, if the {@link activateEnsureHint} is not shown.
   */
  newStripeOnboarding(): void {
    if (!this.stripeCreated && !this.settings?.completed && !this.activateEnsureHint) {
      this.activateEnsureHint = true
      return
    }

    this.customCall(async () => {
      this.stripeAccountLoading = true
      this.stripeLink = await firstValueFrom(this.callOnboardStripe())
      if (this.stripeLink) {
        // disable blocking events to prevent from browser's popups
        this.profileSettingsDialog.dialog.disableBlockingEvents = true
        this.dialog.disableBlockingEvents = true
      }
    })
  }

  /**
   * - Fires when the user clicks on save button.
   * - At this point, the {@link settings} must be initialized.
   */
  async onSave(): Promise<void> {
    const fd = this.form.value
    let le = this.settings?.invoicingAddress?.legalEntity
    // return when no changes
    if (le !== null && fd.registrationNumber === le?.registrationNumber && fd.taxId === le?.taxId && fd.vatId === le?.vatId) {
      this.setSuccess(true)
      return
    }

    const resp = await firstValueFrom(this.callUpdateLegalEntity())
    if (resp && this.noServerMessages()) {
      le = resp.legalEntity
      this.settings.invoicingAddress = resp
      this.data.detailsProvided = ((!le.individual && !!le.registrationNumber) || le.individual)
      this.profileCompletionService.closeCompletionItemAndCheck()
    }
  }

  /**
   * Initializes the {@link form}.
   */
  private initForm(): void {
    const invAddr = this.settings?.invoicingAddress
    const le = invAddr?.legalEntity || this.settings?.stripeLegalEntity
    const formData = this.form?.value
    this.initSelectedEntity()

    // Init a form
    this.form = this.formBuilder.group({
      stripeLanguage: [this.findSelectedLocale(formData?.stripeLanguage)],
      registrationNumber: [formData?.registrationNumber || le?.registrationNumber || ''],
      taxId: [formData?.taxId || le?.taxId || ''],
      vatId: [formData?.vatId || le?.vatId || ''],
      entityType: [this.selectedEntityType || ''],
      vatPayer: [false]
    })

    // Disable already filled fields
    this.disableFilledFields()

    // Value changes
    this.formSubs.push(this.form.valueChanges.subscribe(this.onFormChange.bind(this)))
    this.formSubs.push(this.form.controls.registrationNumber.valueChanges.subscribe(this.registrationNumberChange.bind(this)))
  }

  /**
   * Fires when the {@link form} gets changed.
   */
  private onFormChange(): void {
    const fd = this.form.getRawValue() // includes also disabled fields
    const fc = this.form.controls

    const prevEntity = this.selectedEntityType
    // update selection
    this.selectedEntityType = fd.entityType
    if (!prevEntity?.isLegalEntity && fd.entityType.isLegalEntity) {
      this.changeRef.detectChanges()
    }

    if (prevEntity?.id !== fd.entityType.id) {
      this.clearForm()
    }

    // Clear errors
    if (!this.selectedEntityType.requiredRegNumber) {
      fc.registrationNumber.setErrors(null)
    }
    if (!this.selectedEntityType.requiredTaxId) {
      fc.taxId.setErrors(null)
    }
    if (!fd.vatPayer) {
      fc.vatId.setValue('', {emitEvent: false})
      fc.vatId.setErrors(null)
    } else {
      this.changeRef.detectChanges()
    }
  }

  /**
   * Clears enabled form fields. We suppose that disabled fields are filled, saved, and cannot be erased.
   */
  private clearForm(): void {
    const fc = this.form.controls
    const opts = {emitEvent: false}

    if (fc.registrationNumber.enabled) {
      fc.registrationNumber.setValue('', opts)
    }

    if (fc.taxId.enabled) {
      fc.taxId.setValue('', opts)
    }

    if (fc.vatId.enabled) {
      fc.vatId.setValue('', opts)
      fc.vatId.setErrors(null)
      fc.vatPayer.setValue(false, opts)
    }
  }

  /**
   * Fires when the registration number field of the {@link form} gets changed.
   */
  private registrationNumberChange(val: string): void {
    if (val?.length === Restrictions.LEGAL_REGISTRATION_ID_LENGTH && !this.settings?.completed) {
      this.customCall(async () => {
        this.registrationNumberLoading = true
        this.registrationNumberSearch = await firstValueFrom(this.callSearchLegalEntity(val))
      }, null, () => this.registrationNumberLoading = false)
    } else {
      this.registrationNumberSearch = null
    }
  }

  /**
   * Disables all already filled fields.
   */
  private disableFilledFields(): void {
    const invAddr = this.settings?.invoicingAddress
    const le = invAddr?.legalEntity || this.settings?.stripeLegalEntity
    const fc = this.form.controls
    // Disable if the invoice address already exists
    if (this.stripeCreated || (invAddr !== undefined && invAddr !== null)) {
      fc.entityType.disable()
      fc.stripeLanguage.disable()
    }
    if (le?.registrationNumber) {
      fc.registrationNumber.disable()
    }
    if (le?.taxId) {
      fc.taxId.disable()
    }
    if (le?.vatId) {
      fc.vatPayer.setValue(true)
      fc.vatPayer.disable()
      fc.vatId.disable()
    }
  }

  /**
   * Calls the server API to change the legal entity information of this profile.
   */
  private callUpdateLegalEntity(): Observable<AddressResp> {
    const le = this.settings?.invoicingAddress?.legalEntity
    const formData = this.form.value
    return this.unwrap(this.addressService.callUpdateStripeLegalEntity({
      registrationNumber: le?.registrationNumber || formData.registrationNumber,
      taxId: le?.taxId || formData.taxId,
      vatId: le?.vatId || formData.vatId
    }))
  }

  /**
   * Calls the server API to search for details of the {@link registrationNumber}.
   */
  private callSearchLegalEntity(registrationNumber: string): Observable<SKLegalApiItemResp> {
    return this.unwrap(this.legalEntityService.searchByRegistrationNumber(registrationNumber))
  }

  /**
   * Calls API to generate an activation link for artist account
   */
  private callOnboardStripe(): Observable<string> {
    const dbInd = this.settings?.invoicingAddress?.legalEntity?.individual
    const isIndividual = (dbInd !== null && dbInd !== undefined && dbInd)
    const fd = this.form.value
    return this.unwrap(this.stripeService.callCreateConnectAccount({
      individual: isIndividual || fd.entityType?.isIndividual,
      stripeLanguage: this.settings?.stripeLanguage || fd.stripeLanguage.code,
      isLegalEntity: fd.entityType?.isLegalEntity,
      legalEntityDetails: (fd.entityType?.isLegalEntity) ? {
        taxId: fd.taxId,
        vatId: fd.vatId || null,
        registrationNumber: (this.selectedEntityType.requiredRegNumber) ? fd.registrationNumber : null
      } : null
    }))
  }

  /**
   * Initialized the {@link selectedEntityType} by the {@link settings}.
   */
  private initSelectedEntity(): void {
    const s = this.settings
    const le = s?.invoicingAddress?.legalEntity || s?.stripeLegalEntity
    let type: LegalEntityTypeID
    if (!s?.isLegalEntity && s?.individual) {
      type = LegalEntityTypeID.NO_ENTITY

    } else if (le?.taxId && !le?.registrationNumber && s?.individual) {
      type = LegalEntityTypeID.TAX_ONLY

    } else if (le?.taxId && le?.registrationNumber && s?.individual) {
      type = LegalEntityTypeID.SELF_EMPLOYED

    } else if (le?.taxId && le?.registrationNumber && !s?.individual) {
      type = LegalEntityTypeID.COMPANY
    }
    for (const legalType of this.legalEntityTypes) {
      if (legalType.id === type) {
        this.selectedEntityType = legalType
        return
      }
    }
    // default
    this.selectedEntityType = undefined
  }

  /**
   * - Returns a selected {@link LocaleOption} based on the input {@link stripeLocale}.
   * - If no locale is set, the default gets returned.
   */
  private findSelectedLocale(stripeLocale?: string): LocaleOption {
    let locale: LocaleOption
    if (stripeLocale) {
      locale = this.stripeLanguages.find(it => it.code === stripeLocale)
    } else {
      locale = this.stripeLanguages.find(it => it.code === 'SK')
    }
    return locale
  }

  /**
   * Initializes the {@link stripeLanguages} array of all available locale options.
   */
  private initLocaleOptions(): void {
    this.stripeLanguages = [
      {
        title: 'Slovak',
        code: 'SK',
        flag: 'fi fi-sk'
      }/*,
      {
        title: 'United States',
        code: 'US',
        flag: 'fi fi-us'
      }*/
    ]
  }

  /**
   * Initializes the {@link legalEntityTypes} array.
   */
  private initEntityTypes(): void {
    // already initialized
    if (this.legalEntityTypes?.length > 0) {
      return
    }

    this.legalEntityTypes = [{
      id: LegalEntityTypeID.NO_ENTITY,
      name: $localize`Individual - Non-entrepreneur`,
      icon: 'fa-solid fa-user',
      description: $localize`Individual without any legal entity registration.`,
      isIndividual: true,
      isLegalEntity: false,
      requiredRegNumber: false,
      requiredTaxId: false
    }, {
      id: LegalEntityTypeID.TAX_ONLY,
      name: $localize`Individual - Artist entrepreneur`,
      icon: 'fa-solid fa-user',
      description: $localize`Individual <b>with a registered Tax ID only</b> and <b>without a legal entity registration number</b>. (Can be applied only for artistic professions).`,
      isIndividual: true,
      isLegalEntity: true,
      requiredRegNumber: false,
      requiredTaxId: true,
    }, {
      id: LegalEntityTypeID.SELF_EMPLOYED,
      name: $localize`Self-Employed`,
      icon: 'fa-solid fa-user-tie',
      description: $localize`Individual with a registered legal entity.`,
      isIndividual: true,
      isLegalEntity: true,
      requiredRegNumber: true,
      requiredTaxId: true,
    }, {
      id: LegalEntityTypeID.COMPANY,
      name: $localize`Company`,
      icon: 'fa-solid fa-user-group',
      description: $localize`A company I fully or partially own.`,
      isIndividual: false,
      isLegalEntity: true,
      requiredRegNumber: true,
      requiredTaxId: true,
    }]
  }

  ngOnDestroy(): void {
    this.formSubs?.forEach(it => it.unsubscribe())
  }
}

/**
 * Layout representing available options in {@link LegalEntitySettingsComponent}.
 */
export interface LegalEntityType {
  /**
   * A business type.
   */
  id: LegalEntityTypeID
  name: string
  icon: string
  description: string
  /**
   * Represents whether the legal entity is individual or company.
   */
  isIndividual: boolean
  /**
   * Represents whether the individual is a registered legal entity.
   */
  isLegalEntity: boolean
  /**
   * Determines whether the registration number is required based on the legal type ({@link id}).
   */
  requiredRegNumber: boolean
  /**
   * Determines whether the Tax ID is required based on the legal type ({@link id}).
   */
  requiredTaxId: boolean
}

/**
 * Unique identification of the {@link LegalEntityType}.
 */
export enum LegalEntityTypeID {
  /**
   * A business type where the person has no registered legal entity.
   */
  NO_ENTITY = 'NO_ENTITY',
  /**
   * A business type where the person has registered only TaxID without any legal registration number of an individual / company.
   */
  TAX_ONLY = 'TAX_ONLY',
  /**
   * A business type where the person is individual and has a legal registration number of a legal entity with its Tax ID.
   */
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  /**
   * A business type where the person fully, or partially owns a company.
   */
  COMPANY = 'COMPANY'
}
