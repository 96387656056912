import {Permission} from './permissions'
import {UserResp} from '../service/user.service'

/**
 * Contains all available roles in this project.
 * # Needs to be synchronized with the backend!
 */
export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN'
}

/**
 * Returns an array of permissions for the {@link Role}.
 * # This must be the same definition structure as the backend has!
 */
export function getPermissions(r: Role): Permission[] {
  switch (r) {
    case Role.USER:
      return []
    case Role.ADMIN:
      return [
        Permission.RESOLVE_DISPUTE,
        Permission.RESOLVE_VIOLATIONS,
      ]
  }
}

/**
 * Checks whether the given {@link user} has a {@link role}.
 */
export function hasRole(user: UserResp, role: Role): boolean {
  return user?.roles.some(it => it === role)
}
