import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core'
import {PrimeTemplate} from 'primeng/api'
import {VirtualScroller, VirtualScrollerModule} from 'primeng/virtualscroller'
import {Page} from 'src/app/rest/page-resp'
import {scrollToIndex} from '../../../utils/scroll.utils'
import {NgIf, NgTemplateOutlet} from '@angular/common'

/**
 * - Represents a custom implementation of {@link VirtualScroller} component.
 * - Contains better lazy-loading event emitting, utilities methods for scrolling, and custom css variables.
 * - To set the height of the viewport, it needs to be set the <b>'--scroller-height'</b> css variable
 * in the <b>':host ::ng-deep'</b> container.
 */
@Component({
  selector: 'app-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss'],
  imports: [
    VirtualScrollerModule,
    NgTemplateOutlet,
    NgIf
  ],
  standalone: true
})
export class ScrollerComponent implements AfterViewInit {

  /**
   * Input value of {@link Page}.
   */
  @Input()
  value: Page<any>

  /**
   * Enables the lazy loading emitters.
   */
  @Input()
  lazy: boolean

  /**
   * Appears the loading spinner at the top of the scroller.
   */
  @Input()
  showTopLoading: boolean

  /**
   * Appears the loading spinner at the bottom of the scroller.
   */
  @Input()
  showBottomLoading: boolean

  /**
   * The unique css class of one item in the scroller viewport.
   * By this class it is known to what index the scrollbar should be scrolled.
   */
  @Input()
  itemClass: string

  /**
   * Fires when the user exceeds the top limit and needs to load more data.
   */
  @Output()
  topLazyLoad = new EventEmitter<any>()

  /**
   * Fires when the user exceeds the bottom limit and needs to load more data.
   */
  @Output()
  bottomLazyLoad = new EventEmitter<any>()

  /**
   * Fires when the scrollbar is moved.
   */
  @Output()
  scrollChange = new EventEmitter<any>()

  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>

  /**
   * The main content template of the scroller.
   */
  itemTemplate: PrimeTemplate

  /**
   * The loading template of the scroller.
   */
  loadingTemplate: PrimeTemplate

  /**
   * Defines the visibility of the {@link scroller}.
   * It is required for the {@link #refreshVisibility} function.
   */
  visible = true

  /**
   * The {@link VirtualScroller} from the template.
   */
  @ViewChild('scroller') set content(scroller: VirtualScroller) {
    if (scroller) { // sets the scroller instance after the ngIf is true.
      this.scroller = scroller
      this.subscribeScrollChanges()
    }
  }

  private scroller: VirtualScroller

  constructor(
    public elementRef: ElementRef
  ) {
  }

  ngAfterViewInit(): void {
    this.itemTemplate = this.templates?.find(it => it.name === 'item')
    this.loadingTemplate = this.templates?.find(it => it.name === 'loadingItem')
  }

  /**
   * If the {@link Page.offsetPosition} is present, it scrolls to that position on value changes.
   */
  public performInitialScrolling(): void {
    setTimeout(() => {
      this.scrollOffset(this.value?.offsetPosition + 100, 'auto')
    })
  }

  /**
   * Subscribes to viewport's scrollbar position changes.
   * It emits the {@link scrollChange} emitter on any position changes.
   * - The {@link topLazyLoad} emitter starts emitting if the scrollbar position exceeds the top limit.
   * - The {@link bottomLazyLoad} emitter starts emitting if the scrollbar position exceeds the bottom limit.
   */
  private subscribeScrollChanges(): void {
    this.scroller?.scroller?.onScroll.subscribe((value) => {
      this.scrollChange.emit(value)

      if (this.lazy && this.value?.totalElements !== this.value?.content?.length) {
        if (this.measureScrollOffset('top') <= 700) {
          this.topLazyLoad.emit(value)
        }

        if (this.measureScrollOffset('bottom') <= 700) {
          this.bottomLazyLoad.emit(value)
        }
      }
    })
  }

  /**
   * Scrolls to the given offset.
   *
   * @param topOffset The offset from the top.
   * @param type What type of scrolling should be performed.
   */
  scrollOffset(topOffset: number, type: ScrollBehavior = 'auto'): void {
    // this.scroller?.viewport.scrollToOffset(topOffset, type)
    this.scroller.scroller.scrollTo({
      top: topOffset,
      behavior: type
    })
  }

  /**
   * Measures the scroll offset of the view.
   *
   * @param from Measuring from a specific side.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  measureScrollOffset(from?: ScrollOffsetSide): number {
    // this.scroller.
    // return this.scroller?.viewport.measureScrollOffset(from)
    return 800
  }

  /**
   * Scrolls to the specific index of founded elements by the {@link itemClass}.
   *
   * @param index The specific index of the item.
   * @param type What type of scrolling should be performed.
   */
  scrollToIndex(index: number, type: ScrollBehavior = 'auto'): void {
    scrollToIndex(this.itemClass, index, type)
  }

  /**
   * Scrolls to the first element found by the {@link itemClass}.
   *
   * @param type Type of scrolling.
   */
  scrollTop(type: ScrollBehavior = 'auto'): void {
    this.scrollToIndex(0, type)
  }

  /**
   * Scrolls to the last element found by the {@link itemClass}.
   *
   * @param type Type of scrolling.
   */
  scrollBottom(type: ScrollBehavior = 'auto'): void {
    setTimeout(() => {
      this.scroller.scrollToIndex(this.scroller.value.length - 1, type)
    })
  }

  /**
   * Returns true if the scrollbar offset from the bottom is lower than or the same as the given offset.
   */
  isBottomScrolled(bottomOffset: number = 0): boolean {
    return this.measureScrollOffset('bottom') <= bottomOffset
  }

  /**
   * Returns true if the scrollbar offset from the top is lower than or the same as the given offset.
   */
  isTopScrolled(topOffset: number = 0): boolean {
    return this.measureScrollOffset('top') <= topOffset
  }

  /**
   * Refresh the scroller by scrolling the scrollbar by 1 pixel.
   */
  refresh(): void {
    //const offset = this.measureScrollOffset('top')
    //this.scroller?.viewport.scrollToOffset(offset + 1)
    //this.scroller?.viewport.scrollToOffset(offset)
  }

  /**
   * Refresh the scroller based on the visibility change.
   */
  refreshVisibility(): void { // TODO find the better approach
    this.visible = false
    setTimeout(() => {
      this.visible = true
    })
  }
}

/**
 * Represents the side from which the scrollbar offset measurement is measured.
 */
export type ScrollOffsetSide = 'top' | 'left' | 'right' | 'bottom' | 'start' | 'end'
