import {Pipe, PipeTransform} from '@angular/core'
import {ProfileType} from '../common/profile-type'

@Pipe({
  name: 'profileIcon',
  standalone: true
})
export class ProfileTypeIconPipe implements PipeTransform {
  transform(profile: any): string {
    if (!profile) {
      return ''
    }

    switch (profile.profileType) {
      case ProfileType.USER:
        return 'fa-solid fa-user'
      case ProfileType.ARTIST:
        return 'fa-solid fa-star'
      case ProfileType.ENTERPRISE:
        return 'fa-solid fa-location-dot'
      case ProfileType.EVENT:
        return 'fa-solid fa-calendar-days'
      default:
        return 'fa-solid fa-question'
    }
  }
}
