import {Component, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../abstract/editable.component'
import {combineLatest, firstValueFrom, Subscription} from 'rxjs'
import {UserResp, UserService} from '../../service/user.service'
import {ProfileResp, ProfileService} from '../../service/profile.service'
import {map} from 'rxjs/operators'
import {ProfileType} from '../../common/profile-type'

@Component({
  selector: 'app-feature-showcase',
  templateUrl: './feature-showcase.component.html',
  styleUrl: './feature-showcase.component.scss'
})
export class FeatureShowcaseComponent extends EditableComponent implements OnInit, OnDestroy {

  /**
   * Status of dialog openness.
   */
  showDialog = false

  /**
   * The current page of the showcase.
   */
  page = 0

  /**
   * Subscribe to user and profile observables to only show dialog if both are loaded.
   */
  subscription: Subscription

  /**
   * Timeout for the dialog to show. Prevent showing the dialog twice.
   */
  showDialogTimeout: any

  /**
   * The showcase items shown to the user.
   */
  items: ShowcaseItem[] = []
  /**
   * Artist-specific items
   */
  artistItems: ShowcaseItem[] = [
    {
      name: $localize`Welcome to Umevia!`,
      description: $localize`We are excited that you have joined our community. In this short guide, we will show you the main features of the app. Let's get started!`,
      imageSm: 'assets/logo/logo-color.svg',
      imageLg: 'assets/logo/logo-color.svg'
    },
    {
      name: $localize`Artistic profile`,
      description: $localize`Impress customers with your past work and experience.`,
      imageSm: 'assets/features-showcase/portfolio-sm.png',
      imageLg: 'assets/features-showcase/portfolio-lg.png'
    },
    {
      name: $localize`Availability calendar`,
      description: $localize`<b>Automatically managed</b> based on your orders.`,
      imageSm: 'assets/features-showcase/calendar-sm.png',
      imageLg: 'assets/features-showcase/calendar-lg.png'
    },
    {
      name: $localize`Price offers`,
      description: $localize`Create <b>not binding price offers</b> for your services.`,
      imageSm: 'assets/features-showcase/offer-sm.png',
      imageLg: 'assets/features-showcase/offer-lg.png'
    },
    {
      name: $localize`Orders`,
      description: $localize`Receive the orders from customers right in the app.`,
      imageSm: 'assets/features-showcase/order-sm.png',
      imageLg: 'assets/features-showcase/order-lg.png'
    },
    {
      name: $localize`Manager`,
      description: $localize`Manage, received orders and save time.`,
      imageSm: 'assets/features-showcase/manager-sm.png',
      imageLg: 'assets/features-showcase/manager-lg.png'
    },
    {
      name: $localize`Invoices and documents`,
      description: $localize`All invoices and required documents available in one place.`,
      imageSm: 'assets/features-showcase/invoice-sm.png',
      imageLg: 'assets/features-showcase/invoice-lg.png'
    }
  ]
  /**
   * Customer-specific items
   */
  customerItems: ShowcaseItem[] = [
    {
      name: $localize`Welcome to Umevia!`,
      description: $localize`We are excited that you have joined our community. In this short guide, we will show you the main features of the app. Let's get started!`
    },
    {
      name: $localize`Profile catalog`,
      description: $localize`Discover various artists is our catalog. There is right one for every event.`,
      imageSm: 'assets/features-showcase/catalog-sm.png',
      imageLg: 'assets/features-showcase/catalog-lg.png'
    },
    {
      name: $localize`Use filters`,
      description: $localize`Use filters to find the right artist for you.`,
      imageSm: 'assets/features-showcase/filters-sm.png',
      imageLg: 'assets/features-showcase/filters-lg.png'
    },
    {
      name: $localize`Artistic profile`,
      description: $localize`Get to know the artist through their profile before your order.`,
      imageSm: 'assets/features-showcase/portfolio-sm.png',
      imageLg: 'assets/features-showcase/portfolio-lg.png'
    },
    {
      name: $localize`Availability calendar`,
      description: $localize`Check wheter the artist is available for your date and time.`,
      imageSm: 'assets/features-showcase/calendar-sm.png',
      imageLg: 'assets/features-showcase/calendar-lg.png'
    },
    {
      name: $localize`Price offers`,
      description: $localize`Discover the services which artist offer, with their <b>base price</b>.`,
      imageSm: 'assets/features-showcase/offer-sm.png',
      imageLg: 'assets/features-showcase/offer-lg.png'
    },
    {
      name: $localize`Manager`,
      description: $localize`Manage, ordered artists for various events.`,
      imageSm: 'assets/features-showcase/manager-sm.png',
      imageLg: 'assets/features-showcase/manager-lg.png'
    }
  ]

  protected trans = {
    next: $localize`Next`,
    finish: $localize`Finish`
  }

  constructor(
    private userService: UserService,
    private profileService: ProfileService) {
    super()
  }

  ngOnInit(): void {
    this.initializeSubscriptions()
  }

  initializeSubscriptions(): void {
    this.subscription = combineLatest([
      this.profileService.currentProfile,
      this.userService.user
    ]).pipe(
      map(([profile, user]) => {
        this.checkIfShowDialog(profile, user)
      })).subscribe()
  }

  async checkIfShowDialog(profile: ProfileResp, user: UserResp): Promise<void> {
    clearTimeout(this.showDialogTimeout)
    this.showDialogTimeout = setTimeout(() => {
      this.page = 0
      // If the user is not logged in, or the showcase is already seen, do not show the dialog.
      if (!user || !profile) {
        this.showDialog = false
      } else {
        // If the user is new, show the showcase dialog.
        if (profile.showFeatureShowcase) {
          switch (profile.profileType) {
            case ProfileType.ARTIST:
              this.items = this.artistItems
              break
            default:
              this.items = this.customerItems
          }
          this.showDialog = true
        }
      }
    }, 500)
  }

  /**
   * Go to the previous page.
   */
  onPrevious(): void {
    this.page--
    if (this.page < 0) {
      this.page = 0
    }
  }

  /**
   * If the current page is the last one, call the service to mark the showcase as seen and close the dialog.
   * Otherwise, go to the next page.
   */
  onNext(): void {
    if (this.page === this.items.length - 1) {
      this.markAsSeen()
    } else {
      this.page = (this.page + 1) % this.items.length
    }
  }

  markAsSeen(): void {
    this.callAndFinish(async () => {
      await firstValueFrom(this.profileService.callSeenFeatureShowcase())
    })
    this.showDialog = false
  }

  /**
   * Handle the page change event emitted by carousel component gestures.
   */
  onPageChange(event: any): void {
    this.page = event.page
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}

/**
 * Showcase item interface. There are two image sizes for mobile and desktop view.
 */
interface ShowcaseItem {
  name: string
  description: string
  imageSm?: string
  imageLg?: string
}
