import {Injectable} from '@angular/core'
import {Meta, Title} from '@angular/platform-browser'
import {ProfileResp} from '../profile.service'
import {truncateWithEllipses} from '../../utils/string.utils'
import {ImageVariant} from '../../common/image-variant'
import {BASE_SITE_URL} from '../../../environments/environment'

/**
 * Helper service for setting the meta tags.
 */
@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(
    private meta: Meta,
    private title: Title) {
  }

  /**
   * Sets the meta tags for the profile page.
   * @param profile
   */
  setProfileTags(profile: ProfileResp): void {
    // Create title. Contains name and professions.
    let title = `${profile.displayName} - `
    for (const [i, profession] of profile.professions.entries()) {
      title += ((i + 1) === profile.professions.length) ? ` ${profession.name}.` : ` ${profession.name},`
    }
    title = title.trim()
    // Create description. Contains skills, genres and bio text.
    let description = $localize`Book ${profile.displayName} on Umevia and make your event unforgettable!`
    description = this.createListOfData(profile.professions.map(it => it.name), description, $localize`Professions`)
    description = this.createListOfData(profile.skills.map(it => it.name), description, $localize`Skills`)
    description += profile.bio ? ` ${truncateWithEllipses(profile.bio, 100)}` : ''
    if (description.length === 0) {
      description = $localize`Book ${profile.displayName} on Umevia for your event! Explore talents of ${profile.displayName} and make your event an unforgettable masterpiece.`
    }
    description = description.trim()

    this.title.setTitle(title)
    this.meta.updateTag({name: 'description', content: description})

    this.meta.updateTag({name: 'og:title', content: title})
    this.meta.updateTag({name: 'og:type', content: 'profile'})
    this.meta.updateTag({name: 'og:description', content: description})

    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'})
    this.meta.updateTag({name: 'twitter:title', content: title})
    this.meta.updateTag({name: 'twitter:description', content: description})
    if (profile.wallpapers.length > 0) {
      const wallpaper = `${profile.wallpapers?.[0]}/${ImageVariant.WALLPAPER_800.fileName}`
      this.meta.updateTag({name: 'og:image', content: wallpaper})
      this.meta.updateTag({name: 'twitter:image', content: wallpaper})
    }
  }

  /**
   * Sets custom data as meta tags. All values which should be set must be passed in the {@link customTags} object.
   */
  setCustomTags(customTags: CustomTags): void {
    this.title.setTitle(`Umevia | ${customTags.title}`)
    this.meta.updateTag({name: 'description', content: customTags.description})

    this.meta.updateTag({name: 'og:title', content: customTags.title})
    this.meta.updateTag({name: 'og:type', content: ''})
    this.meta.updateTag({name: 'og:description', content: customTags.description})
    this.meta.updateTag({name: 'og:image', content: customTags?.image ? customTags.image : ''})

    this.meta.updateTag({name: 'twitter:card', content: 'summary'})
    this.meta.updateTag({name: 'twitter:title', content: customTags.title})
    this.meta.updateTag({name: 'twitter:description', content: customTags.description})
    this.meta.updateTag({name: 'twitter:image', content: customTags?.image ? customTags.image : ''})
  }

  /**
   * Removes all custom meta tags and set default ones.
   */
  setDefaultMetatags(): void {
    const description = $localize`Book artists for your events! The calendar of availability helps you plan your events effectively without unnecessary complications. Filter artists by availability, price, professions, or reviews.`
    const title = $localize`Umevia | Book artists`
    const image = `${BASE_SITE_URL}/assets/site/webpage-image.jpg`

    // Description
    this.meta.updateTag({name: 'description', content: description})
    this.meta.updateTag({name: 'og:description', content: description})
    this.meta.updateTag({name: 'twitter:description', content: description})

    // Title
    this.title.setTitle(title)
    this.meta.updateTag({name: 'og:title', content: title})
    this.meta.updateTag({name: 'twitter:title', content: title})

    // Images
    this.meta.updateTag({name: 'og:image', content: image})
    this.meta.updateTag({name: 'twitter:image', content: image})

    // Type
    this.meta.updateTag({name: 'og:type', content: `platform`})

    // Locale
    this.meta.updateTag({name: 'og:locale', content: 'sk_SK'})
    this.meta.updateTag({name: 'og:locale:alternate', content: 'en_US'})

    // Twitter card
    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'})
  }

  /**
   * Creates a list of data from the given {@link data} with name {@link dataName}.
   */
  private createListOfData(data: string[], description: string, dataName: string): string {
    if (data) {
      description += ` ${dataName}:`
      for (const [i, name] of data.entries()) {
        if (i < 3) {
          description += ((i + 1) === data.length) ? ` ${name}.` : ` ${name},`
        } else if (i === 3) {
          description += ` ${name}.`
        }
      }
    }
    return description
  }
}

/**
 * Used to update meta tags with custom data. All attributes are set to meta tags.
 * If some attribute is not set, empty string will be used in meta tag.
 */
export interface CustomTags {
  title: string
  description: string
  image?: string
}
