<!-- CTA -->
<div class="cta-box rounded-medium p-p-3 p-d-flex p-flex-column p-ai-center p-jc-center">
  <app-avatar [profile]="profile"
              [sizePx]="100"
              [redirect]="false"/>
  <app-profile-names [profile]="profile"
                     styleClass="p-mt-1"
                     [charIdVisible]="false"
                     [displayNameLinesCount]="1"
                     [size]="4"/>

  <div *ngIf="profile?.minPriceItem as pi" class="p-mt-2 p-mb-2 price">
    <div class="p-d-flex p-ai-center">
      <div class="p-pr-2" i18n>From</div>
      <div class="price word-break p-text-bold">{{ (pi.price || 0) | price }}</div>
    </div>
    <div *ngIf="!pi.fixedPrice" class="p-text-center cta-hour" i18n>/hour</div>
  </div>
  <app-button type="cta"
              label="See offer"
              i18n-label
              [highlight]="true"
              icon="fa-solid fa-cart-arrow-down"
              iconPos="right"
              styleClass="cta-button"
              (clicked)="scrollToOffer.emit()"></app-button>
  <div class="p-mt-2" i18n>You will not pay anything yet</div>
</div>
