import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {ApiComponent} from '../../../component/abstract/api.component'
import {firstValueFrom, Subscription} from 'rxjs'
import {MenuItem} from 'primeng/api'
import {ActivatedRoute} from '@angular/router'
import {Location} from '@angular/common'
import {NavigationService} from '../../../service/ui/navigation.service'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {SettingCategory} from '../../../common/settings-category'


/**
 * Component to update and review versions of platforms legal documents.
 */
@Component({
  selector: 'app-admin-legal-documents',
  templateUrl: './admin-platform-documents.component.html',
  styleUrls: ['./admin-platform-documents.component.scss']
})
export class AdminPlatformDocumentsComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * Controls the visibility of the sidebar on small screens.
   */
  settingsSidebarVisible = false

  /**
   * All available settings.
   */
  menuItems: MenuItem[] = []
  /**
   * The current selected setting item.
   */
  selectedMenuItem: MenuItem
  /**
   * The current url params subscription.
   */
  urlChangeSub?: Subscription

  LDC: typeof LegalDocumentsCategory = LegalDocumentsCategory

  constructor(
    public changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location) {
    super()
  }

  ngOnInit(): void {
    this.initMenuItems()

    // URL Changes
    this.urlChangeSub = this.route.params.subscribe(async () => {
      await this.parseURL()
      this.changeRef.detectChanges()
    })
  }

  /**
   * Fires when the user clicked on the {@link item} item.
   */
  onSelectedMenuItem(item: MenuItem): void {
    this.menuItems.forEach(it => {
      it['selected'] = undefined
    })
    item['selected'] = true

    // change URL
    this.location.go(`${NavigationService.ADMIN}/${NavigationService.ADMIN_LEGAL_DOCUMENTS}/${item.id}`)
    this.selectedMenuItem = item
    this.settingsSidebarVisible = false
  }

  /**
   * Parses the url of the setting.
   */
  private async parseURL(): Promise<void> {
    const params = await firstValueFrom(this.route.params)
    const category = params?.[NavigationService.ADMIN_LEGAL_DOCUMENTS_CATEGORY] || LegalDocumentsCategory.UPDATE
    this.selectCategory(category)
  }

  /**
   * Selects an appropriate item based on the {@link SettingCategory}.
   */
  private selectCategory(setting: LegalDocumentsCategory): void {
    for (const it of this.menuItems) {
      if (it.id === setting) {
        this.onSelectedMenuItem(it)
        return
      }
    }
  }

  /**
   * Initializes the {@link menuItems}.
   */
  private initMenuItems(): void {
    this.menuItems = [
      {id: LegalDocumentsCategory.UPDATE, title: $localize`New version`, icon: 'fa-solid fa-plus'},
      //{id: LegalDocumentsCategory.VERSIONS_LIST, title: $localize`All versions`, icon: 'fa-solid fa-list'},
    ]
    this.onSelectedMenuItem(this.menuItems[0])
  }

  ngOnDestroy(): void {
    this.urlChangeSub?.unsubscribe()
  }
}

/**
 * Enum with categories for legal documents subsection of admin dashboard.
 */
export enum LegalDocumentsCategory {
  UPDATE = 'update',
  VERSIONS_LIST = 'versions_list'
}
