import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core'

/**
 * Emits an element when it is going to init.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngInit]',
  standalone: true
})
export class InitDirective implements OnInit {

  @Output()
  ngInit = new EventEmitter<ElementRef>()

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit(): void {
    this.ngInit.emit(this.eleRef)
  }
}
