import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ChannelResp, ChatChannelService} from '../../../service/chat-channel.service'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {NgForOf, NgIf} from '@angular/common'
import {LazyListComponent} from '../../common/list/lazy-list/lazy-list.component'
import {Observable, Subscription} from 'rxjs'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {ProfileNamesComponent} from '../../common/profile-names/profile-names.component'
import {FirstChannelProfilePipe} from '../../../pipe/first-channel-profile.pipe'
import {SharedModule} from 'primeng/api'
import {SkeletonModule} from 'primeng/skeleton'
import {RippleModule} from 'primeng/ripple'
import {VarDirective} from '../../../directive/var.directive'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {ProfileProfessionsComponent} from '../../profile/profile-professions/profile-professions.component'
import {PixelsPipe} from '../../../pipe/pixels.pipe'
import {OrderManagerModule} from '../../../modules/order-manager/order-manager.module'
import {
  ProfileOrderChatComponent
} from '../../../modules/order-manager/profile-manager/profile-order-chat/profile-order-chat.component'

@Component({
  selector: 'app-chat-inbox',
  standalone: true,
  imports: [
    DialogComponent,
    NgIf,
    LazyListComponent,
    ProfileNamesComponent,
    FirstChannelProfilePipe,
    SharedModule,
    NgForOf,
    SkeletonModule,
    RippleModule,
    VarDirective,
    AvatarComponent,
    ProfileProfessionsComponent,
    PixelsPipe,
    OrderManagerModule,
    ProfileOrderChatComponent
  ],
  templateUrl: './chat-inbox.component.html',
  styleUrl: './chat-inbox.component.scss'
})
export class ChatInboxComponent extends EditableComponent implements OnInit, OnDestroy {
  /**
   * All available channels.
   */
  channels: Page<ChannelResp> = newEmptyPage()
  /**
   * The data of the currently visible chat window.
   */
  selectedChannel?: ChannelResp
  /**
   * If the chat window is currently visible.
   */
  chatVisible: boolean
  /**
   * The current logged profile.
   */
  currentProfile: ProfileResp
  /**
   * All subscriptions that need to be unsubscribed.
   */
  private subs: Subscription[] = []

  constructor(
    private channelService: ChatChannelService,
    private profileService: ProfileService,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.subs.push(
      // Current profile
      this.profileService.currentProfile.subscribe(data => {
        this.currentProfile = data
      }),

      // Show dialog
      this.channelService.chatInboxOpen.subscribe((visible) => {
        this.show = visible
        this.changeRef.detectChanges()
      })
    )
  }

  /**
   * When a user clicks on a channel.
   */
  selectChannel(channel: ChannelResp): void {
    this.selectedChannel = channel
    this.chatVisible = true
    this.changeRef.detectChanges()
  }

  /**
   * A load function to load chat channels to the lazy list.
   */
  loadAllOfActiveOrders(pageNum: number): Observable<Page<ChannelResp>> {
    return this.unwrap(this.channelService.callGetAllOfActiveOrders(pageNum))
  }

  ngOnDestroy(): void {
    this.subs?.forEach(it => it?.unsubscribe())
  }
}
