<div class="wrapper p-py-1 p-px-3">
  <div *ngIf="descriptionTitle" class="p-mb-3 p-d-none">
    <div class="p-d-flex p-jc-between p-flex-column-reverse p-flex-md-row p-mt-3">
      <h1 class="p-mt-0 p-mb-3">{{ descriptionTitle }}</h1>
      <div class="p-mb-2 p-mb-md-0">
        <p-chip
          label="Booking protection"
          i18n-label
          icon="fa-solid fa-shield">
        </p-chip>
      </div>
    </div>
    <app-collapsible-element>
      <p>{{ descriptionText }}</p>
    </app-collapsible-element>
  </div>

  <div class="p-ai-center" [class.p-d-flex]="!isScreenOf(Screen.XXL)">
    <!--Filters button-->
    <div *ngIf="!isScreenOf(Screen.XXL)">
      <div
        class="p-d-flex p-flex-column p-ai-center p-jc-center p-my-2 filters-button p-p-2 p-mr-2 c-pointer rounded-small"
        pRipple
        (click)="filterClick.emit()">
        <i class="fa-solid fa-sliders-h"></i>
        <div i18n>Filters</div>
      </div>
    </div>

    <!--Carousel-->
    <div class="carousel p-relative p-my-2">
      <p-tabMenu *ngIf="categories?.length > 0"
                 [scrollable]="true"
                 [model]="categories"
                 (ngInit)="tabMenuComponent = tabMenu"
                 #tabMenu>
        <ng-template pTemplate="item" let-item>
          <div class="category p-d-flex p-flex-column p-ai-center p-jc-center p-p-2 p-mr-1 c-pointer rounded-small"
               pRipple
               (click)="onCategoryClick(item)"
               [class.sel-category]="item.selected">
            <i class="category-icon {{item.icon}} p-mb-1"></i>
            <small class="category-name">{{ item.name }}</small>
          </div>
        </ng-template>
      </p-tabMenu>
    </div>
  </div>
</div>
