<div class="wrapper p-d-flex p-flex-column p-ai-center p-px-3">
  <div class="component-wrapper">
    <div class="p-px-3">
      <h2 class="title" i18n>Alternative artists</h2>
      <app-hint i18n>Here are some alternative artists similar to your unavailable.
        Use the checkboxes to narrow down your choices.<br>
        Keep in mind that these artists are filtered based on the <b>priority</b> of the unavailable artist
        <b>(profession, skills, genres) </b>, and then comes the importance of your <b>filter (price, location,
          reviews)</b>.
      </app-hint>
    </div>

    <div class="p-d-flex p-jc-center p-m-3">
      <div *ngFor="let item of alternativeArtistFilters" class="p-mx-2 p-d-flex p-ai-center">
        <app-checkbox [(check)]="item.selected"
                      (checkChange)="callAndLoadSimilarProfiles()"></app-checkbox>
        <div class="border p-d-flex p-flex-column p-flex-sm-row p-ai-center p-jc-center rounded-medium p-p-2">
          <i [class]="item.icon" [class.p-mr-2]="isScreenOf(Screen.SM)"></i>
          <p [class.text-bold]="item.selected">{{item.name}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="loading || !contentLoaded">
      <ng-template [ngTemplateOutlet]="cardsSkeleton"></ng-template>
    </div>

    <div *ngIf="!loading && contentLoaded">
      <div *ngIf="artists?.content.length > 0; else noContent">
        <ng-template [ngTemplateOutlet]="alternativeArtists"></ng-template>
      </div>
    </div>

    <ng-template #alternativeArtists>
      <p-carousel [value]="artists?.content" [numVisible]="3" [numScroll]="3"
                  [circular]="false"
                  [responsiveOptions]="responsiveOptions">

        <ng-template let-artist pTemplate="item">
          <div class="p-d-flex p-jc-center p-ai-center">
            <div class="similar-artist-card rounded-large box-shadow p-m-2">
              <app-profile-catalog-item [data]="artist"></app-profile-catalog-item>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </ng-template>
  </div>
</div>

<!--Skeleton-->
<ng-template #cardsSkeleton>
  <div class="p-d-flex p-m-4">
    <div *ngFor="let i of [].constructor(numberOfSkeletons)" class="w-100 p-mx-2">
      <p-skeleton width="100%" height="25rem" styleClass="p-col-4"></p-skeleton>
    </div>
  </div>
</ng-template>

<ng-template #noContent>
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center">
    <h1 class="no-alternatives-content-text p-text-bold" i18n>Filtered alternatives not found in this filter</h1>
    <i class="fa-solid fa-users-line no-alternatives-content"></i>
  </div>
</ng-template>
