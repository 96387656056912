<div class="p-d-flex p-flex-column p-ai-center" data-test="order-status-badge">
  <div class="badge-{{status.classSuffix}} c-pointer" [appTooltip]="status.description">
    <i class="{{status.icon}}"></i>
    <div class="unselectable l-count-1">{{ status.title }}</div>
  </div>

  <!--Partial funded-->
  <div *ngIf="isAuthor && order.partialFunded" (click)="partiallyPaidClick.emit()"
       class="status-description partial-payment p-d-flex p-ai-center p-mt-1 c-pointer"
       data-test="partially-paid"
       appTooltip="We have received only a partial payment of your order. You have to pay the rest to continue."
       i18n-appTooltip>
    <i class="fa-solid fa-warning p-mr-1"></i>
    <div class="unselectable" i18n>Partially paid</div>
  </div>

  <!--Counter-->
  <div *ngIf="order.expirationAt"
       class="status-description countdown-{{status.classSuffix}} p-d-flex p-ai-center p-mt-1"
       data-test="timer"
       [appTooltip]="expirationReason">
    <i class="fa-solid fa-stopwatch p-mr-1"></i>
    <div class="unselectable">{{ order.expirationAt | countdown: 'dhm' | async }}</div>
  </div>

  <!--Location Verified-->
  <div *ngIf="order.profileArrived"
       appTooltip="The artist arrived at the event place at the required time."
       data-test="verified"
       i18n-appTooltip
       class="status-description verified p-d-flex p-ai-center p-mt-1">
    <i class="fa-solid fa-location-dot p-mr-1 p-mt-1"></i>
    <div class="unselectable" i18n>Artist arrived</div>
  </div>
</div>
