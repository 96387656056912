<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [baseZIndex]="100005"
            [allowDiscardOption]="false"
            [save]="onComponentSave.bind(this)"
            header="Map filters"
            i18n-header
            successMessage="Exploring..."
            i18n-successMessage
            saveLabel="Apply">

  <!--Dialog content-->
  <div class="p-py-2">

    <!--DATE-->
    <div class="p-grid">
      <!--Start-->
      <div class="p-col-12 p-sm-6 p-p-0">
        <app-date-input formFieldName="start"
                        [form]="dateForm"
                        label="Start date"
                        i18n-label
                        styleClass="p-pr-sm-2"
                        [minDate]="minDate"
                        icon="fa-solid fa-play">
        </app-date-input>
      </div>
      <!--End-->
      <div class="p-col-12 p-sm-6 p-p-0">
        <app-date-input formFieldName="end"
                        [form]="dateForm"
                        label="End date"
                        i18n-label
                        styleClass="p-pl-sm-2"
                        [minDate]="minDate"
                        icon="fa-solid fa-flag-checkered">
        </app-date-input>
      </div>
      <!--Reset Butotn-->
      <div class="p-col-12 p-d-flex p-jc-end p-p-0">
        <app-button type="secondary"
                    label="Reset"
                    i18n-label
                    @grow
                    *ngIf="dateForm.dirty"
                    icon="fa-solid fa-arrow-rotate-left"
                    (clicked)="resetDateFields()"></app-button>
      </div>
    </div>

    <!--PRICE-->
    <section class="rounded-medium">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-euro-sign p-mr-2"></i>
        <h2>Price</h2>
      </div>
      <div class="price-label p-mb-3">
        {{priceRange[0] || 0}} - {{priceRange[1] || 0}}<span *ngIf="priceRange[1] === maxPrice">+</span> €
      </div>
      <div class="p-px-2 p-pb-2 price-slider">
        <p-slider [(ngModel)]="priceRange"
                  [range]="true"
                  [step]="50"
                  [max]="maxPrice"></p-slider>
      </div>
    </section>

    <!--GENRES-->
    <div class="p-grid p-p-0">
      <div class="p-col-12 p-p-0">
        <section class="rounded-medium">
          <!--Genres-->
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid fa-signs-post"></i>
            <h2 i18n>Genres</h2>
          </div>
          <!--Source-->
          <ng-template [ngTemplateOutlet]="category"
                       [ngTemplateOutletContext]="{selected: selectedGenres, source: [genres],
                          add: addGenre.bind(this), remove: removeGenre.bind(this),
                          search: callSearchGenres.bind(this)}"></ng-template>
        </section>
      </div>
    </div>
  </div>

</app-dialog>

<!--Genres filter template-->
<ng-template #category
             let-selected="selected"
             let-search="search"
             let-add="add"
             let-remove="remove"
             let-source="source">
  <!--Selected Items-->
  <div class="p-grid">
    <div *ngFor="let g of selected" class="p-col-fixed p-p-0 p-mt-2 p-mr-2">
      <div class="selected-category p-d-flex p-ai-center c-pointer" (click)="remove(g)">
        {{g.name}}
        <i class="fa-solid fa-xmark p-ml-2"></i>
      </div>
    </div>
  </div>

  <ul class="w-100 p-p-0">
    <!--Lazy list-->
    <app-lazy-list [(items)]="source[0]"
                   [component]="this"
                   scrollHeight="20rem"
                   [itemHeight]="39"
                   [loadFunction]="search.bind(this, source[0]?.nextPage, null)">
      <ng-template let-item pTemplate="item">
        <li class="w-100 p-pb-2">
          <app-checkbox [(check)]="item.selected"
                        (checkChange)="$event ? add(item) : remove(item)"
                        [label]="item.name"></app-checkbox>
        </li>
      </ng-template>
    </app-lazy-list>
  </ul>
</ng-template>
