<!--All profiles' components structure-->
<div [ngSwitch]="profileData?.profileType" *ngIf="profileData && canEdit !== null">
  <!--USER profile-->
  <div *ngSwitchCase="ProfileType.USER">
    <app-profile-banner [data]="profileData" [editButtonVisible]="canEdit"></app-profile-banner>

    <div class="p-relative background">
      <div class="custom-top p-d-flex p-jc-center p-pb-5">
        <div class="component-wrapper">
          <!--Name Bar-->
          <div class="p-px-3">
            <app-profile-name-bar [data]="profileData"
                                  [editButtonVisible]="canEdit"
                                  (showSettings)="showSettingsDialog($event)"
                                  [currentProfile]="loggedProfile"></app-profile-name-bar>
          </div>

          <!--Bio-->
          <div class="p-grid p-px-3">
            <div class="p-col-12 p-lg-8">
              <app-profile-bio [data]="profileData"
                               [editButtonVisible]="canEdit"></app-profile-bio>
            </div>
          </div>
        </div>
      </div>
      <app-footer class="p-relative"></app-footer>
    </div>
  </div>

  <!--ARTIST profile-->
  <div *ngSwitchCase="ProfileType.ARTIST">
    <app-profile-banner [data]="profileData"
                        [editButtonVisible]="canEdit"></app-profile-banner>

    <div class="p-relative background">
      <div class="custom-top p-d-flex p-jc-center">
        <div class="component-wrapper">
          <div class="p-px-3 p-grid">
            <div class="p-col-12 p-lg-8 p-p-0">
              <!--Names-->
              <app-profile-name-bar [data]="profileData"
                                    [editButtonVisible]="canEdit"
                                    [currentProfile]="loggedProfile"
                                    (showSettings)="showSettingsDialog($event)"/>

              <!--Chat button-->
              <div class="p-mt-2">
                <app-profile-chat-button *ngIf="!profileData?.owner && canBeOrdered"
                                         [currentProfile]="loggedProfile"
                                         (scrollToOffer)="scrollToOffer()"
                                         [profile]="profileData"></app-profile-chat-button>
              </div>

              <!-- Biography -->
              <app-profile-bio [data]="profileData"
                               [editButtonVisible]="canEdit"></app-profile-bio>

              <!--Attributes-->
              <app-profile-attributes [profile]="profileData"
                                      [editButtonVisible]="canEdit"/>

            </div>
            <div class="p-col-12 p-lg-4 p-p-0">
              <div class="p-ml-2 p-relative h-100" [style.z-index]="10">
                <div class="p-d-flex p-jc-end">
                  <app-profile-status-actions [currentProfile]="loggedProfile"
                                              [editButtonVisible]="canEdit"
                                              (showSettings)="showSettingsDialog($event)"
                                              [profile]="profileData"/>
                </div>

                <!--Sticky components-->
                <div class="cta-sticky-comp"
                     [class.cta-top-navbar]="navbarVisible">
                  <div class="w-100 p-d-flex p-flex-column p-ai-end">
                    <!-- Completion progress bar -->
                    <app-profile-completion *ngIf="canEdit"
                                            [data]="profileData"
                                            [stripeCompletionCheck]="stripeCompletionCheck"
                                            (showSettingsDialog)="showSettingsDialog($event)"/>

                    <!-- CTA button for visitors -->
                    <app-profile-cta *ngIf="isScreenOf(Screen.LG) && canBeOrdered && !canEdit"
                                     [profile]="profileData"
                                     (scrollToOffer)="scrollToOffer()"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-profile-image-showcase [profile]="profileData"
                                  [editButtonVisible]="canEdit"></app-profile-image-showcase>

      <app-profile-showcase [data]="profileData" [editButtonVisible]="canEdit"></app-profile-showcase>

      <!--Calendar and Book form-->
      <div class="p-d-flex p-jc-center p-p-3 w-100 calendar-selector">
        <div class="component-wrapper">
          <h2 class="p-my-0 p-d-flex p-ai-start">
            <div *ngIf="canBeOrdered && !profileData?.owner">
              <span class="profile-circle-number p-mr-2">1.</span>
            </div>
            {{ (canBeOrdered && !profileData?.owner) ? trans.calendar_title_customer : trans.calendar_title_owner }}
          </h2>
          <div class="p-grid p-p-0">
            <div class="p-col-12 p-p-0"
                 [class.p-md-6]="!profileData?.owner"
                 [class.p-xl-8]="!profileData?.owner">
              <app-profile-calendar
                (ready)="calendar = $event; initCalendarFromStorage()"
                [data]="profileData"
                [clickablePastDate]="false"
                [monthsVisible]="isScreenOf(Screen.XL) ? 2 : 1"
                [fetchDetails]="true"
                [fetchCountPendingProfiles]="true"
                [disableCellChange]="disableSelectedCellEmit"
                (selectedCellChange)="calendarSelectedCell = $event"
                (selectedCellDetailChange)="calendarSelectedDetailCell = $event"
                [editButtonVisible]="canEdit"></app-profile-calendar>
            </div>
            <div class="p-col-12 p-md-6 p-xl-4" *ngIf="!profileData?.owner">
              <app-profile-calendar-book-form
                (ready)="bookForm = $event; initCalendarFromStorage()"
                (selectedStartTimeChange)="selectedDateUpdate('startTime', $event)"
                (selectedEndTimeChange)="selectedDateUpdate('endTime', $event)"
                (selectedStartDateChange)="selectedDateUpdate('startDate', $event)"
                (selectedEndDateChange)="selectedDateUpdate('endDate', $event)"
                (scrollToOffer)="scrollToOffer()"
                (unavailableItemChange)="offer.disableCheckout = !!$event; isDateAvailable = !!$event"
                (minimalDistanceErr)="offer.minimalDistanceErr = !!$event"
                [data]="profileData"
                [selectedStartDate]="calendarSelectedCell?.date"></app-profile-calendar-book-form>
            </div>
          </div>
        </div>
      </div>

      <app-alternative-artists *ngIf="isDateAvailable"
                               [profileData]="profileData"
                               [isDateAvailable]="isDateAvailable"
                               [selectedStartTime]="bookForm.selectedStartTime"
                               [selectedEndTime]="bookForm.selectedEndTime"
                               [selectedStartDate]="bookForm.selectedStartDate"
                               [selectedEndDate]="bookForm.selectedEndDate"></app-alternative-artists>

      <app-profile-offer #offer
                         [data]="profileData"
                         [loggedProfile]="loggedProfile"
                         [editButtonVisible]="canEdit"
                         (disabledCheckoutClickEmitted)="bookForm?.markDateFields()"
                         (orderCreated)="bookForm.resetFields()"></app-profile-offer>

      <app-footer class="p-relative"></app-footer>
      <!--CTA button-->
      <app-profile-bottom-bar
        *ngIf="!isScreenOf(Screen.LG) && !canEdit"
        [profile]="profileData"
        (scrollToOffer)="scrollToOffer()"></app-profile-bottom-bar>

    </div>
  </div>

  <!--ENTERPRISE profile-->
  <!--<div *ngSwitchCase="ProfileType.ENTERPRISE">
    <app-profile-banner [data]="profileData" [editButtonVisible]="canEdit"></app-profile-banner>

    <div class="p-relative background">
      <div class="p-d-flex p-jc-center p-py-5">
        <div class="component-wrapper">
          &lt;!&ndash;Name Bar&ndash;&gt;
          <div class="p-px-3">
            <app-profile-name-bar [data]="profileData"
                                  [editButtonVisible]="canEdit"
                                  (showSettings)="showSettingsDialog($event)"
                                  [stripeAccountCompleted]="stripeAccountCompleted"
                                  [currentProfile]="loggedProfile"></app-profile-name-bar>
          </div>

          &lt;!&ndash;Opening Hours, Bio & Calendar&ndash;&gt;
          <div class="p-grid">

            &lt;!&ndash;Opening Hours & Bio&ndash;&gt;
            <div class="p-col-12 p-md-6 p-lg-8 p-px-3">

              &lt;!&ndash;Opening Hours&ndash;&gt;
              <app-profile-opening-hours [data]="profileData"
                                         [editButtonVisible]="canEdit"></app-profile-opening-hours>

              &lt;!&ndash;Bio&ndash;&gt;
              <div class="p-pr-md-5">
                <app-profile-bio [data]="profileData"
                                 [editButtonVisible]="canEdit"></app-profile-bio>
                <app-profile-hashtags [data]="profileData" [editButtonVisible]="canEdit"></app-profile-hashtags>
                <app-profile-calendar-detail [currentProfile]="loggedProfile"
                                             [data]="calendarSelectedDetailCell"></app-profile-calendar-detail>
              </div>
            </div>

            &lt;!&ndash;Calendar&ndash;&gt;
            <div class="p-col-12 p-md-6 p-lg-4 p-p-0 p-pr-md-3">
              <app-profile-calendar [data]="profileData"
                                    *ngIf="false"
                                    [layout]="canEdit ? 'owner' : 'booker'"
                                    [fetchDetails]="true"
                                    [fetchCountPendingProfiles]="true"
                                    (selectedCellDetailChange)="calendarSelectedDetailCell = $event"
                                    [editButtonVisible]="canEdit"
              ></app-profile-calendar>
            </div>
          </div>
        </div>
      </div>

      <app-profile-place [data]="profileData" [editButtonVisible]="canEdit"></app-profile-place>
    </div>
  </div>

  &lt;!&ndash;EVENT profile&ndash;&gt;
  <div *ngSwitchCase="ProfileType.EVENT">
    <app-profile-banner [data]="profileData" [editButtonVisible]="canEdit"></app-profile-banner>

    <div class="p-relative background">
      <div class="custom-top p-d-flex p-jc-center p-pb-5">
        <div class="component-wrapper">
          &lt;!&ndash;Name Bar&ndash;&gt;
          <div class="p-px-3">
            <app-profile-name-bar [data]="profileData"
                                  [editButtonVisible]="canEdit"
                                  (showSettings)="showSettingsDialog($event)"
                                  [stripeAccountCompleted]="stripeAccountCompleted"
                                  [currentProfile]="loggedProfile"></app-profile-name-bar>
          </div>

          &lt;!&ndash;Bio & Schedule&ndash;&gt;
          <div class="p-grid p-px-3">

            &lt;!&ndash;Bio&ndash;&gt;
            <div class="p-col-12 p-md-6">
              <div class="p-pr-md-5">
                <app-profile-bio [data]="profileData"
                                 [editButtonVisible]="canEdit"></app-profile-bio>
                <app-profile-price [data]="profileData" [editButtonVisible]="canEdit"></app-profile-price>
                <app-profile-genres [data]="profileData"
                                    [editButtonVisible]="canEdit"></app-profile-genres>
              </div>
            </div>

            &lt;!&ndash;Schedule&ndash;&gt;
            <div class="p-col-12 p-md-6">
              <app-profile-schedule [data]="profileData" [editButtonVisible]="canEdit"></app-profile-schedule>
            </div>
          </div>
        </div>
      </div>

      <app-profile-place [(editButtonVisible)]="canEdit" [data]="profileData"></app-profile-place>
    </div>
  </div>-->
</div>

<!--Profile Settings-->
<app-profile-settings *ngIf="settingsDialogVisible && profileData"
                      [(show)]="settingsDialogVisible"
                      (showChange)="onSettingsClose()"
                      [data]="profileData"
                      [canBeOrdered]="canBeOrdered"
                      [(stripeCompletionCheck)]="stripeCompletionCheck"
                      [highlightSetting]="settingsDialogHighlight"></app-profile-settings>

<!--Not Found Page-->
<app-page-not-found *ngIf="!profileData && !loading"></app-page-not-found>
