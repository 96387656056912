/**
 * Returns a string value that does not contain more than one following space and two new line character.
 */
export function trimMultipleSpacesAndLines(value: string): string {
  if (!value) {
    return null
  }
  const spaces = /  +/g
  const lines = /\n{2,}/g

  return value
    .replace(spaces, ' ') // max 1 space allowed
    .replace(lines, '\n\n') // max 2 '\n' characters allowed
    .trim()
}

/**
 * Removes all whitespaces from the {@link value}.
 */
export function removeAllWhitespaces(value: string): string {
  if (value && /\s+/.test(value)) {
    return value.replace(/\s+/g, '')
  }
  return value
}

/**
 * Generates a random hash string with the given {@link length}.
 */
export function randomHash(length: number): string {
  return (Math.random() + 1).toString(36).substring(length + 2)
}

/**
 * Truncate text for specified length, if text is longer than {@link max} and add ellipses.
 */
export function truncateWithEllipses(text: string, max: number): string {
  return text.slice(0, max - 1) + (text.length > max ? '&hellip;' : '')
}
