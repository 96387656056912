//@formatter:off
import {BrowserModule} from '@angular/platform-browser'
import {APP_INITIALIZER, ErrorHandler, Inject, Injector, LOCALE_ID, NgModule, PLATFORM_ID} from '@angular/core'
import {AppComponent} from './app.component'
import {InputTextModule} from 'primeng/inputtext'
import {NavigationComponent} from './component/navigation/navigation.component'
import {ButtonModule} from 'primeng/button'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {CheckboxModule} from 'primeng/checkbox'
import {MapComponent} from './component/map/map.component'
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, provideHttpClient, withFetch} from '@angular/common/http'
import {ProgressSpinnerModule} from 'primeng/progressspinner'
import {InputTextareaModule} from 'primeng/inputtextarea'
import {SkeletonModule} from 'primeng/skeleton'
import {DialogModule} from 'primeng/dialog'
import {KnobModule} from 'primeng/knob'
import {RippleModule} from 'primeng/ripple'
import {CarouselModule} from 'primeng/carousel'
import {TabViewModule} from 'primeng/tabview'
import {DataViewModule} from 'primeng/dataview'
import {DropdownModule} from 'primeng/dropdown'
import {TimeDurationPipe} from './pipe/time-duration.pipe'
import {BeforeLongAgoPipe} from './pipe/before-long-ago.pipe'
import {VirtualScrollerModule} from 'primeng/virtualscroller'
import {ScrollerModule} from 'primeng/scroller'
import {ScrollPanelModule} from 'primeng/scrollpanel'
import {TooltipModule} from 'primeng/tooltip'
import {SliderModule} from 'primeng/slider'
import {ParallaxDirective} from './directive/parallax.directive'
import {AvatarModule} from 'primeng/avatar'
import {MenuModule} from 'primeng/menu'
import {ProfileNameBarComponent} from './component/profile/profile-name-bar/profile-name-bar.component'
import {TabMenuModule} from 'primeng/tabmenu'
import {SidebarModule} from 'primeng/sidebar'
import {ImageCropperModule} from 'ngx-image-cropper'
import {UploadAvatarComponent} from './component/upload/upload-avatar/upload-avatar.component'
import {ProfileTypePipe} from './pipe/profile-type.pipe'
import {ProfileComponent} from './component/profile/profile.component'
import {ProfileBioComponent} from './component/profile/profile-bio/profile-bio.component'
import {OverlayPanelModule} from 'primeng/overlaypanel'
import {DialogComponent} from './component/common/dialog/dialog.component'
import {FrontendValidationComponent} from './component/common/frontend-validation/frontend-validation.component'
import {BackendValidationComponent} from './component/common/backend-validation/backend-validation.component'
import {InternalServerErrorComponent} from './component/errors/internal-server-error/internal-server-error.component'
import {ProfileBannerComponent} from './component/profile/profile-banner/profile-banner.component'
import {UploadWallpaperComponent} from './component/upload/upload-wallpaper/upload-wallpaper.component'
import {OrderListModule} from 'primeng/orderlist'
import {UpdateMapLocationComponent} from './component/upload/update-map-location/update-map-location.component'
import {NoContentComponent} from './component/common/no-content/no-content.component'
import {MapSearchComponent} from './component/map/map-search/map-search.component'
import {BriefProfilePipe} from './pipe/brief-profile.pipe'
import {WallpaperPipe} from './pipe/wallpaper.pipe'
import {AvatarPipe} from './pipe/avatar.pipe'
import {ProfileBubbleComponent} from './component/map/profile-bubble/profile-bubble.component'
import {ProfileMarkerComponent} from './component/map/profile-marker/profile-marker.component'
import {BackgroundImagePipe} from './pipe/background-image.pipe'
import {RatingComponent} from './component/common/rating/rating.component'
import {CountPipe} from './pipe/count.pipe'
import {RedirectProfileDirective} from './directive/redirect-profile.directive'
import {UrlDirective} from './directive/url.directive'
import {PhoneDirective} from './directive/phone.directive'
import {MailDirective} from './directive/mail.directive'
import {ProfileCalendarDetailComponent} from './component/profile/profile-calendar/profile-calendar-detail/profile-calendar-detail.component'
import {VarDirective} from './directive/var.directive'
import {ProfileNamesComponent} from './component/common/profile-names/profile-names.component'
import {AvatarComponent} from './component/common/avatar/avatar/avatar.component'
import {ProfileBorderDirective} from './directive/profile-border.directive'
import {CalendarModule} from 'primeng/calendar'
import {DateBeforePipe} from './pipe/date-before.pipe'
import {ProfileSearchComponent} from './component/common/profile-search/profile-search.component'
import {InputSwitchModule} from 'primeng/inputswitch'
import {SwitchProfileComponent} from './component/dialog/switch-profile/switch-profile.component'
import {ProfileSearchBarComponent} from './component/profile/profile-search-bar/profile-search-bar.component'
import {environment} from '../environments/environment'
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging'
import {AngularFireModule} from '@angular/fire/compat'
import {FirstChannelProfilePipe} from './pipe/first-channel-profile.pipe'
import {ScrollerComponent} from './component/common/scroller-component/scroller.component'
import {ContextMenuModule} from 'primeng/contextmenu'
import {TextInputComponent} from './component/common/form/text-input/text-input.component'
import {HtmlInputComponent} from './component/common/form/html-input/html-input.component'
import {InitDirective} from './directive/init.directive'
import {AspectRatioDirective} from './directive/aspect-ratio.directive'
import {ChangeDirective} from './directive/change.directive'
import {ProgressBarModule} from 'primeng/progressbar'
import {SnackbarComponent} from './component/common/snackbar/snackbar.component'
import {ProfileTypeIconPipe} from './pipe/profile-type-icon.pipe'
import {ProfileIconComponent} from './component/common/profile-icon/profile-icon.component'
import {ServiceWorkerModule} from '@angular/service-worker'
import {MapSearchDialogComponent} from './component/map/map-search-dialog/map-search-dialog.component'
import {SwipeDirective} from './directive/swipe.directive'
import {RouterLinkPipe} from './pipe/router-link.pipe'
import {HintComponent} from './component/common/hint/hint.component'
import {ProfileHashtagsComponent} from './component/profile/profile-hashtags/profile-hashtags.component'
import {ProfileLikeComponent} from './component/common/profile-like/profile-like.component'
import {LongClickDirective} from './directive/long-click.directive'
import {DateInputComponent} from './component/common/form/date-input/date-input.component'
import {ProfileCatalogComponent} from './component/order/profile-catalog/profile-catalog.component'
import {ProfileCatalogFilterComponent} from './component/order/profile-catalog/profile-catalog-filter/profile-catalog-filter.component'
import {ProfileCatalogItemComponent} from './component/order/profile-catalog/profile-catalog-item/profile-catalog-item.component'
import {ProfileOfferComponent} from './component/profile/profile-offer/profile-offer.component'
import {CustomNavbarComponent} from './component/common/custom-navbar/custom-navbar.component'
import {CheckboxComponent} from './component/common/form/checkbox/checkbox.component'
import {PricePipe} from './pipe/price.pipe'
import {isPlatformBrowser, NgOptimizedImage, registerLocaleData} from '@angular/common'
import localeSk from '@angular/common/locales/sk'
import {ProfileProfessionsComponent} from './component/profile/profile-professions/profile-professions.component'
import {ProfilePriceItemComponent} from './component/profile/profile-price-item/profile-price-item.component'
import {InputNumberModule} from 'primeng/inputnumber'
import {DialogCookiesComponent} from './component/dialog/dialog-cookies/dialog-cookies.component'
import * as AOS from 'aos'
import {ProfileRateComponent} from './component/profile/profile-rate/profile-rate.component'
import {ProfileShowcaseComponent} from './component/profile/profile-showcase/profile-showcase.component'
import {LazyListComponent} from './component/common/list/lazy-list/lazy-list.component'
import {SearchComponent} from './component/common/search/search.component'
import {getAuth, provideAuth} from '@angular/fire/auth'
import {MultiSelectModule} from 'primeng/multiselect'
import {SelectButtonModule} from 'primeng/selectbutton'
import {DisableDirective} from './directive/disable.directive'
import {ButtonComponent} from './component/common/button/button.component'
import {CookieService} from 'ngx-cookie-service'
import {DialogCookiesDetailsComponent} from './component/dialog/dialog-cookies/dialog-cookies-details/dialog-cookies-details.component'
import {UnsavedChangesComponent} from './component/common/unsaved-changes/unsaved-changes.component'
import {ImgComponent} from './component/common/img/img.component'
import {TutorialComponent} from './component/common/tutorial/tutorial.component'
import {TutorialDirective} from './directive/tutorial.directive'
import {StepsModule} from 'primeng/steps'
import {CardModule} from 'primeng/card'
import {NgxStripeModule, StripeCardComponent} from 'ngx-stripe'
import {ForegroundNotificationComponent} from './component/common/foreground-notification/foreground-notification.component'
import {ProfileCalendarBookFormComponent} from './component/profile/profile-calendar/profile-calendar-book-form/profile-calendar-book-form.component'
import {DropdownComponent} from './component/common/form/dropdown/dropdown.component'
import {CallResponseComponent} from './component/common/call-response/call-response.component'
import {MapFilterComponent} from './component/map/map-filter/map-filter.component'
import {ProfilePriceComponent} from './component/profile/profile-price/profile-price.component'
import {OrderSegmentComponent} from './component/profile/profile-offer/order-segment/order-segment.component'
import {OrderDescriptionComponent} from './component/profile/profile-offer/order-description/order-description.component'
import {OrderSumComponent} from './component/profile/profile-offer/order-sum/order-sum.component'
import {AlternativeArtistsComponent} from './component/alternative-artists/alternative-artists.component'
import {OrderStateComponent} from './component/dialog/order-state/order-state.component'
import {PermissionDialogComponent} from './component/dialog/permission-dialog/permission-dialog.component'
import {ShoppingBasketComponent} from './component/order/shopping-basket/shopping-basket.component'
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {LoadingComponent} from './component/loading/loading.component'
import {RatingModule} from 'primeng/rating'
import {DialogStepsComponent} from './component/common/dialog/dialog-steps/dialog-steps.component'
import {CountdownPipe} from './pipe/countdown-timer.pipe'
import {PixelsPipe} from './pipe/pixels.pipe'
import {QrCodeAuthComponent} from './component/stripe-pay/qr-code-auth/qr-code-auth.component'
import {QrCodeReaderComponent} from './component/stripe-pay/qr-code-reader/qr-code-reader.component'
import {StripePayComponent} from './component/stripe-pay/stripe-pay.component'
import {SupportComponent} from './component/support/support.component'
import {AccordionModule} from 'primeng/accordion'
import {JoinPipe} from './pipe/join.pipe'
import {RadioButtonModule} from 'primeng/radiobutton'
import {HttpXsrfInterceptor} from './utils/csrf.utils'
import {AutoFocusModule} from 'primeng/autofocus'
import {ProfileCatalogBookFormComponent} from './component/order/profile-catalog/profile-catalog-book-form/profile-catalog-book-form.component'
import {ProfileSettingsComponent} from './component/profile/profile-settings/profile-settings.component'
import {FooterComponent} from './component/footer/footer.component'
import {SplashScreenComponent} from './component/splash-screen/splash-screen.component'
import {getMessaging, provideMessaging} from '@angular/fire/messaging'
import {AngularFireAuthModule} from '@angular/fire/compat/auth'
import {DividerModule} from 'primeng/divider'
import {OrderSearchComponent} from './component/order/order-search/order-search.component'
import {CountryPipe} from './pipe/country.pipe'
import {LegalEntitySettingsComponent} from './component/profile/profile-settings/legal-entity-settings/legal-entity-settings.component'
import {OrderInvoicingAddressComponent} from './component/profile/profile-offer/order-location/order-invoicing-address/order-invoicing-address.component'
import {OrderPerformanceAddressComponent} from './component/profile/profile-offer/order-location/order-performance-address/order-performance-address.component'
import {UnitTimeDurationPipe} from './pipe/unit-time-duration.pipe'
import {ChartModule} from 'primeng/chart'
import {LeafletDirective} from './directive/leaflet.directive'
import {ProfileChangeStatusComponent} from './component/profile/profile-settings/profile-change-status/profile-change-status.component'
import {ProfileOrderDialogComponent} from './component/profile/profile-offer/profile-order-dialog/profile-order-dialog.component'
import {ListComponent} from './component/common/list/list/list.component'
import {FieldDescriptionComponent} from './component/common/field-description/field-description.component'
import {ProfileOfferInventoryComponent} from './component/profile/profile-offer/profile-offer-inventory/profile-offer-inventory.component'
import {DialogDeleteConfirmComponent} from './component/common/dialog/dialog-delete-confirm/dialog-delete-confirm.component'
import {DialogConfirmComponent} from './component/common/dialog/dialog-confirm/dialog-confirm.component'
import {LogoutDialogComponent} from './component/dialog/logout-dialog/logout-dialog.component'
import {OrderAwaitingConfirmComponent} from './component/order/order-awaiting-confirm/order-awaiting-confirm.component'
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics'
import {GoogleAnalyticsService} from './service/analytics/google-analytics.service'
import {ProfileCalendarComponent} from './component/profile/profile-calendar/profile-calendar.component'
import {AppRoutingModule} from './app-routing.module'
import {SharedModule} from 'primeng/api'
import {PageNotFoundComponent} from './component/errors/page-not-found/page-not-found.component'
import {Router} from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import {ChatInboxComponent} from './component/chat/chat-inbox/chat-inbox.component'
import {PlatformRatingComponent} from './component/platform-rating/platform-rating.component'
import {MetadataService} from './service/helper-services/metadata.service'
import {PwaService} from './service/helper-services/pwa.service'
import {PwaDialogComponent} from './component/dialog/pwa-dialog/pwa-dialog.component'
import {NotificationInboxComponent} from './component/notification-inbox/notification-inbox.component'
import {PriceItemCategoriesComponent} from './component/order/profile-catalog/price-item-categories/price-item-categories.component'
import {ProfileCompletionComponent} from './component/profile/profile-completion/profile-completion.component'
import {FeatureShowcaseComponent} from './component/feature-showcase/feature-showcase.component'
import {BadgeRequiredComponent} from './component/common/badge/badge-required/badge-required.component'
import {OrderAccountComponent} from './component/profile/profile-offer/order-account/order-account.component'
import {FirebaseAuthComponent} from './modules/authentication/firebase-auth/firebase-auth.component'
import {LoginEmbedComponent} from './modules/authentication/login-embed/login-embed.component'
import {RegisterEmbedComponent} from './modules/authentication/register-embed/register-embed.component'
import {TwoFactorAuthenticationEmbedComponent} from './modules/authentication/two-factor-authentication-embed/two-factor-authentication-embed.component'
import {RegisterFirebaseEmbedComponent} from './modules/authentication/register-firebase-embed/register-firebase-embed.component'
import {ProfileImageShowcaseComponent} from './component/profile/profile-gallery/profile-image-showcase.component'
import {GalleriaModule} from 'primeng/galleria'
import {ProfileImageShowcaseEditComponent} from './component/profile/profile-gallery/profile-image-showcase-edit/profile-image-showcase-edit.component'
import {PhoneNumberPipe} from './pipe/phone-number.pipe'
import {ProfileBottomBarComponent} from './component/profile/profile-bottom-bar/profile-bottom-bar.component'
import {ProfileAttributesComponent} from './component/profile/profile-attributes/profile-attributes.component'
import {ProfileCtaComponent} from './component/profile/profile-cta/profile-cta.component'
import {ProfileStatusActionsComponent} from './component/profile/profile-name-bar/profile-status-actions/profile-status-actions.component'
import {ProfileChatButtonComponent} from './component/profile/profile-name-bar/profile-chat-button/profile-chat-button.component'
import {GalleriaComponent} from './component/common/galleria/galleria.component'
import {FieldsetModule} from 'primeng/fieldset'
import {ChipModule} from 'primeng/chip'
import {CollapsibleElementComponent} from './component/common/collapsible-element/collapsible-element.component'
import {AttributeSearchDialogComponent} from './component/order/profile-catalog/attribute-search-dialog/attribute-search-dialog.component'

// Register languages
registerLocaleData(localeSk)

//@formatter:on
/**
 * Public constant defining whether the app is currently running in a browser (true) or in a server (false) environment.
 */
export let PLATFORM_BROWSER = false

/**
 * Public constant defining whether the app is currently running as a PWA (true) or not (false).
 */
export let RUNNING_AS_PWA = false

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    UploadAvatarComponent,
    ProfileComponent,
    ProfileBioComponent,
    ProfileCompletionComponent,
    InternalServerErrorComponent,
    ProfileBannerComponent,
    UploadWallpaperComponent,
    UpdateMapLocationComponent,
    ProfileCalendarDetailComponent,
    ProfileHashtagsComponent,
    DialogCookiesComponent,
    ProfileCatalogComponent,
    ProfileCatalogFilterComponent,
    ProfileCatalogItemComponent,
    ProfileOfferComponent,
    ProfileShowcaseComponent,
    ProfileCalendarBookFormComponent,
    QrCodeAuthComponent,
    QrCodeReaderComponent,
    DialogCookiesDetailsComponent,
    OrderDescriptionComponent,
    OrderSumComponent,
    ProfilePriceComponent,
    AlternativeArtistsComponent,
    PermissionDialogComponent,
    ShoppingBasketComponent,
    SupportComponent,
    ProfileCatalogBookFormComponent,
    ProfileSettingsComponent,
    FooterComponent,
    SplashScreenComponent,
    LegalEntitySettingsComponent,
    OrderInvoicingAddressComponent,
    OrderPerformanceAddressComponent,
    ProfileChangeStatusComponent,
    ProfileOrderDialogComponent,
    ProfileOfferInventoryComponent,
    LogoutDialogComponent,
    OrderAwaitingConfirmComponent,
    PlatformRatingComponent,
    PwaDialogComponent,
    NotificationInboxComponent,
    PriceItemCategoriesComponent,
    FeatureShowcaseComponent,
    BadgeRequiredComponent,
    OrderAccountComponent,
    ProfileImageShowcaseComponent,
    ProfileImageShowcaseEditComponent,
    ProfileBottomBarComponent,
    ProfileAttributesComponent,
    ProfileCtaComponent,
    ProfileStatusActionsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    InputTextModule,
    ButtonModule,
    ProgressSpinnerModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    InputTextareaModule,
    SkeletonModule,
    DialogModule,
    KnobModule,
    CarouselModule,
    TabViewModule,
    DataViewModule,
    VirtualScrollerModule,
    ScrollerModule,
    ScrollPanelModule,
    TooltipModule,
    SliderModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideMessaging(() => getMessaging()),
    provideAuth(() => getAuth()),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    RippleModule,
    DropdownModule,
    AvatarModule,
    MenuModule,
    TabMenuModule,
    SidebarModule,
    ImageCropperModule,
    OverlayPanelModule,
    OrderListModule,
    CalendarModule,
    InputSwitchModule,
    ContextMenuModule,
    ProgressBarModule,
    // Scheduler service worker, register as soon as possible or after 30s
    ServiceWorkerModule.register('../scheduler-sw.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    InputNumberModule,
    MultiSelectModule,
    SelectButtonModule,
    StepsModule,
    CardModule,
    StripeCardComponent,
    NgxStripeModule.forRoot(environment.stripe.apiKey),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.trackingCode, GoogleAnalyticsService.INITIAL_COMMANDS),
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    RatingModule,
    AccordionModule,
    RadioButtonModule,
    AutoFocusModule,
    DividerModule,
    ChartModule,
    ButtonComponent,
    LazyListComponent,
    InitDirective,
    ListComponent,
    AvatarComponent,
    ProfileBorderDirective,
    AvatarPipe,
    UnsavedChangesComponent,
    HintComponent,
    FrontendValidationComponent,
    DialogComponent,
    DialogConfirmComponent,
    BackendValidationComponent,
    CallResponseComponent,
    CustomNavbarComponent,
    DialogDeleteConfirmComponent,
    DialogStepsComponent,
    FieldDescriptionComponent,
    ForegroundNotificationComponent,
    ImgComponent,
    BackgroundImagePipe,
    ProfileIconComponent,
    ProfileTypePipe,
    ProfileTypeIconPipe,
    ProfileNamesComponent,
    ScrollerComponent,
    SearchComponent,
    TextInputComponent,
    CheckboxComponent,
    VarDirective,
    DateInputComponent,
    DropdownComponent,
    HtmlInputComponent,
    ChangeDirective,
    CountryPipe,
    NoContentComponent,
    ProfileLikeComponent,
    CountPipe,
    ProfileProfessionsComponent,
    JoinPipe,
    ProfileSearchComponent,
    RatingComponent,
    SnackbarComponent,
    TutorialComponent,
    PricePipe,
    PhoneNumberPipe,
    CountdownPipe,
    BeforeLongAgoPipe,
    BriefProfilePipe,
    DateBeforePipe,
    FirstChannelProfilePipe,
    PixelsPipe,
    RouterLinkPipe,
    TimeDurationPipe,
    UnitTimeDurationPipe,
    WallpaperPipe,
    AspectRatioDirective,
    DisableDirective,
    LeafletDirective,
    LongClickDirective,
    MailDirective,
    ParallaxDirective,
    PhoneDirective,
    RedirectProfileDirective,
    SwipeDirective,
    TutorialDirective,
    UrlDirective,
    MapFilterComponent,
    MapSearchComponent,
    MapSearchDialogComponent,
    ProfileMarkerComponent,
    ProfileBubbleComponent,
    MapComponent,
    ProfileRateComponent,
    LoadingComponent,
    OrderSegmentComponent,
    OrderStateComponent,
    StripePayComponent,
    OrderSearchComponent,
    ProfilePriceItemComponent,
    ProfileSearchBarComponent,
    SwitchProfileComponent,
    ProfileNameBarComponent,
    ProfileCalendarComponent,
    SharedModule,
    PageNotFoundComponent,
    ChatInboxComponent,
    NgOptimizedImage,
    FirebaseAuthComponent,
    LoginEmbedComponent,
    RegisterEmbedComponent,
    TwoFactorAuthenticationEmbedComponent,
    RegisterFirebaseEmbedComponent,
    GalleriaModule,
    ProfileChatButtonComponent,
    GalleriaComponent,
    GalleriaModule,
    FieldsetModule,
    ChipModule,
    CollapsibleElementComponent,
    AttributeSearchDialogComponent
  ],
  providers: [
    CookieService,
    {provide: LOCALE_ID, useValue: 'sk'}, // TODO change this dynamically
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (pwaService: PwaService) => () => {
        pwaService.catchBeforeInstallPrompt()
      },
      deps: [PwaService],
      multi: true
    },
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   */
  static injector: Injector
  static platformId: any

  constructor(
    injector: Injector,
    @Inject(PLATFORM_ID) private platformId: any,
    private metadataService: MetadataService) {
    AppModule.injector = injector
    AppModule.platformId = platformId

    PLATFORM_BROWSER = isPlatformBrowser(platformId)
    this.metadataService.setDefaultMetatags()
    if (PLATFORM_BROWSER) {
      // PWA
      RUNNING_AS_PWA = window.matchMedia('(display-mode: standalone)').matches
        || (('standalone' in window.navigator) && (window.navigator['standalone'] != undefined))
        || document.referrer.includes('android-app://')

      // The AOS animation library init
      AOS.init({
        startEvent: 'load'
      })
    }
  }
}
