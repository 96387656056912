<app-dialog #dialog
            *ngIf="show"
            [(show)]="show"
            [allowSaveOption]="false"
            [component]="this"
            [disableDiscardWhileSaving]="saving"
            discardLabel="Close"
            header="Shopping basket"
            i18n-discardLabel
            i18n-header
            type="info"
            url="basket">
  <div *ngIf="(hasBasketItems | async); else noContent" class="wrapper">
    <app-hint>
    <span i18n>
      Here you will find <b>all artists</b> for whom you have clicked <b>at least one service</b>.
      To definitively <b>order</b> their service(s), <b>click on the artist</b> and then <b>continue ordering on their
      profile</b>.<br>
    </span>
      <!-- !!!!!!! created for the future !!!! -->
      <!--    <div *ngVar="basketTimout | async as timeout" i18n>-->
      <!--      Your shopping basket will be cleared in <b>{{ timeout | countdown:'dhms' | async }}</b>.-->
      <!--      Please checkout all items, before they are going to be deleted.-->
      <!--    </div>-->
    </app-hint>

    <app-lazy-list [(items)]="orderedArtists"
                   [component]="this"
                   [enableScroll]="true"
                   [itemHeight]="82"
                   [loadFunction]="callGetBriefProfiles.bind(this, orderedArtists?.nextPage)"
                   [maxScrollHeight]="'20rem' | pixels"
                   [overlayZIndex]="1120"
                   [overlay]="false"
                   [virtual]="false"
                   overlayClass="search-lazy-list-overlay">
      <!--Item content-->
      <ng-template let-orderedArtist pTemplate="item">
        <div class="p-d-flex p-ai-center">
          <!--Clear selected artist button-->
          <app-button (clicked)="clearArtist(orderedArtist)"
                      i18n-pTooltip
                      icon="fa-solid fa-xmark"
                      pTooltip="Delete"
                      tooltipPosition="top"
                      type="danger-text"></app-button>

          <div (click)="selectedProfileClicked(orderedArtist)"
               [disable]="profileClicked"
               class="ordered-artist p-d-flex p-jc-between p-ai-center rounded-medium c-pointer p-m-2 p-p-2 w-100"
               pRipple>
            <div class="p-d-flex p-ai-center">
              <app-avatar [profile]="orderedArtist" [sizePx]="!isScreenOf(Screen.SM) ? 40 : 50"
                          class="p-mr-3 p-mr-sm-5"></app-avatar>
              <h3 class="p-my-0 p-text-bold l-count-1 word-break">{{ orderedArtist.displayName }}</h3>
            </div>
            <i class="fa-solid {{orderedArtist.saving ? 'fa-spin fa-circle-notch' : 'fa-arrow-right'}} p-ml-2"></i>
          </div>
        </div>
      </ng-template>
    </app-lazy-list>
  </div>

  <!--Bottom bar-->
  <ng-template let-discardDisable="discardDisable"
               let-onDiscard="onDiscard" let-onSave="onSave" let-saveDisable="saveDisable"
               let-saveLoading="saveLoading" pTemplate="bottomBar">
    <div class="p-d-flex p-ai-center p-jc-between w-100">
      <!--Clear all-->
      <app-button (clicked)="clearAllArtists()" *ngIf="orderedArtists.content.length > 0"
                  @fade
                  i18n-label
                  icon="fa-solid fa-xmark"
                  label="Clear All"
                  styleClass="p-my-1"
                  type="danger-light"></app-button>

      <!--For the button consistency-->
      <div></div>

      <!-- Save Action -->
      <app-button (clicked)="onDiscard()"
                  [disabled]="discardDisable"
                  i18n-label
                  label="Close"
                  type="text"></app-button>
    </div>

  </ng-template>

  <ng-template #noContent>
    <div class="p-d-flex p-flex-column p-jc-center p-ai-center">
      <i class="info-icon fa-solid fa-user-slash"></i>
      <div class="info-text p-text-center p-my-4" i18n>
        Your basket is empty. Pick some profiles from our catalog to fill the basket!
      </div>
      <!-- To Catalog Action -->
      <app-button (clicked)="navToCatalog()"
                  [highlight]="true"
                  i18n-label
                  icon="fa-solid fa-arrow-right"
                  iconPos="right"
                  label="View Catalog"
                  type="primary"></app-button>
    </div>
  </ng-template>
</app-dialog>
