<div class="wrapper p-relative">
  <div class="p-absolute loading-skeleton" *ngIf="loading" @fade>
    <p-skeleton width="5rem" height="1rem" styleClass="rounded-small p-my-2 p-px-2"></p-skeleton>
    <p-skeleton width="100%" height="2rem" styleClass="rounded-small p-px-2"></p-skeleton>
    <div class="p-grid p-p-0" *ngFor="let i of [].constructor(2)">
      <div class="p-col-12 p-md-6 p-p-0">
        <p-skeleton width="5rem" height="1rem" styleClass="rounded-small p-my-2 p-mr-2"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="rounded-small p-mr-2"></p-skeleton>
      </div>
      <div class="p-col-12 p-md-6 skeleton-second-column">
        <p-skeleton width="5rem" height="1rem" styleClass="rounded-small p-my-2"></p-skeleton>
        <p-skeleton width="100%" height="2rem" styleClass="rounded-small"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="5rem" height="1rem" styleClass="rounded-small p-my-2 p-px-2"></p-skeleton>
    <p-skeleton width="100%" height="2rem" styleClass="rounded-small p-px-2"></p-skeleton>
  </div>

  <!--Invoicing addresses preview-->
  <section *ngIf="invoicingAddresses?.length > 0 && !showNewInvoicingAddress" @grow class="p-py-2">
    <h4 class="p-m-0 p-pl-2" i18n>Remembered Addresses</h4>
    <p-carousel [value]="invoicingAddresses"
                [showNavigators]="false"
                [responsiveOptions]="invoicingAddrResponsiveOpts">
      <ng-template let-addr pTemplate="item">
        <div class="p-relative">
          <!--Delete Badge-->
          <div class="inv-address-delete c-pointer p-absolute p-d-flex p-ai-center p-jc-center"
               pTooltip="Delete this address" i18n-pTooltip
               (click)="showDeleteRememberedAddressDialog(addr)">
            <i class="fa-solid fa-xmark-circle p-absolute"></i>
            <div class="background"></div>
          </div>
          <!--Address content-->
          <div class="inv-address-item rounded-small box-shadow p-m-2 p-p-3 c-pointer"
               [class.selected-addr]="addr.selected" (click)="onInvoicingAddressClick(addr)">
            <div class="p-d-flex w-100">
              <i class="fa-solid fa-location-dot p-mr-3"></i>
              <ul class="p-m-0 p-p-0">
                <li class="l-count-1 p-text-bold">{{ addr?.name }}</li>
                <li class="l-count-1">{{ addr?.line1 }}</li>
                <li class="l-count-1" *ngIf="addr?.line2">{{ addr?.line2 }}</li>
                <li class="l-count-1">{{ addr?.postalCode }} {{ addr?.city }}</li>
                <li class="l-count-1 p-text-bold">{{ addr?.state }}</li>
                <hr *ngIf="addr?.birthDate || addr?.legalEntity" class="p-my-1">
                <li class="l-count-1" *ngIf="addr?.birthDate as b">{{ b.day }}.{{ b.month }}. {{ b.year }}</li>
                <ng-template [ngIf]="addr?.legalEntity">
                  <li class="l-count-2">
                    <span *ngIf="addr.legalEntity.registrationNumber as reg" i18n><b>Reg ID</b>: {{ reg }}, </span>
                    <span *ngIf="addr.legalEntity.taxId as tax"><b i18n>Tax ID</b>: {{ tax }}, </span>
                    <span *ngIf="addr.legalEntity.vatId as vat"><b i18n>Vat ID</b>: {{ vat }}</span>
                  </li>
                </ng-template>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>

    <div class="p-d-flex p-jc-center p-mt-2">
      <!-- Add New Address -->
      <app-button type="secondary"
                  label="Add New"
                  i18n-label
                  icon="fa-solid fa-plus"
                  (clicked)="addNewInvoicingAddress()"></app-button>
    </div>
  </section>

  <div class="p-d-flex p-jc-center p-my-2" *ngIf="invoicingAddresses?.length > 0 && showNewInvoicingAddress">
    <!-- Add New Address -->
    <app-button type="secondary"
                label="Pick from existing"
                i18n-label
                icon="fa-solid fa-address-card"
                (clicked)="returnToInvoicingAddresses()"></app-button>
  </div>

  <div *ngIf="showNewInvoicingAddress" @grow>
    <!-- Legal Entity Form Toggle -->
    <div class="p-ml-2 p-mt-2 w-100">
      <app-checkbox label="Invoicing for a company"
                    i18n-label
                    [form]="checkboxesForm"
                    formFieldName="companyInvoicing"></app-checkbox>
    </div>

    <section *ngIf="checkboxesForm?.controls.companyInvoicing.value" @grow class="p-grid">
      <app-text-input label="Legal Registration Number (CRN)"
                      i18n-label
                      icon="fa-solid fa-user"
                      inputType="text"
                      formFieldName="registrationNumber"
                      [form]="legalEntityForm"
                      [noBlankCharacters]="true"
                      [space]="false"
                      [loading]="legalRegNumberLoading"
                      [required]="true"
                      class="p-col-12 p-sm-6"
                      hint="Your registration number of the invoicing entity in your country."
                      i18n-hint
                      [length]="{min: Restrictions.LEGAL_REGISTRATION_ID_LENGTH, max: Restrictions.LEGAL_REGISTRATION_ID_LENGTH}">

        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages"
                                [check]="ServerMessage.LEGAL_REGISTRATION_ID_INCORRECT" i18n>
          Invalid registration number.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages"
                                [check]="ServerMessage.LEGAL_REGISTRATION_REQUIRED" i18n>
          This field is required for a company.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages"
                                [check]="ServerMessage.LEGAL_REGISTRATION_NUMBER_NOT_FOUND" i18n>
          This number couldn't be found or can't be used.
        </app-backend-validation>
      </app-text-input>

      <!--Tax ID-->
      <app-text-input label="Tax ID"
                      i18n-label
                      icon="fa-solid fa-sack-dollar"
                      inputType="text"
                      formFieldName="taxId"
                      [form]="legalEntityForm"
                      [noBlankCharacters]="true"
                      [space]="false"
                      [required]="true"
                      class="p-col-12 p-sm-6"
                      hint="Your tax id of the invoicing entity in your country."
                      i18n-hint
                      [length]="{min: Restrictions.LEGAL_TAX_ID_LENGTH, max: Restrictions.LEGAL_TAX_ID_LENGTH}">

        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LEGAL_TAX_ID_INCORRECT" i18n>
          Invalid tax id number.
        </app-backend-validation>
      </app-text-input>

      <!--Vat ID-->
      <app-text-input label="Vat ID (Optional)"
                      i18n-label
                      icon="fa-solid fa-sack-dollar"
                      inputType="text"
                      formFieldName="vatId"
                      [form]="legalEntityForm"
                      [noBlankCharacters]="true"
                      [space]="false"
                      class="p-col-12"
                      hint="Your vat id of the invoicing entity in your country."
                      i18n-hint
                      [length]="{min: Restrictions.LEGAL_VAT_ID_LENGTH, max: Restrictions.LEGAL_VAT_ID_LENGTH}">

        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LEGAL_VAT_ID_INCORRECT" i18n>
          Invalid vat id number.
        </app-backend-validation>
      </app-text-input>
    </section>

    <!--address fields-->
    <div class="p-grid" *ngIf="showNewInvoicingAddress || invoicingAddresses?.length === 0" @grow>
      <app-text-input label="Name"
                      i18n-label="@@Person name"
                      inputType="text"
                      formFieldName="name"
                      placeholder="John Smith"
                      i18n-placeholder
                      icon="fa-solid fa-user"
                      class="p-col-12 p-py-0"
                      [form]="addressForm"
                      [required]="true"
                      [length]="{max: Restrictions.MAX_NAME_FIELD_LENGTH}"></app-text-input>

      <!-- Birth Date -->
      <app-date-input *ngIf="!profile.isLegalEntity && !checkboxesForm?.controls.companyInvoicing.value"
                      label="Birth Date"
                      i18n-label
                      hint="We need to put your birth date to the contract with {{profile.displayName}}. (DD.MM.YYYY). Also, you need to be more than {{Restrictions.MIN_BIRTHDATE_YEARS}} years old."
                      i18n-hint
                      placeholder="DD.MM.YYYY"
                      i18n-placeholder
                      header="Birth Date"
                      i18n-header
                      icon="fa-solid fa-calendar"
                      [form]="addressForm"
                      [defaultDate]="birthDatePlaceholder"
                      [required]="true"
                      [keyboard]="true"
                      [showOnFocus]="false"
                      [maxDate]="maxBirthdate"
                      appendTo="body"
                      dateFormat="d.m.yy"
                      formFieldName="birthDate"
                      class="p-col-12 p-py-0"></app-date-input>

      <app-text-input label="Line 1"
                      i18n-label
                      inputType="text"
                      formFieldName="line1"
                      autocomplete="address-line1"
                      placeholder="Street 12/5"
                      i18n-placeholder
                      icon="fa-solid fa-house-flag"
                      class="p-col-12 p-sm-6 p-py-0"
                      [form]="addressForm"
                      [required]="true"
                      [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}">

        <!--FE-CHECK-->
        <app-frontend-validation [form]="addressForm" field="line1" error="containsNumber" i18n>
          Building number is missing
        </app-frontend-validation>
      </app-text-input>

      <app-text-input label="Line 2"
                      i18n-label
                      inputType="text"
                      formFieldName="line2"
                      autocomplete="address-line2"
                      placeholder="Building C (Optional)"
                      i18n-placeholder
                      icon="fa-solid fa-house-flag"
                      class="p-col-12 p-sm-6 p-py-0"
                      [form]="addressForm"
                      [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

      <app-text-input label="City"
                      i18n-label
                      inputType="text"
                      formFieldName="city"
                      icon="fa-solid fa-building-flag"
                      class="p-col-12 p-sm-6 p-py-0"
                      [form]="addressForm"
                      [required]="true"
                      [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

      <app-text-input label="Postal code"
                      i18n-label
                      inputType="text"
                      formFieldName="postalCode"
                      placeholder="12345"
                      icon="fa-solid fa-barcode"
                      class="p-col-12 p-sm-6 p-py-0"
                      [onlyNumbers]="true"
                      [form]="addressForm"
                      [required]="true"
                      [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

      <app-dropdown label="State"
                    i18n-label
                    [options]="supportedCountries"
                    formFieldName="country"
                    class="p-col-12 p-py-0"
                    [form]="addressForm"
                    [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"
                    [required]="true">
        <!--Selected-->
        <ng-template let-selected pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <i [class]="selected.flag" class="p-mx-2"></i>
            <div>{{ selected.name }}</div>
          </div>
        </ng-template>

        <!--Item-->
        <ng-template let-type pTemplate="item">
          <div class="p-d-flex p-ai-center">
            <i [class]="type.flag" class="p-mr-2"></i>
            <div>{{ type.name }}</div>
          </div>
        </ng-template>
      </app-dropdown>

      <!-- Remember Address -->
      <div class="p-col-12 p-mt-2" *ngIf="authorAddress?.lat !== DEFAULT_MAP_POSITION">
        <app-checkbox label="Remember address"
                      i18n-label
                      [form]="checkboxesForm"
                      formFieldName="saveAddress"
                      [class.dropdown-margin]="isScreenOf(Screen.SM)"
                      styleClass="p-ml-2"></app-checkbox>
      </div>

      <!--Location couldn't be find-->
      <app-hint *ngIf="authorAddress?.lat === DEFAULT_MAP_POSITION" @grow
                styleClass="p-mt-2"
                i18n>
        We were <b>not able to verify your address</b>. Please continue only if the address is correct.
      </app-hint>

      <!--Bottom Margin-->
      <div class="p-mb-5"></div>
    </div>
  </div>

  <!--Call response-->
  <app-call-response [component]="this" styleClass="p-my-2"></app-call-response>
</div>

<!--Remembered address - Delete confirm-->
<app-dialog-delete-confirm *ngIf="deleteAddressConfirmVisible"
                           [(show)]="deleteAddressConfirmVisible"
                           [component]="this"
                           question="Are you sure you want to delete this saved address?"
                           i18n-question
                           successMessage="Address has been deleted."
                           i18n-successMessage
                           [save]="deleteRememberedAddress.bind(this)"
                           (ngInit)="deleteAddressConfirmVisibleChange.emit(true)"
                           (showChange)="resetApi();deleteAddressConfirmVisibleChange.emit(deleteAddressConfirmVisible)">
  <div class="inv-address-item rounded-small box-shadow p-m-2 p-p-3" *ngVar="deleteInvoicingAddress as addr">
    <div class="p-d-flex w-100">
      <i class="fa-solid fa-location-dot p-mr-3"></i>
      <ul class="p-m-0 p-p-0">
        <li class="l-count-1 p-text-bold">{{ addr?.name }}</li>
        <li class="l-count-1">{{ addr?.line1 }}</li>
        <li class="l-count-1" *ngIf="addr?.line2">{{ addr?.line2 }}</li>
        <li class="l-count-1">{{ addr?.postalCode }} {{ addr?.city }}</li>
        <li class="l-count-1 p-text-bold">{{ addr?.state }}</li>
      </ul>
    </div>
  </div>
</app-dialog-delete-confirm>
