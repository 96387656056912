import {Injectable} from '@angular/core'
import {CookieService} from 'ngx-cookie-service'
import {PLATFORM_BROWSER} from '../app.module'
import {COOKIE_ORIGIN, environment} from '../../environments/environment'

/**
 * This service operates with the session and local browser storage.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private cookies: CookieService) {
  }

  /**
   * Initializes the {@link USE_LOCAL_STORAGE} storage value.
   * If the 'useLocalStorage' is true, it sets the {@link USE_LOCAL_STORAGE} to true,
   * which means that all further properties will be stored in the local storage.
   * Also moves all items from session storage to local storage.
   */
  initStorageAfterLogin(useLocalStorage: boolean): void {
    if (useLocalStorage) {
      localStorage.setItem(StorageItem.USE_LOCAL_STORAGE, String(true))

      // Iterate over all keys in sessionStorage
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i)
        const value = sessionStorage.getItem(key)

        // Move each key-value pair from sessionStorage to localStorage
        localStorage.setItem(key, value)
      }
      // Clear sessionStorage if needed
      sessionStorage.clear()
    } else {
      localStorage.removeItem(StorageItem.USE_LOCAL_STORAGE)
    }
  }

  /**
   * Tries to get the value by the key from the browser storage.
   * If the value from the session storage is null, it returns the value from the local storage.
   */
  getItemStorage(key: StorageItem): any | null {
    if (PLATFORM_BROWSER) {
      let item = sessionStorage.getItem(key)
      if (!item) {
        item = localStorage.getItem(key)
      }
      return item
    } else {
      return null
    }
  }

  /**
   * - Sets an item to the browser storage.
   * - If the {@link toSession} is 'true', the item will be stored into the session storage. (null by default).
   * If 'false', the local storage is used.
   * If 'null' it is determined by the {@link USE_LOCAL_STORAGE} value. 'True' -> the item will be stored into the local storage.
   * Otherwise, the session storage is used.
   */
  setItemStorage(key: StorageItem, value: string, toSession: boolean | null = null): void {
    if (PLATFORM_BROWSER) {
      const toLocalStorage = (toSession === null) ? this.getItemStorage(StorageItem.USE_LOCAL_STORAGE) : !toSession
      if (toLocalStorage) {
        localStorage.setItem(key, value)
        sessionStorage.removeItem(key)
      } else {
        sessionStorage.setItem(key, value)
        localStorage.removeItem(key)
      }
    }
  }

  /**
   * Clears an item by key in the local and session storage.
   */
  clearItemStorage(key: StorageItem): void {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  }

  /**
   * Adds a new secured cookie to the browser for this {@link COOKIE_ORIGIN}.
   *
   * @param name Name of a cookie.
   * @param value Value of a cookie.
   * @param path Path on which the cooke will be sent.
   * @param maxAge Max time in seconds that the cookie will live from this moment.
   */
  setCookie(name: StorageItem, value: string, path: string = '/', maxAge: number): void {
    this.cookies.set(name, value, maxAge, path, `${COOKIE_ORIGIN}`, environment.sslEnabled)
  }

  /**
   * Gets a cookie from the browser identified by the {@link name}.
   */
  getCookie(name: StorageItem | string): string | null {
    return this.cookies.get(name)
  }

  /**
   * Deletes a cookie from the browser identified by the {@link name}.
   */
  deleteCookie(name: StorageItem | string): void {
    this.cookies.delete(name)
  }

  /**
   * Checks for the presence of a cookie in the browser identified by {@link name}.
   */
  checkCookie(name: StorageItem | string): boolean {
    return this.cookies.check(name)
  }

  /**
   * Clears all {@link StorageItem} values from the cookies, session and local storage.
   */
  clearAll(): void {
    const except = [
      StorageItem.COOKIES_SETTINGS,
      StorageItem.PLATFORM_RATING
    ]
    Object.values(StorageItem).filter(it => !except.find(it2 => it2 === it)).forEach(it => {
      this.deleteCookie(it)
      this.clearItemStorage(it)
    })
  }
}

/**
 * All available items that can be stored into the local or session storage.
 */
export enum StorageItem {
  /**
   * Stores the Firebase Cloud Messaging token.
   */
  FCM_TOKEN = 'fcm_token',
  /**
   * Contains a part of authorization token for server.
   */
  TOKEN_COOKIE = 'token_cookie',
  /**
   * Used to determine whether the 'access_token' HttpOnly cookie is present in the browser.
   */
  ACCESS_TOKEN_PRESENT = 'access_token_present',
  /**
   * Contains session uid for current user's active session.
   */
  SESSION_COOKIE = 'session_cookie',
  /**
   * Stores the uuid of the current used profile.
   */
  CURRENT_PROFILE = 'current_profile',
  /**
   * Stores more info about order like number of guests or specific info about event.
   */
  USER_ORDER_INFO = 'user_order_info',
  /**
   * Stores name and address info about author.
   */
  USER_ORDER_AUTHOR_ADDRESS = 'user_order_author_address',
  /**
   * Stores name and address info about author and location of order.
   */
  USER_ORDER_ADDRESS = 'user_order_address',
  /**
   * Stores selected calendar data.
   */
  SELECTED_CALENDAR_DATA = 'selected_calendar_data',
  /**
   * Stored selected price item additions by char id of the profile.
   */
  SELECTED_ADDITIONS_BY_ID = 'selected_additions_by_id',
  /**
   * Stored selected price items by char id of the profile.
   */
  SELECTED_PRICE_ITEMS_BY_ID = 'selected_price_items_by_id',
  /**
   * Stores number value of when the shopping basket should be emptied.
   * @deprecated created for the future
   */
  SHOPPING_BASKET_EXPIRES_AT = 'shopping_basket_expires_at',
  /**
   * Stores number value of when the shopping order should be emptied.
   * @deprecated created for the future
   */
  SHOPPING_ORDER_EXPIRES_AT = 'shopping_order_expires_at',
  /**
   * Stores flag whether the current user updated timeout time.
   * @deprecated created for the future
   */
  UPDATED_BASKET_TIMEOUT_AFTER_LOGIN = 'updated_timeout',
  /**
   * The current dates the user has selected to filter profiles.
   */
  BOOK_DATES = 'book_dates',
  /**
   * The current selected profile filter settings.
   */
  BOOK_FILTER = 'book_filter',
  /**
   * Current number of tabs opened in the browser.
   */
  OPENED_TABS = 'opened_tabs',
  /**
   * Current register request of a firebase provider.
   */
  FIREBASE_REGISTER_REQ = 'firebase_auth_req',
  /**
   * Determines whether the local storage or the session storage should be used.
   */
  USE_LOCAL_STORAGE = 'use_local_storage',
  /**
   * A storage key to the value of last chat category.
   */
  LAST_CHAT_CATEGORY = 'last_chat_category',
  /**
   * Local storage key that contains all cookie data.
   */
  COOKIES_SETTINGS = 'cookies',
  /**
   * Holds a platform rating data.
   */
  PLATFORM_RATING = 'rating',
  /**
   * Storage key for storing the date when user was asked about installing as PWA.
   */
  PWA_DIALOG = 'pwa'
}

