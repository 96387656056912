import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {ValidComponent} from '../../../../abstract/valid.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {LegalEntityService, SKLegalApiItemResp} from '../../../../../service/legal-entity.service'
import {Country, countryDetailsOf, countryDetailsOfCode, SUPPORTED_COUNTRIES} from '../../../../../common/country'
import {AddressResp, PostalReq} from '../../../../../service/address.service'
import {Restrictions} from '../../../../../common/restrictions'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {StripeService} from '../../../../../service/stripe.service'
import {StorageItem, StorageService} from '../../../../../service/storage.service'
import {growAnimation} from '../../../../../animation/grow.animation'
import {MapService, OSMSearchResult} from '../../../../../service/map.service'
import {fadeAnimation} from '../../../../../animation/fade.animation'
import {containsNumber} from '../../../../../validator/custom.validators'
import {removeAllWhitespaces} from '../../../../../utils/string.utils'
import {minusMonths} from '../../../../../utils/date.utils'
import {ProfileResp} from '../../../../../service/profile.service'
import {Birthdate, UserService} from '../../../../../service/user.service'
import {BasketService} from '../../../../../service/basket.service'
import {ServerMessage} from '../../../../../common/server-message'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-order-invoicing-address',
  templateUrl: './order-invoicing-address.component.html',
  styleUrls: ['./order-invoicing-address.component.scss']
})
export class OrderInvoicingAddressComponent extends ValidComponent implements OnInit, OnDestroy {
  /**
   * The minimal search timeout for {@link apiSearchTimeout}.
   */
  private static readonly API_SEARCH_TIMEOUT = 750
  /**
   * The default lat, and lng position in the address when the {@link startMapSearch} request failed.
   */
  readonly DEFAULT_MAP_POSITION = 1e-7
  /**
   * A current profile to be ordered.
   */
  @Input()
  profile: ProfileResp
  /**
   * Emits the loading process of the component.
   */
  @Output()
  loadingChange = new EventEmitter<boolean>()
  /**
   * Emits when the navigation parts should be disabled due to this component.
   */
  @Output()
  disableNavigation = new EventEmitter<boolean>()
  /**
   * Emits when the {@link deleteAddressConfirmVisible} dialog visibility is changed.
   */
  @Output()
  deleteAddressConfirmVisibleChange = new EventEmitter<boolean>()
  /**
   * Primary address fields.
   */
  addressForm: FormGroup
  /**
   * Legal entity fields.
   */
  legalEntityForm: FormGroup
  /**
   * The checkboxes form containing companyInvoicing and saveAddress option.
   */
  checkboxesForm: FormGroup
  /**
   * Represents a loading state of {@link legalEntityResp}.
   */
  legalRegNumberLoading: boolean
  /**
   * The current response from the API of the {@link legalEntityForm}'s registrationNumber.
   */
  legalEntityResp?: SKLegalApiItemResp
  /**
   * Defines the responsive options for {@link invoicingAddresses} carousel.
   */
  invoicingAddrResponsiveOpts: any[] = []
  /**
   * Changes the layout to adding a new invoicing address.
   */
  showNewInvoicingAddress: boolean
  /**
   * All available saved invoicing addresses of {@link data} customer.
   */
  invoicingAddresses: AddressResp[] = []
  /**
   * A list of all supported countries.
   */
  supportedCountries: typeof SUPPORTED_COUNTRIES = SUPPORTED_COUNTRIES
  /**
   * Defines the search api (OSM, SK legal api) timeout, before it starts searching.
   */
  apiSearchTimeout: any
  /**
   * Controls the visibility of the confirmation dialog for the {@link deleteInvoicingAddress}.
   */
  deleteAddressConfirmVisible: boolean
  /**
   * The current address from the {@link invoicingAddresses} that is going to be deleted.
   */
  deleteInvoicingAddress: PostalReq
  /**
   * An example birthdate shown in the birthdate field of the {@link addressForm}.
   */
  birthDatePlaceholder = minusMonths(new Date(), 12 * 25)
  /**
   * A maximum birthdate that can be selected.
   */
  maxBirthdate = minusMonths(new Date(), 12 * Restrictions.MIN_BIRTHDATE_YEARS)
  /**
   * The current postal information.
   */
  authorAddress: PostalReq
  /**
   * The forms' subscriptions.
   */
  private formSubs?: Subscription[] = []
  /**
   * The {@link addressForm} value changes subscription.
   */
  private addressFormSub?: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private storageService: StorageService,
    private mapService: MapService,
    private legalEntityService: LegalEntityService,
    private basketService: BasketService,
    private changeRef: ChangeDetectorRef,
    private userService: UserService) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.initResponsiveOptions()
    this.initAddress()
  }

  /**
   * Initializes the {@link authorAddress} property from the local storage and the {@link invoicingAddresses} from the API.
   */
  private initAddress(): void {
    // If user not logged in initialize empty form
    if (this.userService.user.getValue() === null) {
      this.authorAddress = this.loadAuthorAddress()
      this.showNewInvoicingAddress = true
      this.initForms()
    } else {
      // Load user address only if the user is logged in
      this.call(async () => {
        this.authorAddress = this.loadAuthorAddress()
        const savedAddresses = await firstValueFrom(this.callLoadAllInvoicingAddresses())
        // Filter valid addresses for the ordered profile
        this.invoicingAddresses = savedAddresses.filter(it => {
          const requiresBirthdate = !this.profile.isLegalEntity
          // returns all with legal entity, or if birthdate is required, only those with a birthdate specified, or all when the birthdate is not required
          return (!!it.legalEntity || (requiresBirthdate && !!it.birthDate) || !requiresBirthdate)
        })
        this.showNewInvoicingAddress = (this.authorAddress && !this.authorAddress.id)
          || (!this.invoicingAddresses || this.invoicingAddresses.length === 0)
        if (this.showNewInvoicingAddress) {
          this.initForms()
        } else { // find address
          const addr = this.authorAddress ? this.invoicingAddresses?.find(it => it.id === this.authorAddress.id) : null
          if (addr) {
            this.onInvoicingAddressClick(addr)
            this.validateAddress()
          } else {
            this.authorAddress = null
            this.saveAuthorAddress()
          }
        }
      })
    }
  }

  /**
   * Initializes all forms ({@link addressForm} and {@link legalEntityForm}) and their value changes subscriptions.
   */
  private initForms(): void {
    // Init checkboxes form
    this.checkboxesForm = this.formBuilder.group({
      companyInvoicing: [!!this.authorAddress?.legalEntity || false],
      saveAddress: [this.authorAddress ? this.authorAddress.saveAddress : true]
    })

    // Init address form
    this.addressForm = this.formBuilder.group({
      name: [this.authorAddress?.name || ''],
      birthDate: [this.createBirthdateAsDate(this.authorAddress?.birthDate) || ''],
      line1: [this.authorAddress?.line1 || '', [containsNumber()]],
      line2: [this.authorAddress?.line2 || ''],
      city: [this.authorAddress?.city || ''],
      postalCode: [this.authorAddress?.postalCode || ''],
      state: [this.authorAddress?.state || ''],
      country: [countryDetailsOfCode(this.authorAddress?.country) || countryDetailsOf(Country.SLOVAKIA)]
    })

    // Init legal entity form
    const le = this.authorAddress?.legalEntity
    this.legalEntityForm = this.formBuilder.group({
      registrationNumber: [le?.registrationNumber || ''],
      taxId: [le?.taxId || ''],
      vatId: [le?.vatId || '']
    })

    // Init forms' value changes subscriptions
    this.updateAddressSubscription(!this.checkboxesForm.value.companyInvoicing)
    if (this.checkboxesForm.value.companyInvoicing) {
      this.addressForm.disable()
    }

    // Legal Entity form changes
    this.formSubs.push(this.legalEntityForm.valueChanges.subscribe(this.legalEntityChanged.bind(this)))
    this.formSubs.push(this.legalEntityForm.controls.registrationNumber.valueChanges.subscribe(this.registrationNumberChanged.bind(this)))

    // saveAddress checkbox changes
    this.formSubs.push(this.checkboxesForm.controls.saveAddress.valueChanges.subscribe(saveAddress => {
      if (this.authorAddress) {
        this.authorAddress.saveAddress = saveAddress
        this.saveAuthorAddress()
      }
    }))

    // CompanyInvoicing checkbox changes
    this.formSubs.push(this.checkboxesForm.controls.companyInvoicing.valueChanges.subscribe((companyInvoicing) => {
      this.legalEntityResp = null
      this.authorAddress = null
      this.clearAddressForm()
      this.clearLegalEntityForm()
      this.updateAddressSubscription(!companyInvoicing)
      // enable or disable address form
      if (companyInvoicing) {
        this.addressForm.disable()
      } else {
        this.addressForm.enable()
      }
    }))
    this.validateAddress()
  }

  /**
   * - Fires when the {@link addressForm} has changed.
   * - Saves the {@link authorAddress} to a localstorage. (calls the {@link saveAuthorAddress})
   */
  private addressChanged(): void {
    const formData = this.addressForm.value
    this.authorAddress = {
      name: formData.name,
      birthDate: this.createBirthdate(formData.birthDate),
      lat: null,
      lng: null,
      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      postalCode: formData.postalCode,
      state: formData.country?.name,
      country: formData.country?.code,
      saveAddress: this.checkboxesForm.value.saveAddress
    }
    clearTimeout(this.apiSearchTimeout)
    this.disableNavigation.emit(true)
    this.apiSearchTimeout = setTimeout(() => {
      this.customCall(async () => {
        this.loadingChange.emit(true)
        await this.startMapSearch()
        this.saveAuthorAddress()
      }, null, () => {
        this.disableNavigation.emit(false)
        this.loadingChange.emit(false)
      })
    }, OrderInvoicingAddressComponent.API_SEARCH_TIMEOUT)
  }

  /**
   * Returns a {@link Birthdate} from the parameter when the {@link profile} is not a legal entity.
   * @param birth A form value of the birthdate.
   */
  private createBirthdate(birth: Date): Birthdate | null {
    if (birth && !this.profile.isLegalEntity) {
      birth = new Date(birth)
      return {
        year: birth.getFullYear(),
        month: birth.getMonth() + 1,
        day: birth.getDate()
      }
    }
    return null
  }

  /**
   * Converts string to the {@link Date} object when the {@link profile} is not a legal entity.
   */
  private createBirthdateAsDate(birth: Birthdate): Date | null {
    if (birth && !this.profile.isLegalEntity) {
      return new Date(birth.year, birth.month - 1, birth.day)
    }
    return null
  }

  /**
   * Fires when the {@link legalEntityForm} has changed.
   */
  private legalEntityChanged(): void {
    const fd = this.legalEntityForm.value
    // Clear VatId error on empty field
    if (!fd.vatId) {
      this.legalEntityForm.controls.vatId.setErrors(null)
    }

    if (this.authorAddress?.legalEntity) {
      // Save TaxId
      if (fd.taxId?.length === Restrictions.LEGAL_TAX_ID_LENGTH) {
        this.authorAddress.legalEntity.taxId = fd.taxId
      } else {
        this.authorAddress.legalEntity.taxId = null
      }
      // Save VatId
      if (fd.vatId?.length === Restrictions.LEGAL_VAT_ID_LENGTH) {
        this.authorAddress.legalEntity.vatId = fd.vatId
      } else {
        this.authorAddress.legalEntity.vatId = null
      }
      this.saveAuthorAddress()
    }
  }

  /**
   * Fires when the 'registrationNumber' of the {@link legalEntityForm} has changed.
   */
  private registrationNumberChanged(regNumber: string): void {
    // stop the current api search task if it exists
    clearTimeout(this.apiSearchTimeout)

    // Registration number change
    if (regNumber?.length === Restrictions.LEGAL_REGISTRATION_ID_LENGTH
      && (!this.legalEntityResp || this.legalEntityResp.identifier !== regNumber)) {

      // start search after a certain amount of time
      this.disableNavigation.emit(true)
      this.apiSearchTimeout = setTimeout(() => {
        this.customCall(async () => {
          this.legalRegNumberLoading = true
          this.legalEntityResp = await firstValueFrom(this.callCheckLegalRegNumber(regNumber))
          // assign identifier
          if (this.legalEntityResp) {
            this.legalEntityResp.identifier = regNumber
          }
          await this.updateAddressWithLegalEntity()
        }, null, () => {
          this.legalRegNumberLoading = false
          this.disableNavigation.emit(false)
        })
      }, OrderInvoicingAddressComponent.API_SEARCH_TIMEOUT)
      // clear legal entity
    } else {
      this.disableNavigation.emit(false)
      this.legalEntityResp = null
      this.updateAddressWithLegalEntity()
    }
  }

  /**
   * Updates the {@link authorAddress} with the legal entity from the {@link legalEntityForm} only if the {@link legalEntityResp} exists.
   */
  private async updateAddressWithLegalEntity(): Promise<void> {
    const fd = this.legalEntityForm.value
    if (this.legalEntityResp) {
      const leAddr = this.legalEntityResp?.addresses?.[0]
      this.authorAddress = {
        name: this.legalEntityResp.fullNames[0].value,
        birthDate: null,
        line1: `${leAddr?.street || ''} ${leAddr?.buildingNumber || ''}`,
        line2: '',
        city: leAddr?.municipality?.value || '',
        postalCode: removeAllWhitespaces(leAddr?.postalCodes?.[0] || ''),
        state: countryDetailsOf(Country.SLOVAKIA).name,
        country: countryDetailsOf(Country.SLOVAKIA).code,
        lat: null, // gets updated via the startMapSearch() function
        lng: null,
        saveAddress: this.checkboxesForm.value.saveAddress,
        legalEntity: {
          registrationNumber: fd.registrationNumber || null,
          taxId: fd.taxId || null,
          vatId: fd.vatId || null,
          individual: false // TODO
        }
      }
      await this.startMapSearch()
    } else {
      this.authorAddress = null
    }
    const fc = this.addressForm.controls
    fc.name.setValue(this.authorAddress?.name || '')
    fc.line1.setValue(this.authorAddress?.line1 || '')
    fc.line2.setValue(this.authorAddress?.line2 || '')
    fc.city.setValue(this.authorAddress?.city || '')
    fc.postalCode.setValue(this.authorAddress?.postalCode || '')
    fc.state.setValue(this.authorAddress?.state || '')
    fc.country.setValue(countryDetailsOfCode(this.authorAddress?.country) || countryDetailsOf(Country.SLOVAKIA))
    this.saveAuthorAddress()
  }

  /**
   * - Starts the map search for getting the lat/lng location of the {@link authorAddress}.
   * - Updates the {@link authorAddress} object.
   */
  private async startMapSearch(): Promise<void> {
    const a = this.authorAddress
    if (!a || !a.line1 || !a.city || !a.country) {
      return
    }

    const resp = await this.callMapSearch(`${a.line1} ${a.city} ${a.postalCode} ${a.country}`)
    if (!resp) {
      this.pushToMessages(this.ServerMessage.ADDRESS_NOT_FOUND)
      this.authorAddress.lat = this.DEFAULT_MAP_POSITION // because JS evaluates 0 as 'false'
      this.authorAddress.lng = this.DEFAULT_MAP_POSITION
      this.authorAddress.saveAddress = false
    } else {
      this.authorAddress.lat = +resp.lat
      this.authorAddress.lng = +resp.lon
      // Based on the user feedback, automatic adress rewrite is disabled
      // this.updateAddressFields(resp)
    }
    this.saveAuthorAddress()
  }

  /**
   * Checks the {@link authorAddress} object whether it is correctly filled.
   */
  private isAddressValid(): boolean {
    const a = this.authorAddress
    return !!(a && a.name && a.line1 && a.city && a.postalCode && a.state && a.country && a.lat && a.lng
        // if legal entity selected, tax & reg number must be present
        && (a.legalEntity ? (a.legalEntity.taxId && a.legalEntity.registrationNumber) : true))
      // if the profile isn't a legal entity, a birthdate is required, when the author is not invoicing on a company
      && ((!this.profile.isLegalEntity && !a.legalEntity) ? !!a.birthDate : true)
      // if adding a new invoicing address, the ID must be empty
      && (this.showNewInvoicingAddress ? !a.id : !!a.id)
  }

  /**
   * Changes the layout to adding a new invoicing address.
   */
  addNewInvoicingAddress(): void {
    this.invoicingAddresses?.forEach(it => it['selected'] = undefined)
    this.invoicingAddresses = [...this.invoicingAddresses]
    this.authorAddress = null

    this.saveAuthorAddress()
    this.initForms()
    this.showNewInvoicingAddress = true
  }

  /**
   * Returns to the previous layout where the user can pick from his {@link invoicingAddresses}.
   */
  returnToInvoicingAddresses(): void {
    this.showNewInvoicingAddress = false
    this.authorAddress = null
    this.saveAuthorAddress()
  }

  /**
   * Fires when the user clicks on the 'Add new invoicing address'.
   */
  onInvoicingAddressClick(item: AddressResp): void {
    this.invoicingAddresses?.forEach(it => it['selected'] = undefined)
    item['selected'] = true
    this.invoicingAddresses = [...this.invoicingAddresses]
    this.authorAddress = item as PostalReq

    this.saveAuthorAddress()
  }

  /**
   * Calls the server API to load all invoicing addresses that the {@link data} customer have saved.
   */
  private callLoadAllInvoicingAddresses(): Observable<AddressResp[]> {
    return this.unwrap(this.stripeService.callGetAllInvoicingAddresses())
  }

  /**
   * Calls the server API to check the legal registration number of a company.
   */
  private callCheckLegalRegNumber(regNumber: string): Observable<SKLegalApiItemResp | null> {
    return this.unwrap(this.legalEntityService.searchByRegistrationNumber(regNumber))
  }

  /**
   * Starts a map search for the lat/lng of the given location determined by {@link search} parameter.
   */
  private async callMapSearch(search: string): Promise<OSMSearchResult | null> {
    return (await firstValueFrom(this.mapService.callOSMSearch(search)))?.[0]
  }

  /**
   * Loads the invoicing-author {@link authorAddress} from the local storage.
   */
  private loadAuthorAddress(): PostalReq {
    return JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)) as PostalReq
  }

  /**
   * Saves the invoicing-author {@link authorAddress} to the local storage.
   */
  private saveAuthorAddress(): void {
    this.basketService.updateOrderStorage(null, null, this.authorAddress, null)
    // this.basketService.createNewOrderTimeout() // created for the future
    this.validateAddress()
  }

  /**
   * Updates certain address fields from the {@link OSMSearchResult} object.
   * - Auto rewrites the 'PostalCode' and 'country' field based on the {@link osmResult}.
   */
  private updateAddressFields(osmResult: OSMSearchResult): void {
    if (osmResult && this.showNewInvoicingAddress) {
      const opts = {emitEvent: false}
      const c = this.addressForm.controls
      const postalCode = osmResult.address?.postcode?.replace(' ', '') || ''
      const country = countryDetailsOfCode(osmResult.address.country_code) || countryDetailsOf(Country.SLOVAKIA)
      c.country.setValue(country, opts)
      c.postalCode.setValue(postalCode, opts)
      this.authorAddress.country = country.code
      this.authorAddress.postalCode = postalCode
    }
  }

  /**
   * Validates the current order step of the address.
   */
  private validateAddress(): void {
    const legalEnabled = this.checkboxesForm?.value.companyInvoicing
    let addressValid: boolean

    // Adding new invoicing address
    if (this.showNewInvoicingAddress) {
      addressValid = ((this.addressForm.enabled && this.addressForm.valid) || !this.addressForm.enabled)
        && this.isAddressValid()
        && ((legalEnabled && this.legalEntityForm.valid) || !legalEnabled)
    } else { // must be selected
      addressValid = !!this.authorAddress?.id
    }

    addressValid = addressValid && this.serverMessages.filter(it => it !== ServerMessage.ADDRESS_NOT_FOUND).length === 0
    this.setValid(!!addressValid)
  }

  /**
   * Adds or removes a value change subscription of the {@link addressForm} based on the {@link subscribe} parameter.
   */
  private updateAddressSubscription(subscribe: boolean): void {
    this.addressFormSub?.unsubscribe()
    if (subscribe) {
      this.addressFormSub = this.addressForm.valueChanges.subscribe(this.addressChanged.bind(this))
    }
  }

  /**
   * Clears the {@link addressForm}.
   */
  private clearAddressForm(): void {
    const c = this.addressForm.controls
    c.name.setValue('')
    c.birthDate.setValue('')
    c.line1.setValue('')
    c.line2.setValue('')
    c.city.setValue('')
    c.postalCode.setValue('')
    c.state.setValue('')
    c.country.setValue(countryDetailsOf(Country.SLOVAKIA))
  }

  /**
   * Clears the {@link legalEntityForm}.
   */
  private clearLegalEntityForm(): void {
    const c = this.legalEntityForm.controls
    c.registrationNumber.setValue('')
    c.taxId.setValue('')
    c.vatId.setValue('')
  }

  /**
   * Shows a confirmation dialog of deleting the {@link authorAddress}.
   * Updates the {@link deleteInvoicingAddress}.
   */
  showDeleteRememberedAddressDialog(address: PostalReq): void {
    this.deleteInvoicingAddress = address
    this.deleteAddressConfirmVisible = true
    this.changeRef.detectChanges()
  }

  /**
   * Calls the server API to delete the {@link deleteInvoicingAddress} from the database and the {@link invoicingAddresses}.
   */
  async deleteRememberedAddress(): Promise<void> {
    const resp = await firstValueFrom(this.unwrap(this.stripeService.callDeleteInvoicingAddress(this.deleteInvoicingAddress.id)))
    if (resp && this.noServerMessages()) {
      const index = this.invoicingAddresses.findIndex(it => it.id === this.deleteInvoicingAddress.id)
      this.invoicingAddresses.splice(index, 1)
      this.invoicingAddresses = [...this.invoicingAddresses]

      // If the currently selected is the address to delete
      if (this.authorAddress?.id === this.deleteInvoicingAddress.id) {
        this.returnToInvoicingAddresses()
      }

      // If no address left, show the address form
      if (this.invoicingAddresses?.length === 0) {
        this.addNewInvoicingAddress()
      }
    }
  }

  ngOnDestroy(): void {
    this.addressFormSub?.unsubscribe()
    // unsubscribe all form value changes
    this.formSubs.forEach((it) => {
      it?.unsubscribe()
    })
  }

  /**
   * Initializes responsive options for {@link invoicingAddresses} carousel.
   */
  private initResponsiveOptions(): void {
    this.invoicingAddrResponsiveOpts = [
      {
        breakpoint: '9999px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '576px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '492px',
        numVisible: 1,
        numScroll: 1
      }]
  }
}
