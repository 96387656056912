import {ProfileType} from '../common/profile-type'

export function getSkillAssetPath(skillName: string): string {
  const path = 'assets/icon/skill/skill-' + skillName.toLowerCase() + '.png'

  if (doesFileExist(path)) {
    return path
  } else {
    return 'assets/icon/skill/skill-default.png'
  }

}


export function doesFileExist(urlToFile): boolean {
  const xhr = new XMLHttpRequest()
  xhr.open('HEAD', urlToFile, false)
  xhr.send()

  return xhr.status !== 404
}

/**
 * Returns the default avatar image by the given profile type.
 * If the {@link profileType} is null, it returns the {@link ProfileType.USER} image.
 */
export function defaultAvatar(profileType?: ProfileType): string {
  const name = (profileType || ProfileType.USER).toLowerCase()
  // The name requires to contain 'default' word due to the avatar pipe
  return `assets/profile/avatar/default-${name}.svg`
}

/**
 * Returns the default wallpaper image.
 */
export function defaultWallpaper(): string {
  return `assets/profile/wallpaper/default-wallpaper.png`
}
