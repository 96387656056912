import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {AbstractComponent} from '../../abstract/abstract.component'
import {ButtonModule} from 'primeng/button'
import {RippleModule} from 'primeng/ripple'
import {RouterLink} from '@angular/router'
import {NgIf, NgTemplateOutlet} from '@angular/common'

/**
 * - Use this component for declaring a button.
 * - Contains a set of buttons from PrimeNG that suits with the rest of the layout.
 * - Use the {@link type} to specify an occasion for a button.
 * - Use the {@link label}, or {@link icon} to specify more intuitive information.
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    RippleModule,
    RouterLink,
    NgTemplateOutlet,
    NgIf
  ]
})
export class ButtonComponent extends AbstractComponent implements OnInit, OnChanges {

  /**
   * Defines the layout of a button.
   * - Primary - For main operations.
   * - Secondary - For other options.
   * - Warning - Requires attention.
   * - Danger - Important decisions.
   * - Danger-Light - Less important decisions.
   * - Edit, Save, Discard, Delete, Delete-Light - Predefined icon & label with styles.
   * - Icon - Only icon is visible with the secondary styles.
   * - Text - Only text is visible with the secondary styles.
   * - Danger-Text - Only text is visible with the danger-light styles.
   * - CTA - A rectangle button with medium rounding and linear gradient to emphasize the CTA.
   */
  @Input()
  type?: 'primary' | 'secondary' | 'warning' | 'danger' | 'danger-light' | 'danger-text' | 'edit' | 'save' | 'discard' | 'delete' | 'delete-light' | 'icon' | 'text' | 'cta'

  /**
   * The label of a button.
   */
  @Input()
  label: string

  /**
   * Hides the button label on small screens.
   */
  @Input()
  labelAutoHide = false

  @Input()
  url?: any[]

  /**
   * The left icon of a button.
   */
  @Input()
  icon: string
  /**
   * Starts playing the highlight animation.
   */
  @Input()
  highlight: boolean
  /**
   * Defines the icon position.
   */
  @Input()
  iconPos: 'left' | 'right' | 'top' | 'bottom' = 'left'

  /**
   * The additional style class for a button.
   */
  @Input()
  styleClass: string

  /**
   * Disables the button.
   */
  @Input()
  disabled: boolean

  /**
   * Sets the button to the loading state.
   */
  @Input()
  loading: boolean

  /**
   * Fires when a user clicked on the button.
   */
  @Output()
  clicked = new EventEmitter<any>()

  /**
   * Represents the custom disabled class for a specific {@link type} button.
   */
  disabledClass: string

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.initBaseClasses()
    this.initProperties()

    if (this.type === 'icon' && !this.icon) {
      throw new Error('<app-button>: Missing [icon] specification.')
    }

    if (!this.label && !this.icon) {
      throw new Error('<app-button>: The [label] or [icon] not set.')
    }

    if (!this.type && !this.styleClass) {
      throw new Error('<app-button>: Button\'s [styleClass] not set.')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
    if (changes.disabled || changes.loading) {
      if (this.type === 'primary') {
        this.disabledClass = (this.disabled) ? 'primary-disabled' : ((this.loading) ? 'custom-disabled' : '')
      } else {
        this.disabledClass = (this.loading || this.disabled) ? 'custom-disabled' : ''
      }
    }
  }

  /**
   * Initializes properties by the {@link type} property.
   */
  private initProperties(): void {
    switch (this.type) {
      case 'save':
        this.icon = this.icon || 'fa-solid fa-check'
        this.label = this.label || $localize`Save`
        break
      case 'edit':
        this.icon = this.icon || 'fa-solid fa-pen'
        this.label = this.label || $localize`Edit`
        break
      case 'discard':
        this.icon = this.icon || 'fa-solid fa-xmark'
        this.label = this.label || ((this.type === 'discard') ? $localize`Discard` : ((this.type === 'info') ? $localize`Info` : $localize`Cancel`))
        break
      case 'warning':
        this.icon = this.icon || 'fa-solid fa-triangle-exclamation'
        this.label = this.label || $localize`Warning`
        break
      case 'delete':
      case 'delete-light':
        this.icon = this.icon || 'fa-solid fa-trash'
        this.label = this.label || $localize`Delete`
        break
      case 'icon':
        this.label = ''
        break
    }
  }

  /**
   * Initializes the {@link styleClass} by the {@link type}.
   */
  private initBaseClasses(): void {
    if (!this.type) {
      // include the rounded style if missing
      if (!this.styleClass || !this.styleClass.includes('p-button-rounded')) {
        this.styleClass += ' p-button-rounded'
      }
      return
    }

    // apply styles based on the type
    switch (this.type) {
      case 'save':
      case 'primary':
        this.styleClass += ' p-button-rounded'
        break
      case 'secondary':
      case 'discard':
      case 'edit':
        this.styleClass += ' p-button-outlined p-button-rounded p-button-secondary'
        break
      case 'delete-light':
      case 'danger-light':
        this.styleClass += ' p-button-outlined p-button-rounded p-button-danger'
        break
      case 'danger':
      case 'delete':
        this.styleClass += ' p-button-rounded p-button-danger'
        break
      case 'warning':
        this.styleClass += ' p-button-outlined p-button-rounded p-button-warning'
        break
      case 'text':
      case 'icon':
        this.styleClass += ' p-button-rounded p-button-secondary p-button-text'
        break
      case 'danger-text':
        this.styleClass += ' p-button-rounded p-button-text p-button-danger'
        break
      case 'cta':
        this.styleClass += ' cta-button'
        break
      default:
        this.styleClass += ' p-button-rounded'
    }
  }
}
