<div class="wrapper {{styleClass}}">
  <!--Errors-->
  <section *ngIf="false">
    <!--Available but has other orders on that date-->
    <app-hint i18n>
      At your selected time, this profile is available. But in that day he has already booked some events. Your order
      may
      be canceled by the artist himself.
    </app-hint>

    <!--Already booked-->
    <app-hint i18n>
      This profile is unavailable at your selected date.
    </app-hint>

    <!--Submitted, but not confirmed-->
    <app-hint i18n>
      At your selected time, there is one or more unconfirmed order from other customer.
      Your order may be canceled by the artist himself.
    </app-hint>
  </section>

  <!--Calendars-->
  <div class="p-relative p-mt-2">
    <div class="p-grid">
      <ng-container *ngFor="let x of [].constructor(monthsVisible); index as i">
        <div [class.p-col-12]="monthsVisible === 1"
             [class.p-col-6]="monthsVisible === 2"
             [class.p-col-4]="monthsVisible === 3"
             [class.p-pr-3]="monthsVisible === 3 && (i === 0 || i === 1) || (monthsVisible === 2 && (i===0))"
             [class.p-pl-3]="monthsVisible === 3 && (i === 1 || i === 2) || (monthsVisible === 2 && (i===1))">
          <!--Grid-->
          <table *ngIf="!loading; else skeleton">
            <tr>
              <th i18n>Mon</th>
              <th i18n>Tue</th>
              <th i18n>Wed</th>
              <th i18n>Thu</th>
              <th i18n>Fri</th>
              <th i18n>Sat</th>
              <th i18n>Sun</th>
            </tr>

            <tr *ngFor="let row of [0, 7, 14, 21, 28, 35]">
              <td *ngFor="let cell of monthsCellData[i] | slice: row : row + 7"
                  [class.selected]="cell.selected"
                  [class.today]="cell.isToday"
                  [class.td-not-current-month]="!cell.dayInCurrentMonth"
                  [class.p-d-none]="(row >= 28 && !cell.dayInCurrentMonth) && !sixRowsCalendar"
                  (click)="onSelectCell(cell)">

                <!--Number-->
                <div class="p-d-flex p-jc-center p-ai-center"
                     *ngVar="(cell.date >= firstAvailableDate
                              && cell.availability !== 'unavailable'
                              && (cell.dayInCurrentMonth || i === 0 || i === (monthsVisible - 1))) as inCurrentMonth">
                  <!--Add circle style class if the month is first, or last visible or the cell is in the current month-->
                  <div class="cell-number" pRipple
                       *ngVar="cell.calendarItems.length as count"
                       [class.cell-disabled]="(cell.date < firstAvailableDate && !cell.isToday)
                                              || cell.availability === 'unavailable'
                                              || count >= maxOrdersPerDay"
                       [class.cell-uncommitted]="cell.date >= firstAvailableDate && cell.availability === 'uncommitted' && count < maxOrdersPerDay"
                       [class.cell-event-1]="count === 1 && inCurrentMonth && count < maxOrdersPerDay && data?.owner"
                       [class.cell-event-2]="count === 2 && inCurrentMonth && count < maxOrdersPerDay && data?.owner"
                       [class.cell-event-3]="count >= 3 && inCurrentMonth && count < maxOrdersPerDay && data?.owner">
                    {{cell.date | date: 'd'}}
                  </div>
                </div>

                <!--Book icon-->
                <!--<div class="book-icon p-relative">
                  <i class="fa-solid fa-bookmark p-absolute"></i>
                </div>-->

                <!--Image number-->
                <!--<div class="p-d-flex p-jc-center p-ai-center">
                  <div class="cell-image fit-image" [style]="data.avatar + '/50' | backgroundImage"></div>
                  <div class="cell-number cell-image-overlay">
                    {{cell.date | date: 'd'}}
                  </div>
                </div>-->

              </td>
            </tr>
          </table>

          <!--Month Title-->
          <footer class="p-d-flex p-jc-center p-ai-center p-mt-2">
            <div class="header-text">
              <h4 class="month p-m-0">{{selectedYearMonths[i] | date: 'LLLL yyyy'}}</h4>
            </div>
          </footer>

        </div>
      </ng-container>
    </div>

    <nav class="p-absolute navigation p-d-flex p-jc-between">
      <!--Left arrow-->
      <app-button type="icon"
                  icon="fa-solid fa-chevron-left"
                  (clicked)="onMonthChangeRequest(false)"></app-button>

      <!--Right arrow-->
      <app-button type="icon"
                  icon="fa-solid fa-chevron-right"
                  (clicked)="onMonthChangeRequest(true)"></app-button>
    </nav>
  </div>

  <div *ngIf="data?.owner && hasFeatures(data.profileType, Feature.BE_ORDERED)">
    <hr class="divider">
    <div class="p-d-flex p-ai-center p-jc-center p-mb-2 p-mx-3 p-mx-sm-5">
      <!--1 event booked-->
      <div class="p-d-flex p-ai-center p-mr-3">
        <div class="legend-event-1 p-mr-2"></div>
        <div>1</div>
      </div>
      <!--2 events booked-->
      <div class="p-d-flex p-ai-center p-mr-3">
        <div class="legend-event-2 p-mr-2"></div>
        <div>2</div>
      </div>
      <!--3 and more events booked-->
      <div class="p-d-flex p-ai-center">
        <div class="legend-event-3 p-mr-2"></div>
        <div i18n>3 and more</div>
      </div>
    </div>
    <div i18n class="p-text-center">Booked or pending orders</div>

    <!--Documentation-->
    <app-docs-hint link="/docs/order/book-artist/#kalend%c3%a1r-dostupnosti" styleClass="p-mt-1 p-jc-center" i18n>
      Read more about calendar
    </app-docs-hint>
  </div>

  <!--Uncommitted Bookings-->
  <app-hint *ngIf="selectedCell?.availability === 'uncommitted'" @grow i18n>
    <b>{{data.displayName}}</b> has one or many requested orders in your <b>selected time</b> duration.
    However, you can still book this day and <b>let the artist decide</b> what order should prioritize.
  </app-hint>

  <!--New items-->
  <!--<div *ngIf="editButtonVisible && pendingHostProfileItemsCount.getValue() > 0"
       class="p-d-flex p-jc-center p-px-2 p-my-2 c-pointer">
    <app-button type="danger"
                icon="fa-solid fa-envelope"
                [label]="pendingHostProfileItemsCount.getValue() + ' new items to accept'"
                (clicked)="showEditComponent()"></app-button>
  </div>-->
</div>

<!--Calendar skeleton-->
<ng-template #skeleton>
  <table class="skeleton-table">
    <tr>
      <th i18n>Mon</th>
      <th i18n>Tue</th>
      <th i18n>Wed</th>
      <th i18n>Thu</th>
      <th i18n>Fri</th>
      <th i18n>Sat</th>
      <th i18n>Sun</th>
    </tr>
    <tr *ngFor="let row of [0, 1, 2, 3, 4, 5]">
      <td *ngFor="let cell of [0, 1, 2, 3, 4, 5, 6]" class="p-p-1">
        <p-skeleton styleClass="skeleton" shape="circle"></p-skeleton>
      </td>
    </tr>
  </table>
</ng-template>
