<p-dialog [(visible)]="show"
          [modal]="true"
          [closeOnEscape]="false"
          [baseZIndex]="baseZIndex">

  <div class="p-p-4">
    <h1 i18n>Want to leave?</h1>
    <p i18n> You have unsaved changes. Do you want to leave?</p>

    <!--Action buttons-->
    <div class="p-d-flex p-ai-center p-jc-end p-mt-2">
      <!--Stay-->
      <app-button icon="fa-solid fa-turn-down"
                  label="Stay"
                  i18n-label
                  type="secondary"
                  styleClass="p-mr-2"
                  (clicked)="close($event, true)"></app-button>

      <!--Leave-->
      <app-button icon="fa-solid fa-right-from-bracket"
                  label="Leave"
                  i18n-label
                  type="primary"
                  (clicked)="close($event, false)"></app-button>
    </div>
  </div>
</p-dialog>
