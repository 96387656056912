<!--Modes of displaying a price item-->
<div [ngSwitch]="layout">

  <!-- Customer layout -->
  <div *ngSwitchCase="'customer'" class="p-grid">
    <div class="{{isScreenOf(Screen.MD) ? 'p-col' : 'p-col-12'}}">
      <!-- Name -->
      <h4 class="p-my-0 p-pr-2 l-count-2">{{ item.name }}</h4>

      <!-- Description and show details-->
      <div #description class="description c-pointer"
           [class.l-count-2]="!description['unwrapped']"
           (click)="description['unwrapped'] = !description['unwrapped']">
        {{ item.description }}
      </div>

      <!-- Category icons -->
      <div class="p-grid">
        <div *ngFor="let category of item.categories | slice:0:categorySliceEnd" class="p-m-2 c-pointer">
          <div class="p-col p-p-0">
            <!-- tooltip is shown after focus, not hover the icon - mainly used for mobile devices -->
            <i [pTooltip]="category.name"
               tooltipPosition="bottom"
               tabindex="0" [tooltipEvent]="isTouchScreen ? 'focus' : 'hover'" style="outline: 0"
               [class]="category.icon"></i>
          </div>
        </div>
        <!--More button-->
        <div *ngIf="item?.categories?.length > 3 && categorySliceEnd === 3">
          <i class="more-icons fa-solid fa-plus c-pointer p-p-1 p-m-2" (click)="categorySliceEnd = 19"></i>
        </div>
      </div>
    </div>

    <!-- Price -->
    <div class="{{isScreenOf(Screen.MD) ? 'p-col-fixed' : 'p-col-12'}}">
      <div
        class="p-d-flex p-flex-column p-flex-sm-row p-flex-md-column p-ai-center p-ai-md-end p-jc-sm-between price-info">
        <div class="p-d-flex p-flex-column p-ai-center p-ai-sm-start p-ai-md-end">
          <div class="p-d-flex p-ai-center">
            <div *ngIf="item.fixedPrice" class="p-pr-2" i18n>from</div>
            <div class="price word-break p-text-bold">{{ (item.price || 0) | price }}</div>
          </div>
          <div *ngIf="!item.fixedPrice" class="p-pl-2" i18n>/per hour.</div>

          <!--Tax and Fees info-->
          <div class="p-d-flex p-ai-center tax-label" *ngIf="item.price !== item.realPrice">
            <i class="fa-solid fa-check p-mt-1 p-mr-1"></i>
            <small i18n>Includes taxes and fees</small>
          </div>
        </div>

        <div class="p-mt-2" *ngIf="canBeOrdered && !data?.owner">
          <!-- Select Button -->
          <app-button *ngIf="!item.selected"
                      type="secondary"
                      label="To Cart"
                      i18n-label
                      icon="fa-solid fa-shopping-cart"
                      (clicked)="selectPriceItem()"></app-button>

          <!-- Selected -->
          <app-button *ngIf="item.selected"
                      type="primary"
                      label="In Cart"
                      i18n-label
                      icon="fa-solid fa-check"
                      (clicked)="selectPriceItem()"></app-button>
        </div>
      </div>
    </div>

  </div>

  <!-- Brief layout -->
  <div *ngSwitchCase="'brief'" class="p-px-3 p-py-2 p-my-2">
    <!--with delete icon version-->
    <div *ngIf="enableDeleteButton"
         class="p-d-flex p-jc-between p-ai-center word-break">

      <h4 class="p-m-0">{{ item.name }}</h4>
      <app-button type="icon"
                  icon="fa-solid fa-xmark"
                  pTooltip="Delete"
                  i18n-pTooltip
                  tooltipPosition="top"
                  styleClass="p-button-danger"
                  (clicked)="onDelete()"></app-button>
    </div>

    <!--without delete icon version-->
    <h4 *ngIf="!enableDeleteButton" class="p-m-0 p-pb-2">{{ item.name }}</h4>


    <!--price section-->
    <div class="p-d-flex p-jc-between p-ai-center">
      <div *ngIf="item.fixedPrice" i18n>Fixed</div>
      <div *ngIf="!item.fixedPrice" class="p-d-flex">
        <div i18n>Hour ({{ (item.price || 0) | price }})</div>
        <div *ngIf="dates && dates.length === 2" class="p-ml-2">x {{ hours }}</div>
      </div>
      <div class="price p-text-bold p-pl-2">{{ ((item.price || 0) * ((!item.fixedPrice) ? hours : 1)) | price }}</div>
    </div>
  </div>

  <!--Edit-->
  <div *ngSwitchCase="'edit'" class="p-p-2">
    <div class="p-p-2">
      <!--with delete icon version-->
      <div class="p-d-flex p-jc-between p-ai-center word-break">
        <h4 class="p-m-0">{{ item.name }}</h4>
        <i class="fa-solid fa-pencil p-ml-2 edit-option"></i>
      </div>

      <!-- Description and show details-->
      <div #description class="description c-pointer"
           [class.l-count-2]="!description['unwrapped']"
           (click)="description['unwrapped'] = !description['unwrapped']">
        {{ item.description }}
      </div>
    </div>

    <div class="p-grid p-d-flex p-jc-between p-ai-start">
      <!-- Category icons -->
      <div class="p-col-12 p-sm-7 p-grid p-p-0">
        <div *ngFor="let category of item.categories" class="p-m-2 c-pointer">
          <div class="p-col p-p-0">
            <!-- tooltip is shown after focus, not hover the icon - mainly used for mobile devices -->
            <i class="{{category.icon}}"></i>
          </div>
        </div>
      </div>

      <!-- Price -->
      <div class="p-col-12 p-sm-5 p-d-flex p-jc-end">
        <div *ngIf="item.fixedPrice" class="p-px-2" i18n>from</div>
        <div class="p-d-flex p-flex-column p-flex-md-row p-flex-lg-column p-flex-xl-row p-ai-end">
          <div class="price word-break p-text-bold">{{ (item.price || 0) | price }}</div>
          <div *ngIf="!item.fixedPrice" class="p-pl-2" i18n>/per hour.</div>
        </div>
      </div>
    </div>
  </div>
</div>


