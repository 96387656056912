<div *ngIf="serverMessages.includes(check)"
     @grow
     [class]="styleClass"
     [class.failed-box]="showAsBox && !showAsSuccess"
     [class.success-box]="showAsBox && showAsSuccess"
     [class.p-invalid]="!showAsBox && !showAsSuccess"
     [class.p-success]="!showAsBox && showAsSuccess">
  <i class="fa-solid p-mr-3"
     [class.fa-heart-broken]="!showAsSuccess"
     [class.fa-check]="showAsSuccess"
     *ngIf="showAsBox"></i>
  <ng-content></ng-content>
</div>
