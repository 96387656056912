import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {ApiService} from './api.service'

@Injectable({
  providedIn: 'root'
})
export class ImageShowcaseService extends ApiService {

  constructor() {
    super()
  }

  callGetImagesForEdit(): Observable<BaseResp<GetImageShowcaseEditResp[]>> {
    return this.post(Endpoint.IMAGE_SHOWCASE_ALL_EDIT, null)
  }

  callNewImage(file: File, uuid: string): Observable<BaseResp<boolean>> {
    const formData = new FormData()
    formData.append('imageUuid', uuid)
    formData.append('image', file)
    return this.post(Endpoint.IMAGE_SHOWCASE_NEW, formData, this.getFormHeaders())
  }

  /**
   * Returns an array of new image URLs.
   */
  callUpdateImages(req: UpdateImageShowcaseReq[]): Observable<BaseResp<ImageShowcaseResp[]>> {
    return this.post(Endpoint.IMAGE_SHOWCASE_UPDATE, req)
  }

  /**
   * Returns an array of all images of a {@link profileId}.
   */
  callGetAllImages(profileId: number): Observable<BaseResp<ImageShowcaseResp[]>> {
    return this.post(Endpoint.IMAGE_SHOWCASE_ALL, profileId, this.getHeaders(), false)
  }
}

export interface GetImageShowcaseEditResp {
  uuid: string
  url: string
  description?: string
}

export interface ImageShowcaseResp {
  url: string
  description?: string
}

export interface UpdateImageShowcaseReq {
  uuid: string
  description?: string
}

