import {Injectable} from '@angular/core'
import {Subject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  /**
   * Defines the current visible message.
   */
  message = new Subject<Snackbar>()

  constructor() {
  }

  /**
   * Shows a new snackbar message.
   */
  showMessage(snackbar: Snackbar): void {
    this.message.next(snackbar)
  }
}

/**
 * The snackbar options.
 */
export interface Snackbar {
  message: string
  /**
   * Use the pre-defined enum of {@link SnackbarDuration}.
   */
  duration?: number
  icon?: string
  click?: (Event) => void
}

/**
 * The snackbar durations in ms.
 */
export enum SnackbarDuration {
  SHORT = 3000,
  NORMAL = 5000,
  LONG = 10000
}
