import {Component, OnDestroy, OnInit} from '@angular/core'
import {PermissionRequest, PermissionService} from '../../../service/ui/permission.service'
import {Subscription} from 'rxjs'
import {ApiComponent} from '../../abstract/api.component'
import {ServerMessage} from '../../../common/server-message'
import {environment} from '../../../../environments/environment'

@Component({
  selector: 'app-permission-dialog',
  templateUrl: './permission-dialog.component.html',
  styleUrls: ['./permission-dialog.component.scss']
})
export class PermissionDialogComponent extends ApiComponent implements OnInit, OnDestroy {

  protected readonly env = environment

  /**
   * The current demanding permission.
   */
  permission: PermissionRequest

  /**
   * The permission observer subscription.
   */
  private permissionSub?: Subscription

  constructor(private permissionService: PermissionService) {
    super()
  }

  ngOnInit(): void {
    this.permissionSub = this.permissionService.requiredPermission.subscribe((p) => {
      this.permission = p
      if (p) {
        this.initDefaultValues()
      }
    })
  }

  /**
   * Fires when the user clicked on buttons.
   * @param accepted Whether the user clicked on the 'allowed' button
   */
  async onResult(accepted: boolean): Promise<void> {
    this.resetApi()

    // check if the permission is now allowed
    if (accepted) {
      if ((await this.permissionService.getState(this.permission.name)).state === 'denied') {
        this.pushToMessages(ServerMessage.FEATURE_NOT_ALLOWED)
        return
      }
    }

    // if allowed, notify components
    this.permissionService.requiredPermission.next(null)
  }

  /**
   * Initializes the default required values of the current {@link permission}.
   * @private
   */
  private initDefaultValues(): void {
    if (!this.permission.icon) {
      switch (this.permission.name) {
        case 'geolocation':
          this.permission.icon = 'fa-solid fa-location-dot'
          break
        case 'notifications':
          this.permission.icon = 'fa-solid fa-comments'
          break
      }
    }

    if (!this.permission.reason) {
      switch (this.permission.name) {
        case 'geolocation':
          this.permission.reason = $localize`To get any further, please allow the location in your settings for us.`
          break
        case 'notifications':
          this.permission.reason = $localize`To get any further, please allow the notifications in your settings for us.`
          break
      }
    }

    if (!this.permission.title) {
      switch (this.permission.name) {
        case 'geolocation':
          this.permission.title = $localize`Geolocation is required`
          break
        case 'notifications':
          this.permission.title = $localize`Notifications are required`
          break
      }
    }
  }

  ngOnDestroy(): void {
    this.permissionSub?.unsubscribe()
  }
}
