<!--Normal scroller-->
<ng-container *ngIf="!overlay || !isScreenOf(Screen.MD)" [ngTemplateOutlet]="scrollerTemplate"></ng-container>

<!--Overlay scroller-->
<p-overlayPanel *ngIf="isScreenOf(Screen.MD) && overlay
                        && (items?.content || (overlayEmptyVisible && items?.content.length === 0 && items.nextPage > 0))"
                [baseZIndex]="overlayZIndex"
                [appendTo]="overlayAppendTo"
                [styleClass]="overlayClass"
                [showCloseIcon]="false"
                [dismissable]="true"
                #overlay>
  <ng-template pTemplate="content">
    <ng-container [ngTemplateOutlet]="scrollerTemplate"></ng-container>
  </ng-template>
</p-overlayPanel>

<!--Scroller-->
<ng-template #scrollerTemplate>
  <div class="p-relative w-100">
    <!--Top loading-->
    <div *ngIf="topLoading && (!skeletonTemplate || (skeletonTemplate && items.nextPage > 0))"
         @grow class="top-loading p-absolute">
      <ng-template [ngTemplateOutlet]="loadingTemplate"></ng-template>
    </div>

    <!--Content Loading-->
    <div *ngIf="items.nextPage === 0 && (component?.loading || bottomLoading || topLoading) && skeletonTemplate" @fade
         class="skeleton p-absolute">
      <ng-template [ngTemplateOutlet]="skeletonTemplate"></ng-template>
    </div>

    <!--Top shadow-->
    <div class="top-shadow-container p-absolute w-100 p-mr-2"
         [class.top-shadow]="shadows && topShadow"></div>

    <!--Virtual Items Size Core-->
    <div *ngIf="virtual"
         [style.height]="scrollHeightContainer"
         class="w-100 p-relative p-py-2"
         [class]="items?.content.length === 0 ? 'p-d-flex p-jc-center p-ai-center' : ''">

      <!--Content-->
      <cdk-virtual-scroll-viewport *ngIf="items?.content.length > 0"
                                   [itemSize]="itemHeight"
                                   (scroll)="applyShadows()"
                                   [style.height]="scrollHeight"
                                   (scrolledIndexChange)="onScroll($event)">
        <div *cdkVirtualFor="let item of items?.content"
             class="w-100">
          <ng-template [ngTemplateOutlet]="itemTemplate"
                       [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
        </div>
      </cdk-virtual-scroll-viewport>

      <!--Empty content-->
      <div *ngIf="(items?.content.length === 0) && (!component?.loading) && !bottomLoading && !topLoading"
           class="p-absolute">
        <ng-template [ngTemplateOutlet]="noContent"></ng-template>
      </div>

      <!--Each word is a virtual div that determines a certain width, this way the cdk is virtual-->
      <span class="virtual-dynamic-width">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Asperiores consequuntur culpa cumque eaque, harum id labore
        laudantium magnam maiores non odio provident qui quia quo quod
        ullam ut voluptas voluptatum?</span>
    </div>

    <!--Scroll after this element is visible - feature-->
    <div *ngIf="virtual" #e (ngInit)="lastElement = e"></div>

    <!--Dynamic items-->
    <div *ngIf="!virtual"
         [style.height]="scrollHeight"
         class="custom-scroller p-py-4"
         #customScroller
         (scroll)="onScrollDynamicItems()">

      <div *ngIf="items?.content.length > 0">
        <div *ngFor="let item of items?.content; index as i"
             (ngInit)="(i === items?.content.length-1) ? applyChanges(): null">
          <ng-template [ngTemplateOutlet]="itemTemplate"
                       [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
        </div>
      </div>
      <!--Empty content-->
      <ng-template [ngTemplateOutlet]="noContent"></ng-template>
    </div>

    <!--Scroll after this element is visible - feature-->
    <div *ngIf="!virtual" #e (ngInit)="lastElement = e"></div>

    <!--Bottom shadow-->
    <div class="bottom-shadow-container p-absolute p-d-flex w-100 p-mr-2"
         [class.bottom-shadow]="shadows && bottomShadow"></div>

    <!--Bottom loading-->
    <div *ngIf="bottomLoading && (!skeletonTemplate || (skeletonTemplate && items.nextPage > 0))"
         @grow class="bottom-loading p-absolute">
      <ng-template [ngTemplateOutlet]="loadingTemplate"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="w-100 p-d-flex p-jc-center">
    <div class="p-d-flex p-ai-center p-my-3">
      <i class="fa-solid fa-spin fa-circle-notch p-mr-2"></i>
      <div i18n>Loading</div>
    </div>
  </div>
</ng-template>

<ng-template #noContent>
  <div *ngIf="items?.content.length === 0 && (!bottomLoading && !topLoading)" @fade>
    <ng-template [ngIf]="!noContentTemplate">
      <div class="p-d-flex p-jc-center p-ai-center w-100 h-100">
        <i class="no-content-icon fa-solid fa-chalkboard-user"></i>
      </div>
    </ng-template>

    <!--Custom-->
    <ng-template [ngIf]="noContentTemplate"
                 [ngTemplateOutlet]="noContentTemplate"></ng-template>
  </div>
</ng-template>
