import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Page} from '../rest/page-resp'
import {BriefProfileResp} from './profile.service'

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends ApiService {

  constructor() {
    super()
  }

  callNewReview(req: NewReviewReq): Observable<BaseResp<OrderReviewResp>> {
    return this.post(Endpoint.REVIEW_PROFILE_NEW, req)
  }

  callEditReview(req: EditReviewReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.REVIEW_PROFILE_EDIT, req)
  }

  callGetProfileReviews(req: GetReviewsReq): Observable<BaseResp<Page<OrderReviewResp>>> {
    return this.post(Endpoint.REVIEW_PROFILE_OF_ORDERS, req)
  }

  callGetAuthorReviews(req: GetReviewsReq): Observable<BaseResp<Page<OrderReviewResp>>> {
    return this.post(Endpoint.REVIEW_AUTHOR_OF_ORDERS, req)
  }

  callDeleteReview(req: DeleteReviewReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.REVIEW_PROFILE_DELETE, req)
  }

  callCountByProfile(req: GetReviewsReq): Observable<BaseResp<number>> {
    return this.post(Endpoint.REVIEW_COUNT_BY_PROFILE, req)
  }

  callAverageProfileRating(req: GetReviewsReq): Observable<BaseResp<number>> {
    return this.post(Endpoint.REVIEW_AVERAGE_PROFILE_RATING, req)
  }
}

export interface NewReviewReq {
  orderId: number
  text?: string
  stars: number
}

export interface EditReviewReq {
  reviewId: number
  text: string
  stars: number
}

export interface GetReviewsReq {
  profileId: number
  page: number
}

export interface DeleteReviewReq {
  reviewId: number
}

export interface OrderReviewResp {
  author: BriefProfileResp
  reviewId: number
  text: string
  stars: number
  modifiedAt: Date
  autoRated: boolean
}

export interface RatingResp {
  avg: number
  ratingCount: number
}

