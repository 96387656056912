<p-breadcrumb [model]="values">
  <ng-template pTemplate="item" let-item>

    <!--Router-->
    <ng-container *ngIf="item.route; else elseBlock">
      <a [routerLink]="item.route" class="item p-px-1 p-d-flex p-ai-center">
        <i *ngIf="item.icon" class="{{item.icon}} p-mr-2"></i>
        <span>{{ item.label }}</span>
      </a>
    </ng-container>

    <!--URL-->
    <ng-template #elseBlock>
      <a [href]="item?.url" class="item p-px-1 p-d-flex">{{ item.label }}</a>
    </ng-template>

  </ng-template>
</p-breadcrumb>
