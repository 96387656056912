<ng-container *ngIf="!phoneVerificationInProgress">
  <h2 class="title p-py-2 p-text-uppercase" i18n>Register</h2>
  <form [formGroup]="form"
        (keyup.enter)="checkUniqueUserAndVerifyPhone()">

    <!--E-MAIL-->
    <app-text-input label="Email"
                    i18n-label
                    inputType="email"
                    placeholder="john.smith@email.com"
                    i18n-placeholder
                    formFieldName="email"
                    [space]="false"
                    [paste]="false"
                    [copy]="false"
                    [form]="form"
                    [required]="true"
                    [email]="true"
                    hint="Your login email used for main communication and verification."
                    i18n-hint
                    [length]="{min: Restrictions.MIN_EMAIL_LENGTH}">

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.EMAIL_ALREADY_REGISTERED"
                              i18n>
        E-mail already registered.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_EMAIL_INCORRECT"
                              i18n>
        Not a valid email.
      </app-backend-validation>
    </app-text-input>

    <!--PHONE-->
    <app-text-input label="Phone number"
                    i18n-label
                    placeholder="+4219xxxxxxxx"
                    i18n-placeholder
                    inputType="tel"
                    formFieldName="phone"
                    autocomplete="tel-area-code"
                    hint="Used for verification and resolving order disputes."
                    i18n-hint
                    [form]="form"
                    [phone]="true"
                    [required]="true"
                    [noBlankCharacters]="true"
                    [space]="false">
      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_INCORRECT" i18n>
        Not a valid phone number.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_ALREADY_EXISTS"
                              i18n>
        The number is already taken.
      </app-backend-validation>
    </app-text-input>

    <!--PASSWORD-->
    <app-text-input label="Password"
                    i18n-label
                    inputType="password"
                    formFieldName="password"
                    [space]="false"
                    [copy]="false"
                    [form]="form"
                    [required]="true"
                    [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
      <!--BE CHECK-->
      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.LOGIN_PASSWORD_INCORRECT" i18n>
        Not a valid password.
      </app-backend-validation>
    </app-text-input>

    <!--PASSWORD CONFIRMATION-->
    <app-text-input label="Password again"
                    i18n-label
                    inputType="password"
                    formFieldName="passwordAgain"
                    autocomplete="password"
                    [space]="false"
                    [paste]="false"
                    [copy]="false"
                    [form]="form"
                    [required]="true"
                    [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" field="passwordAgain" error="mustMatch" i18n>
        Passwords do not match.
      </app-frontend-validation>
    </app-text-input>

    <!--Legal Documents-->
    <div class="legal-text" i18n>
      By clicking Register or registering through a third party you accept the Umevia
      <a [routerLink]="toLegalDocuments(profileType === ProfileType.USER ? 'terms' : 'terms-art')" target="_blank"
         class="text-link">
        Terms of Services</a> and acknowledge the
      <a [routerLink]="toLegalDocuments('privacy')" target="_blank" class="text-link">Privacy Policy</a> and <a
      [routerLink]="toLegalDocuments('cookies')" target="_blank" class="text-link">Cookie Policy</a>.
    </div>

    <!--Register Button-->
    <app-button type="primary"
                label="Continue"
                i18n-label
                styleClass="w-100 p-mt-4 submit-button"
                icon="fa-solid fa-arrow-right"
                iconPos="right"
                (clicked)="checkUniqueUserAndVerifyPhone()"
                [loading]="saving || registered"
                [disabled]="!form.valid || submitted"
    ></app-button>
  </form>
  <!--Response-->
  <app-call-response [component]="this"
                     successMsg="You have been successfully registered!"
                     i18n-successMsg
                     successIcon="fa-solid fa-user-plus"
                     errorMsg="Failed to register."
                     i18n-errorMsg
                     styleClass="p-mt-4"
                     errorIcon="fa-solid fa-user-slash"></app-call-response>
</ng-container>

<app-phone-verification-embed *ngIf="phoneVerificationInProgress"
                                 [email]="form.value.email"
                                 [phone]="form.value.phone"
                                 (phoneVerificationResult)="phoneVerificationResult($event)">
</app-phone-verification-embed>
