/**
 * Project features that can be applied to profile types.
 */
export enum Feature {
  MULTIPLE_PROFILE = 'MULTIPLE_PROFILE',
  SWITCH_FROM_USER_PROFILE = 'SWITCH_FROM_USER_PROFILE',
  WALLPAPER = 'WALLPAPER',
  BE_HOSTED = 'BE_HOSTED',
  CAN_HOST = 'CAN_HOST',
  PERFORM = 'PERFORM',
  OPENING_HOURS = 'OPENING_HOURS',
  SCHEDULE = 'SCHEDULE',
  BE_IN_SCHEDULE = 'BE_IN_SCHEDULE',
  CHAT = 'CHAT',
  BE_IN_CHAT_GROUP = 'BE_IN_CHAT_GROUP',
  LIKE_PROFILE = 'LIKE_PROFILE',
  LIKE_POST = 'LIKE_POST',
  LIKE_POST_COMMENT = 'LIKE_POST_COMMENT',
  SKILLS = 'SKILLS',
  PROFESSIONS = 'PROFESSIONS',
  GENRES = 'GENRES',
  SHOWCASE = 'SHOWCASE',
  IMAGE_SHOWCASE = 'IMAGE_SHOWCASE',

  PRICE_ITEMS = 'PRICE_ITEMS',
  ORDER_PROFILE = 'ORDER_PROFILE',
  BE_ORDERED = 'BE_ORDERED',
}

/**
 * The feature object with optional parameters of a feature.
 */
export interface FeatureResp {
  name: Feature
  max?: number
}
