import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core'
import {OverlayPanel} from 'primeng/overlaypanel'

@Directive({
  selector: '[appTooltip]',
  standalone: true
})
export class TooltipDirective implements OnInit, OnDestroy {

  /**
   * The tooltip text.
   */
  @Input()
  appTooltip: string

  /**
   * Shows a tooltip only on click.
   */
  @Input()
  tooltipOnClickOnly = false

  /**
   * The overlay panel visible on the screen.
   */
  overlayPanel: ComponentRef<OverlayPanel>
  /**
   * A current tooltip delay.
   */
  currentTimeout: any
  /**
   * Determines whether the {@link enableOverlayPanelListeners} has been once called.
   */
  overlayListenersAdded: boolean

  constructor(
    public elRef: ElementRef,
    private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.overlayPanel = this.viewContainerRef.createComponent(OverlayPanel)
    this.elRef.nativeElement.parentNode.appendChild(this.overlayPanel.location.nativeElement)
    this.overlayPanel.location.nativeElement.style.position = 'absolute'
    if (this.tooltipOnClickOnly) {
      this.elRef.nativeElement.classList.add('c-pointer')
    }
  }

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    this.show(event, true, false)
  }

  @HostListener('mouseenter', ['$event'])
  handleHover(hover: Event): void {
    if (!this.tooltipOnClickOnly) {
      this.show(hover, false)
    }
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeave(): void {
    this.hide()
  }

  /**
   * Assigns click and mouseenter listeners to the {@link overlayPanel}.
   */
  private enableOverlayPanelListeners(enable: boolean): void {
    const overlay = this.overlayPanel?.instance?.container
    if (enable) {
      if (this.overlayListenersAdded) {
        return
      }
      this.overlayListenersAdded = true
      overlay?.addEventListener('mouseenter', this.hide.bind(this))
      overlay?.addEventListener('click', this.hide.bind(this))
    } else {
      overlay?.removeEventListener('mouseenter', this.hide.bind(this))
      overlay?.removeEventListener('click', this.hide.bind(this))
    }
  }

  /**
   * Shows a tooltip above the element.
   * - The {@link toggle} defines whether the overlay should be toggeled or showed only.
   */
  private show(event, toggle: boolean, delay: boolean = true): void {
    if (!this.appTooltip) {
      return
    }
    const instance = this.overlayPanel.instance
    const els = document.getElementsByClassName('app-tooltip')
    // Clear olds
    for (let i = 0; i < els.length; i++) {
      els[i].remove()
    }
    // Show new
    clearTimeout(this.currentTimeout)
    this.currentTimeout = setTimeout(() => {
      if (toggle) {
        instance.toggle(event)
      } else {
        instance.show(event)
      }
      setTimeout(() => {
        const styles = getComputedStyle(document.documentElement)
        const container = instance.container.style
        container.background = styles.getPropertyValue('--primary')
        container.color = 'white'
        instance.container.innerHTML = `<div class="p-px-3 p-py-2 app-tooltip" style="max-width: 15rem;">${this.appTooltip}</div>`
        this.enableOverlayPanelListeners(true)
      }, 5)
    }, delay ? 200 : 0)
  }

  /**
   * Hides the overlay panel.
   */
  private hide(): void {
    clearTimeout(this.currentTimeout)
    this.overlayPanel?.instance?.hide()
  }

  ngOnDestroy(): void {
    this.overlayPanel?.destroy()
    this.enableOverlayPanelListeners(false)
  }
}
