import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {EditableComponent} from '../../../../component/abstract/editable.component'
import {ChatMessageService, MessageResp} from '../../../../service/chat-message.service'
import {newEmptyPage, Page} from '../../../../rest/page-resp'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ChannelResp, ChatChannelService, UpdateChannelSeenReq} from '../../../../service/chat-channel.service'
import {ProfileResp} from '../../../../service/profile.service'
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {trimMultipleSpacesAndLines} from '../../../../utils/string.utils'
import {BaseNotification, FcmService} from '../../../../service/fcm.service'
import {LazyListComponent} from '../../../../component/common/list/lazy-list/lazy-list.component'
import {throwAppError} from '../../../../utils/log.utils'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {ViolationType} from '../../../../common/violation-type'
import {TextInputComponent} from '../../../../component/common/form/text-input/text-input.component'
import {DialogComponent} from '../../../../component/common/dialog/dialog.component'
import {DatePipe, NgForOf, NgIf} from '@angular/common'
import {SharedModule} from 'primeng/api'
import {AvatarComponent} from '../../../../component/common/avatar/avatar/avatar.component'
import {FirstChannelProfilePipe} from '../../../../pipe/first-channel-profile.pipe'
import {VarDirective} from '../../../../directive/var.directive'
import {ButtonComponent} from '../../../../component/common/button/button.component'
import {TooltipModule} from 'primeng/tooltip'
import {SkeletonModule} from 'primeng/skeleton'
import {InitDirective} from '../../../../directive/init.directive'
import {BackendValidationComponent} from '../../../../component/common/backend-validation/backend-validation.component'

@Component({
  animations: [fadeAnimation()],
  standalone: true,
  selector: 'app-profile-order-chat',
  templateUrl: './profile-order-chat.component.html',
  imports: [
    DialogComponent,
    NgIf,
    AvatarComponent,
    FirstChannelProfilePipe,
    VarDirective,
    ButtonComponent,
    TooltipModule,
    SharedModule,
    LazyListComponent,
    DatePipe,
    NgForOf,
    SkeletonModule,
    ReactiveFormsModule,
    TextInputComponent,
    InitDirective,
    BackendValidationComponent
  ],
  styleUrls: ['./profile-order-chat.component.scss']
})
export class ProfileOrderChatComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The current previewing channel of messages.
   */
  @Input()
  channel: ChannelResp

  /**
   * Represents the currently logged profile.
   */
  @Input()
  currentProfile: ProfileResp

  /**
   * Specifies all loaded messages of the current {@link channel}.
   */
  messages: Page<MessageResp> = newEmptyPage()

  /**
   * The form instance of the text input.
   */
  form: FormGroup

  /**
   * Defines whether a new message is currently sending.
   */
  sending: boolean

  /**
   * The scrollable content with {@link messages}.
   */
  @ViewChild('list')
  scroller: LazyListComponent<MessageResp>

  /**
   * Defines the visibility of the information overlay that needs to be confirmed before any use of the chat component.
   */
  infoOverlayVisible = true

  /**
   * The input field of the chat.
   */
  inputField: TextInputComponent

  /**
   * Allows only one call at the same time of the {@link updateChannelSeen} function.
   */
  private runningSeenRequest: boolean

  /**
   * The current subscription from the {@link FcmService} of incoming messages.
   */
  private fcmSub?: Subscription

  constructor(
    private channelService: ChatChannelService,
    private messageService: ChatMessageService,
    private fcmService: FcmService,
    private formBuilder: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.validateInputs()
    this.initForm()

    // Subscribe for incoming FCM messages
    this.fcmSub = this.fcmService.onChatMessage.subscribe((data: BaseNotification<MessageResp>) => {
      if (+data.custom.channelId === this.channel?.id) {
        this.processFcmChatMessage(data)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.channel?.currentValue || changes.currentProfile?.currentValue) && this.currentProfile && this.channel) {
      this.initLoad()
    }
  }

  /**
   * Fires when the user presses something on a keyboard in the input area.
   */
  onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      event.preventDefault()
      this.onSendMessage()
    }
  }

  /**
   * Fires when a user clicked on the 'Send' button.
   */
  onSendMessage(): void {
    const message = trimMultipleSpacesAndLines(this.form.value.text)
    if (!message || this.sending || this.loading || this.infoOverlayVisible || this.form.invalid) {
      return
    }

    this.customCall(async () => {
      this.sending = true
      const resp = await firstValueFrom(this.callNewMessage(message))
      if (resp && this.noServerMessages()) {
        this.form.controls.text.setValue('') // clear the form
        this.addNewMessage(resp)
      }
    }, null, () => this.sending = false)
  }

  /**
   * Reverses new set of messages.
   */
  reversePage(page: Page<MessageResp>): Page<MessageResp> {
    page.content = page.content.reverse()
    return page
  }

  /**
   * Loads a next page of messages based on the earliest message id in the {@link messages}.
   */
  callLoadMessages(): Observable<Page<MessageResp>> {
    return this.unwrap(this.messageService.callLoadMessages({
      channelId: this.channel.id,
      limit: 10,
      before: this.messages.content[0]?.id || undefined
    }))
  }

  /**
   * Sets occasionally UI properties based on violations.
   */
  processViolations(messages: MessageResp[]): void {
    for (const message of messages) {
      message['notSent'] = message.violations?.find(it => it === ViolationType.CONTACT) ? true : undefined
    }
  }

  ngOnDestroy(): void {
    this.fcmSub?.unsubscribe()
  }

  /**
   * - Initializes the initial load of messages.
   * - Returns immediately when the {@link loading} is true.
   */
  private initLoad(): void {
    if (this.loading) {
      return
    }

    this.call(async () => {
      const messages = await firstValueFrom(this.callLoadMessages())
      if (messages && this.noServerMessages()) {
        this.processViolations(messages.content)
        this.messages = this.reversePage(messages)
        setTimeout(async () => {
          this.scroller.scrollBottom()
          this.messages.nextPage++ // enables further loading
        }, 10)

        // update seen
        this.resetApi()
        await firstValueFrom(this.callUpdateSeen(new Date()))
        this.channel.unreadMessages = 0
      }
    }, null, () => this.focusInput())
  }

  /**
   * Calls the server API to create a new message.
   */
  private callNewMessage(message: string): Observable<MessageResp> {
    return this.unwrap(this.messageService.callNewMessage({
      channelId: this.channel.id,
      content: message
    }))
  }

  /**
   * Calls the server API to update the channel seen at property.
   */
  private callUpdateSeen(when: Date): Observable<boolean> {
    const req: UpdateChannelSeenReq = {
      channelId: this.channel.id,
      seenAt: when
    }
    return this.unwrap(this.channelService.callUpdateSeen(req))
  }

  /**
   * Processes a received FCM message.
   */
  private processFcmChatMessage(data: BaseNotification<MessageResp>): void {
    const resp: MessageResp = data.custom
    switch (data.category) {
      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_NEW:
        this.addNewMessage(resp)
        if (this.scroller.isBottomScrolled() && data.foreground) {
          this.updateChannelSeen()
        }
        break

      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_BLOCKED:
        for (let i = 0; i < this.messages.content.length; i++) {
          const message = this.messages.content[i]
          if (message.id === +resp.id) {
            this.messages.content[i] = resp
            this.processViolations(this.messages.content)
            this.messages.content = [...this.messages.content]
            break
          }
        }
        break
    }
  }

  /**
   * Adds a new message to the {@link channel} and scrolls to the bottom, if the scroller is near the end.
   */
  private addNewMessage(message: MessageResp): void {
    this.messages.content = [...this.messages.content, message]
    this.messages.totalElements++
    if (this.scroller.isBottomScrolled(30)) {
      this.scroller.scrollBottom('smooth')
    }
    this.focusInput()
  }

  /**
   * Updates the channel participant's seenAt property locally and remotely.
   * This function immediately exits if the {@link runningSeenRequest} property is true - (if this function has not finished yet)
   */
  private async updateChannelSeen(): Promise<void> {
    // Return if the seen request is already running
    if (this.runningSeenRequest) {
      return
    }

    try {
      this.runningSeenRequest = true
      const date = new Date()
      if (await firstValueFrom(this.callUpdateSeen(date))) {
        this.channel.unreadMessages = 0
        // this.authorParticipant.seenAt = date
        // this.emitChannelChanges()
      }
    } finally {
      this.runningSeenRequest = false
    }
  }

  /**
   * Initializes the {@link form} instance.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      text: ''
    })
  }

  /**
   * Tries to focus the {@link inputField}.
   */
  private focusInput(): void {
    if (this.inputField && !this.infoOverlayVisible && !this.loading && !this.saving) {
      this.inputField.inputArea?.nativeElement.focus()
    }
  }

  /**
   * Validates the component's inputs.
   */
  private validateInputs(): void {
    if (!this.currentProfile) {
      throwAppError('ProfileOrderChat', $localize`The [currentProfile] property is not initialized.`)
    }

    if (!this.channel) {
      throwAppError('ProfileOrderChat', $localize`The [channel] property is not initialized.`)
    }
  }
}
