import {Injectable} from '@angular/core'
import {firstValueFrom, Subject} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {AngularFireMessaging} from '@angular/fire/compat/messaging'
import {StorageItem, StorageService} from './storage.service'
import {ApiService} from './api.service'
import {Endpoint} from '../common/endpoints'
import {NotificationTargetProfile} from './profile.service'
import {fixDateObjects} from '../utils/date.utils'
import {extractBasePath} from '../utils/utils'
import {environment} from '../../environments/environment'
import {ChatMessageService, MessageResp} from './chat-message.service'

/**
 * Based on the {@link https://github.com/angular/angularfire/blob/master/docs/messaging/messaging.md}
 */
@Injectable({
  providedIn: 'root'
})
export class FcmService extends ApiService {

  /**
   * The message type that separates messages by the new_order category.
   */
  static readonly NEW_ORDER = 'new_order'
  /**
   * The message type that separates messages by the accepted_order category.
   */
  static readonly ACCEPTED_ORDER = 'accepted_order'

  /**
   * All messages can be observed from this instance.
   */
  onMessage = new Subject<RawFCMMessage>()

  /**
   * - Observes the {@link MessageResp} messages.
   */
  onChatMessage = new Subject<BaseNotification<MessageResp>>()

  /**
   * The background message broadcast channel.
   */
  private broadcast = new BroadcastChannel('Umevia-Background-Messaging')

  constructor(
    private fireMessaging: AngularFireMessaging,
    private storageService: StorageService) {
    super()
  }

  /**
   * Changes token in {@link fireMessaging} module.
   * Deletes {@link StorageItem.FCM_TOKEN} cookie.
   */
  async logout(): Promise<void> {
    const currentToken = await firstValueFrom(this.fireMessaging.getToken).catch(e => console.error(e))
    if (currentToken) {
      await firstValueFrom(this.fireMessaging.deleteToken(currentToken))
    }

    this.storageService.deleteCookie(StorageItem.FCM_TOKEN)
  }

  /**
   * Requests a user token from the Firebase Cloud Messaging.
   * If the token is present, it uploads it to the server API and starts listening to incoming messages.
   */
  async requestToken(): Promise<void> {
    let call = this.fireMessaging.requestToken
    if (!environment.production) { // add error log in non production
      call = call.pipe(
        catchError(e => {
          console.error('Messaging token failed:', e)
          throw e
        })
      )
    }
    const token = await firstValueFrom(call).catch(e => console.error(e))

    // Upload the FCM token only if the user JWT is set.
    if (token) {
      this.callUpdateFCMToken(token)
      this.listen()
    }
  }

  /**
   * Listen to messages in the foreground and background.
   */
  private listen(): void {
    // Foreground message listening
    this.fireMessaging.messages.subscribe((message: any) => {
      message.data.foreground = true
      this.processMessage(message, false)
    })

    // Background message listening
    this.broadcast.onmessage = (event) => {
      if (event.data) {
        event.data.data.backrounwd = true
        this.processMessage(event.data, true)
      }
    }
  }

  /**
   * Divides incoming messages from the FCM into specific subjects.
   */
  private divideMessage(raw: RawFCMMessage): void {
    const data = raw.data

    switch (data?.category) {
      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_NEW:
      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_EDIT:
      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_DELETE:
      case ChatMessageService.NOTIFICATION_CHAT_MESSAGE_BLOCKED:
        this.onChatMessage.next(data)
        break
    }
  }

  /**
   * Calls the server API to upload the FCM token only if the token has changed from the last call.
   * @param token A newly generated token.
   */
  private async callUpdateFCMToken(token: string): Promise<void> {
    // Checks if the token has changed from the last API call.
    if (this.storageService.getCookie(StorageItem.FCM_TOKEN) === token) {
      return
    } else {
      this.storageService.setCookie(StorageItem.FCM_TOKEN, token, extractBasePath(`${Endpoint.USER_FCM_UPDATE_URL}`), -1)
    }

    // Construct a request
    const req: UpdateFCMReq = {
      fcmToken: token
    }

    // Construct a call
    let call = this.post(Endpoint.USER_FCM_UPDATE_URL, req)
    if (!environment.production) {
      call = call.pipe(
        catchError((e) => {
          console.error('Messaging token upload failed: ', e)
          throw e
        })
      )
    }
    // update fcm token
    await firstValueFrom(call)
  }

  /**
   * - Pushes the message to the {@link onMessage} subject.
   * - Furthermore, it parses the `'custom'` field to JSON and fixes date objects.
   * - In the end, the {@link divideMessage} function is called.
   * - The {@link background} specifies whether the message came from the background or foreground message.
   */
  private processMessage(raw: RawFCMMessage, background: boolean): void {
    if (raw.data.custom) {
      const parsed = JSON.parse(raw.data.custom)
      fixDateObjects(parsed)
      raw.data.custom = parsed
    }
    this.onMessage.next(raw)
    this.divideMessage(raw)
    this.logNewMessage(raw, background)
  }

  /**
   * Prints the current received message to the console only if the environment is not a production.
   *
   * @param background Whether the current message is background message.
   * @param rawMessage The payload from the observer.
   */
  private logNewMessage(rawMessage: RawFCMMessage, background: boolean): void {
    if (!environment.production) {
      console.log(background ? 'Background message:' : 'Foreground message:', rawMessage)
    }
  }
}

export interface UpdateFCMReq {
  fcmToken?: string | null
}

/**
 * This is the raw message from the Firebase Cloud Messaging.
 */
export interface RawFCMMessage {
  collapseKey?: string
  data: BaseNotification<any>
  from: string
  messageId: string
}

/**
 * The base body of the Firebase Cloud Messaging notification message.
 */
export interface BaseNotification<T> {
  /**
   * Frontend property to categorize message
   */
  category: string
  /**
   * Notification title.
   */
  title: string
  /**
   * Notification icon url.
   */
  icon?: string
  /**
   * Notification text body.
   */
  body: string
  /**
   * Notification click url.
   */
  url?: string
  /**
   * Represents more complex data stored as JSON.
   */
  custom?: T
  /**
   * A profile to which the notification is targeted to.
   */
  targetProfile?: NotificationTargetProfile
  /**
   * Determines whether the notification can be displayed to user.
   */
  display: boolean
  /**
   * Represents whether this notification is of the foreground type.
   */
  foreground?: boolean
  /**
   * Represents whether this notification is of the background type.
   */
  background?: boolean
}
