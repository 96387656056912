<div class="w-100 p-d-flex {{styleClass}}">
  <a class="text-link p-d-flex p-ai-center p-text-center"
     href="{{env.contact.docs}}{{link}}"
     [target]="targetBlank ? '_blank' : '_self'">
    <i *ngIf="displayLeftIcon" class="fa-solid fa-lightbulb p-mr-2"></i>
    <u class="p-d-block">
      <ng-content></ng-content>
    </u>
    <i class="fa-solid fa-location-arrow p-ml-2 link-icon"></i>
  </a>
</div>
