import {Pipe, PipeTransform} from '@angular/core'
import {formatCurrency, getCurrencySymbol} from '@angular/common'

@Pipe({
  name: 'price',
  standalone: true,
})
export class PricePipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'EUR',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '1.2-2',
    locale: string = 'sk'
  ): string | null {
    return formatCurrency(
      value,
      locale, // AppModule.locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    )
  }
}
