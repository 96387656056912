import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Injectable} from '@angular/core'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class OpeningHoursRestrictionService extends ApiService {

  constructor() {
    super()
  }

  callUpdateOpeningHoursRestriction(req: UpdateOpeningHoursRestrictionReq): Observable<BaseResp<OpeningHoursRestrictionResp>> {
    return this.post(Endpoint.OPENING_HOURS_RESTRICTION_UPDATE_URL, req)
  }

  callGetOpeningHoursRestriction(req: OpeningHoursRestrictionReq): Observable<BaseResp<OpeningHoursRestrictionResp | null>> {
    return this.post(Endpoint.OPENING_HOURS_RESTRICTION_GET_URL, req)
  }

  callFindOpeningHoursRestriction(req: FindOpeningHoursRestrictionReq): Observable<BaseResp<Page<OpeningHoursRestrictionResp>>> {
    return this.post(Endpoint.OPENING_HOURS_RESTRICTION_FIND_URL, req)
  }

  callDeleteOpeningHoursRestriction(req: DeleteOpeningHoursRestrictionReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.OPENING_HOURS_RESTRICTION_DELETE_URL, req)
  }
}

export interface UpdateOpeningHoursRestrictionReq {
  profileId: number

  date: Date
  start?: Date
  end?: Date
  closed: boolean
  message?: string
}

export interface OpeningHoursRestrictionReq {
  profileId: number
  date: Date
}

export interface FindOpeningHoursRestrictionReq {
  profileId: number
  fromDate: Date

  page: number
}

export interface OpeningHoursRestrictionResp {
  id: number

  date: Date
  start?: Date
  end?: Date
  closed: boolean
  message?: string
}

export interface DeleteOpeningHoursRestrictionReq {
  profileId: number
  restrictionId: number
}
