<div class="p-py-3">
  <div *ngIf="(data && priceItem) || editButtonVisible" @grow>
    <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
        class="title" i18n>Price</h2>

    <div class="w-100 p-d-flex p-jc-between" *ngIf="priceItem" @grow>
      <div>{{priceItem.name}}</div>
      <b>{{priceItem.price | price}}</b>
    </div>

    <app-no-content *ngIf="!priceItem && editButtonVisible" @grow i18n>
      No price set.
    </app-no-content>

    <!--Edit button-->
    <div data-aos="fade-up" data-aos-duration="1000"
         *ngIf="editButtonVisible" @grow
         class="p-mt-3 p-d-flex p-ai-center">
      <!--Edit button-->
      <app-button type="edit"
                  pTooltip="Edit your genres."
                  i18n-pTooltip
                  tooltipPosition="top"
                  [disabled]="loading"
                  (clicked)="showEditComponent()"></app-button>

      <!--Delete button-->
      <app-button type="delete-light"
                  *ngIf="priceItem"
                  @fade
                  styleClass="p-ml-2"
                  [loading]="loading"
                  (clicked)="deletePriceItem()"></app-button>
    </div>

    <app-call-response [component]="this"></app-call-response>
  </div>
</div>

<!--Edit Component-->
<app-dialog *ngIf="editComponentVisible"
            [(show)]="editComponentVisible"
            [component]="this"
            header="Edit your profile price"
            i18n-header
            successMessage="Price information has been saved!"
            i18n-successMessage

            (discard)="closeEditComponent()"
            [disableSaveOption]="form.invalid"
            [save]="onComponentSave.bind(this)">

  <div class="p-py-2">
    <!--Name-->
    <app-text-input formFieldName="name"
                    [form]="form"
                    [required]="true"
                    label="Price name"
                    i18n-label
                    placeholder="Base entry"
                    i18n-placeholder
                    styleClass="w-100"
                    icon="fa-solid fa-tag"
                    [notContact]="true"
                    [length]="{max: Restrictions.MAX_PRICE_ITEM_NAME_LENGTH, min: Restrictions.MIN_PRICE_ITEM_NAME_LENGTH}"
                    inputType="text">
      <!--BE-CHECK-->
      <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED" [serverMessages]="serverMessages"
                              i18n>
        The field cannot contain any contact.
      </app-backend-validation>
    </app-text-input>

    <!--Price-->
    <app-text-input formFieldName="price"
                    [form]="form"
                    [required]="true"
                    label="Price value"
                    i18n-label
                    placeholder="12.99"
                    styleClass="w-100"
                    icon="fa-solid fa-tag"
                    inputType="number">

      <app-frontend-validation [form]="form" field="price" error="price" i18n>
        Incorrect value.
      </app-frontend-validation>
    </app-text-input>

  </div>
</app-dialog>

