import {Pipe, PipeTransform} from '@angular/core'
import {BriefProfileResp, ProfileResp} from '../service/profile.service'

@Pipe({
  name: 'briefProfile',
  standalone: true
})
export class BriefProfilePipe implements PipeTransform {
  transform(profile: ProfileResp): BriefProfileResp {
    return profileToBriefProfile(profile)
  }
}

/**
 * Converts values from the {@link ProfileResp} to the {@link BriefProfileResp}.
 */
export function profileToBriefProfile(profile: ProfileResp): BriefProfileResp {
  return {
    profileId: profile.profileId,
    uuid: profile.uuid,
    charId: profile.charId,
    displayName: profile.displayName,
    profileType: profile.profileType,
    profileStatus: profile.profileStatus,
    avatar: profile.avatar,
    professions: profile.professions,
    rating: profile.rating,
    address: profile.address,
    hostProfile: profile.hostProfile,
    saving: profile.saving
  }
}
