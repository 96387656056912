import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core'

/**
 * - Use this directive with the {@link TutorialComponent}.
 * - {@link https://wiki.umevia.com/books/common-components/page/tutorial}
 */
@Directive({
  selector: '[appTutorial]',
  standalone: true
})
export class TutorialDirective implements OnChanges {

  /**
   * App tutorial text.
   */
  @Input()
  tText: string

  /**
   * The position of the help dialog.
   */
  @Input()
  tPosition: TutorialDialogPosition = 'bottom'

  /**
   * App tutorial step index.
   */
  @Input()
  tIndex = 0

  constructor(public elRef: ElementRef) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.tText) {
      console.warn('The [appTutorial] is missing the [tText] value!')
    }
  }
}

export type TutorialDialogPosition = 'top-left' | 'top' | 'top-right' | 'bottom-right' | 'bottom' | 'bottom-left'
