import {Component} from '@angular/core'
import {UserResp} from '../../../service/user.service'
import {growAnimation} from '../../../animation/grow.animation'
import {LoginAbstractComponent} from '../abstract/login-abstract.component'
import {UserResponseType} from '../../../common/user-response-type'
import {FirebaseAuthResponseResult} from '../firebase-auth/firebase-auth.component'

/**
 * Login component used as a single page. Logic is implemented in the abstract class.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends LoginAbstractComponent {

  successLoginAction(user: UserResp): void {
    this.afterSuccessRedirect(user, false)
  }

  twoFactorAction(): void {
    this.navigation.toTwoFactorAuthentication()
  }

  firebaseAuthResponseAction(response: FirebaseAuthResponseResult): void {
    switch (response.responseType) {
      case UserResponseType.TWO_FACTOR:
        this.navigation.toTwoFactorAuthentication()
        break
      case UserResponseType.FIREBASE_REGISTER:
        this.navigation.toRegisterSocial(response.authType.toString().toLowerCase())
        break
    }
  }
}
