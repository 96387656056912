import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {BehaviorSubject, Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {DeviceRest} from '../rest/user/session-device/session-device'

@Injectable({
  providedIn: 'root'
})
export class PlatformRatingService extends ApiService {

  /**
   * By sending the 'true' value, it will show up the {@link PlatformRatingComponent}.
   */
  showPlatformRating = new BehaviorSubject<boolean>(false)

  callNewPlatformRating(req: NewPlatformRatingReq): Observable<BaseResp<number>> {
    return this.post(Endpoint.PLATFORM_RATING_NEW, req, super.getHeaders(), false)
  }
}

export interface NewPlatformRatingReq {
  id?: number
  stars: number
  text?: string
  device: DeviceRest
}
