<div class="wrapper p-sticky p-d-flex p-ai-center p-jc-between p-px-3 p-py-1"
     *ngIf="visible !== undefined" @grow
     [class.bottom-bar-invisible]="!visible">

  <div *ngIf="profile?.minPriceItem as pi" class="price-text">
    <div class="p-d-flex p-ai-center">
      <div class="p-pr-2" i18n>From</div>
      <div class="price word-break p-text-bold">{{ (pi.price || 0) | price }}</div>
    </div>
    <div *ngIf="!pi.fixedPrice" class="price-text-hour" i18n>/hour</div>
  </div>

  <div></div>

  <!-- Save Action -->
  <app-button type="cta"
              label="See offer"
              i18n-label
              [highlight]="true"
              icon="fa-solid fa-cart-shopping"
              styleClass="profile-bottom-bar-cta"
              (clicked)="scrollToOffer.emit()"></app-button>
</div>
