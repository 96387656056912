import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {Endpoint} from '../common/endpoints'
import {BaseResp} from '../rest/base-resp'

@Injectable({
  providedIn: 'root'
})
export class LegalEntityService extends ApiService {

  searchByRegistrationNumber(registrationNumber: string): Observable<BaseResp<SKLegalApiItemResp>> {
    return this.post(Endpoint.STRIPE_SEARCH_LEGAL_ENTITY, registrationNumber, super.getHeaders(), false)
  }
}

/**
 * Contains the response from the Slovak Legal API for browsing through legal registers.
 */
export interface SKLegalApiItemResp {
  id: number
  fullNames: {
    value: string
  }[]
  addresses: {
    street?: string
    buildingNumber?: string
    regNumber: number
    postalCodes: string[]
    municipality: {
      value: string
    }
  }[]
  /**
   * UI-only property for setting the identifier for the {@link SKLegalApiItemResp} response.
   */
  identifier?: string
}
