import {FormGroup} from '@angular/forms'
import {addDays, addMinutes, dateCut, dateJoin, minusMinutes} from './date.utils'
import {Subscription} from 'rxjs'

/**
 * Subscribes for value changes of the {@link startField} and {@link endField} and dynamically changes their values
 * by the rule that the start date cannot be after the end date and vice versa.
 *
 * @param form The form which the {@link startField} and the {@link endField} are taken from.
 * @param startField The start date field.
 * @param endField The end date field.
 * @returns Subscription - array of subscriptions that needs to be unsubscribed.
 */
export function formDatesNotOverlaps(form: FormGroup, startField: string, endField: string): Subscription[] {
  // Fix start date value
  const subStart = form.controls[startField].valueChanges.subscribe(start => {
    if (start && start > form.value[endField]) {
      form.controls[endField].setValue(dateCut(start, 'h'))
    }
  })

  // Fix end date value
  const subEnd = form.controls[endField].valueChanges.subscribe(end => {
    if (end && end < form.value[startField]) {
      form.controls[startField].setValue(dateCut(end, 'h'))
    }
  })

  return [subStart, subEnd]
}

/**
 * Subscribes for value changes of the given fields and dynamically changes their values
 * by the rule that the start (date + time) cannot be after the end (date + time) and vice versa.
 *
 * @param form The form which the fields are taken from.
 * @param startTimeField The start time field.
 * @param endTimeField The end time field.
 * @param startDateField The start date field.
 * @param endDateField The end date field.
 * @param offsetMinutes Offset between the ({@link startDateField} + {@link startTimeField})
 * and ({@link endDateField} + {@link endTimeField}) in minutes.
 * @returns Subscription - array of subscriptions that needs to be unsubscribed.
 */
export function formTimesNotOverlaps(
  form: FormGroup,
  startDateField: string,
  endDateField: string,
  startTimeField: string,
  endTimeField: string,
  offsetMinutes: number = 0
): Subscription[] {
  // Fix start time value
  const subStart = form.controls[startTimeField].valueChanges.subscribe(start => {
    const startDate = form.value[startDateField]
    if (start && startDate) {
      const s = dateJoin(startDate, start)
      const endDate = form.value[endDateField]
      const end = form.value[endTimeField]
      if (s && end && endDate && s > minusMinutes(dateJoin(endDate, end), offsetMinutes)) {
        form.controls[endTimeField].setValue(addMinutes(s, offsetMinutes))
      }
    }
  })
  // Fix end time value
  const subEnd = form.controls[endTimeField].valueChanges.subscribe(end => {
    const endDate = form.value[endDateField]
    if (end && endDate) {
      const e = dateJoin(endDate, end)
      const startDate = form.value[startDateField]
      const start = form.value[startTimeField]
      if (start && startDate) {
        const s = dateJoin(startDate, start)
        if (e && e < addMinutes(s, offsetMinutes)) {
          if (e < s) { // increase the endDateField if the end is before start
            form.controls[endDateField].setValue(addDays(endDate, 1))
          }
          form.controls[startTimeField].setValue(minusMinutes(e, offsetMinutes))
        }
      }
    }
  })

  return [subStart, subEnd]
}

/**
 * Subscribes for value changes of the {@link startField} and {@link endField} and dynamically changes their values
 * by the rule that the start number cannot be after the end number and vice versa.
 *
 * @param form The form which the {@link startField} and the {@link endField} are taken from.
 * @param startField The start number field.
 * @param endField The end number field.
 * @returns Subscription - array of subscriptions that needs to be unsubscribed.
 */
export function formNumbersNotOverlaps(form: FormGroup, startField: string, endField: string): Subscription[] {
  // Fix start number value
  const subStart = form.controls[startField].valueChanges.subscribe(start => {
    if (start && start > form.value[endField]) {
      form.controls[endField].setValue(start)
    }
  })

  // Fix end number value
  const subEnd = form.controls[endField].valueChanges.subscribe(end => {
    if (end && end < form.value[startField]) {
      form.controls[startField].setValue(end)
    }
  })

  return [subStart, subEnd]
}
