import {PenaltyResp} from '../service/profile.service'

/**
 * - Enum of all available penalties the profile can get.
 * - Must be synced with the backend!
 */
export enum PenaltyType {
  ORDER_NOT_ARRIVED = 'ORDER_NOT_ARRIVED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_FALSE_STATEMENT = 'ORDER_FALSE_STATEMENT',
  CHAT_CONTACT = 'CHAT_CONTACT'
}

/**
 * Checks whether provided penalty has given {@link PenaltyType}s.
 * @param penalty Provided penalty.
 * @param penaltyTypes Array of types to be matched against.
 */
export function hasPenaltyType(penalty: PenaltyResp, ...penaltyTypes: PenaltyType[]): boolean {
  return penaltyTypes.some(it => it === penalty?.type)
}

/**
 * - Returns a penalty minutes expiration of the given {@link penalty}.
 * - Must be synced with the backend!
 */
export function penaltyExpirationOf(penalty: PenaltyType): number {
  switch (penalty) {
    case PenaltyType.ORDER_NOT_ARRIVED:
      return (2 * 7 * 24 * 60) // Two weeks
    case PenaltyType.ORDER_CANCELLED:
      return 0 //  now
    case PenaltyType.ORDER_FALSE_STATEMENT:
      return (1 * 30 * 24 * 60) // 1 month
    case PenaltyType.CHAT_CONTACT:
      return (1 * 7 * 24 * 60) // one week
  }
}

