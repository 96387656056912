import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ApiComponent} from '../../../abstract/api.component'
import {firstValueFrom, Observable} from 'rxjs'
import {BriefProfessionResp} from '../../../../service/profession.service'
import {NavbarService} from '../../../../service/ui/navbar.service'
import {MetaService} from '../../../../service/analytics/meta.service'
import {BriefPriceItemCategoryResp, PriceItemService} from '../../../../service/price-item.service'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {FilterSettings} from '../profile-catalog-filter/profile-catalog-filter.component'
import {TabMenu} from 'primeng/tabmenu'
import {ActivatedRoute} from '@angular/router'
import {Location} from '@angular/common'
import {clearUrl} from 'src/app/utils/router.utils'
import {PLATFORM_BROWSER} from '../../../../app.module'

@Component({
  selector: 'app-price-item-categories',
  templateUrl: './price-item-categories.component.html',
  styleUrl: './price-item-categories.component.scss'
})
export class PriceItemCategoriesComponent extends ApiComponent implements OnInit {

  /**
   * All currently visible categories.
   */
  categories: BriefPriceItemCategoryResp[] = []

  /**
   * Represents the currently selected category.
   */
  selectedCategory?: BriefPriceItemCategoryResp

  /**
   * The title describing the currently selected category.
   * Used primarily for SEO optimization.
   */
  @Input()
  descriptionTitle = null

  /**
   * The text describing the currently selected category.
   * Used primarily for SEO optimization.
   */
  @Input()
  descriptionText = null

  /**
   * Fires all professions in the selected category, or null when the category gets deselected.
   */
  @Output()
  categoryChange = new EventEmitter<PriceItemCategoryDetail>()

  /**
   * Fires when the filter button has been clicked.
   */
  @Output()
  filterClick = new EventEmitter<any>()

  /**
   * The tabMenu component from the layout. Gets initialized after certain conditions.
   */
  tabMenuComponent?: TabMenu

  constructor(
    public navbarService: NavbarService,
    private priceItemService: PriceItemService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private location: Location,
    private storageService: StorageService) {
    super()
  }

  ngOnInit(): void {
    this.call(async () => {
      this.categories = await firstValueFrom(this.callLoadAll())
      // Initialization is called from the parent component
      // if (!(await this.parseParams())) {
      //   this.trySelectSavedCategory()
      // }
    })
  }

  /**
   * Tries to fetch URL params and select proper categories.
   */
  private async parseParams(): Promise<boolean> {
    const params = await firstValueFrom(this.route.queryParams)
    const categoryParamId = params['category']
    clearUrl(this.location)
    if (categoryParamId) {
      const cat = this.categories.find(it => it.id === +categoryParamId)
      if (cat) {
        await this.onCategoryClick(cat)
        this.scrollToSelectedCategory(cat)
        return true
      }
    }
    return false
  }

  /**
   * Initializes the component with the given category.
   * Method is public because it is called from the parent component.
   * @param c the category to initialize the component with.
   */
  initialCategoryLoad(c: BriefPriceItemCategoryResp): void {
    if (c) {
      this.scrollToSelectedCategory(c)
    } else {
      this.trySelectSavedCategory()
    }
  }

  /**
   * Fires when the user clicks on a category.
   */
  async onCategoryClick(c: BriefPriceItemCategoryResp): Promise<void> {
    if (!c.selected) { // clear previous category
      this.deselectCategories()
    }
    this.selectedCategory = c
    c.selected = !c.selected // toggle selected
    if (c.selected) {
      this.metaService.onPriceItemCategoryClick(c, false)
      const prof = await firstValueFrom(this.callLoadDetails(c.id))
      this.categoryChange.emit({
        category: c,
        professions: prof
      })
    } else {
      this.categoryChange.emit(null)
    }
  }

  /**
   * Deselects all selected categories.
   */
  deselectCategories(): void {
    this.categories?.forEach(it => it.selected = false)
    this.selectedCategory = null
  }

  /**
   * Tries to select saved category from the localstorage.
   */
  private trySelectSavedCategory(): void {
    const settings = JSON.parse(this.storageService.getItemStorage(StorageItem.BOOK_FILTER)) as FilterSettings
    const c = settings?.categories?.[0]
    if (c) {
      this.scrollToSelectedCategory(c)
    }
  }

  /**
   * Visually scrolls the {@link tabMenuComponent} to the position of the {@link selected} category.
   */
  private scrollToSelectedCategory(selected: BriefPriceItemCategoryResp): void {
    const i = this.categories.findIndex(it => it.id === selected.id)
    this.categories[i].selected = true
    if (PLATFORM_BROWSER) {
      setTimeout(() => {
        this.tabMenuComponent?.updateScrollBar(i)
      }, 200)
    }
  }

  /**
   * Calls the server API to fetch all profession categories.
   */
  private callLoadAll(): Observable<BriefPriceItemCategoryResp[]> {
    return this.unwrap(this.priceItemService.callGetAllCategories())
  }

  /**
   * Calls the server API to
   */
  private callLoadDetails(id: number): Observable<BriefProfessionResp[]> {
    return this.unwrap(this.priceItemService.callGetPriceItemCategoryProfessions(id))
  }
}

/**
 * A schema of the price item category detail. Contains the category with its professions.
 */
export interface PriceItemCategoryDetail {
  category: BriefPriceItemCategoryResp
  professions: BriefProfessionResp[]
}
