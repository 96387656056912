import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core'
import {fadeAnimation} from '../../../animation/fade.animation'
import {NgIf} from '@angular/common'
import {BackgroundImagePipe} from '../../../pipe/background-image.pipe'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  imports: [
    NgIf,
    BackgroundImagePipe
  ],
  standalone: true
})
export class ImgComponent implements OnInit, OnChanges {

  /**
   * The source URL.
   */
  @Input()
  src: string

  /**
   * The alternative image text.
   */
  @Input()
  alt: string

  /**
   * Shows the skeleton element instead of the image.
   */
  @Input()
  loading: boolean

  /**
   * Emits when the {@link src} has been loaded.
   */
  @Output()
  loaded = new EventEmitter<boolean>()

  /**
   * This style class is applied to the <img> element.
   */
  @Input()
  styleClass: string

  /**
   * Enables the {@link fadeAnimation} between the skeleton and img element.
   */
  @Input()
  animations = true
  /**
   * - The base Z index for the image and skeleton.
   * - 1 by default.
   */
  @Input()
  baseZIndex = 1

  /**
   * Use the background-image css instead of the <img> element.
   */
  @Input()
  background: boolean

  /**
   * Represents the current state of the {@link src} whether it has been preloaded or not.
   */
  imgLoaded = false

  constructor(private elRef: ElementRef,
              @Inject(PLATFORM_ID) private platformId: any) {
  }

  ngOnInit(): void {
    if (this.background) {
      this.elRef.nativeElement.style.width = '100%'
    }

    if (!this.alt && !this.background) {
      console.warn('The [alt] is missing in the <app-img> component.')
    }
    this.loadImage()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src?.currentValue) {
      this.loadImage()
    }
  }

  /**
   * Preloads the {@link src}.
   */
  private async loadImage(): Promise<void> {
    if (!this.src) {
      return
    }
    this.imgLoaded = false
    await this.preload(this.src)
    this.imgLoaded = true
  }

  /**
   * Preloads the {@link src} via the {@link HTMLImageElement}.
   */
  private preload(src: string): Promise<void> {
    return new Promise(resolve => {
      const img = new Image()
      img.onload = (): void => {
        img.onload = null
        return resolve()
      }
      img.src = src
    })
  }
}
