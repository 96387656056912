<div class="wrapper">

  <!--Events-->
  <div *ngIf="data?.events?.content?.length > 0">
    <p-carousel [value]="data?.events?.content"
                [numVisible]="1"
                [numScroll]="1"
                [circular]="true"
                [showIndicators]="false"
                class="events-carousel">

      <ng-template pTemplate="item" let-item>
        <div class="p-px-1">
          <div class="p-d-flex p-ai-center p-mb-1 event"
               *ngVar="item.nestedProfile as event">
            <div class="p-d-flex p-ai-center w-100 p-p-2">
              <!--Avatar-->
              <div class="p-d-flex p-ai-center c-pointer">
                <app-avatar [profile]="event"
                            sizePx="50">
                </app-avatar>
              </div>


              <div class="p-d-flex p-jc-between w-100 p-ml-3 c-pointer">

                <!--Title-->
                <div [appRedirectProfile]="event.charId">
                  <div class="event-title l-count-1">{{event.displayName}}</div>
                  <div class="event-type p-d-flex p-ai-center">
                    <app-profile-icon [profile]="event" styleClass="p-mr-2 p-mt-1"></app-profile-icon>
                    {{event.charId}}</div>
                </div>

                <!--Date Time-->
                <div class="p-d-flex">
                  <app-profile-opening-hours [inMapCarousel]="true"
                                             [data]="event"
                  ></app-profile-opening-hours>

                </div>
              </div>

              <!--Follow-->
              <app-profile-like [currentProfile]="currentProfile"
                                [likeTo]="event"></app-profile-like>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>

  <!--Bubble content-->
  <div class="p-d-flex box-shadow container">
    <div class="p-d-flex">
      <div class="photo" [appRedirectProfile]="data.profile.charId">
        <div [style]="data | wallpaper: '500' | backgroundImage" class="fit-image"></div>
      </div>

      <div class="content">
        <h1 class="title c-pointer l-count-1"
            [appRedirectProfile]="data.profile.charId">{{data.profile.displayName}}</h1>
        <div class="p-grid p-d-flex p-ai-center subject-info w-100">
          <div class="c-pointer p-p-0"
               [class.p-col-6]="data.profile.profileType !== ProfileType.EVENT"
               [class.p-col-12]="data.profile.profileType === ProfileType.EVENT">

            <div class="p-d-flex p-ai-center">
              <app-profile-icon [profile]="data.profile" styleClass="p-mr-2"></app-profile-icon>
              <div class="type l-count-1">{{data.profile.charId}}</div>
            </div>
          </div>

          <!--Opening Hours-->
          <div [class.p-col-6]="!isEvent"
               [class.p-col-12]="isEvent"
               class="p-p-0">
            <div [class.p-d-flex]="!isEvent"
                 [class.p-jc-end]="!isEvent">
              <app-profile-opening-hours [inMapBubble]="true"
                                         [data]="data.profile"
              ></app-profile-opening-hours>
            </div>
          </div>
        </div>

        <!--Bio-->
        <article
          [class.l-count-5]="!isEvent"
          [class.l-count-4]="isEvent" class="bio l-count-4">{{data.bio || ''}}</article>

        <div class="p-d-flex p-ai-center p-jc-between p-mt-1" *ngIf="data.genres && data.price">
          <span class="genres l-count-1" *ngIf="data.genres">{{genres | join}}</span>
          <span class="price p-text-bold" *ngIf="data.price">{{data.price?.price | price}}</span>
        </div>

        <!--Rating-->
        <div class="p-d-flex p-ai-center p-mt-2" *ngIf="false">
          <div [pTooltip]="213458 | count" tooltipPosition="top">
            <app-rating></app-rating>
          </div>

          <!--Follow-->
          <app-profile-like [currentProfile]="currentProfile"
                            [likeTo]="data.profile"
                            [displayCount]="true"
                            styleClass="p-ml-5"></app-profile-like>
        </div>

      </div>
    </div>

    <div class="p-d-flex p-jc-center p-ai-center footer">
      <!--<div class="p-d-flex p-ai-center">

        &lt;!&ndash;Web And (Phone Or Mail)&ndash;&gt;
        <div *ngIf="data.contact?.web; else mailPhone" class="p-d-flex p-ai-center p-jc-center c-pointer">
          <ng-container [ngTemplateOutlet]="web"></ng-container>
          <ng-container [ngTemplateOutlet]="(data.contact?.phone)? phone : mail"></ng-container>
        </div>

        &lt;!&ndash;Mail And Phone&ndash;&gt;
        <ng-template #mailPhone>
          <ng-container [ngTemplateOutlet]="mail"></ng-container>
          <ng-container [ngTemplateOutlet]="phone"></ng-container>
        </ng-template>

        &lt;!&ndash;Mail information&ndash;&gt;
        <ng-template #mail>
          <div *ngIf="data.contact?.email" [appMail]="data.contact?.email" class="p-px-4 contact">
            <i class="fa-regular fa-envelope p-mr-2"></i>
            <div class="l-count-1">{{data.contact?.email}}</div>
          </div>
        </ng-template>

        &lt;!&ndash;Phone information&ndash;&gt;
        <ng-template #phone>
          <div *ngIf="data.contact?.phone" [appPhone]="data.contact?.phone" class="p-px-4 contact">
            <i class="fa-solid fa-phone p-mr-2"></i>
            <div class="l-count-1">{{data.contact?.phone}}</div>
          </div>
        </ng-template>

        &lt;!&ndash;Web information&ndash;&gt;
        <ng-template #web>
          <div *ngIf="data.contact?.web" [appUrl]="data.contact?.web" class="p-px-4 contact">
            <i class="fa-solid fa-globe p-mr-2"></i>
            <div class="l-count-1">{{data.contact?.web}}</div>
          </div>
        </ng-template>

        &lt;!&ndash;Char Id&ndash;&gt;
        <div *ngIf="!data.contact" class="p-d-flex p-ai-center p-jc-center c-pointer"
             [appRedirectProfile]="data.profile.charId">
          <i class="fa-solid fa-user p-mr-2"></i>
          <div class="l-count-1">{{data.profile.charId}}</div>
        </div>
      </div>-->
    </div>
  </div>
</div>
