import {Directive, ElementRef, Input, OnInit} from '@angular/core'
import {ProfileType} from '../common/profile-type'

@Directive({
  selector: '[appProfileBorder]',
  standalone: true
})
export class ProfileBorderDirective implements OnInit {

  @Input()
  appProfileBorder: any

  @Input()
  borderVisible = true

  constructor(private elemRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.borderVisible) {
      this.elemRef.nativeElement.style.borderColor = getProfileBorderColor(this.appProfileBorder?.profileType)
    }
  }
}

export function getProfileBorderColor(profileType?: ProfileType): string {
  switch (profileType) {
    case ProfileType.USER:
      return 'white'
    case ProfileType.ARTIST:
      return 'blue'
    case ProfileType.ENTERPRISE:
      return 'black'
    case ProfileType.EVENT:
      return 'green'
    default:
      return 'white'
  }
}
