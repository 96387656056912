import {Injectable, Renderer2, RendererFactory2} from '@angular/core'
import {Observable} from 'rxjs'
import {isFileImage} from '../../utils/file.utils'
import {ServerMessage} from '../../common/server-message'
import Compressor from 'compressorjs'
import {createRandomUUID} from '../../utils/utils'
import Options = Compressor.Options

/**
 * This service includes all utilities associated with image files for resizing, saving, and canvas handling.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private renderer: Renderer2

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  /**
   * @see https://github.com/fengyuanchen/compressorjs
   */
  compress(file, options: Options): Compressor {
    return new Compressor(file, options)
  }

  /**
   * Returns the data url of the image file.
   * The promise is rejected if the passing file is not an image.
   */
  imageFileToURL(imageFile: File): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!imageFile || !isFileImage(imageFile)) {
        return reject(ServerMessage.FILE_FORMAT_NOT_SUPPORTED)
      }

      const reader = new FileReader()
      reader.readAsDataURL(imageFile)
      reader.onload = (ev => {
        // reader.removeAllListeners()
        return resolve(ev.target.result as string)
      })
    })
  }

  /**
   *  Converts the image URL (data, https, ...) to the file.
   */
  urlToFile(url: string, filename: string = createRandomUUID(), mimeType: string = 'image/jpeg'): Promise<File> {
    return (fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, {type: mimeType}))
    )
  }

  /**
   * - Resizes the image file.
   * - Ensures that none of the image edge sizes (width and height) will exceed the given number of 'maxWidthHeight'.
   * The resizing process is done by the larger size (width or height) of the image. This keeps the aspect ratio unchanged.
   * - If the image doesn't exceed the given 'maxWidthHeight' parameter, it will return the unchanged image file.
   * - If the min width or height is present, it checks if the image after resizing has at least that width or height. Otherwise, it rejects a promise.
   *
   * @param imgFile The image file.
   * @param maxWidthHeight The image width and height will not exceed this value.
   * @param minWidth If the image width after the scaling process is lower than this value, it rejects a promise.
   * @param minHeight If the image height after the scaling process is lower than this value, it rejects a promise.
   */
  fitTo(imgFile: File, maxWidthHeight: number, minWidth?: number, minHeight?: number): Observable<File> {
    return new Observable<File>(result => {
      this.createImgElement(imgFile).subscribe(img => {
        const imgWidth = img.width
        const imgHeight = img.height
        const fileName = imgFile.name

        if (imgWidth >= imgHeight) { // SQUARE and LANDSCAPE format
          if (imgWidth > maxWidthHeight) {
            this.scaleImageByWidth(fileName, maxWidthHeight, img, minHeight)
              .then(f => result.next(f))
              .catch(e => result.error(e)) // when a promise is being rejected
            return
          }
        } else { // PORTRAIT format
          if (imgHeight > maxWidthHeight) {
            this.scaleImageByHeight(fileName, maxWidthHeight, img, minWidth)
              .then(f => result.next(f))
              .catch(e => result.error(e))
            return
          }
        }

        // Check if an image without resizing is big enough
        if (minWidth && imgWidth < minWidth || minHeight && imgHeight < minHeight) {
          result.error(ServerMessage.IMAGE_SIZE_TOO_SMALL)
        }

        // the image doesn't need to be resized but make sure it is JPG
        this.resizeImage(img, imgWidth, imgHeight, fileName)
          .then(f => result.next(f))
          .catch(e => result.error(e))
      })
    })
  }

  /**
   * Creates the {@link HTMLImageElement} object from the given image file.
   *
   * @param imgFile An image file.
   */
  private createImgElement(imgFile: File): Observable<HTMLImageElement> {
    const reader = new FileReader()
    reader.readAsDataURL(imgFile)

    return new Observable<HTMLImageElement>((result) => {
      reader.onload = (ev) => {
        const img = new Image()
        img.src = (ev.target as any).result
        img.onload = () => result.next(img)
      }
      reader.onerror = err => result.error(err)
    })
  }

  /**
   * Scales the image height by the given width while the aspect ratio keeps unchanged.
   *
   * @param fileName A file name of the image.
   * @param width A new width of the image.
   * @param img Use {@link createImgElement} function to create an element from a file.
   * @param minHeight If is present, it checks if the scaled image will have at least that height. Otherwise, it rejects a promise.
   */
  private scaleImageByWidth(fileName: string, width: number, img: HTMLImageElement, minHeight?: number): Promise<File> {
    const scaleFactor = width / img.width
    const height = img.height * scaleFactor

    // check if the compressed image has the proper height
    if (minHeight && height < minHeight) {
      return new Promise<File>((resolve, reject) => {
        reject(ServerMessage.IMAGE_SIZE_TOO_SMALL)
      })
    }

    return this.resizeImage(img, width, height, fileName)
  }

  /**
   * Scales the image width by the given height while the aspect ratio keeps unchanged.
   *
   * @param fileName A file name of the image.
   * @param height A new height of the image.
   * @param img Use {@link createImgElement} function to create an element from a file.
   * @param minWidth If is present, it checks if the scaled image will have at least that width. Otherwise, it rejects a promise.
   */
  private scaleImageByHeight(fileName: string, height: number, img: HTMLImageElement, minWidth?: number): Promise<File> {
    const scaleFactor = height / img.height
    const width = img.width * scaleFactor

    // check if the compressed image has the proper width
    if (minWidth && width < minWidth) {
      return new Promise<File>((resolve, reject) => {
        reject(ServerMessage.IMAGE_SIZE_TOO_SMALL)
      })
    }
    return this.resizeImage(img, width, height, fileName)
  }

  /**
   * Resizes and draws an image on the canvas.
   *
   * @param img An Image element with a loaded image.
   * @param width The width the image will have.
   * @param height The height the image will have.
   * @param fileName The file name of the image.
   */
  private resizeImage(img: HTMLImageElement, width: number, height: number, fileName: string): Promise<File> {
    if (img.width < width || img.height < height) {
      return new Promise<File>((resolve, reject) => {
        reject(ServerMessage.IMAGE_SIZE_TOO_SMALL)
      })
    }

    const canvas = this.renderer.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    ctx.fillStyle = '#ffffff' // fill the canvas with the white color (because of PNG transparent channel)
    ctx.fillRect(0, 0, width, height) // create the rectangle over the entire canvas (because of PNGs)

    ctx.drawImage(img, 0, 0, width, height)
    return this.createImageFileFromCanvas(ctx.canvas, fileName)
  }

  /**
   * Creates a file from the canvas element.
   */
  private createImageFileFromCanvas(canvas: HTMLCanvasElement, fileName: string): Promise<File> {
    return new Promise<File>((resolve) => {
      canvas.toBlob((blob) => {
          resolve(
            new File([blob], fileName, {
              type: 'image/jpg',
              lastModified: Date.now()
            })
          )
        },
        'image/jpeg',
        1
      )
    })
  }
}
