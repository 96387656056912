<div class="wrapper p-absolute p-d-flex p-jc-center p-ai-center" *ngIf="visible" @fade>
  <div class="middle-box p-d-flex p-flex-column p-ai-center p-px-3">
    <div class="bounce">
      <img src="assets/logo/logo-color.png" alt="Umevia logo" i18n-alt>
    </div>

    <div class="p-relative">
      <i class="fa-solid fa-trademark p-absolute trade-mark"></i>
      <h1 class="title p-m-0" i18n>Umevia</h1>
    </div>

    <!--Hints-->
    <article class="hint rounded-medium p-d-flex p-ai-center">
      <i class="fa-solid fa-lightbulb p-mr-4"></i>
      <section>
        <b class="p-m-0" i18n>Did You Know?</b>
        <p class="p-m-0" i18n>Our tutorials contain useful advice on how to use Umevia effectively.
          <a href="https://docs.umevia.com" target="_blank">docs.umevia.com</a></p>
      </section>
    </article>

    <!--Social sites-->
    <section class="social p-d-flex p-ai-center">
      <a [href]="contactInfo.instagram" target="_blank"><i class="fa-brands fa-instagram"></i></a>
      <a [href]="contactInfo.x" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
      <a [href]="contactInfo.youtube" target="_blank"><i class="fa-brands fa-youtube"></i></a>
      <a [href]="contactInfo.facebook" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
      <a [href]="contactInfo.linkedin" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
      <a [href]="contactInfo.docs" target="_blank"><i class="fa-solid fa-book"></i></a>
    </section>

    <!--Copyright-->
    <section class="p-mt-3 p-d-flex p-flex-column p-jc-center p-ai-center">
      <div i18n>&copy; {{ currentDate | date: 'yyyy' }} Umevia s.r.o.</div>
      <div i18n>All rights reserved.</div>
    </section>
  </div>
</div>
