import {Component, Input} from '@angular/core'
import {openProfile} from '../../../directive/redirect-profile.directive'
import {BriefProfileResp, OrderableProfileResp, ProfileResp} from '../../../service/profile.service'
import {CalendarItemNestedProfileResp} from '../../../service/calendar.service'
import {ProfileIconComponent} from '../profile-icon/profile-icon.component'
import {NgIf} from '@angular/common'

@Component({
  selector: 'app-profile-names',
  templateUrl: './profile-names.component.html',
  styleUrls: ['./profile-names.component.scss'],
  imports: [
    ProfileIconComponent,
    NgIf
  ],
  standalone: true
})
export class ProfileNamesComponent {

  /**
   * Defines visibility of the charId
   */
  @Input()
  charIdVisible = true

  @Input()
  profile: ProfileResp | BriefProfileResp | CalendarItemNestedProfileResp | OrderableProfileResp

  @Input()
  size = 1

  @Input()
  displayNameLinesCount = 5

  @Input()
  styleClass: string

  /**
   * If a user click on this component, it will redirect him to the selected profile.
   */
  @Input()
  redirect = true

  /**
   * Shows cursor pointer while hovering.
   */
  @Input()
  showPointer: boolean

  constructor() {
  }

  /**
   * Fires when a user clicked on the display name or char id.
   * If the {@link redirect} is enabled, it will redirect the user to the profile based on the {@link charId}.
   */
  onRedirect(): void {
    if (this.redirect) {
      openProfile(this.profile.charId)
    }
  }

}
