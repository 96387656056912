import {Injectable, OnDestroy} from '@angular/core'
import {BehaviorSubject, firstValueFrom, map, Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {NavigationService} from './ui/navigation.service'
import {ApiService} from './api.service'
import {FcmService} from './fcm.service'
import {catchError} from 'rxjs/operators'
import {BriefProfileResp, ProfileService} from './profile.service'
import {Role} from '../common/roles'
import {Endpoint} from '../common/endpoints'
import {ProfileType} from '../common/profile-type'
import {AuthTypeEnum} from '../common/auth-type-enum'
import {UserResponseType} from '../common/user-response-type'
import {SessionLogoutReq, SessionRest} from '../rest/user/session-device/session-device'
import {Language} from '../common/language'
import {StorageItem, StorageService} from './storage.service'
import {PwaService} from './helper-services/pwa.service'
import {environment} from '../../environments/environment'
import {throwAppError} from '../utils/log.utils'

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService implements OnDestroy {

  /**
   * Constant to be added to URL in case of the expired session of user.
   */
  static readonly EXPIRED_URL = 'expired'
  /**
   * Constant to be added to URL when user is logging out.
   */
  static readonly LOGOUT_URL = 'logout'

  /**
   * Represents user information, who is logged in.
   * - Undefined values means, that {@link loadUser} function, was not finished,
   *    so we don't know if there is some logged-in user.
   * - Null value means, that there is no logged-in user.
   * - Not Null value means, that some user is logged-in.
   */
  readonly user = new BehaviorSubject<UserResp>(undefined)

  /**
   * Represents information about user, which needs to authenticate with code before accessing the application.
   */
  readonly twoFactorAuthentication = new BehaviorSubject<TwoFactorResp>(null)

  /**
   * Represents information te be passed for {@link LogoutDialogComponent}.
   * - Subscription allowed only in {@link LogoutDialogComponent} for reaction.
   * - For passing new value use {@link createLogoutRequest} function.
   */
  readonly logoutRequest = new BehaviorSubject<LogoutReq>(null)

  /**
   * Channel to inform other tabs, that user has logged into platform.
   */
  readonly loginChannel = new BroadcastChannel('umevia-login-logout')

  constructor(
    private profileService: ProfileService,
    private fcmService: FcmService,
    private storageService: StorageService,
    private navigation: NavigationService,
    private pwaService: PwaService) {
    super()
    this.loadUser()
    this.initLoginChannel()
  }

  /**
   * It will call the {@link #callGetUser} method to download the user data from the server.
   */
  async loadUser(): Promise<boolean> {
    // Skip when the user is not logged
    if (!this.storageService.checkCookie(StorageItem.TOKEN_COOKIE)) {
      await this.updateUser(null)
      return false
    }

    try {
      const user = await firstValueFrom(this.callGetUser())
      await this.updateUser(user)
      return true
    } catch (e) {
      this.updateUser(null)
      return false
    }
  }

  /**
   * Updates the current user by the {@link UserResp}.
   * - If {@link resp} is null, then does nothing.
   */
  async updateUser(resp: UserResp): Promise<void> {
    if (resp) {
      this.profileService.setCurrentProfile(resp.currentProfile.uuid)
      await this.profileService.init(resp.currentProfile.profileId)
      await this.fcmService.requestToken()
      this.languageService.setUserLanguage(resp.language)
      this.pwaService.initPwaDialog()
    } else {
      this.profileService.clearData()
    }

    this.user.next(resp)
  }

  /**
   * Pushes new value to {@link logoutRequest}, to trigger logout in {@link LogoutDialogComponent}.
   * @param logoutAll Boolean flag whether to use {@link callLogoutAll} or {@link callLogout}.
   * @param pathToNavigate Path where to navigate after succesful log out.
   * @param rememberDestination Parameter for {@link NavigationService.toLogIn}.
   * @param showDialog If log out dialog should be displayed.
   * @param delay Delay in ms, when the navigation should start. Default value is 1000ms.
   * @param performRequest Whether to call server API or just perform post logout actions.
   */
  createLogoutRequest(
    logoutAll = false,
    pathToNavigate = this.navigation.urlPathLogin(false),
    rememberDestination = false,
    showDialog = true,
    delay = 1000,
    performRequest = true
  ): void {
    this.logoutRequest.next({
      logoutAll,
      pathToNavigate,
      rememberDestination,
      delay,
      showDialog,
      performRequest
    })
  }

  /**
   * Makes a call of {@link LoginReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callLogIn(req: LoginReq): Observable<BaseResp<UserResp | TwoFactorResp>> {
    return this.post(Endpoint.LOGIN_URL, req, this.deviceHeaders, false)
  }

  /**
   * Makes a call of {@link LoginTwoFactorReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callLogInTwoFactor(req: LoginTwoFactorReq): Observable<BaseResp<UserResp>> {
    return this.post(Endpoint.LOGIN_TWO_FACTOR_URL, req, this.deviceHeaders, false)
  }

  /**
   * Makes a call of {@link RestoreRequestReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callRestoreTwoFactorVerificationCode(req: RestoreTwoFactorCodeReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.RESTORE_CODE_URL, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link RegisterReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callRegister(req: RegisterReq): Observable<BaseResp<UserResp>> {
    return this.post(Endpoint.REGISTER_URL, req, this.deviceHeaders, false)
  }

  /**
   * Makes a call of {@link RestoreRequestReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callPasswordRestoreRequest(req: RestoreRequestReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.RESTORE_PASSWORD_REQUEST_URL, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link RestoreRequestReq} on the backend API.
   */
  callUniqueUser(req: UniqueUserReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHECK_UNIQUE_USER, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link VerifyPhoneReq} on the backend API.
   */
  callPhoneVerificationInit(req: VerifyPhoneReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PHONE_VERIFICATION_INIT, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link SubmitVerifiedChangeReq} on the backend API.
   */
  callPhoneVerification(req: SubmitVerifiedChangeReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PHONE_VERIFICATION, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link VerifyPhoneReq} on the backend API, to retrieve verification code.
   * #### WARNING - not for PRODUCTION use. Allowed to use only in testing to create new users with ease.
   */
  callPhoneVerificationCode(req: PhoneCodeTestReq): Observable<BaseResp<PhoneCodeTestResp>> {
    if (environment.production) {
      throwAppError('UserService - callPhoneVerificationCode', 'Cannot be used in production.')
    }
    return this.post(Endpoint.PHONE_VERIFICATION_CODE, req, super.getHeaders(), false)
  }

  /**
   * Makes a call of {@link RestoreReq} on the backend API.
   *
   * @param req The body of the call.
   */
  callPasswordRestore(req: RestoreReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.RESTORE_PASSWORD_URL, req, super.getHeaders(), false)
  }

  /**
   * Issues a new code for the email change.
   */
  callUpdateEmailInit(req: ChangeEmailReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_UPDATE_EMAIL_INIT, req)
  }

  /**
   * Makes a call on the backend API to update the user based on the JWT.
   */
  callUpdateEmail(req: SubmitVerifiedChangeReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_UPDATE_EMAIL, req)
  }

  /**
   * Makes a call on the backend API to change authentication provider.
   */
  callUpdateUserProvider(req: UpdateUseProviderReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_UPDATE_PROVIDER, req)
  }

  /**
   * Makes a call on the backend API to update the user settings based on the JWT.
   */
  callGetUserSettings(): Observable<BaseResp<UserSettingsRest>> {
    return this.post(Endpoint.USER_SETTINGS_GET, null)
  }

  /**
   * Makes a call on the backend API to update the user settings based on the JWT.
   */
  callUpdateUserSettings(req: UserSettingsRest): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_UPDATE, req)
  }

  callChangeTwoFactorAuthenticationInit(req: boolean): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_TWO_FACTOR_INIT, req)
  }

  /**
   * Makes a call to the API to change flag of two-factor authentication.
   */
  callChangeTwoFactorAuthentication(req: ChangeTwoFactorReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_TWO_FACTOR, req)
  }

  /**
   * Makes a call of {@link ChangePasswordReq} on the backend API.
   */
  callChangePasswordInit(req: ChangePasswordReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_PASSWORD_INIT, req)
  }

  callChangePassword(req: SubmitVerifiedChangeReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_PASSWORD, req)
  }

  /**
   * Makes a call of {@link CheckPasswordReq} to the API to verify whether the password is correct.
   */
  callCheckPassword(req: CheckPasswordReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHECK_PASSWORD, req)
  }

  /**
   * Makes a call of {@link ChangePasswordReq} on the backend API.
   */
  callChangePhoneInit(req: ChangePhoneReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_PHONE_INIT, req)
  }

  callChangePhone(req: SubmitVerifiedChangeReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_CHANGE_PHONE, req)
  }

  /**
   * Makes a call to get all users sessions to backend API.
   */
  callGetUserSessions(): Observable<BaseResp<Map<string, SessionRest[]>>> {
    return this.post(Endpoint.USER_SETTINGS_SESSIONS, null)
  }

  /**
   * Makes a call {@link SessionLogoutReq} to backend API.
   */
  callLogoutSession(req: SessionLogoutReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.USER_SETTINGS_SESSIONS_LOGOUT, req)
  }

  /**
   * Makes a call of jwt on the backend API.
   * Backend then deletes all cookies for authentication.
   * Does not have to be authenticated request.
   */
  callLogout(): Observable<BaseResp<boolean>> {
    return this.post<boolean>(Endpoint.LOGOUT_URL, null, this.getHeaders(), false)
  }

  /**
   * Makes a call of jwt on the backend API.
   * Backend then deletes all cookies for authentication for current session
   * and marks all other sessions as deleted.
   * Does not have to be authenticated request.
   */
  callLogoutAll(): Observable<BaseResp<boolean>> {
    return this.post<boolean>(Endpoint.LOGOUT_ALL_URL, null, this.getHeaders(), false)
  }

  /**
   * Makes a call of a jwt on the backend API.
   * Backend then changes consented version of legal documents
   * to user in jwt toke.
   */
  callConsentNewPolicy(): Observable<BaseResp<boolean>> {
    return this.post<boolean>(Endpoint.POLICY_UPDATE_URL, null)
  }

  /**
   * Initializes {@link loginChannel} for listening, whether user was logged in or out from page.
   * When event data contains true value, it calls {@link loadUser} function.
   * Otherwise, it calls {@link createLogoutRequest}.
   */
  private initLoginChannel(): void {
    this.loginChannel.onmessage = async (event) => {
      if (event.data) {
        // Stay on the same page and only reload user
        await this.loadUser()
      } else {
        this.createLogoutRequest(
          false,
          this.navigation.urlPathLogin(false),
          false,
          false,
          0,
          false)
      }
    }
  }

  /**
   * Makes a call of jwt on the backend API.
   * Tries to download the user information based on stored jwt.
   * On any server messages, it will logout the user and redirect to login.
   */
  private callGetUser(): Observable<UserResp> {
    return this.post<UserResp>(Endpoint.GET_USER_URL, null, super.getHeaders(), false).pipe(
      map(it => {
        if (it.messages.length > 0) { // logout on errors
          this.createLogoutRequest(false, NavigationService.PROFILE_CATALOG, false, false)
        }
        return it.body // return UserResp
      }),
      catchError(err => {
        throw err
      })
    )
  }

  ngOnDestroy(): void {
    this.loginChannel?.close()
  }
}

/**
 * An interface that defines the structure of the login request call, accepted by the server.
 */
export interface LoginReq {
  email: string
  password: string
  stayLogged: boolean
}

/**
 * An interface that defines the structure of the two-factor authentication request call, accepted by the server.
 */
export interface LoginTwoFactorReq {
  email: string
  code?: string
  stayLogged: boolean
}

/**
 * Issues a new code to change the email.
 */
export interface ChangeEmailReq {
  email: string
}

export interface UpdateUseProviderReq {
  email: string
  password: string
  firebaseUid: string
}

export interface UpdateUseProviderReq {
  email: string
  password: string
  firebaseUid: string
}

export interface UserSettingsRest {
  phone?: string

  emailNewsletter: boolean
  emailChatMessage: boolean
  emailNewOrder: boolean
  emailOrderUpdate: boolean
  emailOrderReminder: boolean

  notificationChatMessage: boolean
  notificationOrderReminder: boolean
  localeCode: string
  twoFactorAuthentication: boolean
}

/**
 * An interface that defines the structure of the User entity provided from the server.
 */
export interface UserResp {
  userId: number
  email: string
  roles: Role[]
  currentProfile: BriefProfileResp
  authType?: AuthTypeEnum
  firebaseUid?: string
  type?: UserResponseType
  language: Language
}

/**
 * An interface that defines the response, when 2FA is needed for a user.
 */
export interface TwoFactorResp {
  userId: number
  email: string
  stayLogged: boolean
  type?: UserResponseType
}

export interface PermissionResp {
  name: string
  /**
   * The optional parameter of the permission. (e.g. max wallpapers per profile, where wallpapers is a permission)
   */
  max?: number
}

/**
 * An interface that defines the structure of the register request call, accepted by the server.
 */
export interface RegisterReq {
  displayName: string
  email: string
  phone: string
  profileType: ProfileType
  password: string
}

/**
 * An interface that defines the structure of the unique charId request call, accepted by the server.
 */
export interface UniqueUserReq {
  email: string
  phone: string
}

/**
 * An interface that defines the structure of the verify phone request call, accepted by the server.
 */
export interface VerifyPhoneReq {
  email?: string
  phone: string
}

/**
 * An interface that defines the structure of the restore request call, accepted by the server.
 */
export interface RestoreReq {
  email: string
  code: string
}

/**
 * An interface that defines the structure of the restore-request request call, accepted by the server.
 */
export interface RestoreRequestReq {
  email: string
  newPassword: string
}

/**
 * An interface that defines the structure of the change password request call, accepted by the server.
 */
export interface ChangePasswordReq {
  password: string
  newPassword: string
}

/**
 * An interface that defines the structure of the check password request call.
 */
export interface CheckPasswordReq {
  password: string
}

/**
 * Issues a new code to change the phone.
 */
export interface ChangePhoneReq {
  phone: string
}

/**
 * An interface that defines structure of two-factor authentication change request.
 */
export interface ChangeTwoFactorReq {
  twoFactor: boolean
  code: string
  authType?: AuthTypeEnum
  password?: string
}

/**
 * An interface that defines structure of two-factor authentication code restoration request.
 */
export interface RestoreTwoFactorCodeReq {
  email: string
}

/**
 * Used to submit an issued code of any verification changes like {@link ChangePhoneReq}, {@link ChangePasswordReq},
 * {@link RestoreRequestReq}, or {@link ChangeEmailReq}.
 */
export interface SubmitVerifiedChangeReq {
  code: string
  email?: string
}

/**
 * A custom type to define user birthdate separately by day, month, and year to prevent zone changing.
 */
export interface Birthdate {
  day: number
  month: number
  year: number
}

/**
 * Interface for request to log out.
 */
export interface LogoutReq {
  /**
   * Boolean flag whether to use {@link callLogoutAll} or {@link callLogout}.
   */
  logoutAll: boolean
  /**
   * Boolean flag where to navigate.
   * ### Use only static variables from {@link NavigationService} !!!
   */
  pathToNavigate: string
  /**
   * Parameter for {@link NavigationService.toLogIn}.
   */
  rememberDestination: boolean
  /**
   * If log out dialog should be displayed.
   */
  showDialog: boolean
  /**
   * Delay in ms, when the navigation should start.
   */
  delay: number
  /**
   * Whether it should be called logout request to server
   * or just call post logout method to delete all necessary cookies.
   */
  performRequest: boolean
}

/**
 * Interface for request to obtain a phone verification code
 * in non-production environments
 */
export interface PhoneCodeTestReq {
  email: string
}

/**
 * Interface with phone verification code
 * in non-production environments.
 */
export interface PhoneCodeTestResp {
  code: string
}
