import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {AuthComponent} from './auth.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {debounceTime, firstValueFrom, Subscription} from 'rxjs'
import {Restrictions} from '../../../common/restrictions'
import {UserResp} from '../../../service/user.service'

/**
 * Abstract class for two-factor authentication components that contains common methods and properties.
 * Contains only common logic for all two-factor authentication components.
 * It is used to avoid code duplication and let the component handle only view without logic.
 */
@Component({template: ''})
export abstract class TwoFactorAbstractComponent extends AuthComponent implements OnInit, OnDestroy {

  /**
   * A form with fields of two-factor authentication.
   */
  form: FormGroup
  /**
   * A state when a user 2FA was successful.
   */
  codeDone = false
  /**
   * Last sent code storage in case of unsuccessful request.
   */
  lastSentCode = ''
  /**
   * Listens to {@link UserService}
   */
  twoFactorReqSub?: Subscription
  /**
   * Subscription to listen to form code field.
   */
  codeFieldSub?: Subscription

  protected formBuilder = inject(FormBuilder)

  ngOnInit(): void {
    this.initForm()
    this.listenTwoFactorReqChanges()
    this.listenCodeFormField()
  }

  /**
   * Call api to verify code for 2FA.
   */
  callLoginTwoFactor(): void {
    this.callAndFinish(async () => {
      this.disableForm(true)
      const formData = this.form.value
      this.lastSentCode = formData.code

      const resp = await firstValueFrom(this.unwrap(this.userService.callLogInTwoFactor({
        email: formData.email,
        code: formData.code,
        stayLogged: formData.stayLogged
      })))

      this.codeDone = resp && this.noServerMessages()
      if (this.codeDone) {
        await this.finishLogin(resp)
        this.afterCodeVerification(resp)
      }
      this.disableForm(false)
    }, () => {
      this.disableForm(false)
    })
  }

  /**
   * Initializes form fields.
   * @private
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      stayLogged: [false],
      email: [''],
      code: ['']
    })
  }

  /**
   * Listens to changes on observable of {@link UserService.twoFactorAuthentication}.
   * If last emitted value is null, it returns to log in form.
   * If it has not null value, it patches the value to the form.
   * @private
   */
  private listenTwoFactorReqChanges(): void {
    this.twoFactorReqSub = this.userService.twoFactorAuthentication.subscribe({
      next: req => {
        if (req == null) {
          this.navigation.toLogIn()
        } else {
          this.form.patchValue({
            email: req.email,
            stayLogged: req.stayLogged
          })
        }
      }
    })
  }

  /**
   * Listens to changes on code form field.
   * When code is not same as {@link lastSentCode} and has length of {@link Restrictions.VERIFIED_CHANGE_CODE_LENGTH}
   * it calls {@link callLoginTwoFactor}
   * @private
   */
  private listenCodeFormField(): void {
    this.codeFieldSub?.unsubscribe()
    this.codeFieldSub = this.form.get('code').valueChanges.pipe(debounceTime(500)).subscribe((code) => {
      if (code !== this.lastSentCode && code.length === Restrictions.VERIFIED_CHANGE_CODE_LENGTH) {
        this.callLoginTwoFactor()
      }
    })
  }

  /**
   * Sets the {@link submitted} property a new value.
   * It also controls the disability of the {@link form}.
   */
  private disableForm(submitted: boolean): void {
    return submitted ? this.form.disable() : this.form.enable()
  }

  ngOnDestroy(): void {
    this.twoFactorReqSub?.unsubscribe()
    this.codeFieldSub?.unsubscribe()
    // in case of accidental redirect here, or typing this address directly into browser
    this.userService.twoFactorAuthentication.next(null)
  }

  /**
   * Called after code verification was successful.
   * Let the child component handle the event.
   */
  abstract afterCodeVerification(user: UserResp): void
}
