import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {growAnimation} from '../../../../animation/grow.animation'
import {AbstractList} from '../abstract.list'
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel'
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {PrimeTemplate} from 'primeng/api'
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {InitDirective} from '../../../../directive/init.directive'

@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    OverlayPanelModule,
    NgTemplateOutlet,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    NgIf,
    InitDirective,
    NgForOf
  ],
  standalone: true
})
export class ListComponent<E> extends AbstractList<E> {
  /**
   * The input page object.
   */
  @Input()
  items: E[] = []

  /**
   * Notifies the {@link items} when they change.
   */
  @Output()
  itemsChange = new EventEmitter<E[]>()
  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>
  /**
   * The scroll viewport.
   */
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport
  /**
   * The overlay panel PrimeNG component.
   */
  @ViewChild('overlay')
  overlayPanel: OverlayPanel
  /**
   * Custom scroller element when the {@link virtual} is enabled.
   */
  @ViewChild('customScroller')
  customScroller: ElementRef<HTMLDivElement>

  constructor(protected changeRef: ChangeDetectorRef) {
    super(changeRef)
  }

  getItemsLength(): number {
    return this.items?.length || 0
  }

  getCustomScroller(): ElementRef<HTMLDivElement> {
    return this.customScroller
  }

  getOverlayPanel(): OverlayPanel {
    return this.overlayPanel
  }

  getViewport(): CdkVirtualScrollViewport {
    return this.viewport
  }

  getTemplates(): QueryList<PrimeTemplate> {
    return this.templates
  }
}
