<app-dialog *ngIf="show"
            type="info"
            [(show)]="show"
            [component]="this"
            [allowSaveOption]="false"
            [successMessage]="trans.intro + (clickedType === ProfileStatus.DELETED ? trans.deleted : (clickedType=== ProfileStatus.INACTIVE ? trans.deactivated : trans.activated))"
            #changeProfileStatusDialog>

  <div *ngIf="clickedType !== ProfileStatus.ACTIVE">
    <!-- Header -->
    <div class="p-d-flex p-flex-column p-jc-center p-ai-center p-my-2">
      <i class="delete-icon fa-solid fa-user-xmark"></i>
      <h3 *ngIf="clickedType === ProfileStatus.DELETED" class="danger-text p-text-center p-mt-4" i18n>
        Are you sure you want to delete your <span class="change-color">{{data.charId}}</span> profile?</h3>
      <h3 *ngIf="clickedType !== ProfileStatus.DELETED" class="danger-text p-text-center p-mt-4" i18n>
        Are you sure you want to deactivate your <span class="change-color">{{data.charId}}</span> profile?</h3>
      <h3 *ngIf="clickedType === ProfileStatus.DELETED" i18n>You can't undo this action.</h3>
    </div>
  </div>

  <!-- Deleting, Deactivating or Activating content -->
  <div [ngSwitch]="clickedType">
    <div *ngSwitchCase="ProfileStatus.DELETED">
      <ng-container [ngTemplateOutlet]="deletingTemplate"></ng-container>
    </div>
    <div *ngSwitchCase="ProfileStatus.INACTIVE">
      <ng-container [ngTemplateOutlet]="deactivatingTemplate"></ng-container>
    </div>
    <div *ngSwitchCase="ProfileStatus.ACTIVE">
      <ng-container [ngTemplateOutlet]="activatingTemplate"></ng-container>
    </div>
  </div>

  <!-- Info about outstanding orders -->
  <div *ngIf="hasActiveOrders" class="failed-box p-d-flex p-flex-column p-jc-center p-my-2">
    <!--text info content-->
    <div class="p-d-flex p-mb-2">
      <i class="fa-solid fa-warning p-mr-4"></i>
      <div class="p-text-center" i18n>You need to <b>resolve all your active (not performed) orders</b> before taking
        this
        action.<br>
        <b>Click on the button</b> and resolve it.
      </div>
    </div>
    <!--button navigating to profile booking manager-->
    <app-button type="secondary"
                icon="fa-solid fa-arrow-right"
                label="Manage orders"
                i18n-label
                [url]="navigation.urlProfileBookings(data.charId, OrderManagerListTypeEnum.PENDING)"></app-button>
  </div>

  <!-- Password verification -->
  <div *ngIf="!userService.user.value?.authType"> <!--  when firebase, registered through google -->
    <p class="p-text-bold p-mt-4 p-mb-2" i18n>
      We need to verify your identity. Please confirm your password.</p>

    <form [formGroup]="profileStatusForm">
      <!--PASSWORD-->
      <app-text-input label="Your password"
                      i18n-label
                      placeholder="Password"
                      i18n-placeholder
                      icon="fa-solid fa-key"
                      inputType="password"
                      formFieldName="password"
                      [space]="false"
                      [paste]="false"
                      [copy]="false"
                      [form]="profileStatusForm"
                      [required]="true"
                      [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_PASSWORD_INCORRECT" i18n>
          Incorrect password.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.USER_WRONG_CREDENTIALS" i18n>
          Not a valid password.
        </app-backend-validation>
      </app-text-input>

      <!--Forgot my password-->
      <div class="p-d-flex p-jc-end">
        <a class="p-text-bold"
           [routerLink]="navigation.urlPasswordRestore()"
           i18n>I forgot my password.</a>
      </div>

      <!--Check password button-->
      <div class="p-d-flex p-jc-center">
        <app-button *ngIf="!passCheckedSuccessfully" @grow
                    type="save"
                    label="Check"
                    i18n-label
                    styleClass="p-mt-2"
                    [loading]="saving && !passCheckedSuccessfully"
                    [disabled]="!profileStatusForm.valid || saving || loading || hasActiveOrders"
                    (clicked)="onSubmitPassword()"></app-button>
      </div>
    </form>
  </div>
  <!-- The question for reassurance if the user really want deactivate or delete his profile-->
  <div
    *ngIf="((noServerMessages() && passCheckedSuccessfully) || (!!userService.user.value?.authType)) && clickedType !== ProfileStatus.ACTIVE"
    class="failed-box p-d-flex p-flex-column p-jc-center p-ai-center p-mt-2">
    <h3 *ngIf="clickedType === ProfileStatus.DELETED" class="p-text-center p-text-normal" i18n>
      Are you sure you want to delete your <span class="change-color">{{data.charId}}</span> profile?</h3>
    <h3 *ngIf="clickedType !== ProfileStatus.DELETED" class="p-text-center p-text-normal" i18n>
      Are you sure you want to deactivate your <span class="change-color">{{data.charId}}</span> profile?</h3>

    <!--danger button-->
    <app-button type="danger"
                [icon]="clickedType === ProfileStatus.DELETED ? 'fa-solid fa-user-xmark' : 'fa-solid fa-user-lock'"
                styleClass="p-mt-2"
                [label]="clickedType === ProfileStatus.DELETED ? trans.delete : trans.deactivate"
                [loading]="loading"
                (clicked)="updateStatus(clickedType)"></app-button>

  </div>

  <!-- Activate button ONLY when user registered through firebase, google -->
  <div *ngIf="!!userService.user.value?.authType && clickedType === ProfileStatus.ACTIVE" class="p-d-flex p-jc-center">
    <app-button type="primary"
                icon="fa-solid fa-user-check"
                styleClass="p-mt-2"
                label="Activate"
                i18n-label
                [loading]="loading"
                (clicked)="updateStatus(clickedType)"></app-button>
  </div>

  <!--BE check-->
  <app-backend-validation [showAsBox]="true" [serverMessages]="serverMessages"
                          [check]="ServerMessage.PROFILE_HAS_ACTIVE_ORDER" i18n>
    You cannot change your status if you have active, undelivered orders.
  </app-backend-validation>
</app-dialog>

<!--Deleting consequences content-->
<ng-template #deletingTemplate>
  <h4 i18n>Deleting your account will do the following: </h4>
  <ul>
    <li i18n>You will <b>permanently lose</b> all of your data, settings, and profile account information.</li>
    <li i18n>
      With this profile account, you will <b>no longer have access</b> to the features and services provided by this
      platform.
    </li>
    <li i18n>This action <b>cannot be restored</b>.</li>
  </ul>
</ng-template>

<!--Deactivating consequences content-->
<ng-template #deactivatingTemplate>
  <h4 i18n>Deactivating your account will do the following: </h4>
  <ul>
    <li i18n><b>Temporarily disabling</b> the <b>{{data.charId}}</b> profile account.</li>
    <li i18n>Deactivated profile account is <b>not visible to other users</b> of the platform.</li>
    <li i18n>This action can be restored and you <b>can reactivate</b> your profile account <b>whenever you want</b>.
    </li>
  </ul>
</ng-template>

<!--Activating content-->
<ng-template #activatingTemplate>
  <div class="p-d-flex p-flex-column p-jc-center p-ai-center p-my-2">
    <i class="info-icon fa-solid fa-user-check"></i>
    <h3 class="p-text-center p-mt-4" i18n>You are going to reactivate your <span
      class="change-color">{{data.charId}}</span> profile account.</h3>
  </div>
  <p class="p-text-center" i18n>We are glad that you have decided to come back to this platform again. </p>
</ng-template>
