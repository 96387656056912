import {Component, OnDestroy, OnInit} from '@angular/core'
import {fadeAnimation} from '../../animation/fade.animation'
import {Subscription} from 'rxjs'
import {Router} from '@angular/router'
import {NavigationService} from '../../service/ui/navigation.service'
import {environment} from '../../../environments/environment'
import {PLATFORM_BROWSER} from '../../app.module'
import {SupportContact} from '../support/support.component'
import {UserService} from '../../service/user.service'

@Component({
  animations: [fadeAnimation(300)],
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit, OnDestroy {

  /**
   * Determines whether the splash screen is visible.
   */
  visible: boolean = environment.splashscreen
  /**
   * The current date.
   */
  currentDate = new Date()
  /**
   * Support contact info
   */
  contactInfo: SupportContact = environment.contact
  /**
   * The current-profile changes subscription
   */
  private subs: Subscription[] = []

  constructor(
    private userService: UserService,
    private router: Router,
    private navigation: NavigationService) {
  }

  ngOnInit(): void {
    // Skip for server
    if (!PLATFORM_BROWSER) {
      return
    }

    if (this.visible) {
      window.scrollTo({top: 0})
      document.body.classList.add('splash-overflow')
    } else {
      document.body.classList.remove('splash-body')
      return
    }

    // Current profile change
    this.subs.push(this.userService.user.subscribe(user => {
      if (user !== undefined) {
        this.hide()
      }
    }))

    // Router changes (when no logged user)
    this.subs.push(this.router.events.subscribe((url) => {
      if (url['url'] === `/${this.navigation.urlPathLogin(false)}`) {
        this.hide()
      }
    }))
  }

  /**
   * Hides the splash screen.
   */
  hide(): void {
    setTimeout(() => {
      if (this.visible && PLATFORM_BROWSER) {
        document.body.classList.remove('splash-body')
        document.body.classList.remove('splash-overflow')
      }
      this.visible = false
      this.unsubscribeAll()
    }, 100)
  }

  /**
   * Unsubscribes all subscribers.
   */
  private unsubscribeAll(): void {
    this.subs.forEach(it => it?.unsubscribe())
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }
}
