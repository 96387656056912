import {AbstractComponent} from '../../abstract/abstract.component'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef
} from '@angular/core'
import {PrimeTemplate} from 'primeng/api'
import {OverlayPanel} from 'primeng/overlaypanel'
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {ScreenSize, toPixels} from '../../../utils/device.utils'
import {scrollToIndex} from 'src/app/utils/scroll.utils'
import {PLATFORM_BROWSER} from '../../../app.module'

@Directive()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class AbstractList<E> extends AbstractComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  /**
   * - Enables the Angular virtual items feature.
   * - ## Do not disable this for items with heavy nested components. It may start lagging.
   */
  @Input()
  virtual = true

  /**
   * The scroll height of the result.
   */
  @Input()
  scrollHeight: string
  /**
   * The scroll height of the container.
   */
  scrollHeightContainer: string
  /**
   * Defines the max item height in pixels.
   */
  @Input()
  itemHeight: number
  /**
   * Defines the max allowed scroll height in pixels.
   */
  @Input()
  maxScrollHeight: number
  /**
   * Displays the {@link items} in the overlay panel only if the screen size is LG.
   */
  @Input()
  overlay: boolean

  /**
   * - Sets the base z index to the overlay panel.
   * - The default is 1120
   * - Needs to be the {@link overlay} activated.
   */
  @Input()
  overlayZIndex = 1120

  /**
   * Target element to attach the panel.
   */
  @Input()
  overlayAppendTo: any = 'body'
  /**
   * - Determines whether the {@link overlay} will be visible even there are no {@link items}.
   * - The overlay gets visible only when the initial load (page 0) of {@link items} has been loaded (nextPage > 0).
   * - To style the no-content, use the ng-template of 'noContent'.
   */
  @Input()
  overlayEmptyVisible: boolean
  /**
   * Defines the style class of the overlay component.
   */
  @Input()
  overlayClass: string
  /**
   * The unique css class of one item in the scroller viewport.
   * By this class it is known to what index the scrollbar should be scrolled.
   */
  @Input()
  itemClass: string
  /**
   * Subscribes for scroll changes.
   */
  @Input()
  enableScroll = true
  /**
   * Disables the {@link topShadow} and {@link bottomShadow}.
   */
  @Input()
  shadows = true
  /**
   * The {@link enableScroll} will be set to true only when the {@link lastElement} gets fully visible.
   */
  @Input()
  scrollAfterFullVisible = true
  /**
   * A last element that needs to be visible to enable the list scrolling.
   */
  lastElement: HTMLDivElement
  /**
   * Controls the visibility of the top shadow.
   */
  topShadow: boolean
  /**
   * Controls the visibility of the bottom shadow.
   */
  bottomShadow: boolean
  /**
   * The main content template of the scroller.
   */
  itemTemplate: TemplateRef<any>
  /**
   * The no-content template.
   */
  noContentTemplate: TemplateRef<any>
  /**
   * A current running interval for updating shadows of the list.
   */
  private updateShadowsInterval: any

  protected constructor(protected changeRef: ChangeDetectorRef) {
    super()
  }

  /**
   * Returns the length of items.
   */
  abstract getItemsLength(): number

  /**
   * Returns the {@link CdkVirtualScrollViewport}.
   */
  abstract getViewport(): CdkVirtualScrollViewport

  /**
   * Returns the {@link OverlayPanel}.
   */
  abstract getOverlayPanel(): OverlayPanel

  /**
   * Returns the custom scroller when the {@link virtual} is disabled.
   */
  abstract getCustomScroller(): ElementRef<HTMLDivElement>

  /**
   * Returns all available templates.
   */
  abstract getTemplates(): QueryList<PrimeTemplate>

  ngOnInit(): void {
    this.checkInputs()
    this.enableShadowInterval(true)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxScrollHeight || changes.itemHeight) {
      this.checkInputs()
      this.calcScrollHeight()
    }

    if (changes.enableScroll) {
      this.enableScrolling()
    }

    if (changes.shadows) {
      this.applyShadows(true)
    }

    // scrollHeight cannot exceed the window viewport (due to locking on small devices on a list)
    if (changes.scrollHeight?.currentValue && PLATFORM_BROWSER) {
      if (toPixels(this.scrollHeight) > window.innerHeight) {
        this.scrollHeight = `${window.innerHeight}px`
      }
    }
  }

  ngAfterViewInit(): void {
    this.itemTemplate = this.getTemplates()?.find(it => it.name === 'item')?.template
    this.noContentTemplate = this.getTemplates()?.find(it => it.name === 'noContent')?.template
    this.changeRef.detectChanges()
  }

  /**
   * Calculates the {@link scrollHeight} based on available {@link items}
   * and applies shadow elements.
   */
  applyChanges(): void {
    this.enableScrolling()
    this.calcScrollHeight()
    this.applyShadows()
    this.changeRef.detectChanges()
    this.onWindowScroll()
  }

  /**
   * - Calculates the {@link scrollHeight} dynamically based on the {@link itemHeight}
   * and number of available {@link items} and {@link maxScrollHeight} limit.
   * - Updates the {@link scrollHeight} value after the process of calculation.
   */
  calcScrollHeight(): void {
    if (!PLATFORM_BROWSER) {
      return
    }
    this.checkInputs()

    const containerMargin = 2.875 * 16
    if (this.itemHeight && this.maxScrollHeight) {
      const length = this.getItemsLength() || 1 // (at least one, while loading)
      const newHeight = (length + 1) * this.itemHeight // minus top + bottom shadow object
      const height = ((newHeight <= this.maxScrollHeight) ? newHeight : this.maxScrollHeight) || 0
      const sum = height + containerMargin
      // cannot exceed the window viewport
      this.scrollHeightContainer = (((sum) > window.innerHeight) ? window.innerHeight : sum) + 'px'
      this.scrollHeight = height + 'px'
    } else if (this.scrollHeight) {
      const sum = (toPixels(this.scrollHeight) + containerMargin)
      this.scrollHeightContainer = (((sum) > window.innerHeight) ? window.innerHeight : sum) + 'px'
    }
  }

  /**
   * Applies {@link topShadow} or {@link bottomShadow} if the {@link shadows} are enabled.
   */
  applyShadows(detectChanges: boolean = false): void {
    // return if disabled
    if (!this.shadows) {
      return
    }

    // Dynamic item height
    if (!this.virtual) {
      const e = this.getCustomScroller()?.nativeElement
      if (e) {
        this.topShadow = e.scrollTop !== 0
        this.bottomShadow = Math.abs(e.scrollHeight - e.scrollTop - e.clientHeight) >= 1
      }

    } else if (this.getViewport()) { // fixed size
      this.topShadow = this.measureScrollOffset('top') > 0
      this.bottomShadow = this.measureScrollOffset('bottom') > 0
      if (detectChanges) {
        this.changeRef.detectChanges()
      }
    }
  }

  /**
   * Measures the scroll offset of the view.
   * @param from Measuring from a specific side.
   */
  measureScrollOffset(from: 'top' | 'bottom'): number {
    if (!this.virtual) {
      const el = this.getCustomScroller()?.nativeElement
      if (el) {
        switch (from) {
          case 'top':
            return el.scrollTop
          case 'bottom':
            const rest = el.scrollHeight - el.scrollTop
            return Math.abs(el.clientHeight - rest)
        }
      }
      return 0
    }

    // Fixed
    return this.getViewport()?.measureScrollOffset(from)
  }

  /**
   * Scrolls to the specific index of founded elements by the {@link itemClass}.
   *
   * @param index The specific index of the item.
   * @param type What type of scrolling should be performed.
   */
  scrollToIndex(index: number, type: ScrollBehavior = 'auto'): void {
    scrollToIndex(this.itemClass, index, type)
  }

  /**
   * Scrolls to the first element found by the {@link itemClass}.
   *
   * @param type Type of scrolling.
   */
  scrollTop(type: ScrollBehavior = 'auto'): void {
    this.scrollToIndex(0, type)
  }

  /**
   * Scrolls to the last element.
   *
   * @param type Type of scrolling.
   */
  scrollBottom(type: ScrollBehavior = 'auto'): void {
    setTimeout(() => {
      if (this.virtual) {
        this.getViewport()?.scrollToIndex(this.getItemsLength() - 1, type)
        return
      }

      // dynamic item height
      const el = this.getCustomScroller()?.nativeElement
      el?.scroll({
        top: el.scrollHeight,
        behavior: 'smooth'
      })
    })
  }

  /**
   * Returns true if the scrollbar offset from the bottom is lower than or the same as the given offset.
   */
  isBottomScrolled(bottomOffset: number = 0): boolean {
    return this.measureScrollOffset('bottom') <= bottomOffset
  }

  /**
   * Returns true if the scrollbar offset from the top is lower than or the same as the given offset.
   */
  isTopScrolled(topOffset: number = 0): boolean {
    return this.measureScrollOffset('top') <= topOffset
  }

  /**
   * Controls the visibility of the {@link overlayPanel}.
   *
   * @param visible Sets the visibility
   * @param openEvent Needs a specific event to know exactly where the panel should be open.
   */
  setOverlayPanelVisible(visible: boolean, openEvent?: any): void {
    if (visible) {
      this.getOverlayPanel()?.show(openEvent)
    } else {
      this.getOverlayPanel()?.hide()
    }
  }

  /**
   * Dynamically enables the scrolling when the {@link enableScroll} is enabled.
   */
  protected enableScrolling(): void {
    if (this.virtual && this.getViewport()) {
      if (this.enableScroll) {
        this.getViewport().getElementRef().nativeElement.classList.remove('disable-scrolling')
      } else {
        this.getViewport().getElementRef().nativeElement.classList.add('disable-scrolling')
      }
    } else if (!this.virtual && this.getCustomScroller()) {
      if (this.enableScroll) {
        this.getCustomScroller().nativeElement.classList.remove('disable-scrolling')
      } else {
        this.getCustomScroller().nativeElement.classList.add('disable-scrolling')
      }
    }
  }

  /**
   * Checks inputs validity and throws occasional errors.
   */
  protected checkInputs(): void {
    if (!this.virtual) {
      if ((this.maxScrollHeight || this.itemHeight) && (!this.maxScrollHeight || !this.itemHeight)) {
        throw new Error(
          'The [maxScrollHeight] must be initialized with the [itemHeight] to provide the auto-height feature in <app-lazy-list>')
      }
    } else if (!this.itemHeight) {
      throw new Error(
        'The [itemHeight] must be initialized to provide the virtual-scrolling feature in <app-lazy-list>')
    }
  }

  /**
   * - Enables the scrolling when the {@link lastElement} is fully visible.
   * - This feature can be turned off by setting the {@link scrollAfterFullVisible} to false.
   */
  @HostListener('window:scroll')
  private onWindowScroll(): void {
    if (this.overlay || !PLATFORM_BROWSER) {
      return
    }

    const isDesktop = this.isScreenOf(ScreenSize.XXL)
    const prev = this.enableScroll

    if (this.scrollAfterFullVisible && !isDesktop) {
      const divRect = this.lastElement?.getBoundingClientRect()
      if (!divRect) {
        return
      }
      this.enableScroll =
        divRect.top >= 0 &&
        divRect.left >= 0 &&
        (divRect.bottom - 50) <= window.innerHeight &&
        divRect.right <= window.innerWidth

      // On desktop turn always enabled
    } else if (isDesktop && this.scrollAfterFullVisible) {
      this.enableScroll = true
    }

    // apply changes only when the state has changed
    if (prev !== this.enableScroll) {
      this.enableScrolling()
    }
  }

  /**
   * It triggers shadows iteratively to ensure the correct visibility.
   */
  private enableShadowInterval(enable: boolean): void {
    if (!PLATFORM_BROWSER) {
      return
    }
    if (enable) {
      this.enableShadowInterval(false)
      this.updateShadowsInterval = setInterval(() => {
        this.applyShadows(false)
      }, 750)
    } else {
      clearInterval(this.updateShadowsInterval)
    }
  }

  ngOnDestroy(): void {
    this.enableShadowInterval(false)
  }
}
