import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'
import {NavigationService as NS} from './service/ui/navigation.service'
import {adminGuard, authGuard} from './utils/router.utils'
import {ProfileCatalogComponent} from './component/order/profile-catalog/profile-catalog.component'
import {ProfileComponent} from './component/profile/profile.component'
import {SupportComponent} from './component/support/support.component'
import {NotificationInboxComponent} from './component/notification-inbox/notification-inbox.component'

@NgModule({
  imports: [RouterModule.forRoot([
    {path: '', redirectTo: NS.PROFILE_CATALOG, pathMatch: 'full'},
    {
      path: NS.ADMIN,
      loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      canActivate: [adminGuard]
    },
    {
      path: NS.AUTHENTICATION,
      loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
      path: NS.LOGIN,
      redirectTo: NS.AUTHENTICATION,
      pathMatch: 'full'
    },
    {
      path: NS.PASSWORD_RESTORE,
      loadComponent: () => import('./modules/authentication/password-restore/password-restore.component')
        .then((c) => c.PasswordRestoreComponent)
    },
    {
      path: NS.USER_SETTINGS,
      loadChildren: () => import('./modules/user-settings/user-settings.module').then(m => m.UserSettingsModule),
      canActivate: [authGuard]
    },
    {
      path: NS.DASHBOARD_PROFILES,
      loadComponent: () => import('./component/dashboard/profile/profiles-dashboard/profiles-dashboard.component')
        .then((c) => c.ProfilesDashboardComponent),
      canActivate: [authGuard]
    },
    {path: NS.PROFILE_CATALOG, component: ProfileCatalogComponent},
    {
      path: `${NS.PROFILE_SERVICES_EDITOR}`,
      loadComponent:
        () => import('./component/profile/profile-services-editor/profile-services-editor.component')
          .then((c) => c.ProfileServicesEditorComponent),
      canActivate: [authGuard]
    },
    {
      path: `${NS.PROFILE_SERVICES_EDITOR}/:${NS.PROFILE_SERVICES_EDITOR_CATEGORY}`,
      loadComponent:
        () => import('./component/profile/profile-services-editor/profile-services-editor.component')
          .then((c) => c.ProfileServicesEditorComponent),
      canActivate: [authGuard]
    },
    {
      path: `${NS.PROFILE}/:${NS.PROFILE_CHAR_ID_PARAMETER}`,
      component: ProfileComponent
    },
    {
      path: `${NS.PROFILE}/:${NS.PROFILE_CHAR_ID_PARAMETER}/:${NS.PROFILE_ACTION_PARAMETER}`,
      component: ProfileComponent
    },
    {
      path: `${NS.PROFILE_BOOKINGS}`,
      loadChildren: () => import('./modules/order-manager/order-manager.module').then(m => m.OrderManagerModule),
      canActivate: [authGuard]
    },
    {path: NS.SUPPORT, component: SupportComponent},
    {path: `${NS.SUPPORT}/:${NS.SECTION}`, component: SupportComponent},
    {path: NS.NOTIFICATIONS, component: NotificationInboxComponent},
    {
      path: '**', loadComponent: () => import('./component/errors/page-not-found/page-not-found.component')
        .then((c) => c.PageNotFoundComponent)
    }
  ], {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
