import {dateCut} from './date.utils'

/**
 * Returns true when the objects contain the same values.
 */
function objectEquals<T>(obj1: T, obj2: T): boolean {
  if (!obj1 || !obj2) {
    return obj1 === obj2
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const value1 = obj1[key]
    const value2 = obj2[key]
    if (!valueEquals(value1, value2)) {
      return false
    }
  }
  return true
}

/**
 * - Returns true when the both arrays contains the same values.
 * - Both arrays must be identical - element position sensitive.
 */
function arrayEquals<T>(arr1: T[], arr2: T[]): boolean {
  if (!arr1 || !arr2) {
    return arr1 === arr2
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    const value1 = arr1[i]
    const value2 = arr2[i]
    if (!valueEquals(value1, value2)) {
      return false
    }
  }
  return true
}

/**
 * Returns true when the both values are the same.
 */
export function valueEquals<T>(value1: T, value2: T): boolean {
  if (!value1 || !value2) {
    return value1 === value2
  }

  // Nested Dates without seconds
  if (value1 instanceof Date && value2 instanceof Date) {
    if (dateCut(value1).getTime() !== dateCut(value2).getTime()) {
      return false
    }

    // Nested Arrays
  } else if (Array.isArray(value1) && Array.isArray(value2)) {
    if (!arrayEquals(value1, value2)) {
      return false
    }

    // Nested objects
  } else if (typeof value1 === 'object' && typeof value2 === 'object') {
    if (!objectEquals(value1, value2)) {
      return false
    }
    // Just the value comparison
  } else if (value1 !== value2) {
    return false
  }

  return true
}
