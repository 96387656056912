import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {ProfileResp} from '../../../service/profile.service'
import {EditableComponent} from '../../abstract/editable.component'
import {ImageShowcaseResp, ImageShowcaseService} from '../../../service/image-showcase.service'
import {firstValueFrom, Subscription} from 'rxjs'
import {PLATFORM_BROWSER} from '../../../app.module'
import {getDeviceMatchWidthWallpaper} from '../../../utils/device.utils'
import {NavbarService} from '../../../service/ui/navbar.service'
import {CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'

@Component({
  selector: 'app-profile-image-showcase',
  templateUrl: './profile-image-showcase.component.html',
  styleUrl: './profile-image-showcase.component.scss'
})
export class ProfileImageShowcaseComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * The current previewing profile.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * The current carousel page index on small devices.
   */
  currentCarouselPage = 0
  /**
   * Shows a fullscreen galleria component.
   */
  fullscreenGalleriaVisible: boolean
  /**
   * Shows a fullscreen images preview component.
   */
  imagesPreviewVisible: boolean
  /**
   * A current galleria index.
   */
  currentGalleriaIndex = 0
  /**
   * All available images.
   */
  images: ImageShowcaseResp[] = []
  /**
   * A subscription for the {@link ProfileCompletionService} show requests.
   */
  private showSub?: Subscription

  constructor(
    private completionService: ProfileCompletionService,
    private imageShowcaseService: ImageShowcaseService,
    public navbarService: NavbarService) {
    super()
  }

  ngOnInit(): void {
    // completion show observer
    this.showSub = this.completionService.completionTypeShow.subscribe((type) => {
      if (type === CompletionType.IMAGE_SHOWCASE) {
        this.editComponentVisible = true
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile?.currentValue) {
      this.currentCarouselPage = 0
      this.currentGalleriaIndex = 0
      this.images = []
      this.loadImages()
    }
  }

  /**
   * Fies when the images have been changed from the edit component.
   */
  onImagesChanged(images: ImageShowcaseResp[]): void {
    this.processImages(images)
    this.images = images
  }

  /**
   * Loads images from the server.
   */
  private loadImages(): void {
    this.call(async () => {
      const images = await firstValueFrom(this.unwrap(this.imageShowcaseService.callGetAllImages(this.profile.profileId)))
      if (images && this.noServerMessages()) {
        this.processImages(images)
      }
    })
  }

  /**
   * Process images response from the server. Basically, it adds proper image variant to url links.
   */
  private processImages(images: ImageShowcaseResp[]): void {
    if (PLATFORM_BROWSER) {
      this.profile.hasImageShowcase = true
      const deviceVariant = getDeviceMatchWidthWallpaper()
      images.forEach(img => {
        img.url = `${img.url}/${deviceVariant}`
      })
      this.images = images
    }
  }

  /**
   * Opens the galleria component on a specific {@link index}.
   */
  openGalleria(index: number = 0): void {
    this.currentGalleriaIndex = index
    this.fullscreenGalleriaVisible = true
    this.navbarService.enable(false)
  }

  /**
   * Fires when the {@link fullscreenGalleriaVisible} property gets changed.
   */
  onGalleriaVisibilityChange(visible: boolean): void {
    if (this.imagesPreviewVisible) {
      return
    }
    this.navbarService.enable(!visible)
  }

  /**
   * Fires when the {@link imagesPreviewVisible} property gets changed.
   */
  onPreviewVisibilityChange(visible: boolean): void {
    if (this.fullscreenGalleriaVisible) {
      return
    }
    this.navbarService.enable(!visible)
  }

  /**
   * Opens images preview component.
   */
  openMoreImages(): void {
    this.imagesPreviewVisible = true
    this.navbarService.enable(false)
  }

  ngOnDestroy(): void {
    this.showSub?.unsubscribe()
  }
}
