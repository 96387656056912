<div class="wrapper p-d-flex p-jc-center p-relative"
     [class.map-body-height]="!singleMarker && !newMarker && !radiusMarker">

  <!--Container-->
  <div class="map-container">

    <div class="location-status p-d-flex p-jc-center"
         *ngIf="watchUserLocation && !currentUserLocation && geolocationGranted">
      <!--Location status-->
      <div class="rounded-small p-py-1 p-px-2 p-d-flex p-jc-center">
        <!--Loading-->
        <div class="p-d-flex p-ai-center">
          <i class="fa-solid fa-spin fa-circle-notch p-mr-2"></i>
          Loading location ...
        </div>
      </div>
    </div>

    <!--Map controls-->
    <div class="map-controls" [class.map-controls-fullscreen]="fullscreen">
      <!--Search Floating Button-->
      <div *ngIf="floatingSearchButtonVisible && explore"
           @fade
           class="p-d-flex p-ai-center p-jc-center p-mt-2 explore"
           (click)="onSearchClicked()"
           [class.c-pointer]="!searching">

        <div class="p-d-flex p-ai-center p-jc-center p-px-3 p-py-2 p-p-md-3 box-shadow rounded-medium">
          <div class="p-d-flex p-ai-center p-jc-center">
            <i class="p-mr-2"
               [class]="searching ? 'fa-solid fa-circle-notch fa-spin' : 'fa-solid fa-magnifying-glass'"></i>
            <div class="text" i18n>Explore</div>
          </div>
        </div>
      </div>

      <!--User location-->
      <div class="map-user rounded-medium box-shadow" *ngIf="userCenterButton && currentUserLocation" @fade>
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             pTooltip="Center to your location" tooltipPosition="right"
             (click)="onUserCenter()">
          <i class="fa-solid fa-user"></i>
        </div>
      </div>

      <!--Map Filter icon-->
      <div class="map-filter rounded-medium box-shadow" *ngIf="filterButton">
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             pTooltip="Filter" i18n-pTooltip tooltipPosition="right"
             (click)="showFiltersDialog()">
          <i class="fa-solid fa-filter"></i>
        </div>
      </div>

      <!--Map Reset icon-->
      <div class="map-undo rounded-medium box-shadow" *ngIf="resetButton" @fade>
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             pTooltip="Reset" i18n-pTooltip tooltipPosition="left"
             (click)="resetClicked.emit($event)">
          <i class="fa-solid fa-arrow-rotate-left"></i>
        </div>
      </div>

      <!--Map Center icon-->
      <div class="map-center rounded-medium box-shadow">
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             pTooltip="Center" i18n-pTooltip tooltipPosition="left"
             (click)="onMapCenter()">
          <i class="fa-solid fa-location-crosshairs"></i>
        </div>
      </div>

      <!--Map Search icon-->
      <div class="map-search rounded-medium box-shadow" *ngIf="search">
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             pTooltip="Search" i18n-pTooltip tooltipPosition="left"
             (click)="searchFieldVisible = !searchFieldVisible">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>

      <!--Search input-->
      <div class="map-search-input rounded-medium box-shadow">
        <app-map-search *ngIf="!isSmallScreen && search"
                        [show]="!isSmallScreen && searchFieldVisible"
                        (showChange)="searchFieldVisible = $event"
                        [@fade]="searchFieldVisible"
                        resultStyleClass="p-p-3"
                        [autofocus]="true"
                        (profileOption)="onSearchProfileSelected($event)"
                        (addressOption)="onSearchAddressSelected($event)"
        ></app-map-search>
      </div>

      <!--Fullscreen-->
      <div class="map-fullscreen rounded-medium box-shadow" *ngIf="search">
        <div class="p-p-2 p-d-flex p-ai-center p-jc-center c-pointer"
             [pTooltip]="fullscreen ? 'Min' : 'Max'" tooltipPosition="left"
             (click)="onFullscreen()">
          <i class="fa-solid"
             [class.fa-up-right-and-down-left-from-center]="!fullscreen"
             [class.fa-down-left-and-up-right-to-center]="fullscreen"></i>
        </div>
      </div>
    </div>

    <!--Map-->
    <div id="map" leaflet
         [class]="styleClass"
         [class.fullscreen]="fullscreen"
         [leafletOptions]="mapOptions"
         (leafletMapReady)="onMapReady($event)"
         (leafletClick)="onMapClicked($event);"
         (leafletMapMoveEnd)="onMapMoveEnd($event)"
         (leafletMapZoomEnd)="onMapZoomEnd($event)">
    </div>
  </div>
</div>

<!--Map search dialog on small devices-->
<app-map-search-dialog *ngIf="isSmallScreen && searchFieldVisible"
                       [(show)]="searchFieldVisible"
                       (profile)="onSearchProfileSelected($event)"
                       (address)="onSearchAddressSelected($event)"
></app-map-search-dialog>

<!--Map filters dialog-->
<app-map-filter [(show)]="filterDialogVisible"
                (showChange)="fullscreen = false"
                [(filter)]="filterReq"
                (filterChange)="onSearchClicked()"></app-map-filter>
