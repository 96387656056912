import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core'
import {fadeAnimation} from '../../../animation/fade.animation'
import {EditableComponent} from '../../abstract/editable.component'
import {firstValueFrom, Subscription} from 'rxjs'
import {NgIf, NgTemplateOutlet} from '@angular/common'
import {throwInputNotInited} from '../../../utils/log.utils'
import {NavbarService} from '../../../service/ui/navbar.service'
import {AbstractComponent} from '../../abstract/abstract.component'
import {PrimeTemplate} from 'primeng/api'
import {scrollToIndex} from '../../../utils/scroll.utils'
import {environment} from '../../../../environments/environment'
import {PLATFORM_BROWSER} from '../../../app.module'
import {DialogModule} from 'primeng/dialog'
import {ButtonComponent} from '../button/button.component'
import {UnsavedChangesComponent} from '../unsaved-changes/unsaved-changes.component'
import {CallResponseComponent} from '../call-response/call-response.component'
import {ActivatedRoute, Router} from '@angular/router'
import {SupportContact} from '../../support/support.component'

/**
 * - This is the base app dialog.
 * - It contains the predefined gateway that allows users perform API requests.
 * ## Very important:
 * 1. The component where you are going to use the DialogComponent needs to be extended from the {@link EditableComponent}
 * to make successful API requests.
 * 2. The app-dialog needs to have initialized the 'component' input in case of a successful communication between the child component.
 * 3. Example: <pre>
 *   <app-dialog [component]="this" ...>
 * </pre>
 * 4. Don't forget to use always double binding show property with the \*ngIf directive.
 * The dialog should be able to close itself from the component.
 * The \*ngIf directive ensures that all the previous state will be removed and everything will be initialized from scratch.<pre>
 *   <app-dialog *ngIf="show" [(show)]="show" ...>
 * </pre>
 *
 * ## How to close this dialog
 * - The dialog is closed when the {@link component}'s {@link EditableComponent.responseSuccess} is true.
 * - You can also close this dialog manually by setting the {@link show} property to false.
 */
@Component({
  animations: [fadeAnimation(200)],
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [
    DialogModule,
    NgIf,
    ButtonComponent,
    NgTemplateOutlet,
    UnsavedChangesComponent,
    CallResponseComponent
  ],
  standalone: true
})
export class DialogComponent extends AbstractComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  /**
   * It takes 150ms to close visually the PrimeNG p-dialog component.
   */
  public static readonly DIALOG_CLOSE_DURATION = 150
  /**
   * It takes 1s to display the preview result message.
   */
  public static readonly DIALOG_STATUS_DURATION = 1000
  /**
   * - This selector is applied to the {@link component}.
   * - This property value represents how many dialogs are waiting under the highest visible one.
   */
  private static readonly holdQueueSelector = 'holdQueue'

  /**
   * - Controls the visibility of the entire dialog.
   * - The real visibility controls the {@link dialogVisible}, that gets initialized with this property.
   */
  @Input()
  show: boolean

  /**
   * Sets the save button to the loading state.
   */
  @Input()
  saveLoading: boolean

  /**
   * - Changes the visibility of the entire dialog.
   * - Do not emit this property directly because of the animation! Use the {@link closeDialog} function instead!
   */
  @Output()
  showChange = new EventEmitter<boolean>()

  /**
   * Decides whether to show {@link CallResponseComponent} after saving.
   */
  @Input()
  showResponse = true

  /**
   *  Controls the real visibility of the PrimeNG dialog.
   */
  dialogVisible: boolean
  /**
   * Optional style classes for the PrimeNG component.
   */
  @Input()
  styleClass: string
  /**
   * - Set a help URL link to https://docs.umevia.com tutorial.
   * - Displays a help button next to the dialog header.
   */
  @Input()
  helpLink?: string
  /**
   * - Needs to be specified to allow users correctly redirect to the previous page.
   * - Temporarily appends this url to the current one. If a user clicks backward, the URL will be erased to the original one.
   * - (Example: `url="edit-info" // will add the url/edit-info`.
   * - The {@link type} value is applied to this input by default.
   */
  @Input()
  url: string

  /**
   * - In certain scenarios, there is no need to modify URL.
   * - ### Do not disable this functionality without any special reason. It provides convenient UX flow.
   */
  @Input()
  modifyUrl = true

  /**
   * - Traps this dialog - If a user wants to discard, close tab, refresh, or leave, the 'UnsavedChanges' dialog will be raised.
   * - You can use this value like: `[trap]="formHasChanged"` - so it will prevent the dialog from closing when the form has
   * changed.
   */
  @Input()
  trap: boolean

  /**
   * - Holds this dialog from any action because other dialog overlays this one.
   * - Use when this dialog needs to wait for a close of the dialog that overlays this one.
   * - Eexample: `[hold]="dialogConfirmVisible || dialogDeleteVisible"`
   */
  @Input()
  hold: boolean

  /**
   * Title header of the dialog.
   */
  @Input()
  header: string

  /**
   * Enables the back arrow near the {@link header} on small devices.
   */
  @Input()
  enableBackArrow = true

  /**
   * If a user needs to disable save option due to incorrectness of some dialog's content values.
   */
  @Input()
  disableSaveOption: boolean
  /**
   * Shows a customer support phone contact button.
   */
  @Input()
  showContactButton: boolean

  /**
   * - This save function will be called when the user clicked on the 'Save' option.
   * - It is called right when everything is set up correctly. See {@link onSave}.
   */
  @Input()
  save: () => Promise<void>
  /**
   * Emits any API error caused inside the {@link save} function.
   */
  @Output()
  err = new EventEmitter<any>()

  /**
   * - Emits when the dialog was successfully saved and hidden.
   * ## Use `[save]` when you need to provide saving function when the user clicked on the save button.
   */
  @Output()
  saved = new EventEmitter<any>()

  /**
   * Emits when a user has clicked on the Discard option.
   */
  @Output()
  discard = new EventEmitter<any>()

  /**
   * Restyles layout of the dialog by the given type. The 'danger' and 'save' options blocks the website reload.
   * - danger -> for delete / revoke / confirm dialogs
   * - save -> the default state. For API requests.
   * - info -> for regular (informative) dialog messages.
   */
  @Input()
  type: 'danger' | 'save' | 'info' = 'save'

  /**
   * Informs UI template that a user has clicked on the save option.
   */
  onSaveClicked: boolean

  /**
   * Represents a custom save button label. If no value specified the default value is used.
   */
  @Input()
  saveLabel?: string

  /**
   * Contains the default save label by the {@link type}.
   */
  defaultSaveLabel: string

  /**
   * Represents a custom save icon that appears near the save label. If no value specified the default value is used.
   */
  @Input()
  saveIcon?: string

  /**
   * Contains the default save icon by the {@link type}.
   */
  defaultSaveIcon: string

  /**
   * Represents a custom discard button label. If no specified the default value is used.
   */
  @Input()
  discardLabel?: string

  /**
   * Represents a custom discard icon that appears near the discard label. If no value specified then no icon is displayed.
   */
  @Input()
  discardIcon?: string

  /**
   * Contains the default discard label by the {@link type}.
   */
  defaultDiscardLabel: string

  /**
   * The base Z index of the dialog.
   * The default value is 10002
   */
  @Input()
  baseZIndex = 10002

  /**
   * Determines whether the save option is shown.
   */
  @Input()
  allowSaveOption = true

  /**
   * Determines whether the discard option is shown.
   */
  @Input()
  allowDiscardOption = true

  /**
   * The 'Discard' button will be disabled when the saving process started.
   */
  @Input()
  disableDiscardWhileSaving = true

  /**
   * Custom message that is displayed after the successful saving process.
   */
  @Input()
  successMessage?: string

  /**
   * Custom message that is displayed after the unsuccessful saving process.
   */
  @Input()
  errorMessage?: string

  /**
   * Determines whether the dialog should block the leave / back actions or not.
   * The default value is true. False can be used for dialogs with only informative content.
   */
  @Input()
  blockLeaveAction = true

  /**
   * The container element of the entire dialog content.
   */
  @ViewChild('container')
  contentContainer: ElementRef<HTMLDivElement>

  @ViewChild('scrollContent')
  scrollContent: ElementRef<HTMLDivElement>

  /**
   * Defines whether the 'UnsavedChanges' dialog is visible.
   */
  unsavedDialogVisible: boolean

  /**
   * - A reference to the component that uses the {@link DialogComponent}.
   * - **Needs to be always initialized when you want to perform API requests!**
   * - *Example:* <pre>
   *   <app-dialog [component]="this" ...>
   * </pre>
   */
  @Input()
  component: EditableComponent

  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>

  /**
   * The custom top action bar.
   */
  topBarTemplate: TemplateRef<any>
  /**
   * The custom bottom action bar.
   */
  bottomBarTemplate: TemplateRef<any>

  /**
   * Controls the visibility of the top bar shadow.
   */
  topShadow: boolean
  /**
   * Controls the visibility of the bottom bar shadow.
   */
  bottomShadow: boolean
  /**
   * Temporarily disables the {@link onBackButton} function.
   */
  disableBlockingEvents: boolean
  /**
   * Contains environment support contact information.
   */
  contact: SupportContact = environment.contact
  /**
   * Defines available translations for HTML.
   */
  protected trans = {
    success: $localize`Your profile has been saved.`,
    error: $localize`An unexpected error occurred.`
  }
  /**
   * Contains the subscription to the {@link component}.
   * Needs to be unsubscribed in the {@link ngOnDestroy} hook.
   */
  private componentSubscription: Subscription
  /**
   * Contains a state whether the url has been once changed.
   */
  private urlChanged: boolean
  /**
   * Determines whether this dialog has been finished successfully.
   */
  private finished: boolean
  /**
   * A current running interval for updating shadows of the dialog.
   */
  private updateShadowsInterval: any

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.throwErrors()
    this.initDefaultProperties()
    this.initHoldQueue()
    this.changeUrl()
    this.enableShadowInterval(true)
    this.navbarService.enable(false)
    this.dialogVisible = this.show
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Show dialog visually
    if (changes.show !== undefined) {
      if (!this.show) {
        this.closeDialog()
      } else {
        this.dialogVisible = true
      }
    }

    // Hold is true or changed from true to false
    const currentHold = changes.hold?.currentValue
    if (currentHold === true
      || (changes.hold?.previousValue === true && !currentHold)) {
      this.updateHoldQueue()
    }

    // Initializes default dialog properties by the given type
    if (changes.type?.currentValue) {
      this.initDefaultProperties()
      this.changeUrl()
    }

    // Component's API properties change
    if ((changes.component?.currentValue) && this.component) {
      // start observing for api value changes from the component
      this.componentSubscription = this.component.apiValueChanges.subscribe(() => {

        // if server error occurred
        if (this.component.responseError || this.component.responseFailed || this.component.disconnected) {
          this.onSaveClicked = false
        }

        // if the final operation successfully ends
        if (this.component.responseSuccess) {

          // show the status message
          setTimeout(() => {
            // reset component's API if the component was not a parent
            if (this.hold && this.component[DialogComponent.holdQueueSelector] > 0) {
              this.component.resetApi()
            } else {
              this.closeDialog(() => {
                this.finished = true
                this.saved.emit()
              })
            }
          }, DialogComponent.DIALOG_STATUS_DURATION)
        }
      })
    }
  }

  ngAfterViewInit(): void {
    this.scrollContent.nativeElement.addEventListener('scroll', this.onScrollContent.bind(this))
    this.topBarTemplate = this.templates?.find(it => it.name === 'topBar')?.template
    this.bottomBarTemplate = this.templates?.find(it => it.name === 'bottomBar')?.template
    this.changeRef.detectChanges()

    // Trigger initial shadows
    setTimeout(() => {
      this.onScrollContent()
    }, 500)
  }

  ngOnDestroy(): void {
    this.componentSubscription?.unsubscribe()
    if (PLATFORM_BROWSER) {
      // When cloning the element, the event listeners are not cloned.
      this.scrollContent.nativeElement = this.scrollContent.nativeElement.cloneNode(true) as HTMLDivElement
    }
    this.navbarService.enable(true)
    this.enableShadowInterval(false)
  }

  /**
   * - Fires when the content is scrolled.
   * - Used to apply visual shadows.
   */
  onScrollContent(detectChanges: boolean = true): void {
    if (this.scrollContent) {
      const e = this.scrollContent.nativeElement
      this.topShadow = e.scrollTop !== 0
      this.bottomShadow = Math.abs(e.scrollHeight - e.scrollTop - e.clientHeight) >= 1
    }
    if (detectChanges) {
      this.changeRef.detectChanges()
    }
  }

  /**
   * It triggers shadows iteratively to ensure the correct visibility.
   */
  enableShadowInterval(enable: boolean): void {
    if (enable) {
      this.enableShadowInterval(false)
      this.updateShadowsInterval = setInterval(() => {
        this.onScrollContent(false)
      }, 750)
    } else {
      clearInterval(this.updateShadowsInterval)
    }
  }

  /**
   * - Fires when a user has clicked on the save option.
   * - Refers the {@link save()} function to the {@link EditableComponent.callAndFinish} function.
   */
  async onSave(): Promise<void> {
    this.onSaveClicked = true
    await this.component.callAndFinish(this.save, (e) => {
      this.err.emit(e)
    })
  }

  /**
   * Fires when a user has clicked on the discard option.
   */
  onDiscard(): void {
    this.closeDialog(() => {
      this.discard.emit()
    })
  }

  /**
   * Call this function when you want to navigate to another route from the currently opened dialog.
   * - The {@link fun} should contain the navigation function.
   */
  closeAndNavigate(fun: () => void): void {
    this.closeDialog(fun)
  }

  /**
   * - Closes the dialog with the outgoing animation.
   * - Resolves a promise when the dialog is not visible.
   * - The optional {@link onClosed} function will be executed before this component gets destroyed. Null by default.
   * - The optional {@link eraseUrl} parameter specifies whether the url should be erased. True by default.
   */
  closeDialog(onClosed: () => void = null, eraseUrl: boolean = true): void {
    // close the dialog visually
    this.dialogVisible = false

    // emit show changes
    setTimeout(async () => {
      if (eraseUrl) {
        await this.changeUrl(true)
      }
      // perform a user-defined function
      onClosed?.()

      // destroy the component
      const holdQueue = this.component[DialogComponent.holdQueueSelector]
      if (holdQueue === 0) { // only one visible dialog in a stack of visible dialogs
        // emit child showChange
        this.navbarService.enable(true)
        // Emits the component showChange.
        this.component.showChange.emit(false)
      } else {
        // decrease 'holdQueue'
        this.updateHoldQueue()
      }

      this.showChange.emit(false)
    }, DialogComponent.DIALOG_CLOSE_DURATION) // 150ms takes the primeng p-dialog's to perform the closing animation
  }

  /**
   * Scrolls to bottom in the {@link scrollContent} container.
   */
  scrollBottom(delay: number = 0): void {
    setTimeout(() => {
      scrollToIndex('app-dialog-bottom', 0, 'smooth', this.scrollContent.nativeElement)
    }, delay)
  }

  /**
   * - Returns true when the @{@link scrollContent} is fully scrolled.
   * (Basically, when the 'app-dialog-bottom' div is fully visible)
   */
  isFullyScrolled(): boolean {
    const container = this.scrollContent.nativeElement
    const lastEl = container?.getElementsByClassName('app-dialog-bottom')[0]
    if (container && lastEl) {
      const containerRect = container.getBoundingClientRect()
      const divRect = lastEl.getBoundingClientRect()

      return Math.round(divRect.bottom) <= Math.round(containerRect.bottom)
    }
    return false
  }

  /**
   * Fires when the user wants to go to previous URL.
   */
  @HostListener('window:popstate', ['$event'])
  onBackButton(event: PopStateEvent): void {
    // Skip event
    if (this.shouldBlockCloseAction()) {
      return
    }
    if (!environment.blockLeaveAction || !this.blockLeaveAction
      || (!this.component.loading
        && !this.component.saving
        && !this.trap)) {

      // close dialog
      this.closeDialog(null, false)

    } else if (this.trap || this.component.loading || this.component.saving) {

      // display custom dialog
      event?.preventDefault()
      this.urlChanged = false
      // re-init the url since the popstate has erased the url
      setTimeout(() => {
        this.changeUrl()
      }, 100)
      // Display unsaved changes dialog
      this.unsavedDialogVisible = true
    }
  }

  /**
   * Fires when the keydown is pressed.
   */
  @HostListener('document:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent): void {
    // Skip event
    if (this.shouldBlockCloseAction()) {
      return
    }
    // Check if the pressed key is the ESC key
    if (event.key === 'Escape' || event.key === 'Esc') {
      history.back()
    }
  }

  /**
   * - Increases or decreases the `component['holdQueue']` property by the {@link hold} input.
   * - This number represents how many dialogs are waiting under the highest visible one.
   *
   * - The setTimeout prevents from an unexpected behavior caused in the {@link onBackButton} function.
   *  It solves a problem when the upper dialog (above this currently visible one) gets closed, this function modified the hold queue and
   *  executes the {@link onBackButton} function as well which results to closing the parent dialog as well. This timeout delays that
   *  process.
   */
  private updateHoldQueue(): void {
    setTimeout(() => {
      const selector = DialogComponent.holdQueueSelector
      this.initHoldQueue()
      this.component[selector] += ((this.hold) ? 1 : ((this.component[selector] === 0) ? 0 : -1))
    }, 100)
  }

  /**
   * Initializes the component's hold queue of currently visible dialogs.
   */
  private initHoldQueue(): void {
    if (!this.component[DialogComponent.holdQueueSelector]) {
      this.component[DialogComponent.holdQueueSelector] = 0
    }
  }

  /**
   * Initializes the default properties of the dialog by the given {@link type}.
   */
  private initDefaultProperties(): void {
    switch (this.type) {
      case 'danger':
        this.defaultDiscardLabel = $localize`Cancel`
        this.defaultSaveLabel = $localize`Delete`
        this.defaultSaveIcon = 'fa-solid fa-xmark'
        break
      case 'save':
        this.defaultDiscardLabel = $localize`Discard`
        this.defaultSaveLabel = $localize`Save`
        this.defaultSaveIcon = 'fa-solid fa-check'
        break
      case 'info':
        this.defaultDiscardLabel = $localize`Close`
        this.defaultSaveLabel = $localize`Close`
        this.defaultSaveIcon = 'fa-solid fa-check'
        break
    }
    this.url = this.url || this.type
    this.trap = this.trap || this.type !== 'info'
  }

  /**
   * Disables reload and tab-closing event.
   */
  @HostListener('window:beforeunload', ['$event'])
  private preventRefresh(event): void {
    // Skip event
    if (this.shouldBlockCloseAction()) {
      return
    }
    if (environment.blockLeaveAction && !this.finished && (this.trap || this.component.loading || this.component.saving)) {
      event.preventDefault()
      event.returnValue = $localize`You have unsaved changes. Want to leave?`
    }
  }

  /**
   * Returns true when the close action must be blocked.
   */
  private shouldBlockCloseAction(): boolean {
    if (!this.blockLeaveAction) {
      return false
    }
    return ((this.component?.[DialogComponent.holdQueueSelector] || 0) > 0) || this.unsavedDialogVisible || this.disableBlockingEvents
  }

  /**
   * Initializes the url by the {@link url} parameter.
   * - The {@link erase} parameter erase the {@link url} from the end.
   */
  private async changeUrl(erase: boolean = false): Promise<void> {
    // change url only once
    if ((!erase && this.urlChanged) || !this.modifyUrl || !this.url) {
      return
    }
    this.urlChanged = true
    if (erase) {
      // wait until the history.back() function is performed (we suppose that .back() will take 100ms)
      await new Promise<void>((resolve) => {
        this.disableBlockingEvents = true
        history.back()

        setTimeout(() => {
          resolve()
        }, 100)
      })
    } else {
      const fragment = await firstValueFrom(this.activatedRoute.fragment)
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        fragment: (fragment) ? `${fragment}_${this.url}` : this.url,
        replaceUrl: false // adds a new history entry,
      })
    }
  }

  /**
   * Checks the required inputs of this component and throw errors if any are not specified.
   */
  private throwErrors(): void {
    if (!this.component) {
      throwInputNotInited('app-dialog', 'component')
    }
  }
}
