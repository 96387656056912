import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {debounceTime, firstValueFrom, Subscription} from 'rxjs'
import {AuthComponent} from './auth.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {Restrictions} from '../../../common/restrictions'
import {PhoneVerificationResult} from './register-abstract.component'
import {ServerMessage} from '../../../common/server-message'
import {addSeconds} from '../../../utils/date.utils'
import {environment} from '../../../../environments/environment'

/**
 * Abstract class for phone verification components that contains common methods and properties.
 * The whole logic is implemented here and child components handle only the view.
 */
@Component({template: ''})
export abstract class PhoneVerificationAbstractComponent extends AuthComponent implements OnInit, OnDestroy {

  PhoneVerificationResult: typeof PhoneVerificationResult = PhoneVerificationResult
  /**
   * A form with fields of phone verification.
   */
  form: FormGroup
  /**
   * Last sent code storage in case of unsuccessful request.
   */
  lastSentCode: string
  /**
   * Subscription to listen to form code field.
   */
  codeFieldSub?: Subscription
  /**
   * Verification change timeout in case multiple retries were made.
   */
  timeout: Date = new Date()
  /**
   * Shows the resend code button if user entered at least once the wrong code.
   */
  showResendCode = false
  /**
   * Email of the new user who is registering.
   */
  @Input()
  email: string
  /**
   * Phone of the new user which is being verified.
   */
  @Input()
  phone: string
  /**
   * Emits the result of the phone verification to the parent component.
   */
  @Output()
  phoneVerificationResult = new EventEmitter<PhoneVerificationResult>()

  protected formBuilder = inject(FormBuilder)

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.initForm()
    this.listenCodeFormField()
    this.initializePhoneVerification()
  }

  /**
   * Call the api to verify phone with temporary code.
   */
  callPhoneVerification(): void {
    this.callAndFinish(async () => {
      this.disableForm(true)
      const formData = this.form.value
      this.lastSentCode = formData.code

      const resp = await firstValueFrom(this.unwrap(this.userService.callPhoneVerification({
        email: this.email,
        code: formData.code
      })))

      if (resp && this.noServerMessages()) {
        this.phoneVerificationResult.emit(PhoneVerificationResult.SUCCESS)
      }
    }, null, () => {
      this.disableForm(false)
    })
  }

  /**
   * Initializes form fields.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      code: ['']
    })
  }

  /**
   * Listens to changes on code form field.
   * When code is not same as {@link lastSentCode} and has length of {@link Restrictions.VERIFIED_CHANGE_CODE_LENGTH}
   * it calls {@link callPhoneVerification}
   * @private
   */
  private listenCodeFormField(): void {
    this.codeFieldSub?.unsubscribe()
    this.codeFieldSub = this.form.get('code').valueChanges.pipe(debounceTime(500)).subscribe({
      next: code => {
        if (code !== this.lastSentCode && code.length === Restrictions.VERIFIED_CHANGE_CODE_LENGTH) {
          this.callPhoneVerification()
        }
      }
    })
  }

  /**
   * Sets the {@link submitted} property a new value.
   * It also controls the disability of the {@link form}.
   */
  private disableForm(submitted: boolean): void {
    return submitted ? this.form.disable() : this.form.enable()
  }

  /**
   * Calls api to initialize phone verification.
   * If the user has requested multiple codes already, it sets the {@link timeout} property.
   */
  protected async initializePhoneVerification(): Promise<void> {
    this.showResendCode = false
    this.call(async () => {
      await firstValueFrom(this.unwrap(this.userService.callPhoneVerificationInit({
        email: this.email,
        phone: this.phone,
      })))

      if (this.isMessage(ServerMessage.VERIFIED_CHANGE_TIMEOUT)) {
        this.timeout = addSeconds(new Date(), +this.obtainFirstDescription(ServerMessage.VERIFIED_CHANGE_TIMEOUT))
      }

      // if not in production ask for verification code for easy registration
      if (this.noServerMessages() && !environment.production) {
        const resp = await firstValueFrom(this.unwrap(this.userService.callPhoneVerificationCode({email: this.email})))

        if (this.noServerMessages() && resp) {
          // prompt instead of alert for easier copy of value
          prompt(`Please copy this value.\nYour phone verification code for number ${this.phone}:`, resp.code)
        } else {
          alert(`There was en error retrieving phone verification code for number ${this.phone}. \nTry to resend code.`)
        }
      }
    })

    // Show the resend code again
    setTimeout(() => {
      this.showResendCode = true
    }, 10000)
  }

  ngOnDestroy(): void {
    this.codeFieldSub?.unsubscribe()
  }
}
