import {Component, EventEmitter, Output} from '@angular/core'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {CallResponseComponent} from '../../../component/common/call-response/call-response.component'
import {CheckboxComponent} from '../../../component/common/form/checkbox/checkbox.component'
import {FirebaseAuthComponent, FirebaseAuthResponseResult} from '../firebase-auth/firebase-auth.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {NgIf} from '@angular/common'
import {RouterLinkPipe} from '../../../pipe/router-link.pipe'
import {TextInputComponent} from '../../../component/common/form/text-input/text-input.component'
import {RouterLink} from '@angular/router'
import {UserResp} from '../../../service/user.service'
import {LoginAbstractComponent} from '../abstract/login-abstract.component'
import {UserResponseType} from '../../../common/user-response-type'

/**
 * Login component which can be embedded in other components. Doesn't contain any redirections and only emits events.
 */
@Component({
  selector: 'app-login-embed',
  standalone: true,
  imports: [
    BackendValidationComponent,
    ButtonComponent,
    CallResponseComponent,
    CheckboxComponent,
    FirebaseAuthComponent,
    FormsModule,
    NgIf,
    RouterLinkPipe,
    TextInputComponent,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './login-embed.component.html',
  styleUrl: './login-embed.component.scss'
})
export class LoginEmbedComponent extends LoginAbstractComponent {

  /**
   * Event emitted when the user login requires second factor to authenticate.
   */
  @Output()
  secondFactorReq = new EventEmitter<void>()

  /**
   * Event emitted when the firebase authentication is redirected to registration.
   */
  @Output()
  registerFirebaseReq = new EventEmitter<void>()

  /**
   * Event emitted when the user successfully logged in.
   */
  @Output()
  loginSuccess = new EventEmitter<UserResp>()

  successLoginAction(user: UserResp): void {
    this.loginSuccess.emit(user)
  }

  twoFactorAction(): void {
    this.secondFactorReq.emit()
  }

  /**
   * Handles the response from the Firebase authentication. Redirects user to the appropriate action / component.
   */
  firebaseAuthResponseAction(response: FirebaseAuthResponseResult): void {
    switch (response.responseType) {
      case UserResponseType.TWO_FACTOR:
        this.secondFactorReq.emit()
        break
      case UserResponseType.FIREBASE_REGISTER:
        this.registerFirebaseReq.emit()
        break
    }
  }
}
