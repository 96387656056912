import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  standalone: true,
  name: 'iban'
})
export class IbanFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return ''
    }

    // Remove any existing spaces
    const cleanedValue = value.replace(/\s+/g, '')

    // Add a space every 4 characters
    return cleanedValue.replace(/(.{4})/g, '$1 ').trim()
  }

}
