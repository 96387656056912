<app-dialog *ngIf="show && !production"
            [(show)]="show"
            type="save"
            url="fund"
            [component]="this"
            header="Simulate funding"
            i18n-header
            successMessage="Profile has been funded"
            i18n-successMessage
            [save]="callFundCustomer.bind(this)"
            saveLabel="Fund"
            i18n-saveLabel>
  <div class="p-py-2 w-100">
    <!-- Amount -->
    <app-text-input label="Amount"
                    i18n-label
                    placeholder="123.45"
                    i18n-placeholder
                    icon="fa-solid fa-coins"
                    [form]="form"
                    formFieldName="amount"/>

    <!-- Full price set -->
    <app-button *ngIf="form.value.amount < bankInstructions.amountRemaining" @grow
                type="secondary"
                label="Set full price"
                icon="fa-solid fa-sack-dollar"
                i18n-label
                styleClass="p-mt-2"
                (clicked)="onFullPrice()"/>

    <!-- Reference -->
    <app-text-input label="Reference"
                    i18n-label
                    placeholder=Reference
                    i18n-placeholder
                    icon="fa-solid fa-receipt"
                    [form]="form"
                    formFieldName="reference"
                    styleClass="p-mt-2"/>

    <!--Fill reference-->
    <app-button *ngIf="form.value.reference !== bankInstructions.reference" @grow
                type="secondary"
                label="Set reference"
                icon="fa-solid fa-stamp"
                i18n-label
                styleClass="p-mt-2"
                (clicked)="onFillReference()"/>

    <!-- Currency -->
    <app-text-input label="Currency"
                    i18n-label
                    placeholder=Currency
                    i18n-placeholder
                    icon="fa-solid fa-euro-sign"
                    [form]="form"
                    formFieldName="currency"
                    styleClass="p-mt-2"/>
  </div>
</app-dialog>
