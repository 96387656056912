import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'


@Injectable({
  providedIn: 'root'
})
export class OpeningHoursService extends ApiService {
  constructor() {
    super()
  }

  callUpdateOpeningHours(req: UpdateOpeningHoursReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.OPENING_HOURS_UPDATE_URL, req)
  }

  callGetOpeningHours(req: OpeningHoursReq): Observable<BaseResp<OpeningHoursResp>> {
    return this.post(Endpoint.OPENING_HOURS_GET_URL, req)
  }

  callGetDateOpeningHours(req: OpeningHoursDateReq): Observable<BaseResp<OpeningHoursDateResp>> {
    return this.post(Endpoint.OPENING_HOURS_GET_DATE_URL, req)
  }
}

export interface UpdateOpeningHoursReq {
  nonstop: boolean

  monStart?: Date
  monEnd?: Date
  monClosed: boolean

  tueStart?: Date
  tueEnd?: Date
  tueClosed: boolean

  wedStart?: Date
  wedEnd?: Date
  wedClosed: boolean

  thuStart?: Date
  thuEnd?: Date
  thuClosed: boolean

  friStart?: Date
  friEnd?: Date
  friClosed: boolean

  satStart?: Date
  satEnd?: Date
  satClosed: boolean

  sunStart?: Date
  sunEnd?: Date
  sunClosed: boolean
}

export interface OpeningHoursReq {
  profileId: number
}

export interface OpeningHoursResp {
  nonstop: boolean

  monStart?: Date
  monEnd?: Date
  monClosed: boolean

  tueStart?: Date
  tueEnd?: Date
  tueClosed: boolean

  wedStart?: Date
  wedEnd?: Date
  wedClosed: boolean

  thuStart?: Date
  thuEnd?: Date
  thuClosed: boolean

  friStart?: Date
  friEnd?: Date
  friClosed: boolean

  satStart?: Date
  satEnd?: Date
  satClosed: boolean

  sunStart?: Date
  sunEnd?: Date
  sunClosed: boolean
}

export interface OpeningHoursDateReq {
  profileId: number
  date: Date
}

export interface OpeningHoursDateResp {
  nonstop: boolean
  start: Date
  end: Date
  closed: boolean
}
