import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core'
import {BASE_SITE_URL} from '../../environments/environment'
import {NavigationService} from '../service/ui/navigation.service'

/**
 * Assigns a click listener to the selected element and after a user clicks on that element,
 * it opens a new tab with a profile that this directive value refers to.
 */
@Directive({
  selector: '[appRedirectProfile]',
  standalone: true
})
export class RedirectProfileDirective implements OnInit {

  /**
   * Requires a profile charId to redirect.
   */
  @Input()
  appRedirectProfile: string

  /**
   * Enables the redirection.
   */
  @Input()
  enableProfileRedirect = true

  /**
   * A-link target. Defaults to '_blank'.
   */
  @Input()
  rTarget = '_blank'

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit(): void {
    this.eleRef.nativeElement.style.cursor = 'pointer'
  }

  @HostListener('click')
  onClick(): void {
    if (this.enableProfileRedirect) {
      openProfile(this.appRedirectProfile, this.rTarget)
    }
  }
}

/**
 * Opens the profile in the new tab.
 *
 * @param charId The character id of a profile to be open.
 * @param target Defaults to '_blank'.
 */
export function openProfile(charId: string, target: string = '_blank'): void {
  window.open(`${BASE_SITE_URL}/${NavigationService.PROFILE}/${charId}`, target)
}
