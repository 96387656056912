import {Component} from '@angular/core'
import {PhoneVerificationAbstractComponent} from '../abstract/phone-verification-abstract.component'
import {AsyncPipe, NgIf} from '@angular/common'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {CountdownPipe} from '../../../pipe/countdown-timer.pipe'
import {TextInputComponent} from '../../../component/common/form/text-input/text-input.component'

/**
 * Phone verification component which can be embedded in other components. Logic is implemented in the abstract class.
 */
@Component({
  selector: 'app-phone-verification-embed',
  standalone: true,
  imports: [
    AsyncPipe,
    BackendValidationComponent,
    ButtonComponent,
    CountdownPipe,
    NgIf,
    TextInputComponent
  ],
  templateUrl: './phone-verification-embed.component.html',
  styleUrl: './phone-verification-embed.component.scss'
})
export class PhoneVerificationEmbedComponent extends PhoneVerificationAbstractComponent {
}
