import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {isFileImage} from '../../../utils/file.utils'
import {CropperPosition, ImageCroppedEvent} from 'ngx-image-cropper'
import {ImageService} from '../../../service/ui/image.service'
import {ServerMessage} from '../../../common/server-message'
import {AvatarService, CropAvatarReq, GetAvatarEditResp} from '../../../service/avatar.service'
import {ProfileResp} from '../../../service/profile.service'
import {createRandomUUID} from '../../../utils/utils'
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs'
import {EditableComponent} from '../../abstract/editable.component'
import {defaultAvatar} from '../../../utils/asset.utils'
import {growAnimation} from '../../../animation/grow.animation'
import {Restrictions} from '../../../common/restrictions'
import {ProfileBannerComponent} from '../../profile/profile-banner/profile-banner.component'


@Component({
  animations: [growAnimation()],
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss']
})
export class UploadAvatarComponent extends EditableComponent implements OnInit {

  @Input()
  data: ProfileResp

  /**
   * Informs the parent component whether a new avatar was saved -> (true) or removed -> (false).
   */
  @Output()
  changed = new EventEmitter<string>()

  /**
   * Requires the parent component to successfully trigger change of avatar data change.
   */
  @Input()
  bannerComponent: ProfileBannerComponent

  /**
   * - An image file for the cropper.
   * - Gets initialized inside of the {@link onImageCompressed} function.
   */
  cropperImage: File

  /**
   * - An image url (from the server) for the cropper.
   * - Gets initialized if a profile has an avatar.
   */
  cropperUrl: string

  /**
   * The preview avatar image.
   */
  previewImage: string

  /**
   * Represents the default avatar image url.
   */
  defaultAvatar: string

  /**
   * Defines the cropper area position of the image.
   * This property gets updated in the {@link imageCropped} function.
   */
  private croppedPosition: CropperPosition

  /**
   * - The image uuid of the current image.
   * - Represents the image name.
   * - This value is vital when a user wants to send the cropped position to the server.
   */
  private imageUuid: string = null

  /**
   * Determines whether a new image has been uploaded to the server.
   */
  private imageUploaded = new BehaviorSubject<boolean>(false)
  /**
   * True, when a user wants to remove his avatar image.
   * This plays the key role in the {@link onComponentSave} function.
   */
  private removeAvatar: boolean

  constructor(
    private imageService: ImageService,
    private avatarService: AvatarService) {
    super()
  }

  ngOnInit(): void {
    this.getAvatarForEdit()
  }

  /**
   * Downloads the avatar image of the profile.
   */
  private getAvatarForEdit(): void {
    this.call(async () => {
      const resp = await firstValueFrom(this.callGetAvatarEdit())

      // only if a user already didn't upload his own file
      if (resp && !this.imageUuid) {
        this.cropperUrl = resp.url
        this.imageUuid = resp.imageUuid
        this.imageUploaded.next(true)
      }

      // load default avatar if no avatar is present
      if (!resp?.url || this.failed() || !this.noServerMessages()) {
        this.defaultAvatar = defaultAvatar(this.data.profileType)
      }
    })
  }

  /**
   * If the user decides to upload his profile avatar.
   */
  onFileSelect(image): void {
    if (!image) { // return if no file selected
      return
    }

    // if selected file is not an image, return
    if (!isFileImage(image)) {
      this.resetApi()
      this.pushToMessages(ServerMessage.FILE_FORMAT_NOT_SUPPORTED)
      return
    }

    // compress the image and show
    this.call(async () => {
      this.cropperUrl = null
      this.imageUuid = createRandomUUID()
      this.imageUploaded.next(false)
      this.removeAvatar = false

      // Compress the image on front-end.
      try {
        const compressed = await firstValueFrom(this.imageService.fitTo(
          image,
          Restrictions.MAX_IMAGE_EDGE_SIZE,
          Restrictions.MIN_IMAGE_AVATAR_SIZE,
          Restrictions.MIN_IMAGE_AVATAR_SIZE)
        )

        if (compressed) {
          this.resetApi()
          await this.onImageCompressed(compressed)
        }
      } catch (message) {
        this.pushToMessages(message)
      }
    })
  }

  /**
   * The next process of the {@link onFileSelect} function.
   * Basically initializes the image cropper component and the avatar preview image.
   *
   * @param compressedImage Needs to be an image file!
   */
  private async onImageCompressed(compressedImage: File): Promise<void> {
    // show in the cropper view
    this.cropperImage = compressedImage

    // Show in the preview
    this.previewImage = await this.imageService.imageFileToURL(compressedImage)

    // Posts the compressed image to the server
    const resp = await firstValueFrom(this.callPostNewAvatar(compressedImage))
    if (!this.removeAvatar) {
      if (!resp) {
        this.pushToMessages(ServerMessage.IMAGE_UPLOAD_FAILED)
      }
      this.imageUploaded.next(resp)
    }
  }

  /**
   * - Fires every time, when a user changed the cropping area.
   * - Updates the {@link previewImage} property.
   */
  imageCropped(event: ImageCroppedEvent): void {
    const cp = event.imagePosition
    this.croppedPosition = {
      x1: Math.ceil(cp.x1),
      x2: Math.ceil(cp.x2),
      y1: Math.ceil(cp.y1),
      y2: Math.ceil(cp.y2)
    }
    this.previewImage = event.base64
  }

  /**
   * Fires when a user wants to save his changes.
   */
  async onComponentSave(): Promise<void> {
    this.saving = true

    // SCENARIO: Remove avatar
    if (this.removeAvatar) {
      const resp = await firstValueFrom(this.callRemoveAvatar())
      if (resp && this.noServerMessages()) {
        this.changed.emit(null)
      }
      this.saving = false
      return
    }
    // SCENARIO: Replace avatar
    // wait until the user image will be uploaded
    this.imageUploaded.subscribe({
        next: async (finished) => {
          if (finished) {
            const resp = await firstValueFrom(this.callPostCrop())
            if (resp && this.noServerMessages()) {
              // Force change data
              this.bannerComponent?.onUploadAvatar(resp)
            }
            this.saving = false
          }
        },
        error: err => {
          this.onResponseError(err)
          this.saving = false
        }
      }
    )
  }

  /**
   * Calls the server API to remove the profile avatar.
   */
  private callRemoveAvatar(): Observable<boolean> {
    return this.unwrap(this.avatarService.callRemoveAvatar())
  }

  /**
   * Calls the API to send the cropper position. <br>
   * ATTENTION: The {@link imageUuid} value must be present before this method!
   */
  private callPostCrop(): Observable<string> {
    const req: CropAvatarReq = {
      imageUuid: this.imageUuid,
      cropperPosition: this.croppedPosition
    }
    return this.unwrap(this.avatarService.callPostCropAvatar(req))
  }

  /**
   * Starts to upload an image that the user has chosen.
   *
   * @param image Needs to be an image file!
   */
  private callPostNewAvatar(image: File): Observable<boolean> {
    return this.unwrap(this.avatarService.callPostNewAvatar(image, this.imageUuid, this.data.profileId))
  }

  /**
   * Calls the server API to get the profile avatar for edit.
   */
  private callGetAvatarEdit(): Observable<GetAvatarEditResp | null> {
    return this.unwrap(this.avatarService.callGetAvatarForEdit())
  }

  /**
   * Fires when a user wants to remove his avatar.
   */
  async removeImage(): Promise<void> {
    this.loading = true
    this.previewImage = defaultAvatar(this.data.profileType)
    this.cropperUrl = null
    this.cropperImage = null
    this.removeAvatar = true
    this.loading = false
  }

  /**
   * Fires when the image cropper component's image-loading process failed.
   */
  loadImageFailed(): void {
    this.pushToMessages(ServerMessage.IMAGE_LOAD_FAILED)
  }
}
