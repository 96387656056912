import {Pipe, PipeTransform} from '@angular/core'
import {ProfileType} from '../common/profile-type'

@Pipe({
  name: 'profileType',
  standalone: true
})
export class ProfileTypePipe implements PipeTransform {

  transform(type: ProfileType): string {
    return stringifyProfileType(type)
  }
}

/**
 * Returns the proper string suitable for end user to display.
 */
export function stringifyProfileType(type: ProfileType): string {
  switch (type) {
    case ProfileType.USER:
      return $localize`User`

    case ProfileType.ARTIST:
      return $localize`Artist`

    case ProfileType.ENTERPRISE:
      return $localize`Enterprise`

    case ProfileType.EVENT:
      return $localize`Event`
  }
}
