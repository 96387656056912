<app-dialog *ngIf="show"
            type="danger"
            [(show)]="show"
            (showChange)="showChange.emit($event)"
            [component]="component"
            saveLabel="Delete"
            i18n-saveLabel
            discardLabel="Keep"
            i18n-discardLabel
            url="confirm"
            [successMessage]="successMessage"
            [save]="save.bind(this)"
            (saved)="saved.emit($event)">

  <!--Icon and Title-->
  <div class="p-d-flex p-px-3">
    <i class="delete-icon p-mt-2 {{icon}}"></i>
    <div class="p-ml-5 w-100">
      <h2 class="p-m-0">{{ question }}</h2>

      <!--Description-->
      <p class="p-mt-2">
        <ng-content></ng-content>
      </p>
    </div>
  </div>
</app-dialog>
