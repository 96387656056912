import {AbstractComponent} from './abstract.component'
import {isArrayNullOrEmpty} from '../../utils/utils'
import {BaseResp} from '../../rest/base-resp'
import {ServerMessage} from '../../common/server-message'
import {HttpErrorResponse} from '@angular/common/http'
import {fixDateObjects} from '../../utils/date.utils'
import {fixPageObject} from '../../rest/page-resp'
import {EventEmitter, inject} from '@angular/core'
import {Observable} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import {DialogComponent} from '../common/dialog/dialog.component'
import {JwtService} from '../../service/helper-services/jwt.service'

/**
 * Includes all common properties that is required when the component needs to communicate with server API.
 */
export abstract class ApiComponent extends AbstractComponent {
  /**
   * Used for signalizing that a user authentication is required.
   */
  static readonly REQUIRED_AUTHENTICATION = 'required_authentication'

  /**
   * Represents a list of all messages from the API call.
   */
  serverMessages: string[] = []

  /**
   * Represents a list of descriptions of the {@link serverMessages}.
   */
  serverMessagesDescription: string[][] = []

  /**
   * A state when a server is down or an invalid request was made.
   */
  responseError: HttpErrorResponse

  /**
   * - A state when everything has been saved.
   * - **Don't update this property directly. Use the {@link setSuccess()} or {@link succeed()}**
   */
  responseSuccess: boolean

  /**
   * - A state when a response has failed.
   * - **Don't update this property directly. Use the {@link setFailed()} or {@link failed()}**
   */
  responseFailed: boolean

  /**
   * A state when a client is disconnected from the internet.
   */
  disconnected: boolean

  /**
   * Sets the component to the loading state.
   */
  loading: boolean

  /**
   * Represents the saving process where all clickable views should be disabled.
   */
  saving: boolean

  /**
   * Gets emitted when the api state got changes.
   */
  apiValueChanges = new EventEmitter<any>()

  // services
  protected jwtService = inject(JwtService)

  /**
   * Parses the API call response.
   * It also includes initialization of the {@link serverMessages} and the {@link serverMessagesDescription}.
   *
   * @param response The API call response
   * @returns T The body of the response.
   */
  protected parseBody<T>(response?: BaseResp<T>): T {
    response?.messages.forEach(msg => {
      this.serverMessages.push(msg.serverMessage)
      // Descriptions
      if (msg.description) {
        if (this.serverMessagesDescription[msg.serverMessage]) {
          this.serverMessagesDescription[msg.serverMessage].push(msg.description)
        } else {
          this.serverMessagesDescription[msg.serverMessage] = [msg.description]
        }
      }
    })
    fixDateObjects(response?.body)

    // Fix Page object
    if (response?.body && response.body[`totalPages`]) {
      // @ts-expect-error The 'totalPages' property is not present in the 'T' type.
      fixPageObject(response?.body)
    }
    return response?.body
  }

  /**
   * Initializes all properties to the default state.
   * (E.g.: Removes all server messages)
   * - Clears the {@link serverMessages}.
   * - Clears the {@link serverMessagesDescription} array.
   * - Sets the {@link responseError} to null.
   * - Sets the {@link responseFailed} to false.
   * - Sets the {@link responseSuccess} to false.
   * - Sets the {@link disconnected} to false.
   * - Sets the {@link loading} to false.
   * - Sets the {@link saving} to false.
   * - Notifies all observers about these changes. {@link notifyApiChanges()}.
   */
  resetApi(): void {
    this.serverMessages = []
    this.serverMessagesDescription = []
    this.responseError = null
    this.responseFailed = false
    this.responseSuccess = false
    this.disconnected = false
    this.loading = false
    this.saving = false
    this.notifyApiChanges()
  }

  /**
   * Calls the {@link resetApi()} after the {@link DialogComponent.DIALOG_STATUS_DURATION}s.
   */
  async resetApiAfter(delayMs: number = DialogComponent.DIALOG_STATUS_DURATION): Promise<void> {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        this.resetApi()
        resolve()
      }, delayMs)
    })
  }

  /**
   * - Evaluates the last API call.
   * - Sets the {@link responseSuccess} property to true if the {@link serverMessages} array contains server messages.
   * - If the {@link responseSuccess} gets true, the {@link DialogComponent} will be closed.
   */
  protected evaluateAndFinish(): boolean {
    this.responseSuccess = isArrayNullOrEmpty(this.serverMessages)
    this.responseFailed = !this.responseSuccess
    this.notifyApiChanges()
    return this.responseSuccess
  }

  /**
   * Returns true if the {@link serverMessages} array is null or empty.
   */
  protected noServerMessages(): boolean {
    return isArrayNullOrEmpty(this.serverMessages)
  }

  /**
   * This function proceeds all errors from API calls.
   * Also, it sets the {@link responseError} property to True.
   *
   * @param e API call error.
   */
  protected onResponseError(e: HttpErrorResponse): void {
    this.responseError = e
    this.disconnected = this.responseError?.status === 0
    if (!this.disconnected) {
      console.error(e)
    }
    this.notifyApiChanges()
  }

  /**
   * Pushes a user message to the global {@link serverMessages} property.
   *
   * @param message A user defined server message.
   * @param description The optional message description.
   */
  protected pushToMessages(message: ServerMessage, description?: any): void {
    this.serverMessages.push(message)
    if (description) {
      if (this.serverMessagesDescription[message]) {
        this.serverMessagesDescription[message].push(description)
      } else {
        this.serverMessagesDescription[message] = [description]
      }
    }
  }

  /**
   * Returns all descriptions of the message.
   *
   * @param message The {@link ServerMessage} message.
   */
  protected obtainDescriptions(message: ServerMessage): any[] | null {
    return this.serverMessagesDescription[message]
  }

  /**
   * Returns the first description of the message if it is present.
   *
   * @param message The {@link ServerMessage} message.
   */
  protected obtainFirstDescription(message: ServerMessage): any | null {
    const description = this.serverMessagesDescription[message]?.[0]
    return description?.split('~')[1]
  }

  /**
   * Returns true if the 'message' contains the 'description'
   *
   * @param message The {@link ServerMessage} message.
   * @param description The description to be search in the {@link serverMessagesDescription} array.
   */
  protected isMessageWithDescription(message: ServerMessage, description: any): boolean {
    return (description in this.serverMessagesDescription[message])
  }

  /**
   * Returns true if the 'message' is in the 'serverMessages'.
   */
  isMessage(message: ServerMessage): boolean {
    return this.serverMessages.includes(message)
  }

  /**
   * Removes the 'message' from the 'serverMessages'.
   */
  protected removeMessage(message: ServerMessage): void {
    for (let i = 0; i < this.serverMessages.length; i++) {
      if (this.serverMessages[i] === message) {
        this.serverMessages.splice(i, 1)
        this.serverMessages = [...this.serverMessages] // refresh the content
        return
      }
    }
  }

  /**
   * Updates the 'responseSuccess' property.
   * It also updates the 'responseFailed' property to the inverted state of the {@link success}.
   */
  protected setSuccess(success: boolean): void {
    this.responseSuccess = success
    this.responseFailed = !this.responseSuccess
    this.notifyApiChanges()
  }

  /**
   * Updates the 'responseFailed' property.
   * It also updates the 'responseSuccess' property to the inverted state of the {@link success}.
   */
  protected setFailed(failed: boolean): void {
    this.responseFailed = failed
    if (this.responseFailed) { // push example message due to callAndFinish
      this.serverMessages.push('FAILED')
    }
    this.responseSuccess = !this.responseFailed
    this.notifyApiChanges()
  }

  /**
   * Returns true if the api call returns no server messages and passed successfully.
   */
  protected succeed(): boolean {
    if (this.responseSuccess === null) {
      throw Error('The succeed() function cannot be called before API request.')
    }
    return this.responseSuccess
  }

  /**
   * Returns true if the api call returns server messages or threw an error.
   */
  protected failed(): boolean {
    if (this.responseFailed === null) {
      throw Error('The failed() function cannot be called before API request.')
    }
    return this.responseFailed
  }

  /**
   * - Notifies all subscribers about new api state that subscribes to the {@link apiValueChanges}.
   */
  protected notifyApiChanges(): void {
    this.apiValueChanges.emit()
  }

  /**
   * Used for performing a task in the component.
   * - Before executing the {@link fun()} specified by the user, it executes the {@link resetApi()}, if the parameter is specified,
   * and sets {@link loading} to true.
   * - After executing the user defined function, it sets the {@link loading} to false.
   * - Wraps the {@link fun()} in the try/finally clause.
   * Usage: <pre>
   *   this.run(() => {
   *     const x = this.calculate(...)
   *     // other code
   *   })
   * </pre>
   */
  run(fun: () => any, resetApi: boolean = false): void {
    if (resetApi) {
      this.resetApi()
    }
    this.loading = true
    try {
      fun?.()
    } finally {
      this.loading = false
    }
  }

  /**
   * Used for calling server API in a more convenient way.
   * - Before executing the {@link fun()} specified by the user, it executes the {@link resetApi()} and sets {@link loading} to true.
   * - After executing the user defined function, it sets the {@link loading} to false and returns a promise.
   * - Wraps the {@link fun()} in the try/catch/finally clause.
   *
   * Usage: <pre>
   *   this.call(async () => {
   *     const resp = await firstValueFrom(...)
   *     const resp2 = await firstValueFrom(...)
   *     // ...
   *   })
   * </pre>
   * @param fun Function contains all callings to external API.
   * @param err Function that will be called in the catch block if the {@link fun} throws an exception.
   * @param fin Function that will be called at the end. (Even on errors).
   */
  async call(fun: () => Promise<void>, err: (e: any) => void = null, fin: () => void = null): Promise<void> {
    this.resetApi()
    this.loading = true
    try {
      await fun?.()
    } catch (e) {
      this.printCommonErrors(e)
      err?.(e)
    } finally {
      fin?.()
      this.loading = false
    }
    return
  }

  /**
   * Used for calling server API in a more convenient way. (The same as {@link call()} function but without setting the {@link loading}
   * property automatically).
   * - Before executing the {@link fun()} specified by the user, it executes the {@link resetApi()}.
   * - After executing the user defined function, it returns a promise.
   * - Wraps the {@link fun()} in the try/catch/finally clause.
   * - Executes the {@link fin()} at the end of the function (in a finally clause)
   *
   * Usage: <pre>
   *   this.customCall(async () => {
   *     this.myCustomLoading = true
   *     const resp = await firstValueFrom(...)
   *     const resp2 = await firstValueFrom(...)
   *     // ...
   *   }, null, () => this.myCustomLoading = false)
   * </pre>
   * @param fun Function contains all callings to external API.
   * @param err Function that will be called in the catch block if the {@link fun} throws an exception.
   * @param fin Function that will be called at the end. (Even on errors).
   */
  async customCall(fun: () => Promise<void>, err: (e: any) => void = null, fin: () => void = null): Promise<void> {
    this.resetApi()
    try {
      await fun?.()
    } catch (e) {
      this.printCommonErrors(e)
      err?.(e)
    } finally {
      fin?.()
    }
    return
  }

  /**
   * Used for calling server API in a more convenient way.
   * - Before executing the {@link fun()} specified by the user, it executes the {@link resetApi()} and sets {@link saving} to true.
   * - After executing the user defined function, it sets {@link saving} to false only if some errors have raised, and returns a promise.
   * - Executes the {@link evaluateAndFinish()} function in the end of a process.
   * - Wraps the {@link fun()} in the try/catch/finally clause.
   * - Returns immediately when the {@link saving} is already true. (This function has been executed before and didn't finish yet)
   *
   * @param fun Function contains all callings to external API.
   * @param err Function that will be called in the catch block if the {@link fun} throws an exception.
   * @param fin Function that will be called at the end. (Even on errors).
   * Usage: <pre>
   *   this.call(async () => {
   *     const resp = await firstValueFrom(...)
   *     const resp2 = await firstValueFrom(...)
   *     // ...
   *   })
   * </pre>
   */
  async callAndFinish(fun: () => Promise<void>, err: (e: any) => void = null, fin: () => void = null): Promise<void> {
    // if it is already saving, return
    if (this.saving) {
      return
    }

    this.resetApi()
    this.saving = true
    try {
      await fun?.()
      this.evaluateAndFinish()
    } catch (e) {
      this.printCommonErrors(e)
      err?.(e)
    } finally {
      fin?.()
      this.saving = false
    }
    // set loading to false only when some errors are present
    if (this.responseFailed || this.responseError || !this.noServerMessages()) {
      this.saving = false
    }
    return
  }

  /**
   * Unwraps the {@link BaseResp} from the {@link observable}.
   * <pre>
   *    const req: YourReq = {
   *      id: this.data.id
   *      // ...
   *    }
   *    this.unwrap(this.yourService.callUpdate(req))
   * </pre>
   */
  protected unwrap<T>(observable: Observable<BaseResp<T>>): Observable<T> {
    return observable.pipe(
      map(it => this.parseBody(it)),
      catchError(err => {
        // prevent from displaying the error if the authentication tokens expired
        if (ApiComponent.REQUIRED_AUTHENTICATION !== err) {
          this.onResponseError(err)
        }

        // handle session expiration
        const message = err.headers?.get(JwtService.AUTH_ERROR_HEADER) as ServerMessage
        if (this.jwtService.handleSessionExpiration(message)) {
          this.onResponseError(err)
        }

        throw err
      })
    )
  }

  /**
   * - Handles common error types.
   * - Prints to the console base errors of the pure javascript to help determine, where could be the problem.
   */
  private printCommonErrors(e): void {
    if (e instanceof ReferenceError || e instanceof TypeError || e instanceof SyntaxError) {
      console.error(e)
    }
  }
}
