import {Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {BankInstructions, StripeService} from '../../../service/stripe.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {firstValueFrom} from 'rxjs'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {NgIf} from '@angular/common'
import {TextInputComponent} from '../../common/form/text-input/text-input.component'
import {ButtonComponent} from '../../common/button/button.component'
import {growAnimation} from '../../../animation/grow.animation'
import {environment} from '../../../../environments/environment'
import {ProfileOrderResp} from '../../../service/profile-order.service'

/**
 * #### Do not use this component in production!
 * Simulates funding test customer profile in Stripe.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-simulate-funding',
  standalone: true,
  imports: [
    DialogComponent,
    NgIf,
    TextInputComponent,
    ButtonComponent
  ],
  templateUrl: './simulate-funding.component.html',
  styleUrl: './simulate-funding.component.scss'
})
export class SimulateFundingComponent extends EditableComponent implements OnChanges {

  /**
   * This profile will be funded.
   */
  @Input({required: true})
  order: ProfileOrderResp
  /**
   * Funding instructions.
   */
  @Input({required: true})
  bankInstructions: BankInstructions
  /**
   * Form with fields.
   */
  form: FormGroup
  /**
   * Whether a current running environment is production.
   */
  production = environment.production

  constructor(
    private stripeService: StripeService,
    private formBuilder: FormBuilder) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bankInstructions?.currentValue) {
      this.initForm()
    }
  }

  /**
   * Calls Stripe to fund author of the {@link order}.
   */
  async callFundCustomer(): Promise<void> {
    const fd = this.form.value
    const resp = await firstValueFrom(this.unwrap(this.stripeService.callLocalhostSimulateBankTransfer({
      amount: fd.amount,
      reference: fd.reference,
      currency: fd.currency,
      profileId: this.order.author.profileId
    })))
    if (!resp || !this.noServerMessages()) {
      return
    }

    // Tries to accept payment if it runs locally
    if (environment.runsLocally) {
      try {
        await firstValueFrom(this.unwrap(this.stripeService.callLocalhostAcceptPayment(this.order.id)))
      } finally {
        this.resetApi()
        this.setSuccess(true)
      }
    }
  }

  /**
   * Sets full amount remaining price to the {@link form}.
   */
  onFullPrice(): void {
    this.form.controls.amount.setValue(this.bankInstructions.amountRemaining / 100)
  }

  /**
   * Fills the bank instruction reference in the {@link form}.
   */
  onFillReference(): void {
    this.form.controls.reference.setValue(this.bankInstructions.reference)
  }

  /**
   * Initializes the {@link form}.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      amount: [Math.round(((this.bankInstructions.amountRemaining / 100) / 2) * 100) / 100],
      reference: [this.bankInstructions.reference],
      currency: ['eur']
    })
  }
}
