import {Component, EventEmitter, Input, Output} from '@angular/core'
import {GalleriaModule} from 'primeng/galleria'
import {AbstractComponent} from '../../abstract/abstract.component'

@Component({
  selector: 'app-galleria',
  standalone: true,
  imports: [
    GalleriaModule
  ],
  templateUrl: './galleria.component.html',
  styleUrl: './galleria.component.scss'
})
export class GalleriaComponent extends AbstractComponent {
  /**
   * The source of images with descriptions.
   */
  @Input()
  images: GalleriaImage[] = []
  /**
   * Determines whether the galleria is visible.
   */
  @Input()
  visible: boolean
  /**
   * Emits {@link visible} changes.
   */
  @Output()
  visibleChange = new EventEmitter<boolean>()
  /**
   * The currently active index in galleria.
   */
  @Input()
  activeIndex: number
  /**
   * Emits {@link activeIndex} changes.
   */
  @Output()
  activeIndexChange = new EventEmitter<number>()

}

/**
 * Image data structure for galleria component.
 */
export interface GalleriaImage {
  url: string
  description?: string
}

