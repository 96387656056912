<p-dialog [visible]="!!permission"
          [modal]="true"
          [draggable]="false"
          [closable]="false"
          [resizable]="false"
          [baseZIndex]="10000">

  <div class="p-py-2" *ngIf="permission">
    <div class="p-grid p-d-flex p-ai-center p-mt-3 p-mt-sm-0">
      <!--Icon-->
      <div class="p-col-12 p-sm-4 p-d-flex p-jc-center p-pr-sm-2">
        <i class="{{permission.icon}} icon"></i>
      </div>
      <!--Description-->
      <div class="p-col-12 p-sm-8">
        <h2>{{permission.title | titlecase}}</h2>
        <p [innerHTML]="permission.reason"></p>
        <p i18n>Check how to enable <a [href]="env.contact.docs + '/docs/guides/permissions/'"
                                       class="text-link"
                                       target="_blank">{{ permission.title }}</a>.</p>
      </div>
    </div>

    <!--Action Buttons-->
    <div class="p-d-flex p-ai-center p-jc-between p-mt-3 p-mb-2">
      <!--Later-->
      <app-button type="secondary"
                  label="Later"
                  i18n-label
                  icon="fa-solid fa-clock"
                  (clicked)="onResult(false)"></app-button>

      <!--Accepted-->
      <app-button type="primary"
                  label="Allowed"
                  i18n-label
                  icon="fa-solid fa-check"
                  (clicked)="onResult(true)"></app-button>
    </div>

    <!--Checks-->
    <app-backend-validation [serverMessages]="serverMessages"
                            [showAsBox]="true"
                            styleClass="p-mt-4"
                            [check]="ServerMessage.FEATURE_NOT_ALLOWED" i18n>
      <div>
        Sorry to bother you, but you haven't allowed this permission yet.
        Check how to enable <a [href]="env.contact.docs + '/docs/guides/permissions/'"
                               class="text-link"
                               target="_blank">{{ permission.title }}</a>.
      </div>
    </app-backend-validation>
  </div>
</p-dialog>
