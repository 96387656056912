import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {firstValueFrom, fromEvent, Observable, Subscription, tap, throttleTime} from 'rxjs'
import {OrderableProfileResp, ProfileService, SearchFilterProfilesReq} from '../../../service/profile.service'
import {appendNewPage, newEmptyPage, Page} from '../../../rest/page-resp'
import {ApiComponent} from '../../abstract/api.component'
import {NavbarService} from '../../../service/ui/navbar.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {ProfileCatalogFilterComponent} from './profile-catalog-filter/profile-catalog-filter.component'
import {ActivatedRoute} from '@angular/router'
import {NavigationService as NS, NavigationService} from '../../../service/ui/navigation.service'
import {PLATFORM_BROWSER} from '../../../app.module'
import {createRandomUUID, dropSingleRequest, singleRequest} from '../../../utils/utils'
import {valueEquals} from 'src/app/utils/comparing.utils'
import {
  PriceItemCategoriesComponent,
  PriceItemCategoryDetail
} from './price-item-categories/price-item-categories.component'
import {MetaService} from '../../../service/analytics/meta.service'
import {BriefProfessionResp, ProfessionService} from '../../../service/profession.service'
import {PriceItemCategoryResp, PriceItemService} from '../../../service/price-item.service'
import {Location} from '@angular/common'
import {MetadataService} from '../../../service/helper-services/metadata.service'
import {clearUrl} from '../../../utils/router.utils'
import {CityResp, CityService} from '../../../service/city.service'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-catalog',
  templateUrl: './profile-catalog.component.html',
  styleUrls: ['./profile-catalog.component.scss']
})
export class ProfileCatalogComponent extends ApiComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Determines a threshold from the bottom of the scrollbar in pixels, when the next page should be loaded.
   */
  private static readonly BOTTOM_LOAD_THRESHOLD = 400
  /**
   * Currently visible profiles.
   */
  profiles: Page<OrderableProfileResp> = newEmptyPage()
  /**
   * Defines the number of visible skeleton cards in a single row.
   */
  numberOfSkeletons = 4
  /**
   * The {@link ProfileCatalogFilterComponent} layout on small devices.
   */
  @ViewChild('mobileFilter')
  mobileFilter: ProfileCatalogFilterComponent
  /**
   * The {@link ProfileCatalogFilterComponent} layout on desktop devices.
   */
  @ViewChild('desktopFilter')
  desktopFilter: ProfileCatalogFilterComponent
  /**
   * The price item categories component.
   */
  @ViewChild('priceItemCategories')
  priceItemCategoriesComponent: PriceItemCategoriesComponent
  /**
   * Current searching filter values.
   */
  private currentFilters: SearchFilterProfilesReq
  /**
   * Holds information about the currently running fetch request.
   */
  private dataRequest = [null]
  /**
   * The window:scroll event subscription.
   */
  private scrollSub: Subscription
  /**
   * The current route params subscription
   */
  private paramsSub: Subscription
  /**
   * Determines whether the initial load has been finished.
   * Used to render proper empty profile list text.
   */
  protected initialLoadFinished = false
  /**
   * A component title which is generated based on price item category and profession.
   * Its main purpose is SEO improvement.
   */
  protected title = null
  /**
   * A component description which is generated based on price item category.
   * It's generated together with {@link title} attribute.
   */
  protected description = null

  constructor(
    private profileService: ProfileService,
    private priceItemService: PriceItemService,
    private professionService: ProfessionService,
    private changeRef: ChangeDetectorRef,
    public navbarService: NavbarService,
    private route: ActivatedRoute,
    private location: Location,
    private metadataService: MetadataService,
    private cityService: CityService,
    private metaService: MetaService) {
    super()
  }

  ngOnInit(): void {
    this.navbarService.setAutoHideEnabled(true)
    this.initSkeletons()
  }

  ngAfterViewInit(): void {
    // Route params subscription
    this.paramsSub?.unsubscribe()
    this.paramsSub = this.route.fragment.subscribe(fragment => {
      switch (fragment) {
        case NavigationService.PROFILE_CATALOG_FILTERS:
          this.mobileFilter.showSidebar()
          this.changeRef.detectChanges()
          break
      }
    })

    // Parse url parameters and initialize children components.
    this.parseParams()
  }

  /**
   * Tries to fetch URL params and select proper categories or professions.
   * If the price item category is present in the url, then set SEO title and description.
   */
  private async parseParams(): Promise<void> {
    const params = await firstValueFrom(this.route.queryParams)
    const categoryParam = params[NS.PROFILE_CATALOG_PRICE_ITEM_PARAMETER]
    const professionParam = params[NS.PROFILE_CATALOG_PROFESSION_PARAMETER]
    const cityParam = params[NS.PROFILE_CATALOG_CITY_PARAMETER]
    clearUrl(this.location)

    const profession = professionParam ? await firstValueFrom(this.callFindProfessionByParam(professionParam)) : null
    const category = categoryParam ? await firstValueFrom(this.callFindPriceItemCategoryBySlug(categoryParam)) : null
    const city = cityParam ? await firstValueFrom(this.callFindCityBySlug(cityParam)) : null
    const startDate = params[NS.PROFILE_CATALOG_START_DATE_PARAMETER]
    const endDate = params[NS.PROFILE_CATALOG_END_DATE_PARAMETER]

    // If a category is present, update title with description and update meta-tags.
    if (category) {
      this.setSEODescription(category, profession, city)
    }

    // If a city was found in the backend, then update a children map location selector component,
    // the filter is not automatically cleared. User must clear filter manually.
    if (city) {
      this.setMapLocationFilter(city)
    }

    // If the date was set in the URL, then update the filter component with the date.
    if (startDate && endDate) {
      this.setDateFilter(new Date(startDate), new Date(endDate))
    }

    // If a category was found in the backend, then update a children category selector component
    if (category) {
      await this.initPriceItemCategoryComponent(category, profession)
      // If only profession or nothing is present, then initialize a filter component with profession or null
    } else {
      this.initFilterComponent(profession)
    }
  }

  /**
   * Initializes the filter component with the given profession.
   * The profession is parsed from the URL and can be null.
   */
  private initFilterComponent(profession?: BriefProfessionResp): void {
    this.desktopFilter?.initialLoad(profession)
    this.mobileFilter?.initialLoad(profession)
  }

  /**
   * Updates the map location filter with the given city.
   * The city is parsed from the URL.
   */
  private setMapLocationFilter(city: CityResp): void {
    this.desktopFilter?.setLocationFilter(city)
    this.mobileFilter?.setLocationFilter(city)
  }

  /**
   * Updates the filter with the given date of the event.
   * The date is parsed from the URL.
   */
  private setDateFilter(startDate: Date, endDate: Date): void {
    this.desktopFilter?.setDateFilter(startDate, endDate)
    this.mobileFilter?.setDateFilter(startDate, endDate)
  }

  /**
   * Initializes the price item category component with the given category and profession.
   * The entities are parsed from the URL.
   */
  private async initPriceItemCategoryComponent(category: PriceItemCategoryResp, profession: BriefProfessionResp): Promise<void> {
    this.priceItemCategoriesComponent?.initialCategoryLoad(category)
    const professions = await firstValueFrom(this.callLoadCategoryProfessions(category.id))
    // This can generate weird combinations, but for the sake of SEO we will keep it :D (for now)
    if (profession && professions.findIndex(it => it.id === profession.id) === -1) {
      professions.push(profession)
    }
    // Prevent updating meta-title because it was already set in {@link setSEODescription}
    this.onSelectedCategory({
      category: category,
      professions: professions
    }, false, false)
  }

  /**
   * After the initial query parameters are parsed, set the title and description for SEO purpose.
   * If the profession is present, then the title will be set as `profession.name category.abbreviation`.
   */
  private setSEODescription(category: PriceItemCategoryResp, profession: BriefProfessionResp, city: CityResp): void {
    if (city && profession) {
      this.title = `${profession.name} ${category.abbreviation} ${city.name}`
    } else if (profession && city == null) {
      this.title = `${profession.name} ${category.abbreviation}`
    } else if (city && profession == null) {
      this.title = `${category.name} ${city.name}`
    } else {
      this.title = category.name
    }
    this.description = category?.description ? category.description : ''
    this.metadataService.setCustomTags({
      title: this.title,
      description: this.description
    })
  }

  /**
   * A track by function for the data-view elements.
   */
  trackBy(index: number, item: OrderableProfileResp): number {
    return item.profileId
  }

  /**
   * Fires when a user selects a category.
   */
  onSelectedCategory(category: PriceItemCategoryDetail, updateTitle: boolean = true, resetDateForm: boolean = true): void {
    if (updateTitle) {
      if (category) {
        this.title = category.category.name
        this.description = category.category.description
        this.metadataService.setCustomTags({
          title: category.category.name,
          description: category.category.description
        })
      } else {
        this.title = null
        this.description = null
        this.metadataService.setDefaultMetatags()
      }
    }
    // desktop header
    if (this.desktopFilter) {
      this.desktopFilter.onCategoryChange(category, resetDateForm)
    }
    // mobile header
    if (this.mobileFilter) {
      this.mobileFilter.onCategoryChange(category, resetDateForm)
      this.mobileFilter.hideSidebarAndSearch()
    }
  }

  /**
   * - Calls the initial load for the {@link req}.
   * - The initial load is called from the {@link ProfileCatalogFilterComponent}.
   */
  callInitialLoad(req: SearchFilterProfilesReq): void {
    if (valueEquals(this.currentFilters, req) || !PLATFORM_BROWSER) {
      return
    }

    // If the request has been performed by modifying filters
    if (req.categories?.length === 0) {
      this.priceItemCategoriesComponent?.deselectCategories()
    }

    this.enableScrollListener(false)
    dropSingleRequest(this.dataRequest)

    this.currentFilters = req
    this.profiles = newEmptyPage()

    // Create a new random identifier
    this.currentFilters.uuid = createRandomUUID()
    singleRequest(this.dataRequest, async () => {
      // Start the Page 0 loading, enables the initial skeleton
      await this.call(async () => {
        await this.onRequest(this.currentFilters)
      })

      setTimeout(async () => {
        await this.tryLoadNextPage()
        await this.tryLoadNextPage()

        // Enable scroll listener
        setTimeout(() => {
          this.enableScrollListener(true)
        }, 200)
      }, 800)
    })
  }

  /**
   * Starts searching for orderable profiles based on the given {@link SearchFilterProfilesReq}.
   */
  async onRequest(req: SearchFilterProfilesReq): Promise<void> {
    if (this.filterHasChanged(req)) {
      return
    }
    const page = await firstValueFrom(this.callSearchProfiles(req))
    if (this.filterHasChanged(req)) {
      return
    }
    // Append new page
    appendNewPage(this.profiles, page)
    // this.printDuplicates()
    this.profiles.nextPage += 1
    this.currentFilters.page = this.profiles.nextPage
    this.loading = false
    this.profiles.nextPageLoading = false
    this.initialLoadFinished = true
  }

  /**
   * Returns true when the {@link SearchFilterProfilesReq} differentiates from the {@link currentFilters}.
   */
  private filterHasChanged(req: SearchFilterProfilesReq): boolean {
    return req.uuid !== this.currentFilters.uuid
  }

  /**
   * Calls the server API to search profiles with filters.
   */
  private callSearchProfiles(req: SearchFilterProfilesReq): Observable<Page<OrderableProfileResp>> {
    return this.unwrap(this.profileService.callSearchOrderableProfiles(req))
  }

  /**
   * Calls the server API to find profession by url slug.
   */
  private callFindProfessionByParam(url: string): Observable<BriefProfessionResp> {
    return this.unwrap(this.professionService.callFindProfessionBySlug(url))
  }

  /**
   * Calls the server API to find price item category by url slug.
   */
  private callFindPriceItemCategoryBySlug(slug: string): Observable<PriceItemCategoryResp> {
    return this.unwrap(this.priceItemService.callFindPriceItemCategoryBySlug(slug))
  }

  /**
   * Calls the server API to find city by url slug.
   */
  private callFindCityBySlug(slug: string): Observable<CityResp> {
    return this.unwrap(this.cityService.findBySlug(slug))
  }

  /**
   * Calls the server API to load the professions which are associated with the price item category.
   */
  private callLoadCategoryProfessions(id: number): Observable<BriefProfessionResp[]> {
    return this.unwrap(this.priceItemService.callGetPriceItemCategoryProfessions(id))
  }

  /**
   * Tries to load next page
   */
  async tryLoadNextPage(): Promise<void> {
    if (!this.canLoad()) {
      return
    }

    await this.customCall(async () => {
      this.profiles.nextPageLoading = true
      await this.onRequest(this.currentFilters)
    }, null, () => {
      this.profiles.nextPageLoading = false
    })
  }

  /**
   * Fires when the user has clicked on a profile.
   * - When a category is selected in the {@link priceItemCategoriesComponent}, it immediately sends the category to the Meta Pixel
   * as an interest of the user.
   */
  onProfileClick(): void {
    if (this.priceItemCategoriesComponent) {
      const c = this.priceItemCategoriesComponent.selectedCategory
      this.metaService.onPriceItemCategoryClick(c, true)
    }
  }

  /**
   * Determines whether the next page can be loaded right now, or is available.
   */
  private canLoad(): boolean {
    const body = PLATFORM_BROWSER ? document.body : null
    const inThreshold = PLATFORM_BROWSER ? body.scrollHeight - (window.scrollY + window.innerHeight) < ProfileCatalogComponent.BOTTOM_LOAD_THRESHOLD : null
    const bodyScrollVisible = PLATFORM_BROWSER ? body.scrollHeight > body.clientHeight : null

    return ((inThreshold || !bodyScrollVisible)
      && !this.profiles.nextPageLoading
      && !this.loading
      && this.profiles.nextPage > 0
      && this.profiles.totalElements > this.profiles.content.length)
  }

  /**
   * Subscribes for 'window:scroll' event with a debounced time.
   */
  private enableScrollListener(enable: boolean): void {
    if (PLATFORM_BROWSER) {
      if (enable) {
        this.scrollSub = fromEvent(window, 'scroll').pipe(
          throttleTime(0), // emits once, then ignores subsequent emissions for 200ms, repeat...
          tap(() => this.tryLoadNextPage())
        ).subscribe()
      } else {
        this.scrollSub?.unsubscribe()
      }
    }
  }

  /**
   * Executes the {@link tryLoadNextPage} when the window gets focused.
   */
  @HostListener('document:visibilitychange')
  private onVisibilityChange(): void {
    if (!PLATFORM_BROWSER || document.hidden) {
      return
    }
    setTimeout(() => {
      this.tryLoadNextPage()
    }, 250)
  }

  /**
   * Initializes the {@link numberOfSkeletons} property by the current device screen.
   */
  private initSkeletons(): void {
    if (!PLATFORM_BROWSER) {
      return
    }

    if (this.isScreenBetween(0, this.Screen.SM)) {
      this.numberOfSkeletons = 1
    } else if (this.isScreenBetween(this.Screen.SM, this.Screen.MD)) {
      this.numberOfSkeletons = 2
    } else if (this.isScreenBetween(this.Screen.MD, this.Screen.LG)) {
      this.numberOfSkeletons = 3
    } else {
      this.numberOfSkeletons = 4
    }
  }

  ngOnDestroy(): void {
    this.enableScrollListener(false)
    this.paramsSub?.unsubscribe()
    this.scrollSub?.unsubscribe()
    this.navbarService.setAutoHideEnabled(false)
  }

  /**
   * Print duplicated profiles.
   */
  private printDuplicates(): void {
    const profiles = [...this.profiles.content]
    const duplicates: number[] = []
    const names: string[] = []
    for (const p of profiles) {
      const count = profiles.filter(pr => pr.profileId === p.profileId)
      if (count.length > 1 && !duplicates.find(it => it === p.profileId)) {
        names.push(p.charId)
        duplicates.push(p.profileId)
      }
    }
    if (names.length > 0) {
      console.log('\n======\nSearching duplicates ', this.currentFilters.page)
      for (const name of names) {
        console.log(name)
      }
      console.log('======\n\n')
    }
  }
}
