import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core'
import {toClipboard} from '../utils/navigator.utils'
import {SnackbarService} from '../service/ui/snackbar.service'

/**
 * Copies a value to the clipboard on click.
 * - Example usage:
 * ```html
 * <i class="fa-solid fa-copy" [appCopyClipboard]="my text">
 * ```
 */
@Directive({
  selector: '[appCopyClipboard]',
  standalone: true
})
export class CopyClipboardDirective implements AfterViewInit {
  /**
   * A custom text to be copied, otherwise the innerText will be used.
   */
  @Input()
  appCopyClipboard?: any
  /**
   * Whether the snackbar should be shown after a copy. Defaults to true.
   */
  @Input()
  snackbar: boolean = true
  /**
   * Emits the copied value.
   */
  @Output()
  copied = new EventEmitter<string>()

  constructor(private eleRef: ElementRef,
              private snackbarService: SnackbarService) {
  }

  ngAfterViewInit(): void {
    const list = this.eleRef.nativeElement.classList
    list.add('text-link')
    list.add('c-pointer')
  }

  @HostListener('click')
  onClick(): void {
    const value = this.appCopyClipboard || this.eleRef.nativeElement.innerText
    toClipboard(value)
    this.copied.emit()
    if (this.snackbar) {
      this.snackbarService.showMessage({
        icon: 'fa-solid fa-check',
        message: $localize`Copied`
      })
    }
  }
}
