import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  RendererFactory2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core'
import {CircleMarker, LatLngExpression, Map, MapOptions, Marker, TileLayer} from 'leaflet'
import {fadeAnimation} from '../../animation/fade.animation'
import {DetailMarker, MapHelper, RadiusMarker} from './map.helper'
import {BriefProfileResp} from '../../service/profile.service'
import {
  BriefMapProfileResp,
  ExploreMapBoundsFilterReq,
  ExploreMapBoundsReq,
  MapBounds,
  MapService
} from '../../service/map.service'
import {ProfileType} from '../../common/profile-type'
import {getDeviceGeolocation} from '../../utils/navigator.utils'
import {NavbarService} from '../../service/ui/navbar.service'
import {ScreenSize} from '../../utils/device.utils'
import {ActivatedRoute, Router} from '@angular/router'
import {NavigationService} from '../../service/ui/navigation.service'
import {firstValueFrom, Observable} from 'rxjs'
import {LeafletService} from '../../service/ui/leaflet.service'
import {growAnimation} from '../../animation/grow.animation'
import {PLATFORM_BROWSER} from '../../app.module'
import {PermissionRequest, PermissionService} from '../../service/ui/permission.service'
import {throwAppError} from '../../utils/log.utils'
import {NgIf} from '@angular/common'
import {MapSearchComponent} from './map-search/map-search.component'
import {MapSearchDialogComponent} from './map-search-dialog/map-search-dialog.component'
import {MapFilterComponent} from './map-filter/map-filter.component'
import {LeafletDirective} from '../../directive/leaflet.directive'
import {TooltipModule} from 'primeng/tooltip'

@Component({
  animations: [fadeAnimation(200), growAnimation()],
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  imports: [
    NgIf,
    MapSearchComponent,
    MapSearchDialogComponent,
    MapFilterComponent,
    LeafletDirective,
    TooltipModule
  ],
  standalone: true
})
export class MapComponent extends MapHelper implements OnInit, OnChanges, OnDestroy {

  /**
   * Centers the map at the initialization process to the specific point.
   */
  @Input()
  center?: LatLngExpression

  /**
   * Initializes map zoom.
   */
  @Input()
  zoom?: number

  /**
   * Initializes map min zoom.
   */
  @Input()
  minZoom?: number

  /**
   * Fits the map into these bounds.
   */
  @Input()
  bounds

  /**
   * - If present, the map will display this marker at the position, where the user will decide. (By clicking somewhere on the map)
   * - This is enabled only if the {@link readonly} is not active.
   */
  @Input()
  newMarker?: BriefProfileResp

  /**
   * Defines the readonly map.
   */
  @Input()
  readonly: boolean

  /**
   * The start point on the map of the {@link newMarker}.
   */
  @Input()
  newMarkerPos?: LatLngExpression

  /**
   * If the {@link newMarker} is present and the user has clicked on the map,
   * this emitter will invoke the position where the user has set the marker.
   */
  @Output()
  newMarkerAdded = new EventEmitter<LatLngExpression>()

  /**
   * If present, the map will display only this marker on the entire map.
   */
  @Input()
  singleMarker?: BriefProfileResp

  /**
   * Enables the searching feature option.
   */
  @Input()
  search = true

  /**
   * Enables the reset icon button.
   */
  @Input()
  resetButton: boolean

  /**
   * Enables the user center button to fly to the {@link currentUserLocation}.
   */
  @Input()
  userCenterButton: boolean

  /**
   * Fires when the {@link resetButton} is enabled and the user has clicked on that button.
   */
  @Output()
  resetClicked = new EventEmitter<any>()
  /**
   * Enables the filter icon button.
   */
  @Input()
  filterButton = true
  /**
   * Defines whether the filter dialog is visible.
   */
  filterDialogVisible: boolean
  /**
   * The current filter request.
   */
  filterReq: ExploreMapBoundsFilterReq

  /**
   * Enables the explore feature.
   */
  @Input()
  explore = true

  /**
   * Enables the feature of displaying a circle with a radius around the new marker.
   */
  @Input()
  radiusMarker?: RadiusMarker

  /**
   * Restricts the exploring feature only to specific profile types.
   */
  @Input()
  exploreOnlyTypes: ProfileType[] = []
  /**
   * Controls watching user location.
   */
  @Input()
  watchUserLocation: boolean
  /**
   * Returns the current user location event ({@link UserGeolocationPosition}) when the {@link watchUserLocation} is enabled.
   */
  @Output()
  userLocationEvent = new EventEmitter<UserGeolocationPosition>()
  /**
   * Default Geolocation permission request dialog content.
   */
  @Input()
  geoPermissionRequest: PermissionRequest = {
    name: 'geolocation',
    title: $localize`Geolocation Feature`,
    reason: $localize`Feel free to allow your location in your device to help you visualize, where you are on a map.`
  }
  /**
   * The custom styles of the map division.
   */
  @Input()
  styleClass: string

  /**
   * Leaflet's and component reference data of the {@link newMarker}.
   */
  newMarkerData?: DetailMarker

  /**
   * Defines the visibility of the 'Explore this area' button.
   */
  searchFieldVisible = false

  /**
   * Determines whether the device screen is a small one.
   */
  isSmallScreen = !this.isScreenOf(ScreenSize.LG)

  /**
   * Represents the default marker used when the user is searching for a destination.
   */
  searchDefaultMarker?: Marker

  /**
   * Represents a boolean state whether the client starts the download request on the server.
   */
  searching: boolean
  /**
   * Represents the current state whether the map is shown fullscreen or not.
   */
  fullscreen = false
  /**
   * Contains a current user location when the {@link watchUserLocation} is enabled.
   */
  currentUserLocation: GeolocationPosition
  /**
   * True when the {@link geoPermissionRequest} has been granted by a user.
   */
  geolocationGranted: boolean
  /**
   * A leaflet map options to properly construct a map instance.
   */
  mapOptions: MapOptions
  /**
   * A map base layer used to construct a leaflet map instance.
   */
  private baseLayer: TileLayer
  /**
   * Stores a geolocation observer id.
   * It is used for properly destroying the observer at the end of a component's lifecycle.
   */
  private geoLocator: number
  /**
   * Represents the user marker on a map.
   */
  private userMarker?: CircleMarker

  constructor(
    rendererFactory: RendererFactory2,
    viewRef: ViewContainerRef,
    changeRef: ChangeDetectorRef,
    leafletSer: LeafletService,
    private navbarService: NavbarService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService,
    private permissionService: PermissionService) {
    super(changeRef, rendererFactory, viewRef, leafletSer)
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.validateInputs()

    // Initialize map
    if (this.leaflet.isReady()) {
      this.baseLayer = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
      this.mapOptions = {
        layers: [
          this.baseLayer
        ],
        zoom: this.zoom || ((this.newMarkerPos || this.singleMarker) ? 15 : 13),
        minZoom: this.minZoom || 13,
        preferCanvas: true,
        doubleClickZoom: false,
        center: this.center || this.leaflet.latLng(49.04867006939826, 20.187377929687504)
      }
    }

    // Resolves whether the markers have their bubble.
    this.bubbleEnabled = !this.singleMarker && !this.newMarker

    // init fullscreen by the size of a device
    this.fullscreen = false
    this.navbarService.setNavbarVisibility(!this.fullscreen)
  }

  ngOnChanges(changes: SimpleChanges): void {
    // new marker pos change
    if (this.map) {
      if (changes.newMarkerPos?.currentValue) {// update marker pos
        if (this.newMarkerPos && this.newMarkerData) {
          this.removeDetailMarker(this.newMarkerData)
        }
        const mapMarker = this.getBriefMapProfileResp(this.newMarker)
        this.newMarkerData = this.createDetailMarker(mapMarker, this.newMarkerPos)
        this.newMarkerData.marker.addTo(this.map)
        super.rendererAppendChild(this.newMarkerData.marker, this.newMarkerData.componentRef)
        this.map.panTo(this.newMarkerPos)

      } else { // remove marker
        if (this.newMarkerData) {
          this.removeDetailMarker(this.newMarkerData)
        }
      }

      // add the radius circle on the map and center
      if (changes.radiusMarker?.currentValue?.latLng) {
        this.addRadiusMarker(this.radiusMarker)
        this.onMapCenter()
      }

      // change map bounds
      if (changes.bounds) {
        this.fitMapBounds(this.bounds)
      }

      // Watch user location
      if (changes.watchUserLocation?.currentValue) {
        this.watchLocation()
      }
    }
  }

  /**
   * Fires when the Leaflet has initialized the map view.
   *
   * @param m The initialized map instance.
   */
  async onMapReady(m: Map): Promise<void> {
    this.map = m
    this.hideContributionBar()

    // if radius marker present, render it
    if (this.radiusMarker?.latLng) {
      this.addRadiusMarker(this.radiusMarker)
    }

    // if new-marker and new-marker-pos present, add them to map
    if (this.newMarker && this.newMarkerPos) {
      this.newMarkerData = this.createDetailMarker(this.getBriefMapProfileResp(this.newMarker), this.newMarkerPos)
      this.newMarkerData.marker.addTo(this.map)
      super.rendererAppendChild(this.newMarkerData.marker, this.newMarkerData.componentRef)
    }

    // display only one marker
    if (this.singleMarker) {
      this.addDetailMarker(this.getBriefMapProfileResp(this.singleMarker))
      this.onMapCenter()

    } else {
      // Parse URL parameters
      const params = await firstValueFrom(this.route.queryParams)
      this.zoom = params[NavigationService.MAP_ZOOM_PARAM]
      const lat = params[NavigationService.MAP_LAT_PARAM]
      const lng = params[NavigationService.MAP_LNG_PARAM]
      if (this.leaflet.isReady()) {
        const location = (lat && lng) ? this.leaflet.latLng(lat, lng) : null
        if (location) {
          this.map.setView(location, this.zoom)
        } else {
          await this.onMapCenter()
        }
      }
      await this.onSearchClicked()
    }
    // apply URL visual changes
    this.changeUrl()

    // watch for user geo position
    this.watchLocation()
    this.changeRef.detectChanges()
  }

  /**
   * - Starts watching for user location.
   * - Requires the {@link watchUserLocation} to be enabled.
   */
  private async watchLocation(): Promise<void> {
    if (this.watchUserLocation) {
      const state = await this.permissionService.requestPermission(this.geoPermissionRequest)
      navigator.geolocation.clearWatch(this.geoLocator)
      this.geolocationGranted = state !== 'denied'

      if (this.geolocationGranted) {
        this.geoLocator = navigator.geolocation.watchPosition(this.onUpdateUserLocation.bind(this), (e) => {
          this.userLocationEvent.emit({err: e})
        }, {
          maximumAge: 0,
          timeout: 10_000,
          enableHighAccuracy: true
        })
      }
    }
  }

  /**
   * Fires when a user has changed his geolocation.
   * @param position A new position of a user's device.
   */
  onUpdateUserLocation(position: GeolocationPosition): void {
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    this.currentUserLocation = position
    this.userLocationEvent.emit({coords: this.currentUserLocation})

    if (this.leaflet.isReady()) {
      const latLngVal = this.leaflet.latLng(lat, lng)
      if (this.userMarker) {
        this.userMarker.setLatLng(latLngVal)
      } else {
        if (this.leaflet.isReady()) {
          this.userMarker = this.leaflet.circleMarker(latLngVal, {
            fillColor: 'blue',
            fillOpacity: 1,
            color: 'white',
            weight: 2,
            radius: 7
          }).bindTooltip('<b>You</b>')
          this.userMarker.addTo(this.map)
        }
      }
    }
  }

  /**
   * Fires when a user clicked on the {@link userCenterButton}.
   */
  onUserCenter(): void {
    const coords = this.currentUserLocation?.coords
    if (coords) {
      const latLng = this.leaflet.latLng(coords.latitude, coords.longitude)
      this.map.panTo(latLng)
    }
  }

  /**
   * Fires when a user changed map bounds.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMapMoveEnd(e): void {
    // this.calculateBoundsDelta();
    if (!this.singleMarker) {
      this.refreshSearchAreaButton()
    }
    this.changeUrl()
  }

  /**
   * Fires when a user zoomed the map view.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMapZoomEnd(e): void {
    this.checkCircleMarkerRadius()
    this.changeUrl()
  }

  /**
   * Fires when a clicked on the map view.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMapClicked(e): void {
    this.closeDetailMarkerBubble()

    // if the map is readonly, return
    if (this.readonly) {
      return
    }

    // Create new circle radius marker on the map
    if (this.radiusMarker) {
      this.addRadiusMarker(this.radiusMarker, e.latlng)
      this.onMapCenter()
      this.newMarkerAdded.emit(e.latlng)
    }

    // Set new detail marker on the map if present
    if (this.newMarker) {
      // if marker data is present, remove
      if (this.newMarkerData) {
        this.removeDetailMarker(this.newMarkerData)
      }
      // create new
      this.newMarkerData = this.createDetailMarker(this.getBriefMapProfileResp(this.newMarker), e.latlng)
      // add to map
      this.newMarkerData.marker.addTo(this.map)
      this.newMarkerAdded.emit(e.latlng)
    }
  }

  /**
   * Shows the map filter dialog.
   */
  showFiltersDialog(): void {
    // since the dialog hides the navigation bar, make the map fullscreen
    this.fullscreen = true
    const currentBounds = this.map.getBounds()
    const boundsReq: MapBounds = {
      NELat: currentBounds.getNorthEast().lat,
      NELng: currentBounds.getNorthEast().lng,
      SWLat: currentBounds.getSouthWest().lat,
      SWLng: currentBounds.getSouthWest().lng
    }
    // update or create the filter request
    if (this.filterReq) {
      this.filterReq.bounds = boundsReq
      this.filterReq.date = new Date()
    } else {
      this.filterReq = {
        bounds: boundsReq,
        date: new Date()
      }
    }

    this.filterDialogVisible = true
    this.floatingSearchButtonVisible = true
  }

  /**
   * Changes the url parameters based on the current map location only if the map is a standalone component.
   */
  private changeUrl(): void {
    if (!this.singleMarker && !this.newMarker && !this.newMarkerPos && !this.radiusMarker && this.map) {
      const location = this.map.getCenter()
      const zoom = this.map.getZoom()
      this.navigation.changeUrlParams(this.route, {
        [NavigationService.MAP_ZOOM_PARAM]: zoom,
        [NavigationService.MAP_LAT_PARAM]: location.lat,
        [NavigationService.MAP_LNG_PARAM]: location.lng
      })
    }
  }

  /**
   * Fires when a user clicked pn the center button.
   * Centers the map view on the specific position based on provided input fields.
   */
  async onMapCenter(): Promise<void> {
    // Single Marker mode
    if (this.singleMarker && this.singleMarker.address) {
      this.map.panTo({
        lat: this.singleMarker.address.lat,
        lng: this.singleMarker.address.lng
      })

      // New Marker Mode
    } else if (this.newMarker && this.newMarkerData) {
      this.map.panTo(this.newMarkerData.marker.getLatLng())

      // radius marker mode
    } else if (this.radiusMarker?.latLng) {
      this.map.panTo(this.radiusMarker.latLng)

      // Map Center mode
    } else if (this.center) {
      this.map.panTo(this.map.getCenter())

      // Device Location mode
    } else {
      const pos = this.userMarker?.getLatLng()
      if (pos) {
        this.map.panTo(pos)
      } else {
        const loc = await getDeviceGeolocation()
        this.map.panTo({
          lat: loc.coords.latitude,
          lng: loc.coords.longitude
        })
      }
    }
  }

  /**
   * Fires when a user clicked on the fullscreen icon.
   */
  onFullscreen(): void {
    this.fullscreen = !this.fullscreen
    this.navbarService.setNavbarVisibility(!this.fullscreen)
  }

  /**
   * Fires when a user has clicked on the profile option in search result.
   */
  onSearchProfileSelected(profile: BriefProfileResp): void {
    this.searchFieldVisible = false

    if (profile.address) {
      const position: LatLngExpression = {
        lat: profile.address.lat,
        lng: profile.address.lng
      }
      this.map.panTo(position).setZoom(17)
      this.onSearchClicked()
    }
  }

  /**
   * Fires when a user has clicked on the address option in search result.
   */
  onSearchAddressSelected(address: any): void {
    this.searchFieldVisible = false

    const position: LatLngExpression = {
      lat: address.lat,
      lng: address.lon
    }
    this.map.panTo(position)
    this.fitMapBounds(address.boundingbox)

    if (this.newMarker) { // New-marker marker
      this.newMarkerData?.marker?.removeFrom(this.map)
      this.newMarkerData?.marker?.setLatLng(position)
      this.newMarkerData?.marker?.addTo(this.map)
      this.newMarkerAdded.emit(position)

    } else { // Default search marker
      this.searchDefaultMarker?.removeFrom(this.map)
      if (this.leaflet.isReady()) {
        this.searchDefaultMarker = this.leaflet.L.marker(position, {
          icon: this.defaultMarkerIcon
        })
        this.searchDefaultMarker.addTo(this.map)
      }
    }
  }

  /**
   * When a user clicked on the floating button over the map to load markers within its map bounds.
   * Also, the {@link explore} property must be enabled.
   */
  async onSearchClicked(): Promise<void> {
    if (this.explore) {
      try {
        this.searching = true
        const currentBounds = this.map.getBounds()
        let markers
        if (this.filterReq) { // apply filters if present
          markers = await firstValueFrom(this.callExploreWithFilters())
        } else {
          markers = await firstValueFrom(this.callFindProfiles(currentBounds))
        }
        if (markers) {
          this.loadMarkers(markers)
          this.lastSearchedMapBounds = currentBounds
        }
      } finally {
        this.floatingSearchButtonVisible = false
        this.searching = false
      }
    }
  }

  /**
   * Makes a server request to download the markers.
   *
   * @param currentBounds The server provides all markers by these bounds.
   */
  private callFindProfiles(currentBounds): Observable<BriefMapProfileResp[]> {
    // Construct a request
    const req: ExploreMapBoundsReq = {
      bounds: {
        NELat: currentBounds.getNorthEast().lat,
        NELng: currentBounds.getNorthEast().lng,
        SWLat: currentBounds.getSouthWest().lat,
        SWLng: currentBounds.getSouthWest().lng
      },
      types: this.exploreOnlyTypes,
      date: new Date()
    }

    // Call the API
    return this.unwrap(this.mapService.callExploreMapBounds(req))
  }

  /**
   * Calls the API to explore profiles with applied user filters.
   */
  private callExploreWithFilters(): Observable<BriefMapProfileResp[]> {
    return this.unwrap(this.mapService.callExploreMapBoundsFilter(this.filterReq))
  }

  /**
   * Hides the map contribution bar.
   */
  private hideContributionBar(): void {
    if (PLATFORM_BROWSER) {
      setTimeout(() => {
        this.map.attributionControl.remove()
      }, 3000)
    }
  }

  /**
   * Validates Angular inputs.
   */
  private validateInputs(): void {
    if (this.watchUserLocation && !this.geoPermissionRequest) {
      throwAppError('Map', '[geoPermissionRequest] not defined while the [watchUserLocation] is enabled.')
    }
  }

  /**
   * Fires when the user returns to the application.
   */
  @HostListener('document:visibilitychange')
  private onResume(): void {
    if (this.watchUserLocation) {
      if (document.visibilityState === 'visible' && this.geoPermissionRequest?.required) {
        this.watchLocation()
      } else {
        navigator.geolocation.clearWatch(this.geoLocator)
      }
    }
  }

  ngOnDestroy(): void {
    // remove the user geo position callback
    if (this.geoLocator) {
      navigator.geolocation.clearWatch(this.geoLocator)
    }
  }
}

/**
 * Contains information about the geolocation event.
 */
export interface UserGeolocationPosition {
  coords?: GeolocationPosition
  err?: GeolocationPositionError
}
