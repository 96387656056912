import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core'
import {TutorialDirective} from '../../../directive/tutorial.directive'
import {AbstractComponent} from '../../abstract/abstract.component'
import {fadeAnimation} from '../../../animation/fade.animation'
import {NgIf} from '@angular/common'
import {DialogModule} from 'primeng/dialog'
import {ButtonComponent} from '../button/button.component'

/**
 * - This component is used for displaying users the UI features.
 * - {@link https://wiki.umevia.com/books/common-components/page/tutorial}
 */
@Component({
  animations: [fadeAnimation()],
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  imports: [
    NgIf,
    DialogModule,
    ButtonComponent
  ],
  standalone: true
})
export class TutorialComponent extends AbstractComponent implements OnChanges, AfterContentInit, OnDestroy {

  /**
   * Shows the tutorial.
   */
  @Input()
  show: boolean

  /**
   * Emits when the user finishes the tutorial.
   */
  @Output()
  showChange = new EventEmitter<boolean>()

  /**
   * - Defines whether the steps should be visible in the info dialog.
   * - The steps corresponds to tIndex-es.
   */
  @Input()
  steps = true

  /**
   * - Defines the base z-index for the overlay.
   * - The info dialog uses the same value but increased by 20.
   */
  @Input()
  baseZIndex = 10000

  /**
   * The list of {@link TutorialDirective} elements.
   */
  @ContentChildren(TutorialDirective, {descendants: true})
  elements: QueryList<TutorialDirective>

  /**
   * Controls the visibility of the info dialog.
   */
  infoDialogVisible: boolean

  /**
   * The sorted {@link TutorialDirective} elements by their tIndex from the {@link elements} array.
   */
  sortedElements: TutorialDirective[] = []

  /**
   * The current visible tutorial element.
   */
  currentElement: TutorialDirective
  /**
   * Current index within the {@link sortedElements} array.
   */
  currentIndex = -1

  /**
   * Defines whether the tutorial has already started and is currently running.
   */
  tutorialRunning: boolean

  /**
   * The temporary property for keeping the original unchanged z-index of the native element.
   */
  private readonly originalZIndexSelector = 'oldZIndex'
  /**
   * The temporary property for keeping the original unchanged position of the native element.
   */
  private readonly originalPositionSelector = 'oldPosition'
  /**
   * The z-index of the currently visible element.
   */
  private visibleElementZ = this.baseZIndex + 10
  /**
   * The z-index of all currently invisible elements (hidden under overlay).
   */
  private invisibleElementZ = this.baseZIndex - 10

  protected trans = {
    finish: $localize`Finish`,
    next: $localize`Next`,
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show?.currentValue && this.sortedElements.length > 0 && !this.infoDialogVisible) {
      this.start()
    }

    // z-index changes
    if (changes.baseZIndex?.currentValue) {
      this.visibleElementZ = this.baseZIndex + 10
      this.invisibleElementZ = this.baseZIndex - 10
      this.ngAfterContentInit()
    }
  }

  ngAfterContentInit(): void {
    if (this.elements) {
      this.sortedElements = this.elements.map(it => it).sort((a, b) => a.tIndex - b.tIndex)

      this.sortedElements.forEach(it => {
        const native = TutorialComponent.getNativeElement(it)
        // store the original unchanged values
        native[this.originalPositionSelector] = native.style.position
        native[this.originalZIndexSelector] = native.style.zIndex

        // modify to this component needs
        native.style.position = 'relative'
        native.style.zIndex = `${this.invisibleElementZ}`
      })

      // start the tutorial
      if (this.show && !this.infoDialogVisible && this.sortedElements.length > 0) {
        this.start()
      }
    }
  }

  /**
   * Starts the tutorial.
   */
  private start(): void {
    if (!this.tutorialRunning) {
      this.tutorialRunning = true
      this.currentIndex = -1
      this.next()
    }
  }

  /**
   * When a user clicked on the next button.
   */
  next(): void {
    this.currentIndex++

    // close if finished
    if (this.currentIndex === this.sortedElements.length) {
      this.currentIndex--
      this.skipAndFinish()
      return
    }

    // toggle off previous
    if (this.currentIndex > 0) {
      const previous = this.sortedElements[this.currentIndex - 1]
      this.toggleOverlay(previous, false)
    }

    // toggle on current
    const next = this.sortedElements[this.currentIndex]
    this.currentElement = next
    this.toggleOverlay(next, true)

    this.infoDialogVisible = true
  }

  /**
   * When the user clicked on the 'Skip' button or there is no other element to display.
   */
  async skipAndFinish(): Promise<void> {
    // toggle off the current visible if any
    if (this.currentIndex >= 0 && this.currentIndex < this.sortedElements.length) {
      await this.toggleOverlay(this.sortedElements[this.currentIndex], false)
    }

    // restore original values
    this.sortedElements.forEach(it => {
      const native = TutorialComponent.getNativeElement(it)
      // restore the original values and clean the temporary properties
      native.style.zIndex = native[this.originalZIndexSelector]
      native.style.position = native[this.originalPositionSelector]
      native[this.originalZIndexSelector] = undefined
      native[this.originalPositionSelector] = undefined
    })

    // reset this component
    this.currentIndex = -1
    this.infoDialogVisible = false

    // close after the PrimeNG dialog animation
    setTimeout(() => {
      this.tutorialRunning = false
      this.showChange.emit(false)
    }, 750)
  }

  /**
   * When the user clicked on the previous button.
   */
  previous(): void {
    this.currentIndex--
    if (this.currentIndex > -1) { // toggle off current
      this.toggleOverlay(this.sortedElements[this.currentIndex + 1], false)
    } else {
      this.skipAndFinish()
      return
    }

    // toggle on previous
    const previous = this.sortedElements[this.currentIndex]
    this.currentElement = previous
    this.toggleOverlay(previous, true)

    this.infoDialogVisible = true
  }

  /**
   * Toggles the overlay.
   *
   * @param directive The currently highlighted element.
   * @param enable Whether to enable or disable the overlay.
   */
  private async toggleOverlay(directive: TutorialDirective, enable: boolean): Promise<void> {
    const e = TutorialComponent.getNativeElement(directive)

    // scroll to the element
    if (enable) {
      TutorialComponent.scrollToElement(e)
    }

    // disable the scrolling
    this.toggleScroll(!enable)

    await this.toggleHole(e, enable)
  }

  /**
   * - Creates a hole in the overlay for the given element.
   * - The `hole`, and `hole-transition` css classes can be found in the `scss/tutorial.scss` file.
   */
  private toggleHole(native: HTMLElement, enable: boolean): Promise<void> {
    // return if no element is present
    if (!native) {
      return
    }

    if (enable) {
      native.classList.add('hole')
      native.style.zIndex = `${this.visibleElementZ}`
    } else {
      // assign temporary transition
      native.classList.add('hole-transition')
      // remove the overlay
      native.style.zIndex = `${this.invisibleElementZ}`

      native.classList.remove('hole')

      // remove temporary transition
      return new Promise((resolve) => {
        setTimeout(() => {
          native?.classList?.remove('hole-transition')
          return resolve()
        }, 300)
      })
    }
  }

  ngOnDestroy(): void {
    this.toggleScroll(true)
  }

  /**
   * Enables or disables the screen scrolling.
   */
  private toggleScroll(enable: boolean): void {
    document.body.style.overflow = (enable) ? 'visible' : 'hidden'
    document.body.style.cursor = (enable) ? 'unset' : 'default'
  }

  /**
   * Returns the native element of the {@link TutorialDirective}.
   */
  private static getNativeElement(element: TutorialDirective): HTMLElement {
    const e = element.elRef.nativeElement
    switch (e.tagName.toLowerCase()) {
      case 'app-button':
        return e.getElementsByTagName('button')[0]

      case 'app-text-input':
      case 'app-date-input':
      case 'app-checkbox':
        return e.getElementsByClassName('tutorial-class')[0]
      default:
        return e
    }
  }

  /**
   * Scrolls to the element to be in the center of the viewport.
   */
  private static scrollToElement(element: Element): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}
