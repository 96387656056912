import {Component, OnInit} from '@angular/core'
import {Snackbar, SnackbarDuration, SnackbarService} from '../../../service/ui/snackbar.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {NgIf} from '@angular/common'
import {ButtonComponent} from '../button/button.component'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [
    NgIf,
    ButtonComponent
  ],
  standalone: true
})
export class SnackbarComponent implements OnInit {

  /**
   * The current visible message.
   */
  snackbar: Snackbar
  /**
   * Controls the visibility of the message.
   */
  visible: boolean
  /**
   * Represents the current message timeout.
   */
  private messageTimeout: any

  constructor(private snackbarService: SnackbarService) {
  }

  ngOnInit(): void {
    // listen for message changes
    this.snackbarService.message.subscribe(snackbar => {
      this.snackbar = snackbar
      this.visible = true
      clearTimeout(this.messageTimeout)
      this.messageTimeout = setTimeout(() => {
        this.visible = false
      }, snackbar.duration || SnackbarDuration.SHORT)
    })
  }

  /**
   * Hides the currently visible snackbar message.
   */
  hide(): void {
    clearTimeout(this.messageTimeout)
    this.visible = false
  }

}


