<div class="wrapper p-d-flex p-jc-center p-p-2 p-p-sm-3">
  <div class="component-wrapper">

    <h1 i18n>Support</h1>

    <!--Umevia Docs-->
    <section class="border rounded-medium p-py-3 p-px-4 p-p-sm-5">
      <div class="p-grid p-p-0">
        <article class="p-col-12 p-sm-6 p-p-0">
          <h2 i18n>Umevia Docs</h2>
          <p i18n>An online version of Umevia tutorials that help you with your struggles.</p>
        </article>

        <a href="https://docs.umevia.com" class="p-col-12 p-sm-6 p-p-0">
          <div class="p-d-flex p-flex-column p-ai-center p-ai-sm-end p-mt-3 p-mt-sm-0">
            <img src="https://docs.umevia.com/images/umevia-full-light.png"
                 alt="Umevia Docs logo"
                 class="docs-logo p-d-block">

            <!-- Umevia Docs link -->
            <app-button type="primary"
                        label="Umevia Docs"
                        icon="fa-solid fa-location-arrow"
                        styleClass="p-mt-3"
                        i18n-label></app-button>
          </div>
        </a>
      </div>
    </section>

    <!-- Feedback -->
    <section class="border rounded-medium p-py-3 p-px-4 p-p-sm-5">
      <h2 i18n>Feedback</h2>
      <p i18n>Behind every review is an experience that matters. Feel free to give us feedback to help improve Umevia's
        services.</p>

      <div *ngVar="(profileData?.profileType) as type" class="p-d-flex p-ai-center p-mt-2">
        <i class="fa-solid fa-seedling p-mr-2"></i>
        <!-- users and non-registered have the same form, registered artists have a different form -->
        <a
          [href]="(type === ProfileType.ARTIST) ? 'https://forms.gle/ofVWXjA9c9mC8FTUA' : 'https://forms.gle/eQPQSFY8bXMJToYt9'"
          target="_blank"
          class="text-link"
          i18n>
          Give us your feedback - Survey!</a>
      </div>

      <div (click)="openPlatformRating()" class="p-mt-2 p-d-flex p-ai-center">
        <i class="fa-solid fa-star p-mr-2"></i>
        <a class="text-link" i18n>Rate the platform</a>
      </div>
    </section>

    <!--Contact-->
    <section class="border rounded-medium p-py-3 p-px-4 p-p-sm-5">
      <h2 i18n>Contact</h2>
      <p class="p-mb-2" i18n>If your question or problem is specific, please contact us.</p>

      <div class="p-d-flex p-ai-center p-pb-2">
        <i class="fa-solid fa-phone p-mr-2"></i>
        <b class="p-pr-2">tel.:</b>
        <a href="tel:{{supportInfo.phone}}" class="text-link">{{supportInfo.phone}}</a>
      </div>
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-envelope p-mr-2"></i>
        <b class="p-pr-2">email:</b>
        <a href="mailto:{{supportInfo.email}}" class="text-link">{{supportInfo.email}}</a>
      </div>
    </section>

    <h2 [class]="legalDocumentsClass" i18n>Legal Documents</h2>
    <p-accordion [multiple]="false" [activeIndex]="legalActiveIndex">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid fa-user p-mr-2"></i>
            <div i18n>Terms of Services</div>
          </div>
        </ng-template>
        <div *ngFor="let doc of tos">
          <ng-template [ngTemplateOutlet]="pdf" [ngTemplateOutletContext]="{$implicit: doc}"></ng-template>
        </div>
      </p-accordionTab>

      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid fa-star p-mr-2"></i>
            <div i18n>Terms of Services - Artists</div>
          </div>
        </ng-template>
        <div *ngFor="let doc of tosArt">
          <ng-template [ngTemplateOutlet]="pdf" [ngTemplateOutletContext]="{$implicit: doc}"></ng-template>
        </div>
      </p-accordionTab>

      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid fa-suitcase p-mr-2"></i>
            <div i18n>Privacy Policy</div>
          </div>
        </ng-template>
        <div *ngFor="let doc of privacy">
          <ng-template [ngTemplateOutlet]="pdf" [ngTemplateOutletContext]="{$implicit: doc}"></ng-template>
        </div>
      </p-accordionTab>

      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid fa-cookie-bite p-mr-2"></i>
            <div i18n>Cookies Policy</div>
          </div>
        </ng-template>
        <div *ngFor="let doc of cookies">
          <ng-template [ngTemplateOutlet]="pdf" [ngTemplateOutletContext]="{$implicit: doc}"></ng-template>
        </div>
      </p-accordionTab>
    </p-accordion>

  </div>
</div>

<!--Legal Documents PDF button-->
<ng-template #pdf let-doc>
  <a class="pdf-button rounded-small c-pointer p-p-3 p-d-inline-flex p-ai-center p-my-1"
     [href]="doc.url" target="_blank">
    <i class="fa-solid fa-file-pdf p-mr-2"></i>
    <div>
      <h4 class="p-m-0">{{doc.title}}</h4>
      <div *ngIf="!doc.effectiveUntil" i18n>Since: {{doc.effectiveAt | date: 'dd.MM. yyyy'}}</div>
      <div *ngIf="doc.effectiveUntil" i18n>Since: {{doc.effectiveAt | date: 'dd.MM. yyyy'}}
        Until: {{ doc.effectiveUntil | date: 'dd.MM. yyyy' }}</div>
    </div>
  </a>
</ng-template>

<app-footer></app-footer>
