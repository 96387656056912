<div class="wrapper p-d-flex p-flex-column p-flex-sm-row">
  <!--Wallpaper-->
  <div class="wallpaper fit-image" *ngIf="isAuthor"
       [class.wallpaper-left]="isScreenOf(Screen.SM)"
       [class.wallpaper-top]="!isScreenOf(Screen.SM)"
       [style]="order.profile | wallpaper: '500' | backgroundImage"></div>

  <!--Info-->
  <div class="w-100" [class.rounded-medium]="!isAuthor"
       [class.right-round]="isScreenOf(Screen.SM)"
       [class.bottom-round]="!isScreenOf(Screen.SM)">
    <!-- Profile Banner -->
    <section class="profile-banner p-px-3 p-py-2 p-d-flex p-jc-between p-ai-center banner-{{status?.classSuffix}}"
             [class.top-round]="!isAuthor"
             [class.top-right-round]="isAuthor && isScreenOf(Screen.SM)"
             *ngVar="(isAuthor) ? order.profile : order.author as pr">
      <div class="p-d-flex p-ai-center">
        <!--Overlap-->
        <i *ngIf="order.overlap" class="fa-solid fa-warning overlap p-mr-2 c-pointer"
           [appTooltip]="trans.overlap + order.overlap.id"></i>

        <app-avatar [profile]="pr" [sizePx]="35" [redirect]="false"/>
        <b class="display-name p-mx-2 l-count-1">{{ pr.displayName }}</b>
      </div>
      <b class="price">{{ order.total | price }}</b>
    </section>

    <!--Order section-->
    <section class="order-section p-px-3 p-pb-3 p-mt-2">
      <div class="p-d-flex p-jc-between">
        <!--Name and ID-->
        <div class="p-mr-2">
          <b class="order-name l-count-1">{{ order.name }}</b>
          <small class="order-id p-d-block">
            <span i18n="@@Order number of">n.</span>
            <span> {{ order.id }}</span>
          </small>
        </div>

        <!--Status badge-->
        <app-profile-order-status-badge [order]="order"
                                        [currentProfile]="profileData"
                                        (statusChange)="status = $event"/>
      </div>

      <!--Order info-->
      <div class="p-d-flex p-jc-between p-ai-end">
        <div>
          <!--Date-->
          <div class="p-d-flex p-mt-1">
            <i class="fa-solid fa-calendar-check p-mr-2 p-mt-1"></i>
            <div *ngVar="order.calendarItem as ci">
              <div class="l-count-1">{{ ci.start | date: 'dd. MMM yyyy' }}</div>
              <div class="l-count-1">{{ (ci.start | date: 'HH:mm') }}- {{ (ci.end | date: 'HH:mm') }}
              </div>
            </div>
          </div>

          <!--Address-->
          <div class="p-d-flex p-mt-2" *ngVar="order.address as a">
            <i class="fa-solid fa-location-dot p-mr-2 p-mt-1"></i>
            <div class="l-count-1">{{ a.city }}, {{ a.country }}</div>
          </div>
        </div>

        <!-- View -->
        <app-button type="text"
                    label="View"
                    i18n-label
                    icon="fa-solid fa-arrow-right"
                    iconPos="right"
                    styleClass="p-ml-2 p-pr-0"/>
      </div>
    </section>
  </div>
</div>
