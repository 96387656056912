import {Component, EventEmitter, Input, Output} from '@angular/core'
import {RatingModule} from 'primeng/rating'
import {FormsModule} from '@angular/forms'

/**
 * The customized version of PrimeNG <p-rating> component.
 */
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  imports: [
    RatingModule,
    FormsModule
  ],
  standalone: true
})
export class RatingComponent {

  /**
   * Ability to edit the stars.
   */
  @Input()
  edit: boolean
  /**
   * Enables the cancel button.
   */
  @Input()
  cancel: boolean
  /**
   * A decimal number between 0 and 5.
   */
  @Input()
  stars: number
  /**
   * Emits when a user changes the {@link stars} count.
   */
  @Output()
  starsChange = new EventEmitter<number>()

  constructor() {
  }
}

