<div class="wrapper p-py-3 w-100">
  <div class="p-grid p-px-3">

    <!--Menu for large screens-->
    <div *ngIf="isScreenOf(Screen.XL)" class="p-col-fixed sidebar">
      <ng-container *ngTemplateOutlet="option"></ng-container>
    </div>

    <!--Small screens sidebar-->
    <div *ngIf="!isScreenOf(Screen.XL)" class="p-d-flex p-jc-center w-100">
      <p-sidebar [(visible)]="settingsSidebarVisible"
                 [blockScroll]="true"
                 position="left"
                 styleClass="settings-sidebar">
        <div class="p-py-3 p-px-4">
          <ng-container *ngTemplateOutlet="option"></ng-container>
        </div>
      </p-sidebar>
    </div>

    <!--Content-->
    <div class="p-col">
      <!--Header-->
      <div class="p-d-flex p-ai-center">
        <i (click)="settingsSidebarVisible = true" *ngIf="!isScreenOf(Screen.XL)"
           class="fa-solid fa-arrow-left p-mr-3 title-icon"></i>
      </div>

      <!--Option Content-->
      <section [ngSwitch]="selectedMenuItem?.id">
        <app-admin-profiles-overview *ngSwitchCase="MC.OVERVIEW"></app-admin-profiles-overview>
        <app-admin-profiles *ngSwitchCase="MC.LIST"></app-admin-profiles>
      </section>
    </div>

  </div>
</div>


<!--The list of available settings-->
<ng-template #option>
  <h2 i18n>Options</h2>
  <div *ngFor="let item of menuItems">
    <div (click)="onSelectedMenuItem(item)"
         [class.disabled-selected-item]="item['disabled']"
         [class.selected-item]="item['selected']"
         class="menu-item p-p-2 p-d-flex p-ai-center unselectable c-pointer rounded-small"
         pRipple>
      <i [class]="item.icon" class="p-mr-2"></i>
      <div>{{ item.title }}</div>
    </div>
  </div>
</ng-template>
