<!--Profile-->
<ng-container *ngIf="profile">
  <div class="p-d-flex p-ai-center p-jc-center">
    <img [src]="profile | avatar: fileName"
         [alt]="profile?.charId + ' avatar'"
         [class.box-shadow]="shadow"
         [class.c-pointer]="redirect || showTooltip"
         (click)="onRedirect(profile?.charId)"
         [pTooltip]="profile?.displayName"
         tooltipPosition="top"
         [tooltipDisabled]="!showTooltip"
         [appProfileBorder]="profile"
         [borderVisible]="typeBorder"
         [style.width]="sizePx + 'px'"
         [style.height]="sizePx + 'px'"
         [style]="singleAvatarStyles">
  </div>
</ng-container>

<!--Others icon-->
<ng-container *ngIf="others">
  <div class="others p-d-flex p-ai-center p-jc-center"
       [style.background-color]="othersColor"
       [style.width]="sizePx + 'px'"
       [style.height]="sizePx + 'px'">
    <div class="p-d-flex p-ai-center p-jc-center">
      <span>+{{(others)}}</span>
    </div>
  </div>
</ng-container>

<!--Group several avatars together-->
<ng-container *ngIf="profiles">
  <div class="group p-d-flex"
       [style.width]="calcWidth() + 'px'"
       [style.height]="sizePx + 'px'">
    <div *ngFor="let profile of profiles |slice:0:groupMax; index as i">
      <img [src]="profile | avatar: '50'"
           [alt]="profile?.charId + ' avatar'"
           [class.box-shadow]="shadow"
           [class.c-pointer]="redirect || showTooltip"
           (click)="onRedirect(profile?.charId)"
           [pTooltip]="profile?.displayName"
           [tooltipDisabled]="!showTooltip"
           tooltipPosition="top"
           (ngInit)="setElementStyle($event, i)">
    </div>
  </div>
</ng-container>

<ng-template [ngIf]="!profile">
  <p-skeleton
    shape="circle"
    [size]="sizePx + 'px'"
  ></p-skeleton>
</ng-template>
