import {Component, OnInit} from '@angular/core'
import {
  InboxNotificationResp,
  NotificationService,
  NotificationStatusUpdateReq
} from "../../service/notification.service"
import {firstValueFrom, Observable} from "rxjs"
import {ApiComponent} from "../abstract/api.component"
import {appendNewPage, newEmptyPage, Page} from "../../rest/page-resp"
import {fadeAnimation} from "../../animation/fade.animation"
import {NavigationService} from "../../service/ui/navigation.service"

@Component({
  animations: [fadeAnimation()],
  selector: 'app-notification-inbox',
  templateUrl: './notification-inbox.component.html',
  styleUrl: './notification-inbox.component.scss'
})
export class NotificationInboxComponent extends ApiComponent implements OnInit {

  /**
   * List of notifications loaded from the backend.
   */
  notifications: Page<InboxNotificationResp> = newEmptyPage()

  constructor(private notificationService: NotificationService,
              protected navigationService: NavigationService) {
    super()
  }

  ngOnInit(): void {
    this.loadNextPage(true)
  }

  /**
   * Load next page of notifications or override all notifications.
   * @param overrideAll - if true, all notifications will be overridden.
   */
  async loadNextPage(overrideAll: boolean = false): Promise<void> {
    await this.customCall(async () => {
      if (overrideAll) {
        this.notifications = newEmptyPage()
        this.loading = true
      }

      this.notifications.nextPageLoading = true
      const page = await firstValueFrom(this.loadNotifications(this.notifications.nextPage))

      if (overrideAll) {
        this.notifications = page
        this.notifications.nextPage = 1
      } else {
        appendNewPage(this.notifications, page)
        this.notifications.nextPage += 1
      }
    }, null, () => {
      this.loading = false
      this.notifications.nextPageLoading = false
    })
  }

  /**
   * A side action to be performed when a notification is clicked. Marks the notification as seen if it wasn't already.
   */
  async notificationAction(notification: InboxNotificationResp): Promise<void> {
    if (!notification.seen) {
      await this.customCall(async () => {
        await firstValueFrom(this.callUpdateNotificationSeenStatus(notification.id, true))
      })
      // If the notification has no URL, mark it as seen locally to update the UI.
      if (!notification.url) {
        this.markLocalAsSeen(notification)
      }
    }
  }

  callUpdateNotificationSeenStatus(id: number, seen: boolean): Observable<InboxNotificationResp> {
    const req: NotificationStatusUpdateReq = {
      id,
      seen
    }
    return this.unwrap(this.notificationService.callUpdateNotificationSeenStatus(req))
  }

  /**
   * Marks all notifications as seen and reloads all notifications.
   */
  async markAllAsSeen(): Promise<void> {
    await this.call(async () => {
      await firstValueFrom(
        this.unwrap(this.notificationService.callMarkAllAsSeen())
      )
    })
    this.loadNextPage(true)
  }

  /**
   * Mark single notification as seen locally to update the UI.
   */
  markLocalAsSeen(notification: InboxNotificationResp): void {
    const index = this.notifications.content.findIndex(n => n.id === notification.id)
    if (index !== -1) {
      this.notifications.content[index].seen = true
    }
  }

  /**
   * A track by function for the data-view elements.
   */
  trackBy(index: number, item: InboxNotificationResp): number {
    return item.id
  }

  /**
   * Load notifications from the server.
   */
  loadNotifications(pageNum: number): Observable<Page<InboxNotificationResp>> {
    return this.unwrap(this.notificationService.callGetAllNotifications({
      page: pageNum
    }))
  }
}
