<!--Scroller-->
<div *ngIf="visible; else background" class="p-relative">
  <div class="loading top"
       *ngIf="showTopLoading"
       [@fade]="showTopLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></div>

  <p-virtualScroller #scroller
                     *ngIf="!value?.content || value.content.length !== 0; else background"
                     [value]="value?.content"
                     styleClass="scroller">
    <ng-template pTemplate="item" let-item>
      <ng-container *ngTemplateOutlet="itemTemplate?.template; context: {$implicit: item}"></ng-container>
    </ng-template>
    <ng-template pTemplate="loadingItem" let-item>
      <ng-container *ngTemplateOutlet="loadingTemplate?.template; context: {$implicit: item}"></ng-container>
    </ng-template>
  </p-virtualScroller>

  <div class="loading bottom"
       *ngIf="showBottomLoading"
       [@fade]="showBottomLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
</div>

<!--Background-->
<ng-template #background>
  <div class="background"></div>
</ng-template>
