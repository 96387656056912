import {Component, EventEmitter, Input, Output} from '@angular/core'
import {NgIf} from '@angular/common'
import {CollapsibleElementComponent} from '../../../common/collapsible-element/collapsible-element.component'

@Component({
  selector: 'app-order-segment',
  templateUrl: './order-segment.component.html',
  styleUrls: ['./order-segment.component.scss'],
  imports: [
    NgIf,
    CollapsibleElementComponent
  ],
  standalone: true
})
export class OrderSegmentComponent {
  /**
   * Represents icon of the order segment.
   */
  @Input()
  titleIcon: string
  /**
   * Represents title of the segment.
   */
  @Input({required: true})
  title: string
  /**
   * Displays at the right side of the title.
   */
  @Input()
  rightIcon: string
  /**
   * Emits when a user clicks on the {@link rightIcon}.
   */
  @Output()
  rightIconClick = new EventEmitter<any>()
  /**
   * Gets applied on the first element.
   */
  @Input()
  styleClass: string
}
