<!--Professions content-->
<div class="wrapper {{styleClass}}" *ngIf="type === 'button' && canHaveProfessions">
  <div class="p-d-flex p-ai-center">
    <!--Required icon-->
    <i *ngIf="requiredProf"
       class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
       pTooltip="This field is required"
       i18n-pTooltip
       tooltipPosition="top"></i>
    <h4 [class.p-my-2]="requiredProf"
        [class.failed-text]="requiredProf && isError && data.professions.length === 0"
        i18n>Professions</h4>
  </div>

  <div class="p-grid p-mb-3"
       *ngIf="data?.professions?.length > 0; else noContent">
    <div *ngFor="let profession of data?.professions | slice:0:Restrictions.MAX_PROFESSION_PER_PROFILE">
      <!--Content-->
      <div class="p-col p-d-flex p-ai-center">
        <app-button [label]="profession.name"
                    icon="fa-solid fa-bookmark"
                    styleClass="p-button-rounded p-button-secondary profession-btn"></app-button>
      </div>
    </div>
    <!--Edit button-->
    <div class="p-col p-d-flex p-ai-center">
      <ng-template [ngTemplateOutlet]="editButton"></ng-template>
    </div>
  </div>
</div>

<!--Edit button-->
<ng-template #editButton>
  <app-button type="edit"
              pTooltip="Edit your profession."
              i18n-pTooltip
              tooltipPosition="top"
              (clicked)="showEditComponent()"></app-button>
</ng-template>

<!--Displays professions as an inline text-->
<div *ngIf="type === 'text' && canHaveProfessions" class="text p-d-flex {{styleClass}}"
     (click)="showEditComponent()">
  <i class="fa-solid fa-graduation-cap p-mr-2 p-mt-1" pTooltip="Profession" i18n-pTooltip tooltipPosition="top"></i>
  <div class="prof-name word-break {{lineCount}}">{{ data?.professions | join: 'name' }}</div>
  <span *ngIf="data?.professions?.length === 0" class="p-p-0" i18n>Unspecified</span>
</div>

<!--No content-->
<ng-template #noContent>
  <div class="p-mb-3"
       [class.failed-box]="requiredProf && isError && data.professions.length === 0"
       [class.c-pointer]="editButtonVisible"
       (click)="showEditComponent()">
    <app-no-content class="w-100" i18n>
      No professions assigned yet.
    </app-no-content>
  </div>
  <ng-template [ngTemplateOutlet]="editButton"></ng-template>
</ng-template>

<!--Edit Component-->
<app-dialog *ngIf="editComponentVisible"
            [(show)]="editComponentVisible"
            (ngInit)="editComponentVisibleChange.emit(true)"
            (showChange)="editComponentVisibleChange.emit($event)"
            [component]="this"
            url="professions"
            header="Edit your profile professions"
            i18n-header
            successMessage="Your professions have been saved."
            i18n-successMessage
            (discard)="closeEditComponent()"
            [save]="onComponentSave.bind(this)"
            #dialog>

  <app-hint i18n>
    Select up to {{ Restrictions.MAX_PROFESSION_PER_PROFILE }} professions that describe this profile.
    You can pick some that are most used, or search for your own. <br>
    Some professions are specified generally like <b>Musician</b> which covers
    <b>Guitarist, Pianist</b>, etc.
    You can use <b>Skills</b> to specify your focus later.
  </app-hint>

  <!--No content-->
  <div class="p-my-2">
    <app-no-content *ngIf="selectedProfessions?.length === 0" i18n>
      No professions assigned yet.
    </app-no-content>
  </div>

  <!--Assigned Professions-->
  <div class="p-grid p-mb-3" *ngIf="selectedProfessions?.length > 0" @grow [@.disabled]="!isScreenOf(Screen.MD)">
    <div *ngFor="let profession of selectedProfessions">
      <div class="p-col">
        <app-button icon="fa-solid fa-xmark"
                    iconPos="right"
                    styleClass="p-button-rounded p-button-secondary profession-btn"
                    [label]="profession.name"
                    (clicked)="remove(profession)"></app-button>
      </div>
    </div>
  </div>

  <div class="p-d-flex p-jc-between">
    <h3 class="p-m-0" i18n>Pick some professions</h3>
    <!-- Search -->
    <app-button type="text"
                icon="fa-solid fa-magnifying-glass"
                styleClass="p-ml-2"
                (clicked)="scrollToSearch()"></app-button>
  </div>

  <!--Most Used-->
  <div class="p-grid p-mb-3" *ngIf="mostUsedItems?.length > 0" @grow [@.disabled]="!isScreenOf(Screen.MD)">
    <div *ngFor="let item of mostUsedItems | slice: 0:(isScreenOf(Screen.MD)? 20 : 5)">
      <div class="p-col">
        <div class="attribute-item-placeholder p-d-flex p-ai-center unselectable c-pointer"
             [class.most-selected]="item.selected"
             pRipple
             (click)="add(item, false)">
          <i class="fa-solid fa-plus-circle p-mr-2"></i>
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <app-search [component]="this"
              [length]="{max: Restrictions.MAX_PROFESSION_NAME_LENGTH}"
              placeholder="Musician, DJ, Comedian, ..."
              styleClass="search-attribute"
              i18n-placeholder
              [searchFunction]="callSearchProfessions.bind(this)"
              [disableResult]="selectedProfessions.length >= Restrictions.MAX_PROFESSION_PER_PROFILE"
              [(items)]="searchPage"
              [beforeAppend]="markAsSelected.bind(this)"
              (itemsChange)="dialog?.scrollBottom()"
              [itemHeight]="55"
              [maxScrollHeight]="24*16"
              #searchIn
              (ngInit)="searchInput = searchIn">

    <ng-template pTemplate="validation" let-form="form" let-field="field">
      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" [field]="field" error="profession" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFESSION_TOO_MANY" i18n>
        Only {{ Restrictions.MAX_PROFESSION_PER_PROFILE }} items are allowed.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFESSION_NOT_FOUND" i18n>
        The '{{ returnMissingProfession()?.name || 'profession' }}' does not exist anymore.
      </app-backend-validation>

      <p class="field-description" *ngIf="selectedProfessions.length >= Restrictions.MAX_PROFESSION_PER_PROFILE" i18n>
        Only {{ Restrictions.MAX_PROFESSION_PER_PROFILE }} items are allowed.
      </p>
    </ng-template>

    <!--Item template-->
    <ng-template let-profession pTemplate="item">
      <div class="w-100 p-p-1">
        <div class="p-grid" pRipple (click)="add(profession, true)">
          <div class="p-col-12 p-sm-9 p-d-flex p-ai-center p-p-0">
            <app-button icon="fa-solid {{profession.selected ? 'fa-check' : 'fa-bookmark'}}"
                        styleClass="p-button-secondary {{profession.selected ? '' : 'p-button-outlined'}}"
                        [label]="profession.name"></app-button>
          </div>
        </div>
      </div>
    </ng-template>

    <!--No content-->
    <ng-template pTemplate="noContent">
      <div class="p-d-flex p-jc-center p-ai-center">
        <i class="fa-solid fa-heart-crack p-mr-2"></i>
        <div i18n> Nothing found</div>
      </div>
    </ng-template>
  </app-search>
</app-dialog>
