import {Restrictions} from '../../common/restrictions'
import {ServerMessage} from '../../common/server-message'
import {hasFeatures, ProfileType} from '../../common/profile-type'
import {ImageVariant} from '../../common/image-variant'
import {deviceScreenMatchesWidth, ScreenSize} from '../../utils/device.utils'
import {Feature} from '../../common/feature'

/**
 * Defines very fundamental properties common for most of the components.
 */
export abstract class AbstractComponent {

  ServerMessage: typeof ServerMessage = ServerMessage
  Restrictions: typeof Restrictions = Restrictions
  ProfileType: typeof ProfileType = ProfileType
  ImageVariant: typeof ImageVariant = ImageVariant
  Feature: typeof Feature = Feature

  hasFeatures: typeof hasFeatures = hasFeatures

  Screen: typeof ScreenSize = ScreenSize

  /**
   * Access to the dev console.
   */
  console: typeof console = console

  /**
   * Returns true if the screen width matches at least one of the given sizes.
   */
  isScreenOf(...sizes: ScreenSize[]): boolean {
    for (const s of sizes) {
      if (deviceScreenMatchesWidth(s)) {
        return true
      }
    }
    return false
  }

  /**
   * Returns true if the screen width is between the {@link fromPx} and {@link toPx} size.
   */
  isScreenBetween(fromPx: number, toPx: number): boolean {
    return this.isScreenOf(fromPx) && !this.isScreenOf(toPx)
  }
}
