import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {AbstractFormField} from '../abstract-form-field'
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {NgIf} from '@angular/common'
import {CheckboxModule} from 'primeng/checkbox'
import {FrontendValidationComponent} from '../../frontend-validation/frontend-validation.component'
import {TooltipModule} from 'primeng/tooltip'
import {VarDirective} from '../../../../directive/var.directive'
import {throwAppError} from '../../../../utils/log.utils'

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [
    ReactiveFormsModule,
    NgIf,
    CheckboxModule,
    FrontendValidationComponent,
    FormsModule,
    TooltipModule,
    VarDirective
  ],
  standalone: true
})
export class CheckboxComponent extends AbstractFormField implements OnInit, AfterViewInit {
  /**
   * Sets the input binary value.
   */
  @Input()
  check: boolean
  /**
   * On value changes.
   */
  @Output()
  checkChange = new EventEmitter<boolean>()

  constructor() {
    super()
  }

  ngOnInit(): void {
    if ((this.form || this.formFieldName) && (this.check)) {
      throw Error($localize`You can\'t use the \'NgModel\' with \'FormGroup\' together.`)
    }

    super.ngOnInit()
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit()
  }

  override doFocus(): void {
    throwAppError('CheckBox', 'doFocus not implemented')
  }

  override initValidators(): void {
    super.initValidators()

    // Required True
    if (this.required) {
      this.addValidator(Validators.requiredTrue)
    } else {
      this.removeValidator(Validators.requiredTrue)
    }
  }

  /**
   * Inverts the value of the checkbox in the form driven template if it is not disabled.
   */
  invertValue(): void {
    if (this.form.controls[this.formFieldName].disabled) {
      return
    }
    const value = this.form.controls[this.formFieldName].value
    this.form.controls[this.formFieldName].setValue(!value)
  }

  /**
   * Fires when the user clicks on the label.
   * Changes the {@link checkChange} only when the checkbox is enabled.
   */
  onLabelClick(): void {
    if (!this.form || this.form?.controls[this.formFieldName].enabled) {
      this.checkChange.emit(!this.check)
    }
  }
}
