<!-- Desktop Inventory list -->
<div *ngIf="isScreenOf(desktopLayout)"
     class="summary-right rounded-medium p-mt-4 p-py-3 p-px-3">
  <h1 class="p-mt-0 p-mb-2" i18n>Summary</h1>
  <ng-container [ngTemplateOutlet]="inventoryList"></ng-container>

  <!--Actions footer-->
  <ng-container *ngTemplateOutlet="actionsFooter"></ng-container>
</div>

<!--Mobile Footer Layout-->
<div *ngIf="!isScreenOf(desktopLayout)"
     class="mobile-layout rounded-medium w-100 p-px-2 p-px-md-4 p-pb-3 p-pt-2">
  <!-- Total price && Law checkboxes visibility -->
  <!-- If at least one checkbox is clicked, then its visible -->
  <ng-container *ngTemplateOutlet="priceFooter"></ng-container>

  <!-- Detail and Checkout Buttons -->
  <div class="p-d-flex p-jc-between p-ai-center p-mt-2">

    <app-button label="Detail"
                i18n-label
                type="text"
                icon="fa-solid fa-magnifying-glass"
                (clicked)="detailDialogVisible = true"></app-button>

    <div class="p-relative">
      <!--Checkout button overlay present if date is undefined-->
      <div *ngIf="!dates || dates.length !== 2 || disableCheckout || minimalDistance"
           class="disabledButtonOverlay p-absolute c-pointer"
           (click)="scrollToCalendar()"></div>

      <!-- Checkout button -->
      <div *ngIf="priceItems.length !== 0">
        <app-button label="Continue"
                    i18n-label
                    type="cta"
                    icon="fa-solid fa-arrow-right"
                    iconPos="right"
                    [highlight]="true"
                    [disabled]="priceItems.length === 0"
                    (clicked)="onCheckout()"></app-button>
        <div *ngIf="priceItems.length !== 0">
        <span
          class="badge p-d-flex p-jc-center p-ai-center p-absolute p-text-bold">{{ priceItems.length + additionItems.length }}</span>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Inventory list of selected items -->
<ng-template #inventoryList>
  <div class="pi-addition-wrapper p-text-center">

    <!-- Selected price items -->
    <div
      *ngIf="(priceItems.length !== 0 || additionItems.length !== 0); else noContent">

      <p-selectButton *ngIf="canHeaderOptionsBeVisible()"
                      [options]="headerOptions"
                      [(ngModel)]="selectedHeaderOption"
                      [allowEmpty]="false"
                      optionLabel="label"
                      optionValue="id"
                      styleClass="p-mt-2"></p-selectButton>

      <div
        *ngIf="canHeaderOptionsBeVisible() ? selectedHeaderOption === HeaderOption.PRICE_ITEM : priceItems.length > 0">
        <!-- Selected price items -->
        <ng-container [ngTemplateOutlet]="itemsList"
                      [ngTemplateOutletContext]="{$implicit: true}"></ng-container>
      </div>

      <!-- Selected additions -->
      <div
        *ngIf="canHeaderOptionsBeVisible() ? selectedHeaderOption === HeaderOption.ADDITIONS : additionItems.length > 0">
        <!-- Hint while empty price items array with some additions -->
        <div *ngIf="priceItems.length === 0 && additionItems.length !== 0"
             class="p-mt-2">
          <app-hint>
            <article i18n>
              In order to <b> purchase </b> additional items, you must order <b> at least one </b> price item service.
            </article>
          </app-hint>
        </div>
        <ng-container [ngTemplateOutlet]="additionsList"
                      [ngTemplateOutletContext]="{$implicit: true}"></ng-container>
      </div>
    </div>
  </div>

  <!--Price Footer-->
  <ng-container *ngTemplateOutlet="priceFooter"></ng-container>
</ng-template>


<!-- Show as dialog -->
<app-dialog *ngIf="detailDialogVisible"
            [(show)]="detailDialogVisible"
            [component]="this"
            [allowSaveOption]="false"
            url="summary"
            type="info"
            header="Summary"
            i18n-header
            #detailDialog>
  <div class="detail-dialog">
    <ng-template [ngTemplateOutlet]="inventoryList"></ng-template>
  </div>

  <!--Bottom bar-->
  <ng-template pTemplate="bottomBar">
    <ng-container [ngTemplateOutlet]="actionsFooter" [ngTemplateOutletContext]="{dialog: detailDialog}"></ng-container>
  </ng-template>

</app-dialog>

<!-- Displays lazy list of the selected price item additions with let-deleteIconVisible,
if this parameter is true, shows the <app-button .... -->
<ng-template #additionsList let-deleteIconVisible>
  <div *ngIf="additionItems.length !== 0">
    <app-list [(items)]="additionItems"
              [scrollHeight]="priceItems.length === 0 ? '23rem' : '27rem'"
              [virtual]="false"
              overlayClass="search-lazy-list-overlay">
      <!--Item content-->
      <ng-template let-addition pTemplate="item">
        <div class="item rounded-medium p-py-2 p-px-3 p-my-2 p-mx-1">
          <div class="p-d-flex p-ai-center p-jc-between">
            <h4 class="word-break p-text-left p-m-0">{{ addition.name }}</h4>
            <app-button *ngIf="deleteIconVisible"
                        type="icon"
                        icon="fa-solid fa-xmark"
                        pTooltip="Delete"
                        i18n-pTooltip
                        tooltipPosition="top"
                        styleClass="p-button-danger p-p-0"
                        (clicked)="deleteSelectedAddition(addition)"></app-button>
          </div>
          <div class="price p-d-flex p-jc-end p-text-bold word-break p-pl-2">{{ addition.price | price }}</div>
        </div>
      </ng-template>
    </app-list>
  </div>
</ng-template>

<!-- List of selected items -->
<ng-template #itemsList let-showPriceItem>
  <div *ngIf="priceItems.length !== 0">
    <app-list [(items)]="priceItems"
              [virtual]="false"
              scrollHeight="27rem">
      <!--Item content-->
      <ng-template let-item pTemplate="item">
        <div *ngIf="showPriceItem"
             class="item rounded-medium p-mx-1 p-text-left">
          <app-profile-price-item layout="brief"
                                  [item]="item"
                                  [enableDeleteButton]="true"
                                  [data]="data"
                                  [dates]="dates"
                                  (delete)="deleteSelectedItem(item)"></app-profile-price-item>
        </div>

        <div *ngIf="!showPriceItem"
             class="event-info p-d-flex p-jc-between word-break rounded-medium p-text-bold p-p-2 p-my-2 p-mx-1">
          <div>{{ item.name }}</div>
          <div>{{ item.price | price }}</div>
        </div>
      </ng-template>
    </app-list>
  </div>
</ng-template>

<!-- Price footer of the inventory list -->
<ng-template #priceFooter>
  <div class="p-d-flex p-jc-between p-text-bold p-my-2 p-p-2">
    <div>
      <div class="sum-text p-text-bold p-my-0" i18n>Total:</div>
      <small class="not-pay-small p-text-italic p-text-normal"
             *ngIf="priceItems.length !== 0 && !isScreenOf(Screen.LG)" @grow
             i18n>You will not pay anything yet</small>
    </div>

    <div class="p-d-inline-flex">
      <div class="total p-text-normal p-px-2 p-my-0" i18n>From</div>
      <div class="total word-break p-my-0">{{ orderTotal | price }}</div>
    </div>
  </div>
</ng-template>

<!--Actions Footer-->
<ng-template #actionsFooter let-dialog="dialog">
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2 w-100">
    <!--Clear all-->
    <app-button label="Clear"
                i18n-label
                type="danger-text"
                icon="fa-solid fa-trash-can"
                styleClass="p-mr-2"
                [labelAutoHide]="true"
                [disabled]="additionItems.length === 0 && priceItems.length === 0"
                (clicked)="clearAll(); dialog?.onDiscard()"></app-button>

    <!-- Discard -->
    <app-button *ngIf="dialog"
                type="text"
                label="Discard"
                i18n-label
                styleClass="p-mr-2"
                (clicked)="dialog.onDiscard()"></app-button>

    <div class="p-relative">
      <!--Checkout button overlay present if date is undefined-->
      <div *ngIf="!dates || dates.length !== 2 || disableCheckout || minimalDistance"
           class="disabledButtonOverlay p-absolute c-pointer"
           (click)="discardDetail(dialog)"></div>

      <!-- Checkout button -->
      <div *ngIf="priceItems.length !== 0" @fade>
        <app-button label="Continue"
                    i18n-label
                    type="cta"
                    [highlight]="true"
                    icon="fa-solid fa-arrow-right"
                    iconPos="right"
                    [disabled]="priceItems.length === 0"
                    (clicked)="onCheckout(); dialog?.onDiscard()"></app-button>

        <span
          class="badge p-d-flex p-jc-center p-ai-center p-absolute p-text-bold">{{ priceItems.length + additionItems.length }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="priceItems.length !== 0 && isScreenOf(Screen.LG)" @grow
       class="p-text-center p-px-2 p-my-2" i18n>You will not pay anything yet
  </div>
</ng-template>

<!-- No content icon -->
<ng-template #noContent>
  <i class="no-content-icon fa-solid fa-shopping-basket"></i>
</ng-template>
