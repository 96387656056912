import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http'
import {firstValueFrom, from, map, Observable, throwError} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {AppModule} from '../app.module'
import {JwtService} from './helper-services/jwt.service'
import {ApiComponent} from '../component/abstract/api.component'
import {catchError} from 'rxjs/operators'
import {DeviceService} from './helper-services/device.service'
import {LanguageService} from './language.service'

/**
 * The abstract service that performs HTTP requests.
 */
export abstract class ApiService {
  protected http: HttpClient
  protected jwtService: JwtService
  protected deviceService: DeviceService
  protected languageService: LanguageService

  protected constructor() {
    this.http = AppModule.injector.get(HttpClient)
    this.jwtService = AppModule.injector.get(JwtService)
    this.deviceService = AppModule.injector.get(DeviceService)
    this.languageService = AppModule.injector.get(LanguageService)
  }

  /**
   * {@link HttpHeaders} with device info, for request that requires them.
   * Used mainly for login and register requests.
   */
  protected get deviceHeaders(): any {
    return {
      withCredentials: true,
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('User-Device', this.deviceService.getDevice())
        .set('Language', this.languageService.getCurrentLanguage())
    }
  }

  /**
   * - Makes the HTTP POST call to the specific URL with a specific body and headers.
   * - The {@link needsAuthenticated} parameter means that if in the time of this request there are no valid authorization tokens,
   * it will drop the request and return to the login page.
   */
  protected post<T>(
    url: string,
    body: any,
    headers: any = this.getHeaders(),
    needsAuthenticated: boolean = true
  ): Observable<BaseResp<T>> {
    if (needsAuthenticated) {
      return this.authenticatedRequest(() => (this.http.post(url, body, headers) as Observable<BaseResp<T>>))
    }
    return this.http.post(url, body, headers) as Observable<BaseResp<T>>
  }

  /**
   * - Makes the HTTP POST call to the specific URL with a specific body and headers that allow reporting progress.
   * - The {@link needsAuthenticated} parameter means that if in the time of this request there are no valid authorization tokens,
   * it will drop the request and return to the login page.
   */
  protected progressPost<T>(
    url: string,
    body: any,
    headers: any = this.getProgressHeaders(),
    needsAuthenticated: boolean = true
  ): Observable<HttpEvent<BaseResp<T>>> {
    if (needsAuthenticated) {
      return this.authenticatedRequest(() => (this.http.post(url, body, headers) as unknown as Observable<HttpEvent<BaseResp<T>>>))
    }
    return this.http.post(url, body, headers) as unknown as Observable<HttpEvent<BaseResp<T>>>
  }

  /**
   * Makes the HTTP GET call to the specific URL with specific headers.
   * - The {@link needsAuthenticated} parameter means that if in the time of this request there are no valid authorization tokens,
   * it will drop the request and return to the login page.
   */
  protected get<T>(
    url: string,
    headers: any = this.getHeaders(),
    needsAuthenticated: boolean = true
  ): Observable<BaseResp<T>> {
      // this.checkSession()
    if (needsAuthenticated) {
      return this.authenticatedRequest(() => (this.http.get(url, headers) as Observable<BaseResp<T>>))
    }
    return this.http.get(url, headers) as Observable<BaseResp<T>>
  }

  /**
   * - Creates an authorized request to the API server.
   *
   * - Firstly checks if the browser cookies contain required tokens for authentication.
   * Throws an error {@link ApiComponent.REQUIRED_AUTHENTICATION} if there are no token.
   * This error performs the immediately return to the login page and no request will be performed.
   * See the {@link ApiComponent.unwrap} function.
   *
   * - Otherwise, it checks whether the refresh token is present and the access token is missing - ({@link JwtService.jwtNeedsRefresh}).
   * Furthermore, it checks if there is any currently running request that will bring the required cookies to the browser inside
   * its headers. If there's no running request, this request will pass immediately and other requests will wait for this one to
   * bring the cookies from the server. Then other requests will be executed with newly created tokens.
   *
   * @param call The request function.
   */
  private authenticatedRequest<T>(call: () => Observable<T>): Observable<T> {
    // need to authenticate before any request
    if (this.jwtService.toLoginIfNoToken()) {
      return throwError(() => new Error(ApiComponent.REQUIRED_AUTHENTICATION))
    }

    const jwtNeedsRefresh = this.jwtService.jwtNeedsRefresh()
    const shouldWaitForJwt = this.jwtService.runningJwtRequest.getValue()

    // if the jwt needs to refresh, and it is a first request, run immediately
    if (jwtNeedsRefresh && !shouldWaitForJwt) {
      this.jwtService.runningJwtRequest.next(jwtNeedsRefresh)
      return call().pipe(
        map(it => {
          this.jwtService.runningJwtRequest.next(false)
          return it
        }),
        catchError(err => {
          this.jwtService.runningJwtRequest.next(false)
          throw err
        })
      )
    }

    // if the jwt needs to refresh, but needs to wait for tokens
    if (jwtNeedsRefresh && shouldWaitForJwt) {
      return from(new Promise<T>((resolve) => {
        const sub = this.jwtService.runningJwtRequest.subscribe(async running => {
          if (!running) {
            const value = await firstValueFrom(call())
            resolve(value)
            sub.unsubscribe()
          }
        })
      }))
    }

    // all tokens are present
    return call()
  }

  /**
   * Returns the headers with the current language.
   * @protected
   */
  protected getHeaders(): any {
    return {
      withCredentials: true,
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Language', this.languageService.getCurrentLanguage())
    }
  }

  /**
   * Returns the form headers with the current language.
   * @protected
   */
  protected getFormHeaders(): any {
    return {
      withCredentials: true,
      headers: new HttpHeaders()
        .set('Language', this.languageService.getCurrentLanguage())
    }
  }

  protected getProgressHeaders(): any {
    return {
      withCredentials: true,
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders()
        .set('Language', this.languageService.getCurrentLanguage())
    }
  }
}
