<h2 class="title p-py-2 p-text-uppercase" i18n>Login</h2>
<form [formGroup]="form"
      (keyup.enter)="login()">

  <!--E-MAIL-->
  <app-text-input label=""
                  placeholder="Email"
                  i18n-placeholder
                  inputType="email"
                  formFieldName="email"
                  styleClass="p-mb-3"
                  [space]="false"
                  [copy]="false"
                  [form]="form"
                  [required]="true"
                  [email]="true"
                  [length]="{min: Restrictions.MIN_EMAIL_LENGTH}">

    <!--BE-CHECK-->
    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.USER_NOT_FOUND" i18n>
      User not registered.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
      Profile not found.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_EMAIL_INCORRECT" i18n>
      Not a valid email.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.FIREBASE_NO_PASSWORD_NEEDED"
                            i18n>
      This e-mail is already logged in with your Google account, please Log in with Google.
    </app-backend-validation>
  </app-text-input>

  <!--PASSWORD-->
  <app-text-input label=""
                  placeholder="••••••••••••"
                  inputType="password"
                  formFieldName="password"
                  styleClass="password"
                  [space]="false"
                  [copy]="false"
                  [form]="form"
                  [required]="true"
                  [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}">
    <!--BE CHECK-->
    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.USER_WRONG_CREDENTIALS" i18n>
      Wrong e-mail or password.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_PASSWORD_INCORRECT"
                            i18n>
      Not a valid password.
    </app-backend-validation>
  </app-text-input>

  <!--Password Forgotten-->
  <div class="p-px-0 p-d-flex p-jc-end">
    <app-button type="text"
                label="Forgot password?"
                i18n-label
                icon="fa-solid fa-unlock-keyhole"
                styleClass="p-p-0"
                [disabled]="saving"
                [routerLink]="['password-restore'] | routerLink"></app-button>
  </div>

  <app-checkbox label="Remember me"
                i18n-label
                [form]="form"
                [disable]="saving"
                styleClass="remember-checkbox p-mt-3"
                formFieldName="rememberMe"></app-checkbox>

  <!--Legal Documents-->
  <div class="legal-text p-mt-3" i18n>
    By logging in you accept the Umevia
    <a [routerLink]="toLegalDocuments('terms')" target="_blank" class="text-link">Terms of Services</a> and
    acknowledge the <a [routerLink]="toLegalDocuments('privacy')" target="_blank" class="text-link">Privacy
    Policy</a> and <a [routerLink]="toLegalDocuments('cookies')" target="_blank" class="text-link">Cookie
    Policy</a>.
  </div>

  <!-- BE - check of the profile with BAN status included -->
  <div class="p-m-2">
    <app-backend-validation [serverMessages]="serverMessages"
                            [showAsBox]="true"
                            [check]="ServerMessage.PROFILE_IN_BAN" i18n>
      This profile is <b>banned</b> due to platform rules violation.
    </app-backend-validation>
    <app-backend-validation [serverMessages]="serverMessages"
                            [showAsBox]="true"
                            [check]="ServerMessage.ALL_PROFILES_DELETED" i18n>
      The account has been <b>deleted</b> by the user, you can create a new account with the following details.
    </app-backend-validation>
  </div>

  <!--Action Buttons-->
  <div class="p-d-flex p-ai-center p-mt-3 p-grid">

    <!--Login-->
    <div class="p-col-12 p-px-0">
      <app-button type="primary"
                  label="Login"
                  i18n-label
                  styleClass="w-100 submit-button"
                  icon="fa-solid fa-paper-plane"
                  (clicked)="login()"
                  [loading]="saving"
                  [disabled]="form.invalid || form.disabled">
      </app-button>
    </div>

    <div class="p-col-12 p-px-0">
      <app-firebase-auth
        [authType]="AuthType.GOOGLE"
        [buttonDisabled]="socialButtonsDisabled"
        buttonStyle="default"
        (formDisabled)="disableForm($event)"
        (firebaseAuthResponse)="firebaseAuthResponseAction($event)">
      </app-firebase-auth>
    </div>
  </div>
</form>

<!--Response-->
<app-call-response [component]="this"
                   styleClass="p-mt-2"></app-call-response>
