import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Injectable} from '@angular/core'
import {ApiService} from './api.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileShowcaseService extends ApiService {

  /**
   * Updates the profile showcase.
   */
  callUpdateProfileEmbedLink(req: UpdateShowcaseReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.SHOWCASE_UPDATE, req)
  }
}

export interface ShowcaseResp {
  youtube?: string
  spotify?: string
  soundcloud?: string
}

export interface UpdateShowcaseReq {
  youtube?: string
  spotify?: string
  soundcloud?: string
}
