import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {BriefProfileResp} from './profile.service'
import {BehaviorSubject, Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Page} from '../rest/page-resp'
import {ProfileType} from '../common/profile-type'
import {MessageResp} from './chat-message.service'


@Injectable({
  providedIn: 'root'
})
export class ChatChannelService extends ApiService {

  /**
   * Used for communication between child and parent components.
   */
  channelChanged = new BehaviorSubject<ChannelResp>(null)
  /**
   * Determines whether the chat inbox should be opened.
   */
  chatInboxOpen = new BehaviorSubject(false)

  constructor() {
    super()
  }

  callGetChannel(req: GetChannelReq): Observable<BaseResp<ChannelResp>> {
    return this.post(Endpoint.CHAT_CHANNEL_GET, req)
  }

  callNewChannel(req: NewChannelReq): Observable<BaseResp<ChannelResp>> {
    return this.post(Endpoint.CHAT_CHANNEL_NEW, req)
  }

  callGetOrCreateChannel(req: NewChannelReq): Observable<BaseResp<ChannelResp>> {
    return this.post(Endpoint.CHAT_CHANNEL_GET_OR_CREATE, req)
  }

  callGetAllOfActiveOrders(page: number = 0): Observable<BaseResp<Page<ChannelResp>>> {
    return this.post(Endpoint.CHAT_CHANNEL_ALL_ACTIVE_ORDERS, page)
  }

  callExistsChatSuitableOrder(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_CHAT_SUITABLE_ORDER, null)
  }

  callUpdateChannel(req: UpdateChannelReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_UPDATE, req)
  }

  callUpdateSeen(req: UpdateChannelSeenReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_SEEN, req)
  }

  callGetAllUnread(req: GetAllUnreadChannelsReq): Observable<BaseResp<UnreadChannelResp>> {
    return this.post(Endpoint.CHAT_CHANNEL_UNREAD, req)
  }

  callSearchChannels(req: SearchChannelsReq): Observable<BaseResp<Page<ChannelResp>>> {
    return this.post(Endpoint.CHAT_CHANNEL_SEARCH, req)
  }

  callAllChannels(req: AllChannelsReq): Observable<BaseResp<Page<ChannelResp>>> {
    return this.post(Endpoint.CHAT_CHANNEL_ALL, req)
  }

  callLeaveChannel(req: LeaveChannelReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_LEAVE, req)
  }

  callAddToChannel(req: AddToChannelReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_ADD, req)
  }

  callDeleteChannel(req: DeleteChannelReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_DELETE, req)
  }

  callRenameChannel(req: RenameChannelReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_RENAME, req)
  }

  /**
   * Returns all chat channels that need to be reviewed by Umevia team.
   */
  callAllReview(page: number = 0): Observable<BaseResp<Page<ChannelResp>>> {
    return this.post(Endpoint.CHAT_CHANNEL_ALL_REVIEW, page)
  }

  /**
   * Unlocks the chat channel identified by {@link channelId} to become reviewable by other reviewers.
   */
  callUnlockChannel(channelId: number): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CHAT_CHANNEL_UNLOCK, channelId)
  }
}

export interface GetChannelReq {
  profileId: number
  channelId: number
}

export interface NewChannelReq {
  isDirect: boolean
  participants: number[]
  name?: string
}

export interface UpdateChannelReq {
  profileId: number
  channelId: number
  isDirect: boolean
  participants: number[]
  name?: string
}

export interface UpdateChannelSeenReq {
  channelId: number
  seenAt: Date
}

export interface GetAllUnreadChannelsReq {
  profileId: number
}

export interface UnreadChannelResp {
  id: number
  name?: string
  isDirect: boolean
}

export interface SearchChannelsReq {
  profileId: number
  isDirect: boolean
  profileType: ProfileType
  input: string
  page: number
}

export interface AllChannelsReq {
  profileId: number
  offsetDate: Date
  profileType?: ProfileType
  excludeTypes: ProfileType[]
  isDirect: boolean
  page: number
}

export interface ChannelResp {
  id: number
  name?: string
  isDirect: boolean
  participants: BriefChannelParticipant[]
  unreadMessages?: number
  // Front-End only
  messages?: Page<MessageResp>
}

export interface BriefChannelParticipant {
  profile: BriefProfileResp
  seenAt: Date
}

export interface LeaveChannelReq {
  profileId: number
  channelId: number
}

export interface AddToChannelReq {
  profileId: number
  channelId: number
  participants: number[]
}

export interface DeleteChannelReq {
  profileId: number
  channelId: number
}

export interface RenameChannelReq {
  profileId: number
  channelId: number
  name?: string
}
