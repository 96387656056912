import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {LoginComponent} from './login/login.component'
import {RegisterComponent} from './register/register.component'
import {RegisterFirebaseComponent} from './register-firebase/register-firebase.component'
import {TwoFactorAuthenticationComponent} from './two-factor-authentication/two-factor-authentication.component'
import {PhoneVerificationComponent} from './phone-verification/phone-verification.component'
import {FirebaseAuthComponent} from './firebase-auth/firebase-auth.component'
import {RouterModule} from '@angular/router'
import {NavigationService as NS} from '../../service/ui/navigation.service'
import {TextInputComponent} from '../../component/common/form/text-input/text-input.component'
import {ReactiveFormsModule} from '@angular/forms'
import {BackendValidationComponent} from '../../component/common/backend-validation/backend-validation.component'
import {CheckboxComponent} from '../../component/common/form/checkbox/checkbox.component'
import {ButtonComponent} from '../../component/common/button/button.component'
import {RouterLinkPipe} from '../../pipe/router-link.pipe'
import {DividerModule} from 'primeng/divider'
import {CallResponseComponent} from '../../component/common/call-response/call-response.component'
import {FrontendValidationComponent} from '../../component/common/frontend-validation/frontend-validation.component'
import {RadioButtonModule} from 'primeng/radiobutton'
import {UnsavedChangesComponent} from '../../component/common/unsaved-changes/unsaved-changes.component'
import {CountdownPipe} from '../../pipe/countdown-timer.pipe'
import {TwoFactorCodeRestoreComponent} from './two-factor-code-restore/two-factor-code-restore.component'
import {VarDirective} from '../../directive/var.directive'


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    RegisterFirebaseComponent,
    TwoFactorAuthenticationComponent,
    PhoneVerificationComponent,
  ],
  imports: [
    CommonModule,
    FirebaseAuthComponent,
    TwoFactorCodeRestoreComponent,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: NS.LOGIN,
        pathMatch: 'full'
      },
      {
        path: NS.LOGIN,
        component: LoginComponent
      },
      {
        path: NS.TWO_FACTOR_AUTHENTICATION,
        component: TwoFactorAuthenticationComponent
      },
      {
        path: NS.REGISTER,
        component: RegisterComponent
      },
      {
        path: `${NS.REGISTER_FIREBASE}/:${NS.SOCIAL_TYPE_PARAMETER}`,
        component: RegisterFirebaseComponent
      },
    ]),
    TextInputComponent,
    ReactiveFormsModule,
    BackendValidationComponent,
    CheckboxComponent,
    ButtonComponent,
    RouterLinkPipe,
    DividerModule,
    CallResponseComponent,
    FrontendValidationComponent,
    RadioButtonModule,
    UnsavedChangesComponent,
    CountdownPipe,
    VarDirective
  ]
})
export class AuthenticationModule {
}
