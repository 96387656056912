<nav class="p-d-flex p-ai-center {{styleClass}}" [style.z-index]="zIndex">
  <div class="p-d-flex p-ai-center">
    <app-button type="icon"
                icon="fa-solid fa-arrow-left"
                styleClass="p-mx-2"
                *ngIf="enableReturn"
                (clicked)="returnAction.emit($event)"></app-button>
    <h2>{{title}}</h2>
  </div>
  <!--Custom content on the right-->
  <div>
    <ng-content></ng-content>
  </div>
</nav>
