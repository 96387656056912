import {v4 as uuidv4} from 'uuid'
import {ElementRef} from '@angular/core'
import {BASE_SERVER_URL} from '../../environments/environment'
import {firstValueFrom, Observable} from 'rxjs'

export function isBlank(str): boolean {
  return (!str || /^\s*$/.test(str))
}

/**
 * Returns the input string all in lower case except the first character.
 */
export function capitalize(str: string): string {
  const lower = str.toLowerCase()
  return str.charAt(0).toUpperCase() + lower.slice(1)
}

/**
 * Extracts the {@link BASE_SERVER_URL} from the {@link environmentPath}.
 */
export function extractBasePath(environmentPath: string): string {
  const baseSiteLength = BASE_SERVER_URL.length
  const pathLength = environmentPath.length
  return environmentPath.slice(baseSiteLength, pathLength)
}

/**
 * Returns the upper camel case format of a string (e.g. sOmE  word -> Some Word)
 * @param str The input string.
 */
export function capitalizeWords(str: string): string {
  let capitalized = ''
  str.split(' ').forEach(word => {
    capitalized = capitalized.trim()
    capitalized += ' '
    capitalized += capitalize(word)
  })
  return capitalized.trim()
}

export function isArrayNullOrEmpty(array: any[]): boolean {
  return array === undefined || array === null || array.length === 0
}

/**
 * Returns true if the given element is focused.
 */
export function isFocused(element: ElementRef): boolean {
  return document.activeElement === element.nativeElement
}

/**
 * Returns an array of all enum values from the enum class.
 */
export function enumValues<T>(enumClass: any): T[] {
  return Object.keys(enumClass).map(it => enumClass[it])
}

/**
 * Generates a new random UUIDv4 of string type.
 */
export function createRandomUUID(): string {
  return uuidv4()
}

/**
 * Returns a Json string of given data.
 */
export function toJson(data: any, pretty: boolean = false): string {
  return JSON.stringify(data, null, pretty ? 2 : 0)
}

/**
 * Prints the data as a JSON in alert.
 */
export function alertData(data): void {
  alert(toJson(data, true))
}

/**
 * Prints the data as a JSON in the console.
 */
export function logData(data): void {
  console.log(toJson(data, true))
}

/**
 * Adds styles to the {@link source} string.
 * Returns the {@link source} appended with the <code>'style':'value';</code> value.
 */
export function addStyle(source: string = '', style: string, value: any): string {
  source += `${style}:${value};`
  return source
}

/**
 * - Blocks the history if the user wants to go back.
 * - Basically, it adds the current location.
 */
export function blockHistory(): void {
  history.pushState(null, null, window.location.href)
}

/**
 * Converts {@link degrees} to radians.
 */
export function toRadians(degrees: number): number {
  return degrees * Math.PI / 180
}

/**
 * A wrapper function for the `Promise.resolve(value)`.
 */
export function promiseOf<T>(value: T): Promise<T> {
  return Promise.resolve(value)
}

/**
 * - A function which ensures that the {@link body} will be running only as a single request.
 * - If you call this function again, the previous call gets terminated immediately.
 * - {@link holder} A value that holds information about the current running progress of the {@link body}.
 * - {@link delay} Whether this request has to be run with a delay.
 * Example:
 * <pre>
 *   // class member, holds the information in the first element of the array.
 *   dataRequest: any[] = [null]
 *
 *   // in a function - pass the entire array
 *   singleRequest(this.dataRequest, async () => {
 *     // ...
 *   })
 * </pre>
 */
export function singleRequest(holder: any[], body: () => Promise<any>, delay = 10): void {
  clearTimeout(holder[0])
  holder[0] = setTimeout(body, delay)
}

/**
 * Immediately drops the {@link singleRequest}.
 * @param holder The array used in the {@link singleRequest} function.
 */
export function dropSingleRequest(holder: any[]): void {
  clearTimeout(holder[0])
}

/**
 * - Use in scenarios, when you plan to abort the {@link observable} request with the {@link abortController}.
 * - When you abort the observable, this promise gets rejected, so you need to catch the error and check if the {@link abortController} has been aborted.
 * ```javascript
 * const abortController = new AbortController()
 * setTimeout(() => {
 *    abortController.abort() // cancel the request
 * }, 5000) // time to execute the request below
 *
 * try {
 *   const result = await abortableRequest(this.myObservable.bind(this), abortController)
 * } catch (err) {
 *    if (abortController.signal.aborted) {
 *      this.setFailed(true)
 *      return
 *    } else {
 *      throw err
 * }
 * ```
 */
export function abortableRequest<T>(observable: () => Observable<T>, abortController: AbortController): Promise<T> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    // Gets called from the 'controller'. Rejects the current promise.
    const abortListener = (): void => {
      abortController.signal.removeEventListener('abort', abortListener)
      reject({result: {aborted: true}})
    }
    abortController.signal.addEventListener('abort', abortListener)

    try {
      // calls to confirm the payment
      const result = await firstValueFrom(observable())
      abortController.signal.removeEventListener('abort', abortListener)
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
