import {Injectable} from '@angular/core'
import * as Sentry from '@sentry/angular-ivy'
import pkg from '../../../../package.json'
import {environment} from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SentryAnalyticsService {

  /**
   * Enables the sentry analytics.
   */
  enableSentry(enable: boolean): void {
    Sentry.init({
      dsn: 'https://ee5be284c4ad1234dc4c1c40745efa1d@o4506384921460736.ingest.sentry.io/4506395748597760',
      release: 'umevia-uip@' + pkg.version,
      debug: false,
      environment: environment.name,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', /^https:\/\/umevia\.com/]
        })
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysOnErrorSampleRate: 1.0,
      enabled: enable
    })
  }
}
