import {Injectable} from '@angular/core'
import {Language} from '../common/language'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * Holds the language preference of the current user.
   * @private
   */
  private userLanguage: Language = null

  /**
   * Update the language preference of the current user.
   * @param language
   */
  public setUserLanguage(language: Language): void {
    this.userLanguage = language
  }

  /**
   * Get the language the content should be fetched from the backend.
   */
  public getCurrentLanguage(): Language {
    return this.userLanguage || Language.SK
  }
}
