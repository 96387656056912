<p-sidebar [visible]="show"
           (visibleChange)="showChange.emit($event)"
           [baseZIndex]="11000">
  <div class="p-p-1">
    <!--Calendar title-->
    <h3 class="p-text-center p-m-0" i18n>Calendar</h3>
    <!--Calendar skeleton-->
    <div *ngIf="!data">
      <div class="p-py-0">
        <ng-template [ngTemplateOutlet]="calendarSkeleton"></ng-template>
      </div>
    </div>
    <!--Calendar-->
    <app-profile-calendar [data]="data"
                          [clickablePastDate]="false"
                          [editButtonVisible]="true"
                          [monthsVisible]="1"
                          class="calendar"></app-profile-calendar>

    <hr class="divider">

    <div *ngIf="data">
      <!--Bell icon-->
      <div *ngIf="profilesUnresolvedOrders?.content?.length > 0 && show" @grow
           class="p-d-flex p-jc-center p-ai-center p-mt-3">
        <i class="bell-icon fa-solid fa-bell fa-shake p-mr-3"></i>

        <!--Profiles title-->
        <h3 *ngIf="hasFeatures(data.profileType, Feature.BE_ORDERED)" class="p-my-0" i18n>
          Profiles with pending orders</h3>

        <h3 *ngIf="hasFeatures(data.profileType, Feature.ORDER_PROFILE)" class="p-my-0" i18n>
          Profiles with awaiting payment orders</h3>
      </div>

      <!--BE-CHECK-->
      <div class="p-m-2">
        <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                                [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
          The desired profile was not found.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                                [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                                i18n>
          You're not the owner of that profile.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                                [check]="ServerMessage.USER_NOT_FOUND"
                                i18n>
          User not registered.
        </app-backend-validation>
      </div>

      <!--  profiles of the user account -->
      <div *ngIf="profilesUnresolvedOrders?.content?.length > 0 && show" @grow class="profiles-container">
        <app-lazy-list [(items)]="profilesUnresolvedOrders"
                       [virtual]="false"
                       [skipInitialLoad]="true"
                       [component]="this"
                       [beforeAppend]="filterCurrentProfile.bind(this)"
                       overlayClass="search-lazy-list-overlay"
                       [loadFunction]="getUserProfilesWithOrders.bind(this, profilesUnresolvedOrders?.nextPage)">

          <!--Item content-->
          <ng-template let-profile pTemplate="item">

            <div
              class="user-profile p-d-flex p-jc-between p-ai-center rounded-medium p-my-2 p-py-2 p-px-3 w-100 c-pointer"
              (click)="onItemClick(profile)">
              <div class="p-d-flex p-ai-center">
                <!-- avatar -->
                <app-avatar [profile]="profile" [sizePx]="45"></app-avatar>

                <div class="p-mx-3">
                  <!-- displayName -->
                  <h3 class="p-my-0 word-break l-count-1">{{profile.displayName}}</h3>

                  <!--Professions-->
                  <div class="profession-container">
                    <app-profile-professions *ngIf="profile.professions?.length > 0"
                                             [data]="profile"
                                             type="text"
                                             styleClass="profession"></app-profile-professions>
                  </div>
                </div>
              </div>

              <!--Saving icon -->
              <i class="fa-solid {{profile.saving ? 'fa-spin fa-circle-notch' : 'fa-arrow-right'}}"></i>
            </div>
          </ng-template>

          <!--Loading skeleton-->
          <ng-template pTemplate="skeleton">
            <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
          </ng-template>

          <!--No content custom template-->
          <ng-template pTemplate="noContent">
            <ng-template [ngTemplateOutlet]="customNoContent"></ng-template>
          </ng-template>

          <!--BE-CHECK-->
          <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
            The desired profile was not found.
          </app-backend-validation>
        </app-lazy-list>
      </div>
    </div>
  </div>
</p-sidebar>

<!--no content-->
<ng-template #customNoContent>
  <div class="p-d-flex p-jc-center w-100">
    <i class="no-content-icon fa-solid fa-bell-slash"></i>
  </div>
</ng-template>

<!--Item skeleton-->
<ng-template #itemSkeleton>
  <div class="p-mt-5">
    <div class="p-mx-3 p-my-2" *ngFor="let i of [1,2,3]">
      <p-skeleton height="4rem"></p-skeleton>
    </div>
  </div>
</ng-template>

<!--Skeleton-->
<ng-template #calendarSkeleton>
  <div class="p-d-flex p-jc-center p-ai-center w-100">
    <div *ngFor="let cal of [].constructor(1); index as i">
      <table class="skeleton-width" [class.p-mr-4]="i === 0">
        <tr>
          <th i18n>Mon</th>
          <th i18n>Tue</th>
          <th i18n>Wed</th>
          <th i18n>Thu</th>
          <th i18n>Fri</th>
          <th i18n>Sat</th>
          <th i18n>Sun</th>
        </tr>
        <tr *ngFor="let row of [].constructor(6)">
          <td *ngFor="let cell of [].constructor(7)" class="p-p-1">
            <p-skeleton shape="circle" size="2.5rem"></p-skeleton>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
