import {Component, Input, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {BriefProfileResp, ProfileResp} from '../../../service/profile.service'
import {
  OpeningHoursReq,
  OpeningHoursResp,
  OpeningHoursService,
  UpdateOpeningHoursReq
} from '../../../service/opening-hours.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {addDays, dateEditYearMonthDate, dateEquals, dateJoin, dayPosInWeek, minusDays} from '../../../utils/date.utils'
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {
  OpeningHoursRestrictionReq,
  OpeningHoursRestrictionResp,
  OpeningHoursRestrictionService
} from '../../../service/opening-hours-restriction.service'
import {firstValueFrom, Observable} from 'rxjs'
import {ProfileType} from '../../../common/profile-type'
import {
  CalendarItemNestedProfileResp,
  CalendarItemResp,
  CalendarService,
  FindFirstCalendarItemReq
} from '../../../service/calendar.service'
import {DatePipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {SkeletonModule} from 'primeng/skeleton'
import {NoContentComponent} from '../../common/no-content/no-content.component'
import {RippleModule} from 'primeng/ripple'
import {ButtonComponent} from '../../common/button/button.component'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {CheckboxModule} from 'primeng/checkbox'
import {DateInputComponent} from '../../common/form/date-input/date-input.component'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-opening-hours',
  templateUrl: './profile-opening-hours.component.html',
  styleUrls: ['./profile-opening-hours.component.scss'],
  imports: [
    NgIf,
    SkeletonModule,
    NoContentComponent,
    RippleModule,
    DatePipe,
    NgForOf,
    ButtonComponent,
    DialogComponent,
    ReactiveFormsModule,
    CheckboxModule,
    DateInputComponent,
    NgTemplateOutlet
  ],
  standalone: true
})
export class ProfileOpeningHoursComponent extends EditableComponent implements OnInit {

  @Input()
  data: ProfileResp | BriefProfileResp | CalendarItemNestedProfileResp

  /**
   * Transforms this component for displaying it in a map bubble.
   */
  @Input()
  inMapBubble: boolean

  /**
   * Transforms this component for displaying it in a carousel of a map bubble.
   */
  @Input()
  inMapCarousel: boolean

  displayableOpeningHours: OpeningHoursDate[] = []

  /**
   * Contains the today's date opening hours data.
   */
  todayOpeningHours?: OpeningHoursDate

  /**
   * Shows all days opening hours.
   */
  showDetails: boolean

  /**
   * Represents the server provided opening hours.
   */
  openingHours: OpeningHoursResp

  /**
   * The form of the edit component dialog.
   */
  form: FormGroup
  /**
   * This value is set to true when the {@link form} values have changed.
   */
  formHasChanged: boolean

  /**
   * The visibility control of the {@link EditOpeningHoursRestrictionComponent} dialog.
   */
  editRestrictionComponentVisible: boolean

  /**
   * Represents the default start time of the opening hours edit component.
   */
  defaultStartTime: Date
  /**
   * Represents the default finish time of the opening hours edit component.
   */
  defaultFinishTime: Date
  /**
   * Determines whether the event ends today.
   */
  eventEndsToday: boolean

  constructor(
    private openingHoursService: OpeningHoursService,
    private openingHoursRestrictionService: OpeningHoursRestrictionService,
    private formBuilder: FormBuilder,
    private calendarService: CalendarService) {
    super()
  }

  async ngOnInit(): Promise<void> {
    this.loading = true
    this.initDefaultTimes()

    // Profile type EVENT
    if (this.data.profileType === ProfileType.EVENT) {
      await this.getEventOpeningHours()

      // Profile type ENTERPRISE
    } else if (this.data.profileType === ProfileType.ENTERPRISE) {
      const today = new Date()
      await this.getEnterpriseOpeningHours(today)

      // if the end time is after today's date,
      // download the next day and display the next opening time
      if (this.inMapBubble && !this.todayOpeningHours.nonstop && this.todayOpeningHours.end < today) {
        await this.getEnterpriseOpeningHours(addDays(today, 1))
      }
    }

    this.loading = false
  }

  /**
   * Gets the dates of an event.
   */
  private async getEventOpeningHours(): Promise<void> {
    const resp = await firstValueFrom(this.callFindEventOpeningHours())
    if (resp) {
      const current = new Date()
      this.todayOpeningHours = {
        dayName: '',
        nonstop: false,
        start: resp.start,
        end: resp.end,
        isNowOpen: resp.start <= current && resp.end >= current,
        closed: resp.end < current
      }
      this.eventEndsToday = dateEquals(current, resp.end, 'h')
    }
  }

  /**
   * Fires when a user clicked on the edit button.
   */
  showEditComponent(): void {
    this.resetApi()
    this.initForm(this.openingHours)
    this.form.valueChanges.subscribe(() => this.formHasChanged = true)
    super.showEditComponent()
  }

  /**
   * Fires when a user clicked on save button in the edit component dialog.
   */
  async onComponentSave(): Promise<void> {
    if (this.formHasChanged) {
      await firstValueFrom(this.callUpdateOpeningHours(this.form.value))
    }
  }

  /**
   * Calls the server API to get profile's opening hours.
   */
  private callGetOpeningHours(): Observable<OpeningHoursResp> {
    // Construct a request
    const req: OpeningHoursReq = {
      profileId: this.data.profileId
    }

    // Call the API
    return this.unwrap(this.openingHoursService.callGetOpeningHours(req))
  }

  /**
   * Calls the server API to find the event opening hours.
   */
  private callFindEventOpeningHours(): Observable<CalendarItemResp> {
    const req: FindFirstCalendarItemReq = {
      profileId: this.data.profileId
    }
    return this.unwrap(this.calendarService.callFindFirstCalendarItem(req))
  }

  /**
   * Calls the server API to update opening hours.
   *
   * @param formData The form values of the {@link form}.
   */
  private callUpdateOpeningHours(formData): Observable<boolean> {
    // Construct the req
    const req: UpdateOpeningHoursReq = {
      nonstop: formData.nonstop,

      monStart: formData.monStart,
      monEnd: formData.monEnd,
      monClosed: formData.monClosed,

      tueStart: formData.tueStart,
      tueEnd: formData.tueEnd,
      tueClosed: formData.tueClosed,

      wedStart: formData.wedStart,
      wedEnd: formData.wedEnd,
      wedClosed: formData.wedClosed,

      thuStart: formData.thuStart,
      thuEnd: formData.thuEnd,
      thuClosed: formData.thuClosed,

      friStart: formData.friStart,
      friEnd: formData.friEnd,
      friClosed: formData.friClosed,

      satStart: formData.satStart,
      satEnd: formData.satEnd,
      satClosed: formData.satClosed,

      sunStart: formData.sunStart,
      sunEnd: formData.sunEnd,
      sunClosed: formData.sunClosed
    }

    // Call the API
    return this.unwrap(this.openingHoursService.callUpdateOpeningHours(req))
  }

  /**
   * Gets the opening hours of an enterprise
   *
   * @param today Represents the date that is considered as 'today'.
   */
  private async getEnterpriseOpeningHours(today: Date): Promise<void> {
    this.openingHours = await firstValueFrom(this.callGetOpeningHours())
    if (this.openingHours) {
      this.createDisplayableOpeningHours(this.openingHours)
      this.conformDateObjects(this.displayableOpeningHours)

      const todayRestriction = await firstValueFrom(this.callGetRestriction(today))
      const previousRestriction = await firstValueFrom(this.callGetRestriction(minusDays(today, 1)))
      this.todayOpeningHours = this.getTodayOpeningHours(today, previousRestriction, todayRestriction)
    }
  }

  /**
   * Calls the server API to get current date's restriction.
   * Returns the observable.
   */
  private callGetRestriction(dateRestriction: Date): Observable<OpeningHoursRestrictionResp | null> {
    // construct a request
    const req: OpeningHoursRestrictionReq = {
      profileId: this.data.profileId,
      date: dateRestriction
    }

    // call the server API
    return this.unwrap(this.openingHoursRestrictionService.callGetOpeningHoursRestriction(req))
  }

  /**
   * Returns the today's opening hours from the {@link OpeningHoursResp} response.
   * If the previous day end hour-minute opening hours not finished yet, it returns the previous day opening hours.
   * To the deciding process is taken an account of the restrictions.
   */
  private getTodayOpeningHours(today: Date,
                               prevRest?: OpeningHoursRestrictionResp,
                               todayRest?: OpeningHoursRestrictionResp): OpeningHoursDate | null {
    const previousDate = minusDays(today, 1)

    // make a copy of objects
    const previousDayOH = {...this.displayableOpeningHours[dayPosInWeek(previousDate, false)]}
    const todayOH = {...this.displayableOpeningHours[dayPosInWeek(today, false)]}

    // adapt restrictions if they exist
    if (prevRest) {
      this.adaptRestriction(previousDayOH, prevRest)
    }
    if (todayRest) {
      this.adaptRestriction(todayOH, todayRest)
    }

    // update times of adapted objects
    this.conformDateObjects([previousDayOH, todayOH])

    const tomorrowDate = addDays(today, 1)
    let result: OpeningHoursDate
    if ((previousDayOH.start && previousDayOH.end)
      && previousDayOH.start.getDate() === today.getDate()
      && previousDayOH.end.getDate() === tomorrowDate.getDate()
      && tomorrowDate <= previousDayOH.end) {
      result = previousDayOH
      result.isNowOpen = true
    } else {
      result = todayOH
      result.isNowOpen = ((result.start && result.end) && result.start <= today && today <= result.end)
    }

    return result
  }

  /**
   * Edits the {@link OpeningHoursDate} object with the restriction data.
   */
  private adaptRestriction(openingHours: OpeningHoursDate, restriction: OpeningHoursRestrictionResp): void {
    openingHours.start = restriction.start
    openingHours.end = restriction.end
    openingHours.closed = restriction.closed
    openingHours.restrictionMessage = restriction.message
    openingHours.nonstop = false
    openingHours.restricted = true
  }

  /**
   * Creates the array of {@link OpeningHoursDate} objects for the UI.
   *
   * @param openingHours The response from the server.
   */
  private createDisplayableOpeningHours(openingHours: OpeningHoursResp): void {
    this.displayableOpeningHours[0] = {
      dayName: $localize`Mon`,
      start: openingHours.monStart,
      end: openingHours.monEnd,
      closed: openingHours.monClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[1] = {
      dayName: $localize`Tue`,
      start: openingHours.tueStart,
      end: openingHours.tueEnd,
      closed: openingHours.tueClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[2] = {
      dayName: $localize`Wed`,
      start: openingHours.wedStart,
      end: openingHours.wedEnd,
      closed: openingHours.wedClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[3] = {
      dayName: $localize`Thu`,
      start: openingHours.thuStart,
      end: openingHours.thuEnd,
      closed: openingHours.thuClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[4] = {
      dayName: $localize`Fri`,
      start: openingHours.friStart,
      end: openingHours.friEnd,
      closed: openingHours.friClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[5] = {
      dayName: $localize`Sat`,
      start: openingHours.satStart,
      end: openingHours.satEnd,
      closed: openingHours.satClosed,
      nonstop: openingHours.nonstop
    }

    this.displayableOpeningHours[6] = {
      dayName: $localize`Sun`,
      start: openingHours.sunStart,
      end: openingHours.sunEnd,
      closed: openingHours.sunClosed,
      nonstop: openingHours.nonstop
    }
  }

  /**
   * Changes the 'start' and 'end' dates to the current year-month-date date, while the hours and minutes keep unchanged.
   * If item's start time is after item's end time or the same, the end time date is increased by 1.
   *
   * @param openingHours The array of opening hours.
   */
  private conformDateObjects(openingHours: OpeningHoursDate[]): void {
    const todayDate = new Date()

    openingHours.forEach(item => {
      if (!item.start || !item.end) {
        return
      }

      dateEditYearMonthDate(todayDate, item.start)
      dateEditYearMonthDate(todayDate, item.end)

      // if start is after end or the same, set the end date + 1
      if (item.start >= item.end) {
        item.end = addDays(item.end, 1)
      }
    })
  }

  /**
   * Initializes the form of edit component dialog.
   *
   * @param openingHours The response from the server.
   */
  private initForm(openingHours?: OpeningHoursResp): void {
    this.form = this.formBuilder.group({
      nonstop: [openingHours?.nonstop || false, []],

      monStart: [openingHours?.monStart || '', []],
      monEnd: [openingHours?.monEnd || '', []],
      monClosed: [openingHours?.monClosed || false, []],

      tueStart: [openingHours?.tueStart || '', []],
      tueEnd: [openingHours?.tueEnd || '', []],
      tueClosed: [openingHours?.tueClosed || false, []],

      wedStart: [openingHours?.wedStart || '', []],
      wedEnd: [openingHours?.wedEnd || '', []],
      wedClosed: [openingHours?.wedClosed || false, []],

      thuStart: [openingHours?.thuStart || '', []],
      thuEnd: [openingHours?.thuEnd || '', []],
      thuClosed: [openingHours?.thuClosed || false, []],

      friStart: [openingHours?.friStart || '', []],
      friEnd: [openingHours?.friEnd || '', []],
      friClosed: [openingHours?.friClosed || false, []],

      satStart: [openingHours?.satStart || '', []],
      satEnd: [openingHours?.satEnd || '', []],
      satClosed: [openingHours?.satClosed || false, []],

      sunStart: [openingHours?.sunStart || '', []],
      sunEnd: [openingHours?.sunEnd || '', []],
      sunClosed: [openingHours?.sunClosed || false, []]
    })
    this.disableFieldsInEditForm()
  }

  /**
   * Initializes the {@link defaultStartTime} and {@link defaultFinishTime} properties.
   */
  private initDefaultTimes(): void {
    const current = new Date()
    this.defaultStartTime = dateJoin(current, new Date(0, 0, 0, 9))
    this.defaultFinishTime = dateJoin(current, new Date(0, 0, 0, 17))
  }

  /**
   * Disables certain fields in the {@link form}.
   */
  disableFieldsInEditForm(): void {
    for (const day of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
      this.disableDayTimes(day)
    }
  }

  /**
   * Disables a certain field in the {@link form} by the {@link dayName}.
   */
  disableDayTimes(dayName: string): void {
    const c = this.form.controls
    if (c[$localize`${dayName}Closed`].value || c.nonstop.value) {
      c[$localize`${dayName}Start`].disable()
      c[$localize`${dayName}End`].disable()
    } else if (!c[$localize`${dayName}Closed`].value) {
      c[$localize`${dayName}Start`].enable()
      c[$localize`${dayName}End`].enable()
    }

    if (c.nonstop.value) {
      c[$localize`${dayName}Closed`].disable()
    } else {
      c[$localize`${dayName}Closed`].enable()
    }
  }
}

export interface OpeningHoursDate {
  dayName: string
  start: Date
  end: Date
  closed: boolean
  isNowOpen?: boolean
  nonstop: boolean
  restricted?: boolean
  restrictionMessage?: string
}
