<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            header="Contract preview"
            type="info"
            url="preview"
            i18n-header
            [allowSaveOption]="false"
            discardLabel="Close"
            i18n-discardLabel
            (discard)="closeEditComponent()">
  <div class="p-py-2">
    <app-hint styleClass="p-mb-2" i18n>
      This is only a preview version of the agreement. The official document will be present in the order detail after
      the customer will pay for the order in full price.
    </app-hint>
    <ng-template [ngTemplateOutlet]="contract"></ng-template>
  </div>
</app-dialog>

<ng-template #contract>
  <h3 style="margin: 0;">Zmluva o podaní umeleckého výkonu a licencia na jeho použitie</h3>
  <h4 class="p-text-normal" style="margin: 0;">Uzatvorená v zmysle § 65 a nasl. v spojitosti s § 94 a nasl. zákona č.
    185/2015 Z. z. Autorského
    zákona v platnom znení (ďalej len <b>„Autorský zákon“</b>)</h4>
  <h4 class="p-text-normal">(ďalej len <b>„Zmluva“</b>)</h4>

  <h4>medzi:</h4>
  <ol>
    <li class="p-text-normal">
      <table>
        <tr>
          <td><b>Objednávateľ: </b></td>
          <td><span>{{ order.author.displayName }}</span></td>
        </tr>
        <tr>
          <td>v zastúpení:</td>
          <td><i>Bude doplnené</i></td>
        </tr>
        <tr>
          <td>
            <span>trvalé bydlisko: </span>
          </td>
          <td><i>Bude doplnené</i></td>
        </tr>
        <tr>
          <td>dátum narodenia:</td>
          <td><i>Bude doplnené</i></td>
        </tr>
      </table>
      <br>
      <div>(ďalej len <b>„Objednávateľ“</b>)</div>
      <br>
    </li>

    <li>
      <table>
        <tr>
          <td><b>Výkonný umelec: </b></td>
          <td><span>{{ order.profile.displayName }}</span></td>
        </tr>
        <tr>
          <td>v zastúpení:</td>
          <td><i>Bude doplnené</i></td>
        </tr>
        <tr>
          <td>trvalé bydlisko:</td>
          <td><i>Bude doplnené</i></td>
        </tr>
        <tr>
          <td>dátum narodenia:</td>
          <td><i>Bude doplnené</i></td>
        </tr>
      </table>
      <br>
      <div>(ďalej len <b>„Výkonný umelec“</b>)</div>
      <div style="margin-top:20px;">(ďalej spolu ako <b>„zmluvné strany“</b>)</div>
      <br>
    </li>
  </ol>

  <h4 class="p-mb-0">Čl. I</h4>
  <h4 class="p-mt-1">Predmet Zmluvy</h4>

  <ol>
    <li>Predmetom tejto Zmluvy je úprava vzájomných práv a povinností zmluvných strán pri podaní umeleckého výkonu a
      podmienok udelenia licencie na jeho použitie.
    </li>
    <li>Výkonný umelec sa zaväzuje podať umelecký výkon (ďalej len <b>„umelecký výkon“</b>) podľa požiadavky stanovenej
      Objednávateľom.
    </li>
    <li>Termín a miesto konania podujatia: <b>{{ order.calendarItem.start | date: 'dd. MM. yyyy' }}</b> v
      <b>{{ addressStr }}</b></li>
    <li>Číslo objednávky: <b>{{ order.id }}</b></li>
    <li>Výkonný umelec sa zaväzuje podať umelecký výkon v čase od: <b>{{ order.calendarItem.start | date: 'HH:mm' }}</b>
      do
      <b>{{ order.calendarItem.end | date: 'HH:mm' }}</b> hod. (t. j. celkový čas trvania podania umeleckého výkonu je
      <b>{{ duration }}</b> minút).
    </li>
    <li>Objednávateľ sa zaväzuje Výkonnému umelcovi zaplatiť odmenu dohodnutú v súlade s čl. III tejto Zmluvy riadne a
      včas.
    </li>
  </ol>

  <h4 class="p-mb-0">Čl. II</h4>
  <h4 class="p-mt-1">Práva a povinnosti zmluvných strán</h4>
  <ol>
    <li>
      <div>Objednávateľ sa zaväzuje</div>
      <ol>
        <li>poskytnúť Výkonnému umelcovi súčinnosť potrebnú na podanie umeleckého výkonu,</li>
        <li>zabezpečiť podmienky pre vykonanie umeleckého výkonu po priestorovej a technickej stránke;</li>
      </ol>
    </li>

    <li>
      <div>Výkonný umelec sa zaväzuje</div>
      <ol>
        <li>dostaviť sa na miesto konania podujatia minimálne <b>30 min.</b> pred začiatkom svojho vystúpenia,</li>
        <li>podať umelecký výkon osobne a s náležitou odbornou starostlivosťou,</li>
        <li>rešpektovať organizačné pokyny Objednávateľa a plniť jeho požiadavky týkajúce sa podujatia,</li>
        <li>bezprostredne, prostredníctvom chatu, prípadne Umevia supportu oznámiť Objednávateľovi ochorenie
          alebo úraz, pre ktoré nemôže podať umelecký výkon na podujatí; ochorenie a úraz je potrebné preukázať
          lekárskym potvrdením. V prípade vzniku takejto situácie nárok na odmenu podľa čl. III tejto Zmluvy
          Výkonnému umelcovi neprislúcha.
        </li>
      </ol>
    </li>
  </ol>


  <h4 class="p-mb-0">Čl. III</h4>
  <h4 class="p-mt-1">Odmena</h4>
  <ol class="p-text-normal">
    <li>Zmluvné strany sa dohodli, že odmena za podanie umeleckého výkonu a za udelenie licencie na jeho použitie je v
      sume
      <b *ngIf="orderTotal">{{ orderTotal | price }}</b>
      <i *ngIf="!orderTotal">(bude doplnené)</i>.
    </li>
    <li>Objednávateľ sa zaväzuje zaplatiť odmenu pred podaním umeleckého výkonu, a to prostredníctvom Platformy na
      webovej stránke <a href="https://umevia.com">www.umevia.com</a>.
    </li>
    <li>Zmluvné strany sa výslovne dohodli, že za uhradenie akejkoľvek dane z vyplatenej odmeny v zmysle príslušných
      všeobecne záväzných právnych predpisov platných v SR a uhradenie akéhokoľvek povinného odvodu do príslušného
      umeleckého fondu z vyplatenej odmeny v zmysle zákona Národnej rady Slovenskej republiky č. 13/1993 Z. z. o
      umeleckých fondoch v znení neskorších predpisov je zodpovedný v plnom rozsahu výkonný umelec, ktorý je zároveň
      povinný ich riadne a včas uhradiť.
    </li>
    <li>Zmluvné strany sa v zmysle § 43 ods. 14 zákona č. 595/2003 Z. z. o dani z príjmov v platnom znení dohodli o
      neuplatnení dane vyberanej zrážkou, pričom Výkonný umelec sa zaväzuje si odmenu uvedenú v tomto článku zdaniť
      sám prostredníctvom daňového priznania.
    </li>
  </ol>

  <h4 class="p-mb-0">Čl. IV</h4>
  <h4 class="p-mt-1">Spôsob použitia a udelenia licencie</h4>
  <ol>
    <li>
      <div>Výkonný umelec udeľuje Objednávateľovi nevýhradný a neodvolateľný súhlas (licenciu) na všetky spôsoby
        použitia umeleckého výkonu súlade s § 97 ods. 3 Autorského zákona, a to nasledovným spôsobom:
      </div>
      <ol>
        <li>verejný prenos nezaznamenaného umeleckého výkonu okrem vysielania,</li>
        <li>vyhotovenie originálu záznamu umeleckého výkonu,</li>
        <li>vyhotovenie rozmnoženiny záznamu umeleckého výkonu</li>
        <li>
          <div>verejné rozširovanie originálu záznamu umeleckého výkonu alebo jeho rozmnoženiny</div>
          <ol>
            <li>prevodom vlastníckeho práva,</li>
            <li>nájmom alebo</li>
            <li>vypožičaním,</li>
          </ol>
        </li>
        <li>sprístupňovanie záznamu umeleckého výkonu verejnosti,</li>
        <li>retransmisia záznamu umeleckého výkonu, okrem káblovej retransmisie.</li>
      </ol>
    </li>
    <li>Výkonný umelec udeľuje Objednávateľovi nevýhradnú licenciu v neobmedzenom vecnom a územnom rozsahu, a to na dobu
      trvania majetkových práv Výkonného umelca.
    </li>
    <li>Objednávateľ je oprávnený udeliť tretej osobe súhlas na použitie umeleckého výkonu v rozsahu udelenej licencie
      podľa tejto Zmluvy (sublicenciu).
    </li>
  </ol>


  <h4 class="p-mb-0">Čl. V</h4>
  <h4 class="p-mt-1">Spoločné a záverečné ustanovenia</h4>
  <ol>
    <li>Zmluva je platná a účinná okamihom akceptácie cenovej ponuky oboch zmluvných strán.</li>
    <li>Práva a povinnosti v tejto Zmluve neupravené sa riadia príslušnými ustanoveniami Autorského zákona a inými
      právnymi predpismi platnými na území Slovenskej republiky
    </li>
    <li>Obsah tejto Zmluvy je možné meniť a doplňovať len po dohode zmluvných strán. Zmeny sa vykonávajú v písomnej
      forme dodatkami označenými poradovým číslom.
    </li>
    <li>V prípade akéhokoľvek nedorozumenia, sporu, resp. sporného nároku sa ich zmluvné strany zaväzujú riešiť bez
      zbytočného odkladu vzájomnou dohodou.
    </li>
    <li>Zmluva bola vygenerovaná systémom, v prípade nepravdivých informácií prosím bezodkladne kontaktujte
      <a href="mailto:support@umevia.com">support&#64;umevia.com</a>.
    </li>
    <li>Zmluva sa riadi ustanoveniami Všeobecných obchodných podmienok zverejnených na Platforme (ďalej len „VOP“).
      V prípade rozporu znenia Zmluvy a VOP platia ustanovenia VOP.
    </li>
  </ol>
</ng-template>
