<div class="wrapper rounded-medium p-p-3 {{styleClass}}">
  <!--Not expandable element-->
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <div class="p-d-flex p-ai-center">
      <i class="{{titleIcon}} p-mr-2"></i>
      <b class="section-title">{{ title }}</b>
    </div>

    <i *ngIf="rightIcon"
       (click)="rightIconClick.emit($event)"
       class="fa-regular fa-pen-to-square icon c-pointer p-mt-1"></i>
  </div>
  <ng-content></ng-content>
</div>

