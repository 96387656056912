import {Component, OnInit} from '@angular/core'
import {PrimeNGConfig} from 'primeng/api'
import {Carousel} from 'primeng/carousel'
import {requestNotificationPermission} from './utils/navigator.utils'
import {getCsrfToken} from './utils/csrf.utils'
import {UserService} from './service/user.service'
import {Location} from '@angular/common'
import {environment} from '../environments/environment'
import {PLATFORM_BROWSER} from './app.module'
import {NavigationService} from './service/ui/navigation.service'
import {combineLatest, filter, firstValueFrom} from 'rxjs'
import {NavigationEnd} from '@angular/router'
import {map} from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * Makes the application visible.
   */
  bootstrap: boolean
  /**
   * Enables the splashscreen.
   */
  enableSplashscreen: boolean

  isBrowser = PLATFORM_BROWSER

  constructor(
    private primengConfig: PrimeNGConfig,
    private userService: UserService,
    private navigation: NavigationService,
    private location: Location) {
  }

  ngOnInit(): void {
    this.enableSplashscreen = environment.splashscreen
    if (PLATFORM_BROWSER) {
      this.clearUrlFragment()
      // if user loads application already with session expired or to unauthorized, redirect to home page
      const windowURL = window.document.URL
      if (windowURL.includes(UserService.EXPIRED_URL)
        || windowURL.includes(UserService.LOGOUT_URL)
        || windowURL.includes(NavigationService.UNAUTHORIZED)) {
        this.navigation.toProfileCatalog()
      }

      // Enable primeng ripple animation
      this.primengConfig.ripple = true

      // enables vertical scrolling for carousels
      Carousel.prototype.onTouchMove = () => {
      }

      requestNotificationPermission()
      getCsrfToken()
    }

    this.execBootstrap(this.enableSplashscreen)
  }

  /**
   * Starts bootstrapping child components.
   * @param lazy Whether it should start bootstrap after a few moments, due to splash-screen.
   */
  private execBootstrap(lazy: boolean): void {
    if (lazy) {
      setTimeout(() => {
        this.execBootstrap(false)
      }, PLATFORM_BROWSER ? 500 : 0)
    } else {
      this.bootstrap = true
    }
  }

  /**
   * Clears any URL fragment (https://../my/path#fragment) appended from {@link DialogComponent}s.
   */
  private clearUrlFragment(): void {
    const path = this.location.path()
    const baseUrl = path.split('#')[0]
    this.location.replaceState(baseUrl)
  }

  /**
   * For checking if cookie settings should be cleared.
   * - Refreshed tab && logged user => false
   * - Refreshed tab && not logged user => false
   * - Full reload && logged user => false
   * - Full reload && not logged user => true
   *
   * Value from this function will then be combined in {@link DialogCookiesComponent.ngOnChanges}
   * with number of opened tabs.
   *
   * Detects if page was refreshed with the help on Angular router.
   * Also checks if there is user logged in.
   * @deprecated Here for future potential usage. DON'T USE !!!
   */
  private async resolveClearCookies(): Promise<boolean> {
    const refreshedObservable = this.navigation.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.id === 1 && event.url === event.urlAfterRedirects)  //condition for page reload
    )

    const userObservable = this.userService.user.pipe(
      filter(user => user !== undefined)
    )

    //check if it is a page refresh
    return firstValueFrom(
      combineLatest([refreshedObservable, userObservable]).pipe(
        map(([ev, user]) => !ev && user === null)
      )
    )
  }
}
