<div [class]="styleClass" [formGroup]="form"
     class="p-mb-2 tutorial-class w-100" #wrapper
     [attr.data-test]="formFieldName"
     *ngVar="form.controls[formFieldName].invalid && form.controls[formFieldName].touched as invalid">

  <div class="p-d-flex p-ai-center"
       (click)="(hint) ? showHint = !showHint : null">
    <!--Required icon-->
    <i *ngIf="showRequiredSymbol && label"
       class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
       pTooltip="This field is required"
       i18n-pTooltip
       tooltipPosition="top"></i>

    <!--Label-->
    <label [for]="formFieldName"
           [class.c-pointer]="hint"
           [class.label-invalid]="invalid">{{ label }}</label>

    <!--Show hint icon-->
    <i *ngIf="hint"
       pTooltip="Why this field?"
       i18n-pTooltip
       tooltipPosition="top"
       class="fa-solid fa-info-circle p-ml-2 p-mb-2 hint-icon"
       [class.hint-icon-invalid]="invalid"></i>
  </div>


  <span [class.p-input-icon-left]="icon || loading"
        [class.input-error]="invalid"
        class="w-100 p-relative">

    <!--Left side icon-->
    <i class="icon p-ml-2 {{loading ? loadingIcon : icon}}"
       [class.textarea-icon]="textArea"
       *ngIf="icon || loading" @fade>
    </i>

    <!--Input field-->
    <div class="p-relative">   <!-- Do not remove this div, else it will break in Safari browser. -->
      <input #input
             *ngIf="!textArea; else area"
             (blur)="blurEvent.emit($event)"
             (click)="onInputClick($event)"
             (copy)="onCopy($event)"
             (keydown.space)="onSpace($event)"
             (keyup)="onKeyUp($event)"
             (paste)="onPaste($event)"
             [formControlName]="formFieldName"
             [id]="id || formFieldName"
             [autocomplete]="autocomplete"
             [maxlength]="length?.max || 10e6"
             [class.counter-padding]="length?.max"
             [placeholder]="placeholder || label"
             [type]="inputType"
             [min]="min"
             (focusout)="focusLost.emit($event)"
             class="input w-100 rounded-small"
             [attr.aria-label]="label"
             pInputText>
      <div [ngTemplateOutlet]="counter"></div>
    </div>

    <!--Text area-->
    <ng-template #area>
      <textarea #textArea
                (blur)="blurEvent.emit($event)"
                (click)="onInputClick($event)"
                (copy)="onCopy($event)"
                (keydown.space)="onSpace($event)"
                (keyup)="onKeyUp($event)"
                (paste)="onPaste($event)"
                [formControlName]="formFieldName"
                [id]="id || formFieldName"
                [maxLength]="length?.max || 10e6"
                [class.counter-padding]="length?.max"
                [placeholder]="placeholder || label"
                [rows]="rows"
                class="input w-100 rounded-small"
                pInputTextarea></textarea>
      <div [ngTemplateOutlet]="counter"></div>
    </ng-template>
  </span>

  <app-frontend-validation *ngIf="!disableRequired" [field]="formFieldName" [form]="form" error="required" i18n>
    Required field.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableNoBlankCharacters" [field]="formFieldName" [form]="form"
                           error="blankCharacters" i18n>
    No invisible characters allowed.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disablePattern" [field]="formFieldName" [form]="form" error="pattern" i18n>
    Incorrect format.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableNotPattern" [field]="formFieldName" [form]="form" error="notPattern" i18n>
    Incorrect format.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableEmail" [field]="formFieldName" [form]="form" error="email" i18n>
    Not a valid email.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableNotEmail" [field]="formFieldName" [form]="form" error="notEmail" i18n>
    Cannot contain an email.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disablePhone" [field]="formFieldName" [form]="form" error="phone" i18n>
    Not a valid phone number. Must be in format +4219xxxxxxxx.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableNotPhone" [field]="formFieldName" [form]="form" error="notPhone" i18n>
    Too many digits.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableUrl" [field]="formFieldName" [form]="form" error="url" i18n>
    Not a valid url.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableNotUrl" [field]="formFieldName" [form]="form" error="notUrl" i18n>
    Cannot contain a link.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableOnlyNumbers" [field]="formFieldName" [form]="form" error="onlyNumbers" i18n>
    Only numbers are allowed.
  </app-frontend-validation>

  <app-frontend-validation *ngIf="!disableLength" [field]="formFieldName" [form]="form" error="length">
    <ng-template [ngIf]="length?.min && length?.max && length?.min === length?.max" i18n>Needs to be {{ length.min }}
      characters long.
    </ng-template>
    <ng-template [ngIf]="length?.min && length?.max && length?.min !== length?.max" i18n>Needs to be {{ length.min }}
      - {{ length.max }}
      characters long.
    </ng-template>
    <ng-template [ngIf]="length?.min === undefined && length?.max" i18n>Exceeds {{ length.max }} characters.
    </ng-template>
    <ng-template [ngIf]="length?.max === undefined && length?.min" i18n>Must be at least {{ length.min }}
      characters long.
    </ng-template>
  </app-frontend-validation>

  <!--Optional hint-->
  <div class="hint p-d-block" *ngIf="showHint" @grow>{{ hint }}</div>

  <!--Validation and other content-->
  <ng-content></ng-content>

</div>

<!--Character counter-->
<ng-template #counter>
    <span *ngIf="length?.max && form?.controls[formFieldName].dirty" @fade
          [class.counter-danger]="inputCharCount <= 10"
          [class.counter-area]="textArea"
          [class.counter]="!textArea"
          class="p-mb-1 p-d-flex">
      {{ inputCharCount }}
    </span>
</ng-template>
