<div *ngVar="(collapsableHint && !this.isScreenOf(Screen.MD)) as collapse"
     [class.c-pointer]="collapse" class="hint rounded-medium p-mt-2 p-grid {{styleClass}}"
     (click)="hintClick()">
  <i [class]="icon" class="icon p-mr-2 p-col-fixed"></i>
  <small [class.l-count-1]="collapse ? !showHintContent : showHintContent" class="p-col p-p-0">
    <ng-content></ng-content>
  </small>
  <i *ngIf="collapse && !showHintContent" class="fa-solid fa-chevron-down p-d-flex p-ai-center p-ml-2"></i>
  <i *ngIf="collapse && showHintContent" class="fa-solid fa-chevron-up p-ml-2"></i>
</div>
