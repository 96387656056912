import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core'
import {appendNewPage, newEmptyPage, Page} from 'src/app/rest/page-resp'
import {PrimeTemplate} from 'primeng/api'
import {firstValueFrom, Observable} from 'rxjs'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {ApiComponent} from '../../../abstract/api.component'
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel'
import {growAnimation} from '../../../../animation/grow.animation'
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {AbstractList} from '../abstract.list'
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common'
import {InitDirective} from '../../../../directive/init.directive'

/**
 * This component can be used when you want to load items to your list lazily.
 *
 */
@Component({
  animations: [fadeAnimation(200), growAnimation()],
  selector: 'app-lazy-list',
  templateUrl: './lazy-list.component.html',
  styleUrls: ['./lazy-list.component.scss'],
  imports: [
    NgTemplateOutlet,
    OverlayPanelModule,
    NgIf,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    NgForOf,
    CdkFixedSizeVirtualScroll,
    InitDirective
  ],
  standalone: true
})
export class LazyListComponent<E> extends AbstractList<E> implements OnInit, OnChanges, AfterViewInit {

  /**
   * The input page object.
   */
  @Input()
  items: Page<E> = newEmptyPage()

  /**
   * Notifies the {@link items} when they change.
   */
  @Output()
  itemsChange = new EventEmitter<Page<E>>()

  /**
   * Used to access the api properties.
   */
  @Input()
  component: ApiComponent

  /**
   * - This function will be called on lazy load.
   * - The idOrPage argument represents the next page or from what id the next load should start.
   * It depends on the {@link nextBy} value.
   */
  @Input()
  loadFunction: (idOrPage: number) => Observable<Page<E>>

  /**
   * The next page of data will be loaded by id or page.
   * - **id** *(profileId, channelId, id...)* - From the last item id will load the  next {@link count} of items.
   * - **page** *(default)* - Appends the new page to the total elements.
   */
  @Input()
  nextBy = 'page'

  /**
   * - Enables the top lazy loading.
   * - Default is **false**.
   */
  @Input()
  enableTop = false

  /**
   * - Enables the bottom lazy loading.
   * - Default is **true**.
   */
  @Input()
  enableBottom = true

  /**
   * - Skips the initial call of the {@link loadFunction()}.
   * - Default is **false**
   */
  @Input()
  skipInitialLoad = false

  /**
   * Fires when the top content has been loaded.
   */
  @Output()
  topLoaded = new EventEmitter<Page<E>>()

  /**
   * Fires when the bottom content has been loaded.
   */
  @Output()
  bottomLoaded = new EventEmitter<Page<E>>()

  /**
   * - Calls this function before any next loaded page is appended.
   * - Great to modify the page before append.
   */
  @Input()
  beforeAppend: (page: Page<E>) => Page<E>

  /**
   * - Specifies how many milliseconds needs to be delayed before the lazy loading request.
   * - Default is 100 ms.
   */
  @Input()
  loadDelay = 100

  /**
   * Defines the offset from the start and bottom of the scrollbar current position after which the {@link loadFunction} will be called.
   */
  @Input()
  loadScrollOffset = 200
  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>
  /**
   * The overlay panel PrimeNG component.
   */
  @ViewChild('overlay')
  overlayPanel: OverlayPanel
  /**
   * Custom scroller element when the {@link virtual} is enabled.
   */
  @ViewChild('customScroller')
  customScroller: ElementRef<HTMLDivElement>
  /**
   * The scroll viewport.
   */
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport
  /**
   * The loading skeleton template displayed while loading.
   */
  skeletonTemplate: TemplateRef<PrimeTemplate>
  /**
   * Specifies whether the top loading is currently performing.
   * See the {@link onTopLazyLoad()}.
   */
  topLoading: boolean
  /**
   * Specifies whether the bottom loading is currently performing.
   * See the {@link onBottomLazyLoad()}.
   */
  bottomLoading: boolean
  /**
   * Stores the current value of the scrollbar position.
   */
  private previousIndex: number
  /**
   * Contains the current top scroll timeout.
   */
  private topScrollTimeout: any
  /**
   * Contains the current bottom scroll timeout.
   */
  private bottomScrollTimeout: any

  /**
   * Contains the current scrolling position if the {@link virtual} property is enabled.
   */
  private currentScrollingPos = 0

  constructor(protected changeRef: ChangeDetectorRef) {
    super(changeRef)
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)

    if (changes.items?.currentValue) {
      // Load initial data
      if (!this.skipInitialLoad) {
        this.onBottomLazyLoad()
      }
      super.applyChanges()
    }
  }

  async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit()
    this.skeletonTemplate = this.templates?.find(it => it.name === 'skeleton')?.template

    super.applyChanges()
    this.changeRef.detectChanges()
  }

  /**
   * Fires when the {@link viewport} index changed in the fixed-item-size container.
   */
  onScroll(index: number): void {
    this.lazyLoad(index > this.previousIndex)
    // must be after the lazyLoad
    this.previousIndex = index
    super.applyChanges()
  }

  /**
   * Fires when a user scrolled in the dynamic-item-size container.
   */
  onScrollDynamicItems(): void {
    super.applyShadows()
    const topPos = super.measureScrollOffset('top')
    this.lazyLoad(topPos > this.currentScrollingPos)
    this.currentScrollingPos = topPos
  }

  /**
   * Lazy-loads a next set of data if the conditions are suitable.
   */
  lazyLoad(scrollingDown: boolean): void {
    const contentLength = this.items.content.length
    // return if no other elements can be loaded
    if (!this.enableScroll
      || !this.items
      || (this.skipInitialLoad && this.items.nextPage < 1)
      || (this.items.nextPage > 0 && (contentLength === this.items.totalElements || this.items.totalElements === 0))) {
      return
    }

    // TOP loading
    if (this.enableTop && !scrollingDown && super.measureScrollOffset('top') <= this.loadScrollOffset) {
      if (!this.topLoading) {
        clearTimeout(this.topScrollTimeout)

        this.topScrollTimeout = setTimeout(async () => {
          await this.onTopLazyLoad()
        }, this.loadDelay)
      }

      // BOTTOM loading
    } else if (this.enableBottom && scrollingDown && super.measureScrollOffset('bottom') <= this.loadScrollOffset) {
      if (!this.bottomLoading) {
        clearTimeout(this.bottomScrollTimeout)

        this.bottomScrollTimeout = setTimeout(async () => {
          await this.onBottomLazyLoad()
        }, this.loadDelay)
      }
    }
    super.applyChanges()
  }

  /**
   * Fires when the user wants to load top content.
   */
  async onTopLazyLoad(): Promise<void> {
    if (this.items.nextPageLoading) {
      return
    }

    this.topLoading = true
    this.component?.resetApi()
    this.setComponentLoading(true)
    try {
      const next = this.nextBy === 'page' ? this.items.nextPage : this.items.content[0]?.[this.nextBy]
      if (next) {
        this.items.nextPageLoading = true
        let newPage = await firstValueFrom(this.loadFunction(next))
        if (this.beforeAppend) {
          newPage = this.beforeAppend(newPage)
        }
        if (this.isTopScrolled(0)) { // Scroll a little to keep the scrollbar at the unchanged position
          this.getCustomScroller().nativeElement.scrollTop += 5
        }
        appendNewPage(this.items, newPage, 'start', this.nextBy === 'id')
        // this.itemsChange.emit(this.items)
        this.topLoaded.emit(newPage)
        this.items.nextPage++
      }
    } finally {
      this.topLoading = false
      this.setComponentLoading(false)
      this.items.nextPageLoading = false
    }
    super.applyChanges()
  }

  /**
   * Fires when the user wants to load bottom content.
   */
  async onBottomLazyLoad(): Promise<void> {
    // Prevent from unnecessary loading non-existing pages
    if (this.items.nextPageLoading || (this.nextBy === 'page' && this.items.nextPage > 0 && this.items.totalPages <= this.items.nextPage)) {
      return
    }

    this.bottomLoading = true
    this.component?.resetApi()
    this.setComponentLoading(true)
    try {
      const lastElPos = this.items.content.length - 1
      const next = this.nextBy === 'page' ? this.items.nextPage : this.items.content[lastElPos]?.[this.nextBy]
      this.items.nextPageLoading = true
      let newPage = await firstValueFrom(this.loadFunction(next))
      if (this.beforeAppend) {
        newPage = this.beforeAppend(newPage)
      }
      appendNewPage(this.items, newPage, 'end', this.nextBy === 'id')
      // this.itemsChange.emit(this.items)
      this.bottomLoaded.emit(newPage)
      this.items.nextPage++
    } finally {
      this.bottomLoading = false
      this.setComponentLoading(false)
      this.items.nextPageLoading = false
    }
    super.applyChanges()
  }

  /**
   * Sets the {@link component}'s loading property.
   */
  private setComponentLoading(loading: boolean): void {
    if (this.component) {
      this.component.loading = loading
    }
  }

  getItemsLength(): number {
    return this.items?.content?.length || 0
  }

  getCustomScroller(): ElementRef<HTMLDivElement> {
    return this.customScroller
  }

  getOverlayPanel(): OverlayPanel {
    return this.overlayPanel
  }

  getViewport(): CdkVirtualScrollViewport {
    return this.viewport
  }

  getTemplates(): QueryList<PrimeTemplate> {
    return this.templates
  }
}
