import {Component, OnInit} from '@angular/core'
import {AuthTypeEnum} from '../../../../common/auth-type-enum'
import {ValidComponent} from '../../../abstract/valid.component'
import {PostalReq} from '../../../../service/address.service'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {UserResponseType} from '../../../../common/user-response-type'
import {FirebaseAuthResponseResult} from '../../../../modules/authentication/firebase-auth/firebase-auth.component'

@Component({
  selector: 'app-order-account',
  templateUrl: './order-account.component.html',
  styleUrl: './order-account.component.scss'
})
export class OrderAccountComponent extends ValidComponent implements OnInit {

  AuthType: typeof AuthTypeEnum = AuthTypeEnum
  AuthActionType: typeof AuthActionType = AuthActionType
  /**
   * The invoicing address information of author.
   */
  authorAddress: PostalReq
  /**
   * Current action that is taken by the user.
   */
  protected authAction = AuthActionType.DECISION

  constructor(private storageService: StorageService) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.authorAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)) as PostalReq
  }

  /**
   * Handles the response from the Firebase authentication. Redirects user to the appropriate action / component.
   */
  firebaseAuthResponseAction(response: FirebaseAuthResponseResult): void {
    switch (response.responseType) {
      case UserResponseType.TWO_FACTOR:
        this.authAction = AuthActionType.TWO_FACTOR
        break
      case UserResponseType.FIREBASE_REGISTER:
        this.authAction = AuthActionType.REGISTER_FIREBASE
        break
      default:
        this.authAction = AuthActionType.DECISION
        break
    }
  }
}

/**
 * Defines the actions that can be taken by the user on this step.
 * Based on the action type the corresponding component will be displayed.
 */
enum AuthActionType {
  /**
   * As first step user needs to decide what action to take.
   * This is the default state. And user can return to this state from any other state.
   */
  DECISION = 'decision',
  LOGIN = 'login',
  REGISTER_FIREBASE = 'register-firebase',
  REGISTER = 'register',
  TWO_FACTOR = 'two-factor'
}
