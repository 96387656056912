import {Component, Input, OnInit} from '@angular/core'
import {EditableComponent} from '../../../../../component/abstract/editable.component'
import {ProfileOrderResp, ProfileOrderService} from '../../../../../service/profile-order.service'
import {ProfileResp} from '../../../../../service/profile.service'
import {growAnimation} from '../../../../../animation/grow.animation'
import {FormBuilder, FormGroup} from '@angular/forms'
import {Acceptance} from '../../../../../common/acceptance'
import {firstValueFrom, Observable} from 'rxjs'
import {minusMinutes} from '../../../../../utils/date.utils'
import {Restrictions} from '../../../../../common/restrictions'

@Component({
  animations: [growAnimation()],
  selector: 'app-order-reply',
  templateUrl: './order-reply.component.html',
  styleUrls: ['./order-reply.component.scss']
})
export class OrderReplyComponent extends EditableComponent implements OnInit {

  /**
   * The current logged profile.
   */
  @Input()
  currentProfile: ProfileResp
  /**
   * The order to be replied to.
   */
  @Input()
  order: ProfileOrderResp
  /**
   * Whether the {@link currentProfile} wants to reject this order, otherwise accept.
   */
  @Input()
  isReject: boolean
  /**
   * Whether the {@link currentProfile} is an author of this @{@link order}.
   */
  @Input()
  isAuthor: boolean
  /**
   * Adjusted price of the {@link order}.
   */
  @Input()
  additionalPrice = 0
  /**
   * Determines whether the {@link order} is already paid.
   */
  isPaid: boolean
  /**
   * Determines whether the {@link order} has already started.
   */
  hasStarted: boolean
  /**
   * Determines whether the profile has accepted this {@link order}.
   */
  profileAccepted: boolean
  /**
   * Displays a contract preview dialog for performers.
   */
  contractPreviewVisible: boolean

  form: FormGroup

  /**
   * Object containing translation strings
   */
  protected trans = {
    accept: $localize`Accept`,
    reject: $localize`Reject`,
    accepted_success_msg: $localize`Order accepted.`,
    rejected_success_msg: $localize`Order rejected.`,
    header_accept: $localize`Order accept`,
    header_reject: $localize`Are you sure?`,
    discard_reject: $localize`Keep`,
    discard_accept: $localize`Cancel`
  }

  constructor(
    private formBuilder: FormBuilder,
    private orderService: ProfileOrderService) {
    super()
  }


  ngOnInit(): void {
    this.isPaid = this.order.authorAcceptance === Acceptance.ACCEPTED
    this.hasStarted = new Date() >= minusMinutes(this.order.calendarItem.start, Restrictions.PROFILE_ORDER_START_BEFORE_MINUTES)
    this.profileAccepted = this.order.profileAcceptance === Acceptance.ACCEPTED

    // Close immediately, when the order has already started
    if (this.hasStarted && this.isReject) {
      this.closeEditComponent(false)
    }

    this.initForm()
  }

  /**
   * Initializes the {@link form}.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      replyMessage: [''],
      cancelReason: ['']
    })
  }

  /**
   * Updates the {@link order}'s acceptance.
   */
  async modifyOrderAcceptance(): Promise<void> {
    const acc = this.isReject ? Acceptance.REJECTED : Acceptance.ACCEPTED
    const resp = await firstValueFrom(this.callUpdateProfileOrderAcceptance(acc))
    if (resp && this.noServerMessages()) {
      if (this.isAuthor) {
        this.order.authorAcceptance = acc
      } else {
        this.order.profileAcceptance = acc
      }
    }
  }

  /**
   * Calls the server API to update the {@link order}'s acceptance.
   */
  private callUpdateProfileOrderAcceptance(acc: Acceptance): Observable<boolean> {
    const fd = this.form.value

    let cancel = null
    let profileReply = null

    // Author
    if (this.isAuthor && acc === Acceptance.REJECTED) {
      cancel = fd.cancelReason
    } else if (!this.isAuthor) { // Profile
      if (this.order.profileReply && acc === Acceptance.REJECTED) {
        cancel = fd.cancelReason
      } else if (!this.order.profileReply) {
        profileReply = fd.replyMessage || fd.cancelReason
      }
    }
    return this.unwrap(this.orderService.callAcceptanceProfileOrder({
      id: this.order.id,
      acceptance: acc,
      reply: profileReply,
      cancelReason: cancel,
      additionalPrice: this.additionalPrice
    }))
  }

  reload(): void {
    window.location.reload()
  }
}
