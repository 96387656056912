import {ApiComponent} from './api.component'
import {Directive, EventEmitter, Input, Output} from '@angular/core'

/**
 * Defines common properties for components that are editable.
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EditableComponent extends ApiComponent {

  /**
   * - Controls the visibility of the entire component.
   * - This can be used if your component template uses only the app-dialog.
   */
  @Input()
  show: boolean

  /**
   * Visibility changes of the entire component.
   */
  @Output()
  showChange = new EventEmitter<boolean>()

  /**
   * - Defines the visibility of the edit button option.
   * ### The edit button has to be implemented.
   * - The common use case is like when the owner is looking on his profile, etc.
   */
  @Input()
  editButtonVisible: boolean

  /**
   * Defines the visibility change of the edit button option.
   */
  @Output()
  editButtonVisibleChange = new EventEmitter<boolean>()

  /**
   * - Defines the visibility of nested editable component.
   * - This can be used if your component contains other component
   * that uses the app-dialog, and you want to control its visibility.
   */
  editComponentVisible: boolean
  /**
   * Value changes of the {@link editComponentVisible}.
   */
  @Output()
  editComponentVisibleChange = new EventEmitter<boolean>()

  /**
   * Fires when a component is about to save.
   */
  onComponentSave(): void {
    this.evaluateAndFinish()
  }

  /**
   * - Sets the {@link editComponentVisible} to true which means show the edit component.
   * - Before the {@link editButtonVisible} is set to true, it calls the {@link resetApi}.
   */
  showEditComponent(): void {
    this.resetApi()
    this.editComponentVisible = true
  }

  /**
   * - Sets the {@link editComponentVisible} to false which means hide the edit component.
   * - It also emits the {@link showChange} emitter to false.
   * - If the {@link delay} is specified, it hides the component after a time.
   */
  closeEditComponent(delay: boolean = false): void {
    if (delay) {
      setTimeout(() => {
        this.closeEditComponent(false)
      }, 1000)
    } else {
      this.editComponentVisibleChange.emit(false)
      this.editComponentVisible = false
      this.showChange.emit(false)
      this.show = false
    }
  }
}
