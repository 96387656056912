<div
  class="wrapper p-d-flex p-flex-column p-ai-center p-relative p-px-3 p-py-3 {{ProfileBottomBar.OFFER_COMPONENT_CLASS}}">
  <!--Penalty overlay-->
  <ng-template *ngIf="loggedProfile && loggedProfile.penalty"
               [ngTemplateOutlet]="penaltyOverlayTemplate"></ng-template>

  <!--NOT active user overlay-->
  <ng-template *ngIf="loggedProfile && !loggedProfile.penalty && (loggedProfile.profileStatus !== ProfileStatus.ACTIVE)"
               [ngTemplateOutlet]="notActiveOverlayTemplate"></ng-template>

  <div class="component-wrapper p-d-flex" @grow>
    <div class="w-100">

      <!--No services hint for profile-->
      <app-hint *ngIf="data?.owner && (allPriceItems?.content?.length || 0) === 0 && !hideNoServiceHint" i18n>
        Hmmm. Looks like you have no services to offer right now. Let's change it quickly by clicking on <b>Edit</b>
        button.
      </app-hint>

      <!--Select category from the menu full of categories-->
      <div class="p-d-flex p-jc-between p-ai-center p-pb-1 p-mt-3">
        <h2 class="title p-d-flex p-ai-start p-my-0">
          <div *ngIf="canBeOrdered && !data?.owner">
            <span class="profile-circle-number p-mr-2">2.</span>
          </div>
          {{ (canBeOrdered && !data?.owner) ? trans.offer_title_customer : trans.offer_title_owner }}
        </h2>

        <!--Filter items by category button-->
        <div class="p-d-flex p-ai-center">
          <!--Edit button for the owner-->
          <app-button *ngIf="editButtonVisible"
                      type="edit"
                      pTooltip="Edit your offer"
                      i18n-pTooltip
                      tooltipPosition="top"
                      [url]="navigation.urlProfileServicesEditor()"></app-button>

          <ng-template [ngIf]="!editButtonVisible || (isScreenOf(Screen.SM) && editButtonVisible)">
            <!--Filter category button-->
            <app-button type="secondary"
                        [icon]="selectedCategory?.icon || 'fa-solid fa-filter'"
                        [label]="selectedCategory?.name || 'Filter'"
                        styleClass="p-ml-2"
                        (clicked)="menu.toggle($event)"></app-button>

            <!--Reset category-->
            <app-button *ngIf="menuCategoryClicked" @fade
                        type="icon"
                        icon="fa-solid fa-rotate-right"
                        styleClass="p-mr-2"
                        [disabled]="loading"
                        (clicked)="resetCategory()"></app-button>

            <!--Menu of categories-->
            <p-menu #menu
                    [popup]="true"
                    [model]="menuCategories"></p-menu>
          </ng-template>
        </div>
      </div>

      <div class="p-grid p-p-0">
        <!--Offer list-->
        <div class="p-p-0 p-col-12 {{editButtonVisible ? '' : 'p-lg-8'}}">
          <div class="offer-list">
            <app-lazy-list [(items)]="allPriceItems"
                           [scrollHeight]="!isScreenOf(Screen.LG) ? (!isScreenOf(Screen.SM) ? '65dvh' : '30rem') : '46.5rem'"
                           [virtual]="false"
                           [component]="this"
                           overlayClass="search-lazy-list-overlay"
                           [loadFunction]="callGetPriceItemsFilter.bind(this, allPriceItems?.nextPage)"
                           (bottomLoaded)="selectPriceItemsManually(); this.hideNoServiceHint = false">

              <!--Item content-->
              <ng-template let-priceItem pTemplate="item">
                <div class="price-item rounded-medium p-p-3 p-my-2 w-100 c-pointer">
                    <app-profile-price-item [item]="priceItem"
                                            [data]="data"
                                          [currentProfile]="loggedProfile"
                                          [editButtonVisible]="editButtonVisible"
                                          (selected)="onPriceItemSelectionChange(priceItem)"></app-profile-price-item>
                </div>
              </ng-template>
            </app-lazy-list>
          </div>
        </div>

        <!--Inventory list-->
        <div class="p-col-12 p-lg-4 p-p-0 p-p-md-2" *ngIf="!editButtonVisible">
          <app-profile-offer-inventory [(priceItems)]="selectedPriceItems"
                                       (priceItemsChange)="selectPriceItemsManually()"
                                       [(additionItems)]="selectedPriceItemsAdditions"
                                       (additionItemsChange)="selectAdditionsManually()"
                                       [(orderTotal)]="orderTotal"
                                       (orderTotalChange)="changeRef.detectChanges()"
                                       (disabledCheckoutClick)="disabledCheckoutClickEmitted.emit($event)"
                                       [dates]="[selectedStartDate, selectedEndDate]"
                                       [data]="data"
                                       [disableCheckout]="disableCheckout"
                                       [minimalDistance]="minimalDistanceErr"
                                       (checkout)="onCheckOut()"
                                       (priceItemRemoved)="onPriceItemSelectionChange($event)"></app-profile-offer-inventory>
        </div>
        <!--Scroll-purpose div-->
        <div class="mobile-layout-checkout-footer"></div>
      </div>
    </div>
  </div>
</div>

<!--Order dialog-->
<app-profile-order-dialog *ngIf="orderDialogVisible"
                          [show]="orderDialogVisible"
                          (showChange)="onOrderDialogVisibleChange($event)"
                          [profileData]="data"
                          [allPriceItemCategories]="priceItemCategories"
                          [selectedPriceItems]="selectedPriceItems"
                          [dates]="[selectedStartDate, selectedEndDate]"
                          [allAdditionalItems]="allAdditionalItems"
                          [(selectedPriceItemsAdditions)]="selectedPriceItemsAdditions"
                          (selectedPriceItemsAdditionsChange)="changeRef.detectChanges()"
                          (orderSubmitted)="onOrderSubmitted()"
                          [orderTotal]="orderTotal"></app-profile-order-dialog>

<!-- not allowed order and open checkout dialog -->
<app-dialog *ngIf="notAllowedOrderDialogVisible"
            [(show)]="notAllowedOrderDialogVisible"
            [component]="this"
            [allowSaveOption]="false"
            (discard)="notAllowedOrderDialogVisible = false"
            type="info"
            discardLabel="Ok"
            i18n-discardLabel>
  <div *ngVar="(hasFeatures(loggedProfile.profileType, Feature.BE_ORDERED)) as loggedAsOrderableProfile" class="p-py-2">

    <!-- The reason is, that a user is an orderable profile, and this type of the profile cannot order artist (artist cannot order artist) -->
    <div *ngIf="loggedAsOrderableProfile"
         class="p-d-flex p-flex-column p-jc-center p-ai-center">
      <i class="info-icon fa-solid fa-lock"></i>
      <div class="dialog-info-text p-text-center p-mt-4" i18n>Sorry, this feature is not supported for service providers
        yet.
        We will add that soon when you write us via <a [routerLink]="navigation.urlSupport()" class="text-link">support
          section</a> about that.
      </div>
    </div>

    <!-- The reason is that, if a user is not an orderable profile, and the order amount is lower than the minimum order value -->
    <div *ngIf="!loggedAsOrderableProfile && (orderTotal <= minimumOrderPrice)"
         class="p-d-flex p-flex-column p-jc-center p-ai-center">
      <i class="info-icon fa-solid fa-money-check"></i>
      <div class="dialog-info-text p-text-center p-mt-4" i18n>Hey there! It seems like your order might need a little
        extra
        warmth. How about adding some more items to make your event even more special? 😊🎉 Aim for at least
        <b>{{ minimumOrderPrice | price }}</b> to ensure you have a fantastic and memorable event!
      </div>
    </div>
  </div>
</app-dialog>

<!-- Successful order dialog -->
<app-dialog *ngIf="orderedDialogVisible"
            [(show)]="orderedDialogVisible"
            [component]="this"
            (discard)="orderedDialogVisible = false"
            (saved)="navBookings()"
            discardLabel="Close"
            i18n-discardLabel
            successMessage="You will be redirected"
            i18n-successMessage
            saveIcon="fa-solid fa-arrow-right"
            saveLabel="Bookings"
            i18n-saveLabel
            type="save">

  <div class="p-py-2 p-d-flex p-flex-column p-jc-center p-ai-center">
    <i class="info-icon fa-solid fa-circle-check"></i>
    <div class="dialog-info-text p-text-center p-mt-4" i18n>
      Congratulations! You have sent a new order to your artist. The reply from him will arrive soon, or no later than
      48 hours. While you're sipping on your favorite cup of coffee, you can check the state of all your orders in the
      <span class="p-text-bold">Order manager</span>.
    </div>
  </div>
</app-dialog>

<!--Penalty overlay-->
<ng-template #penaltyOverlayTemplate>
  <div class="penalty-overlay p-d-flex p-flex-column p-jc-center p-text-center p-absolute">
    <i class="info-icon fa-solid fa-circle-exclamation"></i>
    <div class="dialog-info-text p-mx-4" i18n>Oh no! Ordering this artist isn't allowed at the moment because of a
      penalty. 😔
      Don't worry, there are plenty of other amazing artists waiting for you to explore.
      Don't hesitate to pick another one!
    </div>
  </div>
</ng-template>

<!--NOT active user overlay-->
<ng-template #notActiveOverlayTemplate>
  <div class="penalty-overlay p-d-flex p-flex-column p-jc-center p-text-center p-absolute">
    <i class="info-icon fa-solid fa-circle-exclamation"></i>
    <h2 class="p-mx-4" i18n>Oops! You can't order with an inactive account. <br>
      Click the button below to activate your profile and start ordering.</h2>
    <!-- causes the profile settings dialog to open and scrolls to the bottom of this dialog -->
    <app-button type="primary"
                label="Activate"
                i18n-label
                styleClass="p-mt-2"
                (clicked)="this.navigation.toProfileCustom(loggedProfile.charId, 'deactivate')"></app-button>
  </div>
</ng-template>
