import {Component, EventEmitter, Input, Output} from '@angular/core'
import {ButtonComponent} from '../button/button.component'
import {NgIf} from '@angular/common'

@Component({
  selector: 'app-custom-navbar',
  templateUrl: './custom-navbar.component.html',
  styleUrls: ['./custom-navbar.component.scss'],
  imports: [
    ButtonComponent,
    NgIf
  ],
  standalone: true
})
export class CustomNavbarComponent {

  /**
   * Displays the return arrow on the left.
   */
  @Input()
  enableReturn = true

  /**
   * The title of a navbar
   */
  @Input()
  title: string

  /**
   * The style class of the entire component.
   */
  @Input()
  styleClass = ''

  /**
   * The z-index of the navigation bar. The default is 10200.
   */
  @Input()
  zIndex = 10200

  /**
   * Fires when a user clicked on the return arrow.
   */
  @Output()
  returnAction = new EventEmitter<any>()

  constructor() {
  }
}
