<app-dialog *ngIf="show && !disablePopup"
            [(show)]="show"
            [component]="this"
            header="Action Required"
            i18n-header
            type="info"
            data-test="order-awaiting-confirm"
            [modifyUrl]="false"
            discardLabel="Later"
            i18n-discardLabel
            [allowSaveOption]="false"
            (discard)="later()">
  <div class="p-py-2">

    <!--BE-CHECK-->
    <div class="p-m-2">
      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
        The desired profile was not found.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                              i18n>
        You're not the owner of that profile.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.USER_NOT_FOUND"
                              i18n>
        User not registered.
      </app-backend-validation>
    </div>

    <app-list [(items)]="orders"
              [virtual]="false">
      <!-- Item -->
      <ng-template let-item pTemplate="item">
        <div class="rounded-medium order-item box-shadow p-mb-3 p-mr-2 c-pointer unselectable" (click)="redirect(item)">

          <!--Status Bar-->
          <div class="p-d-flex p-ai-center label w-100 p-px-3 p-py-2">
            <i class="fa-solid fa-warning p-mr-2"></i>
            <div class="p-text-uppercase p-text-bold" i18n>Confirmation Required</div>
          </div>

          <!--Content-->
          <div class="p-px-3 p-py-2">
            <h3 class="l-count-1 unselectable p-m-0">{{ item.name }}</h3>
            <div>
              <div class="p-mr-2 l-count-1"><b i18n>Start:</b> {{ item.calendarItem.start | date: 'dd.MM. HH:mm' }}
              </div>
              <div class="l-count-1"><b i18n>End:</b> {{ item.calendarItem.end | date: 'dd.MM. HH:mm' }}</div>
            </div>

            <!-- Redirect -->
            <div class="p-d-flex p-jc-between p-mt-1">
              <div class="p-d-flex p-ai-center p-mt-2">
                <app-avatar [profile]="item" [sizePx]="30"></app-avatar>
                <div class="p-text-bold p-mx-2 word-break l-count-1">{{ item.charId }}</div>
              </div>
              <app-button type="primary"
                          i18n-label
                          label="Resolve"
                          [loading]="saving"
                          icon="fa-solid fa-arrow-right"
                          iconPos="right"
                          (clicked)="redirect(item)"></app-button>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</app-dialog>
