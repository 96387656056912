<div class="w-100 {{styleClass}}">
  <!--Hint content-->
  <app-hint class="p-my-2" *ngIf="hintTemplate">
    <ng-template [ngTemplateOutlet]="hintTemplate"></ng-template>
  </app-hint>

  <!--Search Input Field-->
  <form [formGroup]="form" autocomplete="off" class="p-my-2">
    <app-text-input [form]="form"
                    id="input"
                    formFieldName="search"
                    [paste]="false"
                    icon="fa-solid fa-search"
                    [focus]="searchFocus"
                    [selectText]="true"
                    [loading]="component?.loading"
                    [length]="length"
                    (keyupEvent)="startSearch($event)"
                    (blurEvent)="onBlur()"
                    (clickEvent)="onInputClick($event)"
                    (focusLost)="focusLost.emit($event)"
                    [placeholder]="placeholder || 'Search'"
                    #input>

      <!--Frontend and Backend validations, hints, etc.-->
      <ng-template [ngTemplateOutlet]="validationTemplate"
                   [ngTemplateOutletContext]="{form: form, field: 'search'}"></ng-template>
    </app-text-input>
  </form>

  <!--Search Results-->
  <div *ngIf="(!disableResultLocal && !disableResult && items?.nextPage > 0)" @grow>
    <div class="p-mb-2">
      <app-lazy-list #lazyList
                     [(items)]="items"
                     [maxScrollHeight]="maxScrollHeight"
                     [itemHeight]="itemHeight"
                     [overlay]="resultAsOverlay"
                     [overlayZIndex]="resultOverlayZ"
                     [overlayEmptyVisible]="overlayEmptyVisible"
                     [overlayAppendTo]="input.wrapper.nativeElement"
                     [component]="component"
                     [enableScroll]="enableScrollLazy"
                     [beforeAppend]="beforeAppend?.bind(this)"
                     [skipInitialLoad]="true"
                     [scrollAfterFullVisible]="false"
                     overlayClass="search-lazy-list-overlay {{overlayClass}}"
                     [loadFunction]="searchFunction.bind(this, items?.nextPage, search)">

        <!--Item content-->
        <ng-template let-item pTemplate="item">
          <ng-template [ngTemplateOutlet]="itemTemplate"
                       [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
        </ng-template>

        <!--No Content-->
        <ng-template pTemplate="noContent">
          <div *ngIf="!noContentTemplate" class="p-d-flex p-ai-center p-my-3 p-px-3 unselectable">
            <i class="fa-solid fa-heart-crack p-mr-2"></i>
            <div i18n>Nothing found</div>
          </div>
          <ng-template [ngIf]="noContentTemplate" [ngTemplateOutlet]="noContentTemplate"></ng-template>
        </ng-template>
      </app-lazy-list>
    </div>
  </div>
</div>
