import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ProfileOrderService, RequiresConfirmationOrderResp} from '../../../service/profile-order.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {NavigationService} from '../../../service/ui/navigation.service'
import {PLATFORM_BROWSER} from '../../../app.module'
import {UserService} from '../../../service/user.service'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {BaseNotification, FcmService, RawFCMMessage} from '../../../service/fcm.service'
import {OrderJobCategory} from '../../../common/job-category'
import {ActivatedRoute} from '@angular/router'

/**
 * Shows a list of required actions for profile orders that is currently running and not yet confirmed.
 */
@Component({
  selector: 'app-order-awaiting-confirm',
  templateUrl: './order-awaiting-confirm.component.html',
  styleUrls: ['./order-awaiting-confirm.component.scss']
})
export class OrderAwaitingConfirmComponent extends EditableComponent implements OnInit, OnDestroy {
  /**
   * All awaiting orders for a confirmation.
   */
  orders: RequiresConfirmationOrderResp[]
  /**
   * Prevents showing the dialog because the query param contains 'no-popup' parameter.
   * This can be handy to prevent display dialog in E2E testing.
   */
  disablePopup: boolean
  /**
   * All subscriptions that need to be unsubscribed.
   */
  private subs: Subscription[] = []
  /**
   * Contains current timeout when the user pressed the 'Later' button.
   */
  private laterDelay: any

  constructor(
    private orderService: ProfileOrderService,
    private userService: UserService,
    private profileService: ProfileService,
    private navigation: NavigationService,
    private changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private fcmService: FcmService) {
    super()
  }

  ngOnInit(): void {
    // Subscribe for changes of the updated list
    this.subs.push(
      this.orderService.ordersToRequiresAction.subscribe(orders => {
        this.orders = orders
        this.tryShowDialog()
      }),
      // Query params observer
      this.route.queryParams.subscribe(queryParams => {
        this.disablePopup = queryParams['no-popups']
      })
    )

    // Initializes the orders to confirm
    this.userService.user
      .subscribe((user) => {
        if (user) {
          // If user logged in try to show the dialog
          this.tryLoadOrders()
          this.tryShowDialog()
        } else {
          // If user logged out, hide the dialog
          this.show = false
        }
      })

    // Subscribe when the message JobReminder category is sent
    this.subs.push(this.fcmService.onMessage.subscribe((raw: RawFCMMessage) => {
      switch ((raw.data as BaseNotification<any>).category) {
        case OrderJobCategory.ORDER_STATE_START:
        case OrderJobCategory.ORDER_STATE_END:
        case OrderJobCategory.ORDER_STATE_AFTER_ONE_HOUR:
        case OrderJobCategory.ORDER_STATE_AFTER_ONE_DAY:
        case OrderJobCategory.ORDER_STATE_AFTER_TWO_DAYS:
          this.tryShowDialog()
          break
      }
    }))
  }

  /**
   * Tries to load {@link orders} using {@link ProfileOrderService.loadOrdersToRequiredAction} function.
   */
  private tryLoadOrders(): void {
    // Prevents double loading (because on that path the 'loadOrdersToRequiredAction()' is calling again).
    if (PLATFORM_BROWSER) {
      const path = window.location.pathname
      for (const order of this.orders) {
        const url = `${NavigationService.PROFILE_BOOKINGS}/${order.charId}/${NavigationService.PROFILE_BOOKINGS_LIST}`
        if (path.includes(url)) {
          return
        }
      }
    }

    // Load orders
    this.orderService.loadOrdersToRequiredAction()
  }

  /**
   * - Redirects to the specific profile order booking detail to hande actions there.
   * - Hides the dialog.
   */
  redirect(order: RequiresConfirmationOrderResp): void {
    this.switchProfile(order.charId, order.id)
  }

  /**
   * - Fires when the user clicked on 'Later' button.
   * - Basically, executes the {@link ProfileOrderService.loadOrdersToRequiredAction} function again after the 15-minutes delay.
   */
  later(): void {
    this.closeEditComponent()

    clearTimeout(this.laterDelay)
    this.laterDelay = setTimeout(async () => {
      this.orderService.loadOrdersToRequiredAction()
    }, 15 * 60 * 1000) // 15 minutes
  }

  /**
   * Tries to show the dialog with required actions.
   */
  private tryShowDialog(): void {
    this.resetApi()
    if (this.orders?.length > 0) {

      // Parse orderId from the URL (applicable on order detail)
      let orderId
      if (PLATFORM_BROWSER) {
        const path = window.location.pathname
        for (const order of this.orders) {
          orderId = path.split(`/${NavigationService.PROFILE_BOOKINGS}/${order.charId}/`)?.[1]?.split('/')?.[0]
          if (orderId && !isNaN(+orderId)) {
            orderId = +orderId
            // Shows when the order-detail is not currently open with one of the orders (that requires action)
            this.show = !this.orders.find(it => it.id === orderId)
            break
          } else {
            orderId = undefined
          }
        }
      }

      // Show when already has been set or there is no orderId
      this.show = this.show || !orderId
      this.changeRef.detectChanges()
    }
  }

  /**
   * Tries to switch to the desired profile with given {@link charId}.
   */
  private switchProfile(charId: string, orderId: number): void {
    this.callAndFinish(async () => {
      await firstValueFrom(this.unwrap(this.profileService.switchToProfileCharId(charId)))

      if (this.noServerMessages()) {
        // redirect
        this.navigation.toProfileBookingDetail(charId, orderId)
      }
    })
  }

  /**
   * Calls the API to download all necessary profile data.
   * @param characterId The charId of the profile.
   */
  private callGetProfile(characterId: string): Observable<ProfileResp> {
    return this.unwrap(this.profileService.callGetProfile({
      charId: characterId
    }))
  }

  ngOnDestroy(): void {
    this.subs?.forEach(it => it?.unsubscribe())
  }
}
