import {Injectable} from '@angular/core'
import {DeviceService} from './device.service'
import {DeviceRest} from '../../rest/user/session-device/session-device'
import {BehaviorSubject} from 'rxjs'
import {StorageItem, StorageService} from '../storage.service'
import {daysDifference} from '../../utils/date.utils'
import {PLATFORM_BROWSER, RUNNING_AS_PWA} from '../../app.module'

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  /**
   * Controls information about opening prompt to ask user about installing as PWA.
   */
  pwaDialog = new BehaviorSubject<PwaReq>(null)

  /**
   * Device detected from {@link DeviceService}.
   * @private
   */
  private device: DeviceRest

  /**
   * Install pwa event for android devices.
   */
  private event: any

  constructor(
    private deviceService: DeviceService,
    private storageService: StorageService
  ) {
    this.initDevice()
  }

  /**
   * Fired instantly after app loading to catch event,
   * so installation of PWA on Android is easier.
   */
  catchBeforeInstallPrompt(): void {
    if (this.device.os === 'android') {
      if (PLATFORM_BROWSER) {
        window.addEventListener('beforeinstallprompt', event => {
          event.preventDefault()
          this.event = event
        })
      }
    }
  }

  /**
   * Initializes the process of asking user to install app as PWA into his device.
   */
  initPwaDialog(): void {
    const date = this.storageService.getItemStorage(StorageItem.PWA_DIALOG) || '01.01.2099'

    // if user was asked less than 7 days ago, then abort
    if (daysDifference(new Date(date), new Date()) < 7) {
      return
    }

    // Check if device is mobile, running in browser and not already as PWA
    if (this.device.os === 'ios' || this.device.os === 'android'
      && PLATFORM_BROWSER
      && !RUNNING_AS_PWA) {
      this.pwaDialog.next({device: this.device, event: this.event})
    }
  }

  /**
   * Write time of users interaction with PWA prompt to localstorage.
   */
  finishPwaPrompt(): void {
    this.storageService.setItemStorage(StorageItem.PWA_DIALOG, new Date().toString(), false)
  }

  /**
   * Initializes device info and sets all variables to lower case
   * for easier access in {@link PwaDialogComponent}.
   */
  private initDevice(): void {
    const device = this.deviceService.getDeviceObject()
    device.os = device.os.toLowerCase()
    device.browser = device.browser.toLowerCase()
    device.type = device.type.toLowerCase()
    this.device = device
  }
}

/**
 * Request for displaying {@link PwaDialogComponent}.
 */
export interface PwaReq {
  /**
   * Detected users device to show correct installation guide.
   */
  device: DeviceRest
  /**
   * Caught **Install PWA event** for Android devices.
   * Otherwise, undefined.
   */
  event?: any
}
