import {Injectable} from '@angular/core'
import {ProfileType} from '../common/profile-type'
import {BehaviorSubject, firstValueFrom, from, Observable, of} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {HostProfileResp} from './host-profile.service'
import {ApiService} from './api.service'
import {map} from 'rxjs/operators'
import {newEmptyPage, Page} from '../rest/page-resp'
import {StorageItem, StorageService} from './storage.service'
import {HashtagResp} from './hashtag.service'
import {SkillResp} from './skill.service'
import {BriefProfessionResp} from './profession.service'
import {UserResp, UserService} from './user.service'
import {ShowcaseResp} from './showcase.service'
import {RatingResp} from './review.service'
import {AddressResp} from './address.service'
import {ProfileStatus} from '../common/profile-status'
import {ProfileOrderService} from './profile-order.service'
import {NavbarService} from './ui/navbar.service'
import {PenaltyType} from '../common/penalty-type'
import {isArrayNullOrEmpty} from '../utils/utils'
import {AppModule} from '../app.module'
import {ServerMessage} from '../common/server-message'
import {PriceItemResp} from './price-item.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ApiService {

  briefProfilesNotEvents = new BehaviorSubject<BriefProfileResp[]>([])
  eventProfiles = new BehaviorSubject<BriefProfileResp[]>([])
  currentProfile = new BehaviorSubject<ProfileResp>(null)

  constructor(
    private storageService: StorageService,
    private profileOrderService: ProfileOrderService,
    private navbarService: NavbarService) {
    super()
  }

  /**
   * - Initializes the required values.
   * - {@link currentProfileId} The new current profile id.
   */
  async init(currentProfileId?: number): Promise<void> {
    // Load user profiles
    const profiles = await firstValueFrom(this.getUserProfilesNotEvents())
    this.briefProfilesNotEvents.next(profiles.content)

    // Load current profile
    const profileId = this.getCurrentProfile()
    if (profileId || currentProfileId) {
      const currentProfile = await firstValueFrom(this.callCurrentProfile(profileId || currentProfileId))
      this.setCurrentProfile(currentProfile.uuid)
      this.currentProfile.next(currentProfile)

      // load if the current profile has some activity in order manager
      const listNotEmpty = await firstValueFrom((this.profileOrderService.callHasListAnyOrder()))
      this.navbarService.isNewActivityOrderManager(listNotEmpty.body)
    }
  }

  callNewProfile(req: NewProfileReq): Observable<BaseResp<BriefProfileResp | null>> {
    return this.post(Endpoint.PROFILE_NEW_URL, req)
  }

  callGetProfile(req: ProfileReq): Observable<BaseResp<ProfileResp>> {
    return this.post(Endpoint.PROFILE_GET_URL, req, super.getHeaders(), false)
  }

  callGetBriefProfiles(req: BriefProfilesListReq): Observable<BaseResp<Page<BriefProfileResp>>> {
    return this.post(Endpoint.PROFILE_GET_BRIEF_LIST_URL, req, super.getHeaders(), false)
  }

  callGetWithOrdersProfiles(req: ProfilesReq): Observable<BaseResp<Page<BriefProfileResp>>> {
    return this.post(Endpoint.PROFILE_GET_UNRESOLVED_ORDERS_URL, req)
  }

  callUpdateProfileNames(req: UpdateProfileNamesReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_NAMES_URL, req)
  }

  callUpdateProfileBio(req: UpdateProfileBioReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_BIO_URL, req)
  }

  callSearchBriefProfiles(req: SearchProfilesReq): Observable<BaseResp<Page<BriefProfileResp>>> {
    return this.post(Endpoint.PROFILE_SEARCH_URL, req, super.getHeaders(), false)
  }

  callSearchBriefProfileWithAddress(req: SearchProfilesWithAddressReq): Observable<BaseResp<BriefProfileResp[]>> {
    return this.post(Endpoint.PROFILE_SEARCH_WITH_ADDRESS_URL, req, super.getHeaders(), false)
  }

  callSearchOrderableProfiles(req: SearchFilterProfilesReq): Observable<BaseResp<Page<OrderableProfileResp>>> {
    return this.post(Endpoint.PROFILE_SEARCH_ORDERABLE_FILTERS_URL, req, super.getHeaders(), false)
  }

  callBriefProfiles(req: BriefProfilesReq): Observable<BaseResp<Page<BriefProfileResp>>> {
    return this.post(Endpoint.PROFILE_BRIEF_LIST_URL, req, super.getHeaders(), false)
  }

  callBriefEvents(req: BriefEventsReq): Observable<BaseResp<Page<BriefProfileResp>>> {
    return this.post(Endpoint.PROFILE_BRIEF_EVENTS_URL, req)
  }

  callSwitchProfile(req: SwitchProfileReq): Observable<BaseResp<UserResp>> {
    return this.post(Endpoint.PROFILE_SWITCH, req)
  }

  callSimilarProfiles(req: FilterSimilarProfilesReq): Observable<BaseResp<Page<OrderableProfileResp>>> {
    return this.post(Endpoint.PROFILE_SIMILAR, req, super.getHeaders(), false)
  }

  callUpdateProfileStatus(req: UpdateProfileStatusReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_STATUS, req)
  }

  callCheckActiveOrders(req: BriefProfileReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_CHECK_ACTIVE_ORDERS, req)
  }

  /**
   * Makes a call to mark the feature showcase as seen for current user.
   */
  callSeenFeatureShowcase(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_SEEN_FEATURE_SHOWCASE, {})
  }

  /**
   * Sets the current profile to the local storage.
   */
  setCurrentProfile(uuid: string): void {
    this.storageService.setItemStorage(StorageItem.CURRENT_PROFILE, uuid)
  }

  /**
   * Returns the stored current profile id from the local storage.
   */
  getCurrentProfile(): number {
    let uuid = this.storageService.getItemStorage(StorageItem.CURRENT_PROFILE)
    if (!uuid) {
      uuid = this.briefProfilesNotEvents.getValue()[0].uuid
      this.setCurrentProfile(uuid)
    }

    for (const it of this.briefProfilesNotEvents.getValue()) {
      if (it.uuid === uuid) {
        return it.profileId
      }
    }
  }

  /**
   * Tries to switch to the desired {@link profile}.
   * - Returns {@link ServerMessage.PROFILE_NOT_FOUND} when {@link profile} is not defined.
   * - Returns a positive response when the {@link profile} is the current logged profile, otherwise, it switches.
   */
  switchToProfile(profile: BriefProfileResp): Observable<BaseResp<void>> {
    // Null, skip
    if (!profile) {
      return of({messages: [{serverMessage: ServerMessage.PROFILE_NOT_FOUND}]})
    }

    // Already switched
    if (profile.profileId === this.currentProfile.getValue().profileId) {
      return of({messages: []})
    }

    // Switch
    profile.saving = true // Start saving
    // eslint-disable-next-line no-async-promise-executor
    return from(new Promise<BaseResp<void>>(async (resolve) => {
      try {
        const resp = await firstValueFrom(this.callSwitchProfile({charId: profile.charId}))
        if (resp && isArrayNullOrEmpty(resp.messages)) {
          await AppModule.injector.get(UserService).updateUser(resp.body)
        }
        return resolve({messages: resp?.messages || []})
      } finally {
        profile.saving = false // Finish saving, regardless of success or failure
      }
    }))
  }

  /**
   * - Tries to switch to the desired profile with given {@link charIdVal}.
   * - Returns {@link ServerMessage.PROFILE_NOT_FOUND} when {@link charIdVal} is not defined.
   * - Returns a positive response when the {@link charIdVal} is the current logged profile, otherwise, it switches.
   */
  switchToProfileCharId(charIdVal: string): Observable<BaseResp<void>> {
    // Null, skip
    if (!charIdVal) {
      return of({messages: [{serverMessage: ServerMessage.PROFILE_NOT_FOUND}]})
    }

    // Already switched
    if (charIdVal === this.currentProfile.getValue().charId) {
      return of({messages: []})
    }

    // Switch
    // eslint-disable-next-line no-async-promise-executor
    return from(new Promise<BaseResp<void>>(async (resolve) => {
      const resp = await firstValueFrom(this.callSwitchProfile({charId: charIdVal}))
      if (resp && isArrayNullOrEmpty(resp.messages)) {
        await AppModule.injector.get(UserService).updateUser(resp.body)
      }
      return resolve({messages: resp?.messages || []})
    }))
  }

  /**
   * Clears the service properties.
   * (e.g. due to a user logout)
   */
  clearData(): void {
    this.currentProfile.next(null)
    this.briefProfilesNotEvents.next(null)
  }

  /**
   * Calls the server API to fetch all user profiles that not includes EVENTs.
   */
  private getUserProfilesNotEvents(): Observable<Page<BriefProfileResp>> {
    const req: BriefProfilesReq = {
      types: [],
      notTypes: [ProfileType.EVENT],
      statuses: [],
      notStatuses: [ProfileStatus.DELETED, ProfileStatus.BAN]
    }

    return this.callBriefProfiles(req).pipe(
      map(it => (it.body || newEmptyPage()))
    )
  }

  /**
   * Calls the API to get the current profile data.
   *
   * @param profileIdNum The profile's id.
   */
  private callCurrentProfile(profileIdNum: number): Observable<ProfileResp> {
    if (!profileIdNum) {
      return
    }
    const req: ProfileReq = {
      profileId: profileIdNum
    }

    return this.callGetProfile(req).pipe(
      map(it => it.body)
    )
  }
}

export interface ProfileReq {
  charId?: string
  profileId?: number
}

export interface BriefProfileReq {
  profileId: number
}

export interface SearchFilterProfilesReq {
  start?: Date
  end?: Date

  priceStart?: number
  priceEnd?: number

  lat?: number
  lng?: number

  group?: boolean | null

  skills?: number[]
  professions?: number[]
  genres?: number[]
  categories?: number[]
  page: number
  size?: number

  /**
   * UI-only property to determine the request ID.
   */
  uuid?: string
}

export interface OrderableProfileResp {
  profileId: number
  uuid: string
  wallpapers?: string[]
  avatar?: string
  charId: string
  profileType: ProfileType
  displayName: string
  address?: AddressResp
  bio?: string

  minPrice: number
  minPriceFixed: boolean

  rating: RatingResp
  genres: GenreRest[]
  skills: SkillResp[]
  professions: BriefProfessionResp[]
}

export interface ProfileResp {
  profileId: number
  uuid: string
  profileType: ProfileType
  profileStatus: ProfileStatus

  wallpapers: string[]
  avatar?: string

  charId: string
  displayName: string
  group: boolean
  /**
   * Returns true if the account has filled legal entity and finished Stripe connect account.
   */
  detailsProvided: boolean

  bio: string

  showcase?: ShowcaseResp

  skills: SkillResp[]
  genres: GenreRest[]
  hashtags: HashtagResp[]
  professions: BriefProfessionResp[]

  address?: AddressResp
  hostProfile?: HostProfileResp
  rating: RatingResp
  owner: boolean
  /**
   * Defines, whether the profile is a registered legal entity.
   */
  isLegalEntity: boolean

  penalty?: PenaltyResp

  showFeatureShowcase?: boolean
  minPriceItem?: PriceItemResp
  /**
   * UI-Only property, represents the current status of saving.
   */
  saving?: boolean
  hasImageShowcase?: boolean // UI only - gets initialized in the component
}

/**
 * Update profile names and contact information.
 */
export interface UpdateProfileNamesReq {
  charId: string
  displayName: string
  group: boolean
}

export interface UpdateProfileBioReq {
  text?: string
}

export interface SkillRest {
  id: number
  name: string
  icon: string
}

export interface GenreRest {
  id: number
  name: string
  /**
   * UI-only property
   */
  selected?: boolean
}

export interface SearchProfilesWithAddressReq {
  input: string
  types: ProfileType[]
}

export interface SearchProfilesReq {
  input: string
  types: ProfileType[]
  page: number
}

export interface ProfilesReq {
  page: number
}

export interface NewProfileReq {
  type: ProfileType
  charId: string
  displayName: string
}

export interface SimpleProfileResp {
  profileId: number
  charId: string
  displayName: string
  profileType: ProfileType
  avatar?: string
  wallpapers: string[]
  professions: BriefProfessionResp[]
}

export interface BriefProfileResp {
  profileId: number
  uuid: string
  avatar?: string
  charId: string
  profileType: ProfileType
  profileStatus: ProfileStatus
  displayName: string
  professions: BriefProfessionResp[]
  rating: RatingResp

  address?: AddressResp
  hostProfile?: HostProfileResp

  /**
   * UI-Only property, represents the current status of saving.
   */
  saving?: boolean
}

export interface BriefProfilesReq {
  types: ProfileType[]
  notTypes: ProfileType[]
  statuses: ProfileStatus[]
  notStatuses: ProfileStatus[]
}

export interface BriefProfilesListReq {
  profilesIds: number[]
  page: number
}

export interface BriefEventsReq {
  page?: number
  size?: number
}

export interface SwitchProfileReq {
  charId: string
}

export interface FilterSimilarProfilesReq {
  profileId: number
  page?: number

  start?: Date
  end?: Date

  lat?: number
  lng?: number

  reviews: boolean
  price: boolean
}

export interface UpdateProfileStatusReq {
  profileId: number
  /**
   * Defines the type of the clicked status like ACTIVATE, DEACTIVATE or DELETE.
   */
  selectedStatus: ProfileStatus
}


export interface PenaltyResp {
  type: PenaltyType
  expiresAt: Date
}

export interface NotificationTargetProfile {
  id: number
  charId: string
  displayName: string
  profileType: ProfileType
  avatar?: string
}
