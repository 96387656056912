import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {NavigationService} from '../../../service/ui/navigation.service'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'
import {EditableComponent} from '../../abstract/editable.component'
import {growAnimation} from '../../../animation/grow.animation'
import {ProfileStatus} from '../../../common/profile-status'
import {ShowProfileSettingsDialogEvent} from '../profile.component'
import {ProfileRateComponent} from '../profile-rate/profile-rate.component'
import {ProfileProfessionsComponent} from '../profile-professions/profile-professions.component'
import {AsyncPipe, DatePipe, NgIf, NgTemplateOutlet} from '@angular/common'
import {ProfileIconComponent} from '../../common/profile-icon/profile-icon.component'
import {BackendValidationComponent} from '../../common/backend-validation/backend-validation.component'
import {TooltipModule} from 'primeng/tooltip'
import {ButtonComponent} from '../../common/button/button.component'
import {CountdownPipe} from '../../../pipe/countdown-timer.pipe'
import {SkeletonModule} from 'primeng/skeleton'
import {RouterLink} from '@angular/router'
import {ProfileSettingsDialogHighlight} from '../profile-settings/profile-settings.component'
import {PricePipe} from '../../../pipe/price.pipe'
import {ProfileChatButtonComponent} from './profile-chat-button/profile-chat-button.component'

@Component({
  animations: [fadeAnimation(200), growAnimation()],
  selector: 'app-profile-name-bar',
  templateUrl: './profile-name-bar.component.html',
  styleUrls: ['./profile-name-bar.component.scss'],
  imports: [
    ProfileRateComponent,
    ProfileProfessionsComponent,
    NgIf,
    ProfileIconComponent,
    BackendValidationComponent,
    TooltipModule,
    ButtonComponent,
    CountdownPipe,
    AsyncPipe,
    SkeletonModule,
    RouterLink,
    NgTemplateOutlet,
    DatePipe,
    PricePipe,
    ProfileChatButtonComponent,
  ],
  standalone: true
})
export class ProfileNameBarComponent extends EditableComponent implements OnChanges {
  /**
   * The data to be displayed.
   */
  @Input({required: true})
  data: ProfileResp
  /**
   * The current logged profile.
   */
  @Input()
  currentProfile: ProfileResp
  /**
   * Shows the {@link ProfileSettingsComponent} dialog.
   */
  @Output()
  showSettings = new EventEmitter<ShowProfileSettingsDialogEvent>()
  /**
   * Represents the visibility of the professions.
   */
  professionsVisible: boolean
  /**
   * Controls the visibility of reviews component dialog.
   */
  showReviews: boolean
  /**
   * Whether the {@link data} contains the {@link Feature.BE_ORDERED}.
   */
  canBeOrdered: boolean

  ProfileStatus: typeof ProfileStatus = ProfileStatus

  protected trans = {
    solo: $localize`Solo`,
    group: $localize`Group`
  }

  constructor(
    public navigation: NavigationService,
    public profileService: ProfileService) {
    super()
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.currentProfile || changes.data) && this.data) {
      this.professionsVisible = hasFeatures(this.data.profileType, Feature.PROFESSIONS)
      this.canBeOrdered = hasFeatures(this.data.profileType, Feature.BE_ORDERED)
    }
  }

  /**
   * Opens a setting dialog with an optional highlight of a setting.
   */
  openSettings(settingToHighlight?: ProfileSettingsDialogHighlight): void {
    // Skip when cannot edit
    if (!this.editButtonVisible) {
      return
    }
    this.showSettings.emit({show: true, highlightSetting: settingToHighlight})
  }
}

