import {BASE_SERVER_URL} from '../../environments/environment'

/**
 * Contains all endpoints to the main application server.
 */
export const Endpoint = {
  LOGIN_URL: `${BASE_SERVER_URL}/auth/login`,
  LOGOUT_URL: `${BASE_SERVER_URL}/auth/logout`,
  LOGOUT_ALL_URL: `${BASE_SERVER_URL}/auth/logout/all`,
  LOGIN_TWO_FACTOR_URL: `${BASE_SERVER_URL}/auth/login/two-factor`,
  REGISTER_URL: `${BASE_SERVER_URL}/auth/register`,
  RESTORE_PASSWORD_REQUEST_URL: `${BASE_SERVER_URL}/auth/restore/request`,
  RESTORE_PASSWORD_URL: `${BASE_SERVER_URL}/auth/restore`,
  RESTORE_CODE_URL: `${BASE_SERVER_URL}/auth/restore/two-factor-code`,
  FIREBASE_AUTH_URL: `${BASE_SERVER_URL}/auth/login/firebase`,
  FIREBASE_REGISTER_URL: `${BASE_SERVER_URL}/auth/register/firebase`,
  COOKIES_VERSION_INFO_URL: `${BASE_SERVER_URL}/auth/cookies`,
  CHECK_UNIQUE_USER: `${BASE_SERVER_URL}/auth/register/verify-unique-user`,
  PHONE_VERIFICATION_INIT: `${BASE_SERVER_URL}/auth/verify-phone/init`,
  PHONE_VERIFICATION: `${BASE_SERVER_URL}/auth/verify-phone`,
  POLICY_UPDATE_URL: `${BASE_SERVER_URL}/auth/policy`,

  GET_USER_URL: `${BASE_SERVER_URL}/auth/jwt`,
  USER_FCM_UPDATE_URL: `${BASE_SERVER_URL}/auth/fcm`,
  USER_UPDATE_EMAIL_INIT: `${BASE_SERVER_URL}/auth/update/email/init`,
  USER_UPDATE_EMAIL: `${BASE_SERVER_URL}/auth/update/email`,
  USER_UPDATE_PROVIDER: `${BASE_SERVER_URL}/auth/update/auth-provider`,
  USER_SETTINGS_GET: `${BASE_SERVER_URL}/user/settings/get`,
  USER_SETTINGS_UPDATE: `${BASE_SERVER_URL}/user/settings/update`,
  USER_SETTINGS_CHANGE_PASSWORD_INIT: `${BASE_SERVER_URL}/user/settings/change-password/init`,
  USER_SETTINGS_CHANGE_PASSWORD: `${BASE_SERVER_URL}/user/settings/change-password`,
  USER_SETTINGS_CHECK_PASSWORD: `${BASE_SERVER_URL}/user/settings/check-password`,
  USER_SETTINGS_CHANGE_PHONE_INIT: `${BASE_SERVER_URL}/user/settings/change-phone/init`,
  USER_SETTINGS_CHANGE_PHONE: `${BASE_SERVER_URL}/user/settings/change-phone`,
  USER_SETTINGS_CHANGE_TWO_FACTOR_INIT: `${BASE_SERVER_URL}/user/settings/change-two-factor/init`,
  USER_SETTINGS_CHANGE_TWO_FACTOR: `${BASE_SERVER_URL}/user/settings/change-two-factor`,
  USER_SETTINGS_SESSIONS: `${BASE_SERVER_URL}/user/settings/sessions`,
  USER_SETTINGS_SESSIONS_LOGOUT: `${BASE_SERVER_URL}/user/settings/sessions/logout`,
  USER_SETTINGS_SESSIONS_DELETE: `${BASE_SERVER_URL}/user/settings/sessions/delete`,

  PROFILE_NEW_URL: `${BASE_SERVER_URL}/profile/new`,
  PROFILE_GET_URL: `${BASE_SERVER_URL}/profile/get`,
  PROFILE_GET_BRIEF_LIST_URL: `${BASE_SERVER_URL}/profile/get/brief/list`,
  PROFILE_GET_UNRESOLVED_ORDERS_URL: `${BASE_SERVER_URL}/profile/get/unresolved-orders`,
  PROFILE_SEARCH_URL: `${BASE_SERVER_URL}/profile/search`,
  PROFILE_SEARCH_WITH_ADDRESS_URL: `${BASE_SERVER_URL}/profile/search/address`,
  PROFILE_SEARCH_ORDERABLE_FILTERS_URL: `${BASE_SERVER_URL}/profile/filter/orderable`,
  PROFILE_UPDATE_NAMES_URL: `${BASE_SERVER_URL}/profile/update/names`,
  PROFILE_UPDATE_BIO_URL: `${BASE_SERVER_URL}/profile/update/bio`,
  PROFILE_UPDATE_STATUS: `${BASE_SERVER_URL}/profile/update/status`,
  PROFILE_CHECK_ACTIVE_ORDERS: `${BASE_SERVER_URL}/profile/check/active-orders`,
  PROFILE_BRIEF_GET_URL: `${BASE_SERVER_URL}/profile/brief/get`,
  PROFILE_BRIEF_LIST_URL: `${BASE_SERVER_URL}/profile/brief/list`,
  PROFILE_BRIEF_EVENTS_URL: `${BASE_SERVER_URL}/profile/brief/events`,
  PROFILE_SWITCH: `${BASE_SERVER_URL}/profile/switch`,
  PROFILE_SIMILAR: `${BASE_SERVER_URL}/profile/similar`,
  PROFILE_SEEN_FEATURE_SHOWCASE: `${BASE_SERVER_URL}/profile/seen-feature-showcase`,

  WALLPAPERS_GET_EDIT_URL: `${BASE_SERVER_URL}/wallpaper/get-edit`,
  WALLPAPERS_NEW_URL: `${BASE_SERVER_URL}/wallpaper/new`,
  WALLPAPERS_UPDATE_URL: `${BASE_SERVER_URL}/wallpaper/update`,

  IMAGE_SHOWCASE_ALL: `${BASE_SERVER_URL}/showcase/image/all`,
  IMAGE_SHOWCASE_ALL_EDIT: `${BASE_SERVER_URL}/showcase/image/all/edit`,
  IMAGE_SHOWCASE_NEW: `${BASE_SERVER_URL}/showcase/image/new`,
  IMAGE_SHOWCASE_UPDATE: `${BASE_SERVER_URL}/showcase/image/update`,

  AVATAR_GET_EDIT_URL: `${BASE_SERVER_URL}/avatar/get-edit`,
  AVATAR_NEW_URL: `${BASE_SERVER_URL}/avatar/new`,
  AVATAR_CROP_URL: `${BASE_SERVER_URL}/avatar/crop`,
  AVATAR_REMOVE_URL: `${BASE_SERVER_URL}/avatar/remove`,

  GENRE_SEARCH_URL: `${BASE_SERVER_URL}/genre/search`,
  GENRE_ALL_URL: `${BASE_SERVER_URL}/genre/all`,
  GENRE_MOST_USED: `${BASE_SERVER_URL}/genre/most-used`,
  PROFILE_UPDATE_GENRES_URL: `${BASE_SERVER_URL}/genre/profile/update`,

  SKILL_SEARCH_URL: `${BASE_SERVER_URL}/skill/search`,
  SKILL_ALL_URL: `${BASE_SERVER_URL}/skill/all`,
  SKILL_MOST_USED: `${BASE_SERVER_URL}/skill/most-used`,
  PROFILE_UPDATE_SKILLS_URL: `${BASE_SERVER_URL}/skill/profile/update`,

  PROFESSION_SEARCH_URL: `${BASE_SERVER_URL}/profession/search`,
  PROFESSION_GET_URL: `${BASE_SERVER_URL}/profession/get`,
  PROFESSION_FIND_BY_SLUG: `${BASE_SERVER_URL}/profession/find-by-slug`,
  PROFESSION_ALL_URL: `${BASE_SERVER_URL}/profession/all`,
  PROFESSION_MOST_USED: `${BASE_SERVER_URL}/profession/most-used`,
  PROFILE_UPDATE_PROFESSIONS_URL: `${BASE_SERVER_URL}/profession/profile/update`,

  PROFILE_UPDATE_ADDRESS_URL: `${BASE_SERVER_URL}/address/profile/update`,
  PROFILE_DELETE_ADDRESS_URL: `${BASE_SERVER_URL}/address/profile/delete`,

  PROFILE_UPDATE_HOST_PROFILE_URL: `${BASE_SERVER_URL}/host-profile/profile/host`,
  PROFILE_COUNT_PENDING_HOST_PROFILES_URL: `${BASE_SERVER_URL}/host-profile/profile/pending/count`,
  PROFILE_FIND_PENDING_HOST_PROFILES_URL: `${BASE_SERVER_URL}/host-profile/profile/pending`,
  PROFILE_UPDATE_HOST_PROFILE_SEEN_URL: `${BASE_SERVER_URL}/host-profile/profile/seen`,
  PROFILE_UPDATE_HOST_PROFILE_HOST_SEEN_URL: `${BASE_SERVER_URL}/host-profile/profile/seen/host`,
  PROFILE_UPDATE_HOST_PROFILE_ACCEPTANCE_URL: `${BASE_SERVER_URL}/host-profile/profile/acceptance`,
  PROFILE_UPDATE_HOST_PROFILE_HOST_ACCEPTANCE_URL: `${BASE_SERVER_URL}/host-profile/profile/acceptance/host`,
  PROFILE_DELETE_HOST_PROFILE: `${BASE_SERVER_URL}/host-profile/profile/delete`,

  MAP_EXPLORE_BOUNDS_URL: `${BASE_SERVER_URL}/map/explore`,
  MAP_EXPLORE_BOUNDS_FILTER_URL: `${BASE_SERVER_URL}/map/explore/filter`,
  MAP_GET_PROFILE_DETAILS: `${BASE_SERVER_URL}/map/profile/details`,

  CALENDAR_ITEM_NEW_URL: `${BASE_SERVER_URL}/calendar/profile/new`,
  CALENDAR_ITEM_NESTED_PROFILE_URL: `${BASE_SERVER_URL}/calendar/profile/nested-profile`,
  CALENDAR_ITEM_UPDATE_URL: `${BASE_SERVER_URL}/calendar/profile/update`,
  CALENDAR_ITEM_FIND_BETWEEN_URL: `${BASE_SERVER_URL}/calendar/profile/between`,
  CALENDAR_ITEM_FIND_BETWEEN_ORDERS_URL: `${BASE_SERVER_URL}/calendar/profile/between/orders`,
  CALENDAR_ITEM_FIND_FIRST_URL: `${BASE_SERVER_URL}/calendar/profile/first`,
  CALENDAR_ITEM_DELETE_URL: `${BASE_SERVER_URL}/calendar/profile/delete`,

  OPENING_HOURS_UPDATE_URL: `${BASE_SERVER_URL}/opening-hours/update`,
  OPENING_HOURS_GET_URL: `${BASE_SERVER_URL}/opening-hours/get`,
  OPENING_HOURS_GET_DATE_URL: `${BASE_SERVER_URL}/opening-hours/get/date`,

  OPENING_HOURS_RESTRICTION_UPDATE_URL: `${BASE_SERVER_URL}/opening-hours-restriction/update`,
  OPENING_HOURS_RESTRICTION_GET_URL: `${BASE_SERVER_URL}/opening-hours-restriction/get`,
  OPENING_HOURS_RESTRICTION_FIND_URL: `${BASE_SERVER_URL}/opening-hours-restriction/find`,
  OPENING_HOURS_RESTRICTION_DELETE_URL: `${BASE_SERVER_URL}/opening-hours-restriction/delete`,

  SCHEDULE_UPDATE_URL: `${BASE_SERVER_URL}/schedule/update`,
  SCHEDULE_GET_URL: `${BASE_SERVER_URL}/schedule/get`,
  SCHEDULE_UPDATE_ACCEPTANCE_URL: `${BASE_SERVER_URL}/schedule/acceptance`,
  SCHEDULE_UPDATE_SEEN_URL: `${BASE_SERVER_URL}/schedule/seen`,

  CHAT_CHANNEL_GET: `${BASE_SERVER_URL}/chat/channel/get`,
  CHAT_CHANNEL_GET_OR_CREATE: `${BASE_SERVER_URL}/chat/channel/get-create`,
  CHAT_CHANNEL_ALL_ACTIVE_ORDERS: `${BASE_SERVER_URL}/chat/channel/of-active-orders`,
  CHAT_CHANNEL_CHAT_SUITABLE_ORDER: `${BASE_SERVER_URL}/chat/channel/open-order`,
  CHAT_CHANNEL_NEW: `${BASE_SERVER_URL}/chat/channel/new`,
  CHAT_CHANNEL_UPDATE: `${BASE_SERVER_URL}/chat/channel/update`,
  CHAT_CHANNEL_SEEN: `${BASE_SERVER_URL}/chat/channel/seen`,
  CHAT_CHANNEL_UNREAD: `${BASE_SERVER_URL}/chat/channel/unread`,
  CHAT_CHANNEL_SEARCH: `${BASE_SERVER_URL}/chat/channel/search`,
  CHAT_CHANNEL_ALL: `${BASE_SERVER_URL}/chat/channel/all`,
  CHAT_CHANNEL_ADD: `${BASE_SERVER_URL}/chat/channel/add`,
  CHAT_CHANNEL_LEAVE: `${BASE_SERVER_URL}/chat/channel/leave`,
  CHAT_CHANNEL_RENAME: `${BASE_SERVER_URL}/chat/channel/rename`,
  CHAT_CHANNEL_DELETE: `${BASE_SERVER_URL}/chat/channel/delete`,
  CHAT_CHANNEL_ALL_REVIEW: `${BASE_SERVER_URL}/chat/channel/all/review`,
  CHAT_CHANNEL_UNLOCK: `${BASE_SERVER_URL}/chat/channel/unlock`,

  CHAT_MESSAGE_NEW: `${BASE_SERVER_URL}/chat/message/new`,
  CHAT_MESSAGE_EDIT: `${BASE_SERVER_URL}/chat/message/edit`,
  CHAT_MESSAGE_SEEN_BY: `${BASE_SERVER_URL}/chat/message/seen`,
  CHAT_MESSAGE_DELETE: `${BASE_SERVER_URL}/chat/message/delete`,
  CHAT_MESSAGE_LOAD: `${BASE_SERVER_URL}/chat/message/load`,
  CHAT_MESSAGE_LOAD_UNCHECKED: `${BASE_SERVER_URL}/chat/message/load/unchecked`,
  CHAT_MESSAGE_VIOLATIONS_RESOLVE: `${BASE_SERVER_URL}/chat/message/report/resolve`,
  CHAT_MESSAGE_VIOLATIONS_RESOLVE_ALL: `${BASE_SERVER_URL}/chat/message/report/resolve/all`,

  LIKE_PROFILE_UPDATE: `${BASE_SERVER_URL}/like/profile/update`,
  LIKE_PROFILE_GET: `${BASE_SERVER_URL}/like/profile/get`,

  LIKE_POST_UPDATE: `${BASE_SERVER_URL}/like/post/update`,

  LIKE_COMMENT_UPDATE: `${BASE_SERVER_URL}/like/comment/update`,

  POST_NEW: `${BASE_SERVER_URL}/post/new`,
  POST_NEW_MEDIA_IMAGE: `${BASE_SERVER_URL}/post/media/image`,
  POST_ALL: `${BASE_SERVER_URL}/post/all`,
  POST_UPDATE: `${BASE_SERVER_URL}/post/update`,
  POST_DELETE: `${BASE_SERVER_URL}/post/delete`,

  POST_COMMENT_NEW: `${BASE_SERVER_URL}/post/comment/new`,
  POST_COMMENT_EDIT: `${BASE_SERVER_URL}/post/comment/edit`,
  POST_COMMENT_DELETE: `${BASE_SERVER_URL}/post/comment/delete`,
  POST_COMMENT_LOAD: `${BASE_SERVER_URL}/post/comment/load`,

  FEED_LOAD_BY_FOLLOW: `${BASE_SERVER_URL}/feed/follow`,
  FEED_LOAD_BY_LOCATION: `${BASE_SERVER_URL}/feed/location`,

  HASHTAG_SEARCH: `${BASE_SERVER_URL}/hashtag/search`,
  HASHTAG_PROFILE_UPDATE: `${BASE_SERVER_URL}/hashtag/profile/update`,

  PRICE_ITEM_UPDATE: `${BASE_SERVER_URL}/price-item/update`,
  PRICE_ITEM_DELETE: `${BASE_SERVER_URL}/price-item/delete`,
  PRICE_ITEM_ALL: `${BASE_SERVER_URL}/price-item/all`,
  PRICE_ITEM_GET: `${BASE_SERVER_URL}/price-item/get`,
  PRICE_ITEM_GET_MIN: `${BASE_SERVER_URL}/price-item/get/min`,
  PRICE_ITEM_GET_BRIEF: `${BASE_SERVER_URL}/price-item/get/brief`,
  PRICE_ITEM_FILTER: `${BASE_SERVER_URL}/price-item/filter`,
  PRICE_ITEM_HAS: `${BASE_SERVER_URL}/price-item/has-items`,

  PRICE_ITEM_CATEGORY_SEARCH: `${BASE_SERVER_URL}/price-item-category/search`,
  PRICE_ITEM_CATEGORY_ALL: `${BASE_SERVER_URL}/price-item-category/all`,
  PRICE_ITEM_CATEGORY_ALL_OF_PROFILE: `${BASE_SERVER_URL}/price-item-category/all/profile`,
  PRICE_ITEM_CATEGORY_FIND_BY_SLUG: `${BASE_SERVER_URL}/price-item-category/find-by-slug`,
  PRICE_ITEM_CATEGORY_MOST_USED: `${BASE_SERVER_URL}/price-item-category/most-used`,
  PRICE_ITEM_CATEGORY_PROFESSIONS: `${BASE_SERVER_URL}/price-item-category/professions`,

  PRICE_ITEM_ADDITION_ALL: `${BASE_SERVER_URL}/price-item-addition/all`,
  PRICE_ITEM_ADDITION_GET: `${BASE_SERVER_URL}/price-item-addition/get`,
  PRICE_ITEM_ADDITION_UPDATE: `${BASE_SERVER_URL}/price-item-addition/update`,
  PRICE_ITEM_ADDITION_DELETE: `${BASE_SERVER_URL}/price-item-addition/delete`,

  PROFILE_ORDER_NEW: `${BASE_SERVER_URL}/profile-order/new`,
  PROFILE_ORDER_GET: `${BASE_SERVER_URL}/profile-order/get`,
  PROFILE_ORDER_GET_INVOICES: `${BASE_SERVER_URL}/profile-order/order-invoices`,
  PROFILE_ORDER_ACCEPTANCE: `${BASE_SERVER_URL}/profile-order/acceptance`,
  PROFILE_ORDER_CHECK_ALL_CONFIRM_REQUIREMENTS: `${BASE_SERVER_URL}/profile-order/check-orders-confirmations`,
  PROFILE_ORDER_CONFIRM_ARRIVAL: `${BASE_SERVER_URL}/profile-order/confirm-arrival`,
  PROFILE_ORDER_CHECK_PROFILE_ARRIVAL: `${BASE_SERVER_URL}/profile-order/check-arrival`,
  PROFILE_ORDER_SEARCH: `${BASE_SERVER_URL}/profile-order/search`,
  PROFILE_ORDER_ALL: `${BASE_SERVER_URL}/profile-order/all`,
  PROFILE_ORDER_ALL_FILTERED_REJECTED: `${BASE_SERVER_URL}/profile-order/all-filtered-rejected`,
  PROFILE_ORDER_ALL_FILTERED_ACCEPTED: `${BASE_SERVER_URL}/profile-order/all-filtered-accepted`,
  PROFILE_ORDER_ANY_IN_LIST: `${BASE_SERVER_URL}/profile-order/has-orders-in-list`,
  PROFILE_ORDER_HAS_ACTIVE_ORDERS: `${BASE_SERVER_URL}/profile-order/check-active`,
  PROFILE_ORDER_DELETE: `${BASE_SERVER_URL}/profile-order/delete`,
  PROFILE_ORDER_CONFIRM_LOCATION: `${BASE_SERVER_URL}/profile-order/confirm-location`,
  PROFILE_ORDER_PLATFORM_DOCUMENTS: `${BASE_SERVER_URL}/profile-order/platform-documents`,

  SHOWCASE_UPDATE: `${BASE_SERVER_URL}/showcase/update`,

  REVIEW_PROFILE_NEW: `${BASE_SERVER_URL}/review/new`,
  REVIEW_PROFILE_EDIT: `${BASE_SERVER_URL}/review/edit`,
  REVIEW_PROFILE_OF_ORDERS: `${BASE_SERVER_URL}/review/get/profile-of-orders`,
  REVIEW_AUTHOR_OF_ORDERS: `${BASE_SERVER_URL}/review/get/author-of-orders`,
  REVIEW_PROFILE_DELETE: `${BASE_SERVER_URL}/review/delete`,
  REVIEW_COUNT_BY_PROFILE: `${BASE_SERVER_URL}/count-by-profile`,
  REVIEW_AVERAGE_PROFILE_RATING: `${BASE_SERVER_URL}/review/profile-rating`,

  STRIPE_CREATE_PAYMENT_INTENT: `${BASE_SERVER_URL}/stripe/order-payment`,
  STRIPE_PAYMENT_INTENT_NEXT_ACTION: `${BASE_SERVER_URL}/stripe/order-payment/next-action`,
  STRIPE_CREATE_CONNECT_ACCOUNT: `${BASE_SERVER_URL}/stripe/create-connect-account`,
  STRIPE_LOCALHOST_ACCEPT_PAYMENT: `${BASE_SERVER_URL}/stripe/localhost-accept-payment`,
  STRIPE_LOCALHOST_SIMULATE_BANK_TRANSFER: `${BASE_SERVER_URL}/stripe/localhost-simulate-bank-transfer`,
  STRIPE_CHECK_PERFORMER_ACCOUNT_FINISHED: `${BASE_SERVER_URL}/stripe/connect-completed`,
  STRIPE_GET_CONNECT_SETTINGS: `${BASE_SERVER_URL}/stripe/connect-details`,
  STRIPE_UPDATE_PROFILE_LEGAL_ENTITY: `${BASE_SERVER_URL}/stripe/legal-entity`,
  STRIPE_SEARCH_LEGAL_ENTITY: `${BASE_SERVER_URL}/stripe/search-legal-entity`,
  STRIPE_CUSTOMER_ALL_INV_ADDRESSES: `${BASE_SERVER_URL}/stripe/invoicing-addresses`,
  STRIPE_CUSTOMER_DELETE_INV_ADDRESS: `${BASE_SERVER_URL}/stripe/invoicing-addresses/delete`,

  QR_SCANNER_FIND_ORDER: `${BASE_SERVER_URL}/qr-code/find-order`,
  QR_CODE_GENERATE: `${BASE_SERVER_URL}/qr-code/generate-qr`,

  CSRF_TOKEN: `${BASE_SERVER_URL}/auth/csrf`,

  SUPPORT_DOCUMENTS: `${BASE_SERVER_URL}/support/documents`,

  PLATFORM_RATING_NEW: `${BASE_SERVER_URL}/platform/rating`,

  NOTIFICATION_GET_ALL: `${BASE_SERVER_URL}/notification/get-all`,
  NOTIFICATION_UPDATE_SEEN_STATUS: `${BASE_SERVER_URL}/notification/update-seen-status`,
  NOTIFICATION_MARK_ALL_SEEN: `${BASE_SERVER_URL}/notification/mark-all-seen`,
  NOTIFICATION_DELETE: `${BASE_SERVER_URL}/notification/delete`,

  CITY_FIND_BY_SLUG: `${BASE_SERVER_URL}/city/find-by-slug`,

  /***********************
   * TESTING ONLY ENDPOINTS
   ***********************/
  PHONE_VERIFICATION_CODE: `${BASE_SERVER_URL}/prepare-data/get-auth-code`,

  /***********************
   * ADMIN ONLY ENDPOINTS
   ***********************/
  ADMIN_PROFILE_ORDER_BRIEF: `${BASE_SERVER_URL}/profile-order/admin/brief`,
  ADMIN_PROFILE_ORDER_BRIEF_FROZEN: `${BASE_SERVER_URL}/profile-order/admin/brief/frozen`,
  ADMIN_PROFILE_ORDER: `${BASE_SERVER_URL}/profile-order/admin/detail`,
  ADMIN_PROFILE_ORDER_DISPUTE_RESOLVE: `${BASE_SERVER_URL}/profile-order/dispute/resolve`,
  ADMIN_PROFILE_ORDER_MAKE_LIVE: `${BASE_SERVER_URL}/profile-order/admin/move/live`,
  ADMIN_PROFILE_ORDER_MAKE_FINISHED: `${BASE_SERVER_URL}/profile-order/admin/move/finish`,
  ADMIN_PROFILE_ORDER_MODIFY_ACCEPTANCE: `${BASE_SERVER_URL}/profile-order/admin/acceptance`,
  ADMIN_PROFILE_ORDER_STATS: `${BASE_SERVER_URL}/profile-order/admin/stats`,
  ADMIN_PROFILE_ORDER_NEW: `${BASE_SERVER_URL}/profile-order/admin/create`,
  ADMIN_PROFILE_ORDER_MOVE_CREATED_AT: `${BASE_SERVER_URL}/profile-order/admin/move/created-at`,
  ADMIN_PROFILE_ORDER_CHECK_PAID: `${BASE_SERVER_URL}/profile-order/admin/check/paid`,

  ADMIN_PROFILE_GET: `${BASE_SERVER_URL}/profile/admin/get`,
  ADMIN_PROFILE_GET_COMPLEX: `${BASE_SERVER_URL}/profile/admin/get/complex`,
  ADMIN_PROFILE_GET_LIST: `${BASE_SERVER_URL}/profile/admin/get/list`,
  ADMIN_PROFILE_GET_BLOCKED_MESSAGES: `${BASE_SERVER_URL}/profile/admin/blocked-messages`,
  ADMIN_PROFILES_OVERVIEW: `${BASE_SERVER_URL}/profile/admin/overview`,
  ADMIN_PROFILES_DAILY_STATS: `${BASE_SERVER_URL}/profile/admin/daily-stats`,
  ADMIN_PROFILES_PEAK_HOURS: `${BASE_SERVER_URL}/profile/admin/peak-hours`,
  ADMIN_ORDERABLE_PROFILES: `${BASE_SERVER_URL}/profile/admin/search/orderable`,
  ADMIN_AUTHOR_PROFILES: `${BASE_SERVER_URL}/profile/admin/search/author`,

  ADMIN_UPDATE_PLATFORM_DOCUMENTS: `${BASE_SERVER_URL}/admin/documents/update`,

  ADMIN_PRICE_ITEM_CATEGORY_ALL: `${BASE_SERVER_URL}/admin/price-item-category/all`,
  ADMIN_PRICE_ITEM_CATEGORY_UPDATE: `${BASE_SERVER_URL}/admin/price-item-category/update`,
  ADMIN_PRICE_ITEM_CATEGORY_SEARCH: `${BASE_SERVER_URL}/admin/price-item-category/search`,
  ADMIN_PRICE_ITEM_CATEGORY_UNUSED_PROFESSIONS: `${BASE_SERVER_URL}/admin/price-item-category/unused-professions`,
  ADMIN_PRICE_ITEM_CATEGORY_DELETE: `${BASE_SERVER_URL}/admin/price-item-category/delete`,
  ADMIN_PRICE_ITEM_CATEGORY_UPDATE_FOR_ITEM: `${BASE_SERVER_URL}/admin/price-item-category/update/item`,

  ADMIN_SKILL_GET_ALL: `${BASE_SERVER_URL}/admin/skill/all`,
  ADMIN_SKILL_UPDATE: `${BASE_SERVER_URL}/admin/skill/update`,
  ADMIN_SKILL_DELETE: `${BASE_SERVER_URL}/admin/skill/delete`,
  ADMIN_SKILL_PROFILE_UPDATE: `${BASE_SERVER_URL}/admin/skill/update/profile`,

  ADMIN_GENRE_GET_ALL: `${BASE_SERVER_URL}/admin/genre/all`,
  ADMIN_GENRE_UPDATE: `${BASE_SERVER_URL}/admin/genre/update`,
  ADMIN_GENRE_DELETE: `${BASE_SERVER_URL}/admin/genre/delete`,
  ADMIN_GENRE_PROFILE_UPDATE: `${BASE_SERVER_URL}/admin/genre/update/profile`,

  ADMIN_PROFESSION_GET_ALL: `${BASE_SERVER_URL}/admin/profession/all`,
  ADMIN_PROFESSION_UPDATE: `${BASE_SERVER_URL}/admin/profession/update`,
  ADMIN_PROFESSION_DELETE: `${BASE_SERVER_URL}/admin/profession/delete`,
  ADMIN_PROFESSION_PROFILE_UPDATE: `${BASE_SERVER_URL}/admin/profession/update/profile`,
  ADMIN_PROFESSION_NEW_IMAGE: `${BASE_SERVER_URL}/admin/profession/image/new`,

  ADMIN_SEARCH_INPUT_GET_ALL: `${BASE_SERVER_URL}/admin/search-input/all`,
  ADMIN_SEARCH_INPUT_GET_FILTER: `${BASE_SERVER_URL}/admin/search-input/filter`,

  ADMIN_PLATFORM_RATING_SEARCH: `${BASE_SERVER_URL}/admin/platform-rating/search`,

  ADMIN_CITY_GET_ALL: `${BASE_SERVER_URL}/admin/city/all`,
  ADMIN_CITY_UPDATE: `${BASE_SERVER_URL}/admin/city/update`,
  ADMIN_CITY_DELETE: `${BASE_SERVER_URL}/admin/city/delete`,
  ADMIN_CITY_SEARCH: `${BASE_SERVER_URL}/admin/city/search`,

  ADMIN_SEO_PAGE_UPDATE: `${BASE_SERVER_URL}/admin/seo-page/update`,
  ADMIN_SEO_PAGE_SEARCH: `${BASE_SERVER_URL}/admin/seo-page/search`,
  ADMIN_SEO_PAGE_DELETE: `${BASE_SERVER_URL}/admin/seo-page/delete`,
  ADMIN_SEO_PAGE_IMAGE_NEW: `${BASE_SERVER_URL}/admin/seo-page/image/new`,
  ADMIN_SEO_PAGE_RUN_EXPORT: `${BASE_SERVER_URL}/admin/seo-page/run-export`,
}
