import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {NavigationService} from '../../service/ui/navigation.service'
import {NavbarService} from '../../service/ui/navbar.service'
import {MenuItem} from 'primeng/api'
import {BriefProfileResp, ProfileService} from '../../service/profile.service'
import {UserService} from '../../service/user.service'
import {fadeAnimation} from '../../animation/fade.animation'
import {ScreenSize} from '../../utils/device.utils'
import {defaultAvatar} from '../../utils/asset.utils'
import {EditableComponent} from '../abstract/editable.component'
import {hasFeatures} from '../../common/profile-type'
import {FcmService, RawFCMMessage} from '../../service/fcm.service'
import {hasRole, Role} from '../../common/roles'
import {BasketService} from '../../service/basket.service'
import {Feature} from '../../common/feature'
import {PLATFORM_BROWSER} from '../../app.module'
import {CookiesSettingsService} from '../../service/cookies-settings.service'
import {OrderManagerListTypeEnum} from '../../modules/order-manager/order-manager-list-type.enum'
import {firstValueFrom, Subscription} from 'rxjs'
import {ChatChannelService} from '../../service/chat-channel.service'
import {scrollToIndexOptions} from '../../utils/scroll.utils'
import {DialogComponent} from '../common/dialog/dialog.component'

@Component({
  animations: [fadeAnimation(200)],
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends EditableComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * The current logged user profile.
   */
  currentProfile: BriefProfileResp

  /**
   * Represents the visibility of the search input field.
   */
  showSearch: boolean
  /**
   * Determines, whether this profile has some active orders.
   */
  hasActiveOrders: boolean
  /**
   * Defines the user menu options.
   */
  userMenuItems: MenuItem[]

  /**
   * Defines the navbar icon options on small screens.
   */
  iconMenuItems: MenuItem[]

  /**
   * Controls the navbar transparency.
   */
  navbarTransparent: boolean

  /**
   * Controls the navbar visibility.
   */
  navbarVisible = true

  /**
   * Controls the visibility of the 'Switch profile dialog'.
   */
  switchProfileComponentVisible: boolean

  /**
   * Controls the visibility of the 'Search profiles' dialog on small screens.
   */
  searchProfileDialogVisible: boolean

  /**
   * Controls the dialog visibility of the ordered profiles from localstorage.
   */
  orderedProfilesDialogVis = false

  /**
   * Controls the badge visibility of the ordered profiles from local storage.
   */
  orderedBadgeVisible = this.basketService.hasBasketItems

  /**
   * Controls the badge visibility of the new activity (new order ordered or order accepted in order manager.
   */
  newOrderManagerActivity = this.navbarService.orderManagerActivity

  NS: typeof NavigationService = NavigationService

  private subs: Subscription[] = []

  constructor(
    public navigation: NavigationService,
    public changeRef: ChangeDetectorRef,
    public navbarService: NavbarService,
    private profileService: ProfileService,
    private basketService: BasketService,
    private userService: UserService,
    private fcmService: FcmService,
    private chatChannelService: ChatChannelService,
    private cookiesSettingsService: CookiesSettingsService) {
    super()
  }

  ngOnInit(): void {
    this.subscribeTransparencyChange()
    this.subscribeVisibilityChange()
    this.initToggleMenuItems()

    this.subs.push(
      this.profileService.currentProfile.subscribe(data => {
        this.currentProfile = data
        this.initHasActiveOrders()
        this.initProfileMenu()
        this.initToggleMenuItems()
      }),

      this.profileService.briefProfilesNotEvents.subscribe(() => {
        this.initProfileMenu()
      }),

      this.userService.user.subscribe(user => {
        if (user) {
          this.initProfileMenu()
        }
      }),

      this.fcmService.onMessage.subscribe((message: RawFCMMessage) => {
        if (((message.data.category === FcmService.NEW_ORDER) && hasFeatures(this.currentProfile.profileType, Feature.BE_ORDERED)) ||
          ((message.data.category === FcmService.ACCEPTED_ORDER) && hasFeatures(this.currentProfile.profileType, Feature.ORDER_PROFILE))) {
          this.navbarService.isNewActivityOrderManager(true)
        }
      }))
  }

  ngAfterViewInit(): void {
    this.setNavbarInvisibleWhileScrolling()
  }

  /**
   * Fires when a user clicked on the search icon.
   */
  onSearchIconClicked(): void {
    if (!this.isScreenOf(ScreenSize.MD)) {
      this.searchProfileDialogVisible = true
    } else {
      this.showSearch = true
    }
  }

  /**
   * - Navigates to the profile of {@link charId}.
   * - The optional {@link dialog} property is for closing that dialog properly before any other action.
   */
  navigateToProfile(charId: string, dialog: DialogComponent | null = null): void {
    // If dialog, first close the dialog and then redirect.
    if (dialog) {
      dialog.closeDialog(this.navigateToProfile.bind(this, charId))
      return
    }

    // causes the order dialog to open
    this.navigation.toProfile(charId)

    setTimeout(() => {
      // scroll to check out button of the profile
      scrollToIndexOptions('mobile-layout', 0, {
        behavior: 'smooth',
        block: 'center'
      })
      this.changeRef.detectChanges()
    }, 3000)
  }

  /**
   * Opens the {@link orderedProfilesDialogVis} dialog.
   */
  showOrderedProfilesDialog(): void {
    this.orderedProfilesDialogVis = true
    this.changeRef.detectChanges()
  }

  /**
   * Initializes the {@link hasActiveOrders} property from the server.
   */
  async initHasActiveOrders(): Promise<void> {
    if (!this.currentProfile) {
      return
    }
    const profileType = this.currentProfile.profileType
    if (hasFeatures(profileType, Feature.BE_ORDERED) || hasFeatures(profileType, Feature.ORDER_PROFILE)) {
      this.hasActiveOrders = await firstValueFrom(this.unwrap(this.chatChannelService.callExistsChatSuitableOrder()))
      this.initProfileMenu()
    }
  }

  ngOnDestroy(): void {
    this.subs?.forEach(it => it?.unsubscribe())
  }

  /**
   * Sets the navigation bar invisible while a user scrolls.
   */
  private setNavbarInvisibleWhileScrolling(): void {
    this.navbarService.autoHide.subscribe(enabled => {
      if (PLATFORM_BROWSER) {
        if (enabled) {
          // start listening
          let prevScrollPos = window.scrollY
          window.onscroll = () => {
            if (!this.navbarService.autoHide.getValue()) {
              this.updateVisibility(false)
              return
            }
            const currentScrollY = window.scrollY
            const scrollDifference = currentScrollY - prevScrollPos
            const isScrollingDown = scrollDifference > 0
            prevScrollPos = currentScrollY

            if (isScrollingDown && scrollDifference > 10) {
              this.updateVisibility(false)
            } else {
              if (currentScrollY <= 20 || scrollDifference <= -20) {
                this.updateVisibility(true)
              }
            }
          }
        } else {
          window.onscroll = null
          this.updateVisibility(true)
        }
      }
    })
  }

  /**
   * Updates the {@link navbarVisible} value and emits to the {@link NavbarService.visible} property.
   */
  private updateVisibility(visible: boolean): void {
    this.navbarVisible = visible
    this.navbarService.visible.next(visible)
  }

  /**
   * Initializes profile's part of the logged-in user menu.
   */
  private initProfileMenu(): void {
    const canHavePriceItems = hasFeatures(this.currentProfile?.profileType, Feature.PRICE_ITEMS)
    const beOrdered = hasFeatures(this.currentProfile?.profileType, Feature.BE_ORDERED)
    const canOrder = hasFeatures(this.currentProfile?.profileType, Feature.ORDER_PROFILE)
    // initializes the user menu that appears under the profile avatar.
    const avatar = this.currentProfile?.avatar ? `${this.currentProfile.avatar}/50` : defaultAvatar(this.currentProfile?.profileType)
    this.userMenuItems = [
      {
        label: $localize`Profile`,
        styleClass: 'profile-label',
        items: [
          {
            label: '<div class="p-d-flex p-ai-center">' +
              '      <img src="' + avatar + '" alt="' + this.currentProfile?.displayName + ' avatar" class="p-mr-2 profile-avatar">' +
              '      <span class="p-menuitem-text p-mr-2">' + this.currentProfile?.displayName + '</span>' +
              '    </div>',
            escape: false,
            routerLink: this.navigation.urlProfile(this.currentProfile?.charId)
          }
        ]
      }]

    // My services
    if (canHavePriceItems) {
      this.userMenuItems[0].items.push({
        label: $localize`My Services`,
        icon: 'fa-solid fa-chart-bar',
        routerLink: this.navigation.urlProfileServicesEditor()
      })
    }

    // Bookings
    if (canOrder || beOrdered) {
      this.userMenuItems[0].items.push({
          label: $localize`Bookings`,
          icon: 'fa-solid fa-calendar-days',
          routerLink: this.navigation.urlProfileBookings(this.currentProfile.charId, OrderManagerListTypeEnum.PENDING)
        },
        {
          label: $localize`Profiles`,
          icon: 'fa-solid fa-users',
          routerLink: this.navigation.urlUserProfilesDashboard()
        })
    }

    // Chat inbox
    if (this.hasActiveOrders) {
      this.userMenuItems[0].items.push({
        label: $localize`Chat`,
        icon: 'fa-solid fa-comments',
        command: () => {
          this.chatChannelService.chatInboxOpen.next(true)
        }
      })
    }

    // Switch profile
    if (this.profileService.briefProfilesNotEvents.getValue()?.length > 1) {
      this.userMenuItems[0].items.push({
        label: $localize`Switch`, id: 'switch',
        icon: 'fa-solid fa-shuffle',
        command: () => {
          this.switchProfileComponentVisible = true
          this.changeRef.detectChanges()
        }
      })
    }

    // User settings
    this.userMenuItems.push({
      label: $localize`User`,
      items: [
        {
          label: $localize`Notifications`,
          icon: 'fa-solid fa-bell',
          routerLink: this.navigation.urlNotifications()
        },
        {
          label: $localize`Support`,
          icon: 'fa-regular fa-circle-question',
          routerLink: this.navigation.urlSupport()
        },
        {
          label: $localize`Settings`,
          icon: 'fa-solid fa-gear',
          routerLink: this.navigation.urlUserSettings()
        },
        {
          label: $localize`Cookies settings`,
          icon: 'fa-solid fa-cookie-bite',
          command: () => this.cookiesSettingsService.editCookiesSettings.next(true)
        },
        {
          label: $localize`Log Out`,
          icon: 'fa-solid fa-right-from-bracket',
          command: (() => {
            this.userService.createLogoutRequest()
          })
        }
      ]
    })
    this.initUserMenu()
  }

  /**
   * Initializes user's part of the logged-in user menu.
   */
  private initUserMenu(): void {
    // Admin tools
    if (hasRole(this.userService.user.getValue(), Role.ADMIN)) {
      this.userMenuItems.push({
        label: $localize`Admin`,
        items: [
          {
            label: $localize`Dashboard`,
            icon: 'fa-solid fa-square-poll-horizontal',
            routerLink: this.navigation.urlAdminDashboard()
          }
        ]
      })
    }
  }

  /**
   * Initializes items which in hamburger menu {@link Screen.SM} screens, that are visible for all users.
   */
  private initToggleMenuItems(): void {
    // initializes the icons' menu on small screens
    this.iconMenuItems = [
      {
        label: $localize`Search`,
        icon: 'fa-solid fa-magnifying-glass',
        command: () => {
          this.searchProfileDialogVisible = true
          this.changeRef.detectChanges()
        }
      },
      {
        label: $localize`Artists`,
        icon: 'fa-solid fa-masks-theater',
        routerLink: this.navigation.urlProfileCatalog()
      }
    ]

    if (this.currentProfile == null || hasFeatures(this.currentProfile?.profileType, Feature.ORDER_PROFILE)) {
      this.iconMenuItems.push({
        label: $localize`Ordered artists`,
        icon: 'fa-solid fa-basket-shopping basket-red',
        command: () => {
          this.showOrderedProfilesDialog()
        }
      })
    }
  }

  /**
   * Observes the {@link NavbarService} transparency changes.
   * When a true value comes in, the navbar transparency should be change to transparent and vice versa.
   */
  private subscribeTransparencyChange(): void {
    this.navbarService.transparency.subscribe((value: boolean) => {
      this.navbarTransparent = value
    })
  }

  /**
   * Observes the {@link NavbarService} transparency changes.
   * When a true value comes in, the navbar transparency should be change to transparent and vice versa.
   */
  private subscribeVisibilityChange(): void {
    this.navbarService.visibility.subscribe((value: boolean) => {
      this.updateVisibility(value)
    })
  }
}
