<div *ngIf="visibility && likeResp && !loading"
     [class]="styleClass"
     [class.followed]="likeResp.liked">

  <!--Large button-->
  <app-button type="secondary"
              icon="fa-solid fa-user-group"
              *ngIf="buttonType === 'button'"
              [label]="likeResp?.liked ? trans.followed : trans.follow"
              (clicked)="onLikeClicked()"></app-button>

  <!--Heart icon-->
  <div *ngIf="buttonType === 'heart'"
       class="p-d-flex p-ai-center p-ml-3 p-mr-2 c-pointer heart" (click)="onLikeClicked()">
    <i class="fa-heart" [class]="likeResp.liked ? 'fa-solid' : 'fa-regular'"></i>
    <div class="p-ml-2" *ngIf="displayCount">{{likeResp.count > 0 ? (likeResp.count | count) : ''}}</div>
  </div>
</div>

<!--Skeleton-->
<ng-template [ngIf]="loading">
  <div class="p-px-2">
    <p-skeleton *ngIf="buttonType === 'button'"
                width="10rem"
                height="3rem"
                borderRadius="2rem"></p-skeleton>

    <p-skeleton *ngIf="buttonType === 'heart'"
                width="1.5rem"
                height="1.5rem"
                borderRadius="8px"></p-skeleton>
  </div>
</ng-template>
