<div class="wrapper rounded-medium p-p-3 p-my-2">
  <h2 class="p-mt-0 p-mb-2" i18n>Profile completion</h2>

  <div class="p-grid">
    <div class="p-col p-p-0">
      <p-progressBar [value]="percentage"
                     [showValue]="false"></p-progressBar>
    </div>
    <div class="p-col-fixed percentage">
      {{ percentage }}%
    </div>
  </div>

  <div *ngIf="someRequiredItemsMissing" @grow class="warning-box p-my-2 c-pointer" (click)="showEditComponent()">
    <span
      i18n><b>You are not able to receive new orders right now</b>. You have to fill up the required information.</span>
  </div>

  <ng-template [ngIf]="!someRequiredItemsMissing">
    <div *ngIf="percentage > 0 && percentage <= 30" class="warning-box p-my-2 c-pointer"
         (click)="showEditComponent()" i18n>
      This profile is very non-attractive. Make it more catchy by filling in some additional information.
    </div>
    <div *ngIf="percentage > 30 && percentage <= 60" class="warning-box p-my-2 c-pointer"
         (click)="showEditComponent()" i18n>
      This profile is less attractive. To get more orders, you have to consider providing more information.
    </div>
    <div *ngIf="percentage > 60 && percentage < 100" class="warning-box p-my-2 c-pointer"
         (click)="showEditComponent()" i18n>
      You are on a good way to the profile attractiveness. However, you can still increase your chances of getting
      orders by filling up some other information.
    </div>

    <div *ngIf="percentage === 100 && !initiallyCompleted" class="success-box p-my-2" i18n>
      Congratulations! You have successfully filled up all the information! Umevia will now do everything to get you as
      many orders as possible.
    </div>
  </ng-template>

  <!--Complete button-->
  <div *ngIf="data"
       class="p-d-flex p-jc-start p-mt-3 p-mb-2">
    <app-button type="primary"
                icon="fa-solid fa-list-check"
                label="Complete"
                i18n-label
                pTooltip="Complete your profile."
                i18n-pTooltip
                tooltipPosition="top"
                [highlight]="true"
                (clicked)="showEditComponent()"
                [hidden]="percentage === 100"></app-button>
  </div>
</div>

<!-- Edit Component -->
<app-dialog *ngIf="editComponentVisible"
            #dialog
            [component]="this"
            [(show)]="editComponentVisible"
            url="completion"
            type="info"
            [allowSaveOption]="false"
            header="Complete your profile"
            i18n-header>

  <div class="p-grid">
    <div class="p-col p-p-0">
      <p-progressBar [value]="percentage"
                     [showValue]="false"></p-progressBar>
    </div>
    <div class="p-col-fixed percentage">
      {{ percentage }}%
    </div>
  </div>

  <!-- Incomplete items -->
  <ng-template [ngIf]="uncompletedItems.length > 0">
    <h2 class="p-mb-2" i18n>Remains to be filled</h2>
    <ng-container *ngFor="let it of uncompletedItems"
                  [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{$implicit: it}"></ng-container>
  </ng-template>

  <!-- Complete items -->
  <ng-template [ngIf]="completedItems.length > 0">
    <h2 class="p-mb-2" i18n>Completed</h2>
    <ng-container *ngFor="let it of completedItems"
                  [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{$implicit: it}"></ng-container>
  </ng-template>
</app-dialog>

<!--Item data-->
<ng-template #item let-it>
  <div class="p-d-flex p-jc-between p-py-2 item c-pointer p-mb-2 rounded-medium p-py-1 p-px-3"
       (click)="executeOnClick(it)">
    <!--Info-->
    <div class="p-pr-2">
      <div class="p-d-flex">
        <i [class]="it.completed ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'"
           class="p-mr-3 check-icon p-mt-2"></i>
        <div class="p-d-flex p-flex-column c-pointer">
          <div class="p-d-flex p-ai-center">
            <h3 class="p-m-0">{{ it.name }}</h3>
            <app-badge-required *ngIf="it.required" styleClass="p-ml-2"></app-badge-required>
          </div>
          <p class="p-m-0">{{ it.description }}</p>
        </div>
      </div>
    </div>
    <!--Edit button-->
    <div class="p-ai-center p-p-2 p-d-none p-d-sm-flex">
      <app-button type="icon"
                  icon="fa-solid fa-chevron-right"></app-button>
    </div>
  </div>
</ng-template>
