import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {MenuItem} from 'primeng/api'
import {ActivatedRoute} from '@angular/router'
import {Location} from '@angular/common'
import {firstValueFrom, Subscription} from 'rxjs'
import {NavigationService} from '../../../service/ui/navigation.service'
import {UserResp, UserService} from '../../../service/user.service'
import {environment} from '../../../../environments/environment'
import {SettingCategory} from '../../../common/settings-category'
import {EditableComponent} from '../../../component/abstract/editable.component'

@Component({
  selector: 'app-admin-dashboard-profiles',
  templateUrl: './admin-dashboard-profiles.component.html',
  styleUrl: './admin-dashboard-profiles.component.scss'
})
export class AdminDashboardProfilesComponent extends EditableComponent implements OnInit, OnDestroy {
  /**
   * Controls the visibility of the sidebar on small screens.
   */
  settingsSidebarVisible = false

  /**
   * All available settings.
   */
  menuItems: MenuItem[] = []
  /**
   * The current selected setting item.
   */
  selectedMenuItem: MenuItem

  /**
   * Currently logged user.
   */
  user: UserResp
  /**
   * Determines, whether the current environment is production.
   */
  isProduction = environment.production

  private subs: Subscription[] = []

  MC: typeof AdminProfilesMenuCategory = AdminProfilesMenuCategory

  constructor(
    public changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService) {
    super()
  }

  ngOnInit(): void {
    this.initMenuItems()

    this.subs.push(
      // URL Changes
      this.route.params.subscribe(async () => {
        await this.parseURL()
        this.changeRef.detectChanges()
      }),

      //user
      this.userService.user.subscribe(user => {
        this.user = user
      })
    )
  }

  /**
   * Fires when the user clicked on the {@link item} item.
   */
  onSelectedMenuItem(item: MenuItem): void {
    this.menuItems.forEach(it => {
      it['selected'] = undefined
    })
    item['selected'] = true

    // change URL
    this.location.go(`${NavigationService.ADMIN}/${NavigationService.ADMIN_DASHBOARD_PROFILES}/${item.id}`)
    this.selectedMenuItem = item
    this.settingsSidebarVisible = false
  }

  /**
   * Parses the url of the setting.
   */
  private async parseURL(): Promise<void> {
    const params = await firstValueFrom(this.route.params)
    const category = params?.[NavigationService.ADMIN_DASHBOARD_PROFILES_CATEGORY] || AdminProfilesMenuCategory.OVERVIEW
    this.selectCategory(category)
  }

  /**
   * Selects an appropriate item based on the {@link SettingCategory}.
   */
  private selectCategory(setting: SettingCategory): void {
    for (const it of this.menuItems) {
      if (it.id === setting) {
        this.onSelectedMenuItem(it)
        return
      }
    }
  }

  /**
   * Initializes the {@link menuItems}.
   */
  private initMenuItems(): void {
    this.menuItems = [
      {id: AdminProfilesMenuCategory.OVERVIEW, title: $localize`Overview`, icon: 'fa-solid fa-chart-pie'},
      {id: AdminProfilesMenuCategory.LIST, title: $localize`List`, icon: 'fa-solid fa-bars'}
    ]
    this.onSelectedMenuItem(this.menuItems[0])
  }

  ngOnDestroy(): void {
    this.subs.forEach(it => it?.unsubscribe())
  }
}

export enum AdminProfilesMenuCategory {
  OVERVIEW = 'overview',
  LIST = 'list',
}
