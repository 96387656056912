import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core'
import {ValidComponent} from '../../../abstract/valid.component'
import {ProfileResp} from '../../../../service/profile.service'
import {Subscription} from 'rxjs'
import {GuestsNumber, OrderDetails} from '../order-description/order-description.component'
import {PriceItemAdditionResp, PriceItemResp} from '../../../../service/price-item.service'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {LatLngExpression} from 'leaflet'
import {LeafletService} from '../../../../service/ui/leaflet.service'
import {growAnimation} from '../../../../animation/grow.animation'
import {AddressReq, PostalReq} from '../../../../service/address.service'
import {environment} from '../../../../../environments/environment'
import {OrderStepType} from '../profile-order-dialog/profile-order-dialog.component'

@Component({
  animations: [growAnimation()],
  selector: 'app-order-sum',
  templateUrl: './order-sum.component.html',
  styleUrls: ['./order-sum.component.scss']
})
export class OrderSumComponent extends ValidComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  data: ProfileResp
  /**
   * All selected price items of the profile.
   */
  @Input()
  selectedPriceItems: PriceItemResp[]
  /**
   * All selected additional price items of the profile.
   */
  @Input()
  selectedPriceAdditions: PriceItemAdditionResp[]
  /**
   * All additional price items of the profile.
   */
  @Input()
  allPriceAdditions: PriceItemAdditionResp[]
  /**
   * - The sum of prices of {@link selectedPriceAdditions} and {@link selectedPriceItems}.
   * - This price does not include the additional price or platform fee. This amount receives the ordered profile.
   */
  @Input()
  orderTotal = 0
  /**
   * The platform fee in total.
   */
  feesTotal = 0
  /**
   * Selected hours of performance from book form.
   */
  @Input()
  hours: number
  /**
   * - Start and End date.
   * - [StartDatetime, EndDatetime].
   */
  @Input()
  dates: Date[]
  /**
   * Specifies steps in the ordering dialog.
   */
  @Output()
  stepsChanged = new EventEmitter<OrderStepType>()
  /**
   * Emits the clicked to update segment of address - if the address type is invoicing or event.
   */
  @Output()
  isSegmentInvoicing = new EventEmitter<boolean>()
  /**
   * The order detail information.
   */
  detail: OrderDetails
  /**
   * The invoicing address information of author.
   */
  authorAddress: PostalReq
  /**
   * The address information about ordered location.
   */
  orderAddress: AddressReq
  /**
   * The latitude and longitude of the {@link orderAddress}.
   */
  coords: LatLngExpression
  /**
   * The current form value changes subscription.
   */
  private formSub: Subscription
  /**
   * In some cases, the map needs to be loaded a little bit late.
   */
  lazyLoadMap = false
  /**
   * The list of benefits displayed under the summary.
   */
  benefits: string[] = [
    $localize`Money Back Guarantee`,
    $localize`24/7 support`,
    $localize`Money stays with us until the event is over`,
    $localize`No risk of purchase of services`,
    $localize`Reminders for the upcoming event`
  ]
  /**
   * The fixed fee amount per an order.
   */
  readonly fixedFee = environment.platformFee.platformOrder.feeFixed

  Guests: typeof GuestsNumber = GuestsNumber
  Step: typeof OrderStepType = OrderStepType

  constructor(
    private storageService: StorageService,
    private leaflet: LeafletService) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.detail = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_INFO)) as OrderDetails
    this.authorAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)) as PostalReq
    this.orderAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_ADDRESS)) as AddressReq

    if (this.leaflet.isReady()) {
      this.coords = this.leaflet.latLng(this.orderAddress.lat, this.orderAddress.lng)
    }

    this.setValid(true)

    // Lazy load map
    setTimeout(() => {
      this.lazyLoadMap = true
    }, 10)
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update
    if (changes.selectedPriceItems || changes.selectedPriceAdditions || changes.hours) {
      this.initFeesTotal()
    }
  }

  /**
   * Initializes the {@link feesTotal} property.
   */
  private initFeesTotal(): void {
    let realPrices = 0
    this.selectedPriceItems.forEach(it => {
      realPrices += (it.realPrice * ((it.fixedPrice) ? 1 : this.hours))
    })
    this.selectedPriceAdditions.forEach(it => realPrices += it.realPrice)
    this.feesTotal = this.orderTotal - realPrices + this.fixedFee
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe()
  }
}
