import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {addStyle, isArrayNullOrEmpty} from '../../../../utils/utils'
import {openProfile} from '../../../../directive/redirect-profile.directive'
import {
  BriefProfileResp,
  NotificationTargetProfile,
  OrderableProfileResp,
  ProfileResp
} from '../../../../service/profile.service'
import {CalendarItemNestedProfileResp} from 'src/app/service/calendar.service'
import {NgForOf, NgIf, SlicePipe} from '@angular/common'
import {SkeletonModule} from 'primeng/skeleton'
import {TooltipModule} from 'primeng/tooltip'
import {ProfileBorderDirective} from '../../../../directive/profile-border.directive'
import {AvatarPipe} from '../../../../pipe/avatar.pipe'
import {InitDirective} from '../../../../directive/init.directive'

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  imports: [
    NgIf,
    SkeletonModule,
    TooltipModule,
    ProfileBorderDirective,
    AvatarPipe,
    SlicePipe,
    InitDirective,
    NgForOf
  ],
  standalone: true
})
export class AvatarComponent implements OnInit, OnChanges {

  /**
   * Profile of any type
   */
  @Input()
  profile: BriefProfileResp | ProfileResp | CalendarItemNestedProfileResp | NotificationTargetProfile | OrderableProfileResp

  /**
   * Many profiles of any types
   */
  @Input()
  profiles: any[]

  /**
   * Displays the profile based border color.
   */
  @Input()
  typeBorder: boolean

  /**
   * How many images in group will be visible.
   */
  @Input()
  groupMax = 3

  /**
   * Avatar(s) size(s).
   * Default value is 50px.
   * Cannot be
   */
  @Input()
  sizePx = 50

  /**
   * Avatar's image file name.
   * Default value is 50.
   * This is gets updated everytime the {@link sizePx} changes.
   */
  fileName = '50'

  /**
   * Avatar image(s) will have a box shadow effect.
   */
  @Input()
  shadow: boolean

  /**
   * Shows a tooltip with a profile display name while hovering.
   * It is set to true by default.
   */
  @Input()
  showTooltip = true

  @Input()
  others: any

  @Input()
  othersColor = '#383838'

  @Input()
  borderColor: string

  /**
   * When a user clicks on the avatar, it will redirect him to the desired profile.
   * This property controls the redirection.
   */
  @Input()
  redirect = true

  /**
   * Represents styles for the {@link profile} input.
   */
  singleAvatarStyles: string

  moveFactor = 0.75

  constructor() {
  }

  ngOnInit(): void {
    if (!this.typeBorder && this.borderColor) {
      addStyle(this.singleAvatarStyles, 'border-color', this.borderColor)
      addStyle(this.singleAvatarStyles, 'border-style', 'solid')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sizePx?.currentValue) {
      if (this.sizePx <= 50) {
        this.fileName = '50'
      } else if (this.sizePx > 50 && this.sizePx <= 130) {
        this.fileName = '130'
      } else if (this.sizePx > 130 && this.sizePx <= 300) {
        this.fileName = '300'
      } else if (this.sizePx > 300) {
        throw Error(`The avatar size cannot be more than 300px`)
      }
    }
  }

  /**
   * Sets the img element style.
   *
   * @param e Image element
   * @param index If it should be displayed as group of avatars. (Index from *ngFor)
   */
  setElementStyle(e, index?: number): void {
    const img = e?.path?.[0]
    if (!img) {
      return
    }
    img.style.width = this.sizePx + 'px'
    img.style.height = this.sizePx + 'px'

    if (!this.typeBorder && this.borderColor) {
      img.style.borderColor = this.borderColor
      img.style.borderStyle = 'solid'
    }

    if (index != null || undefined) {
      img.style.zIndex = (2 + this.profiles.length - index)
      img.style.right = '-' + (this.sizePx * this.moveFactor * index) + 'px'
      img.style.left = (this.sizePx * this.moveFactor * index) + 'px'
    }
  }

  /**
   * Calculates the group div width.
   */
  calcWidth(): number {
    if (!isArrayNullOrEmpty(this.profiles)) {
      const length = this.profiles.length
      let result = 0
      if (length < this.groupMax) {
        for (let i = 1; i <= length; i++) {
          result += ((this.sizePx - (this.sizePx * this.moveFactor)) * i)
        }
      } else {
        for (let i = 1; i <= length; i++) {
          result += ((this.sizePx - (this.sizePx * this.moveFactor)) * i)
        }
      }
      return result
    }
    return 0
  }

  /**
   * Fires when a user clicked on the avatar image.
   * If the {@link redirect} is enabled, it will redirect the user to the profile based on the charId.
   */
  onRedirect(charId?: string): void {
    if (this.redirect && charId) {
      openProfile(charId)
    }
  }
}
