import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {firstValueFrom, Observable} from 'rxjs'
import {BriefProfileResp, ProfileService, SearchProfilesReq} from '../../../../service/profile.service'
import {ApiComponent} from '../../../abstract/api.component'
import {newEmptyPage, Page} from 'src/app/rest/page-resp'
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel'
import {trimMultipleSpacesAndLines} from '../../../../utils/string.utils'
import {HtmlService} from '../../../../service/ui/html.service'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {ChangeDirective} from '../../../../directive/change.directive'
import {NgForOf, NgIf} from '@angular/common'
import {DataViewModule} from 'primeng/dataview'
import {AvatarComponent} from '../../avatar/avatar/avatar.component'
import {VarDirective} from '../../../../directive/var.directive'
import {CountryPipe} from '../../../../pipe/country.pipe'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.scss'],
  imports: [
    ChangeDirective,
    NgIf,
    OverlayPanelModule,
    DataViewModule,
    AvatarComponent,
    VarDirective,
    CountryPipe,
    NgForOf
  ],
  standalone: true
})
export class HtmlInputComponent extends ApiComponent implements AfterViewInit, OnChanges {

  /**
   * Controls the {@link inputDiv}' content-editable attribute.
   */
  @Input()
  disabled = false

  /**
   * Used for styling this component.
   */
  @Input()
  styleClass: string

  /**
   * The placeholder of the {@link inputDiv} appears when the element's value is not empty.
   */
  @Input()
  placeholder: string

  /**
   * Restrict the user input length.
   */
  @Input()
  length: { min?: number; max?: number }

  /**
   * Sets this field as required.
   */
  @Input()
  required: boolean

  /**
   * Emits the invalid state of the input.
   */
  @Output()
  isInvalid = new EventEmitter<boolean>()

  /**
   * An array of messages from the {@link validate} function.
   */
  invalidMessages: string[] = []

  /**
   * The content-editable div, where a user can type his text.
   */
  @ViewChild('input')
  inputDiv: ElementRef<HTMLDivElement>

  /**
   * The overlay panel menu that search for profiles to mention.
   */
  @ViewChild('overlayPanel')
  overlayPanel: OverlayPanel

  /**
   * Represents the current searching value in the {@link overlayPanel}.
   */
  searchValue = ''

  /**
   * Fires when a user dispatch the keyup enter event.
   */
  enter = new EventEmitter<string>()

  /**
   * Search result instantiated by the {@link searchTask}.
   */
  profiles: Page<BriefProfileResp> = newEmptyPage()

  /**
   * The timeout task that performs the API search.
   */
  private searchTask: any

  /**
   * The native element of the {@link inputDiv}.
   *
   * @private
   */
  private inputElement: HTMLDivElement

  constructor(
    private profileService: ProfileService,
    private htmlService: HtmlService) {
    super()
  }

  ngAfterViewInit(): void {
    this.inputElement = this.inputDiv.nativeElement
    this.applyDisabledProperty()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.applyDisabledProperty()
    }
  }

  /**
   * Fires when a user pressed the 'Enter' button without the 'Shift' or 'Ctrl'.
   */
  onEnter(event: KeyboardEvent): void {
    if (!event.ctrlKey && !event.shiftKey) {
      this.enter.emit(this.inputDiv.nativeElement.innerText)
    }
  }

  /**
   * Fires when a user dispatch the 'keydown' event.
   */
  onInput(event: KeyboardEvent): void {
    if (event.key === '@') {
      this.setOverlayPanelVisible(true, event)
    } else if (event.code === 'Escape' || (event.code === 'Backspace' && this.searchValue.length === 0)) {
      this.setOverlayPanelVisible(false)
    }

    if (this.overlayPanel.overlayVisible) {
      if (event.key.length === 1 && event.key !== '@') {
        this.searchValue = this.searchValue + event.key
        this.startSearchTask(this.searchValue)
      }

      if (event.code === 'Backspace') {
        if (this.searchValue.length > 0) {
          this.searchValue = this.searchValue.slice(0, -1)
          this.startSearchTask(this.searchValue)
        } else {
          this.setOverlayPanelVisible(false)
        }
      }

      // destroy the event
      event.preventDefault()
    }
  }

  /**
   * Fires when a user want to un-focus the {@link inputDiv}.
   */
  onBlur(): void {
    // it keeps the input element focused at the specific caret position.
    if (this.overlayPanel.overlayVisible) {
      this.inputElement.focus()
    }
  }

  /**
   * Controls the visibility of the {@link overlayPanel}.
   * It also initializes the {@link searchValue} to ''.
   * Furthermore, it stops the currently running {@link searchTask}.
   *
   * @param visible The state that will be applied.
   * @param openEvent This event needs to be provided when the {@link overlayPanel} is going to be opened.
   */
  setOverlayPanelVisible(visible: boolean, openEvent?: KeyboardEvent): void {
    this.stopSearchTask()
    this.searchValue = ''
    if (visible) {
      this.overlayPanel.show(openEvent)
    } else {
      this.overlayPanel.hide()
    }
  }

  /**
   * Fires when a user selected a profile in the mention search menu.
   */
  selectProfile(profile: BriefProfileResp): void {
    this.setOverlayPanelVisible(false)
    this.inputElement.focus()
    const html = this.htmlService.createMentionHtml(profile)
    this.htmlService.pasteHtmlAtCaret(html)
  }

  /**
   * Starts a new search task
   *
   * @param input The search value.
   */
  private startSearchTask(input: string): void {
    const text = trimMultipleSpacesAndLines(input)
    // remove the previous set timeout
    this.stopSearchTask()

    // add a new one
    this.searchTask = setTimeout(async () => {
      if (text.length >= 3) {
        this.loading = true
        this.profiles = await firstValueFrom(this.callSearchProfiles(text))
        this.loading = false
      }
    }, 750)
  }

  /**
   * Stops the currently running search task.
   */
  private stopSearchTask(): void {
    if (this.searchTask) {
      clearTimeout(this.searchTask)
    }
  }

  /**
   * Calls the API to search through registered profiles by the {@link inputStr}.
   */
  private callSearchProfiles(inputStr: string): Observable<Page<BriefProfileResp>> {
    const req: SearchProfilesReq = {
      input: inputStr,
      types: [],
      page: 0
    }
    return this.unwrap(this.profileService.callSearchBriefProfiles(req))
  }

  /**
   * Returns the text value of the element that is properly converted from HTML.
   *
   * @param sanitize Properly clears HTML tags to the text value.
   */
  getTextValue(sanitize: boolean = true): string {
    if (this.inputElement) {
      if (sanitize) {
        const element = this.inputElement.cloneNode(true) as HTMLDivElement
        this.htmlService.convertMentionsHtmlToId(element)

        const text = element.innerText
        element.remove()
        return text
      } else {
        return this.inputElement.innerText
      }
    }
  }

  /**
   * Sets the value to the {@link inputElement}.
   * Also, it runs the {@link validate} function.
   *
   * @param html
   */
  setValue(html: string): void {
    this.inputElement.innerHTML = html
    this.validate()
  }

  /**
   * - Validates the user input.
   * - It emits the {@link isInvalid} if some {@link invalidMessages} was set.
   * - It uses the {@link required} and {@link length} fields.
   */
  validate(): void {
    const messages: string[] = []
    const text = this.getTextValue().trim()
    const textLen = text.length

    // Length
    if (this.length) {
      const min = this.length.min
      const max = this.length.max

      if (min && max && (textLen < min || textLen > max)) {
        messages.push($localize`Needs to be ${min} - ${max} characters long.`)
      } else if (min === undefined && max && textLen > max) {
        messages.push($localize`Exceeds ${max} characters.`)
      } else if (max === undefined && min && textLen < min) {
        messages.push($localize`Must be at least ${min} characters long.`)
      }
    }

    // Required
    if (this.required && !textLen) {
      messages.push($localize`Required field.`)
    }

    this.isInvalid.emit(messages.length > 0)
    this.invalidMessages = messages
  }

  /**
   * Sets the 'contentEditable' property to true if the {@link disabled} is false and vice versa.
   */
  private applyDisabledProperty(): void {
    if (this.inputElement) {
      this.inputElement.contentEditable = `${!this.disabled}`
    }
  }
}
