import {Pipe, PipeTransform} from '@angular/core'
import {countryDetailsOfCode, CountryInfo} from '../common/country'

/**
 * - Returns {@link CountryInfo} of given country code.
 */
@Pipe({
  name: 'country',
  standalone: true
})
export class CountryPipe implements PipeTransform {
  transform(countryCode: string): CountryInfo {
    return countryDetailsOfCode(countryCode)
  }
}
