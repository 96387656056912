import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ProfileResp, ProfileService} from '../../../../service/profile.service'
import {EditableComponent} from '../../../abstract/editable.component'
import {ProfileStatus} from '../../../../common/profile-status'
import {NavigationService} from '../../../../service/ui/navigation.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {UserService} from '../../../../service/user.service'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {growAnimation} from '../../../../animation/grow.animation'
import {OrderManagerListTypeEnum} from '../../../../modules/order-manager/order-manager-list-type.enum'

@Component({
  animations: [growAnimation()],
  selector: 'app-profile-change-status',
  templateUrl: './profile-change-status.component.html',
  styleUrls: ['./profile-change-status.component.scss']
})
export class ProfileChangeStatusComponent extends EditableComponent implements OnInit, OnDestroy {

  /**
   * The data to be displayed.
   */
  @Input()
  data: ProfileResp
  /**
   * Represents whether the user wants to delete, deactivate or activate his profile.
   */
  @Input()
  clickedType: ProfileStatus
  /**
   * Represents whether the {@link data} has active orders.
   */
  @Input()
  hasActiveOrders: boolean
  /**
   * A form with fields of password and code confirmation.
   */
  profileStatusForm: FormGroup
  /**
   * Decides whether user's password is correct or not.
   */
  passCheckedSuccessfully: boolean

  private formSub?: Subscription

  @ViewChild('changeProfileStatusDialog')
  changeProfileStatusDialog: DialogComponent
  ProfileStatus: typeof ProfileStatus = ProfileStatus

  OrderManagerListTypeEnum: typeof OrderManagerListTypeEnum = OrderManagerListTypeEnum

  protected trans = {
    intro: $localize`Your account is now successfully `,
    activated: $localize`activated`,
    deactivated: $localize`deactivated`,
    deleted: $localize`deleted`,
    delete: $localize`Delete`,
    deactivate: $localize`Deactivate`,
  }

  constructor(
    public userService: UserService,
    public profileService: ProfileService,
    public navigation: NavigationService,
    private formBuilder: FormBuilder
  ) {
    super()
  }

  ngOnInit(): void {
    this.initSetProfileStatusForm()
  }

  /**
   * Initializes the {@link profileStatusForm}.
   */
  private initSetProfileStatusForm(): void {
    this.profileStatusForm = this.formBuilder.group({
      password: ['']
    })

    this.formSub = this.profileStatusForm.controls.password.valueChanges.subscribe(() => {
      this.resetApi() //clean server messages
    })
  }

  /**
   * Fires when a user clicked submit check button to verify whether the password is correct,
   * if so a field does the action by {@link clickedTypeDecision} type.
   */
  onSubmitPassword(): void {
    this.call(async () => {
      const passResp = await firstValueFrom(this.callCheckPassword())

      this.passCheckedSuccessfully = passResp && this.noServerMessages()
      if (this.passCheckedSuccessfully) {
        this.profileStatusForm.get('password').disable()
        if (this.clickedType === ProfileStatus.ACTIVE) {
          this.updateStatus(this.clickedType) //ACTIVE
        } else {
          this.changeProfileStatusDialog.scrollBottom(500)
        }
      }
    })
  }

  /**
   * Updates the new status of the profile by {@link clickedType} param and then log out the user.
   */
  updateStatus(clickedType: ProfileStatus): void {
    this.callAndFinish(async () => {
      const statusResp = await firstValueFrom(this.callUpdateStatus(clickedType))

      // do not call logout after activating profile
      if (clickedType === ProfileStatus.ACTIVE) {
        return
      }

      // call in timeout, so the redirection will be done, after dialog finished its changeUrl() method
      setTimeout(() => {
        if (statusResp && this.noServerMessages()) {
          // if user is deleting account, he is already logged out from backend
          this.userService.createLogoutRequest(
            false,
            this.navigation.urlPathLogin(false),
            false,
            false,
            1000,
            false
          )
        }
      }, DialogComponent.DIALOG_STATUS_DURATION)
    })
  }

  /**
   * Constructs a request from the {@link data} and {@link clickedTypeDecision}, then calls the API.
   */
  private callUpdateStatus(clickedTypeDecision: ProfileStatus): Observable<boolean> {
    return this.unwrap(this.profileService.callUpdateProfileStatus({
      profileId: this.data.profileId,
      selectedStatus: clickedTypeDecision
    }))
  }

  /**
   * Constructs a request from the given {@link profileStatusForm} and calls the API.
   */
  private callCheckPassword(): Observable<boolean> {
    return this.unwrap(this.userService.callCheckPassword({
      password: this.profileStatusForm.value.password?.trim(),
    }))
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe()
  }
}
