import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {CookiesSettingsService} from '../../../service/cookies-settings.service'
import {PLATFORM_BROWSER} from '../../../app.module'
import {firstValueFrom, Subscription} from 'rxjs'
import {ApiComponent} from '../../abstract/api.component'
import {environment} from '../../../../environments/environment'

@Component({
  selector: 'app-dialog-cookies',
  templateUrl: './dialog-cookies.component.html',
  styleUrls: ['./dialog-cookies.component.scss']
})
export class DialogCookiesComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * Controls the visibility of the cookies dialog
   */
  display: boolean

  /**
   * Shows the detailed dialog with cookies.
   */
  detailedCookiesDialogVisible: boolean

  /**
   * Holds value of the newest version of cookies documents.
   */
  cookiesVersion: number

  /**
   * Subscribes to {@link CookiesSettingsService.editCookiesSettings}.
   */
  detailDialogVisibilitySub?: Subscription

  constructor(
    private cookiesSettingsService: CookiesSettingsService,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  /**
   * Shows dialog for cookies if necessary.
   */
  ngOnInit(): void {
    if (PLATFORM_BROWSER) {
      this.listenShowCookiesDetail()
      this.call(async () => {
        this.cookiesVersion = (await firstValueFrom(this.unwrap(this.cookiesSettingsService.callGetCookiesVersion())))?.version || -1
        this.display = environment.name !== 'e2e'
          && (this.cookiesSettingsService.getVersion() < this.cookiesVersion || !this.cookiesSettingsService.cookiesOnceAccepted())

        // clear so it will not be shown with some as true by default
        if (this.display) {
          this.cookiesSettingsService.clearAll()
        }
      })
    }
  }

  /**
   * Accepts cookies
   */
  acceptAllCookies(): void {
    this.cookiesSettingsService.acceptAll(this.cookiesVersion)
    this.display = false
  }

  /**
   * Shows more detailed dialog.
   */
  showDetails(): void {
    this.detailedCookiesDialogVisible = true
    this.display = false
    this.changeRef.detectChanges()
  }

  /**
   * Listen to changes on cookie settings service and show detailed dialog based on received value.
   */
  listenShowCookiesDetail(): void {
    this.detailDialogVisibilitySub = this.cookiesSettingsService.editCookiesSettings.subscribe((value) => {
      if (value == null) {
        return
      }
      this.display = false
      this.detailedCookiesDialogVisible = value
      this.changeRef.detectChanges()
    })
  }

  /**
   * Listener to decrease tabCount in localStorage.
   * @deprecated This method is here only for potential future usage. DON'T USE !!!
   */
  //@HostListener('window:beforeunload', ['$event'])
  decreaseTabCount(): void {
    this.cookiesSettingsService.updateTabsCount(false)
  }

  ngOnDestroy(): void {
    this.detailDialogVisibilitySub?.unsubscribe()
  }
}
