import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations'

/**
 * Defines the Fade-In and Fade-Out animation.
 * Use the [@fade]="boolean" directive with the *ngIf simultaneously.
 */
export function fadeAnimation(ms: number = 100): AnimationTriggerMetadata {
  return trigger('fade', [
    state('*', style({opacity: 1})),
    state('void', style({opacity: 0})),
    transition('* <=> void', [animate('' + ms + 'ms ease-in-out')])
  ])
}
