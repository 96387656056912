import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core'
import {Location} from '@angular/common'
import {DialogModule} from 'primeng/dialog'
import {ButtonComponent} from '../button/button.component'

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
  imports: [
    DialogModule,
    ButtonComponent
  ],
  standalone: true
})
export class UnsavedChangesComponent {

  @Input()
  show: boolean

  @Output()
  showChange = new EventEmitter<boolean>()

  /**
   * If a user wants to leave.
   */
  @Output()
  leave = new EventEmitter<any>()

  /**
   * If a user wants to stay.
   */
  @Output()
  stay = new EventEmitter<any>()

  /**
   * The base z-index of the dialog.
   */
  @Input()
  baseZIndex = 200000

  /**
   * This component contains some basic logic to prevent the back button.
   * If it is required to replace this behavior, set this property to true.
   */
  @Input()
  customBehavior = false

  constructor(private location: Location) {
  }

  /**
   * - Closes the dialog.
   * - Emits the {@link stay} emitter if the 'stay' parameter is true, otherwise the {@link leave} gets emitted.
   */
  close(e: any, stay: boolean): void {
    if (stay) {
      if (!this.customBehavior) {
        history.pushState(null, null, this.location.path())
      }
      this.stay.emit(e)
    } else {
      this.leave.emit(e)
      if (!this.customBehavior) {
        history.back()
      }
    }
    this.showChange.emit(false)
  }

  /**
   * Fires when the keydown is pressed.
   */
  @HostListener('document:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Escape':
      case 'Esc':
        this.close(event, true)
        break
      case 'Enter':
        this.close(event, false)
        break
    }
  }

  /**
   * Disables the back button in the browser.
   */
  @HostListener('window:popstate', ['$event'])
  private onBackButton(event): void {
    event.preventDefault()
  }
}
