import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {
  CalendarItemNestedProfileResp,
  CalendarItemNestedProfilesReq,
  CalendarItemResp,
  CalendarService,
  FindCalendarItemsBetweenReq
} from '../../../service/calendar.service'
import {ProfileResp} from '../../../service/profile.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {addDays, addHours, addMonths, dateCut, dateEquals, dateJoin, daysInMonth, minusMonths} from '../../../utils/date.utils'
import {isArrayNullOrEmpty} from '../../../utils/utils'
import {FindHostPendingItemsReq, FindHostPendingItemsResp, HostProfileService} from '../../../service/host-profile.service'
import {ProfileCalendarHelper} from './profile-calendar.helper'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ProfileType} from '../../../common/profile-type'
import {growAnimation} from '../../../animation/grow.animation'
import {StorageItem, StorageService} from '../../../service/storage.service'
import {HintComponent} from '../../common/hint/hint.component'
import {DatePipe, NgForOf, NgIf, SlicePipe} from '@angular/common'
import {RippleModule} from 'primeng/ripple'
import {VarDirective} from '../../../directive/var.directive'
import {ButtonComponent} from '../../common/button/button.component'
import {SkeletonModule} from 'primeng/skeleton'
import {DocsHintComponent} from '../../common/docs-hint/docs-hint.component'

/**
 * This calendar component is divided into two classes to keep the business logic and UI logic separated.
 * - This primary component class ({@link ProfileCalendarComponent}) is used
 *      for defining the business logic, logic with inputs, calculations, API calling etc.
 * - The abstract {@link ProfileCalendarHelper} class is mainly focused
 *      for UI processing like constructing cells, and all work associated with them.
 */
@Component({
  animations: [fadeAnimation(200), growAnimation()],
  selector: 'app-profile-calendar',
  templateUrl: './profile-calendar.component.html',
  styleUrls: ['./profile-calendar.component.scss'],
  imports: [
    HintComponent,
    NgForOf,
    SlicePipe,
    RippleModule,
    VarDirective,
    DatePipe,
    ButtonComponent,
    NgIf,
    SkeletonModule,
    DocsHintComponent
  ],
  standalone: true
})
export class ProfileCalendarComponent extends ProfileCalendarHelper implements OnInit, OnChanges, AfterViewInit, OnDestroy {


  @Input()
  data: ProfileResp

  /**
   * Emits this component when it is ready. (After View Init)
   */
  @Output()
  ready = new EventEmitter<ProfileCalendarComponent>()

  /**
   * Defines multiple layouts for specific user category.
   * - <b>Owner</b> - the owner of a calendar, visible all circles including public events.
   * - <b>Booker</b> - a customer who wants to book a profile.
   * - <b>Guest</b> - visitor who wants to get information about public events.
   */
  @Input()
  layout: 'owner' | 'booker' | 'guest'

  /**
   * Enables user clicks on table cells and further processing.
   * If enabled, every click on the calendar cell will emit the result in the {@link selectedCellChange}.
   */
  @Input()
  clickable = true

  /**
   * If enabled, the calendar will fetch details about selected cell automatically.
   */
  @Input()
  fetchDetails: boolean

  /**
   * If {@link fetchDetails} is enabled, this emitter will emit all details of the {@link selectedCell}.
   * This emitter emits null, if the calendar is fetching detailed information about the {@link selectedCell}, and no data is available yet.
   */
  @Output()
  selectedCellDetailChange = new EventEmitter<CalendarCellDetail | null>()

  /**
   * Represents a state of getting the number of pending profile items to accept.
   * Result can be obtained in the {@link pendingHostProfileItemsCountChange}, and {@link pendingHostProfileItemsCount}.
   */
  @Input()
  fetchCountPendingProfiles: boolean

  /**
   * Represents a state of getting the details of pending profile items to accept, and waiting for acceptance by the other side.
   * Result can be obtained in the {@link pendingProfilesChange}.
   */
  @Input()
  fetchPendingProfiles: boolean

  /**
   * The number of pending profile items to accept.
   * Activated when the {@link fetchCountPendingProfiles} is enabled.
   */
  @Output()
  pendingHostProfileItemsCountChange = new EventEmitter<number>()

  /**
   * The details of pending profile items to accept, and waiting for acceptance by the other side.
   * Activated when the {@link fetchPendingProfiles} is enabled.
   */
  @Output()
  pendingProfilesChange = new EventEmitter<FindHostPendingItemsResp>()

  /**
   * Style classes that will be applied on the top of the entire component.
   */
  @Input()
  styleClass: string

  /**
   * Defines the number of orders that is acceptable in a single day.
   */
  maxOrdersPerDay = 50 // TODO

  /**
   * Specifies whether the {@link }
   */
  searchAllCells = true
  /**
   * The availability search start-datetime.
   */
  searchStartTime?: Date
  /**
   * The availability search end-datetime.
   */
  searchEndTime?: Date
  /**
   * Defines whether at least one of the currently visible calendar has six rows
   */
  sixRowsCalendar: boolean

  /**
   * The current date without hours, minutes, and seconds.
   */
  readonly currentYearMonthDay = dateCut(new Date(), 'h')
  /**
   * The current full date without any modification.
   */
  readonly currentDate = new Date()
  /**
   * The threshold, that represents the first datetime from which the orders can be ordered.
   */
  readonly firstAvailableDate = dateCut(addHours(new Date(), 1))
  /**
   * Prevents from multiple searching at once.
   */
  private searchValuesTimeout

  private subs: Subscription[] = []

  constructor(
    private calendarService: CalendarService,
    private changeRef: ChangeDetectorRef,
    private hostProfileService: HostProfileService,
    private storageService: StorageService) {
    super()
  }

  ngOnInit(): void {
    this.subs.push(
      // Jump To Date changes
      this.jumpToDate.subscribe(date => {
        this.onJumpToDate(date)
      }),

      // pendingHostProfileItemsCount value changes
      this.pendingHostProfileItemsCount.subscribe(count => {
        this.pendingHostProfileItemsCountChange.emit(count)
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.monthsVisible?.currentValue) {
      this.initMonthsAndLoadData()
    }

    if (changes.data?.currentValue) {
      super.clearData()

      this.initMonthsAndLoadData()

      // Jump To Date changes
      let rawStartDate = this.currentDate
      const startDateStr = JSON.parse(this.storageService.getItemStorage(StorageItem.SELECTED_CALENDAR_DATA))?.startDate

      if (startDateStr) {
        rawStartDate = new Date(startDateStr)
      }
      if (!dateEquals(rawStartDate, this.currentDate)) {
        this.onJumpToDate(rawStartDate)
      }
    }
  }

  /**
   * Init months to visible and load data.
   */
  private initMonthsAndLoadData(): void {
    this.initMonthsVisible(this.currentYearMonthDay)

    // Load data and select today's date
    this.call(async () => {
      await this.loadData()
      // const c = this.getTodayCell()
      // await this.onSelectCell(c)
    })
  }

  ngAfterViewInit(): void {
    this.ready.emit(this)
  }

  /**
   * Re-renders all cells in the calendar by the {@link searchStartTime}, {@link searchEndTime}, and {@link searchAllCells}.
   * - No action is performed if search values are not specified.
   */
  applySearchValues(): void {
    clearTimeout(this.searchValuesTimeout)
    // will be executed only once when multiple calls occurs
    this.searchValuesTimeout = setTimeout(() => {
      if (this.searchAllCells) {
        if (!this.searchStartTime || !this.searchEndTime || this.data.owner) {
          return
        }
        for (let i = 0; i < this.monthsVisible; i++) {
          for (const cell of this.monthsCellData[i]) {
            const cellDate = cell.date // should be without hours and minutes
            const startTime = dateJoin(cellDate, this.searchStartTime)
            let endTime = dateJoin(cellDate, this.searchEndTime)
            // if the end time is before, make the next day
            if (endTime <= startTime) {
              endTime = dateJoin(addDays(cellDate, 1), this.searchEndTime)
            }

            cell.availability = 'free' // init to default state
            for (const ci of cell.calendarItems) {
              // Whether the calendar item matches the given datetime range
              const matchesTime = ci.start >= startTime && ci.start <= endTime
                || ci.end >= startTime && ci.end <= endTime
                || ci.start <= startTime && ci.end >= endTime

              // check for unavailability
              if (matchesTime && ci.orderActive && !ci.deleted) {
                cell.availability = 'unavailable'
                break
              }

              // check whether some other order is set during this time interval but is not accepted yet
              if (matchesTime && !ci.orderActive && ci.deleted) {
                cell.availability = 'uncommitted'
              }
            }
          }
        }
      }
    }, 10)
  }

  /**
   * - Loads the data for the first time from the server. (Based on the {@link selectedYearMonths} date property.)
   * - Furthermore, it loads all pending profile items that are waiting for an acceptance from both parties.
   */
  private async loadData(): Promise<void> {
    // Pending profile items
    const profileType = this.data.profileType
    if (profileType === ProfileType.ENTERPRISE) {

      // Pending profiles count only
      if (this.fetchCountPendingProfiles && !this.fetchPendingProfiles) {
        const pendingProfilesCount = await firstValueFrom(this.callCountPendingHostProfiles())
        this.pendingHostProfileItemsCountChange.emit(pendingProfilesCount)
        this.pendingHostProfileItemsCount.next(pendingProfilesCount)

        // Fetch pending profiles
      } else if (this.fetchPendingProfiles) {
        const pendingProfilesResp = await firstValueFrom(this.callPendingHostProfileItems())
        const fakeHostItems = this.constructFakeCalendarItemsFromPendingProfiles(pendingProfilesResp.pendingHostItems)
        const fakeItems = this.constructFakeCalendarItemsFromPendingProfiles(pendingProfilesResp.pendingItems)

        for (let i = 0; i < this.monthsVisible; i++) {
          this.monthsCellData[i] = this.applyItems(this.selectedYearMonths[i], [...fakeHostItems, ...fakeItems])
        }

        // Emit the result
        this.pendingProfileItemsResp = pendingProfilesResp
        this.pendingProfilesChange.emit(pendingProfilesResp)
        this.pendingHostProfileItemsCountChange.emit(fakeHostItems.length)
        this.pendingHostProfileItemsCount.next(fakeHostItems.length)
      }
    }
    // Assign data cells to the monthsCellData array
    await this.loadMonthsCellData()
  }

  /**
   * - Fires when a user clicked on the previous or next month button in the calendar navigation.
   * - Updates the {@link selectedYearMonths} array and then loads the proper {@link CalendarItemResp} data for that new date selection.
   * - Updates the {@link monthsCellData} property with new visible data.
   *
   * @param add True, when a user clicked on the next month button, otherwise on the previous month button.
   */
  onMonthChangeRequest(add: boolean): void {
    // adjust the selected year months array
    for (let i = 0; i < this.monthsVisible; i++) {
      if (add) {
        this.selectedYearMonths[i] = addMonths(this.selectedYearMonths[i], 1)
      } else {
        this.selectedYearMonths[i] = minusMonths(this.selectedYearMonths[i], 1)
      }
    }
    // load months cell data
    this.call(async () => {
      await this.loadMonthsCellData()
    })
  }

  /**
   * Fires when the {@link jumpToDate} input value is changed.
   * It displays the data from the year-month of the date param and clicks on the day cell from the date param.
   *
   * @param date A new {@link jumpToDate} value.
   */
  onJumpToDate(date: Date | null): void {
    // if null, unselect the current selected cell
    if (!date) {
      this.selectAndHighlight(null)
      return
    }

    this.call(async () => {
      // check whether the 'date' argument is currently visible
      let inCurrentMonth = false
      for (const monthDate of this.selectedYearMonths) {
        if (dateEquals(monthDate, date, 'd')) {
          inCurrentMonth = true
          break
        }
      }

      // find desired month && render all visible months starting from the 'date'.
      if (!inCurrentMonth) {
        this.initMonthsVisible(date) // re-init the 'selectedYearMonths'
        await this.loadMonthsCellData()
      }

      // select the cell by the date
      const cell = this.getCalendarCellByTheDate(date)
      if (cell) {
        await this.onSelectCell(cell)
      }
    })
  }

  /**
   * Fires when a user clicked on the calendar's cell.
   *
   * @param cell The cell data.
   */
  async onSelectCell(cell: CalendarCell): Promise<void> {
    // Return if the clickable option is disabled, or is not in current month, or the cell is before current date
    if (!this.clickable
      || !cell.dayInCurrentMonth
      || cell.availability === 'unavailable'
      || cell.calendarItems.length >= this.maxOrdersPerDay
      || (!this.clickablePastDate && dateCut(cell.date, 'h') < this.currentYearMonthDay)) {
      this.selectAndHighlight(null)
      return
    }

    this.selectAndHighlight(cell)

    // Fetch details if feature enabled.
    if (this.fetchDetails) {
      this.resetApi()
      // all calendar items with nested profile that has not been fetched
      const profileIdsToBeFetched: number[] = cell.calendarItems
        .filter(item => item.nestedProfile)
        .map(item => item.nestedProfile.profileId)
        .filter(item => !this.fetchedProfileIds.includes(item))

      if (!isArrayNullOrEmpty(profileIdsToBeFetched)) {
        this.fetchedProfileIds.push(...profileIdsToBeFetched)
        // emit the selected detail cell to not ready yet.
        this.emitSelectedCellDetail(false)
        // call the API
        const nestedProfiles = await firstValueFrom(this.callGetNestedProfileResp(profileIdsToBeFetched, cell))
        this.fetchedProfiles.push(...nestedProfiles)
        // emit the selected detail cell.
        this.emitSelectedCellDetail(true, cell)
      } else {
        this.emitSelectedCellDetail(true, cell)
      }
    }
  }

  /**
   * Calls the server API to get detailed data of calendar item's nested profiles.
   *
   * @param profileIds Calendar item's nested profiles ids.
   * @param selectedCell Calendar's selected cell.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private callGetNestedProfileResp(profileIds: number[], selectedCell: CalendarCell): Observable<CalendarItemNestedProfileResp[]> {
    const req: CalendarItemNestedProfilesReq = {
      nestedProfileIds: profileIds
    }
    return this.unwrap(this.calendarService.callCalendarItemNestedProfiles(req))
  }

  /**
   * Constructs the {@link CalendarCellDetail} object and sets it to the {@link selectedCellDetail}, and emits
   * into the {@link selectedCellDetailChange} emitter by the {@link selectedCell} param.
   * If the 'ready' param is false, it sets these properties to null.
   *
   * @private
   */
  private emitSelectedCellDetail(ready: boolean, selectedCell?: CalendarCell): void {
    if (!ready) {
      this.selectedCellDetail = null
      this.selectedCellDetailChange.emit(null)

    } else if (selectedCell) {
      // find all detail profiles by selected-cell's nested profiles
      const profiles = selectedCell.calendarItems
        .filter(item => item.nestedProfile)
        .map(item => this.getFetchedProfile(item.nestedProfile))

      // construct detail cell
      const detailCell: CalendarCellDetail = {
        cell: selectedCell,
        fetchedProfiles: profiles
      }

      // emit
      this.selectedCellDetail = detailCell
      this.selectedCellDetailChange.emit(detailCell)
    }
  }

  /**
   * Loads the cell data into the {@link monthsCellData}.
   */
  private loadMonthsCellData(): void {
    this.sixRowsCalendar = false // reset
    for (let i = 0; i < this.monthsVisible; i++) {
      const currentMonth = this.selectedYearMonths[i]
      // construct basic cell dates (improves the loading speed)
      this.monthsCellData[i] = this.constructCells(currentMonth)
      this.sixRowsCalendar = this.sixRowsCalendar || (this.monthsCellData[i][35]?.dayInCurrentMonth)
      // load additional information
      setTimeout(async () => {
        // fetch data only if the year-month has not been already fetched.
        if (this.monthNotAlreadyFetched(currentMonth)) {
          const calendarItems = await firstValueFrom(this.callFindCalendarItemsBetween(currentMonth))
          const cellData = this.applyItems(currentMonth, calendarItems)
          if (dateEquals(currentMonth, this.selectedYearMonths[i], 'd')) {
            this.monthsCellData[i] = cellData
          }
        }
        this.applySearchValues()
      })
    }
  }

  /**
   * Calls the 'find calendar items between range' server API with the range of first and last table cell date.
   *
   * @param date Used to define the month-based range.
   */
  private callFindCalendarItemsBetween(date: Date): Observable<CalendarItemResp[]> {
    this.resetApi()

    const startDate = dateCut(date, 'h')
    startDate.setDate(1)
    const endDate = addDays(startDate, daysInMonth(startDate) - 1)

    // Create a request
    const req: FindCalendarItemsBetweenReq = {
      profileId: this.data.profileId,
      start: startDate,
      end: new Date(endDate.setHours(23, 59, 0, 0))
    }
    // Call server API
    return this.unwrap(this.calendarService.callFindCalendarItemsBetweenOfOrders(req))
  }

  /**
   * Calls the API to get pending host profile items count.
   */
  private callCountPendingHostProfiles(): Observable<number> {
    this.resetApi()
    // Construct the request
    const req: FindHostPendingItemsReq = {
      profileId: this.data.profileId,
      date: new Date()
    }
    // Call the API
    return this.unwrap(this.hostProfileService.callCountPendingHostProfiles(req))
  }

  /**
   * Calls the API to get pending host profile items.
   */
  private callPendingHostProfileItems(): Observable<FindHostPendingItemsResp> {
    this.resetApi()
    // Construct the request
    const req: FindHostPendingItemsReq = {
      profileId: this.data.profileId,
      date: new Date()
    }
    // Call the API
    return this.unwrap(this.hostProfileService.callFindPendingHostProfiles(req))
  }

  /**
   * - The {@link monthsVisible} property has to be only between 1 - 3.
   * - Initializes the {@link selectedYearMonths} array by the {@link monthsVisible} property.
   * - The {@link startDate} is the first visible month.
   */
  private initMonthsVisible(startDate: Date): void {
    // correct the value between 1 - 3
    if (this.monthsVisible > 3) {
      this.monthsVisible = 3
    } else if (this.monthsVisible <= 0) {
      this.monthsVisible = 1
    }
    // init the selectedYearMonths array
    const cut = dateCut(startDate, 'h')
    cut.setDate(1)
    this.selectedYearMonths = [cut]
    for (let i = 1; i < this.monthsVisible; i++) {
      this.selectedYearMonths.push(addMonths(cut, i))
    }
  }

  ngOnDestroy(): void {
    this.subs?.forEach(it => it?.unsubscribe())
  }
}

/**
 * Defines the skeleton of calendar's cell data.
 */
export interface CalendarCell {
  /**
   * Specifies whether this cell is today.
   */
  isToday: boolean
  /**
   * If the cell is in the current month. - applicable for additional styles.
   */
  dayInCurrentMonth: boolean
  /**
   * Current selected cell.
   */
  selected: boolean
  closed: boolean
  /**
   * The cell's date containing year, month, and day only.
   */
  date: Date
  calendarItems: CalendarItemResp[]
  /**
   * - Unavailable - between search dates are calendar items.
   * - Uncommitted - between search dates are uncommitted calendar items.
   * - Free - between search dates are no calendar items.
   */
  availability: 'unavailable' | 'uncommitted' | 'free'
}

/**
 * Contains the {@link CalendarCell} object with the fetched profiles of {@link CalendarItemNestedProfileResp} of that cell.
 */
export interface CalendarCellDetail {
  cell: CalendarCell
  fetchedProfiles: CalendarItemNestedProfileResp[]
}
