<section>
  <form [formGroup]="form">

    <!--Time section-->
    <section class="p-grid p-p-0">
      <!--Start time-->
      <div class="p-col-6 p-md-12 p-p-0">
        <app-date-input [form]="form"
                        formFieldName="startTime"
                        [timeOnly]="true"
                        [required]="true"
                        label="Start time"
                        i18n-label
                        styleClass="p-mr-2 p-mr-md-0"
                        [defaultDate]="defaultStart"
                        icon="fa-solid fa-hourglass-start"
                        (click)="clickOnTimeField('startTime')"
                        placeholder="Start at"
                        i18n-placeholder></app-date-input>
      </div>

      <!--End time-->
      <div class="p-col-6 p-md-12 p-p-0">
        <app-date-input [form]="form"
                        formFieldName="endTime"
                        label="End time"
                        i18n-label
                        styleClass="p-ml-2 p-ml-md-0"
                        [timeOnly]="true"
                        [required]="true"
                        [defaultDate]="defaultEnd"
                        [duration]="{min: Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH}"
                        (click)="clickOnTimeField('endTime')"
                        icon="fa-solid fa-hourglass-end"
                        placeholder="Finish at"
                        i18n-placeholder>
          <!--FE-CHECK-->
          <app-frontend-validation [form]="form" field="endTime" error="date" i18n>
            Must be at least {{ Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH / 1000 / 60 }} min long.
          </app-frontend-validation>
        </app-date-input>
      </div>
    </section>

    <!--Date section-->
    <section class="p-grid p-p-0">
      <!--Start date-->
      <div class="p-col-12 p-sm-6 p-p-0">
        <app-date-input [form]="form"
                        formFieldName="startDate"
                        [minDate]="currentDate"
                        label="Start date"
                        i18n-label
                        styleClass="p-mr-sm-2"
                        placeholder="Start"
                        i18n-placeholder
                        [keyboard]="false"
                        [required]="true"
                        icon="fa-solid fa-calendar-check"></app-date-input>
      </div>

      <!--End date-->
      <div class="p-col-12 p-sm-6 p-p-0">
        <app-date-input [form]="form"
                        formFieldName="endDate"
                        [minDate]="currentDate"
                        label="End date"
                        i18n-label
                        styleClass="p-ml-sm-2"
                        placeholder="Finish"
                        i18n-placeholder
                        icon="fa-solid fa-minus c-pointer"
                        rightIcon="fa-solid fa-plus"
                        [required]="true"
                        [disableIcon]="disableDateMinusIcon || !form?.value.endDate"
                        [disableRightIcon]="!form?.value.endDate"
                        (iconClick)="modifyDate('endDate', false)"
                        (rightIconClick)="modifyDate('endDate', true)"></app-date-input>
      </div>
    </section>

    <!--Action Buttons-->
    <div class="p-d-flex p-ai-center p-jc-between p-my-2">
      <!--Reset fields-->
      <app-button type="secondary"
                  icon="fa-solid fa-arrow-rotate-left"
                  *ngIf="form?.value.endDate || form?.value.startDate"
                  @fade
                  styleClass="p-mr-2"
                  [disabled]="checkAvailabilityLoading"
                  (clicked)="resetFields()"></app-button>

      <!--Check availability-->
      <app-button *ngIf="!unavailableItem && responseSuccess && selectedStartTime >= defaultStart" @fade
                  type="cta"
                  label="See offer"
                  i18n-label
                  icon="fa-solid fa-cart-shopping"
                  class="p-mx-2"
                  [highlight]="true"
                  [disabled]="form?.invalid || selectedStartTime < defaultStart || checkAvailabilityLoading"
                  (clicked)="scrollToOffer.emit()"></app-button>
    </div>

    <app-backend-validation [check]="ServerMessage.PROFILE_ORDER_MINIMAL_DISTANCE_FAILED"
                            [serverMessages]="serverMessages"
                            [showAsBox]="true"
                            styleClass="p-mt-2">
      <div i18n>
        Cannot set order's start earlier than <b>{{ Restrictions.MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER }}
        minutes </b>
        from now.
      </div>
    </app-backend-validation>

    <!--Available-->
    <div *ngIf="!unavailableItem && responseSuccess && selectedStartTime >= defaultStart"
         class="success-box p-d-flex p-my-2" @grow>
      <i class="fa-solid fa-user-check p-mr-2"></i>
      <div>
        <b class="p-mr-1">{{ data.displayName || data.charId }}</b> <span i18n>is available.</span>
      </div>
    </div>

    <!--Unavailable-->
    <div *ngIf="unavailableItem && responseSuccess && selectedStartTime >= defaultStart"
         class="failed-box p-d-flex p-my-2" @grow>
      <i class="fa-solid fa-user-xmark p-mr-2"></i>
      <div>
        <b class="p-mr-1">{{ data.displayName || data.charId }}</b>
        <span class="p-mr-1" i18n>is</span>
        <b class="p-mr-1">{{ unavailableItem.start | date: 'd. M.' }}</b>
        <span class="p-mr-1" i18n>unavailable from</span>
        <b>{{ unavailableItem.start | date: 'H:mm' }}.</b>
      </div>
    </div>

    <!--Past time-->
    <div *ngIf="selectedStartTime < defaultStart && form.value.startDate && form.value.endDate"
         class="failed-box p-d-flex p-ai-center p-my-2" @grow>
      <i class="fa-solid fa-calendar-xmark p-mr-2"></i>
      <div>
        <span class="p-mr-1" i18n>Can't select the</span>
        <b class="p-mr-1" i18n>past</b>.
      </div>
    </div>
  </form>
</section>
