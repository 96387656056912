import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {ProfileResp, ProfileService, UpdateProfileBioReq} from '../../../service/profile.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {EditableComponent} from '../../abstract/editable.component'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'
import {TextInputComponent} from '../../common/form/text-input/text-input.component'

@Component({
  selector: 'app-profile-bio',
  templateUrl: './profile-bio.component.html',
  styleUrls: ['./profile-bio.component.scss']
})
export class ProfileBioComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  data: ProfileResp

  form: FormGroup
  /**
   * Defines visibility of the more button of the bio text
   */
  @Input()
  fullBioVisible = false
  /**
   * Subscription related for showing this dialog.
   * Emitted from {@link ProfileCompletionComponent}.
   */
  private showSub: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private completionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    if (!this.data) {
      this.loading = true
    }
    this.initShowSub()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.loading = false
    }
  }

  showEditComponent(): void {
    super.showEditComponent()
    this.initEditDialogForm()
  }

  /**
   * Focuses the input element after a certain amount of time.
   */
  focusInput(el: TextInputComponent): void {
    setTimeout(() => {
      el.doFocus()
    }, 50)
  }

  /**
   * Initializes form fields.
   */
  private initEditDialogForm(): void {
    this.form = this.formBuilder.group({
      bio: [this.data.bio || '']
    })
  }

  /**
   * Fires when a user has clicked on the save button.
   */
  async onComponentSave(): Promise<void> {
    const formData = this.form.value
    const resp = await firstValueFrom(this.callUpdateProfileBio(formData))
    if (resp && this.noServerMessages()) {
      this.data.bio = formData?.bio?.trim()
      this.completionService.closeCompletionItemAndCheck()
    }
  }

  /**
   * Constructs a request from the given formData and calls the API.
   */
  private callUpdateProfileBio(formData): Observable<boolean> {
    const req: UpdateProfileBioReq = {
      text: formData.bio || null
    }
    return this.unwrap(this.profileService.callUpdateProfileBio(req))
  }

  /**
   * Initializes {@link showSub} based on value from {@link ProfileCompletionService.completionTypeShow}.
   */
  private initShowSub(): void {
    this.showSub = this.completionService.completionTypeShow.subscribe(type => {
      if (type === CompletionType.BIOGRAPHY) {
        this.showEditComponent()
      }
    })
  }

  ngOnDestroy(): void {
    this.showSub?.unsubscribe()
  }
}
