<!--Normal preview-->
<ng-template [ngIf]="!url || url?.length === 0">
  <ng-template [ngTemplateOutlet]="btn"></ng-template>
</ng-template>

<!--RouterLink-driven navigation-->
<a *ngIf="url?.length > 0" [routerLink]="url" [attr.aria-label]="label">
  <ng-template [ngTemplateOutlet]="btn"></ng-template>
</a>

<ng-template #btn>
  <button (click)="clicked.emit($event)"
          [class.highlight]="!disabled && highlight"
          [disabled]="disabled || loading"
          [iconPos]="iconPos"
          [icon]="(loading) ? 'fa-solid fa-circle-notch fa-spin' : icon"
          [label]="(labelAutoHide && !isScreenOf(Screen.SM)) ? '' : label"
          class="{{styleClass}} {{disabledClass}}"
          pButton
          pRipple
          [attr.aria-label]="!url?.length ? label : ''"
          type="button"></button>
</ng-template>
