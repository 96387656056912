<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [hold]="deleteConfirmDialogVisible"
            type="save"
            header="Edit showcase images"
            i18n-header
            successMessage="Profile images have been saved"
            i18n-successMessage
            url="image-showcase"
            [disableSaveOption]="saving || loading"
            (discard)="closeEditComponent()"
            [save]="onSave.bind(this)">

  <!--Hint-->
  <app-hint>
      <span i18n>
        All images you are going to upload must be at least {{ Restrictions.MIN_IMAGE_SHOWCASE_WIDTH }}
        x{{ Restrictions.MIN_IMAGE_SHOWCASE_WIDTH }} pixels.
      </span>
    <span i18n>You can upload up to {{ maxImages }} images and change their order.</span>
  </app-hint>

  <!--Wallpapers-->
  <div *ngIf="!loading; else skeleton">
    <!--File input-->
    <input id="fileInput"
           type="file"
           class="p-d-none"
           [disabled]="loading || saving || images?.length >= maxImages"
           (change)="onFileSelect($event.target['files'][0])"
           accept="image/png, image/jpeg"
           #fileInput>


    <!--Upload button-->
    <ng-template [ngTemplateOutlet]="uploadButton" [ngTemplateOutletContext]="{$implicit: fileInput}"/>


    <!--BE-CHECK-->
    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.WALLPAPERS_UPDATE_FAILED"
                            [showAsBox]="true" i18n>
      Uploading has failed, try a different image.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGES_MAXIMUM_EXCEEDED"
                            [showAsBox]="true" i18n>
      You have exceeded the max number ({{ maxImages }}) of images.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_NOT_FOUND"
                            [showAsBox]="true" i18n>
      This image is not fully upload or not at all.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_SIZE_TOO_SMALL"
                            [showAsBox]="true" i18n>
      Since we want you to have professional and good-looking photos, we stop uploading this image, because it can be
      blurred. This image is too small, or has unappropriated resolution. Try to upload images having at least
      {{ Restrictions.MIN_IMAGE_SHOWCASE_WIDTH }} x {{ Restrictions.MIN_IMAGE_SHOWCASE_WIDTH }} pixels, and
      standard resolutions like 1:1, 4:3, or 21:9.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.FILE_FORMAT_NOT_SUPPORTED"
                            [showAsBox]="true" i18n>
      Please upload an image file. ['.<b>jpg</b>', or '.<b>png</b>']
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.IMAGE_DESCRIPTION_TOO_LONG"
                            [showAsBox]="true" i18n>
      Some of the image descriptions are too long.
    </app-backend-validation>

    <!--Order list-->
    <div class="p-py-3" *ngIf="images?.length > 0">

      <!--Hint-->
      <app-hint i18n>
        Drag images top and down to update their positions in a preview. The first 5 images will be displayed on your
        profile, while the others can be displayed when a visitor clicks on the 'More images'.
      </app-hint>

      <p-orderList [value]="images"
                   (onReorder)="refreshImages()"
                   [dragdrop]="true"
                   styleClass="p-mt-4">
        <ng-template let-item pTemplate="item">
          <div class="p-p-1 image-item p-relative" style="z-index: 20;">
            <div class="p-d-flex p-jc-between">
              <div class="p-d-flex p-ai-center w-100">
                <!--Sort Icon-->
                <i class="fa-solid fa-sort sort-icon p-mr-2"></i>

                <!--Image-->
                <div>
                  <div class="preview-image box-shadow rounded-medium fit-image"
                       [style]="item.url | backgroundImage"></div>
                </div>

                <!--Description on medium+ screens-->
                <div *ngIf="isScreenOf(Screen.SM)" class="w-100">
                  <ng-template [ngTemplateOutlet]="description"/>
                </div>
              </div>

              <div class="p-d-flex p-ai-center">
                <!--Edit-->
                <app-button type="secondary"
                            styleClass="p-mr-3"
                            class="edit-index"
                            icon="fa-solid fa-pen"
                            pTooltip="Edit"
                            i18n-pTooltip
                            tooltipPosition="top"
                            *ngIf="currentEditingItem?.uuid !== item.uuid" @fade
                            [disabled]="loading || saving || deleteConfirmDialogVisible"
                            (clicked)="onEditItem(item)"/>

                <!--Save-->
                <app-button type="secondary"
                            styleClass="p-mr-3"
                            class="edit-index"
                            icon="fa-solid fa-check"
                            pTooltip="Save"
                            i18n-pTooltip
                            tooltipPosition="top"
                            *ngIf="currentEditingItem?.uuid === item.uuid" @fade
                            [disabled]="loading || saving || deleteConfirmDialogVisible"
                            (clicked)="currentEditingItem = null"/>

                <!--Remove-->
                <app-button type="danger-light"
                            styleClass="p-mr-3"
                            icon="fa-solid fa-xmark"
                            pTooltip="Remove this image"
                            i18n-pTooltip
                            tooltipPosition="top"
                            [disabled]="loading || saving || deleteConfirmDialogVisible"
                            (clicked)="onRemove(item)"/>
              </div>
            </div>

            <!--Description on small screens-->
            <ng-template [ngIf]="!isScreenOf(Screen.SM)" #description>
              <!-- Description Edit -->
              <app-text-input *ngIf="currentEditingItem?.uuid === item.uuid" @fade
                              label="Image description"
                              i18n-label
                              placeholder="A wonderful event!"
                              i18n-placeholder
                              hint="Tell visitors what they can see in this picture."
                              [textArea]="true"
                              [rows]="3"
                              [focus]="true"
                              [notContact]="true"
                              [space]="true"
                              [disable]="loading || saving || deleteConfirmDialogVisible"
                              [form]="form"
                              [length]="{max: Restrictions.MAX_FILE_DESCRIPTION_LENGTH}"
                              (keydown)="$event.stopImmediatePropagation()"
                              formFieldName="description"
                              styleClass="p-px-2"
                              class="edit-field edit-index"/>

              <!--Item description-->
              <small class="p-m-2 p-text-italic l-count-3 p-pl-3 p-pl-sm-0"
                     *ngIf="currentEditingItem?.uuid !== item.uuid && item.description">
                {{ item.description }}
              </small>
            </ng-template>

          </div>
        </ng-template>
      </p-orderList>

      <div *ngIf="images?.length > 20">
        <!--Upload button-->
        <ng-template [ngTemplateOutlet]="uploadButton" [ngTemplateOutletContext]="{$implicit: fileInput}"/>
      </div>

    </div>
  </div>
</app-dialog>

<!--The upload button with an optional success message -->
<ng-template #uploadButton let-fileInput>
  <!--Action Buttons-->
  <div class="p-d-flex p-jc-center p-my-2 w-100">

    <!--Upload-->
    <app-button type="primary"
                icon="fa-solid fa-upload"
                label="Upload"
                i18n-label
                styleClass="p-mr-3"
                [disabled]="loading || saving || images.length >= maxImages"
                (clicked)="fileInput.click()"></app-button>
  </div>

  <!--Success message that the limit has been exceeded-->
  <div class="success-box p-mb-2" *ngIf="images?.length >= maxImages" @grow i18n>
    <i class="fa-solid fa-check p-mr-2"></i>
    <div>You have reached the maximum available images.</div>
  </div>
</ng-template>

<!--Skeleton-->
<ng-template #skeleton>
  <p-skeleton *ngFor="let i of [].constructor(5)"
              width="100%" height="3rem" styleClass="p-mb-2 rounded-medium"></p-skeleton>
</ng-template>

<!--Remove confirm dialog-->
<app-dialog *ngIf="deleteConfirmDialogVisible"
            [(show)]="deleteConfirmDialogVisible"
            [component]="this"
            type="danger"
            header="Are you sure?"
            i18n-header
            class="delete-confirm-dialog"
            successMessage="Removed successfully"
            i18n-successMessage
            saveLabel="Remove"
            i18n-saveLabel
            discardLabel="Keep"
            i18n-discardLabel
            [disableSaveOption]="saving || loading"
            (discard)="onRemoveConfirm(false)"
            (saved)="onRemoveConfirm(true)">

  <div class="p-py-2">
    <p i18n>The following image will be removed</p>
    <app-img [src]="images[deleteConfirmDialogImageIndex].url"
             alt="Profile image"
             i18n-alt
             styleClass="fit-image delete-image p-mt-2 rounded-small"></app-img>
  </div>
</app-dialog>
