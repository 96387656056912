<div class="wrapper p-py-3">

  <div *ngIf="data; else skeleton">
    <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
        class="title" i18n>
      Hashtags</h2>

    <!--No content-->
    <div *ngIf="editButtonVisible && data?.hashtags?.length === 0">
      <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
         class="no-content">
        <i class="fa-solid fa-circle-info"></i>
        <span i18n>
          Add some hashtags to increase your profile popularity.
        </span>
      </p>
    </div>

    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
         class="p-d-flex p-ai-center p-grid"
    >
      <div *ngFor="let hashtag of data.hashtags | slice: 0:sliceEnd">
        <div class="p-col hashtag rounded-medium p-p-2 p-m-2 p-d-flex p-ai-center">
          <i class="fa-solid fa-hashtag p-mr-2"></i>
          {{hashtag.name}}
        </div>
      </div>

      <!--More button-->
      <div class="p-col" *ngIf="data?.hashtags?.length > 5 && sliceEnd === 5">
        <app-button icon="fa-solid fa-plus"
                    label="More"
                    i18n-label
                    styleClass="p-button-rounded p-button-text p-button-plain p-button-outlined"
                    (clicked)="sliceEnd = Restrictions.MAX_HASHTAGS_PER_PROFILE"></app-button>
      </div>
    </div>

    <div data-aos="fade-up" data-aos-duration="1000"
         *ngIf="editButtonVisible"
         class="p-mt-3">
      <app-button type="edit"
                  pTooltip="Edit your hashtags."
                  i18n-pTooltip
                  tooltipPosition="top"
                  (clicked)="showEditComponent()"></app-button>
    </div>
  </div>
</div>

<!--Edit Component-->
<app-dialog *ngIf="editComponentVisible"
            [(show)]="editComponentVisible"
            [component]="this"
            header="Edit your profile hashtags"
            i18n-header

            (discard)="closeEditComponent()"
            [save]="onComponentSave.bind(this)">

  <div *ngIf="data; else skeletonEditDialog">

    <p *ngIf="selectedHashtags.length === 0" class="no-content">
      <i class="fa-solid fa-circle-info"></i>
      <span i18n>
        No hashtags assigned yet.
      </span>
    </p>

    <div class="p-grid p-mb-3">
      <div *ngFor="let hashtag of selectedHashtags">
        <div class="p-col">
          <app-button icon="fa-solid fa-xmark"
                      iconPos="right"
                      styleClass="p-button-rounded p-button-secondary"
                      [label]="hashtag.name"
                      (clicked)="remove(hashtag)"></app-button>
        </div>
      </div>
    </div>

    <h4 i18n>Pick some hashtags</h4>
    <p class="p-help p-m-0" i18n>Type hashtags names without # like: Music, Formal, or Funny.</p>

    <!--Search Results-->
    <div [class.p-d-none]="input.disabled || !input.value || input.value.length < Restrictions.MIN_HASHTAG_NAME_LENGTH"
         [@fade]="input.disabled || !input.value">
      <div class="popup-description">
        <p class="p-m-0" i18n>{{searchHashtags.totalElements || 0}} items available.</p>
      </div>

      <div class="mat-elevation-z3 p-mb-2 p-p-3">
        <p-scrollPanel [style]="{width: '100%', height: '20rem'}">
          <p-dataView [value]="searchHashtags.content"
                      [lazy]="true"
                      [totalRecords]="searchHashtags.totalElements"
                      [rows]="10"
                      layout="list">

            <ng-template let-hashtag pTemplate="listItem">
              <div class="p-col-12 p-p-1">
                <div class="p-grid" pRipple (click)="add(hashtag)">
                  <div class="p-col-12 p-sm-9 p-d-flex p-ai-center">
                    <app-button icon="fa-solid fa-bookmark"
                                styleClass="p-button-rounded p-button-secondary"
                                [label]="hashtag.name"></app-button>
                  </div>

                  <div class="p-col-12 p-sm-3 p-d-flex p-ai-center">
                    <p *ngIf="hashtag.count" class="times-used">{{hashtag.count}}x used</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>
        </p-scrollPanel>
      </div>

      <div class="popup-description"
           *ngIf="!loading && searchHashtags.totalElements !== searchHashtags.content.length" [@fade]="loading">
        <div class="p-d-flex p-jc-center">
          <a i18n>More</a>
        </div>
      </div>

      <div class="popup-description" *ngIf="loading" [@fade]=loading>
        <div class="p-d-flex p-ai-center">
          <i class="fa-solid fa-circle-notch fa-spin p-mr-2"></i>
          <div i18n>Loading...</div>
        </div>
      </div>
    </div>

    <!--Search Input Field-->
    <form [formGroup]="form" autocomplete="off" class="p-mt-3">
      <input #input
             id="search"
             type="text"
             pInputText
             placeholder="Type a hashtag"
             i18n-placeholder
             autocapitalize="words"
             formControlName="search"
             (input)="onInputChange()"
             (paste)="$event.preventDefault()">

      <p class="field-description" *ngIf="selectedHashtags.length >= Restrictions.MAX_HASHTAGS_PER_PROFILE" i18n>
        You have already {{Restrictions.MAX_HASHTAGS_PER_PROFILE}} hashtags.
      </p>

      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" field="search" error="length" i18n>
        Too long name of a hashtag.
      </app-frontend-validation>

      <app-frontend-validation [form]="form" field="search" error="hashtag" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.HASHTAG_NAME_INCORRECT" i18n>
        Invalid or too long name of a hashtag.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.HASHTAG_COUNT" i18n>
        Exceeds the maximum of {{Restrictions.MAX_HASHTAGS_PER_PROFILE}} hashtags.
      </app-backend-validation>

    </form>
  </div>
</app-dialog>

<!--Skeleton templates-->
<ng-template #skeleton>
  <p-skeleton width="10rem" height="2rem" styleClass="p-mb-2"></p-skeleton>

  <div class="p-grid p-ml-1">
    <p-skeleton width="5rem" height="3rem" borderRadius="0.5rem" styleClass="p-mr-3 p-mb-2"></p-skeleton>
    <p-skeleton width="8rem" height="3rem" borderRadius="0.5rem" styleClass="p-mr-3 p-mb-2"></p-skeleton>
    <p-skeleton width="6rem" height="3rem" borderRadius="0.5rem"></p-skeleton>
  </div>
</ng-template>

<ng-template #skeletonEditDialog>
  <p-skeleton width="80%" height="1.5rem" styleClass="p-mt-4 p-mb-2"></p-skeleton>
  <p-skeleton width="40%" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
  <p-skeleton width="60%" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
  <p-skeleton width="30%" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
</ng-template>

