import {Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../../../component/abstract/editable.component'
import {BriefProfileOrderResp} from '../../../../service/profile-order.service'
import {ProfileResp} from '../../../../service/profile.service'
import {Acceptance} from '../../../../common/acceptance'
import {OrderStatusBadge} from '../profile-order-status-badge/profile-order-status-badge.component'

@Component({
  selector: 'app-profile-manager-item',
  templateUrl: './profile-manager-item.component.html',
  styleUrls: ['./profile-manager-item.component.scss']
})
export class ProfileManagerItemComponent extends EditableComponent implements OnChanges {

  /**
   * The current order item.
   */
  @Input({required: true})
  order: BriefProfileOrderResp
  /**
   * The current logged profile.
   */
  @Input()
  profileData: ProfileResp
  /**
   * Defines the state category.
   */
  @Input()
  stateCategory: Acceptance | 'awaiting_payment'
  /**
   * Defines whether the curren logged {@link profileData} is the author of the {@link order}.
   */
  isAuthor: boolean
  /**
   * The abbreviation for the {@link Acceptance} enum.
   */
  A: typeof Acceptance = Acceptance
  /**
   * A current order status badge.
   */
  status: OrderStatusBadge

  /**
   * Available translations for HTML.
   */
  protected trans = {
    overlap: $localize`This order is overlapping with the order of `
  }

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.profileData?.currentValue || changes.order?.currentValue) && this.profileData && this.order) {
      this.isAuthor = this.order.author.charId === this.profileData.charId
    }
  }
}
