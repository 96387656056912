//@formatter:off
import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild} from '@angular/core'
import {appendNewPage, newEmptyPage, Page} from '../../../rest/page-resp'
import {ApiComponent} from '../../abstract/api.component'
import {firstValueFrom, Observable} from 'rxjs'
import {FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn} from '@angular/forms'
import {maxLength} from '../../../validator/custom.validators'
import {PrimeTemplate, SharedModule} from 'primeng/api'
import {LazyListComponent} from '../list/lazy-list/lazy-list.component'
import {TextInputComponent} from '../form/text-input/text-input.component'
import {growAnimation} from '../../../animation/grow.animation'
import {HintComponent} from '../hint/hint.component'
import {NgIf, NgTemplateOutlet} from '@angular/common'

/**
 * - A common component that provides a search bar with the {@link LazyListComponent}, where the result is being displayed.
 * ## The {@link component} property needs to be initialized always!
 * <pre>
 *   <app-search [component]="this" ...>
 * </pre>
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  imports: [
    HintComponent,
    NgTemplateOutlet,
    NgIf,
    ReactiveFormsModule,
    LazyListComponent,
    TextInputComponent,
    SharedModule
  ],
  standalone: true
})
export class SearchComponent<E> implements OnInit, OnChanges, AfterViewInit {
  /**
   * Contains the current trimmed text value from the input field.
   */
  @Input()
  search: string
  /**
   * Emits the current trimmed text value from the input field.
   */
  @Output()
  searchChange = new EventEmitter<string>()
  /**
   * Disables the input field.
   */
  @Input()
  disableInput: boolean
  /**
   * Sets the visibility of the result permanently.
   */
  @Input()
  disableResult = false
  /**
   * Hides the result section.
   * This property is changed only in the {@link SearchComponent}.
   */
  disableResultLocal = true
  /**
   * Hides the result and disables the input.
   */
  @Input()
  disable: boolean
  /**
   * The placeholder text visible in the input field.
   */
  @Input()
  placeholder: string

  /**
   * The input page object.
   */
  @Input()
  items: Page<E> = newEmptyPage()
  /**
   * Notifies the {@link items} when they change.
   */
  @Output()
  itemsChange = new EventEmitter<Page<E>>()
  /**
   * - Calls this function before any next loaded page is appended.
   * - Great to modify the page before append.
   */
  @Input()
  beforeAppend: (page: Page<E>) => Page<E>
  /**
   * - Used to access the api properties.
   * - *It uses features like loading property and resetApi()*
   */
  @Input()
  component: ApiComponent
  /**
   * - This function will be called on search and next page lazy-loading.
   * - The **page** argument represents the page that should be loaded.
   * - The **search** argument represents the string that will be searched.
   */
  @Input()
  searchFunction: (page: number, search: string) => Observable<Page<E>>
  /**
   * The scroll height of the result section.
   */
  @Input()
  scrollHeight: string
  /**
   * Defines the height of the single item in the result section.
   * - This property must be initialized with the {@link maxScrollHeight} to make the process of auto {@link scrollHeight} work.
   */
  @Input()
  itemHeight: number
  /**
   * - Defines the max allowed scroll height in pixels.
   * - This property must be initialized with the {@link itemHeight} to make the process of auto {@link scrollHeight} work.
   */
  @Input()
  maxScrollHeight: number
  /**
   * Sets the result {@link LazyListComponent} in the overlay panel.
   */
  @Input()
  resultAsOverlay: boolean
  /**
   * - Determines whether the {@link resultAsOverlay} will be visible even there are no {@link items}.
   * - The overlay gets visible only when the initial load (page 0) of {@link items} has been loaded (nextPage > 0).
   * - To style the no-content, use the ng-template of 'noContent'.
   */
  @Input()
  overlayEmptyVisible: boolean
  /**
   * - The base Z index of the result overlay panel.
   * - Default is **1120**
   */
  @Input()
  resultOverlayZ = 1120
  /**
   * Defines the style class of the overlay panel in the {@link LazyListComponent}.
   */
  @Input()
  overlayClass: string
  /**
   * Defines the style class of the component.
   */
  @Input()
  styleClass: string
  /**
   * - Specifies how many milliseconds needs to be delayed before the next search request.
   * - Default is 1000 ms.
   */
  @Input()
  searchDelay = 1000
  /**
   * Focus the search field automatically. Disabled by default.
   */
  @Input()
  searchFocus = false
  /**
   * Defines whether this field should check for the 'length' error.
   * The 'min/max' property represents the minimum/maximum allowed characters for this field.
   */
  @Input()
  length?: { min?: number; max?: number }
  /**
   * Extra validations for the search input field.
   */
  @Input()
  extraValidations: ValidatorFn[] = []
  /**
   * Emits when the input element lost the focus.
   */
  @Output()
  focusLost = new EventEmitter<any>()
  /**
   * List of ng-template objects in the child component layout.
   */
  @ContentChildren(PrimeTemplate)
  templates: QueryList<PrimeTemplate>
  /**
   * The result lazy list component.
   */
  @ViewChild('lazyList')
  lazyList: LazyListComponent<E>
  /**
   * The search input text component.
   */
  @ViewChild('input')
  input: TextInputComponent
  /**
   * The main content template of the scroller.
   */
  itemTemplate: TemplateRef<any>
  /**
   * The no-content template.
   */
  noContentTemplate: TemplateRef<any>
  /**
   * The search additional content. A space for frontend and backend validations.
   */
  validationTemplate: TemplateRef<any>
  /**
   * The hint content.
   */
  hintTemplate: TemplateRef<any>
  /**
   * The FormGroup object containing the input field.
   */
  form: FormGroup
  /**
   * Enables the lazy-loading new page in the lazy-list.
   */
  enableScrollLazy: boolean
  /**
   * Represents the current search task timeout.
   */
  private searchTimeout: any

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allowInput()

    // validate inputs
    if (changes.maxScrollHeight || changes.itemHeight) {
      this.validateInputs()
    }
  }

  ngAfterViewInit(): void {
    this.initTemplates()
  }

  /**
   * Fires when a user want to un-focus the input.
   */
  onBlur(): void {
    // it keeps the input element focused at the specific caret position.
    if (this.resultAsOverlay && this.lazyList?.overlayPanel?.overlayVisible) {
      this.input?.input?.nativeElement?.focus()
    }
  }

  /**
   * Fires when a user clicked on the input.
   */
  onInputClick(event): void {
    if (this.resultAsOverlay) {
      this.setOverlayPanelVisible(true, event)
    }
  }

  /**
   * Focuses the {@link input} field.
   */
  focus(delay: number = 0): void {
    if (delay > 0) {
      setTimeout(() => {
          this.focus(0)
      }, delay)
    } else {
      this.input.doFocus()
    }
  }

  /**
   * Starts a new searching requests if everything is correct.
   */
  startSearch(event): void {
    const text = this.form.value.search.trim()
    // return if no changes
    if (text === this.search) {
      return
    }
    // create new page before a new call
    this.enableScrollLazy = false
    this.items = newEmptyPage()
    this.updateSearch(text)

    // remove the previous search timeout process
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }

    // start search if no errors
    if (this.form.invalid
      || text.length < (this.length?.min || 1)
      || !this.items
      || (this.items.content.length === this.items.totalElements && this.items.nextPage !== 0)) {
      this.search = ''
      this.disableResultLocal = true
      this.enableScrollLazy = false
      this.setOverlayPanelVisible(false)
      return
    }

    // add a new one
    this.searchTimeout = setTimeout(async () => {
      // starts loading
      this.component?.resetApi()
      this.setComponentLoading(true)
      try {
        let newPage = await firstValueFrom(this.searchFunction(this.items.nextPage, this.search))
        newPage = this.beforeAppend?.(newPage) || newPage
        appendNewPage(this.items, newPage, 'end')
        this.items.nextPage++
        this.disableResultLocal = false
        this.enableScrollLazy = true
        this.itemsChange.emit(this.items)
        setTimeout(() => {
          this.setOverlayPanelVisible(true, event)
        })
      } finally {
        this.setComponentLoading(false)
      }
    }, this.searchDelay)
  }

  /**
   * Initializes the {@link form}
   */
  private initForm(): void {
    const validators = this.extraValidations || []

    // Max length check validation
    if (this.length?.max) {
      validators.push(maxLength(this.length.max))
    }

    // Init form
    this.form = this.formBuilder.group({
      search: ['', validators]
    })
  }

  /**
   * Initializes all templates
   */
  private initTemplates(): void {
    setTimeout(() => {
      for (const pTemplate of this.templates) {
        switch (pTemplate.name) {
          case 'item':
            this.itemTemplate = pTemplate.template
            break
          case 'hint':
            this.hintTemplate = pTemplate.template
            break
          case 'validation':
            this.validationTemplate = pTemplate.template
            break
          case 'noContent':
            this.noContentTemplate = pTemplate.template
            break
        }
      }
    })
  }

  /**
   * Enables or disables the input field.
   */
  private allowInput(): void {
    if (this.disableInput || this.disable) {
      this.form?.disable()
    } else {
      this.form?.enable()
    }
  }

  /**
   * Sets the {@link component}'s loading property.
   */
  private setComponentLoading(loading: boolean): void {
    if (this.component) {
      this.component.loading = loading
    }
  }

  /**
   * Updates the {@link search} and emits in the {@link searchChange}.
   */
  private updateSearch(text: string): void {
    this.search = text
    this.searchChange.emit(text)
  }

  /**
   * Controls the {@link lazyList}'s overlay panel visibility, if the {@link resultAsOverlay} is enabled.
   *
   * @param visible The state the overlay should be turned into.
   * @param openEvent Required for the proper opening the overlay panel.
   */
  private setOverlayPanelVisible(visible: boolean, openEvent?: any): void {
    if (this.resultAsOverlay) {
      // displays overlay also when no content
      const fixVisibility = visible && (this.items?.content?.length > 0
        || (this.overlayEmptyVisible && this.items?.content?.length === 0 && this.items.nextPage > 0))
      this.lazyList?.setOverlayPanelVisible(fixVisibility, openEvent)
    }
  }

  /**
   * Checks inputs validity and throws occasional errors.
   */
  private validateInputs(): void {
    if (this.maxScrollHeight || this.itemHeight) {
      if (!this.maxScrollHeight || !this.itemHeight) {
        throw new Error(
          'The [maxScrollHeight] must be initialized with the [itemHeight] to provide the auto-height feature in <app-search>')
      }
    }
  }
}
