import {Component, EventEmitter, Input, Output} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {DialogComponent} from '../dialog.component'
import {NgIf} from '@angular/common'

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  imports: [
    DialogComponent,
    NgIf
  ],
  standalone: true
})
export class DialogConfirmComponent extends EditableComponent {
  /**
   * The save function to be executed by the dialog.
   */
  @Input()
  save: () => Promise<void>
  /**
   * Emits when the dialog gets saved successfully.
   */
  @Output()
  saved = new EventEmitter<boolean>()
  /**
   * An icon to be used for the {@link saveButtonIcon}.
   */
  saveIcon = 'fa-solid fa-check'
  /**
   * Label for save button.
   */
  saveLabel = $localize`Confirm`
  /**
   * A success message that will be displayed after a successful {@link save} call.
   */
  @Input()
  successMessage: string
  /**
   * An icon to be used near the {@link question} text.
   */
  @Input()
  icon = 'fa-solid fa-circle-exclamation'
  /**
   * - A question text displayed to the user. Should be like 'Are you sure?'.
   */
  @Input()
  question: string
  /**
   * Decides whether to show {@link CallResponseComponent} after confirming.
   */
  @Input()
  showResponse = true
}
