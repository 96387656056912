import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {RegisterAbstractComponent} from './register-abstract.component'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {UserResp} from '../../../service/user.service'
import {FirebaseAuthService, FirebaseRegisterReq} from '../../../service/firebase-auth.service'
import {AuthTypeEnum} from '../../../common/auth-type-enum'
import {ActivatedRoute} from '@angular/router'
import {StorageItem} from '../../../service/storage.service'

/**
 * Abstract class for firebase register components that contains common methods and properties.
 * Contains only common logic for all firebase register components.
 * It is used to avoid code duplication and let the component handle only view without logic.
 */
@Component({template: ''})
export abstract class RegisterFirebaseAbstractComponent extends RegisterAbstractComponent implements OnInit, OnDestroy {

  /**
   * {@link Subscription} to listen to changes, whether it should register new user.
   */
  registerUserSub?: Subscription
  /**
   * Title to display in template.
   */
  title: string
  firebaseRequest?: FirebaseRegisterReq
  /**
   * AuthTypeEnum to send in request to server.
   * @private
   */
  protected authType: AuthTypeEnum

  protected firebaseAuthService = inject(FirebaseAuthService)
  protected route = inject(ActivatedRoute)

  constructor() {
    super()
  }

  override ngOnInit(): void {
    // Get from localstorage first
    this.firebaseRequest = JSON.parse(this.storageService.getCookie(StorageItem.FIREBASE_REGISTER_REQ) || 'null')
    super.ngOnInit()
    this.listenToUserChanges()
  }

  /**
   * Fired when register button is clicked.
   * Registers user in server.
   * If no error messages, then it fetches data about user, so he can stay logged in.
   */
  override onRegister(): void {
    this.callAndFinish(async () => {
      this.setFormSubmitted(true)
      const resp = await firstValueFrom(this.callRegister(this.form.getRawValue()))

      // when registration was successfull
      if (resp && this.noServerMessages()) {
        this.storageService.deleteCookie(StorageItem.FIREBASE_REGISTER_REQ)
        this.afterRegisterSuccess(resp)
      }
    }, null, () => {
      this.setFormSubmitted(false)
    })
  }

  /**
   * Sends request through {@link FirebaseAuthService}
   * @param formData
   * @protected
   */
  protected override callRegister(formData): Observable<UserResp> {
    return this.unwrap(this.firebaseAuthService.callRegisterFirebase(
      this.createRegisterReq(formData)
    ))
  }

  protected override initForm(): void {
    this.form = this.formBuilder.group({
      displayName: [this.firebaseRequest?.displayName || ''],
      email: [this.firebaseRequest?.email || ''],
      phone: [this.firebaseRequest?.phone || ''],
      selectedCategory: [this.categories[0]],
      firebaseUid: ['']
    })
  }

  /**
   * Patches new data to form controls.
   * @param req Object with user's data.
   * @private
   */
  private patchValues(req: FirebaseRegisterReq): void {
    this.form.patchValue({
      displayName: req.displayName,
      email: req.email,
      firebaseUid: req.firebaseUid,
      phone: req.phone
    })
    this.storageService.setCookie(StorageItem.FIREBASE_REGISTER_REQ, JSON.stringify(req), '/', 5 + 60)
  }

  private getTitle(authType: AuthTypeEnum): string {
    switch (authType) {
      case AuthTypeEnum.FACEBOOK:
        return 'Facebook'
      case AuthTypeEnum.GOOGLE:
        return 'Google'
    }
  }

  /**
   * Listens to changes on {@link FirebaseAuthService} register user and patches values to form.
   * @private
   */
  private listenToUserChanges(): void {
    this.registerUserSub = this.firebaseAuthService.socialRegisterUser.subscribe(req => {
      if (req) {
        this.authType = req.authType
        this.patchValues(req)
        this.title = this.getTitle(this.authType)
      } else if (!this.firebaseRequest) {
        this.emptyServiceDataAction()
      }
    })
  }

  override ngOnDestroy(): void {
    this.registerUserSub?.unsubscribe()
    super.ngOnDestroy()
  }

  /**
   * Generate a register request object.
   */
  abstract createRegisterReq(formData): FirebaseRegisterReq

  /**
   * Let the child component to handle the event of incorrect data in {@link FirebaseAuthService}.
   */
  abstract emptyServiceDataAction(): void
}
