<div [class]="styleClass"
     class="p-mb-2 tutorial-class"
     [formGroup]="form"
     *ngVar="form.controls[formFieldName].invalid && form.controls[formFieldName].touched as invalid">

  <div class="p-d-flex p-ai-center"
       (click)="(hint) ? showHint = !showHint : null">
    <!--Required icon-->
    <i *ngIf="showRequiredSymbol && label" class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
       pTooltip="This field is required"
       i18n-pTooltip
       tooltipPosition="top"></i>

    <!--Label-->
    <label [for]="formFieldName"
           [class.c-pointer]="hint"
           [class.label-invalid]="invalid">{{ label }}</label>

    <!--Show hint icon-->
    <i *ngIf="hint"
       pTooltip="Why this field?"
       i18n-pTooltip
       tooltipPosition="top"
       class="fa-solid fa-circle-info p-ml-2 p-mb-2 hint-icon"
       [class.hint-icon-invalid]="invalid"></i>
  </div>

  <span [class.p-input-icon-left]="icon"
        [class.input-error]="invalid"
        *ngVar="(!isScreenOf(Screen.LG) && !inline) as touchUi;"
        class="w-100 p-relative">

    <!--Left side icon-->
    <i *ngIf="icon"
       class="{{icon}} p-ml-2"
       [class.disabled-element]="disableIcon"
       (click)="!disableIcon ? iconClick.emit($event) : null"></i>

    <p-calendar #calendar
                [formControlName]="formFieldName"
                [placeholder]="placeholder || label"
                [dateFormat]="dateFormat"
                hourFormat="24"
                styleClass="calendar-input"
                [readonlyInput]="!keyboard || disable"
                [showOnFocus]="showOnFocus"
                [inputId]="formFieldName"
                [defaultDate]="defaultDate"
                [minDate]="minDate"
                [firstDayOfWeek]="1"
                [baseZIndex]="baseZIndex + 1"
                [appendTo]="appendTo || ((touchUi) ? 'body' : null)"
                [selectionMode]="selectionMode"
                [inline]="inline"
                [showTime]="showTime"
                [timeOnly]="timeOnly"
                [required]="required"
                [maxDate]="maxDate"
                [stepHour]="stepHour"
                [stepMinute]="stepMinute"
                [stepSecond]="stepSecond"
                [tabindex]="tabindex"
                [touchUI]="touchUI !== null ? touchUI : touchUi"
                (ngModelChange)="valueChange.emit($event)"
                (keyup)="keyupEvent.emit($event)"
                (paste)="onPaste($event)"
                (copy)="onCopy($event)"
                (onShow)="openCalendar(true)"
                (onClose)="openCalendar(false)">
      <!--Header in touch UI-->
      <ng-template pTemplate="header">
        <h4 *ngIf="(touchUi || touchUI) && (header || placeholder)"
            class="p-text-center w-100 p-m-0 p-pt-3 l-count-1">{{ header || placeholder }}</h4>
      </ng-template>

      <ng-template [ngIf]="timeOnly">
        <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-end p-mr-2">
          <u class="p-text-bold c-pointer"
             (click)="calendar.hideOverlay()"
             i18n>Apply</u>
        </div>
      </ng-template>
      </ng-template>
    </p-calendar>

    <!--Left side icon-->
    <i *ngIf="rightIcon"
       class="{{rightIcon}} p-mr-2"
       [class.c-pointer]="!disableRightIcon"
       [class.disabled-element]="disableRightIcon"
       (click)="!disableRightIcon ? rightIconClick.emit($event) : null"></i>
  </span>

  <!--Required-->
  <app-frontend-validation *ngIf="!disableRequired" [form]="form" [field]="formFieldName" error="required" i18n>
    Required field.
  </app-frontend-validation>

  <!--Min Date-->
  <app-frontend-validation *ngIf="!disableMinDate && minDate" [field]="formFieldName" [form]="form" error="minDate"
                           i18n>
    Cannot be earlier than {{ minDate | date: 'd.M. yy' }}
  </app-frontend-validation>

  <!--Date After-->
  <app-frontend-validation *ngIf="!disableDateAfter && !duration"
                           [form]="form"
                           [field]="formFieldName"
                           error="dateAfter" i18n>
    The finish date can not be earlier than the start date.
  </app-frontend-validation>

  <!--Duration Date After-->
  <app-frontend-validation *ngIf="!disableDateAfter && duration"
                           [form]="form"
                           [field]="formFieldName"
                           error="dateAfter">

    <ng-template [ngIf]="duration.min && duration.max" i18n>
      This date must be {{ duration.min | date: 'mm' }} - {{ duration.max | date: 'mm' }} min later.
    </ng-template>
    <ng-template [ngIf]="duration.min === undefined && duration.max" i18n>
      This date cannot be late more than {{ duration.max | date: 'mm' }} min.
    </ng-template>
    <ng-template [ngIf]="duration.max === undefined && duration.min" i18n>
      This date must be at least {{ duration.min | date: 'mm' }} min later.
    </ng-template>
  </app-frontend-validation>

  <!--Optional hint-->
  <div class="hint p-d-block" *ngIf="showHint" @grow>{{ hint }}</div>

  <!--Validation and other content-->
  <ng-content></ng-content>
</div>

<!--Overlay-->
<!--<div *ngIf="(!isScreenOf(Screen.LG) || touchUI)&& !inline && calendarOpened"
     class="p-absolute overlay"
     [style.z-index]="baseZIndex"></div>-->
