import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core'
import {Calendar, CalendarModule} from 'primeng/calendar'
import {minDate} from '../../../../validator/custom.validators'
import {FrontendValidationComponent} from '../../frontend-validation/frontend-validation.component'
import {growAnimation} from '../../../../animation/grow.animation'
import {AbstractFormField} from '../abstract-form-field'
import {ReactiveFormsModule} from '@angular/forms'
import {VarDirective} from '../../../../directive/var.directive'
import {DatePipe, NgIf} from '@angular/common'
import {TooltipModule} from 'primeng/tooltip'

@Component({
  animations: [growAnimation(100)],
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  imports: [
    ReactiveFormsModule,
    VarDirective,
    NgIf,
    TooltipModule,
    CalendarModule,
    FrontendValidationComponent,
    DatePipe
  ],
  standalone: true
})
export class DateInputComponent extends AbstractFormField implements OnInit, AfterViewInit, OnChanges {

  /**
   * Custom header above the picker in touch UI layout.
   */
  @Input()
  header?: string

  /**
   * Output value changes
   */
  @Output()
  valueChange = new EventEmitter<Date>()
  /**
   * Index of the element in tabbing order.
   */
  @Input()
  tabindex?: number
  /**
   * - Displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'dateAfter' error within the <app-date-input>
   * selector tags.
   * - The 'min/max' property represents the minimum/maximum allowed amount of time to be later from some date.
   * - Requires to have the 'dateTimeAfterDateTime()', or the 'dateMustBeAfter()' validator.
   */
  @Input()
  duration?: { min?: number; max?: number } = null
  /**
   * The default date of the calendar.
   */
  @Input()
  defaultDate: Date
  /**
   * - The minimum selectable date.
   * - Adds the 'minDate()' validator to this field, if it is not already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'minDate' error within the <app-date-input>
   * selector tags.
   */
  @Input()
  minDate?: Date = null
  /**
   * The maximum selectable date.
   */
  @Input()
  maxDate?: Date = null
  /**
   * Defines the selection mode.
   */
  @Input()
  selectionMode: 'single' | 'multiple' | 'range' | undefined = 'single'
  /**
   * Sets the component to touch ui.
   */
  @Input()
  touchUI: boolean = null
  /**
   * Displays the calendar component as inline.
   */
  @Input()
  inline: boolean
  /**
   * The minimum z index.
   */
  @Input()
  baseZIndex = 100
  /**
   * To which element the calendar popup should be appended.
   */
  @Input()
  appendTo?: string = null
  /**
   * Shows the time section.
   */
  @Input()
  showTime: boolean
  /**
   * Shows only the time section.
   */
  @Input()
  timeOnly: boolean
  /**
   * The displayable date format.
   */
  @Input()
  dateFormat = 'd.m. yy'
  /**
   * Hours to change per step.
   */
  @Input()
  stepHour = 1
  /**
   * Minutes to change per step.
   */
  @Input()
  stepMinute = 5
  /**
   * Seconds to change per step.
   */
  @Input()
  stepSecond = 1
  /**
   * The PrimeNG Calendar component.
   */
  @ViewChild('calendar')
  calendar: Calendar

  /**
   * Determines whether the input is read only.
   */
  @Input()
  keyboard = false
  /**
   * Shows the calendar context menu when a user clicks on a field.
   */
  @Input()
  showOnFocus = true
  /**
   * True, when the calendar is opened.
   */
  calendarOpened: boolean
  /**
   * Disables the default 'minDate' error message because the user has his own implementation.
   */
  disableMinDate: boolean
  /**
   * Disables the default 'dateAfter' error message because the user has his own implementation.
   */
  disableDateAfter: boolean
  /**
   * Disables the default 'distance' error message because the user has his own implementation.
   */
  disableDistance: boolean

  constructor() {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit()

    // focuses the calendar field on demand
    if (this.focus) {
      this.calendar.focus = true
    }
  }

  override doFocus(): void {
    this.openCalendar(true)
  }

  /**
   * Initializes the validators of this {@link formFieldName}.
   */
  protected override initValidators(): void {
    super.initValidators()

    // Min Date
    if (this.minDate) {
      this.addValidator(minDate(this.minDate))
    }
  }

  /**
   * Disables the default messages of the validation errors based on the {@link frontendValidations}.
   */
  protected override disableDefaultValidators(): void {
    super.disableDefaultValidators()

    for (const valid of this.frontendValidations) {
      switch (valid.error) {
        case 'minDate':
          this.disableMinDate = true
          break
        case 'dateAfter':
          this.disableDateAfter = true
          break
        case 'distance':
          this.disableDistance = true
      }
    }
  }

  /**
   * - Fires when the calendar is opened and closed.
   * - Updates the {@link calendarOpened} property.
   * - Positions the popup to not overflowing the viewport.
   */
  openCalendar(open: boolean): void {
    this.calendarOpened = open
    if (open) {
      const native = document.getElementsByClassName('p-datepicker')[0]
      const react = native.getBoundingClientRect()
      // push to left if the calendar is overflowing
      if (react.width + react.x > screen.width
        || react.width + react.x > window.innerWidth) {
        native['style'].left = '-230px'
        native['style'].right = '0'
      }
    }
  }
}
