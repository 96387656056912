import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {ApiComponent} from '../../../component/abstract/api.component'
import {BriefProfileResp, ProfileResp, ProfileService} from '../../../service/profile.service'
import {firstValueFrom, Observable} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {NavigationService} from '../../../service/ui/navigation.service'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'
import {OrderManagerListTypeEnum} from '../order-manager-list-type.enum'
import {growAnimation} from '../../../animation/grow.animation'

/**
 * Represent profiles of currently logged user with unresolved orders.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-profile-order-manager-attention',
  templateUrl: './profile-order-manager-attention.component.html',
  styleUrls: ['./profile-order-manager-attention.component.scss']
})
export class ProfileOrderManagerAttentionComponent extends ApiComponent implements OnInit {
  /**
   * Represents the current previewing profile data.
   */
  @Input()
  data: ProfileResp
  /**
   * Represent if the one of the currently logged user profiles has unresolved orders (profile / author)-
   */
  @Output()
  hasUnresolvedOrders = new EventEmitter<boolean>()
  /**
   * Shows the sidebar.
   */
  @Input()
  show: boolean
  /**
   * Emits the {@link show} changes.
   */
  @Output()
  showChange = new EventEmitter<boolean>()
  /**
   * Represents all profiles associated with the user account.
   */
  profilesUnresolvedOrders: Page<BriefProfileResp> = newEmptyPage()

  constructor(
    private navigation: NavigationService,
    private profileService: ProfileService) {
    super()
  }

  ngOnInit(): void {
    this.call(async () => {
      const items = await firstValueFrom(this.getUserProfilesWithOrders(0))
      this.profilesUnresolvedOrders = this.filterCurrentProfile(items)
      this.profilesUnresolvedOrders.nextPage++

      // Emits hasUnresolvedOrders to resolve if the one of the currently logged user profiles has unresolved orders.
      this.hasUnresolvedOrders.emit(this.profilesUnresolvedOrders.content.length > 0)
    })
  }

  /**
   * Filters all profiles that are the same as the {@link data}.
   */
  filterCurrentProfile(page: Page<BriefProfileResp>): Page<BriefProfileResp> {
    page.content = page.content.filter(it => it.profileId !== this.data.profileId)
    return page
  }

  /**
   * Tries to switch to the desired {@link profile}.
   */
  onItemClick(profile: BriefProfileResp): void {
    let listType: OrderManagerListTypeEnum
    // Awaiting Payment - Authors
    if (hasFeatures(profile.profileType, Feature.ORDER_PROFILE)) {
      listType = OrderManagerListTypeEnum.AWAITING_PAYMENT

      // Pending - Profiles
    } else if (hasFeatures(profile.profileType, Feature.BE_ORDERED)) {
      listType = OrderManagerListTypeEnum.PENDING
    }

    // change URL
    this.navigation.toProfileBookings(profile.charId, listType)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  /**
   * Calls the server API to fetch all user profiles that not includes EVENTs, ENTERPRISEs AND USERs.
   */
  getUserProfilesWithOrders(pageNum: number): Observable<Page<BriefProfileResp>> {
    return this.unwrap(this.profileService.callGetWithOrdersProfiles({page: pageNum}))
  }
}
