import {Pipe, PipeTransform} from '@angular/core'
import {defaultAvatar} from '../utils/asset.utils'
import {BriefProfileResp, ProfileResp} from '../service/profile.service'

@Pipe({
  name: 'avatar',
  standalone: true
})
export class AvatarPipe implements PipeTransform {
  transform(profile: ProfileResp | BriefProfileResp, fileName: string): string {
    if (!profile) {
      return ''
    }

    const avatar = profile?.avatar
    if (avatar && avatar !== 'null' && !avatar.includes('default')) {
      if (avatar.endsWith('avatar') || avatar.endsWith('avatar/')) {
        return `${avatar}/${fileName}`
      }
      return avatar
    } else {
      return defaultAvatar(profile.profileType)
    }
  }
}
