import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {Endpoint} from '../common/endpoints'
import {CropperPosition} from 'ngx-image-cropper'
import {BaseResp} from '../rest/base-resp'
import {ApiService} from './api.service'

/**
 * The service contains all API calls associated with avatars.
 */
@Injectable({
  providedIn: 'root'
})
export class AvatarService extends ApiService {

  constructor() {
    super()
  }

  /**
   * Call the API to get the original image for edit purposes.
   */
  callGetAvatarForEdit(): Observable<BaseResp<GetAvatarEditResp | null>> {
    return this.post(Endpoint.AVATAR_GET_EDIT_URL, null)
  }

  /**
   * Call the API to upload a new avatar image.
   *
   * @param file: Needs to be an image file!
   * @param imageUuid: The uuid of a newly uploaded image.
   * @param profileId: The id of a profile.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callPostNewAvatar(file: File, imageUuid: string, profileId: number): Observable<BaseResp<boolean>> {
    const formData: FormData = new FormData()
    formData.append('image', file)
    formData.append('imageUuid', imageUuid)
    return this.post(Endpoint.AVATAR_NEW_URL, formData, this.getFormHeaders())
  }

  /**
   * - Call the API for the purpose of cropping the image.
   * - Returns the new URL of the image.
   */
  callPostCropAvatar(req: CropAvatarReq): Observable<BaseResp<string | null>> {
    return this.post(Endpoint.AVATAR_CROP_URL, req)
  }

  /**
   * Call the API to remove the avatar of a profile.
   */
  callRemoveAvatar(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.AVATAR_REMOVE_URL, null)
  }
}

/**
 * The response from the {@link callGetAvatarForEdit} API call.
 */
export interface GetAvatarEditResp {
  imageUuid: string
  url: string
  cropPosition: CropperPosition
}

/**
 * The request object for the {@link callPostCropAvatar} API call.
 */
export interface CropAvatarReq {
  imageUuid: string
  cropperPosition: CropperPosition
}
