import {Component, Input} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {Observable} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {BriefProfileOrderSearchResp, ProfileOrderService} from '../../../service/profile-order.service'
import {NavigationService} from '../../../service/ui/navigation.service'
import {ProfileResp} from '../../../service/profile.service'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {SearchComponent} from '../../common/search/search.component'
import {PricePipe} from '../../../pipe/price.pipe'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {VarDirective} from '../../../directive/var.directive'
import {ProfileProfessionsComponent} from '../../profile/profile-professions/profile-professions.component'
import {NgIf} from '@angular/common'
import {SharedModule} from 'primeng/api'
import {RippleModule} from 'primeng/ripple'
import {RouterLink} from '@angular/router'

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
  imports: [
    DialogComponent,
    SearchComponent,
    PricePipe,
    AvatarComponent,
    VarDirective,
    ProfileProfessionsComponent,
    NgIf,
    SharedModule,
    RippleModule,
    RouterLink
  ],
  standalone: true
})
export class OrderSearchComponent extends EditableComponent {

  /**
   * The current logged profile.
   */
  @Input()
  profileData: ProfileResp
  /**
   * Contains all searched profile orders from the {@link callSearchProfileOrders} request.
   */
  searchPage = newEmptyPage<BriefProfileOrderSearchResp>()

  constructor(
    private profileOrderService: ProfileOrderService,
    public navigation: NavigationService) {
    super()
  }

  /**
   * Calls the server API to search for profile orders by the {@link typedInput}.
   */
  callSearchProfileOrders(pageNum: number, typedInput: string): Observable<Page<BriefProfileOrderSearchResp>> {
    return this.unwrap(this.profileOrderService.callSearchProfileOrder({
      input: typedInput,
      page: pageNum
    }))
  }
}
