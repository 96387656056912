import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {ApiService} from './api.service'
import {Injectable} from '@angular/core'
import {Birthdate} from './user.service'

@Injectable({
  providedIn: 'root'
})
export class AddressService extends ApiService {

  constructor() {
    super()
  }

  callUpdateProfileAddress(req: AddressReq): Observable<BaseResp<AddressResp>> {
    return this.post(Endpoint.PROFILE_UPDATE_ADDRESS_URL, req)
  }

  callDeleteProfileAddress(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_DELETE_ADDRESS_URL, null)
  }

  callUpdateStripeLegalEntity(req: UpdateLegalEntityReq): Observable<BaseResp<AddressResp>> {
    return this.post(Endpoint.STRIPE_UPDATE_PROFILE_LEGAL_ENTITY, req)
  }
}

/**
 * The abstract definition of address requests and responses.
 */
interface AbstractAddress {
  id?: number
  lat: number
  lng: number

  line1: string
  line2?: string
  city: string
  postalCode: string
  state: string
  country: string
  name?: string
}

/**
 * - The postal information request that can be used as an invoicing address.
 * - must include name (person/company) and optionally legal entity information (company reg.number/taxId).
 */
export interface PostalReq extends AbstractAddress {
  id?: number
  lat: number
  lng: number

  line1: string
  line2?: string
  city: string
  postalCode: string
  state: string
  country: string
  name: string
  legalEntity?: LegalEntityRest
  birthDate?: Birthdate
  /**
   * Defines whether this address should be saved between invoicing addresses.
   */
  saveAddress?: boolean
}

/**
 * - The brief address information without any special need.
 * - This address cannot be used as an invoicing address. (Reach for {@link PostalReq} instead)
 */
export interface AddressReq extends AbstractAddress {
  lat: number
  lng: number

  line1: string
  line2?: string
  city: string
  postalCode: string
  state: string
  country: string
}

/**
 * General response of the address object.
 */
export interface AddressResp extends AbstractAddress {
  id: number
  lat: number
  lng: number

  line1: string
  line2?: string
  city: string
  postalCode: string
  state: string
  country: string
  name?: string
  legalEntity?: LegalEntityRest
  birthDate?: Birthdate
}

/**
 * Contains information about legal entity such as reg. number of a company or tax and vat ids.
 */
export interface LegalEntityRest {
  taxId: string
  registrationNumber?: string
  vatId?: string
  individual: boolean
}

export interface UpdateLegalEntityReq {
  taxId: string
  registrationNumber?: string
  vatId?: string
}
