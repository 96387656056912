<p-sidebar [(visible)]="display"
           [modal]="false"
           [dismissible]="false"
           position="bottom">
  <ng-template pTemplate="headless">
    <div class="p-d-flex p-justify-start p-ai-center p-p-2 w-100">
      <app-button type="icon"
                  icon="fa-solid fa-xmark"
                  styleClass="p-button-icon-only-smaller"
                  (clicked)="closeDialog()">
      </app-button>
      <img ngSrc="assets/logo/favicon.png"
           alt="Umevia logo"
           height="32"
           width="32"
           class="p-mr-2"/>

      <ng-container *ngIf="displayGuide; then guide; else message"></ng-container>
    </div>
  </ng-template>
</p-sidebar>

<!-- Message with install button -->
<ng-template #message>
  <div class="p-d-flex p-jc-between p-ai-center"
       style="width: inherit;">
    <span i18n>Install the app for better user experience and notifications.</span>

    <app-button type="secondary"
                label="Install"
                styleClass="bg-white p-ml-2"
                i18n-label
                (clicked)="installApplication()">
    </app-button>
  </div>
</ng-template>

<!-- Template for guides -->
<ng-template #guide>
  <div @grow
       class="p-d-flex p-flex-column">
    <ng-container *ngIf="pwaRequest.device.os === 'ios'; then guideIOS; else guideAndroid"></ng-container>
  </div>
</ng-template>

<!-- iOS specific guide -->
<ng-template #guideIOS>
  <ol *ngVar="pwaRequest.device.browser as browser">
    <li *ngIf="browser !== 'safari' || browser !== 'chrome'" i18n>Click on <b>"Menu"</b><i
      class="fa-solid fa-bars p-ml-1"></i>.
    </li>
    <li i18n>Click on <b>"Share"</b><i class="fa-solid fa-arrow-up-from-bracket p-ml-1"></i>.</li>
    <li i18n>Click on <b>"Add to screen"</b><i class="fa-regular fa-square-plus p-ml-1"></i>.</li>
    <li i18n>Set up own name and click <b>"Add"</b>.</li>
  </ol>
</ng-template>

<!-- Android specific guide -->
<ng-template #guideAndroid>
  <ol *ngVar="pwaRequest.device.browser as browser">
    <li i18n>Click on <b>"Menu"</b>.</li>
    <li i18n>Click on <b>{{ trans[browser] }}</b>.</li>
    <li *ngIf="browser === 'edge' || browser === 'samsung'" i18n>Click on <b>"Home screen"</b>.</li>
    <li i18n>In pop-up click on <b>"Install"</b>.</li>
  </ol>
</ng-template>
