import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'count',
  standalone: true
})
export class CountPipe implements PipeTransform {
  transform(count: number): string {
    // Nine Zeroes for Billions
    if (Math.abs(count) >= 1.0e+9) {
      return (Math.abs(count) / 1.0e+9).toFixed(1) + 'B'

      // Six Zeroes for Millions
    } else if (Math.abs(count) >= 1.0e+6) {
      return (Math.abs(count) / 1.0e+6).toFixed(1) + 'M'

      // Three Zeroes for Thousands
    } else if (Math.abs(count) >= 1.0e+3) {
      return (Math.abs(count) / 1.0e+3).toFixed(1) + 'k'

    } else {
      return Math.abs(count) + ''
    }
  }
}
