import {Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {BreadcrumbModule} from 'primeng/breadcrumb'
import {MenuItem} from 'primeng/api'
import {NgIf} from '@angular/common'
import {AbstractComponent} from '../../abstract/abstract.component'
import {ScreenSize} from '../../../utils/device.utils'

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    BreadcrumbModule,
    NgIf
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent extends AbstractComponent implements OnChanges {
  /**
   * Represents values in the breadcrumb navigation.
   */
  @Input({required: true})
  values: MenuItem[]

  /**
   * When the device is smaller than  {@link ScreenSize.SM} only the last (this number) items will be visible.
   */
  @Input()
  smallDevicesItemsCount: number = 2

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.values?.currentValue && !this.isScreenOf(ScreenSize.SM) && this.values.length > this.smallDevicesItemsCount) {
      this.values.splice(0, (this.values.length - this.smallDevicesItemsCount))
    }
  }
}
