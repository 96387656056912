<app-search [component]="this"
            [length]="{max: Restrictions.MAX_PROFILE_SEARCH_LENGTH}"
            placeholder="Search profiles by name or nickname"
            i18n-placeholder
            [searchFunction]="callSearchBriefProfiles.bind(this)"
            styleClass="profile-search"
            overlayClass="profile-search-overlay"
            [searchFocus]="true"
            [(items)]="searchItems"
            (focusLost)="onFocusLost($event)"
            [searchDelay]="500"
            [resultAsOverlay]="true"
            [overlayEmptyVisible]="true"
            [itemHeight]="70"
            [maxScrollHeight]="70*8">
  <ng-template pTemplate="item" let-profile>
    <!--Item-->
    <div class="p-d-flex p-ai-center visible-item c-pointer rounded-medium p-py-2 p-px-3"
         pRipple
         (click)="onProfileSelected(profile)">

      <!--Avatar-->
      <div class="p-d-flex p-ai-center p-mr-2">
        <app-avatar [profile]="profile"
                    [sizePx]="50"
                    [redirect]="redirect"></app-avatar>
      </div>


      <div class="w-100 p-d-flex p-jc-between">
        <!--Names-->
        <div class="p-mr-2">
          <app-profile-names [profile]="profile"
                             [size]="2"
                             [displayNameLinesCount]="1"
                             [redirect]="redirect"
          ></app-profile-names>
        </div>

        <div class="p-d-flex">
          <!--Professions-->
          <div class="professions p-py-2 p-px-3 p-jc-end rounded-medium" *ngIf="profile.professions.length > 0">
            <app-profile-professions [data]="profile" type="text" lineCount="l-count-2"
                                     styleClass="professions-content"></app-profile-professions>
          </div>

          <!--Address-->
          <div class="address p-py-2 p-px-3 p-jc-end rounded-medium p-ml-2"
               *ngIf="profile.address && isScreenOf(Screen.LG)">
            <div class="p-d-flex">
              <i class="fa-solid fa-globe-europe p-mt-1 p-mr-2"></i>
              <div>
                <p class="l-count-1">{{profile.address?.city}}</p>
                <p class="l-count-1">{{profile.address?.country}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!--Skeleton templates-->
  <ng-template pTemplate="skeleton">
    <div class="result skeleton rounded-medium p-p-2">
      <div class="p-d-flex p-mt-4 p-ml-2" *ngFor="let i of [1,2,3]">
        <p-skeleton
          shape="circle"
          size="3rem"
          styleClass="p-mr-2 p-mb-2"
        ></p-skeleton>

        <div class="p-d-flex p-flex-column">
          <p-skeleton width="7rem" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
          <p-skeleton width="10rem" height="1rem"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-template>

  <!--No content-->
  <ng-template pTemplate="noContent">
    <div class="p-d-flex p-jc-center p-ai-center p-my-3 p-px-3 unselectable">
      <i class="fa-solid fa-heart-crack p-mr-2"></i>
      <div i18n>No profiles found</div>
    </div>

    <!--Recommendation for filters-->
    <app-hint styleClass="p-mt-2" i18n>
      If you are looking for a specific category of art, genre, or profession,
      try <a class="text-link filters"
             [routerLink]="navigation.urlProfileCatalog()"
             [fragment]="NS.PROFILE_CATALOG_FILTERS">filters</a>.
    </app-hint>
  </ng-template>
</app-search>
