import {Inject, Injectable} from '@angular/core'
import {environment} from '../../../environments/environment'
import {StorageService} from '../storage.service'
import {DOCUMENT} from '@angular/common'

/**
 * Service for controlling collection of analytic data, mainly heatmaps through Clarity software.
 */
@Injectable({
  providedIn: 'root'
})
export class ClarityAnalyticsService {

  /**
   * Contains the current state of the Microsoft Clarity tracking.
   */
  private analyticsEnabled: boolean

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService) {
  }

  /**
   * Enables or disables Clarity analytics based on provided parameter.
   */
  enableClarity(enable: boolean): void {
    // Prevents multiple same updates
    if (enable === this.analyticsEnabled) {
      return
    }
    this.analyticsEnabled = enable

    if (this.analyticsEnabled) {
      this.loadClarityScript()
    } else {
      this.deleteClarityAnalytics()
    }
  }

  /**
   * Loads Microsoft Clarity to application.
   */
  private async loadClarityScript(): Promise<void> {
    const clarityScript = this.document.createElement('script')
    clarityScript.id = 'clarityAnalytics'
    clarityScript.text = `(function(c, l, a, r, i, t, y) {
        c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r);
        t.async = 1;
        t.src="https://www.clarity.ms/tag/"+i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t,y);
    }) (window, document, "clarity", "script", "${environment.clarity.appId}");`
    this.document.head.append(clarityScript)
  }

  /**
   * Deletes all Microsoft Clarity related cookies from storage.
   */
  async deleteClarityAnalytics(): Promise<void> {
    // @ts-expect-error Clarity is not defined
    if (this.document.defaultView.clarity) {
      // @ts-expect-error Clarity is not defined
      this.document.defaultView.clarity('stop')
    }
    this.storageService.deleteCookie('_clsk')
    this.storageService.deleteCookie('_clck')
  }
}
