import {Component, EventEmitter, Output} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {BriefProfileResp} from '../../../service/profile.service'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {NgIf} from '@angular/common'
import {MapSearchComponent} from '../map-search/map-search.component'

@Component({
  selector: 'app-map-search-dialog',
  templateUrl: './map-search-dialog.component.html',
  styleUrls: ['./map-search-dialog.component.scss'],
  imports: [
    DialogComponent,
    NgIf,
    MapSearchComponent
  ],
  standalone: true
})
export class MapSearchDialogComponent extends EditableComponent {

  /**
   * Emits a profile selected by a user.
   */
  @Output()
  profile = new EventEmitter<BriefProfileResp>()

  /**
   * Emits an address selected by a user.
   */
  @Output()
  address = new EventEmitter<any>()

  constructor() {
    super()
  }

  onComponentSave(): void {
  }
}
