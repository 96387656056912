<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            type="info"
            header="Switch to profile"
            i18n-header
            successMessage="Switched successfully."
            i18n-successMessage
            discardLabel="Close"
            i18n-discardLabel
            url="switch-profile"
            [allowSaveOption]="false"
            (discard)="closeEditComponent()">

  <div class="p-py-3">

    <p-dataView [value]="profiles">
      <ng-template let-profiles pTemplate="list">
        <div class="p-grid p-p-0">
          <div class="p-col-12 p-p-0" *ngFor="let profile of profiles">
            <div class="p-d-flex p-ai-center w-100 c-pointer p-py-3" pRipple
                 [disable]="loading || saving" (enabledClick)="profileSelected(profile)">
              <!--Avatar-->
              <div class="p-mr-3">
                <app-avatar [profile]="profile"
                            [showTooltip]="false"
                            [redirect]="false"
                            [sizePx]="70">
                </app-avatar>
              </div>

              <div class="p-d-flex p-jc-between w-100">
                <div class="p-mr-2">
                  <!--Profile names-->
                  <app-profile-names [redirect]="false"
                                     [profile]="profile"
                                     [size]="2"
                  ></app-profile-names>
                </div>

                <!--Selected icon-->
                <div class="p-d-flex p-ai-center p-jc-center p-p-3">
                  <i *ngIf="profile.profileId === currentProfile?.profileId"
                     class="fa-solid fa-check indicator-icon"></i>
                  <i *ngIf="profile.saving && !responseError && !responseFailed"
                     class="fa-solid fa-spin fa-circle-notch indicator-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>

    <!--BE-CHECK-->
    <div class="p-m-2">
      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
        The desired profile was not found.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                              i18n>
        You're not the owner of that profile.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [showAsBox]="true"
                              [check]="ServerMessage.USER_NOT_FOUND"
                              i18n>
        User not registered.
      </app-backend-validation>
    </div>
  </div>
</app-dialog>
