<app-button (clicked)="onChat()"
            *ngIf="canOrder && canBeOrdered"
            i18n-label
            icon="fa-solid fa-paper-plane"
            label="Send a message"
            type="secondary"></app-button>

<app-dialog #infoDialog
            *ngIf="cannotChatDialogVisible"
            [(show)]="cannotChatDialogVisible"
            [allowSaveOption]="false"
            [component]="this"
            header="Sending messages"
            i18n-header
            styleClass="chat-info-dialog"
            type="info"
            url="chat">
  <div class="p-py-2">
    <!--Not logged-->
    <ng-container *ngIf="!currentProfile"
                  [ngTemplateOutletContext]="{item: notLoggedContent, dialog: infoDialog}"
                  [ngTemplateOutlet]="missingContent"/>

    <!--Logged with no order-->
    <ng-container *ngIf="currentProfile && !chatChannel"
                  [ngTemplateOutletContext]="{item: missingOrderContent, dialog: infoDialog}"
                  [ngTemplateOutlet]="missingContent"/>
  </div>
</app-dialog>

<!--Unified dialog content-->
<ng-template #missingContent let-dialog="dialog" let-item="item">
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center info-dialog-content">
    <i [class]="item.icon"></i>

    <div class="p-py-4 p-text-center">{{ item.title }}</div>

    <app-button (clicked)="item.button.fun(dialog)"
                [highlight]="true"
                [icon]="item.button.icon"
                [label]="item.button.label"
                iconPos="right"
                type="primary"></app-button>
  </div>
</ng-template>

<!--Order Chat Dialog-->
<app-profile-order-chat *ngIf="chatVisible && chatChannel"
                        [(show)]="chatVisible"
                        [channel]="chatChannel"
                        [currentProfile]="currentProfile"></app-profile-order-chat>
