import {Component, Input} from '@angular/core'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {NgIf} from '@angular/common'
import {PricePipe} from '../../../pipe/price.pipe'
import {VarDirective} from '../../../directive/var.directive'
import {EditableComponent} from '../../abstract/editable.component'
import {BankInstructions} from '../../../service/stripe.service'
import {IbanFormatPipe} from '../../../pipe/iban'
import {toClipboard} from '../../../utils/navigator.utils'
import {TooltipDirective} from '../../../directive/tooltip.directive'
import {TooltipModule} from 'primeng/tooltip'
import {HintComponent} from '../../common/hint/hint.component'
import {environment} from '../../../../environments/environment'
import {SimulateFundingComponent} from '../simulate-funding/simulate-funding.component'
import {ProfileOrderResp} from '../../../service/profile-order.service'
import {ButtonComponent} from '../../common/button/button.component'

/**
 * Displays bank information to the client.
 */
@Component({
  selector: 'app-bank-instructions',
  standalone: true,
  imports: [
    DialogComponent,
    NgIf,
    PricePipe,
    VarDirective,
    IbanFormatPipe,
    TooltipDirective,
    TooltipModule,
    HintComponent,
    SimulateFundingComponent,
    ButtonComponent
  ],
  templateUrl: './bank-instructions.component.html',
  styleUrl: './bank-instructions.component.scss'
})
export class BankInstructionsComponent extends EditableComponent {
  /**
   * The currently previewing order.
   */
  @Input()
  order: ProfileOrderResp
  /**
   * The source of information.
   */
  @Input({required: true})
  bankInstructions: BankInstructions
  /**
   * Opens the simulate funding dialog.
   */
  simulateFundingOpened: boolean
  /**
   * Whether a currently running environment is production.
   */
  production = environment.production

  /**
   * - Copies the {@link text} to the clipboard.
   * - Changes the icon of the {@link element} when clicked.
   */
  copy(text: any, element: HTMLElement): void {
    element.classList.remove('fa-copy')
    element.classList.add('fa-check')
    toClipboard(text)
  }
}
