<p-dialog [(visible)]="show"
          [draggable]="false"
          [closable]="true"
          [resizable]="false"
          [closeOnEscape]="false"
          (onHide)="onClose()"
          [breakpoints]="{'768px': '25rem'}"
          styleClass="platform-rating"
          header="Do you like Umevia?"
          i18n-header
          position="bottom"
          [baseZIndex]="100000">
  <div>
    <form [formGroup]="form" class="p-d-flex p-flex-column p-ai-center">
      <p-rating (onRate)="stars = $event.value; send()"
                [cancel]="false"
                iconOnClass="fa-solid fa-star star"
                iconOffClass="fa-regular fa-star star"></p-rating>

      <!-- Text -->
      <app-text-input *ngIf="stars > 0" @grow
                      label="Any ideas on how to improve Umevia?"
                      i18n-label
                      placeholder="Type your notices, improvement tips, or struggles you want to share with us!"
                      i18n-placeholder
                      icon="fa-solid fa-envelope-open-text"
                      [form]="form"
                      formFieldName="text"
                      [focus]="true"
                      [textArea]="true"
                      [rows]="4"
                      [length]="{max: Restrictions.MAX_PLATFORM_RATING_TEXT}"
                      class="w-100"
                      styleClass="p-mt-4 platform-rating-text"></app-text-input>

      <!-- Later -->
      <app-button *ngIf="stars > 0 && !form.value.text"
                  type="text"
                  label="Later"
                  i18n-label
                  icon="fa-solid fa-clock-rotate-left"
                  styleClass="p-mt-2"
                  (clicked)="onClose()"></app-button>

      <!-- Send -->
      <app-button *ngIf="form.value.text && form.valid && !responseSuccess"
                  type="primary"
                  label="Send Feedback"
                  i18n-label
                  icon="fa-solid fa-paper-plane"
                  styleClass="p-mt-2"
                  [loading]="saving || loading"
                  (clicked)="send()"></app-button>
    </form>

    <app-call-response *ngIf="form.getRawValue().text"
                       successMsg="Thank you!"
                       i18n-successMsg
                       successIcon="fa-solid fa-handshake-simple"
                       styleClass="p-mt-2"
                       [component]="this"></app-call-response>

  </div>
</p-dialog>
