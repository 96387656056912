/**
 * Enum of all supported countries.
 */
export enum Country {
  SLOVAKIA = 'SLOVAKIA'
}

/**
 * All supported countries by the platform.
 */
export const SUPPORTED_COUNTRIES: CountryInfo[] = [
  {
    id: Country.SLOVAKIA,
    name: 'Slovakia',
    currency: 'eur',
    code: 'SK',
    flag: 'fi fi-sk'
  }
]

/**
 * Returns a {@link CountryInfo} details of the {@link country}.
 */
export function countryDetailsOf(country: Country): CountryInfo {
  return SUPPORTED_COUNTRIES.find(it => it.id === country)
}

/**
 * Returns a {@link CountryInfo} details of the country {@link code}.
 */
export function countryDetailsOfCode(code: string): CountryInfo {
  return SUPPORTED_COUNTRIES.find(it => it.code === code)
}

/**
 * Contains details of a country.
 */
export interface CountryInfo {
  id: Country
  name: string
  currency: string
  code: string
  flag: string
}
