<div [className]="styleClass">
  <!--Input field-->
  <div #wrapper>
    <!--Placeholder-->
    <div class="placeholder" *ngIf="placeholder && !input.innerHTML" [@fade]="!input.innerHTML">
      {{placeholder}}
    </div>

    <article #input
             class="input-field"
             (contentChange)="validate()"
             (keydown)="onInput($event)"
             (blur)="onBlur()"
             (keyup.enter)="onEnter($event)">
    </article>

    <!--Errors-->
    <div *ngFor="let m of invalidMessages" class="p-my-2">
      <p class="invalid-message">{{m}}</p>
    </div>
  </div>

  <!--Profile search overlay panel-->
  <p-overlayPanel #overlayPanel
                  [appendTo]="wrapper"
                  (onHide)="searchValue = ''">
    <ng-template pTemplate="container">
      <h1 class="overlay-title" i18n>Mention a profile:</h1>


      <div class="p-d-flex p-ai-center p-py-2">
        <i class="fa-solid fa-search p-mr-2"></i>
        <div i18n>Search: &#64;{{searchValue}}</div>
      </div>

      <div *ngIf="loading; else data" [@fade]="loading" class="loading p-d-flex p-jc-center p-py-3">
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      </div>

      <ng-template #data>
        <p-dataView [value]="profiles.content">
          <ng-template let-profile pTemplate="listItem">
            <div class="search-item p-d-flex p-ai-center c-pointer w-100 p-py-2"
                 (click)="selectProfile(profile)">

              <app-avatar [profile]="profile"
                          [redirect]="false"
                          [showTooltip]="false"
                          [sizePx]="40"
              ></app-avatar>

              <div class="p-ml-2 p-d-flex" *ngVar="profile.address as adr">
                <div class="p-mr-2">
                  <h1 class="l-count-1">&#64;{{profile.charId}}</h1>
                  <h2 class="l-count-1">{{profile.displayName}}</h2>
                </div>

                <div *ngIf="adr" class="p-pl-2 address">
                  <p class="l-count-1">{{adr?.line1}}</p>
                  <p class="l-count-1" *ngIf="adr?.line2">{{adr?.line2}}</p>
                  <p class="l-count-1">{{adr?.city}} {{(adr?.country | country)?.name}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </ng-template>
    </ng-template>
  </p-overlayPanel>
</div>

