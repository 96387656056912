import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {BriefProfileResp, OrderableProfileResp, ProfileResp} from '../../../service/profile.service'
import {firstValueFrom, Observable} from 'rxjs'
import {Restrictions} from '../../../common/restrictions'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {BriefProfessionResp, ProfessionService, SearchProfessionReq, UpdateProfileProfessionsReq} from '../../../service/profession.service'
import {ServerMessage} from '../../../common/server-message'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'
import {ValidComponent} from '../../abstract/valid.component'
import {growAnimation} from '../../../animation/grow.animation'
import {NgForOf, NgIf, NgTemplateOutlet, SlicePipe} from '@angular/common'
import {ButtonComponent} from '../../common/button/button.component'
import {NoContentComponent} from '../../common/no-content/no-content.component'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {HintComponent} from '../../common/hint/hint.component'
import {RippleModule} from 'primeng/ripple'
import {SearchComponent} from '../../common/search/search.component'
import {FrontendValidationComponent} from '../../common/frontend-validation/frontend-validation.component'
import {BackendValidationComponent} from '../../common/backend-validation/backend-validation.component'
import {TooltipModule} from 'primeng/tooltip'
import {JoinPipe} from '../../../pipe/join.pipe'
import {SharedModule} from 'primeng/api'
import {InitDirective} from '../../../directive/init.directive'
import {ProfileCompletionService} from '../../../service/profile-completion.service'
import {scrollToIndexOptions} from '../../../utils/scroll.utils'

@Component({
  animations: [growAnimation()],
  selector: 'app-profile-professions',
  templateUrl: './profile-professions.component.html',
  styleUrls: ['./profile-professions.component.scss'],
  imports: [
    NgIf,
    ButtonComponent,
    NgTemplateOutlet,
    NoContentComponent,
    DialogComponent,
    HintComponent,
    NgForOf,
    RippleModule,
    SearchComponent,
    FrontendValidationComponent,
    BackendValidationComponent,
    TooltipModule,
    JoinPipe,
    SlicePipe,
    SharedModule,
    InitDirective
  ],
  standalone: true
})
export class ProfileProfessionsComponent extends ValidComponent implements OnInit, OnChanges {

  @Input()
  data: ProfileResp | OrderableProfileResp | BriefProfileResp
  /**
   * Sets the proper layout of the component.
   */
  @Input()
  type: 'button' | 'text' = 'button'
  /**
   * Additional visuals for the root node.
   */
  @Input()
  styleClass: string
  /**
   * Styleclass for the content line.
   */
  @Input()
  lineCount: string
  /**
   * Determines if the professions are required.
   */
  @Input()
  requiredProf: boolean
  /**
   * Determines if the error occurred, if required property is missing.
   */
  @Input()
  isError: boolean
  /**
   * All professions that a user has assigned to his profile.
   */
  selectedProfessions: BriefProfessionResp[]
  /**
   * - All loaded most-used items.
   * - From these items the user can also pick.
   */
  mostUsedItems: BriefProfessionResp[] = []
  /**
   * Contains all searched professions from the {@link callSearchProfessions} request.
   */
  searchPage = newEmptyPage<BriefProfessionResp>()
  /**
   * Checks whether the component can be visible.
   */
  canHaveProfessions: boolean
  /**
   * The input search field.
   */
  searchInput: SearchComponent<any>

  constructor(
    private professionService: ProfessionService,
    private profileCompletionService: ProfileCompletionService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.canHaveProfessions = hasFeatures(this.data.profileType, Feature.PROFESSIONS)
    }
  }

  showEditComponent(): void {
    // skip when cannot update
    if (!this.editButtonVisible) {
      return
    }

    super.showEditComponent()
    // Init the already assigned professions to the profile.
    this.selectedProfessions = [...this.data.professions]
    this.setValid(this.selectedProfessions.length > 0)
    // call most used items
    this.call(async () => {
      const items = await firstValueFrom(this.unwrap(this.professionService.callGetMostUsed()))
      this.mostUsedItems = this.markAsSelected({totalElements: 0, content: items}).content
    })
  }

  async onComponentSave(): Promise<void> {
    const resp = await firstValueFrom(this.callUpdateProfessions())
    if (resp && this.noServerMessages()) {
      this.data.professions = this.selectedProfessions
      this.profileCompletionService.closeCompletionItemAndCheck()
    }
  }

  /**
   * Scrolls dialog to search.
   */
  scrollToSearch(): void {
    scrollToIndexOptions('search-attribute', 0, {
      block: 'center',
      behavior: 'smooth',
    })
    this.searchInput?.focus(250)
  }

  /**
   * Adds a user-selected profession to the {@link selectedProfessions} array.
   * @param p User-selected profession from the {@link selectedProfessions} array.
   * @param fromSearch Determines, whether this function has been called from the search-result, otherwise from the most-items.
   */
  add(p: BriefProfessionResp, fromSearch: boolean): void {
    for (const item of this.selectedProfessions) {
      if (item.id === p.id) {
        this.remove(p)
        return
      }
    }

    // Return if maximum exceeds
    if (this.selectedProfessions.length >= Restrictions.MAX_PROFESSION_PER_PROFILE) {
      return
    }
    this.selectedProfessions.push(p)
    this.setValid(true)
    p.selected = true

    // Also selects in opposite array of items
    if (fromSearch) {
      this.selectInArray(this.mostUsedItems, p)
    } else {
      this.selectInArray(this.searchPage.content, p)
    }
  }

  /**
   * Removes the user-selected profession from the {@link selectedProfessions} array.
   * @param p User-selected profession from the {@link selectedProfessions} array.
   */
  remove(p: BriefProfessionResp): void {
    this.selectedProfessions = this.selectedProfessions.filter(e => e.id !== p.id)
    // Unselect from global
    this.unselectFromArray(this.searchPage.content, p)
    this.unselectFromArray(this.mostUsedItems, p)
    this.setValid(this.selectedProfessions.length > 0)
  }

  /**
   * - This function is called before the {@link searchPage} gets changed.
   * - Marks all {@link selectedProfessions} in the {@link page}.
   */
  markAsSelected(page: Page<BriefProfessionResp>): Page<BriefProfessionResp> {
    for (const p of page.content) {
      p.selected = !!this.selectedProfessions.find(it => it.id === p.id)
    }
    return page
  }

  /**
   * Constructs a request from the given formData and calls the API.
   */
  private callUpdateProfessions(): Observable<boolean> {
    const req: UpdateProfileProfessionsReq = {
      ids: this.selectedProfessions.map(it => it.id)
    }
    return this.unwrap(this.professionService.callUpdateProfileProfessions(req))
  }

  /**
   * Calls the server API to search for a profession.
   */
  callSearchProfessions(pageNum: number, input: string): Observable<Page<BriefProfessionResp>> {
    const req: SearchProfessionReq = {
      name: input.trim(),
      page: pageNum
    }
    return this.unwrap(this.professionService.callSearchProfession(req))
  }

  /**
   * Returns the missing profession when the {@link ServerMessage.PROFESSION_NOT_FOUND} has occurred.
   */
  returnMissingProfession(): BriefProfessionResp {
    const id = this.obtainFirstDescription(ServerMessage.PROFESSION_NOT_FOUND)
    return this.selectedProfessions.find(it => it.id === id)
  }

  /**
   * Selects a {@link p}rofession in the {@link array}.
   */
  private selectInArray(array: BriefProfessionResp[], p: BriefProfessionResp): void {
    const selectedProfession = array.filter(it => it.id === p.id)[0]
    if (selectedProfession) {
      selectedProfession.selected = true
    }
  }

  /**
   * Unselects a {@link p}rofession from the {@link array}.
   */
  private unselectFromArray(array: BriefProfessionResp[], p: BriefProfessionResp): void {
    for (const global of array) {
      if (global.id === p.id) {
        global.selected = undefined
        return
      }
    }
  }
}
