import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core'
import {Acceptance} from '../../../../common/acceptance'
import {BriefProfileOrderResp, ProfileOrderResp} from '../../../../service/profile-order.service'
import {BriefProfileResp} from '../../../../service/profile.service'

@Component({
  selector: 'app-profile-order-status-badge',
  templateUrl: './profile-order-status-badge.component.html',
  styleUrl: './profile-order-status-badge.component.scss'
})
export class ProfileOrderStatusBadgeComponent implements OnChanges {
  /**
   * A source of information for this component.
   */
  @Input({required: true})
  order: BriefProfileOrderResp | ProfileOrderResp
  /**
   * A currently logged profile.
   */
  @Input({required: true})
  currentProfile: BriefProfileResp
  /**
   * Emits when the {@link order} gets changed.
   */
  @Output()
  statusChange = new EventEmitter<OrderStatusBadge>()
  /**
   * Emits when the order is partially paid and the user clicked on that label.
   */
  @Output()
  partiallyPaidClick = new EventEmitter<any>()
  /**
   * Defines whether the curren logged {@link profileData} is the author of the {@link order}.
   */
  isAuthor: boolean
  /**
   * A current status to display.
   */
  status: OrderStatusBadge
  /**
   * If relevant, a current reason of expiration the order.
   */
  expirationReason: string

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.order?.currentValue || changes.currentProfile?.currentValue) && this.order && this.currentProfile) {
      this.isAuthor = this.currentProfile.profileId === this.order.author.profileId
      this.initStatus()
    }
  }

  /**
   * Initializes status properties
   */
  private initStatus(): void {
    const pAcc = this.order.profileAcceptance
    const aAcc = this.order.authorAcceptance
    // Submitted
    if (pAcc === Acceptance.PENDING && aAcc === Acceptance.PENDING) {
      if (this.isAuthor) {
        this.expirationReason = $localize`Time by which the artist have to respond or the order will expire.`
      } else {
        this.expirationReason = $localize`Time by which you have to respond or the order will expire.`
      }
      this.setStatus({
        icon: 'fa-solid fa-clock',
        classSuffix: 'orange',
        title: $localize`:{Order submitted}:Submitted`,
        description: (this.isAuthor) ? $localize`The order awaits the artist's response.` : $localize`The order awaits your response.`
      })
      return
    }

    // Awaiting payment
    if (pAcc === Acceptance.ACCEPTED && aAcc === Acceptance.PENDING) {
      if (this.isAuthor) {
        this.expirationReason = $localize`Time by which you have to pay for the order or the order will expire.`
      } else {
        this.expirationReason = $localize`Time by which the customer have to pay for the order or the order will expire.`
      }
      this.setStatus(this.status = {
        icon: 'fa-solid fa-credit-card',
        classSuffix: 'blue',
        title: $localize`Awaiting payment`,
        description: (this.isAuthor) ? $localize`The order awaits your payment.` : $localize`The order awaits customer's payment.`
      })
      return
    }

    // Accepted
    if (pAcc === Acceptance.ACCEPTED && aAcc === Acceptance.ACCEPTED) {

      // Finished
      if (this.order.finished) {
        this.setStatus({
          icon: 'fa-solid fa-check',
          classSuffix: 'green',
          title: $localize`:{Order finished}:Finished`,
          description: $localize`The order has been successfully finished.`
        })
        return
      }

      // Paid
      this.setStatus({
        icon: 'fa-solid fa-check',
        classSuffix: 'green',
        title: $localize`:{Order paid}:Paid`,
        description: $localize`The order has been paid in full.`
      })
      return
    }

    // Rejected
    if (pAcc === Acceptance.REJECTED || aAcc === Acceptance.REJECTED) {

      // In a dispute by author
      if (this.order.dispute?.freeze === true) {
        this.setStatus({
          icon: 'fa-solid fa-heart-crack',
          classSuffix: 'red',
          title: $localize`In Dispute`,
          description: (this.isAuthor) ? $localize`In a dispute process - you stated, that the artist did not come.` : $localize`In a dispute process - The customer stated, that you did not come.`
        })
        return
      }

      // Dispute resolved
      if (this.order.dispute?.freeze === false) {
        let desc
        // Profile canceled
        if (this.order.dispute.penaltyProfile === this.order.profile.profileId) {
          desc = (this.isAuthor) ? $localize`The artist canceled this order.` : $localize`You canceled this order.`
        } else { // Author canceled
          desc = (this.isAuthor) ? $localize`You canceled this order.` : $localize`The artist canceled this order.`
        }

        this.setStatus({
          icon: 'fa-solid fa-heart-crack',
          classSuffix: 'red',
          title: $localize`:{Order canceled}:Canceled`,
          description: desc
        })
        return
      }

      let desc
      // Profile reject
      if (pAcc === Acceptance.REJECTED) {
        desc = (this.isAuthor) ? $localize`The artist did not accept your order.` : $localize`You did not accept the customer's order.`
      } else { // Author reject
        desc = (this.isAuthor) ? $localize`You decided to reject this order.` : $localize`Customer decided to reject this order.`
      }
      this.setStatus({
        icon: 'fa-solid fa-heart-crack',
        classSuffix: 'red',
        title: $localize`:{Order rejected}:Rejected`,
        description: desc
      })
      return
    }
  }

  /**
   * Updates the {@link status} and emits changes into {@link statusChange} emitter.
   */
  private setStatus(status: OrderStatusBadge): void {
    this.status = status
    this.statusChange.emit(status)
  }
}

/**
 * Defines order state badge layout.
 */
export interface OrderStatusBadge {
  icon: string
  classSuffix: string
  title: string
  description: string
}
