<div *ngIf="!inMapBubble && !inMapCarousel; else bubble"><!--Loading skeleton-->
  <ng-template [ngIf]="loading">
    <p-skeleton styleClass="p-col-12 p-md-6 p-lg-4 p-p-0 p-mb-2"
                height="1rem">
    </p-skeleton>
  </ng-template>

  <!--No content-->
  <ng-template [ngIf]="!loading && displayableOpeningHours.length === 0">
    <app-no-content i18n>
      No opening hours information yet.
    </app-no-content>
  </ng-template>

  <div *ngIf="!loading && displayableOpeningHours.length !== 0"
       class="p-col-12 p-p-0 p-mb-2">
    <!--Today's Opening Hours-->
    <div *ngIf="!showDetails; else all"
         class="c-pointer"
         (click)="showDetails = true"
         pRipple>

      <table class="rounded-medium" *ngIf="todayOpeningHours || todayOpeningHours.restricted">
        <tr>
          <td class="p-d-flex p-ai-center p-pr-2"
              [class.open]="!todayOpeningHours.restricted && (todayOpeningHours.nonstop || (todayOpeningHours.isNowOpen && !todayOpeningHours.closed))"
              [class.closed]="!todayOpeningHours.restricted && (!todayOpeningHours.nonstop && (!todayOpeningHours.isNowOpen || todayOpeningHours.closed))"
              [class.restriction]="todayOpeningHours.restricted">

            <i class="fa-solid p-mr-2"
               [class.fa-check]="!todayOpeningHours.restricted && (todayOpeningHours.nonstop || (todayOpeningHours.isNowOpen && !todayOpeningHours.closed))"
               [class.fa-location-pin-lock]="!todayOpeningHours.restricted && (!todayOpeningHours.nonstop && (!todayOpeningHours.isNowOpen || todayOpeningHours.closed))"
               [class.fa-bell-concierge]="todayOpeningHours.restricted"></i>

            <h3>{{todayOpeningHours.dayName}}:</h3>
          </td>

          <ng-template [ngIf]="!todayOpeningHours.nonstop">
            <!--No information-->
            <ng-template [ngIf]="(!todayOpeningHours.start || !todayOpeningHours.end) && !todayOpeningHours.closed">
              <td colspan="3" class="no-info" i18n>No information</td>
            </ng-template>

            <!--From - To-->
            <ng-template [ngIf]="todayOpeningHours.start && !todayOpeningHours.closed">
              <!--Start-->
              <td [class.open]="todayOpeningHours.isNowOpen"
                  [class.closed]="!todayOpeningHours.isNowOpen"
                  class="p-pr-2">{{todayOpeningHours.start | date: 'H:mm'}}</td>

              <!-- to -->
              <td [class.open]="todayOpeningHours.isNowOpen"
                  [class.closed]="!todayOpeningHours.isNowOpen"
                  class="p-text-center p-pr-2">-
              </td>

              <!--End-->
              <td [class.open]="todayOpeningHours.isNowOpen"
                  [class.closed]="!todayOpeningHours.isNowOpen">{{todayOpeningHours.end | date: 'H:mm'}}</td>
            </ng-template>

            <!--Closed-->
            <ng-template [ngIf]="todayOpeningHours.closed">
              <td colspan="3" class="closed" i18n>Closed</td>
            </ng-template>
          </ng-template>

          <!--Non-Stop-->
          <ng-template [ngIf]="todayOpeningHours.nonstop">
            <td colspan="3" class="open" i18n>Non-Stop</td>
          </ng-template>
        </tr>
      </table>
      <p *ngIf="todayOpeningHours?.restrictionMessage"
         class="restriction p-text-italic l-count-1">{{todayOpeningHours.restrictionMessage}}</p>
    </div>

    <!--All Days Opening Hours-->
    <ng-template #all>
      <table [@fade]="showDetails" class="rounded-medium table-detail">
        <tr *ngFor="let item of displayableOpeningHours">
          <td class="p-d-flex p-ai-center p-pr-2"
              [class.open]="item.nonstop || (item.start && !item.closed)"
              [class.closed]="!item.nonstop && item.closed">
            <i class="fa-solid p-mr-2"
               [class.fa-check]="item.nonstop || (item.start && !item.closed)"
               [class.fa-location-pin-lock]="!item.nonstop && item.closed"
               [class.fa-comment-slash]="!item.nonstop && !item.start && !item.closed"></i>
            <h3>{{item.dayName}}:</h3>
          </td>

          <ng-template [ngIf]="!item.nonstop">
            <!--No information-->
            <ng-template [ngIf]="!item.start && !item.closed">
              <td colspan="3" class="no-info" i18n>No information</td>
            </ng-template>

            <!--From - To-->
            <ng-template [ngIf]="item.start && !item.closed">
              <td class="open p-pr-2">{{item.start | date: 'H:mm'}}</td>
              <td class="open p-text-center p-pr-2">-</td>
              <td class="open">{{item.end | date: 'H:mm'}}</td>
            </ng-template>

            <ng-template [ngIf]="item.closed">
              <td colspan="3" class="closed" i18n>Closed</td>
            </ng-template>
          </ng-template>

          <!--Non-Stop-->
          <ng-template [ngIf]="item.nonstop">
            <td colspan="3" class="open" i18n>Non-Stop</td>
          </ng-template>
        </tr>
      </table>

      <!--Hide details-->
      <div class="p-d-flex p-jc-center">
        <app-button type="secondary"
                    label="Collapse"
                    i18n-label
                    (clicked)="showDetails = false"></app-button>
      </div>
    </ng-template>
  </div>

  <!--Edit button-->
  <div *ngIf="editButtonVisible" class="p-d-flex p-my-3">
    <app-button *ngIf="data"
                type="edit"
                styleClass="p-mr-2"
                pTooltip="Edit opening hours"
                i18n-pTooltip
                tooltipPosition="top"
                (clicked)="showEditComponent()"></app-button>

    <app-button *ngIf="openingHours && data"
                label="Exceptions"
                i18n-label
                styleClass="p-button-rounded p-button-help p-button-outlined"
                pTooltip="Edit exceptions"
                i18n-pTooltip
                tooltipPosition="top"
                (clicked)="editRestrictionComponentVisible = true"></app-button>
  </div>

  <!--Edit component dialog-->
  <app-dialog *ngIf="editComponentVisible"
              [(show)]="editComponentVisible"
              [component]="this"
              header="Edit your opening hours"
              i18n-header

              [save]="onComponentSave.bind(this)"
              (discard)="closeEditComponent()">

    <div *ngIf="data; else skeletonEditDialog">
      <form [formGroup]="form" class="edit-form p-py-3">

        <h4 i18n>Non-Stop</h4>
        <div class="p-mb-3">
          <p-checkbox label="Non-Stop 24/7"
                      i18n-label
                      binary="true"
                      formControlName="nonstop"
                      (ngModelChange)="disableFieldsInEditForm()">
          </p-checkbox>
        </div>

        <div *ngIf="!form.controls.nonstop.value" [@fade]="form.controls.nonstop.value">
          <h4 i18n>Monday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="monStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="monEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.monEnd.value && (form.controls.monStart.value >= form.controls.monEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="monClosed"
                          (ngModelChange)="disableDayTimes('mon')">
              </p-checkbox>
            </div>
          </div>

          <h4 i18n>Tuesday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="tueStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="tueEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.tueEnd.value && (form.controls.tueStart.value >= form.controls.tueEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="tueClosed"
                          (ngModelChange)="disableDayTimes('tue')">
              </p-checkbox>
            </div>
          </div>


          <h4 i18n>Wednesday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="wedStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="wedEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.wedEnd.value && (form.controls.wedStart.value >= form.controls.wedEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="wedClosed"
                          (ngModelChange)="disableDayTimes('wed')">
              </p-checkbox>
            </div>
          </div>


          <h4 i18n>Thursday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="thuStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="thuEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.thuEnd.value && (form.controls.thuStart.value >= form.controls.thuEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="thuClosed"
                          (ngModelChange)="disableDayTimes('thu')">
              </p-checkbox>
            </div>
          </div>


          <h4 i18n>Friday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="friStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="friEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.friEnd.value && (form.controls.friStart.value >= form.controls.friEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="friClosed"
                          (ngModelChange)="disableDayTimes('fri')">
              </p-checkbox>
            </div>
          </div>


          <h4 i18n>Saturday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="satStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="satEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.satEnd.value && (form.controls.satStart.value >= form.controls.satEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="satClosed"
                          (ngModelChange)="disableDayTimes('sat')">
              </p-checkbox>
            </div>
          </div>

          <h4 i18n>Sunday</h4>
          <div class="p-grid p-mb-3">

            <!--Start-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="sunStart"
                              placeholder="Open time"
                              i18n-placeholder
                              icon="fa-solid fa-play"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultStartTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>
            </div>

            <!--End-->
            <div class="p-col-12 p-sm-5 field">
              <app-date-input formFieldName="sunEnd"
                              placeholder="Close time"
                              i18n-placeholder
                              icon="fa-solid fa-flag-checkered"
                              [form]="form"
                              appendTo="body"
                              [defaultDate]="defaultFinishTime"
                              [baseZIndex]="11000"
                              [timeOnly]="true"></app-date-input>

              <!--Next Day Info-->
              <ng-container
                *ngIf="form.controls.sunEnd.value && (form.controls.sunStart.value >= form.controls.sunEnd.value)"
                [ngTemplateOutlet]="nextDayInfo"
              ></ng-container>
            </div>

            <!--Closed-->
            <div class="p-col-12 p-sm-2 p-d-flex p-ai-center field">
              <p-checkbox label="Closed"
                          i18n-label
                          binary="true"
                          formControlName="sunClosed"
                          (ngModelChange)="disableDayTimes('sun')">
              </p-checkbox>
            </div>
          </div>
        </div>

      </form>

      <ng-template #nextDayInfo>
        <p class="field-description" i18n>The next day.</p>
      </ng-template>

    </div>
  </app-dialog>

  <!--Edit restrictions component dialog-->
  <app-edit-opening-hours-restriction *ngIf="editRestrictionComponentVisible"
                                      [(show)]="editRestrictionComponentVisible"
                                      (changed)="editRestrictionComponentVisible = false"
                                      [data]="data">
  </app-edit-opening-hours-restriction>
</div>

<!--Bubble component-->
<ng-template #bubble>
  <div *ngIf="!loading && todayOpeningHours"
       class="p-p-0 w-100 p-d-flex p-jc-between"
       [class.as-carousel]="inMapCarousel"
       [class.p-flex-column]="inMapCarousel"
       [class.p-ai-center]="!inMapCarousel">

    <!--Date of event-->
    <div *ngIf="data.profileType === ProfileType.EVENT"
         class="p-d-flex p-ai-center">
      <i class="fa-solid p-mr-2"
         [class.fa-clock]="!todayOpeningHours.isNowOpen"
         [class.fa-hourglass-end]="todayOpeningHours.isNowOpen"></i>

      <div class="date">
        {{(todayOpeningHours.isNowOpen ? todayOpeningHours.end : todayOpeningHours.start) | date: 'd. MMM y'}}
      </div>
    </div>

    <!--Today's Opening Hours-->
    <table>
      <tr class="p-d-flex p-ai-center">
        <td class="p-d-flex p-ai-center p-pr-2"
            [class.open]="!todayOpeningHours.restricted && (todayOpeningHours.nonstop || (todayOpeningHours.isNowOpen && !todayOpeningHours.closed))"
            [class.closed]="!todayOpeningHours.restricted && (!todayOpeningHours.nonstop && (!todayOpeningHours.isNowOpen || todayOpeningHours.closed))"
            [class.restriction]="todayOpeningHours.restricted">

          <i class="fa-solid"
             [class.fa-check]="!todayOpeningHours.restricted && (todayOpeningHours.nonstop || (todayOpeningHours.isNowOpen && !todayOpeningHours.closed))"
             [class.fa-location-pin-lock]="!todayOpeningHours.restricted && (!todayOpeningHours.nonstop && (!todayOpeningHours.isNowOpen || todayOpeningHours.closed))"
             [class.fa-bell-concierge]="todayOpeningHours.restricted"></i>
        </td>

        <ng-template [ngIf]="!todayOpeningHours.nonstop">
          <!--No information-->
          <ng-template [ngIf]="(!todayOpeningHours.start || !todayOpeningHours.end) && !todayOpeningHours.closed">
            <td colspan="3" class="no-info">Not set</td>
          </ng-template>

          <!--From - To-->
          <ng-template [ngIf]="todayOpeningHours.start && !todayOpeningHours.closed">

            <!--Time-->
            <td [class.open]="todayOpeningHours.isNowOpen"
                [class.closed]="!todayOpeningHours.isNowOpen">
              {{todayOpeningHours.isNowOpen ? 'Till ' : 'Start '}}
              <!--Display the Till text if the subject is currently open-->
              {{(todayOpeningHours.isNowOpen ? todayOpeningHours.end : todayOpeningHours.start) | date: 'H:mm'}}</td>
          </ng-template>

          <!--Closed-->
          <ng-template [ngIf]="todayOpeningHours.closed">
            <td colspan="3" class="closed" i18n>Closed</td>
          </ng-template>
        </ng-template>

        <!--Non-Stop-->
        <ng-template [ngIf]="todayOpeningHours.nonstop">
          <td colspan="3" class="open" i18n>Non-Stop</td>
        </ng-template>
      </tr>
    </table>
  </div>
</ng-template>

<!--Skeleton templates-->
<ng-template #skeletonEditDialog>
  <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" class="p-d-flex p-ai-center p-grid w-100 p-ml-4">
    <p-skeleton width="10rem" height="2rem" styleClass="p-m-2 p-col-5 p-mr-2"></p-skeleton>
    <p-skeleton width="10rem" height="2rem" styleClass="p-m-2 p-col-5"></p-skeleton>
    <p-skeleton width="5rem" height="1rem" styleClass="p-m-2 p-col-2"></p-skeleton>
  </div>
</ng-template>
