<div class="p-grid action-buttons">
  <!--Edit Button-->
  <div *ngIf="editButtonVisible"
       class="p-col p-d-flex p-flex-column p-ai-end p-px-0">

    <!--Settings Button-->
    <app-button (clicked)="showSettingsDialog()"
                i18n-label
                icon="fa-solid fa-cog"
                label="Settings"
                type="secondary"></app-button>

    <!--Penalty label-->
    <div *ngIf="profile.penalty" @grow
         class="failed-box p-text-bold c-pointer p-py-2 p-my-2"
         i18n
         i18n-pTooltip pTooltip="Your account is invisible due to {{penaltyReason}}.">
      In penalty for <br> {{ profile.penalty.expiresAt | countdown:'dhms' | async }}.
    </div>

    <!--Inactive label-->
    <div *ngIf="profile && hasProfileStatus(profile, ProfileStatus.INACTIVE)" @grow
         (click)="inactiveTagClicked()"
         class="failed-box p-d-flex p-text-bold c-pointer p-py-2 p-my-2"
         i18n-pTooltip
         pTooltip="Your account is inactive based to your decision.">
      <i class="fa-regular fa-circle-xmark p-mr-2"></i>
      <div i18n>INACTIVE</div>
    </div>

    <!-- Stripe future requirements label -->
    <div *ngIf="stripeNewRequirementsDue" @grow
         (click)="inactiveTagClicked()"
         class="failed-box p-d-flex p-text-bold c-pointer p-py-2 p-my-2"
         i18n-pTooltip
         pTooltip="Required to provide additional data for Stripe to prevent blocking payouts starting at {{stripeNewRequirementsDue | date: 'dd. MM. yyyy'}}.">
      <div i18n>Stripe update required</div>
    </div>
  </div>
</div>

<!--Switch Profile-->
<app-button (clicked)="switchToDataProfile()"
            *ngIf="profile?.owner && currentProfile?.profileId !== profile?.profileId"
            [loading]="switchLoading"
            type="edit"></app-button>

