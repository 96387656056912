import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {ApiService} from './api.service'

@Injectable({
  providedIn: 'root'
})
export class WallpaperService extends ApiService {

  constructor() {
    super()
  }

  callGetWallpapersForEdit(): Observable<BaseResp<GetWallpaperEditResp[]>> {
    return this.post(Endpoint.WALLPAPERS_GET_EDIT_URL, null)
  }

  callPostNewWallpaper(file: File, uuid: string): Observable<BaseResp<boolean>> {
    const formData = new FormData()
    formData.append('imageUuid', uuid)
    formData.append('image', file)
    return this.post(Endpoint.WALLPAPERS_NEW_URL, formData, this.getFormHeaders())
  }

  /**
   * Returns an array of new wallpaper URLs.
   */
  callUpdateWallpapers(req: UpdateWallpapersReq): Observable<BaseResp<string[]>> {
    return this.post(Endpoint.WALLPAPERS_UPDATE_URL, req)
  }
}

export interface GetWallpaperEditResp {
  uuid: string
  url: string
}

export interface UpdateWallpapersReq {
  uuids: string[]
}
