<app-dialog *ngIf="showDialog"
            [component]="this"
            url="feature-showcase"
            [showResponse]="false"
            [(show)]="showDialog">

  <p-carousel [value]="items"
              [numVisible]="1"
              [numScroll]="1"
              [circular]="true"
              [showIndicators]="false"
              [showNavigators]="false"
              [page]="page"
              (onPage)="onPageChange($event)">
    <ng-template let-item pTemplate="item">
      <div class="p-d-flex p-ai-center p-jc-center image">
        <img src="{{ isScreenOf(Screen.LG) ? item.imageLg : item.imageSm }}"
             alt="{{item.name}} image"/>
      </div>

      <h1 class="p-m-0 p-pt-2 p-text-center w-100">{{ item.name }}</h1>

      <div class="p-mt-2 rounded-small p-p-3 description">
        <p [innerHTML]="item.description" class="p-text-center"></p>
      </div>
    </ng-template>
  </p-carousel>

  <!--Bottom Bar-->
  <ng-template pTemplate="bottomBar">
    <div class="p-d-flex p-ai-center w-100 {{page === 0 ? 'p-jc-between' : 'p-jc-end'}}">
      <app-button *ngIf="page === 0"
                  label="Previous"
                  i18n-label
                  icon="fa-solid fa-angles-left"
                  type="secondary"
                  [labelAutoHide]="true"
                  (clicked)="onPrevious()"></app-button>

      <app-button *ngIf="page < items.length -1"
                  label="Skip"
                  i18n-label
                  type="secondary"
                  (clicked)="markAsSeen()"
                  iconPos="right"></app-button>

      <app-button [label]="(page === items.length -1) ? trans.finish : trans.next"
                  [icon]="(page === items.length -1) ? 'fa-solid fa-flag-checkered' : 'fa-solid fa-angles-right'"
                  type="secondary"
                  (clicked)="onNext()"
                  iconPos="right"></app-button>
    </div>
  </ng-template>
</app-dialog>
