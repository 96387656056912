<app-dialog *ngIf="steps"
            [(show)]="show"
            [component]="this"
            type="save"
            [successMessage]="steps[currentStep]?.['successMessage']"
            [hold]="steps.length > 1 && currentStep === 0 && !disableHold"
            #dialog>

  <!--Top action bar-->
  <ng-template pTemplate="topBar">
    <app-dialog-steps [steps]="steps"
                      class="w-100"
                      [(activeIndex)]="currentStep"></app-dialog-steps>
  </ng-template>

  <div class="p-py-2">

    <!-- ARRIVAL CONTENT - PROFILE-->
    <ng-template [ngIf]="steps[currentStep]?.id === 'confirm' && !isAuthor">
      <h1 class="title p-text-center" i18n>Did you Arrive?</h1>
      <p class="p-text-bold p-text-center w-100 p-py-3" i18n>
        We need to check your location in case of any future discrepancies with this order.
      </p>

      <!--I will not come-->
      <div class="p-d-flex p-jc-center p-mb-3" *ngIf="!profileWillNotComeWarningVisible" @grow>
        <app-button type="secondary"
                    label="I will not come"
                    i18n-label
                    [disabled]="saving || loading"
                    (clicked)="profileWillNotComeWarningVisible = true; dialog.scrollBottom(200)"></app-button>
      </div>

      <app-hint>
        <ul class="p-m-0 p-p-0 gps-hint">
          <li i18n>Turn on your GPS setting, <b>get at the location</b> displayed as <b>the blue circle</b>,
            and then click on the <b>I'm here</b>.
          </li>
          <li class="p-mt-1" i18n>Sometimes you have to wait until your location gets more accurate.</li>
        </ul>
      </app-hint>

      <!--Event map-->
      <div *ngIf="order && mapVisible" class="p-mt-3">
        <div class="map p-py-2">
          <app-map [newMarker]="(isAuthor) ? order.profile : order.author"
                   [newMarkerPos]="coords"
                   [radiusMarker]="radiusMarker"
                   [resetButton]="false"
                   [readonly]="true"
                   [zoom]="15"
                   [minZoom]="7"
                   [search]="false"
                   styleClass="rounded-medium"
                   [filterButton]="false"
                   [explore]="false"
                   [watchUserLocation]="true"
                   [userCenterButton]="true"
                   (userLocationEvent)="onUserLocationChange($event)"
                   [geoPermissionRequest]="geolocationPermissionReq"></app-map>
        </div>
      </div>

      <!--Map skeleton-->
      <p-skeleton *ngIf="!order" styleClass="rounded-medium p-mt-3 p-py-2" width="100%" height="30rem"></p-skeleton>

      <!--Are you sure to cancel order?-->
      <div class="p-d-flex p-flex-column p-ai-center p-mt-4" *ngIf="profileWillNotComeWarningVisible" @grow>
        <section class="failed-box p-flex-column">
          <h2 i18n>Do you really want to cancel this order?</h2>
          <p class="p-text-center" i18n>Based on our <u>Terms of Services</u> your profile will be penalized for a while
            since
            you are cancelling the already paid order.</p>
          <app-button type="danger"
                      label="Cancel Order"
                      i18n-label
                      icon="fa-solid fa-heart-crack"
                      styleClass="p-mt-2"
                      [loading]="disputeRequest"
                      [disabled]="saving || loading"
                      (clicked)="cancelPaidOrder()"></app-button>
        </section>
      </div>

      <!--Importance reminder-->
      <div class="p-d-flex p-flex-column p-ai-center p-mt-4">
        <section class="failed-box p-flex-column" *ngIf="showLaterHint" @grow>
          <h2 i18n>But don't forget!</h2>
          <ul class="confirm-warning p-p-0 p-m-0">
            <li i18n>You still have <b>{{confirmArrivalUntil | countdown | async}}</b> to confirm your arrival.</li>
            <li class="p-mt-2" i18n>Be sure to <b>confirm your location</b> to have <b>proof in the system</b>, that you
              have
              been at the location at the right time.
            </li>
            <li class="p-mt-2" i18n><b>Otherwise, you have to rely on your customer</b> who will decide whether you have
              arrived or not.
            </li>
          </ul>
        </section>
      </div>

      <!--Backend validation-->
      <app-backend-validation styleClass="p-mt-4"
                              [showAsBox]="true"
                              [serverMessages]="serverMessages"
                              [check]="ServerMessage.GEOLOCATION_FAILED">
        <div i18n>Your geolocation could not be taken. You're device doesn't support the GPS or you have not allowed the
          <b>Geolocation</b>
          permission for this app.
        </div>
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.PROFILE_ORDER_PROFILE_NOT_AT_LOCATION"
                              [showAsBox]="true" i18n>
        Please, get to the blue circle to confirm your arrival.
      </app-backend-validation>

    </ng-template>

    <!-- ARRIVAL CONTENT - AUTHOR-->
    <ng-template [ngIf]="steps[currentStep]?.id === 'confirm' && isAuthor">
      <h1 class="title p-text-center" i18n>Did Artist Arrive?</h1>
      <p class="p-text-bold p-text-center w-100 p-py-3" i18n>
        Please confirm whether {{order.profile.displayName}} arrived at the location of this event.
      </p>

      <!--Didn't arrive button-->
      <div class="p-d-flex p-jc-center p-mb-3"
           *ngIf="rateStart <= currentDate && !profileWillNotComeWarningVisible && !order.dispute" @grow>
        <app-button type="secondary"
                    label="Did Not Arrived"
                    i18n-label
                    [disabled]="saving || loading"
                    (clicked)="profileWillNotComeWarningVisible = true; dialog.scrollBottom(200)"></app-button>
      </div>

      <!--Event map-->
      <div *ngIf="order && mapVisible" class="p-mt-3">
        <div class="map p-py-2">
          <app-map [newMarker]="order.author"
                   [newMarkerPos]="coords"
                   [radiusMarker]="radiusMarker"
                   [resetButton]="false"
                   [readonly]="true"
                   [zoom]="15"
                   [minZoom]="7"
                   [search]="false"
                   styleClass="rounded-medium"
                   [filterButton]="false"
                   [explore]="false"></app-map>
        </div>
      </div>

      <!--Map skeleton-->
      <p-skeleton *ngIf="!order" styleClass="rounded-medium p-mt-3 p-py-2" width="100%" height="30rem"></p-skeleton>

      <!--Open dispute process reminder-->
      <div class="p-d-flex p-flex-column p-ai-center p-mt-4"
           *ngIf="profileWillNotComeWarningVisible && !profileArrivedCheck" @grow>
        <section class="failed-box p-flex-column">
          <h2 i18n>Open a Dispute Process</h2>
          <p class="p-text-center" i18n>By accepting this decision you are going to <b>open a dispute process</b> for
            this order. Then, our team will contact both sides to identify problems.</p>
          <p class="p-text-center p-mt-2" i18n>Based on our <a [routerLink]="navigation.urlLegalDocuments('terms')"><u>Terms
            of Services</u></a> in case of <b>your untruth statement, your profile will be penalized</b> for some time.
            Please, <b>consider this decision carefully</b> again.</p>

          <app-button type="danger"
                      label="Artist didn't come"
                      i18n-label
                      icon="fa-solid fa-heart-crack"
                      styleClass="p-mt-2"
                      [loading]="disputeRequest"
                      (clicked)="authorProfileNotArrived()"></app-button>
        </section>
      </div>

      <!--Potentially false statement (profile has arrived)-->
      <div class="p-d-flex p-flex-column p-ai-center p-mt-4"
           *ngIf="profileArrivedCheck" @grow>
        <section class="failed-box p-flex-column p-text-center" (ngInit)="dialog.scrollBottom(250)">
          <h2 i18n>Warning</h2>

          <p i18n>The system has verified that <b>{{oppositeProfile.displayName}} has been on the location</b>
            at the time of this order.</p>

          <p class="p-mt-2" i18n>If you disagree with the system verification, you can still open the dispute
            process. <b>Our team will try to identify the problems.</b> We will then contact you about the process of
            the dispute via the following contact you have provided (phone or email).</p>

          <!--Update contact-->
          <app-button type="secondary"
                      styleClass="p-mt-2"
                      label="Update Contact"
                      i18n-label
                      icon="fa-solid fa-envelope"
                      [url]="navigation.urlUserSettings()"></app-button>

          <!--Submit-->
          <app-button type="danger"
                      label="Continue"
                      i18n-label
                      icon="fa-solid fa-heart-crack"
                      styleClass="p-mt-2"
                      [loading]="saving || loading || disputeRequest"
                      (clicked)="cancelPaidOrder()"></app-button>
        </section>
      </div>

      <!--Don't forget to confirm hint-->
      <app-hint styleClass="p-mt-4" *ngIf="showLaterHint" @grow i18n>
        Please, don't forget to <b>confirm {{oppositeProfile.displayName}} arrival</b> when he comes at the ordered
        location. You'll be also notified when you forget.
      </app-hint>
    </ng-template>

    <!-- RATING CONTENT-->
    <ng-template [ngIf]="steps[currentStep]?.id === 'rate'">
      <h1 class="title p-text-center" i18n>Rate {{oppositeProfile.displayName}}</h1>
      <div class="p-d-flex p-jc-center p-mt-4 p-mb-3">
        <app-rating [(stars)]="reviewStars" [edit]="true"></app-rating>
      </div>

      <app-hint class="p-d-flex p-jc-center" i18n><b>Click on a star</b> to rate {{oppositeProfile.displayName}}.<br>
        You can also type optional commentary for the profile you are rating.
      </app-hint>

      <app-text-input [focus]="true"
                      [form]="form"
                      [length]="{max: Restrictions.MAX_REVIEW_TEXT_LENGTH}"
                      [textArea]="true"
                      [notContact]="true"
                      formFieldName="text"
                      icon="fa-solid fa-message"
                      inputType="text"
                      styleClass="p-mt-2"
                      [placeholder]="isAuthor ? trans.author_placeholder : trans.profile_placeholder"
                      label="Notes"
                      i18n-label>
      </app-text-input>

      <!--Later action hint-->
      <app-hint *ngIf="showLaterHint" @grow i18n>
        You still have <b>2 more days to rate</b> this profile manually, otherwise after that time of your inactivity,
        he will be rated with 5 stars automatically.
      </app-hint>
    </ng-template>

    <!--Global BE CHECK-->
    <div class="p-mt-2">
      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.PROFILE_ORDER_REJECTED"
                              [showAsBox]="true" i18n>
        This order has been already rejected.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.PROFILE_ORDER_ALREADY_CONFIRMED"
                              [showAsBox]="true"
                              [showAsSuccess]="true" i18n>
        This order has been already confirmed.
      </app-backend-validation>
      <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED" [serverMessages]="serverMessages"
                              i18n>
        The field cannot contain any contact.
      </app-backend-validation>
    </div>


    <!--Custom response-->
    <app-call-response *ngIf="steps.length > 1 && currentStep === 0 && !disableHold"
                       styleClass="p-mt-4"
                       [component]="this"
                       [successMsg]="steps[currentStep]?.['successMessage']"></app-call-response>
  </div>

  <!--Preview and next buttons-->
  <ng-template pTemplate="bottomBar"
               let-onSave="onSave" let-saveLoading="saveLoading" let-saveDisable="saveDisable"
               let-onDiscard="onDiscard" let-discardDisable="discardDisable">

    <div class="p-d-flex p-jc-between p-ai-center w-100">
      <!--Later-->
      <app-button type="text"
                  label="Ask Later"
                  i18n-label
                  data-test="later"
                  styleClass="p-my-3"
                  [disabled]="saving || loading"
                  (clicked)="later()"></app-button>

      <!--I'm here button - profile-->
      <app-button *ngIf="steps[currentStep]?.id === 'confirm' && !isAuthor"
                  type="primary"
                  label="I'm here"
                  i18n-label
                  icon="fa-solid fa-location-dot"
                  [disabled]="!isOnLocation || !!order.dispute"
                  [loading]="saving || loading"
                  (clicked)="confirmArrival()"></app-button>

      <!--Profile has arrived - author-->
      <app-button *ngIf="steps[currentStep]?.id === 'confirm' && isAuthor"
                  type="primary"
                  label="Arrived"
                  i18n-label
                  icon="fa-solid fa-check"
                  [loading]="(saving || loading) && !this.disputeRequest"
                  [disabled]="!!order.dispute || this.disputeRequest"
                  (clicked)="confirmArrival()"></app-button>

      <!--Upload rating-->
      <app-button *ngIf="steps[currentStep]?.id === 'rate'"
                  type="save"
                  label="Rate"
                  i18n-label
                  icon="fa-solid fa-star"
                  [disabled]="!reviewStars || reviewStars === 0"
                  [loading]="saving || loading"
                  (clicked)="uploadRating()"></app-button>
    </div>
  </ng-template>
</app-dialog>
