<app-dialog *ngIf="show"
            [(show)]="show"
            type="info"
            [component]="this"
            [allowSaveOption]="false"
            [showContactButton]="true"
            [hold]="simulateFundingOpened"
            header="Remaining amount to pay"
            i18n-header>
  <div class="p-py-2 bank-transfer-dialog" *ngVar="bankInstructions as b">
    <h2 class="p-text-center p-mt-0">{{ (b.amountRemaining / 100) | price }}</h2>

    <div class="p-p-2 rounded-medium payment-information">
      <b i18n>Payment Information</b>
      <table class="w-100 " *ngVar="b.financialAddresses?.[0] as a">
        <tr>
          <td>IBAN</td>
          <td><i class="fa-solid fa-copy"
                 pTooltip="Copy"
                 i18n-pTooltip
                 #iban
                 (click)="copy(a.iban.iban, iban)"></i></td>
          <td>{{ a.iban.iban | iban }}</td>
        </tr>
        <tr>
          <td>BIC</td>
          <td><i class="fa-solid fa-copy"
                 #bic
                 pTooltip="Copy"
                 i18n-pTooltip
                 (click)="copy(a.iban.bic, bic);"></i></td>
          <td>{{ a.iban.bic }}</td>
        </tr>
        <tr>
          <td i18n>Account</td>
          <td><i class="fa-solid fa-copy"
                 pTooltip="Copy"
                 i18n-pTooltip
                 #acc
                 (click)="copy(a.iban.accountHolderName, acc)"></i></td>
          <td>{{ a.iban.accountHolderName }}</td>
        </tr>
        <tr>
          <td i18n="@@Bank transfer reference">Reference</td>
          <td><i class="fa-solid fa-copy"
                 pTooltip="Copy"
                 i18n-pTooltip
                 #ref
                 (click)="copy(b.reference, ref)"></i></td>
          <td>{{ b.reference }}</td>
        </tr>
        <tr>
          <td i18n>Amount</td>
          <td><i class="fa-solid fa-copy"
                 pTooltip="Copy"
                 i18n-pTooltip
                 #amount
                 (click)="copy((b.amountRemaining / 100), amount)"></i></td>
          <td>{{ (b.amountRemaining / 100) | price }}</td>
        </tr>
      </table>
    </div>

    <!-- Simulate Funding -->
    <app-button *ngIf="!production"
                type="secondary"
                label="Simulate Funding"
                i18n-label
                icon="fa-solid fa-building-columns"
                styleClass="p-my-2"
                (clicked)="simulateFundingOpened = true"/>

    <app-hint [collapsableHint]="false">
      <span i18n>
        Please send the remaining amount to the following bank account. Double check the <b>IBAN</b>, <b>amount</b>
        and the <b>reference</b>.
      </span>
      <span i18n>If your bank charges extra fees for cross-country payments, ensure you will send the increased amount
        to those fees.</span>
      <span i18n>
        However, Ireland belongs to SEPA countries, so when you make a SEPA transfer using the currency EUR, no fees
        should be applied.
      </span><br>
      <span i18n>As soon as we receive your payment, we will inform you about that.</span>
    </app-hint>
  </div>
</app-dialog>

<!-- Test Only Simulate Funding -->
<app-simulate-funding *ngIf="simulateFundingOpened && !production"
                      [(show)]="simulateFundingOpened"
                      [bankInstructions]="bankInstructions"
                      [order]="order"></app-simulate-funding>
