import {Component, EventEmitter, Input, Output} from '@angular/core'
import {AuthTypeEnum} from '../../../common/auth-type-enum'
import {TwoFactorResp, UserResp} from '../../../service/user.service'
import {FirebaseAuthService, FirebaseRegisterReq} from '../../../service/firebase-auth.service'
import {UserResponseType} from '../../../common/user-response-type'
import {firstValueFrom} from 'rxjs'
import {AuthComponent} from '../abstract/auth.component'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {LowerCasePipe, TitleCasePipe} from '@angular/common'

@Component({
  selector: 'app-firebase-auth',
  templateUrl: './firebase-auth.component.html',
  styleUrls: ['./firebase-auth.component.scss'],
  imports: [
    ButtonComponent,
    BackendValidationComponent,
    TitleCasePipe,
    LowerCasePipe
  ],
  standalone: true
})
export class FirebaseAuthComponent extends AuthComponent {
  /**
   * Decides what Firebase auth provider to use.
   */
  @Input()
  authType: AuthTypeEnum
  /**
   * Variable to indicate when to disable Third party login button.
   */
  @Input()
  buttonDisabled = false
  /**
   * The classes applied to the button will be based on this variable.
   */
  @Input()
  buttonStyle: 'default' | 'white' = 'white'
  /**
   * Variable to disable the form, when logging is in progress.
   * Emitted:
   * - true: when waiting for response
   * - false: when waiting for response is finished
   */
  @Output()
  formDisabled: EventEmitter<boolean> = new EventEmitter<boolean>()
  /**
   * Emits response from Firebase authentication to parent component which will decide the next action.
   */
  @Output()
  firebaseAuthResponse: EventEmitter<FirebaseAuthResponseResult> = new EventEmitter<FirebaseAuthResponseResult>()

  constructor(private firebaseAuthService: FirebaseAuthService) {
    super()
  }

  /**
   * Callback function to the backend, to retrieve JWT token after logging in with Third party account.
   * Fires when Third party login is successful.
   */
  async login(): Promise<void> {
    let user
    switch (this.authType) {
      case AuthTypeEnum.GOOGLE:
        user = (await this.firebaseAuthService.googleAuth()).user
        break
      case AuthTypeEnum.FACEBOOK:
        user = (await this.firebaseAuthService.facebookAuth()).user
        break
      default:
        return
    }

    const idToken = await user.getIdToken()
    const phoneNumber = user.phoneNumber
    this.callLoginFirebase(idToken, phoneNumber, this.authType)
  }

  /**
   * Call to server for social media authentication.
   * If response is of type {@link UserResponseType.USER}, redirect to content.
   * If response is of type {@link UserResponseType.TWO_FACTOR} redirect to {@link TwoFactorAuthenticationComponent}.
   * If {@link UserResponseType.FIREBASE_REGISTER}is returned, then redirect to registration.
   */
  callLoginFirebase(idToken: string, phone: string, type: AuthTypeEnum): void {
    this.callAndFinish(async () => {
      this.formDisabled.emit(true)
      const resp = await firstValueFrom(this.unwrap(this.firebaseAuthService.callLoginFirebase({
        idToken,
        phone,
        type
      })))
      await this.firebaseAuthService.authLogout()

      if (resp == null || resp.type == null || !this.noServerMessages()) {
        this.formDisabled.emit(false)
        return
      }

      // redirect based on
      switch (resp.type) {
        case UserResponseType.USER:
          // cast response and update logged-in user
          const user = resp as UserResp
          await this.finishLogin(user)
          this.afterSuccessRedirect(user, false)
          break
        case UserResponseType.FIREBASE_REGISTER:
          this.firebaseAuthService.pushRegistrationReq(resp as FirebaseRegisterReq)
          this.firebaseAuthResponse.emit({
            responseType: UserResponseType.FIREBASE_REGISTER,
            authType: type
          })
          break
        case UserResponseType.TWO_FACTOR:
          this.userService.twoFactorAuthentication.next(resp as TwoFactorResp)
          this.firebaseAuthResponse.emit({
            responseType: UserResponseType.TWO_FACTOR,
            authType: type
          })
          break
      }
    }, null, () => {
      this.formDisabled.emit(false)
    })
  }

  /**
   * Returns the class based on the button style.
   */
  protected getStyleClass(): string {
    switch (this.buttonStyle) {
      case 'default':
        return 'w-100'
      case 'white':
        return 'social-auth-button'
    }
  }
}

/**
 * Response from Firebase authentication. Wrapped object to contain response type and auth type in one.
 */
export interface FirebaseAuthResponseResult {
  responseType: UserResponseType
  authType: AuthTypeEnum
}
