import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../../../../component/abstract/editable.component'
import {
  GetProfileOrderReq,
  ProfileOrderInvoicesResp,
  ProfileOrderResp,
  ProfileOrderService
} from '../../../../service/profile-order.service'
import {ProfileResp, ProfileService} from '../../../../service/profile.service'
import {MenuItem} from 'primeng/api'
import {Acceptance} from '../../../../common/acceptance'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {NavigationService} from '../../../../service/ui/navigation.service'
import {ActivatedRoute} from '@angular/router'
import {FormBuilder, FormGroup} from '@angular/forms'
import {LatLngExpression} from 'leaflet'
import {LeafletService} from '../../../../service/ui/leaflet.service'
import {addDays, dateEquals, hoursDifference, minusDays, minusMinutes} from '../../../../utils/date.utils'
import {GuestsNumber} from '../../../../component/profile/profile-offer/order-description/order-description.component'
import {Restrictions} from '../../../../common/restrictions'
import {growAnimation} from '../../../../animation/grow.animation'
import {ChannelResp, ChatChannelService} from '../../../../service/chat-channel.service'
import {FcmService} from '../../../../service/fcm.service'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {OrderManagerListTypeEnum} from '../../order-manager-list-type.enum'
import {createGoogleMapsUrl} from '../../../../utils/router.utils'
import {ChatMessageService} from '../../../../service/chat-message.service'
import {DocumentLinks} from '../../../../service/support.service'
import {BankInstructions, StripeService} from '../../../../service/stripe.service'
import {scrollToIndex} from '../../../../utils/scroll.utils'
import {OrderReviewResp} from '../../../../service/review.service'
import {PLATFORM_BROWSER} from '../../../../app.module'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-manager-item-detail',
  templateUrl: './profile-manager-item-detail.component.html',
  styleUrls: ['./profile-manager-item-detail.component.scss']
})
export class ProfileManagerItemDetailComponent extends EditableComponent implements OnInit, OnDestroy {

  /**
   * Represents the current previewing profile data.
   */
  profileData: ProfileResp
  /**
   * Detailed - clicked item
   */
  order: ProfileOrderResp
  /**
   * Platform documents valid at a time of order creation.
   */
  platformDocuments: DocumentLinks
  /**
   * Represents the new order-total (the ordered profile profit) changed by the ordered profile.
   */
  newOrderTotal: number
  /**
   * A real platform fee
   */
  platformFee = 0
  /**
   * The {@link order} invoicing documents.
   */
  documents?: ProfileOrderInvoicesResp
  /**
   * True when the {@link documents} are fetching.
   */
  loadingDocuments: boolean
  /**
   * The form field with custom price after pending.
   */
  formCustomPrice: FormGroup
  /**
   * Ensures visibility of the reply dialog.
   */
  replyDialogVisible: boolean
  /**
   * Controls the visibility of the order state dialog.
   */
  orderStateDialogVisible = false
  /**
   * Decides if the reject button is clicked or not.
   */
  rejectButtonClicked: boolean
  /**
   * Controls the visibility of the order chat channel.
   */
  orderChatVisible: boolean
  /**
   * A direct chat channel for this order.
   */
  chatChannel?: ChannelResp
  /**
   * Represents a loading of the chat icon opener.
   */
  chatChannelLoading: boolean
  /**
   * Specifies whether this is an author or profile view.
   */
  isAuthor: boolean
  /**
   * When the profile accepts the order, this price represents the balance of the total amount and the customized price.
   */
  additionalPrice = 0
  /**
   * Contains the user-specified map location.
   */
  mapLocation?: LatLngExpression
  /**
   * Shows payment app dialog
   */
  showPaymentDialog: boolean
  /**
   * - Enables the 'Confirm' button to show the order state dialog.
   * - Gets initialized in the {@link tryShowStateDialog} function.
   */
  confirmDialogButtonVisible: boolean
  /**
   * - Defines the date from which both sides can accept the arrival.
   * - Furthermore, from this date, the reject button is invisible.
   */
  arrivalStart: Date
  /**
   * Determines the datetime when the rating feature is available.
   */
  rateStart: Date
  /**
   * Defines whether the {@link profileData} has confirmed the {@link order}.
   */
  confirmed: boolean
  /**
   * Contains the current date time.
   */
  currentDate = new Date()
  /**
   * Shows a custom Stripe bank transfer instructions dialog.
   */
  bankInstructionsDialogVisible: boolean
  /**
   * Contains Stripe bank transfer instructions information.
   */
  bankInstructions: BankInstructions
  /**
   * Whether the order's start and end date is different.
   */
  endDateDifferent: boolean
  /**
   * Represents hours difference between order's start and end date.
   */
  hoursDifference: number
  /**
   * Shows an input field to enter a custom new final price.
   */
  priceInputVisible: boolean
  /**
   * Shows a preview contract to the interested parties.
   */
  contractPreviewVisible: boolean
  /**
   * Holds the current path to the order.
   */
  breadcrumb: MenuItem[] = []
  /**
   * If it is present, contains the other side review. (If the current previewing profile is author, this will contain profile's review)
   */
  otherSideReview?: OrderReviewResp
  /**
   * If it is present, contains the {@link profileData}'s profile review.
   */
  profileReview?: OrderReviewResp
  /**
   * Determines whether the CTA bottom bar is visible.
   */
  bottomBarVisible: boolean
  /**
   * Determines all available options in the CTA bottom bar.
   */
  bottomBarOptions: BottomBarOptions
  /**
   * Updates certain behavior of the view because the test framework is running.
   */
  disablePopups: boolean

  A: typeof Acceptance = Acceptance
  Guests: typeof GuestsNumber = GuestsNumber
  OrderManagerListTypeEnum: typeof OrderManagerListTypeEnum = OrderManagerListTypeEnum
  /**
   * A reference to the {@link createGoogleMapsUrl} function.
   */
  googleMapsUrl: typeof createGoogleMapsUrl = createGoogleMapsUrl

  /**
   * All value change subscriptions.
   */
  private subs?: Subscription[] = []
  /**
   * The current interval that tries to show the {@link tryShowStateDialog} when the right time comes up.
   */
  private showStateDialogInterval

  /**
   * To regularly update the {@link currentDate}.
   */
  private dateRefreshInterval

  /**
   * Object containing transaltion strings
   */
  protected trans = {
    artist: $localize`Artist`,
    customer: $localize`Customer`,
    service_fee: $localize`Service fee`,
    performance: $localize`Performance`,
    show: $localize`Show`,
    receipt: $localize`Receipt`,
    overlap: $localize`This order is overlapping with the order of `,
    platform_fee: $localize`Administrative fee`,
    performance_costs: $localize`Performance costs`,
    tooltip_perforamce: $localize`:{Profile increased price...}:increased price to cover performance costs based on the details you provided. Includes taxes and fees.`,
    tooltip_platform_fee: $localize`The one-time fee allows us to cover the basic functionality of this platform and provide you with the best possible user experience.`,
    original_profit: $localize`Original profit`,
    total: $localize`Total`,
    payment_receipt: $localize`Payment Receipt`,
    profile_reply: $localize`Reply`,
    cancel_reason: $localize`Cancel reason`,
    orders: $localize`Orders`
  }

  constructor(
    public navigation: NavigationService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private profileOrderService: ProfileOrderService,
    private channelService: ChatChannelService,
    private fcmService: FcmService,
    private leaflet: LeafletService,
    private stripeService: StripeService,
    public changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.enableDateRefreshInterval(true)

    // FCM on order state message
    this.observeFCMMessages()

    this.subs.push(
      this.route.params.subscribe((params) => {

        // get params
        const profileCharId = params[NavigationService.PROFILE_CHAR_ID_PARAMETER]
        const orderId = params[NavigationService.PROFILE_BOOKINGS_DETAIL_PARAMETER]

        if (!profileCharId) {
          return
        }

        // only number param allowed
        if (!(/^\d+$/.test(orderId))) {
          this.onOrderNotExists()
          return
        }

        this.loadOrder(profileCharId, orderId)
      }),

      // Query params observer
      this.route.queryParams.subscribe(queryParams => {
        this.disablePopups = queryParams['no-popups']
      })
    )
  }

  /**
   * Loads the order from the parsed URL parameters of {@link profileCharId} and {@link orderId}.
   */
  private loadOrder(profileCharId: string, orderId: number): void {
    this.call(async () => {
      // init profileData from the switched profile
      this.profileData = this.profileService.currentProfile.getValue()

      // switch to profile
      await firstValueFrom(this.unwrap(this.profileService.switchToProfileCharId(profileCharId)))
      if (!this.noServerMessages()) {
        this.onOrderNotExists()
        return
      }
      // Update profileData
      this.profileData = this.profileService.currentProfile.getValue()

      // order number is valid
      this.order = await firstValueFrom(this.callGetProfileOrder(orderId))
      if (!this.order || !this.noServerMessages()) {
        this.onOrderNotExists()
        return
      }

      this.isEndDateDifferent()
      this.loadIntentNextAction(this.order.id, false)
      this.initRealPlatformFee(this.order)
      this.getPlatformDocuments(this.order.createdAt)
      await this.getChatChannel()
      this.initDisputeProperties()
      this.initIsAuthor()
      this.initItems()
      this.initBreadcrumb()
      this.initForms()
      this.disableLoading()
      this.enableStateInterval(true)
      this.tryShowStateDialog()
      this.initBottomBar()
    })
  }

  /**
   * Initializes the {@link platformFee} property based on the {@link order}.
   */
  private initRealPlatformFee(order: ProfileOrderResp): void {
    let realPrices = order.realAdditionalPrice || 0
    order.priceItems.forEach(it => {
      realPrices += (it.item.realPrice * it.quantity)
    })
    order.priceAdditions.forEach(it => {
      realPrices += it.realPrice
    })
    this.platformFee = (order.total - realPrices)
  }

  /**
   * - Creates a new subscription on the FCM notifications.
   * - Reloads the location when this {@link order} has changed its state.
   */
  private observeFCMMessages(): void {
    this.subs.push(this.fcmService.onMessage.subscribe(raw => {
      const base = raw.data
      if (base.category.includes('order') && !isNaN(base.custom) && (+base.custom) === this.order.id && PLATFORM_BROWSER) {
        window.location.reload()
      }
    }))
  }

  /**
   * Returns to the booking list of {@link OrderManagerListTypeEnum.PENDING}.
   */
  private onOrderNotExists(): void {
    this.navigation.toProfileBookings(this.profileData.charId, OrderManagerListTypeEnum.PENDING)
  }

  /**
   * - Shows the reply dialog.
   * - {@link reject} Determines whether the dialog is going to reject the order.
   */
  showReplyDialog(reject: boolean): void {
    this.rejectButtonClicked = reject
    this.replyDialogVisible = true
    this.changeRef.detectChanges()
  }

  /**
   * Changes {@link loading} value to false when the condition is true.
   */
  private disableLoading(): void {
    if (this.order && this.profileData) {
      this.loading = false
    }
  }

  /**
   * Initializes the author's view, otherwise the profile view.
   */
  private initIsAuthor(): void {
    this.isAuthor = this.order && this.profileData && this.order.author.profileId === this.profileData.profileId
  }

  /**
   * Fires when the {@link order} gets paid.
   */
  onOrderPaid(): void {
    setTimeout(async () => { // stripe needs some time to call the webhook
      window.location.reload()
    }, 3 * 1000)
  }

  /**
   * Fires when a user clicks on a partial payment label.
   */
  onPartialPaymentLabelClick(): void {
    setTimeout(() => {
      scrollToIndex('partial-refund-box', 0)
    }, 250)
  }

  /**
   * Inits some properties related to price items.
   * Sets value of {@link formCustomPrice}.
   */
  private initItems(): void {
    // event start minus 15 minutes
    this.arrivalStart = minusMinutes(this.order.calendarItem.start, Restrictions.PROFILE_ORDER_START_BEFORE_MINUTES)
    this.rateStart = minusMinutes(this.order.calendarItem.end, Restrictions.PROFILE_ORDER_END_BEFORE_MINUTES)// event end minus 15 minutes

    this.enableStateInterval(true)

    if (this.order) {
      if (this.leaflet.isReady()) {
        this.mapLocation = this.leaflet.latLng(this.order.address.lat, this.order.address.lng)
      }

      // set custom price value
      this.formCustomPrice?.controls.customPrice.setValue(+((this.order.orderTotal).toFixed(2)))
      this.disableLoading()
    }
  }

  /**
   * Tries to show the order state dialog if the criteria are suitable.
   */
  tryShowStateDialog(): void {
    if (this.profileData && this.order && !this.orderStateDialogVisible && this.order.paidAt != null) {
      // return if not accepted
      if (this.order.profileAcceptance !== Acceptance.ACCEPTED || this.order.authorAcceptance !== Acceptance.ACCEPTED) {
        return
      }
      const end = this.order.calendarItem.end
      const now = new Date()

      const isAuthor = this.order.author.profileId === this.profileData.profileId
      this.otherSideReview = this.order.reviews.find(it => it.author.profileId === ((isAuthor) ? this.order.profile.profileId : this.order.author.profileId))
      this.profileReview = this.order.reviews.find(it => it.author.profileId === this.profileData.profileId)
      const twoDaysDL = addDays(end, Restrictions.MAX_REVIEW_DAYS_AFTER_ORDER)  // two days deadline

      // customer confirmed the arrival // artist submitted the right location
      this.confirmed = (isAuthor) ? !!this.order.authorConfirmedAt : (!!this.order.authorConfirmedAt || this.order.profileArrived || this.rateStart <= new Date())

      const dispute = this.order.dispute
      const disputeDL = addDays(dispute?.modifiedAt || now, Restrictions.MAX_REVIEW_DAYS_AFTER_ORDER)

      if (((now >= this.arrivalStart && !this.confirmed) // event started
          || (now >= this.rateStart && !this.profileReview)) // not confirmed or rated
        && !dispute?.freeze // not in a frozen dispute
        && (now <= twoDaysDL || (dispute && now <= disputeDL))) { // before the deadline
        this.orderStateDialogVisible = true
        this.confirmDialogButtonVisible = true
        this.enableStateInterval(false)
      }
    }
  }

  /**
   * Initializes the {@link bottomBarOptions} and {@link bottomBarVisible} properties.
   */
  @HostListener('window:resize')
  initBottomBar(): void {
    const pAcc = this.order.profileAcceptance
    const aAcc = this.order.authorAcceptance

    const o: BottomBarOptions = {
      reject: // Reject option
        this.currentDate < this.arrivalStart
        && pAcc !== Acceptance.REJECTED
        && aAcc !== Acceptance.REJECTED,

      pay: // Payment button
        this.isAuthor
        && aAcc === Acceptance.PENDING
        && pAcc === Acceptance.ACCEPTED
        && this.currentDate < this.arrivalStart,

      accept: // Accept option for profile
        !this.isAuthor
        && pAcc === Acceptance.PENDING
        && aAcc === Acceptance.PENDING,

      state: // Action required - confirm order
        this.confirmDialogButtonVisible
        && (!this.profileReview || !this.confirmed)
    }
    this.bottomBarVisible = Object.values(o).some(it => it)
    o.rejectLabelVisible = Object.values(o).filter(it => it).length >= 2
    this.bottomBarOptions = o
  }

  /**
   * Tries to fetch the Chat Channel of this {@link order}.
   */
  private getChatChannel(): void {
    // order is not active, or has passed over
    if (this.order.profileAcceptance === Acceptance.REJECTED
      || this.order.authorAcceptance === Acceptance.REJECTED
      || this.order.calendarItem.end < minusDays(this.currentDate, Restrictions.MAX_CHAT_CHANEL_DAYS_OPEN)) {
      return
    }

    this.customCall(async () => {
      this.chatChannelLoading = true
      this.chatChannel = await firstValueFrom(this.callGetChatChannel())

      // subscribe for fcm messages
      if (this.chatChannel) {
        this.subs.push(this.fcmService.onChatMessage.subscribe((data) => {
          // increase unreadMessages on new messages
          if (data.category === ChatMessageService.NOTIFICATION_CHAT_MESSAGE_NEW
            && this.chatChannel
            && !this.orderChatVisible) {
            if (!this.chatChannel.unreadMessages) {
              this.chatChannel.unreadMessages = 1
            } else {
              this.chatChannel.unreadMessages++
            }
          }
        }))
      }
    }, null, () => this.chatChannelLoading = false)
  }

  /**
   * Initializes the order dispute object to display sufficient messages to the user.
   */
  private initDisputeProperties(): void {
    if (this.order?.dispute) {
      const createdById = this.order.dispute.createdBy
      // created by object property
      if (createdById === this.order.profile.profileId) {
        this.order.dispute.createdByObject = this.order.profile
      } else {
        this.order.dispute.createdByObject = this.order.author
      }

      // In favor of property
      const penaltyProfileId = this.order.dispute.penaltyProfile
      if (penaltyProfileId) {
        this.order.dispute.inFavorOf = (penaltyProfileId === this.order.author.profileId) ?
          this.order.profile : this.order.author
      }
    }
  }

  /**
   * Initializes and subscribes {@link formCustomPrice} form of reply message after pending state and
   * {@link formCustomPrice} form of modified custom price by artist.
   */
  private initForms(): void {
    // Init custom price form
    this.formCustomPrice = this.formBuilder.group({
      customPrice: [this.order?.orderTotal]
    })

    // custom price value changes
    this.subs.push(this.formCustomPrice.valueChanges.subscribe(this.onPriceChanged.bind(this)))
  }

  /**
   * Fires on {@link formCustomPrice} value changes.
   */
  private onPriceChanged(): void {
    const fc = this.formCustomPrice.controls.customPrice
    if (this.order && !this.isAuthor && this.order.profileAcceptance === Acceptance.PENDING) {
      const customPrice = fc.value
      if (customPrice > Restrictions.MAX_PROFILE_ORDER_PRICE) {
        fc.setErrors({maxExceeded: true})
      }
      this.additionalPrice = Number((customPrice - this.order.orderTotal).toFixed(2))
      if (this.additionalPrice < 0) {
        fc.setErrors({negativeAdditional: true})
      } else {
        this.order.additionalPrice = this.additionalPrice
        this.newOrderTotal = this.order.orderTotal + this.additionalPrice
      }
    }
  }

  /**
   * - Calls the server API to fetch next_action values of a payment intent.
   * - When the {@link autoShow} is enabled, it shows the {@link bankInstructionsDialogVisible}.
   */
  loadIntentNextAction(orderId: number, autoShow: boolean): void {
    this.customCall(async () => {
      const nextAction = await firstValueFrom(this.unwrap(this.stripeService.callGetIntentNextAction(orderId)))
      this.bankInstructions = nextAction?.['displayBankTransferInstructions']

      // try to show the dialog
      if (this.bankInstructions && autoShow) {
        this.bankInstructionsDialogVisible = true
      }
    })
  }

  /**
   * Calls the server API to get or create a chat channel.
   */
  private callGetChatChannel(): Observable<ChannelResp> {
    return this.unwrap(this.channelService.callGetOrCreateChannel({
      isDirect: true,
      participants: [this.order.author.profileId, this.order.profile.profileId]
    }))
  }

  /**
   * Calls server to get profile order.
   */
  private callGetProfileOrder(itemId: number): Observable<ProfileOrderResp | null> {
    const req: GetProfileOrderReq = {
      id: itemId
    }
    return this.unwrap(this.profileOrderService.callGetProfileOrder(req))
  }

  /**
   * Initializes {@link platformDocuments} valid at {@link createdAt} time.
   */
  private async getPlatformDocuments(createdAt: Date): Promise<void> {
    const observable = this.unwrap(this.profileOrderService.callGetOrderPlatformDocuments(createdAt))
    this.platformDocuments = await firstValueFrom(observable)
  }

  /**
   * Calls the server API to get ProfileOrder invoices.
   */
  private callGetProfileOrderInvoices(): Observable<ProfileOrderInvoicesResp> {
    return this.unwrap(this.profileOrderService.callGetProfileOrderInvoices(this.order.id))
  }

  /**
   * - Starts executing the {@link tryShowStateDialog} function every minute.
   * - So when the right time comes up to show the state dialog, it gets pop-up-ed.
   */
  private enableStateInterval(enable: boolean): void {
    if (enable) {
      this.enableStateInterval(false)
      this.showStateDialogInterval = setInterval(() => {
        this.tryShowStateDialog()
        this.initBottomBar()
      }, 60 * 1000)
    } else {
      clearInterval(this.showStateDialogInterval)
    }
  }

  /**
   * Starts refreshing the {@link currentDate} after each minute to update the UI.
   */
  private enableDateRefreshInterval(enable: boolean): void {
    if (enable) {
      this.enableDateRefreshInterval(false)
      this.dateRefreshInterval = setInterval(() => {
        this.currentDate = new Date()
      }, 1000)
    } else {
      clearInterval(this.dateRefreshInterval)
    }
  }

  ngOnDestroy(): void {
    // unsubscribe all form value changes
    this.subs?.forEach((it) => {
      it?.unsubscribe()
    })
    this.enableStateInterval(false)
    this.enableDateRefreshInterval(false)
  }

  /**
   * Initializes the {@link breadcrumb} menu.
   */
  private initBreadcrumb(): void {
    let listType: OrderManagerListTypeEnum
    let listTypeName: string
    const pAcc = this.order?.profileAcceptance
    const aAcc = this.order?.authorAcceptance

    if (pAcc === this.A.ACCEPTED && aAcc === this.A.ACCEPTED) {
      listType = OrderManagerListTypeEnum.ACCEPTED
      listTypeName = $localize`:{Accepted orders}:Accepted`

    } else if (pAcc === this.A.REJECTED || aAcc === this.A.REJECTED) {
      listType = OrderManagerListTypeEnum.REJECTED
      listTypeName = $localize`:{Rejected orders}:Rejected`

    } else if (pAcc === this.A.ACCEPTED && aAcc === this.A.PENDING) {
      listType = OrderManagerListTypeEnum.AWAITING_PAYMENT
      listTypeName = $localize`:{Awaiting payments of orders}:Awaiting payments`

    } else {
      listType = OrderManagerListTypeEnum.PENDING
      listTypeName = $localize`:{Pending orders}:Pending`
    }

    this.breadcrumb = [
      {
        icon: 'fa-solid fa-calendar-check',
        label: $localize`Bookings`,
        route: this.navigation.urlProfileBookings(this.profileData.charId)
      },
      {
        label: this.profileData.displayName,
        route: this.navigation.urlProfileBookings(this.profileData.charId)
      },
      {
        label: listTypeName,
        route: this.navigation.urlProfileBookings(this.profileData.charId, listType)
      },
      {
        label: `${this.order.id}`,
        route: this.navigation.urlProfileBookingDetail(this.profileData.charId, this.order.id)
      }
    ]
  }

  /**
   * Initializes the {@link endDateDifferent} and {@link hoursDifference} properties.
   */
  private isEndDateDifferent(): void {
    const ci = this.order.calendarItem
    this.endDateDifferent = !dateEquals(ci.start, ci.end, 'h')
    this.hoursDifference = hoursDifference(ci.start, ci.end)
  }

  /**
   * - Opens documents of the order.
   * @param hosted Determines whether the document should be downloaded or just previewed in a browser.
   * @param document Represents a document type that should be opened.
   */
  async openDocument(document: DocumentType, hosted: boolean): Promise<void> {
    // Fetch if not present
    if (!this.documents) {
      await this.initDocuments()
    }
    // Open a relevant document
    let url
    switch (document) {
      case 'service-fee':
        url = (hosted) ? this.documents.authorInvoiceHosted : this.documents.authorInvoicePDF
        break
      case 'performance':
        url = this.documents.profileContractPDF || ((hosted) ? (this.documents.profileInvoiceHosted) : (this.documents.profileInvoicePDF))
        break
      case 'receipt':
        url = this.documents.profileTransferReceiptPDF
        break
    }
    if (url) {
      window.open(url, '_blank')
    }
  }

  /**
   * Initializes the {@link documents} from the server API.
   */
  private async initDocuments(): Promise<void> {
    await this.customCall(async () => {
      this.loadingDocuments = true
      this.documents = await firstValueFrom(this.callGetProfileOrderInvoices())
    }, null, () => this.loadingDocuments = false)
  }
}

/**
 * Specifies a document type to be opened.
 */
type DocumentType = 'service-fee' | 'performance' | 'receipt'

/**
 * Defines available options in the bottom CTA bar.
 */
interface BottomBarOptions {
  reject: boolean
  pay: boolean
  accept: boolean
  state: boolean
  rejectLabelVisible?: boolean
}
