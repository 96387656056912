import {ProfileResp} from '../service/profile.service'

/**
 * Represents the profile STATUS that a user can have.
 */
export enum ProfileStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  BAN = 'BAN'
}

/**
 * Checks whether provided profile has given {@link ProfileStatus}s.
 * @param profile Provided profile.
 * @param profileStatuses Array of statuses to be matched against.
 */
export function hasProfileStatus(profile: ProfileResp, ...profileStatuses: ProfileStatus[]): boolean {
  return profileStatuses.some(it => it === profile?.profileStatus)
}
