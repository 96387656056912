import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {ValidComponent} from '../../../abstract/valid.component'
import {ProfileResp} from '../../../../service/profile.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {MenuItem} from 'primeng/api'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {firstValueFrom, Subscription} from 'rxjs'
import {BriefPriceItemCategoryResp, PriceItemService} from '../../../../service/price-item.service'
import {BasketService} from '../../../../service/basket.service'

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss']
})
export class OrderDescriptionComponent extends ValidComponent implements OnInit, OnDestroy {

  @Input()
  data: ProfileResp
  /**
   * Displays all categories from input.
   */
  @Input()
  allPriceItemCategories: BriefPriceItemCategoryResp[] = []
  /**
   * The form fields with information about order (number of guests and description).
   */
  form: FormGroup
  /**
   * Displays a menu with the number of guests.
   */
  menuGuestsNumber: MenuItem[] = []
  /**
   * The order details result.
   */
  private details: OrderDetails
  /**
   * The {@link form} value changes subscription.
   */
  private formSub?: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private priceItemService: PriceItemService,
    private basketService: BasketService) {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.initNumberOfGuests()
    this.initForm()
    this.validate()

    this.call(async () => {
      this.allPriceItemCategories =
        await firstValueFrom(this.unwrap(this.priceItemService.callGetAllCategories()))
    })
  }

  /**
   * Initializes forms of additional info about the order (number of guests and order description ...)
   */
  private initForm(): void {
    this.details = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_INFO)) as OrderDetails

    const guestNumber = (this.details?.guestsNumber) ? this.menuGuestsNumber.find(it => it.id === this.details?.guestsNumber) : null
    this.form = this.formBuilder.group({
      orderName: [this.details?.name || ''],
      orderDescription: [this.details?.description || ''],
      numberOfGuests: [guestNumber || '']
    })

    // If already filled information, validate
    if (this.details) {
      this.validate()
    }

    this.formSub = this.form.valueChanges.subscribe(() => {
        this.setValid(false)
        const formData = this.form.value
        this.details = {
          name: formData.orderName,
          guestsNumber: formData.numberOfGuests?.id,
          description: formData.orderDescription
        }
        this.basketService.updateOrderStorage(this.details, null, null, null)
        // this.basketService.createNewOrderTimeout() // created for the future
        this.validate()
      }
    )
  }

  /**
   * Validates the current order step component.
   */
  private validate(): void {
    let valid = this.form.valid
    if (!this.details || !this.details?.guestsNumber || !this.details?.name) {
      // DIRTY is needed when the data is empty, VALID doesn't catch it
      valid = valid && this.form.dirty
    }
    this.setValid(valid)
  }

  /**
   * In case the user has selected an item from the list offered.
   */
  dropdownChanged(): void {
    const formOrderName = this.form.value.orderName
    console.log('formOrderName', formOrderName)
    // If user selected an item from the list.
    /*if (formOrderName.id !== undefined) {
      this.form.controls.orderName.setValue(formOrderName.name)
    }*/
    this.validate()
  }

  /**
   * Initializes the number of guests menu.
   */
  private initNumberOfGuests(): void {
    this.menuGuestsNumber = [{
      label: '< 50',
      id: GuestsNumber.UNDER_50
    }, {
      label: '< 100',
      id: GuestsNumber.UNDER_100
    }, {
      label: '< 500',
      id: GuestsNumber.UNDER_500
    }, {
      label: '< 1 000',
      id: GuestsNumber.UNDER_1000
    }, {
      label: '< 5 000',
      id: GuestsNumber.UNDER_5000
    }, {
      label: '5 000+',
      id: GuestsNumber.OVER_5000
    }]
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe()
  }
}

/**
 * The result definition used in the {@link OrderDescriptionComponent}.
 */
export interface OrderDetails {
  name: string
  guestsNumber: GuestsNumber
  description?: string
}

/**
 * Specifies enum - Number of guests.
 */
export enum GuestsNumber {
  UNDER_50 = 'UNDER_50',
  UNDER_100 = 'UNDER_100',
  UNDER_500 = 'UNDER_500',
  UNDER_1000 = 'UNDER_1000',
  UNDER_5000 = 'UNDER_5000',
  OVER_5000 = 'OVER_5000'
}
