import {Component, Input} from '@angular/core'
import {ApiComponent} from '../../../component/abstract/api.component'
import {firstValueFrom} from 'rxjs'
import {UserService} from '../../../service/user.service'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {CallResponseComponent} from '../../../component/common/call-response/call-response.component'

@Component({
  selector: 'app-code-restore',
  templateUrl: './two-factor-code-restore.component.html',
  styleUrls: ['./two-factor-code-restore.component.scss'],
  imports: [
    ButtonComponent,
    CallResponseComponent
  ],
  standalone: true
})
export class TwoFactorCodeRestoreComponent extends ApiComponent {

  /**
   * Email of the user for restoring 2FA code.
   */
  @Input() email: string

  constructor(private userService: UserService) {
    super()
  }

  /**
   * Calls api to restore code of the user.
   */
  callRestoreVerificationCode(): void {
    this.callAndFinish(async () => {
      const resp = await firstValueFrom(this.unwrap(this.userService.callRestoreTwoFactorVerificationCode({ email: this.email })))

      if (resp && this.noServerMessages()) {
        this.resetApiAfter(3000)
      }
    })
  }
}
