import {Injectable} from '@angular/core'
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector'
import {DeviceRest} from '../../rest/user/session-device/session-device'

/**
 * Service with usage of {@link DeviceDetectorService}.
 * Detects device with U-A header and stringify {@link DeviceRest} object.
 */
@Injectable({providedIn: 'root'})
export class DeviceService {
  /**
   * Stringified version of current device info.
   */
  private deviceInfo: string

  constructor(private deviceDetector: DeviceDetectorService) {
  }

  /**
   * Returns info about current device running the application.
   */
  getDevice(): string {
    if (!this.deviceInfo) {
      this.setDeviceInfo()
    }
    return this.deviceInfo
  }

  /**
   * Constructs a device info object.
   */
  getDeviceObject(): DeviceRest {
    const device = this.deviceDetector.getDeviceInfo()
    return {
      type: device.deviceType,
      os: device.os,
      browser: device.browser,
      userAgent: device.userAgent
    }
  }

  /**
   * Provides string version od {@link DeviceRest},
   * based on value from {@link DeviceService.setDeviceInfo}.
   */
  private setDeviceInfo(): void {
    const device = this.deviceDetector.getDeviceInfo()
    this.updateDeviceInfo(device)
    this.deviceInfo = JSON.stringify({
      type: device.deviceType,
      os: device.os,
      browser: device.browser
    } as DeviceRest)
  }

  /**
   * Adjusts info, so that every Apple device has same operating system.
   */
  private updateDeviceInfo(device: DeviceInfo): void {
    // adjust os name for Apple devices
    if (device.os === 'Mac' || device.os === 'iOS') {
      device.os = 'Apple'
    }
    if (device.browser.toLowerCase().includes('edge')) {
      device.browser = 'Edge'
    }
  }
}
