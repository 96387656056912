<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [allowSaveOption]="responseFailed"
            [allowDiscardOption]="responseFailed"
            [url]="US.LOGOUT_URL"
            header="Log Out"
            i18n-header
            saveLabel="Log Out"
            i18n-saveLabel
            saveIcon="fa-solid fa-right-from-bracket"
            discardLabel="Cancel"
            i18n-discardLabel
            successMessage="Logged out successfully."
            i18n-successMessage
            errorMessage="Logged out failed."
            i18n-errorMessage>
  <div *ngIf="saving" class="p-d-flex p-ai-center p-jc-center">
    <i class="fa-solid fa-circle-notch fa-spin logout-icon p-mr-2 loading-icon"></i>
    <span class="logout-text" i18n>We're securely logging you out. Please wait.</span>
  </div>
</app-dialog>
