import {Route, Router} from '@angular/router'
import {BASE_SITE_URL} from '../../environments/environment'
import {inject} from '@angular/core'
import {UserService} from '../service/user.service'
import {NavigationService} from '../service/ui/navigation.service'
import {firstValueFrom, skipWhile} from 'rxjs'
import {map} from 'rxjs/operators'
import {hasRole, Role} from '../common/roles'
import {Location} from '@angular/common'

/**
 * Replaces the value after the last '/' character with a new value.
 */
export function replaceLastParam(url: string, newValue: any): string {
  const regex = /(\/)(?!.*\/)/
  return url.substring(0, regex.exec(url).index) + `/${newValue}`
}

/**
 * Returns the current visible URL without the {@link BASE_SITE_URL}.
 */
export function currentRelativeURL(): string {
  const current = window.location.href
  return current.slice(BASE_SITE_URL.length, current.length)
}

/**
 * Creates a URL of the GoogleMaps search of the {@link lat} and {@link lng}.
 */
export function createGoogleMapsUrl(lat: number, lng: number): string {
  return `https://google.com/maps/search/${lat},+${lng}`
}

/**
 * Generates several ({@link length}) variations of the {@link base} route with optional parameters.
 * - Example: length: 3, it will return base route supplied with another three routes with variations:
 * - /baseRoute
 * - /baseRoute/:0
 * - /baseRoute/:0/:1
 * - /baseRoute/:0/:1/:2
 *
 * These parameters are then accessible through the numbers in the ActivatedRoute.
 * @deprecated Don't use any more since the URLs get cleared automatically.
 */
export function varargRoute(base: Route, length: number): Route[] {
  const routes = [base]
  let currentRoute = base.path
  for (let i = 0; i < length; i++) {
    currentRoute = `${currentRoute}/:${i}`
    routes.push({
      path: currentRoute,
      ...(base.component && {component: base.component}),
      ...(base.loadComponent && {loadComponent: base.loadComponent}),
      ...(base.loadChildren && {loadChildren: base.loadChildren})
    })
  }
  return routes
}

/**
 * Asynchronous function to guard routes, which should be accessible only by logged-in users.
 * Takes {@link firstValueFrom} {@link UserService.user}, that is not undefined.
 * - If a result is true -> allow user to go to destination.
 * - If a result is false -> remember headed destination in {@link NavigationService.lastUrlBeforeAuth}
 *    and redirect to {@link LoginComponent}
 */
export async function authGuard(): Promise<boolean> {
  const userService = inject(UserService)
  const navigation = inject(NavigationService)

  const result = await firstValueFrom(userService.user.pipe(
      skipWhile(user => user === undefined),
      map(user => user !== null)
    )
  )

  if (!result) {
    navigation.toLogIn(true)
  }
  return result
}

/**
 * Guarding routes only accessible by {@link Role.ADMIN}.
 * If a user is not admin, show him 404 error without changing the url.
 */
export async function adminGuard(): Promise<boolean> {
  const userService = inject(UserService)
  const navigation = inject(Router)

  const result = await firstValueFrom(userService.user.pipe(
      skipWhile(user => user === undefined),
      map(user => hasRole(user, Role.ADMIN))
    )
  )

  if (!result) {
    navigation.navigate(['/**'], {skipLocationChange: true})
  }
  return result
}

/**
 * Clears all URL params and fragments (https://../my/path?param=XY#paramName).
 */
export function clearUrl(location: Location): void {
  const path = location.path()
  let newUrl = path
  if (path.includes('?')) {
    newUrl = path.split('?')[0]
  } else if (path.includes('#')) {
    newUrl = path.split('#')[0]
  }
  location.replaceState(newUrl)
}
