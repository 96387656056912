import {Component, EventEmitter, Input, Output} from '@angular/core'
import {ApiComponent} from '../../abstract/api.component'
import {BriefProfileResp, ProfileService} from '../../../service/profile.service'
import {ProfileType} from '../../../common/profile-type'
import {fadeAnimation} from '../../../animation/fade.animation'
import {Observable} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {SearchComponent} from '../search/search.component'
import {AvatarComponent} from '../avatar/avatar/avatar.component'
import {RippleModule} from 'primeng/ripple'
import {ProfileNamesComponent} from '../profile-names/profile-names.component'
import {NgForOf, NgIf} from '@angular/common'
import {SkeletonModule} from 'primeng/skeleton'
import {SharedModule} from 'primeng/api'
import {ProfileProfessionsComponent} from '../../profile/profile-professions/profile-professions.component'
import {HintComponent} from '../hint/hint.component'
import {NavigationService} from '../../../service/ui/navigation.service'
import {RouterLink} from '@angular/router'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-search',
  templateUrl: './profile-search.component.html',
  styleUrls: ['./profile-search.component.scss'],
  imports: [
    SearchComponent,
    AvatarComponent,
    RippleModule,
    ProfileNamesComponent,
    NgIf,
    SkeletonModule,
    NgForOf,
    SharedModule,
    ProfileProfessionsComponent,
    HintComponent,
    RouterLink
  ],
  standalone: true
})
export class ProfileSearchComponent extends ApiComponent {

  /**
   * Restricts searching to only these profiles.
   */
  @Input()
  profileTypes: ProfileType[] = [ProfileType.ARTIST]

  /**
   * Fires when a user clicked on the search result profile.
   */
  @Output()
  profileSelected = new EventEmitter<BriefProfileResp>()

  @Output()
  focusLost = new EventEmitter<FocusEvent>()

  /**
   * When a user clicked on the profile, it will redirect him to the desired profile.
   * This property controls the redirection.
   */
  @Input()
  redirect: boolean

  /**
   * Represents the visibility of the results' menu.
   */
  showResults = true

  /**
   * Current visible items.
   */
  searchItems: Page<BriefProfileResp> = newEmptyPage()

  NS: typeof NavigationService = NavigationService

  constructor(
    public navigation: NavigationService,
    private profileService: ProfileService) {
    super()
  }

  /**
   * Fires when a user clicked on a profile in the search result.
   */
  onProfileSelected(profile: BriefProfileResp): void {
    this.profileSelected.emit(profile)
    this.showResults = false
  }

  /**
   * Fires when the input field lost its focus.
   */
  onFocusLost(e: FocusEvent): void {
    setTimeout(() => {
      this.focusLost.emit(e)
      this.showResults = false
    }, 250)
  }

  /**
   * Calls the server API to search for brief profiles by the input string.
   */
  callSearchBriefProfiles(pageNum: number, search: string): Observable<Page<BriefProfileResp>> {
    return this.unwrap(this.profileService.callSearchBriefProfiles({
      input: search,
      types: this.profileTypes,
      page: pageNum
    }))
  }
}
