<div *ngIf="isAnyLinkPresent() || editButtonVisible"
     class="wrapper w-100 p-d-flex p-jc-center p-ai-center p-py-3">
  <div class="component-wrapper" [class.p-px-3]="!isAnyLinkPresent()">

    <div *ngIf="isAnyLinkPresent(); else empty" class="p-d-flex p-flex-column">
      <!--TabView Nav-->
      <p-tabView>

        <!--Youtube Tab-->
        <p-tabPanel *ngIf="data.showcase?.youtube">
          <ng-template pTemplate="header">
            <div class="hide-header">
              <i class="fa-brands fa-youtube fa-xl"></i>
              <span>&nbsp;YouTube</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <ng-template [ngTemplateOutlet]="youtube"></ng-template>
          </ng-template>
        </p-tabPanel>

        <!--Spotify Tab-->
        <p-tabPanel *ngIf="data.showcase?.spotify">
          <ng-template pTemplate="header">
            <div class="hide-header">
              <i class="fa-brands fa-spotify fa-xl"></i>
              <span>&nbsp;Spotify</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <ng-template [ngTemplateOutlet]="spotify"></ng-template>
          </ng-template>
        </p-tabPanel>

        <!--SoundCloud Tab-->
        <p-tabPanel *ngIf="data.showcase?.soundcloud">
          <ng-template pTemplate="header">
            <div class="hide-header">
              <i class="fa-brands fa-soundcloud fa-xl"></i>
              <span>&nbsp;SoundCloud</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <ng-template [ngTemplateOutlet]="soundcloud"></ng-template>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>

    <!--Edit Button-->
    <div class="p-d-flex p-my-3 p-jc-center">
      <app-button (clicked)="showEditComponent()"
                  *ngIf="editButtonVisible && data"
                  type="edit">
      </app-button>
    </div>
  </div>
</div>

<ng-template #empty>
  <h2 i18n>Showcase</h2>
  <app-no-content i18n>
    Pin a YouTube video, Spotify, or SoundCloud track to your profile.
  </app-no-content>
</ng-template>

<!--YOUTUBE Video-->
<ng-template #youtube>
  <div class="p-d-flex p-ai-center p-jc-center">
    <iframe
      [src]="youtubeUrlSafe"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="ratio rounded-medium"
      title="YouTube video player"
      i18n-title>
    </iframe>
  </div>
</ng-template>

<!--SPOTIFY track-->
<ng-template #spotify>
  <div class="p-d-flex p-ai-center p-jc-center">
    <iframe class="rounded-medium" [src]="spotifyUrlSafe"
            width="100%" height="352" frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"></iframe>
  </div>
</ng-template>

<!--SOUNDCLOUD track-->
<ng-template #soundcloud>
  <div class="p-d-flex p-ai-center p-jc-center">
    <iframe [src]="soundcloudUrlSafe"
            allow="autoplay"
            class="ratio rounded-medium">
    </iframe>
  </div>
</ng-template>


<!--Edit dialog-->
<app-dialog *ngIf="editComponentVisible"
            (discard)="closeEditComponent()"
            [save]="save.bind(this)"
            [(show)]="editComponentVisible"
            [component]="this"
            [disableSaveOption]="form.invalid"
            (saved)="reloadPage()"
            [helpLink]="env.contact.docs + '/docs/profiles/artist/showcase/'"
            header="Add to your showcase"
            i18n-header>

  <div class="p-d-flex p-flex-column">
    <!--Dialog content-->
    <form [formGroup]="form">
      <app-text-input [form]="form"
                      [length]="{max: Restrictions.MAX_YOUTUBE_LINK_LENGTH}"
                      formFieldName="youtube"
                      icon="fa-brands fa-youtube"
                      inputType="text"
                      [sanitize]="false"
                      label="Youtube URL"
                      i18n-label>
        <!--FE-CHECK-->
        <app-frontend-validation [form]="form" field="youtube" error="pattern" i18n>
          This is not a valid link.
        </app-frontend-validation>
        <!--BE-CHECK-->
        <app-backend-validation [check]="ServerMessage.YOUTUBE_LINK_PLAYLIST" [serverMessages]="serverMessages" i18n>
          This link navigates to the YouTube playlist, copy one video from that playlist and paste the URL link here.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.YOUTUBE_LINK_CHANNEL" [serverMessages]="serverMessages" i18n>
          This link navigates to the YouTube channel, copy one video from that channel and paste the URL link here.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.YOUTUBE_LINK_INCORRECT" [serverMessages]="serverMessages" i18n>
          This is not a YouTube link.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.YOUTUBE_LINK_MAXIMUM_EXCEEDED" [serverMessages]="serverMessages"
                                i18n>
          Youtube link exceeds {{ Restrictions.MAX_YOUTUBE_LINK_LENGTH }} characters.
        </app-backend-validation>
      </app-text-input>

      <app-text-input [form]="form"
                      [length]="{max: Restrictions.MAX_SPOTIFY_LINK_LENGTH}"
                      formFieldName="spotify"
                      icon="fa-brands fa-spotify"
                      inputType="text"
                      [sanitize]="false"
                      label="Spotify URL"
                      i18n-label>
        <!--FE-CHECK-->
        <app-frontend-validation [form]="form" field="spotify" error="pattern" i18n>
          This is not a valid link.
        </app-frontend-validation>

        <!--BE-CHECK-->
        <app-backend-validation [check]="ServerMessage.SPOTIFY_LINK_INCORRECT"
                                [serverMessages]="serverMessages" i18n>
          This is not a Spotify link.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.SPOTIFY_LINK_MAXIMUM_EXCEEDED"
                                [serverMessages]="serverMessages" i18n>
          Spotify link exceeds {{ Restrictions.MAX_SPOTIFY_LINK_LENGTH }} characters.
        </app-backend-validation>

      </app-text-input>

      <app-text-input [form]="form"
                      [length]="{max: Restrictions.MAX_EMBED_SOUNDCLOUD_LINK_LENGTH}"
                      formFieldName="soundcloud"
                      icon="fa-brands fa-soundcloud"
                      inputType="text"
                      [sanitize]="false"
                      hint="The SoundCloud link has to start with the &#60;iframe&#62; tag."
                      i18n-hint
                      label="SoundCloud Embed Link"
                      i18n-label
                      placeholder="<iframe ... </iframe>">

        <!--FE-CHECK-->
        <app-frontend-validation [form]="form" field="soundcloud" error="pattern" i18n>
          This is not a valid SoundCloud link, you need to copy and paste whole &#60;iframe&#62; tag from SoundCloud.
        </app-frontend-validation>

        <!--BE-CHECK-->
        <app-backend-validation [check]="ServerMessage.EMBED_LINK_SOUNDCLOUD_INCORRECT"
                                [serverMessages]="serverMessages" i18n>
          The SoundCloud link is not correct, you need to copy and paste whole &#60;iframe&#62; tag from SoundCloud.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.SOUNDCLOUD_LINK_MAXIMUM_EXCEEDED"
                                [serverMessages]="serverMessages" i18n>
          The Soundcloud link exceeds {{ Restrictions.MAX_EMBED_SOUNDCLOUD_LINK_LENGTH }} characters.
        </app-backend-validation>

      </app-text-input>
    </form>
  </div>
</app-dialog>
