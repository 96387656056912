/**
 * Defines the Spring Boot's Page response object.
 * This object is used when the data is retrieved as lazy-loaded.
 * The {@link content} contains the list of elements.
 * The {@link totalElements} represents the total numbers of records found in the database.
 * The {@link totalPages} represents the total pages of records found in the database.
 * The {@link nextPage} represents the next page to be loaded.
 * The {@link nextPageLoading} represents whether the next page loading has started.
 */
export interface Page<T> {
  content?: T[]
  totalElements: number
  totalPages?: number
  nextPage?: number
  offsetPosition?: number
  nextPageLoading?: boolean
}

/**
 * Returns new empty {@link Page} object.
 */
export function newEmptyPage<T>(): Page<T> {
  return {
    content: [],
    nextPage: 0,
    totalElements: 0,
    totalPages: 0,
    offsetPosition: 0
  }
}

/**
 * Initializes missing values of the {@link Page} object to default.
 */
export function fixPageObject<T>(page: Page<T>): Page<T> {
  page.nextPage = page.nextPage ? page.nextPage : 0
  page.offsetPosition = page.offsetPosition ? page.offsetPosition : 0
  return page
}

/**
 * Appends the 'newData' to the 'object' instance.
 *
 * @param object The instance that is going to be expanded.
 * @param newData The instance from that all properties are taken.
 * @param sideInList Determines whether the 'newData' should be appended at the beginning of the content array or not.
 * @param sumElements Determines whether it should sum the {@link Page.totalPages} and {@link Page.totalElements} properties together.
 */
export function appendNewPage<T>(object: Page<T>,
                                 newData: Page<T>,
                                 sideInList: 'start' | 'end' = 'end',
                                 sumElements: boolean = false): void {
  if (sideInList === 'start') {
    // eslint-disable-next-line no-unsafe-optional-chaining
    object.content = [...newData?.content, ...object?.content]
  } else if (sideInList === 'end') {
    // eslint-disable-next-line no-unsafe-optional-chaining
    object.content = [...object?.content, ...newData?.content]
  }
  if (sumElements) {
    object.totalElements += newData.totalElements
    object.totalPages += newData.totalPages
  } else {
    object.totalElements = newData.totalElements
    object.totalPages = newData.totalPages
  }
}
