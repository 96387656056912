<div [class]="styleClass"
     class="p-mb-2 tutorial-class"
     [formGroup]="form"
     *ngVar="form.controls[formFieldName].invalid && form.controls[formFieldName].touched as invalid">

  <div class="p-d-flex p-ai-center"
       (click)="(hint) ? showHint = !showHint : null">
    <!--Required icon-->
    <i *ngIf="showRequiredSymbol && label" class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
       pTooltip="This field is required"
       i18n-pTooltip
       tooltipPosition="top"></i>

    <!--Label-->
    <label [for]="formFieldName"
           [class.c-pointer]="hint"
           [class.label-invalid]="invalid">{{label}}</label>

    <!--Show hint icon-->
    <i *ngIf="hint"
       pTooltip="Why this field?"
       i18n-pTooltip
       tooltipPosition="top"
       class="fa-solid fa-circle-info p-ml-2 p-mb-2 hint-icon"
       [class.hint-icon-invalid]="invalid"></i>
  </div>

  <span [class.p-input-icon-left]="icon"
        [class.input-error]="invalid"
        *ngVar="(!isScreenOf(Screen.LG)) as touchUi;"
        (click)="onClick()"
        #dropdownContainer
        class="w-100 p-relative">

    <!--Left side icon-->
    <i *ngIf="icon"
       class="{{icon}} p-ml-2"
       [class.disabled-element]="disableIcon"
       (click)="!disableIcon ? iconClick.emit($event) : null"></i>


    <!--Type-->
      <p-dropdown #dropdown
                  [options]="options"
                  [formControlName]="formFieldName"
                  [id]="formFieldName"
                  [filterBy]="filterBy"
                  [filter]="!!filterBy"
                  [minlength]="length?.min"
                  [maxlength]="length?.max"
                  styleClass="w-100"
                  [placeholder]="placeholder || label"
                  (onChange)="onChange($event)"
                  (onClick)="onClick()"
                  (onShow)="dropdownOpened = true"
                  (onHide)="dropdownOpened = false">

        <!--Selected template-->
        <ng-template pTemplate="selectedItem" *ngVar="form.getRawValue()[formFieldName] as selected">
          <div *ngIf="!selectedTemplate">{{selected}}</div>
          <ng-template [ngIf]="selectedTemplate"
                       [ngTemplateOutlet]="selectedTemplate"
                       [ngTemplateOutletContext]="{$implicit: selected}"></ng-template>
        </ng-template>

        <!--Item template-->
        <ng-template let-item pTemplate="item">
          <div *ngIf="!itemTemplate">{{item}}</div>
          <ng-template [ngIf]="itemTemplate"
                       [ngTemplateOutlet]="itemTemplate"
                       [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
        </ng-template>
      </p-dropdown>
  </span>

  <!--Required-->
  <app-frontend-validation *ngIf="!disableRequired" [form]="form" [field]="formFieldName" error="required" i18n>
    Required field.
  </app-frontend-validation>

  <!--Not Email-->
  <app-frontend-validation *ngIf="!disableNotEmail" [field]="formFieldName" [form]="form" error="notEmail" i18n>
    Cannot contain an email.
  </app-frontend-validation>

  <!--Not Phone-->
  <app-frontend-validation *ngIf="!disableNotPhone" [field]="formFieldName" [form]="form" error="notPhone" i18n>
    Too many digits.
  </app-frontend-validation>

  <!--Not Url-->
  <app-frontend-validation *ngIf="!disableNotUrl" [field]="formFieldName" [form]="form" error="notUrl" i18n>
    Cannot contain a link.
  </app-frontend-validation>

  <!--Length-->
  <app-frontend-validation *ngIf="!disableLength" [field]="formFieldName" [form]="form" error="minlength">
    <ng-template [ngIf]="length?.min && length?.max" i18n>Needs to be {{length.min}} - {{length.max}}
      characters long.
    </ng-template>
    <ng-template [ngIf]="length?.min === undefined && length?.max" i18n>Exceeds {{length.max}} characters.
    </ng-template>
    <ng-template [ngIf]="length?.max === undefined && length?.min" i18n>Must be at least {{length.min}}
      characters long.
    </ng-template>
  </app-frontend-validation>

  <!--Optional hint-->
  <div class="hint p-d-block" *ngIf="showHint" @grow>{{hint}}</div>

  <!--Validation and other content-->
  <ng-content></ng-content>

  <!--Overlay-->
  <!--<div *ngIf="(!isScreenOf(Screen.LG) || touchUI) && dropdownOpened"
       (ngInit)="console.log('showed')"
       class="p-absolute overlay"
       [style.z-index]="baseZIndex + 500">

    <div class="touch-content p-px-2" *ngFor="let item of options">
      &lt;!&ndash;Item template&ndash;&gt;
      <div *ngIf="!itemTemplate" class="p-p-2">{{item}}</div>
      <ng-template [ngIf]="itemTemplate"
                   [ngTemplateOutlet]="itemTemplate"
                   [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    </div>

  </div>-->
</div>

