<!--address fields-->
<div class="p-grid">
  <app-text-input label="Contact Person"
                  i18n-label="@@address.name"
                  inputType="text"
                  formFieldName="name"
                  placeholder="John Smith"
                  i18n-placeholder
                  icon="fa-solid fa-user"
                  class="p-col-12 p-py-0"
                  [form]="addressForm"
                  [required]="true"
                  [length]="{max: Restrictions.MAX_NAME_FIELD_LENGTH}"></app-text-input>

  <app-text-input label="Line 1"
                  i18n-label
                  inputType="text"
                  formFieldName="line1"
                  autocomplete="address-line1"
                  placeholder="Street 12/5"
                  i18n-placeholder
                  icon="fa-solid fa-house-flag"
                  class="p-col-12 p-sm-6 p-py-0"
                  [form]="addressForm"
                  [required]="true"
                  [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

  <app-text-input label="Line 2"
                  i18n-label
                  inputType="text"
                  formFieldName="line2"
                  autocomplete="address-line2"
                  placeholder="Building C (Optional)"
                  i18n-placeholder
                  icon="fa-solid fa-house-flag"
                  class="p-col-12 p-sm-6 p-py-0"
                  [form]="addressForm"
                  [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

  <app-text-input label="City"
                  i18n-label
                  inputType="text"
                  formFieldName="city"
                  icon="fa-solid fa-building-flag"
                  class="p-col-12 p-sm-6 p-py-0"
                  [form]="addressForm"
                  [required]="true"
                  [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

  <app-text-input label="Postal code"
                  i18n-label
                  inputType="text"
                  formFieldName="postalCode"
                  placeholder="12345"
                  icon="fa-solid fa-barcode"
                  class="p-col-12 p-sm-6 p-py-0"
                  [form]="addressForm"
                  [onlyNumbers]="true"
                  [required]="true"
                  [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

  <app-dropdown label="State"
                i18n-label
                [options]="supportedCountries"
                formFieldName="country"
                class="p-col-12 p-py-0"
                [form]="addressForm"
                [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"
                [required]="true">
    <!--Selected-->
    <ng-template let-selected pTemplate="selectedItem">
      <div class="p-d-flex p-ai-center">
        <i [class]="selected.flag" class="p-mx-2"></i>
        <div>{{selected.name}}</div>
      </div>
    </ng-template>

    <!--Item-->
    <ng-template let-type pTemplate="item">
      <div class="p-d-flex p-ai-center">
        <i [class]="type.flag" class="p-mr-2"></i>
        <div>{{type.name}}</div>
      </div>
    </ng-template>
  </app-dropdown>
</div>

<!--Location couldn't be find-->
<div *ngIf="isMessage(ServerMessage.PROFILE_ORDER_LOCATION_MISSING) && (!orderAddress.lng || !orderAddress.lat)" @grow
     class="failed-box p-mt-2">
  <i class="fa-solid fa-map-location-dot p-mr-2"></i>
  <div>
    <span i18n>
       We were <b>not able to verify your address</b>. Please verify the input fields.
    </span>
    <span i18n *ngIf="addressForm.value.postalCode">
      If you are sure about your address, please <b>click on a map</b> to <b>specify where the artist will perform</b>.
    </span>
  </div>
</div>

<!--Map information-->
<div class="postal-map rounded-medium p-py-3" (ngInit)="dialog?.onScrollContent()"
     *ngIf="addressForm.valid
             && !loading && (cityOnlyLocation || mapLocation)" @grow>

  <!--Introduction Hint-->
  <h3 class="p-pl-1 p-mt-2" i18n>Map location</h3>
  <app-hint styleClass="p-mb-3">
    <div i18n>
      <b class="p-mr-1">{{ profile.displayName }}</b>
      <span class="p-mr-1"> will come to this location visible</span>
      <b class="p-mr-1">on the map</b>
      <span>below.<br>Click on the map to accurate the location and tell the artist where to come.</span>
    </div>
  </app-hint>

  <app-map [newMarker]="profile"
           [newMarkerPos]="mapLocation || originalMapLocation"
           (newMarkerAdded)="updateLocation($event); this.saveOrderAddress()"
           [center]="mapLocation || cityOnlyLocation"
           [resetButton]="mapLocation !== originalMapLocation && !!originalMapLocation"
           (resetClicked)="updateLocation(originalMapLocation)"
           [explore]="false"
           [filterButton]="false"
           [minZoom]="8"
           styleClass="rounded-medium"
           [search]="false"></app-map>
</div>

<!--Used for autoscroll to element-->
<div class="scroll-selector"></div>

<!--Call response-->
<app-call-response [component]="this" styleClass="p-my-2"></app-call-response>
