import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations'

export function growAnimation(ms: number = 200): AnimationTriggerMetadata {
  return trigger('grow', [
    transition(':enter', [
      style({height: 0, opacity: 0}),
      animate(ms + 'ms ease-out', style({height: '*', opacity: 1}))
    ]),
    transition(':leave', [
      style({height: '*', opacity: 1}),
      animate(ms + 'ms ease-in', style({height: 0, opacity: 0}))
    ])
  ])
}
