import {Pipe, PipeTransform} from '@angular/core'
import {toPixels} from '../utils/device.utils'

/**
 * - Converts given unit in string to the pixels.
 * - E.g. '10rem' -> 10*16
 */
@Pipe({
  name: 'pixels',
  standalone: true
})
export class PixelsPipe implements PipeTransform {
  transform(unit: string): number {
    return toPixels(unit)
  }
}
