import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {hasProfileStatus, ProfileStatus} from '../../../../common/profile-status'
import {EditableComponent} from '../../../abstract/editable.component'
import {ProfileResp, ProfileService} from '../../../../service/profile.service'
import {PenaltyType} from '../../../../common/penalty-type'
import {firstValueFrom} from 'rxjs'
import {ShowProfileSettingsDialogEvent} from '../../profile.component'
import {hasFeatures} from '../../../../common/profile-type'
import {Feature} from '../../../../common/feature'
import {PriceItemService} from '../../../../service/price-item.service'
import {ProfileSettingsDialogHighlight} from '../../profile-settings/profile-settings.component'
import {NavigationService} from '../../../../service/ui/navigation.service'

@Component({
  selector: 'app-profile-status-actions',
  templateUrl: './profile-status-actions.component.html',
  styleUrl: './profile-status-actions.component.scss'
})
export class ProfileStatusActionsComponent extends EditableComponent implements OnInit, OnChanges {
  /**
   * Currently previewing profile.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * Currently logged profile.
   */
  @Input()
  currentProfile: ProfileResp
  /**
   * Represents whether this {@link data} has no price items.
   */
  noPriceItems: boolean
  /**
   * When the {@link data} profile has completed the Stripe account.
   */
  @Input()
  stripeAccountCompleted: boolean = null
  /**
   * When the {@link data} profile has some requirements to fill in.
   */
  @Input()
  stripeNewRequirementsDue?: Date = null
  /**
   * Shows the {@link ProfileSettingsComponent} dialog.
   */
  @Output()
  showSettings = new EventEmitter<ShowProfileSettingsDialogEvent>()
  /**
   * Represents in more detail the reason for imposing the penalty.
   */
  penaltyReason: string
  /**
   * Loading when switching to the profile.
   */
  switchLoading: boolean
  /**
   * Whether the {@link data} contains the {@link Feature.BE_ORDERED}.
   */
  canBeOrdered: boolean

  readonly hasProfileStatus = hasProfileStatus
  readonly ProfileStatus = ProfileStatus

  constructor(
    private navigation: NavigationService,
    private profileService: ProfileService,
    private priceItemService: PriceItemService) {
    super()
  }

  ngOnInit(): void {
    this.initPenaltyReason()

    // Init has price items
    if (this.profile.owner && hasFeatures(this.profile.profileType, Feature.BE_ORDERED)) {
      this.customCall(async () => {
        this.noPriceItems = !await firstValueFrom(this.unwrap(this.priceItemService.callHasPriceItems()))
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.currentProfile || changes.profile) && this.profile) {

      // Triggers the change of skeleton loading
      setTimeout(() => {
        this.loading = false
      })

      this.canBeOrdered = hasFeatures(this.profile.profileType, Feature.BE_ORDERED)
    }
  }

  /**
   * Initializes the {@link penaltyReason} property.
   */
  private initPenaltyReason(): void {
    if (this.profile.penalty) {
      switch (this.profile.penalty.type) {
        case PenaltyType.CHAT_CONTACT: {
          this.penaltyReason = $localize`the contact details provided in the chat`
          break
        }
        case PenaltyType.ORDER_CANCELLED: {
          this.penaltyReason = $localize`the cancellation of a paid order`
          break
        }
        case PenaltyType.ORDER_FALSE_STATEMENT: {
          this.penaltyReason = $localize`a false statement provided via an ordered offer`
          break
        }
        case PenaltyType.ORDER_NOT_ARRIVED: {
          this.penaltyReason = $localize`the non-delivery of your order`
          break
        }
      }
    }
  }

  /**
   * Switches to the current looking profile when the account is an owner of this profile.
   */
  switchToDataProfile(): void {
    this.customCall(async () => {
      this.switchLoading = true
      await firstValueFrom(this.profileService.switchToProfile(this.profile))
    }, null, () => {
      this.switchLoading = false
    })
  }

  /**
   * Shows settings dialog and scrolls the bottom.
   */
  inactiveTagClicked(): void {
    this.showSettingsDialog('delete')
    this.navigation.toProfileCustom(this.profile.charId, 'deactivate')
  }

  /**
   * - Emits the {@link showSettings} to make the {@link ProfileSettingsComponent} visible.
   * - Also initializes the optional highlight setting parameter.
   */
  showSettingsDialog(highlightSetting?: ProfileSettingsDialogHighlight): void {
    let stripeSetting: ProfileSettingsDialogHighlight
    if (this.profile.address
      && this.profile.professions.length > 0
      && (this.stripeAccountCompleted === false || !!this.stripeNewRequirementsDue)) {
      stripeSetting = 'stripe'
    }

    this.showSettings.emit({
      show: true,
      highlightSetting: highlightSetting || stripeSetting
    })
  }
}
