import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {ProfileOrderService} from '../../../../service/profile-order.service'
import {firstValueFrom, Observable} from 'rxjs'
import {ProfileResp} from '../../../../service/profile.service'
import {BriefPriceItemCategoryResp, PriceItemAdditionResp, PriceItemResp, PriceItemService} from '../../../../service/price-item.service'
import {Page} from '../../../../rest/page-resp'
import {NavigationService} from '../../../../service/ui/navigation.service'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {BasketService} from '../../../../service/basket.service'
import {MenuItem} from 'primeng/api'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {throwAppError} from '../../../../utils/log.utils'
import {OrderDetails} from '../order-description/order-description.component'
import {AddressReq, PostalReq} from '../../../../service/address.service'
import {growAnimation} from '../../../../animation/grow.animation'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {hoursDifference} from '../../../../utils/date.utils'
import {PixelCategory, PixelService} from '../../../../service/analytics/meta-pixel/pixel.service'
import {UserService} from '../../../../service/user.service'
import {SupportContact} from '../../../support/support.component'
import {environment} from '../../../../../environments/environment'
import {GoogleAnalyticsEvent, GoogleAnalyticsService} from '../../../../service/analytics/google-analytics.service'
import {OrderPerformanceAddressComponent} from '../order-location/order-performance-address/order-performance-address.component'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-order-dialog',
  templateUrl: './profile-order-dialog.component.html',
  styleUrls: ['./profile-order-dialog.component.scss']
})
export class ProfileOrderDialogComponent extends EditableComponent implements OnInit {

  Step: typeof OrderStepType = OrderStepType
  /**
   * The data of the profile.
   */
  @Input()
  profileData: ProfileResp
  /**
   * - Start and End date.
   * - [StartDatetime, EndDatetime].
   */
  @Input()
  dates: Date[]
  /**
   * All available additional items of the {@link profileData}.
   */
  @Input()
  allAdditionalItems: Page<PriceItemAdditionResp>
  /**
   * - Selected price items from the parent component.
   * - Cannot be changed to keep the parent's reference unchanged.
   */
  @Input()
  readonly selectedPriceItems: PriceItemResp[]
  /**
   * - Selected price items additions.
   */
  @Input()
  selectedPriceItemsAdditions: PriceItemAdditionResp[]
  /**
   * Emits when the {@link selectedPriceItemsAdditions} changes.
   */
  @Output()
  selectedPriceItemsAdditionsChange = new EventEmitter<PriceItemAdditionResp[]>()
  /**
   * Order total of the both {@link selectedPriceItems} and {@link selectedPriceItemsAdditions}.
   */
  @Input()
  orderTotal = 0
  /**
   * Emits when the profile order gets submitted by a user.
   */
  @Output()
  orderSubmitted = new EventEmitter()
  /**
   * Displays all categories of price item to zoom in on the type of performance - service.
   */
  @Input()
  allPriceItemCategories: BriefPriceItemCategoryResp[] = []
  /**
   * Hours difference between {@link dates}.
   */
  hours: number
  /**
   * The index of the current step in the header bar.
   */
  currentVisualStepIndex = 0
  /**
   * A current active index in the {@link steps} array.
   */
  currentStepIndex = 0
  /**
   * Displays named steps in the header of the dialog.
   * Content can vary based on the conditions like the existence of additional items.
   */
  visualSteps: MenuItem[]
  /**
   * Contains all steps of the order.
   */
  steps: OrderStepType[] = []
  /**
   * - Defines whether the current order step component is valid.
   * - Enables the 'Next' button.
   */
  segmentValid = true
  /**
   * Helping variable to determine if in the summary step of the order dialog is the one of the address segments (invoicing or event type)
   * clicked to update.
   */
  isInvoicing: boolean
  /**
   * Disables the next button of the finish order dialog.
   */
  disableNext: boolean
  /**
   * Defines the visibility of the overlaying delete address dialog.
   * to set [hold] value of the {@link dialog}.
   */
  deleteAddressConfirmVisible: boolean
  /**
   * The order finish dialog with steps.
   */
  @ViewChild('dialog')
  dialog: DialogComponent
  /**
   * Contains the child component for the accessing via the parent.
   */
  orderPerformanceAddress?: OrderPerformanceAddressComponent
  /**
   * Available platform support contact.
   */
  contactInfo: SupportContact = environment.contact
  /**
   * Defines whether the artist profile has an additional items.
   */
  hasAdditionalItems = false
  /**
   * Defines labels of the addresses in the select button.
   */
  selectedBtnAddressOptions = [
    {label: $localize`Invoicing`, value: OrderStepType.INVOICING_ADDRESS},
    {label: $localize`Event`, value: OrderStepType.ORDER_ADDRESS}]
  /**
   * Object containing translation strings
   */
  protected trans = {
    invoice_adress: $localize`Invoice address`,
    event_adress: $localize`Event address`,
    send_order: $localize`Send order`,
    send_order_sm: $localize`Send`
  }

  constructor(
    public navigation: NavigationService,
    private priceItemService: PriceItemService,
    private profileOrderService: ProfileOrderService,
    private storageService: StorageService,
    private basketService: BasketService,
    public changeRef: ChangeDetectorRef,
    private pixelService: PixelService,
    private gaService: GoogleAnalyticsService,
    private userService: UserService) {
    super()
  }

  ngOnInit(): void {
    this.validateProperties()

    this.hasAdditionalItems = this.allAdditionalItems?.content?.length > 0
    this.initStepsMenu()
    this.reselectAdditionalItems()

    this.hours = hoursDifference(this.dates[0], this.dates[1])

    // Enable the segment if the additional items exist
    if (this.hasAdditionalItems) {
      this.segmentValid = true
    }

    this.tryJumpToEnd()
  }

  /**
   * Updates the {@link currentStep} value.
   */
  increaseStep(increase: boolean): void {
    const currentType = this.steps[this.currentStepIndex]

    // Manage steps before increasing
    switch (currentType) {
      case OrderStepType.ADDITIONS:
        this.segmentValid = true
        break
      case OrderStepType.ORDER_ADDRESS:
        if (increase && !this.dialog.isFullyScrolled()) {
          this.orderPerformanceAddress?.scrollBottom()
          return
        }
        break
    }
    // Reset API due to a new step
    this.resetApi()
    this.currentStepIndex += (increase) ? 1 : -1
    if (this.steps[this.currentStepIndex] !== OrderStepType.ORDER_ADDRESS
      || (!increase && currentType === OrderStepType.ORDER_ADDRESS)) { // Skip increasing
      this.currentVisualStepIndex += (increase) ? 1 : -1
    }
    this.triggerDialogShadows(500)
    this.changeRef.detectChanges()
  }

  /**
   * Creates new order of the profile.
   */
  async callCreateNewProfileOrder(): Promise<void> {
    await firstValueFrom(this.callNewProfileOrder())
  }

  /**
   * When order is successfully created, clean and reset all selected items, filled fields, sum and so on.
   */
  onSubmittedOrder(): void {
    // removes all data from ls by key only
    this.basketService.deletePriceItemsOfProfile(this.profileData.profileId)
    this.basketService.deletePriceItemAdditionsOfProfile(this.profileData.profileId)

    // Back the offer to the default state
    this.orderSubmitted.emit()

    // removes all these data from local storage after order
    this.basketService.clearOnlyUserData()

    const trackOptions = {
      order_total: (this.orderTotal),
      char_id: this.profileData.charId
    }

    // Send the order amount to meta-pixel
    this.pixelService.trackCustom(PixelCategory.ORDER_SENT, trackOptions)
    this.gaService.event(GoogleAnalyticsEvent.PROFILE_ORDER_SENT, trackOptions)
  }

  /**
   * Fires, when the {@link selectedPriceItemsAdditions} should be changed based on the information in {@link pia}.
   */
  updateAddition(pia: PriceItemAdditionResp): void {
    // Add
    if (pia.selected) {
      this.basketService.addPriceItemAddition(this.profileData.profileId, pia)
      for (const item of this.selectedPriceItemsAdditions) {
        if (item.id === pia.id) {
          return // Already added
        }
      }
      this.selectedPriceItemsAdditions.push(pia)
      this.applyAdditionalItemsChange()

      // Remove
    } else {
      // TODO remove the inside loop of the deletePriceItemAddition() in the basket service
      this.basketService.deletePriceItemAddition(this.profileData.profileId, pia)
      for (let i = 0; i < this.selectedPriceItemsAdditions.length; i++) {
        const item = this.selectedPriceItemsAdditions[i]
        if (item.id === pia.id) {
          this.selectedPriceItemsAdditions.splice(i, 1)
          this.applyAdditionalItemsChange()
          return
        }
      }
    }
  }

  /**
   * Pushes changes to the {@link selectedPriceItemsAdditionsChange} emitter reassigns the {@link selectedPriceItemsAdditions}.
   */
  private applyAdditionalItemsChange(): void {
    this.selectedPriceItemsAdditions = [...this.selectedPriceItemsAdditions]
    this.selectedPriceItemsAdditionsChange.emit(this.selectedPriceItemsAdditions)
  }

  /**
   * Visually re-selects all {@link selectedPriceItemsAdditions} in the {@link allAdditionalItems}.
   */
  reselectAdditionalItems(): void {
    for (const allAddition of this.allAdditionalItems.content) {
      allAddition.selected = false // unselect first

      // select if between selected
      for (const selected of this.selectedPriceItemsAdditions) {
        if (allAddition.id === selected.id) {
          allAddition.selected = true
        }
      }
    }
    this.hasAdditionalItems = this.allAdditionalItems?.content?.length > 0
    this.triggerDialogShadows(400)
  }

  /**
   * Go to the corresponding step based on the {@link OrderStepType}.
   * Current step index is calculated based on the step type because steps array can vary.
   */
  goToStep(step: OrderStepType): void {
    this.currentStepIndex = this.steps.findIndex(it => it === step)
    let visualStep = step
    if (step === OrderStepType.INVOICING_ADDRESS || step === OrderStepType.ORDER_ADDRESS) {
      visualStep = OrderStepType.ADDRESS
    }
    this.currentVisualStepIndex = this.visualSteps.findIndex(it => it.id === visualStep)
  }

  /**
   * Calls the server API to get profile additional items.
   */
  callGetPriceItemAdditionsLazy(pageNum: number = 0): Observable<Page<PriceItemAdditionResp>> {
    return this.unwrap(this.priceItemService.callGetPriceItemAdditions({
      profileId: this.profileData.profileId,
      page: pageNum
    }))
  }

  /**
   * Fires when a user clicks on the birthdate error in the summary.
   */
  onBirthdateClick(): void {
    this.goToStep(OrderStepType.INVOICING_ADDRESS)
    this.resetApi()
  }

  /**
   * Get new profile order from the server.
   */
  private callNewProfileOrder(): Observable<boolean> {
    const orderDetails = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_INFO)) as OrderDetails
    const savedAuthorAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)) as PostalReq
    const savedOrderAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_ADDRESS)) as AddressReq

    return this.unwrap(this.profileOrderService.callNewProfileOrder({
      profileId: this.profileData.profileId,
      name: orderDetails?.name,
      description: orderDetails?.description,
      numberOfGuests: orderDetails?.guestsNumber,
      authorAddress: savedAuthorAddress,
      orderAddress: savedOrderAddress,
      calendarItem: {
        profileId: this.profileData.profileId,
        start: this.dates[0],
        end: this.dates[1]
      },
      priceItems: this.selectedPriceItems.map(it => it.id),
      priceItemAdditions: this.selectedPriceItemsAdditions.map(it => it.id)
    }))
  }

  /**
   * Tries to jump to the last step when all required fields are filled.
   */
  private tryJumpToEnd(): void {
    const details = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_INFO)) as OrderDetails
    const authorAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)) as PostalReq
    const orderAddress = JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_ADDRESS)) as AddressReq

    if (details?.name && details?.guestsNumber && authorAddress?.lat && orderAddress?.lat
      && ((!this.profileData.isLegalEntity && (!!authorAddress?.birthDate || !!authorAddress?.legalEntity)) || this.profileData.isLegalEntity)
      && this.userService.user.getValue() !== null) {
      this.goToStep(OrderStepType.SUM)
    }
  }

  /**
   * Initializes the {@link visualSteps}.
   */
  private initStepsMenu(): void {
    const visualSteps: MenuItem[] = []
    const steps: OrderStepType[] = []

    // Additions steps if relevant
    if (this.hasAdditionalItems) {
      visualSteps.push({
        id: OrderStepType.ADDITIONS,
        label: $localize`Additions`
      })
      steps.push(OrderStepType.ADDITIONS)
    }

    // Other steps
    visualSteps.push({
        id: OrderStepType.ADDRESS,
        label: $localize`Address`
      },
      {
        id: OrderStepType.DESCRIPTION,
        label: $localize`More`
      })
    steps.push(
      OrderStepType.INVOICING_ADDRESS,
      OrderStepType.ORDER_ADDRESS,
      OrderStepType.DESCRIPTION
    )

    // Account step if user is not logged in
    if (this.userService.user.getValue() === null) {
      visualSteps.push({
        id: OrderStepType.ACCOUNT,
        label: $localize`Account`
      })
      steps.push(OrderStepType.ACCOUNT)
    }

    visualSteps.push({
      id: OrderStepType.SUM,
      label: $localize`Sum`
    })
    steps.push(OrderStepType.SUM)

    this.visualSteps = visualSteps
    this.steps = steps
  }

  /**
   * Manually triggers the dialogs top and bottom scroll shadows, because of a dynamic content.
   */
  private triggerDialogShadows(delay: number): void {
    setTimeout(() => {
      this.dialog?.onScrollContent()
    }, delay)
  }

  /**
   * Validates the input properties to ensure the correct behavior of this component.
   */
  private validateProperties(): void {
    if (!this.selectedPriceItems) {
      throwAppError('ProfileOrderDialog', '[selectedPriceItems] is not specified].')
    }
    if (this.dates?.length !== 2) {
      throwAppError('ProfileOrderDialog', '[dates] needs to be initialized to [startDateTime, endDateTime].')
    }
  }
}

/**
 * Defines the steps of the order dialog. Each step has a corresponding component and actions.
 * Used to uniquely identify the step in the dialog.
 */
export enum OrderStepType {
  ADDITIONS = 'additions',

  ADDRESS = 'address', // Visual only
  INVOICING_ADDRESS = 'invoicing_address', // Select Button only
  ORDER_ADDRESS = 'order_address', // Select Button only

  DESCRIPTION = 'description',
  ACCOUNT = 'account',
  SUM = 'sum'
}
