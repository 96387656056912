<a *ngIf="notification?.display" @fade
   (click)="closeNotification(notification.url)">
  <div class="wrapper p-d-flex p-ai-center p-jc-between box-shadow">

    <!--Icons-->
    <i *ngIf="!notification.icon" class="fa-solid fa-bell p-mr-3 bell-icon fa-shake"></i>
    <img *ngIf="notification.icon" class="notification-icon p-mr-3" [src]="notification.icon" alt="">

    <!--Content-->
    <div>
      <h4 class="p-m-0 l-count-1">{{notification.title}}</h4>
      <div class="l-count-1 body" *ngIf="notification.body">{{notification.body}}</div>
    </div>

    <!--Target Profile-->
    <div *ngIf="notification.targetProfile?.id !== currentProfile?.profileId" class="p-d-flex p-jc-center"
         pTooltip="Received to {{notification.targetProfile.displayName}}." i18n-pTooltip
         tooltipZIndex="1000000" tooltipPosition="bottom">
      <div class="delimiter p-mx-2"></div>
      <app-avatar [profile]="notification.targetProfile"
                  [sizePx]="35"
                  [showTooltip]="false"
                  [redirect]="false"></app-avatar>
    </div>
  </div>
</a>
