<!--All changes successfully saved-->
<div *ngIf="component?.responseSuccess && successMsg" @grow
     class="p-d-flex p-jc-center success-result rounded-medium {{styleClass}}"
     data-test="successful-response">
  <div class="p-d-flex p-ai-center">
    <i class="{{successIcon}} p-mr-2"></i>
    <p class="p-m-0" data-test="message">{{ successMsg }}</p>
  </div>
</div>

<!--Offline error-->
<div *ngIf="component?.disconnected; else unexpected" @grow
     class="p-d-flex p-jc-center failed-result rounded-medium {{styleClass}}"
     data-test="failed-response">
  <div class="p-d-flex p-ai-center">
    <i class="fa-solid fa-heart-crack p-mr-2"></i>
    <p class="p-m-0" data-test="message" i18n>You're offline.</p>
  </div>
</div>

<!--Unexpected error-->
<ng-template #unexpected>
  <div *ngIf="component?.responseError" @grow
       class="p-d-flex p-jc-center failed-result rounded-medium {{styleClass}}"
       data-test="failed-response">
    <div class="p-d-flex p-ai-center">
      <i class="{{errorIcon}} p-mr-2"></i>
      <p class="p-m-0" data-test="message">{{ errorMsg }}</p>
    </div>
  </div>
</ng-template>
<div class="call-selector" *ngIf="scrollOnResponse"></div>
