<div class="p-col-12 p-px-0 p-py-0 p-d-flex p-jc-center">
  <app-button type="text"
              label="Didn't get verification code ?"
              i18n-label
              icon="fa-solid fa-unlock-keyhole"
              [disabled]="saving"
              (clicked)="callRestoreVerificationCode()"></app-button>
</div>

<!--Response-->
<app-call-response [component]="this"
                   successMsg="New code was sent to your email."
                   i18n-successMsg
                   errorMsg="Failed to send new code."
                   i18n-errorMsg
                   styleClass="p-mt-4"></app-call-response>
