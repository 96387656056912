import {Injectable} from '@angular/core'
import {StorageItem, StorageService} from './storage.service'
import {ClarityAnalyticsService} from './analytics/clarity-analytics.service'
import {GoogleAnalyticsService} from './analytics/google-analytics.service'
import {ApiService} from './api.service'
import {BaseResp} from '../rest/base-resp'
import {BehaviorSubject, Observable} from 'rxjs'
import {Endpoint} from '../common/endpoints'
import {SentryAnalyticsService} from './analytics/sentry-analytics.service'
import {MetaService} from './analytics/meta.service'

@Injectable({
  providedIn: 'root'
})
export class CookiesSettingsService extends ApiService {

  /**
   * Maximum time for cookies settings validity in browser. 365 days.
   */
  private static readonly COOKIES_MAX_DAYS = 365
  /**
   * Maximum time for cookies validity in browser, when no user is logged. 1 day.
   */
  private static readonly COOKIES_MAX_DAYS_NOT_LOGGED = 1

  /**
   * Controls showing of dialog to edit settings of cookies.
   */
  editCookiesSettings = new BehaviorSubject<boolean>(null)

  /**
   * Boolean flag to determine whether user some user is logged in.
   * Based on this value, the expiration of cookies settings will be set.
   */
  isUserLogged = !this.jwtService.jwtNeedsRefresh() || this.jwtService.isJwtTokenPresent()

  constructor(
    private storageService: StorageService,
    private microsoftClarity: ClarityAnalyticsService,
    private sentry: SentryAnalyticsService,
    private googleAnalytics: GoogleAnalyticsService,
    private meta: MetaService) {
    super()
    this.loadAnalyticCookies()
  }

  /**
   * Emits 'true' to the {@link editCookiesSettings} which results in showing the cookie settings dialog.
   */
  showSettingsDialog(): void {
    this.editCookiesSettings.next(true)
  }

  /**
   * Makes a call to serer API to get current version of cookies document.
   */
  callGetCookiesVersion(): Observable<BaseResp<CookiesVersionResp>> {
    return this.get(Endpoint.COOKIES_VERSION_INFO_URL, this.getHeaders(), false)
  }

  /**
   * Saves {@link settings} with its items and version to the cookies under the key of {@link COOKIES_SETTINGS}.
   */
  saveSettings(settings: CookiesSettings): void {
    this.storageService.setCookie(
      StorageItem.COOKIES_SETTINGS,
      JSON.stringify(settings),
      '/',
      this.isUserLogged ? CookiesSettingsService.COOKIES_MAX_DAYS : CookiesSettingsService.COOKIES_MAX_DAYS_NOT_LOGGED
    )
    this.loadAnalyticCookies()
  }

  /**
   * - Returns all cookie settings from the local storage stored under the key of {@link COOKIES_SETTINGS}.
   * - Returns an empty array if no cookies were saved yet.
   */
  getSettings(): CookiesSettings {
    const settings = this.storageService.getCookie(StorageItem.COOKIES_SETTINGS)
    let parsed = settings ? JSON.parse(settings) : {version: -1, items: []}

    // to make sure it works fine with old structure of cookies storage
    // TODO: can be deleted in future versions.
    if (Array.isArray(parsed)) {
      parsed = {
        version: parsed.find(it => it.version !== undefined)?.version || -1,
        items: parsed.filter(it => it.version === undefined)
      }
    }

    return parsed
  }

  /**
   * Returns a version of cookies consent document, to which user agreed.
   */
  getVersion(): number {
    return this.getSettings()?.version || -1
  }

  /**
   * - Checks whether the cookie under the {@link name} is granted.
   * - Please, specify and use all names from the {@link CookiesSettingsService} class.
   */
  checkSetting(name: string): boolean {
    return this.getSettings().items.find(it => it.name === name)?.state || false
  }

  /**
   * Returns true whether cookies have been once accepted.
   */
  cookiesOnceAccepted(): boolean {
    return this.getSettings().items.length !== 0
  }

  /**
   * Accepts all cookie settings and saves them to the local storage
   * for the provided {@link cookiesVersion} from server.
   */
  acceptAll(cookiesVersion: number): void {
    this.saveSettings({
      version: cookiesVersion,
      items: Object.values(CookieOption).map(it => this.createSettingFromKey(it))
    })
  }

  /**
   * Clears all Cookies settings from the local storage.
   */
  clearAll(): void {
    this.storageService.deleteCookie(StorageItem.COOKIES_SETTINGS)
    this.storageService.deleteCookie(StorageItem.FIREBASE_REGISTER_REQ)
    this.microsoftClarity.enableClarity(false)
    this.googleAnalytics.enableGoogleAnalytics(false)
    this.sentry.enableSentry(false)
    this.meta.enableMetaPixel(false)
  }

  /**
   * Loads analytics tools based on stored {@link COOKIE_UMEVIA_ANALYTICS} setting.
   */
  loadAnalyticCookies(): void {
    this.googleAnalytics.enableGoogleAnalytics(this.checkSetting(CookieOption.COOKIE_GOOGLE_ANALYTICS))
    this.microsoftClarity.enableClarity(this.checkSetting(CookieOption.COOKIE_CLARITY_ANALYTICS))
    this.sentry.enableSentry(this.checkSetting(CookieOption.COOKIE_SENTRY_ANALYTICS))
    this.meta.enableMetaPixel(this.checkSetting(CookieOption.COOKIE_TARGET_META))
  }

  /**
   * After user logs into the application, it will set cookies setting expiration to 365 days.
   */
  updateExpirationAfterLogin(): void {
    this.isUserLogged = !this.jwtService.jwtNeedsRefresh() || this.jwtService.isJwtTokenPresent()
    this.saveSettings(this.getSettings())
  }

  /**
   * Creates the {@link CookiesItemSetting} from the {@link key} and sets the 'state' to true.
   */
  private createSettingFromKey(key: CookieOption): CookiesItemSetting {
    return {
      name: key,
      state: true
    }
  }

  /**
   * Increases or decreases count of opened tabs in storage
   * based on provided parameter.
   * @deprecated This method is here only for potential future usage. DON'T USE !!!
   */
  updateTabsCount(increase: boolean): void {
    let count = this.storageService.getItemStorage(StorageItem.OPENED_TABS) || (increase ? 0 : 1)
    this.storageService.setItemStorage(StorageItem.OPENED_TABS, (increase ? ++count : --count).toString())
  }
}

/**
 * A data structure in the local storage that represents a single cookie setting.
 */
export interface CookiesItemSetting {
  name: CookieOption | string
  state: boolean
}

/**
 * A data structure, that represents cookies settings state stored in cookies.
 */
export interface CookiesSettings {
  version: number
  items: CookiesItemSetting[]
}

/**
 * Interface for response about cookies version from server API.
 */
export interface CookiesVersionResp {
  version: number
}

/**
 * Represents all Cookies options in the Cookies dialog.
 */
export enum CookieOption {
  /**
   * All Umevia analytic cookies.
   */
  COOKIE_UMEVIA_ANALYTICS = 'umevia_analytics',
  /**
   * Using all functional umevia cookies.
   */
  COOKIE_UMEVIA_FUNCTIONAL = 'umevia_functional',
  /**
   * Usage of google accounts.
   */
  COOKIE_ACCOUNTS_GOOGLE = 'accounts_google',
  /**
   * Usage of facebook sharing functionallity,
   */
  COOKIE_FACEBOOK_FUNCTIONALITY = 'facebook_functionality',


  /**
   * Using google advertisements. - Google Ads
   */
  COOKIE_TARGET_GOOGLE = 'target_google',
  /**
   * Using advertisements in facebook. - Meta Pixel
   */
  COOKIE_TARGET_META = 'target_meta',
  /**
   * Using google analytics.
   */
  COOKIE_GOOGLE_ANALYTICS = 'google_analytics',
  /**
   * Using Microsoft clarity for heatmaps.
   */
  COOKIE_CLARITY_ANALYTICS = 'clarity_analytics',
  /**
   * Using Microsoft clarity for heatmaps.
   */
  COOKIE_SENTRY_ANALYTICS = 'sentry_analytics',
}
