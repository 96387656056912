import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {Page} from '../rest/page-resp'
import {BriefProfessionResp} from './profession.service'

@Injectable({
  providedIn: 'root'
})

export class PriceItemService extends ApiService {

  constructor() {
    super()
  }

  // PRICE ITEM - LAZY
  callGetPriceItemsFilter(req: LazyFilteredPriceItemsReq): Observable<BaseResp<Page<PriceItemResp>>> {
    return this.post(Endpoint.PRICE_ITEM_FILTER, req, super.getHeaders(), false)
  }

  callGetBriefPriceItems(req: LazyBriefItemsReq): Observable<BaseResp<Page<BriefPriceItemResp>>> {
    return this.post(Endpoint.PRICE_ITEM_GET_BRIEF, req)
  }

  callHasPriceItems(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PRICE_ITEM_HAS, null)
  }

  // PRICE ITEM
  callUpdatePriceItem(req: UpdatePriceItemReq): Observable<BaseResp<PriceItemResp>> {
    return this.post(Endpoint.PRICE_ITEM_UPDATE, req)
  }

  callGetPriceItem(req: GetPriceItemReq): Observable<BaseResp<PriceItemResp>> {
    return this.post(Endpoint.PRICE_ITEM_GET, req)
  }

  callGetMinPriceItem(profileId: number): Observable<BaseResp<PriceItemResp>> {
    return this.post(Endpoint.PRICE_ITEM_GET_MIN, profileId, super.getHeaders(), false)
  }

  callDeletePriceItem(req: DeletePriceItemReq): Observable<BaseResp<PriceItemResp>> {
    return this.post(Endpoint.PRICE_ITEM_DELETE, req)
  }

  // CATEGORY
  callGetAllCategories(): Observable<BaseResp<BriefPriceItemCategoryResp[]>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_ALL, null, super.getHeaders(), false)
  }

  callGetAllCategoriesOfProfile(profileId: number): Observable<BaseResp<BriefPriceItemCategoryResp[]>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_ALL_OF_PROFILE, profileId, super.getHeaders(), false)
  }

  callSearchPriceItemCategories(req: SearchPriceItemCategoryReq): Observable<BaseResp<Page<BriefPriceItemCategoryResp>>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_SEARCH, req, super.getHeaders(), false)
  }

  callGetMostUsedCategories(): Observable<BaseResp<BriefPriceItemCategoryResp[]>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_MOST_USED, null, super.getHeaders(), false)
  }

  callGetPriceItemCategoryProfessions(categoryId: number): Observable<BaseResp<BriefProfessionResp[]>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_PROFESSIONS, categoryId, super.getHeaders(), false)
  }

  callFindPriceItemCategoryBySlug(slug: string): Observable<BaseResp<PriceItemCategoryResp>> {
    return this.post(Endpoint.PRICE_ITEM_CATEGORY_FIND_BY_SLUG, slug, super.getHeaders(), false)
  }

  // ADDITION - LAZY
  callGetPriceItemAdditions(req: LazyPriceItemAdditionsReq): Observable<BaseResp<Page<PriceItemAdditionResp>>> {
    return this.post(Endpoint.PRICE_ITEM_ADDITION_ALL, req, super.getHeaders(), false)
  }

  // ADDITION
  callGetPriceItemAddition(req: GetPriceItemAdditionReq): Observable<BaseResp<PriceItemAdditionResp>> {
    return this.post(Endpoint.PRICE_ITEM_ADDITION_GET, req, super.getHeaders(), false)
  }

  callUpdatePriceItemAddition(req: UpdatePriceItemAdditionReq): Observable<BaseResp<PriceItemAdditionResp>> {
    return this.post(Endpoint.PRICE_ITEM_ADDITION_UPDATE, req)
  }

  callDeletePriceItemAddition(req: DeletePriceItemAdditionReq): Observable<BaseResp<PriceItemAdditionResp>> {
    return this.post(Endpoint.PRICE_ITEM_ADDITION_DELETE, req)
  }
}

export interface LazyFilteredPriceItemsReq {
  profileId: number
  categoryId?: number
  page: number
}

export interface LazyBriefItemsReq {
  profileId: number
  page: number
}

export interface LazyPriceItemAdditionsReq {
  profileId: number
  page: number
}

export interface GetPriceItemReq {
  id: number
}

export interface DeletePriceItemReq {
  id: number
}

export interface GetPriceItemAdditionReq {
  id: number
}

export interface DeletePriceItemAdditionReq {
  id: number
}

export interface UpdatePriceItemReq {
  /**
   * If the id is null the price item does not exist.
   */
  id?: number
  name: string
  description?: string
  fixedPrice: boolean // if true, then the amount of price is fixed, else the amount is per hour
  price: number
  categoriesIds: number[]
}

export interface PriceItemResp {
  id: number
  name: string
  description?: string
  fixedPrice: boolean // if true, then the amount of price is fixed, else the amount is per hour
  /**
   * Can be real, or modified for a customer.
   */
  price: number
  /**
   * Not modified price by a specific profile view.
   */
  realPrice: number
  categories: BriefPriceItemCategoryResp[]

  /**
   * UI only property, defines if the price item is selected.
   */
  selected: boolean
}

export interface OrderPriceItemResp {
  item: PriceItemResp
  /**
   * - Represents the amount of ordered item.
   * - Should be 1 by default.
   * - In per/hour mode, it represents how many hours it has being ordered.
   */
  quantity: number
}

export interface SearchPriceItemCategoryReq {
  name: string
  page?: number
}

export interface BriefPriceItemCategoryResp {
  id: number
  name: string
  description?: string
  abbreviation: string
  slug: string
  icon: string
  count: number

  /**
   * UI-only property.
   */
  selected?: boolean
}

export interface PriceItemCategoryResp {
  id: number
  name: string
  description?: string
  abbreviation: string
  slug: string
  icon: string
  count: number
}

export interface BriefPriceItemResp {
  /**
   * If the id is null, the price item does not exist.
   */
  id?: number
  name: string
  fixedPrice: boolean // if true, then the amount of price is fixed, else the amount is per hour
  /**
   * Can be real, or modified for a customer.
   */
  price: number
  /**
   * Not modified price by a specific profile view.
   */
  realPrice: number
}

// addition
export interface UpdatePriceItemAdditionReq {
  id?: number
  additionName: string
  additionPrice: number
}

export interface PriceItemAdditionResp {
  /**
   * If the id is null, the price item addition does not exist.
   */
  id?: number
  name: string
  /**
   * Can be real, or modified for a customer.
   */
  price: number
  /**
   * Not modified price by a specific profile view.
   */
  realPrice: number
  count: number

  /**
   * UI only property, defines if the price item addition is selected.
   */
  selected: boolean
}
