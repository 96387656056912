import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {email, maxLength, minLength, noBlankCharacters, onlyNumbers, pattern, phone, url} from '../../../../validator/custom.validators'
import {FrontendValidationComponent} from '../../frontend-validation/frontend-validation.component'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {growAnimation} from '../../../../animation/grow.animation'
import {AbstractFormField} from '../abstract-form-field'
import {ReactiveFormsModule} from '@angular/forms'
import {VarDirective} from '../../../../directive/var.directive'
import {NgIf, NgTemplateOutlet} from '@angular/common'
import {TooltipModule} from 'primeng/tooltip'
import {InputTextareaModule} from 'primeng/inputtextarea'
import {InputTextModule} from 'primeng/inputtext'
import {debounceTime, Subscription} from 'rxjs'
import {BackendValidationComponent} from '../../backend-validation/backend-validation.component'

/**
 * This component is used as a set of attributes to display the <input> element.
 * Validation, hints and other content can be defined inside the component's selector.
 */
@Component({
  animations: [fadeAnimation(), growAnimation(100)],
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  imports: [
    ReactiveFormsModule,
    VarDirective,
    NgIf,
    TooltipModule,
    NgTemplateOutlet,
    InputTextareaModule,
    FrontendValidationComponent,
    InputTextModule,
    BackendValidationComponent
  ],
  standalone: true
})
export class TextInputComponent extends AbstractFormField implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /**
   * The type of the input element.
   */
  @Input()
  inputType = 'text'
  /**
   * The native HTML input ID attribute.
   */
  @Input()
  id: string
  /**
   * The native HTML input autocomplete attribute.
   */
  @Input()
  autocomplete = 'on'
  /**
   * Min value while {@link inputType} = number.
   */
  @Input()
  min: string | number | null
  /**
   * Changes this component from the input to the text area.
   */
  @Input()
  textArea: boolean
  /**
   * Count of input characters
   */
  inputCharCount: number
  /**
   * Selects all input text after a user clicked into that field.
   */
  @Input()
  selectText: boolean
  /**
   * Enables the input sanitization from HTML elements. Enabled by default.
   */
  @Input()
  sanitize = true
  /**
   * - Adds the 'pattern()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'pattern' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  pattern: RegExp
  /**
   * - Adds the 'notPattern()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'notPattern' error within the
   * <app-text-input> selector tags.
   */
  @Input()
  notPattern: RegExp
  /**
   * - Adds the 'noBlankCharacters()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'blankCharacters' error within the
   * <app-text-input> selector tags.
   */
  @Input()
  noBlankCharacters: boolean
  /**
   * - Adds the 'email()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'email' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  email: boolean
  /**
   * - Adds the 'notEmail()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'notEmail' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  notEmail: boolean
  /**
   * - Adds the 'phone()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'phone' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  phone: boolean
  /**
   * - Adds the 'notPhone()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'notPhone' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  notPhone: boolean
  /**
   * - Adds the 'url()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'url' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  url: boolean
  /**
   * - Adds the 'notUrl()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'notUrl' error within the <app-text-input>
   * selector tags.
   */
  @Input()
  notUrl: boolean
  /**
   * - Adds the 'onlyNumbers()' validator to the {@link formFieldName}, if it isn't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'onlyNumbers' error within the
   * <app-text-input> selector tags.
   */
  @Input()
  onlyNumbers: boolean
  /**
   * Adds several validators to protect the input field of having the email, url, and phone present.
   */
  @Input()
  notContact: boolean
  /**
   * - Adds a length validators to the {@link formFieldName}, if they aren't already added.
   * - Furthermore, it displays a default message to the user if the condition doesn't meet.
   * But only if you don't already declare your own {@link FrontendValidationComponent} of the 'length' error within the <app-text-input>
   * selector tags.
   * - The 'min/max' property represents the minimum/maximum allowed characters for this field.
   */
  @Input()
  length?: { min?: number; max?: number }
  /**
   * Number of rows
   */
  @Input()
  rows = 10

  /**
   * Emits when the input element lost the focus.
   */
  @Output()
  focusLost = new EventEmitter<any>()

  @ViewChild('input')
  input: ElementRef<HTMLInputElement>
  /**
   * The parent element of this component.
   * (Must be public to be accessible outside of this component)
   */
  @ViewChild('wrapper')
  wrapper: ElementRef<HTMLDivElement>

  @ViewChild('textArea')
  inputArea: ElementRef<HTMLTextAreaElement>
  /**
   * Disables the default 'blankCharacters' error message because the user has his own implementation.
   */
  disableNoBlankCharacters: boolean
  /**
   * Disables the default 'pattern' error message because the user has his own implementation.
   */
  disablePattern: boolean
  /**
   * Disables the default 'notPattern' error message because the user has his own implementation.
   */
  disableNotPattern: boolean
  /**
   * Disables the default 'email' error message because the user has his own implementation.
   */
  disableEmail: boolean
  /**
   * Disables the default 'notEmail' error message because the user has his own implementation.
   */
  disableNotEmail: boolean
  /**
   * Disables the default 'phone' error message because the user has his own implementation.
   */
  disablePhone: boolean
  /**
   * Disables the default 'notPhone' error message because the user has his own implementation.
   */
  disableNotPhone: boolean
  /**
   * Disables the default 'url' error message because the user has his own implementation.
   */
  disableUrl: boolean
  /**
   * Disables the default 'notUrl' error message because the user has his own implementation.
   */
  disableNotUrl: boolean
  /**
   * Disables the default 'length' error message because the user has his own implementation.
   */
  disableLength: boolean
  /**
   * Disables the default 'onlyNumbers' error message because the user has his own implementation.
   */
  disableOnlyNumbers: boolean

  /**
   * The current value subscription.
   */
  private valueSub: Subscription

  constructor() {
    super()
  }

  override ngOnInit(): void {
    super.ngOnInit()

    if (this.sanitize) {
      this.startSanitization()
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes)
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit()

    // triggers the initial validation when the value is present
    if (this.form.value[this.formFieldName]) {
      setTimeout(() => {
        this.form.controls[this.formFieldName].markAsTouched()
        this.form.controls[this.formFieldName].markAsDirty()
        this.triggerValidations()
      }, 100) // give some time to initialize the form value
    }

    // focuses the input field on demand
    if (this.focus) {
      this.input?.nativeElement.focus()
      if (this.textArea) {
        this.inputArea?.nativeElement.focus()
      }
    }
  }

  override doFocus(): void {
    this.input?.nativeElement.focus()
    if (this.textArea) {
      this.inputArea?.nativeElement.focus()
    }
  }

  /**
   * Manually triggers validators.
   */
  private triggerValidations(): void {
    const control = this.form.controls[this.formFieldName]
    const errors = control.errors || {}

    // return when no value is present
    if (!control.value) {
      return
    }

    if (this.notContact) {
      if (email(true)(control)) {
        errors['notEmail'] = true
      }
      if (url(true)(control)) {
        errors['notUrl'] = true
      }
      if (phone(true)(control)) {
        errors['notPhone'] = true
      }
      this.form.controls[this.formFieldName].markAsTouched()
      this.form.controls[this.formFieldName].markAsDirty()

      if (Object.keys(errors).length !== 0) {
        this.form.controls[this.formFieldName].setErrors(errors)
      } else {
        this.form.controls[this.formFieldName].setErrors(null)
      }
    }
  }


  /**
   * Counts number of written characters into input field
   */
  onKeyUp(event): void {
    if (this.input) {
      this.inputCharCount = this.length?.max - this.input.nativeElement.value.length
    } else if (this.inputArea) {
      this.inputCharCount = this.length?.max - this.inputArea.nativeElement.textLength
    }
    this.triggerValidations()
    this.keyupEvent.emit(event)
  }


  /**
   * Fires when a user clicked on the input field.
   */
  onInputClick(event): void {
    this.clickEvent.emit(event)
    if (this.selectText) {
      this.input.nativeElement.select()
    }
  }

  override ngOnDestroy(): void {
    this.valueSub?.unsubscribe()
    super.ngOnDestroy()
  }

  /**
   * Initializes the validators of this {@link formFieldName}.
   */
  protected override initValidators(): void {
    super.initValidators()

    // No blank characters
    if (this.noBlankCharacters) {
      this.addValidator(noBlankCharacters())
    }

    // Email
    if (this.email) {
      this.addValidator(email())
    } else if (this.notEmail || this.notContact) {
      this.addValidator(email(true))
    }

    // Phone
    if (this.phone) {
      this.addValidator(phone())
    } else if (this.notPhone || this.notContact) {
      this.addValidator(phone(true))
    }

    // Url
    if (this.url) {
      this.addValidator(url())
    } else if (this.notUrl || this.notContact) {
      this.addValidator(url(true))
    }

    // Min Length
    if (this.length?.min > -1) {
      this.addValidator(minLength(this.length.min))
    }

    // Max Length
    if (this.length?.max > -1) {
      this.addValidator(maxLength(this.length.max))
    }

    // Regex Pattern
    if (this.pattern) {
      this.addValidator(pattern(this.pattern))
    } else if (this.notPattern) {
      this.addValidator(pattern(this.notPattern, true))
    }

    // Only numbers
    if (this.onlyNumbers) {
      this.addValidator(onlyNumbers())
    }
  }

  /**
   * Disables the default messages of the validation errors based on the {@link frontendValidations}.
   */
  protected override disableDefaultValidators(): void {
    super.disableDefaultValidators()

    for (const valid of this.frontendValidations) {
      switch (valid.error) {
        case 'blankCharacters':
          this.disableNoBlankCharacters = true
          break
        case 'pattern':
          this.disablePattern = true
          break
        case 'notPattern':
          this.disableNotPattern = true
          break
        case 'email':
          this.disableEmail = true
          break
        case 'notEmail':
          this.disableNotEmail = true
          break
        case 'phone':
          this.disablePhone = true
          break
        case 'notPhone':
          this.disableNotPhone = true
          break
        case 'url':
          this.disableUrl = true
          break
        case 'notUrl':
          this.disableNotUrl = true
          break
        case 'length':
          this.disableLength = true
          break
        case 'onlyNumbers':
          this.onlyNumbers = true
          break
      }
    }
  }

  /**
   * Sanitizes the input value from HTML tags with a debounced time of 200ms.
   */
  private startSanitization(): void {
    this.valueSub?.unsubscribe()
    this.valueSub = this.form.controls[this.formFieldName].valueChanges.pipe(debounceTime(200)).subscribe((value: string) => {
      const fixed = value.replace(/<[^>]*>/g, '')
      if (fixed === value) { // Returns when no change is needed
        return
      }
      // must fire updated value to other subscribers
      this.form.controls[this.formFieldName].setValue(fixed)
    })
  }
}
