import {Component, EventEmitter, Input, Output} from '@angular/core'
import {ProfileResp} from '../../../service/profile.service'
import {AbstractComponent} from '../../abstract/abstract.component'

@Component({
  selector: 'app-profile-cta',
  templateUrl: './profile-cta.component.html',
  styleUrl: './profile-cta.component.scss'
})
export class ProfileCtaComponent extends AbstractComponent {

  @Input({required: true})
  profile: ProfileResp

  /**
   * Emits when a user has to be scrolled to the offer component.
   */
  @Output()
  scrollToOffer = new EventEmitter<any>()
}
