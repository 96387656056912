<div class="desktop-content p-px-3 h-100 rounded-medium"
     *ngIf="alwaysVisible"
     #desktopContentContainer>
  <ng-template [ngTemplateOutlet]="content"/>

  <!--Reset all Filters-->
  <div class="p-d-flex p-jc-center">
    <app-button type="secondary"
                icon="fa-solid fa-arrow-rotate-left"
                styleClass="p-my-2"
                label="Clear"
                i18n-label
                (clicked)="resetFilters()"/>
  </div>
  <div class="desktop-last-el"></div>

  <ng-template [ngTemplateOutlet]="footer"/>
</div>

<!--Artist category sidebar-->
<p-sidebar #sidebar
           *ngIf="!alwaysVisible"
           [(visible)]="sidebarVisible"
           (visibleChange)="!$event ? hideSidebarAndSearch() : null"
           styleClass="filter-sidebar"
           [baseZIndex]="11000">

  <app-custom-navbar title="Filters"
                     i18n-title
                     styleClass="p-jc-between"
                     (returnAction)="hideSidebarAndSearch()">
    <!--Reset Button-->
    <app-button type="secondary"
                icon="fa-solid fa-arrow-rotate-left"
                label="Reset"
                i18n-label
                [labelAutoHide]="true"
                styleClass="p-ml-2 reset-filters-btn"
                (clicked)="resetFilters()"/>

    <!--Apply Search-->
    <app-button type="primary"
                icon="fa-solid fa-check"
                label="Apply"
                i18n-label
                styleClass="p-mx-2 apply-filters"
                (clicked)="hideSidebarAndSearch()"/>
  </app-custom-navbar>

  <div class="p-px-2 content p-pt-2"
       appSwipe (swipeLeft)="hideSidebarAndSearch()">
    <ng-template [ngTemplateOutlet]="content"/>

    <ng-template [ngTemplateOutlet]="footer"/>
  </div>
</p-sidebar>

<ng-template #content>
  <section class="categories rounded-medium">
    <div class="p-d-flex p-ai-center p-jc-between unselectable c-pointer" (click)="toggleDateFilter()">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-calendar"></i>
        <h2 i18n>Date</h2>
      </div>

      <!--Visual buttons-->
      <app-button *ngIf="dateFormVisible"
                  type="icon"
                  icon="fa-solid fa-circle-xmark danger-icon"
                  label="Clear"
                  i18n-label
                  styleClass="p-button-danger"
                  pTooltip="Clear Date Filter"
                  i18n-pTooltip
                  tooltipPosition="left"/>

      <app-button *ngIf="!dateFormVisible"
                  type="icon"
                  icon="fa-solid fa-plus"
                  pTooltip="Filter by Date"
                  i18n-pTooltip
                  tooltipPosition="left"/>
    </div>

    <!--Incomplete badge-->
    <div class="filter-not-set-badge p-d-flex p-ai-center p-my-1"
         *ngIf="dateFormVisible && lazyComponentVisible && (!startDate || !endDate) && sidebarVisible" @grow
         pTooltip="All fields must be filled." i18n-pTooltip tooltipPosition="right">
      <i class="fa-solid fa-circle-info p-mr-2"></i>
      <span i18n>
        Fill all fields
      </span>
    </div>

    <!--Book Form-->
    <div class="p-mt-3" *ngIf="dateFormVisible && sidebarVisible" @grow>
      <app-profile-catalog-book-form [initialStartDate]="initialStartDate"
                                     (startDate)="startDate = $event; onFilterChange()"
                                     [initialEndDate]="initialEndDate"
                                     (endDate)="endDate = $event; onFilterChange()"/>
    </div>
  </section>


  <section class="categories rounded-medium">
    <!--Profession-->
    <div class="p-d-flex p-ai-center p-jc-between unselectable c-pointer"
         (click)="professionSearchVisible = true; changeRef.detectChanges()">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-user-tie"></i>
        <h2 i18n>Profession</h2>
      </div>
      <app-button type="icon"
                  icon="fa-solid fa-plus"/>
    </div>
    <!--Source-->
    <ng-template [ngTemplateOutlet]="selectedItems"
                 [ngTemplateOutletContext]="{selected: selectedProfessions, remove: removeProfession.bind(this)}"/>
  </section>


  <section class="categories rounded-medium">
    <!--Skills-->
    <div class="p-d-flex p-ai-center p-jc-between unselectable c-pointer"
         (click)="skillSearchVisible = true; changeRef.detectChanges()">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-user-graduate"></i>
        <h2 i18n>Skills</h2>
      </div>
      <app-button type="icon"
                  icon="fa-solid fa-plus"/>
    </div>
    <!--Source-->
    <ng-template [ngTemplateOutlet]="selectedItems"
                 [ngTemplateOutletContext]="{selected: selectedSkills, remove: removeSkill.bind(this)}"/>
  </section>

  <section class="categories rounded-medium">
    <!--Genres-->
    <div class="p-d-flex p-ai-center p-jc-between unselectable c-pointer"
         (click)="genreSearchVisible = true; changeRef.detectChanges()">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-signs-post"></i>
        <h2 i18n>Genres</h2>
      </div>

      <app-button type="icon"
                  icon="fa-solid fa-plus"/>
    </div>
    <!--Source-->
    <ng-template [ngTemplateOutlet]="selectedItems"
                 [ngTemplateOutletContext]="{selected: selectedGenres, remove: removeGenre.bind(this)}"/>
  </section>

  <!--Count-->
  <section class="categories rounded-medium">
    <div class="p-d-flex p-ai-center">
      <i class="fa-solid fa-people-group"></i>
      <h2 i18n>Count</h2>
    </div>

    <!--Checkboxes-->
    <ul class="p-d-flex">
      <li *ngFor="let item of countFilters" class="p-mr-4">
        <app-checkbox [(check)]="item.selected"
                      (checkChange)="onFilterChange()"
                      [label]="item.name"/>
      </li>
    </ul>
  </section>

  <!--PRICE-->
  <section class="categories rounded-medium">
    <div class="p-d-flex p-ai-center">
      <i class="fa-solid fa-euro-sign p-mr-2"></i>
      <h2 i18n>Price</h2>
    </div>
    <div class="price-label p-mb-3">
      {{ priceRange[0] || 0 }} - {{ priceRange[1] || 0 }}<span *ngIf="priceRange[1] === maxPrice">+</span> €
    </div>
    <div class="p-px-2 p-pb-3 price-slider">
      <p-slider [(ngModel)]="priceRange"
                (ngModelChange)="onFilterChange()"
                [range]="true"
                [step]="100"
                [max]="maxPrice"/>
    </div>
  </section>

  <!--LOCATION-->
  <section class="categories rounded-medium p-mb-3">
    <div class="p-d-flex p-ai-center p-jc-between unselectable c-pointer" (click)="toggleMapFilter()">
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-location-dot p-mr-2"></i>
        <h2 i18n>Location</h2>
      </div>

      <!--Visual buttons-->
      <app-button *ngIf="mapFilterVisible"
                  type="icon"
                  icon="fa-solid fa-circle-xmark danger-icon"
                  label="Clear"
                  i18n-label
                  styleClass="p-button-danger"
                  pTooltip="Clear Location Filter"
                  i18n-pTooltip
                  tooltipPosition="left"/>

      <app-button *ngIf="!mapFilterVisible"
                  type="icon"
                  icon="fa-solid fa-plus"
                  pTooltip="Filter by Location"
                  i18n-pTooltip
                  tooltipPosition="left"/>
    </div>

    <!--Incomplete badge-->
    <div class="filter-not-set-badge p-d-flex p-ai-center p-my-1"
         *ngIf="mapFilterVisible && lazyComponentVisible && !radiusMarker?.latLng && sidebarVisible" @grow
         pTooltip="No location is provided." i18n-pTooltip tooltipPosition="right">
      <i class="fa-solid fa-circle-info p-mr-2"></i>
      <span i18n>
        No location
      </span>
    </div>

    <div *ngIf="mapFilterVisible && sidebarVisible" @grow class="p-pb-2">
      <!--Map Search-->
      <div class="w-100 p-mt-2">
        <app-map-search
          [show]="sidebarVisible"
          [resultAsOverlay]="true"
          [autoClickScroll]="false"
          (addressOption)="addressChange($event)">
        </app-map-search>
      </div>

      <!--Map-->
      <div class="map-container rounded-medium p-py-3" *ngIf="lazyComponentVisible">
        <app-map [radiusMarker]="radiusMarker"
                 (newMarkerAdded)="locationChange($event)"
                 [filterButton]="false"
                 [explore]="false"
                 [search]="false"
                 [watchUserLocation]="true"
                 [zoom]="11"
                 [minZoom]="8">
        </app-map>
      </div>

      <app-hint [collapsableHint]="false" i18n>
        You can adjust the location by tapping on the map.
      </app-hint>
    </div>
  </section>
</ng-template>

<!--Genres Searching-->
<app-attribute-search-dialog *ngIf="genreSearchVisible"
                             [(show)]="genreSearchVisible"
                             [(selectedItems)]="selectedGenres"
                             (selectedItemsChange)="onFilterChange()"
                             type="genres"
                             dialogHeader="Filter by Genres"
                             i18n-dialogHeader
></app-attribute-search-dialog>

<!--Skill Searching-->
<app-attribute-search-dialog *ngIf="skillSearchVisible"
                             [(show)]="skillSearchVisible"
                             [(selectedItems)]="selectedSkills"
                             (selectedItemsChange)="onFilterChange()"
                             type="skills"
                             dialogHeader="Filter by Skills"
                             i18n-dialogHeader
></app-attribute-search-dialog>

<!--Profession Searching-->
<app-attribute-search-dialog *ngIf="professionSearchVisible"
                             [(show)]="professionSearchVisible"
                             [(selectedItems)]="selectedProfessions"
                             (selectedItemsChange)="onFilterChange()"
                             type="professions"
                             dialogHeader="Filter by Professions"
                             i18n-dialogHeader
></app-attribute-search-dialog>

<!--Categories Searching-->
<app-attribute-search-dialog *ngIf="categorySearchVisible"
                             [(show)]="categorySearchVisible"
                             [(selectedItems)]="selectedCategories"
                             (selectedItemsChange)="onFilterChange()"
                             type="price-item-categories"
                             dialogHeader="Filter by Categories"
                             i18n-dialogHeader
></app-attribute-search-dialog>

<!--Selected Item Template-->
<ng-template #selectedItems
             let-selected="selected"
             let-remove="remove">
  <!--Selected Items-->                               <!--sidebarVisible triggers the animation-->
  <div class="p-grid selected-items-container p-pb-2" *ngIf="selected?.length > 0 && sidebarVisible" @grow>
    <div *ngFor="let item of selected" class="p-col-fixed p-p-0 p-mt-2 p-mr-2">
      <div class="selected-category p-d-flex p-ai-center c-pointer unselectable" (click)="remove(item)">
        <div>{{ item.name }}</div>
        <i class="fa-solid fa-xmark p-ml-2"></i>
      </div>
    </div>
  </div>
</ng-template>

<!--Footer-->
<ng-template #footer>
  <!--Divider line-->
  <hr class="divider p-my-2">

  <!--Footer-->
  <footer class="p-d-flex p-jc-center w-100 p-mb-3">

    <!--Terms of use, etc.-->
    <div class="p-d-flex p-flex-column p-ai-center p-px-2">
      <p>&copy; {{ todayDate | date: 'yyyy' }} Umevia s.r.o.</p>
      <p i18n>All rights reserved.</p>

      <!--Social Links-->
      <div class="p-d-flex p-ai-center brands p-py-1">
        <a [href]="contactInfo.instagram" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-instagram p-mr-3"></i>
        </a>
        <a [href]="contactInfo.x" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-x-twitter p-mr-3"></i>
        </a>
        <a [href]="contactInfo.youtube" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-youtube p-mr-3"></i>
        </a>
        <a [href]="contactInfo.facebook" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-facebook p-mr-3"></i>
        </a>
        <a [href]="contactInfo.linkedin" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-linkedin p-mr-3"></i>
        </a>
        <a [href]="contactInfo.docs" target="_blank" rel="noopener noreferrer">
          <i class="fa-solid fa-book"></i>
        </a>
      </div>

      <!--Legal Links-->
      <div class="p-d-flex">
        <div><a class="p-px-1" [routerLink]="navigation.urlLegalDocuments('privacy')" i18n>Privacy</a></div>
        <div>•<a class="p-px-1" [routerLink]="navigation.urlLegalDocuments('terms')" i18n>Terms</a></div>
        <div>•<a class="p-px-1" (click)="cookies.showSettingsDialog()" i18n>Cookies</a></div>
      </div>
    </div>
  </footer>
</ng-template>
