<!--Shows only stars rating and number of reviews-->
<div class="p-d-flex p-ai-center c-pointer" (click)="show = true">
  <app-rating [stars]="avgRating"></app-rating>
  <span class="count" *ngIf="countVisible && profile?.rating.ratingCount > 0">
    ({{ profile?.rating.ratingCount || 0 }})
  </span>
</div>

<!--Preview-->
<app-dialog *ngIf="show && withDetails && profile?.rating.ratingCount > 0"
            (discard)="closeEditComponent()"
            [(show)]="show"
            type="info"
            [allowSaveOption]="false"
            [component]="this"
            [draggable]="false"
            url="reviews"
            header="Reviews"
            i18n-header
            discardLabel="Close"
            i18n-discardLabel>

  <div class="reviews-dialog">
    <app-lazy-list [(items)]="reviews"
                   [component]="this"
                   [virtual]="false"
                   [itemHeight]="200"
                   [maxScrollHeight]="60*16"
                   [loadFunction]="callGetProfileReviews.bind(this)">

      <!--Item-->
      <ng-template let-review pTemplate="item">
        <div class="p-mb-3">
          <!--Author info-->
          <section class="p-d-flex p-m-2">
            <div class="p-sm-8 p-d-flex">
              <app-avatar [profile]="review.author"
                          [redirect]="false"
                          class="p-mr-3"></app-avatar>

              <div class="p-d-flex p-flex-column p-ai-start">
                <app-profile-names [charIdVisible]="false"
                                   [displayNameLinesCount]="1"
                                   [profile]="review.author"
                                   [redirect]="false"
                                   [size]="4"
                                   class="p-d-flex p-ai-center">
                </app-profile-names>
                <div class="p-d-flex p-flex-row p-ai-center">
                  <!--Rating on small screen-->
                  <app-rating *ngIf="!isScreenOf(Screen.SM)"
                              class="p-mr-2"
                              [stars]="review.stars"></app-rating>
                  <!--Date-->
                  <span class="p-d-flex p-ai-center date-font-size">
                        {{ review.modifiedAt | date: 'dd.MM. yyyy' }}</span>
                </div>
              </div>
            </div>

            <!--Rating on large screen-->
            <div *ngIf="isScreenOf(Screen.SM)"
                 class="p-col-4 p-d-flex p-jc-end p-ai-center">
              <app-rating [stars]="review.stars"></app-rating>
            </div>

          </section>

          <!--Text-->
          <div class="p-pb-3 p-mx-3">
            <i *ngIf="review.autoRated" i18n>(Auto rated)</i>
            <ng-template [ngIf]="review.text && !review.autoRated">
              <article #content [class.l-count-3]="!review['unwrapped']" id="content">
                {{ review.text }}
              </article>

              <!--Read more / less button-->
              <div *ngIf="content.clientHeight >= 77">
                <u (click)="review['unwrapped'] = !review['unwrapped']"
                   class="p-d-flex p-ai-center c-pointer p-text-bold">
                  {{ review['unwrapped'] ? 'read less' : 'read more' }}</u>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>

      <!--Skeletons-->
      <ng-template pTemplate="skeleton">
        <div *ngFor="let i of [].constructor(2)" class="p-mb-6">
          <div class="p-d-flex p-jc-between">
            <div class="p-d-flex p-ai-center p-mb-2">
              <p-skeleton shape="circle" height="50px" width="50px"></p-skeleton>
              <div class="p-ml-2">
                <p-skeleton width="10rem" height="1.2rem" styleClass="rounded-small"></p-skeleton>
                <p-skeleton width="8rem" height="1rem" styleClass="rounded-small p-mt-2"></p-skeleton>
              </div>
            </div>

            <!--Rating-->
            <div *ngIf="isScreenOf(Screen.SM)">
              <p-skeleton width="5rem" height="1rem" styleClass="rounded-small"></p-skeleton>
            </div>
          </div>
          <p-skeleton width="100%" height="1rem" styleClass="rounded-small p-mt-2"></p-skeleton>
          <p-skeleton width="90%" height="1rem" styleClass="rounded-small p-mt-1"></p-skeleton>
          <p-skeleton width="80%" height="1rem" styleClass="rounded-small p-mt-1"></p-skeleton>
        </div>
      </ng-template>
    </app-lazy-list>
  </div>

</app-dialog>
