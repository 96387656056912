import {Component, OnInit} from '@angular/core'
import {EditableComponent} from '../abstract/editable.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {StorageItem, StorageService} from '../../service/storage.service'
import {addDays, fixDateObjects} from '../../utils/date.utils'
import {growAnimation} from '../../animation/grow.animation'
import {PlatformRatingService} from '../../service/platform-rating.service'
import {firstValueFrom} from 'rxjs'
import {DeviceService} from '../../service/helper-services/device.service'
import {environment} from '../../../environments/environment'

/**
 * A randomly pop-up-ing dialog, to rate this platform.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-platform-rating',
  templateUrl: './platform-rating.component.html',
  styleUrl: './platform-rating.component.scss'
})
export class PlatformRatingComponent extends EditableComponent implements OnInit {

  /**
   * A source of input values.
   */
  form: FormGroup
  /**
   * Current stars rating number.
   */
  stars: number
  /**
   * - The ID of already sent rating request.
   * - If this is specified, it means, that in the next {@link send} call, this review will be updated.
   */
  reviewId: number

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private deviceService: DeviceService,
    private platformRatingService: PlatformRatingService) {
    super()
  }

  ngOnInit(): void {
    this.initForm()

    // Init on new devices
    this.getRating()

    // try to show the dialog no earlier than X minutes from the start
    setTimeout(() => {
      const rating = this.getRating()
      if (rating?.expiresAt <= new Date()) {
        this.showDialog(true)
      }
    }, environment.platformRating.showAfterMinutes * 60 * 1000)

    // Trigger the platform rating outside of this component, does not need to unsubscribe
    this.platformRatingService.showPlatformRating.subscribe((show) => {
      this.showDialog(show)
    })
  }

  /**
   * - Returns a current {@link PlatformRating} object information from the localstorage.
   * - Saves a new object, when the item was not found.
   */
  private getRating(): PlatformRating | null {
    const item = this.storageService.getItemStorage(StorageItem.PLATFORM_RATING)
    if (!item) {
      this.postponeRating()
      return null
    }
    // Parse & fix date objects
    const req: PlatformRating = JSON.parse(item) as PlatformRating
    fixDateObjects(req)
    return req
  }

  /**
   * Shows the rating dialog if the {@link show} is true, otherwise, it closes.
   */
  private showDialog(show: boolean): void {
    if (environment.name !== 'e2e') {
      this.show = show
    }
  }

  /**
   * Postpones the next rating popup.
   * @param fromClose Whether this function was fired by user closing the dialog or providing rating.
   */
  private postponeRating(fromClose: boolean = false): void {
    let item = this.storageService.getItemStorage(StorageItem.PLATFORM_RATING)
    if (!item) {
      (item as PlatformRating) = {
        expiresAt: addDays(new Date(), environment.platformRating.daysUntilFirstShow),
        count: 0
      }
    } else {
      // re-init, parse & fix date objects
      item = this.getRating() // returns not null
    }

    // If the rating is present, increase the next rating date
    if (this.reviewId) {
      item.count++
    }

    // by the count of reviews, the device gave, increase the next day up to 7
    switch (item.count) {
      case 0:
        const days = fromClose ? 2 : environment.platformRating.daysUntilFirstShow
        item.expiresAt = addDays(new Date(), days)
        break
      case 1:
        item.expiresAt = addDays(new Date(), 4)
        break
      case 2:
      default:
        item.expiresAt = addDays(new Date(), 7)
    }

    const str = JSON.stringify(item)
    this.storageService.setItemStorage(StorageItem.PLATFORM_RATING, str, false)
  }

  /**
   * Fires when the user closes the dialog, or press the 'Later' button.
   */
  onClose(): void {
    this.postponeRating(true)
    this.show = false
  }

  /**
   * - Sends a new review to the server API.
   * - This function can be called twice.
   * - For the first time, only the 'stars' rating is sent, while for the second time, the optional details can be added.
   */
  async send(): Promise<void> {
    const txt = this.form.value.text
    const fun = async (): Promise<void> => {
      // assign reviewId of a stars-only rating for the second time, where the details can be added.
      this.reviewId = await firstValueFrom(this.unwrap(this.platformRatingService.callNewPlatformRating({
        id: this.reviewId,
        stars: this.stars,
        text: txt,
        device: this.deviceService.getDeviceObject()
      })))
    }

    if (txt) {
      await this.callAndFinish(fun)
      // Close dialog
      if (this.noServerMessages()) {
        setTimeout(() => {
          this.postponeRating()
          this.closeAndReset()
        }, 1000)
      }
    } else {
      await this.call(fun)
    }
  }


  /**
   * Initializes the {@link form}.
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      text: ['']
    })
  }

  /**
   * Closes the dialog and resets all properties.
   */
  private closeAndReset(): void {
    this.show = false
    this.initForm()
    this.resetApi()
    this.reviewId = undefined
    this.stars = undefined
  }
}

/**
 * A structure of the platform rating object information in a local storage.
 */
interface PlatformRating {
  expiresAt: Date
  count: number
}
