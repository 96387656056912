import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {ProfileResp} from '../../../service/profile.service'
import {PriceItemService} from '../../../service/price-item.service'
import {firstValueFrom, Subscription} from 'rxjs'
import {EditableComponent} from '../../abstract/editable.component'
import {fadeAnimation} from '../../../animation/fade.animation'
import {CompletionType, ProfileCompletionService} from '../../../service/profile-completion.service'

@Component({
  animations: [fadeAnimation()],
  selector: 'app-profile-attributes',
  templateUrl: './profile-attributes.component.html',
  styleUrl: './profile-attributes.component.scss'
})
export class ProfileAttributesComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The currently previewing profile.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * A joint array of all {@link skills}, {@link genres}, {@link piCategories}.
   */
  attributes: Attribute[] = []
  /**
   * All assigned skills to the {@link profile}.
   */
  skills: Attribute
  /**
   * All assigned genres to the {@link profile}.
   */
  genres: Attribute
  /**
   * All assigned price item categories to the {@link profile}.
   */
  piCategories: Attribute
  /**
   * Opens an edit dialog to edit skills.
   */
  editSkillsDialogVisible: boolean
  /**
   * Opens an edit dialog to edit genres.
   */
  editGenresDialogVisible: boolean
  /**
   * Shows a large dialog with all attributes
   */
  attributesPreviewDialogVisible: boolean
  /**
   * All subscriptions than need to be unsubscribed.
   */
  private subs: Subscription[] = []

  readonly defaultSlice = 5

  constructor(
    private priceItemService: PriceItemService,
    public profileCompletionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    this.initShowListener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile?.currentValue) {
      this.initAttributes()
    }

    if (changes.editButtonVisible?.currentValue === false) {
      // Load categories only for visitors
      this.loadCategories()
    }
  }

  /**
   * Initializes all available attributes of the {@link profile}.
   */
  private initAttributes(): void {
    // Genres
    this.attributes = []
    this.genres = {
      id: 'genres',
      icon: 'fa-solid fa-microphone-lines',
      title: $localize`Genres`,
      noContentDescription: $localize`If it is relevant to your profession, you can specify the genres you are primarily focusing on.`,
      items: this.profile.genres,
      onEdit: () => {
        this.editGenresDialogVisible = true
      }
    }
    this.pushAttribute(this.genres)

    // Skills
    this.skills = {
      id: 'skills',
      icon: 'fa-solid fa-masks-theater',
      title: $localize`Skills`,
      noContentDescription: $localize`If it is relevant to your profession, you can specify skills of art you have.`,
      items: this.profile.skills,
      onEdit: () => {
        this.editSkillsDialogVisible = true
      }
    }
    this.pushAttribute(this.skills)
  }

  /**
   * Fires when the accordion opens a new tab.
   */
  onAccordionChange(index: number): void {
    this.attributes.forEach(it => it.expanded = false)
    this.attributes[index].expanded = true
  }

  /**
   * Loads price item categories of the {@link profile}.
   */
  private loadCategories(): void {
    this.call(async () => {
      const items = await firstValueFrom(this.unwrap(this.priceItemService.callGetAllCategoriesOfProfile(this.profile.profileId)))
      this.piCategories = {
        id: 'categories',
        icon: 'fa-solid fa-calendar-check',
        title: $localize`Categories`,
        items: items,
        expanded: true
      }
      this.pushAttribute(this.piCategories, true)
    })
  }

  /**
   * - Pushes the {@link attr} into the {@link attributes} array.
   * - {@link start} - Whether the attribute should be inserted at index 0, otherwise on the last.
   */
  private pushAttribute(attr: Attribute, start: boolean = false): void {
    if (this.editButtonVisible || attr.items.length > 0) {
      if (start) {
        this.attributes.splice(0, 0, attr)
        return
      }
      this.attributes.push(attr)
    }
  }

  /**
   * Subscribes for show changes from the {@link ProfileCompletionService}.
   */
  private initShowListener(): void {
    this.subs.push(this.profileCompletionService.completionTypeShow.subscribe((type) => {
      switch (type) {
        case CompletionType.GENRES:
          this.editGenresDialogVisible = true
          break
        case CompletionType.SKILLS:
          this.editSkillsDialogVisible = true
          break
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.forEach((it) => it?.unsubscribe())
  }
}

interface Attribute {
  id: 'genres' | 'skills' | 'categories'
  icon: string
  title: string
  noContentDescription?: string
  items: any[]
  expanded?: boolean
  onEdit?: () => void
}
