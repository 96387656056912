import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core'
import {fadeAnimation} from '../../../animation/fade.animation'
import {ProfileResp} from '../../../service/profile.service'
import {EditableComponent} from '../../abstract/editable.component'
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs'
import {ServerMessage} from '../../../common/server-message'
import {GetWallpaperEditResp, UpdateWallpapersReq, WallpaperService} from '../../../service/wallpaper.service'
import {ImageService} from '../../../service/ui/image.service'
import {getFeature, ProfileType} from '../../../common/profile-type'
import {createRandomUUID, isArrayNullOrEmpty} from '../../../utils/utils'
import {isFileImage} from '../../../utils/file.utils'
import {Restrictions} from '../../../common/restrictions'
import {getDeviceMatchWidthWallpaper} from '../../../utils/device.utils'
import {Feature} from 'src/app/common/feature'
import {Carousel} from 'primeng/carousel'
import {PLATFORM_BROWSER} from '../../../app.module'

@Component({
  animations: [fadeAnimation(500)],
  selector: 'app-upload-wallpaper',
  templateUrl: './upload-wallpaper.component.html',
  styleUrls: ['./upload-wallpaper.component.scss']
})
export class UploadWallpaperComponent extends EditableComponent implements OnChanges, OnDestroy {

  @Input()
  data: ProfileResp

  /**
   * All wallpapers from the {@link data}.
   */
  wallpapers: GetWallpaperEditResp[] = []

  /**
   * The maximum number of wallpapers for the profile.
   */
  maxWallpapers: number

  /**
   * Returns a new array of wallpaper URLs if the user has changed his wallpapers.
   */
  @Output()
  changed = new EventEmitter<string[]>()

  /**
   * Represents the carousel component.
   */
  @ViewChild('carousel')
  carousel?: Carousel

  /**
   * Represents a state when a user has clicked on the reorder button.
   */
  onReorderClicked: boolean

  /**
   * Contains the information about the minimum required image edges.
   */
  requiredSizes: number[] = []
  /**
   * The current image index within the {@link wallpapers} to be removed.
   */
  deleteConfirmDialogImageIndex: number
  /**
   * Controls the delete image dialog visibility.
   */
  deleteConfirmDialogVisible: boolean
  /**
   * Determines whether a new image was uploaded to the server.
   */
  private imagesUploaded = new BehaviorSubject<boolean[]>([])

  /**
   * The {@link imagesUploaded} subscription
   */
  private sub: Subscription

  constructor(
    private wallpaperService: WallpaperService,
    private imageService: ImageService) {
    super()
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.data?.currentValue) {
      this.initProperties()

      if (!isArrayNullOrEmpty(this.data.wallpapers)) {
        await this.getWallpapersForEdit()
      }
    }
  }

  /**
   * Gets all currently active profile wallpapers.
   */
  private async getWallpapersForEdit(): Promise<void> {
    await this.call(async () => {
      // Load all active wallpapers
      const resp = await firstValueFrom(this.callGetWallpapersForEdit())
      if (resp) {
        this.initWallpapers(resp)
      }
    })
  }

  /**
   * Fires when a user clicked on the save button.
   */
  async onComponentSave(): Promise<void> {
    await this.waitForUpload()

    // Update the wallpapers
    const resp = await firstValueFrom(this.callUpdate())
    if (resp && this.noServerMessages()) {
      this.changed.emit(resp)
    }
  }

  /**
   * Fires when a user selected a file.
   */
  onFileSelect(file: File): void {
    if (!file) { // return if no file selected
      return
    }

    // if selected file is not an image, return
    if (!isFileImage(file)) {
      this.resetApi()
      this.pushToMessages(ServerMessage.FILE_FORMAT_NOT_SUPPORTED)
      return
    }

    // Compress the image on front-end.
    this.call(async () => {
      try {
        const compressed = await firstValueFrom(this.imageService.fitTo(
          file,
          Restrictions.MIN_IMAGE_WALLPAPER_WIDTH,
          this.ImageVariant.WALLPAPER_COMPRESS_SIZE.width,
          this.ImageVariant.WALLPAPER_COMPRESS_SIZE.height)
        )

        if (compressed) {
          this.resetApi()
          await this.addImage(compressed)
        }
      } catch (error) {
        this.pushToMessages(error)
      }
    })
  }


  /**
   * - If the {@link wallpapers} contain more than 1 wallpaper, the confirm dialog will be shown and the result will
   * continue inside the {@link onRemoveConfirm} function.
   * - Otherwise, it will remove the image via the {@link removeImage} function immediately.
   */
  onRemove(index: number): void {
    // the carousel is null, null index, return
    if (index === null) {
      return
    }
    // remove directly
    if (this.wallpapers?.length === 1) {
      this.removeImage(index)
      return
    }

    // show delete confirm dialog
    if (this.wallpapers?.length > 1) {
      this.deleteConfirmDialogImageIndex = index
      this.deleteConfirmDialogVisible = true
    }
  }

  /**
   * If the user confirms to remove selected image.
   */
  onRemoveConfirm(confirm: boolean): void {
    if (confirm) {
      this.removeImage(this.deleteConfirmDialogImageIndex)
    }
    this.deleteConfirmDialogImageIndex = null
  }

  /**
   * - Adds a new image to the wallpapers.
   * - It also takes into account the {@link maxWallpapers} property.
   * - Furthermore, it calls the server API to upload the image.
   */
  async addImage(compressed: File): Promise<void> {
    // image file name
    const imageUuid = createRandomUUID()
    // reads the file as the data URL
    const dataUrl = await this.imageService.imageFileToURL(compressed)

    // limit exceeded
    if (this.maxWallpapers > 1 && this.wallpapers.length >= this.maxWallpapers) {
      return
    }

    // adds the image to the 'wallpapers' property.
    this.addWallpaper(dataUrl, imageUuid)

    // upload
    const resp = await firstValueFrom(this.callPostNewWallpaper(compressed, imageUuid))
    if (resp) {
      this.onImageUploaded(imageUuid)
    }
  }


  /**
   * Sets the `'true'` value in the {@link imagesUploaded} array at the position of the {@link imageUuid} in the {@link wallpapers} array.
   */
  onImageUploaded(imageUuid: string): void {
    for (let i = 0; i < this.wallpapers.length; i++) {
      const image = this.wallpapers[i]

      if (image.uuid === imageUuid) {
        const arr = this.imagesUploaded.getValue()
        arr[i] = true
        this.imagesUploaded.next(arr)
      }
    }
  }

  /**
   * Reloads the array of wallpapers.
   */
  reloadWallpapers(): void {
    this.wallpapers = [...this.wallpapers]
  }

  /**
   * Removes the wallpaper image from the {@link wallpapers}.
   *
   * @param index A specific index position.
   */
  private removeImage(index: number): void {
    this.wallpapers.splice(index, 1)
    this.reloadWallpapers()

    const arr = [...this.imagesUploaded.getValue()]
    arr.splice(index, 1)
    this.imagesUploaded.next(arr)
  }

  /**
   * Initializes the {@link maxWallpapers} property by the profile type.
   */
  private initProperties(): void {
    const type = this.data.profileType
    this.maxWallpapers = getFeature(type, Feature.WALLPAPER).max

    if (type === ProfileType.EVENT) {
      this.requiredSizes = [
        Restrictions.MIN_IMAGE_WALLPAPER_EVENT_SIZE,
        Restrictions.MIN_IMAGE_WALLPAPER_EVENT_SIZE
      ]
    } else {
      this.requiredSizes = [
        Restrictions.MIN_IMAGE_WALLPAPER_WIDTH,
        Restrictions.MIN_IMAGE_WALLPAPER_HEIGHT
      ]
    }
  }

  /**
   * - Initializes the {@link wallpapers} field by the given {@link resp}.
   * - Also, it updates the {@link imagesUploaded} property to a new array of `'true'` values.
   */
  private initWallpapers(resp: GetWallpaperEditResp[]): void {
    // Clear previous wallpapers
    this.wallpapers = []

    const imagesUploaded: boolean[] = []
    // display appropriate size
    if (PLATFORM_BROWSER) {
      const imageVariant = getDeviceMatchWidthWallpaper()
      resp.forEach(w => {
        w.url = `${w.url}/${imageVariant}`
        imagesUploaded.push(true)
      })
      this.imagesUploaded.next(imagesUploaded)
      this.wallpapers = resp
    }
  }

  /**
   * - Adds a new wallpaper to the {@link wallpapers} property.
   *
   * @param dataUrl - Image data
   * @param imageUuid - Image filename
   */
  private addWallpaper(dataUrl: string, imageUuid: string): void {
    // new wallpaper object
    const newWallpaper: GetWallpaperEditResp = {
      url: dataUrl,
      uuid: imageUuid
    }

    // replace wallpaper
    if (this.maxWallpapers === 1) {
      this.imagesUploaded.next([false])
      this.wallpapers = [newWallpaper]

    } else { // add wallpaper
      // inserts the next element of 'false' value.
      this.imagesUploaded.next([...this.imagesUploaded.getValue(), false])
      // inserts new wallpaper
      this.wallpapers = [...this.wallpapers, newWallpaper]
    }
  }

  /**
   * Waits until all wallpapers gets properly uploaded.
   */
  private async waitForUpload(): Promise<void> {
    return new Promise<void>(resolve => {
      this.sub = this.imagesUploaded.subscribe(async (arr: boolean[]) => {
        // wait until every image will be uploaded
        if (!arr.some(it => it === false)) {
          return resolve()
        }
      })
    })
  }

  /**
   * Makes a request on the API to update all wallpapers.
   */
  private callUpdate(): Observable<string[]> {
    const req: UpdateWallpapersReq = {
      uuids: this.wallpapers.map(w => w.uuid)
    }
    return this.unwrap(this.wallpaperService.callUpdateWallpapers(req))
  }

  /**
   * Calls the API to upload a new wallpaper.
   */
  private callPostNewWallpaper(imageFile: File, imageUuid: string): Observable<boolean> {
    return this.unwrap(this.wallpaperService.callPostNewWallpaper(imageFile, imageUuid))
  }

  /**
   * Downloads the wallpapers images of the profile.
   */
  private callGetWallpapersForEdit(): Observable<GetWallpaperEditResp[]> {
    return this.unwrap(this.wallpaperService.callGetWallpapersForEdit())
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe()
  }
}
