import {ServerMessage} from '../common/server-message'
import {environment} from '../../environments/environment'

/**
 * - Returns a device geolocation if the user has granted the geolocation permission.
 * - (Use the {@link requestPermission} function to request the 'geolocation' permission first)
 * @param highAccuracy Returns the high accuracy value. This parameter requires more time and energy consumption.
 */
export function getDeviceGeolocation(highAccuracy: boolean = false): Promise<GeolocationPosition> {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation) {
      return reject(ServerMessage.FEATURE_NOT_SUPPORTED)
    }

    navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      (err) => reject(err),
      {
        timeout: 10_000,
        enableHighAccuracy: highAccuracy,
        maximumAge: 0
      }
    )
  })
}

/**
 * Requests a notification permission from the user.
 */
export function requestNotificationPermission(): Promise<NotificationPermission> {
  if (notificationsNotSupported()) {
    if (!environment.production) {
      console.warn('Notification not supported!')
    }
    return Promise.resolve('denied')
  }

  if (Notification.permission !== 'granted') {
    return Notification.requestPermission()
  }
}

/**
 * Checks if notifications are allowed on users browser.
 */
export function notificationsNotSupported(): boolean {
  return !('Notification' in window)
}

/**
 * Writes the string value to the user's clipboard.
 */
export function toClipboard(value: string): Promise<void> {
  return navigator.clipboard.writeText(value)
}
