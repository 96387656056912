import {isBlank} from '../utils/utils'
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms'
import {dateCut, dateJoin} from '../utils/date.utils'

// eslint-disable-next-line no-control-regex
export const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
// eslint-disable-next-line no-control-regex
export const notEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

// CUSTOMIZED FOR SLOVAK PHONE NUMBERS !
// Original regex: /^((\+421[0-9]{9})|(09[0-9]{8})|([\+]?[(]?(?!421)(?!09)[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}))$/im
export const phoneRegex = /^(\+4219[0-9]{8})$/im
// Original regex: /((\+421[0-9]{9})|(09[0-9]{8})|([\+]?[(]?(?!421)(?!09)[0-9]{1,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}))/im
export const notPhoneRegex = /((\+421[0-9]{9})|(09[0-9]{8})|([+]?[(]?(?!421)(?!09)[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}))/im

export const notPhonePrepareRegex = /[^\d]|[\uD800-\uDBFF][\uDC00-\uDFFF]|\s/g

export const genreRegex = /[^a-zA-Z0-9-/ ]/
export const skillRegex = /[^a-zA-Z/ ]/
export const professionRegex = /[^a-zA-Z/ ]/
export const hashtagRegex = /[^a-zA-Z0-9]/
export const youtubeRegex = /([a-zA-Z0-9_-]+\.)?(youtube(\.com)?|youtu\.be)(\/[a-zA-Z0-9_-]+)?/
export const soundcloudRegex = /<iframe.*src=["'](https?:\/\/?w\.soundcloud\.com\/.*)["'].*<\/iframe>/
export const spotifyRegex = /([a-zA-Z0-9_-]+\.)?(open\.)?spotify(\.com)?(\/[a-zA-Z0-9_-]+)?/
export const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/
export const notUrlRegex = /(https?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const priceRegex = /^(\d+)(,\d+|\.\d+)?$/
export const noBlankRegex = /^\S*$/
export const charIdRegex = /[^a-zA-Z0-9._]/
export const containsNumberRegex = /\d/
export const onlyNumbersRegex = /^\d+$/

/**
 * - Validates whether the provided char id is valid. If the id is not valid, it returns the {charId: true} object.
 */
export function charId(): ValidatorFn {
  return doRegexValidation(charIdRegex, false, 'charId', 'notCharId', true)
}

/**
 * - Validates whether the provided value complies to be an email. Returns <code>{email: true}</code> if it is not an email.
 * Otherwise,null is returned.
 * - If the {@link inverted} parameter is specified, it will return the <code>{notEmail: true}</code>
 * error if the value contains a valid email.
 */
export function email(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(inverted ? notEmailRegex : emailRegex, inverted, 'email', 'notEmail')
}

/**
 * - Validates whether the provided url is a valid url. If the url is not valid, it returns the <code>{url: true}</code> object.
 * - If the {@link inverted} parameter is specified, it will return the <code>{notUrl: true}</code> error if the value contains a valid url.
 */
export function url(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(inverted ? notUrlRegex : urlRegex, inverted, 'url', 'notUrl')
}

/**
 * - Validates the provided price. If the price is not valid, it returns the <code>{price: true}</code> object.
 * - If the {@link inverted} parameter is specified, it will return the <code>{notPrice: true}</code>
 * error if the value contains a valid price.
 */
export function price(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(priceRegex, inverted, 'price', 'notPrice')
}

/**
 * Validates whether the given value contains at least one digit.
 * - Returns <code>{containsNumber: true}</code> when the value does NOT contain a number.
 * - Returns <code>{notContainsNumber: true}</code> when the value contain a number.
 */
export function containsNumber(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(containsNumberRegex, inverted, 'containsNumber', 'notContainsNumber')
}

/**
 * Validates whether the given value contains only digits.
 * - Returns <code>{onlyNumbers: true}</code> when the value does NOT fully consist of digits.
 * - Returns <code>{notOnlyNumbers: true}</code> when the value contains numbers.
 */
export function onlyNumbers(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(onlyNumbersRegex, inverted, 'onlyNumbers', 'notOnlyNumbers')
}

/**
 * - Validates whether the provided phone number is valid. If the number is not valid, it returns the {phone: true} object.
 * - If the {@link inverted} parameter is specified, it will return the <code>{notPhone: true}</code>
 * error if the value contains a valid phone.
 */
export function phone(inverted: boolean = false): ValidatorFn {
  return doRegexValidation(inverted ? notPhoneRegex : phoneRegex, inverted, 'phone', 'notPhone', false, (value) => {
      if (inverted) {
        return value.replace(notPhonePrepareRegex, '')
      }
      return value
    }
  )
}

/**
 * Validates whether the provided genre name is valid.
 * If the genre is not valid, it returns the {genre: true} object.
 */
export function genre(): ValidatorFn {
  return doRegexValidation(genreRegex, false, 'genre', 'notGenre', true)
}

/**
 * Validates whether the provided skill name is valid.
 * If the skill is not valid, it returns the {skill: true} object.
 */
export function skill(): ValidatorFn {
  return doRegexValidation(skillRegex, false, 'skill', 'notSkill', true)
}

/**
 * Validates whether the provided profession name is valid.
 * If the profession is not valid, it returns the {profession: true} object.
 */
export function profession(): ValidatorFn {
  return doRegexValidation(professionRegex, false, 'profession', 'notProfession', true)
}

/**
 * Validates whether the provided hashtag name is valid.
 * If the hashtag is not valid, it returns the {hashtag: true} object.
 */
export function hashtag(): ValidatorFn {
  return doRegexValidation(hashtagRegex, false, 'hashtag', 'notHashtag', true)
}

/**
 * Validates whether the provided string value has not blank characters.
 * If the value is not valid, it returns the {blankCharacters: true} object.
 */
export function noBlankCharacters(): ValidatorFn {
  return doRegexValidation(noBlankRegex, false, 'blankCharacters', 'notBlankCharacters')
}

/**
 * - Returns the 'pattern' error if the value is not null and not matches the given {@link regex}.
 * - Returns the 'notPattern' error if the parameter {@link inverted} is specified and the value
 * contains a valid string by the {@link regex}.
 */
export function pattern(regex: RegExp, inverted: boolean = false): ValidatorFn {
  return doRegexValidation(regex, inverted, 'pattern', 'notPattern')
}

/**
 * Validates whether the provided string value has the minimum length of characters.
 * The string value is being trimmed before the check.
 * Returns null if everything is correct. If doesn't, it returns the object of {length: true}.
 *
 * @param length The minimum length of a value.
 */
export function minLength(length: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const text: string = (control.value as string).trim()
    if (text.length >= length) {
      return null
    } else {
      return {length: true}
    }
  }
}

/**
 * Validates whether the provided string value has the maximum length of characters.
 * The string value is being trimmed before the check.
 * Returns null if everything is correct. If doesn't, it returns the object of {length: true}.
 *
 * @param length The maximum length of a value.
 */
export function maxLength(length: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const text: string = (control.value as string).trim()
    if (text.length <= length) {
      return null
    } else {
      return {length: true}
    }
  }
}

/**
 * Validates whether two provided form control values match.
 * Returns {mustMatch: true} if the values are not the same. Otherwise, null is returned.
 *
 * @param controlName The first control form name.
 * @param matchingControlName The second control form name.
 */
export function mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName]
    const matchingControl = formGroup.controls[matchingControlName]

    // return null if controls are not initialized yet
    if (!control || !matchingControl) {
      return null
    }

    // return null if another validator has already found an error on the matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true})
    } else {
      matchingControl.setErrors(null)
    }
  }
}

/**
 * Validates whether two provided form control values not match.
 * Returns {mustNotMatch: true} if the values are the same. Otherwise, null is returned.
 *
 * @param controlName The first control form name.
 * @param matchingControlName The second control form name.
 */
export function mustNotMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName]
    const matchingControl = formGroup.controls[matchingControlName]

    // return null if controls are not initialized yet
    if (!control || !matchingControl) {
      return null
    }

    // return null if another validator has already found an error on the matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null
    }

    // set error on matchingControl if validation fails
    if (control.value === matchingControl.value) {
      matchingControl.setErrors({mustNotMatch: true})
    } else {
      matchingControl.setErrors(null)
    }
  }
}

/**
 * Validates whether the second date is after the first date.
 * Sets the {dateAfter: true} on the {@link secondDateControlName} if the values are incorrect. Otherwise, null is set.
 *
 * @param firstDateControlName The first control form name.
 * @param secondDateControlName The second control form name.
 */
export function dateMustBeAfter(firstDateControlName: string, secondDateControlName: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const firstDateForm = formGroup.controls[firstDateControlName]
    const secondDateForm = formGroup.controls[secondDateControlName]

    // return null if controls are not initialized yet
    if (!firstDateForm.value || !secondDateForm.value) {
      return null
    }

    const firstDate = dateCut(firstDateForm.value, 'h')
    const secondDate = dateCut(secondDateForm.value, 'h')

    // set error on secondDateForm if validation fails
    if (firstDate > secondDate) {
      secondDateForm.setErrors({dateAfter: true})
    } else {
      secondDateForm.setErrors(null)
    }
  }
}

/**
 * Validates whether the second date is after the first date, and the second time is after the first time.
 * Sets the {dateAfter: true} on the {@link secondTimeControlName} if the values are incorrect. Otherwise, null is set.
 *
 * @param firstDateControlName The first date control form name.
 * @param secondDateControlName The second date control form name.
 * @param firstTimeControlName The first time control form name.
 * @param secondTimeControlName The second time control form name.
 * @param msOffset Represents the minimum duration between the start-date and the end-date.
 */
export function dateTimeAfterDateTime(
  firstDateControlName: string,
  secondDateControlName: string,
  firstTimeControlName: string,
  secondTimeControlName: string,
  msOffset: number = 0): ValidatorFn {
  return (formGroup: FormGroup) => {
    const firstDateForm = formGroup.controls[firstDateControlName]
    const secondDateForm = formGroup.controls[secondDateControlName]
    const firstTimeForm = formGroup.controls[firstTimeControlName]
    const secondTimeForm = formGroup.controls[secondTimeControlName]

    // return null if controls are not initialized yet
    if (!firstDateForm.value || !secondDateForm.value || !firstTimeForm.value || !secondTimeForm.value) {
      return null
    }

    const firstDate = firstDateForm.value
    const secondDate = secondDateForm.value
    const firstTime = firstTimeForm.value
    const secondTime = secondTimeForm.value

    const first = dateJoin(firstDate, firstTime)
    const second = dateJoin(secondDate, secondTime)

    // set error on secondTimeForm if validation fails
    if (second.getTime() - (first.getTime()) < msOffset) {
      secondTimeForm.setErrors({dateAfter: true})
    } else {
      secondTimeForm.setErrors(null)
    }
  }
}

/**
 * Validates whether the provided formDate is after the date parameter.
 * Returns null if everything is correct. If it doesn't, it returns the object of {minDate: true}.
 *
 * @param date The date that the form date must be after or the same.
 */
export function minDate(date: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const formDate: Date = new Date(control.value)
    if (formDate < date) {
      return {minDate: true}
    } else {
      return null
    }
  }
}

/**
 * - Returns a validation function that tries to match the given input value with the {@link regex}.
 * If the value doesn't match, it returns the <code>{normalError: true}</code>.
 *
 * - If the {@link inverted} parameter is enabled, the function does the opposite.
 * Returns the <code>{invertedError: true}</code> if the value matches the given {@link regex}.
 *
 * - The parameter {@link invertRegex} tells the regex to match everything except the given {@link regex}.
 * It can be helpful when you want to find the first other occurrence of other character than within the regex.
 */
function doRegexValidation(
  regex: RegExp,
  inverted: boolean,
  normalError: string,
  invertedError: string,
  invertRegex: boolean = false,
  prepareValue: (value: string) => string = null
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value: string = control.value.toString().trim()
    if (prepareValue) {
      value = prepareValue(value)
    }
    const result = {}
    if (inverted) {
      if ((invertRegex) ? !regex.test(value) : regex.test(value)) {
        result[`${invertedError}`] = true
        return result
      }
    } else {
      if (!isBlank(value) && ((invertRegex) ? regex.test(value) : !regex.test(value))) {
        result[`${normalError}`] = true
        return result
      }
    }
    return null
  }
}
