import {Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core'
import {PLATFORM_BROWSER} from '../app.module'

/**
 * - Visually disables the current element by the {@link disable} value.
 * - Performs the 'click' ({@link enabledClick}) event only if the {@link disable} property is false.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disable]',
  standalone: true
})
export class DisableDirective implements OnInit, OnChanges, OnDestroy {

  /**
   * Visually disables the current element.
   */
  @Input()
  disable: boolean

  /**
   * Fires when a user clicked on the element and the {@link disable} property is false.
   */
  @Output()
  enabledClick = new EventEmitter<any>()

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit(): void {
    this.eleRef.nativeElement.addEventListener('click', this.onClick.bind(this))
    this.applyStyles()
  }

  ngOnChanges(): void {
    this.applyStyles()
  }

  /**
   * Emits the {@link enabledClick} only if the {@link component}'s loading property is false.
   */
  private onClick(): void {
    if (!this.disable) {
      this.enabledClick.emit()
    }
  }

  /**
   * Applies custom disabled styles to the element.
   */
  private applyStyles(): void {
    if (this.disable) {
      this.eleRef.nativeElement.classList.add('disabled-element')
    } else {
      this.eleRef.nativeElement.classList.remove('disabled-element')
    }
  }

  ngOnDestroy(): void {
    if (PLATFORM_BROWSER) {
      this.eleRef.nativeElement.removeAllListeners()
    }
  }
}
