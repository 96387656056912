import {Component} from '@angular/core'
import {RegisterReq} from '../../../service/user.service'
import {fadeAnimation} from '../../../animation/fade.animation'
import {growAnimation} from '../../../animation/grow.animation'
import {blockHistory} from '../../../utils/utils'
import {ProfileType} from '../../../common/profile-type'
import {RegisterTraditionalAbstractComponent} from '../abstract/register-traditional-abstract.component'

/**
 * Register component used as a single page. Logic is implemented in the abstract class.
 */
@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends RegisterTraditionalAbstractComponent {

  /**
   * Creates and inits the input form with validators.
   */
  override initForm(): void {
    super.initForm()

    this.formSub = this.form.valueChanges.subscribe(() => {
      if (!this.blockReturn) {
        blockHistory()
      }
      this.blockReturn = true
    })
  }

  createRegisterReq(formData: any): RegisterReq {
    return {
      displayName: formData.displayName.trim(),
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      profileType: (formData.selectedCategory.key === 'artist') ? ProfileType.ARTIST : ProfileType.USER,
      password: formData.password
    }
  }
}


