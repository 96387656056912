import {Component, EventEmitter, Output} from '@angular/core'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {CallResponseComponent} from '../../../component/common/call-response/call-response.component'
import {TextInputComponent} from '../../../component/common/form/text-input/text-input.component'
import {TwoFactorCodeRestoreComponent} from '../two-factor-code-restore/two-factor-code-restore.component'
import {TwoFactorAbstractComponent} from '../abstract/two-factor-abstract.component'
import {UserResp} from '../../../service/user.service'

/**
 * Two-factor component which can be embedded in other components. Doesn't contain aby redirections and only emits events.
 */
@Component({
  selector: 'app-two-factor-embed',
  standalone: true,
  imports: [
    BackendValidationComponent,
    CallResponseComponent,
    TextInputComponent,
    TwoFactorCodeRestoreComponent
  ],
  templateUrl: './two-factor-authentication-embed.component.html',
  styleUrl: './two-factor-authentication-embed.component.scss'
})
export class TwoFactorAuthenticationEmbedComponent extends TwoFactorAbstractComponent {

  /**
   * Event emitted when the user successfully verified the code.
   */
  @Output()
  codeVerification: EventEmitter<UserResp> = new EventEmitter<UserResp>()

  afterCodeVerification(user: UserResp): void {
    this.codeVerification.emit(user)
  }
}
