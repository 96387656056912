import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ProfileResp} from '../../../service/profile.service'
import {
  BriefPriceItemResp,
  DeletePriceItemReq,
  LazyBriefItemsReq,
  PriceItemResp,
  PriceItemService,
  UpdatePriceItemReq
} from '../../../service/price-item.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {Observable} from 'rxjs/internal/Observable'
import {Page} from 'src/app/rest/page-resp'
import {firstValueFrom} from 'rxjs'
import {price} from '../../../validator/custom.validators'
import {growAnimation} from '../../../animation/grow.animation'
import {fadeAnimation} from '../../../animation/fade.animation'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-price',
  templateUrl: './profile-price.component.html',
  styleUrls: ['./profile-price.component.scss']
})
export class ProfilePriceComponent extends EditableComponent implements OnChanges {

  /**
   * Source of profile data.
   */
  @Input()
  data: ProfileResp

  /**
   * The current price information.
   */
  priceItem?: BriefPriceItemResp

  /**
   * The price form.
   */
  form: FormGroup

  constructor(
    private priceItemService: PriceItemService,
    private formBuilders: FormBuilder,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.initPrice()
    }
  }

  showEditComponent(): void {
    this.initForm()
    super.showEditComponent()
    this.changeRef.detectChanges()
  }

  /**
   * Fires when the user wants to delete the price item.
   */
  deletePriceItem(): void {
    this.call(async () => {
      const resp = await firstValueFrom(this.callDeletePriceItem())
      if (resp && this.noServerMessages()) {
        this.priceItem = null
      }
      this.evaluateAndFinish()
    }, () => {
      this.evaluateAndFinish()
    })
  }

  /**
   * Fires when the user wants to save his changes.
   */
  async onComponentSave(): Promise<void> {
    const resp = await firstValueFrom(this.callUpdatePriceItem())
    if (resp) {
      if (!this.priceItem) {
        this.priceItem = {
          name: resp.name,
          price: resp.price,
          realPrice: resp.price,
          id: resp.id,
          fixedPrice: true
        }
      } else {
        this.priceItem.price = resp.price
        this.priceItem.name = resp.name
      }
    }
  }

  /**
   * Initializes the {@link priceItem} from the server.
   */
  private async initPrice(): Promise<void> {
    const resp = await firstValueFrom(this.callGetPriceItem())
    if (resp && resp.content[0]) {
      this.priceItem = resp.content[0]
    }
    this.initForm()
  }

  /**
   * Calls the server API to get the price item.
   */
  private callGetPriceItem(): Observable<Page<BriefPriceItemResp>> {
    const req: LazyBriefItemsReq = {
      profileId: this.data.profileId,
      page: 0
    }
    return this.unwrap(this.priceItemService.callGetBriefPriceItems(req))
  }

  /**
   * Calls the server API to update the price item.
   */
  private callUpdatePriceItem(): Observable<PriceItemResp> {
    const formData = this.form.value
    const req: UpdatePriceItemReq = {
      id: this.priceItem?.id,
      categoriesIds: [],
      price: formData.price,
      name: formData.name?.trim(),
      fixedPrice: true
    }
    return this.unwrap(this.priceItemService.callUpdatePriceItem(req))
  }

  /**
   * Calls the server API to delete the price item.
   */
  private callDeletePriceItem(): Observable<PriceItemResp> {
    const req: DeletePriceItemReq = {
      id: this.priceItem.id
    }
    return this.unwrap(this.priceItemService.callDeletePriceItem(req))
  }

  /**
   * Initializes the {@link form} instance.
   */
  private initForm(): void {
    this.form = this.formBuilders.group({
      name: [this.priceItem?.name || ''],
      price: [this.priceItem?.price || '', [
        price()
      ]]
    })
  }

}
