import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {addHours, dateCut} from '../../../../utils/date.utils'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {ApiComponent} from '../../../abstract/api.component'
import {RadiusMarker} from '../../../map/map.helper'
import {LatLngExpression} from 'leaflet'
import {LeafletService} from '../../../../service/ui/leaflet.service'
import {SearchSkillReq, SkillResp, SkillService} from '../../../../service/skill.service'
import {firstValueFrom, map, Observable} from 'rxjs'
import {BriefProfessionResp, ProfessionService, SearchProfessionReq} from '../../../../service/profession.service'
import {GenreRest, GenreService, SearchGenreReq} from '../../../../service/genre.service'
import {SearchFilterProfilesReq} from '../../../../service/profile.service'
import {appendNewPage, newEmptyPage, Page} from 'src/app/rest/page-resp'
import {BaseResp} from '../../../../rest/base-resp'
import {growAnimation} from '../../../../animation/grow.animation'
import {
  BriefPriceItemCategoryResp,
  PriceItemService,
  SearchPriceItemCategoryReq
} from '../../../../service/price-item.service'
import {BookingService} from '../../../../service/ui/booking.service'
import {StorageItem, StorageService} from '../../../../service/storage.service'
import {Sidebar} from 'primeng/sidebar'
import {SnackbarService} from '../../../../service/ui/snackbar.service'
import {NavigationService} from '../../../../service/ui/navigation.service'
import {CookiesSettingsService} from '../../../../service/cookies-settings.service'
import {SupportContact} from '../../../support/support.component'
import {environment} from '../../../../../environments/environment'
import {PixelCategory, PixelService} from '../../../../service/analytics/meta-pixel/pixel.service'
import {PriceItemCategoryDetail} from '../price-item-categories/price-item-categories.component'
import {ActivatedRoute} from '@angular/router'
import {Location} from '@angular/common'
import {clearUrl} from '../../../../utils/router.utils'
import {CityResp} from '../../../../service/city.service'

@Component({
  animations: [fadeAnimation(100), growAnimation()],
  selector: 'app-profile-catalog-filter',
  templateUrl: './profile-catalog-filter.component.html',
  styleUrls: ['./profile-catalog-filter.component.scss']
})
export class ProfileCatalogFilterComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * Represents a visual radius on a map in meters.
   */
  static readonly RADIUS_IN_METERS = 10_000

  /**
   * Returns the request from the selected filters, ready for the API.
   */
  @Output()
  request = new EventEmitter<SearchFilterProfilesReq>()

  /**
   * - Makes the component always visible.
   * - This includes firing the {@link request} in any change.
   */
  @Input()
  alwaysVisible: boolean

  /**
   * Controls the visibility of the searching dialog for genres.
   */
  genreSearchVisible: boolean
  /**
   * Controls the visibility of the searching dialog for skills.
   */
  skillSearchVisible: boolean
  /**
   * Controls the visibility of the searching dialog for professions.
   */
  professionSearchVisible: boolean
  /**
   * Controls the visibility of the searching dialog for categories.
   */
  categorySearchVisible: boolean
  /**
   * Determines whether the date form of the date filter is visible.
   */
  dateFormVisible: boolean
  /**
   * Controls the visibility of the map filter.
   */
  mapFilterVisible: boolean
  /**
   * Some components need to be initialized a little later.
   * This property controls the visibility of that components.
   */
  lazyComponentVisible: boolean
  /**
   * Controls the visibility of the sidebar
   */
  sidebarVisible = false

  /**
   * Defines the artist multiplicity.
   */
  countFilters: CountFilterOption[] = []

  /**
   * All user selected skills.
   */
  selectedSkills: SkillResp[] = []
  /**
   * All user selected professions.
   */
  selectedProfessions: BriefProfessionResp[] = []
  /**
   * All user selected genres.
   */
  selectedGenres: GenreRest[] = []
  /**
   * All user selected price item categories.
   */
  selectedCategories: BriefPriceItemCategoryResp[] = []

  /**
   * Defines the price range by which a user wants to filter profiles.
   */
  priceRange: number[] = [0, 3000]
  /**
   * Max price to display.
   */
  maxPrice = 3000

  /**
   * Contains the current location response from the OSM.
   */
  location: any
  /**
   * Displays the radius circle on a map.
   */
  radiusMarker: RadiusMarker = {
    radius: ProfileCatalogFilterComponent.RADIUS_IN_METERS
  }

  /**
   * The current selected start date.
   */
  startDate?: Date | null
  /**
   * The current selected end date.
   */
  endDate?: Date | null
  /**
   * The value which is used to initialize the start date field.
   * Value is read from query parameters and passed to the child component.
   */
  initialStartDate?: Date
  /**
   * The value which is used to initialize the end date field.
   * Value is read from query parameters and passed to the child component.
   */
  initialEndDate?: Date

  readonly todayDate = dateCut(new Date(), 'h')

  /**
   * The sidebar instance.
   */
  @ViewChild('sidebar')
  sidebar: Sidebar

  /**
   * Scroll container on desktop layout.
   */
  @ViewChild('desktopContentContainer')
  desktopContainer: ElementRef<HTMLDivElement>

  /**
   * Determines whether the filter has changed.
   */
  dirty: boolean
  /**
   * A page size for the {@link currentFilters}.
   */
  pageSize = 4
  /**
   * Support contact info
   */
  contactInfo: SupportContact = environment.contact
  /**
   * - The auto-construct request timeout.
   * - Used to delay the request construction when the {@link onFilterChange} gets executed.
   * - Prevents from unnecessary duplicate API requests with same values.
   */
  private requestTimeout: any

  constructor(
    public changeRef: ChangeDetectorRef,
    public navigation: NavigationService,
    public cookies: CookiesSettingsService,
    private skillService: SkillService,
    private professionService: ProfessionService,
    private genreService: GenreService,
    private bookingService: BookingService,
    private priceItemService: PriceItemService,
    private storageService: StorageService,
    private snackbar: SnackbarService,
    private leaflet: LeafletService,
    private pixelService: PixelService,
    private locationRoute: Location,
    private route: ActivatedRoute) {
    super()
  }

  async ngOnInit(): Promise<void> {
    // Page size init
    if (this.isScreenOf(this.Screen.XXL + 1)) {
      this.pageSize = 10
    } else if (this.isScreenOf(this.Screen.LG)) {
      this.pageSize = 6
    } else {
      this.pageSize = 4
    }

    // because the layout requires this property initialized (because of animations)
    if (this.alwaysVisible) {
      this.showSidebar()
    }
    this.initCountFilters()
    // Initialization is called from the parent component
    // if (!(await this.parseParams())) {
    //   this.loadSettings()
    // }

    // Initial emit change - start initial search request
    this.dirty = false
  }

  /**
   * Initializes the filter with the given profession.
   * The Method is public to be called from the parent component.
   * @param profession - the profession to initialise the filter with.
   */
  initialLoad(profession?: BriefProfessionResp): void {
    if (profession) {
      this.resetFilters()
      this.selectedProfessions.push(profession)
      this.storeSettings()
      this.request.emit(this.constructRequest())
    } else {
      this.loadSettings()
    }
  }

  /**
   * Sets the location filter based on the given city.
   */
  setLocationFilter(city: CityResp): void {
    if (this.leaflet.isReady()) {
      this.radiusMarker.latLng = this.leaflet.latLng(city.lat, city.lng)
      this.radiusMarker = {...this.radiusMarker}
    }
  }

  /**
   * Sets the date filter based on the given date and set {@link dateFormVisible} to true.
   */
  setDateFilter(startDate: Date, endDate: Date): void {
    this.dateFormVisible = true
    this.initialStartDate = startDate
    this.initialEndDate = endDate
  }

  /**
   * Shows the filter sidebar.
   */
  showSidebar(): void {
    this.dirty = false
    this.tryOpenDateFilter()
    this.tryOpenMapFilter()
    this.sidebarVisible = true
    setTimeout(() => {
      this.lazyComponentVisible = true
    }, 200)
  }

  /**
   * Resets all filters.
   */
  resetFilters(): void {
    this.selectedGenres = []
    this.selectedCategories = []
    this.selectedSkills = []
    this.selectedProfessions = []
    this.radiusMarker = {
      radius: ProfileCatalogFilterComponent.RADIUS_IN_METERS
    }
    this.startDate = null
    this.endDate = null
    this.priceRange = [0, 3000]
    this.location = null
    this.initCountFilters()
    this.bookingService.updateBookDate({})
    this.tryOpenDateFilter()
    this.tryOpenMapFilter()
    this.onFilterChange()
  }

  /**
   * Updates filters based on the clicked price item category.
   * - Clears price range, while keeps date info, location, skills, and genres.
   */
  onCategoryChange(category: PriceItemCategoryDetail | null, resetDateForm: boolean = true): void {
    this.selectedCategories = category ? [category.category] : []
    if (category) {
      this.selectedProfessions = category.professions
    }

    this.priceRange = [0, 3000]
    this.initCountFilters()
    if (resetDateForm) {
      this.bookingService.updateBookDate({})
    }
    this.tryOpenDateFilter()
    this.tryOpenMapFilter()
    this.onFilterChange()
  }

  /**
   * Hides the filter sidebar and starts the searching.
   */
  hideSidebarAndSearch(): void {
    this.storeSettings()
    this.sidebarVisible = false
    this.lazyComponentVisible = false
    if (this.dirty) {
      this.request.emit(this.constructRequest())
    }
  }

  /**
   * Emits when the {@link alwaysVisible} is enabled and the user makes any change to the filter.
   */
  onFilterChange(): void {
    this.dirty = true
    if (this.alwaysVisible) {
      clearTimeout(this.requestTimeout)
      this.requestTimeout = setTimeout(() => {
        this.storeSettings()
        this.request.emit(this.constructRequest())
      }, 750)
    }
  }

  /**
   * - Toggles the date filter.
   * - Clears the {@link startDate}, {@link endDate} if the {@link dateFormVisible} is false.
   */
  toggleDateFilter(): void {
    this.dateFormVisible = !this.dateFormVisible
    if (!this.dateFormVisible) {
      this.startDate = null
      this.endDate = null
      this.bookingService.updateBookDate(null)
      this.onFilterChange()
    }
    this.changeRef.detectChanges()
  }

  /**
   * - Toggles the map filter
   * - Scrolls to the bottom of the {@link sidebar} (because the map is the last filter), if the user wants to show this filter.
   * - Clears the {@link location} and re-initializes {@link radiusMarker} and {@link radius} values to default.
   */
  toggleMapFilter(): void {
    this.mapFilterVisible = !this.mapFilterVisible
    if (!this.mapFilterVisible) {
      this.location = null
      this.radiusMarker = {
        radius: ProfileCatalogFilterComponent.RADIUS_IN_METERS
      }
      this.onFilterChange()
    } else {
      // Scroll behavior
      const scrollOpts: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'end'
      }
      // Auto scroll function
      setTimeout(() => {
        // Scroll to the bottom of the sidebar
        if (!this.alwaysVisible) {
          this.sidebar.container.getElementsByClassName('content')[0].scrollIntoView(scrollOpts)
        } else {
          this.desktopContainer.nativeElement.getElementsByClassName('desktop-last-el')[0].scrollIntoView(scrollOpts)
        }
      }, 250)
    }
  }

  /**
   * Removes a skill from the {@link selectedSkills} array.
   */
  removeSkill(s: SkillResp): void {
    for (let i = 0; i < this.selectedSkills.length; i++) {
      if (this.selectedSkills[i].id === s.id) {
        this.selectedSkills.splice(i, 1)
        this.selectedSkills = [...this.selectedSkills]
        break
      }
    }
    this.onFilterChange()
  }

  /**
   * Removes the profession from the {@link selectedProfessions} array.
   */
  removeProfession(p: BriefProfessionResp): void {
    for (let i = 0; i < this.selectedProfessions.length; i++) {
      if (this.selectedProfessions[i].id === p.id) {
        this.selectedProfessions.splice(i, 1)
        this.selectedProfessions = [...this.selectedProfessions]
        break
      }
    }
    this.onFilterChange()
  }

  /**
   * Removes the genre from the {@link selectedGenres} array.
   */
  removeGenre(g: GenreRest): void {
    for (let i = 0; i < this.selectedGenres.length; i++) {
      if (this.selectedGenres[i].id === g.id) {
        this.selectedGenres.splice(i, 1)
        this.selectedGenres = [...this.selectedGenres]
        break
      }
    }
    this.onFilterChange()
  }

  /**
   * Removes the {@link category} from the {@link selectedCategories} array.
   */
  removeCategory(c: BriefPriceItemCategoryResp): void {
    for (let i = 0; i < this.selectedCategories.length; i++) {
      if (this.selectedCategories[i].id === c.id) {
        this.selectedCategories.splice(i, 1)
        this.selectedCategories = [...this.selectedCategories]
        break
      }
    }
    this.onFilterChange()
  }

  /**
   * Fires when a user has changed an address.
   */
  addressChange(address): void {
    this.location = address
    if (this.leaflet.isReady()) {
      this.radiusMarker.latLng = this.leaflet.latLng(address.lat, address.lon)
      this.radiusMarker = {...this.radiusMarker}
      this.onFilterChange()
    }
  }

  /**
   * Fires when a user has changed a location by clicking on a map.
   */
  locationChange(latLngVal: LatLngExpression): void {
    this.location = null
    this.radiusMarker.latLng = latLngVal
    this.radiusMarker = {...this.radiusMarker}
    this.onFilterChange()
  }

  /**
   * - Calls the server API to get all professions.
   * - If the {@link search} is present, it will call the search endpoint instead of the default one.
   */
  callSearchProfessions(pageNum: number, search?: string): Observable<Page<BriefProfessionResp>> {
    let obs
    if (search) {
      const req: SearchProfessionReq = {
        name: search?.trim(),
        page: pageNum
      }
      obs = this.professionService.callSearchProfession(req)
    } else {
      obs = this.professionService.callAllProfessions({page: pageNum})
    }
    return this.unwrap(obs)
  }

  /**
   * Calls the server API to fetch the profession based on the {@link professionId}.
   */
  callGetProfession(professionId: number): Observable<BriefProfessionResp> {
    return this.unwrap(this.professionService.callGetProfession(professionId))
  }

  /**
   * - Calls the server API to get all skills.
   * - If the {@link search} is present, it will call the search endpoint instead of the default one.
   */
  callSearchSkills(pageNum: number, search?: string): Observable<Page<SkillResp>> {
    let obs
    if (search) {
      const req: SearchSkillReq = {
        name: search?.trim(),
        page: pageNum
      }
      obs = this.skillService.callSearchSkill(req)
    } else {
      obs = this.skillService.callGetAllSkills({page: pageNum})
    }
    return this.unwrap(obs)
  }

  /**
   * - Calls the server API to get all genres.
   * - If the {@link search} is present, it will call the search endpoint instead of the default one.
   */
  callSearchGenres(pageNum: number, search?: string): Observable<Page<GenreRest>> {
    let obs
    if (search) {

      const req: SearchGenreReq = {
        name: search?.trim(),
        page: pageNum
      }
      obs = this.genreService.callSearchGenre(req)
    } else {
      obs = this.genreService.callGetAllGenres({page: pageNum})
    }
    return this.unwrap(obs)
  }

  /**
   * - Calls the server API to get all price item categories.
   * - If the {@link search} is present, it will call the search endpoint instead of the default one.
   */
  callSearchCategories(pageNum: number, search?: string): Observable<Page<BriefPriceItemCategoryResp>> {
    let obs
    if (search) {

      const req: SearchPriceItemCategoryReq = {
        name: search?.trim(),
        page: pageNum
      }
      obs = this.priceItemService.callSearchPriceItemCategories(req)
    } else {
      obs = this.priceItemService.callGetAllCategories().pipe((it) => {
        map((resp: BaseResp<any>) => {
          const items = resp.body
          resp.body = newEmptyPage()
          appendNewPage(resp.body, items)
        })
        return it
      })
    }
    return this.unwrap(obs)
  }

  /**
   * Calls the most used genres.
   */
  callMostUsedGenres(): Observable<BaseResp<GenreRest[]>> {
    return this.genreService.callGetMostUsed()
  }

  /**
   * Calls the most used skills.
   */
  callMostUsedSkills(): Observable<BaseResp<SkillResp[]>> {
    return this.skillService.callGetMostUsed()
  }

  /**
   * Calls the most used professions.
   */
  callMostUsedProfessions(): Observable<BaseResp<BriefProfessionResp[]>> {
    return this.professionService.callGetMostUsed()
  }

  /**
   * Calls the most used price item categories.
   */
  callMostUsedCategories(): Observable<BaseResp<BriefPriceItemCategoryResp[]>> {
    return this.priceItemService.callGetMostUsedCategories()
  }

  /**
   * Shows a success message that filters have been applied.
   */
  showSnackbarMsg(): void {
    this.snackbar.showMessage({
      message: $localize`Filters have been applied.`,
      icon: 'fa-solid fa-sliders'
    })
  }

  /**
   * Tries to fetch URL params and select proper categories.
   */
  private async parseParams(): Promise<boolean> {
    const params = await firstValueFrom(this.route.queryParams)
    const professionId = params['profession']
    clearUrl(this.locationRoute)
    if (professionId) {
      const prof = await firstValueFrom(this.callGetProfession(professionId))
      if (prof && this.noServerMessages()) {
        this.resetFilters()
        this.selectedProfessions.push(prof)
        this.storeSettings()
        this.request.emit(this.constructRequest())
        return true
      }
    }
    return false
  }

  /**
   * Tries to open the date filter when there is booking information in the localstorage.
   */
  private tryOpenDateFilter(): void {
    const data = this.bookingService.getBookDate()
    this.dateFormVisible = !!data.startDate && !!data.startTime && !!data.endDate && !!data.endTime
      || !!this.initialStartDate && !!this.initialEndDate
    this.changeRef.detectChanges()
  }

  /**
   * Tries to open the location filter when there is a stored location in the localstorage.
   */
  private tryOpenMapFilter(): void {
    this.mapFilterVisible = !!this.radiusMarker?.latLng
  }

  /**
   * - Constructs the {@link SearchFilterProfilesReq} from the selected filters.
   */
  private constructRequest(): SearchFilterProfilesReq {
    const req = {
      start: this.startDate,
      end: this.endDate,
      priceStart: (this.priceRange[0] > 0) ? this.priceRange[0] : null,
      priceEnd: (this.priceRange[1] < this.maxPrice && this.priceRange[1] > 0) ? this.priceRange[1] : null,

      lat: this.radiusMarker.latLng?.[`lat`],
      lng: this.radiusMarker.latLng?.[`lng`],

      group: this.isGroupSelected(),

      skills: this.selectedSkills.map(it => it.id),
      professions: this.selectedProfessions.map(it => it.id),
      genres: this.selectedGenres.map(it => it.id),
      categories: this.selectedCategories.map(it => it.id),

      page: 0,
      size: this.pageSize
    }
    this.pixelService.trackCustom(PixelCategory.FILTER, req)
    return req
  }

  /**
   * - Returns null if the filter is not used, or both options are selected which is the same state as no option selected.
   * - Returns true if the only group is selected, false when the 'solo' is selected.
   */
  private isGroupSelected(): boolean | null {
    const countSelected = this.countFilters.filter(it => it.selected)
    if (countSelected.length === 0 || countSelected.length === 2) {
      return null
    } else {
      return this.countFilters.find(it => it.selected)?.id === 2
    }
  }

  /**
   * Initializes the {@link countFilters}.
   */
  private initCountFilters(): void {
    this.countFilters = [
      {
        id: 1,
        name: $localize`Solo`
      },
      {
        id: 2,
        name: $localize`Group / Band`
      }
    ]
  }

  /**
   * Saves the current filter state to the localstorage.
   */
  private storeSettings(): void {
    const settings: FilterSettings = {
      categories: this.selectedCategories || [],
      professions: this.selectedProfessions || [],
      skills: this.selectedSkills || [],
      genres: this.selectedGenres || [],
      group: this.isGroupSelected(),
      priceRange: this.priceRange,
      location: {
        lat: this.radiusMarker.latLng?.[`lat`],
        lng: this.radiusMarker.latLng?.[`lng`]
      },
      expirationAt: addHours(new Date(), 1)
    }
    this.storageService.setItemStorage(StorageItem.BOOK_FILTER, JSON.stringify(settings), true)
  }

  /**
   * Loads stored filter settings from the localstorage.
   */
  private loadSettings(): void {
    const settings = JSON.parse(this.storageService.getItemStorage(StorageItem.BOOK_FILTER)) as FilterSettings
    if (settings?.expirationAt && new Date(settings.expirationAt) < new Date()) {
      this.storeSettings()
      return
    }
    this.selectedCategories = settings?.categories || []
    this.selectedProfessions = settings?.professions || []
    this.selectedSkills = settings?.skills || []
    this.selectedGenres = settings?.genres || []
    this.countFilters[1].selected = settings?.group
    this.countFilters[0].selected = settings?.group === false
    if (settings?.priceRange) {
      this.priceRange = settings.priceRange
    }

    const location = settings?.location
    if (location && location.lat && location.lng) {
      if (this.leaflet.isReady()) {
        this.radiusMarker.latLng = this.leaflet.latLng(location.lat, location.lng)
      }
    }
    this.request.emit(this.constructRequest())

    // Shows the information to the user about applied filters
    if (!this.noFilters()) {
      this.showSnackbarMsg()
    }
  }

  /**
   * Determines whether no filters are set.
   */
  private noFilters(): boolean {
    const f = this.constructRequest()
    return !f.start
      && !f.start
      && !f.end
      && !f.priceStart
      && !f.priceEnd
      && !f.lat
      && !f.lng
      //&& !f.radiusKm
      && f.group == null
      && f.skills?.length === 0
      && f.professions?.length === 0
      && f.genres?.length === 0
      && f.categories?.length === 0
  }

  ngOnDestroy(): void {
    // store settings only if the sidebar is visible (prevents from double storing unnecessarily)
    if (this.sidebarVisible) {
      this.storeSettings()
    }
  }
}

/**
 * Defines a structure of the count filter options.
 */
export interface CountFilterOption {
  id: number
  name: string
  selected?: boolean
}

/**
 * Represents the settings structure stored in a localstorage.
 */
export interface FilterSettings {
  categories?: BriefPriceItemCategoryResp[]
  professions?: BriefProfessionResp[]
  skills?: SkillResp[]
  genres?: GenreRest[]
  priceRange?: number[]
  location?: {
    lat: number
    lng: number
  }
  group?: boolean
  expirationAt: Date
}
