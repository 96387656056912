import {EditableComponent} from '../../abstract/editable.component'
import {CalendarCell, CalendarCellDetail} from './profile-calendar.component'
import {Directive, EventEmitter, Input, Output} from '@angular/core'
import {
  addDays,
  addMonths,
  dateEquals,
  dayPosInWeek,
  daysDifference,
  daysInMonth,
  isToday,
  minusMonths
} from '../../../utils/date.utils'
import {isArrayNullOrEmpty} from '../../../utils/utils'
import {CalendarItemNestedProfileResp, CalendarItemResp} from '../../../service/calendar.service'
import {BriefProfileResp} from '../../../service/profile.service'
import {Acceptance} from 'src/app/common/acceptance'
import {FindHostPendingItemsResp, HostPendingItemResp} from '../../../service/host-profile.service'
import {BehaviorSubject, Subject} from 'rxjs'
import {throwAppError} from '../../../utils/log.utils'

/**
 * Documentation available at {@link ProfileCalendarComponent}.
 */
@Directive()
export class ProfileCalendarHelper extends EditableComponent {
  /**
   * Defines whether the table cells can be clickable before the current date.
   */
  @Input()
  clickablePastDate = true
  /**
   * Specify, how many months should be visible.
   */
  @Input()
  monthsVisible = 2
  /**
   * User selected year and month to display.
   * This property gets updated in the {@link }
   */
  selectedYearMonths: Date[] = []
  /**
   * - Displayable cell data containing {@link CalendarItemResp}s elements.
   * - This property uses the HTML document to display all information.
   * - The first dimension represents the current visible year month (based on the input property).
   * - The second dimension contains all {@link CalendarCell}s of that month.
   */
  monthsCellData: CalendarCell[][] = []

  /**
   * An array of ids of profiles that were requested to load from the server.
   */
  fetchedProfileIds: number[] = []
  /**
   * The detailed information of profiles of {@link fetchedProfileIds}.
   */
  fetchedProfiles: CalendarItemNestedProfileResp[] = []

  /**
   * Contains all pending profiles from both sides, the host and the other side(non-host).
   */
  pendingProfileItemsResp: FindHostPendingItemsResp
  /**
   * The current state of the count of the pending host profile items.
   */
  pendingHostProfileItemsCount = new BehaviorSubject<number>(0)
  /**
   * The user-selected cell element in the calendar view.
   */
  selectedCell?: CalendarCell
  /**
   * Emits the selected cell, if the {@link selectedCell} gets a new value.
   */
  @Output()
  selectedCellChange = new EventEmitter<CalendarCell>()
  /**
   * The {@link selectedCellChange} will not emit any values.
   */
  @Input()
  disableCellChange: boolean
  /**
   * The user-selected cell element in the calendar view with the more complex data (e.g. profiles data, etc.).
   */
  protected selectedCellDetail?: CalendarCellDetail

  protected jumpToDate = new Subject<Date>()
  /**
   * Server-provided calendar data.
   * The first dimension represents the 'day-month-year' selector,
   * whereas the second dimension contains all {@link CalendarItemResp}s of that day.
   */
  private calendarItems: CalendarItemResp[][] = []
  /**
   * Holds the records of what months of a year have been already fetched from the server.
   */
  private fetchedMonths: string[] = []

  Acceptance: typeof Acceptance = Acceptance

  constructor() {
    super()
  }

  onComponentSave(): void {
  }

  /**
   * Jumps to the next pending host profile cell. (That is waiting for acceptance by the host).
   */
  jumpToNextPendingHostProfileCell(): void {
    if (this.selectedCellDetail) {
      const current = this.selectedCellDetail.cell.date
      const hostPendingItems = this.pendingProfileItemsResp.pendingHostItems
      // jump to the next item
      for (let i = 0; i < hostPendingItems.length; i++) {
        const item = hostPendingItems[i]
        if (dateEquals(current, item.start, 'h') && (i + 1) !== hostPendingItems.length) {
          this.jumpToDate.next(new Date(hostPendingItems[i + 1].start))
          return
        }
      }

      // jump to first item if present
      if (!isArrayNullOrEmpty(hostPendingItems)) {
        this.jumpToDate.next(new Date(hostPendingItems[0].start))
      }
    }
  }

  /**
   * Moves calendar item to the different date time.
   * Basically it removes from the calendar the oldItem and sets the newItem back into the calendar.
   *
   * @param oldItem The item to be removed.
   * @param newItem The item to be added.
   */
  moveItemInCalendar(oldItem: CalendarItemResp, newItem: CalendarItemResp): void {
    if (oldItem) {
      this.removeItemFromCalendar(oldItem)
    }
    for (let i = 0; i < this.monthsVisible; i++) {
      this.monthsCellData[i] = this.applyItems(this.selectedYearMonths[i], [newItem])
    }
  }

  /**
   * Removes the {@link CalendarItemResp} from the {@link monthsCellData},
   * {@link calendarItems}, and the {@link pendingProfileItemsResp} if it exists there.
   *
   * @param item Item to remove.
   */
  removeItemFromCalendar(item: CalendarItemResp): void {
    this.removeItemFromCells(item)
    this.removeItemFromCalendarData(item)
    if (item.nestedProfile) {
      this.removeItemFromPendingProfiles(item)
    }
  }

  /**
   * Parses the {@link CalendarItemResp} result from the server into the {@link calendarItems} array.
   * It also calls the {@link constructCells} to display the content in calendar cells.
   *
   * @param yearMonth The current year month data of the {@link resp}.
   * @param resp Server data.
   */
  protected applyItems(yearMonth: Date, resp: CalendarItemResp[]): CalendarCell[] {
    resp.forEach(item => {
      // if the item is longer than one day, add that item to multiple cells.
      const dayDuration = daysDifference(item.start, item.end)
      for (let i = 0; i <= dayDuration; i++) {
        const selector = this.createSelectorFromDate(addDays(item.start, i))
        if (!this.calendarItems[selector]) { // initialize the array
          this.calendarItems[selector] = []
        }
        this.pushItemToCalendarData(selector, item) // push the CalendarItemResp to the specific position
      }
    })
    return this.constructCells(yearMonth)
  }

  /**
   * Creates the calendar {@link monthsCellData} from the {@link calendarItems} provided by the server.
   * - Creates the cells (containing all information of calendar items) based on provided {@link yearMonth} date.
   */
  protected constructCells(yearMonth: Date): CalendarCell[] {
    const cells: CalendarCell[] = [] // cell data of the current year month
    const currentDate = new Date()
    const prevMonth = minusMonths(yearMonth, 1)
    const nextMonth = addMonths(yearMonth, 1)

    const prevMonthMaxDays = daysInMonth(prevMonth)
    const currMonthMaxDays = daysInMonth(yearMonth)
    const nextMonthMaxDays = daysInMonth(nextMonth)

    // Previous month
    const posInWeek = dayPosInWeek(yearMonth, false)
    for (let i = posInWeek - 1; i >= 0; i--) {
      const cell = this.constructCellData(prevMonthMaxDays - i, prevMonth, currentDate, yearMonth)
      cells.push(cell)
    }

    // Current month
    for (let i = 0; i < currMonthMaxDays; i++) {
      const cell = this.constructCellData(i + 1, yearMonth, currentDate, yearMonth)
      cells.push(cell)
    }

    // Next month
    for (let i = 0; i < nextMonthMaxDays; i++) {
      if (cells.length < 42) { // 6 rows * 7 days
        const cell = this.constructCellData(i + 1, nextMonth, currentDate, yearMonth)
        cells.push(cell)
      } else {
        break
      }
    }

    return cells
  }

  /**
   * Constructs the specific cell by the date with the right attributes.
   * Adds the cell to the {@link monthsCellData} array.
   *
   * @param dayNum The day number label.
   * @param yearMonth The date that the numbers are taken from.
   * @param currentDate The current today's date.
   * @param selectedYearMonth The year-month date which is currently selected.
   * @throws Error when yearMonth or selectedYearMonth have hours.
   */
  private constructCellData(dayNum: number, yearMonth: Date, currentDate: Date, selectedYearMonth: Date): CalendarCell {
    // Cannot have hours in the yearMonth date
    if (yearMonth.getHours() !== 0) {
      throwAppError('Calendar', $localize`constructCells() - [yearMonth] cannot have hours!`)
    }
    if (selectedYearMonth.getHours() !== 0) {
      throwAppError('Calendar', $localize`constructCells() - [selectedYearMonth] cannot have hours!`)
    }

    const today = isToday(currentDate, yearMonth, dayNum)
    const cellDate = new Date(yearMonth.getFullYear(), yearMonth.getMonth(), dayNum, 0, 0, 0, 0)
    const isSelected = (this.selectedCell) ? dateEquals(this.selectedCell.date, cellDate, 'h') : false
    const cell: CalendarCell = {
      calendarItems: this.getItemsFromCalendarData(this.createSelector(yearMonth, dayNum)),
      dayInCurrentMonth: dateEquals(yearMonth, selectedYearMonth, 'd'),
      isToday: today,
      closed: false,
      selected: isSelected,
      date: cellDate,
      availability: 'free'
    }
    // update the selected cell reference
    if (isSelected) {
      this.selectedCell = cell
    }
    return cell
  }

  /**
   * Constructs fake items of {@link CalendarItemResp} from the given items of the {@link HostPendingItemResp} with a negative ID value.
   * (Negative, because it is known that they are fake items)
   *
   * @param items The pending profiles that wait for an acceptance.
   */
  protected constructFakeCalendarItemsFromPendingProfiles(items: HostPendingItemResp[]): CalendarItemResp[] {
    // Construct fake calendar items to be visible in the profile's calendar
    const fakeCalendarItems: CalendarItemResp[] = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      fakeCalendarItems.push({
        id: -(i + 1),
        start: item.start,
        end: item.end,
        nestedProfile: item.profile
      })
    }
    return fakeCalendarItems
  }

  /**
   * Returns true, if the year-month date has not been already fetched from the server.
   * This function also updates the {@link fetchedMonths} array.
   *
   * @param date The year-month date.
   */
  protected monthNotAlreadyFetched(date: Date): boolean {
    const selector = `${date.getFullYear()}-${date.getMonth()}`
    if (!this.fetchedMonths.includes(selector)) {
      this.fetchedMonths.push(selector)
      return true
    } else {
      return false
    }
  }

  /**
   * Gets the {@link CalendarItemNestedProfileResp} based on the {@link BriefProfileResp} from the {@link fetchedProfiles}.
   * Returns null, if the profile does not exists in the {@link fetchedProfiles} yet.
   */
  protected getFetchedProfile(nestedProfile: BriefProfileResp): CalendarItemNestedProfileResp {
    for (const profile of this.fetchedProfiles) {
      if (profile.profileId === nestedProfile.profileId) {
        return profile
      }
    }
    return null
  }

  /**
   * Returns the calendar cell data from the {@link monthsCellData} based on the date parameter.
   */
  protected getCalendarCellByTheDate(date: Date): CalendarCell {
    for (const monthCells of this.monthsCellData) {
      for (const cell of monthCells) {
        if (dateEquals(date, cell.date, 'h') && cell.dayInCurrentMonth) {
          return cell
        }
      }
    }
  }

  /**
   * Returns the today's date cell.
   */
  protected getTodayCell(): CalendarCell {
    for (const monthCells of this.monthsCellData) {
      for (const cell of monthCells) {
        if (cell.isToday) {
          return cell
        }
      }
    }
  }

  /**
   * Remove item from the {@link monthsCellData} array.
   *
   * @param item The item to be removed.
   */
  private removeItemFromCells(item: CalendarItemResp): void {
    for (const monthCells of this.monthsCellData) {
      for (const cell of monthCells) {
        for (let i = 0; i < cell.calendarItems.length; i++) {
          const it = cell.calendarItems[i]
          if (it.id === item.id) {
            cell.calendarItems.splice(i, 1)
          }
        }
      }
    }
  }

  /**
   * Removes the item from the {@link pendingProfileItemsResp} arrays.
   *
   * @param item The item to be removed.
   */
  private removeItemFromPendingProfiles(item: CalendarItemResp): void {
    // remove item from pending profiles if it exists here
    const hostItems = this.pendingProfileItemsResp.pendingHostItems
    const items = this.pendingProfileItemsResp.pendingItems

    // Remove from host pending items
    for (let i = 0; i < hostItems.length; i++) {
      if (item.nestedProfile.profileId === hostItems[i].profile.profileId) {
        hostItems.splice(i, 1)
      }
    }

    // Remove from pending items
    for (let i = 0; i < items.length; i++) {
      if (item.nestedProfile.profileId === items[i].profile.profileId) {
        items.splice(i, 1)
      }
    }

    // update the host profile count
    this.pendingHostProfileItemsCount.next(hostItems.length)
  }

  /**
   * Removes the item from the {@link monthsCellData} array.
   *
   * @param item The item to be removed.
   */
  private removeItemFromCalendarData(item: CalendarItemResp): void {
    const start = new Date(item.start.setHours(0, 0, 0, 0))
    const end = new Date(item.end.setHours(0, 0, 0, 0))

    const daysLast = daysDifference(start, end)
    // iterate over calendar data
    for (let i = 0; i <= daysLast; i++) {
      const selector = this.createSelectorFromDate(addDays(start, i))

      if (!isArrayNullOrEmpty(this.calendarItems[selector])) {
        const items: CalendarItemResp[] = this.calendarItems[selector]

        // remove the item from the cell items
        for (let j = 0; j < items.length; j++) {
          if (items[j].id === item.id) {
            items.splice(j, 1)
          }
        }
      }
    }
  }

  /**
   * Clears the calendar properties.
   * (e.g. due to a profileData changes)
   */
  protected clearData(): void {
    this.pendingProfileItemsResp = null
    this.selectedCell = null
    this.selectedCellDetail = null
    this.calendarItems = []
    this.fetchedProfiles = []
    this.fetchedProfileIds = []
    this.monthsCellData = []
    this.fetchedMonths = []
  }

  /**
   * - Updates the {@link selectedCell} property with the passing cell.
   * - Also, it sets the highlight to that cell and removes it from the previous one.
   * - If the cell is null, it clears the current selection.
   */
  protected selectAndHighlight(cell?: CalendarCell): void {
    if (this.selectedCell) {
      this.selectedCell.selected = false
    }
    this.selectedCell = cell
    if (cell) {
      this.selectedCell.selected = true
    }
    // emit changes only when it is not blocked
    if (!this.disableCellChange) {
      this.selectedCellChange.emit(cell)
    }
  }

  /**
   * Returns the proper list of {@link CalendarItemResp} from the {@link calendarItems} array at the {@link selector} position.
   * If no data was found at the selector position, the empty array is returned.
   *
   * @param selector What data should be return.
   */
  private getItemsFromCalendarData(selector: string): CalendarItemResp[] {
    const items = this.calendarItems[selector]
    if (!items) {
      return []
    }
    return items
  }

  /**
   * Pushes the item in the {@link calendarItems} array at the position defined by the selector param.
   * It pushes the item only if it does not already exist in the array.
   *
   * @param selector The position in the {@link calendarItems} array.
   * @param item The item to be pushed.
   */
  private pushItemToCalendarData(selector: string, item: CalendarItemResp): void {
    for (const cellItem of this.calendarItems[selector]) {
      if (cellItem.itemId === item.id) {
        return
      }
    }
    this.calendarItems[selector].push(item)
  }

  /**
   * Creates a selector from the given date for the {@link calendarItems} array in the 'yyyy-MM-dd' format.
   *
   * @param date The date that the selector is constructed of.
   */
  private createSelectorFromDate(date: Date): string {
    const year = date.getFullYear().toString()
    const month = date.getMonth().toString()
    const day = date.getDate().toString()
    return `${year}-${month}-${day}`
  }

  /**
   * Creates a selector from the given parameters for the {@link calendarItems} array in the 'yyyy-MM-dd' format.
   *
   * @param yearMonth Used for year and month selector part.
   * @param date Used for date selector part.
   */
  private createSelector(yearMonth: Date, date: number): string {
    return `${yearMonth.getFullYear()}-${yearMonth.getMonth()}-${date}`
  }
}
