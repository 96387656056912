import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core'

@Directive({
  selector: '[appSwipe]',
  standalone: true
})
export class SwipeDirective {

  @Output()
  swipeUp = new EventEmitter<Event>()

  @Output()
  swipeDown = new EventEmitter<Element>()

  @Output()
  swipeLeft = new EventEmitter<Element>()

  @Output()
  swipeRight = new EventEmitter<Element>()

  constructor(private eleRef: ElementRef) {
  }

  @HostListener('swiped-up', ['$event'])
  private onSwipeUp(event): void {
    if (event.target === this.eleRef.nativeElement) {
      this.swipeUp.emit(event)
    }
  }

  @HostListener('swiped-down', ['$event'])
  private onSwipeDown(event): void {
    if (event.target === this.eleRef.nativeElement) {
      this.swipeDown.emit(event)
    }
  }

  @HostListener('swiped-left', ['$event'])
  private onSwipeLeft(event): void {
    if (event.target === this.eleRef.nativeElement) {
      this.swipeLeft.emit(event)
    }
  }

  @HostListener('swiped-right', ['$event'])
  private onSwipeRight(event): void {
    if (event.target === this.eleRef.nativeElement) {
      this.swipeRight.emit(event)
    }
  }

}
