import {Component} from '@angular/core'
import {NavigationService} from '../../../service/ui/navigation.service'
import {ButtonComponent} from '../../common/button/button.component'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  imports: [
    ButtonComponent
  ],
  standalone: true
})
export class PageNotFoundComponent {

  constructor(public navigation: NavigationService) {
  }
}
