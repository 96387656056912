<h2 class="two-factor-title p-py-3 p-text-uppercase" i18n>Two-factor authentication</h2>

<!--CODE-->
<app-text-input label="Paste verification code"
                i18n-label
                icon="fa-solid fa-key"
                inputType="text"
                formFieldName="code"
                [form]="form"
                [loading]="saving"
                [noBlankCharacters]="true"
                [space]="false"
                [copy]="false"
                [required]="true"
                [length]="{min: Restrictions.VERIFIED_CHANGE_CODE_LENGTH, max: Restrictions.VERIFIED_CHANGE_CODE_LENGTH}">
  <!--BE-CHECK-->
  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.VERIFIED_CHANGE_CODE_NOT_FOUND" i18n>
    The verification code doesn't exist.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages"
                          [check]="ServerMessage.VERIFIED_CHANGE_CODE_INCORRECT" i18n>
    The verification code is not valid.
  </app-backend-validation>

  <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.VERIFIED_CHANGE_TIMEOUT" i18n>
    The provided code is no longer valid. Issue a new code.
  </app-backend-validation>
</app-text-input>

<p class="two-factor-text" i18n>A message with verification code has been sent to your e-mail. Enter the code to
  continue.</p>

<!--Code not received-->
<app-code-restore [email]="form.value.email"></app-code-restore>

<!--Response-->
<app-call-response [component]="this"
                   styleClass="p-mt-4"></app-call-response>
