<div class="wrapper rounded-medium"
     [class.p-d-none]="!show"
     [@fade]="show">

  <div #container [className]="styleClass">
    <!--Input-->
    <div class="rounded-medium">
    <span class="p-input-icon-left w-100">
      <i class="fa-solid p-ml-1"
         [class.fa-location-dot]="!searching"
         [class.fa-circle-notch]="searching"
         [class.fa-spin]="searching"></i>
      <input #input
             id="input"
             type="text"
             pInputText
             placeholder="Type an address"
             i18n-placeholder
             autocapitalize="words"
             autocomplete="off"
             class="w-100"
             (click)="onInputClick($event)"
             (focusout)="onFocusLost()"
             (input)="onSearchInput($event)"
             (paste)="$event.preventDefault()">
    </span>
    </div>

    <!--Search result overlay-->
    <p-overlayPanel #overlayPanel *ngIf="resultAsOverlay" [style]="{'max-width': '50rem'}">
      <ng-container [ngTemplateOutlet]="result"></ng-container>
    </p-overlayPanel>

    <!--Search result as embedded view-->
    <div class="p-mb-2"
         [className]="resultStyleClass"
         *ngIf="!resultAsOverlay && displayableSearchResult.length !== 0 && embedResultVisible">
      <ng-container [ngTemplateOutlet]="result"></ng-container>
    </div>
  </div>
</div>

<!--Result template-->
<ng-template #result>
  <p-scrollPanel [style]="{width: '100%', height: '100%'}">
    <p-dataView [value]="displayableSearchResult"
                layout="list">
      <ng-template let-items pTemplate="list">
        <div class="p-grid p-p-0">
          <div class="p-col-12 p-p-0" *ngFor="let item of items">
            <div class="p-col-12 p-p-1 p-d-flex p-ai-center search-result-item c-pointer"
                 pRipple (click)="searchResultSelected(item)">
              <div class="p-d-flex p-ai-center">

                <!--Icon / Avatar-->
                <div class="p-mr-3 p-ml-1 image p-d-flex p-ai-center p-jc-center">
                  <app-avatar *ngIf="item.charId; else globeIcon"
                              [profile]="item"
                              [redirect]="false"
                              [showTooltip]="false"></app-avatar>

                  <ng-template #globeIcon>
                    <i class="fa-solid fa-location-dot globe-icon"></i>
                  </ng-template>
                </div>

                <!--Location-->
                <div>
                  <div *ngIf="item.displayName; else mapResult">
                    <h5 class="profile-display-name">{{item.displayName}}</h5>
                    <span>{{item.charId}}</span>
                  </div>

                  <ng-template #mapResult>
                    <span class="l-count-1">{{item.address.road}} {{item.address.house_number}}</span>
                    <span class="l-count-1">{{item.address.postcode}} {{item.address.town}} {{item.address.city}}</span>
                    <span class="l-count-1">{{item.address.state}} {{item.address.country}}</span>
                  </ng-template>
                </div>

              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </p-scrollPanel>
</ng-template>
