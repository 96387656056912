import {Component} from '@angular/core'
import {NavigationService} from '../../service/ui/navigation.service'
import {CookiesSettingsService} from '../../service/cookies-settings.service'
import {SupportContact} from '../support/support.component'
import {environment} from '../../../environments/environment'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentDate = new Date()
  /**
   * Support contact info
   */
  contactInfo: SupportContact = environment.contact
  NS: typeof NavigationService = NavigationService

  constructor(
    public navigation: NavigationService,
    public cookieSettings: CookiesSettingsService) {
  }
}
