import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {FormBuilder, FormGroup} from '@angular/forms'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {dateMustBeAfter, dateTimeAfterDateTime} from '../../../../validator/custom.validators'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {addDays, addHours, dateCut, dateEquals, dateJoin, minusDays, minusHours} from '../../../../utils/date.utils'
import {formDatesNotOverlaps, formTimesNotOverlaps} from '../../../../utils/form.utils'
import {Restrictions} from '../../../../common/restrictions'
import {CalendarItemResp, CalendarService, FindCalendarItemsBetweenReq} from '../../../../service/calendar.service'
import {ApiComponent} from '../../../abstract/api.component'
import {ProfileResp} from '../../../../service/profile.service'
import {growAnimation} from '../../../../animation/grow.animation'
import {BookingService} from 'src/app/service/ui/booking.service'
import {BasketService} from '../../../../service/basket.service'
import {ServerMessage} from '../../../../common/server-message'

@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-profile-calendar-book-form',
  templateUrl: './profile-calendar-book-form.component.html',
  styleUrls: ['./profile-calendar-book-form.component.scss']
})
export class ProfileCalendarBookFormComponent extends ApiComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Executes the {@link onCheck} function after this inactivity.
   */
  private static readonly AUTO_CHECK_INTERVAL = 750

  @Input()
  data: ProfileResp

  /**
   * Emits this component when it is ready. (After View Init)
   */
  @Output()
  ready = new EventEmitter<ProfileCalendarBookFormComponent>()
  /**
   * External input for the start date field.
   */
  @Input()
  selectedStartTime: Date
  /**
   * External input for the end date field.
   */
  @Input()
  selectedEndTime: Date
  /**
   * External input for the start date field.
   */
  @Input()
  selectedStartDate: Date
  /**
   * External input for the end date field.
   */
  @Input()
  selectedEndDate: Date
  /**
   * Emits the {@link unavailableItem} changes.
   */
  @Output()
  unavailableItemChange = new EventEmitter<CalendarItemResp | null>()
  /**
   * Emits if the order's start is earlier than {@link MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER} minutes from now
   */
  @Output()
  minimalDistanceErr = new EventEmitter<boolean>()
  /**
   * Emits start date field changes.
   */
  @Output()
  selectedStartTimeChange = new EventEmitter<Date>()

  /**
   * Emits end date field changes.
   */
  @Output()
  selectedEndTimeChange = new EventEmitter<Date>()
  /**
   * Emits start time field changes.
   */
  @Output()
  selectedStartDateChange = new EventEmitter<Date>()
  /**
   * Emits end time field changes.
   */
  @Output()
  selectedEndDateChange = new EventEmitter<Date>()
  /**
   * Emits when the user has to be scrolled to the offer component.
   */
  @Output()
  scrollToOffer = new EventEmitter<void>()
  /**
   * Values of the given date-field outputs.
   */
  selectedStartTimeOutputValue: Date
  selectedEndTimeOutputValue: Date
  selectedStartDateOutputValue: Date
  selectedEndDateOutputValue: Date
  /**
   * The current date without hours, minutes, and seconds.
   */
  readonly currentDate = dateCut(new Date(), 'h')
  /**
   * Default start date option in popups.
   */
  defaultStart = addHours(dateCut(new Date(), 'm'), 1)
  /**
   * Default end date option in popups.
   */
  defaultEnd = addHours(dateCut(new Date(), 'm'), 2)

  form: FormGroup
  /**
   * Represents whether the {@link form} has been modified by user.
   */
  formDirty: boolean = null
  /**
   * Disables the left action button in the start and end date fields.
   */
  disableDateMinusIcon = true
  /**
   * Contains the first item that has been found during the {@link onCheck} function.
   */
  unavailableItem?: CalendarItemResp = null
  /**
   * Temporarily disables changing values to the {@link form} from the outside of
   * this component via ({@link selectedStartDate}, and affiliate inputs).
   */
  enableCalendarInput = true
  /**
   * Defines whether it is currently checking for availability.
   */
  checkAvailabilityLoading: boolean
  /**
   * Temporarily disables emitting the new values outside of component.
   */
  private disableEmitDates: boolean
  /**
   * Prevents from calling form changes multiple times at once.
   */
  private formChangedTimeout
  /**
   * Current auto-check interval.
   */
  private autoCheckTimeout
  /**
   * All form value changes subscribers that needs to be unsubscribed in the ngOnDestroy.
   */
  private formSubs?: Subscription[] = []
  /**
   * Unsubscribes sub of the book-form field calendar reset.
   */
  private fieldSub?: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private calendarService: CalendarService,
    private bookingService: BookingService,
    private basketService: BasketService,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.initHeaderForm()

    this.fieldSub = this.basketService.callResetFields.subscribe(() => {
      if (this.selectedEndDateOutputValue && this.selectedEndTimeOutputValue &&
        this.selectedStartDateOutputValue && this.selectedStartTimeOutputValue) {
        this.resetFields()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.enableCalendarInput && this.form?.controls) {
      const c = this.form.controls
      this.disableEmitDates = true
      if (changes.selectedStartDate?.currentValue) {
        c.startDate.setValue(this.selectedStartDate)
      }
      if (changes.selectedEndDate?.currentValue) {
        c.endDate.setValue(this.selectedEndDate)
      }
      if (changes.selectedStartTime?.currentValue) {
        c.startTime.setValue(this.selectedStartTime)
      }
      if (changes.selectedEndTime?.currentValue) {
        c.endTime.setValue(this.selectedEndTime)
      }
      // update disable date minus icon
      if ((changes.selectedStartDate || changes.selectedEndDate) && this.selectedStartDate) {
        this.disableDateMinusIcon = dateEquals(this.selectedStartDate, this.currentDate, 'h')
      }
      this.disableEmitDates = false
      this.changeRef.detectChanges()
    }

    if (changes.emptyDateFields?.currentValue && this.form?.controls) {
      this.markDateFields()
    }
  }

  ngAfterViewInit(): void {
    this.ready.emit(this)
    // give some time to calendar component to render
    setTimeout(() => {
      this.formHasChanged()
    }, 500)
  }

  /**
   * Adds or reduces days from the {@link field}.
   */
  modifyDate(field: string, plus: boolean): void {
    this.disableCalendarInput(() => {
      // modify value
      const data = this.form.value
      const val = dateCut(data[field] || this.currentDate, 'h')
      const modified = (plus) ? addDays(val, 1) : minusDays(val, 1)
      this.disableDateMinusIcon = modified < this.currentDate
      if (!this.disableDateMinusIcon) {
        this.form.controls[field].setValue(modified)
        this.form.controls.endTime.markAsTouched()
        this.form.updateValueAndValidity()
      }
    })
  }

  /**
   * Fires when a user clicked on the 'startTime', or 'endTime' field.
   * - Fills up the field by the {@link defaultStart}, or {@link defaultEnd}.
   */
  clickOnTimeField(field: string): void {
    const data = this.form.value
    if (!data[field]) {
      this.form.controls[field].setValue(field.startsWith('start') ? this.defaultStart : this.defaultEnd)
    }
    this.updateDefaultTimes()
  }

  /**
   * Clears {@link form} controls' values.
   */
  resetFields(): void {
    this.disableCalendarInput(() => {
      this.form.controls.startTime.setValue(this.defaultStart)
      this.form.controls.startDate.setValue('')
      this.form.controls.endTime.setValue(this.defaultEnd)
      this.form.controls.endDate.setValue('')
      this.selectedStartTimeChange.emit(null)
      this.selectedEndTimeChange.emit(null)
      this.selectedStartDateChange.emit(null)
      this.selectedEndDateChange.emit(null)
      this.formDirty = true
      this.unavailableItem = null
      this.unavailableItemChange.emit(null)
      this.resetApi()
      this.bookingService.resetProfileBookDate()
    })
  }

  /**
   * - Fires when the user wants to check whether the profile is available between the current date selection.
   */
  onCheck(): void {
    if (this.autoCheckTimeout) {
      clearTimeout(this.autoCheckTimeout)
    }
    // return in no changes, form invalid, or past datetime
    if (this.formDirty === false || this.form.invalid || this.selectedStartTime < this.defaultStart) {
      return
    }

    this.customCall(async () => {
      this.checkAvailabilityLoading = true
      this.unavailableItem = (await firstValueFrom(this.callCheckAvailability(this.form.value)))?.filter(it => it.orderActive)[0]
      this.unavailableItemChange.emit(this.unavailableItem)
      this.minimalDistanceErr.emit(this.serverMessages.includes(ServerMessage.PROFILE_ORDER_MINIMAL_DISTANCE_FAILED))
      this.evaluateAndFinish()
      this.formDirty = false
    }, () => {
      this.formDirty = true
    }, () => {
      this.checkAvailabilityLoading = false
    })
  }

  /**
   * Calls a server API to check availability of this profile.
   */
  private callCheckAvailability(formData): Observable<CalendarItemResp[]> {
    const req: FindCalendarItemsBetweenReq = {
      profileId: this.data.profileId,
      start: dateJoin(formData.startDate, formData.startTime),
      end: dateJoin(formData.endDate, formData.endTime)
    }
    return this.unwrap(this.calendarService.callFindCalendarItemsBetween(req))
  }

  /**
   * Initializes the {@link form} property.
   */
  private initHeaderForm(): void {
    const bookInfo = this.bookingService.getProfileBookDate()
    const sDate = bookInfo?.startDate || this.selectedStartDate || this.defaultStart
    const eDate = bookInfo?.endDate || this.selectedEndDate || this.defaultEnd
    const sTime = bookInfo?.startTime || this.selectedStartTime || this.defaultStart
    const eTime = bookInfo?.endTime || this.selectedEndTime || this.defaultEnd

    // init the 'form' instance
    this.form = this.formBuilder.group({
      startDate: [bookInfo?.startDate || ''],
      endDate: [bookInfo?.endDate || ''],

      startTime: [dateJoin(sDate, sTime)],
      endTime: [dateJoin(eDate, eTime)]
    }, {
      validators: [
        dateMustBeAfter('startDate', 'endDate'),
        dateTimeAfterDateTime('startDate', 'endDate', 'startTime', 'endTime', Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH)
      ]
    })
    this.changeRef.detectChanges()

    // call auto check if the bookInfo is present
    const formData = this.form.value
    if (formData?.startDate && formData?.endDate) {
      this.autoCheckAvailability()
    }

    this.formSubs.push(
      // value changes
      this.form.valueChanges.subscribe(this.formHasChanged.bind(this)),

      // Subscribe for value changes and ensure their correctness
      ...formDatesNotOverlaps(this.form, 'startDate', 'endDate'),
      ...formTimesNotOverlaps(this.form, 'startDate', 'endDate', 'startTime', 'endTime')
    )
  }

  /**
   * Fires when the form has changed.
   */
  private formHasChanged(): void {
    clearTimeout(this.formChangedTimeout)
    this.formChangedTimeout = setTimeout(() => {
      this.formDirty = true
      const data = this.form.value

      // emit the start datetime change
      if (data.startTime) {
        const start = dateJoin(data.startDate || this.currentDate, data.startTime)
        this.selectedStartTime = start
        if (!this.disableEmitDates) {
          this.selectedStartTimeChange.emit(start)
          this.selectedStartTimeOutputValue = start
        }
      }

      // emit the end datetime change
      if (data.endTime) {
        let end = dateJoin(data.endDate || this.currentDate, data.endTime)
        if (end <= data.startDate) {
          end = addDays(end, 1)
        }
        this.selectedEndTime = end
        if (!this.disableEmitDates) {
          this.selectedEndTimeChange.emit(end)
          this.selectedEndTimeOutputValue = end
        }
      }

      // emit the start date change
      if (data.startDate) {
        const curDate = dateCut(data.startDate, 'h')
        this.selectedStartDate = curDate
        if (!this.disableEmitDates) {
          this.selectedStartDateChange.emit(curDate)
          this.selectedStartDateOutputValue = curDate
        }
      }

      // emit the end date change
      if (data.endDate) {
        const curDate = dateCut(data.endDate, 'h')
        this.selectedEndDate = curDate

        if (dateJoin(data.endDate, data.endTime) < dateJoin(data.startDate, data.startTime)) {
          this.form.controls.endDate.setValue(data.endDate, 1)
        }

        if (!this.disableEmitDates) {
          this.selectedEndDateChange.emit(curDate)
          this.selectedEndDateOutputValue = curDate
        }
      }

      this.updateDefaultTimes()
      this.autoCheckAvailability()
    }, 50)
  }

  /**
   * Updates the {@link defaultStart} and {@link defaultEnd} properties by the currently present values in the form.
   * - If the user set the 'startTime' first, the default value for the 'endTime' will be 'startTime + 1 hour'.
   * - If the user set the 'endTime' first, the default value for the 'startTime' will be 'endTime - 1 hour'.
   */
  private updateDefaultTimes(): void {
    const data = this.form.value
    if (!data.startTime && data.endTime) {
      this.defaultStart = minusHours(dateCut(data.endTime, 'm'), 1)
    }

    if (!data.endTime && data.startTime) {
      this.defaultEnd = addHours(dateCut(data.startTime, 'm'), 1)
    }
  }

  /**
   * Executes the auto check for availability by the current {@link form} data.
   */
  private autoCheckAvailability(): void {
    if (this.autoCheckTimeout) {
      clearTimeout(this.autoCheckTimeout)
    }
    this.autoCheckTimeout = setTimeout(() => {
      this.onCheck()
    }, ProfileCalendarBookFormComponent.AUTO_CHECK_INTERVAL)
  }

  /**
   * Disables value changes from calendar while the {@link fun} is performing.
   */
  private disableCalendarInput(fun: () => void): void {
    this.enableCalendarInput = false
    fun()
    setTimeout(() => {
      this.enableCalendarInput = true
    }, 10)
  }

  /**
   * Marks the start and end date of the calendar as touched and dirty.
   */
  markDateFields(): void {
    this.form.controls['startDate'].markAsTouched()
    this.form.controls['endDate'].markAsTouched()
    this.form.controls['startDate'].markAsDirty()
    this.form.controls['endDate'].markAsDirty()
  }

  ngOnDestroy(): void {
    // unsubscribe all form value changes
    this.formSubs.forEach((it) => {
      it?.unsubscribe()
    })
    this.fieldSub?.unsubscribe()
  }
}
