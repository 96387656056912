import {Injectable, OnDestroy} from '@angular/core'
import {StorageItem, StorageService} from './storage.service'
import {PriceItemAdditionResp, PriceItemResp} from './price-item.service'
import {addMinutes} from '../utils/date.utils'
import {UserService} from './user.service'
import {BehaviorSubject, filter, Subscription} from 'rxjs'
import {ProfileType} from '../common/profile-type'
import {ProfileResp} from './profile.service'
import {BasketClearParams} from '../component/profile/profile-offer/profile-offer.component'
import {Restrictions} from '../common/restrictions'
import {AppModule, PLATFORM_BROWSER} from '../app.module'
import {BookInformation, BookingService} from './ui/booking.service'
import {OrderDetails} from '../component/profile/profile-offer/order-description/order-description.component'
import {AddressReq, PostalReq} from './address.service'

@Injectable({
  providedIn: 'root'
})
export class BasketService implements OnDestroy {
  /**
   * Indicates whether the Clear one profile button or the Clear all button has been clicked.
   */
  clearClicked = new BehaviorSubject<BasketClearParams>({
    clearArtist: false,
    clearAll: false,
    artist: null
  })
  /**
   * Enables visibility of badge next to the navbar shopping basket.
   */
  hasBasketItems: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   * Indicates when the basket will be cleared.
   * @deprecated created for the future
   */
  basketTimeout: BehaviorSubject<Date> = new BehaviorSubject<Date>(null)
  /**
   * Indicates when to call the function to reset the book-form fields.
   */
  callResetFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   * Specifies Map selected price items of the current artist profile {@link data.profileId}
   * and storages it into session storage.
   */
  private selectedPriceItemsById: Map<number, PriceItemResp[]>
  /**
   * Specifies Map of selected additions of the current artist profile {@link data.profileId}
   * and storages it into session storage.
   */
  private selectedAdditionsById: Map<number, PriceItemAdditionResp[]>
  /**
   * Specifies selected calendar data of the current artist profile {@link data.profileId}
   * and storages it into session storage.
   */
  private selectedCalendarData: BookInformation
  /**
   * Specifies selected address of the order.
   */
  private orderAddress: AddressReq
  /**
   * Specifies selected author address of the order.
   */
  private authorAddress: PostalReq
  /**
   * Specifies selected details of the order.
   */
  private detail: OrderDetails
  /**
   * ID of the timeout to clear basket.
   */
  private timerId: number
  /**
   * ID of the timeout to clear order info.
   */
  private timerIdOrder: number
  /**
   * ID of currently logged-in user's profile.
   */
  private profileId: number = null
  /**
   * Subscription to the current user.
   */
  private userSub?: Subscription

  constructor(
    private userService: UserService,
    private storageService: StorageService) {
    if (PLATFORM_BROWSER) {
      this.initFromLocalStorage()
      this.subscribeToUserChanges()
    }
  }

  /**
   * Adds provided item to {@link selectedPriceItemsById}.
   * If added successfully, fires {@link updateBasketStorage} and updates {@link timerId}
   * with new {@link setTimeout} callback.
   * @param profileId ProfileId, to which item belongs.
   * @param item Price item to be added to basket.
   */
  addPriceItem(profileId: number, item: PriceItemResp): void {
    if (this.add(profileId, this.selectedPriceItemsById, item)) {
      this.updateBasketStorage()
      // this.createNewBasketTimeout() // created for the future
    }
  }

  /**
   * Deletes provided item to {@link selectedPriceItemsById}.
   * If deleted successfully, fires {@link updateBasketStorage}.
   * @param profileId ProfileId, to which item belongs.
   * @param item Price item to be deleted from basket.
   */
  deletePriceItem(profileId: number, item: PriceItemResp): void {
    if (this.delete(profileId, this.selectedPriceItemsById, item)) {
      this.updateBasketStorage()
    }
  }

  /**
   * Returns price items in basket belonging to provided profileId.
   * @param profileId Provided profileId.
   */
  getPriceItemsOfProfile(profileId: number): PriceItemResp[] {
    if (!PLATFORM_BROWSER) {
      return
    }
    if (this.selectedPriceItemsById.has(profileId)) {
      return this.selectedPriceItemsById.get(profileId)
    }
    return []
  }

  /**
   * Deletes all price items for given profileId.
   */
  deletePriceItemsOfProfile(profileId: number): void {
    this.selectedPriceItemsById.delete(profileId)
    this.updateBasketStorage()
  }

  /**
   * Adds provided item to {@link selectedAdditionsById}.
   * If added successfully, fires {@link updateBasketStorage} and updates {@link timerId}
   * with new {@link setTimeout} callback.
   * @param profileId ProfileId, to which item belongs.
   * @param item Price item addition to be added to basket.
   */
  addPriceItemAddition(profileId: number, item: PriceItemAdditionResp): void {
    if (this.add(profileId, this.selectedAdditionsById, item)) {
      this.updateBasketStorage()
      // this.createNewBasketTimeout() // created for the future
    }
  }

  /**
   * Deletes provided item to {@link selectedAdditionsById}.
   * If deleted successfully, fires {@link updateBasketStorage}.
   * @param profileId ProfileId, to which item belongs.
   * @param item Price item addition to be deleted from basket.
   */
  deletePriceItemAddition(profileId: number, item: PriceItemAdditionResp): void {
    if (this.delete(profileId, this.selectedAdditionsById, item)) {
      this.updateBasketStorage()
    }
  }

  /**
   * Returns price items in basket belonging to provided profileId.
   * @param profileId Provided profileId.
   */
  getPriceItemAdditionsOfProfile(profileId: number): PriceItemAdditionResp[] {
    if (!PLATFORM_BROWSER) {
      return
    }
    if (this.selectedAdditionsById.has(profileId)) {
      return this.selectedAdditionsById.get(profileId)
    }
    return []
  }

  /**
   * Deletes all price item additions for given profileId.
   */
  deletePriceItemAdditionsOfProfile(profileId: number): void {
    this.selectedAdditionsById.delete(profileId)
    this.updateBasketStorage()
  }

  /**
   * Deletes all items from the basket and session storage.
   * Emits {@link clearClicked} with new value.
   */
  clearBasket(): void {
    this.initSelections()
    this.updateBasketStorage()
    this.clearOnlyUserData()
    this.isClearClicked(false, true)
  }

  /**
   * Clears the user-related data.
   * Removes the access token, fcm token, current profile, and roles.
   */
  clearOnlyUserData(): void {
    this.detail = null
    this.orderAddress = null
    this.authorAddress = null

    this.storageService.clearItemStorage(StorageItem.CURRENT_PROFILE)
    this.storageService.clearItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS)
    this.storageService.clearItemStorage(StorageItem.USER_ORDER_ADDRESS)
    this.storageService.clearItemStorage(StorageItem.USER_ORDER_INFO)
    // this.storageService.clearItemStorage(StorageItem.SHOPPING_ORDER_EXPIRES_AT) // created for the future
    AppModule.injector.get(BookingService).resetProfileBookDate()
    this.callResetFields.next(true)
    // this.killTimeoutOrder() // created for the future

    this.storageService.clearItemStorage(StorageItem.BOOK_DATES)
    // this.storageService.clearItemStorage(StorageItem.UPDATED_TIMEOUT_AFTER_LOGIN) // created for the future
  }

  /**
   * Indicates whether the Clear one profile button or the Clear all button has been clicked.
   */
  isClearClicked(clearArtist: boolean, clearAll: boolean, artist?: ProfileResp): void {
    this.clearClicked.next({clearArtist, clearAll, artist})
  }

  /**
   * Returns Ids of artists whose items are in basket.
   */
  getProfileIds(): number[] {
    return [...this.selectedPriceItemsById.keys(), ...this.selectedAdditionsById.keys()]
  }

  /**
   * Initializes {@link selectedPriceItemsById} and {@link selectedAdditionsById} as empty maps.
   */
  private initSelections(): void {
    this.selectedPriceItemsById = new Map()
    this.selectedAdditionsById = new Map()
  }

  /**
   * Inits data of keys
   * {@link StorageItem.SELECTED_PRICE_ITEMS_BY_ID},
   * {@link StorageItem.SELECTED_ADDITIONS_BY_ID},
   * {@link StorageItem.SELECTED_CALENDAR_DATA}
   * from session storage.
   */
  private initFromLocalStorage(): void {
    // additions
    this.selectedAdditionsById =
      new Map(JSON.parse(this.storageService.getItemStorage(StorageItem.SELECTED_ADDITIONS_BY_ID))) || new Map()

    // price items
    this.selectedPriceItemsById =
      new Map(JSON.parse(this.storageService.getItemStorage(StorageItem.SELECTED_PRICE_ITEMS_BY_ID))) || new Map()

    // calendar data
    const rawCalendarData = this.storageService.getItemStorage(StorageItem.SELECTED_CALENDAR_DATA)
    if (rawCalendarData) {
      this.selectedCalendarData = JSON.parse(rawCalendarData) as BookInformation
    }

    // initiate if basket has items
    const initBasketItems = this.selectedAdditionsById.size > 0 || this.selectedPriceItemsById.size > 0
    this.hasBasketItems.next(initBasketItems)

    // TODO CREATED FOR THE FUTURE
    // initiate current deletion time of the basket
    // const initTimeout = this.storageService.getItemStorage(StorageItem.SHOPPING_BASKET_EXPIRES_AT)
    // const initTimoutDate = initTimeout ? new Date(+initTimeout) : null
    // this.basketTimeout.next(initTimoutDate)
    // this.initiateTimout(initTimoutDate, new Date())

    // TODO CREATED FOR THE FUTURE
    // initiate current deletion time of the order
    // const initTimeoutOrder = this.storageService.getItemStorage(StorageItem.SHOPPING_ORDER_EXPIRES_AT)
    // const initTimoutDateOrder = initTimeoutOrder ? new Date(+initTimeoutOrder) : null
    // this.initiateTimoutOrder(initTimoutDateOrder, new Date())
  }

  /**
   * Decides if profileId exists in Map of selected items. If is, it adds selected items to the existed profileId,
   * if not then creates new profileId with selected items in Map.
   * Also determines if the local or session storage of the selected ordered artists is empty or not.
   */
  private add(profileId: number, selectedByProfileId: Map<number, any[]>, addedItem: any): boolean {
    // add selected if map with profileId already exists
    if (selectedByProfileId.has(profileId)) {

      // push only if it is not present
      const items = selectedByProfileId.get(profileId)
      for (const item of items) {
        if (item.id === addedItem.id) {
          return true // already added
        }
      }
      items.push(addedItem)

      // create new profileId of map to add selected
    } else {
      selectedByProfileId.set(profileId, [addedItem])
    }
    return true
  }

  /**
   * Deletes provided item from map of selectedByProfileId for given profileId.
   */
  private delete(profileId: number, selectedByProfileId: Map<number, any[]>, deletedItem: any): boolean {
    // if profileId is not in map
    if (!selectedByProfileId.has(profileId)) {
      return false
    }

    // delete from array
    const items = selectedByProfileId.get(profileId)
    for (let i = 0; i < items.length; i++) {
      if (deletedItem.id === items[i].id) {
        items.splice(i, 1)
        break
      }
    }

    // if empty for profile, delete from map
    if (items.length === 0) {
      selectedByProfileId.delete(profileId)
    }
    return true
  }

  /**
   * Updates session storage according to basket emptiness.
   * - If the basket is empty, then clears items from localstorage and kills timer.
   * - If the basket has items, then actualize those items in storage.
   */
  private updateBasketStorage(): void {
    if (this.selectedPriceItemsById.size <= 0 && this.selectedAdditionsById.size <= 0) {
      this.storageService.clearItemStorage(StorageItem.SELECTED_PRICE_ITEMS_BY_ID)
      this.storageService.clearItemStorage(StorageItem.SELECTED_ADDITIONS_BY_ID)
      // this.storageService.clearItemStorage(StorageItem.SHOPPING_BASKET_EXPIRES_AT) // created for the future
      // this.killTimeout() // created for the future
      this.hasBasketItems.next(false)
    } else {
      this.storageService.setItemStorage(StorageItem.SELECTED_PRICE_ITEMS_BY_ID, JSON.stringify([...this.selectedPriceItemsById]))
      this.storageService.setItemStorage(StorageItem.SELECTED_ADDITIONS_BY_ID, JSON.stringify([...this.selectedAdditionsById]))
      this.hasBasketItems.next(true)
    }
  }

  /**
   * Updates order items in local or session storage.
   * @param detailParam is the order detail info
   * @param orderAddressParam is the order address
   * @param authorAddressParam is the author order address
   * @param calendarDataParam is the selected calendar data
   */
  updateOrderStorage(
    detailParam?: OrderDetails,
    orderAddressParam?: AddressReq,
    authorAddressParam?: PostalReq,
    calendarDataParam?: BookInformation): void {
    // order detail info
    const detailVar = detailParam || this.detail
    if (detailVar) {
      this.storageService.setItemStorage(StorageItem.USER_ORDER_INFO, JSON.stringify(detailVar))
    }

    // order address
    const orderAddressVar = orderAddressParam || this.orderAddress
    if (orderAddressVar) {
      this.storageService.setItemStorage(StorageItem.USER_ORDER_ADDRESS, JSON.stringify(orderAddressVar))
    }

    // author order address
    const authorAddressVar = authorAddressParam || this.authorAddress
    if (authorAddressVar) {
      this.storageService.setItemStorage(StorageItem.USER_ORDER_AUTHOR_ADDRESS, JSON.stringify(authorAddressVar))
    }

    // selected calendar data
    this.selectedCalendarData = JSON.parse(this.storageService.getItemStorage(StorageItem.SELECTED_CALENDAR_DATA)) // need to update
    const calendarDataVar = calendarDataParam || this.selectedCalendarData
    if (calendarDataVar) {
      this.storageService.setItemStorage(StorageItem.SELECTED_CALENDAR_DATA, JSON.stringify(calendarDataVar))
    }
  }

  /**
   * Creates timout for clearing the basket.
   * Time is determined based on currently logged-in user.
   * @deprecated created for the future
   */
  private createNewBasketTimeout(): void {
    //first kill current timeout
    clearInterval(this.timerId)

    // new time based on current profileId
    const currentTime = new Date()
    let clearBasketTime: Date
    if (this.profileId) {
      clearBasketTime = addMinutes(currentTime, Restrictions.BASKET_LOGGED_USER_TIMEOUT)
    } else {
      clearBasketTime = addMinutes(currentTime, Restrictions.BASKET_DEFAULT_TIMEOUT)
    }
    this.storageService.setItemStorage(StorageItem.SHOPPING_BASKET_EXPIRES_AT, clearBasketTime.getTime().toString())
    this.initiateTimout(clearBasketTime, currentTime)
  }

  /**
   * Creates timout for clearing the order.
   * Time is determined based on currently logged-in user.
   * @deprecated created for the future
   */
  createNewOrderTimeout(): void {
    //first kill current timeout
    clearInterval(this.timerIdOrder)

    // new time based on current profileId
    const currentTime = new Date()
    let clearOrderTime: Date
    if (this.profileId) {
      clearOrderTime = addMinutes(currentTime, Restrictions.BASKET_LOGGED_USER_TIMEOUT)
    } else {
      clearOrderTime = addMinutes(currentTime, Restrictions.BASKET_DEFAULT_TIMEOUT)
    }
    this.storageService.setItemStorage(StorageItem.SHOPPING_ORDER_EXPIRES_AT, clearOrderTime.getTime().toString())
    this.initiateTimoutOrder(clearOrderTime, currentTime)
  }

  /**
   * {@link clearTimeout} the current {@link timerId}.
   * Then emits null to {@link basketTimeout}.
   * @deprecated created for the future
   */
  private killTimeout(): void {
    clearTimeout(this.timerId)
    this.basketTimeout.next(null)
  }

  /**
   * {@link clearTimeout} the current {@link timerIdOrder}.
   * @deprecated created for the future
   */
  killTimeoutOrder(): void {
    clearTimeout(this.timerIdOrder)
  }

  /**
   * Initiates current {@link timerId} based on given attributes.
   * If {@link clearTime} is null, then return from function.
   * Emits {@link clearTime} to {@link basketTimeout}.
   * @param clearTime Time when basket should be cleared.
   * @param currentTime Current time.
   * @deprecated created for the future
   */
  private initiateTimout(clearTime: Date, currentTime: Date): void {
    if (clearTime != null) {
      // this.killTimeout() // created for the future
      this.timerId = +setTimeout(() => {
        this.initSelections()
        this.updateBasketStorage()
        this.isClearClicked(false, true)
      }, clearTime.getTime() - currentTime.getTime())
    }
    this.basketTimeout.next(clearTime)
  }

  /**
   * Initiates current {@link timerIdOrder} based on given attributes.
   * If {@link clearTime} is null, then return from function.
   * @param clearTime Time when order should be cleared.
   * @param currentTime Current time.
   * @deprecated created for the future
   */
  private initiateTimoutOrder(clearTime: Date, currentTime: Date): void {
    if (clearTime != null) {
      // this.killTimeoutOrder() // created for the future
      this.timerIdOrder = +setTimeout(() => {
        this.updateOrderStorage()
        this.clearOnlyUserData()
      }, clearTime.getTime() - currentTime.getTime())
    }
  }

  /**
   * Subscribes to current user.
   * If current user is null or undefined or his profile is {@link ProfileType.ARTIST}, then skips.
   * If current user is ok, then add 24 hours to basket timeout.
   * @private
   */
  private subscribeToUserChanges(): void {
    this.userSub = this.userService.user.pipe(
      filter(user => user !== null && user !== undefined && user.currentProfile.profileType !== ProfileType.ARTIST)
    ).subscribe(user => {
      this.profileId = user.currentProfile.profileId
      // TODO CREATED FOR THE FUTURE
      // if (!this.storageService.getItemStorage(StorageItem.UPDATED_BASKET_TIMEOUT_AFTER_LOGIN)) {
      //   this.createNewBasketTimeout()
      //   this.createNewOrderTimeout()
      //   this.storageService.setItemStorage(StorageItem.UPDATED_BASKET_TIMEOUT_AFTER_LOGIN, '1')
      // }
    })
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe()
  }
}
