import {Feature, FeatureResp} from './feature'
import {UserResp} from '../service/user.service'

/**
 * Represents all available profile types that a user can create.
 */
export enum ProfileType {
  USER = 'USER',
  ARTIST = 'ARTIST',
  ENTERPRISE = 'ENTERPRISE',
  EVENT = 'EVENT'
}

/**
 * Checks whether provided user has given {@link ProfileType}s.
 * @param user Provided user.
 * @param profileTypes Array of types to be matched against.
 */
export function hasProfileTypes(user: UserResp, ...profileTypes: ProfileType[]): boolean {
  return profileTypes.some(it => it === user?.currentProfile.profileType)
}

/**
 * Checks whether the {@link ProfileType} has the required {@link features}.
 */
export function hasFeatures(type?: ProfileType, ...features: Feature[]): boolean {
  if (!type) {
    return false
  }
  const typeFeatures = profileFeatures(type)

  outer:
    for (const feature of features) {
      for (const typeFeature of typeFeatures) {
        if (feature === typeFeature.name) {
          continue outer
        }
      }
      return false
    }
  return true
}

/**
 * Checks whether the {@link ProfileType} has the required {@link feature}.
 * In case of matching, it returns the {@link FeatureResp} with optional parameters.
 */
export function getFeature(type: ProfileType, feature: Feature): FeatureResp | undefined {
  return profileFeatures(type).find((it) => it.name === feature)
}

/**
 * Returns all features of the profile type.
 * # Needs to be synchronized with the backend definition!
 */
export function profileFeatures(type: ProfileType): FeatureResp[] {
  switch (type) {
    case ProfileType.USER:
      return [
        {name: Feature.CHAT},
        {name: Feature.BE_IN_CHAT_GROUP},
        {name: Feature.LIKE_PROFILE},
        {name: Feature.LIKE_POST},
        {name: Feature.LIKE_POST_COMMENT},
        {name: Feature.ORDER_PROFILE}
      ]
    case ProfileType.ARTIST:
      return [
        {name: Feature.MULTIPLE_PROFILE},
        {name: Feature.SWITCH_FROM_USER_PROFILE},
        {name: Feature.WALLPAPER, max: 3},
        {name: Feature.PERFORM},
        {name: Feature.BE_IN_SCHEDULE},
        {name: Feature.CHAT},
        {name: Feature.BE_IN_CHAT_GROUP},
        {name: Feature.LIKE_PROFILE},
        {name: Feature.LIKE_POST},
        {name: Feature.LIKE_POST_COMMENT},
        {name: Feature.SKILLS},
        {name: Feature.PROFESSIONS},
        {name: Feature.GENRES},
        {name: Feature.PRICE_ITEMS},
        // {name: Feature.ORDER_PROFILE}, // for now only user can order.
        {name: Feature.BE_ORDERED},
        {name: Feature.SHOWCASE},
        {name: Feature.IMAGE_SHOWCASE, max: 30}
      ]
    case ProfileType.ENTERPRISE:
      return [
        {name: Feature.MULTIPLE_PROFILE},
        {name: Feature.WALLPAPER, max: 3},
        {name: Feature.CAN_HOST},
        {name: Feature.OPENING_HOURS},
        {name: Feature.CHAT},
        {name: Feature.LIKE_PROFILE},
        {name: Feature.LIKE_POST},
        {name: Feature.LIKE_POST_COMMENT},
        {name: Feature.PRICE_ITEMS},
        {name: Feature.ORDER_PROFILE},
        {name: Feature.SHOWCASE}
      ]
    case ProfileType.EVENT:
      return [
        {name: Feature.MULTIPLE_PROFILE},
        {name: Feature.WALLPAPER, max: 1},
        {name: Feature.BE_HOSTED},
        {name: Feature.SCHEDULE},
        {name: Feature.CHAT},
        {name: Feature.LIKE_POST},
        {name: Feature.LIKE_POST_COMMENT},
        {name: Feature.GENRES},
        {name: Feature.PRICE_ITEMS},
        {name: Feature.ORDER_PROFILE},
        {name: Feature.SHOWCASE},
        {name: Feature.PRICE_ITEMS, max: 1}
      ]
  }
}
