<footer class="wrapper p-d-flex p-jc-center p-p-5">
  <div class="component-wrapper">

    <!--Main footer sections with links-->
    <div class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-sm-center p-py-2">
        <div class="set-width">
          <h2 i18n>Links</h2>
          <ul class="p-grid">
            <li><a href="https://umevia.com" i18n>About</a></li>
            <li><a routerLink="/{{NS.PROFILE_CATALOG}}" i18n>Catalog</a></li>
            <li><a (click)="cookieSettings.showSettingsDialog()" i18n>Cookies</a></li>
          </ul>
        </div>

        <div class="set-width">
          <h2 i18n>Support</h2>
          <ul class="p-grid">
            <li><a routerLink="/{{NS.SUPPORT}}" i18n>Umevia Support</a></li>
            <li><a [href]="contactInfo.docs" i18n>Umevia Docs</a></li>
            <li><a href="https://stripe.com" target="_blank" rel="noopener noreferrer" i18n>Payments</a></li>
            <li><a href=""></a></li>
          </ul>
        </div>

        <div class="set-width">
          <h2 i18n>Contact</h2>
          <ul class="p-grid">
            <li><a href="mailto:support@umevia.com">support&#64;umevia.com</a></li>
            <li><a href="tel:{{contactInfo.phone}}">{{ contactInfo.phone | phoneNumber }}</a></li>
            <li><a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeS_hiTBugA9p_f2nq67mAcuuN54C1M4jQFRHVKotN7ASXRqQ/viewform?pli=1"
              target="_blank" rel="noopener noreferrer" i18n>Feedback</a></li>
            <li><a href="mailto:contact@umevia.com" i18n>Cooperation</a></li>
          </ul>
        </div>
      </div>

      <!-- Social links -->
      <div>
        <li class="p-col-12 p-d-flex p-ai-center p-jc-center brands p-mt-2">
          <a [href]="contactInfo.instagram" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-instagram p-mr-3"></i>
          </a>
          <a [href]="contactInfo.x" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-x-twitter p-mr-3"></i>
          </a>
          <a [href]="contactInfo.youtube" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-youtube p-mr-3"></i>
          </a>
          <a [href]="contactInfo.facebook" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-facebook p-mr-3"></i>
          </a>
          <a [href]="contactInfo.linkedin" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-linkedin p-mr-3"></i>
          </a>
          <a [href]="contactInfo.docs" target="_blank" rel="noopener noreferrer">
            <i class="fa-solid fa-book"></i>
          </a>
        </li>
      </div>
    </div>

    <!--Divider line-->
    <hr class="divider">

    <!--Terms of use, etc.-->
    <div class="p-d-flex p-flex-column p-ai-center p-px-2">
      <p>&copy; {{ currentDate | date: 'yyyy' }} Umevia s.r.o.</p>
      <p class="p-mb-3" i18n>All rights reserved.</p>

      <div class="p-d-flex">
        <div><a class="p-px-1" [routerLink]="navigation.urlLegalDocuments('privacy')" i18n>Privacy</a></div>
        <div>•<a class="p-px-1" [routerLink]="navigation.urlLegalDocuments('terms')" i18n>Terms</a></div>
      </div>
    </div>
  </div>
</footer>
