<!--Splash screen-->
<app-splash-screen></app-splash-screen>

<!--Main Content-->
<div class="p-relative" *ngIf="bootstrap">
  <app-navigation></app-navigation>
  <div class="navigation-padding"></div>
  <router-outlet></router-outlet>
  <app-pwa-dialog></app-pwa-dialog>
  <app-chat-inbox></app-chat-inbox>
  <app-order-awaiting-confirm></app-order-awaiting-confirm>
  <app-snackbar></app-snackbar>
  <app-platform-rating *ngIf="isBrowser"></app-platform-rating>
  <app-dialog-cookies></app-dialog-cookies>
  <app-permission-dialog></app-permission-dialog>
  <app-logout></app-logout>
  <!--<app-feature-showcase></app-feature-showcase>-->

  <!--Foreground notifications-->
  <div class="notification p-fixed p-d-flex p-jc-center">
    <app-foreground-notification></app-foreground-notification>
  </div>
</div>


