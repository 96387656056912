import {Component} from '@angular/core'
import {growAnimation} from '../../../animation/grow.animation'
import {TwoFactorAbstractComponent} from '../abstract/two-factor-abstract.component'
import {UserResp} from '../../../service/user.service'

/**
 * Two factor authentication component used as a single page. Logic is implemented in the abstract class.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-two-factor',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent extends TwoFactorAbstractComponent {

  afterCodeVerification(user: UserResp): void {
    setTimeout(() => {
      this.afterSuccessRedirect(user, false)
    }, 1000)
  }
}
