import {Injectable} from '@angular/core'
import {ApiService} from '../api.service'
import {BaseResp} from '../../rest/base-resp'
import {Observable} from 'rxjs'
import {Endpoint} from '../../common/endpoints'

@Injectable({
  providedIn: 'root'
})
export class LikeProfileService extends ApiService {

  constructor() {
    super()
  }

  callUpdateLikeProfile(req: UpdateLikeProfileReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.LIKE_PROFILE_UPDATE, req)
  }

  callGetLikeProfile(req: GetLikeProfileReq): Observable<BaseResp<LikeProfileResp>> {
    return this.post(Endpoint.LIKE_PROFILE_GET, req)
  }
}

export interface UpdateLikeProfileReq {
  from: number
  to: number
  // represents whether the request should like the profile or not
  like: boolean
}

export interface GetLikeProfileReq {
  from: number
  to: number
}

export interface LikeProfileResp {
  count: number
  liked: boolean
}
