<app-dialog [(show)]="show"
            [component]="this"
            type="save"
            (discard)="closeEditComponent()"
            [disableSaveOption]="loading || saving"
            url="payment"
            header="Payment required"
            i18n-header
            [styleClass]="currentSelectedMethod === PM.BANK_TRANSFER && isScreenOf(Screen.MD) ? 'dialog-bank-transfer' : 'normal-dialog'"
            successMessage="Payment successful"
            i18n-successMessage
            [errorMessage]="errorMessage">

  <section class="p-relative">
    <!--Skeleton loading-->
    <div *ngIf="loading || !clientSecret || !paymentReady"
         class="p-py-2 loading-content" @grow>

      <p-skeleton height="1rem" width="5rem" styleClass="rounded-small"></p-skeleton>
      <p-skeleton height="3rem" width="100%" styleClass="rounded-small p-mt-1"></p-skeleton>
      <div class="p-grid p-mt-3">
        <div class="p-col-12 p-sm-6 p-p-0 p-pr-sm-1">
          <p-skeleton height="1rem" width="10rem" styleClass="rounded-small"></p-skeleton>
          <p-skeleton height="3rem" width="100%" styleClass="rounded-small p-mt-1"></p-skeleton>
        </div>
        <div class="p-col-12 p-sm-6 p-p-0 p-pl-sm-1">
          <p-skeleton height="1rem" width="2rem" styleClass="rounded-small"></p-skeleton>
          <p-skeleton height="3rem" width="100%" styleClass="rounded-small p-mt-1"></p-skeleton>
        </div>
      </div>
      <p-skeleton height="1rem" width="5rem" styleClass="rounded-small p-mt-3"></p-skeleton>
      <p-skeleton height="3rem" width="100%" styleClass="rounded-small p-mt-1"></p-skeleton>
    </div>

    <div class="p-grid">
      <!--Stripe-->
      <section *ngIf="!loading && clientSecret"
               [class.p-d-none]="!paymentReady"
               [class.p-md-6]="currentSelectedMethod === PM.BANK_TRANSFER"
               class="p-col-12 p-p-0">
        <!--Price to pay-->
        <h2 class="p-text-center p-mt-0" *ngIf="paymentReady" @grow>
          {{ ((bankInstructions?.amountRemaining || 0) / 100) || orderAmount | price }}
        </h2>

        <!--Content-->
        <div class="p-py-2 payment-content">
          <ngx-stripe-payment [clientSecret]="clientSecret"
                              [stripe]="stripe"
                              [options]="options"
                              (ready)="paymentReady = true"
                              (change)="onChangePaymentMethod($event)"
                              (escape)="closeEditComponent()"
                              lang="sk"
                              [doNotCreateUntilClientSecretIsSet]="true"></ngx-stripe-payment>
        </div>

        <div *ngIf="paymentReady" @grow>

          <!--BE-CHECK-->
          <app-backend-validation [showAsBox]="true"
                                  [showAsSuccess]="true"
                                  [serverMessages]="serverMessages"
                                  [check]="ServerMessage.PROFILE_ORDER_ALREADY_PAID" i18n>
            This order has been already paid.
          </app-backend-validation>

          <!--Secured by Stripe-->
          <span class="p-d-flex p-flex-wrap p-jc-center p-ai-center p-my-2">
          <i class="fa-solid fa-lock p-mr-2"></i>
          <span class="p-mr-2 p-text-center" i18n>Payments are provided and secured by</span>
          <a href="https://stripe.com" target="_blank"><i class="fa-brands fa-stripe stripe-icon p-mt-1"></i></a>
        </span>

          <!-- Stripe Webhook Enabled -->
          <app-checkbox *ngIf="!production && isLocalEnvironment && currentSelectedMethod !== PM.BANK_TRANSFER"
                        label="[TEST]: Stripe Webhook enabled"
                        i18n-label
                        hint="Enable this option when you have a running Stripe webhook on the machine where the server is running."
                        i18n-hint
                        [(check)]="webhookEnabled"
                        styleClass="p-mt-2"></app-checkbox>
        </div>
      </section>

      <section *ngIf="currentSelectedMethod === PM.BANK_TRANSFER" @grow
               class="p-col-12 p-md-6 p-pl-0 p-pl-md-3 p-pt-2 p-pt-md-0 p-pr-0 p-pb-0">
        <app-hint icon="fa-solid fa-building-columns"
                  [collapsableHint]="false">
          <div>
            <div i18n>
              By continuing, you would have to make a bank transfer to <b>Ireland</b>, since the
              Stripe payment gateway resides in Ireland.
            </div>
            <br>
            <div i18n>
              Ireland belongs to SEPA countries, so no extra fees might be applied when you make a SEPA transfer.
              But if you don't have a frequent-used bank, ensure your bank doesn't charge extra fees. Otherwise, you
              will have to increase your payment by these fees.
            </div>
            <br>
            <div i18n>
              If you are not confident, rather make a payment with a <b>card</b>, or contact your bank.
            </div>
          </div>
        </app-hint>

        <b class="p-my-2 p-d-block" i18n>Some of frequent-used banks</b>
        <p-carousel [value]="popularBanks"
                    [numVisible]="4"
                    [numScroll]="2"
                    [responsiveOptions]="popularBanksOpts"
                    [showIndicators]="false">
          <ng-template pTemplate="item" let-item>
            <div class="p-d-flex p-jc-center h-100">
              <div
                class="bank-item p-d-flex p-flex-column p-ai-center p-jc-between p-p-1 rounded-small p-m-1 unselectable">
                <i class="fa-solid fa-building-columns"></i>
                <b class="l-count-2">{{ item.title }}</b>
                <small *ngIf="!item.fee" class="p-mt-1" i18n>Without fees</small>
                <div *ngIf="item.fee" class="p-d-flex p-ai-center">
                  <i *ngIf="item.description"
                     class="fa-solid fa-info-circle p-mr-2"
                     [appTooltip]="item.description"></i>
                  <small>{{ item.fee }}</small>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
        <div class="w-100 p-d-flex p-jc-center banks-update-date">
          <small><i i18n>Updated</i>: 9.7. 2024</small>
        </div>
      </section>
    </div>
  </section>

  <!--Bottom bar-->
  <ng-template let-discardDisable="discardDisable"
               let-onDiscard="onDiscard" let-onSave="onSave" let-saveDisable="saveDisable"
               let-saveLoading="saveLoading" pTemplate="bottomBar">

    <div class="w-100 p-d-flex p-jc-between p-ai-center">
      <!-- Call -->
      <a href="tel:{{contact.phone}}" *ngIf="paymentReady">
        <app-button type="secondary"
                    label="Help"
                    [labelAutoHide]="true"
                    i18n-label
                    styleClass="p-my-2"
                    icon="fa-solid fa-phone"/>
      </a>

      <!-- Not Now -->
      <app-button type="text"
                  label="Later"
                  i18n-label
                  styleClass="p-mr-2"
                  [disabled]="discardDisable"
                  (clicked)="onDiscard()"/>

      <!--Pay-->
      <app-button *ngIf="!loading && clientSecret && paymentReady"
                  type="cta"
                  [label]="payButtonLabel"
                  [highlight]="true"
                  icon="fa-solid fa-credit-card"
                  [disabled]="loading || saveDisable"
                  [loading]="saving || saveLoading"
                  (clicked)="onPay()"/>
    </div>
  </ng-template>
</app-dialog>

