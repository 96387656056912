<!--Input field-->
<span class="p-input-icon-left w-100">
    <i class="fa-solid fa-magnifying-glass"></i>
      <input #searchField
             type="text"
             pInputText
             [ngModel]="searchValue"
             placeholder="Search profiles"
             i18n-placeholder
             class="p-inputtext-sm w-100"
             (ngModelChange)="onSearchInput()"
             (click)="searchOverlayPanel.show($event)"
      />
    </span>

<!--BE-CHECK-->
<app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
  Your profile does not exists.
</app-backend-validation>

<app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER" i18n>
  This profile does not belong to you.
</app-backend-validation>

<!--Search result-->
<p-overlayPanel #searchOverlayPanel>
  <ng-template pTemplate>
    <div class="overlay-panel-container">

      <!--Information-->
      <p *ngIf="searching" class="p-d-flex p-ai-center" i18n>
        <i class="fa fa-circle-notch fa-spin p-mr-2"></i>
        Searching...</p>
      <p *ngIf="!searching && searchValue?.length < 3" i18n>Keep typing...</p>
      <p *ngIf="!searching && searchProfiles.length === 0 && searchValue?.length >= 3" i18n>Nothing found.</p>

      <!--Result-->
      <p-dataView *ngIf="searchProfiles.length > 0" [value]="searchProfiles" [@fade]="searchProfiles.length > 0">
        <ng-template let-profiles pTemplate="list">
          <div class="p-grid p-p-0">
            <div class="p-col-12 p-p-0" *ngFor="let profile of profiles">
              <!--Profile item-->
              <div class="w-100 p-d-flex p-ai-center p-p-2 profile-item"
                   (click)="onProfileClick(profile)">
                <!--Avatar-->
                <div class="p-mr-3">
                  <app-avatar [profile]="profile" [redirect]="false"></app-avatar>
                </div>
                <!--Name-->
                <app-profile-names [profile]="profile"
                                   [redirect]="false"
                                   [displayNameLinesCount]="2"
                                   [size]="3"
                                   [showPointer]="true"
                ></app-profile-names>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </ng-template>
</p-overlayPanel>
