<div class="wrapper p-py-2 p-d-flex p-jc-center" *ngIf="images?.length > 0 || editButtonVisible">
  <div class="component-wrapper p-px-3">

    <h2 class="p-mt-0 p-mb-2" i18n>Images</h2>

    <!-- Edit -->
    <app-button *ngIf="editButtonVisible"
                type="edit"
                styleClass="p-mb-2"
                (clicked)="editComponentVisible = true"></app-button>

    <!--Grid-->
    <div *ngIf="isScreenOf(Screen.MD); else wide" class="p-grid p-p-0 p-relative">
      <!--More images button-->
      <div *ngIf="isScreenOf(Screen.MD) && images?.length > 5"
           (click)="openMoreImages()"
           class="more-images p-absolute rounded-small p-px-2 p-py-1 p-d-flex p-ai-center c-pointer">
        <i class="fa-solid fa-image p-mr-2"></i>
        <div i18n>More images</div>
      </div>

      <!--Content-->
      <div class="p-col-6 p-p-0">
        <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{$implicit: 0, isGrid: false}"/>
      </div>
      <div class="p-col-6 p-p-0 p-flex-column">
        <div class="p-grid p-p-0">
          <div class="p-col-6 p-p-0 grid-image">
            <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{$implicit: 1, isGrid: true}"/>
          </div>
          <div class="p-col-6 p-p-0 grid-image">
            <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{$implicit: 2, isGrid: true}"/>
          </div>
        </div>
        <div class="p-grid p-p-0">
          <div class="p-col-6 p-p-0 grid-image">
            <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{$implicit: 3, isGrid: true}"/>
          </div>
          <div class="p-col-6 p-p-0 grid-image">
            <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{$implicit: 4, isGrid: true}"/>
          </div>
        </div>
      </div>
    </div>

    <!--Images counter label-->
    <div *ngIf="!isScreenOf(Screen.MD) && images?.length > 0"
         class="images-counter p-absolute rounded-small p-px-2 p-py-1">
      <div>{{ currentCarouselPage + 1 }}/{{ images.length }}</div>
    </div>

    <!--Small screens preview-->
    <ng-template #wide>
      <p-carousel *ngIf="images?.length > 0"
                  [value]="images"
                  [autoplayInterval]="5000"
                  [page]="currentCarouselPage"
                  (onPage)="currentCarouselPage = $event.page"
                  [showIndicators]="false"
                  [circular]="true">
        <ng-template pTemplate="item" let-item>
          <ng-template [ngTemplateOutlet]="image" [ngTemplateOutletContext]="{image: item.url}"/>
        </ng-template>
      </p-carousel>
    </ng-template>
  </div>
</div>

<!--Image template-->
<ng-template #image let-imgIndex let-image="image" let-isGrid="isGrid">
  <div class="p-p-1 h-100">
    <ng-template [ngIf]="image || images[imgIndex]?.url">
      <div class="fit-image w-100 h-100 rounded-small c-pointer"
           (click)="openGalleria(imgIndex)"
           [style]="(image || images?.[imgIndex]?.url) | backgroundImage"
           [class.grid-image]="isGrid"
           [class.large-image]="!isGrid"></div>
    </ng-template>

    <!--Skeleton-->
    <div *ngIf="!image && !images?.[imgIndex]?.url"
         class="image-placeholder w-100 h-100 rounded-small p-d-flex p-ai-center p-jc-center">
      <i class="fa-solid fa-image"></i>
    </div>
  </div>
</ng-template>

<!--Fullscreen all images-->
<p-sidebar [(visible)]="imagesPreviewVisible"
           (visibleChange)="onPreviewVisibilityChange($event)"
           [closeOnEscape]="true"
           [dismissible]="true"
           [modal]="false"
           [baseZIndex]="100000"
           #sidebar
           [fullScreen]="true">
  <ng-template pTemplate="header">
    <div class="p-d-flex p-ai-center p-p-2">
      <i class="fa-solid fa-arrow-left p-mr-2 c-pointer" (click)="sidebar.close($event)"></i>
      <h3 class="p-my-0" i18n>Images</h3>
    </div>
  </ng-template>
  <div class="p-d-flex p-ai-center p-flex-column p-py-3">
    <div class="p-grid images-preview-container w-100">
      <div *ngFor="let item of images; index as i"
           class="p-col-12 p-p-0"
           [class.p-sm-6]="i % 3 !== 0">
        <ng-template [ngTemplateOutlet]="image"
                     [ngTemplateOutletContext]="{$implicit: i, image: item.url}"></ng-template>
      </div>
    </div>
  </div>
</p-sidebar>

<!--Fullscreen galleria-->
<app-galleria *ngIf="isScreenOf(Screen.MD)"
              [(visible)]="fullscreenGalleriaVisible"
              [(activeIndex)]="currentGalleriaIndex"
              [images]="images"
              (visibleChange)="onGalleriaVisibilityChange($event)"/>

<!--Edit component-->
<app-profile-image-showcase-edit *ngIf="editComponentVisible"
                                 [(show)]="editComponentVisible"
                                 [profile]="profile"
                                 (changed)="onImagesChanged($event)"></app-profile-image-showcase-edit>
