import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {newEmptyPage, Page} from '../../../../rest/page-resp'
import {firstValueFrom, Observable} from 'rxjs'
import {BaseResp} from '../../../../rest/base-resp'
import {GenreRest} from '../../../../service/profile.service'
import {BriefProfessionResp, ProfessionService} from '../../../../service/profession.service'
import {SkillResp, SkillService} from '../../../../service/skill.service'
import {BriefPriceItemCategoryResp, PriceItemService} from '../../../../service/price-item.service'
import {ValidatorFn} from '@angular/forms'
import {growAnimation} from '../../../../animation/grow.animation'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {GenreService} from '../../../../service/genre.service'
import {Restrictions} from '../../../../common/restrictions'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {InitDirective} from '../../../../directive/init.directive'
import {NgForOf, NgIf, SlicePipe} from '@angular/common'
import {ButtonComponent} from '../../../common/button/button.component'
import {RippleModule} from 'primeng/ripple'
import {SearchComponent} from '../../../common/search/search.component'
import {SharedModule} from 'primeng/api'
import {FrontendValidationComponent} from '../../../common/frontend-validation/frontend-validation.component'

/**
 * This dialog can be used to save attributes of the currently logged profile or just filtering all available attributes in the database.
 */
@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-attribute-search-dialog',
  templateUrl: './attribute-search-dialog.component.html',
  styleUrls: ['./attribute-search-dialog.component.scss'],
  imports: [
    DialogComponent,
    InitDirective,
    NgIf,
    ButtonComponent,
    NgForOf,
    RippleModule,
    SearchComponent,
    SharedModule,
    FrontendValidationComponent,
    SlicePipe
  ],
  standalone: true
})
export class AttributeSearchDialogComponent<E extends BriefProfessionResp | SkillResp | GenreRest | BriefPriceItemCategoryResp>
  extends EditableComponent implements OnInit, OnChanges {

  /**
   * All currently selected items.
   */
  @Input()
  selectedItems: E[]
  /**
   * All items gets copied into this array in the purpose of not modifying the original items.
   */
  selectedItemsCopy: E[] = []
  /**
   * Any updates of the {@link selectedItems}.
   */
  @Output()
  selectedItemsChange = new EventEmitter<E[]>()
  /**
   * Defines what attributes should be loaded.
   */
  @Input({required: true})
  type: 'skills' | 'genres' | 'professions' | 'price-item-categories'
  /**
   * Defines a behavior of the dialog.
   * - `Save` - Saves selected items to the currently logged profile.
   * - `Info` - Just filters available items in the database.
   */
  @Input()
  behavior: 'info' | 'save' = 'info'
  /**
   * A success message to be displayed when the {@link behavior} is `save`.
   */
  successMessage: string

  /**
   * - All loaded most-used items.
   * - From these items the user can also pick.
   */
  mostUsedItems: E[] = []

  /**
   * All items returned from a search process.
   */
  searchItems: Page<E> = newEmptyPage()

  /**
   * The search function for items. Updates the {@link searchItems}.
   */
  searchFunction: (pageNum: number, input: string) => Observable<Page<any>>

  /**
   * Through this function the {@link mostUsedItems} gets loaded.
   */
  mostUsedFunction: () => Observable<BaseResp<E[]>>
  /**
   * A save function when the {@link behavior} is `save`.
   */
  @Input()
  saveFunction: () => Observable<BaseResp<any>>
  /**
   * Determines whether the default {@link saveFunction} will be overridden. Don't forget to initialize the {@link saveFunction}.
   */
  @Input()
  overrideSaveFunction: boolean
  /**
   * Any supplementary validators that will be added to the search bar.
   */
  @Input()
  searchValidators: ValidatorFn[] = []

  /**
   * A placeholder text in a search bar.
   */
  searchPlaceholder: string

  /**
   * The dialog header text.
   */
  @Input({required: true})
  dialogHeader: string

  /**
   * Maximum allowed length in a search bar.
   */
  @Input()
  maxSearchLength: number
  /**
   * Defines the maximum allowed items.
   */
  maxItems: number
  /**
   * The current dialog window.
   */
  dialogComponent: DialogComponent

  /**
   * Translations
   */
  protected trans = {
    apply: $localize`Apply`,
    close: $localize`Close`
  }

  constructor(
    private genreService: GenreService,
    private skillService: SkillService,
    private priceItemService: PriceItemService,
    private professionService: ProfessionService) {
    super()
  }

  ngOnInit(): void {
    this.initFunctions()
    // call most used items only when this function is present
    this.call(async () => {
      this.mostUsedItems = await firstValueFrom(this.unwrap(this.mostUsedFunction()))
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems?.currentValue) {
      this.selectedItemsCopy = [...this.selectedItems]
    }
  }

  /**
   * Initializes calling functions based on the {@link type} input.
   */
  private initFunctions(): void {
    switch (this.type) {
      case 'skills':
        this.searchFunction = (pageNum: number, input: string) => this.unwrap(this.skillService.callSearchSkill({
          name: input,
          page: pageNum
        }))
        this.mostUsedFunction = this.skillService.callGetMostUsed.bind(this.skillService)
        if (!this.overrideSaveFunction) {
          this.saveFunction = this.saveSkills.bind(this)
        }
        this.maxSearchLength = Restrictions.MAX_SKILL_NAME_LENGTH
        this.maxItems = (this.behavior === 'save') ? Restrictions.MAX_SKILLS_PER_PROFILE : null
        this.searchPlaceholder = $localize`Guitar, Piano, Singing, ...`
        this.successMessage = $localize`Skills saved`
        break
      case 'genres':
        this.searchFunction = (pageNum: number, input: string) => this.unwrap(this.genreService.callSearchGenre({
          name: input,
          page: pageNum
        }))
        this.mostUsedFunction = this.genreService.callGetMostUsed.bind(this.genreService)
        if (!this.overrideSaveFunction) {
          this.saveFunction = this.saveGenres.bind(this)
        }
        this.maxSearchLength = Restrictions.MAX_GENRE_NAME_LENGTH
        this.maxItems = (this.behavior === 'save') ? Restrictions.MAX_GENRES_PER_PROFILE : null
        this.searchPlaceholder = $localize`Funk, Rock, Blues, ...`
        this.successMessage = $localize`Genres saved`
        break
      case 'price-item-categories':
        this.searchFunction = (pageNum: number, input: string) => this.unwrap(this.priceItemService.callSearchPriceItemCategories({
          name: input,
          page: pageNum
        }))
        this.mostUsedFunction = this.priceItemService.callGetMostUsedCategories.bind(this.priceItemService)
        this.searchPlaceholder = $localize`Birthday, Outdoor, Wedding, ...`
        this.successMessage = $localize`Categories saved`
        break
      case 'professions':
        this.searchFunction = (pageNum: number, input: string) => this.unwrap(this.professionService.callSearchProfession({
          name: input,
          page: pageNum
        }))
        if (!this.overrideSaveFunction) {
          this.saveFunction = this.saveProfessions.bind(this)
        }
        this.mostUsedFunction = this.professionService.callGetMostUsed.bind(this.professionService)
        this.maxSearchLength = Restrictions.MAX_PROFESSION_NAME_LENGTH
        this.maxItems = (this.behavior === 'save') ? Restrictions.MAX_PROFESSION_PER_PROFILE : null
        this.searchPlaceholder = $localize`Musician, DJ, Comedian, ...`
        this.successMessage = $localize`Professions saved`
        break
    }
  }

  /**
   * Adds a user selected {@link item}  to the {@link selectedItems} array.
   */
  add(item: E): void {
    // Max items
    if (this.maxItems && this.selectedItemsCopy.length >= this.maxItems) {
      this.dialogComponent?.scrollBottom()
      return
    }
    // Skip already added
    for (const it of this.selectedItemsCopy) {
      if (it.id === item.id) {
        return
      }
    }
    // Add
    this.selectedItemsCopy.push(item)
    this.emitChanges()
  }

  /**
   * Removes the user selected {@link item} from the {@link selectedItems} array.
   */
  remove(item: E): void {
    this.selectedItemsCopy = this.selectedItemsCopy.filter(it => it.id !== item.id)
    this.emitChanges()
  }

  /**
   * Emits changes to the {@link selectedItemsChange} emitter only if the {@link behavior} is not `save`.
   * @param force Forces to emit the changes.
   */
  emitChanges(force: boolean = false): void {
    if (this.behavior !== 'save' || force || this.overrideSaveFunction) {
      this.selectedItemsChange.emit(this.selectedItemsCopy)
    }
  }

  /**
   * Saves the skills for a currently logged profile.
   */
  private async saveSkills(): Promise<void> {
    await firstValueFrom(this.unwrap(this.skillService.callUpdateProfileSkills({
      ids: this.selectedItemsCopy.map(it => it.id)
    })))
  }

  /**
   * Saves the genres for a currently logged profile.
   */
  private async saveGenres(): Promise<void> {
    await firstValueFrom(this.unwrap(this.genreService.callUpdateProfileGenres({
      ids: this.selectedItemsCopy.map(it => it.id)
    })))
  }

  /**
   * Saves the professions for a currently logged profile.
   */
  private async saveProfessions(): Promise<void> {
    await firstValueFrom(this.unwrap(this.professionService.callUpdateProfileProfessions({
      ids: this.selectedItemsCopy.map(it => it.id)
    })))
  }
}
