<div [ngSwitch]="authAction" class="p-d-flex p-jc-center">

  <!--Action Buttons-->
  <div class="p-grid p-jc-center account-form p-text-center"
       *ngSwitchCase="AuthActionType.DECISION">

    <h2 i18n class="p-mb-0">Almost there!</h2>
    <p class="p-p-3" i18n>We'll take care of your order while you check in with us.</p>

    <!--Register-->
    <div class="p-col-12 p-px-0">
      <app-button type="primary"
                  label="Register"
                  i18n-label
                  styleClass="w-100"
                  (clicked)="authAction = AuthActionType.REGISTER"
                  icon="fa-solid fa-user-plus">
      </app-button>
    </div>

    <!--Login-->
    <div class="p-col-12 p-px-0">
      <app-button type="secondary"
                  label="Login"
                  i18n-label
                  styleClass="w-100 submit-button"
                  icon="fa-solid fa-paper-plane"
                  (clicked)="authAction = AuthActionType.LOGIN">
      </app-button>
    </div>

    <!--Google authentication-->
    <div class="p-col-12 p-px-0">
      <app-firebase-auth
        [authType]="AuthType.GOOGLE"
        buttonStyle="default"
        [buttonDisabled]="false"
        (firebaseAuthResponse)="firebaseAuthResponseAction($event)">
      </app-firebase-auth>
    </div>
  </div>

  <div *ngSwitchCase="AuthActionType.LOGIN" class="account-form p-text-center">
    <ng-template *ngTemplateOutlet="backButton"></ng-template>
    <app-login-embed
      (secondFactorReq)="authAction = AuthActionType.TWO_FACTOR"
      (registerFirebaseReq)="authAction = AuthActionType.REGISTER_FIREBASE">
    </app-login-embed>
  </div>

  <div *ngSwitchCase="AuthActionType.TWO_FACTOR" class="account-form p-text-center">
    <ng-template *ngTemplateOutlet="backButton"></ng-template>
    <app-two-factor-embed></app-two-factor-embed>
  </div>

  <div *ngSwitchCase="AuthActionType.REGISTER" class="account-form p-text-center">
    <ng-template *ngTemplateOutlet="backButton"></ng-template>
    <app-register-embed [displayName]="authorAddress.name"></app-register-embed>
  </div>

  <div *ngSwitchCase="AuthActionType.REGISTER_FIREBASE" class="account-form p-text-center">
    <ng-template *ngTemplateOutlet="backButton"></ng-template>
    <app-register-firebase-embed [displayName]="authorAddress.name"
                                 (invalidRequest)="authAction = AuthActionType.DECISION">
    </app-register-firebase-embed>
  </div>

</div>

<ng-template #backButton>
  <app-button type="text"
              label="Back"
              i18n-label
              icon="fa-solid fa-arrow-left"
              (clicked)="authAction = AuthActionType.DECISION"></app-button>
</ng-template>
