import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core'
import {ScreenSize} from '../../../../utils/device.utils'
import {PriceItemAdditionResp, PriceItemResp} from '../../../../service/price-item.service'
import {EditableComponent} from '../../../abstract/editable.component'
import {ProfileResp} from '../../../../service/profile.service'
import {BasketService} from '../../../../service/basket.service'
import {MenuItem} from 'primeng/api'
import {Subscription} from 'rxjs'
import {hoursDifference} from '../../../../utils/date.utils'
import {BasketClearParams} from '../profile-offer.component'
import {scrollToIndexOptions} from '../../../../utils/scroll.utils'
import {DialogComponent} from '../../../common/dialog/dialog.component'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {growAnimation} from '../../../../animation/grow.animation'

@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-profile-offer-inventory',
  templateUrl: './profile-offer-inventory.component.html',
  styleUrls: ['./profile-offer-inventory.component.scss']
})
export class ProfileOfferInventoryComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * The data of the profile.
   */
  @Input()
  data: ProfileResp
  /**
   * Defines a breakpoint when the inventory list gets switched into the desktop layout. (Default to {@link ScreenSize.LG}).
   */
  @Input()
  desktopLayout: ScreenSize = ScreenSize.LG
  /**
   * Selected price items.
   */
  @Input()
  priceItems: PriceItemResp[]
  /**
   * Emits when the {@link priceItems} get changed.
   */
  @Output()
  priceItemsChange = new EventEmitter<PriceItemResp[]>()
  /**
   * Selected price item additional.
   */
  @Input()
  additionItems: PriceItemAdditionResp[]
  /**
   * Emits when the {@link additionItems} get changed.
   */
  @Output()
  additionItemsChange = new EventEmitter<PriceItemAdditionResp[]>()
  /**
   * Emits when the price item gets removed.
   */
  @Output()
  priceItemRemoved = new EventEmitter<PriceItemResp>()
  /**
   * Selected [startDatetime, endDatetime] from the book-form.
   */
  @Input()
  dates: Date[]
  /**
   * Selected hours of performance from the book-form.
   */
  @Input()
  hours: number
  /**
   * Disables the checkout button. This is a request from outside this component.
   */
  @Input()
  disableCheckout: boolean
  /**
   * Fires, when the user clicked on the 'Checkout' button.
   */
  @Output()
  checkout = new EventEmitter()
  /**
   * The price number of summing both {@link priceItems} and {@link additionItems} altogether.
   */
  @Input()
  orderTotal: number
  /**
   * Disables the checkout button when the order's start is earlier than {@link MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER} minutes from now.
   */
  @Input()
  minimalDistance: boolean
  /**
   * Emits, when the {@link orderTotal} gets changed.
   */
  @Output()
  orderTotalChange = new EventEmitter()
  /**
   * Determines if the user clicked on the checkout btn without specifying an order date.
   * If so, displays dirty date fields.
   */
  @Output()
  disabledCheckoutClick = new EventEmitter<boolean>()
  /**
   * Controls the visibility of the detail dialog on small screens.
   */
  detailDialogVisible: boolean
  /**
   * Available header options in the inventory list.
   */
  headerOptions: MenuItem[]
  /**
   * Defines the button option, if the user want to see selected price items or additions.
   */
  selectedHeaderOption: HeaderOption = HeaderOption.PRICE_ITEM

  HeaderOption: typeof HeaderOption = HeaderOption
  /**
   * Basket subscription.
   */
  private basketServiceSub: Subscription

  constructor(private basketService: BasketService) {
    super()
  }

  ngOnInit(): void {
    this.initHeaderOptions()
    this.initItemsFromBasketService()
    this.calculateSum()

    // subscribes if any of the clear buttons have been pressed.
    this.basketServiceSub = this.basketService.clearClicked.subscribe((params: BasketClearParams) => {
      if (params.clearAll || ((params.artist?.profileId === this.data?.profileId) && params.clearArtist)) {
        this.clearAll()
        this.calculateSum()
      }

      this.initItemsFromBasketService()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priceItems || changes.additionItems || changes.data || changes.dates) {
      this.calculateSum()
    }

    if (changes.data) {
      this.initItemsFromBasketService()
      this.calculateSum()
    }
  }

  /**
   * Calculates the total price of the {@link selectedPriceItems} and {@link selectedPriceItemsAdditions}.
   */
  calculateSum(): void {
    let total = 0
    this.hours = hoursDifference(this.dates[0], this.dates[1])

    this.priceItems
      ?.map(it => {
        if (!it.fixedPrice && this.hours > 0) {
          // Hourly defined price
          return it.price * this.hours
        } else {
          // Fixed price
          return it.price
        }
      })
      ?.forEach(it => {
        total += it
      })
    this.additionItems?.map(it => it.price).forEach(it => {
      total += it
    })

    this.orderTotal = total
    this.orderTotalChange.emit(this.orderTotal)
  }

  /**
   * Fires when the user clicked on the checkout button.
   */
  onCheckout(): void {
    // Skip when some information is not specified - uses the scrollToCalendar function
    if (this.priceItems.length === 0
      || !this.dates
      || this.dates.length !== 2
      || this.disableCheckout
      || this.minimalDistance) {
      return
    }
    this.checkout.emit()
  }

  /**
   * Based on {@link BasketService} it inits selected additions and price items from the current profile of an artist.
   */
  private initItemsFromBasketService(): void {
    this.priceItems = this.basketService.getPriceItemsOfProfile(this.data?.profileId) || []
    this.additionItems = this.basketService.getPriceItemAdditionsOfProfile(this.data?.profileId) || []
    this.priceItemsChange.emit(this.priceItems)
    this.additionItemsChange.emit(this.additionItems)
  }

  /**
   * Deletes selected {@link addition} from the summary,
   * unchecks the checked price item addition,
   * sets the local storage and
   * calls {@link calculateSum} .
   * Removes the {@link addition} from the {@link additionItems}.
   */
  deleteSelectedAddition(addition: PriceItemAdditionResp): void {
    for (let i = 0; i < this.additionItems.length; i++) {
      const item = this.additionItems[i]
      if (addition.id === item.id) {
        this.additionItems.splice(i, 1)
        this.basketService.deletePriceItemAddition(this.data.profileId, item)
        this.additionItems = [...this.additionItems]
        this.additionItemsChange.emit(this.additionItems)
        this.calculateSum()
        break
      }
    }
  }

  /**
   * - Sets the {@link PriceItemResp.selected} to false (as removed) and emits the {@link priceItemRemoved}.
   * - The item gets truly removed in the parent component due to avoiding duplicate code.
   */
  deleteSelectedItem(item: PriceItemResp): void {
    item.selected = false
    this.priceItemRemoved.emit(item)
  }

  /**
   * - Clears all {@link priceItems} and {@link additionItems} from the local space and the local storage.
   * - Also recalculates the {@link orderTotal}.
   */
  clearAll(): void {
    this.basketService.deletePriceItemsOfProfile(this.data.profileId)
    this.basketService.deletePriceItemAdditionsOfProfile(this.data.profileId)
    this.priceItems = []
    this.additionItems = []
    this.priceItemsChange.emit(this.priceItems)
    this.additionItemsChange.emit(this.additionItems)
    this.calculateSum()
  }

  /**
   * Defines visibility of selected button (price items and additions clicker).
   */
  canHeaderOptionsBeVisible(): boolean {
    return this.priceItems.length !== 0 && this.additionItems.length !== 0
  }

  /**
   * Initializes the {@link headerOptions}.
   */
  private initHeaderOptions(): void {
    this.headerOptions = [
      {label: 'Price Items', id: HeaderOption.PRICE_ITEM},
      {label: 'Additions', id: HeaderOption.ADDITIONS}]
  }

  ngOnDestroy(): void {
    this.basketServiceSub?.unsubscribe()
  }

  /**
   * Visually scrolls to the calendar component.
   */
  scrollToCalendar(): void {
    if (!this.dates || this.dates.length !== 2 || this.disableCheckout) {
      this.disabledCheckoutClick.emit(true)
      scrollToIndexOptions('calendar-selector', 0, {
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  /**
   * Discards detail dialog and scrolls to the calendar component when a disabled checkout button is clicked.
   * @param dialog
   */
  discardDetail(dialog: DialogComponent): void {
    dialog?.onDiscard()
    setTimeout(() => {
      this.scrollToCalendar()
    }, 500)
  }
}


/**
 * Specifies enum - UserOptionalState.
 */
enum HeaderOption {
  PRICE_ITEM = 'price_item',
  ADDITIONS = 'additions'
}
