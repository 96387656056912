<div class="wrapper p-py-3" *ngIf="data">

  <div *ngIf="data; else skeleton">

    <h1 data-aos="fade-up" data-aos-duration="1000"
        class="p-px-2">
      {{data.cell.date | date: 'EEEE'}}</h1>
    <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"
        class="p-px-2">
      {{data.cell.date | date: 'd. MMMM yyyy'}}</h2>

    <div class="p-py-3 p-relative">
      <p-carousel [value]="data.cell.calendarItems"
                  [showNavigators]="false"
                  [showIndicators]="data.cell.calendarItems.length > 1"
                  [autoplayInterval]="data.cell.calendarItems.length > 1 ? 5000 : 0"
                  *ngIf="data?.cell.calendarItems?.length !== 0">
        <ng-template let-item pTemplate="item">
          <ng-template [ngTemplateOutlet]="item.nestedProfile ? nestedProfile : message"
                       [ngTemplateOutletContext]="{item: item}"></ng-template>
        </ng-template>
      </p-carousel>

      <!--No content-->
      <app-no-content data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500"
                      *ngIf="data?.cell.calendarItems?.length === 0">
        <p i18n>There's no content provided for this day.</p>
      </app-no-content>
    </div>
  </div>
</div>

<!--Message item-->
<ng-template #message let-item="item">
  <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500"
       class="message rounded-medium box-shadow p-m-2 p-p-4 p-d-flex p-flex-column p-jc-between"
       [class.info]="item.messageType === CalendarMessageType.INFO"
       [class.warning]="item.messageType === CalendarMessageType.WARNING"
       [class.menu]="item.messageType === CalendarMessageType.MENU"
       [class.discount]="item.messageType === CalendarMessageType.DISCOUNT">

    <!--Content-->
    <div class="p-d-flex">
      <img src="assets/profile/user/testimonial/quotation-mark-white.png"
           alt="Quote icon"
           i18n-alt
           class="quote-mark p-m-2 p-d-none p-d-sm-block">
      <div class="p-ml-0 p-ml-sm-2 w-100">
        <h1 class="l-count-2">{{item.name}}</h1>
        <article class="p-mt-1 l-count-5">{{item.description}}</article>

      </div>
    </div>

    <!--Show Details button-->
    <div class="w-100 p-d-flex p-jc-center p-jc-md-end">
      <app-button label="Show"
                  i18n-label
                  type="secondary"
                  icon="fa-solid fa-eye"
                  styleClass="p-mt-2"
                  (clicked)="showMessage(item, true)"></app-button>
    </div>

  </div>
</ng-template>

<ng-template #nestedProfile let-item="item">
  <!--Nested Profile-->
  <div *ngIf="item.nestedProfile; else message" class="profile rounded-medium box-shadow p-m-2 p-p-3">
    <div *ngVar="fetchedProfiles[item.nestedProfile?.profileId] as profile">
      <div *ngIf="profile" class="p-grid">
        <!--Wallpaper - squared-->
        <div class="p-col-3 p-sm-4 p-md-3 p-lg-4">
          <div [style]="profile | wallpaper: '500' | backgroundImage"
               class="fit-image poster rounded-medium p-d-none p-d-sm-block p-d-md-none p-d-lg-block"></div>
          <div class="p-d-sm-none p-d-md-block p-d-lg-none">
            <app-avatar [profile]="profile"
                        [sizePx]="isScreenBetween(Screen.MD, Screen.LG) ? 80 : 55"></app-avatar>
          </div>

          <!--Participants-->
          <div *ngIf="profile.participants"
               class="p-grid p-flex-column p-flex-sm-row p-flex-md-column p-flex-lg-row">
            <div *ngFor="let participant of profile.participants | slice:0:3;"
                 class="p-col-12 p-sm-3 p-md-12 p-lg-3 participant">
              <!--Avatar-->
              <div>
                <app-avatar [profile]="participant.profile"
                            [sizePx]="isScreenOf(Screen.MD) ? 40 : 35"></app-avatar>
              </div>
            </div>

            <!--And Others-->
            <div class="p-col-12 p-sm-3 p-md-12 p-lg-3 participant">
              <app-avatar *ngIf="profile.participants.length > 3"
                          [others]="profile.participants.length - 3"
                          [sizePx]="isScreenOf(Screen.MD) ? 40 : 35"></app-avatar>
            </div>
          </div>
        </div>

        <!--Text-->
        <div class="p-col-9 p-sm-8 p-md-9 p-lg-8">

          <div class="text">
            <!--Header-->
            <div class="p-grid">
              <!--Names-->
              <div class="p-col-12 p-sm-6 p-md-12 p-lg-6 p-d-flex">
                <app-profile-names [profile]="profile"
                                   displayNameLinesCount="1"
                                   [size]="isScreenOf(Screen.LG) ? 2 : isScreenOf(Screen.SM) ? 3 : 4"></app-profile-names>
              </div>

              <!--Time-->
              <div
                class="p-col-12 p-sm-6 p-md-12 p-lg-6 p-d-flex p-jc-start p-jc-sm-end p-jc-md-start p-jc-lg-end time">
                <div>
                  <app-profile-opening-hours [inMapCarousel]="true" [data]="profile">
                  </app-profile-opening-hours>
                </div>
              </div>
            </div>

            <!--Bio-->
            <article class="l-count-5 p-px-2">{{profile.bio}}</article>
          </div>

          <div class="separator p-mt-2 p-grid">
            <!--Attendees-->
            <div class="p-col-12 p-sm-6 p-md-12 p-lg-6">
              <div class="p-d-flex p-ai-center">
                <app-avatar [profiles]="profile.attendees.content"
                            sizePx="40"
                            [borderColor]="'#2d2d2d'"></app-avatar>

                <div class="p-ml-2 p-d-flex"
                     *ngIf="(profile.attendeesCount - 3) > 0">
                  +{{profile.attendeesCount - 3 | count}}
                </div>
              </div>
            </div>


            <!--Follow button-->
            <div class="p-col-12 p-sm-6 p-md-12 p-lg-6">
              <div class="p-d-flex p-jc-start p-jc-sm-end p-jc-md-start p-jc-lg-end p-ai-center">
                <app-profile-like [currentProfile]="currentProfile"
                                  [likeTo]="profile"
                                  [displayCount]="true"></app-profile-like>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--Show full message item-->
<div class="full-dialog" *ngIf="messageFullVisible" [@fade]="messageFullVisible">
  <p-dialog [(visible)]="messageFullVisible"
            [modal]="true"
            [draggable]="false"
            [closable]="true"
            [baseZIndex]="10002"
            [showHeader]="false">

    <!--Content-->
    <div class="message p-m-2 p-p-4"
         [class.info]="messageFulLData.messageType === CalendarMessageType.INFO"
         [class.warning]="messageFulLData.messageType === CalendarMessageType.WARNING"
         [class.menu]="messageFulLData.messageType === CalendarMessageType.MENU"
         [class.discount]="messageFulLData.messageType === CalendarMessageType.DISCOUNT">

      <div class="p-d-flex">
        <img src="assets/profile/user/testimonial/quotation-mark-white.png"
             alt="Quote icon"
             i18n-alt
             class="quote-mark p-m-2 p-d-none p-d-sm-block">
        <div class="p-ml-0 p-ml-sm-2">
          <h1>{{messageFulLData.name}}</h1>
          <article class="p-mt-1">{{messageFulLData.description}}</article>
        </div>
      </div>

    </div>

    <!--Close button-->
    <div class="p-d-flex p-jc-center p-py-2">
      <app-button label="Close"
                  i18n-label
                  type="secondary"
                  icon="fa-solid fa-check"
                  (clicked)="showMessage(messageFulLData, false)"></app-button>
    </div>
  </p-dialog>
</div>

<!--Skeleton-->
<ng-template #skeleton>
  <p-skeleton width="12rem" height="2rem" styleClass="p-mb-1"></p-skeleton>
  <p-skeleton width="6rem" height="1rem" styleClass="p-mb-3"></p-skeleton>
  <p-skeleton width="80%" height="15rem" styleClass="rounded"></p-skeleton>
</ng-template>
