import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {PriceItemResp} from '../../../service/price-item.service'
import {EditableComponent} from '../../abstract/editable.component'
import {ProfileResp} from '../../../service/profile.service'
import {hoursDifference} from '../../../utils/date.utils'
import {PLATFORM_BROWSER} from '../../../app.module'
import {NgForOf, NgIf, NgSwitch, NgSwitchCase, SlicePipe} from '@angular/common'
import {CheckboxComponent} from '../../common/form/checkbox/checkbox.component'
import {PricePipe} from '../../../pipe/price.pipe'
import {ButtonComponent} from '../../common/button/button.component'
import {TooltipModule} from 'primeng/tooltip'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'

/**
 * Item contains title with description, time duration and price.
 */
@Component({
  selector: 'app-profile-price-item',
  templateUrl: './profile-price-item.component.html',
  styleUrls: ['./profile-price-item.component.scss'],
  imports: [
    NgSwitch,
    NgSwitchCase,
    CheckboxComponent,
    SlicePipe,
    NgIf,
    PricePipe,
    ButtonComponent,
    NgForOf,
    TooltipModule
  ],
  standalone: true
})
export class ProfilePriceItemComponent extends EditableComponent implements OnInit, OnChanges {
  /**
   * The data of the profile.
   */
  @Input()
  data: ProfileResp

  @Input()
  currentProfile: ProfileResp
  /**
   * The source price item.
   */
  @Input()
  item: PriceItemResp
  /**
   * Ensures visibility of the delete icon in brief item mode of the price item.
   */
  @Input()
  enableDeleteButton = false
  /**
   * Emits when the user wants to delete the {@link item}.
   */
  @Output()
  delete = new EventEmitter()
  /**
   * Selected [startDatetime, endDatetime] from the book-form.
   */
  @Input()
  dates: Date[]
  /**
   * Emits checkbox changes of the price item.
   */
  @Output()
  selected = new EventEmitter<boolean>()
  /**
   * Specifies the layout mode of the price item.
   * - customer - represents a behavior where the customer can click on a checkbox. (Default)
   * - brief - the preview of already checked item.
   */
  @Input()
  layout: 'customer' | 'brief' | 'edit' = 'customer'
  /**
   * Defines the number of visible categories for the first time.
   * This value may change if a profile has more genres than this default value,
   * and user has clicked on the more option button.
   */
  @Input()
  categorySliceEnd = 3
  /**
   * Duration in hours between the {@link dates}.
   */
  hours = 0

  /**
   * Specifies if the screen window is touchable or not.
   */
  isTouchScreen: boolean
  /**
   * Determines whether the {@link data} profile can be ordered.
   */
  canBeOrdered: boolean
  /**
   * Determines whether the {@link currentProfile} can order profiles.
   */
  canOrder: boolean

  constructor() {
    super()
  }

  ngOnInit(): void {
    if (this.dates?.[0] && this.dates?.[1]) {
      this.hours = hoursDifference(this.dates[0], this.dates[1])
    }
    // defines if the touch screen is present
    this.isTouchScreen = (PLATFORM_BROWSER && ('ontouchstart' in window || !!navigator.maxTouchPoints))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dates) {
      this.hours = 0
      if (this.dates?.[0] && this.dates?.[1]) {
        this.hours = hoursDifference(this.dates[0], this.dates[1])
      }
    }

    if (changes.currentProfile?.currentValue) {
      this.canOrder = hasFeatures(this.currentProfile.profileType, Feature.ORDER_PROFILE)
    }

    if (changes.data?.currentValue) {
      this.canBeOrdered = hasFeatures(this.data.profileType, Feature.BE_ORDERED)
    }
  }

  /**
   * When the user clicks on a price item, the price item is selected and the checkbox is checked.
   * Another click on that price item unselects the price item and unchecks the checkbox.
   */
  selectPriceItem(): void {
    if (!this.editButtonVisible) {
      this.item.selected = !this.item.selected
      this.selected.emit(this.item.selected)
    }
  }

  /**
   * - Fires, when a user wants to delete the {@link item}.
   */
  onDelete(): void {
    this.delete.emit()
  }

  /**
   * Shows delete dialog.
   */
  showEditComponent(): void {
    this.resetApi()
    this.editComponentVisible = true
  }
}


