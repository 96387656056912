<div [class]="'p-d-flex size-' + size + ' ' + styleClass">
  <div>
    <h1 class="display-name unselectable l-count-{{displayNameLinesCount}}"
        [class.c-pointer]="redirect || showPointer"
        [class.lines-count]="displayNameLinesCount"
        (click)="onRedirect()">{{profile?.displayName}}</h1>

    <h2 *ngIf="charIdVisible"
        class="character-id p-d-inline-flex p-ai-center">
      <app-profile-icon [profile]="profile" class="p-mr-2 profile-icon"></app-profile-icon>
      <span class="p-text-italic"
            [class.c-pointer]="redirect"
            (click)="onRedirect()">{{profile?.charId}}</span>
    </h2>
  </div>
</div>
