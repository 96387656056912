import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {GetImageShowcaseEditResp, ImageShowcaseResp, ImageShowcaseService} from '../../../../service/image-showcase.service'
import {ProfileResp} from '../../../../service/profile.service'
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs'
import {PLATFORM_BROWSER} from '../../../../app.module'
import {getDeviceMatchWidthWallpaper} from '../../../../utils/device.utils'
import {getFeature} from '../../../../common/profile-type'
import {Feature} from '../../../../common/feature'
import {isFileImage} from '../../../../utils/file.utils'
import {ServerMessage} from '../../../../common/server-message'
import {ImageService} from '../../../../service/ui/image.service'
import {Restrictions} from '../../../../common/restrictions'
import {createRandomUUID} from '../../../../utils/utils'
import {FormBuilder, FormGroup} from '@angular/forms'
import {growAnimation} from '../../../../animation/grow.animation'
import {fadeAnimation} from '../../../../animation/fade.animation'
import {ProfileCompletionService} from '../../../../service/profile-completion.service'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-image-showcase-edit',
  templateUrl: './profile-image-showcase-edit.component.html',
  styleUrl: './profile-image-showcase-edit.component.scss'
})
export class ProfileImageShowcaseEditComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The current profile data.
   */
  @Input({required: true})
  profile: ProfileResp
  /**
   * All available images that can be edited.
   */
  images: GetImageShowcaseEditResp[]
  /**
   * Returns a new array of wallpaper URLs if the user has changed his wallpapers.
   */
  @Output()
  changed = new EventEmitter<ImageShowcaseResp[]>()
  /**
   * Max available messages to be saved.
   */
  maxImages: number = 0
  /**
   * The current image index within the {@link wallpapers} to be removed.
   */
  deleteConfirmDialogImageIndex: number
  /**
   * Delete warning dialog visibility control.
   */
  deleteConfirmDialogVisible: boolean
  /**
   * The form field used for {@link currentEditingItem}.
   */
  form: FormGroup
  /**
   * The current editing item for its description.
   */
  currentEditingItem?: GetImageShowcaseEditResp
  /**
   * Determines whether a new image was uploaded to the server.
   */
  private imagesUploaded = new BehaviorSubject<boolean[]>([])
  /**
   * This subscription is for observing the {@link imagesUploaded} property.
   */
  private subs?: Subscription[] = []

  constructor(
    private imageShowcaseService: ImageShowcaseService,
    private completionService: ProfileCompletionService,
    private imageService: ImageService,
    private formBuilder: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    // form init
    this.form = this.formBuilder.group({
      description: ['']
    })

    // Value changes observer
    this.subs.push(this.form.controls.description.valueChanges.subscribe(value => {
      if (this.currentEditingItem) {
        this.currentEditingItem.description = value
      }
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile?.currentValue) {
      this.maxImages = getFeature(this.profile.profileType, Feature.IMAGE_SHOWCASE).max
      this.getImagesForEdit()
    }
  }

  /**
   * Fires when a user selected a file.
   */
  onFileSelect(file: File): void {
    if (!file) { // return if no file selected
      return
    }

    // if selected file is not an image, return
    if (!isFileImage(file)) {
      this.resetApi()
      this.pushToMessages(ServerMessage.FILE_FORMAT_NOT_SUPPORTED)
      return
    }

    // Compress the image on front-end.
    this.call(async () => {
      try {
        const compressed = await firstValueFrom(this.imageService.fitTo(
          file,
          Restrictions.MAX_IMAGE_EDGE_SIZE,
          this.ImageVariant.IMAGE_SHOWCASE_COMPRESS_SIZE.width,
          this.ImageVariant.IMAGE_SHOWCASE_COMPRESS_SIZE.height)
        )

        if (compressed) {
          this.addImageFile(compressed)
        }
      } catch (error) {
        this.pushToMessages(error)
      }
    })
  }

  /**
   * Fires when a user clicked on the save button.
   */
  async onSave(): Promise<void> {
    await this.waitForUpload()

    // Update the wallpapers
    const resp = await firstValueFrom(this.unwrap(this.imageShowcaseService.callUpdateImages(this.images.map(it => {
      return {
        uuid: it.uuid,
        description: it.description
      }
    }))))
    if (resp && this.noServerMessages()) {
      this.profile.hasImageShowcase = this.images?.length > 0
      this.completionService.closeCompletionItemAndCheck()
      this.changed.emit(resp)
    }
  }

  /**
   * - If the {@link wallpapers} contain more than 1 wallpaper, the confirm dialog will be shown and the result will
   * continue inside the {@link onRemoveConfirm} function.
   * - Otherwise, it will remove the image via the {@link removeImage} function immediately.
   */
  onRemove(item: GetImageShowcaseEditResp): void {
    // the carousel is null, null index, return
    if (!item) {
      return
    }

    // show delete confirm dialog
    if (this.images?.length >= 0) {
      this.deleteConfirmDialogImageIndex = this.images.findIndex(it => it.uuid === item.uuid)
      this.deleteConfirmDialogVisible = true
    }
  }

  /**
   * If the user confirms to remove selected image.
   */
  onRemoveConfirm(confirm: boolean): void {
    if (confirm) {
      this.images.splice(this.deleteConfirmDialogImageIndex, 1)
      this.images = [...this.images]

      const arr = [...this.imagesUploaded.getValue()]
      arr.splice(this.deleteConfirmDialogImageIndex, 1)
      this.imagesUploaded.next(arr)
    }
    this.deleteConfirmDialogImageIndex = null
  }

  /**
   * Shows a text area to edit the {@link item}.
   */
  onEditItem(item: GetImageShowcaseEditResp): void {
    this.currentEditingItem = null
    this.form.controls.description.setValue(item.description || '')
    this.currentEditingItem = item
  }

  /**
   * - Adds a new image to the wallpapers.
   * - It also takes into account the {@link maxWallpapers} property.
   * - Furthermore, it calls the server API to upload the image.
   */
  private addImageFile(compressed: File): void {
    this.customCall(async () => {
      // image file name
      const imageUuid = createRandomUUID()
      // reads the file as the data URL
      const dataUrl = await this.imageService.imageFileToURL(compressed)

      // limit exceeded
      if (this.maxImages > 1 && this.images.length >= this.maxImages) {
        return
      }

      // adds the image to the 'wallpapers' property.
      this.addImage(dataUrl, imageUuid)

      // upload
      const resp = await firstValueFrom(this.unwrap(this.imageShowcaseService.callNewImage(compressed, imageUuid)))
      if (resp) {
        this.onImageUploaded(imageUuid)
      }
    })
  }

  /**
   * - Adds a new image to the {@link images} property.
   *
   * @param dataUrl - Image data
   * @param imageUuid - Image filename
   */
  private addImage(dataUrl: string, imageUuid: string): void {
    // new wallpaper object
    const newImage: GetImageShowcaseEditResp = {
      url: dataUrl,
      uuid: imageUuid
    }

    // replace wallpaper
    if (this.maxImages === 1) {
      this.imagesUploaded.next([false])
      this.images = [newImage]

    } else { // add image
      // inserts the next element of 'false' value.
      this.imagesUploaded.next([...this.imagesUploaded.getValue(), false])
      // inserts new wallpaper
      this.images = [...this.images, newImage]
    }
  }

  /**
   * Sets the `true` value in the {@link imagesUploaded} array at the position of the {@link imageUuid} in the {@link images} array.
   */
  private onImageUploaded(imageUuid: string): void {
    for (let i = 0; i < this.images.length; i++) {
      const image = this.images[i]

      if (image.uuid === imageUuid) {
        const arr = this.imagesUploaded.getValue()
        arr[i] = true
        this.imagesUploaded.next(arr)
      }
    }
  }

  /**
   * Gets all currently active profile wallpapers.
   */
  private getImagesForEdit(): void {
    this.call(async () => {
      // Load all active wallpapers
      const resp = await firstValueFrom(this.unwrap(this.imageShowcaseService.callGetImagesForEdit()))
      if (resp && this.noServerMessages()) {
        this.initImages(resp)
      }
    })
  }

  /**
   * Initializes all images from the server. Basically, it appends a proper image variant based on the current screen size.
   */
  private initImages(images: GetImageShowcaseEditResp[]): void {
    const imagesUploaded: boolean[] = []
    // display appropriate size
    if (PLATFORM_BROWSER) {
      const imageVariant = getDeviceMatchWidthWallpaper()
      images.forEach(img => {
        img.url = `${img.url}/${imageVariant}`
        imagesUploaded.push(true)
      })
      this.imagesUploaded.next(imagesUploaded)
      this.images = images
    }
  }

  /**
   * Waits until all images gets properly uploaded.
   */
  private async waitForUpload(): Promise<void> {
    return new Promise<void>(resolve => {
      this.subs.push(this.imagesUploaded.subscribe(async (arr: boolean[]) => {
        // wait until every image will be uploaded
        if (!arr.some(it => it === false)) {
          return resolve()
        }
      }))
    })
  }

  refreshImages(): void {
    this.images = [...this.images]
  }

  ngOnDestroy(): void {
    this.subs.forEach(it => it?.unsubscribe())
  }
}
