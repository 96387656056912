<div class="artist-wrapper">
  <h2 class="p-mt-2 p-mb-2" i18n>Biography</h2>
  <!--Content-->
  <article *ngIf="data.bio"
           class="bio">{{ data.bio || '' }}
  </article>
  <article *ngIf="!data.bio && !editButtonVisible"
           class="bio bio-not-filled" i18n>
    Profile did not provide any detailed description.
  </article>

  <!--No content-->
  <app-no-content styleClass="p-my-2 c-pointer" (click)="showEditComponent()"
                  *ngIf="!data?.bio && editButtonVisible" i18n>
    To be more attractive to customers, introduce yourself and describe your art here.
  </app-no-content>

  <!--Edit button-->
  <div *ngIf="editButtonVisible && data"
       class="p-d-flex p-jc-start p-mt-3 p-mb-2">
    <app-button type="edit"
                pTooltip="Edit your bio."
                i18n-pTooltip
                tooltipPosition="top"
                (clicked)="showEditComponent()"></app-button>
  </div>
</div>

<!--Edit dialog-->
<app-dialog *ngIf="editComponentVisible"
            [(show)]="editComponentVisible"
            [component]="this"
            header="Edit your profile bio"
            i18n-header
            [save]="onComponentSave.bind(this)"
            (discard)="closeEditComponent()"
            [disableSaveOption]="form.invalid">

  <!--Dialog content-->
  <div *ngIf="data; else skeleton">
    <app-hint>
      <div i18n>
        This is your space for any text you would like to tell the visitors about you, your achievements,
        requirements, or conditions under which you are willing to take orders.
      </div>
      <div i18n>Don't go into details, 5 sentences would be the best.</div>
      <div i18n>Focus on explaining frequent information to help customers understand your conditions.</div>
    </app-hint>

    <form [formGroup]="form">
      <app-text-input [form]="form"
                      [length]="{max: Restrictions.MAX_BIO_LENGTH}"
                      [notContact]="true"
                      [textArea]="true"
                      [focus]="true"
                      formFieldName="bio"
                      placeholder="Introduce yourself."
                      i18n-placeholder
                      #textInp
                      (ngInit)="focusInput(textInp)">

        <!--BE-CHECK-->
        <app-backend-validation [check]="ServerMessage.BIO_LENGTH_INCORRECT" [serverMessages]="serverMessages" i18n>
          Your bio exceeds {{ Restrictions.MAX_BIO_LENGTH }} characters.
        </app-backend-validation>

        <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED" [serverMessages]="serverMessages"
                                i18n>
          The field cannot contain any contact.
        </app-backend-validation>
      </app-text-input>
    </form>
  </div>
</app-dialog>

<!--Skeleton templates-->
<ng-template #skeleton>
  <p-skeleton height="1.5rem" styleClass="p-mt-4 p-mb-2" width="80%"></p-skeleton>
  <p-skeleton height="1.5rem" styleClass="p-mb-2" width="40%"></p-skeleton>
  <p-skeleton height="1.5rem" styleClass="p-mb-2" width="60%"></p-skeleton>
  <p-skeleton height="1.5rem" styleClass="p-mb-2" width="30%"></p-skeleton>
</ng-template>
