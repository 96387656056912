<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            url="business-profile"
            header="Business information"
            i18n-header
            [save]="onSave.bind(this)"
            (discard)="closeEditComponent()"
            [helpLink]="env.contact.docs + '/docs/profiles/artist/stripe-account/'"
            successMessage="Business profile updated"
            i18n-successMessage
            [allowSaveOption]="settings?.completed"
            [disableSaveOption]="form?.invalid || stripeAccountLoading"
            #dialog>
  <div class="p-py-2 dialog-content p-relative">

    <!--Loading Skeleton-->
    <div *ngIf="(loading || stripeAccountLoading) && !stripeLink" @fade class="p-absolute w-100 loading-skeleton">
      <div *ngFor="let i of [].constructor(3)">
        <p-skeleton width="30%" height="1rem" styleClass="rounded-small"></p-skeleton>
        <p-skeleton width="100%" height="3rem" styleClass="rounded-small p-mt-1 p-mb-3"></p-skeleton>
      </div>
    </div>

    <!--Content-->
    <div *ngIf="!loading && !stripeAccountLoading && !stripeLink" @grow>
      <!--Entity Type-->
      <app-dropdown [options]="legalEntityTypes"
                    formFieldName="entityType"
                    [form]="form"
                    [required]="true"
                    label="Your business type"
                    i18n-label
                    placeholder="Choose"
                    i18n-placeholder>
        <!--Selected-->
        <ng-template let-item pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <i class="{{item.icon}} p-mr-2 p-ml-3"></i>
            <div>{{ item.name }}</div>
          </div>
        </ng-template>

        <!--Item-->
        <ng-template let-item pTemplate="item">
          <div class="p-d-flex">
            <i class="{{item.icon}} p-mr-2"></i>
            <div>{{ item.name }}</div>
          </div>
        </ng-template>
      </app-dropdown>

      <!--Dropdown Description-->
      <small *ngIf="selectedEntityType"
             class="field-description rounded-small p-d-flex p-ai-center p-px-2 p-py-1 p-mb-2">
        <i class="fa-solid fa-info-circle p-mr-2"></i>
        <span [innerHTML]="selectedEntityType.description"></span>
      </small>

      <!--Ensure hint-->
      <app-hint *ngIf="activateEnsureHint" [collapsableHint]="false" @grow styleClass="p-mt-2" i18n>
        Umevia doesn't support changing business type after creation right now. <b>Ensure you have selected the
        right option</b>.
      </app-hint>

      <!-- Form -->
      <form [formGroup]="form" *ngIf="selectedEntityType" @grow
            class="p-mt-2">

        <!-- Stripe Language -->
        <app-dropdown [form]="form"
                      formFieldName="stripeLanguage"
                      label="Legal documents language"
                      i18n-label
                      hint="All legal documents & communication with Stripe will be in this language."
                      i18n-hint
                      [options]="stripeLanguages"
                      [required]="true">
          <!--Selected-->
          <ng-template let-selected pTemplate="selectedItem">
            <div class="p-d-flex p-ai-center">
              <i [class]="selected.flag" class="p-mx-2"></i>
              <div>{{ selected.title }}</div>
            </div>
          </ng-template>

          <!--Item-->
          <ng-template let-type pTemplate="item">
            <div class="p-d-flex p-ai-center">
              <i [class]="type.flag" class="p-mr-2"></i>
              <div>{{ type.title }}</div>
            </div>
          </ng-template>

          <!--BE-CHECK-->
          <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.STRIPE_LANGUAGE_INCORRECT"
                                  i18n>
            Not supported locale yet.
          </app-backend-validation>
        </app-dropdown>

        <!--Registration Number ID-->
        <app-text-input *ngIf="selectedEntityType.requiredRegNumber" @grow
                        label="Registration Number"
                        i18n-label
                        icon="fa-solid fa-user"
                        inputType="text"
                        formFieldName="registrationNumber"
                        [form]="form"
                        [noBlankCharacters]="true"
                        [space]="false"
                        [loading]="registrationNumberLoading"
                        [onlyNumbers]="true"
                        [required]="true"
                        hint="Your registration number of the legal invoicing entity in your country."
                        i18n-hint
                        [length]="{min: Restrictions.LEGAL_REGISTRATION_ID_LENGTH, max: Restrictions.LEGAL_REGISTRATION_ID_LENGTH}">

          <!--BE-CHECK-->
          <app-backend-validation [serverMessages]="serverMessages"
                                  [check]="ServerMessage.LEGAL_REGISTRATION_ID_INCORRECT" i18n>
            Invalid registration number.
          </app-backend-validation>

          <app-backend-validation [serverMessages]="serverMessages"
                                  [check]="ServerMessage.LEGAL_REGISTRATION_REQUIRED" i18n>
            This field is required for a company.
          </app-backend-validation>

          <app-backend-validation [serverMessages]="serverMessages"
                                  [check]="ServerMessage.LEGAL_REGISTRATION_NUMBER_NOT_FOUND" i18n>
            This number couldn't be found or can't be used.
          </app-backend-validation>
        </app-text-input>

        <!--Company preview-->
        <div class="company-preview p-p-3 p-mb-1 rounded-medium p-d-inline-block w-100"
             *ngIf="!settings?.completed && registrationNumberSearch" @grow>
          <b>{{ registrationNumberSearch?.fullNames?.[0]?.value }}</b>
          <div *ngVar="registrationNumberSearch?.addresses?.[0] as addr">
            <div *ngIf="!addr?.street">{{ addr?.municipality?.value }} {{ addr?.regNumber }}</div>
            <div *ngIf="addr?.street">{{ addr?.street }} {{ addr?.buildingNumber }}</div>
            <div>{{ addr?.postalCodes?.[0] }} {{ addr?.municipality?.value }}</div>
          </div>
        </div>

        <!--Tax ID-->
        <app-text-input *ngIf="selectedEntityType.requiredTaxId" @grow
                        label="Tax ID"
                        i18n-label
                        icon="fa-solid fa-sack-dollar"
                        inputType="text"
                        formFieldName="taxId"
                        [form]="form"
                        [noBlankCharacters]="true"
                        [onlyNumbers]="true"
                        [space]="false"
                        [required]="true"
                        hint="Your tax id of the invoicing entity in your country."
                        i18n-hint
                        [length]="{min: Restrictions.LEGAL_TAX_ID_LENGTH, max: Restrictions.LEGAL_TAX_ID_LENGTH}">

          <!--BE-CHECK-->
          <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LEGAL_TAX_ID_INCORRECT" i18n>
            Invalid tax id number.
          </app-backend-validation>
        </app-text-input>

        <!-- Vat Payer checkbox -->
        <app-checkbox *ngIf="selectedEntityType.requiredTaxId" @grow
                      label="I am a VAT payer"
                      i18n-label
                      [form]="form"
                      formFieldName="vatPayer"
                      styleClass="p-mt-3"></app-checkbox>

        <!--Vat ID-->
        <app-text-input *ngIf="selectedEntityType.requiredTaxId && form.value['vatPayer']" @grow
                        label="Vat ID"
                        i18n-label
                        icon="fa-solid fa-sack-dollar"
                        inputType="text"
                        formFieldName="vatId"
                        styleClass="p-mt-3"
                        [form]="form"
                        [noBlankCharacters]="true"
                        [space]="false"
                        [required]="true"
                        hint="Your vat id of the invoicing entity in your country."
                        i18n-hint
                        [length]="{min: Restrictions.LEGAL_VAT_ID_LENGTH, max: Restrictions.LEGAL_VAT_ID_LENGTH}">

          <!--BE-CHECK-->
          <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LEGAL_VAT_ID_INCORRECT" i18n>
            Invalid vat id number.
          </app-backend-validation>
        </app-text-input>

        <!--Attention hint-->
        <app-hint styleClass="p-mt-2" *ngIf="selectedEntityType?.isLegalEntity" i18n>
          <b>Make sure your IDs are correct</b>, once saved you can't change them.
          You're also confirming that this information is correct and you are fully responsible for any incorrect
          information.
        </app-hint>
      </form>

      <!-- Activate Stripe -->
      <div class="p-d-flex p-jc-center" *ngIf="selectedEntityType">
        <app-button type="primary"
                    [label]="activateEnsureHint ? trans.activateStripe : trans.next"
                    icon="fa-solid {{activateEnsureHint ? 'fa-wallet' : 'fa-chevron-right'}}"
                    styleClass="p-mt-2"
                    [highlight]="true"
                    [loading]="stripeAccountLoading"
                    [disabled]="form.invalid || !noServerMessages() || registrationNumberLoading
                                || (selectedEntityType.requiredRegNumber && !registrationNumberSearch)"
                    (clicked)="newStripeOnboarding();"
                    *ngIf="!stripeCreated && !settings?.completed" @grow></app-button>

        <!--Update Stripe-->
        <app-button type="secondary"
                    label="Update Stripe"
                    i18n-label
                    icon="fa-solid fa-wallet"
                    styleClass="p-mt-2"
                    [loading]="stripeAccountLoading"
                    (clicked)="newStripeOnboarding()"
                    *ngIf="settings?.invoicingAddress && settings?.completed && !settings?.newRequirementDue"
                    @grow></app-button>

        <!--Action required-->
        <app-button type="warning"
                    label="Update Stripe"
                    i18n-label
                    icon="fa-solid fa-triangle-exclamation"
                    styleClass="p-button-rounded p-button-warning p-mt-2 p-ml-2"
                    *ngIf="(!settings?.completed && stripeCreated)
                            || (settings?.newRequirementDue && stripeCreated)
                            || (!settings?.invoicingAddress && settings?.completed)" @grow
                    (clicked)="newStripeOnboarding()"></app-button>

      </div>
    </div>

    <!--Stripe Link-->
    <div class="p-grid p-p-0 p-jc-center" *ngIf="stripeLink" @grow>
      <div class="p-col-12 p-sm-8 p-p-0">
        <div class="p-d-flex p-flex-column p-ai-center stripe-link p-p-3 rounded-medium">
          <h2 class="p-mb-1" i18n>Registration process is ready</h2>
          <p i18n>Please continue the registration process on the Stripe platform. Don't forget to fill in all
            required fields to make the process successful.</p>

          <!-- Stripe Onboarding -->
          <a [href]="stripeLink">
            <app-button type="primary"
                        label="Continue"
                        i18n-label
                        icon="fa-solid fa-arrow-right"
                        iconPos="right"
                        (clicked)="redirecting = true"
                        [loading]="redirecting"
                        styleClass="p-mt-3"></app-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
