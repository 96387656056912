import {ApiService} from './api.service'
import {Injectable} from '@angular/core'
import {Endpoint} from '../common/endpoints'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {environment} from '../../environments/environment'
import {AddressResp, LegalEntityRest, UpdateLegalEntityReq} from './address.service'
import {PaymentIntent} from '@stripe/stripe-js'
import NextAction = PaymentIntent.NextAction

@Injectable({
  providedIn: 'root'
})
export class StripeService extends ApiService {

  constructor() {
    super()
  }

  callGetPaymentIntent(orderId: number): Observable<BaseResp<string>> {
    return this.post(Endpoint.STRIPE_CREATE_PAYMENT_INTENT, orderId)
  }

  callGetIntentNextAction(orderId: number): Observable<BaseResp<NextAction>> {
    return this.post(Endpoint.STRIPE_PAYMENT_INTENT_NEXT_ACTION, orderId)
  }

  callCreateConnectAccount(req: NewConnectOnboardingReq): Observable<BaseResp<string>> {
    return this.post(Endpoint.STRIPE_CREATE_CONNECT_ACCOUNT, req)
  }

  callCheckAccountCompleted(): Observable<BaseResp<StripeCompletionCheckResp>> {
    return this.post(Endpoint.STRIPE_CHECK_PERFORMER_ACCOUNT_FINISHED, null)
  }

  callGetStripeSettings(): Observable<BaseResp<ConnectAccountSettingsResp | null>> {
    return this.post(Endpoint.STRIPE_GET_CONNECT_SETTINGS, null)
  }

  callGetAllInvoicingAddresses(): Observable<BaseResp<AddressResp[]>> {
    return this.post(Endpoint.STRIPE_CUSTOMER_ALL_INV_ADDRESSES, null)
  }

  callDeleteInvoicingAddress(addressId: number): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.STRIPE_CUSTOMER_DELETE_INV_ADDRESS, addressId)
  }

  /**
   * Manually accept the order as paid.
   */
  callLocalhostAcceptPayment(orderId: number): Observable<BaseResp<boolean>> {
    if (environment.production) {
      throw new Error('Calling manual accept payment in production!')
    }
    return this.post(Endpoint.STRIPE_LOCALHOST_ACCEPT_PAYMENT, orderId)
  }

  /**
   * Simulates a bank transfer for development environments.
   */
  callLocalhostSimulateBankTransfer(req: SimulateBankTransferReq): Observable<BaseResp<boolean>> {
    if (environment.production) {
      throw new Error('Calling simulating bank transfer in production!')
    }
    return this.post(Endpoint.STRIPE_LOCALHOST_SIMULATE_BANK_TRANSFER, req)
  }
}

/**
 * Requests a new Stripe Connect onboarding link.
 */
export interface NewConnectOnboardingReq {
  /**
   * When the account is not created, this property tells the Stripe what business type account should be created.
   */
  individual: boolean
  /**
   * Language of Stripe onboarding, invoices, receipts and other legal documents.
   */
  stripeLanguage: string
  /**
   * When the account is not created, this property controls whether the business type is a legal entity.
   */
  isLegalEntity: boolean
  /**
   * When the account is not created and the {@link isLegalEntity} is set, this field contains all required information about the legal
   * entity.
   */
  legalEntityDetails?: UpdateLegalEntityReq
}

/**
 * Represents information about the Stripe Connect Account.
 */
export interface ConnectAccountSettingsResp {
  /**
   * Represents whether the business type is individual or a company.
   */
  individual: boolean
  /**
   * Language of Stripe onboarding, invoices, receipts and other legal documents.
   */
  stripeLanguage: string
  /**
   * Represents whether the business type is a registered legal entity.
   */
  isLegalEntity: boolean
  /**
   * - Initialized when a user fills information about their legal entity before the {@link invoicingAddress} is created.
   * After the user has completed the Stripe Onboarding process, the invoicing address is saved on the backend and extended by this field.
   * After the {@link invoicingAddress} is present, these values will be in the {@link invoicingAddress} under the legalEntity property.
   * - Basically this property will always be initialized when the user fills some fields with the {@link LegalEntityRest},
   * the {@link invoicingAddress} is not yet created, and the user has not completed the Stripe onboarding process.
   */
  stripeLegalEntity?: LegalEntityRest
  /**
   * Determines whether the Stripe Connect account is completed.
   */
  completed: boolean
  /**
   * If defined,it indicates that user has to fill in some additional data
   * to not have payouts disabled by Stripe.
   */
  newRequirementDue?: Date
  /**
   * Initialized when the Stripe Connect onboarding has been successful and the user filled up all required fields.
   */
  invoicingAddress?: AddressResp
  /**
   * Whether the user fills incorrect information.
   */
  errors: boolean
  /**
   * Whether the Stripe is currently verifying provided information.
   */
  pendingVerification: boolean

}

/**
 * Class holding information about Stripe Account completion
 * and possible future requirements due date.
 */
export interface StripeCompletionCheckResp {
  completed: boolean
  newRequirementDue?: Date
}

export interface BankInstructions {
  amountRemaining: number
  currency: string
  financialAddresses: {
    aba: any
    iban: {
      accountHolderName: string
      bic: string
      country: string
      iban: string
    }
    sortCode: any
    spei: any
    supportedNetworks: string[]
    swift: string
    type: string
    zengin: string
  }[]
  hostedInstructionsUrl: string
  reference: string
  type: string
}

export interface SimulateBankTransferReq {
  profileId: number
  amount: number
  reference: string
  currency?: string
}
