import {Component, OnDestroy, OnInit} from '@angular/core'
import {ProfileResp, ProfileService} from '../../service/profile.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ActivatedRoute} from '@angular/router'
import {NavigationService} from '../../service/ui/navigation.service'
import {scrollToIndex} from '../../utils/scroll.utils'
import {SupportDocumentLinks, SupportService} from '../../service/support.service'
import {ApiComponent} from '../abstract/api.component'
import {environment} from '../../../environments/environment'
import {PlatformRatingService} from '../../service/platform-rating.service'

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * Represents the current previewing profile data.
   */
  profileData: ProfileResp
  /**
   * Current active index of the legal documents accordion.
   */
  legalActiveIndex = 0
  /**
   * Legal Documents section class selector. Used for smooth scrolling.
   */
  protected readonly legalDocumentsClass = 'legal-documents'
  /**
   * Represents a set of translations for this component.
   */
  private trans = {
    tos: $localize`Terms of Services`,
    tos_art: $localize`Terms of Services - Artists`,
    privacy: $localize`Privacy Policy`,
    cookies: $localize`Cookies Policy`
  }

  /**
   * All versions of Terms of Services documents.
   */
  tos: DocumentType[] = []

  /**
   * All versions of 'Terms of Services for artists' documents.
   */
  tosArt: DocumentType[] = []

  /**
   * All versions of Privacy Policy documents.
   */
  privacy: DocumentType[] = []

  /**
   * All versions of Cookies documents.
   */
  cookies: DocumentType[] = []
  /**
   * Support contact info
   */
  supportInfo: SupportContact
  /**
   * Current profile subscription.
   */
  private currentProfileSub?: Subscription

  constructor(
    private profileService: ProfileService,
    private supportService: SupportService,
    private platformRating: PlatformRatingService,
    private route: ActivatedRoute) {
    super()
  }

  ngOnInit(): void {
    // load legal documents from server
    this.initDocuments()

    // Subscribe for current logged profile changes
    this.currentProfileSub = this.profileService.currentProfile.subscribe(profile => {
      this.profileData = profile
    })

    // Subscribes for route details
    this.route.params.subscribe(params => {
      const section = params[NavigationService.SECTION] as LegalDocumentType
      this.tryScrollToLegalDocuments(section)
    })

    // Support contact info
    this.supportInfo = environment.contact
  }

  /**
   * Opens the {@link PlatformRatingComponent}.
   */
  openPlatformRating(): void {
    this.platformRating.showPlatformRating.next(true)
  }

  /**
   * Initializes {@link tos}, {@link tosArt}, {@link privacy} and {@link cookies} arrays of documents.
   */
  private initDocuments(): void {
    this.callAndFinish(async () => {
      const resp = await firstValueFrom(this.callGetDocuments())

      if (resp) {
        resp.tosLinks?.forEach(it =>
          this.tos.push({
            title: `${this.trans.tos} - v${it.version}`,
            url: it.link,
            effectiveAt: it.effectiveAt,
            effectiveUntil: it.endAt
          })
        )

        resp.tosArtistLinks?.forEach(it =>
          this.tosArt.push({
            title: `${this.trans.tos_art} - v${it.version}`,
            url: it.link,
            effectiveAt: it.effectiveAt,
            effectiveUntil: it.endAt
          })
        )

        resp.privacyLinks?.forEach(it =>
          this.privacy.push({
            title: `${this.trans.privacy} - v${it.version}`,
            url: it.link,
            effectiveAt: it.effectiveAt,
            effectiveUntil: it.endAt
          })
        )

        resp.cookiesLinks?.forEach(it =>
          this.cookies.push({
            title: `${this.trans.cookies} - v${it.version}`,
            url: it.link,
            effectiveAt: it.effectiveAt,
            effectiveUntil: it.endAt
          })
        )
      }

      this.resetApi()
    })
  }

  /**
   * Makes a call with {@link SupportService} to obtain all legal documents.
   */
  private callGetDocuments(): Observable<SupportDocumentLinks> {
    return this.unwrap(this.supportService.callGetDocuments())
  }

  /**
   * Scrolls to the legal section and opens the right {@link legalActiveIndex} by the given {@link section}.
   */
  private tryScrollToLegalDocuments(section?: LegalDocumentType): void {
    if (section && (section === 'terms' || section === 'terms-art' || section === 'privacy' || section === 'cookies')) {
      setTimeout(() => {
        scrollToIndex(this.legalDocumentsClass, 0, 'smooth')
        switch (section) {
          case 'terms':
            this.legalActiveIndex = 0
            break
          case 'terms-art':
            this.legalActiveIndex = 1
            break
          case 'privacy':
            this.legalActiveIndex = 2
            break
          case 'cookies':
            this.legalActiveIndex = 3
            break
        }
      }, 250)
    }
  }

  ngOnDestroy(): void {
    this.currentProfileSub?.unsubscribe()
  }
}

/**
 * Represents a structure of the legal document.
 */
interface DocumentType {
  title: string
  effectiveAt: Date
  effectiveUntil?: Date
  url: string
}

/**
 * Contains information about the document type where the page should be scrolled in the {@link SupportComponent}.
 */
export type LegalDocumentType = 'terms' | 'terms-art' | 'privacy' | 'cookies'

/**
 * A structure of the support contact.
 */
export interface SupportContact {
  phone: string
  email: string

  instagram: string
  x: string
  youtube: string
  facebook: string
  linkedin: string
  docs: string
}
