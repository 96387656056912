import {Injectable} from '@angular/core'
import {PixelCategory, PixelService} from './meta-pixel/pixel.service'
import {environment} from '../../../environments/environment'
import {ProfileResp, ProfileService} from '../profile.service'
import {PLATFORM_BROWSER} from '../../app.module'
import {hasFeatures} from '../../common/profile-type'
import {Feature} from '../../common/feature'
import {BriefPriceItemCategoryResp} from '../price-item.service'

/**
 * Represents the communication with the Meta API.
 */
@Injectable({
  providedIn: 'root'
})
export class MetaService {
  /**
   * This amount of time will be delayed the {@link PixelCategory.PRICE_ITEM_CATEGORY} event.
   */
  static readonly PRICE_ITEM_CATEGORY_TIMEOUT = 15 * 1000

  /**
   * This amount of time will be delayed the {@link PixelCategory.PROFILE_PREVIEW} event.
   */
  static readonly PROFILE_PREVIEW_TIMEOUT = 10 * 1000

  /**
   * The current running timeout of the {@link PixelCategory.PRICE_ITEM_CATEGORY} event.
   */
  private priceItemCategoryTimeout: any
  /**
   * The current running timeout of the {@link PixelCategory.PROFILE_PREVIEW} event.
   */
  private profilePreviewTimeout: any
  /**
   * Determines the current logged profile.
   */
  private currentProfile: ProfileResp

  constructor(
    private pixelService: PixelService,
    private profileService: ProfileService
  ) {
    // current logged profiles change
    this.profileService.currentProfile.subscribe(profile => {
      this.currentProfile = profile
      if (profile) {
        this.pixelService.trackCustom(PixelCategory.PROFILE_TYPE, {profileType: profile.profileType})
      }
    })
  }

  /**
   * Enables the Meta pixel tracking.
   */
  enableMetaPixel(enable: boolean): void {
    if (enable) {
      this.pixelService.initialize(environment.metaPixel)
    } else {
      this.pixelService.remove()
    }
  }

  /**
   * Call this function when a user clicks on a price item category. This informs Meta Pixel about the category.
   * By default, the track function will be delayed by {@link PRICE_ITEM_CATEGORY_TIMEOUT} to prevent user's mis clicks.
   * If the {@link immediately} parameter is enabled, the track will be performed without any delay.
   * If the {@link category} is null, the {@link priceItemCategoryTimeout} will be terminated and no track will be performed.
   */
  onPriceItemCategoryClick(category: BriefPriceItemCategoryResp | null, immediately: boolean): void {
    clearTimeout(this.priceItemCategoryTimeout)
    if (PLATFORM_BROWSER && category && this.isCustomer()) {
      this.priceItemCategoryTimeout = setTimeout(() => {
        this.pixelService.trackCustom(PixelCategory.PRICE_ITEM_CATEGORY, {
          categoryId: category.id
        })
      }, immediately ? 0 : MetaService.PRICE_ITEM_CATEGORY_TIMEOUT)
    }
  }

  /**
   * Call this function when a user previews a profile. This informs Meta Pixel about the profile data.
   * By default, the track function will be delayed by {@link PROFILE_PREVIEW_TIMEOUT} to send only user real interests.
   * If the {@link profile} is null, the {@link profilePreviewTimeout} will be terminated and no track will be performed.
   */
  onProfilePreview(profile: ProfileResp | null): void {
    clearTimeout(this.profilePreviewTimeout)
    if (PLATFORM_BROWSER && profile && this.isCustomer()) {
      this.profilePreviewTimeout = setTimeout(() => {
        this.pixelService.trackCustom(PixelCategory.PROFILE_PREVIEW, {
          name: profile.displayName,
          profileId: profile.profileId,
          profileType: profile.profileType,
          skills: profile.skills.map(it => it.id),
          genres: profile.genres.map(it => it.id),
          professions: profile.professions.map(it => it.id)
        })
      }, MetaService.PROFILE_PREVIEW_TIMEOUT)
    }
  }

  /**
   * Verifies whether the current browsing user is a customer or a guest.
   */
  private isCustomer(): boolean {
    return (!this.currentProfile || !hasFeatures(this.currentProfile.profileType, Feature.BE_ORDERED))
  }
}
