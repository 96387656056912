<div [class.invisible]="!navbarVisible"
     [class.transparent]="navbarTransparent && !showSearch"
     class="wrapper p-d-flex p-ai-center p-jc-between p-px-2">

  <!--Logo-->
  <a *ngIf="!loading; else skeletonLogo"
     class="p-d-flex p-ai-center c-pointer"
     href="https://umevia.com">
    <img alt="Umevia logo" class="logo-img p-ml-2" i18n-alt src="assets/logo/logo-color.png">
    <img alt="Umevia text logo" class="logo-text c-pointer"
         i18n-alt
         src="assets/logo/text-white.png">
  </a>

  <!--Search input-->
  <div *ngIf="showSearch && isScreenOf(Screen.MD)" class="search-container p-relative">
    <div [@fade]="showSearch" class="search">
      <app-profile-search (focusLost)="showSearch = false"
                          (profileSelected)="navigation.toProfile($event.charId)"
                          [redirect]="true"></app-profile-search>
    </div>
  </div>

  <div *ngIf="!loading; else skeletonIcons">
    <div class="p-d-flex p-ai-center p-jc-end">
      <div class="p-d-none p-d-md-flex">
        <!--Search-->
        <div (click)="onSearchIconClicked()" *ngIf="!showSearch" [@fade]="showSearch" class="icon c-pointer">
          <i class="fa-solid fa-magnifying-glass p-mr-5 p-ml-2"></i>
        </div>

        <!--Profile catalog-->
        <a [routerLink]="navigation.urlProfileCatalog()" class="icon c-pointer p-mr-5 p-d-flex p-ai-center">
          <i class="fa-solid fa-masks-theater artists p-mr-2"></i>
          <span i18n>Artists</span>
        </a>

        <!--Shopping basket-->
        <div (click)="showOrderedProfilesDialog()" class="p-relative c-pointer">
          <div *ngIf="(!currentProfile || hasFeatures(currentProfile?.profileType, Feature.ORDER_PROFILE))"
               class="icon p-relative">
            <i class="fa-solid fa-basket-shopping p-mr-5"></i>
          </div>
          <div [ngTemplateOutlet]="shoppingBadge"></div>
        </div>
      </div>

      <!--Menu bars-->
      <div (click)="iconMenu.toggle($event)" class="p-d-md-none p-relative c-pointer">
        <div class="icon p-d-flex">
          <i class="fa-solid fa-bars p-mr-3"></i>
        </div>
        <div [ngTemplateOutlet]="shoppingBadge"></div>
      </div>

      <!--Icon menu-->
      <p-menu #iconMenu [class.basket-red]="(orderedBadgeVisible | async)" [model]="iconMenuItems"
              [popup]="true"></p-menu>

      <!--Badge icon SHOPPING BASKET-->
      <ng-template #shoppingBadge>
      <span
        *ngIf="(orderedBadgeVisible | async) && (!currentProfile || hasFeatures(currentProfile?.profileType, Feature.ORDER_PROFILE))"
        class="badge-shopping p-d-flex p-jc-center p-ai-center p-absolute p-text-bold">
            <i
              class="badge-shopping-icon {{isScreenOf(Screen.MD) ? 'fa-solid fa-user' : 'fa-solid fa-shopping-basket'}}"></i>
          </span>
      </ng-template>

      <!--current logged profile-->
      <div (click)="menu.toggle($event)"
           data-test="navbar-avatar"
           *ngIf="currentProfile; else loginRegister"
           class="p-relative c-pointer">
        <!--Avatar-->
        <app-avatar [profile]="currentProfile"
                    [redirect]="false"
                    [showTooltip]="false"
                    [sizePx]="isScreenOf(Screen.SM) ? 40 : 35"></app-avatar>

        <!--Badge icon BELL BOOKING-->
        <span *ngIf="(newOrderManagerActivity | async) && currentProfile"
              class="badge-order-manager p-d-flex p-jc-center p-ai-center p-absolute p-text-bold">
          <i class="badge-manager-icon fa-solid fa-bell"></i>
        </span>
      </div>

      <!--User menu-->
      <div class="user-menu">
        <p-menu data-test="user-menu"
                #menu [class.booking-red]="(newOrderManagerActivity | async)" [model]="userMenuItems"
                [popup]="true"></p-menu>
      </div>

      <!--Login Register buttons-->
      <ng-template #loginRegister>
        <!--Login-->
        <app-button *ngIf="!loading"
                    [labelAutoHide]="true"
                    [url]="navigation.urlLogin(true)"
                    i18n-label
                    icon="fa-solid fa-user"
                    label="Login"
                    styleClass="login-button"
                    type="primary"></app-button>
      </ng-template>
    </div>
  </div>
</div>

<!--Switch profile dialog-->
<app-switch-profile *ngIf="switchProfileComponentVisible"
                    [(show)]="switchProfileComponentVisible"></app-switch-profile>

<!--Search profiles on small screens-->
<app-dialog #searchDialog
            *ngIf="searchProfileDialogVisible"
            [(show)]="searchProfileDialogVisible"
            [allowSaveOption]="false"
            [component]="this"
            discardLabel="Cancel"
            header="Search profiles"
            i18n-discardLabel
            i18n-header
            url="search">

  <div class="p-py-2 w-100 search-dialog">

    <!--Recommendation for filters-->
    <app-hint [collapsableHint]="false" styleClass="p-mt-2">
      <span i18n>If you are looking for a specific category of art, genre, or profession, try </span>
      <a (click)="searchDialog.closeDialog(null, false)"
         [fragment]="NS.PROFILE_CATALOG_FILTERS"
         [routerLink]="navigation.urlProfileCatalog()"
         class="text-link filters" i18n>filters</a>.
    </app-hint>

    <app-profile-search (profileSelected)="navigateToProfile($event.charId, searchDialog)"
                        [redirect]="true"></app-profile-search>
  </div>
</app-dialog>

<!--All selected ordered profiles from localstorage-->
<app-shopping-basket (selectedProfile)="navigateToProfile($event.charId)"
                     *ngIf="orderedProfilesDialogVis"
                     [(show)]="orderedProfilesDialogVis"/>


<!--Skeleton templates-->
<ng-template #skeletonLogo>
  <div class="p-d-flex p-jc-start">
    <p-skeleton height="2rem" styleClass="p-mx-2" width="3rem"></p-skeleton>
    <p-skeleton height="2rem" styleClass="p-d-none p-d-sm-flex" width="15rem"></p-skeleton>
    <p-skeleton height="2rem" styleClass="p-d-sm-none" width="5rem"></p-skeleton>
  </div>
</ng-template>

<ng-template #skeletonIcons>
  <div class="p-d-flex p-jc-end p-ai-center">
    <p-skeleton height="2rem" styleClass="p-d-none p-d-md-flex p-mr-2" width="3rem"></p-skeleton>
    <p-skeleton height="2rem" styleClass="p-d-none p-d-md-flex p-mr-2" width="4rem"></p-skeleton>
    <p-skeleton height="2rem" styleClass="p-mr-2" width="3rem"></p-skeleton>
    <p-skeleton
      shape="circle"
      size="3rem"
    ></p-skeleton>
  </div>
</ng-template>
