import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor} from '@angular/common/http'
import {Endpoint} from '../common/endpoints'
import {logData} from './utils'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

export function getCsrfToken(): void {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', Endpoint.CSRF_TOKEN, false)
  xhr.withCredentials = true
  xhr.send()

  if (!environment.production) {
    logData('CSRF token loaded!')
  }
}

/**
 * Intercepts all HTTP requests and adds the CSRF token from cookie to the header.
 */
@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-XSRF-TOKEN'
    const token = this.tokenExtractor.getToken() as string
    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token) })
    }
    return next.handle(req)
  }
}

