<div class="w-100" [class.p-relative]="tutorialRunning" [style.z-index]="baseZIndex">
  <div *ngIf="tutorialRunning" class="p-absolute overlay" [style.z-index]="baseZIndex + 1"></div>
  <ng-content></ng-content>
</div>

<!--Info dialog-->
<p-dialog [(visible)]="infoDialogVisible"
          [position]="currentElement?.tPosition"
          [baseZIndex]="baseZIndex + 20"
          [resizable]="false"
          [dismissableMask]="true"
          [closable]="false"
          [draggable]="true"
          [closeOnEscape]="true"
          styleClass="info-dialog rounded">

  <!--Steps-->
  <h2 class="step" *ngIf="steps">Step {{currentIndex < 0 ? 0 : currentIndex + 1}}</h2>

  <!--Text-->
  <div class="p-d-flex p-ai-center p-pb-2">
    <i class="fa-solid fa-lightbulb p-mr-3 text-icon"></i>
    {{currentElement?.tText}}
  </div>

  <!--Action buttons-->
  <div class="p-d-flex p-ai-center {{sortedElements.length > 1 ? 'p-jc-between' : 'p-jc-end' }}">
    <!--Previous-->
    <app-button type="secondary"
                label="Previous"
                i18n-label
                icon="fa-solid fa-chevron-left"
                (clicked)="previous()"
                *ngIf="currentIndex > 0"
                @fade
                class="p-px-2 p-mt-2"
                styleClass="small-button"></app-button>

    <!--Skip-->
    <app-button type="secondary"
                label="Skip"
                i18n-label
                icon="fa-solid fa-forward"
                (clicked)="skipAndFinish()"
                class="p-px-2 p-mt-2"
                *ngIf="currentIndex < sortedElements.length - 1"
                @fade
                styleClass="small-button"></app-button>

    <!--Next-->
    <app-button type="primary"
                [label]="currentIndex === sortedElements.length - 1 ? trans.finish : trans.next"
                iconPos="right"
                icon="fa-solid fa-chevron-right"
                (clicked)="next()"
                *ngIf="currentIndex < sortedElements?.length"
                @fade
                class="p-px-2 p-mt-2"
                styleClass="small-button"></app-button>
  </div>
</p-dialog>
