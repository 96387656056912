<div class="wrapper p-d-flex p-jc-center p-relative">
  <div class="component-wrapper p-px-3 p-my-2"
       *ngIf="!loading  && order; else skeleton" @grow>
    <app-breadcrumb [values]="breadcrumb"/>
    <header class="p-grid">
      <!--Order name, ID, overlap, created at template-->
      <div class="p-col-12 p-sm-8 p-p-0">
        <div class="p-d-flex">
          <!--Overlap-->
          <a *ngIf="order.overlap"
             [routerLink]="navigation.urlProfileBookingDetail(profileData.charId, order.overlap.id)"
             [appTooltip]="trans.overlap + order.overlap.id"
             class="c-pointer p-mr-2 p-mt-2">
            <i class="fa-solid fa-warning overlap-warning"></i>
          </a>
          <div>
            <h1 class="order-title p-my-0" data-test="order-title">{{ order.name }}</h1>
            <div class="order-info">
              <i class="fa-solid fa-copy p-mr-2"
                 [appCopyClipboard]="order.id"></i>
              <span i18n="@@Order number of">n.</span>
              <span data-test="order-id"> {{ order.id }}</span>
              <i class="fa-solid fa-click"></i>
            </div>
            <div class="order-info">
              <span i18n="@@Order created">Created</span>
              <span>: {{ order.createdAt | date: 'dd. MMM. yyyy - HH:mm' }}</span>
              <i class="fa-solid fa-click"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- Status badge -->
      <div class="p-col-12 p-sm-4 p-p-0">
        <div class="p-d-flex p-jc-sm-end p-mb-2 p-ml-sm-2 p-my-2">
          <app-profile-order-status-badge
            [order]="order"
            (partiallyPaidClick)="onPartialPaymentLabelClick()"
            [currentProfile]="profileData"/>
        </div>
      </div>
    </header>

    <div class="p-grid">
      <div class="p-col-12 p-sm-6 p-lg-4 p-px-0 p-pb-0">
        <!--Other-side profile-->
        <app-order-segment titleIcon="fa-solid {{isAuthor ? 'fa-masks-theater' : 'fa-user'}}"
                           [title]="isAuthor ? trans.artist : trans.customer">
          <div class="p-grid">
            <div class="p-col-12 p-p-0"
                 [class.p-sm-6]="chatChannel">
              <div *ngVar="isAuthor ? order.profile : order.author as p"
                   data-test="opposite-profile"
                   class="p-d-flex p-ai-center">
                <app-avatar [profile]="p" [sizePx]="40"/>
                <div class="p-ml-2">
                  <div>{{ p.displayName }}</div>
                  <app-profile-rate [profile]="p"
                                    [withDetails]="true"
                                    [countVisible]="true"></app-profile-rate>
                </div>
              </div>
            </div>
            <!-- Chat -->
            <div class="p-col-12 p-md-6 p-p-0" *ngIf="chatChannel" @grow>
              <div class="p-d-flex p-jc-md-end p-mt-2 p-mt-md-0 p-relative">
                <div class="p-absolute chat-badge unselectable" *ngIf="chatChannel?.unreadMessages > 0"
                     @fade>{{ chatChannel?.unreadMessages }}
                </div>

                <app-button type="secondary"
                            label="Chat"
                            i18n-label
                            data-test="chat-button"
                            icon="fa-solid fa-paper-plane"
                            (clicked)="orderChatVisible = true"/>
              </div>
            </div>
          </div>
        </app-order-segment>

        <!--Number of guests-->
        <app-order-segment titleIcon="fa-solid fa-users"
                           title="Number of guests"
                           i18n-title
                           styleClass="p-mt-2">
          <div [ngSwitch]="order?.numberOfGuests" data-test="guests-number">
            <div *ngSwitchCase="Guests.UNDER_50" i18n>Less then 50 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_100" i18n>Less then 100 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_500" i18n>Less then 500 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_1000" i18n>Less then 1000 guests.</div>
            <div *ngSwitchCase="Guests.UNDER_5000" i18n>Less then 5000 guests.</div>
            <div *ngSwitchCase="Guests.OVER_5000" i18n>Over then 5000 guests.</div>
          </div>
        </app-order-segment>

        <!-- Order description -->
        <app-order-segment titleIcon="fa-solid fa-file-lines"
                           title="Description"
                           styleClass="p-mt-2"
                           i18n-title>
          <ng-template #longText let-text="text" let-divider="divider" let-authorOfText="authorOfText">
            <p-divider *ngIf="divider"
                       align="center" layout="horizontal" type="solid">
              <div data-test="divider-text">{{ divider }}</div>
            </p-divider>

            <div #textDiv
                 class="c-pointer"
                 data-test="text"
                 [class.l-count-3]="!textDiv['unwrapped']"
                 (click)="textDiv['unwrapped'] = !textDiv['unwrapped']">
              {{ text }}
              <br>
              <div class="p-d-flex p-jc-end cancel-reason p-mt-2" *ngIf="authorOfText" @grow>
                <div data-test="from"><span i18n>From:</span><span> {{ authorOfText.displayName }}</span></div>
              </div>
            </div>
          </ng-template>

          <!-- Description -->
          <div data-test="description">
            <ng-container *ngIf="order.description"
                          [ngTemplateOutlet]="longText"
                          [ngTemplateOutletContext]="{text: order.description}"/>
            <i *ngIf="!order.description" i18n>Not provided</i>
          </div>

          <!--Profile Reply-->
          <div *ngIf="order.profileReply" data-test="reply" @grow>
            <ng-container [ngTemplateOutlet]="longText"
                          [ngTemplateOutletContext]="{text: order.profileReply, divider: trans.profile_reply}"/>
          </div>

          <!--Cancel Reason-->
          <div data-test="cancel-reason"
               *ngIf="order?.cancelReason && (order.profileAcceptance === A.REJECTED || order.authorAcceptance === A.REJECTED)"
               @grow>
            <ng-container
              [ngTemplateOutlet]="longText"
              [ngTemplateOutletContext]="{text: order.cancelReason,
                                      divider: trans.cancel_reason,
                                      authorOfText: ((order?.profileAcceptance === A.REJECTED) ? order.profile : order.author)}"/>
          </div>
        </app-order-segment>

        <div *ngIf="!isScreenOf(Screen.LG)" class="p-mt-2">
          <ng-container [ngTemplateOutlet]="place"/>
        </div>

        <!--Reviews-->
        <ng-container *ngIf="isScreenOf(Screen.LG)" [ngTemplateOutlet]="reviews"/>
      </div>

      <ng-template #date>
        <!-- Order time -->
        <app-order-segment titleIcon="fa-solid fa-calendar-check"
                           title="When"
                           i18n-title>
          <div *ngVar="order.calendarItem as ci">
            <div>{{ ci.start | date: 'dd. MMM yyyy' }}<span
              *ngIf="endDateDifferent"> - {{ ci.start | date: 'dd. MMM yyyy' }}</span>
            </div>
            <div>{{ (ci.start | date: 'HH:mm') }} - {{ (ci.end | date: 'HH:mm') }}</div>
            <i class="p-mt-1 p-d-block">
              <span i18n>Number of hours: </span>
              <span data-test="hours-count">{{ hoursDifference }}</span>
            </i>
          </div>
        </app-order-segment>
      </ng-template>

      <ng-template #place>
        <!-- Order address -->
        <app-order-segment titleIcon="fa-solid fa-location-dot"
                           title="Where"
                           i18n-title>
          <div class="p-d-flex p-jc-between"
               data-test="order-address"
               *ngVar="order.address as a">
            <div>
              <div class="p-mt-1">{{ a.line1 }} <span *ngIf="a.line2">{{ a.line2 }}</span></div>
              <div class="p-mb-1">{{ a.postalCode }}, {{ a.city }}, {{ (a.country | country)?.name }}</div>
            </div>

          <!-- Open In GoogleMaps -->
          <a [href]="googleMapsUrl(a.lat, a.lng)" target="_blank" rel="noopener noreferrer">
            <app-button type="secondary"
                        icon="fa-solid fa-arrow-up-right-from-square"
                        styleClass="p-mt-2"></app-button>
          </a>
        </div>

          <!--Map-->
          <div class="map rounded-medium p-pl-md-0 p-mt-2">
            <app-map *ngIf="profileData && order"
                     [newMarker]="isAuthor ? order.author : order.profile"
                     [newMarkerPos]="mapLocation"
                     [resetButton]="false"
                     [explore]="false"
                     [filterButton]="false"
                     [readonly]="true"
                     styleClass="rounded-medium"
                     [search]="false"></app-map>
          </div>
        </app-order-segment>
      </ng-template>

      <div class="p-col-12 p-lg-4 p-px-2" *ngIf="isScreenOf(Screen.LG)">
        <ng-container [ngTemplateOutlet]="date"/>
        <div class="p-mt-2">
          <ng-container [ngTemplateOutlet]="place"/>
        </div>
      </div>

      <div class="p-col-12 p-sm-6 p-lg-4 p-px-0 p-pl-sm-2 p-pl-lg-0">
        <div class="p-mb-2" *ngIf="!isScreenOf(Screen.LG)">
          <ng-container [ngTemplateOutlet]="date"/>
        </div>

        <!-- Price items -->
        <app-order-segment titleIcon="fa-solid fa-cart-shopping"
                           title="Ordered"
                           styleClass="p-mt-2 p-mt-sm-0"
                           i18n-title>
          <!--Ordinary-->
          <div class="item-container" data-test="price-items">
            <div *ngFor="let item of order?.priceItems" [attr.data-test]="item.item.id">
              <div data-test="name">{{ item.item.name }}</div>
              <div class="p-d-flex p-jc-between p-ai-center">
                <i *ngIf="item.item.fixedPrice" class="item-info" i18n>Fixed</i>
                <i *ngIf="!item.item.fixedPrice"
                   data-test="hour-info"
                   class="p-d-flex item-info" i18n>Hour ({{ item.item.price | price }})
                  <div class="p-ml-2">x <span data-test="quantity">{{ item.quantity }}</span></div>
                </i>
                <div data-test="price">{{ (item.item.price * ((!item.item.fixedPrice) ? item.quantity : 1)) | price }}
                </div>
              </div>
            </div>
          </div>

          <p-divider align="center" layout="horizontal" type="solid"
                     data-test="price-additions-divider"
                     *ngIf="order?.priceAdditions?.length > 0"
                     i18n>
            Additional Services
          </p-divider>

          <ng-template #itemTempl let-id="id" let-name="name" let-price="price" let-tooltip="tooltip"
                       let-priceClass="priceClass">
            <div class="p-d-flex p-jc-between p-ai-center" [attr.data-test]="id">
              <div class="p-d-flex p-ai-center">
                <div data-test="name">{{ name }}</div>
                <i *ngIf="tooltip"
                   [appTooltip]="tooltip"
                   [tooltipOnClickOnly]="true"
                   class="fa-solid fa-circle-info p-ml-2 tooltip-icon"></i>
              </div>
              <div class="p-ml-2 {{priceClass}}" data-test="price">{{ price | price }}</div>
            </div>
          </ng-template>

          <!--Additions-->
          <div class="item-container" data-test="price-additions">
            <ng-container *ngFor="let item of order?.priceAdditions"
                          [ngTemplateOutlet]="itemTempl"
                          [ngTemplateOutletContext]="{name: item.name,
                                                      price: item.price,
                                                      id: item.id}"/>
          </div>

          <!--Summary-->
          <p-divider align="center" layout="horizontal" type="solid"
                     i18n>
            Summary
          </p-divider>

          <!-- Platform Fixed fee -->
          <div *ngIf="isAuthor" data-test="platform-fee">
            <ng-container [ngTemplateOutlet]="itemTempl"
                          [ngTemplateOutletContext]="{name: trans.platform_fee,
                                                price: order?.platformFee,
                                                tooltip: trans.tooltip_platform_fee}"/>
          </div>

          <!--Additional price form artist-->
          <div *ngIf="order?.additionalPrice > 0" data-test="additional-price" @grow>
            <ng-container [ngTemplateOutlet]="itemTempl"
                          [ngTemplateOutletContext]="{name: trans.performance_costs,
                                                 price: order?.additionalPrice,
                                                 tooltip: (order.profile.displayName) + ' ' + trans.tooltip_perforamce}"/>
          </div>

          <div *ngIf="isAuthor === false && additionalPrice > 0" @grow data-test="original-profit">
            <!--Original profit-->
            <ng-container [ngTemplateOutlet]="itemTempl"
                          [ngTemplateOutletContext]="{name: trans.original_profit,
                                                  price: order.orderTotal,
                                                  priceClass: 'line-through'}"/>
          </div>

          <!--Total Price-->
          <div class="item">
            <div class="p-d-flex p-jc-between p-ai-center total-price" data-test="total">
              <b i18n>Total</b>
              <div class="p-ml-2">
            <span *ngIf="(order?.profileAcceptance === A.PENDING && order?.authorAcceptance === A.PENDING)"
                  i18n>From</span>
                <b data-test="price"> {{ (newOrderTotal || order?.total) | price }}</b>
              </div>
            </div>
          </div>

          <!--Taxes and Fees-->
          <div class="p-d-flex p-mt-1" *ngIf="isAuthor" data-test="fees-sentence"
               appTooltip="This price includes a money-back guarantee, 24/7 support, secure payments, and the provision of this platform in order to achieve the best user experience."
               [tooltipOnClickOnly]="true"
               i18n-appTooltip>
            <div class="p-d-flex p-ai-center fees-info">
              <i class="fa-solid fa-check p-mr-2 "></i>
              <small i18n>Includes taxes and fees</small>
              <i class="fa-solid fa-info-circle p-ml-2"></i>
            </div>
          </div>

          <div class="p-d-flex p-flex-column rounded-medium p-py-1 w-100">
            <!--Edit price field for an Artist-->
            <form *ngIf="!isAuthor && (order?.profileAcceptance === A.PENDING && order?.authorAcceptance === A.PENDING)"
                  [formGroup]="formCustomPrice">
              <!--Edit button-->
              <div class="p-d-flex p-jc-end edit-price-button" *ngIf="!priceInputVisible" @grow data-test="edit-price">
                <a class="text-link p-d-flex p-ai-center" (click)="priceInputVisible = true" i18n>
                  <i class="fa-solid fa-pen p-mr-2"></i>
                  <u>Edit price</u>
                </a>
              </div>
              <!--Edit input-->
              <app-text-input *ngIf="priceInputVisible" @grow
                              inputType="number"
                              formFieldName="customPrice"
                              icon="fa-solid fa-pen"
                              placeholder="Your profit"
                              data-test="price-input"
                              i18n-placeholder
                              [min]="order?.orderTotal"
                              [form]="formCustomPrice"/>
              <!--FE-CHECK-->
              <app-frontend-validation [form]="formCustomPrice" field="customPrice" error="negativeAdditional"
                                       i18n>
                You can only increase the price.
              </app-frontend-validation>

              <app-frontend-validation [form]="formCustomPrice" field="customPrice" error="maxExceeded" i18n>
                Too large number.
              </app-frontend-validation>

              <!--Hint for profile-->
              <app-hint *ngIf="priceInputVisible" @grow
                        styleClass="p-mt-2" i18n>
                You can increase your profit to cover all performance costs. Take in mind that your changes also
                increase Service fees and the total price the customer needs to pay.
              </app-hint>
            </form>

            <!--Refund - paid amount-->
            <div *ngIf="isAuthor && order.authorRefund > 0 && (order.total - order.authorRefund) > 0" @grow
                 data-test="refund-compensation"
                 class="p-d-flex p-jc-between"
                 appTooltip="Compensation for cancelation of this order."
                 i18n-appTooltip>
              <div class="p-mr-2" data-test="name" i18n>Compensation</div>
              <div data-test="price">{{ -(order.total - order.authorRefund) | price }}</div>
            </div>

            <!--Refund amount-->
            <div *ngIf="isAuthor && order.authorRefund > 0" @grow
                 data-test="refund-amount"
                 class="p-d-flex p-jc-between"
                 appTooltip="This amount will be refunded to your used payment method."
                 i18n-appTooltip>
              <div class="p-mr-2" data-test="name" i18n>Refunded</div>
              <div data-test="price">{{ order.authorRefund | price }}</div>
            </div>

            <!--Transfer amount-->
            <div *ngIf="!isAuthor && order.profileTransferred !== null" @grow
                 data-test="transfer"
                 class="p-d-flex p-jc-between"
                 appTooltip="This amount will be retrieved to your bank account soon." i18n-appTooltip>
              <div class="p-mr-2" data-test="name" i18n>Received</div>
              <div data-test="price">{{ order.profileTransferred | price }}</div>
            </div>
          </div>

          <!--Partial Funded-->
          <div class="warning-box rounded-medium p-flex-column p-mt-2 partial-refund-box"
               data-test="partial-funded"
               *ngIf="isAuthor
                    && ((((bankInstructions?.amountRemaining) / 100) || order.total) < order.total)
                    && order?.authorAcceptance === A.PENDING && order?.profileAcceptance === A.ACCEPTED
                    && currentDate < arrivalStart" @grow>
            <div class="p-d-flex w-100">
              <i class="fa-solid fa-warning p-mr-2 p-mt-1"></i>
              <div class="w-100">
                <div class="p-d-flex p-jc-between">
                  <div class="p-mr-2" data-test="paid-name" i18n>Partially paid</div>
                  <div data-test="paid">{{ (order.total - ((bankInstructions?.amountRemaining) / 100)) | price }}</div>
                </div>
                <div class="p-d-flex p-jc-between">
                  <b class="p-mr-2" data-test="remaining-name" i18n>Required to pay</b>
                  <b data-test="remaining">{{ (bankInstructions?.amountRemaining / 100) | price }}</b>
                </div>
              </div>
            </div>
            <div class="p-mt-2" i18n>We have received only a partial payment. This may happen if you didn't set the
              exact amount, or your bank charges additional fees for cross-country payments.
            </div>

            <!-- Pay -->
            <app-button type="cta"
                        label="Pay"
                        i18n-label
                        [highlight]="true"
                        icon="fa-solid fa-credit-card"
                        styleClass="p-mt-2"
                        class="w-100"
                        (clicked)="showPaymentDialog = true"/>
          </div>

          <!--Hint for an author-->
          <app-hint
            *ngIf="isAuthor && (order?.profileAcceptance === A.PENDING && order?.authorAcceptance === A.PENDING)"
            data-test="hint-price-informative"
            styleClass="p-mt-2">
            <p i18n>
              The <b>total price is only informative</b> and can be increased by
              <b>{{ order.profile.displayName }} </b> to cover all performance costs.
            </p>
            <p i18n>
              The final price will be displayed after {{ order.profile.displayName }} accepts your order. (You'll
              be notified.)
            </p>
          </app-hint>
        </app-order-segment>

        <!--Accounting documents-->
        <app-order-segment titleIcon="fa-solid fa-file-invoice-dollar"
                           title="Documents"
                           i18n-title
                           styleClass="p-mt-2"
                           data-test="documents"
                           *ngIf="!loading && ((isAuthor && order.profileAcceptance !== A.PENDING) || !isAuthor)" @grow>

          <!--Performance Hint -->
          <div *ngIf="((isAuthor && order.profileAcceptance === A.ACCEPTED)
                      || (!isAuthor && order.authorAcceptance !== A.REJECTED && order.profileAcceptance !== A.REJECTED))
                      && !order.finished && !order.profileTransferredAt && !order.paidAt" data-test="hint-performance">
            <p-divider align="right" layout="horizontal" type="solid" styleClass="p-my-0"
                       i18n>
              Performance
            </p-divider>
            <!--Profile is pending-->
            <app-hint styleClass="p-mt-1" icon="fa-solid fa-file-invoice-dollar">
              <!--Accepted and legal entity-->
              <span *ngIf="order.profileIsLegalEntity" data-test="invoice"
                    i18n>
                An accounting document (invoice) will be issued after the event automatically and able to download here.
              </span>
              <!--Accepted and not legal entity-->
              <div *ngIf="!order.profileIsLegalEntity" data-test="contract">
                <span *ngIf="isAuthor" data-test="author" i18n>
                  Since the artist is not a legal person, you enter into an artistic performance contract with them.
                </span>
                <span *ngIf="!isAuthor" data-test="profile" i18n>
                  Since you are not a legal person, you enter into an artistic performance contract with a customer.
                </span>
                <span i18n>
                  The contract will be generated and can be downloaded once the order has been paid in full.
                  For now, you can see what the contract will look like.
                </span>
                <br>
                <!-- Contract Preview -->
                <app-button type="text"
                            label="Contract Preview"
                            i18n-label
                            data-test="contract-preview-button"
                            icon="fa-solid fa-eye"
                            styleClass="p-mt-2 p-pl-0"
                            (clicked)="contractPreviewVisible = true"/>
              </div>
            </app-hint>
          </div>

          <!--Performance-->
          <div data-test="performance"
               *ngIf="(order.profileTransferredAt || (order.profileContractPresent && order.paidAt) && order?.documentsPresent)">
            <ng-container [ngTemplateOutlet]="documentTemplate"
                          [ngTemplateOutletContext]="{title: trans.performance, document: 'performance'}"/>
          </div>

          <!--Profile Receipt-->
          <div data-test="profile-receipt"
               *ngIf="!isAuthor && order.profileContractPresent && order.profileTransferredAt && order.authorAcceptance === A.REJECTED">
            <ng-container [ngTemplateOutlet]="documentTemplate"
                          [ngTemplateOutletContext]="{title: trans.receipt, document: 'receipt'}"/>
          </div>

          <!-- Platform Fee before invoice -->
          <div *ngIf="isAuthor
                && order.paidAt
                && !order.profileTransferredAt
                && order.profileAcceptance !== A.REJECTED
                && order.authorAcceptance !== A.PENDING"
               data-test="hint-platform-fee">
            <p-divider align="right" layout="horizontal" type="solid">{{ trans.service_fee }}</p-divider>
            <app-hint icon="fa-solid fa-file-invoice-dollar" styleClass="p-mt-1" i18n>
              The invoice for the administrative fee will be issued immediately after the
              successful completion of the event.
            </app-hint>
          </div>

          <!--Platform Fee-->
          <div data-test="platform-fee-doc" *ngIf="isAuthor && order.profileTransferredAt">
            <ng-container [ngTemplateOutlet]="documentTemplate"
                          [ngTemplateOutletContext]="{title: trans.service_fee, document: 'service-fee'}"/>
          </div>

          <!--Stripe Receipt-->
          <div *ngIf="isAuthor && order.paidAt" data-test="hint-payment-receipt">
            <p-divider align="right" layout="horizontal" type="solid"
                       i18n>
              Payment Receipt
            </p-divider>
            <app-hint icon="fa-brands fa-stripe-s" styleClass="p-mt-1" i18n>
              This is not an accounting document. Once you have paid the full price, Stripe will send you a
              confirmation of payment to your email.
            </app-hint>
          </div>

          <!--Document template-->
          <ng-template #documentTemplate let-title="title" let-doc="document">
            <div>
              <p-divider align="right" layout="horizontal" type="solid" styleClass="p-my-0">
                <div data-test="divider-title">{{ title }}</div>
              </p-divider>

              <div class="p-d-flex p-ai-center p-my-2">
                <!--Download Invoice PDF-->
                <app-button type="secondary"
                            icon="fa-solid fa-download"
                            styleClass="p-mr-2"
                            label="Download"
                            i18n-label
                            data-test="download"
                            [loading]="loadingDocuments"
                            (clicked)="openDocument(doc, false)"></app-button>

                <!-- Preview Invoice (Hosted) -->
                <app-button type="secondary"
                            icon="fa-solid fa-eye"
                            [label]="isScreenOf(Screen.SM) ? trans.show : ''"
                            appTooltip="Show"
                            i18n-appTooltip
                            data-test="show"
                            [loading]="loadingDocuments"
                            (clicked)="openDocument(doc, true)"></app-button>
              </div>
            </div>
          </ng-template>
        </app-order-segment>

        <!--Reviews-->
        <ng-container *ngIf="!isScreenOf(Screen.LG)" [ngTemplateOutlet]="reviews"/>
      </div>
    </div>

    <!-- Call Response -->
    <app-call-response [component]="this" styleClass="p-mt-3 box-shadow" data-test="general-response"/>
  </div>

  <!--CTA Bar-->
  <div class="p-fixed cta-bottom-bar p-d-flex p-jc-center" data-test="cta-bottom-bar"
       *ngIf="bottomBarVisible" @grow>
    <div class="component-wrapper p-d-flex p-ai-center p-jc-between p-px-3 p-py-2">
      <!--Reject-->
      <app-button
        *ngIf="bottomBarOptions.reject" @grow
        label="Reject"
        i18n-label
        type="danger-text"
        data-test="reject"
        [labelAutoHide]="bottomBarOptions.rejectLabelVisible"
        styleClass="p-pl-0"
        icon="fa-solid fa-heart-crack p-mr-2"
        (clicked)="showReplyDialog(true)"/>

      <!--Price-->
      <div class="bottom-bar-price" data-test="price">{{ (newOrderTotal || order.total) | price }}</div>

      <!--Pay-->
      <app-button
        *ngIf="bottomBarOptions.pay" @grow
        label="Pay"
        i18n-label
        type="cta"
        data-test="pay"
        [highlight]="true"
        icon="fa-solid fa-credit-card p-mr-2"
        (clicked)="showPaymentDialog = true"/>

      <!--Accept-->
      <app-button
        *ngIf="bottomBarOptions.accept"
        @grow
        label="Accept"
        i18n-label
        type="cta"
        data-test="accept"
        [highlight]="true"
        icon="fa-solid fa-check p-mr-2"
        [disabled]="formCustomPrice.invalid"
        (clicked)="showReplyDialog(false)"/>

      <!--Show order state dialog-->
      <app-button
        *ngIf="bottomBarOptions.state" @grow
        label="Action Required"
        i18n-label
        data-test="action-required"
        [highlight]="true"
        type="cta"
        icon="fa-solid fa-bell fa-shake"
        (clicked)="orderStateDialogVisible = true"/>
    </div>
  </div>
</div>

<!--Reviews template-->
<ng-template #reviews>
  <app-order-segment titleIcon="fa-regular fa-star"
                     title="Review"
                     styleClass="p-mt-2"
                     i18n-title="@@Order review"
                     data-test="reviews"
                     *ngIf="otherSideReview || profileReview" @grow>

    <!-- Other side Review -->
    <div *ngIf="otherSideReview as r" data-test="other-side">
      <ng-container [ngTemplateOutlet]="rev"
                    [ngTemplateOutletContext]="{$implicit: r}"/>
    </div>

    <!-- Profile Review -->
    <div *ngIf="profileReview as r" data-test="profile">
      <ng-container [ngTemplateOutlet]="rev"
                    [ngTemplateOutletContext]="{$implicit: r}"/>
    </div>

    <ng-template #rev let-r>
      <p-divider
        align="right" layout="horizontal" type="solid">
        <span data-test="divider-title">{{ r.author.displayName }}</span>
      </p-divider>
      <app-rating [stars]="r.stars"/>
      <div *ngIf="r.autoRated"
           data-test="auto-rated"
           appTooltip="The reviewer did not post the review within the required time."
           i18n-appTooltip
           class="p-d-flex p-ai-center">
        <i class="fa-solid fa-info-circle p-mr-2 c-pointer"></i>
        <i i18n>(Auto rated)</i>
      </div>
      <div data-test="text">{{ r.text }}</div>
    </ng-template>
  </app-order-segment>
</ng-template>

<!--Order Reply dialog-->
<app-order-reply *ngIf="replyDialogVisible"
                 [(show)]="replyDialogVisible"
                 [isAuthor]="isAuthor"
                 [isReject]="rejectButtonClicked"
                 [additionalPrice]="additionalPrice"
                 [order]="order"
                 [currentProfile]="profileData"/>

<!--Order State dialog-->
<app-order-state *ngIf="orderStateDialogVisible && !disablePopups"
                 [(show)]="orderStateDialogVisible"
                 [profile]="profileData"
                 (confirmed)="confirmed = true"
                 (rated)="profileReview = $event; order.reviews?.push($event)"
                 (done)="confirmDialogButtonVisible = false"
                 [order]="order"/>

<!--Payment dialog-->
<app-stripe-pay *ngIf="showPaymentDialog"
                [orderId]="order?.id"
                [orderAmount]="order.total"
                [profileData]="profileData"
                [(show)]="showPaymentDialog"
                [bankInstructions]="bankInstructions"
                (showBankInstructions)="bankInstructionsDialogVisible = true"
                (loadBankInstructions)="loadIntentNextAction(order.id, true)"
                (successful)="onOrderPaid()"/>

<!--Custom Bank Instructions dialog-->
<app-bank-instructions *ngIf="bankInstructions && bankInstructionsDialogVisible"
                       [order]="order"
                       [(show)]="bankInstructionsDialogVisible"
                       [bankInstructions]="bankInstructions"/>

<!--Order Chat Dialog-->
<app-profile-order-chat *ngIf="orderChatVisible"
                        [(show)]="orderChatVisible"
                        [channel]="chatChannel"
                        [currentProfile]="profileData"/>

<!--Contract Preview-->
<app-contract-preview *ngIf="contractPreviewVisible"
                      [(show)]="contractPreviewVisible"
                      [orderTotal]="(isAuthor) ? 0 : (order.orderTotal + additionalPrice)"
                      [order]="order"/>

<!--Loading skeleton-->
<ng-template #skeleton>
  <div class="component-wrapper order-skeleton w-100 p-p-3 p-absolute" [style.z-index]="5">
    <!--Breadcrumb-->
    <p-skeleton width="50%" height="3rem" styleClass="rounded-medium p-mb-2"/>
    <!--Title + state-->
    <div class="p-grid">
      <div class="p-col-12 p-sm-6 p-p-0">
        <p-skeleton width="50%" height="2rem" styleClass="rounded-medium p-mb-2"/>
        <p-skeleton width="10%" height="1.5rem" styleClass="rounded-medium p-mb-2"/>
        <p-skeleton width="40%" height="1.5rem" styleClass="rounded-medium p-mb-2"/>
      </div>
      <div class="p-col-12 p-sm-6 p-p-0">
        <div class="p-d-flex p-jc-start p-jc-sm-end">
          <p-skeleton width="10rem" height="3rem" styleClass="rounded-medium p-mb-2"/>
        </div>
      </div>
    </div>
    <!--Content-->
    <div class="p-grid">
      <div class="p-col-12 p-sm-6 p-lg-4 p-p-0">
        <div class="p-pr-2">
          <p-skeleton width="100%" height="10rem" styleClass="rounded-medium p-mb-2"/>
          <p-skeleton width="100%" height="4rem" styleClass="rounded-medium p-mb-2"/>
          <p-skeleton width="100%" height="8rem" styleClass="rounded-medium p-mb-2"/>
        </div>
      </div>
      <div class="p-col-12 p-sm-6 p-lg-4 p-p-0 ">
        <div class="p-d-none p-d-sm-block w-100 p-pr-2">
          <p-skeleton width="100%" height="10rem" styleClass="rounded-medium p-mb-2"/>
          <p-skeleton width="100%" height="20rem" styleClass="rounded-medium p-mb-2"/>
        </div>
      </div>
      <div class="p-col-12 p-sm-6 p-lg-4 p-p-0">
        <div class="p-d-none p-d-lg-block w-100 p-pr-2">
          <p-skeleton width="100%" height="20rem" styleClass="rounded-medium p-mb-2"/>
          <p-skeleton width="100%" height="5rem" styleClass="rounded-medium p-mb-2"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>
