<div class="wrapper p-px-sm-3 w-100 p-d-flex p-jc-center">
  <div *ngIf="activeItem" class="component-wrapper p-px-2 p-mt-1 p-mt-sm-2">

    <!--Floating button - search order-->
    <div class="p-fixed floating-button box-shadow"
         pTooltip="Search order"
         i18n-pTooltip
         tooltipPosition="left"
         (click)="searchOrderDialogVisible = true">
      <app-button label="Search"
                  i18n-label
                  iconPos="left"
                  icon="fa-solid fa-search p-mr-2"
                  type="primary"
                  styleClass="ripple"></app-button>
    </div>

    <!--Nav-->
    <div class="p-d-flex p-ai-center">
      <i class="fa-solid fa-calendar-days p-mr-2 title-icon p-d-none p-d-sm-block"></i>
      <h1 class="title p-my-0" i18n>Bookings</h1>
    </div>

    <!--Online tutorial-->
    <app-docs-hint *ngIf="data" @grow
                   styleClass="p-mb-2"
                   [link]="canOrder ? '/docs/profiles/customer/order-manager-user/' : '/docs/profiles/artist/order-manager-artist/'"
                   i18n>
      Online tutorial
    </app-docs-hint>

    <div class="p-d-flex p-jc-between">
      <!--Calendar and unresolved profile orders button-->
      <div class="p-d-inline-block p-relative p-ai-center">
        <i *ngIf="bellVisible" class="red-bell p-d-flex p-ai-center p-jc-center fa-solid fa-bell"></i>
        <app-button label="Menu"
                    i18n-label
                    iconPos="left"
                    icon="fa-solid fa-calendar-days p-mr-2"
                    type="primary"
                    styleClass="ripple p-my-2"
                    (clicked)="sidebarVisible = true">
        </app-button>
      </div>

      <!-- Filter Dropdown -->
      <app-dropdown *ngIf="activeItem.id === ListType.REJECTED || activeItem.id === ListType.ACCEPTED" @fade
                    placeholder="Filter"
                    [form]="form"
                    formFieldName="filter"
                    [options]="filterCategories">
        <!--Selected-->
        <ng-template let-item pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid {{item.icon}} p-mr-2"></i>
            {{ item.name }}
          </div>
        </ng-template>

        <!--Item-->
        <ng-template let-item pTemplate="item">
          <div class="p-d-flex p-ai-center">
            <i class="fa-solid {{item.icon}} p-mr-2"></i>
            {{ item.name }}
          </div>
        </ng-template>
      </app-dropdown>
    </div>

    <!--Calendar and manager content skeleton-->
    <div *ngIf="!data">
      <div class="p-py-0 p-mt-2">
        <ng-template [ngTemplateOutlet]="managerContentSkeleton"></ng-template>
      </div>
    </div>

    <!--Content-->
    <section>
      <!--Calendar and profiles with unresolved orders-->
      <section class="p-py-0"
               *ngIf="data">

        <!--Sidebar-->
        <app-profile-order-manager-attention [data]="data"
                                             [(show)]="sidebarVisible"
                                             (hasUnresolvedOrders)="bellVisible=$event"></app-profile-order-manager-attention>
        <!--Orders-->
        <section class="order-container p-py-0 p-mt-2">
          <!--Booking navbar-->
          <div *ngIf="data">
            <p-tabMenu #menuManager
                       [model]="tabItems"
                       [activeItem]="!activeItem ? tabItems[0] : activeItem"
                       styleClass="p-d-flex p-jc-center">
              <ng-template pTemplate="item" let-item let-i="index">
                <div class="p-d-flex p-flex-column p-jc-center p-ai-center p-p-1 p-p-md-2 c-pointer rounded-small"
                     pRipple
                     [class.selected-tab]="activeItem.id === item.id">
                  <i class="p-relative {{item.icon}}">
                    <!--only first-->
                    <div *ngIf="i === 0 && notifDotPendingVisible" class="red-sign-artist"></div>
                    <!--only second-->
                    <div *ngIf="i === 1 && notifDotPaymentsVisible" class="red-sign-user"></div>
                  </i>
                  <div class="nav-label">{{ item.label }}</div>
                </div>
              </ng-template>
            </p-tabMenu>


            <div class="p-d-flex p-jc-center rounded-medium">
              <div [ngSwitch]="menuManager.activeItem.id">

                <!-- Pending -->
                <div *ngSwitchCase="ListType.PENDING">
                  <app-lazy-list [(items)]="pendingOrderedItems"
                                 [maxScrollHeight]="(window.innerHeight - ('22.5rem' | pixels))"
                                 [itemHeight]="isScreenOf(Screen.SM) ? itemHeight.smallItem : ((canOrder) ? itemHeight.largeItem : itemHeight.smallItem)"
                                 [overlay]="false"
                                 [overlayZIndex]="1120"
                                 [loadScrollOffset]="300"
                                 [component]="this"
                                 overlayClass="search-lazy-list-overlay"
                                 [loadFunction]="callListProfileOrderPending.bind(this, pendingOrderedItems?.nextPage)">

                    <!--Item content-->
                    <ng-template let-pendingItem pTemplate="item">
                      <div class="p-d-flex p-mb-2">
                        <div class="card rounded-medium box-shadow c-pointer p-p-0 w-100">
                          <a [routerLink]="navigation.urlProfileBookingDetail(data.charId, pendingItem.id)" pRipple>
                            <app-profile-manager-item [order]="pendingItem"
                                                      [profileData]="data"
                                                      [stateCategory]="A.PENDING"></app-profile-manager-item>
                          </a>
                        </div>
                      </div>
                    </ng-template>

                    <!--No content custom template-->
                    <ng-template pTemplate="noContent">
                      <ng-template [ngTemplateOutlet]="customNoContent"></ng-template>
                    </ng-template>

                    <!--Loading skeleton-->
                    <ng-template pTemplate="skeleton">
                      <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
                    </ng-template>
                  </app-lazy-list>
                </div>

                <!-- Awaiting payment -->
                <div *ngSwitchCase="ListType.AWAITING_PAYMENT">
                  <app-lazy-list [(items)]="awaitingPaymentItems"
                                 [maxScrollHeight]="(window.innerHeight - ('22.5rem' | pixels))"
                                 [itemHeight]="isScreenOf(Screen.SM) ? itemHeight.smallItem : ((canOrder) ? itemHeight.largeItem : itemHeight.smallItem)"
                                 [overlayZIndex]="1120"
                                 [component]="this"
                                 overlayClass="search-lazy-list-overlay"
                                 [loadFunction]="callListProfileOrderAwaitingPayment.bind(this, awaitingPaymentItems?.nextPage)">

                    <!--Item content-->
                    <ng-template let-paymentItem pTemplate="item">
                      <div class="p-d-flex p-mb-2">
                        <div class="card rounded-medium box-shadow c-pointer p-p-0 w-100">
                          <a [routerLink]="navigation.urlProfileBookingDetail(data.charId, paymentItem.id)" pRipple>
                            <app-profile-manager-item [order]="paymentItem"
                                                      [profileData]="data"
                                                      stateCategory="awaiting_payment"></app-profile-manager-item>
                          </a>
                        </div>
                      </div>
                    </ng-template>

                    <!--No content custom template-->
                    <ng-template pTemplate="noContent">
                      <ng-template [ngTemplateOutlet]="customNoContent"></ng-template>
                    </ng-template>

                    <!--Loading skeleton-->
                    <ng-template pTemplate="skeleton">
                      <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
                    </ng-template>
                  </app-lazy-list>
                </div>

                <!-- Accepted -->
                <div *ngSwitchCase="ListType.ACCEPTED">
                  <!--View confirmed-->
                  <div class="p-d-flex p-jc-center" (click)="setFilterToConfirmed()">
                    <a *ngIf="form.value.filter.key !== FilterKey.CONFIRMED" class="text-link" i18n>View already
                      confirmed.</a>
                  </div>

                  <app-lazy-list [(items)]="acceptedOrderedItems"
                                 [maxScrollHeight]="isScreenOf(Screen.XL) ? (window.innerHeight - ('22.5rem' | pixels)) : ('35rem' | pixels)"
                                 [itemHeight]="isScreenOf(Screen.SM) ? itemHeight.smallItem : ((canOrder) ? itemHeight.largeItem : itemHeight.smallItem)"
                                 [overlay]="false"
                                 [overlayZIndex]="1120"
                                 [component]="this"
                                 overlayClass="search-lazy-list-overlay"
                                 [loadFunction]="callFilteredListProfileOrderAccepted.bind(this, acceptedOrderedItems?.nextPage)">

                    <!--Item content-->
                    <ng-template let-acceptedItem pTemplate="item">
                      <div class="p-d-flex p-mb-2"
                           [class.expired-overlay]="acceptedItem.rated">
                        <div class="card rounded-medium box-shadow c-pointer p-p-0 w-100">
                          <a [routerLink]="navigation.urlProfileBookingDetail(data.charId, acceptedItem.id)" pRipple>
                            <app-profile-manager-item [order]="acceptedItem"
                                                      [profileData]="data"
                                                      [stateCategory]="A.ACCEPTED"></app-profile-manager-item>
                          </a>
                        </div>
                      </div>
                    </ng-template>

                    <!--No content custom template-->
                    <ng-template pTemplate="noContent">
                      <ng-template [ngTemplateOutlet]="customNoContent"></ng-template>
                    </ng-template>

                    <!--Loading skeleton-->
                    <ng-template pTemplate="skeleton">
                      <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
                    </ng-template>
                  </app-lazy-list>
                </div>

                <!-- Rejected -->
                <div *ngSwitchCase="ListType.REJECTED">
                  <app-lazy-list [(items)]="rejectedOrderedItems"
                                 [maxScrollHeight]="isScreenOf(Screen.XL) ? (window.innerHeight - ('22.5rem' | pixels)) : ('35rem' | pixels)"
                                 [itemHeight]="isScreenOf(Screen.SM) ? itemHeight.smallItem : ((canOrder) ? itemHeight.largeItem : itemHeight.smallItem)"
                                 [overlay]="false"
                                 [overlayZIndex]="1120"
                                 [component]="this"
                                 overlayClass="search-lazy-list-overlay"
                                 [loadFunction]="callFilteredListProfileOrderRejected.bind(this, rejectedOrderedItems?.nextPage)">

                    <!--Item content-->
                    <ng-template let-rejectedItem pTemplate="item">
                      <div class="p-d-flex p-mb-2">
                        <div class="card rounded-medium box-shadow c-pointer p-p-0 w-100">
                          <a [routerLink]="navigation.urlProfileBookingDetail(data.charId, rejectedItem.id)" pRipple>
                            <app-profile-manager-item [order]="rejectedItem"
                                                      [profileData]="data"
                                                      [stateCategory]="A.REJECTED"></app-profile-manager-item>
                          </a>
                        </div>
                      </div>
                    </ng-template>

                    <!--No content custom template-->
                    <ng-template pTemplate="noContent">
                      <ng-template [ngTemplateOutlet]="customNoContent"></ng-template>
                    </ng-template>

                    <!--Loading skeleton-->
                    <ng-template pTemplate="skeleton">
                      <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
                    </ng-template>
                  </app-lazy-list>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  </div>
</div>

<!--Calendar day card-->
<ng-template #calendarDay let-item>
  <div class="p-col-12 p-md-6 calendar-day rounded-medium p-text-center p-p-1">
    <h2 class="p-m-0">{{ item.calendarItem.start | date: 'd' }}</h2>
    <div>{{ item.calendarItem.start | date: 'MMM' }}</div>
    <div>{{ item.calendarItem.start | date: 'yyyy' }}</div>
  </div>
</ng-template>

<!--no content-->
<ng-template #customNoContent>
  <div class="p-d-flex p-jc-center w-100">
    <i class="no-content-icon fa-solid fa-calendar-xmark"></i>
  </div>
</ng-template>

<!--Manager content skeleton-->
<ng-template #managerContentSkeleton>
  <p-skeleton height="4rem" width="100%"></p-skeleton>
  <div class="p-d-flex p-jc-center">
    <p-skeleton height="1rem" width="15rem" styleClass="p-my-4"></p-skeleton>
  </div>
  <ng-template [ngTemplateOutlet]="itemSkeleton"></ng-template>
</ng-template>

<!--Item skeleton-->
<ng-template #itemSkeleton>
  <div class="p-mt-4"></div>
  <div *ngFor="let i of [].constructor(2)"
       class="skeleton-item rounded-medium p-p-3 p-d-flex w-100 p-mb-3">
    <div class="p-mr-2">
      <p-skeleton height="5rem" width="3rem" styleClass="rounded-small"></p-skeleton>
    </div>

    <div class="w-100">
      <!--Names-->
      <div class="p-d-flex p-ai-center">
        <p-skeleton shape="circle" height="50px" width="50px" styleClass="p-mr-2"></p-skeleton>
        <div class="w-100">
          <p-skeleton height="2rem" width="70%" styleClass="rounded-small"></p-skeleton>
          <p-skeleton height="1rem" width="40%" styleClass="rounded-small p-mt-2"></p-skeleton>
        </div>
      </div>

      <!--Text-->
      <div class="p-d-flex p-jc-between p-mt-2 w-100">
        <div class="w-100">
          <p-skeleton height="2rem" width="65%" styleClass="rounded-small"></p-skeleton>
          <p-skeleton height="1rem" width="40%" styleClass="rounded-small p-mt-2"></p-skeleton>
        </div>
        <div class="w-100">
          <p-skeleton height="2rem" width="65%" styleClass="rounded-small"></p-skeleton>
          <p-skeleton height="1rem" width="40%" styleClass="rounded-small p-mt-2"></p-skeleton>
        </div>
      </div>

      <!--Price-->
      <div class="p-d-flex p-jc-between p-mt-3">
        <p-skeleton height="1.2rem" width="4rem" styleClass="rounded-small"></p-skeleton>
        <p-skeleton height="1.2rem" width="7rem" styleClass="rounded-small"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<!--Search order dialog-->
<app-order-search *ngIf="searchOrderDialogVisible"
                  [(show)]="searchOrderDialogVisible"
                  [profileData]="data"></app-order-search>
