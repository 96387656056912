<div class="wrapper p-d-flex p-ai-center p-jc-between p-py-2 p-px-3"
     *ngIf="visible" [@fade]="visible"
     [class.c-pointer]="snackbar?.click"
     (click)="snackbar?.click($event);">
  <!--Content-->
  <div class="p-d-flex p-ai-center">
    <span *ngIf="snackbar?.icon" class="p-mr-3"><i [class]="snackbar?.icon"></i></span>
    <span>{{snackbar?.message}}</span>
  </div>

  <!--Close-->
  <app-button type="icon"
              icon="fa-solid fa-xmark"
              (clicked)="hide()"></app-button>
</div>
