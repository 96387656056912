import {Component} from '@angular/core'
import {FirebaseRegisterReq} from '../../../service/firebase-auth.service'
import {blockHistory} from '../../../utils/utils'
import {ProfileType} from '../../../common/profile-type'
import {fadeAnimation} from '../../../animation/fade.animation'
import {growAnimation} from '../../../animation/grow.animation'
import {RegisterFirebaseAbstractComponent} from '../abstract/register-firebase-abstract.component'

/**
 * Register component used as a single page. Logic is implemented in the abstract class.
 */
@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-register-firebase',
  templateUrl: './register-firebase.component.html',
  styleUrls: ['./register-firebase.component.scss']
})
export class RegisterFirebaseComponent extends RegisterFirebaseAbstractComponent {

  protected override initForm(): void {
    super.initForm()

    this.formSub = this.form.valueChanges.subscribe(() => {
      if (!this.blockReturn) {
        blockHistory()
      }
      this.blockReturn = true
    })
  }

  createRegisterReq(formData: any): FirebaseRegisterReq {
    return {
      displayName: formData.displayName.trim(),
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      profileType: (formData.selectedCategory.key === 'artist') ? ProfileType.ARTIST : ProfileType.USER,
      authType: this.authType,
      firebaseUid: formData.firebaseUid
    }
  }

  emptyServiceDataAction(): void {
    this.navigation.toLogIn(false)
  }
}
