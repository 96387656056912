<div [formGroup]="form"
     [class]="styleClass"
     [attr.data-test]="formFieldName"
     *ngIf="(form && formFieldName); else ngModel">

  <div class="p-d-flex p-ai-center tutorial-class w-100">
    <p-checkbox [formControlName]="formFieldName"
                [binary]="true"></p-checkbox>

    <!--Label-->
    <div class="p-d-flex p-ai-center p-ml-2 w-100 c-pointer"
         (click)="invertValue()">

      <!--Required icon-->
      <i *ngIf="showRequiredSymbol"
         class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
         pTooltip="This field is required"
         i18n-pTooltip
         tooltipPosition="top"></i>

      <div class="label unselectable">{{label}}</div>
    </div>
  </div>

  <!--FE-CHECK-->
  <app-frontend-validation *ngIf="!disableRequired" [form]="form" [field]="formFieldName" error="required" i18n>
    Required.
  </app-frontend-validation>
</div>

<!--Ng Model driven form-->
<ng-template #ngModel>
  <div [class]="'p-d-flex p-ai-center tutorial-class ' + styleClass"
       [attr.data-test]="formFieldName"
       *ngVar="form?.controls[formFieldName].invalid && form.controls[formFieldName].touched as invalid">

    <p-checkbox [(ngModel)]="check"
                (ngModelChange)="checkChange.emit($event)"
                [binary]="true"
                [styleClass]="invalid ? 'checkbox-input-error' : ''"></p-checkbox>

    <!--Label-->
    <div class="p-d-flex p-ai-center p-ml-2 w-100 c-pointer"
         (click)="onLabelClick()">

      <!--Required icon-->
      <i *ngIf="showRequiredSymbol"
         class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
         pTooltip="This field is required"
         i18n-pTooltip
         tooltipPosition="top"></i>

      <div class="label unselectable">{{label}}</div>
    </div>
  </div>
</ng-template>
