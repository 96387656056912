import {Directive, EventEmitter, OnInit, Output} from '@angular/core'
import {EditableComponent} from './editable.component'

/**
 * The base abstract class component used for emit validation state.
 */
@Directive()
export abstract class ValidComponent extends EditableComponent implements OnInit {

  /**
   * Emits the component validity.
   */
  @Output()
  valid = new EventEmitter<boolean>()

  protected setValid(valid: boolean): void {
    this.valid.emit(valid)
  }

  ngOnInit(): void {
    this.setValid(false)
  }
}
