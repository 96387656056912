<p-galleria
  [value]="images"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [numVisible]="5"
  [(activeIndex)]="activeIndex"
  (activeIndexChange)="activeIndex = $event"
  [circular]="true"
  [baseZIndex]="10000"
  [fullScreen]="true"
  [showThumbnails]="false"
  [showItemNavigators]="true">
  <ng-template pTemplate="item" let-item>
    <div class="p-d-flex p-ai-center p-flex-column galleria-image">
      <img [src]="item.url" class="p-d-block unselectable galleria-image rounded-small"/>
      <div class="galleria-footer w-100 p-py-3 p-d-flex p-jc-between">
        <div class="p-pr-3 l-count-3">{{ images[activeIndex]?.description || '' }}</div>
        <div>{{ activeIndex + 1 }}/{{ images.length }}</div>
      </div>
    </div>
  </ng-template>
</p-galleria>
