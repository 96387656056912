import {Injectable} from '@angular/core'
import {Endpoint} from '../common/endpoints'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {ApiService} from './api.service'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class GenreService extends ApiService {

  constructor() {
    super()
  }

  callSearchGenre(req: SearchGenreReq): Observable<BaseResp<Page<GenreRest>>> {
    return this.post(Endpoint.GENRE_SEARCH_URL, req, super.getHeaders(), false)
  }

  callUpdateProfileGenres(req: UpdateProfileGenresReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_GENRES_URL, req)
  }

  callGetAllGenres(req: AllGenresReq): Observable<BaseResp<Page<GenreRest>>> {
    return this.post(Endpoint.GENRE_ALL_URL, req)
  }

  callGetMostUsed(): Observable<BaseResp<GenreRest[]>> {
    return this.post(Endpoint.GENRE_MOST_USED, null, super.getHeaders(), false)
  }
}

export interface SearchGenreReq {
  name: string
  page?: number
}

export interface UpdateProfileGenresReq {
  ids: number[]
}

export interface GenreRest {
  id: number
  name: string
  count: number
}

export interface AllGenresReq {
  page: number
}
