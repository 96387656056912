import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {ProfileReq, ProfileResp, ProfileService} from '../../service/profile.service'
import {ApiComponent} from '../abstract/api.component'
import {CalendarCell, CalendarCellDetail, ProfileCalendarComponent} from './profile-calendar/profile-calendar.component'
import {NavbarService} from '../../service/ui/navbar.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {ProfileOfferComponent} from './profile-offer/profile-offer.component'
import {ProfileCalendarBookFormComponent} from './profile-calendar/profile-calendar-book-form/profile-calendar-book-form.component'
import {BookField, BookingService} from '../../service/ui/booking.service'
import {dateJoin} from '../../utils/date.utils'
import {StripeCompletionCheckResp, StripeService} from '../../service/stripe.service'
import {Feature} from '../../common/feature'
import {hasFeatures} from '../../common/profile-type'
import {MetadataService} from '../../service/helper-services/metadata.service'
import {NavigationService as NS, NavigationService} from '../../service/ui/navigation.service'
import {Location} from '@angular/common'
import {MetaService} from '../../service/analytics/meta.service'
import {ProfileCompletionService} from '../../service/profile-completion.service'
import {ProfileSettingsDialogHighlight} from './profile-settings/profile-settings.component'
import {scrollToIndexOptions} from '../../utils/scroll.utils'
import {ProfileBottomBarComponent} from './profile-bottom-bar/profile-bottom-bar.component'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * Represents the current previewing profile data.
   */
  profileData: ProfileResp

  /**
   * Represents a current logged used profile data.
   */
  loggedProfile: ProfileResp

  /**
   * Determines whether the current user is owner of this profile.
   */
  canEdit: boolean = null

  /**
   * Contains a value of the currently selected cell in the {@link ProfileCalendarComponent}.
   */
  calendarSelectedDetailCell?: CalendarCellDetail

  /**
   * The current calendar selected cell.
   */
  calendarSelectedCell?: CalendarCell

  /**
   * Disables the changes of the @{@link calendarSelectedCell} property.
   */
  disableSelectedCellEmit: boolean

  /**
   * Determines whether the date is unavailable.
   */
  isDateAvailable: boolean
  /**
   * Gets initialized via the @{@link ProfileCalendarComponent} (ready) emitter.
   */
  calendar: ProfileCalendarComponent
  /**
   * Gets initialized via the @{@link ProfileCalendarBookFormComponent} (ready) emitter.
   */
  bookForm: ProfileCalendarBookFormComponent
  /**
   * Whether the {@link data} contains the {@link Feature.BE_ORDERED}.
   */
  canBeOrdered: boolean
  /**
   * Contains the check whether the stripe is completed.
   */
  stripeCompletionCheck?: StripeCompletionCheckResp = null
  /**
   * Controls the visibility of the profile settings dialog.
   */
  settingsDialogVisible: boolean
  /**
   * Determines what settings in the {@link ProfileSettingsComponent} dialog should be highlighted.
   */
  settingsDialogHighlight: ProfileSettingsDialogHighlight
  /**
   * - Contains the current visibility state of the top navigation bar.
   * - Used to add special margin on sticky elements depending on the visibility.
   */
  navbarVisible: boolean

  @ViewChild('offer')
  offer: ProfileOfferComponent

  /**
   * Available translations for HTML.
   */
  protected trans = {
    calendar_title_owner: $localize`Calendar`,
    calendar_title_customer: $localize`Choose the date of your event`
  }

  private currentProfileSub?: Subscription
  private paramsSub?: Subscription
  private navbarSub?: Subscription

  constructor(
    public changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private completionService: ProfileCompletionService,
    public navbarService: NavbarService,
    private bookingService: BookingService,
    private stripeService: StripeService,
    private metadataService: MetadataService,
    private metaService: MetaService,
    private location: Location) {
    super()
  }

  ngOnInit(): void {
    this.parseURL()
    this.getLoggedProfile()
    // set navbar auto hide
    this.navbarService.setAutoHideEnabled(true)
    this.navbarSub = this.navbarService.visible.subscribe((visible) => {
      this.navbarVisible = visible
      this.changeRef.detectChanges()
    })
  }

  /**
   * Initializes calendar and book form from local storage.
   */
  initCalendarFromStorage(): void {
    if (!this.calendar || !this.profileData || !this.bookForm || this.profileData.owner) {
      return
    }

    const stored = this.bookingService.getProfileBookDate()
    if (stored) {
      this.jumpToDateWithoutEmit(stored.startDate)
      setTimeout(() => {
        const c = this.bookForm.form.controls

        if (stored.startTime) {
          c['startTime'].setValue(stored.startTime)
          this.selectedDateUpdate('startTime', stored.startTime)
        }
        if (stored.endTime) {
          c['endTime'].setValue(stored.endTime)
          this.selectedDateUpdate('endTime', stored.endTime)
        }
        c['endDate'].setValue(stored.endDate || '')
        // start date is not required, because of onJumpToDate
      }, 10) // give some time to calendar's onJumpToDate
    }
    this.changeRef.detectChanges()
  }

  /**
   * Fires when the profile-settings dialog gets closed.
   */
  async onSettingsClose(): Promise<void> {
    this.fixUrl()
    // When the stripe account is not completed, try to re-init
    if (!this.stripeCompletionCheck?.completed) {
      await this.checkStripeCompleted()
    }
  }

  /**
   * Jumps to date without emitting the cell changes written in the {@link calendarSelectedCell}.
   *
   * @param date The date to jump.
   */
  private jumpToDateWithoutEmit(date: Date): void {
    this.disableSelectedCellEmit = true
    this.calendar.onJumpToDate(date)
    this.disableSelectedCellEmit = false
  }

  /**
   * Fires when a user has to be scrolled into offer component.
   */
  scrollToOffer(): void {
    scrollToIndexOptions(ProfileBottomBarComponent.OFFER_COMPONENT_CLASS, 0, {
      behavior: 'smooth',
      block: 'start'
    })
  }

  /**
   * Update properties inside of the {@link calendar} and {@link offer} component.
   */
  selectedDateUpdate(field: BookField, date: Date): void {
    this.bookingService.updateProfileBookDate(field, date)

    switch (field) {
      case 'startTime':
        this.calendar.searchStartTime = date
        this.calendar.applySearchValues()
        break
      case 'endTime':
        this.calendar.searchEndTime = date
        this.calendar.applySearchValues()
        break
      case 'startDate':
        this.jumpToDateWithoutEmit(date)
        break
    }
    this.offer.disableCheckout = true
    this.changeRef.detectChanges()

    if (this.bookForm.selectedStartDate && this.bookForm.selectedStartTime
      && this.bookForm.selectedEndTime && this.bookForm.selectedEndDate) {
      this.offer.selectedStartDate = dateJoin(this.bookForm.selectedStartDate, this.bookForm.selectedStartTime)
      this.offer.selectedEndDate = dateJoin(this.bookForm.selectedEndDate, this.bookForm.selectedEndTime)
    }
  }

  /**
   * Parses URL to get its parameters.
   */
  private parseURL(): void {
    this.paramsSub = this.route.params.subscribe(async (params) => {
      const charId = params[NavigationService.PROFILE_CHAR_ID_PARAMETER]

      if (params[NS.PROFILE_ACTION_PARAMETER] === 'deactivate') {
        this.settingsDialogHighlight = 'delete'
        this.settingsDialogVisible = true
        // The page is loaded from the stripe onboarding process
      }

      await this.call(async () => {
        this.profileData = await firstValueFrom(this.callGetProfile(charId))
        if (this.profileData) {
          this.metadataService.setProfileTags(this.profileData)
          this.metaService.onProfilePreview(this.profileData)
          this.canBeOrdered = hasFeatures(this.profileData.profileType, Feature.BE_ORDERED)
        }
      })
      this.initProperties(params[NS.PROFILE_ACTION_PARAMETER] === 'stripe')
    })
  }

  /**
   * Gets the currently logged profile.
   */
  private getLoggedProfile(): void {
    // Subscribe for current logged profile changes
    this.currentProfileSub = this.profileService.currentProfile.subscribe(profile => {
      this.loggedProfile = profile
      this.initProperties()
    })
  }

  /**
   * Calls the API to download all necessary profile data.
   *
   * @param characterId The charId of the profile (parsed from URL).
   */
  private callGetProfile(characterId: string): Observable<ProfileResp> {
    const req: ProfileReq = {
      charId: characterId
    }
    return this.unwrap(this.profileService.callGetProfile(req))
  }

  /**
   * - Fixes the current url to the correct state.
   * - Called when the settings dialog gets closed and the user has changed its charId.
   */
  private fixUrl(): void {
    setTimeout(() => {
      this.location.replaceState(`${NavigationService.PROFILE}/${this.profileData.charId}`)
    }, 100)
  }

  /**
   * Initializes the {@link canEdit} property.
   * If the {@link tryOpenDialog} is specified, it will try to open the settings dialog.
   */
  private initProperties(tryOpenDialog: boolean = false): void {
    if (this.profileData && this.loggedProfile) {
      this.canEdit = (this.profileData.profileId === this.loggedProfile.profileId)

      // check stripe completed
      this.call(async () => {
        await this.checkStripeCompleted()
        if (tryOpenDialog) {
          this.tryShowSettingsDialog()
        }
      })
    } else if (!this.loggedProfile) {
      this.canEdit = false
    }
  }

  /**
   * If some of the required profile setting is missing, or not set, it will try to show the {@link settingsDialogVisible}.
   * Also set {@link settingsDialogVisible} to true, when it is about Stripe account, so it will be scrolled to Stripe section.
   */
  tryShowSettingsDialog(): void {
    this.call(async () => {
      await this.checkStripeCompleted()
      if (!this.canBeOrdered) {
        return
      }

      // Check if necessary profile data is missing
      if (!this.profileData.address
        || this.profileData.professions.length === 0
        || this.stripeCompletionCheck?.completed === false
        || this.stripeCompletionCheck?.newRequirementDue
        || !this.profileData.detailsProvided) {

        // scroll to Stripe if address and professions are filled and Stripe require attention
        if (this.profileData.address
          && this.profileData.professions.length > 0
          && this.stripeCompletionCheck?.completed === false) {
          this.settingsDialogHighlight = 'stripe'
        }
        // open the settings dialog
        this.settingsDialogVisible = true
      }
    })
  }

  /**
   * Checks whether the Stripe Account is completed.
   */
  private async checkStripeCompleted(): Promise<void> {
    if (this.canBeOrdered) {
      await this.call(async () => {
        this.stripeCompletionCheck = await firstValueFrom(this.callCheckPerformerAccountCompleted())
        if (this.stripeCompletionCheck) {
          setTimeout(() => {
            this.completionService.closeCompletionItemAndCheck()
          }, 50)
        }

        if (this.isMessage(this.ServerMessage.STRIPE_ACCOUNT_NOT_FOUND)) {
          this.stripeCompletionCheck = null
          this.resetApi()
        }
      })
    }
  }

  /**
   * Calls the server API to check whether the {@link data} profile has completed his Stripe account.
   */
  private callCheckPerformerAccountCompleted(): Observable<StripeCompletionCheckResp> {
    return this.unwrap(this.stripeService.callCheckAccountCompleted())
  }

  /**
   * Shows settings dialog by {@link settingsDialogVisible} property.
   */
  showSettingsDialog(e?: ShowProfileSettingsDialogEvent): void {
    this.settingsDialogHighlight = e?.highlightSetting
    this.settingsDialogVisible = e?.show
  }

  ngOnDestroy(): void {
    // disable the navbar auto-hide feature
    this.navbarService.setAutoHideEnabled(false)
    this.navbarSub?.unsubscribe()
    this.currentProfileSub?.unsubscribe()
    this.paramsSub?.unsubscribe()
    this.metadataService.setDefaultMetatags()
    this.metaService.onProfilePreview(null)
  }
}

/**
 * Defines a structure of the show and scroll ProfileSettingsDialog options.
 */
export interface ShowProfileSettingsDialogEvent {
  show: boolean
  highlightSetting: ProfileSettingsDialogHighlight
}
