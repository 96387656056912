import {environment} from '../../environments/environment'

/**
 * Throws a new error to the console, that the {@link componentSelectorName} doesn't have the {@link inputName} initialized!
 * - The format is: `The 'Component' has not the [input] input initialized!`
 */
export function throwInputNotInited(componentSelectorName: string, inputName: string): void {
  if (!environment.production) {
    throw new Error(`The '<${componentSelectorName.toLowerCase()}>' has not the [${inputName}] input initialized!`)
  }
}

/**
 * Throws an error for a given {@link component} and {@link message} in a pretty format.
 */
export function throwAppError(component: string, message: string): void {
  if (!environment.production) {
    const suffix = component.endsWith('Component') ? '' : 'Component'
    throw new Error(`[${component}]: ${message}${suffix}`)
  }
}
