<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [save]="modifyOrderAcceptance.bind(this)"
            [disableSaveOption]="form.invalid"
            [successMessage]="isReject ? trans.rejected_success_msg : trans.accepted_success_msg"
            [saveLabel]="isReject ? trans.reject : trans.accept"
            (saved)="reload()"
            [hold]="contractPreviewVisible"
            url="reply"
            [type]="isReject ? 'danger' : 'save'"
            saveIcon="fa-solid {{isReject ? 'fa-heart-crack' : 'fa-check'}}"
            [discardLabel]="isReject ? trans.discard_reject : trans.discard_accept"
            [header]="isReject ? trans.header_reject : trans.header_accept">

  <!--If the reject button is clicked, the info dialog is displayed instead of the reply dialog-->
  <div *ngIf="isReject">
    <section>
      <!--Unpaid Order-->
      <ng-template [ngIf]="!isPaid">
        <!--Author-->
        <div *ngIf="isAuthor && profileAccepted" i18n>
          You are going to cancel the this order, that has been accepted by {{order.profile.displayName}}.
          Tell the other side why you are going to do that.
        </div>
        <!--Profile-->
        <div *ngIf="!isAuthor || (isAuthor && !profileAccepted)" i18n>
          You are going to cancel this order. Tell the other side why you are going to do this.
        </div>
      </ng-template>

      <!--Paid Order-->
      <ng-template [ngIf]="isPaid">
        <div i18n>You are going to cancel this already-paid order.</div>

        <div *ngIf="isAuthor" i18n>
          Since the order has not started yet, it will be finished without any special action.
          Take in mind, that <b>{{order.profile.displayName}}</b> will get up to 30% as compensation for this
          canceled order.
        </div>

        <!--Profile-->
        <div *ngIf="!isAuthor" i18n>
          Your profile will be penalized for this action and you won't receive new orders for a while.
        </div>
      </ng-template>

      <div i18n>This action cannot be undone and closes this order.</div>
    </section>


    <form [formGroup]="form" class="p-mt-2">
      <app-text-input label="Cancel Reason"
                      i18n-label
                      inputType="text"
                      placeholder="Please let the other side know why you are canceling this order."
                      i18n-placeholder
                      formFieldName="cancelReason"
                      icon="fa-solid fa-heart-crack"
                      [form]="form"
                      [textArea]="true"
                      [notContact]="true"
                      [length]="{max: Restrictions.MAX_PROFILE_ORDER_CANCEL_REASON_LENGTH}"
                      [rows]="3">

        <!--BE-CHECK-->
        <app-backend-validation [serverMessages]="serverMessages"
                                [check]="ServerMessage.PROFILE_ORDER_ACCEPTANCE_ORDER_STARTED" i18n>
          This order has already started, you are not allowed to reject it right now.
        </app-backend-validation>
      </app-text-input>
    </form>
  </div>

  <!--Only for orderable profiles, ACCEPT-->
  <div *ngIf="!isReject">

    <!--Hint-->
    <section i18n>
      Leave some message for the customer.
      <span *ngIf="additionalPrice > 0">Since you have adjusted the price of the order, you can also tell the customer why.</span>
      By accepting this order, the customer will have {{Restrictions.MAX_PROFILE_ORDER_AUTHOR_PAYMENT_DAYS * 24}} hours
      to pay for this order. If they do so, you'll get access to contact them via chat. Also, you will be notified
      about each action.
    </section>

    <!--Order details-->
    <section *ngIf="order.description" class="order-details rounded-small p-p-3 w-100 p-mt-2">
      <b i18n>Order Details</b>
      <p>{{order.description}}</p>
    </section>

    <!--Reply Text-->
    <form [formGroup]="form" class="p-mt-2">
      <app-text-input label="Your Reply"
                      i18n-label
                      inputType="text"
                      formFieldName="replyMessage"
                      icon="fa-regular fa-comments"
                      [form]="form"
                      [textArea]="true"
                      [notContact]="true"
                      [length]="{max: Restrictions.MAX_PROFILE_ORDER_PROFILE_REPLY_LENGTH}"
                      [rows]="6"></app-text-input>
      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.PROFILE_ORDER_ADDITIONAL_PRICE_NOT_VALID"
                              [showAsBox]="true" i18n>
        The modified price cannot be lower than the original price.
      </app-backend-validation>
    </form>

    <!--Your adjusted profit-->
    <div class="p-d-flex p-jc-between" *ngIf="additionalPrice > 0">
      <div i18n>Adjusted by</div>
      <div>{{additionalPrice | price}}</div>
    </div>

    <!--Your profit summary-->
    <div class="p-d-flex p-jc-between">
      <div class="p-text-bold" i18n>Your profit</div>
      <div>{{(order.orderTotal + additionalPrice) | price}}</div>
    </div>

    <!--Contract preview hint-->
    <app-hint *ngIf="!order.profileIsLegalEntity"
              [collapsableHint]="false"
              styleClass="p-mt-2" i18n>Since you are performing without a registered legal entity, by clicking on the
      <b>{{trans.accept}}</b> button, you accept the following
      <a class="p-text-bold contract-link text-link" (click)="contractPreviewVisible = true">contract</a> with the
      customer.
    </app-hint>
  </div>

  <!--Global BE-CHECK-->
  <div class="p-mt-2">
    <app-backend-validation [check]="ServerMessage.CONTACT_NOT_ALLOWED"
                            [serverMessages]="serverMessages" [showAsBox]="true" i18n>
      The field cannot contain any contact.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages"
                            [check]="ServerMessage.PROFILE_ORDER_REJECTED"
                            [showAsBox]="true" i18n>
      This order has been already rejected.
    </app-backend-validation>

    <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_ORDER_IN_DISPUTE"
                            [showAsBox]="true" i18n>
      This order is in a dispute process.
    </app-backend-validation>
  </div>
</app-dialog>

<!--Contract Preview for Orderable profiles-->
<app-contract-preview *ngIf="contractPreviewVisible"
                      [(show)]="contractPreviewVisible"
                      [orderTotal]="order.orderTotal + additionalPrice"
                      [order]="order"></app-contract-preview>
