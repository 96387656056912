import {NgModule} from '@angular/core'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AppModule} from './app.module'
import {AppComponent} from './app.component'

/**
 * This module is used for browser side rendering.
 * It extends the AppModule and import the BrowserAnimationsModule to render app with animations.
 */
@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppModule
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {
}
