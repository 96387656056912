import {PixelConfiguration, PixelEventName, PixelEventProperties} from './pixel.models'
import {Inject, Injectable, Optional, Renderer2, RendererFactory2} from '@angular/core'
import {NavigationEnd, Router} from '@angular/router'
import {DOCUMENT} from '@angular/common'
import {filter} from 'rxjs/operators'
import {PLATFORM_BROWSER} from '../../../app.module'

/**
 * Meta pixel tracking function.
 */
declare const fbq: any

/**
 * Based on the {@link https://github.com/NielsCodes/ngx-pixel/blob/main/projects/pixel/src/lib/pixel.service.ts}.
 */
@Injectable({
  providedIn: 'root'
})
export class PixelService {

  private doc: Document
  private renderer: Renderer2
  /**
   * Default configuration for the Meta pixel.
   */
  private config: PixelConfiguration = {
    enabled: false,
    pixelId: null
  }

  constructor(
    @Inject(DOCUMENT) private injectedDocument: any,
    @Optional() private router: Router,
    private rendererFactory: RendererFactory2
  ) {

    // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
    // It is therefore injected as any and then cast to Document
    this.doc = injectedDocument as Document
    this.renderer = rendererFactory.createRenderer(null, null)

    if (router) {
      // Log page views after router navigation ends
      router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {

        if (this.isLoaded()) {
          this.track('PageView')
        }

      })
    }

  }

  /**
   * Initialize the Pixel tracking script
   * - Adds the script to page's head
   * - Tracks first page view
   */
  initialize(pixelId = this.config.pixelId): void {
    if (this.isLoaded()) {
      console.warn('Tried to initialize a Pixel instance while another is already active. Please call `remove()` before initializing a new instance.')
      return
    }
    this.config.enabled = true
    this.addPixelScript(pixelId)
  }

  /** Remove the Pixel tracking script */
  remove(): void {
    this.removePixelScript()
    this.config.enabled = false
  }

  /**
   * Track a Standard Event as predefined by Meta
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/reference Meta Pixel docs - reference}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  track(
    eventName: PixelEventName,
    properties?: PixelEventProperties
  ): void {
    if (!PLATFORM_BROWSER) {
      return
    }

    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.')
      return
    }

    if (properties) {
      properties.currency = 'EUR'
      fbq('track', eventName, properties)
    } else {
      fbq('track', eventName)
    }

  }

  /**
   * Track a custom Event
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-conversions Meta Pixel docs - custom conversions}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  trackCustom(eventName: PixelCategory, properties?: object): void {
    if (!PLATFORM_BROWSER) {
      return
    }

    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.')
      return
    }

    if (properties) {
      properties['currency'] = 'EUR'
      fbq('trackCustom', eventName, properties)
    } else {
      fbq('trackCustom', eventName)
    }
  }

  /**
   * Adds the Meta Pixel tracking script to the application
   * @param pixelId The Meta Pixel ID to use
   */
  private addPixelScript(pixelId: string): void {
    if (!PLATFORM_BROWSER) {
      return
    }

    const pixelCode = `
    var pixelCode = function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');`


    const scriptElement = this.renderer.createElement('script')
    this.renderer.setAttribute(scriptElement, 'id', 'pixel-script')
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript')
    this.renderer.setProperty(scriptElement, 'innerHTML', pixelCode)
    this.renderer.appendChild(this.doc.head, scriptElement)

    // Inivisble 1x1 pixel
    const pixel = this.renderer.createElement('img')
    this.renderer.setAttribute(pixel, 'height', '1')
    this.renderer.setAttribute(pixel, 'width', '1')
    this.renderer.setAttribute(pixel, 'style', 'display:none')
    this.renderer.setAttribute(pixel, 'src', `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`)

    const noscript = this.renderer.createElement('noscript')
    this.renderer.appendChild(noscript, pixel)
    this.renderer.appendChild(this.injectedDocument.head, noscript)
  }

  /** Remove Meta Pixel tracking script from the application */
  private removePixelScript(): void {
    if (!PLATFORM_BROWSER) {
      return
    }
    const pixelElement = this.doc.getElementById('pixel-script')
    if (pixelElement) {
      pixelElement.remove()
    }
  }

  /** Checks if the script element is present */
  private isLoaded(): boolean {
    if (PLATFORM_BROWSER) {
      const pixelElement = this.doc.getElementById('pixel-script')
      return !!pixelElement
    }
    return false
  }

}

/**
 * All trackable categories for the Meta Pixel.
 */
export enum PixelCategory {
  ORDER_SENT = 'OrderSent',
  ADD_TO_CART = 'AddToCart',
  FILTER = 'Filter',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PRICE_ITEM_CATEGORY = 'PriceItemCategory',
  PROFILE_PREVIEW = 'ProfilePreview',
  PROFILE_TYPE = 'ProfileType'
}
