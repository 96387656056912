<a [routerLink]="navigation.urlProfile(data.charId)"
   (click)="clicked.emit(data)"
   pRipple>
  <div class="wrapper p-d-flex p-jc-center">
    <div class="component-wrapper">

      <!--Wallpaper-->
      <p-carousel *ngVar="wallpapers.length > 1 as multiple"
                  [value]="wallpapers"
                  [numVisible]="1"
                  [numScroll]="1"
                  [showIndicators]="false"
                  [showNavigators]="false"
                  [autoplayInterval]="(multiple) ? 5 * 1000 : null"
                  [circular]="multiple">

        <ng-template let-item pTemplate="item">
          <div class="wallpaper-item">
            <app-img [src]="item"
                     [background]="true"
                     [baseZIndex]="5"
                     styleClass="fit-image wallpaper-item"
                     alt="{{data.displayName}}'s wallpaper"
                     i18n-alt></app-img>
          </div>
        </ng-template>
      </p-carousel>

      <!--Content-->
      <section class="content p-p-2">

        <!--User name and avatar-->
        <div class="p-d-flex">
          <!--Avatar-->
          <app-avatar [sizePx]="50"
                      [profile]="data"
                      class="p-mr-2"
                      [redirect]="false">
          </app-avatar>

          <header class="p-d-flex p-flex-column word-break l-count-1">
            <app-profile-names [charIdVisible]="false"
                               [redirect]="false"
                               [profile]="data"
                               [displayNameLinesCount]="1"
                               [size]="4"></app-profile-names>

            <!--Stars-->
            <div class="stars p-d-flex p-ai-baseline">
              <app-rating [stars]="data.rating.avg"></app-rating>
              <span class="stars-count">({{ data.rating.ratingCount }}x)</span>
            </div>

          </header>
        </div>

        <!--Professions-->
        <div class="p-mt-2">
          <app-profile-professions type="text"
                                   lineCount="l-count-1"
                                   styleClass="info"
                                   [data]="data"></app-profile-professions>
        </div>

        <!--Address-->
        <div class="info p-d-flex p-ai-center">
          <i class="fa-solid fa-globe-europe p-mr-2"></i>
          <span class="word-break l-count-1">{{ data.address?.city || trans.unspecified }}</span>
        </div>

        <!--Bio-->
        <div class="p-d-flex p-my-2 bio">
          <article class="l-count-3 text-small" *ngIf="data?.bio">{{ data?.bio }}</article>
          <app-no-content *ngIf="!data?.bio" styleClass="bio-empty p-d-flex p-jc-center" class="w-100"
                          pTooltip="This profile has not provided any further information yet." i18n-pTooltip i18n>
            No details
          </app-no-content>
        </div>

      </section>

      <!--Price-->
      <div class="price-bar p-d-flex p-jc-center p-ai-baseline p-py-1 p-px-2 l-count-1">
        <span *ngIf="data?.minPrice" i18n>From <b>{{ data.minPrice | price }}</b></span>
        <span *ngIf="!data.minPriceFixed" class=" p-ml-1 per-hour" i18n>/ hour</span>
        <b *ngIf="!data?.minPrice" i18n>Free</b>
      </div>
    </div>
  </div>
</a>
