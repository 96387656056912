/**
 * The enum of available user settings.
 * Used in these components:
 * - {@link SettingsComponent}
 * - {@link AdminDashboardOrdersComponent}
 */
export enum SettingCategory {
  ACCOUNT = 'account',
  DEVICES = 'devices',
  LOGIN = 'login',
  PASSWORD = 'password',
  PHONE = 'phone',
  NOTIFICATIONS = 'notification',
  EMAILS = 'emails',
  DELETE_DEACTIVATE_ACCOUNT = 'delete_deactivate_account'
}
