<div>
  <div *ngIf="bubbleVisible" [@fade]="bubbleVisible" class="bubble">
    <div *ngIf="!downloading && profileMapData; else skeleton">
      <app-profile-bubble [data]="profileMapData"></app-profile-bubble>
    </div>
  </div>

  <div *ngIf="!bubbleOnly" class="container"
       (click)="onClick();"
       [class.not-live]="!data.isLive">
    <div class="pulse p-d-flex p-jc-center p-ai-center">
      <div class="p-d-flex p-ai-center p-jc-center content box-shadow"
           [style]="'background-color: '+markerColor+';'"
           *ngVar="data?.profile as profile">
        <img [src]="profile | avatar: '50'"
             [alt]="profile.charId + ' avatar'"
             [class.grayscale]="!data?.isNowOpen">

        <!--Incoming event-->
        <i *ngIf="data?.hasEvents" class="fa-solid fa-user incoming box-shadow"></i>
      </div>
    </div>
  </div>
</div>

<!--Skeleton template-->
<ng-template #skeleton>
  <div class="downloading rounded-medium box-shadow p-p-3 p-d-flex">
    <!--Wallpaper-->
    <p-skeleton height="100%" width="8rem" styleClass="p-mr-3"></p-skeleton>
    <!--Content-->
    <div class="w-100">
      <p-skeleton height="1.2rem" width="8rem" styleClass="p-mb-2"></p-skeleton>
      <div class="p-d-flex p-mb-2">
        <p-skeleton height="1rem" width="6rem" styleClass="p-mr-2"></p-skeleton>
        <p-skeleton height="1rem" width="6rem"></p-skeleton>
      </div>
      <p-skeleton height="10rem" width="100%" styleClass="p-mb-2"></p-skeleton>
    </div>

  </div>
</ng-template>
