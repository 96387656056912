<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            header="Edit your profile location"
            i18n-header
            url="location"
            successMessage="Your profile location has been saved"
            i18n-successMessage
            [saveLoading]="loading"
            (discard)="closeEditComponent()"
            [save]="onComponentSave.bind(this)"
            [disableSaveOption]="!mapLocation || addressForm.invalid || (dateTimeForm && dateTimeForm.invalid)">
  <div>
    <!--Preview-->
    <div class="preview p-d-flex p-py-3">
      <!--Avatar-->
      <div class="p-d-flex p-ai-center">
        <app-avatar [profile]="data"
                    [redirect]="false"
                    [showTooltip]="false"
                    sizePx="80"></app-avatar>
      </div>

      <!--Information-->
      <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-center p-ai-center p-jc-sm-start p-ml-4">
        <div>
          <h1 class="display-name l-count-1">{{data.displayName}}</h1>
          <div *ngIf="(hostProfile || data.address || addressForm.controls.line1.value); else noAddress">
            <div *ngIf="hostProfile; else postalInfo">
              <div class="host-profile p-p-3 p-m-1 rounded-medium" [appRedirectProfile]="hostProfile.charId">
                <div class="p-d-flex p-ai-center">
                  <!--Host Avatar-->
                  <div class="p-mr-3">
                    <app-avatar [profile]="hostProfile"
                                [redirect]="false"
                                [showTooltip]="true"
                                sizePx="50"></app-avatar>
                  </div>
                  <!--Host Names-->
                  <div>
                    <h2>
                      <app-profile-icon [profile]="hostProfile"></app-profile-icon>
                      {{hostProfile.displayName}}
                    </h2>
                    <h3 class="p-d-inline-flex p-ai-center">
                      <i class="fa-solid fa-users p-mr-2"></i>
                      {{hostProfile.charId}}
                    </h3>
                  </div>
                </div>
                <!--Host Address-->
                <div class="host-address">
                  <div class="word-break p-mt-2">
                    <h2>{{hostProfile.address.line1}}</h2>
                    <h2 *ngIf="hostProfile.address.line2">{{hostProfile.address.line2}}</h2>
                    <h2>{{hostProfile.address.postalCode}} {{hostProfile.address.city}}</h2>
                    <h3>{{hostProfile.address.state}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #postalInfo>
              <div *ngVar="hasFeatures(data.profileType, Feature.BE_ORDERED) as orderable">
                <h2 *ngIf="!orderable">{{addressForm.controls.line1.value}}</h2>
                <h2 *ngIf="!orderable && addressForm.controls.line2.value">{{addressForm.controls.line2.value}}</h2>
                <h2>{{addressForm.controls.postalCode.value}} {{addressForm.controls.city.value}}</h2>
                <h3>{{(addressForm.controls.country.value | country)?.name}}</h3>
              </div>
            </ng-template>
          </div>

          <ng-template #noAddress>
            <app-no-content i18n>
              Your profile has not any address yet.
            </app-no-content>
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="!hostProfile" [@fade]="!hostProfile">
      <!--Hint-->
      <app-hint i18n>
        Specify the location from which you primarily want to receive orders.<br>
        <b>Only the City will be visible publicly</b>.
        <br>Also, you can change your location as you travel anytime.
      </app-hint>

      <!--Postal information-->
      <div class="p-mt-3" [class.postal-margin-bottom]="data.profileType !== ProfileType.EVENT">
        <form [formGroup]="addressForm">

          <div class="p-grid">

            <!--Line 1-->
            <app-text-input label="Line 1"
                            i18n-label
                            icon="fa-solid fa-house-flag"
                            inputType="text"
                            autocomplete="address-line1"
                            formFieldName="line1"
                            placeholder="Street 12/5"
                            i18n-placeholder
                            hint="Provide a street name to get a more accurate position."
                            i18n-hint
                            class="p-col-12 p-sm-6 p-py-0"
                            [form]="addressForm"
                            [required]="true"
                            [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

            <!--Line 2-->
            <app-text-input label="Line 2"
                            i18n-label
                            icon="fa-solid fa-envelopes-bulk"
                            inputType="text"
                            autocomplete="address-line2"
                            formFieldName="line2"
                            placeholder="Building C (Optional)"
                            i18n-placeholder
                            class="p-col-12 p-sm-6 p-py-0"
                            [form]="addressForm"
                            [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

            <!--City-->
            <app-text-input label="City"
                            i18n-label
                            icon="fa-solid fa-building-flag"
                            inputType="text"
                            formFieldName="city"
                            class="p-col-12 p-sm-6 p-py-0"
                            [form]="addressForm"
                            [required]="true"
                            [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>

            <!--Postal Code-->
            <app-text-input label="Postal Code"
                            i18n-label
                            icon="fa-solid fa-barcode"
                            inputType="text"
                            formFieldName="postalCode"
                            autocomplete="postal-code"
                            placeholder="12345"
                            i18n-placeholder
                            class="p-col-12 p-sm-6 p-py-0"
                            [form]="addressForm"
                            [onlyNumbers]="true"
                            [required]="true"
                            [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"></app-text-input>


            <!--State & Country-->
            <app-dropdown label="State"
                          i18n-label
                          [options]="supportedCountries"
                          formFieldName="country"
                          class="p-col-12 p-py-0"
                          [form]="addressForm"
                          [length]="{max: Restrictions.MAX_ADDRESS_FIELD_LENGTH}"
                          [required]="true">
              <!--Selected-->
              <ng-template let-selected pTemplate="selectedItem">
                <div class="p-d-flex p-ai-center">
                  <i [class]="selected.flag" class="p-mr-2 p-ml-3"></i>
                  <div>{{selected.name}}</div>
                </div>
              </ng-template>

              <!--Item-->
              <ng-template let-type pTemplate="item">
                <div class="p-d-flex p-ai-center">
                  <i [class]="type.flag" class="p-mr-2"></i>
                  <div>{{type.name}}</div>
                </div>
              </ng-template>
            </app-dropdown>
          </div>
        </form>

        <!--Global backend validation-->
        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.ADDRESS_FIELD_TOO_LONG" i18n>
          Some fields exceed {{Restrictions.MAX_ADDRESS_FIELD_LENGTH}} characters.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.HOST_PROFILE_NOT_FOUND" i18n>
          The host profile not found.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_BELONGS_TO_USER"
                                i18n>
          You are not permitted to this operation!
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_NOT_FOUND" i18n>
          Your profile does not exist.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_CANNOT_BE_HOSTED" i18n>
          This profile can not be hosted.
        </app-backend-validation>

        <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PROFILE_HOST_CANNOT_HOST" i18n>
          The {{hostProfile?.displayName}} can not host your {{(this.data.profileType | profileType).toLowerCase()}}.
        </app-backend-validation>

        <!--Action Buttons-->
        <div class="p-d-flex p-jc-between p-py-3">
          <app-button type="danger-light"
                      icon="fa-solid fa-xmark"
                      label="Clear All"
                      i18n-label
                      [disabled]="loading"
                      (clicked)="onClearClicked()"></app-button>

          <app-button *ngIf="addressForm.valid && (cityOnlyLocation || mapLocation) && mapVisible"
                      type="secondary"
                      icon="fa-solid fa-arrow-down"
                      (clicked)="scrollBottom()"></app-button>
        </div>

        <!--Location couldn't be find-->
        <div *ngVar="addressForm.value as d">
          <!--Location couldn't be find-->
          <div *ngIf="isMessage(ServerMessage.PROFILE_ORDER_LOCATION_MISSING) && !mapLocation" @grow
               class="failed-box p-mt-2">
            <i class="fa-solid fa-map-location-dot p-mr-2"></i>
            <div>
              <span i18n>
                We were <b>not able to verify your address</b>. Please verify the input fields.
              </span>
              <span i18n *ngIf="addressForm.value.postalCode">
                If you are sure about your address, you can <b>click on a map</b> to <b>specify your location</b>.
              </span>
            </div>
          </div>
        </div>


        <!--Map information-->
        <div class="map-container rounded-medium p-py-3 p-mb-6"
             *ngIf="(cityOnlyLocation || mapLocation) && mapVisible"
             @grow>
          <!--Description-->
          <!--<p class="field-description" *ngIf="!hostProfile; else hostedProfile" [@fade]="!hostProfile" i18n>
            Please check your location and postal information twice to avoid any misunderstandings from your visitors.
          </p>-->
          <!--Map-->
          <app-map [newMarker]="data"
                   [newMarkerPos]="mapLocation || originalMapLocation"
                   (newMarkerAdded)="updateLocation($event)"
                   [center]="mapLocation || cityOnlyLocation"
                   [resetButton]="mapLocation !== originalMapLocation && !!originalMapLocation"
                   (resetClicked)="updateLocation(originalMapLocation)"
                   [explore]="false"
                   [filterButton]="false"
                   styleClass="rounded-medium"
                   [search]="false"></app-map>
        </div>
        <div class="bottom-map"></div>
      </div>
    </div>

    <!--DateTime form for EVENTs-->
    <div *ngIf="data.profileType === ProfileType.EVENT" class="time-date-info">
      <hr [class.line]="addressForm.valid">
      <form [formGroup]="dateTimeForm">

        <!--DATE-->
        <div class="p-grid">
          <!--Start Date-->
          <div class="p-col-12 p-sm-6 p-p-0">
            <app-date-input label="Start Date"
                            i18n-label
                            icon="fa-solid fa-calendar-xmark"
                            formFieldName="startDate"
                            [form]="dateTimeForm"
                            styleClass="time-date-input p-pr-0 p-pr-sm-3"
                            [minDate]="todayDate"
                            appendTo="body"
                            [required]="true"></app-date-input>
          </div>

          <!--End Date-->
          <div class="p-col-12 p-sm-6 p-p-0">
            <app-date-input label="Finish Date"
                            i18n-label
                            icon="fa-solid fa-calendar-check"
                            formFieldName="endDate"
                            [form]="dateTimeForm"
                            styleClass="time-date-input"
                            [minDate]="todayDate"
                            appendTo="body"
                            [required]="true"></app-date-input>
          </div>
        </div>

        <!--TIME-->
        <div class="p-grid">

          <!--Start Time-->
          <div class="p-col-12 p-sm-6 p-p-0">
            <app-date-input label="Start Time"
                            i18n-label
                            icon="fa-solid fa-play"
                            formFieldName="startTime"
                            styleClass="p-pr-0 p-pr-sm-3"
                            [defaultDate]="defaultStartTime"
                            [form]="dateTimeForm"
                            [timeOnly]="true"
                            [required]="true"></app-date-input>
          </div>

          <!--Finish Time-->
          <div class="p-col-12 p-sm-6 p-p-0">
            <app-date-input label="Finish Time"
                            i18n-label
                            icon="fa-solid fa-flag-checkered"
                            formFieldName="endTime"
                            [defaultDate]="defaultEndTime"
                            [form]="dateTimeForm"
                            [timeOnly]="true"
                            [duration]="{min: Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH}"
                            [required]="true">
              <!--BE-CHECK-->
              <app-backend-validation [serverMessages]="serverMessages"
                                      [check]="ServerMessage.CALENDAR_ITEM_DATES_INCORRECT" i18n>
                The finish time must be at least {{Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH | date: 'mm'}} min
                later.
              </app-backend-validation>
            </app-date-input>
          </div>
        </div>

      </form>
    </div>

    <ng-template #hostedProfile>
      <!--Profile needs to be accepted-->
      <div *ngVar="data.profileType | profileType | lowercase as type">
        <p *ngIf="!data.hostProfile
        || data.hostProfile.host.profileId !== hostProfile.profileId
        || data.hostProfile.hostAcceptance !== Acceptance.ACCEPTED
        || data.hostProfile.acceptance !== Acceptance.ACCEPTED; else hostAccepted" i18n>
          Your <b>{{data.displayName}}</b> {{type}} location is set under the
          <b>{{hostProfile.charId}}</b> {{hostProfile.profileType | profileType | lowercase}}
          and needs to be accepted by the second profile.<br> After the successful confirmation, your {{type}} will be
          publicly available.</p>

        <!--Profile is visible publicly-->
        <ng-template #hostAccepted>
          <p i18n>The <b>{{hostProfile.charId}}</b> has accepted your {{type}}. Now you are publicly available.</p>
        </ng-template>
      </div>
    </ng-template>
  </div>
</app-dialog>


<!--Skeleton templates-->
<ng-template #skeleton>
  <div class="p-d-flex p-flex-column p-ai-center p-flex-sm-row p-jc-sm-start p-mb-4">
    <p-skeleton
      shape="circle"
      size="5rem"
      styleClass="p-mr-2 p-mb-2"
    ></p-skeleton>

    <div class="p-d-flex p-flex-column">
      <p-skeleton width="10rem" height="2rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="15rem" height="1.5rem"></p-skeleton>
    </div>
  </div>

  <p-skeleton width="50%" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
  <p-skeleton width="100%" height="20rem" styleClass="p-mb-4"></p-skeleton>

  <p-skeleton width="10rem" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
  <p-skeleton width="60%" height="3rem" styleClass="p-mb-4"></p-skeleton>

  <div *ngFor="let i of [1,2,3,4,5]">
    <p-skeleton width="10rem" height="1.5rem" styleClass="p-mb-1"></p-skeleton>
    <p-skeleton width="15rem" height="1rem" styleClass="p-mb-3"></p-skeleton>
  </div>
</ng-template>
