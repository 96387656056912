import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {Location} from '@angular/common'
import {MenuItem} from 'primeng/api'
import {EditableComponent} from '../../../component/abstract/editable.component'
import {ActivatedRoute} from '@angular/router'
import {firstValueFrom, Subscription} from 'rxjs'
import {NavigationService} from '../../../service/ui/navigation.service'

@Component({
  selector: 'app-admin-attribute-editor',
  templateUrl: './admin-attribute-editor.component.html',
  styleUrl: './admin-attribute-editor.component.scss'
})
export class AdminAttributeEditorComponent extends EditableComponent implements OnInit, OnDestroy {

  /**
   * Controls the visibility of the sidebar on small screens.
   */
  settingsSidebarVisible = false

  /**
   * All available settings.
   */
  menuItems: MenuItem[] = [
    {id: AdminProfileAttributeTypes.PROFESSIONS, title: $localize`Professions`, icon: 'fa-solid fa-user-tie'},
    {id: AdminProfileAttributeTypes.SKILLS, title: $localize`Skills`, icon: 'fa-solid fa-user-graduate'},
    {id: AdminProfileAttributeTypes.GENRES, title: $localize`Genres`, icon: 'fa-solid fa-signs-post'},
  ]
  /**
   * The current selected setting item.
   */
  selectedMenuItem: MenuItem
  /**
   * The current url params subscription.
   */
  urlChangeSub?: Subscription

  PAT: typeof AdminProfileAttributeTypes = AdminProfileAttributeTypes

  constructor(
    public changeRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location) {
    super()
  }

  ngOnInit(): void {
    this.onSelectedMenuItem(this.menuItems[0])

    // URL Changes
    this.urlChangeSub = this.route.params.subscribe(async () => {
      await this.parseURL()
      this.changeRef.detectChanges()
    })
  }

  /**
   * Fires when the user clicked on the {@link item} item.
   */
  onSelectedMenuItem(item: MenuItem): void {
    this.menuItems.forEach(it => {
      it['selected'] = undefined
    })
    item['selected'] = true

    // change URL
    this.location.go(`${NavigationService.ADMIN}/${NavigationService.ADMIN_PROFILE_ATTRIBUTES}/${item.id}`)
    this.selectedMenuItem = item
    this.settingsSidebarVisible = false
  }

  /**
   * Parses the url of the setting.
   */
  private async parseURL(): Promise<void> {
    const params = await firstValueFrom(this.route.params)
    const category = params?.[NavigationService.ADMIN_PROFILE_ATTRIBUTES_TYPE] || AdminProfileAttributeTypes.PROFESSIONS
    this.selectType(category)
  }

  /**
   * Selects an appropriate item based on the {@link AdminProfileAttributeTypes}.
   */
  private selectType(type: AdminProfileAttributeTypes): void {
    for (const it of this.menuItems) {
      if (it.id === type) {
        this.onSelectedMenuItem(it)
        return
      }
    }
  }

  ngOnDestroy(): void {
    this.urlChangeSub?.unsubscribe()
  }
}

/**
 * Skills, professions and genres are considered as profile attributes.
 * This enum represents these types for the administration purposes.
 */
export enum AdminProfileAttributeTypes {
  PROFESSIONS = 'professions',
  SKILLS = 'skills',
  GENRES = 'genres',
}
