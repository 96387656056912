import {ApiService} from '../api.service'
import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {notificationsNotSupported} from '../../utils/navigator.utils'

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends ApiService {

  /**
   * - The current demanding permission.
   * ## Don't use next() directly! Use the {@link requestPermission} function instead.
   * - null represents, of course, none demanding permission but has also a notifying functionality,
   * that the custom UI dialog has been closed.
   */
  requiredPermission = new BehaviorSubject<PermissionRequest>(null)

  /**
   * Notification permission request with custom message targeting orders and chat.
   */
  private notificationPermissionRequest: PermissionRequest = {
    name: 'notifications',
    title: $localize`Notifications`,
    reason: $localize`Feel free to allow notification in your device to stay informed about order updates and new chat messages.`
  }

  /**
   * Requests a permission to the user.
   * @param permission The specific permission identifier.
   * @returns PermissionStatus of a user decision.
   */
  requestPermission(permission: PermissionRequest): Promise<PermissionState> {
    const currentPermission = this.requiredPermission.getValue()
    if (currentPermission && currentPermission.name !== permission.name) {
      console.error('Requesting more permissions at once!')
      return
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<PermissionState>(async (resolve) => {
      const state = (await this.getState(permission.name)).state
      if (state === 'denied' || state === 'prompt') {
        this.requiredPermission.next(permission)

        // wait for acceptance
        const sub = this.requiredPermission.subscribe(async (p) => {
          if (!p) {
            sub?.unsubscribe()
            resolve((await this.getState(permission.name)).state)
          }
        })
      } else {
        resolve(state)
      }
    })
  }

  /**
   * Asking for notification permission with custom {@link notificationPermissionRequest}.
   */
  askNotificationPermission(delay: number = 2.5 * 1000): void {
    if (notificationsNotSupported()) {
      return
    }

    setTimeout(() => {
      this.requestPermission(this.notificationPermissionRequest)
    }, delay)
  }

  /**
   * Returns the state of the {@link permission}.
   */
  async getState(permission: PermissionName): Promise<PermissionStatus> {
    return await navigator.permissions.query({name: permission})
  }
}

/**
 * The information displayed in the custom UI dialog.
 */
export interface PermissionRequest {
  name: PermissionName
  icon?: string
  reason?: string
  title?: string
  /**
   * The permission dialog will be throwing as much as possible to achieve the granted status.
   */
  required?: boolean
}
