import {Injectable} from '@angular/core'
import {BriefProfileResp} from '../profile.service'

@Injectable({
  providedIn: 'root'
})
export class HtmlService {

  private static readonly PROFILE_ID_ATTRIBUTE = 'pid'
  private static readonly MENTION_CLASS = 'mention'

  constructor() {
  }

  /**
   * Searches for the '<@![1-9]>' tag that contains the profile id and replaces it with an HTML mention tag.
   *
   * @param str Source string.
   * @param profiles Available profiles.
   */
  convertMentionsToHTML(str: string, profiles: BriefProfileResp[] = []): string {
    const mentionReg = /<@![0-9]*>/g
    let match
    do {
      match = mentionReg.exec(str)
      if (match) {
        const tag = match[0]
        const profileId = match[0].slice(3, -1)
        const profile = profiles.find(it => it.profileId === profileId)
        const html = profile ? this.createMentionHtml(profile) : null
        str = str.replace(tag, html || '')
      }
    } while (match !== null)
    return str || ''
  }

  /**
   * Finds all elements by class name of 'mention' and replaces their values with the '<@!{profileId}>' value.
   */
  convertMentionsHtmlToId(editableDiv: HTMLDivElement): void {
    const mentions = editableDiv.getElementsByClassName(HtmlService.MENTION_CLASS)

    for (const mention of mentions[Symbol.iterator]()) {
      const id = mention.getAttribute(HtmlService.PROFILE_ID_ATTRIBUTE)
      mention.innerHTML = `<@!${id}>`
    }
  }

  /**
   * Creates the HTML tag that represents a user mention.
   */
  createMentionHtml(profile: BriefProfileResp): string {
    return `<span contenteditable="false" class="${HtmlService.MENTION_CLASS}" ${HtmlService.PROFILE_ID_ATTRIBUTE}="${profile.profileId}">@${profile.displayName || profile.charId}</span>`
  }

  /**
   * Inserts an HTML string at the caret position.
   * Based on the {@link https://stackoverflow.com/a/6691294/7586486}.
   * This is a very shitty-written code, but it works.
   */
  pasteHtmlAtCaret(html: string, selectPastedContent: boolean = false): void {
    let sel
    let range
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0)
        range.deleteContents()

        // Range.createContextualFragment() would be useful here but is
        // only relatively recently standardized and is not supported in
        // some browsers (IE9, for one)
        const el = document.createElement('span')
        el.innerHTML = html
        const frag = document.createDocumentFragment()
        let node
        let lastNode
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node)
        }
        const firstNode = frag.firstChild
        range.insertNode(frag)

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange()
          range.setStartAfter(lastNode)
          if (selectPastedContent) {
            range.setStartBefore(firstNode)
          } else {
            range.collapse(true)
          }
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }
    }
  }

  /**
   * Gets a position of caret.
   * Based on the {@link https://stackoverflow.com/a/4812022/7586486}.
   * This is a very shitty-written code, but it works.
   */
  getSelectionCharacterOffsetWithin(element): { start: number; end: number } {
    let startPos = 0
    let endPos = 0
    const doc = element.ownerDocument || element.document
    const win = doc.defaultView || doc.parentWindow
    let sel
    if (typeof win.getSelection !== 'undefined') {
      sel = win.getSelection()
      if (sel.rangeCount > 0) {
        const range = win.getSelection().getRangeAt(0)
        const preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(element)
        preCaretRange.setEnd(range.startContainer, range.startOffset)
        startPos = preCaretRange.toString().length
        preCaretRange.setEnd(range.endContainer, range.endOffset)
        endPos = preCaretRange.toString().length
      }
    } else if ((sel = doc.selection) && sel.type !== 'Control') {
      const textRange = sel.createRange()
      const preCaretTextRange = doc.body.createTextRange()
      preCaretTextRange.moveToElementText(element)
      preCaretTextRange.setEndPoint('EndToStart', textRange)
      startPos = preCaretTextRange.text.length
      preCaretTextRange.setEndPoint('EndToEnd', textRange)
      endPos = preCaretTextRange.text.length
    }
    return {start: startPos, end: endPos}
  }
}
