import {Component, Input, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
// import {ZXingScannerComponent} from '@zxing/ngx-scanner'
import {StripeService} from '../../../service/stripe.service'
import {firstValueFrom, Observable} from 'rxjs'
import {QrScannerService} from '../../../service/qr-scanner.service'

@Component({
  selector: 'app-qr-code-reader',
  templateUrl: './qr-code-reader.component.html',
  styleUrls: ['./qr-code-reader.component.scss']
})
export class QrCodeReaderComponent extends EditableComponent implements OnInit {

  // @ViewChild(ZXingScannerComponent)
  // scanner: ZXingScannerComponent
  // selectedDevice: MediaDeviceInfo

  qrCodeScanned: boolean

  @Input()
  data: string
  success: boolean

  async ngOnInit(): Promise<void> {
    await this.openScanner()
  }

  constructor(private paymentService: StripeService,
              private qrCodeService: QrScannerService) {
    super()
  }

  /**
   * Method which finds camera device and starts scanning
   */
  async openScanner(): Promise<void> {
    this.qrCodeScanned = false
    this.resetApi()
    await navigator.mediaDevices.getUserMedia({video: true})
    // const device = await this.scanner.camerasFound
    // this.selectedDevice = device[0]
    // this.scanner.scanStart()
  }

  /**
   * Method which is responsible for authentification of scanned QR code
   */
  async onCodeResult(result: string): Promise<void> {
    this.qrCodeScanned = true
    this.success = await firstValueFrom(this.callGetOrderByQRCode(result))
    if (this.success) {
      // const id = result.split('!')
      //await firstValueFrom(this.callPayoutArtist(Number(id[0])))
    }
  }

  /**
   * Calls API to check if database contains specified Order in QR Code
   */
  callGetOrderByQRCode(resp: string): Observable<boolean> {
    return this.unwrap(this.qrCodeService.callGetOrderByQR(resp))
  }
}
