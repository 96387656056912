<ng-container *ngIf="!phoneVerificationInProgress">
  <h2 class="title p-py-3 p-text-uppercase" i18n>Register with {{ title }}</h2>
  <form [formGroup]="form"
        (keyup.enter)="checkUniqueUserAndVerifyPhone()">

    <!--E-MAIL-->
    <app-text-input label="Email"
                    i18n-label
                    inputType="email"
                    placeholder="john.smith@email.com"
                    i18n-placeholder
                    formFieldName="email"
                    [space]="false"
                    [paste]="false"
                    [copy]="false"
                    [form]="form"
                    [required]="true"
                    [email]="true"
                    [disable]="true"
                    hint="Your login email used for main communication and verification."
                    i18n-hint
                    [length]="{min: Restrictions.MIN_EMAIL_LENGTH}">

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.EMAIL_ALREADY_REGISTERED"
                              i18n>
        E-mail already registered.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.LOGIN_EMAIL_INCORRECT"
                              i18n>
        Not a valid email.
      </app-backend-validation>
    </app-text-input>

    <!--DISPLAY-NAME-->
    <app-text-input label="Display Name"
                    i18n-label
                    inputType="text"
                    placeholder="John Smith"
                    i18n-placeholder
                    formFieldName="displayName"
                    [form]="form"
                    [required]="true"
                    hint="Your real name or pseudonym."
                    i18n-hint
                    [length]="{min: Restrictions.MIN_DISPLAY_NAME_LENGTH, max: Restrictions.MAX_DISPLAY_NAME_LENGTH}">
      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.DISPLAY_NAME_LENGTH_INCORRECT" i18n>
        The length needs to be within {{ Restrictions.MIN_DISPLAY_NAME_LENGTH }}
        - {{ Restrictions.MAX_DISPLAY_NAME_LENGTH }} characters.
      </app-backend-validation>
    </app-text-input>

    <!--USER-NAME-->
    <app-text-input *ngIf="false"
                    label="Nickname"
                    i18n-label
                    inputType="text"
                    placeholder="john.smith"
                    i18n-placeholder
                    formFieldName="charId"
                    [space]="false"
                    [form]="form"
                    [required]="true"
                    [noBlankCharacters]="true"
                    hint="Your unique user name used for your overall identification. Must not contain any blank characters."
                    i18n-hint
                    [length]="{min: Restrictions.MIN_CHAR_ID_LENGTH, max: Restrictions.MAX_CHAR_ID_LENGTH}">
      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" field="charId" error="charId" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages"
                              [check]="ServerMessage.CHAR_ID_ALREADY_EXISTS" i18n>
        This name is already taken.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.CHAR_ID_INCORRECT" i18n>
        Not valid username.
      </app-backend-validation>
    </app-text-input>

    <!--PHONE-->
    <app-text-input label="Phone number"
                    i18n-label
                    placeholder="+4219xxxxxxxx"
                    i18n-placeholder
                    inputType="tel"
                    formFieldName="phone"
                    hint="Used for verification and resolving order disputes."
                    i18n-hint
                    [form]="form"
                    [phone]="true"
                    [required]="true"
                    [noBlankCharacters]="true"
                    [space]="false">
      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_INCORRECT" i18n>
        Not a valid phone number.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.PHONE_ALREADY_EXISTS"
                              i18n>
        The number is already taken.
      </app-backend-validation>
    </app-text-input>

    <!--Legal Documents-->
    <div class="legal-text" i18n>
      By registering through a third party you accept the Umevia
      <a [routerLink]="toLegalDocuments(profileType === ProfileType.USER ? 'terms' : 'terms-art')" target="_blank"
         class="text-link">
        Terms of Services</a> and acknowledge the
      <a [routerLink]="toLegalDocuments('privacy')" target="_blank" class="text-link">Privacy Policy</a> and <a
      [routerLink]="toLegalDocuments('cookies')" target="_blank" class="text-link">Cookie Policy</a>.
    </div>

    <!--Register Button-->
    <app-button type="primary"
                label="Continue"
                i18n-label
                styleClass="w-100 p-mt-4 submit-button"
                icon="fa-solid fa-arrow-right"
                iconPos="right"
                (clicked)="checkUniqueUserAndVerifyPhone()"
                [loading]="saving || registered"
                [disabled]="form.invalid || submitted"></app-button>

    <!--Response-->
    <app-call-response [component]="this"
                       successMsg="You have been successfully registered!"
                       i18n-successMsg
                       successIcon="fa-solid fa-user-plus"
                       errorMsg="Failed to register."
                       i18n-errorMsg
                       styleClass="p-mt-4"
                       errorIcon="fa-solid fa-user-slash"></app-call-response>
  </form>
</ng-container>

<!--Phone verification component-->
<app-phone-verification-embed *ngIf="phoneVerificationInProgress"
                                 [email]="form.get('email').value"
                                 [phone]="form.get('phone').value"
                                 (phoneVerificationResult)="phoneVerificationResult($event)">
</app-phone-verification-embed>
