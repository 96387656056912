<p-dialog [(visible)]="display"
          position="bottomright"
          [modal]="true"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [draggable]="false"
          [style]="{width: '35vw'}">
  <ng-template pTemplate="header">
    <h1 class="p-m-0" i18n>Cookies</h1>
  </ng-template>
  <span i18n>
    By using our site you agree to our use of cookies to deliver a better site experience.
  </span>
  <p i18n>For more information, how cookies works <a href="https://2gdpr.com/cookies" target="_blank">click here</a></p>
  <ng-template pTemplate="footer">
    <!--Manage cookies-->
    <app-button type="secondary"
                label="Manage"
                i18n-label
                icon="fa-solid fa-screwdriver-wrench"
                styleClass="p-mr-2 p-mb-2"
                (clicked)="showDetails()"></app-button>

    <!--Accept all cookies-->
    <app-button type="primary"
                label="Accept All"
                i18n-label
                icon="fa fa-cookie-bite"
                styleClass="p-mr-2 p-mb-2"
                (clicked)="acceptAllCookies()">
    </app-button>
  </ng-template>
</p-dialog>

<!--Shows detailed info-->
<app-dialog-cookies-details *ngIf="detailedCookiesDialogVisible"
                            [(show)]="detailedCookiesDialogVisible"
                            [cookiesVersion]="cookiesVersion"></app-dialog-cookies-details>

