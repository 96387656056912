<div class="wrapper p-d-flex p-jc-center p-px-3">
  <div class="component-wrapper p-py-3">
    <!--Content-->
    <div class="p-grid p-jc-center">
      <div class="p-col-12 p-md-8">
        <!--ToolBar-->
        <div class="p-grid">
          <div class="p-col-12 p-sm-6">
            <h1 class="title p-m-0" i18n>Notifications</h1>
          </div>
          <div class="p-col-12 p-sm-6 p-d-flex p-ai-center p-jc-sm-end">
            <app-button type="text"
                        label="Mark all as read"
                        i18n-label
                        (clicked)="markAllAsSeen()"></app-button>
          </div>
        </div>

        <div class="p-col-12">
          <!--Skeleton Loading-->
          <div *ngIf="loading" @fade>
            <ng-template [ngTemplateOutlet]="skeleton"></ng-template>
          </div>


          <!--Content-->
          <p-dataView [value]="notifications?.content"
                      *ngIf="!loading"
                      [trackBy]="trackBy.bind(this)">
            <ng-template pTemplate="list" let-notifications>
              <a (click)="notificationAction(notif)"
                 [routerLink]="notif.url? navigationService.urlSingleNotification(notif) : navigationService.urlNotifications()"
                 *ngFor="let notif of notifications">
                <div
                  class="p-card rounded-medium p-py-2 p-px-4 w-100 p-d-flex p-mb-3 {{ notif.seen ? 'seen' : 'unseen' }}">
                  <div class="p-d-flex p-ai-center">
                    <app-avatar [profile]="notif.sourceProfile"
                                *ngIf="notif.sourceProfile"
                                [sizePx]="48"
                    ></app-avatar>
                    <div *ngIf="!notif.sourceProfile" class="p-d-flex p-justify-center no-profile">
                      <i class="fa-solid fa-bell"></i>
                    </div>
                  </div>
                  <div class="content w-100 p-pl-4">
                    <h3 class="p-m-0 p-h4">{{ notif.title }}</h3>
                    <p> {{ notif.body }}</p>
                  </div>
                </div>
              </a>
            </ng-template>

            <ng-template pTemplate="empty">
              <div class="p-d-flex p-justify-center">
                <h1 class="empty" i18n>No notifications</h1>
              </div>
            </ng-template>
          </p-dataView>

          <div class="p-p-2 w-100 p-d-flex p-jc-center" *ngIf="notifications.nextPageLoading" @fade>
            <div class="p-d-flex p-ai-center">
              <i class="fa-solid fa-circle-notch fa-spin p-mr-2"></i>
              <div i18n>Loading</div>
            </div>
          </div>

          <div class="p-p-2 w-100 p-d-flex p-jc-center"
               *ngIf="!notifications.nextPageLoading
               && !loading
               && notifications.totalElements > notifications.content.length"
               @fade>
            <div class="p-d-flex p-ai-center">
              <app-button type="text"
                          label="Load more"
                          i18n-label
                          (clicked)="loadNextPage(false)"></app-button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>


<!--Skeleton-->
<ng-template #skeleton>
  <div *ngFor="let j of [].constructor(4)" class="card rounded-medium box-shadow p-p-3 p-p-0 w-100 p-d-flex p-mb-2">
    <div class="p-d-flex p-ai-center">
      <p-skeleton shape="circle" size="3rem"></p-skeleton>
    </div>
    <div class="content w-100 p-px-4">
      <p-skeleton height="3rem"></p-skeleton>
    </div>
  </div>
</ng-template>
