import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

/**
 * Service for controlling {@link ProfileCompletionComponent}.
 * Allows to emit {@link CompletionType} in able to display correct edit dialog.
 */
@Injectable({
  providedIn: 'root'
})
export class ProfileCompletionService {

  /**
   * {@link BehaviorSubject} with {@link CompletionType} for opening correct edit dialog.
   */
  readonly completionTypeShow = new BehaviorSubject<CompletionType>(null)

  /**
   * {@link BehaviorSubject} for triggering items reordering
   * and percentage recalculation in {@link ProfileCompletionComponent}.
   */
  readonly checkCompletion = new BehaviorSubject<boolean>(true)

  /**
   * Emits {@link type} into {@link completionTypeShow}. It will open corresponding edit dialog.
   * @param type Type of {@link CompletionItem} to have edit dialog opened.
   */
  openCompletionItem(type: CompletionType): void {
    this.completionTypeShow.next(type)
  }

  /**
   * Emits null to {@link completionTypeShow} for reset, in case of navigation from {@link ProfileComponent}.
   * Emits true to {@link checkCompletion}.
   * That will trigger percentage recalculation in {@link ProfileCompletionComponent}.
   */
  closeCompletionItemAndCheck(): void {
    this.completionTypeShow.next(null)
    this.checkCompletion.next(true)
  }
}

/**
 * Interface to hold all relevant data.
 */
export interface CompletionItem {
  /**
   * Displayed name.
   */
  name: string
  /**
   * Displayed description.
   */
  description: string
  /**
   * Whether item was completed by {@link ProfileResp}
   */
  completed: boolean
  /**
   * Type of item for easier sorting.
   */
  type: CompletionType
  /**
   * Action to be executed when user clicks on item.
   */
  onClick: () => void
  /**
   * Function to determine {@link completed} status of item.
   */
  completionCheck: (item: CompletionItem) => Promise<void>
  /**
   * Defines whether this item has to be filled up to be the profile successfully visible.
   */
  required?: boolean
}

/**
 * Type of {@link CompletionItem} for profile.
 */
export enum CompletionType {
  LOCATION,
  STRIPE,
  PROFESSIONS,
  PRICE_ITEMS,
  BIOGRAPHY,
  AVATAR,
  WALLPAPER,
  IMAGE_SHOWCASE,
  SHOWCASE,
  GENRES,
  SKILLS
}
