import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {scrollToIndex, scrollToIndexOptions} from '../../../utils/scroll.utils'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {charId} from '../../../validator/custom.validators'
import {Feature} from '../../../common/feature'
import {Location} from '@angular/common'
import {NavigationService} from '../../../service/ui/navigation.service'
import {AddressService} from '../../../service/address.service'
import {growAnimation} from '../../../animation/grow.animation'
import {fadeAnimation} from '../../../animation/fade.animation'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {ConnectAccountSettingsResp, StripeCompletionCheckResp, StripeService} from '../../../service/stripe.service'
import {hasFeatures} from '../../../common/profile-type'
import {hasProfileStatus, ProfileStatus} from '../../../common/profile-status'
import {currentRelativeURL} from '../../../utils/router.utils'
import {PermissionService} from '../../../service/ui/permission.service'
import {ProfileCompletionService} from '../../../service/profile-completion.service'
import {environment} from '../../../../environments/environment'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent extends EditableComponent implements OnInit, OnDestroy, AfterViewInit {

  static readonly DIALOG_SCROLL_DELAY = 500

  /**
   * The data to be displayed.
   */
  @Input()
  data: ProfileResp
  /**
   * Retrieves the initial stripe completion check from the parent component.
   */
  @Input()
  stripeCompletionCheck: StripeCompletionCheckResp
  /**
   * Used to update the completion component status based on updated data in this component.
   */
  @Output()
  stripeCompletionCheckChange = new EventEmitter<StripeCompletionCheckResp>()
  /**
   * Whether the {@link data} contains the {@link Feature.BE_ORDERED}.
   */
  @Input()
  canBeOrdered: boolean
  /**
   * Defines a setting to highlight and scroll to on startup.
   */
  @Input()
  highlightSetting: ProfileSettingsDialogHighlight = 'delete'
  /**
   * Represents the visibility of the professions.
   */
  professionsVisible: boolean
  /**
   * Controls the visibility of the professions dialog.
   */
  professionsDialogVisible: boolean
  /**
   * All available options to profile quantity.
   */
  profileQuantitySettings: ProfileQuantitySetting[] = []
  /**
   * Profile information and contact form.
   */
  form: FormGroup
  /**
   * Controls the visibility of the edit location dialog.
   */
  locationEditVisible: boolean
  /**
   * Controls the visibility of the delete location dialog.
   */
  locationDeleteVisible: boolean
  /**
   * Controls the visibility of the legal entity settings dialog.
   */
  legalEntityDialogVisible: boolean
  /**
   * Controls if the save button was clicked in the edit dialog.
   */
  saveClicked = false
  /**
   * Controls if some required content in the edit dialog missing.
   */
  requiredSettingMissing = null
  /**
   * Represents that at least one of the {@link form} fields changed.
   */
  inputChanged = false
  /**
   * Controls the visibility of the dialog, where the user can delete, deactivate or activate his profile account.
   */
  changeStatusDialogVisible = false
  /**
   * Represent whether the user wants to delete, deactivate or activate his profile.
   */
  clickedTypeDecision: ProfileStatus
  /**
   * The current visible dialog instance.
   */
  @ViewChild('dialog')
  dialog: DialogComponent
  /**
   * Represents the profile Stripe Connect settings.
   */
  stripeSettings: ConnectAccountSettingsResp
  /**
   * Controls the visibility of the loading when the {@link stripeSettings} is fetching.
   */
  stripeSettingsLoading: boolean
  /**
   * Represents whether the {@link data} has active orders.
   */
  hasActiveOrders: boolean
  /**
   * The charId of the {@link data} when this dialog has opened.
   */
  previousCharId: string
  /**
   * True when the names are updating.
   */
  namesUpdateLoading: boolean
  /**
   * {@link form} value changes subscription.
   */
  private formSubs: Subscription[] = []

  /**
   * scrollToIndex function redirect.
   */
  scrollFun: typeof scrollToIndex = scrollToIndex
  readonly env = environment
  ProfileStatus: typeof ProfileStatus = ProfileStatus
  hasProfileStatus: typeof hasProfileStatus = hasProfileStatus

  protected trans = {
    later: $localize`Later`,
    save: $localize`Save`
  }

  constructor(
    public navigation: NavigationService,
    public changeRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private location: Location,
    private profileService: ProfileService,
    private addressService: AddressService,
    private stripeService: StripeService,
    private permissionService: PermissionService,
    private profileCompletionService: ProfileCompletionService) {
    super()
  }

  ngOnInit(): void {
    this.initForm()
    this.loadStripeSettings()
    this.professionsVisible = hasFeatures(this.data.profileType, Feature.PROFESSIONS)
    this.previousCharId = this.data.charId
  }

  ngAfterViewInit(): void {
    if (this.highlightSetting) {
      scrollToIndexOptions('highlight-setting', 0, {
        behavior: 'smooth',
        block: 'center'
      }, 500)
    }
  }

  /**
   * Fires when a user wants to save the edit dialog.
   */
  async onSave(): Promise<void> {
    await this.updateNames(false)

    // When Save clicked for the second time
    if (this.saveClicked) {
      return
    }

    // Orderable Profile check
    if (this.canBeOrdered) {
      this.verifyOrderableProfile()

      // if user is artist, then ask for notifications if not allowed
      this.permissionService.askNotificationPermission()
    }
  }

  /**
   * Update names of the profile.
   * @param customCall Whether the function logic should be called in a {@link customCall} function. Set to false when saving.
   */
  async updateNames(customCall: boolean): Promise<void> {
    // firstly, check if the input fields are valid and without server messages, otherwise return and stop
    if (!customCall) {
      // firstly, check if the input fields are valid and without server messages, otherwise return and stop
      if (this.inputChanged) {
        const result = await this.updateDataNames(this.form.value)
        if (result && this.noServerMessages()) {
          this.inputChanged = false
        }
      }
    } else {
      await this.customCall(async () => {
        this.namesUpdateLoading = true
        await this.updateNames(false)
      }, null, () => {
        this.namesUpdateLoading = false
      })
    }
  }

  /**
   * Contains a specific logic for the {@link canBeOrdered} profiles.
   * - Uses the {@link saveClicked} and {@link requiredSettingMissing} to validate important settings.
   */
  private verifyOrderableProfile(): void {
    // if the save button is clicked 2x or the required content is filled correctly with valid form fields
    if (this.saveClicked ||
      (this.form.valid
        && this.data.address
        && this.data.professions.length > 0
        && this.stripeCompletionCheck?.completed
        && this.stripeSettings?.completed)) {
      this.dialog?.scrollBottom(200)
      return
    }

    // if the required content is not filled correctly
    if (this.form.invalid
      || !this.data.address
      || (this.data.professions?.length || 0) === 0
      || this.stripeCompletionCheck?.completed === false
      || !this.stripeSettings?.completed) {

      this.requiredSettingMissing = true
      scrollToIndex('required', 0, 'smooth')
    }

    // save btn clicked for the first time
    this.saveClicked = true

    // prevents from infinite loading
    this.setFailed(true)
  }

  /**
   * Calls the server API to remove profile address.
   */
  removeLocation(): void {
    this.call(async () => {
      const resp = await firstValueFrom(this.callRemoveProfileAddress())
      if (resp && this.noServerMessages()) {
        this.data.address = null
        this.saveClicked = false
        this.requiredSettingMissing = null
        this.locationDeleteVisible = false
        this.profileCompletionService.closeCompletionItemAndCheck()
      }
    })
  }

  /**
   * - Updates {@link data} names like charId and displayName, when they are updated.
   * - When the updated response without server messages, returns {@link Promise.resolve(true)}, otherwise {@link Promise.resolve(false)}
   */
  private async updateDataNames(formData): Promise<boolean> {
    const resp = await firstValueFrom(this.callUpdateProfileNames(formData))
    if (resp && this.noServerMessages()) {
      const formCharId = formData.charId.trim().toLowerCase()

      // if charId form field input changed
      if (this.data.charId !== formCharId) {
        // Update URL
        this.location.replaceState(currentRelativeURL().replace(this.data.charId, formCharId))

        this.data.charId = formCharId
        await this.profileService.init(this.data.profileId)
      }

      // reload data values
      this.data.charId = formCharId
      this.data.displayName = formData.displayName.trim()
      this.data.group = formData.quantity.id === 'group'

      return Promise.resolve(true)
    } else {
      this.saveClicked = false // to show again required error
      return Promise.resolve(false)
    }
  }

  /**
   * Opens the Stripe Legal Entity settings dialog.
   */
  showLegalEntityDialog(): void {
    this.legalEntityDialogVisible = true
    this.changeRef.detectChanges()
  }

  /**
   * Loads the profile's Stripe Connect settings.
   */
  private loadStripeSettings(): void {
    if (!hasFeatures(this.data.profileType, Feature.BE_ORDERED)) {
      return
    }
    this.customCall(async () => {
      this.stripeSettingsLoading = true
      this.stripeSettings = await firstValueFrom(this.callStripeSettings())
      this.data.detailsProvided = this.stripeSettings?.completed && !!this.stripeSettings?.invoicingAddress
      this.stripeCompletionCheck = {
        completed: !!this.stripeSettings?.completed,
        newRequirementDue: this.stripeSettings?.newRequirementDue
      }
      this.stripeCompletionCheckChange.emit(this.stripeCompletionCheck)
      this.profileCompletionService.checkCompletion.next(true)
    }, null, () => this.stripeSettingsLoading = false)
  }

  /**
   * Initializes {@link form} fields.
   */
  private initForm(): void {
    // Init profile quantity values
    this.profileQuantitySettings = [{
      name: $localize`Solo`,
      id: 'solo',
      icon: 'fa-solid fa-user'
    },
      {
        name: $localize`Group`,
        id: 'group',
        icon: 'fa-solid fa-user-group'
      }]

    this.form = this.formBuilder.group({
      charId: [this.data.charId || '', [
        charId()
      ]],
      displayName: [this.data.displayName || ''],
      quantity: [this.profileQuantitySettings[(this.data.group) ? 1 : 0]]
    })

    this.formSubs.push(this.form.valueChanges.subscribe(() => {
      this.inputChanged = true
      this.serverMessages = [] //clean array with server messages
    }))
  }

  /**
   * Calls when a deleted, deactivated or activated button of the profile settings dialog is clicked.
   */
  accountDecisionClicked(status: ProfileStatus): void {
    this.callAndFinish(async () => {
      this.hasActiveOrders = await firstValueFrom(this.callCheckActiveOrders())

      if (this.noServerMessages()) {
        this.changeStatusDialogVisible = true
        this.clickedTypeDecision = status
        this.changeRef.detectChanges()
      }
    })
  }

  /**
   * Constructs a request from the given formData and calls the API.
   * @param formData The data from the user {@link form}.
   */
  private callUpdateProfileNames(formData): Observable<boolean> {
    return this.unwrap(this.profileService.callUpdateProfileNames({
      charId: formData.charId.toLowerCase(),
      displayName: formData.displayName,
      group: formData.quantity.id === 'group'
    }))
  }

  /**
   * Calls the server API to remove the profile address.
   */
  private callRemoveProfileAddress(): Observable<boolean> {
    return this.unwrap(this.addressService.callDeleteProfileAddress())
  }

  /**
   * Calls the server API to load the Stripe Connect settings.
   */
  private callStripeSettings(): Observable<ConnectAccountSettingsResp> {
    return this.unwrap(this.stripeService.callGetStripeSettings())
  }

  /**
   * Calls the server API to check if the {@link data} has active orders.
   */
  private callCheckActiveOrders(): Observable<boolean> {
    return this.unwrap(this.profileService.callCheckActiveOrders({
      profileId: this.data.profileId
    }))
  }

  ngOnDestroy(): void {
    this.formSubs?.forEach(it => {
      it.unsubscribe()
    })
  }

  /**
   * Scrolls the bottom of the {@link dialog}.
   */
  scrollDialog(): void {
    this.dialog.scrollBottom(ProfileSettingsComponent.DIALOG_SCROLL_DELAY)
  }
}

/**
 * Represents the profile quantity layout structure.
 */
interface ProfileQuantitySetting {
  name: string
  id: string
  icon: string
}

/**
 * Defines all available setting types to highlight and scroll to on startup.
 */
export type ProfileSettingsDialogHighlight = 'location' | 'profession' | 'stripe' | 'delete'

