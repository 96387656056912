/**
 * Selects all document elements by a class name and scrolls to the specific element by given index.
 *
 * @param classSelector All available elements to scroll.
 * @param index To which index it should scroll. The -1 value means the last element.
 * @param type What type of scrolling should be performed.
 * @param container The container of elements.
 */
export function scrollToIndex(classSelector: string, index: number, type: ScrollBehavior = 'auto', container: any = document): void {
  setTimeout(() => {
    const items = container.getElementsByClassName(classSelector)
    if (items.length > 0) {
      index = (index === -1) ? (items.length - 1) : index
      items[index]?.scrollIntoView({
        behavior: type
      })
    }
  }, 10)
}

/**
 * The same as the {@link scrollToIndex} function but with more customizable parameters.
 */
export function scrollToIndexOptions(classSelector: string, index: number, args: ScrollIntoViewOptions, delay: number = 0): void {
  setTimeout(() => {
    const items = document.getElementsByClassName(classSelector)
    if (items.length > 0) {
      index = (index === -1) ? (items.length - 1) : index
      items[index]?.scrollIntoView(args)
    }
  }, 10 + delay)
}
