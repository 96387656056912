<div class="wrapper p-d-flex p-jc-center p-ai-center">
  <div @grow class="component-wrapper p-p-3 p-py-sm-5 p-d-flex p-ai-center p-jc-center rounded-medium">
    <div class="w-100 p-px-2 p-px-sm-5">
      <!--Logo image-->
      <div *ngIf="isScreenOf(Screen.SM)" class="w-100 p-d-flex p-jc-center logo">
        <img alt="Umevia logo" class="logo" i18n-alt src="assets/logo/logo-color.svg">
      </div>
      <h1 class="title p-py-2 p-text-uppercase" data-test="title" i18n>Login</h1>
      <form (keyup.enter)="login()"
            [formGroup]="form">

        <!--E-MAIL-->
        <app-text-input [copy]="false"
                        [email]="true"
                        [form]="form"
                        [length]="{min: Restrictions.MIN_EMAIL_LENGTH}"
                        [required]="true"
                        [space]="false"
                        data-test="email"
                        formFieldName="email"
                        i18n-placeholder
                        inputType="email"
                        label=""
                        placeholder="Email"
                        styleClass="p-mb-3">

          <!--BE-CHECK-->
          <app-backend-validation [check]="ServerMessage.USER_NOT_FOUND" [serverMessages]="serverMessages" i18n>
            User not registered.
          </app-backend-validation>

          <app-backend-validation [check]="ServerMessage.PROFILE_NOT_FOUND" [serverMessages]="serverMessages" i18n>
            Profile not found.
          </app-backend-validation>

          <app-backend-validation [check]="ServerMessage.LOGIN_EMAIL_INCORRECT" [serverMessages]="serverMessages" i18n>
            Not a valid email.
          </app-backend-validation>

          <app-backend-validation [check]="ServerMessage.FIREBASE_NO_PASSWORD_NEEDED" [serverMessages]="serverMessages"
                                  i18n>
            This e-mail is already logged in with your Google account, please Log in with Google.
          </app-backend-validation>
        </app-text-input>

        <!--PASSWORD-->
        <app-text-input [copy]="false"
                        [form]="form"
                        [length]="{min: Restrictions.MIN_PASSWORD_LENGTH}"
                        [required]="true"
                        [space]="false"
                        data-test="password"
                        formFieldName="password"
                        inputType="password"
                        label=""
                        placeholder="••••••••••••"
                        styleClass="password">
          <!--BE CHECK-->
          <app-backend-validation [check]="ServerMessage.USER_WRONG_CREDENTIALS" [serverMessages]="serverMessages" i18n>
            Wrong e-mail or password.
          </app-backend-validation>

          <app-backend-validation [check]="ServerMessage.LOGIN_PASSWORD_INCORRECT" [serverMessages]="serverMessages"
                                  i18n>
            Not a valid password.
          </app-backend-validation>
        </app-text-input>

        <!--Password Forgotten-->
        <div class="p-px-0 p-d-flex p-jc-end">
          <app-button [disabled]="saving"
                      [routerLink]="['password-restore'] | routerLink"
                      i18n-label
                      icon="fa-solid fa-unlock-keyhole"
                      label="Forgot password?"
                      data-test="forgot-password"
                      styleClass="p-p-0"
                      type="text"></app-button>
        </div>

        <app-checkbox [disable]="saving"
                      [form]="form"
                      formFieldName="rememberMe"
                      i18n-label
                      label="Remember me"
                      styleClass="remember-checkbox p-mt-3"></app-checkbox>

        <!--Legal Documents-->
        <div class="legal-text p-mt-3" i18n>
          By logging in you accept the Umevia
          <a [routerLink]="toLegalDocuments('terms')" class="text-link" target="_blank">Terms of Services</a> and
          acknowledge the <a [routerLink]="toLegalDocuments('privacy')" class="text-link" target="_blank">Privacy
          Policy</a> and <a [routerLink]="toLegalDocuments('cookies')" class="text-link" target="_blank">Cookie
          Policy</a>.
        </div>

        <!-- BE - check of the profile with BAN status included -->
        <div class="p-m-2">
          <app-backend-validation [check]="ServerMessage.PROFILE_IN_BAN"
                                  [serverMessages]="serverMessages"
                                  [showAsBox]="true" i18n>
            This profile is <b>banned</b> due to platform rules violation.
          </app-backend-validation>
          <app-backend-validation [check]="ServerMessage.ALL_PROFILES_DELETED"
                                  [serverMessages]="serverMessages"
                                  [showAsBox]="true" i18n>
            The account has been <b>deleted</b> by the user, you can create a new account with the following details.
          </app-backend-validation>
        </div>

        <!--Action Buttons-->
        <div class="p-d-flex p-ai-center p-mt-3 p-grid">

          <!--Login-->
          <div class="p-col-12 p-px-0">
            <app-button (clicked)="login()"
                        [disabled]="form.invalid || form.disabled"
                        [loading]="saving"
                        data-test="login"
                        i18n-label
                        icon="fa-solid fa-paper-plane"
                        label="Login"
                        styleClass="w-100 submit-button"
                        type="primary">

            </app-button>
          </div>

          <!--Register-->
          <div class="p-col-12 p-px-0">
            <app-button (clicked)="navigation.toRegister()"
                        [disabled]="saving || form.disabled"
                        i18n-label
                        icon="fa-solid fa-user-plus"
                        data-test="register"
                        label="Register"
                        styleClass="w-100"
                        type="secondary">
            </app-button>
          </div>

          <!--Google authentication-->
          <div class="p-col-12 p-px-0">
            <app-firebase-auth
              (firebaseAuthResponse)="firebaseAuthResponseAction($event)"
              (formDisabled)="disableForm($event)"
              [authType]="AuthType.GOOGLE"
              [buttonDisabled]="socialButtonsDisabled">
            </app-firebase-auth>
          </div>

          <!--Facebook authentication-->
          <div *ngIf="false" class="p-col-12 p-px-0">
            <app-firebase-auth
              (firebaseAuthResponse)="firebaseAuthResponseAction($event)"
              (formDisabled)="disableForm($event)"
              [authType]="AuthType.FACEBOOK"
              [buttonDisabled]="socialButtonsDisabled">
            </app-firebase-auth>
          </div>
        </div>
      </form>

      <!--Response-->
      <app-call-response [component]="this"
                         styleClass="p-mt-2"></app-call-response>
    </div>
  </div>
</div>
