import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {FormBuilder, FormGroup} from '@angular/forms'
import {ChangeDetectorRef, Component, HostListener, inject, OnDestroy, OnInit} from '@angular/core'
import {UniqueUserReq, UserResp} from '../../../service/user.service'
import {Location} from '@angular/common'
import {AuthComponent} from './auth.component'
import {StorageService} from '../../../service/storage.service'
import {NavigationService} from '../../../service/ui/navigation.service'
import {removeAccents} from '../../../../assets/js/remove-accents.js'
import {scrollToIndex} from '../../../utils/scroll.utils'
import {PixelService} from '../../../service/analytics/meta-pixel/pixel.service'
import {GoogleAnalyticsEvent, GoogleAnalyticsService} from '../../../service/analytics/google-analytics.service'

/**
 * Abstract class for register components that contains common methods and properties.
 * Contains only common logic for all register components independent of the register type.
 */
@Component({template: ''})
export abstract class RegisterAbstractComponent extends AuthComponent implements OnInit, OnDestroy {
  /**
   * A form with fields of registration.
   */
  form: FormGroup
  /**
   * Represents if a user pressed the submit button of the {@link form}.
   */
  submitted: boolean
  /**
   * Represents a state when a user has been successfully registered.
   */
  registered: boolean
  /**
   * Blocks browser's back button.
   */
  blockReturn: boolean
  /**
   * Display the unsaved dialog if the user wants to go back in history & {@link blockReturn} is enabled.
   */
  unsavedDialogVisible: boolean
  /**
   * Represents if the process of phone verification is in progress.
   */
  phoneVerificationInProgress: boolean
  /**
   * The {@link form} value changes subscription.
   */
  protected formSub?: Subscription
  /**
   * A display name control value changes subscription.
   */
  private displayNameSub?: Subscription

  categories: any[] = [
    {name: $localize`Book Artists`, key: 'customer'},
    {name: $localize`Be an Artist`, key: 'artist'}
  ]

  // injected services
  protected formBuilder: FormBuilder = inject(FormBuilder)
  protected location: Location = inject(Location)
  protected navigation: NavigationService = inject(NavigationService)
  protected storageService: StorageService = inject(StorageService)
  private changeRef: ChangeDetectorRef = inject(ChangeDetectorRef)
  private pixelService: PixelService = inject(PixelService)
  private gaService: GoogleAnalyticsService = inject(GoogleAnalyticsService)

  protected constructor() {
    super()
  }

  ngOnInit(): void {
    this.redirectIfLogged()
    this.initForm()
    this.changeRef.detectChanges()
  }

  /**
   * Fires when a user successfully verifies the phone number.
   * It finishes the registration process.
   */
  abstract onRegister(): void

  /**
   * Call the server API to register a new user.
   *
   * @param formData The {@link form} value.
   */
  protected abstract callRegister(formData): Observable<UserResp>

  /**
   * Creates and inits the input form with validators.
   */
  protected abstract initForm(): void

  /**
   * Fires when a user submits this {@link form}.
   */
  protected async checkUniqueUserAndVerifyPhone(): Promise<void> {
    // This implementation of field reading is necessary because other way it doesn't work with disabled email input field
    const uniqueUserReq: UniqueUserReq = {
      email: this.form.get('email').value,
      phone: this.form.get('phone').value
    }
    this.call(async () => {
      const result = await firstValueFrom(this.unwrap(this.userService.callUniqueUser(uniqueUserReq)))
      if (result) {
        this.phoneVerificationInProgress = true
      }
    })
  }

  /**
   * Handles the result of phone verification.
   */
  phoneVerificationResult(result: PhoneVerificationResult): void {
    switch (result) {
      case PhoneVerificationResult.SUCCESS:
        // scroll down, so user can see loading state
        setTimeout(() => {
          scrollToIndex('app-register-bottom', 0, 'smooth')
        }, 750)
        this.phoneVerificationInProgress = false
        this.onRegister()
        break
      case PhoneVerificationResult.CANCEL:
        this.phoneVerificationInProgress = false
        break
    }
  }

  /**
   * Starts observing display name value changes to fill the 'charId' based on display name.
   */
  setDisplayNameValueChanges(): void {
    this.displayNameSub = this.form.controls.displayName?.valueChanges.subscribe((val: string) => {
      if (this.form.controls.charId.untouched) {
        this.form.controls.charId.setValue(removeAccents(val?.trim()?.toLowerCase()?.replaceAll(' ', '.')))
      }
    })
  }

  /**
   * Called after registration was successful.
   * Retrieves all necessary data about user and his profile.
   * @param user User returned as response.
   */
  protected async afterRegisterSuccess(user: UserResp): Promise<void> {
    this.registered = true
    this.form.disable()
    const trackEvent = {
      user_id: user.userId
    }
    this.pixelService.track('CompleteRegistration', {
      contents: [trackEvent]
    })
    this.gaService.event(GoogleAnalyticsEvent.PROFILE_REGISTER, trackEvent)
    await this.finishLogin(user)
    this.afterSuccessRedirect(user, true)
  }

  /**
   * Sets the {@link submitted} property a new value.
   * It also controls the disability of the {@link form}.
   */
  protected setFormSubmitted(submitted: boolean): void {
    this.submitted = submitted
    if (submitted) {
      this.form.disable()
    } else {
      this.form.enable()
      this.blockReturn = false
    }
  }

  /**
   * Disables reload and tab-closing event.
   */
  @HostListener('window:beforeunload', ['$event'])
  protected preventRefresh(event): void {
    if (this.blockReturn) {
      event.preventDefault()
      event.returnValue = $localize`You have an unfinished registration. Do you want to leave?`
    }
  }

  /**
   * Fires when the user wants to go to previous URL.
   */
  @HostListener('window:popstate', ['$event'])
  onBackButton(event: PopStateEvent): void {
    if (this.blockReturn) {
      event.preventDefault()
      this.unsavedDialogVisible = true
      this.location.replaceState(this.location.path())
    }
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe()
    this.displayNameSub?.unsubscribe()
  }
}

/**
 * Represents the result of phone verification process.
 */
export enum PhoneVerificationResult {
  SUCCESS,
  CANCEL
}
