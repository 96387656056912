import {Injectable} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {BASE_SITE_URL} from '../../../environments/environment'
import {SettingCategory} from '../../common/settings-category'
import {AdminOrdersMenuCategory} from '../../common/admin/admin-orders-menu.category'
import {LegalDocumentType} from '../../component/support/support.component'
import {OrderManagerListTypeEnum} from '../../modules/order-manager/order-manager-list-type.enum'
import {LegalDocumentsCategory} from '../../modules/admin/admin-platform-documents/admin-platform-documents.component'
import {
  AdminProfilesMenuCategory
} from '../../modules/admin/admin-dashboard-profiles/admin-dashboard-profiles.component'
import {AdminProfileAttributeTypes} from '../../modules/admin/admin-attribute-editor/admin-attribute-editor.component'
import {InboxNotificationResp} from '../notification.service'
import {PLATFORM_BROWSER} from '../../app.module'


/**
 * Contains all routes where the user can navigate.
 * - For the best UX use functions start with 'url'. Those functions return parameters for the [routerLink] that generates <a> elements
 * and allows users better navigation.
 * - Only in inevitable cases use the navigation functions starting with the 'to'. Take in mind, that those functions are very UX poor,
 * since they are not pushing a new browser history item and the user cannot navigate back easily.
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  static readonly AUTHENTICATION = 'auth'
  static readonly LOGIN = 'login'
  static readonly TWO_FACTOR_AUTHENTICATION = 'two-factor-auth'
  static readonly REGISTER = 'register'
  static readonly REGISTER_FIREBASE = 'register-firebase'
  static readonly PHONE_VERIFICATION = 'phone-verification'
  static readonly SOCIAL_TYPE_PARAMETER = 'authType'
  static readonly PASSWORD_RESTORE = 'password-restore'
  static readonly MAP = 'map'
  static readonly MAP_LAT_PARAM = 'lat'
  static readonly MAP_LNG_PARAM = 'lng'
  static readonly MAP_ZOOM_PARAM = 'zoom'
  static readonly FEED = 'feed'
  static readonly EDITOR = 'editor'
  static readonly USER_SETTINGS = 'settings'
  static readonly USER_SETTINGS_PARAMETER = 'type'
  static readonly PROFILE = 'profile'
  static readonly PROFILE_SERVICES_EDITOR = 'services-editor'
  static readonly PROFILE_SERVICES_EDITOR_CATEGORY = 'category'
  static readonly PROFILE_CHAR_ID_PARAMETER = 'charId'
  static readonly PROFILE_ACTION_PARAMETER = 'profileActionParameter'
  static readonly CHAT_CHANNEL = 'ch'
  static readonly CHAT_CHANNEL_ID_PARAMETER = 'channelId'
  static readonly CHAT = 'chat'
  static readonly DASHBOARD_PROFILES = 'dashboard/profiles'
  static readonly PROFILE_CATALOG = 'profiles'
  // When updating this variables. They need to be also updated in the seo pages project for compatibility.
  static readonly PROFILE_CATALOG_PRICE_ITEM_PARAMETER = 'category'
  static readonly PROFILE_CATALOG_PROFESSION_PARAMETER = 'profession'
  static readonly PROFILE_CATALOG_CITY_PARAMETER = 'city'
  static readonly PROFILE_CATALOG_START_DATE_PARAMETER = 'startDate'
  static readonly PROFILE_CATALOG_END_DATE_PARAMETER = 'endDate'
  static readonly PROFILE_CATALOG_FILTERS = 'filters'
  static readonly PROFILE_BOOKINGS = 'bookings'
  static readonly PROFILE_BOOKINGS_DETAIL_PARAMETER = 'orderId'
  static readonly PROFILE_BOOKINGS_LIST = 'list'
  static readonly PROFILE_BOOKINGS_LIST_PARAM = 'listType'
  static readonly PROFILE_BOOKING_STATE_PARAMETER = 'state'
  static readonly SUPPORT = 'support'
  static readonly SECTION = 'section'
  static readonly NOTIFICATIONS = 'notifications'

  static readonly ADMIN = 'admin'
  static readonly RESOLVE_DISPUTE = 'resolve-dispute'
  static readonly REVIEW_CHAT = 'review-chat'
  static readonly ADMIN_DASHBOARD_ORDERS = 'order-dashboard'
  static readonly ADMIN_DASHBOARD_ORDERS_CATEGORY = 'category'
  static readonly ADMIN_DASHBOARD_PROFILES = 'profile-dashboard'
  static readonly ADMIN_DASHBOARD_PROFILES_CATEGORY = 'category'
  static readonly ADMIN_LEGAL_DOCUMENTS = 'legal-documents'
  static readonly ADMIN_LEGAL_DOCUMENTS_CATEGORY = 'documentsCategory'
  static readonly ADMIN_PRICE_ITEM_CATEGORIES = 'profession-categories'
  static readonly ADMIN_PROFILE_ATTRIBUTES = 'profile-attributes'
  static readonly ADMIN_PROFILE_ATTRIBUTES_TYPE = 'profileAttributesType'
  static readonly ADMIN_PLATFORM_RATING = 'platform-rating'
  static readonly ADMIN_CITY_MANAGER = 'city-manager'
  static readonly ADMIN_SEO_PAGE_MANAGER = 'seo-page-manager'

  static readonly INTERNAL_SERVER_ERROR = 'internal-server-error'
  static readonly UNAUTHORIZED = 'unauthorized'

  /**
   * Stores URL for the last destination before the authentication module was called.
   * By default, it navigates to {@link PROFILE_CATALOG}
   */
  private lastUrlBeforeAuth = NavigationService.PROFILE_CATALOG

  constructor(public router: Router) {
  }

  /**
   * Returns URL parameters for the routerLink that navigates to the login.
   * @param rememberDestination Flag whether to remember destination, where user was going or reset to home page.
   */
  urlPathLogin(rememberDestination: boolean = false): string {
    if (PLATFORM_BROWSER) {
      this.lastUrlBeforeAuth = rememberDestination ? window.location.pathname : NavigationService.PROFILE_CATALOG
    } else {
      this.lastUrlBeforeAuth = NavigationService.PROFILE_CATALOG
    }
    return `${NavigationService.AUTHENTICATION}/${NavigationService.LOGIN}`
  }

  urlLogin(rememberDestination: boolean = false): any[] {
    return [`/${this.urlPathLogin(rememberDestination)}`]
  }

  /**
   * Checks if {@link lastUrlBeforeAuth} is equal to {@link NavigationService.PROFILE_CATALOG}.
   * Used in LoginComponent to determine if a newly logged-in artist should be redirected to his profile or not.
   */
  isLastUrlHomePage(): boolean {
    return this.lastUrlBeforeAuth === NavigationService.PROFILE_CATALOG
      || this.lastUrlBeforeAuth === `/${NavigationService.PROFILE_CATALOG}`
  }

  /**
   * Navigates to a provided path in the parameter of the function.
   * Be cautious and use only static variables from {@link NavigationService}.
   */
  toProvidedPath(path: string): void {
    this.router.navigate([path])
  }

  urlAdminDashboard(): any[] {
    return [`/${NavigationService.ADMIN}`]
  }

  urlResolveDispute(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.RESOLVE_DISPUTE}`]
  }

  urlReviewChat(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.REVIEW_CHAT}`]
  }

  urlAdminDashboardOrders(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_DASHBOARD_ORDERS}/${AdminOrdersMenuCategory.RECENT}`]
  }

  urlAdminDashboardProfiles(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_DASHBOARD_PROFILES}/${AdminProfilesMenuCategory.LIST}`]
  }

  urlAdminPriceItemCategories(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_PRICE_ITEM_CATEGORIES}`]
  }

  urlAdminProfileAttributes(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_PROFILE_ATTRIBUTES}/${AdminProfileAttributeTypes.PROFESSIONS}`]
  }

  urlAdminPlatformRating(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_PLATFORM_RATING}`]
  }

  urlAdminCityManager(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_CITY_MANAGER}`]
  }

  urlAdminSeoPageManager(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_SEO_PAGE_MANAGER}`]
  }

  toAdminDashboardOrderDetail(orderId: number, newTab: boolean = true): void {
    if (newTab) {
      window.open(`${BASE_SITE_URL}/${NavigationService.ADMIN}/${NavigationService.PROFILE_BOOKINGS}/${orderId}`, '_blank')
      return
    }
    this.router.navigate([`${NavigationService.ADMIN}/${NavigationService.PROFILE_BOOKINGS}/${orderId}`])
  }

  urlAdminProfileDetail(charId: string): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.PROFILE}/${charId}`]
  }

  urlAdminLegalDocuments(): any[] {
    return [`/${NavigationService.ADMIN}/${NavigationService.ADMIN_LEGAL_DOCUMENTS}/${LegalDocumentsCategory.UPDATE}`]
  }

  /**
   * Performs navigation to the login page.
   * @param rememberDestination Flag whether to remember destination, where user was going or reset to home page.
   */
  toLogIn(rememberDestination = false): void {
    this.router.navigate([this.urlPathLogin(rememberDestination)])
  }

  toTwoFactorAuthentication(): void {
    this.router.navigate([`${NavigationService.AUTHENTICATION}/${NavigationService.TWO_FACTOR_AUTHENTICATION}`])
  }

  toRegister(): void {
    this.router.navigate([`${NavigationService.AUTHENTICATION}/${NavigationService.REGISTER}`])
  }

  toRegisterSocial(type: string): void {
    this.router.navigate([`${NavigationService.AUTHENTICATION}/${NavigationService.REGISTER_FIREBASE}/${type}`])
  }

  toUserSettings(setting: SettingCategory = SettingCategory.ACCOUNT): void {
    this.router.navigate([`${NavigationService.USER_SETTINGS}/${setting}`])
  }

  urlUserSettings(setting: SettingCategory = SettingCategory.ACCOUNT): any[] {
    return [`/${NavigationService.USER_SETTINGS}/${setting}`]
  }

  urlPasswordRestore(): any[] {
    return [`/${NavigationService.PASSWORD_RESTORE}`]
  }

  urlNotifications(): any[] {
    return [`/${NavigationService.NOTIFICATIONS}`]
  }

  toMap(): void {
    this.router.navigate([NavigationService.MAP])
  }

  toFeed(): void {
    this.router.navigate([NavigationService.FEED])
  }

  toEditor(): void {
    this.router.navigate([NavigationService.EDITOR])
  }

  /**
   * Navigates to the profile with {@link charId}.
   */
  toProfile(charId: string): void {
    this.router.navigate([`${NavigationService.PROFILE}/${charId}`])
  }

  /**
   * Returns a router-link url to the desired profile.
   */
  urlProfile(charId: string): any[] {
    return [`/${NavigationService.PROFILE}/${charId}`]
  }

  urlSingleNotification(notification: InboxNotificationResp): any[] {
    return [`/${notification.url}`]
  }

  /**
   * Navigates to the profile with {@link charId} and add {@link custom} param to do some action, like open dialog and so on.
   */
  toProfileCustom(charId: string, custom: string): void {
    this.router.navigate([`${NavigationService.PROFILE}/${charId}/${custom}`])
  }

  toChatChannel(channelId: number): void {
    this.router.navigate([`${NavigationService.CHAT}/${NavigationService.CHAT_CHANNEL}/${channelId}`])
  }

  toUserProfilesDashboard(): void {
    this.router.navigate([NavigationService.DASHBOARD_PROFILES])
  }

  urlUserProfilesDashboard(): any[] {
    return [`/${NavigationService.DASHBOARD_PROFILES}`]
  }

  toChat(): void {
    this.router.navigate([NavigationService.CHAT])
  }

  toProfileCatalog(): void {
    this.router.navigate([NavigationService.PROFILE_CATALOG])
  }

  urlProfileCatalog(): any[] {
    return [`/${NavigationService.PROFILE_CATALOG}`]
  }

  toInternalServerError(): void {
    this.router.navigate([NavigationService.INTERNAL_SERVER_ERROR])
  }

  toProfileServicesEditor(): void {
    this.router.navigate([`${NavigationService.PROFILE_SERVICES_EDITOR}`])
  }

  urlProfileServicesEditor(): any[] {
    return [`/${NavigationService.PROFILE_SERVICES_EDITOR}`]
  }

  toProfileBookings(charId: string, listType: OrderManagerListTypeEnum = OrderManagerListTypeEnum.PENDING): void {
    this.router.navigate([`${NavigationService.PROFILE_BOOKINGS}/${charId}/${NavigationService.PROFILE_BOOKINGS_LIST}/${listType}`])
  }

  urlProfileBookings(charId: string, listType: OrderManagerListTypeEnum = OrderManagerListTypeEnum.PENDING): any[] {
    return [`/${NavigationService.PROFILE_BOOKINGS}/${charId}/${NavigationService.PROFILE_BOOKINGS_LIST}/${listType}`]
  }

  toProfileBookingDetail(charId: string, id: number): void {
    this.router.navigate([`${NavigationService.PROFILE_BOOKINGS}/${charId}/${id}`])
  }

  urlProfileBookingDetail(charId: string, id: number): any[] {
    return [`/${NavigationService.PROFILE_BOOKINGS}/${charId}/${id}`]
  }

  toSupport(elementId?: string): void {
    const path = [NavigationService.SUPPORT]
    if (elementId) {
      path.push(elementId)
    }
    this.router.navigate(path)
  }

  urlSupport(): any[] {
    return [`/${NavigationService.SUPPORT}`]
  }

  /**
   * Returns an url address to be used in [routerLink] to the local documents.
   */
  urlLegalDocuments(type?: LegalDocumentType): any[] {
    const url = [`/${NavigationService.SUPPORT}`]
    if (type) {
      url.push(type)
    }
    return url
  }

  /**
   * Navigates to the {@link lastUrlBeforeAuth} remembered path.
   * If that path is part of authentication flow, the redirects to {@link NavigationService.PROFILE_CATALOG}.
   */
  toLastUrlBeforeAuthentication(): void {
    if (this.lastUrlBeforeAuth.includes(NavigationService.AUTHENTICATION)) {
      this.lastUrlBeforeAuth = NavigationService.PROFILE_CATALOG
    }
    this.router.navigate([this.lastUrlBeforeAuth])
  }

  /**
   * Navigates to the new url without reloading the page.
   *
   * @param route The current URL route.
   * @param newUrl The new URL.
   */
  changeUrl(route: ActivatedRoute, newUrl: string): void {
    this.router.navigate([newUrl], {
      relativeTo: route
    })
  }

  /**
   * Changes the URL parameters of a {@link route}.
   *
   * @param route The current URL route.
   * @param params The parameters to be changed.
   */
  changeUrlParams(route: ActivatedRoute, params: any): void {
    this.router.navigate([], {
      relativeTo: route,
      queryParams: params
    })
  }
}
