import {Component, EventEmitter, Input, Output} from '@angular/core'
import {RegisterFirebaseAbstractComponent} from '../abstract/register-firebase-abstract.component'
import {FirebaseRegisterReq} from 'src/app/service/firebase-auth.service'
import {ProfileType} from '../../../common/profile-type'
import {BackendValidationComponent} from '../../../component/common/backend-validation/backend-validation.component'
import {ButtonComponent} from '../../../component/common/button/button.component'
import {CallResponseComponent} from '../../../component/common/call-response/call-response.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {FrontendValidationComponent} from '../../../component/common/frontend-validation/frontend-validation.component'
import {NgForOf, NgIf} from '@angular/common'
import {RadioButtonModule} from 'primeng/radiobutton'
import {TextInputComponent} from '../../../component/common/form/text-input/text-input.component'
import {VarDirective} from '../../../directive/var.directive'
import {RouterLink} from '@angular/router'
import {UnsavedChangesComponent} from '../../../component/common/unsaved-changes/unsaved-changes.component'
import {AuthenticationModule} from '../authentication.module'
import {PhoneVerificationEmbedComponent} from '../phone-verification-embed/phone-verification-embed.component'

/**
 * Register component which can be embedded in other components. Doesn't contain aby redirections and only emits events.
 */
@Component({
  selector: 'app-register-firebase-embed',
  standalone: true,
  imports: [
    BackendValidationComponent,
    ButtonComponent,
    CallResponseComponent,
    FormsModule,
    FrontendValidationComponent,
    NgForOf,
    NgIf,
    RadioButtonModule,
    ReactiveFormsModule,
    TextInputComponent,
    VarDirective,
    RouterLink,
    UnsavedChangesComponent,
    AuthenticationModule,
    PhoneVerificationEmbedComponent
  ],
  templateUrl: './register-firebase-embed.component.html',
  styleUrl: './register-firebase-embed.component.scss'
})
export class RegisterFirebaseEmbedComponent extends RegisterFirebaseAbstractComponent {

  /**
   * Display name of the user which is being registered.
   * By inserting the name here user is not required to enter it in the form to speed up registration process.
   */
  @Input({required: true})
  displayName: string

  /**
   * Profile type of the user which is being registered.
   * By inserting the type here user is not required to enter it in the form to speed up registration process.
   */
  @Input()
  profileType: ProfileType = ProfileType.USER

  /**
   * Event emitted when the user registration request is invalid.
   */
  @Output()
  invalidRequest = new EventEmitter<void>()

  override initForm(): void {
    super.initForm()

    this.form.patchValue({
      displayName: this.displayName
    })
  }

  createRegisterReq(formData: any): FirebaseRegisterReq {
    return {
      displayName: formData.displayName.trim(),
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      profileType: this.profileType,
      authType: this.authType,
      firebaseUid: formData.firebaseUid
    }
  }

  emptyServiceDataAction(): void {
    this.invalidRequest.emit()
  }
}
