import {OnDestroy, Pipe, PipeTransform} from '@angular/core'
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs'
import {map} from 'rxjs/operators'

/**
 * - Represents countdown timer of the given date, interval and time format.
 * - Don't forget to append '| async' pipe after the countdown.
 */
@Pipe({
  name: 'countdown',
  standalone: true
})
export class CountdownPipe implements PipeTransform, OnDestroy {

  /**
   * BehaviorSubject subscriptions.
   */
  private subs: Subscription

  transform(
    endDate: Date,
    format: 'd' | 'dh' | 'dhm' | 'dhms' | 'hm' = 'dhms',
    intervalMs: number = 1000
  ): Observable<string> {
    const values = new BehaviorSubject(this.updateCountdown(endDate, format))

    this.subs = interval(intervalMs).pipe(
      map(() => this.updateCountdown(endDate, format))
    ).subscribe(it => values.next(it))

    return values.asObservable()
  }

  private updateCountdown(endDate: Date, format: 'd' | 'dh' | 'dhm' | 'dhms' | 'hm'): string {
    const now = new Date()
    const timer = endDate.getTime() - now.getTime()
    let countdownString = ''

    if (timer > 0) {
      const days = Math.floor(timer / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timer / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((timer / 1000 / 60) % 60)
      const seconds = Math.floor((timer / 1000) % 60)

      switch (format) {
        case 'd':
          countdownString = days > 0 ? `${days}d` : ''
          break
        case 'dh':
          countdownString = days > 0 ? `${days}d ` : ''
          countdownString += hours > 0 ? `${hours}h` : ''
          break
        case 'dhm':
          countdownString = days > 0 ? `${days}d ` : ''
          countdownString += hours > 0 ? `${hours}h ` : ''
          countdownString += minutes > 0 ? `${minutes}m` : ''
          break
        case 'dhms':
          countdownString = days > 0 ? `${days}d ` : ''
          countdownString += hours > 0 ? `${hours}h ` : ''
          countdownString += minutes > 0 ? `${minutes}m ` : ''
          countdownString += seconds > 0 ? `${seconds}s` : ''
          break
        case 'hm':
          countdownString = hours > 0 ? `${hours}h ` : ''
          countdownString += minutes > 0 ? `${minutes}m` : ''
          break
      }

    }

    return (countdownString || '0s')
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
