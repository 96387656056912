import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core'
import {MenuItem} from 'primeng/api'
import {EditableComponent} from '../../../component/abstract/editable.component'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {BriefProfileOrderResp, ProfileOrderService} from '../../../service/profile-order.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {Acceptance} from '../../../common/acceptance'
import {NavigationService} from '../../../service/ui/navigation.service'
import {ActivatedRoute, Params} from '@angular/router'
import {NavbarService} from '../../../service/ui/navbar.service'
import {Feature} from '../../../common/feature'
import {FormBuilder, FormGroup} from '@angular/forms'
import {Location} from '@angular/common'
import {OrderManagerListTypeEnum} from '../order-manager-list-type.enum'
import {PLATFORM_BROWSER} from '../../../app.module'
import {fadeAnimation} from '../../../animation/fade.animation'
import {hasFeatures} from '../../../common/profile-type'
import {growAnimation} from '../../../animation/grow.animation'
import {PermissionService} from '../../../service/ui/permission.service'

@Component({
  animations: [fadeAnimation(), growAnimation()],
  selector: 'app-profile-manager',
  templateUrl: './profile-manager.component.html',
  styleUrls: ['./profile-manager.component.scss']
})
export class ProfileManagerComponent extends EditableComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Represents the current previewing profile data.
   */
  data: ProfileResp
  /**
   * A form of radio buttons to filter orders.
   */
  form: FormGroup

  pendingOrderedItems: Page<BriefProfileOrderResp> = newEmptyPage()
  acceptedOrderedItems: Page<BriefProfileOrderResp> = newEmptyPage()
  rejectedOrderedItems: Page<BriefProfileOrderResp> = newEmptyPage()
  awaitingPaymentItems: Page<BriefProfileOrderResp> = newEmptyPage()

  tabItems: MenuItem[] = []
  /**
   * The short abbreviation of the {@link Acceptance}.
   */
  A: typeof Acceptance = Acceptance
  ListType: typeof OrderManagerListTypeEnum = OrderManagerListTypeEnum
  FilterKey: typeof FilterKey = FilterKey
  /**
   * Represents the visibility of a small red dot in the order manager navbar
   * - shows that the artist has at least one pending order present.
   */
  notifDotPendingVisible: boolean
  /**
   * Represents the visibility of a small red dot in the order manager navbar
   * - shows that the user has at least one awaiting payment order present.
   */
  notifDotPaymentsVisible: boolean
  /**
   * Defines visibility of the search order dialog.
   */
  searchOrderDialogVisible: boolean
  /**
   * Active item in order manager navigation.
   */
  activeItem: MenuItem
  /**
   * Radio buttons categories.
   */
  filterCategories: any[] = []
  /**
   * - Contains a selected values of the {@link filterCategories}.
   * - 0 is for the {@link OrderManagerListTypeEnum.ACCEPTED}, while 1 for the {@link OrderManagerListTypeEnum.REJECTED}.
   */
  selectedFilterCategories: any[] = []
  /**
   * Controls the visibility of sidebar.
   */
  sidebarVisible = false
  /**
   * Controls visibility of the bell icon on the button.
   */
  bellVisible: boolean
  /**
   * Passes the {@link window} instance to the HTML.
   */
  window
  /**
   * Whether the current profile can order other profiles.
   */
  canOrder: boolean
  /**
   * Defines item height in pixels for the virtual scrolling.
   */
  protected itemHeight = {
    smallItem: 226,
    largeItem: 375
  }
  /**
   * The current activated path URL params.
   */
  private urlParams: Params
  /**
   * The {@link form} value changes subscription.
   */
  private formSub?: Subscription
  /**
   * Current profile subscription.
   */
  private currentProfileSub?: Subscription
  /**
   * All value change subscriptions.
   */
  private paramSub?: Subscription

  constructor(
    public navigation: NavigationService,
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private orderService: ProfileOrderService,
    private navbarService: NavbarService,
    private permissionService: PermissionService) {
    super()
  }

  ngOnInit(): void {
    // Init window on browser platforms
    if (PLATFORM_BROWSER) {
      this.window = window
    }
    // Set initial loading
    this.loading = true
    this.initTabMenuItems()

    // Current profile data
    this.currentProfileSub = this.profileService.currentProfile.subscribe(async data => {
      this.data = data
      if (data) {
        this.canOrder = hasFeatures(this.data.profileType, Feature.ORDER_PROFILE)
        await this.parseUrl()
        await this.initNotificationBadges()
      }
    })

    // URL Params
    this.paramSub = this.route.params.subscribe(async (params) => {
      this.urlParams = params
      await this.parseUrl()
    })
  }

  /**
   * After content is fully rendered, if user is artist ask for notifications permission.
   */
  ngAfterViewInit(): void {
    if (hasFeatures(this.data.profileType, Feature.BE_ORDERED)) {
      this.permissionService.askNotificationPermission()
    }
  }

  /**
   * Parses the url when the {@link data} and {@link urlParams} are present.
   */
  private async parseUrl(): Promise<void> {
    if (!this.data || !this.urlParams) {
      return
    }
    await this.call(async () => {
      // get profile charId params
      const profileCharId = this.urlParams[NavigationService.PROFILE_CHAR_ID_PARAMETER]
      const bookingList = this.urlParams[NavigationService.PROFILE_BOOKINGS_LIST_PARAM]
      this.urlParams = null // prevents unexpected behavior in profile-switching

      // try switch to the profile
      await firstValueFrom(this.unwrap(this.profileService.switchToProfileCharId(profileCharId)))

      // get list type params
      await this.initWithListType(bookingList)
    })
  }

  /**
   * Clears the url of the order to the base format.
   * Inits items of the tab-menu and form.
   * Updates list of orders from server calling.
   */
  private async initWithListType(listParam: OrderManagerListTypeEnum): Promise<void> {
    const correctListType = Object.values(OrderManagerListTypeEnum).includes(listParam)
    const listType = correctListType ? listParam : OrderManagerListTypeEnum.PENDING

    // Clears the URL to the base format
    const baseUrl = `${NavigationService.PROFILE_BOOKINGS}/${this.data.charId}/${NavigationService.PROFILE_BOOKINGS_LIST}/${listType}`
    this.location.replaceState(baseUrl)

    // inits
    this.activeItem = this.tabItems.find(it => it.id === listType)
    this.initForm()
  }

  /**
   * Specifies if there is any activity in the order manager (pending order accepted, or new order ordered).
   * Visually sets the red dot (empty badge) next to the one of the {@link tabItems}.
   */
  private async initNotificationBadges(): Promise<void> {
    const listNotEmpty = await firstValueFrom(this.unwrap(this.orderService.callHasListAnyOrder()))
    if (this.noServerMessages()) {
      // Profile
      if (this.hasFeatures(this.data.profileType, Feature.BE_ORDERED)) {
        this.notifDotPendingVisible = listNotEmpty
        this.navbarService.isNewActivityOrderManager(this.notifDotPendingVisible)
      }

      // Author
      if (this.hasFeatures(this.data.profileType, Feature.ORDER_PROFILE)) {
        this.notifDotPaymentsVisible = listNotEmpty
        this.navbarService.isNewActivityOrderManager(this.notifDotPaymentsVisible)
      }
    }
  }

  /**
   * Creates and inits the defaulted value of radio buttons.
   */
  private initForm(): void {
    this.initFilter()
    const isAccepted = this.activeItem.id === OrderManagerListTypeEnum.ACCEPTED
    this.form = this.formBuilder.group({
      filter: [this.selectedFilterCategories[isAccepted ? 0 : 1] || this.filterCategories[0]]
    })

    this.formSub?.unsubscribe() // multiple calls
    this.formSub = this.form.valueChanges.subscribe((val) => {
      // While filtering call backend based on activate item.
      if (this.activeItem.id === OrderManagerListTypeEnum.ACCEPTED) {
        this.acceptedOrderedItems = newEmptyPage()
        this.selectedFilterCategories[0] = val.filter
      } else if (this.activeItem.id === OrderManagerListTypeEnum.REJECTED) {
        this.rejectedOrderedItems = newEmptyPage()
        this.selectedFilterCategories[1] = val.filter
      }
    })
  }

  /**
   * Initializes the {@link filterCategories}.
   */
  private initFilter(): void {
    this.filterCategories = [
      {name: $localize`Oldest`, key: FilterKey.OLDEST, icon: 'fa-solid fa-arrow-down-short-wide'},
      {name: $localize`Latest`, key: FilterKey.LATEST, icon: 'fa-solid fa-arrow-up-short-wide'}
    ]
    if (this.activeItem.id === OrderManagerListTypeEnum.ACCEPTED) {
      this.filterCategories.push(
        {name: $localize`Confirmed`, key: FilterKey.CONFIRMED, icon: 'fa-solid fa-map-location-dot'})
    }
  }

  /**
   * Sets the {@link filter}'s value to {@link FilterKey.CONFIRMED}.
   */
  setFilterToConfirmed(): void {
    this.form.controls.filter.setValue(this.filterCategories.find(it => it.key === FilterKey.CONFIRMED))
  }

  /**
   * Calls server to get Page pending orders.
   */
  callListProfileOrderPending(pageNum: number): Observable<Page<BriefProfileOrderResp>> {
    return this.unwrap(this.orderService.callListProfileOrder({
      authorAcceptance: Acceptance.PENDING,
      profileAcceptance: Acceptance.PENDING,
      upTo: null,
      start: null,
      end: null,
      page: pageNum
    }))
  }

  /**
   * Calls server to get Page orders prepared to pay.
   */
  callListProfileOrderAwaitingPayment(pageNum: number): Observable<Page<BriefProfileOrderResp>> {
    return this.unwrap(this.orderService.callListProfileOrder({
      authorAcceptance: Acceptance.PENDING,
      profileAcceptance: Acceptance.ACCEPTED,
      upTo: null,
      start: null,
      end: null,
      page: pageNum
    }))
  }

  /**
   * Calls server to get Page accepted FILTERED orders.
   */
  callFilteredListProfileOrderAccepted(pageNum: number): Observable<Page<BriefProfileOrderResp>> {
    return this.unwrap(this.orderService.callAcceptedFilterProfileOrders({
      upTo: null,
      start: null,
      end: null,

      oldest: this.form.value.filter.key === FilterKey.OLDEST,
      confirmed: this.form.value.filter.key === FilterKey.CONFIRMED,

      page: pageNum
    }))
  }

  /**
   * Calls server to get Page rejected FILTERED orders.
   */
  callFilteredListProfileOrderRejected(pageNum: number): Observable<Page<BriefProfileOrderResp>> {
    return this.unwrap(this.orderService.callRejectedFilteredProfileOrders({
      upTo: null,
      start: null,
      end: null,

      oldest: this.form.value.filter.key === FilterKey.OLDEST,
      confirmed: false,

      page: pageNum
    }))
  }

  /**
   * Inits items in the booking tab menu.
   */
  private initTabMenuItems(): void {
    this.tabItems = [
      {
        id: OrderManagerListTypeEnum.PENDING,
        label: $localize`Pending`,
        icon: 'fa-regular fa-clock',
        // Don't refactor into the url
        command: () => {
          this.navigation.toProfileBookings(this.data.charId, OrderManagerListTypeEnum.PENDING)
        }
      },
      {
        id: OrderManagerListTypeEnum.AWAITING_PAYMENT,
        label: $localize`Awaiting Payment`,
        icon: 'fa-regular fa-credit-card',
        command: () => {
          this.navigation.toProfileBookings(this.data.charId, OrderManagerListTypeEnum.AWAITING_PAYMENT)
        }
      },
      {
        id: OrderManagerListTypeEnum.ACCEPTED,
        label: $localize`Accepted / Confirmed`,
        icon: 'fa-solid fa-check',
        command: () => {
          this.navigation.toProfileBookings(this.data.charId, OrderManagerListTypeEnum.ACCEPTED)
        }
      },
      {
        id: OrderManagerListTypeEnum.REJECTED,
        label: $localize`Rejected / Disputed`,
        icon: 'fa-solid fa-heart-crack',
        command: () => {
          this.navigation.toProfileBookings(this.data.charId, OrderManagerListTypeEnum.REJECTED)
        }
      }
    ]
  }

  ngOnDestroy(): void {
    this.currentProfileSub?.unsubscribe()
    this.formSub?.unsubscribe()
    this.paramSub?.unsubscribe()
  }
}

/**
 * All available keys in the {@link filterCategories}.
 */
enum FilterKey {
  OLDEST = 'OLDEST',
  LATEST = 'LATEST',
  CONFIRMED = 'CONFIRMED'
}
