<app-dialog (discard)="closeEditComponent()"
            *ngIf="show"
            [(show)]="show"
            [allowSaveOption]="false"
            [component]="this"
            [draggable]="false"
            discardLabel="Cancel"
            header="Scan the QR">

  <zxing-scanner (scanSuccess)="onCodeResult($event)" *ngIf="!qrCodeScanned"
                 [(device)]="selectedDevice"></zxing-scanner>

  <div *ngIf="qrCodeScanned"
       class="p-d-flex p-flex-column p-ai-center p-jc-center">

    <div *ngIf=success class="p-d-flex p-flex-column p-ai-center p-jc-center">
      <h3>Authentification successful</h3>
      <h4>Money has been sent to your account</h4>
    </div>
    <app-backend-validation [check]="ServerMessage.PROFILE_ORDER_NOT_FOUND" [serverMessages]="serverMessages"
                            [showAsBox]="true">
      Authentification failed. Try again.
    </app-backend-validation>

    <app-backend-validation [check]="ServerMessage.PROFILE_ORDER_ALREADY_CONFIRMED" [serverMessages]="serverMessages"
                            [showAsBox]="true">
      This order is canceled. Please try again with different order.
    </app-backend-validation>

    <app-backend-validation [check]="ServerMessage.QR_CANNOT_BE_SCANNED_BY_ORDERED_ARTIST"
                            [serverMessages]="serverMessages"
                            [showAsBox]="true">
      You cannot scan QR code by yourself.
    </app-backend-validation>

    <app-no-content *ngIf="success === null && noServerMessages()"> Wait a moment</app-no-content>

    <app-button (clicked)="openScanner()" *ngIf="!success && serverMessages?.length > 0" icon="fas fa-rotate"
                label="Try again" styleClass="p-m-2" type="danger"></app-button>
  </div>
</app-dialog>
