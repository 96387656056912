<app-button (clicked)="login()"
            [disabled]="buttonDisabled"
            [highlight]="false"
            [loading]="saving"
            type="secondary"
            icon="fa-brands fa-{{ authType | lowercase }}"
            label="Use with {{ authType | titlecase }}"
            i18n-label
            [styleClass]="getStyleClass()">
</app-button>

<app-backend-validation [serverMessages]="serverMessages"
                        [check]="ServerMessage.FIREBASE_EMAIL_ALREADY_REGISTERED"
                        styleClass="center-with-padding" i18n>
  Already registered with another provider. Try to login.
</app-backend-validation>

<app-backend-validation [serverMessages]="serverMessages"
                        [check]="ServerMessage.FIREBASE_ID_TOKEN_INVALID" i18n>
  Problem with Google authentication. Try again later.
</app-backend-validation>

<app-backend-validation [serverMessages]="serverMessages"
                        [check]="ServerMessage.FIREBASE_GENERAL_ERROR" i18n>
  Problem with Google authentication. Try again later.
</app-backend-validation>
