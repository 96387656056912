import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import {firstValueFrom, Observable} from 'rxjs'
import {ProfileOrderResp, ProfileOrderService} from '../../../service/profile-order.service'
import {BriefProfileResp, ProfileResp} from '../../../service/profile.service'
import {EditableComponent} from '../../abstract/editable.component'
import {throwAppError} from '../../../utils/log.utils'
import {MenuItem, SharedModule} from 'primeng/api'
import {growAnimation} from '../../../animation/grow.animation'
import {LatLng} from 'leaflet'
import {LeafletService} from '../../../service/ui/leaflet.service'
import {RadiusMarker} from '../../map/map.helper'
import {toRadians} from '../../../utils/utils'
import {addDays, minusMinutes} from '../../../utils/date.utils'
import {OrderReviewResp, ReviewService} from '../../../service/review.service'
import {FormBuilder, FormGroup} from '@angular/forms'
import {Acceptance} from '../../../common/acceptance'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {ServerMessage} from '../../../common/server-message'
import {NavigationService} from '../../../service/ui/navigation.service'
import {UserResp, UserService} from '../../../service/user.service'
import {Restrictions} from '../../../common/restrictions'
import {environment} from '../../../../environments/environment'
import {MapComponent, UserGeolocationPosition} from '../../map/map.component'
import {PermissionRequest} from '../../../service/ui/permission.service'
import {DialogStepsComponent} from '../../common/dialog/dialog-steps/dialog-steps.component'
import {AsyncPipe, NgIf} from '@angular/common'
import {ButtonComponent} from '../../common/button/button.component'
import {SkeletonModule} from 'primeng/skeleton'
import {CountdownPipe} from '../../../pipe/countdown-timer.pipe'
import {BackendValidationComponent} from '../../common/backend-validation/backend-validation.component'
import {VarDirective} from '../../../directive/var.directive'
import {HintComponent} from '../../common/hint/hint.component'
import {RatingComponent} from '../../common/rating/rating.component'
import {TextInputComponent} from '../../common/form/text-input/text-input.component'
import {CallResponseComponent} from '../../common/call-response/call-response.component'
import {RouterLink} from '@angular/router'
import {UrlDirective} from '../../../directive/url.directive'
import {InitDirective} from '../../../directive/init.directive'

@Component({
  animations: [growAnimation()],
  selector: 'app-order-state',
  templateUrl: './order-state.component.html',
  styleUrls: ['./order-state.component.scss'],
  imports: [
    DialogComponent,
    DialogStepsComponent,
    NgIf,
    ButtonComponent,
    MapComponent,
    SkeletonModule,
    CountdownPipe,
    AsyncPipe,
    BackendValidationComponent,
    VarDirective,
    HintComponent,
    RatingComponent,
    TextInputComponent,
    CallResponseComponent,
    SharedModule,
    RouterLink,
    UrlDirective,
    InitDirective
  ],
  standalone: true
})
export class OrderStateComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * The order to be confirmed.
   */
  @Input()
  order: ProfileOrderResp
  /**
   * The current logged profile.
   */
  @Input()
  profile: ProfileResp
  /**
   * Fires when the user has confirmed the arrival.
   */
  @Output()
  confirmed = new EventEmitter<boolean>()
  /**
   * Fires when the user has rated the other profile.
   */
  @Output()
  rated = new EventEmitter<OrderReviewResp>()

  /**
   * When the state dialog is done for now and it shouldn't be executed again at this moment.
   */
  @Output()
  done = new EventEmitter<boolean>()

  @ViewChild('dialog')
  dialog: DialogComponent
  /**
   * Whether the author or artist confirmed the arrival, depends on the {@link isAuthor} property.
   */
  isConfirmed: boolean
  /**
   * Whether the author or artist rated the opposite profile, depends on the {@link isAuthor} property.
   */
  isRated: boolean

  /**
   * The list of steps for this component.
   */
  steps: MenuItem[] = []
  /**
   * The current visible step in the dialog.
   */
  currentStep = 0

  /**
   * The latitude and longitude of the {@link order}.
   */
  coords: LatLng
  /**
   * Defines the radius of the acceptable area for the ordered profile.
   */
  radius = environment.orderAcceptanceAreaRadius
  /**
   * Displays the radius circle on a map.
   */
  radiusMarker: RadiusMarker = {
    radius: this.radius
  }

  /**
   * Controls the visibility of the dialog that alerts consequences of cancelling the paid order.
   */
  profileWillNotComeWarningVisible: boolean
  /**
   * Defines whether the current logged profile is an author of the order. Otherwise, it is an ordered profile.
   */
  isAuthor: boolean
  /**
   * If the current viewer is author, this property will contain the ordered profile and vice versa.
   */
  oppositeProfile: BriefProfileResp
  /**
   * Defines whether the profile is in the location of the order.
   */
  isOnLocation: boolean
  /**
   * The map component needs to be delayed to be visible on the screen.
   */
  mapVisible = false
  /**
   * Rating form.
   */
  form: FormGroup
  /**
   * Current visible amount of stars in rating.
   */
  reviewStars: number
  /**
   * Shows the additional hint, when the user clicked on the later button.
   */
  showLaterHint: boolean
  /**
   * The loading property for the process of checking whether the ordered profile has been on the location.
   */
  disputeRequest: boolean
  /**
   * Contains the result from the backend when the author wants to dispute the order.
   */
  profileArrivedCheck: boolean
  /**
   * Represents the currently logged user.
   */
  loggedUser: UserResp
  /**
   * Displays the countdown date until what time the profile can accept the arrival.
   */
  confirmArrivalUntil: Date
  /**
   * Determines the datetime when the rating feature is available.
   */
  rateStart: Date
  /**
   * Represents the current datetime.
   */
  currentDate = new Date()
  /**
   * Disables hold when a user clicks on 'Later' button.
   */
  disableHold: boolean
  /**
   * Geolocation permission request dialog content for a map component.
   */
  geolocationPermissionReq: PermissionRequest = {
    name: 'geolocation',
    required: true,
    // eslint-disable-next-line max-len
    reason: $localize`<b>We need to check that you arrived</b> safe and sound at the place of the event. Please <b>allow the location</b> for this check <b>in your settings</b>.`
  }
  /**
   * The current user location.
   */
  private userLocation: UserGeolocationPosition

  /**
   * To regularly update the {@link currentDate}.
   */
  private dateRefreshInterval

  protected trans = {
    author_placeholder: $localize`Very well performed!`,
    profile_placeholder: $localize`Very nice and friendly customer!`
  }

  constructor(
    private orderService: ProfileOrderService,
    private reviewService: ReviewService,
    private formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef,
    public navigation: NavigationService,
    public userService: UserService,
    private leaflet: LeafletService) {
    super()
  }

  ngOnInit(): void {
    this.loggedUser = this.userService.user.getValue()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.order?.currentValue || changes.profile?.currentValue) && this.order && this.profile) {
      // show the map
      setTimeout(() => {
        this.mapVisible = true
      }, 100)
      this.enableDateRefreshInterval(true)
      this.updateValues()
    }
  }

  /**
   * Fires when the user clicked on the Later action button.
   */
  later(): void {
    if (!this.showLaterHint) {
      this.dialog?.scrollBottom(200)
      this.showLaterHint = true
      return
    }
    this.closeDialog(0)
  }

  /**
   * - If the current profile {@link isAuthor}, confirms the profile's arrival.
   * - Otherwise, it uploads a user's device location to the server, since the current logged profile is an ordered profile.
   * - If the {@link currentStep} is not the last one, it will redirect
   */
  confirmArrival(): void {
    this.call(async () => {
      let resp
      // Author
      if (this.isAuthor) {
        resp = await firstValueFrom(this.callConfirmOrderArrival())
        if (this.isMessage(ServerMessage.PROFILE_ORDER_ALREADY_CONFIRMED)) {
          resp = true
          await this.resetApiAfter(2000)
        }

        // Profile
      } else {
        const location = this.userLocation?.coords
        if (!location) {
          this.pushToMessages(ServerMessage.GEOLOCATION_FAILED)
          return
        }
        resp = await firstValueFrom(this.callNewUserLocation(location))
      }

      // validate response
      if (resp && this.noServerMessages()) {
        this.confirmLocally()
        this.confirmed.emit(true)
        this.resolveState()
      }
    }, err => {
      this.pushToMessages(err.message)
    })
  }

  /**
   * Fires when the author calls that the ordered profile didn't come to the event.
   */
  authorProfileNotArrived(): void {
    this.setDisputeRequest(async () => {
      await this.call(async () => {
        this.profileArrivedCheck = await firstValueFrom(this.callCheckProfileArrival())
        this.order.profileArrived = this.profileArrivedCheck // update the order
        if (!this.profileArrivedCheck) {
          await this.cancelPaidOrder()
        }
      })
    })
  }

  /**
   * - Updates the acceptance to the rejected by the current logged profile side.
   */
  async cancelPaidOrder(): Promise<void> {
    await this.setDisputeRequest(async () => {
      const old = this.steps[this.currentStep][`successMessage`]
      await this.callAndFinish(async () => {
        this.steps[this.currentStep][`successMessage`] = $localize`Dispute process has started.`
        const resp = await firstValueFrom(this.callRejectPaidOrder())
        if (resp && this.noServerMessages()) {
          // close the dialog immediately without rating
          this.disableHold = true
          this.createDisputeLocally()
          this.done.emit(true)
        }
      }, () => {
        this.steps[this.currentStep][`successMessage`] = old
      })
    })
  }

  /**
   * - Saves a user typed rating.
   * - Always tries to finish this dialog since it is always the last step.
   */
  uploadRating(): void {
    this.callAndFinish(async () => {
      const review = await firstValueFrom(this.callNewReview())
      if (review && this.noServerMessages()) {
        this.appendReviewToOrder(review)
        this.rated.emit(review)
        this.done.emit(true)
      }
      this.evaluateAndFinish()
    })
  }

  /**
   * - Resolves the dialog state.
   * - If the dialog doesn't contain any next step, it will be closed.
   * Otherwise, it will change the content and reset the dialog.
   */
  private resolveState(): void {
    // this will not close the dialog because if the <app-dialog> [hold] condition meets,
    // However, it is required when no other step is present
    this.evaluateAndFinish()

    // move to rating if it is present
    if (this.currentStep !== this.steps.length - 1) {
      // this will display the custom <app-call-response> and let it visible for a standard dialog finish time
      setTimeout(() => {
        // since we are re-using the same dialog instance, the resetApi must be called immediately to prevent the auto close the dialog
        this.resetApi()
        // change the dialog content
        this.currentStep++
      }, DialogComponent.DIALOG_STATUS_DURATION)
      return
    }
    this.done.emit(true)
  }

  /**
   * Calls the API to check the profile arrival.
   */
  private callCheckProfileArrival(): Observable<boolean> {
    return this.unwrap(this.orderService.callCheckProfileArrival({
      orderId: this.order.id
    }))
  }

  /**
   * - Calls the server API to update the author's confirmation of artist's arrival.
   */
  private callConfirmOrderArrival(): Observable<boolean> {
    return this.unwrap(this.orderService.callConfirmOrderArrival({
      orderId: this.order.id
    }))
  }

  /**
   * - Calls the server API to reject the current paid order.
   */
  private callRejectPaidOrder(): Observable<boolean> {
    return this.unwrap(this.orderService.callAcceptanceProfileOrder({
      id: this.order.id,
      acceptance: Acceptance.REJECTED
    }))
  }

  /**
   * Updates the {@link userLocation} and checks whether the user is in location.
   */
  onUserLocationChange(pos: UserGeolocationPosition): void {
    this.userLocation = pos
    this.checkIfInLocation()
  }

  /**
   * Initializes the {@link coords} property.
   */
  private initCoords(): void {
    const address = this.order.address
    if (this.leaflet.isReady()) {
      this.coords = this.leaflet.latLng(address.lat, address.lng)
      this.radiusMarker.latLng = this.coords
      this.radiusMarker = {...this.radiusMarker}
    }
  }

  /**
   * Initializes the {@link isAuthor}, and {@link oppositeProfile} property.
   */
  private initAuthor(): void {
    if (this.order && this.profile) {
      if (this.order.author.profileId === this.profile.profileId) {
        this.isAuthor = true
      } else if (this.order.profile.profileId === this.profile.profileId) {
        this.isAuthor = false
      } else {
        throwAppError('OrderState', $localize`The ${this.profile.charId} is not a part of order ${this.order.id}`)
      }
      this.oppositeProfile = (this.isAuthor) ? this.order.profile : this.order.author
    }
  }

  /**
   * Calls the server API to upload a new user location.
   */
  private callNewUserLocation(location: GeolocationPosition): Observable<boolean> {
    return this.unwrap(this.orderService.callConfirmLocation({
      lat: location.coords.latitude,
      lng: location.coords.longitude,
      date: new Date(location.timestamp),
      orderId: this.order.id
    }))
  }

  /**
   * Calls the server API to create a new review.
   */
  private callNewReview(): Observable<OrderReviewResp> {
    return this.unwrap(this.reviewService.callNewReview({
      orderId: this.order.id,
      stars: this.reviewStars,
      text: this.form.value.text || null
    }))
  }

  /**
   * Updates the {@link isOnLocation} when the user is in the location.
   */
  private checkIfInLocation(): void {
    const userCoords = this.userLocation?.coords?.coords
    if (!userCoords || this.userLocation?.err || !this.coords) {
      this.isOnLocation = false
      return
    }

    const earthRadius = 6371 // in kilometers

    const userLat = userCoords.latitude
    const centerLat = this.coords.lat

    const dLat = toRadians(centerLat - userLat)
    const dLng = toRadians(this.coords.lng - userCoords.longitude)

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(toRadians(userLat)) * Math.cos(toRadians(centerLat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = earthRadius * c * 1000.0
    this.isOnLocation = distance <= this.radius
  }

  /**
   * Calls the several functions which updates the component properties.
   */
  private updateValues(): void {
    this.initCoords()
    this.initAuthor()
    this.initStepsProperties()
    this.initRatingForm()
    this.changeRef.detectChanges()
  }

  /**
   * Initializes the {@link isConfirmed}, {@link isRated} and {@link steps} properties.
   */
  private initStepsProperties(): void {
    const start = this.order.calendarItem.start
    const end = this.order.calendarItem.end
    const now = new Date()

    this.isConfirmed = (this.isAuthor) ? !!this.order.authorConfirmedAt : (this.order.profileArrived || end <= this.currentDate)
    this.isRated = this.order.reviews.some(it => it.author.profileId === this.profile.profileId)

    const twoDaysDL = addDays(end, Restrictions.MAX_REVIEW_DAYS_AFTER_ORDER)  // two days deadline
    const arrivalStart = minusMinutes(start, Restrictions.PROFILE_ORDER_START_BEFORE_MINUTES) // event start minus 15 minutes
    this.rateStart = minusMinutes(end, Restrictions.PROFILE_ORDER_END_BEFORE_MINUTES) // event end minus 15 minutes

    this.confirmArrivalUntil = (this.isAuthor) ? twoDaysDL : (minusMinutes(end, Restrictions.PROFILE_ORDER_END_BEFORE_MINUTES))

    const dispute = this.order.dispute
    const disputeDL = addDays(dispute?.modifiedAt || now, Restrictions.MAX_REVIEW_DAYS_AFTER_ORDER)

    this.steps = []
    if (!this.isConfirmed && now >= arrivalStart && now <= this.confirmArrivalUntil && !dispute) {
      const step = {
        label: $localize`Arrival`,
        icon: 'fa-solid fa-location-dot',
        id: 'confirm'
      }
      step['successMessage'] = $localize`Confirmed successfully.`
      this.steps.push(step)
    }

    if (!this.isRated && now >= this.rateStart && ((!dispute && now < twoDaysDL) || (!dispute?.freeze && now < disputeDL))) {
      this.enableDateRefreshInterval(false)
      const step = {
        label: $localize`Rate`,
        icon: 'fa-solid fa-star',
        id: 'rate'
      }
      step['successMessage'] = $localize`Profile rated successfully.`
      this.steps.push(step)
    }
  }

  /**
   * Initializes the rating form.
   */
  private initRatingForm(): void {
    const review = this.order.reviews.find(it => it.author.profileId === this.profile.profileId)
    this.form = this.formBuilder.group({
        text: [review?.text || this.form?.value.text || '']
      }
    )
  }

  /**
   * Enables the {@link disputeRequest} before the {@link fun} and disables after the execution.
   */
  private async setDisputeRequest(fun: () => Promise<void>): Promise<void> {
    this.disputeRequest = true
    await fun()
    this.disputeRequest = false
  }

  /**
   * Locally updates the {@link order} confirmation properties.
   */
  private confirmLocally(): void {
    if (this.isAuthor) {
      this.order.authorConfirmedAt = new Date()
    } else {
      this.order.profileArrived = true
    }

    // Remove from global requirements list
    const pendingOrders = this.orderService.ordersToRequiresAction.getValue() || []
    const index = pendingOrders.findIndex(it => it.id === this.order.id)
    pendingOrders.splice(index, 1)
    this.orderService.ordersToRequiresAction.next(pendingOrders)
  }

  /**
   * Appends a {@link review} to the {@link order}.
   */
  private appendReviewToOrder(review: OrderReviewResp): void {
    if (!this.order.reviews) {
      this.order.reviews = []
    }
    if (!this.order.reviews.some(it => it.author.profileId === this.profile.profileId)) {
      this.order.reviews.push(review)
    }
  }

  /**
   * Creates a fake dispute for the {@link order} because of the {@link profile} has started  a dispute process.
   */
  private createDisputeLocally(): void {
    if (this.isAuthor) {
      this.order.authorAcceptance = Acceptance.REJECTED
    } else {
      this.order.profileAcceptance = Acceptance.REJECTED
    }
    this.order.dispute = {
      createdBy: this.profile.profileId,
      freeze: this.isAuthor, // if profile -> false (I will not come) | author -> true (Artist didn't come)
      modifiedAt: new Date(),
      createdByObject: this.profile
    }
  }

  /**
   * Closes this dialog with an optional delay. Also takes care about the hold condition.
   */
  private closeDialog(delay: number = DialogComponent.DIALOG_STATUS_DURATION): void {
    this.disableHold = true
    setTimeout(() => {
      this.dialog?.onDiscard()
    }, delay)
  }

  /**
   * Starts refreshing the {@link currentDate} after each minute to update the UI.
   */
  private enableDateRefreshInterval(enable: boolean): void {
    if (enable) {
      this.enableDateRefreshInterval(false)
      this.dateRefreshInterval = setInterval(() => {
        this.currentDate = new Date()
        this.initStepsProperties()
      }, 1000)
    } else {
      clearInterval(this.dateRefreshInterval)
    }
  }

  ngOnDestroy(): void {
    this.enableDateRefreshInterval(false)
  }
}
