import {Injectable} from '@angular/core'
import {Acceptance} from '../common/acceptance'
import {Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Endpoint} from '../common/endpoints'
import {BriefProfileResp} from './profile.service'
import {ApiService} from './api.service'

@Injectable({
  providedIn: 'root'
})
export class HostProfileService extends ApiService {

  constructor() {
    super()
  }

  callNewHostProfile(req: NewHostProfileReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_HOST_PROFILE_URL, req)
  }

  callCountPendingHostProfiles(req: FindHostPendingItemsReq): Observable<BaseResp<number>> {
    return this.post(Endpoint.PROFILE_COUNT_PENDING_HOST_PROFILES_URL, req)
  }

  callFindPendingHostProfiles(req: FindHostPendingItemsReq): Observable<BaseResp<FindHostPendingItemsResp>> {
    return this.post(Endpoint.PROFILE_FIND_PENDING_HOST_PROFILES_URL, req)
  }

  callUpdateHostProfileSeen(req: UpdateHostProfileSeenReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_HOST_PROFILE_SEEN_URL, req)
  }

  callUpdateHostProfileHostSeen(req: UpdateHostProfileHostSeenReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_HOST_PROFILE_HOST_SEEN_URL, req)
  }

  callUpdateHostProfileAcceptance(req: UpdateHostProfileAcceptanceReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_UPDATE_HOST_PROFILE_ACCEPTANCE_URL, req)
  }

  callUpdateHostProfileHostAcceptance(req: UpdateHostProfileHostAcceptanceReq): Observable<BaseResp<HostProfileResp>> {
    return this.post(Endpoint.PROFILE_UPDATE_HOST_PROFILE_HOST_ACCEPTANCE_URL, req)
  }

  callDeleteHostProfile(req: DeleteHostReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_DELETE_HOST_PROFILE, req)
  }

}

export interface NewHostProfileReq {
  profileId: number
  hostProfileId: number
}

export interface FindHostPendingItemsReq {
  profileId: number
  date: Date
}

export interface FindHostPendingItemsResp {
  pendingHostItems: HostPendingItemResp[]
  pendingItems: HostPendingItemResp[]
}

export interface HostPendingItemResp {
  seenAt?: Date
  acceptance: Acceptance
  modifiedAt: Date

  hostSeenAt?: Date
  hostAcceptance: Acceptance
  hostModifiedAt?: Date
  start: Date
  end: Date
  profile: BriefProfileResp
}

export interface UpdateHostProfileSeenReq {
  profileId: number
  hostProfileId: number
  seenAt: Date
}

export interface UpdateHostProfileHostSeenReq {
  profileId: number
  hostProfileId: number
  hostSeenAt: Date
}

export interface UpdateHostProfileAcceptanceReq {
  hostProfileId: number
  profileId: number

  acceptance: Acceptance
}

export interface UpdateHostProfileHostAcceptanceReq {
  hostProfileId: number
  profileId: number

  hostAcceptance: Acceptance
  start: Date
  end: Date
}

export interface DeleteHostReq {
  profileId: number
}

export interface HostProfileResp {
  host: BriefProfileResp
  seenAt?: Date
  acceptance: Acceptance
  modifiedAt: Date

  hostSeenAt?: Date
  hostAcceptance: Acceptance
  hostModifiedAt?: Date
  start?: Date
  end?: Date
}
