import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {delay, filter, firstValueFrom, Observable, Subscription} from 'rxjs'
import {LogoutReq, UserService} from '../../../service/user.service'
import {NavigationService} from '../../../service/ui/navigation.service'
import {ProfileService} from '../../../service/profile.service'
import {FcmService} from '../../../service/fcm.service'
import {CookiesSettingsService} from '../../../service/cookies-settings.service'
import {BasketService} from '../../../service/basket.service'
import {LanguageService} from '../../../service/language.service'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {StorageService} from '../../../service/storage.service'

/**
 * Dialog component, which traps user from leaving the page before log out is finished.
 */
@Component({
  selector: 'app-logout',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent extends EditableComponent implements OnInit, OnDestroy {

  /**
   * Defined type for HTML templates.
   */
  readonly US: typeof UserService = UserService

  /**
   * Subscription to {@link US.logoutRequest}.
   */
  private logoutSub?: Subscription

  constructor(
    private userService: UserService,
    private navigation: NavigationService,
    private profileService: ProfileService,
    private fcmService: FcmService,
    private cookiesSettingsService: CookiesSettingsService,
    private basketService: BasketService,
    private languageService: LanguageService,
    private storageService: StorageService,
    private changeRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.initLogoutSub()
  }

  /**
   * Initializes {@link Subscription} to {@link US.postLogout}
   */
  private initLogoutSub(): void {
    this.logoutSub = this.userService.logoutRequest
      .pipe(filter(req => req != null))
      .subscribe(async req => {
        this.show = req.showDialog
        this.changeRef.detectChanges()

        if (this.show || req.performRequest) {
          await this.logout(req)
        } else {
          this.postLogout(req.pathToNavigate)
        }
      })
  }

  /**
   * Calls appropriate method by request parameters.
   * @param req LogoutReq received from {@link userService}.
   */
  private async logout(req: LogoutReq): Promise<void> {
    return this.callAndFinish(async () => {
      const result = await firstValueFrom(req.logoutAll ? this.callLogoutAll(req.delay) : this.callLogout(req.delay))
      this.saving = false

      // if some error occurred, then return from function
      if (!result) {
        return
      }

      // notify other tabs in the browser
      this.userService.loginChannel.postMessage(false)

      // finish logging out by clearing storages
      this.postLogout(req.pathToNavigate)
    })
  }

  /**
   * Clears the user data from local, session, cookie storage and language service.
   * @param pathToNavigate Where the user should be navigated after successful logout.
   */
  private postLogout(pathToNavigate: string): void {
    this.fcmService.logout()
    this.cookiesSettingsService.clearAll()
    this.profileService.clearData()
    this.basketService.clearBasket()
    this.languageService.setUserLanguage(null)
    this.userService.updateUser(null)
    this.storageService.clearAll()

    this.show = false
    this.resetApi()
    this.changeRef.detectChanges()

    // after delay, navigate based on condition
    setTimeout(() => {
      this.navigation.toProvidedPath(pathToNavigate)
    }, DialogComponent.DIALOG_CLOSE_DURATION + 250)
  }

  /**
   * Calls server to log out CURRENT sessions of current user.
   * Emits value after specified delay.
   * @param callDelay Time after which value will be emitted.
   */
  private callLogout(callDelay = 0): Observable<boolean> {
    return this.unwrap(this.userService.callLogout().pipe(delay(callDelay)))
  }

  /**
   * Calls server to log out ALL sessions of current user.
   * Emits value after specified delay.
   * @param callDelay Time after which value will be emitted.
   */
  private callLogoutAll(callDelay = 0): Observable<boolean> {
    return this.unwrap(this.userService.callLogoutAll().pipe(delay(callDelay)))
  }

  ngOnDestroy(): void {
    this.logoutSub?.unsubscribe()
  }
}
