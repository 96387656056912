import {Component, Input, OnInit} from '@angular/core'
import {EditableComponent} from '../../../../../component/abstract/editable.component'
import {ProfileOrderResp} from '../../../../../service/profile-order.service'
import {minutesDifference} from '../../../../../utils/date.utils'

/**
 * - Shows a draft of a contract between {@link ProfileOrderResp.author} and {@link ProfileOrderResp.profile}.
 * - Used to display the contract upfront to interested parties before the PDF gets generated.
 * - ### Be careful, every change in this file needs to be updated in the backend Contract-Generation service as well!
 */
@Component({
  selector: 'app-contract-preview',
  templateUrl: './contract-preview.component.html',
  styleUrls: ['./contract-preview.component.scss']
})
export class ContractPreviewComponent extends EditableComponent implements OnInit {

  /**
   * The source of order data.
   */
  @Input()
  order: ProfileOrderResp
  /**
   * The revenue for the performer (order total + additional increased price).
   */
  @Input()
  orderTotal: number
  /**
   * Contains the place of the order where the performer will perform.
   */
  addressStr: string
  /**
   * Duration of the order in minutes.
   */
  duration: number

  constructor() {
    super()
  }

  ngOnInit(): void {
    // Order address string
    const addr = this.order.address
    this.addressStr = `${addr.line1}, `
    if (addr.line2) {
      this.addressStr += ` ${addr.line2}, `
    }
    this.addressStr += `${addr.postalCode} ${addr.city}, ${addr.state}`

    // Time duration in minutes
    const ci = this.order.calendarItem
    this.duration = minutesDifference(ci.start, ci.end)
  }
}
