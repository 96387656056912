import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {GetReviewsReq, OrderReviewResp, ReviewService} from '../../../service/review.service'
import {Observable, Subscription} from 'rxjs'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {BriefProfileResp, OrderableProfileResp, ProfileResp, ProfileService} from '../../../service/profile.service'
import {RatingComponent} from '../../common/rating/rating.component'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {LazyListComponent} from '../../common/list/lazy-list/lazy-list.component'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {ProfileNamesComponent} from '../../common/profile-names/profile-names.component'
import {DatePipe, NgForOf, NgIf} from '@angular/common'
import {SkeletonModule} from 'primeng/skeleton'
import {SharedModule} from 'primeng/api'
import {ProfileType} from '../../../common/profile-type'
import {BaseResp} from '../../../rest/base-resp'

@Component({
  selector: 'app-profile-rate',
  templateUrl: './profile-rate.component.html',
  styleUrls: ['./profile-rate.component.scss'],
  imports: [
    RatingComponent,
    DialogComponent,
    LazyListComponent,
    AvatarComponent,
    ProfileNamesComponent,
    DatePipe,
    NgIf,
    SkeletonModule,
    NgForOf,
    SharedModule
  ],
  standalone: true
})
export class ProfileRateComponent extends EditableComponent implements OnInit, OnDestroy {
  /**
   * Rating of this profile will be displayed.
   */
  @Input()
  profile: BriefProfileResp | ProfileResp | OrderableProfileResp
  /**
   * Input field which defines if the list of reviews is visible or just count of them.
   */
  @Input()
  countVisible: boolean
  /**
   * Controls whether the user can preview the details of orders after clicking on stars.
   */
  @Input()
  withDetails: boolean
  /**
   * Page of ReviewResp values.
   */
  reviews: Page<OrderReviewResp> = newEmptyPage()
  /**
   * Represents average rating of profile.
   */
  avgRating: number
  /**
   * The current logged user profile.
   */
  currentProfile: ProfileResp
  /**
   * Contains the current profile subscription
   */
  private currentProfileSub: Subscription

  constructor(
    private profileService: ProfileService,
    private reviewService: ReviewService) {
    super()
  }


  ngOnInit(): void {
    this.initProperties()
    this.currentProfileSub = this.profileService.currentProfile.subscribe(profile => {
      this.currentProfile = profile
    })
  }

  /**
   * Method that calculates avg count of stars to show to user
   */
  initProperties(): void {
    this.avgRating = this.profile.rating.avg
    if ((this.avgRating - Math.floor(this.avgRating)) <= 0.64
      && (this.avgRating - Math.floor(this.avgRating)) >= 0.39) {
      this.avgRating = Math.floor(this.avgRating) + 0.5
    } else {
      this.avgRating = Math.round(this.avgRating)
    }
  }

  /**
   * Constructs a request from the given parameters and calls the API.
   */
  callGetProfileReviews(pageNum: number): Observable<Page<OrderReviewResp>> {
    const req: GetReviewsReq = {
      page: pageNum,
      profileId: this.profile.profileId
    }
    let obs: Observable<BaseResp<Page<OrderReviewResp>>>
    switch (this.profile.profileType) {
      case ProfileType.ARTIST:
        obs = this.reviewService.callGetProfileReviews(req)
        break
      case ProfileType.USER:
      default:
        obs = this.reviewService.callGetAuthorReviews(req)
        break
    }
    return this.unwrap(obs)
  }

  ngOnDestroy(): void {
    this.currentProfileSub?.unsubscribe()
  }
}
