import {EventEmitter, Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {PLATFORM_BROWSER} from '../../app.module'

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  transparency: EventEmitter<boolean> = new EventEmitter<boolean>()
  visibility: EventEmitter<boolean> = new EventEmitter<boolean>()
  /**
   * Enables the 'auto-hide navbar when a user scrolls' feature
   */
  autoHide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   * Contains the current visibility value of the navigation bar component.
   */
  visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  /**
   * Enables visibility of badge next to the navbar shopping basket.
   */
  orderManagerActivity = new BehaviorSubject<boolean>(false)
  /**
   * - The current state whether the navigation bar is enabled or not.
   * - Must be enabled by default because of the navigation router.
   */
  private enabled = true
  private topBorder = 490
  private isTransparent: boolean

  constructor() {
  }

  /**
   * Specifies if there is any activity in the order manager (pending order accepted, or new order ordered),
   * and sets false if the order manager is clicked and opened.
   */
  isNewActivityOrderManager(isActivity: boolean): void {
    this.orderManagerActivity.next(isActivity)
  }

  /**
   * Determines, whether the top navbar should be transparent or not.
   * Emits the {@link transparency} emitter to a new state.
   */
  setNavbarTransparent(transparent: boolean): void {
    this.transparency.emit(transparent)
  }

  /**
   * Determines, whether the top navbar should be visible or not.
   * If the {@link enabled} is false, it will not trigger a new value, you need to firstly use the {@link enable} function.
   * Emits the {@link visibility} emitter to a new state.
   */
  setNavbarVisibility(visible: boolean): void {
    if (!this.enabled) {
      this.visibility.emit(false)
      return
    }
    this.visibility.emit(visible)
  }

  /**
   * Sets the {@link autoHide} feature enabled.
   * When a user scrolls, the navbar will hide.
   * # Attention! Don't forget to disable this feature when a component is going to be destroyed. It prevents an unexpected behavior.
   */
  setAutoHideEnabled(enabled: boolean): void {
    this.autoHide.next(enabled)
  }

  /**
   * Enables the navigation bar.
   */
  enable(enable: boolean = true): void {
    this.enabled = enable
    this.setAutoHideEnabled(enable)
    this.setNavbarVisibility(enable)
  }

  /**
   * Subscribes the {@link scrollListener} on the {@link document} and informs
   * the {@link transparency} emitter when to change the navbar transparency.
   *
   * @param enable Determines whether to add the {@link scrollListener} or not.
   * @param topBorderPx Determines the 'top' css property border when the navbar should appear non transparent.
   */
  listenScrollEvent(enable: boolean, topBorderPx: number = 490): void {
    this.topBorder = topBorderPx
    if (PLATFORM_BROWSER) {
      if (enable) {
        document.addEventListener('scroll', this.scrollListener)
      } else {
        document.removeEventListener('scroll', this.scrollListener)
      }
    }
  }

  /**
   * An event listener listening to the 'scroll' event of the document.
   */
  private scrollListener = (): void => {
    const borderOverScrolled = window.scrollY >= this.topBorder
    if (borderOverScrolled && this.isTransparent) {
      this.setNavbarTransparent(false)
      this.isTransparent = false
    } else if (!borderOverScrolled && !this.isTransparent) {
      this.setNavbarTransparent(true)
      this.isTransparent = true
    }
  }
}
