import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {ProfileResp} from '../../../service/profile.service'
import {
  BriefPriceItemCategoryResp,
  PriceItemAdditionResp,
  PriceItemResp,
  PriceItemService
} from '../../../service/price-item.service'
import {firstValueFrom, Observable, Subscription} from 'rxjs'
import {NavigationService} from '../../../service/ui/navigation.service'
import {MenuItem} from 'primeng/api'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {growAnimation} from '../../../animation/grow.animation'
import {fadeAnimation} from '../../../animation/fade.animation'
import {BasketService} from '../../../service/basket.service'
import {environment} from '../../../../environments/environment'
import {hasFeatures} from '../../../common/profile-type'
import {Feature} from '../../../common/feature'
import {OrderManagerListTypeEnum} from '../../../modules/order-manager/order-manager-list-type.enum'
import {PermissionService} from '../../../service/ui/permission.service'
import {ProfileStatus} from '../../../common/profile-status'
import {PixelCategory, PixelService} from '../../../service/analytics/meta-pixel/pixel.service'
import {UserService} from '../../../service/user.service'
import {ScreenSize} from '../../../utils/device.utils'
import {scrollToIndexOptions} from '../../../utils/scroll.utils'
import {ProfileBottomBarComponent} from '../profile-bottom-bar/profile-bottom-bar.component'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-profile-offer',
  templateUrl: './profile-offer.component.html',
  styleUrls: ['./profile-offer.component.scss']
})
export class ProfileOfferComponent extends EditableComponent implements OnInit, OnChanges, OnDestroy {

  ProfileStatus: typeof ProfileStatus = ProfileStatus
  ProfileBottomBar: typeof ProfileBottomBarComponent = ProfileBottomBarComponent
  /**
   * The data of the profile.
   */
  @Input()
  data: ProfileResp
  /**
   * Represents a current logged used profile data.
   */
  @Input()
  loggedProfile: ProfileResp
  /**
   * Disables the checkout button. This is a request from outside this component.
   */
  @Input()
  disableCheckout: boolean
  /**
   * Emits when the user has created an order.
   */
  @Output()
  orderCreated = new EventEmitter()
  /**
   * Determines if the user clicked on the checkout btn without specifying an order date.
   * If so, displays dirty date fields.
   */
  @Output()
  disabledCheckoutClickEmitted = new EventEmitter<boolean>()
  /**
   * Selected start date with time from bookform.
   */
  selectedStartDate?: Date
  /**
   * Selected end date with time from bookform.
   */
  selectedEndDate?: Date
  /**
   * Contains all / filtered LAZY price items of the artist from the {@link callGetPriceItemsFilter} request.
   */
  allPriceItems: Page<PriceItemResp> = newEmptyPage()
  /**
   * All available additional items of the {@link profileData}.
   */
  allAdditionalItems: Page<PriceItemAdditionResp> = newEmptyPage()
  /**
   * Contains selected price items of the artist.
   */
  selectedPriceItems: PriceItemResp[] = []
  /**
   * Displays selected - offered service additions.
   */
  selectedPriceItemsAdditions: PriceItemAdditionResp[] = []
  /**
   * Displays all categories of price item to zoom in on the type of performance - service.
   */
  priceItemCategories: BriefPriceItemCategoryResp[] = []
  /**
   * Represents the currently selected category, null is the default, where it shows all (unfiltered) price items.
   */
  selectedCategory?: BriefPriceItemCategoryResp = null
  /**
   * Ensures visibility of the {@link ProfileOrderDialogComponent}.
   */
  orderDialogVisible = false
  /**
   * Shows the 'Successfully ordered dialog' at the end of the {@link ProfileOrderDialogComponent}.
   */
  orderedDialogVisible = false
  /**
   * Ensures visibility of the dialog that appears when the user is not authorized to place an order.
   */
  notAllowedOrderDialogVisible: boolean
  /**
   * Disables the checkout button when the order's start is earlier than {@link MIN_PROFILE_ORDER_MINUTES_DISTANCE_ORDER} minutes from now.
   */
  minimalDistanceErr: boolean
  /**
   * A menu of the {@link priceItemCategories}
   */
  menuCategories: MenuItem[] = []
  /**
   * Clicked category from the menu to filter price items.
   */
  menuCategoryClicked = false
  /**
   * Specifies the order total price (sum of {@link selectedPriceItems} and {@link selectedPriceItemsAdditions}).
   */
  orderTotal = 0
  /**
   * Minimum value of the order amount.
   */
  minimumOrderPrice: number
  /**
   * Hides and show no services hint for a profile.
   */
  hideNoServiceHint: boolean
  /**
   * Determines whether the {@link data} profile can be ordered.
   */
  canBeOrdered: boolean
  /**
   * Subscription to the user observable. Used to reload the order dialog after the user has logged in.
   */
  private userSub: Subscription
  /**
   * Available translations for HTML.
   */
  protected trans = {
    offer_title_owner: $localize`Offer`,
    offer_title_customer: $localize`Choose an offer`,
  }

  constructor(
    public navigation: NavigationService,
    public changeRef: ChangeDetectorRef,
    private priceItemService: PriceItemService,
    private basketService: BasketService,
    private permissionService: PermissionService,
    private pixelService: PixelService,
    private userService: UserService) {
    super()
  }

  ngOnInit(): void {
    this.minimumOrderPrice = environment.minimumOrderPrice
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.call(async () => {
        this.allPriceItems = newEmptyPage()
        await this.loadFirstAdditionalItems()
        this.priceItemCategories = await firstValueFrom(this.callGetAllPriceItemCategories())
        // inits menus from dropdowns
        this.initCategoryOptionMenu()
      })
      if (!this.data) {
        this.loadFirstAdditionalItems()
      }
      this.canBeOrdered = hasFeatures(this.data.profileType, Feature.BE_ORDERED)
    }
  }

  /**
   * Fires, when user close the order dialog. Unsubscribes from the user observable
   * to prevent reloading the order dialog after login.
   */
  onOrderDialogVisibleChange(visible: boolean): void {
    this.orderDialogVisible = visible
    if (!visible) {
      this.userSub?.unsubscribe()
    }
  }

  /**
   * Fires, when the user clicked on the 'Checkout' button.
   * - If there is not a current logged profile, user is shown dialog to login.
   * - If the currently logged-in profile has the {@link Feature.BE_ORDERED}, or
   *    {@link orderTotal} is <= than {@link minimumOrderPrice}, sets {@link notAllowedOrderDialogVisible} to true.
   * - If all conditions are met the order dialog gets fired.
   */
  onCheckOut(): void {
    // Cannot order
    if (hasFeatures(this.loggedProfile?.profileType, Feature.BE_ORDERED) || this.orderTotal <= this.minimumOrderPrice) {
      this.notAllowedOrderDialogVisible = true
      return
    }
    // Show order dialog
    this.orderDialogVisible = true
    this.pixelService.trackCustom(PixelCategory.INITIATE_CHECKOUT, {
      price: this.orderTotal,
      price_items: this.selectedPriceItems.map(it => it.id),
      profile_id: this.data.profileId,
      professions: this.data.professions.map(it => it.id),
      skills: this.data.skills.map(it => it.id),
      genres: this.data.genres.map(it => it.id)
    })
    // Add subscription to user observable to reload the order dialog after login.
    // Used to force user to log in or create an account before placing an order.
    this.userSub = this.userService.user
      .subscribe(user => {
        if (user) {
          this.orderDialogVisible = false

          setTimeout(() => {
            this.orderDialogVisible = true
          }, 500)
        }
      })

    this.changeRef.detectChanges()
  }

  /**
   * - Fires, when the price item ({@link pi}) has been modified (added / removed).
   * - If the {@link PriceItemResp.selected} is true, the price item will be added into the {@link selectedPriceItems}, otherwise removed.
   * - The final change is saved into the localstorage.
   * - The price calculation of {@link orderTotal} is done via the {@link ProfileOfferInventoryComponent} when
   * the {@link selectedPriceItems} get changed.
   */
  onPriceItemSelectionChange(pi: PriceItemResp): void {
    // Add
    if (pi.selected) {
      // Return if already added
      if (this.selectedPriceItems.some(it => it.id === pi.id)) {
        return
      }
      // Push and trigger changes
      this.selectedPriceItems = [...this.selectedPriceItems, pi]
      this.basketService.addPriceItem(this.data.profileId, pi)
      this.publishPixelItemAdd(pi)
      // Remove
    } else {
      for (let i = 0; i < this.selectedPriceItems.length; i++) {
        const item = this.selectedPriceItems[i]
        if (pi.id === item.id) {
          this.selectedPriceItems.splice(i, 1)
          this.basketService.deletePriceItem(this.data.profileId, item)
          this.selectedPriceItems = [...this.selectedPriceItems]
          break
        }
      }
    }
    this.selectPriceItemsManually()
    this.changeRef.detectChanges()
    // Scroll into price footer on smaller devices
    if (pi.selected && !this.isScreenOf(ScreenSize.LG)) {
      scrollToIndexOptions('mobile-layout-checkout-footer', 0, {
        behavior: 'smooth',
        block: 'end'
      })
    }
  }

  /**
   * Publishes the 'AddToCart' event
   */
  private publishPixelItemAdd(pi: PriceItemResp): void {
    this.pixelService.trackCustom(PixelCategory.ADD_TO_CART, {
      price: pi.price,
      content_type: 'price_item',
      item_id: pi.id,
      profile_id: this.data.profileId,
      professions: this.data.professions.map(it => it.id),
      skills: this.data.skills.map(it => it.id),
      genres: this.data.genres.map(it => it.id)
    })
  }

  /**
   * - Fires when the order has been submitted by a user.
   * - Restores everything to defaults.
   */
  onOrderSubmitted(): void {
    this.clearAll()
    this.orderedDialogVisible = true
    this.orderCreated.emit()
  }

  /**
   * Resets the {@link selectedCategory} to null and hides the {@link menuCategoryClicked}.
   */
  resetCategory(): void {
    this.hideNoServiceHint = true
    this.menuCategoryClicked = false
    this.selectedCategory = null
    this.clearAllPriceItems()
  }

  /**
   * Goes through the entire array of {@link allPriceItems} and updates the {@link selectedPriceItems.selected} property if the item
   * is in the {@link selectedPriceItemsAdditions}.
   */
  selectPriceItemsManually(): void {
    if (!this.allPriceItems) {
      return
    }
    for (const item of this.allPriceItems.content) {
      item.selected = false // unselect first
      for (const selected of this.selectedPriceItems) {
        if (item.id === selected.id) {
          item.selected = true
        }
      }
    }
  }

  /**
   * Goes through the entire array of {@link allAdditionalItems} and updates the {@link PriceItemAdditionResp.selected} property if the
   * item is in the {@link selectedPriceItemsAdditions}.
   */
  selectAdditionsManually(): void {
    if (!this.allAdditionalItems) {
      return
    }
    for (const item of this.allAdditionalItems.content) {
      item.selected = false // unselect first
      for (const selected of this.selectedPriceItemsAdditions) {
        if (item.id === selected.id) {
          item.selected = true
        }
      }
    }
  }

  /**
   * Unchecks and inits selected price items and additions.
   */
  clearAll(): void {
    this.selectedPriceItems = []
    this.selectedPriceItemsAdditions = []
    this.selectPriceItemsManually() // unselect all
    this.selectAdditionsManually() // unselect all
    this.orderTotal = 0
  }

  /**
   * Navigates the user to the 'Submitted' bookings.
   */
  navBookings(): void {
    this.navigation.toProfileBookings(this.loggedProfile.charId, OrderManagerListTypeEnum.PENDING)
    this.permissionService.askNotificationPermission(5 * 1000)
  }

  /**
   * - Initializes the category menu items by the available data.
   * - After a user clicked on category from the {@link menuCategories}, it sets the value of {@link menuCategoryClicked} to true.
   * - Also, it calls the {@link clearAllPriceItems} to refresh the content.
   */
  private initCategoryOptionMenu(): void {
    this.menuCategories = []

    const categories: MenuItem[] = []

    for (const category of this.priceItemCategories) {
      categories.push({
        label: category.name,
        icon: category.icon,
        command: async () => {
          this.hideNoServiceHint = true
          this.menuCategoryClicked = true
          this.selectedCategory = category
          this.clearAllPriceItems()
        }
      })
    }
    this.menuCategories = categories
  }

  /**
   * Calls the server API to get profile categories of items.
   */
  callGetAllPriceItemCategories(): Observable<BriefPriceItemCategoryResp[]> {
    return this.unwrap(this.priceItemService.callGetAllCategories())
  }

  /**
   * Call the server API to get the price items of the profile.
   */
  callGetPriceItemsFilter(pageNum: number): Observable<Page<PriceItemResp>> {
    return this.unwrap(this.priceItemService.callGetPriceItemsFilter({
      profileId: this.data.profileId,
      categoryId: this.selectedCategory?.id, // null represents all price items
      page: pageNum
    }))
  }

  /**
   * While skipInitialLoad of additions lazy list is true, then it is necessary to use this logic.
   */
  private async loadFirstAdditionalItems(): Promise<void> {
    this.allAdditionalItems = await firstValueFrom(this.callGetPriceItemAdditionsLazy(0))
    this.allAdditionalItems.nextPage = 1
  }

  /**
   * Calls the server API to get profile additional items.
   */
  private callGetPriceItemAdditionsLazy(pageNum: number = 0): Observable<Page<PriceItemAdditionResp>> {
    return this.unwrap(this.priceItemService.callGetPriceItemAdditions({
      profileId: this.data.profileId,
      page: pageNum
    }))
  }

  /**
   * Inits the {@link allPriceItems} to the {@link newEmptyPage}.
   */
  private clearAllPriceItems(): void {
    this.allPriceItems = newEmptyPage()
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe()
  }
}


/**
 * Specifies data object of the clear params.
 */
export interface BasketClearParams {
  artist?: ProfileResp
  clearArtist: boolean
  clearAll: boolean
}

