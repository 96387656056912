import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {BaseNotification, FcmService, RawFCMMessage} from '../../../service/fcm.service'
import {NgIf} from '@angular/common'
import {ApiComponent} from '../../abstract/api.component'
import {Router, RouterLink} from '@angular/router'
import {ProfileResp, ProfileService} from '../../../service/profile.service'
import {AvatarComponent} from '../avatar/avatar/avatar.component'
import {fadeAnimation} from '../../../animation/fade.animation'
import {TooltipModule} from 'primeng/tooltip'
import {PLATFORM_BROWSER} from '../../../app.module'

@Component({
  animations: [fadeAnimation(200)],
  selector: 'app-foreground-notification',
  templateUrl: './foreground-notification.component.html',
  styleUrls: ['./foreground-notification.component.scss'],
  imports: [
    NgIf,
    RouterLink,
    AvatarComponent,
    TooltipModule
  ],
  standalone: true
})
export class ForegroundNotificationComponent extends ApiComponent implements OnInit, OnDestroy {

  /**
   * The notification will be visible for this time specified in milliseconds.
   */
  private static readonly NOTIFICATION_DURATION = 5000

  /**
   * The current visible notification.
   */
  notification: BaseNotification<any>
  /**
   * The current logged profile.
   */
  currentProfile: ProfileResp
  /**
   * Notification timeout.
   */
  private notificationTimeout
  /**
   * All subscriptions to be unsubscribed.
   */
  private subs: Subscription[] = []

  constructor(
    private fcmService: FcmService,
    private router: Router,
    private profileService: ProfileService) {
    super()
  }

  ngOnInit(): void {
    this.subs.push(this.profileService.currentProfile.subscribe(profile => {
      this.currentProfile = profile
    }))
    this.subs.push(this.fcmService.onMessage.subscribe(this.publishNotification.bind(this)))
  }

  /**
   * Fires when the user clicked on the X button.
   */
  closeNotification(url: string): void {
    if (this.router.url === `/${url}`) {
      if (PLATFORM_BROWSER) {
        window.location.reload()
      }
    } else {
      this.router.navigateByUrl(url)
    }
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout)
    }
    this.notification = null
  }

  /**
   * Publishes the notification.
   */
  private publishNotification(payload: RawFCMMessage): void {
    const data = payload.data
    // Return if it should be hidden
    if (!data.display) {
      return
    }

    this.notification = {
      category: data.category,
      title: data.title,
      icon: data.icon,
      url: data.url,
      body: data.body,
      custom: data.custom,
      targetProfile: data.targetProfile,
      display: !!data.display
    }
    // clear the current visible notification
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout)
    }

    // show the latest notification
    this.notificationTimeout = setTimeout(() => {
      this.notification = null
    }, ForegroundNotificationComponent.NOTIFICATION_DURATION)
  }


  ngOnDestroy(): void {
    this.subs.forEach(it => it?.unsubscribe())
  }
}
