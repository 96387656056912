import {Component, Input, OnInit} from '@angular/core'
import {NgIf} from '@angular/common'
import {environment} from '../../../../environments/environment'

@Component({
  selector: 'app-docs-hint',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './docs-hint.component.html',
  styleUrl: './docs-hint.component.scss'
})
export class DocsHintComponent implements OnInit {

  readonly env = environment
  /**
   * A relative link to the {@link https://docs.umevia.com}
   */
  @Input()
  link: string
  /**
   * Whether it should be opened in a new tab.
   */
  @Input()
  targetBlank = true
  /**
   * Displays the left lightbulb icon.
   */
  @Input()
  displayLeftIcon = true
  /**
   * Additional style class.
   */
  @Input()
  styleClass: string

  ngOnInit(): void {
    // Append suffix '/'
    if (!this.link.startsWith('/')) {
      this.link = `/${this.link}`
    }
  }
}
