import {Component, Input} from '@angular/core'

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
  standalone: true
})
export class NoContentComponent {

  /**
   * The default icon visible on the left.
   */
  @Input()
  icon = 'fa-solid fa-circle-info'

  /**
   * Style class of the entire component.
   */
  @Input()
  styleClass: string

  constructor() {
  }
}
