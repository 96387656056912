<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [type]="behavior"
            url="attributes"
            [header]="dialogHeader"
            [allowSaveOption]="behavior !== 'info'"
            [successMessage]="successMessage"
            [discardLabel]="(behavior !== 'save' && selectedItemsCopy?.length > 0) ? trans.apply : trans.close"
            [save]="saveFunction.bind(this)"
            (saved)="emitChanges(true)"
            (discard)="closeEditComponent()"
            (ngInit)="dialogComponent = dialog"
            #dialog>

  <!--Selected-->
  <div class="p-grid p-mb-3" *ngIf="selectedItemsCopy?.length > 0" @grow [@.disabled]="!isScreenOf(Screen.MD)">
    <div class="p-p-2" *ngFor="let item of selectedItemsCopy">
      <app-button icon="fa-solid fa-xmark"
                  iconPos="right"
                  styleClass="p-button-rounded p-button-secondary"
                  [label]="item.name"
                  (clicked)="remove(item)"></app-button>
    </div>
  </div>

  <!--Most Used-->
  <div class="p-grid p-mb-3" *ngIf="mostUsedItems?.length > 0" @grow [@.disabled]="!isScreenOf(Screen.MD)">
    <div *ngFor="let item of mostUsedItems | slice: 0:(isScreenOf(Screen.MD)? 20 : 5)">
      <div class="p-col">
        <div class="item-placeholder p-d-flex p-ai-center unselectable c-pointer"
             pRipple
             (click)="add(item)">
          <i class="fa-solid fa-plus-circle p-mr-2"></i>
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <app-search *ngIf="searchFunction"
              [component]="this"
              [length]="{max: maxSearchLength}"
              [extraValidations]="searchValidators"
              [placeholder]="searchPlaceholder"
              [searchFunction]="searchFunction.bind(this)"
              [(items)]="searchItems"
              (itemsChange)="dialog?.scrollBottom()"
              [itemHeight]="55"
              [maxScrollHeight]="24*16">

    <!--Validation-->
    <ng-template pTemplate="validation" let-form="form" let-field="field">
      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" [field]="field" error="genre" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <app-frontend-validation [form]="form" [field]="field" error="skill" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <app-frontend-validation [form]="form" [field]="field" error="profession" i18n>
        Unsupported characters.
      </app-frontend-validation>

    </ng-template>

    <!--Item template-->
    <ng-template let-item pTemplate="item">
      <div class="w-100 p-p-1">
        <div class="p-grid" pRipple (click)="add(item)">
          <div class="p-col-12 p-sm-9 p-d-flex p-ai-center p-p-0">
            <app-button icon="{{item.icon || 'fa-solid fa-bookmark'}}"
                        styleClass="p-button-rounded p-button-secondary"
                        [label]="item.name"></app-button>
          </div>
        </div>
      </div>
    </ng-template>
  </app-search>

  <!--Maximum reached-->
  <div class="success-box p-mt-2"
       *ngIf="maxItems && selectedItemsCopy?.length >= maxItems" @grow>
    <i class="fa-solid fa-check p-mr-2"></i>
    <div i18n>You have reached the maximum allowed items</div>
  </div>

</app-dialog>
