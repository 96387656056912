import {ChangeDetectorRef, Component, OnInit} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {BriefProfileResp, ProfileService} from '../../../service/profile.service'
import {firstValueFrom} from 'rxjs'
import {DialogComponent} from '../../common/dialog/dialog.component'
import {DataViewModule} from 'primeng/dataview'
import {AvatarComponent} from '../../common/avatar/avatar/avatar.component'
import {ProfileNamesComponent} from '../../common/profile-names/profile-names.component'
import {BackendValidationComponent} from '../../common/backend-validation/backend-validation.component'
import {Location, NgForOf, NgIf} from '@angular/common'
import {DisableDirective} from '../../../directive/disable.directive'
import {RippleModule} from 'primeng/ripple'
import {currentRelativeURL} from '../../../utils/router.utils'

@Component({
  selector: 'app-switch-profile',
  templateUrl: './switch-profile.component.html',
  styleUrls: ['./switch-profile.component.scss'],
  imports: [
    DialogComponent,
    DataViewModule,
    AvatarComponent,
    ProfileNamesComponent,
    BackendValidationComponent,
    NgIf,
    DisableDirective,
    RippleModule,
    NgForOf
  ],
  standalone: true
})
export class SwitchProfileComponent extends EditableComponent implements OnInit {
  /**
   * All available profiles
   */
  profiles: BriefProfileResp[] = []

  /**
   * Current logged profile
   */
  currentProfile?: BriefProfileResp

  constructor(
    public changeRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private location: Location) {
    super()
  }

  ngOnInit(): void {
    this.call(async () => {
      // Subscribe for current profile
      this.currentProfile = await firstValueFrom(this.profileService.currentProfile)

      // Subscribe for brief profiles
      this.profiles = await firstValueFrom(this.profileService.briefProfilesNotEvents)
    })
  }

  /**
   * - Fires when a user has selected {@link profile}.
   * - Switch the given {@link profile}.
   */
  profileSelected(profile: BriefProfileResp): void {
    const previousCharId = this.currentProfile.charId
    this.callAndFinish(async () => {
      await firstValueFrom(this.unwrap(this.profileService.switchToProfile(profile)))

      if (this.noServerMessages()) {
        this.currentProfile = profile
        let currentPath = currentRelativeURL()
        currentPath = currentPath.replace(previousCharId, profile.charId)
        this.location.go(currentPath) // Changes the URL for the first time, then the dialog hits history.back()
        this.location.go(currentPath) // Re-init the changed URL again.
      }
    })
  }
}
