import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  standalone: true,
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value
    }

    // Remove all non-numeric characters except '+' and leading '00'
    let cleanedValue = value.replace(/[^0-9+]/g, '')

    // If the number starts with 00, replace it with +
    if (cleanedValue.startsWith('00')) {
      cleanedValue = '+' + cleanedValue.substring(2)
    }

    // Match and capture international prefix and the rest of the number
    const internationalMatch = cleanedValue.match(/(\+\d{1,3})(\d{3})(\d{0,3})(\d{0,3})(\d{0,3})/)

    if (internationalMatch) {
      // Format the international number with spaces
      return internationalMatch.slice(1).filter(Boolean).join(' ')
    }

    // Match local number (e.g., 0912 123 456)
    const localMatch = cleanedValue.match(/(\d{4})(\d{3})(\d{3})/)

    if (localMatch) {
      // Format the local number with spaces
      return localMatch.slice(1).filter(Boolean).join(' ')
    }

    // If no match, return the original cleaned value
    return cleanedValue
  }
}
