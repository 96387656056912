import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {EditableComponent} from '../../../abstract/editable.component'
import {DialogComponent} from '../dialog.component'
import {NgIf} from '@angular/common'
import {throwAppError} from '../../../../utils/log.utils'

/**
 * - A pre-defined delete confirm dialog to inform users about the consequences of their action.
 * - Use {@link question} to ask them whether they are sure.
 * - Use the space between tags to define description or any other specific content. Be careful and do not customize
 * that layout to ensure the graphic content equality.
 */
@Component({
  selector: 'app-dialog-delete-confirm',
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.scss'],
  imports: [
    DialogComponent,
    NgIf
  ],
  standalone: true
})
export class DialogDeleteConfirmComponent extends EditableComponent implements OnInit {
  /**
   * The save function to be executed by the dialog.
   */
  @Input()
  save: () => Promise<void>
  /**
   * Emits when the dialog gets saved successfully.
   */
  @Output()
  saved = new EventEmitter<boolean>()
  /**
   * A success message that will be displayed after a successful {@link save} call.
   */
  @Input()
  successMessage: string
  /**
   * An icon to be used near the {@link question} text.
   */
  @Input()
  icon = 'fa-solid fa-trash'
  /**
   * - A question text displayed to user. Should be like 'Are you sure?'.
   */
  @Input()
  question: string
  /**
   * Requires to be initialized.
   */
  @Input()
  component: EditableComponent

  ngOnInit(): void {
    // verify inputs
    if (!this.component) {
      throwAppError('DialogDeleteConfirm', 'The [component] needs to be initialized!')
    }
    // reset api
    this.component.resetApi()
  }
}
