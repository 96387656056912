import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'backgroundImage',
  standalone: true
})
export class BackgroundImagePipe implements PipeTransform {
  transform(url: string): string {
    return `background-image: url('${url}')`
  }
}
