import {Pipe, PipeTransform} from '@angular/core'
import {defaultWallpaper} from '../utils/asset.utils'

@Pipe({
  name: 'wallpaper',
  standalone: true
})
export class WallpaperPipe implements PipeTransform {
  transform(profile: any, fileName?: string): string {
    if (!profile) {
      return ''
    }

    const wallpaper = profile?.wallpapers[0]
    if (wallpaper) {
      if (fileName) {
        return `${wallpaper}/${fileName}`
      } else {
        return wallpaper
      }
    } else {
      return defaultWallpaper()
    }
  }
}
