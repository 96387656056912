import {Component, EventEmitter, Output, ViewChild} from '@angular/core'
import {EditableComponent} from '../../abstract/editable.component'
import {BriefProfileResp, BriefProfilesListReq, ProfileResp, ProfileService} from '../../../service/profile.service'
import {Observable} from 'rxjs'
import {NavigationService} from '../../../service/ui/navigation.service'
import {newEmptyPage, Page} from '../../../rest/page-resp'
import {BasketService} from '../../../service/basket.service'
import {dateEndsWithin} from '../../../utils/date.utils'
import {growAnimation} from '../../../animation/grow.animation'
import {fadeAnimation} from '../../../animation/fade.animation'
import {DialogComponent} from '../../common/dialog/dialog.component'

@Component({
  animations: [growAnimation(), fadeAnimation()],
  selector: 'app-shopping-basket',
  templateUrl: './shopping-basket.component.html',
  styleUrls: ['./shopping-basket.component.scss']
})
export class ShoppingBasketComponent extends EditableComponent {

  /**
   * Fires when a user clicked on the ordered artist from localstorage.
   */
  @Output()
  selectedProfile = new EventEmitter<BriefProfileResp>()
  /**
   * Pageable ordered localstorage profiles.
   */
  orderedArtists: Page<BriefProfileResp> = newEmptyPage()
  /**
   * Determines which dialog content to show when clicking on basket icon.
   */
  hasBasketItems = this.basketService.hasBasketItems
  /**
   * Indicates when the basket will be cleared.
   * @deprecated created for the future.
   */
  basketTimout = this.basketService.basketTimeout
  /**
   * Blocks the ability to click on another artist from the {@link orderedArtists} if it is already clicked.
   */
  profileClicked = false

  @ViewChild('dialog')
  dialog: DialogComponent

  readonly dateEndsWithin = dateEndsWithin

  constructor(
    public navigation: NavigationService,
    private basketService: BasketService,
    private profileService: ProfileService) {
    super()
  }

  /**
   * Calls server to get Page brief selected localstorage orders.
   */
  callGetBriefProfiles(pageNum: number): Observable<Page<BriefProfileResp>> {
    const mergedArray = this.basketService.getProfileIds()
    const req: BriefProfilesListReq = {
      profilesIds: mergedArray,
      page: pageNum
    }
    return this.unwrap(this.profileService.callGetBriefProfiles(req))
  }

  /**
   * Clears the {@link orderedArtist} from localstorage in the ordered profiles Dialog.
   */
  clearArtist(orderedArtist: ProfileResp): void {
    // removes all these data from local storage after order
    this.basketService.clearOnlyUserData()

    // removes from localstorage
    this.basketService.deletePriceItemsOfProfile(orderedArtist.profileId)
    this.basketService.deletePriceItemAdditionsOfProfile(orderedArtist.profileId)

    this.basketService.isClearClicked(true, false, orderedArtist)

    // refresh lazy loading
    this.orderedArtists = newEmptyPage()
  }

  /**
   * Clears on selected ordered artists from localstorage in the ordered profiles Dialog.
   */
  clearAllArtists(): void {
    // removes from localstorage
    this.basketService.clearBasket()

    // refresh lazy loading
    this.orderedArtists = newEmptyPage()
  }

  /**
   * Fires when a user clicks on an artist from the {@link orderedArtists}.
   */
  selectedProfileClicked(profile: BriefProfileResp): void {
    if (this.profileClicked) {
      return
    }
    this.profileClicked = true
    profile.saving = true
    this.dialog.closeDialog(() => {
      profile.saving = false
      this.profileClicked = false
      this.selectedProfile.emit(profile)
    })
  }

  /**
   * Navigates to the catalog.
   */
  navToCatalog(): void {
    this.dialog.onDiscard()
    setTimeout(() => {
      this.navigation.toProfileCatalog()
    }, DialogComponent.DIALOG_CLOSE_DURATION + 10)
  }
}
