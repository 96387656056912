import {Injectable} from '@angular/core'
import {Acceptance} from '../common/acceptance'
import {ApiService} from './api.service'
import {CalendarItemResp, NewCalendarItemReq} from './calendar.service'
import {BriefProfileResp, SimpleProfileResp} from './profile.service'
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs'
import {BaseResp} from '../rest/base-resp'
import {Page} from '../rest/page-resp'
import {OrderPriceItemResp, PriceItemAdditionResp} from './price-item.service'
import {GuestsNumber} from '../component/profile/profile-offer/order-description/order-description.component'
import {OrderReviewResp} from './review.service'
import {Endpoint} from '../common/endpoints'
import {AddressReq, AddressResp, PostalReq} from './address.service'
import {DocumentLinks} from './support.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileOrderService extends ApiService {

  static readonly NOTIFICATION_ORDER_NEW = 'order_new'
  static readonly NOTIFICATION_ORDER_ACCEPTED = 'order_accepted'
  static readonly NOTIFICATION_ORDER_REJECTED = 'order_rejected'
  static readonly NOTIFICATION_ORDER_CONFIRMED = 'order_confirmed'

  /**
   * - Contains a list of profile orders that are visible in the {@link OrderAwaitingConfirmComponent}
   * to confirm their location or profile arrival.
   * - Gets initializes in the {@link OrderAwaitingConfirmComponent}.
   * - Gets deducted in the {@link OrderStateComponent} when a certain order gets confirmed.
   */
  readonly ordersToRequiresAction = new BehaviorSubject<RequiresConfirmationOrderResp[]>([])

  constructor() {
    super()
  }

  /**
   * - Updates the {@link ProfileOrderService.ordersToRequiresAction} subject with orders that require user action.
   */
  async loadOrdersToRequiredAction(): Promise<void> {
    const orders = (await firstValueFrom(this.callCheckAnyOrderRequiresConfirmation()))?.body
    this.ordersToRequiresAction.next(orders)
  }

  callNewProfileOrder(req: NewProfileOrderReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_NEW, req)
  }

  callGetProfileOrder(req: GetProfileOrderReq): Observable<BaseResp<ProfileOrderResp | null>> {
    return this.post(Endpoint.PROFILE_ORDER_GET, req)
  }

  callGetOrderPlatformDocuments(createdAt: Date): Observable<BaseResp<DocumentLinks>> {
    return this.post(Endpoint.PROFILE_ORDER_PLATFORM_DOCUMENTS, {createdAt})
  }

  callGetProfileOrderInvoices(orderId: number): Observable<BaseResp<ProfileOrderInvoicesResp>> {
    return this.post(Endpoint.PROFILE_ORDER_GET_INVOICES, orderId)
  }

  callAcceptanceProfileOrder(req: UpdateProfileOrderAcceptanceReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_ACCEPTANCE, req)
  }

  callListProfileOrder(req: ListAllProfileOrdersReq): Observable<BaseResp<Page<BriefProfileOrderResp>>> {
    return this.post(Endpoint.PROFILE_ORDER_ALL, req)
  }

  callAcceptedFilterProfileOrders(req: ListFilteredProfileOrdersReq): Observable<BaseResp<Page<BriefProfileOrderResp>>> {
    return this.post(Endpoint.PROFILE_ORDER_ALL_FILTERED_ACCEPTED, req)
  }

  callRejectedFilteredProfileOrders(req: ListFilteredProfileOrdersReq): Observable<BaseResp<Page<BriefProfileOrderResp>>> {
    return this.post(Endpoint.PROFILE_ORDER_ALL_FILTERED_REJECTED, req)
  }

  callHasListAnyOrder(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_ANY_IN_LIST, null)
  }

  callHasActiveOrders(): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_HAS_ACTIVE_ORDERS, null)
  }

  callDeleteProfileOrder(req: DeleteProfileOrderReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_DELETE, req)
  }

  callCheckAnyOrderRequiresConfirmation(): Observable<BaseResp<RequiresConfirmationOrderResp[]>> {
    return this.post(Endpoint.PROFILE_ORDER_CHECK_ALL_CONFIRM_REQUIREMENTS, null)
  }

  callConfirmOrderArrival(req: ConfirmOrderArrivalReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_CONFIRM_ARRIVAL, req)
  }

  callConfirmLocation(req: NewUserLocationOrderReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_CONFIRM_LOCATION, req)
  }

  callCheckProfileArrival(req: CheckProfileArrivalReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.PROFILE_ORDER_CHECK_PROFILE_ARRIVAL, req)
  }

  callSearchProfileOrder(req: SearchProfileOrderReq): Observable<BaseResp<Page<BriefProfileOrderSearchResp>>> {
    return this.post(Endpoint.PROFILE_ORDER_SEARCH, req)
  }
}

export interface GetProfileOrderReq {
  id: number
}

export interface OrderPlatformDocumentsReq {
  createdAt: Date
}

export interface UpdateProfileOrderAcceptanceReq {
  id: number
  acceptance: Acceptance
  reply?: string
  cancelReason?: string
  /**
   * This is the additional item from the 'order profile'.
   * It can represent a sale or final price adjustment (round).
   */
  additionalPrice?: number
}

export interface DeleteProfileOrderReq {
  id: number
}

export interface ListAllProfileOrdersReq {
  authorAcceptance?: Acceptance
  profileAcceptance?: Acceptance
  /**
   * All records up to this date.
   */
  upTo?: Date
  start?: Date
  end?: Date
  page: number
}

export interface ListFilteredProfileOrdersReq {
  /**
   * All records up to this date.
   */
  upTo?: Date
  start?: Date
  end?: Date

  oldest: boolean // if false, then earliest or confirmed
  confirmed: boolean

  page?: number
}

export interface NewProfileOrderReq {
  /**
   * Profile id of the profile that is going to be ordered.
   */
  profileId: number
  name: string
  description?: string
  numberOfGuests: GuestsNumber
  /**
   * Invoicing address of the author.
   */
  authorAddress: PostalReq
  /**
   * The address where the ordered event is to take place.
   */
  orderAddress: AddressReq
  calendarItem: NewCalendarItemReq
  priceItems: number[]
  priceItemAdditions: number[]
}

export interface NewUserLocationOrderReq {
  lat: number
  lng: number
  date: Date
  orderId: number
}

export interface UserLocationResp {
  lat: number
  lng: number
  date: Date
}

export interface CheckProfileArrivalReq {
  orderId: number
}

export interface ConfirmOrderArrivalReq {
  orderId: number
}

export interface RequiresConfirmationOrderResp {
  id: number
  name: string
  calendarItem: CalendarItemResp
  isAuthor: boolean
  /**
   * To get profile charId of the order to create url to the right navigation.
   */
  charId: string
}

export interface BriefProfileOrderResp {
  id: number
  author: SimpleProfileResp
  profile: SimpleProfileResp
  name: string
  profileAcceptance: Acceptance
  authorAcceptance: Acceptance
  address: AddressResp
  calendarItem: CalendarItemResp
  /**
   * The sum of {@link priceItems}, {@link priceAdditions}, and {@link additionalPrice}.
   */
  orderTotal: number
  /**
   * The sum of {@link orderTotal} and platform fee.
   */
  total: number
  paidAt?: Date
  partialFunded: boolean
  createdAt: Date
  expirationAt?: Date
  profileAcceptanceAt?: Date
  profileArrived: boolean
  /**
   * If the order has reviews from the author and profile.
   */
  rated: boolean
  /**
   * Contains the first order that the dates are overlapping.
   */
  overlap?: BriefProfileOrderResp
  dispute?: OrderDisputeResp
  finished: boolean
}

export interface ProfileOrderResp {
  id: number
  createdAt: Date
  author: BriefProfileResp
  profile: BriefProfileResp
  name: string
  description?: string
  numberOfGuests: GuestsNumber
  profileReply?: string
  cancelReason?: string
  address: AddressResp
  calendarItem: CalendarItemResp
  priceItems: OrderPriceItemResp[]
  priceAdditions: PriceItemAdditionResp[]
  /**
   * The sum of {@link priceItems}, {@link priceAdditions}, and {@link additionalPrice}.
   */
  orderTotal: number
  /**
   * - The amount of the platform service fee for this order.
   * - Can be unspecified when the ordered profile didn't make any action so far.
   */
  platformFee?: number
  /**
   * The sum of {@link orderTotal} and {@link platformFee}.
   */
  total: number
  /**
   *  - The adjusted [total] price portion by the [profile].
   *  - (already included in the [total] price)
   */
  additionalPrice?: number
  /**
   * A not-modified {@link additionalPrice} directly from the server.
   */
  realAdditionalPrice?: number
  /**
   * If the author has been refunded.
   */
  authorRefund?: number
  authorRefundAt?: Date
  /**
   * How much the ordered profile got.
   */
  profileTransferred?: number
  profileTransferredAt?: Date
  paidAt?: Date
  partialFunded: boolean
  expirationAt?: Date
  profileAcceptance: Acceptance
  authorAcceptance: Acceptance
  profileArrived: boolean
  /**
   * Determines, whether the profile is a registered legal entity.
   */
  profileIsLegalEntity: boolean
  /**
   * Determines whether order documents (invoices or contract) are present for download.
   */
  documentsPresent: boolean
  /**
   * Determines whether order contract is present for this order.
   */
  profileContractPresent: boolean
  /**
   * Whether the author confirmed the arrival of profile.
   */
  authorConfirmedAt?: Date
  reviews: OrderReviewResp[]
  /**
   * Contains the first order that the dates are overlapping.
   */
  overlap?: BriefProfileOrderResp
  dispute?: OrderDisputeResp
  finished: boolean
}

export interface OrderDisputeResp {
  createdBy: number
  penaltyProfile?: number
  freeze: boolean
  modifiedAt: Date

  /**
   * - UI only property
   * - Gets initialized from the {@link createdBy} id.
   */
  createdByObject?: BriefProfileResp
  /**
   * - UI only property
   * - Tells what profile won the dispute.
   */
  inFavorOf?: BriefProfileResp | null
}

export interface SearchProfileOrderReq {
  input: string
  page?: number
}

export interface BriefProfileOrderSearchResp {
  id: number
  author: BriefProfileResp
  profile: BriefProfileResp
  name: string
  total: number
}

/**
 * Profile order invoices.
 */
export interface ProfileOrderInvoicesResp {
  profileInvoicePDF?: string
  profileInvoiceHosted?: string

  authorInvoicePDF?: string
  authorInvoiceHosted?: string

  profileContractPDF?: string
  profileTransferReceiptPDF?: string
}

