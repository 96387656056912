<!--Edit Component-->
<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            header="Profile Settings"
            i18n-header
            url="settings"
            successMessage="Settings have been saved."
            i18n-successMessage
            [saveLabel]="requiredSettingMissing ? trans.later : trans.save"
            [saveIcon]="requiredSettingMissing ? 'fa-solid fa-clock' : 'fa-solid fa-check'"
            [hold]="locationEditVisible || legalEntityDialogVisible || changeStatusDialogVisible || professionsDialogVisible"
            [save]="onSave.bind(this)"
            (discard)="closeEditComponent()"
            [disableSaveOption]="form.invalid || stripeSettingsLoading || namesUpdateLoading"
            #dialog>

  <!--Preview-->
  <div class="preview p-d-flex p-ai-center p-py-3">
    <div class="p-d-flex p-jc-center p-ai-center p-mr-3">
      <app-avatar [profile]="data" [sizePx]="isScreenOf(Screen.MD) ? 80 : 50"></app-avatar>
    </div>

    <div>
      <h1 class="l-count-1 p-m-0">{{ form.controls.displayName.value }}</h1>
      <div class="p-d-inline-flex p-ai-center">
        <app-profile-icon [profile]="data" styleClass="p-mr-2"></app-profile-icon>
        <h2 class="p-m-0">{{ form.controls.charId.value }}</h2>
      </div>
    </div>
  </div>

  <form [formGroup]="form">

    <!--Display name-->
    <app-text-input label="Display name"
                    i18n-label
                    icon="fa-solid fa-user"
                    inputType="text"
                    hint="Your real name by which others will recognize you. We put this name to your invoices."
                    i18n-hint
                    formFieldName="displayName"
                    [form]="form"
                    [required]="true"
                    [length]="{min: Restrictions.MIN_DISPLAY_NAME_LENGTH, max: Restrictions.MAX_DISPLAY_NAME_LENGTH}">
      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.DISPLAY_NAME_LENGTH_INCORRECT"
                              i18n>
        The length needs to be within {{ Restrictions.MIN_DISPLAY_NAME_LENGTH }}
        - {{ Restrictions.MAX_DISPLAY_NAME_LENGTH }} characters.
      </app-backend-validation>
    </app-text-input>

    <!--CharId-->
    <app-text-input label="Nick name"
                    i18n-label
                    icon="fa-solid fa-user"
                    inputType="text"
                    formFieldName="charId"
                    [form]="form"
                    [noBlankCharacters]="true"
                    [space]="false"
                    [paste]="false"
                    [copy]="false"
                    [required]="true"
                    hint="Your unique profile identifier, by which others are able to mention or tag."
                    i18n-hint
                    [length]="{min: Restrictions.MIN_CHAR_ID_LENGTH, max: Restrictions.MAX_CHAR_ID_LENGTH}">

      <!--FE-CHECK-->
      <app-frontend-validation [form]="form" field="charId" error="charId" i18n>
        Unsupported characters.
      </app-frontend-validation>

      <!--BE-CHECK-->
      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.CHAR_ID_INCORRECT" i18n>
        Not valid profile name.
      </app-backend-validation>

      <app-backend-validation [serverMessages]="serverMessages" [check]="ServerMessage.CHAR_ID_ALREADY_EXISTS" i18n>
        This name is already taken.
      </app-backend-validation>
    </app-text-input>

    <!--Solo/Group setting-->
    <app-dropdown [options]="profileQuantitySettings"
                  label="Profile Represents"
                  i18n-label
                  [form]="form"
                  formFieldName="quantity"
                  hint="How many of you represent this profile?"
                  i18n-hint
                  [required]="true"
                  *ngIf="canBeOrdered">
      <ng-template let-item pTemplate="item">
        <div class="p-d-flex p-ai-center">
          <i class="{{item.icon}} p-mr-2"></i>
          <div>{{ item.name }}</div>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <div class="p-d-flex p-ai-center p-relative">
          <i class="{{item.icon}} p-ml-3 p-mr-2 p-absolute"></i>
          <div class="p-pl-6">{{ item.name }}</div>
        </div>
      </ng-template>
    </app-dropdown>

    <!-- Save Action -->
    <div class="p-d-flex p-jc-center w-100" *ngIf="canBeOrdered && inputChanged" @grow>
      <app-button type="primary"
                  label="Apply Changes"
                  i18n-label
                  icon="fa-solid fa-check"
                  styleClass="p-mt-2"
                  [disabled]="saving || loading || form.invalid"
                  [loading]="namesUpdateLoading"
                  (clicked)="updateNames(true)"></app-button>
    </div>

    <!--Professions-->
    <div class="p-mt-3"
         [class.highlight-setting]="highlightSetting === 'profession'"
         *ngIf="professionsVisible" @grow>
      <app-profile-professions [data]="data"
                               (editComponentVisibleChange)="professionsDialogVisible = $event; changeRef.detectChanges()"
                               [requiredProf]="true"
                               (valid)="requiredSettingMissing = false; saveClicked = false; changeRef.detectChanges()"
                               [isError]="requiredSettingMissing"
                               type="button"
                               [editButtonVisible]="true"></app-profile-professions>
    </div>
    <!--Profile location-->
    <div *ngIf="canBeOrdered"
         [class.highlight-setting]="highlightSetting === 'location'">
      <div class="p-d-flex p-ai-center"
           [class.danger-text]="requiredSettingMissing && !data.address">
        <!--Required icon-->
        <i class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
           pTooltip="This field is required"
           i18n-pTooltip
           tooltipPosition="top"></i>
        <h4 class="p-my-2" i18n>Acting Address</h4>
      </div>
      <div *ngIf="data.address; else noAddress">
        <div class="address p-p-2 p-m-0 rounded-medium p-d-inline-block w-100 c-pointer"
             (click)="locationEditVisible = true">
          <div class="p-d-flex">
            <i class="fa-solid fa-globe-europe p-mx-2"></i>
            <ul class="p-pl-0 p-mx-2">
              <li class="l-count-1">{{ data.address?.line1 }}</li>
              <li class="l-count-1" *ngIf="data.address?.line2">{{ data.address?.line2 }}</li>
              <li class="l-count-1">{{ data.address?.postalCode }} {{ data.address?.city }}</li>
              <li class="l-count-1 p-text-bold">{{ data.address?.state }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="p-d-flex p-pt-3">
        <app-button type="edit"
                    styleClass="p-mr-2"
                    (clicked)="locationEditVisible = true"></app-button>

        <!--<app-button type="danger-light"
                    icon="fa-solid fa-xmark"
                    label="Remove"
                    i18n-label
                    *ngIf="data.address" @fade
                    (clicked)="locationDeleteVisible = true; scrollFun('location-delete-bottom', 0, 'smooth')"></app-button>-->
      </div>

      <div *ngIf="locationDeleteVisible" @grow class="p-my-3">
        <ng-template [ngTemplateOutlet]="locationDelete"></ng-template>
      </div>
      <div class="location-delete-bottom"></div>

      <!--No address content-->
      <ng-template #noAddress>
        <div [class.failed-box]="requiredSettingMissing && !data.address"
             class="c-pointer"
             (click)="locationEditVisible = true">
          <app-no-content i18n>
            Add your area of interest where you act.
          </app-no-content>
        </div>
      </ng-template>
    </div>

    <!--Stripe And Legal Entity-->
    <div *ngIf="canBeOrdered"
         [class.highlight-setting]="highlightSetting === 'stripe'"
         class="p-mt-2 p-relative"
         [class.stripe-container]="stripeSettingsLoading">
      <!--Loading-->
      <div *ngIf="stripeSettingsLoading" @fade class="p-absolute stripe-skeleton w-100 p-mt-2">
        <p-skeleton width="50%" height="2rem" styleClass="rounded-small"></p-skeleton>
        <p-skeleton width="100%" height="13rem" styleClass="p-mt-2 rounded-small"></p-skeleton>
      </div>

      <ng-template [ngIf]="!stripeSettingsLoading">
        <div class="p-d-flex p-ai-center"
             [class.failed-text]="requiredSettingMissing && !stripeSettings?.invoicingAddress?.legalEntity">
          <div class="p-d-flex p-ai-center">
            <!--Required icon-->
            <i class="required-icon fa-solid fa-asterisk p-mr-1 p-mb-3"
               pTooltip="This field is required"
               i18n-pTooltip
               tooltipPosition="top"></i>
            <h4 class="p-my-2" i18n>Stripe Account (Payments)</h4>
          </div>

          <!--Pending Verification-->
          <div *ngIf="stripeSettings?.pendingVerification"
               class="p-d-flex p-ai-center pending-verification p-ml-2 p-py-1 p-px-2 rounded-small"
               pTooltip="Your provided information is currently being verified. It can take several minutes."
               i18n-pTooltip
               tooltipPosition="left">
            <i class="fa-solid fa-clock p-mr-2"></i>
            <div i18n>Verifying</div>
          </div>

          <!--Completed-->
          <div *ngIf="!stripeSettings?.pendingVerification && stripeCompletionCheck?.completed"
               class="p-d-flex p-ai-center stripe-completed p-ml-2 p-py-1 p-px-2 rounded-small"
               pTooltip="You have successfully activated the Stripe account."
               i18n-pTooltip
               tooltipPosition="left">
            <i class="fa-solid fa-check p-mr-2"></i>
            <div i18n="@@Stripe active label">Active</div>
          </div>
        </div>

        <!--Invoicing Address-->
        <div *ngIf="stripeSettings?.invoicingAddress; else noLegalEntity">
          <div class="address p-p-2 p-m-0 rounded-medium p-d-inline-block w-100 c-pointer"
               (click)="showLegalEntityDialog()">
            <div class="p-d-flex" *ngVar="stripeSettings?.invoicingAddress as invAddr">
              <i class="fa-solid fa-location-dot p-mx-2"></i>
              <ul class="p-pl-0 p-mx-2" *ngVar="invAddr?.legalEntity as le">
                <li class="l-count-1 p-text-bold">{{ invAddr?.name }}</li>
                <li class="l-count-1">{{ invAddr?.line1 }}</li>
                <li class="l-count-1" *ngIf="invAddr?.line2">{{ invAddr?.line2 }}</li>
                <li class="l-count-1">{{ invAddr?.postalCode }} {{ invAddr?.city }}</li>
                <li class="l-count-1 p-text-bold">{{ invAddr?.state }}</li>
                <hr class="legal-entity-hr" *ngIf="le">
                <li class="l-count-1" *ngIf="le?.registrationNumber"><b i18n>Reg ID: </b>{{ le?.registrationNumber }}
                </li>
                <li class="l-count-1" *ngIf="le?.taxId"><b i18n>Tax ID: </b>{{ le?.taxId }}</li>
                <li class="l-count-1" *ngIf="le?.vatId"><b i18n>Vat ID: </b>{{ le?.vatId }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-pt-3" *ngVar="stripeSettings?.invoicingAddress as invAddr">

          <!-- Create Stripe -->
          <app-button type="primary"
                      label="Activate Stripe"
                      [highlight]="true"
                      i18n-label
                      icon="fa-solid fa-credit-card"
                      (clicked)="showLegalEntityDialog()"
                      *ngIf="!stripeCompletionCheck?.completed && !stripeSettings"></app-button>

          <!--Edit-->
          <app-button type="edit"
                      styleClass="p-mr-2"
                      (clicked)="showLegalEntityDialog()"
                      *ngIf="stripeCompletionCheck?.completed && !stripeCompletionCheck?.newRequirementDue && (!stripeSettings?.isLegalEntity || invAddr?.legalEntity)
                             || stripeSettings?.pendingVerification"></app-button>

          <!--Action required (Edit)-->
          <app-button type="primary"
                      label="Update Required"
                      [highlight]="true"
                      i18n-label
                      icon="fa-solid fa-triangle-exclamation"
                      (clicked)="showLegalEntityDialog()"
                      *ngIf="!stripeSettings?.pendingVerification && (
                             (!stripeCompletionCheck?.completed && !!stripeSettings)
                             || (stripeCompletionCheck?.newRequirementDue && !!stripeSettings)
                             || stripeSettings?.errors
                             || (!!stripeSettings && stripeSettings.isLegalEntity && !invAddr.legalEntity))"></app-button>
        </div>
      </ng-template>

      <!--No address content-->
      <ng-template #noLegalEntity>
        <div [class.failed-box]="requiredSettingMissing && !stripeSettings?.invoicingAddress?.legalEntity"
             class="c-pointer"
             (click)="showLegalEntityDialog()">
          <app-no-content styleClass="warning-box">
            <div *ngIf="(!stripeSettings?.pendingVerification && (
                             (!stripeCompletionCheck?.completed && !!stripeSettings)
                             || (stripeCompletionCheck?.newRequirementDue && !!stripeSettings)
                             || stripeSettings?.errors
                             || (!!stripeSettings && stripeSettings.isLegalEntity && !stripeSettings?.invoicingAddress?.legalEntity))); else noStripe"
                 class="w-100 p-text-left">
              <span i18n>You have not finished the activation process. Please specify missing information to start receiving
              orders. More about Stripe</span>
              <a href="{{env.contact.docs}}/docs/profiles/artist/stripe-account/"
                 target="_blank" class="text-link p-ml-1" i18n>here</a>.
            </div>
            <ng-template #noStripe>
              <div class="w-100 p-text-left" i18n>
                It is required to successfully activate the <b>Stripe Payment Gateway</b> for receiving payments.
              </div>
            </ng-template>
          </app-no-content>
        </div>
      </ng-template>
    </div>

    <!--Settings required warning-->
    <div *ngIf="requiredSettingMissing" class="failed-box p-d-flex p-ai-center p-mt-3 p-mb-2">
      <i class="fa-solid fa-warning p-mr-4"></i>
      <div i18n>Keep in mind, that you have to fill all required fields to start receiving orders. Also, you can do this
        later.
      </div>
    </div>

    <div class="required"></div>
  </form>

  <!--Delete, Deactivate or Activate profile feature-->
  <div [class.highlight-setting]="highlightSetting === 'delete'" class="p-mt-2">
    <h4 *ngIf="data.profileStatus === ProfileStatus.ACTIVE" class="p-my-2" i18n>Delete / Deactivate profile</h4>
    <h4 *ngIf="data.profileStatus !== ProfileStatus.ACTIVE" class="p-my-2" i18n>Delete / Activate profile</h4>
    <p-accordion (click)="scrollDialog()">
      <p-accordionTab>
        <!-- Header -->
        <ng-template pTemplate="header">
          <div class="danger-text p-d-flex p-ai-center">
            <div *ngIf="data.profileStatus === ProfileStatus.ACTIVE" class="p-mr-2" i18n>Delete / Deactivate profile
            </div>
            <div *ngIf="data.profileStatus !== ProfileStatus.ACTIVE" class="p-mr-2" i18n>Delete / Activate profile</div>
            <i class="fa-solid fa-users"></i>
          </div>
        </ng-template>

        <!-- Content -->
        <ng-template pTemplate="content">
          <p i18n>You can <b>temporarily deactivate</b> your profile(s) to take a break from the Umevia platform instead
            of
            permanently deleting them. If you <b>don't reactivate</b> the account(s), you <b>won't be visible</b> on the
            platform.</p>

          <app-hint [collapsableHint]="false" i18n>We are sorry to see you go. Please let us know
            <a href="https://forms.gle/SEFZbHb54675xmSf8"
               target="_blank" class="text-link underline-link">here</a>
            the reason why you are leaving to help us improve.
          </app-hint>

          <!-- Deactivate / Activate and Delete buttons -->
          <div class="p-d-flex p-jc-center p-ai-center p-mt-4">
            <!--Activate button-->
            <app-button *ngIf="hasProfileStatus(data, ProfileStatus.INACTIVE)"
                        type=secondary
                        icon="fa-solid fa-user-check"
                        label="Activate"
                        i18n-label
                        (clicked)="accountDecisionClicked(ProfileStatus.ACTIVE)"></app-button>

            <!--Deactivate button-->
            <app-button *ngIf="hasProfileStatus(data, ProfileStatus.ACTIVE)"
                        type=danger-light
                        icon="fa-solid fa-user-lock"
                        label="Deactivate"
                        i18n-label
                        (clicked)="accountDecisionClicked(ProfileStatus.INACTIVE)"></app-button>

            <!--Delete button-->
            <app-button type=danger-light
                        icon="fa-solid fa-user-xmark"
                        label="Delete"
                        i18n-label
                        styleClass="p-ml-2"
                        (clicked)="accountDecisionClicked(ProfileStatus.DELETED)"></app-button>
          </div>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</app-dialog>


<!--Update map location edit dialog-->
<app-update-map-location *ngIf="locationEditVisible"
                         [(show)]="locationEditVisible"
                         (valid)="requiredSettingMissing = false; saveClicked = false; changeRef.detectChanges()"
                         [data]="data"></app-update-map-location>

<!--Legal Entity settings dialog-->
<app-legal-entity-settings *ngIf="legalEntityDialogVisible"
                           [(show)]="legalEntityDialogVisible"
                           [settings]="stripeSettings"
                           [data]="data"
                           [profileSettingsDialog]="this"
                           (forceClose)="dialog.onDiscard()"></app-legal-entity-settings>

<!-- Sets status of the profile account dialog -->
<app-profile-change-status *ngIf="changeStatusDialogVisible"
                           [(show)]="changeStatusDialogVisible"
                           [data]="data"
                           [hasActiveOrders]="hasActiveOrders"
                           [clickedType]="clickedTypeDecision"></app-profile-change-status>

<!--Location delete dialog confirm-->
<ng-template #locationDelete>
  <div class="failed-box p-d-flex p-flex-column p-jc-center p-ai-center">
    <i class="delete-icon fa-solid fa-trash"></i>
    <p class="p-text-center p-mt-4" i18n>
      Are you sure you want to delete your profile location?<br>
      This will make your profile invisible to customers.
    </p>

    <div class="p-d-flex p-pt-3">
      <app-button type="secondary"
                  styleClass="p-mr-2"
                  label="Keep"
                  i18n-label
                  (clicked)="locationDeleteVisible = false"></app-button>

      <app-button type="danger"
                  icon="fa-solid fa-xmark"
                  label="Remove"
                  i18n-label
                  [loading]="loading"
                  (clicked)="removeLocation()"></app-button>
    </div>
  </div>
</ng-template>
