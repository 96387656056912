import {Injectable} from '@angular/core'
import {CookieService} from 'ngx-cookie-service'
import {BehaviorSubject} from 'rxjs'
import {NavigationService} from '../ui/navigation.service'
import {ServerMessage} from '../../common/server-message'
import {UserService} from '../user.service'
import {StorageItem} from '../storage.service'
import {AppModule} from '../../app.module'

/**
 * - This service must be included only via {@link AppModule.injector} to avoid circular dependencies injection.
 * - Used for managing authentication tokens in a browser.
 */
@Injectable({
  providedIn: 'root'
})
export class JwtService {
  /**
   * Name of the header with {@link ServerMessage}, when error occurred in JwtAuthenticationFilter on server.
   */
  static readonly AUTH_ERROR_HEADER = 'auth-error'

  /**
   * Used in the {@link ApiComponent.authenticatedRequest} function to check whether the currently running
   * request should wait or be executed immediately.
   */
  runningJwtRequest = new BehaviorSubject(false)

  constructor(private cookies: CookieService,
              private navigation: NavigationService) {
  }

  /**
   * Checks whether the access token is still present in cookies storage.
   */
  jwtNeedsRefresh(): boolean {
    return !this.cookies.check(StorageItem.ACCESS_TOKEN_PRESENT)
  }

  /**
   * Returns true if the {@link TOKEN_COOKIE} is present.
   */
  isJwtTokenPresent(): boolean {
    return this.cookies.check(StorageItem.TOKEN_COOKIE)
  }

  getRandTokenCookie(): string {
    return this.cookies.get(StorageItem.TOKEN_COOKIE)
  }

  /**
   * Returns true if {@link SESSION_COOKIE} is present.
   */
  isSessionPresent(): boolean {
    return this.cookies.check(StorageItem.SESSION_COOKIE)
  }

  /**
   * Checks whether the {@link ACCESS_TOKEN_PRESENT} and the {@link TOKEN_COOKIE} is present.
   * If not, it will redirect to the login.
   */
  toLoginIfNoToken(): boolean {
    if (this.jwtNeedsRefresh() && !this.isJwtTokenPresent()) {
      this.navigation.toLogIn()
      return true
    }
    return false
  }

  /**
   * Shows session expiration dialog, when message is equal to one from switch statement.
   * @param serverMessage
   */
  handleSessionExpiration(serverMessage: ServerMessage): boolean {
    switch (serverMessage) {
      case ServerMessage.SESSION_TIMEOUT:
      case ServerMessage.SESSION_NOT_FOUND:
      case ServerMessage.REFRESH_TOKEN_EXPIRED:
        AppModule.injector.get(UserService)
          .createLogoutRequest(false, this.navigation.urlPathLogin(true), true, false)
        return true
      default:
        return false
    }
  }
}
