<div class="wrapper p-py-3 w-100">
  <div class="p-grid p-px-3">

    <!--Menu for large screens-->
    <div class="p-col-3" *ngIf="isScreenOf(Screen.MD)">
      <ng-container *ngTemplateOutlet="option"></ng-container>
    </div>

    <!--Small screens sidebar-->
    <div class="p-d-flex p-jc-center w-100" *ngIf="!isScreenOf(Screen.MD)">
      <p-sidebar [(visible)]="settingsSidebarVisible"
                 [blockScroll]="true"
                 styleClass="settings-sidebar"
                 position="left">
        <div class="p-py-3 p-px-4">
          <ng-container *ngTemplateOutlet="option"></ng-container>
        </div>
      </p-sidebar>
    </div>

    <!--Content-->
    <div class="p-col-12 p-md-9 p-lg-6">
      <!--Header-->
      <div class="p-d-flex p-ai-center">
        <i class="fa-solid fa-arrow-left p-mr-3 title-icon" (click)="settingsSidebarVisible = true"
           *ngIf="!isScreenOf(Screen.MD)"></i>
        <h2 class="p-my-0 p-my-md-3">{{selectedMenuItem.title}}</h2>
      </div>

      <!--Option Content-->
      <section [ngSwitch]="selectedMenuItem?.id">
        <app-admin-ld-all-versions *ngSwitchCase="LDC.VERSIONS_LIST"></app-admin-ld-all-versions>
        <app-admin-new-legal-docs *ngSwitchCase="LDC.UPDATE"></app-admin-new-legal-docs>
      </section>
    </div>

  </div>
</div>

<!--The list of available settings-->
<ng-template #option>
  <h2 i18n>Options</h2>
  <div *ngFor="let item of menuItems">
    <div class="menu-item p-p-2 p-d-flex p-ai-center unselectable c-pointer rounded-small"
         [class.selected-item]="item['selected']"
         [class.disabled-selected-item]="item['disabled']"
         pRipple
         (click)="onSelectedMenuItem(item)">
      <i [class]="item.icon" class="p-mr-2"></i>
      <div>{{item.title}}</div>
    </div>
  </div>
</ng-template>
