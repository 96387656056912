<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [baseZIndex]="100005"
            [save]="acceptAll.bind(this)"
            (discard)="onAcceptSelected()"
            header="Cookies Settings"
            i18n-header
            successMessage="Cookies settings have been updated."
            i18n-successMessage
            url="cookies"
            saveLabel="Accept All"
            i18n-saveLabel
            discardLabel="Accept Selected"
            i18n-discardLabel>
  <!--Dialog content-->
  <div class="p-py-2">

    <h1 class="p-m-0" i18n>Functional</h1>
    <app-hint styleClass="p-my-2" i18n>
      These cookies are strictly necessary for this website as is. They are used for the basic mandatory operations and
      allow you to log in, set your privacy preferences, or fill out forms.
    </app-hint>
    <ng-container *ngFor="let it of functional"
                  [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{$implicit: it}"></ng-container>

    <!--Analytics-->
    <div class="p-d-flex p-ai-center p-jc-between p-pt-5 p-pr-2">
      <h1 i18n="@@cookiesHeader">Analytics</h1>
      <p-inputSwitch [ngModel]="allAnalyticsSwitch"
                     (ngModelChange)="allAnalyticsSwitch = $event; enableGroup(allAnalyticsSwitch, analytics)"></p-inputSwitch>
    </div>
    <app-hint styleClass="p-my-2" i18n>
      They serve to track and analyze website usage patterns, providing valuable insights to website owners about
      visitor interactions and preferences for improved user experience and content optimization.
    </app-hint>
    <ng-container *ngFor="let it of analytics" [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{$implicit: it}"></ng-container>


    <!--Marketing-->
    <div class="p-d-flex p-ai-center p-jc-between p-pt-5 p-pr-2">
      <h1 i18n>Marketing</h1>
      <p-inputSwitch [ngModel]="allMarketingSwitch"
                     (ngModelChange)="allMarketingSwitch = $event; enableGroup(allMarketingSwitch, marketing)"></p-inputSwitch>
    </div>
    <app-hint i18n>
      With these cookies, you can get more personalized and relevant advertisements on other sites. By disallowing the
      cookies mentioned below, you will lose the experience of targeted advertisements across different websites.
    </app-hint>
    <ng-container *ngFor="let it of marketing" [ngTemplateOutlet]="item"
                  [ngTemplateOutletContext]="{$implicit: it}"></ng-container>

  </div>
</app-dialog>

<!--Item data-->
<ng-template #item let-it>
  <div class="p-d-flex p-jc-between p-py-2 item">
    <!--Info-->
    <div class="p-pr-2">
      <div class="p-d-flex p-ai-center">
        <i [class]="it.icon + ' p-mr-2'"></i>
        <h2 class="p-m-0">{{it.name}}</h2>
      </div>
      <p class="p-m-0">{{it.description}}</p>
    </div>
    <!--Switch-->
    <div class="p-d-flex p-ai-center p-p-2" [class.disabled]="it.disabled">
      <p-inputSwitch [ngModel]="it.state" (ngModelChange)="it.state = $event; updateGroupSwitch()"
                     [disabled]="it.disabled"></p-inputSwitch>
    </div>
  </div>
</ng-template>
