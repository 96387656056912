<p-dialog [(visible)]="dialogVisible"
          [baseZIndex]="baseZIndex"
          [closable]="false"
          [closeOnEscape]="type === 'info'"
          [draggable]="false"
          [focusOnShow]="false"
          [modal]="true"
          [resizable]="false"
          [styleClass]="styleClass">

  <div #container class="container p-relative">
    <div [class.top-shadow]="topShadow" class="dialog-title p-fixed p-d-flex p-ai-center p-px-3 p-px-md-5">
      <!--Default Top Action Bar-->
      <ng-template [ngIf]="!topBarTemplate">
        <div class="p-d-flex p-jc-between p-ai-center w-100">
          <div class="p-d-flex p-ai-center">
            <app-button (clicked)="onDiscard()"
                        *ngIf="!isScreenOf(Screen.LG) && enableBackArrow"
                        [disabled]="disableDiscardWhileSaving ? ((onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError) : false"
                        icon="fa-solid fa-arrow-left"
                        styleClass="p-mr-2"
                        type="icon"></app-button>
            <h3 *ngIf="header" class="p-m-0">{{ header }}</h3>
          </div>

          <!--Help-->
          <a *ngIf="helpLink"
             [href]="helpLink" class="p-d-block"
             target="_blank">
            <!-- Help -->
            <app-button [labelAutoHide]="true"
                        i18n-label
                        icon="fa-solid fa-circle-question"
                        label="Help"
                        styleClass="p-mt-2"
                        type="text"></app-button>
          </a>
        </div>
      </ng-template>

      <!--Custom Top Action Bar-->
      <ng-template [ngIf]="topBarTemplate"
                   [ngTemplateOutletContext]="{discardDisable: disableDiscardWhileSaving ? ((onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError) : false}"
                   [ngTemplateOutlet]="topBarTemplate"></ng-template>
    </div>

    <div #scrollContent class="content w-100 p-px-3 p-px-md-5">
      <!--Dialog content-->
      <ng-content></ng-content>

      <!--Response result-->
      <ng-template [ngIf]="!hold">
        <app-call-response *ngIf="showResponse"
                           data-test="dialog-call-response"
                           [component]="component"
                           [errorMsg]="errorMessage || trans.error"
                           [successMsg]="successMessage || trans.success"
                           styleClass="p-my-2"></app-call-response>
      </ng-template>
      <!--Scroll to bottom element-->
      <div class="app-dialog-bottom"></div>
    </div>

    <!--Action Buttons-->
    <div [class.bottom-shadow]="bottomShadow"
         class="action-buttons p-fixed p-d-flex p-jc-end p-px-3 p-px-md-5">
      <!--Default Bottom Action Bar-->
      <ng-template [ngIf]="!bottomBarTemplate">
        <div class="p-d-flex p-jc-between p-ai-center w-100">
          <!-- Phone Contact -->
          <a href="tel:{{contact.phone}}" *ngIf="showContactButton">
            <app-button type="secondary"
                        label="Help"
                        i18n-label
                        icon="fa-solid fa-phone"/>
          </a>
          <div></div>
          <div class="p-d-flex p-ai-center p-jc-end">
            <!--Discard-->
            <app-button (clicked)="onDiscard()"
                        data-test="dialog-discard-option"
                        *ngIf="allowDiscardOption"
                        [disabled]="disableDiscardWhileSaving ? ((onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError) : false"
                        [icon]="discardIcon"
                        [label]="discardLabel || defaultDiscardLabel"
                        styleClass="p-button-rounded p-button-secondary p-button-text p-mr-3"></app-button>

            <!--Save-->
            <div class="submit p-mr-1">
              <app-button (clicked)="onSave()"
                          data-test="dialog-save-option"
                          *ngIf="allowSaveOption"
                          [disabled]="component?.responseSuccess
                                || onSaveClicked
                                || disableSaveOption
                                || component?.loading
                                || component?.saving"
                          [icon]="saveIcon || defaultSaveIcon"
                          [label]="saveLabel || defaultSaveLabel"
                          [loading]="((onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError) || saveLoading"
                          [type]="type === 'danger' ? 'danger' : ((type === 'info') ? 'secondary' : 'save')"></app-button>
            </div>
          </div>
        </div>
      </ng-template>

      <!--Custom bottom action bar template-->
      <ng-template [ngIf]="bottomBarTemplate"
                   [ngTemplateOutletContext]="{
                      discardDisable: disableDiscardWhileSaving ? ((onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError) : false,
                      onDiscard: onDiscard.bind(this),
                      onSave: onSave.bind(this),
                      saveDisable: component?.responseSuccess
                                || onSaveClicked
                                || disableSaveOption
                                || component?.loading
                                || component?.saving,
                      saveLoading: (onSaveClicked || component?.saving) && !component?.responseSuccess && !component?.responseError
                  }"
                   [ngTemplateOutlet]="bottomBarTemplate"></ng-template>
    </div>
  </div>
</p-dialog>

<!--Unsaved changes dialog-->
<app-unsaved-changes (leave)="closeDialog()"
                     *ngIf="unsavedDialogVisible"
                     [(show)]="unsavedDialogVisible"
                     [baseZIndex]="baseZIndex + 100"
                     [customBehavior]="true"></app-unsaved-changes>
