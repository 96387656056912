import {ApiComponent} from '../../../component/abstract/api.component'
import {inject} from '@angular/core'
import {UserResp, UserService} from '../../../service/user.service'
import {NavbarService} from '../../../service/ui/navbar.service'
import {ProfileOrderService} from '../../../service/profile-order.service'
import {StorageService} from '../../../service/storage.service'
import {NavigationService} from '../../../service/ui/navigation.service'
import {AuthTypeEnum} from '../../../common/auth-type-enum'
import {hasProfileTypes, ProfileType} from '../../../common/profile-type'
import {JwtService} from '../../../service/helper-services/jwt.service'
import {CookiesSettingsService} from '../../../service/cookies-settings.service'
import {LegalDocumentType} from '../../../component/support/support.component'
import {firstValueFrom, Subject, takeUntil} from 'rxjs'

/**
 * Abstract component for authentication purpose components.
 * Contains all common services.
 * Also has a function to control the visibility of navbar items.
 */
export abstract class AuthComponent extends ApiComponent {

  AuthType: typeof AuthTypeEnum = AuthTypeEnum

  //inject common services
  protected userService = inject(UserService)
  protected navbarService = inject(NavbarService)
  protected navigation = inject(NavigationService)
  protected profileOrderService = inject(ProfileOrderService)
  protected storageService = inject(StorageService)
  protected jwtService: JwtService = inject(JwtService)
  protected cookiesSettingsService: CookiesSettingsService = inject(CookiesSettingsService)

  /**
   * Subject to end checking for user in {@link redirectIfLogged} method.
   */
  private cancelSub = new Subject<void>()

  /**
   * Manages where to redirect in case of successful login or registration.
   * @param user User returned as response.
   * @param forceArtistProfileRedirect If true, the artist fill be redirected to his/her profile.
   */
  protected afterSuccessRedirect(user: UserResp, forceArtistProfileRedirect: boolean): void {
    // logged or registered Artist
    if (hasProfileTypes(user, ProfileType.ARTIST)) {
      // if other than '/profiles' path is remembered, redirect to that path
      if (forceArtistProfileRedirect) {
        this.navigation.toProfile(user.currentProfile.charId)
      } else {
        this.navigation.toLastUrlBeforeAuthentication()
      }

      // logged or registered User
    } else {
      this.navigation.toLastUrlBeforeAuthentication()
    }
  }

  /**
   * Redirects a user to the {@link ProfilesDashboardComponent} if the JWT is present.
   */
  protected redirectIfLogged(): void {
    this.userService.user
      .pipe(takeUntil(this.cancelSub))
      .subscribe(user => {
        if (user !== null) {
          this.navigation.toProfileCatalog()
        }

        // emit value so this subscription will be closed
        this.cancelSub.next()
        this.cancelSub.complete()
      })
  }

  /**
   * Redirects to the legal documents page.
   */
  protected toLegalDocuments(type?: LegalDocumentType): any[] {
    return [`/${NavigationService.SUPPORT}`, type]
  }

  /**
   * Performs the final steps after successful login.
   */
  protected async finishLogin(resp: UserResp): Promise<void> {
    // If resp is without server messages, then call server to get if there are present orders or list of orders is empty
    const listNotEmpty = await firstValueFrom(this.unwrap(this.profileOrderService.callHasListAnyOrder()))

    // send value to the behavior subject to show a notification bell
    this.navbarService.isNewActivityOrderManager(listNotEmpty)

    this.userService.loginChannel.postMessage(true)

    this.storageService.initStorageAfterLogin(false) // before !!formData.stayLogged, for now only a false statement is required
    //update cookies settings expiration date
    this.cookiesSettingsService.updateExpirationAfterLogin()

    await this.userService.updateUser(resp)
  }

}
