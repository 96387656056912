<form [formGroup]="form">
  <!--Time section-->
  <section class="p-grid p-p-0">
    <!--Start time-->
    <div class="p-col-6 p-p-0">
      <app-date-input [form]="form"
                      formFieldName="startTime"
                      [timeOnly]="true"
                      [required]="true"
                      label="Start time"
                      i18n-label
                      styleClass="p-mr-2"
                      [defaultDate]="defaultStart"
                      icon="fa-solid fa-hourglass-start"
                      (click)="clickOnTimeField('startTime')"
                      placeholder="Start at"
                      i18n-placeholder></app-date-input>
    </div>

    <!--End time-->
    <div class="p-col-6 p-p-0">
      <app-date-input [form]="form"
                      formFieldName="endTime"
                      label="End time"
                      i18n-label
                      styleClass="p-ml-2"
                      [timeOnly]="true"
                      [required]="true"
                      [defaultDate]="defaultEnd"
                      (click)="clickOnTimeField('endTime')"
                      icon="fa-solid fa-hourglass-end"
                      placeholder="Finish at"
                      i18n-placeholder>
        <!--FE-CHECK-->
        <app-frontend-validation [form]="form" field="endTime" error="date" i18n>
          Must be at least {{Restrictions.MIN_EVENT_DURATION_IN_MS_LENGTH / 1000 / 60}} min long.
        </app-frontend-validation>
      </app-date-input>
    </div>
  </section>

  <!--Date section-->
  <section class="p-grid p-p-0">
    <!--Start date-->
    <div class="p-col-12 p-sm-6 p-p-0">
      <app-date-input [form]="form"
                      formFieldName="startDate"
                      [minDate]="tomorrowDate"
                      label="Start date"
                      i18n-label
                      styleClass="p-mr-sm-2"
                      placeholder="Start date"
                      i18n-placeholder
                      appendTo="body"
                      [keyboard]="false"
                      [required]="true"
                      icon="fa-solid fa-calendar-check"></app-date-input>
    </div>

    <!--End date-->
    <div class="p-col-12 p-sm-6 p-p-0">
      <app-date-input [form]="form"
                      formFieldName="endDate"
                      [minDate]="tomorrowDate"
                      label="End date"
                      i18n-label
                      styleClass="p-ml-sm-2"
                      placeholder="Finish date"
                      i18n-placeholder
                      icon="fa-solid fa-minus c-pointer"
                      rightIcon="fa-solid fa-plus"
                      appendTo="body"
                      [required]="true"
                      [disableIcon]="disableDateMinusIcon || !form?.value.endDate"
                      [disableRightIcon]="!form?.value.endDate"
                      (iconClick)="modifyDate('endDate', false)"
                      (rightIconClick)="modifyDate('endDate', true)"></app-date-input>
    </div>
  </section>


  <!--Reset fields-->
  <app-button type="secondary"
              icon="fa-solid fa-arrow-rotate-left"
              *ngIf="form?.value.endDate || form?.value.startDate"
              @fade
              label="Clear Dates"
              i18n-label
              styleClass="p-mr-2 p-my-2"
              (clicked)="resetFields()"></app-button>

  <!--Past time-->
  <div *ngIf="selectedStartTime < defaultStart && form.value['startDate'] && form.value['endDate']"
       class="failed-box p-d-flex p-ai-center p-my-2" @grow>
    <i class="fa-solid fa-calendar-xmark p-mr-2"></i>
    <div>
      <span i18n>Requires at least <b>one hour</b> from now.</span>
    </div>
  </div>

  <!--More than 2 days booking Info-->
  <app-hint *ngIf="daysDifference >= 2" @grow class="p-mb-2" i18n>
    Are you sure you want to book an artist for more than two days in a row? The results can be very narrow.
  </app-hint>
</form>
