import {Component, OnDestroy, OnInit} from '@angular/core'
import {PwaReq, PwaService} from '../../../service/helper-services/pwa.service'
import {Subscription} from 'rxjs'
import {growAnimation} from '../../../animation/grow.animation'
import {RUNNING_AS_PWA} from '../../../app.module'

/**
 * Small dialog from bottom of the page, to ask user to install
 * app as PWA into his device.
 */
@Component({
  animations: [growAnimation()],
  selector: 'app-pwa-dialog',
  templateUrl: './pwa-dialog.component.html',
  styleUrl: './pwa-dialog.component.scss'
})
export class PwaDialogComponent implements OnInit, OnDestroy {

  /**
   * Whether to show dialog from bottom.
   */
  display = false

  /**
   * Whether to show guide after user clicked 'Install' on iOS device.
   */
  displayGuide = false

  /**
   * PwaReq made from {@link PwaService}.
   */
  pwaRequest: PwaReq

  /**
   * Subscription to listen changes on {@link PwaService.pwaDialog}.
   */
  private pwaSub?: Subscription

  protected trans = {
    chrome: $localize`"Install app"`,
    firefox: $localize`"Install"`,
    opera: $localize`"Add to..."`,
    samsung: $localize`"Add site to"`,
    edge: $localize`"Add to phone"`
  }

  constructor(private pwaService: PwaService) {
  }

  ngOnInit(): void {
    this.initPwaSub()
  }

  /**
   * Closes dialog and writes date of interaction to local storage.
   */
  closeDialog(): void {
    this.display = false
    this.displayGuide = false
    this.pwaService.finishPwaPrompt()
  }

  /**
   * Shows guide for ios devices and fires prompt to install for android.
   */
  installApplication(): void {
    const os = this.pwaRequest.device.os

    switch (os) {
      case 'ios':
        this.displayGuide = true
        break
      case 'android':
        if (this.pwaRequest.event) {
          this.closeDialog()
          this.pwaRequest.event?.prompt()
        } else {
          this.displayGuide = true
        }
        break
      default:
        console.error('Unsupported operating system:', os)
        break
    }
  }

  /**
   * Initialized subscription to {@link PwaService.pwaDialog} to control
   * the visibility of the dialog.
   * @private
   */
  private initPwaSub(): void {
    this.pwaSub = this.pwaService.pwaDialog.subscribe(value => {
      this.pwaRequest = value
      this.display = !!this.pwaRequest
        && this.pwaRequest.device.browser !== 'unknown'
        && this.pwaRequest.device.os !== 'unknown'
        && !RUNNING_AS_PWA
    })
  }

  ngOnDestroy(): void {
    this.pwaSub?.unsubscribe()
  }
}
