import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {Country, countryDetailsOf, countryDetailsOfCode, SUPPORTED_COUNTRIES} from '../../../../../common/country'
import {ValidComponent} from '../../../../abstract/valid.component'
import {FormBuilder, FormGroup} from '@angular/forms'
import {StorageItem, StorageService} from '../../../../../service/storage.service'
import {MapService, OSMSearchResult} from '../../../../../service/map.service'
import {firstValueFrom, Subscription} from 'rxjs'
import {AddressReq} from '../../../../../service/address.service'
import {LatLngExpression} from 'leaflet'
import {LeafletService} from '../../../../../service/ui/leaflet.service'
import {scrollToIndex} from '../../../../../utils/scroll.utils'
import {ServerMessage} from '../../../../../common/server-message'
import {ProfileResp} from '../../../../../service/profile.service'
import {growAnimation} from '../../../../../animation/grow.animation'
import {BasketService} from '../../../../../service/basket.service'
import {DialogComponent} from '../../../../common/dialog/dialog.component'

@Component({
  animations: [growAnimation()],
  selector: 'app-order-performance-address',
  templateUrl: './order-performance-address.component.html',
  styleUrls: ['./order-performance-address.component.scss']
})
export class OrderPerformanceAddressComponent extends ValidComponent implements OnInit, OnDestroy {
  @Input()
  profile: ProfileResp
  /**
   * Parent dialog component.
   */
  @Input()
  dialog?: DialogComponent
  /**
   * Emits the loading process of the component.
   */
  @Output()
  emitLoading = new EventEmitter<boolean>()
  /**
   * The form fields with user {@link addressOption} - invoicing-author or event-order address.
   */
  addressForm: FormGroup
  /**
   * Contains the original location on the map from the map search.
   */
  originalMapLocation?: LatLngExpression
  /**
   * Contains the user-specified map location.
   */
  mapLocation?: LatLngExpression
  /**
   * - When the map search fails, this will contain only the city-postal-country-state search result.
   * - It is used to center the map.
   */
  cityOnlyLocation?: LatLngExpression
  /**
   * A list of all supported countries.
   */
  supportedCountries: typeof SUPPORTED_COUNTRIES = SUPPORTED_COUNTRIES
  /**
   * The current postal information.
   */
  orderAddress: AddressReq
  /**
   * - Holds the current map search timeout.
   * - See the {@link startMapSearch} function.
   */
  private mapSearchTimeout: any
  /**
   * The forms' value change subscriptions.
   */
  private formSubs?: Subscription[] = []

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private mapService: MapService,
    private changeRef: ChangeDetectorRef,
    private leaflet: LeafletService,
    private basketService: BasketService) {
    super()
  }

  override ngOnInit(): void {
    this.orderAddress = this.loadOrderAddress()
    this.initForm()
    super.ngOnInit()

    // start map search if the address has not been set
    if (!this.orderAddress?.lat || !this.orderAddress?.lng) {
      this.startMapSearch(this.addressForm.value)
    } else {
      if (this.leaflet.isReady()) {
        this.mapLocation = this.leaflet.latLng(this.orderAddress.lat, this.orderAddress.lng)
        this.originalMapLocation = this.mapLocation
        this.validateAddress()
      }
    }
  }

  /**
   * - Calls the request to search on the map for the latitude and longitude of given {@link addressForm} data.
   * - The request will start after the 750ms of user inactivity. (From the last function call).
   */
  startMapSearch(d): void {
    if (this.mapSearchTimeout) {
      clearTimeout(this.mapSearchTimeout)
    }
    this.mapSearchTimeout = setTimeout(() => {
      // return if missing mandatory values
      if (!d.line1 || !d.city || !d.country) {
        return
      }

      // call the map search
      this.call(async () => {
        this.emitLoading.emit(true)
        this.setValid(false)
        const citySearch = `${d.city || ''} ${d.postalCode || ''} ${countryDetailsOfCode(d.country)?.name || ''}`.trim()
        const search = `${d.line1 || ''} ${citySearch}`.trim()
        let result = (await firstValueFrom(this.mapService.callOSMSearch(search)))?.[0]
        if (result) {
          this.cityOnlyLocation = null
          if (this.leaflet.isReady()) {
            this.mapLocation = this.leaflet.latLng(+result.lat, +result.lon)
            this.originalMapLocation = this.mapLocation // update the original location
            this.updateLocation(this.originalMapLocation)
            // Based on the user feedback, automatic adress rewrite is disabled
            // this.updateAddressFields(result)
          }
        } else {
          this.mapLocation = null
          this.originalMapLocation = null
          // City Only location
          result = (await firstValueFrom(this.mapService.callOSMSearch(citySearch)))?.[0]
          if (result && this.leaflet.isReady()) {
            this.cityOnlyLocation = this.leaflet.latLng(+result.lat, +result.lon)
            // Based on the user feedback, automatic adress rewrite is disabled
            // this.updateAddressFields(result)
          }

          this.pushToMessages(ServerMessage.PROFILE_ORDER_LOCATION_MISSING)
          this.updateLocation(null)
        }
      }, (e) => {
        this.onResponseError(e)
        this.pushToMessages(ServerMessage.PROFILE_ORDER_LOCATION_MISSING)
        this.updateLocation(null)
      }, () => {
        this.saveOrderAddress()
        this.emitLoading.emit(false)
      })
    }, 1200)
  }

  /**
   * Initializes form of the {@link orderAddress} info about the customer based on the clicked {@link addressOption}.
   */
  initForm(): void {
    this.addressForm = this.formBuilder.group({
      name: [this.orderAddress?.name || ''],
      line1: [this.orderAddress?.line1 || ''],
      line2: [this.orderAddress?.line2 || ''],
      city: [this.orderAddress?.city || ''],
      postalCode: [this.orderAddress?.postalCode || ''],
      state: [this.orderAddress?.state || ''],
      country: [countryDetailsOfCode(this.orderAddress?.country) || countryDetailsOf(Country.SLOVAKIA)]
    })

    this.validateAddress()
    if (!this.orderAddress?.lat || !this.orderAddress?.lng) {
      this.startMapSearch(this.addressForm.value)
    }
    this.changeRef.detectChanges()

    this.formSubs.push(this.addressForm.valueChanges.subscribe(this.formChanged.bind(this)))
  }

  /**
   * Fires on {@link addressForm} value changes.
   */
  private formChanged(): void {
    this.cityOnlyLocation = null
    const formData = this.addressForm.value
    this.orderAddress = {
      name: formData.name,
      lat: null,
      lng: null,
      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      postalCode: formData.postalCode,
      state: formData.country?.name,
      country: formData.country?.code
    }

    this.saveOrderAddress()
    this.startMapSearch(formData)
  }

  /**
   * Updates a map location and saves it to the local storage.
   */
  updateLocation(location: any): void {
    this.mapLocation = location
    this.orderAddress.lat = location?.lat || null
    this.orderAddress.lng = location?.lng || null
  }

  /**
   * Updates certain address fields from the {@link OSMSearchResult} object.
   */
  private updateAddressFields(osmResult: OSMSearchResult): void {
    if (osmResult) {
      const opts = {emitEvent: false}
      const c = this.addressForm.controls
      const postalCode = osmResult.address?.postcode?.replace(' ', '') || ''
      const country = countryDetailsOfCode(osmResult.address.country_code) || countryDetailsOf(Country.SLOVAKIA)
      c.country.setValue(country, opts)
      c.postalCode.setValue(postalCode, opts)
      this.orderAddress.postalCode = postalCode
      this.orderAddress.country = country.code
    }
  }

  /**
   * Validates the current order step of the address.
   */
  private validateAddress(): void {
    const areMessages = this.serverMessages.filter(it => it !== ServerMessage.PROFILE_ORDER_LOCATION_MISSING).length > 0
    const addressValid = this.orderAddress ?
      (this.addressForm.valid && !areMessages && this.orderAddress.lat > 0 && this.orderAddress.lng > 0) : false
    this.setValid(!!addressValid)
  }

  /**
   * Saves the event-order {@link orderAddress} to the local storage.
   */
  saveOrderAddress(): void {
    this.basketService.updateOrderStorage(null, this.orderAddress, null, null)
    // this.basketService.createNewOrderTimeout() // created for the future
    this.validateAddress()
  }

  /**
   * Loads the event-order {@link orderAddress} from the local storage.
   */
  private loadOrderAddress(): AddressReq {
    return JSON.parse(this.storageService.getItemStorage(StorageItem.USER_ORDER_ADDRESS)) as AddressReq
  }

  /**
   * - Scrolls to the bottom of this component.
   * - It is called outside the component.
   */
  scrollBottom(): void {
    scrollToIndex('scroll-selector', 0, 'smooth')
  }

  ngOnDestroy(): void {
    // unsubscribe all form value changes
    this.formSubs.forEach((it) => {
      it?.unsubscribe()
    })
  }
}
