import {Component, EventEmitter, Input, Output} from '@angular/core'
import {MenuItem} from 'primeng/api'
import {StepsModule} from 'primeng/steps'

/**
 * The customized PrimeNG <p-steps> component suitable for Dialog's header.
 */
@Component({
  selector: 'app-dialog-steps',
  templateUrl: './dialog-steps.component.html',
  styleUrls: ['./dialog-steps.component.scss'],
  imports: [
    StepsModule
  ],
  standalone: true
})
export class DialogStepsComponent {

  /**
   * The model of steps.
   */
  @Input()
  steps: MenuItem[] = []

  /**
   * - Represents the current active index in {@link steps}.
   * - 0 by default.
   */
  @Input()
  activeIndex = 0

  /**
   * Emits on changes the {@link activeIndex}.
   */
  @Output()
  activeIndexChange = new EventEmitter<number>()

  /**
   * - Defines whether the steps are in readonly mode.
   * - Enabled by default.
   */
  @Input()
  readonly = true

  /**
   * Style classes to be applied directly to the nested component.
   */
  @Input()
  styleClass: string
}
