import {Pipe, PipeTransform} from '@angular/core'

/**
 * Joins values with the comma.
 * Returns joined values of array.
 */
@Pipe({
  name: 'join',
  standalone: true
})
export class JoinPipe implements PipeTransform {
  transform(values: any[], mappedBy?: string): string {
    if (mappedBy) {
      return values.map(it => it[mappedBy]).join(', ')
    }
    return values.join(', ')
  }
}
