import {Injectable} from '@angular/core'
import {BaseResp} from '../rest/base-resp'
import {Observable} from 'rxjs'
import {BriefProfileResp} from './profile.service'
import {Endpoint} from '../common/endpoints'
import {ProfileType} from '../common/profile-type'
import {CalendarMessageType} from '../common/calendar-message-type'
import {ApiService} from './api.service'
import {ScheduleItemResp} from './schedule.service'
import {Page} from '../rest/page-resp'

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends ApiService {

  constructor() {
    super()
  }

  callNewCalendarItem(req: NewCalendarItemReq): Observable<BaseResp<CalendarItemResp>> {
    return this.post(Endpoint.CALENDAR_ITEM_NEW_URL, req)
  }

  callCalendarItemNestedProfiles(req: CalendarItemNestedProfilesReq): Observable<BaseResp<CalendarItemNestedProfileResp[]>> {
    return this.post(Endpoint.CALENDAR_ITEM_NESTED_PROFILE_URL, req)
  }

  callUpdateCalendarItem(req: UpdateCalendarItemReq): Observable<BaseResp<CalendarItemResp>> {
    return this.post(Endpoint.CALENDAR_ITEM_UPDATE_URL, req)
  }

  callFindCalendarItemsBetween(req: FindCalendarItemsBetweenReq): Observable<BaseResp<CalendarItemResp[]>> {
    return this.post(Endpoint.CALENDAR_ITEM_FIND_BETWEEN_URL, req, super.getHeaders(), false)
  }

  callFindCalendarItemsBetweenOfOrders(req: FindCalendarItemsBetweenReq): Observable<BaseResp<CalendarItemResp[]>> {
    return this.post(Endpoint.CALENDAR_ITEM_FIND_BETWEEN_ORDERS_URL, req, super.getHeaders(), false)
  }

  callFindFirstCalendarItem(req: FindFirstCalendarItemReq): Observable<BaseResp<CalendarItemResp | null>> {
    return this.post(Endpoint.CALENDAR_ITEM_FIND_FIRST_URL, req)
  }

  callDeleteCalendarItem(req: DeleteCalendarItemReq): Observable<BaseResp<boolean>> {
    return this.post(Endpoint.CALENDAR_ITEM_DELETE_URL, req)
  }
}

export interface NewCalendarItemReq {
  profileId: number
  messageType?: CalendarMessageType
  start: Date
  end: Date
  name?: string
  description?: string
  nestedProfileId?: number
}

export interface CalendarItemResp {
  id: number
  messageType?: CalendarMessageType
  start: Date
  end: Date
  name?: string
  description?: string
  nestedProfile?: BriefProfileResp
  deleted?: boolean

  // represents whether the calendar item belongs to the ProfileOrder and the order is accepted by profile
  orderActive?: boolean
}

export interface CalendarItemNestedProfilesReq {
  nestedProfileIds: number[]
}

export interface CalendarItemNestedProfileResp {
  profileId: number
  profileType: ProfileType

  wallpapers: string[]
  avatar?: string

  charId: string
  displayName: string

  bio?: string

  followers: number
  participants: ScheduleItemResp[]
  attendees: Page<BriefProfileResp>
  attendeesCount: number
}

export interface UpdateCalendarItemReq {
  profileId: number
  calendarItemId: number
  messageType?: CalendarMessageType
  start: Date
  end: Date
  name?: string
  description?: string
  nestedProfileId?: number
}

export interface FindCalendarItemsBetweenReq {
  profileId: number
  start: Date
  end: Date
}

export interface FindFirstCalendarItemReq {
  profileId: number
}

export interface DeleteCalendarItemReq {
  profileId: number
  calendarItemId: number
}

export interface CalendarRest {
  modifiedAt: Date
  modificationProfile: BriefProfileResp
}
