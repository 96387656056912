import {Component} from '@angular/core'

/**
 * Standalone shared component.
 * Can be used in a situation where content is too large and part of content
 * needs to be hidden but not the whole content.
 * For example, with long paragraphs where the first one - two sentences needs to be visible.
 * And the rest after expansion.
 */
@Component({
  selector: 'app-collapsible-element',
  standalone: true,
  imports: [],
  templateUrl: './collapsible-element.component.html',
  styleUrl: './collapsible-element.component.scss'
})
export class CollapsibleElementComponent {

  expanded = false

  toggleExpanded(): void {
    this.expanded = !this.expanded
  }

  expand(): void {
    this.expanded = true
  }
}
