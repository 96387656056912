import {Component, Input} from '@angular/core'
import {ServerMessage} from '../../../common/server-message'
import {growAnimation} from '../../../animation/grow.animation'
import {NgIf} from '@angular/common'

@Component({
  animations: [growAnimation()],
  selector: 'app-backend-validation',
  templateUrl: './backend-validation.component.html',
  styleUrls: ['./backend-validation.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class BackendValidationComponent {

  @Input()
  serverMessages: string[] = []

  @Input()
  check: ServerMessage

  @Input()
  styleClass: string

  /**
   * Displays the error message within a fancy error box.
   */
  @Input()
  showAsBox: boolean

  /**
   * Displays the error message within a positive colors, that represent the success.
   */
  @Input()
  showAsSuccess: boolean

  constructor() {
  }

}
