import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core'
import {OrderableProfileResp} from '../../../../service/profile.service'
import {ApiComponent} from '../../../abstract/api.component'
import {getDeviceMatchWidthWallpaper} from '../../../../utils/device.utils'
import {defaultWallpaper} from '../../../../utils/asset.utils'
import {isArrayNullOrEmpty} from '../../../../utils/utils'
import {PLATFORM_BROWSER} from '../../../../app.module'
import {NavigationService} from '../../../../service/ui/navigation.service'

@Component({
  selector: 'app-profile-catalog-item',
  templateUrl: './profile-catalog-item.component.html',
  styleUrls: ['./profile-catalog-item.component.scss']
})

export class ProfileCatalogItemComponent extends ApiComponent implements OnChanges {

  @Input()
  data: OrderableProfileResp

  wallpapers: string[] = []

  /**
   * Fires the {@link data} when the user clicked on the item.
   */
  @Output()
  clicked = new EventEmitter<OrderableProfileResp>()
  /**
   * Translations for this component.
   */
  protected trans = {
    unspecified: $localize`Unspecified`
  }

  constructor(public navigation: NavigationService) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.initWallpapers()
    }
  }

  /**
   * Initializes the wallpaper images.
   */
  private initWallpapers(): void {
    if (PLATFORM_BROWSER) {
      const deviceVariant = getDeviceMatchWidthWallpaper()
      this.wallpapers = this.data.wallpapers?.map(w => `${w}/${deviceVariant}`)
      if (isArrayNullOrEmpty(this.wallpapers)) {
        this.wallpapers = [defaultWallpaper()]
      }
    }
  }
}
