<app-dialog *ngIf="show"
            [(show)]="show"
            [component]="this"
            [allowSaveOption]="false"
            header="Search order"
            i18n-header
            type="info"
            discardLabel="Cancel"
            i18n-discardLabel>

  <app-search [component]="this"
              [length]="{max: Restrictions.MAX_PROFILE_SEARCH_LENGTH}"
              placeholder="Search profile orders by order ID, name or profile"
              i18n-placeholder
              [searchFocus]="true"
              [searchFunction]="callSearchProfileOrders.bind(this)"
              [(items)]="searchPage"
              [itemHeight]="126"
              [maxScrollHeight]="126*3">

    <!--Item template-->
    <ng-template let-order pTemplate="item">
      <!--Order-->
      <a class="p-d-block order rounded-medium box-shadow c-pointer w-100 p-mx-1 p-my-2" pRipple
         [routerLink]="navigation.urlProfileBookingDetail(profileData?.charId, order.id)">
        <!--order header-->
        <div class="order-header p-d-flex p-jc-between p-ai-center word-break p-px-3">
          <h3 class="p-m-0">{{order.name}}</h3>
          <div class="p-text-bold">{{order.total | price}}</div>
        </div>

        <!--order body-->
        <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-between p-px-3 p-my-2">
          <!--avatar, name, profession-->
          <div *ngVar="(order.author.charId === profileData.charId) ? order.profile : order.author as pr"
               class="p-d-flex p-mb-1">
            <app-avatar [profile]="pr"></app-avatar>

            <div class="p-ml-2">
                <h3 class="p-my-0 profile-title word-break unselectable"
                  [class.l-count-2]="pr.professions?.length === 0"
                  [class.l-count-1]="pr.professions?.length > 0">{{pr.displayName}}</h3>
              <!--Professions-->
              <div class="profession-container">
                <app-profile-professions *ngIf="pr.professions?.length > 0"
                                         [data]="pr"
                                         type="text"
                                         styleClass="profession"></app-profile-professions>
              </div>
            </div>
          </div>

          <!--Order ID-->
          <div class="order-id p-d-flex p-flex-sm-column p-jc-center rounded-medium p-px-2 p-py-1">
            <div i18n>Order ID</div>
            <hr *ngIf="isScreenOf(Screen.SM)" class="p-m-0">
            <div class="p-text-bold p-pl-1">#{{order.id}}</div>
          </div>
        </div>
      </a>
    </ng-template>

    <ng-template pTemplate="noContent">
      <div class="p-d-flex p-ai-center p-my-3 p-px-3 unselectable">
        <i class="fa-solid fa-heart-crack p-mr-2"></i>
        <div i18n>No orders found</div>
      </div>
    </ng-template>
  </app-search>
</app-dialog>
