<app-dialog *ngIf="show"
            type="save"
            discardLabel="Cancel"
            i18n-discardLabel
            url="confirm"
            [(show)]="show"
            [component]="this"
            [saveLabel]="saveLabel"
            [saveIcon]="saveIcon"
            [successMessage]="successMessage"
            [showResponse]="showResponse"
            [save]="save.bind(this)"
            (saved)="saved.emit($event)">

  <!--Icon and Title-->
  <div class="p-d-flex p-px-3">
    <i class="confirm-icon p-mt-2 {{icon}}"></i>
    <div class="p-ml-3">
      <h2 class="p-m-0">{{question}}</h2>

      <!--Description-->
      <p class="p-mt-2 p-mb-2">
        <ng-content></ng-content>
      </p>
    </div>
  </div>
</app-dialog>
